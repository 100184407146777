import { t } from '@lingui/macro';

import { BFGCurrentUser, User, ExtendedUserRoles, UserRoles } from '@api/v4/UserTypes';

/**
 * Get a users display name
 * @example Jim Hanifen (firstname + lastname)
 * @example jim.hanifen@brandfolder.com (email)
 * @example Anonymous (user is null or undefined)
 * @param user User | null | undefined
 * @returns string
 */
export const getUserDisplayName = (user?: User | null): string => {
  if (!user) return t`Anonymous`;

  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }

  return user.email;
};

/**
 * Get whether the current user is an admin or not
 * @param user BFGCurrentUser | null
 * @returns boolean
 */
export const getCurrentUserIsAdmin = (user?: BFGCurrentUser | null): boolean => {
  if (!user) return false;
  if (user.role === UserRoles.Admin && user.user_id) return true;
  return false;
};

/**
 * Get whether the current user is a collaborator or not
 * @param user BFGCurrentUser | null
 * @returns boolean
 */
export const getCurrentUserIsCollaborator = (user?: BFGCurrentUser | null): boolean => {
  if (!user) return false;
  if (user.role === UserRoles.Collaborator && user.user_id) return true;
  return false;
};

/**
 * Get whether the current user is anonymous or not
 * @param user BFGCurrentUser | null
 * @returns boolean
 */
export const getCurrentUserIsAnonymous = (user?: BFGCurrentUser | null): boolean => {
  if (!user) return true;
  if (!user.authorizedAsGuest) return true;
  if (user.role === ExtendedUserRoles.Empty || user.role === ExtendedUserRoles.None) return true;
  // sigh... this is when you're locally on a public resource or share link in an private/incognito window
  if (user.role === UserRoles.Guest && user.user_id === '') return true;
  return false;
};

/**
 * Get whether the current user is a guest or not
 * @param user BFGCurrentUser | null
 * @returns boolean
 */
export const getCurrentUserIsGuest = (user?: BFGCurrentUser | null): boolean => {
  if (!user) return false;
  // user.user_id is needed because guests are assigned UserRoles.Guest
  if (user.role === UserRoles.Guest && user.user_id && user.authorizedAsGuest) return true;
  return false;
};

/**
 * Get whether the current user is an owner or not
 * @param user BFGCurrentUser | null
 * @returns boolean
 */
export const getCurrentUserIsOwner = (user?: BFGCurrentUser | null): boolean => {
  if (!user) return false;
  if (user.role === UserRoles.Owner && user.user_id) return true;
  return false;
};

/**
 * Get whether the current user is a super user or not
 * @param user BFGCurrentUser | null
 * @returns boolean
 */
export const getCurrentUserIsSuper = (user?: BFGCurrentUser | null): boolean => {
  if (!user) return false;
  if (user.su) return true;
  return false;
};

/**
 * Get whether the current users is anonymous or guest
 * @param user BFGCurrentUser | null
 * @returns boolean
 */
export const getCurrentUserIsAnonymousOrGuest = (user?: BFGCurrentUser | null): boolean => getCurrentUserIsAnonymous(user) || getCurrentUserIsGuest(user);

/** Get whether the current user is a super user, an owner or an admin or not
* @param user BFGCurrentUser | null
* @returns boolean
*/
export const getCurrentUserIsAdminPlus = (user?: BFGCurrentUser | null): boolean => {
  if (!user) return false;
  return (user.su
    || user.role === UserRoles.Owner
    || user.role === UserRoles.Admin
  );
};

/**
* Get whether the current user is a super user, an owner or an admin or a collaborator not
* @param user BFGCurrentUser | null
* @returns boolean
*/
export const getCurrentUserIsCollaboratorPlus = (user?: BFGCurrentUser | null): boolean => {
  if (!user) return false;
  return (user.su
    || user.role === UserRoles.Owner
    || user.role === UserRoles.Admin
    || user.role === UserRoles.Collaborator
  );
};
