import { FontIcons } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { useState, FunctionComponent, useEffect, ReactElement } from 'react';

import { useFetch, useNotifyFetchError } from '@api/ApiHelper';
import {
  CustomizedTemplate,
  CustomizedTemplatePutBody,
  CustomizedTemplatePutBodyClient,
  CustomizedTemplateResponse,
  CustomizedTemplateApprovalStatuses
} from '@api/v4/private/resources/design_huddles';
import { BFLoader } from '@components/common/loader/main';
import { APPROVAL_STATUS_STRINGS } from '@components/design_huddles/editors/helpers';
import { SaveToBrandfolder, SaveToBrandfolderProps } from '@components/design_huddles/editors/SaveToBrandfolder';
import { PrimaryButton, TextButton } from '@components/library/button';
import { snakifyObjectKeys } from '@components/library/utils';
import { getCurrentUserIsAdminPlus } from '@helpers/user';

import './styles/approval-state.scss';

interface ApprovalStatusProps {
  approvalsRequired: boolean;
  approvalStatus: CustomizedTemplateApprovalStatuses;
  customizedTemplateKey: string;
  customizedTemplateLoading: boolean;
  saveToBrandfolderProps: Omit<SaveToBrandfolderProps, 'templateKey'>;
  setCustomizedTemplate: SetStateDispatch<CustomizedTemplate>;
  userHasCollabPerms: boolean;
}

enum AvailableActions {
  RequestApproval,
  Approve,
  Unapprove,
  Save
}

export const ApprovalStatus: FunctionComponent<ApprovalStatusProps> = ({
  approvalsRequired,
  approvalStatus,
  customizedTemplateKey,
  customizedTemplateLoading,
  saveToBrandfolderProps,
  setCustomizedTemplate,
  userHasCollabPerms,
}) => {
  const [putBody, setPutBody] = useState<CustomizedTemplatePutBodyClient | null>(null);
  const snakifiedPutBody = snakifyObjectKeys<CustomizedTemplatePutBodyClient, CustomizedTemplatePutBody>(putBody);
  const putCustomizedTemplate = useFetch<CustomizedTemplateResponse>({
    url: `/api/v4/private/customized_design_huddle_templates/${customizedTemplateKey}`,
    fetchOnMount: false,
    method: 'PUT',
    body: {
      data: {
        attributes: {
          ...snakifiedPutBody
        }
      }
    }
  });
  const isAdmin = getCurrentUserIsAdminPlus(BFG.currentUser);

  const saveToBrandfolderButton = (
    <React.Fragment key="template-save-to-brandfolder-button">
      <PrimaryButton
        className="project__button--save"
        id="template-save-to-brandfolder"
        onClick={(): void => saveToBrandfolderProps?.setShowSaveToBrandfolder(true)}
      >
        <Trans>Save to Brandfolder</Trans>
      </PrimaryButton>
      <SaveToBrandfolder
        {...saveToBrandfolderProps}
        templateKey={customizedTemplateKey}
      />
    </React.Fragment>
  );

  const requestApprovalButton = (
    <PrimaryButton
      key="template-request-approval-button"
      className="project__button--request-approval"
      onClick={(): void => {
        setPutBody({ approvalState: CustomizedTemplateApprovalStatuses.Pending });
      }}
    >
      <Trans>Request approval</Trans>
    </PrimaryButton>
  );

  const approveButton = (
    <TextButton
      key="template-approve-button"
      aria-label={t`Approve template`}
      className="template-approve-button"
      icon={FontIcons.Approve}
      onClick={(): void => {
        setPutBody({
          approvalState: CustomizedTemplateApprovalStatuses.Approved
        });
      }}
    >
      <Trans>Approve</Trans>
    </TextButton>
  );

  const unapproveButton = (
    <TextButton
      key="template-unapprove-button"
      aria-label={t`Unapprove template`}
      className="template-unapprove-button"
      icon={FontIcons.Unapprove}
      onClick={(): void => {
        setPutBody({
          approvalState: CustomizedTemplateApprovalStatuses.Unapproved
        });
      }}
    >
      <Trans>Unapprove</Trans>
    </TextButton>
  );

  const actionButtonMap: Record<AvailableActions, ReactElement> = {
    [AvailableActions.Approve]: approveButton,
    [AvailableActions.RequestApproval]: requestApprovalButton,
    [AvailableActions.Save]: saveToBrandfolderButton,
    [AvailableActions.Unapprove]: unapproveButton
  };

  const renderPrimaryActions = (
    actions: AvailableActions[],
  ): ReactElement => (
    <div className="template__primary-actions">
      {actions.map((action) => actionButtonMap[action])}
    </div>
  );

  useEffect(() => {
    if (putBody) {
      putCustomizedTemplate.fetch();
    }
  }, [putBody]); // eslint-disable-line react-hooks/exhaustive-deps

  useNotifyFetchError(putCustomizedTemplate);

  useEffect(() => {
    if (putCustomizedTemplate.response) {
      const { attributes } = putCustomizedTemplate.response.data;
      setCustomizedTemplate(attributes);

      if (attributes.approval_state !== CustomizedTemplateApprovalStatuses.NoApprovalRequested) {
        Notify.create({
          type: 'success',
          title: APPROVAL_STATUS_STRINGS()[attributes.approval_state]?.successMessage
        });
      }
    }
  }, [putCustomizedTemplate.response]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!approvalsRequired
    || (isAdmin && approvalStatus === CustomizedTemplateApprovalStatuses.NoApprovalRequested)) {
    return userHasCollabPerms && renderPrimaryActions([AvailableActions.Save]);
  }

  if (customizedTemplateLoading || putCustomizedTemplate.loading) {
    return (
      <div className="template__primary-actions">
        <BFLoader />
      </div>
    );
  }

  if (!isAdmin) {
    switch (approvalStatus) {
      case CustomizedTemplateApprovalStatuses.NoApprovalRequested:
        return (
          renderPrimaryActions([AvailableActions.RequestApproval])
        );
      case CustomizedTemplateApprovalStatuses.Pending:
        return (
          renderPrimaryActions([])
        );
      case CustomizedTemplateApprovalStatuses.Approved:
        return (
          renderPrimaryActions(userHasCollabPerms ? [AvailableActions.Save] : [])
        );
      case CustomizedTemplateApprovalStatuses.Unapproved:
        return (
          renderPrimaryActions([AvailableActions.RequestApproval])
        );
      default:
        return null;
    }
  }

  switch (approvalStatus) {
    case CustomizedTemplateApprovalStatuses.Pending:
      return (
        renderPrimaryActions([AvailableActions.Approve, AvailableActions.Unapprove, AvailableActions.Save])
      );
    case CustomizedTemplateApprovalStatuses.Approved:
      return (
        renderPrimaryActions([AvailableActions.Unapprove, AvailableActions.Save])
      );
    case CustomizedTemplateApprovalStatuses.Unapproved:
      return (
        renderPrimaryActions([AvailableActions.Approve, AvailableActions.Save])
      );
    case CustomizedTemplateApprovalStatuses.NoApprovalRequested: /* should never be hit */
    default:
      return null;
  }
};
