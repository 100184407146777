import React from 'react';

import { EmbeddedWidget, getEmbeddedWidget, ResourceTypes } from '@api/v4/private/embedded_widgets';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { EmbeddedWidgetsCard } from './embedded_widgets_card';

import './styles/embedded_widgets_card.scss';

interface EmbeddedWidgetsCardsProps {
  resourceKey: string;
  resourceType: ResourceTypes;
}

interface EmbeddedWidgetsCardsState {
  embeddedWidget: EmbeddedWidget | null;
}

/**
 * NOTE: this is required to be a React class component (but it can just be a functional component wrapper, if needed)
 * because react-rails (https://github.com/reactjs/react-rails) doesn't support functional components from slim files
 */
class EmbeddedWidgetsCards extends React.Component<EmbeddedWidgetsCardsProps> {
  state: EmbeddedWidgetsCardsState = {
    embeddedWidget: null
  };

  componentDidMount(): void {
    this.fetchEmbeddedWidgets();
  }

  async fetchEmbeddedWidgets(): Promise<void> {
    const { resourceKey, resourceType } = this.props;

    if (resourceKey && resourceType) {
      try {
        const result = await getEmbeddedWidget({ resourceKey, resourceType });
        this.setState({ embeddedWidget: result });
      } catch (err) {
        // do nothing (don't display the embedded widget card)
      }
    } else {
      // do nothing (don't display the embedded widget card)
    }
  }

  render(): JSX.Element | null {
    const { resourceType } = this.props;
    const { embeddedWidget } = this.state;

    if (!embeddedWidget || (embeddedWidget && !embeddedWidget.isActive)) return null;

    return (
      <I18nProviderWrapper>
        <div className={`embedded-widgets-cards embedded-widgets-cards--${resourceType}`}>
          <EmbeddedWidgetsCard
            buttonText={embeddedWidget.buttonText}
            description={embeddedWidget.description}
            name={embeddedWidget.name}
            srcUrl={embeddedWidget.srcUrl}
          />
        </div>
      </I18nProviderWrapper>
    );
  }
}

export default EmbeddedWidgetsCards;
