import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { PermissionsTab } from '@components/bulk_management/user_groups/PermissionsTab';
import { UserGroupsAddUsers } from '@components/bulk_management/user_groups/UserGroupsAddUsers';
import { UserGroupsUsersTable } from '@components/bulk_management/user_groups/UserGroupsUsersTable';
import { StandardTabs } from '@components/library/tabs';

import classes from './styles/user-groups.module.scss';

export type Tab = 'permissions' | 'users';

interface PermissionsUsersTabsProps {
  currentTab: Tab;
  memberCount: number;
  reloadMembers: boolean;
  setReloadMembers: SetStateDispatch<boolean>;
  setCurrentTab: SetStateDispatch<Tab>;
  userGroupKey: string;
}

export const PermissionsUsersTabs: FunctionComponent<PermissionsUsersTabsProps> = ({
  currentTab,
  reloadMembers,
  setReloadMembers,
  userGroupKey,
  setCurrentTab
}) => {
  return (
    <section className={classnames(classes.tabsContainer, 'user-groups-tabs')}>
      <StandardTabs
        activatePanelOnPrevNext={false}
        caption={t`Choose between adding a group to a resource and inviting a user to a user group`}
        id="user-groups-edit-tabs"
        initialActiveIndex={currentTab === 'users' ? 0 : 1}
        onChange={(nextIndex): void => {
          if (nextIndex === 0) {
            setCurrentTab('users');
          } else {
            setCurrentTab('permissions');
          }
        }}
        showUnderline
        tabButtonClassName={classnames(classes.tabButton)}
        tabListClassName={classnames(classes.tabList, 'user-groups-edit-tab-list')}
        tabPanelClassName={classes.tabPanel}
        tabs={[{
          disabled: false,
          tabButton: t`Users`,
          tabPanel: (
            <div className={classnames(classes.usersPage, 'user-groups-users-page')}>
              <UserGroupsAddUsers
                setReloadMembers={setReloadMembers}
                userGroupKey={userGroupKey}
              />
              <UserGroupsUsersTable
                reloadMembers={reloadMembers}
                setReloadMembers={setReloadMembers}
                userGroupKey={userGroupKey}
              />
            </div>
          )
        }, {
          disabled: false,
          tabButton: t`Permissions`,
          tabPanel: (
            <PermissionsTab userGroupKey={userGroupKey} />
          )
        }]}
      />
    </section>
  );
};
