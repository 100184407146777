import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { postToggleApproval } from '@api/v4/bulk_actions/assets';
import RadioButton from "@components/common/radio_button/main";
import { bulkRefreshSections } from '@helpers/show_page_helpers';

import { handleNotification, sweetAlertUnapproveOptions } from './helper';

const Approval = (props) => {
  const [approvingInProgress, setApprovingInProgress] = useState(false);
  const { asset, setAsset, updateFetchControllers } = props;
  const { activeCdnTracers, approved } = asset.attributes;

  const updateAsset = (assetApproved) => {
    setAsset((oldAsset) => {
      const newAsset = { ...oldAsset };
      newAsset.attributes.availability = assetApproved ? 'approved' : 'pending_approval';
      newAsset.attributes.approved = !oldAsset.attributes.approved;

      return newAsset;
    });
  };

  const approveAsset = (assetApproved) => {
    updateAsset(assetApproved);
    setApprovingInProgress(true);

    const options = {
      approvalType: assetApproved ? 'approve' : 'unapprove',
      data: { asset_keys: [asset.id] },
      context: this
    };

    postToggleApproval(options, updateFetchControllers)
      .then((response) => {
        setApprovingInProgress(false);
        if (response && !response.ok) {
          bulkRefreshSections([asset.id]);
          handleNotification('success', assetApproved, response.required_approvals);
        } else {
          updateAsset(!assetApproved); // reverse state in case of error
          handleNotification('error', assetApproved);
        }
      });
  };

  const requestApproval = () => {
    if (approved) {
      if (activeCdnTracers && activeCdnTracers > 0) {
        window.swal(sweetAlertUnapproveOptions(), (unapprove_selected) => {
          if (unapprove_selected) {
            approveAsset(false);
          }
        });
      } else {
        approveAsset(false);
      }
    } else {
      approveAsset(true);
    }
  };

  return (
    <div className={`availability-row approval-container ${approvingInProgress ? 'disabled' : ''}`}>
      <h4><Trans>Approval Status:</Trans></h4>
      <div className="radio-buttons radio-button-container">
        <RadioButton
          checkedButton={approved ? "approve" : "unapprove"}
          labelCopy={t`Approved`}
          name="status-approval"
          onChangeFunction={requestApproval}
          valueCopy="approve"
        />
        <RadioButton
          checkedButton={approved ? "approve" : "unapprove"}
          labelCopy={t`Unapproved`}
          name="status-approval"
          onChangeFunction={requestApproval}
          valueCopy="unapprove"
        />
      </div>
    </div>
  );
};

Approval.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      activeCdnTracers: PropTypes.number,
      approved: PropTypes.bool,
      availability: PropTypes.string,
    })
  }).isRequired,
  setAsset: PropTypes.func.isRequired,
  updateFetchControllers: PropTypes.func.isRequired,
};

export default Approval;
