import { OnlyForScreenReader } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, ReactElement } from 'react';

import { useMatchMedia } from '@components/common/custom_hooks/useMatchMedia';
import { TertiaryButton } from '@components/library/button';
import { FontIcon, FontIcons } from '@components/library/icon';
import { StandardTooltip } from '@components/library/tooltip';

import './styles/export.scss';

interface DownloadButtonProps {
  setShowExport: SetStateDispatch<boolean>;
}

export const DownloadButton: FunctionComponent<DownloadButtonProps> = ({ setShowExport }) => {
  const isSmallDesktop = useMatchMedia('(max-width: 1280px)');
  const label = t`Download`;

  const renderLabel = (): ReactElement => {
    const buttonLabel = <span className="project__button-label">{label}</span>;
    if (isSmallDesktop) {
      return (
        <OnlyForScreenReader>
          {buttonLabel}
        </OnlyForScreenReader>
      );
    }
    return buttonLabel;
  };

  const renderButton = (): ReactElement => {
    const button = (
      <TertiaryButton
        className="project__button"
        id="project-export-button"
        onClick={(): void => setShowExport(true)}
      >
        <FontIcon
          aria-hidden
          icon={FontIcons.Download}
        />
        {renderLabel()}
      </TertiaryButton>
    );

    if (isSmallDesktop) {
      return (
        <span className="project__button-wrapper">
          <StandardTooltip id="project-export-button-tooltip" tooltip={label}>
            {button}
          </StandardTooltip>
        </span>
      );
    }
    return <span className="project__button-wrapper">{button}</span>;
  };

  // IMPORTANT: this component needs to return this function in order for
  // the useMatchMedia magic to happen for hiding the label on re-render
  return renderButton();
};
