import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { BulkCustomFieldsList } from '@components/show_page/bulk_actions/sub_components/BulkCustomFieldsList';
import TagsModalContent from '@components/show_page/bulk_actions/sub_components/TagsModalContent';
import { unsavedChangesOptions } from '@helpers/sweet_alert_options';

import renderModal from '../modals/renderModal';

const BulkTagWrapper = (props) => {
  const [showCustomFields, toggleCustomFields] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => (
    () => { props.closeModal(); }
  ), []);

  const toggleTab = (displayCustomFields = false) => {
    if (hasUnsavedChanges) {
      window.swal(unsavedChangesOptions({
        onLeaveCopy: t`Discard Changes`,
        onStayCopy: showCustomFields ? t`Continue Editing` : t`Continue Tagging`,
      }), (confirm) => {
        if (confirm) {
          toggleCustomFields(displayCustomFields);
          setHasUnsavedChanges(false);
        }
      });
    } else {
      toggleCustomFields(displayCustomFields);
      setHasUnsavedChanges(false);
    }
  };

  const hasCustomFields = BFG.context.hasFeature('custom_fields');

  return (
    <div className="bulk-tag-wrapper">
      <div className="modal-content-wrapper__header wrapper-headers">
        <div
          className={`header ${!showCustomFields ? "active" : ''} ${!hasCustomFields ? 'one-tab' : ''}`}
          onClick={() => toggleTab()}
        >
          <span aria-hidden="true" className="bff-tag icon" />
          <h3 className="modal-title"><Trans>Bulk Tag</Trans></h3>
        </div>
        {hasCustomFields && (
          <div
            className={`header ${showCustomFields && "active"}`}
            onClick={() => toggleTab(true)}
          >
            <span aria-hidden="true" className="bff-custom-fields icon" />
            <h3 className="modal-title"><Trans>Bulk Custom Fields</Trans></h3>
          </div>
        )}
      </div>
      {showCustomFields ? (
        <BulkCustomFieldsList
          {...props}
          hasUnsavedChanges={hasUnsavedChanges}
          setHasUnsavedChanges={setHasUnsavedChanges}
        />
      ) : (
        <TagsModalContent
          {...props}
          hasUnsavedChanges={hasUnsavedChanges}
          setHasUnsavedChanges={setHasUnsavedChanges}
        />
      )}
    </div>
  );
};

BulkTagWrapper.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

const ModalComponent = renderModal(BulkTagWrapper, 'BulkTagWrapper');
export default ModalComponent;
