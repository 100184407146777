import {
  Breakpoints,
  MoreInfoTooltip,
  StandardTable,
  StandardTableColumn,
  StandardTableRow,
} from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useFetch } from '@api/ApiHelper';
import { BFLoader } from '@components/common/loader/main';
import { Widths } from '@components/library/utils';
import { LimitsTableRowInput } from '@components/limits/LimitsTableRowInput';
import { moreInfoLabel } from '@components/standard-messages';

import { isGettyClient } from '@helpers/getty-strings';

import { AllocationAlert } from './AllocationAlert';
import {
  CreateLimitData,
  FlattenedApiData,
  FolderLimitsTableProps,
  LimitsApiDataResponse,
  PlanLimitsApiDataResponse,
  UpdateLimitData,
} from './customLimitsTypes';
import { assignLimitsUnits } from './helpers/assignLimitsUnits';
import {
  flattenLimitsData,
  organizeLimitsFolderData,
  organizeNoLimitsFolderData,
} from './helpers/flattenLimitsData';
import { postLimits } from './helpers/postLimits';
import { ResourceLabel } from './ResourceLabel';
import './styles/folder-limits-table.scss';
import { SubmitLimitsButton } from './SubmitLimitsButton';

interface DisplayDetails {
  caption: string;
  limitTypePlanLimit: string;
  url: string;
  tooltip?: string;
}

const limitTypeDisplayDetails = (): { [key: string]: DisplayDetails } => {
  return {
    admin: {
      caption: isGettyClient()
        ? t`List of how admins are allocated per Library`
        : t`List of how admins are allocated per Brandfolder`,
      limitTypePlanLimit: 'admins',
      url: 'Admin',
    },
    collaborator: {
      caption: isGettyClient()
        ? t`List of how many collaborators are allocated per Library`
        : t`List of how many collaborators are allocated per Brandfolder`,
      limitTypePlanLimit: 'collaborators',
      url: 'Collaborator',
    },
    storage: {
      caption: isGettyClient()
        ? t`List of storage usage allocated per Library`
        : t`List of storage usage allocated per Brandfolder`,
      limitTypePlanLimit: 'storage_gb',
      url: 'storage',
      tooltip: isGettyClient()
        /* eslint-disable max-len */
        ? t`Brandfolder usage will not be able to exceed the limit. All Brandfolder limits combined equals the organization limit.`
        : t`Library usage will not be able to exceed the limit. All Library limits combined equals the organization limit.`
      /* eslint-disable max-len */

    },
  };
};

export const FolderLimitsTable: FunctionComponent<FolderLimitsTableProps> = ({
  limitType = 'admin',
  orgKey,
  setOpen,
}) => {
  const displayDetails = limitTypeDisplayDetails();
  const planLimit = displayDetails[limitType].limitTypePlanLimit;

  const foldersWithLimitsPayload = [];
  const foldersWithoutLimitsPayload = [];

  const brandfoldersGetUrl = `/api/v4/organizations/${orgKey}/brandfolders?fields=current_usage,storage`;
  const brandguidesGetUrl = `/api/v4/organizations/${orgKey}/brandguides?fields=current_usage`;
  const organizationGetUrl = `/api/v4/organizations/${orgKey}?fields=current_usage`;
  const portalsGetUrl = `/api/v4/private/organizations/${orgKey}/portals?fields=current_usage`;
  const bfLimitsGetUrl = `/api/v4/organizations/${orgKey}/limits/brandfolder/${limitType}?include=resource`;
  const brandguideLimitsUrl = `/api/v4/organizations/${orgKey}/limits/brandguide/${limitType}?include=resource`;
  const orgLimitsGetUrl = `/api/v4/organizations/${orgKey}/limits/organization/${limitType}?include=resource`;
  const planLimitGetUrl = `/api/v4/organizations/${orgKey}/plan`;
  const portalsLimitsUrl = `/api/v4/organizations/${orgKey}/limits/portal/${limitType}?include=resource`;

  // resource type doesn't matter for PATCH requests
  const limitsPatchUrl = `api/v4/organizations/${orgKey}/limits/organization/${limitType}`;

  const brandfoldersFetch = useFetch<LimitsApiDataResponse>({
    url: brandfoldersGetUrl,
    fetchOnMount: true,
  });

  const brandguidesFetch = useFetch<LimitsApiDataResponse>({
    url: brandguidesGetUrl,
    fetchOnMount: true,
  });

  const organizationFetch = useFetch<LimitsApiDataResponse>({
    url: organizationGetUrl,
    fetchOnMount: true,
  });

  const portalsFetch = useFetch<LimitsApiDataResponse>({
    url: portalsGetUrl,
    fetchOnMount: true,
  });

  const bfLimitsFetch = useFetch<LimitsApiDataResponse>({
    url: bfLimitsGetUrl,
    fetchOnMount: true,
  });

  const brandguideLimitsFetch = useFetch<LimitsApiDataResponse>({
    url: brandguideLimitsUrl,
    fetchOnMount: false
  });

  const orgLimitsFetch = useFetch<LimitsApiDataResponse>({
    url: orgLimitsGetUrl,
    fetchOnMount: true,
  });

  const planLimitFetch = useFetch<PlanLimitsApiDataResponse>({
    url: planLimitGetUrl,
    fetchOnMount: true,
  });

  const portalLimitsFetch = useFetch<LimitsApiDataResponse>({
    url: portalsLimitsUrl,
    fetchOnMount: false
  });

  const limitsPatchFetch = useFetch<UpdateLimitData>({
    url: limitsPatchUrl,
    method: 'PATCH',
    fetchOnMount: false,
    body: {
      data: foldersWithLimitsPayload,
    },
  });
  const handleSuccess = useCallback((): void => {
    Notify.create({
      title: t`Successfully updated your limits`,
      type: 'success',
    });
    setOpen(false);
  }, [setOpen]);

  const handleError = (): void => {
    Notify.create({
      type: 'error',
      title: t`Error updating limits`,
    });
  };
  const [limitsSuccess, setLimitsSuccess] = useState<boolean>();
  const [folders, setFolders] = useState<FlattenedApiData[]>([]);
  const [submitLimitsDisabled, setSubmitLimitsDisabled] = useState(true);
  const sumFoldersLimit = folders.reduce(
    (sum, { currentLimit }) => sum + currentLimit || 0,
    0
  );

  const sumFoldersUsage =
    folders.reduce((sum, { currentUsage }) => sum + currentUsage, 0) || 0;

  useEffect(() => {
    if (limitType === 'storage') {
      if (bfLimitsFetch?.response && brandfoldersFetch?.response) {
        const storageFlatLimitsData = flattenLimitsData(
          bfLimitsFetch.response,
          brandfoldersFetch.response,
          limitType
        );
        setFolders(storageFlatLimitsData);
      }
    }
  }, [limitType, bfLimitsFetch.response, brandfoldersFetch.response]);

  useEffect(() => {
    if (limitType !== 'storage') {
      portalLimitsFetch.fetch();
      brandguideLimitsFetch.fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitType]);

  useEffect(() => {
    if (limitType !== 'storage') {
      if (
        brandfoldersFetch?.response &&
        brandguidesFetch?.response &&
        organizationFetch?.response &&
        portalsFetch?.response &&
        bfLimitsFetch?.response &&
        brandguideLimitsFetch?.response &&
        orgLimitsFetch?.response &&
        portalLimitsFetch?.response
      ) {
        const flatLimitsData =
          flattenLimitsData(
            organizeLimitsFolderData(
              orgLimitsFetch.response,
              bfLimitsFetch.response,
              portalLimitsFetch.response,
              brandguideLimitsFetch.response
            ),
            organizeNoLimitsFolderData(
              !orgLimitsFetch.response?.data?.length,
              brandfoldersFetch.response,
              organizationFetch.response,
              portalsFetch.response,
              brandguidesFetch.response
            ),
            limitType
          );
        setFolders(flatLimitsData);
      }
    }
  }, [
    brandfoldersFetch.response,
    brandguidesFetch.response,
    organizationFetch.response,
    portalsFetch.response,
    bfLimitsFetch.response,
    brandguideLimitsFetch.response,
    orgLimitsFetch.response,
    portalLimitsFetch.response,
    limitType,
  ]);

  useEffect(() => {
    if (limitsPatchFetch.error) {
      setLimitsSuccess(false);
    }
  }, [limitsPatchFetch.error]);

  useEffect(() => {
    if (limitsPatchFetch.response) {
      setLimitsSuccess(true);
    }
  }, [limitsPatchFetch.response, handleSuccess]);

  useEffect(() => {
    if (limitsSuccess) {
      handleSuccess();
    } else if (limitsSuccess === false) handleError();
  }, [limitsSuccess, handleSuccess]);

  const maxOrgLimit =
    planLimitFetch.response?.data?.attributes.limits[planLimit] || 0;

  const maxPlanLimit: number | string =
    0 <= maxOrgLimit ? maxOrgLimit : t`Unlimited`;

  const updateFolderCurrentLimit = (
    value: number,
    resourceId: string
  ): void => {
    setFolders((prev) => {
      return prev.map((folder) => {
        if (folder.resourceId === resourceId) {
          return { ...folder, currentLimit: value };
        }
        return folder;
      });
    });
    setSubmitLimitsDisabled(false);
  };

  const submitLimitsData = (): void => {
    const foldersWithLimits: FlattenedApiData[] = [];
    const foldersWithoutLimits: FlattenedApiData[] = [];

    // Separate folders that have limits from those without limits
    folders.map((folder) => {
      if (folder.hasLimits && folder.currentLimit !== folder.startingLimit) {
        foldersWithLimits.push(folder);
      }
      if (!folder.hasLimits && folder.currentLimit !== folder.startingLimit) {
        foldersWithoutLimits.push(folder);
      }
    });

    // Prepare and send PATCH request for folders with existing limits
    if (foldersWithLimits.length) {
      foldersWithLimits.map((folder) => {
        const hasLimitObj: UpdateLimitData = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          limit_id: folder.limitId,
          value: folder.currentLimit,
        };
        foldersWithLimitsPayload.push(hasLimitObj);
      });
      limitsPatchFetch.fetch();
    }

    // Prepare and send POST request for folders without existing limits
    if (foldersWithoutLimits.length) {
      foldersWithoutLimits.map((folder) => {
        const hasNoLimitObj: CreateLimitData = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          resource_id: folder.resourceId,
          value: folder.currentLimit,
          resourceType: folder.resourceType,
        };
        foldersWithoutLimitsPayload.push(hasNoLimitObj);
      });

      // fetch individually to ensure correct resource type is passed
      foldersWithoutLimitsPayload.forEach((folder) => {
        postLimits(folder, limitType, orgKey, setLimitsSuccess);
      });
    }
  };

  const rows: StandardTableRow[] = folders.map((folder) => ({
    brandName: (
      <span className="limits-standard-table__folder-name">
        {folder.resourceName}
      </span>
    ),
    resourceType: limitType !== 'storage' && (
      <ResourceLabel resourceType={folder.resourceType} />
    ),
    currentUsage: assignLimitsUnits(limitType, folder.currentUsage || 0),
    typeLimit: (
      <LimitsTableRowInput
        currentLimit={folder.currentLimit}
        currentUsage={folder.currentUsage}
        handleChange={updateFolderCurrentLimit}
        limitType={limitType}
        resourceId={folder.resourceId}
      />
    ),
  }));

  const columns: StandardTableColumn[] = [
    {
      children: <Trans>Resource</Trans>,
      rowKey: 'brandName',
      width: '50%',
    },
    {
      children: <Trans>Type</Trans>,
      rowKey: 'resourceType',
    },
    {
      children: <Trans>Usage</Trans>,
      rowKey: 'currentUsage',
      width: '25%',
    },
    {
      children: (
        <>
          <Trans>Limit</Trans>
          {limitType === 'storage' && (
            <MoreInfoTooltip
              iconLabel={moreInfoLabel()}
              iconSize={13}
              id="storage-limit-tooltip"
              tooltip={displayDetails.storage.tooltip}
              triggerOffset={12}
              width={Widths.Small}
            />
          )}
        </>
      ),
      rowKey: 'typeLimit',
      tdClassName: `limits-table-column__limit ${limitType}`,
    },
  ];

  const removeTypeColumn = columns.filter(({ rowKey }) => rowKey !== 'resourceType');

  const loading =
    brandfoldersFetch.loading ||
    brandguidesFetch.loading ||
    organizationFetch.loading ||
    portalsFetch.loading ||
    bfLimitsFetch.loading ||
    brandguideLimitsFetch.loading ||
    orgLimitsFetch.loading ||
    planLimitFetch.loading ||
    portalLimitsFetch.loading;

  if (loading) {
    return (
      <div
        className="limits-modal-loading-container"
        data-testid="limits-modal-loading-container"
      >
        <BFLoader />
      </div>
    );
  }

  const currentUsageFooterCell = (
    <div className="current-usage-total">
      <span>
        {assignLimitsUnits(limitType, sumFoldersUsage)}
      </span>
    </div>
  );

  const limitsTotalFooterCell = (
    <div className='limits-total-container' data-testid="limits-total">
      <div className="limits-current-limit-count">
        <span data-testid="current-limit-total" id="current-limit-total">
          {sumFoldersLimit.toLocaleString('en-US')}
        </span>
        <span data-testid="max-plan-limit" id='current-limit-available'>
          {assignLimitsUnits(limitType, maxPlanLimit).toLocaleString('en-US')}
        </span>
      </div>
      <AllocationAlert
        limit={sumFoldersLimit}
        limitType={limitType}
        location="footer"
        maxPlanLimit={maxPlanLimit}
      />
    </div>
  );

  const footerTableRow = {
    brandName: '',
    resourceType: '',
    currentUsage: currentUsageFooterCell,
    typeLimit: limitsTotalFooterCell,
  };

  return (
    <>
      {!!folders?.length && (
        <AllocationAlert
          limit={sumFoldersLimit}
          limitType={limitType}
          location="header"
          maxPlanLimit={maxPlanLimit}
        />
      )}
      <div
        className="limits-standard-table"
        data-testid="limits-standard-table"
      >
        <StandardTable
          breakpoints={[Breakpoints.Sm]}
          caption={displayDetails[limitType].caption}
          columns={
            limitType === 'storage'
              ? removeTypeColumn
              : columns
          }
          empty={!rows.length}
          emptyContent={t`No results`}
          errorContent={t`An error occurred`}
          footer={false}
          hover
          id="bf-limits-standard-table"
          rows={[...rows, footerTableRow]}
          scrollX
        />
        <SubmitLimitsButton
          setOpen={setOpen}
          submitDisabled={submitLimitsDisabled}
          submitUpdatedLimits={submitLimitsData}
        />
      </div>
    </>
  );
};
