import { Tag } from '@api/v4/assets/assetTypes';
import {
  ReducerState,
} from '@components/asset/modal/tabs/edit/EditTabTypes';

export interface SubmitTagsData {
  initialTags: Tag[];
  editedTags: Tag[];
}

const tagComparison = (tagA: Tag, tagB: Tag): boolean => (
  tagA.name === tagB.name
);

const tagsAreDirty = (tagListA: Tag[], tagListB: Tag[], initialValue: boolean): boolean => (
  tagListA.reduce((prev, currTag) => prev || !tagListB.find((tag) => tagComparison(tag, currTag)), initialValue)
);

const getTagChanges = (state: ReducerState): SubmitTagsData | false => {
  const initialTags = state.initialData.tags;
  const editedTags = state.editState.tags as Tag[];
  let tagsDirty = false;
  tagsDirty = tagsAreDirty(initialTags, editedTags, tagsDirty);
  if (!tagsDirty) {
    tagsDirty = tagsAreDirty(editedTags, initialTags, tagsDirty);
  }
  return tagsDirty && { initialTags, editedTags };
};

export default getTagChanges;
