import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { AssetData, AssetTypes } from '@api/v4/assets/assetTypes';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { PrimaryButton } from '@components/library/button/index';

import ColorDetails from './colorDetails';
import AssetLinks from './links/asset_links';
import ShareAsset from './shareAsset';

interface SidebarAttachmentlessAssetsProps {
  assetData: AssetData;
  assetKey: string;
  assetType: AssetTypes;
  bestLinkFor: string;
  isDownloadable: boolean;
  libraryName: string;
  published: boolean;
  assetName?: string; // needed only for font asset type
}

export const SidebarAttachmentlessAssets: FunctionComponent<SidebarAttachmentlessAssetsProps> = ({
  assetData,
  assetKey,
  assetType,
  bestLinkFor,
  isDownloadable,
  libraryName,
  published,
  assetName,
}) => {
  const renderAssetlinks = (): JSX.Element => (
    <AssetLinks
      assetData={assetData}
      assetName={assetName}
      assetType={assetType}
    />
  );

  return (
    <I18nProviderWrapper>
      <ul className="s-download-attachments-container s-attachment-conversion s-color-org-container clickable-attachments sidebar-attachmentless-assets">
        {assetType === AssetTypes.Color && <ColorDetails assetData={assetData} />}
        {BFG.resource?.type === 'organization' && renderAssetlinks()}
      </ul>
      <ul className="s-download-attachments-container actions-container">
        {isDownloadable && published && (
          <li className="s-asset-action-item t-show-modal-share-action">
            <ShareAsset assetKey={assetKey} />
          </li>
        )}
        {BFG.resource?.type === 'organization' ? (
          <li className="s-asset-action-item s-download-menu-spacing t-show-modal-download-action">
            <PrimaryButton
              onClick={(): void => { window.open(bestLinkFor, '_blank'); }}
              size="large"
            >
              <Trans>View in {libraryName}</Trans>
            </PrimaryButton>
          </li>
        ) : (
          renderAssetlinks()
        )}
      </ul>
    </I18nProviderWrapper>
  );
};
