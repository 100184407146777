import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import { FormikErrors } from 'formik';
import React, { FunctionComponent } from 'react';

import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { FilestackUploader } from '@components/common/filestack_uploader/FilestackUploader';
import { TextWarningButton } from '@components/library/button';
import { CreateProjectValues } from '@components/show_page/sections/asset/custom-templates';
import { ValidationAlert } from '@components/show_page/sections/asset/custom-templates/ValidationAlert';

import './styles/upload-an-image.scss';

interface UploadAnImageProps {
  backgroundImageUrl: string;
  errors: FormikErrors<CreateProjectValues>;
  filename: string;
  hasSubmitted: boolean;
  initialValues: CreateProjectValues;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  validateField: (field: string) => void;
}

export const UploadAnImage: FunctionComponent<UploadAnImageProps> = (props) => {
  const {
    backgroundImageUrl,
    errors,
    filename,
    hasSubmitted,
    initialValues,
    setFieldValue,
    setFieldTouched,
    validateField
  } = props;

  const validate = (): void => {
    setTimeout(() => {
      validateField('backgroundImageUrl');
    }, 50);
  };

  return (
    <div
      className={classnames(
        'upload-an-image__upload',
        backgroundImageUrl && 'upload-an-image__upload--pending'
      )}
    >
      {!backgroundImageUrl
        ? (
          <>
            {hasSubmitted && errors.backgroundImageUrl && (
              <ValidationAlert>
                {errors.backgroundImageUrl}
              </ValidationAlert>
            )}
            <FilestackUploader
              onUploadDone={(files): void => {
                const filestackFile = files.filesUploaded[0];
                setFieldValue('backgroundImageUrl', filestackFile.url);
                setFieldTouched('backgroundImageUrl', true);
                setFieldValue('filename', filestackFile.filename);
                validate();
              }}
              pickerOptions={{
                accept: 'image/*',
                maxFiles: 1
              }}
            />
          </>
        )
        : (
          <figure className="upload-an-image__file">
            <FilestackPreviewImage
              alt={filename || ''}
              className="upload-an-image__file-image"
              src={backgroundImageUrl}
            />
            <div className="upload-an-image__file-details">
              {filename && (
                <figcaption className="upload-an-image__file-name">
                  {filename}
                </figcaption>
              )}
              <TextWarningButton
                id="upload-an-image-delete-image"
                onClick={(): void => {
                  setFieldValue('backgroundImageUrl', initialValues.backgroundImageUrl);
                  setFieldTouched('backgroundImageUrl', undefined);
                  setFieldValue('filename', initialValues.filename);
                  validate();
                }}
              >
                <Trans>Delete</Trans>
              </TextWarningButton>
            </div>
          </figure>
        )}
    </div>
  );
};
