import { t, Trans, Plural } from '@lingui/macro';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ListDropdown } from '@components/library/dropdown';
import { isGettyClient } from '@helpers/getty-strings';

import Checkbox from '../common/checkbox/main';

export default class InviteUsersStage2 extends Component {
  state = {
    permissionLevels: [t`Owner`, t`Admin`, t`Collaborator`, t`Guest`],
    searchInput: '',
  }

  createOptionsForSelect(options) {
    return options.map((option) => ({ value: option, label: option }));
  }

  sortResources(resourcesObject) {
    const resourceKeys = Object.keys(resourcesObject);
    const resourceArraySorted = resourceKeys.map((key) => [key, resourcesObject[key].name]);

    resourceArraySorted.sort((a, b) => this.sortHelper(a, b));

    return resourceArraySorted;
  }

  sortHelper(a, b) {
    if (a[1] < b[1]) {
      return -1;
    } if (a[1] > b[1]) {
      return 1;
    }
    return 0;
  }

  brandfolderNoAccessClass(brandfolderKey) {
    return this.props.resources[brandfolderKey].permitted ? '' : 'no-access';
  }

  carrotHiddenClass(collections) {
    return collections ? '' : 'hide-carrot';
  }

  expandedClass(brandfolderKey) {
    return this.props.expandedBrandfolders[brandfolderKey] ? 'expanded' : '';
  }

  searchInputClass() {
    return this.state.searchInput === '' ? 'search-input-empty' : '';
  }

  renderCollections(collections, parentKey) {
    const { selectedResources, toggleAllCollections, toggleResource } = this.props;
    const { searchInput } = this.state;
    const collectionData = [];
    const collectionArraySorted = this.sortResources(collections);

    collectionArraySorted.forEach((collectionKeyNameArray) => {
      const collectionKey = collectionKeyNameArray[0];
      const collection = collections[collectionKey];

      // Apply search criteria
      if (collection.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1) {
        collectionData.push(
          <div key={collectionKey} className="collection-select-row select-row">
            <Checkbox
              checked={!!selectedResources[collectionKey]}
              datakey={collectionKey}
              labelHtml={<h4>{collection.name}</h4>}
              size="md"
              toggle={(e) => toggleResource(e.target.getAttribute('data-key'))}
            />
          </div>
        );
      }
    });

    if (collectionData.length) {
      const titleContainer = (
        <div key="0" className="resource-title-container collection-title-container">
          <h6 className="resource-title"><Trans>COLLECTIONS</Trans></h6>
          <h6 className="select-all-collections" onClick={() => toggleAllCollections(collections, parentKey, "selectAll")}><Trans>Select All</Trans></h6>
          <h6 className="title-seperator">|</h6>
          <h6 className="deselect-all-collections" onClick={() => toggleAllCollections(collections, parentKey, "deselectAll")}><Trans>Deselect All</Trans></h6>
        </div>
      );

      collectionData.splice(0, 0, titleContainer);
    }

    return collectionData;
  }

  renderDataTree() {
    const { expandedBrandfolders, resources, selectedResources, toggleResource, toggleTreeExpand, libraryName } = this.props;
    const { searchInput } = this.state;
    const searchCopy = searchInput.toLowerCase();
    const treeData = [];
    const resourceArraySorted = this.sortResources(resources);

    if (!resourceArraySorted.length) {
      return isGettyClient()
        ? <h4><Trans>No Libraries or Collections found for you to manage</Trans></h4>
        : <h4><Trans>No Brandfolders or Collections found for you to manage</Trans></h4>;
    }

    resourceArraySorted.forEach((resourceKeyNameArray) => {
      const brandfolderKey = resourceKeyNameArray[0];
      const brandfolder = resources[brandfolderKey];
      const brandfolderName = brandfolder.name.toLowerCase();
      const collections = brandfolder.collections;
      const collectionsDisabled = selectedResources[brandfolderKey] ? 'collections-disabled' : '';
      let renderBrandfolder = true;
      let renderCollection = false;
      let brandfolderChildSelected = '';

      // apply brandfolder view criteria
      if (brandfolderName.indexOf(searchCopy) === -1) {
        renderBrandfolder = false;
      }

      if (collections) {
        if (searchCopy) {
          delete expandedBrandfolders[brandfolderKey]; // collapse brandfolder if collection is not in search
        }

        Object.keys(collections).forEach((collectionKey) => {
          const collectionName = collections[collectionKey].name.toLowerCase();

          if (searchCopy && collectionName.indexOf(searchCopy) > -1) {
            renderCollection = true; // render brandfolder if at least one collection matches search value
            expandedBrandfolders[brandfolderKey] = true;
          }

          // brandfolder is not selected AND at least one child is selected
          if (!selectedResources[brandfolderKey] && selectedResources[collectionKey] && selectedResources[collectionKey].parent_key === brandfolderKey) {
            brandfolderChildSelected = 'brandfolder-child-selected';
          }
        });
      }

      if (renderBrandfolder || renderCollection) {
        treeData.push(
          <div
            key={brandfolderKey}
            className={`brandfolder-select-container select-container ${this.expandedClass(brandfolderKey)} ${brandfolderChildSelected}`}
          >
            <div className={`brandfolder-select-row select-row ${this.brandfolderNoAccessClass(brandfolderKey)}`}>
              <span
                className={`bff-caret-down icon ${this.carrotHiddenClass(collections)}`}
                data-key={brandfolderKey}
                onClick={(e) => {
                  this.setState({ searchInput: '' });
                  toggleTreeExpand(e.target.getAttribute('data-key'));
                }}
              />
              <Checkbox
                checked={!!selectedResources[brandfolderKey]}
                datakey={brandfolderKey}
                labelHtml={<h4>{brandfolder.name}</h4>}
                size="md"
                toggle={(e) => toggleResource(e.target.getAttribute('data-key'))}
              />
            </div>
            {collections
              ? (
                <div className={`collection-select-container select-container ${collectionsDisabled}`}>
                  {this.renderCollections(brandfolder.collections, brandfolderKey)}
                </div>
              )
              : null
            }
          </div>
        );
      }
    });

    if (treeData.length) {
      const titleContainer = (
        <div key="0" className="resource-title-container brandfolder-title-container">
          <h6 className="resource-title">{pluralize(libraryName).toUpperCase()}</h6>
        </div>
      );

      treeData.splice(0, 0, titleContainer);
    } else {
      treeData.push(
        <h4 key="0" className="search-results-empty active"><Trans>No results found for "{searchInput}"</Trans></h4>
      );
    }
    return treeData;
  }

  render() {
    if (this.props.stage !== 2) { return null; }

    const {
      updateInviteState,
      emailsArray,
      selectedPermissionLevel,
      submitted,
      libraryName
    } = this.props;
    const { permissionLevels, searchInput } = this.state;
    const userQty = emailsArray.length;

    return (
      <React.Fragment>
        <div className="section-title-container user-permission-level">
          <h3 className="section-title">
            <Plural
              one="User permission level for # user:"
              other="User permission level for # users:"
              value={userQty}
            />
          </h3>
          <ListDropdown
            onChange={(option) => updateInviteState({ selectedPermissionLevel: option.value })}
            options={this.createOptionsForSelect(permissionLevels)}
            value={selectedPermissionLevel}
            virtualizeOptions={false}
          />
        </div>
        <div className={`section-body body-select-resources ${this.searchInputClass()}`}>
          <div className="resource-search-container">
            <h3 className="section-title">
              {isGettyClient()
                ? <Trans>Select Libraries or Collections</Trans>
                : <Trans>Select Brandfolders or Collections</Trans>}
            </h3>
            <div className="resource-search">
              <input
                className="inputs"
                onChange={(e) => this.setState({ searchInput: e.target.value })}
                placeholder={t`Search`}
                type="text"
                value={searchInput}
              />
              <span className="bff-search icon" />
              <span
                className="bff-close icon"
                onClick={() => this.setState({ searchInput: '' })}
              />
            </div>
          </div>
          <div className="resource-select-container">
            <h4 className="search-input-feedback">
              <Trans>Showing results for "{searchInput}"...</Trans>
            </h4>
            { this.renderDataTree() }
          </div>
        </div>
        <div className="button-container">
          <button
            className="button tertiary sm"
            onClick={() => updateInviteState({ stage: 1 })}
            type="button"
          >
            <Trans>Back</Trans>
          </button>
          <button
            className="button primary lg"
            disabled={submitted}
            onClick={() => updateInviteState({ stage: 3 })}
            type="button"
          >
            <Trans>Review Selections</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

InviteUsersStage2.defaultProps = {
  libraryName: "Brandfolder"
};

InviteUsersStage2.propTypes = {
  emailsArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  expandedBrandfolders: PropTypes.objectOf(PropTypes.object).isRequired,
  libraryName: PropTypes.string,
  resources: PropTypes.objectOf(PropTypes.object).isRequired,
  selectedPermissionLevel: PropTypes.string.isRequired,
  selectedResources: PropTypes.objectOf(PropTypes.object).isRequired,
  stage: PropTypes.number.isRequired,
  submitted: PropTypes.bool.isRequired,
  toggleAllCollections: PropTypes.func.isRequired,
  toggleResource: PropTypes.func.isRequired,
  toggleTreeExpand: PropTypes.func.isRequired,
  updateInviteState: PropTypes.func.isRequired
};
