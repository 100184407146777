import { StandardDatePicker } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { CollectionPutResponse } from '@api/v4/collections';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { WorkspaceEvents } from '@components/workspace/WorkspaceEnums';
import { WorkspaceUpdated } from '@components/workspace/WorkspaceTypes';
import { getStandardDatePickerLabels } from '@translations';

import classes from './styles/workspace-due-date.module.scss';

interface WorkspaceDueDateProps {
  defaultDueDate: string; // iso string
}

export const WorkspaceDueDate: FunctionComponent<WorkspaceDueDateProps> = (props) => {
  const { defaultDueDate } = props;

  const { key } = BFG.resource;

  const [dueDate, setDueDate] = useState(defaultDueDate || '');
  const [initialDueDate, setInitialDueDate] = useState(defaultDueDate || '');
  const [mounted, setMounted] = useState(false);

  const updateWorkspace = useFetch<CollectionPutResponse>({
    body: {
      data: {
        attributes: {
          due_date: dueDate // eslint-disable-line @typescript-eslint/naming-convention
        }
      }
    },
    fetchOnMount: false,
    method: 'PUT',
    url: `/api/v4/collections/${key}`
  });

  useEffect(() => {
    setMounted(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mounted) {
      updateWorkspace.fetch();
    }
  }, [dueDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updateWorkspace.response) {
      const { due_date: updatedDueDate } = updateWorkspace.response.data.attributes;

      setInitialDueDate(updatedDueDate);

      // update the due date within WorkspaceProgressStatus.tsx
      const workspaceUpdatedEvent = new CustomEvent<WorkspaceUpdated>(WorkspaceEvents.WorkspaceUpdated, {
        detail: {
          dueDate: updatedDueDate
        }
      });

      window.dispatchEvent(workspaceUpdatedEvent);

      Notify.create({
        title: t`Due date updated!`,
        type: 'success'
      });
    }
  }, [updateWorkspace.response]);

  useEffect(() => {
    if (updateWorkspace.error) {
      Notify.create({
        title: t`There was an error updating the due date. Please try again.`,
        type: 'error'
      });
    }
  }, [updateWorkspace.error]);

  if (!mounted) return null;

  return (
    <I18nProviderWrapper>
      <StandardDatePicker
        allowPastDates={false}
        className={classes.workspaceDueDate}
        defaultSelectedDate={initialDueDate}
        id="workspace-due-date-setting"
        labels={getStandardDatePickerLabels()}
        locale={determineUGTLocale()}
        onSelection={(date): void => setDueDate(date.toISOString())}
        showLabel
      />
    </I18nProviderWrapper>
  );
};
