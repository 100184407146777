import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const SectionShroud = ({
  assetCount,
  assetWidth,
  assetHeight,
  gridGap,
  onShown,
}) => {
  const [height, setHeight] = useState(0);
  const container = useRef(null);

  useEffect(() => {
    const width = container.current.clientWidth;
    if (width) {
      const rows = (assetCount * (assetWidth + gridGap)) / width;
      const approxHeight = rows * assetHeight;
      setHeight(approxHeight);
    } else {
      setHeight(0);
    }
  }, [container, assetCount, assetWidth, assetHeight, gridGap]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries.reduce((a, entry) => a || entry.isIntersecting, false);
      if (isIntersecting) {
        onShown();
      }
    });

    observer.observe(container.current);

    return () => {
      observer.disconnect();
    };
  }, [container]);

  return (
    <div
      style={{ height }}
      ref={container}
    />
  );
};

SectionShroud.propTypes = {
  assetCount: PropTypes.number.isRequired,
  assetHeight: PropTypes.number,
  assetWidth: PropTypes.number,
  gridGap: PropTypes.number,
  onShown: PropTypes.func,
};

SectionShroud.defaultProps = {
  assetHeight: 180,
  assetWidth: 290,
  gridGap: 30,
  onShown: () => {},
};

export default SectionShroud;
