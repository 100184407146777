import { Trans, Plural } from '@lingui/macro';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';

import { ValidationErrorsEnum, PortalCard, DisplayWidths } from '@api/v4/private/PortalCardTypes';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { FilestackUploader } from '@components/common/filestack_uploader/FilestackUploader';
import { PrimaryButton, TextWarningButton } from '@components/library/button/index';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';
import { TextSanitizationTooltipLabel } from '@components/shared/sanitization';

import { handleDeletion } from '../helpers/card_deletion_helper';
import { makeValidUrl } from '../helpers/make_valid_url';
import { validationSettings, nameError, linkError, descriptionError } from '../portal_cards/validations';

interface TileNavigationFormProps {
  card: PortalCard;
  closeModal: () => void;
  isValid: boolean;
  saving: boolean;
  setCard: (card: PortalCard) => void;
  setImageUrl: (imageUrl: string) => void;
  submitCreateCard: () => Promise<void>;
  submitUpdateCard: () => Promise<void>;
  triggerCardsRefresh: () => void;
  validationErrors: ValidationErrorsEnum[];
  portalCardId?: string;
}

export const TileNavigationForm: FunctionComponent<TileNavigationFormProps> = (props) => {
  const {
    card,
    closeModal,
    isValid,
    portalCardId,
    saving,
    setCard,
    setImageUrl,
    submitCreateCard,
    submitUpdateCard,
    triggerCardsRefresh,
    validationErrors
  } = props;

  const [showFilestackUploader, setShowFilestackUploader] = useState(false);

  const { description, displayWidth, imageUrl, linkUrl, name } = card;

  const nameLength = name.length;
  const descriptionLength = description ? description.length : 0;
  const descriptionRemainingCharacters = card?.displayWidth && validationSettings[card?.displayWidth]?.descriptionLength - descriptionLength;

  // this is a hack to fix FilestackUploader not applying a new aspect ratio when toggling between 1/3, 2/3, and full widths
  // we briefly hide it then show it again to re-initialze the component which will reset the aspect ratio
  useEffect(() => {
    // only run this hack if an image hasn't been uploaded
    if (!card.imageUrl) {
      setShowFilestackUploader(false);
      setTimeout(() => {
        setShowFilestackUploader(true);
      }, 10);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.displayWidth]);

  return (
    <>
      <div className="tile-form__name">
        <div className="portal-modal__character-limit">
          <InputContainer
            attributes={{
              className: `${card?.displayWidth && validationSettings[card?.displayWidth]?.nameLength - nameLength < 0 ? 'invalid' : ''}`,
              name: 'tile-name',
              onChange: (e: InputChangeEvent): void => setCard({ ...card, name: e.target.value }),
              type: InputType.Text,
            }}
            input={{ value: name, error: nameError(validationErrors) }}
            labelCopy={<><Trans>Name</Trans> *</>}
            submitAttempted={!isValid}
          />
          <span className="portal-modal__character-limit-count">
            <span className="countdown">
              {`${card?.displayWidth && validationSettings[card?.displayWidth]?.nameLength - nameLength}`}
            </span>
            <Trans>Characters</Trans>
          </span>
        </div>
      </div>
      <InputContainer
        attributes={{
          name: 'tile-url',
          onBlur: (): void => setCard({ ...card, linkUrl: makeValidUrl(linkUrl ? linkUrl.trim() : '') }),
          onChange: (e: InputChangeEvent): void => setCard({ ...card, linkUrl: e.target.value }),
          type: InputType.Text,
        }}
        input={{ value: linkUrl || '', error: linkError(validationErrors) }}
        labelCopy={<><Trans>Link URL</Trans> *</>}
        submitAttempted={!isValid}
      />
      <div className="tile-form__description">
        <div className="portal-modal__character-limit">
          <InputContainer
            attributes={{
              className: `${card?.displayWidth && validationSettings[card?.displayWidth]?.descriptionLength - descriptionLength < 0 ? 'invalid' : ''}`,
              name: 'tile-description',
              onChange: (e: ChangeEvent<HTMLTextAreaElement>): void => setCard({ ...card, description: e.target.value }),
              type: InputType.Textarea,
            }}
            input={{ value: description || '', error: descriptionError(validationErrors) }}
            labelCopy={
              <TextSanitizationTooltipLabel>
                <Trans>Description</Trans>
              </TextSanitizationTooltipLabel>
            }
            submitAttempted={!isValid}
          />
          <span className="portal-modal__character-limit-count">
            <Plural
              one={<Trans><span className="countdown">#</span>Character</Trans>}
              other={<Trans><span className="countdown">#</span>Characters</Trans>}
              value={descriptionRemainingCharacters}
            />
          </span>
        </div>
      </div>
      <div className="portal-modal__filestack-container">
        <div className="portal-modal__filestack-container--description">
          <h3><Trans>Tile image</Trans></h3>
          {displayWidth === DisplayWidths.OneThird && (
            <p><Trans>Recommended size: 300 x 220px</Trans></p>
          )}
          {displayWidth === DisplayWidths.TwoThirds && (
            <p><Trans>Recommended size: 630 x 220px</Trans></p>
          )}
          {displayWidth === DisplayWidths.Full && (
            <p><Trans>Recommended size: 960 x 220px</Trans></p>
          )}
        </div>
        {imageUrl && (
          <div className="portal-modal__filestack-container--thumbnail">
            <div className="portal-modal__filestack-container--thumbnail-container">
              <FilestackPreviewImage
                alt="Icon"
                className="portal-modal__filestack-container--thumbnail--image"
                data-private
                src={imageUrl}
              />
            </div>
            <TextWarningButton
              icon="bff-trash"
              onClick={(): void => setCard({ ...card, imageUrl: null })}
            />
          </div>
        )}
        {!imageUrl && showFilestackUploader && (
          <FilestackUploader
            onUploadDone={(files): void => setImageUrl(files.filesUploaded[0].url)}
            pickerOptions={{
              accept: 'image/*',
              maxFiles: 1,
              transformations: {
                crop: {
                  aspectRatio: card?.displayWidth ? validationSettings[card?.displayWidth]?.aspectRatio : 0,
                  force: true
                }
              }
            }}
          />
        )}
      </div>
      <div className="portal-modal__button">
        {portalCardId && (
          <div className="portal-modal__buttons portal-modal__buttons--two-buttons">
            <TextWarningButton
              disabled={saving}
              icon="bff-trash"
              onClick={(): void => handleDeletion(portalCardId, name, closeModal, triggerCardsRefresh)}
            >
              <Trans>Delete Tile</Trans>
            </TextWarningButton>
            <PrimaryButton
              disabled={saving}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={submitUpdateCard}
              size="medium"
            >
              <Trans>Update Tile</Trans>
            </PrimaryButton>
          </div>
        )}
        {!portalCardId && (
          <PrimaryButton
            disabled={saving}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={submitCreateCard}
            size="small"
          >
            <Trans>Create Tile</Trans>
          </PrimaryButton>
        )}
      </div>
    </>
  );
};
