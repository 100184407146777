import fetchJSON from '@api/api_helper';
import { Portal, PortalResponse } from '@api/v4/private/PortalTypes';

interface GetPortalOptions {
  params?: { [key: string]: string };
}

const createPortalUrl = (organizationId: string): string => `/api/v4/private/organizations/${organizationId}/portals`;
const portalUrl = (portalId: string): string => `/api/v4/private/portals/${portalId}`;

export const getPortal = (portalId: string, options: GetPortalOptions): Promise<PortalResponse> => (
  fetchJSON(portalUrl(portalId), options, (): void => { /* no op */ }) // NOTE: implement updateFetchControllers
);

export const createPortal = (
  organizationId: string,
  portal: Portal
): Promise<PortalResponse> => {
  const {
    name,
    slug,
    description,
    brandHexColor,
    headerUrl,
    headerHexColor,
    headerNameOverlay,
    privacy,
    searchUrl,
  } = portal;
  const appendedOptions = {
    body: {
      data: {
        attributes: {
          name: name.trim(),
          slug,
          description,
          brand_hex_color: brandHexColor?.replace("#", '').trim(),
          header_url: headerUrl,
          header_hex_color: headerHexColor?.replace("#", '').trim(),
          header_name_overlay: headerNameOverlay,
          privacy,
          search_url: searchUrl,
        }
      }
    },
    method: 'POST'
  };

  // NOTE: implement updateFetchControllers
  return fetchJSON(createPortalUrl(organizationId), appendedOptions, () => { /* no op */ })
    .then((response): PortalResponse => response);
};

export const updatePortal = (
  portalId: string,
  portal: Portal
): Promise<PortalResponse> => {
  const {
    name,
    slug,
    description,
    brandHexColor,
    headerUrl,
    headerHexColor,
    headerNameOverlay,
    privacy,
    searchUrl,
  } = portal;
  const appendedOptions = {
    body: {
      data: {
        attributes: {
          name: name.trim(),
          slug,
          description,
          brand_hex_color: brandHexColor?.replace("#", '').trim(),
          header_url: headerUrl,
          header_hex_color: headerHexColor?.replace("#", '').trim(),
          header_name_overlay: headerNameOverlay,
          privacy,
          search_url: searchUrl,
        }
      }
    },
    method: 'PUT'
  };

  // NOTE: implement updateFetchControllers
  return fetchJSON(portalUrl(portalId), appendedOptions, () => { /* no op */ })
    .then((response): PortalResponse => response);
};

export const deletePortal = (portalId: string): Promise<{}> => {
  const appendedOptions = {
    method: 'DELETE'
  };

  // NOTE: implement updateFetchControllers
  return fetchJSON(portalUrl(portalId), appendedOptions, () => { /* no op */ })
    .then((response) => response);
};
