import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { EditOrCreate, Views } from '@components/bulk_management/automation/AutomationEnums';
import { AutomationShowResponse } from '@components/bulk_management/automation/AutomationTypes';
import { AutomationForm } from '@components/bulk_management/automation/form/AutomationForm';
import { transformIncludedActions, transformTriggers } from '@components/bulk_management/automation/helpers';

import { BFLoader } from '@components/common/loader/main';

import { TextButton } from '@components/library/button';
import { FontIcons } from '@components/library/icon';

import { localizeDateTime } from '@helpers/localize';

interface DetailsViewProps {
  automationKey: string;
  isCopy: boolean;
  setView: SetStateDispatch<Views>;
}

export const DetailsView: FunctionComponent<DetailsViewProps> = ({
  automationKey,
  isCopy,
  setView
}) => {
  const getAutomationFetch = useFetch<AutomationShowResponse>({
    fetchOnMount: true,
    fields: ['creator', 'created_at', 'last_executed'],
    params: {
      include: 'actions',
    },
    url: `/api/v4/private/automations/${automationKey}`
  });

  if (getAutomationFetch.loading) {
    return <BFLoader />;
  }

  if (getAutomationFetch.error) {
    // go back to the home view and show an error
    Notify.create({ title: t`Oops! Something went wrong.`, type: 'error' });
    setView(Views.Home);
    return null;
  }

  const {
    name: automationName,
    creator,
    created_at: createdAt,
    last_executed: lastExecuted
  } = getAutomationFetch.response.data.attributes;
  const localizedCreatedAtTime = localizeDateTime(createdAt);
  const localizedLastExecutedTime = lastExecuted ? localizeDateTime(lastExecuted) : '';
  const lastRun = lastExecuted ? (
    <Trans>
      <h3 className="details__summary--label--title">Last run on</h3>
      <span className="details__summary--copy details__last-executed">
        {localizedLastExecutedTime}
      </span>
    </Trans>
  ) : (
    <Trans>
      <h3 className="details__summary--label--title">Last run on</h3>
      <span className="details__summary--copy details__last-executed">
        Never
      </span>
    </Trans>
  );

  return (
    <div className="details">
      <TextButton
        className="return-to-home-button"
        icon={FontIcons.ArrowLeft}
        onClick={(): void => setView(Views.Home)}
      >
        <Trans>Back to Asset Automation Home</Trans>
      </TextButton>
      {isCopy
        ? <h2><Trans>Create Asset Automation</Trans></h2>
        : (
          <>
            <h2 className="details__title">{automationName}</h2>
            <hr />
            <div className="details__summary">
              <label className="details__summary--label">
                <Trans>
                  <h3 className="details__summary--label--title">Created by</h3>
                  <span className="details__summary--copy details__created-by">{creator}</span>
                </Trans>
              </label>
              <label className="details__summary--label">
                <Trans>
                  <h3 className="details__summary--label--title">Created on</h3>
                  <span className="details__summary--copy details__created-on">{localizedCreatedAtTime}</span>
                </Trans>
              </label>
              <label className="details__summary--label">
                {lastRun}
              </label>
            </div>
          </>
        )
      }
      <AutomationForm
        automationKey={automationKey}
        editOrCreate={isCopy ? EditOrCreate.Copy : EditOrCreate.Edit}
        goHome={(): void => setView(Views.Home)}
        initialValues={{
          actions: transformIncludedActions(getAutomationFetch.response.included),
          name: isCopy ? t`Copy ${automationName}` : automationName,
          ...transformTriggers(getAutomationFetch.response.data.attributes.triggers)
        }}
      />
    </div>
  );
};
