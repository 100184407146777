import classnames from 'classnames';
import React, { cloneElement, FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';
import { Arrow, useHover, useLayer } from 'react-laag';
import { CSSTransition } from 'react-transition-group';

import { BaseTooltipProps, TooltipTypes } from '@components/library/tooltip';
import { Placements } from '@components/library/utils';

import classes from './styles/base-tooltip.module.scss';

const baseName = 'tooltip';

export const BaseTooltip: FunctionComponent<BaseTooltipProps> = (props) => {
  const {
    animationTimeout = 300,
    children,
    defaultOpen,
    id,
    onClose,
    onOpen,
    openOnHover = true,
    placement = Placements.TopCenter,
    tooltip,
    triggerOffset = 5,
    type = TooltipTypes.Description,
    variantName,
    wrapperProps,
    ...otherProps
  } = props;

  const [isOver, hoverProps] = useHover();
  const [open, setOpen] = useState(defaultOpen || false);

  const isOpen = (openOnHover && isOver) || open;

  const handleKeyUp = (e: KeyboardEvent): void => {
    const { key } = e;
    if (key === 'Escape') {
      setOpen(false);
    }
  };

  const handleOnClose = (): void => {
    if (onClose) {
      onClose();
    }
  };

  const handleOnOpen = (): void => {
    if (onOpen) {
      onOpen();
    }
  };

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    arrowOffset: 0,
    auto: true,
    containerOffset: 0,
    isOpen,
    overflowContainer: false,
    placement,
    triggerOffset
  });

  useEffect(() => {
    setOpen(defaultOpen || false);
  }, [defaultOpen]);

  const animate = 'fade-in';

  return (
    <span
      {...triggerProps}
      {...hoverProps}
      {...wrapperProps}
      className={classnames(
        `bf-${baseName}__${variantName}--wrapper`,
        wrapperProps && wrapperProps.className
      )}
    >
      {cloneElement(children, {
        'aria-describedby': type === TooltipTypes.Description ? id : undefined,
        'aria-labelledby': type === TooltipTypes.Label ? id : undefined,
        onBlur: () => {
          setOpen(false);
          handleOnClose();
        },
        onFocus: () => {
          setOpen(true);
          handleOnOpen();
        },
        onKeyUp: (e) => handleKeyUp(e)
      })}
      {renderLayer(
        <CSSTransition
          classNames={{
            enter: classes[`${animate}-enter`],
            enterActive: classes[`${animate}-enter-active`],
            enterDone: classes[`${animate}-enter-done`],
            exit: classes[`${animate}-exit`],
            exitActive: classes[`${animate}-exit-active`],
          }}
          in={isOpen}
          mountOnEnter
          timeout={animationTimeout}
          unmountOnExit
        >
          <p
            {...layerProps}
            {...otherProps}
            className={classnames(
              `bf-${baseName}__${variantName}`,
              classes.tooltip,
              otherProps && otherProps.className
            )}
            id={id}
            role="tooltip"
          >
            {tooltip}
            <Arrow
              {...arrowProps}
              className={classnames(
                `bf-${baseName}__${variantName}__arrow`,
                `bf-${baseName}__${variantName}__arrow--${placement}`,
                classes.arrow
              )}
            />
          </p>
        </CSSTransition>
      )}
    </span>

  );
};
