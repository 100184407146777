import classnames from 'classnames';
import { AccordionAnimations, FontIcon, FontIcons, StandardAccordion } from '@brandfolder/react';
import React, { useEffect, useRef, useState, FunctionComponent, ReactNode } from 'react';

import { StandardTooltip } from '@brandfolder/react';
import {
  Collection,
  HeadingType,
  ResourceDetails
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';
import { ResourceButton } from '@components/bulk_management/user-settings/resource-sidebar/ResourceButton';
import { ResourceHeading } from '@components/bulk_management/user-settings/resource-sidebar/ResourceHeading';
import { ResourcePrivacyBadge } from '@components/bulk_management/user-settings/resource-sidebar/ResourcePrivacyBadge';
import { collectionOrChildSelected } from '@components/bulk_management/user-settings/helpers';

interface CollectionAccordionProps {
  resource: Collection;
  resourceIndex: number;
  selectedResourceKey: string;
  setResource: SetStateDispatch<ResourceDetails>;
  headingType: HeadingType;
}

export const CollectionAccordion: FunctionComponent<CollectionAccordionProps> = ({
  headingType,
  resource,
  selectedResourceKey,
  setResource
}) => {
  const [open, setOpen] = useState(collectionOrChildSelected(resource, selectedResourceKey));
  const [showTooltip, setShowTooltip] = useState(false);
  const id = `${headingType}-accordion-${resource.key}`;
  const { adminable, key, subcollections, privacy, name } = resource;
  const subCollectionExists = subcollections?.length > 0;
  const collectionResource = useRef(null);
  const subcollectionResource = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (collectionResource?.current) {
        const { offsetWidth, scrollWidth } = collectionResource?.current;
        if (offsetWidth < scrollWidth) {
          setShowTooltip(true);
        }
      }
    }, 10); // Need a short delay to allow time to fetch and render the <CollectionAccordion> component
  }, []);

  useEffect(() => {
    if (selectedResourceKey) {
      setOpen(collectionOrChildSelected(resource, selectedResourceKey));
    }
  }, [selectedResourceKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const subcollectionList = subcollections?.map((subcollection) => (
    <ResourceButton
      key={`button-${headingType}-${subcollection.key}`}
      ref={subcollectionResource}
      headingType="subcollection"
      resource={subcollection}
      selectedResourceKey={selectedResourceKey}
      setResource={setResource}
      showTooltip={showTooltip}
    />
  ));

  const checkSubcollectionNameLength = (): void => {
    setTimeout(() => {
      const { offsetWidth, scrollWidth } = subcollectionResource?.current;
      if (offsetWidth < scrollWidth) {            
        setShowTooltip(true);
      }
    }, 10); // Need a short delay to allow time for the accordion to open
  };

  const checkAccordionState = (): ReactNode => {
    if (open) {
      return (
        <>
          <FontIcon aria-hidden className="subcollection-accordion-caret-down" icon={FontIcons.CaretDown} />
          {checkSubcollectionNameLength()}
        </>
      );
    }

    return (
      <FontIcon aria-hidden className="subcollection-accordion-caret-right" icon={FontIcons.CaretRight} />
    );
  };

  if (subCollectionExists) {
    return (
      <div
        key={`accordion-${id}`}
        className={classnames(
          'resource-sidebar__accordion',
          'collection-accordion',
          { ['active-resource']: selectedResourceKey === key },
        )}
      >
        <StandardAccordion
          key={id}
          accordions={[{
            button:
              <div className="accordion-button-text__tooltip-container">
                {checkAccordionState()}
                {showTooltip ? (
                  <StandardTooltip id={`${name}-${id}`} tooltip={name}>
                    <p>{name}</p>
                  </StandardTooltip>
                ) : (
                  <p>{name}</p>
                )}
              </div>,
            buttonIcon: <ResourcePrivacyBadge privacy={privacy} />,
            panel: (
              <div className="resource-sidebar__accordion">
                <ResourceHeading
                  headingType="subcollection"
                  length={subcollections.length || 1}
                />
                {subcollectionList}
              </div>
            )
          }]}
          allowZeroExpanded
          animation={AccordionAnimations.FadeInFromTop}
          id={id}
          initialOpen={open}
          onChange={(): void => {
            if (selectedResourceKey === key) {
              setOpen((prev) => !prev);
            } else {
              setResource({ adminable, headingType, key, name, type: 'collection' });
            }
          }}
          overrideAccordionsState={[{ isDisabled: false, isOpen: open }]}
        />
      </div>
    );
  }

  return (
    <ResourceButton
      ref={collectionResource}
      headingType={headingType}
      resource={resource}
      selectedResourceKey={selectedResourceKey}
      setResource={setResource}
      showTooltip={showTooltip}
    />
  );
};