import React from 'react';

import AssetThumbnail from '@components/asset/data/asset_thumbnail';
import { generateAssetDeepLink } from '@helpers/assets';

import './styles/attachment_card.scss';

// Note: This is a ripoff of ./attachment_card to visually match assets with attachments
// in the asset modal "similar" tab
const SimpleAssetCard = ({ asset }) => {
  const href = generateAssetDeepLink({ assetKey: asset.id, slug: BF.fx.brandfolder().slug });

  return (
    <li className="attachment-card-component">
      <a href={href} rel="noopener noreferrer" target="_blank">
        <div className="card-head">
          <div className="card-head__thumbnail">
            <AssetThumbnail asset={asset} />
          </div>
        </div>
        <div className="card-description">
          <p className="attachment-name">{asset.attributes.name}</p>
          <div className="file-type-pill">
            <span>{asset.attributes.extension}</span>
          </div>
        </div>
      </a>
    </li>
  )
};

export default SimpleAssetCard;
