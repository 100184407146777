import React, {
  FunctionComponent,
  LiHTMLAttributes,
} from 'react';

import BaseDropdownItem from '@components/library/dropdown_item/base/BaseDropdownItem';
import { ListDropdownItemProps } from '@components/library/dropdown_item/DropdownItemProps';
import styleClassNames from './list-dropdown-item.module.scss';

const ListDropdownItem: FunctionComponent<ListDropdownItemProps & LiHTMLAttributes<HTMLLIElement>> = (
  props
) => {
  const baseProps = {
    ...props,
    styleClassNames,
    variantName: 'list',
  };

  return <BaseDropdownItem {...baseProps} />;
};

export default ListDropdownItem;
