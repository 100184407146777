import { ButtonLooks, FontIcons, StandardButton } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { BrandguideCreateForm } from '@components/brandguides/BrandguideCreateForm';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { generateBrandguideUrl } from '@helpers/brandguide';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';

import classes from './styles/brandguide-create-button.module.scss';

interface BrandguideCreateButtonProps {
  atOverBrandguideLimit: boolean;
}

export const BrandguideCreateButton: FunctionComponent<BrandguideCreateButtonProps> = (props) => {
  const { atOverBrandguideLimit } = props;

  const [open, setOpen] = useState(false);

  const triggerMailTo = (): void => {
    const element = document.createElement('a');
    element.setAttribute('target', '_blank');
    element.setAttribute('href', `mailto:${BFG.supportEmail}?subject=Upgrade Inquiry&body=I'd like to inquire about adding more Brandguides to ${BFG.org_name}.`);
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleClick = (): void => {
    if (atOverBrandguideLimit) {
      triggerMailTo();
    } else {
      sendAction(TrackedAction.BrandguideCreateOpen);
      setOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const slug = query.get('brandguide');
    const copy = t`Visit Brandguide`;

    if (slug) {
      Notify.create({
        body: `<a href="${generateBrandguideUrl({ slug: decodeURIComponent(slug), organizationKey: BFG.organization_key, v2: true })}">${copy}</a>`,
        title: t`Brandguide created!`,
        type: 'success'
      });

      // remove the query string without reloading
      const url = new URL(window.location.href);
      url.searchParams.delete('brandguide');
      window.history.replaceState({}, document.title, url.href);
    }
  }, []);

  return (
    <I18nProviderWrapper>
      <StandardButton
        className={classes.createButton}
        id="create-brandguide-button"
        look={ButtonLooks.TextSecondary}
        onClick={handleClick}
        onKeyUp={(e): void => {
          if (e.key === 'Enter') {
            handleClick();
          }
        }}
        startIcon={atOverBrandguideLimit ? undefined : FontIcons.Plus}
        startIconProps={{
          iconSize: 12
        }}
      >
        {atOverBrandguideLimit ? <Trans>Upgrade for Brandguides</Trans> : <Trans>New Brandguide</Trans>}
      </StandardButton>
      <BrandguideCreateForm
        open={open}
        organizationKey={BFG.organization_key}
        setOpen={setOpen}
      />
    </I18nProviderWrapper>
  );
};
