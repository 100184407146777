import { StandardTabs } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import {
  AssetHistoryLogTable,
  shouldDisplayAssetDetails,
  shouldDisplayAssetHistory
} from '@components/asset/modal/tabs/workflow/asset_details/asset-history-log';
import {
  WorkflowAssetDetails,
  WorkflowAssetDetailsProps
} from '@components/asset/modal/tabs/workflow/asset_details/WorkflowAssetDetails';

import '../styles/_asset_details_tabs.scss';

interface WorkflowAssetDetailsTabsProps {
  assetKey: string;
  workflowAssetDetailsProps: WorkflowAssetDetailsProps;
  historyLogInitial?: boolean;
}

export const WorkflowAssetDetailsTabs: FunctionComponent<WorkflowAssetDetailsTabsProps> = ({
  assetKey, historyLogInitial = false, workflowAssetDetailsProps
}) => {
  /**
   * has history tab been clicked into at least once
   * used to prevent unnecessary fetch if user never
   * selects history tab
   */
  const [historyTabActived, setHistoryTabActived] = useState(historyLogInitial);
  const displayAssetDetails = shouldDisplayAssetDetails();
  const displayAssetHistory = shouldDisplayAssetHistory();

  if (!displayAssetDetails && !displayAssetHistory) {
    return null;
  }

  if (!displayAssetHistory) {
    return <WorkflowAssetDetails {...workflowAssetDetailsProps} showTitle />;
  }

  if (!displayAssetDetails) {
    return (
      <div className="workflow-asset-details__history-log-container">
        <AssetHistoryLogTable active assetKey={assetKey} showTitle />
      </div>
    );
  }

  const handleOnChange = (nextIndex: number): void => {
    setHistoryTabActived(nextIndex === 1);
  };

  return (
    <StandardTabs
      caption={t`Select between asset availability and history log`}
      id="asset-details-tabs"
      initialActiveIndex={historyLogInitial ? 1 : 0}
      onChange={handleOnChange}
      showUnderline
      tabs={[{
        tabButton: <Trans>Asset availability</Trans>,
        tabPanel: <WorkflowAssetDetails {...workflowAssetDetailsProps} />
      }, {
        tabButton: <Trans>Asset history</Trans>,
        tabPanel: <AssetHistoryLogTable active={historyTabActived} assetKey={assetKey} />
      }]}
    />
  );
};
