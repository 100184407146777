/* eslint-disable */
import React from 'react';
import PropTypes from "prop-types";
import videojs from 'video.js'

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import './styles/main.scss';

export default class VideoJSPlayer extends React.Component {
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props);
    this.props.onSuccess(this.player);
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <I18nProviderWrapper>
        <div id='hls-player'>
          <div data-vjs-player>
            <video ref={ node => this.videoNode = node } className="video-js"></video>
          </div>
        </div>
      </I18nProviderWrapper>
    )
  }
}

VideoJSPlayer.propTypes = {
  autoplay: PropTypes.bool,
  muted:    PropTypes.bool,
  loop:     PropTypes.bool,
  controls: PropTypes.bool,
  poster:   PropTypes.string,
  sources:  PropTypes.arrayOf(PropTypes.shape({
    src:  PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  onSuccess: PropTypes.func
};

VideoJSPlayer.defaultProps = {
  autoplay:  false,
  muted:     false,
  loop:      false,
  controls:  true,
  onSuccess: () => {}
};
