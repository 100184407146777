import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { structureUppyAttachments } from '@helpers/uploaders';
import {
  uniqueFilesByKey,
  duplicateSweetAlertOptions,
  formattedUppyAttachments
} from '@helpers/uppy';

class AttachmentUppy extends React.Component {
  triggerUpload = (files) => {
    const uniqFiles = uniqueFilesByKey(files, "md5hash");
    if (files.length !== uniqFiles.length) {
      window.swal(duplicateSweetAlertOptions, (removeDuplicates) => {
        if (removeDuplicates) {
          this.stageAttachmentForUpload(formattedUppyAttachments(uniqFiles));
        } else {
          this.stageAttachmentForUpload(formattedUppyAttachments(files));
        }
      });
    } else {
      this.stageAttachmentForUpload(structureUppyAttachments(files));
    }
  }

  stageAttachmentForUpload = (attachments) => {
    const { assetKey, onUpload } = this.props;

    onUpload(attachments);
    Insight.createEvent('added_attachments', assetKey, 'asset');
  }

  trigger = () => `uppy-asset-upload-trigger-${this.props.assetKey}`

  renderArea() {
    return (
      <div className="uppy-attachment-area" id={this.trigger()}>
        <p><Trans>Click to add an attachment</Trans></p>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderArea()}
        <UppyUploader
          {...this.props}
          button
          handleUpload={this.triggerUpload}
          trigger={`#${this.trigger()}`}
          uniqIdentifier={`attachment-uppy-${this.props.assetKey}`}
        />
      </React.Fragment>
    );
  }
}

AttachmentUppy.propTypes = {
  assetKey: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired
};

export default AttachmentUppy;
