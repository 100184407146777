import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import { FontIcon, FontIcons } from '@components/library/icon';
import { BaseTab, StandardTabs } from '@components/library/tabs';

import { AssetDetailsRule } from './AssetDetailsRule';

import classes from './styles/asset-details-tabs.module.scss';

interface AssetDetailsTabsProps {
  assetTabPanel: ReactNode;
  taskTabPanel?: ReactNode;
}

export const AssetDetailsTabs: FunctionComponent<AssetDetailsTabsProps> = (props) => {
  const {
    assetTabPanel,
    taskTabPanel,
  } = props;

  const renderTabs = (): BaseTab[] => {
    const tabs = [{
      tabButton: (
        <>
          <FontIcon aria-hidden icon={FontIcons.Info} iconSize={13} />{' '}
          <Trans>Asset Details</Trans>
        </>
      ),
      tabPanel: assetTabPanel
    }];

    if (taskTabPanel) {
      tabs.unshift({
        tabButton: (
          <>
            <FontIcon aria-hidden icon={FontIcons.Task} iconSize={11} />{' '}
            <Trans>Task Details</Trans>
          </>
        ),
        tabPanel: taskTabPanel
      });
    }

    return tabs;
  };

  return (
    <div className={classes['asset-details-tabs']}>
      <StandardTabs
        caption="Asset details"
        id="asset-details-tabs"
        tabListClassName={classnames(
          classes['asset-details-tabs__tab-list'],
          !taskTabPanel && classes['asset-details-tabs__tab-list--single']
        )}
        tabPanelClassName={classes['asset-details-tabs__tab-panel']}
        tabs={renderTabs()}
      />
      <AssetDetailsRule className={classes['asset-details-tabs__rule']} />
    </div>
  );
};
