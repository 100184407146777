import React from 'react';

import './base_styles.scss';

const BaseLabel = (props) => (
  <label
    {...props}
    className={`bf-label ${props.className}`}
  >
    {props.children}
  </label>
);

export default BaseLabel;
