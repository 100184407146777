import { t, Trans } from '@lingui/macro';
import React from 'react';

import { createCsvUploadMetasheets, getCsvUploadMetasheets } from '@api/v4/private/resources/csv/uploads';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import UGTDisclaimer from '@components/common/language_menu/ugtDisclaimer';
import { UppyUploader } from '@components/common/uppy/UppyUploader';

import { getLanguageMapEntryFromSearchString } from '../user_generated_translations/bulk_ugt_helpers';
import BulkManagementLanguageMenuDropdown from '../user_generated_translations/BulkManagementLanguageMenuDropdown';

import CsvRow from './csv_row';

import './styles/main.scss';

class CsvUpload extends React.Component {
  state = {
    csvUploads: null
  }

  componentDidMount() {
    getCsvUploadMetasheets({ resourceType: BFG.resource.type })
      .then((response) => {
        this.setState({ csvUploads: response.data });
      })
      .catch(() => {
        Notify.create({
          title: t`Error loading CSV uploads. Please refresh the page to try again.`,
          type: 'error'
        });
      });
  }

  triggerUpload = (files) => {
    this.uploadCSV(files[0].url);
  }

  uploadCSV = (url) => {
    createCsvUploadMetasheets({
      locale: getLanguageMapEntryFromSearchString()?.locale || "en",
      uploadUrl: url
    })
      .then((response) => {
        Notify.create({
          title: t`CSV Uploaded`,
          body: t`It may take a few moments for your changes to be reflected. Reloading...`,
          type: 'success'
        });

        window.location.reload();
        return response;
      }).catch(() => {
        Notify.create({
          title: t`Something went wrong. Try again soon!`,
          type: 'error'
        });
      });
  };

  trigger = () => 'uppy-csv-upload-trigger';

  renderArea() {
    return (
      <div className="uppy-csv-area" id={this.trigger()}>
        <span className="bff-plus" />
        <p>
          <Trans>Click to upload a CSV</Trans>
        </p>
      </div>
    );
  }

  render() {
    return (
      <I18nProviderWrapper>
        <UGTDisclaimer getSelectedLanguage={getLanguageMapEntryFromSearchString} />
        <div className="csv-metasheets-header">
          <h2><Trans>CSV Metasheets</Trans></h2>
          <BulkManagementLanguageMenuDropdown />
        </div>
        <hr />
        {this.renderArea()}
        <UppyUploader
          {...this.props}
          button
          handleUpload={this.triggerUpload}
          restrictions={{
            // 100mb: file size bumped up for getty
            maxFileSize: 100000000,
            maxNumberOfFiles: 1,
            allowedFileTypes: ['.csv']
          }}
          template="file_ingest"
          trigger={`#${this.trigger()}`}
          uniqIdentifier={`csv-uppy-${BF.fx.brandfolder().key}`}
        />
        <div className="headers">
          <h3 className="twenty"><Trans>Upload Date</Trans></h3>
          <h3 className="fifteen"><Trans>Uploaded by</Trans></h3>
          <h3 className="fifteen"><Trans>Filename</Trans></h3>
          <h3 className="ten center"><Trans>Successful</Trans></h3>
          <h3 className="ten center"><Trans>Invalid</Trans></h3>
          <h3 className="ten center"><Trans>Failed</Trans></h3>
          <h3 className="ten center"><Trans>Total</Trans></h3>
          <h3 className="ten center"><Trans>Status</Trans></h3>
        </div>
        {this.state.csvUploads ? (
          this.state.csvUploads.map((csv_upload) => (
            <div key={csv_upload.id} className="csv-row">
              <CsvRow csv_upload={csv_upload} />
            </div>
          ))
        ) : (
          <h3><Trans>Loading Metasheets...</Trans></h3>
        )}
      </I18nProviderWrapper>
    );
  }
}

export default CsvUpload;
