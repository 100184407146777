/** @type {import('plop').PlopGeneratorConfig} */
module.exports = {
  description: 'Generate a TypeScript enum',
  prompts: [
    {
      type: 'input',
      name: 'folderName',
      message: 'Folder name'
    },
    {
      type: 'input',
      name: 'enumName',
      message: 'Enum name'
    },
  ],
  actions: () => {
    const rootTemplatePath = 'app/javascript/components/plop/templates/typescript-enum';
    const rootDestinationPath = 'app/javascript/components/{{snakeCase folderName}}';

    const actions = [
      {
        destination: rootDestinationPath,
        base: rootTemplatePath,
        templateFiles: [`${rootTemplatePath}/*.hbs`]
      }
    ];

    return actions.map(({ base, destination, templateFiles }) => ({
      type: 'addMany',
      destination,
      base,
      templateFiles
    }));
  },
};
