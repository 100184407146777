import {
  AlertLooks,
  ButtonLooks,
  FontIcons,
  StandardAlert,
  StandardButton,
  StandardTable,
  StandardTableColumn,
  StandardTableRow
} from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { MAX_PAGE_SIZE, useFetch } from '@api/ApiHelper';
import { AccessRequestsGetResponse, RequestableResourceGetResponse } from '@api/v4/users/access_requests';
import { Sizes, StandardCheckbox } from '@components/library/checkbox';
import { StandardDialog } from '@components/library/dialog';
import { DangerButton, TextButton } from '@components/library/button';
import { GrantAccessRequestForm } from './GrantAccessRequestForm';
import { ServerResourceType } from './resource-sidebar';

import './styles/access-requests.scss';

interface AccessRequestsProps {
  selectedResourceKey: string;
  selectedResourceType: ServerResourceType,
  setReloadPendingInvitations: SetStateDispatch<boolean>,
  setReloadPlanLimits: SetStateDispatch<boolean>,
  setReloadUsers: SetStateDispatch<boolean>
}

export const AccessRequests: FunctionComponent<AccessRequestsProps> = ({
  selectedResourceKey,
  selectedResourceType,
  setReloadPendingInvitations,
  setReloadPlanLimits,
  setReloadUsers
}) => {
  const [accessRequestsData, setAccessRequestData] = useState([]);
  const [accessRequestsDeleteKey, setAccessRequestsDeleteKey] = useState<string | undefined>();
  const [accessRequestPrompt, setAccessRequestPrompt] = useState('');
  const [deleteAccessRequestsOpen, setDeleteAccessRequestsOpen] = useState(false);
  const [grantedAccessRequest, setGrantedAccessRequest] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  const accessRequestsFetch = useFetch<AccessRequestsGetResponse>({
    url: `/api/v4/${selectedResourceType}s/${selectedResourceKey}/access_requests`,
    params: {
      per: MAX_PAGE_SIZE
    }
  });

  const accessRequestPromptFetch = useFetch<RequestableResourceGetResponse>({
    url: `/api/v4/${selectedResourceType}s/${selectedResourceKey}`,
    fields: 'access_request_prompt',
  });

  const accessRequestsDelete = useFetch({
    url: `/api/v4/access_requests/${accessRequestsDeleteKey}`,
    fetchOnMount: false,
    method: 'DELETE'
  });

  useEffect(() => {
    if (accessRequestsFetch.response) {
      setAccessRequestData(accessRequestsFetch.response?.data );
    }
  }, [accessRequestsFetch.response]);

  useEffect(() => {
    if (accessRequestPromptFetch.response) {
      setAccessRequestPrompt(accessRequestPromptFetch.response?.data.attributes.access_request_prompt);
    }
  }, [accessRequestPromptFetch.response]);

  useEffect(() => {
    if (accessRequestsDeleteKey) {
      setDeleteAccessRequestsOpen(true);
    }
  }, [accessRequestsDeleteKey]);

  useEffect(() => {
    if (accessRequestsDelete.response) {
      setAccessRequestsDeleteKey(undefined);
      setDeleteAccessRequestsOpen(!deleteAccessRequestsOpen);
      accessRequestsFetch.fetch();
    }
  }, [accessRequestsDelete.response]);

  const handleSelect = (event): void => {
    const { id, checked } = event.target;
    if (checked) {
      setIsChecked([...isChecked, id]);
    } else {
      setIsChecked(isChecked.filter((item) => item !== id));
    }
  };

  const selectAllChecked = isChecked.length > 0 && isChecked.length === accessRequestsData.length;

  const handleSelectAll = (): void => {
    if (selectAllChecked) {
      setIsChecked([]);
    } else {
      setIsChecked(accessRequestsData.map((request) => request.id));
    }
  };

  const selectedEmails = accessRequestsData.filter((request)=> isChecked.includes(request.id)).map((request) => request.attributes.email);

  const accessRequestsDeleteConfirmationDialog = (
    <StandardDialog
      dialogHeaderClassName="access-requests-delete-confirmation-header"
      footer={
        <DangerButton
          isLoading={accessRequestsDelete.loading}
          onClick={(): void => {
            accessRequestsDelete.fetch();
          }}
        >
          <Trans>Delete access request</Trans>
        </DangerButton>
      }
      id="access-requests-delete-confirmation-dialog"
      open={deleteAccessRequestsOpen}
      setOpen={setDeleteAccessRequestsOpen}
      showFooter
      title={t`Delete access request`}
      titleIcon={`bff-${FontIcons.Trash}`}
    >
      <p><Trans>Are you sure you want to delete this access request?</Trans></p>
    </StandardDialog>
  );

  const columns: StandardTableColumn[] = [{
    children: (
      <StandardCheckbox
        checked={selectAllChecked}
        id="select-all"
        name={t`Select all`}
        onChange={(): void => handleSelectAll()}
        partiallyChecked={isChecked.length > 0 && isChecked.length !== accessRequestsData.length}
        size={Sizes.Medium}
      />
    ),
    heading: <Trans>Select all</Trans>,
    rowKey: 'checkbox'
  }, {
    children: <Trans>Email</Trans>,
    heading: <Trans>Email</Trans>,
    rowKey: 'email',
    width: '50%'
  }, !!accessRequestPrompt && {
    children: <Trans>Description</Trans>,
    heading:  <Trans>Description</Trans>,
    maxWidth: '425px',
    rowKey: 'promptRequest',
    width: '50%'
  }, {
    rowKey: 'removeAccessRequestIcon'
  }];

  const rows: StandardTableRow[] = accessRequestsFetch.response ? accessRequestsFetch.response.data.map((accessRequest) => ({
    checkbox: (
      <StandardCheckbox
        checked={isChecked.includes(accessRequest.id)}
        className='access-request-checkbox'
        id={accessRequest.id}
        name={t`Select individual checkbox`}
        onChange={(): void => handleSelect(event)}
        size={Sizes.Medium}
      />
    ),
    email: accessRequest.attributes.email,
    promptRequest: accessRequest.attributes.prompt_response,
    removeAccessRequestIcon: (
      <StandardButton
        children={''}
        aria-label={t`Remove access request`}
        className="access-request-remove-icon"
        look={ButtonLooks.Warning}
        onClick={(): void => {
          setDeleteAccessRequestsOpen(true);
          setAccessRequestsDeleteKey(accessRequest.id);
        }}
        startIcon={FontIcons.Remove}
      />
    )
  })) : [];

  return (rows.length > 0 || grantedAccessRequest) && (
    <div
      className={classnames(
        'access-requests-container',
        'user-settings__segment',
        { 'no-access-request-prompt': !accessRequestPrompt }
      )}
    >
      { accessRequestsFetch.response && accessRequestsDeleteConfirmationDialog }
      <h3><Trans>Access Requests</Trans></h3>
      <>
      {showAlert && (
        <StandardAlert
          className="access-requests-container__submission-alert"
          look={AlertLooks.Success}
        >
          <Trans>
            We're working on creating invitations. You'll receive a notification when the invitations have been sent.
          </Trans>
        </StandardAlert>
      )}
      {showError && (
        <StandardAlert
          className="access-requests-container__submission-alert"
          look={AlertLooks.Error}
        >
          <Trans>Something went wrong while creating invitations. Try again or contact customer support.</Trans>
        </StandardAlert>
      )}
    </>
      <StandardTable
        caption={t`Access requests Table`}
        columns={columns}
        empty={rows.length === 0}
        emptyContent={t`There are no access requests`}
        error={!!accessRequestsFetch.error}
        errorContent={(
          <div>
            <p><Trans>Oops! Something went wrong.</Trans></p>
            <TextButton onClick={(): void => { accessRequestsFetch.fetch(); }}>
              <Trans>Try again</Trans>
            </TextButton>
          </div>
        )}
        id="access-requests-table"
        loading={accessRequestsFetch.loading}
        rows={rows}
        scrollY
      />
      <GrantAccessRequestForm
        accessRequestFetch={accessRequestsFetch.fetch}
        selectedEmails={selectedEmails}
        selectedResourceKey={selectedResourceKey}
        selectedResourceType={selectedResourceType}
        setGrantedAccessRequest={setGrantedAccessRequest}
        setReloadPendingInvitations={setReloadPendingInvitations}
        setReloadPlanLimits={setReloadPlanLimits}
        setReloadUsers={setReloadUsers}
        setShowAlert={setShowAlert}
        setShowError={setShowError}
      />
    </div>
  );
};
