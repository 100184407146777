// This file maps metadata properties to the required inputs for Exiftool to properly write them to a file.
// Exiftools documentation: https://www.sno.phy.queensu.ca/~phil/exiftool/TagNames/index.html
// IPTC specification: http://www.iptc.org/std/photometadata/specification/IPTC-PhotoMetadata
// Best practices: http://www.metadataworkinggroup.org/pdf/mwg_guidance.pdf

// Metadata is generally written to various blocks within the file depending on filetype
// Certain files don't support EXIF, so most stuff gets written to either the IPTC-IIM or XMP blocks.
// It gets more complicated when you consider not all applications support reading/writing EXIF/IPTC-IIM/XMP properties.
// Do you best to follow best practices and standards here, but don't kill yourself.

// `tag` if the ExifTool tag, `label` is the human friendly label, and `group`
// is optional, but indicates the properties should be grouped together in the
// UI for clarity (they often have overlapping labels with other properties)

export default class MetadataStandards {
  constructor(...args) {}

  // TODO: add aliases for redendant properties that overlap between EXIF, IPTC-IIM,
  // and XMP in order to keep them in sync when one is edited. This would be a best
  // practice, but not required for MVP of metadata editing.

  // TODO: add a list of internal properties to inform users they should not be
  // edited. These are reserved by editing software and may damage the file if
  // edited incorrectly. Example: `filesize`.

  static iptc_property_names() {
    let property_names = []
    property_names = property_names.concat(MetadataStandards.iptc_standard_properties())
    property_names = property_names.concat(MetadataStandards.iptc_extended_properties())
    return property_names.map(property => { return property.tag.toLowerCase() })
  }

  static iptc_standard_properties() {
    return [
      { tag: 'Caption-Abstract', label: 'Caption/Description' },
      { tag: 'Headline', label: 'Headline' },
      { tag: 'ObjectAttributeReference', label: 'Intellectual Genre' },
      { tag: 'Keywords', label: 'Keywords' },
      { tag: 'Province-State', label: 'Province/State' },
      { tag: 'Scene', label: 'Scene Code' },
      { tag: 'SubjectReference', label: 'Subject Code' },
      { tag: 'DateCreated', label: 'Date Created' }, // Required format: `yyyymmdd`
      { tag: 'Writer-Editor', label: 'Writer/Editor' },
      { tag: 'SpecialInstructions', label: 'Instructions' },
      { tag: 'OriginalTransmissionReference', label: 'Job Id' },
      { tag: 'ObjectName', label: 'Title' },
      { tag: 'CopyrightNotice', label: 'Copyright Notice' },
      { tag: 'By-line', label: 'Creator' },
      { tag: 'CreatorAddress', label: 'Creator Address', group: "Creator’s Contact Info" },
      { tag: 'CreatorCity', label: 'Creator City', group: "Creator’s Contact Info" },
      { tag: 'CreatorRegion', label: 'Creator Region', group: "Creator’s Contact Info" },
      { tag: 'CreatorPostalCode', label: 'Creator Postal Code', group: "Creator’s Contact Info" },
      { tag: 'CreatorCountry', label: 'Creator Country', group: "Creator’s Contact Info" },
      { tag: 'CreatorWorkEmail', label: 'Creator Work Email', group: "Creator’s Contact Info" },
      { tag: 'CreatorWorkTelephone', label: 'Creator Work Telephone', group: "Creator’s Contact Info" },
      { tag: 'CreatorWorkURL', label: 'Creator Work URL', group: "Creator’s Contact Info" },
      { tag: 'By-lineTitle', label: 'Creator’s Jobtitle', group: "Creator’s Contact Info" },
      { tag: 'Credit', label: 'Credit Line' },
      { tag: 'UsageTerms', label: 'Usage Terms' },
      { tag: 'Source', label: 'Source' }
    ]
  }

  static iptc_extended_properties() {
    return [
      { tag: 'AdditionalModelInformation', label: 'Additional Model Information' },
      { tag: 'ArtworkCircaDateCreated', label: 'Circa Date Created', group: 'Artwork or Object in the Image' },
      // doesn't work via Blitline? { tag: 'ArtworkContentDescription', label: 'Content Description', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkContributionDescription', label: 'Contribution Description', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkCopyrightNotice', label: 'Copyright notice', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkCreator', label: 'Creator', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkCreatorID', label: 'Creator ID', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkCopyrightOwnerID', label: 'Current Copyright Owner ID', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkCopyrightOwnerName', label: 'Current Copyright Owner Name', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkLicensorID', label: 'Current Licensor ID', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkLicensorName', label: 'Current Licensor Name', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkDateCreated', label: 'Date created', group: 'Artwork or Object in the Image' }, // Required format: YYYY:mm:dd HH:MM:SS[.ss][+/-HH:MM|Z]
      { tag: 'ArtworkPhysicalDescription', label: 'Physical Description', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkSource', label: 'Source', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkSourceInventoryNo', label: 'Source inventory number', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkSourceInvURL', label: 'Source Inventory URL', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkStylePeriod', label: 'Style Period', group: 'Artwork or Object in the Image' },
      { tag: 'ArtworkTitle', label: 'Title', group: 'Artwork or Object in the Image' },
      { tag: 'OrganisationInImageCode', label: 'Code of Featured Organisation' },
      { tag: 'OrganisationInImageName', label: 'Name of Featured Organisation' },
      { tag: 'AboutCvTermCvId', label: 'CV ID', group: 'CV-Term' },
      { tag: 'AboutCvTermId', label: 'Term ID', group: 'CV-Term' },
      { tag: 'AboutCvTermName', label: 'Name', group: 'CV-Term' },
      { tag: 'AboutCvTermRefinedAbout', label: 'Refined Aboutness', group: 'CV-Term' },
      { tag: 'Genre', label: 'Genre' },
      { tag: 'LocationShownCity', label: 'City', group: 'Location' },
      { tag: 'LocationShownCountryCode', label: 'Country ISO-Code', group: 'Location' },
      { tag: 'LocationShownCountryName', label: 'Country Name', group: 'Location' },
      { tag: 'LocationShownLocationId', label: 'Location ID', group: 'Location' },
      { tag: 'LocationShownProvinceState', label: 'Province/State', group: 'Location' },
      { tag: 'LocationShownSublocation', label: 'Sublocation', group: 'Location' },
      { tag: 'LocationShownWorldRegion', label: 'World Region', group: 'Location' },
      // doesn't work via Blitline? { tag: 'LocationShownLocationName', label: 'Location Name', group: 'Location' },
      // doesn't work via Blitline? { tag: 'LocationShownGPSLongitude', label: 'GPS-Longitude', group: 'Location' },
      // doesn't work via Blitline? { tag: 'LocationShownGPSLatitude', label: 'GPS-Lattitude', group: 'Location' },
      // doesn't work via Blitline? { tag: 'LocationShownGPSAltitude', label: 'GPS-Altitude', group: 'Location' }, // requires number
      { tag: 'ModelAge', label: 'Model Age' }, // requires integer
      { tag: 'PersonInImage', label: 'Person Shown', group: 'Person in Image' },
      { tag: 'PersonInImageDescription', label: 'Description', group: 'Person in Image' },
      { tag: 'PersonInImageId', label: 'Identifier', group: 'Person in Image' },
      { tag: 'PersonInImageName', label: 'Name', group: 'Person in Image' },
      { tag: 'ProductInImageDescription', label: 'Description', group: 'Product in Image' },
      { tag: 'ProductInImageGTIN', label: 'GTIN', group: 'Product in Image' },
      { tag: 'ProductInImageName', label: 'Name', group: 'Product in Image' },
      { tag: 'DigitalImageGUID', label: 'Digital Image Identifier' },
      { tag: 'DigitalSourceType', label: 'Type of Source for This Photo' },
      { tag: 'Event', label: 'Event' },
      { tag: 'Rating', label: 'Rating' }, // requires number (decimals allowed)
      { tag: 'RegistryItemID', label: 'Item Identifier', group: 'Image Registry Entry' },
      { tag: 'RegistryOrganisationID', label: 'Organisation Identifier', group: 'Image Registry Entry' },
      // doesn't work via Blitline? { tag: 'RegistryEntryRole', label: 'Role', group: 'Image Registry Entry' },
      { tag: 'ImageSupplierName', label: 'Image Supplier' },
      { tag: 'ImageSupplierID', label: 'Image Supplier Image ID' },
      { tag: 'IPTCLastEdited', label: 'IPTC Fields Last Edited' }, // Required format: YYYY:mm:dd HH:MM:SS[.ss][+/-HH:MM|Z]
      { tag: 'LocationCreatedCity', label: 'Created In', group: 'Location Created' },
      { tag: 'LocationCreatedCountryCode', label: 'City Created In', group: 'Location Created' },
      { tag: 'LocationCreatedCountryName', label: 'Country Created In', group: 'Location Created' },
      // doesn't work via Blitline? { tag: 'LocationCreatedLocationName', label: 'Name of Location Created In', group: 'Location Created' },
      { tag: 'LocationCreatedProvinceState', label: 'Province/State Created In', group: 'Location Created' },
      { tag: 'LocationCreatedSublocation', label: 'Name of Sublocation Created In', group: 'Location Created' },
      { tag: 'LocationCreatedWorldRegion', label: 'World Region Created In', group: 'Location Created' },
      { tag: 'MaxAvailHeight', label: 'Maximum available height' }, // requires integer
      { tag: 'MaxAvailWidth', label: 'Maximum available width' }, // requires integer
      { tag: 'Version', label: 'PLUS Version' },
      { tag: 'CopyrightOwnerName', label: 'Copyright Owner' },
      { tag: 'EmbeddedEncodedRightsExpr', label: 'Link to Rights Expression', group: 'Embedded Encoded Rights Expression' },
      { tag: 'EmbeddedEncodedRightsExprType', label: 'Encoding type', group: 'Embedded Encoded Rights Expression' },
      { tag: 'EmbeddedEncodedRightsExprLangID', label: 'Rights expression language ID', group: 'Embedded Encoded Rights Expression' },
      { tag: 'LinkedEncodedRightsExpr', label: 'Link to Rights Expression', group: 'Linked Encoded Rights Expression' },
      { tag: 'LinkedEncodedRightsExprType', label: 'Encoding type', group: 'Linked Encoded Rights Expression' },
      { tag: 'LinkedEncodedRightsExprLangID', label: 'Rights Expression Language ID', group: 'Linked Encoded Rights Expression' },
      { tag: 'LicensorCity', label: 'City', group: 'Licensor Details' },
      { tag: 'LicensorCountry', label: 'Country', group: 'Licensor Details' },
      { tag: 'LicensorEmail', label: 'Email', group: 'Licensor Details' },
      { tag: 'LicensorExtendedAddress', label: 'Extended Address', group: 'Licensor Details' },
      { tag: 'LicensorID', label: 'ID', group: 'Licensor Details' },
      { tag: 'LicensorName', label: 'Name', group: 'Licensor Details' },
      { tag: 'LicensorPostalCode', label: 'Postal Code', group: 'Licensor Details' },
      { tag: 'LicensorRegion', label: 'Region', group: 'Licensor Details' },
      { tag: 'LicensorStreetAddress', label: 'Street Address', group: 'Licensor Details' },
      { tag: 'LicensorTelephone1', label: 'Telephone 1', group: 'Licensor Details' },
      { tag: 'LicensorTelephone2', label: 'Telephone 2', group: 'Licensor Details' },
      { tag: 'LicensorURL', label: 'URL', group: 'Licensor Details' },
      { tag: 'WebStatement', label: 'Copyright Info URL' }
    ]
  }
}

/* Sample metadata from IPTC 2017.1 standard example file (extracted with Exiftool):
---- ExifTool ----
ExifToolVersion                 : 10.69
---- System ----
FileName                        : standard.jpg
Directory                       : .
FileSize                        : 89 kB
FileModifyDate                  : 2017:12:27 16:04:20+08:00
FileAccessDate                  : 2017:12:27 16:05:15+08:00
FileInodeChangeDate             : 2017:12:27 16:04:28+08:00
FilePermissions                 : rw-r--r--
---- File ----
FileType                        : JPEG
FileTypeExtension               : jpg
MIMEType                        : image/jpeg
ExifByteOrder                   : Big-endian (Motorola, MM)
CurrentIPTCDigest               : cff19e30a872971b0c882244e12dd50e
ImageWidth                      : 1000
ImageHeight                     : 500
EncodingProcess                 : Baseline DCT, Huffman coding
BitsPerSample                   : 8
ColorComponents                 : 3
YCbCrSubSampling                : YCbCr4:2:0 (2 2)
---- IFD0 ----
ImageDescription                : The description aka caption (ref2017.1)
XResolution                     : 72
YResolution                     : 72
ResolutionUnit                  : inches
Artist                          : Creator1 (ref2017.1)
YCbCrPositioning                : Centered
Copyright                       : Copyright (Notice) 2017.1 IPTC - www.iptc.org  (ref2017.1)
---- IPTC ----
ObjectAttributeReference        : A Genre (ref2017.1)
ObjectName                      : The Title (ref2017.1)
SubjectReference                : IPTC:1ref2017.1, IPTC:2ref2017.1, IPTC:3ref2017.1
Keywords                        : Keyword1ref2017.1, Keyword2ref2017.1, Keyword3ref2017.1
SpecialInstructions             : An Instruction (ref2017.1)
DateCreated                     : 2017:07:13
TimeCreated                     : 17:01:00+00:00
By-line                         : Creator1 (ref2017.1)
By-lineTitle                    : Creator's Job Title  (ref2017.1)
City                            : City (Core) (ref2017.1)
Sub-location                    : Sublocation (Core) (ref2017.1)
Province-State                  : Province/State (Core) (ref2017.1
Country-PrimaryLocationCode     : R17
Country-PrimaryLocationName     : Country (Core) (ref2017.1)
OriginalTransmissionReference   : Job Id (ref2017.1)
Headline                        : The Headline (ref2017.1)
Credit                          : Credit Line (ref2017.1)
Source                          : Source (ref2017.1)
CopyrightNotice                 : Copyright (Notice) 2017.1 IPTC - www.iptc.org  (ref2017.1)
Caption-Abstract                : The description aka caption (ref2017.1)
Writer-Editor                   : Description Writer (ref2017.1)
ApplicationRecordVersion        : 4
---- XMP-x ----
XMPToolkit                      : Image::ExifTool 10.36
---- XMP-iptcCore ----
CountryCode                     : R17
CreatorCity                     : Creator's CI: City (ref2017.1)
CreatorCountry                  : Creator's CI: Country (ref2017.1)
CreatorAddress                  : Creator's CI: Address, line 1 (ref2017.1)
CreatorPostalCode               : Creator's CI: Postcode (ref2017.1)
CreatorRegion                   : Creator's CI: State/Province (ref2017.1)
CreatorWorkEmail                : Creator's CI: Email@1, Email@2 (ref2017.1)
CreatorWorkTelephone            : Creator's CI: Phone # 1, Phone # 2 (ref2017.1)
CreatorWorkURL                  : http://www.Creators.CI/WebAddress/ref2017.1
IntellectualGenre               : A Genre (ref2017.1)
Location                        : Sublocation (Core) (ref2017.1)
Scene                           : IPTC-Scene-Code1 (ref2017.1), IPTC-Scene-Code2 (ref2017.1)
SubjectCode                     : 1ref2017.1, 2ref2017.1, 3ref2017.1
---- XMP-iptcExt ----
AboutCvTermCvId                 : http://example.com/cv/about/ref2017.1
AboutCvTermId                   : http://example.com/cv/about/ref2017.1/code987
AboutCvTermName                 : CV-Term Name 1 (ref2017.1)
AboutCvTermRefinedAbout         : http://example.com/cv/refinements2/ref2017.1/codeX145
AdditionalModelInformation      : Additional Model Info (ref2017.1)
ArtworkCircaDateCreated         : AO Circa Date: between 1550 and 1600 (ref2017.1)
ArtworkContentDescription       : AO Content Description 1 (ref2017.1)
ArtworkContributionDescription  : AO Contribution Description 1 (ref2017.1)
ArtworkCopyrightNotice          : AO Copyright Notice 1 (ref2017.1)
ArtworkCreator                  : AO Creator Name 1a (ref2017.1), AO Creator Name 1b (ref2017.1)
ArtworkCreatorID                : AO Creator Id 1a (ref2017.1), AO Creator Id 1b (ref2017.1)
ArtworkCopyrightOwnerID         : AO Current Copyright Owner ID 1 (ref2017.1)
ArtworkCopyrightOwnerName       : AO Current Copyright Owner Name 1 (ref2017.1)
ArtworkLicensorID               : AO Current Licensor ID 1 (ref2017.1)
ArtworkLicensorName             : AO Current Licensor Name 1 (ref2017.1)
ArtworkDateCreated              : 1916:10:26 12:13:14+00:00
ArtworkPhysicalDescription      : AO Physical Description 1 (ref2017.1)
ArtworkSource                   : AO Source 1 (ref2017.1)
ArtworkSourceInventoryNo        : AO Source Inventory No 1 (ref2017.1)
ArtworkSourceInvURL             : AO Source Inventory URL (ref2017.1)
ArtworkStylePeriod              : AO Style Baroque (ref2017.1), AO Style Italian Baroque (ref2017.1)
ArtworkTitle                    : AO Title 1 (ref2017.1)
DigitalImageGUID                : http://example.com/imageGUIDs/TestGUID12345/ref2017.1
DigitalSourceType               : http://cv.iptc.org/newscodes/digitalsourcetype/softwareImage
EmbeddedEncodedRightsExpr       : The Encoded Rights Expression (ref2017.1)
EmbeddedEncodedRightsExprType   : IANA Media Type of ERE (ref2017.1)
EmbeddedEncodedRightsExprLangID : http://example.org/RELids/id4711/ref2017.1
Event                           : An Event (ref2017.1)
GenreCvId                       : http://example.com/cv/genre/ref2017.1
GenreCvTermId                   : http://example.com/cv/genre/ref2017.1/code1369
GenreCvTermName                 : Genre CV-Term Name 1 (ref2017.1)
GenreCvTermRefinedAbout         : http://example.com/cv/genrerefinements2/ref2017.1/codeY864
LinkedEncodedRightsExpr         : http://example.org/linkedrightsexpression/id986/ref2017.1
LinkedEncodedRightsExprType     : IANA Media Type of ERE (ref2017.1)
LinkedEncodedRightsExprLangID   : http://example.org/RELids/id4712/ref2017.1
LocationCreatedCity             : City (Location created1) (ref2017.1)
LocationCreatedCountryCode      : R17
LocationCreatedCountryName      : CountryName (Location created1) (ref2017.1)
LocationCreatedLocationId       : Location Id (Location created1) (ref2017.1)
LocationCreatedProvinceState    : Province/State (Location created1) (ref2017.1)
LocationCreatedSublocation      : Sublocation (Location created1) (ref2017.1)
LocationCreatedWorldRegion      : Worldregion (Location created1) (ref2017.1)
LocationShownCity               : City (Location shown1) (ref2017.1), City (Location shown2) (ref2017.1)
LocationShownCountryCode        : R17, R17
LocationShownCountryName        : CountryName (Location shown1) (ref2017.1), CountryName (Location shown2) (ref2017.1)
LocationShownLocationId         : Location Id 1a(Location shown1) (ref2017.1), Location Id 1b(Location shown1) (ref2017.1), Location Id 2a(Location shown2) (ref2017.1), Location Id 2b(Location shown2) (ref2017.1)
LocationShownProvinceState      : Province/State (Location shown1) (ref2017.1), Province/State (Location shown2) (ref2017.1)
LocationShownSublocation        : Sublocation (Location shown1) (ref2017.1), Sublocation (Location shown2) (ref2017.1)
LocationShownWorldRegion        : Worldregion (Location shown1) (ref2017.1), Worldregion (Location shown2) (ref2017.1)
MaxAvailHeight                  : 14
MaxAvailWidth                   : 20
ModelAge                        : 25, 27, 30
OrganisationInImageCode         : Organisation Code 1 (ref2017.1), Organisation Code 2 (ref2017.1), Organisation Code 3 (ref2017.1)
OrganisationInImageName         : Organisation Name 1 (ref2017.1), Organisation Name 2 (ref2017.1), Organisation Name 3 (ref2017.1)
PersonInImage                   : Person Shown 1 (ref2017.1), Person Shown 2 (ref2017.1)
PersonInImageCvTermCvId         : http://example.com/cv/test99/ref2017.1
PersonInImageCvTermId           : http://example.com/cv/test99/code987/ref2017.1
PersonInImageCvTermName         : Person Characteristic Name 1 (ref2017.1)
PersonInImageCvTermRefinedAbout : http://example.com/cv/refinements987/codeY765/ref2017.1
PersonInImageDescription        : Person Description 1 (ref2017.1)
PersonInImageId                 : http://wikidata.org/item/Q123456789/ref2017.1, http://freebase.com/m/987654321/ref2017.1
PersonInImageName               : Person Name 1 (ref2017.1)
ProductInImageDescription       : Product Description 1 (ref2017.1)
ProductInImageGTIN              : 123456782017.1
ProductInImageName              : Product Name 1 (ref2017.1)
RegistryEntryRole               : Registry Entry Role ID 1 (ref2017.1), Registry Entry Role ID 2 (ref2017.1)
RegistryItemID                  : Registry Image ID 1 (ref2017.1), Registry Image ID 2 (ref2017.1)
RegistryOrganisationID          : Registry Organisation ID 1 (ref2017.1), Registry Organisation ID 2 (ref2017.1)
---- XMP-dc ----
Creator                         : Creator1 (ref2017.1), Creator2 (ref2017.1)
Description                     : The description aka caption (ref2017.1)
Rights                          : Copyright (Notice) 2017.1 IPTC - www.iptc.org  (ref2017.1)
Subject                         : Keyword1ref2017.1, Keyword2ref2017.1, Keyword3ref2017.1
Title                           : The Title (ref2017.1)
---- XMP-photoshop ----
AuthorsPosition                 : Creator's Job Title  (ref2017.1)
CaptionWriter                   : Description Writer (ref2017.1)
City                            : City (Core) (ref2017.1)
Country                         : Country (Core) (ref2017.1)
Credit                          : Credit Line (ref2017.1)
DateCreated                     : 2017:07:13 17:01:00+00:00
Headline                        : The Headline (ref2017.1)
Instructions                    : An Instruction (ref2017.1)
Source                          : Source (ref2017.1)
State                           : Province/State (Core) (ref2017.1)
TransmissionReference           : Job Id (ref2017.1)
---- XMP-plus ----
CopyrightOwnerID                : Copyright Owner Id 1 (ref2017.1), Copyright Owner Id 2 (ref2017.1)
CopyrightOwnerName              : Copyright Owner Name 1 (ref2017.1), Copyright Owner Name 2 (ref2017.1)
ImageCreatorID                  : Image Creator Id 1 (ref2017.1), Image Creator Id 2 (ref2017.1)
ImageCreatorName                : Image Creator Name 1 (ref2017.1), Image Creator Name 2 (ref2017.1)
ImageCreatorImageID             : Image Creator Image ID (ref2017.1)
ImageSupplierID                 : Image Supplier Id (ref2017.1)
ImageSupplierName               : Image Supplier Name (ref2017.1)
ImageSupplierImageID            : Image Supplier Image ID (ref2017.1)
LicensorCity                    : Licensor City 1 (ref2017.1), Licensor City 2 (ref2017.1)
LicensorCountry                 : Licensor Country 1 (ref2017.1), Licensor Country 2 (ref2017.1)
LicensorEmail                   : Licensor Email 1 (ref2017.1), Licensor Email 2 (ref2017.1)
LicensorExtendedAddress         : Licensor Ext Addr 1 (ref2017.1), Licensor Ext Addr 2 (ref2017.1)
LicensorID                      : Licensor ID 1 (ref2017.1), Licensor ID 2 (ref2017.1)
LicensorName                    : Licensor Name 1 (ref2017.1), Licensor Name 2 (ref2017.1)
LicensorPostalCode              : Licensor Postcode 1 (ref2017.1), Licensor Postcode 2 (ref2017.1)
LicensorRegion                  : Licensor Region 1 (ref2017.1), Licensor Region 2 (ref2017.1)
LicensorStreetAddress           : Licensor Street Addr 1 (ref2017.1), Licensor Street Addr 2 (ref2017.1)
LicensorTelephone1              : Licensor Phone1 1 (ref2017.1), Licensor Phone1 2 (ref2017.1)
LicensorTelephone2              : Licensor Phone2 1 (ref2017.1), Licensor Phone2 2 (ref2017.1)
LicensorURL                     : Licensor URL 1 (ref2017.1), Licensor URL 2 (ref2017.1)
MinorModelAgeDisclosure         : Age 25 or Over
ModelReleaseID                  : Model Release ID 1 (ref2017.1), Model Release ID 2 (ref2017.1)
ModelReleaseStatus              : Not Applicable
PropertyReleaseID               : Property Release ID 1 (ref2017.1), Property Release ID 2 (ref2017.1)
PropertyReleaseStatus           : Not Applicable
---- XMP-xmp ----
Rating                          : 1.0
---- XMP-xmpRights ----
UsageTerms                      : Rights Usage Termns (ref2017.1)
WebStatement                    : http://www.WebStatementOfRights.org/2017.1
---- Adobe ----
DCTEncodeVersion                : 100
APP14Flags0                     : Encoded with Blend=1 downsampling
APP14Flags1                     : (none)
ColorTransform                  : YCbCr
---- Composite ----
DateTimeCreated                 : 2017:07:13 17:01:00+00:00
DateTimeOriginal                : 2017:07:13 17:01:00+00:00
ImageSize                       : 1000x500
Megapixels                      : 0.500
*/
