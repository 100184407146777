import { t } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";

import FontInput from "./font_input";

class TextArea extends React.Component {
  state = {
    value: this.props.frame.value,
    fontSize: null,
    validationMessage: null,
  }

  componentDidMount() {
    this.setValue(); // Respect pagination updates
  }

  setValue = () => {
    const { textFrameData, frame } = this.props;
    if (textFrameData[frame.id] && textFrameData[frame.id].value) {
      this.setState({ value: textFrameData[frame.id].value });
    }
  }

  triggerFrameUpdate = () => {
    const { frame, updateFrameData } = this.props;
    const { value, fontSize } = this.state;
    updateFrameData({ [frame.id]: { value, font_size: fontSize } });
  }

  handleValueChange = (value) => {
    this.validateValue(value);
    this.setState({ value });
  }

  validateValue = (value) => {
    // Limit the number of rows in the input
    const limit = this.props.validations.rows;
    if (limit) {
      const lines = value.replace(/\r\n/g, "\n").replace(/\r/g, "").split(/\n/g); // split lines
      if (lines.length > limit) {
        this.setState({ validationMessage: t`Restricted to ${limit} rows.` });
      } else if (this.state.validationMessage) {
        this.setState({ validationMessage: null });
      }
    }
  }

  updateFontSize = (fontSize) => {
    this.setState({ fontSize: parseInt(fontSize, 10) }, () => {
      this.triggerFrameUpdate();
    });
  }

  renderValidationMessage = () => (
    <p style={{ color: "red" }}>{ this.state.validationMessage }</p>
  );

  render() {
    const { frame, validations, fontSettings, templateSettings } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
        <div className="input-headers">
          <label className="bf-label bf-label--primary">
            {frame.name === "$ID/" ? "" : frame.name}
          </label>
          <FontInput
            fontSettings={fontSettings}
            frame={frame}
            templateSettings={templateSettings}
            updateFontSize={this.updateFontSize}
          />
        </div>
        <textarea
          maxLength={validations.length_max}
          name={`${frame.id}-text-area`}
          onBlur={this.triggerFrameUpdate}
          onChange={(e) => this.handleValueChange(e.target.value)}
          required
          rows={validations.rows || 5}
          style={{ height: "auto" }}
          value={value}
        />
        {this.renderValidationMessage()}
      </React.Fragment>
    );
  }
}

TextArea.propTypes = {
  frame: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
  }).isRequired,
  validations: PropTypes.shape({
    inputType: PropTypes.string,
    rows: PropTypes.number
  }),
  updateFrameData: PropTypes.func.isRequired,
  fontSettings: PropTypes.shape({}).isRequired,
  templateSettings: PropTypes.shape({
    allowFontSizing: PropTypes.bool
  }).isRequired,
  textFrameData: PropTypes.shape({}),
};

TextArea.defaultProps = {
  textFrameData: {},
  validations: {}
};

export default TextArea;
