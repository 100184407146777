import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import getAsset from '@api/v4/assets/assets';
import { post as postShareManifest } from '@api/v4/brandfolders/share_manifests';
import { get as getPrinterSettings } from '@api/v4/organizations/printer_settings';
import { get as getPrinters } from '@api/v4/organizations/printers';
import { post as postPrinterRequests } from '@api/v4/printers/print_requests';
import { BFLoader } from '@components/common/loader/main';
import renderModal from '@components/show_page/modals/renderModal';
import { generateAssetDeepLink } from '@helpers/assets';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';

import { createRequestContent, postPrinterRequestsSwal } from './helpers';
import ImagePreview from './image_preview';
import PrinterSettings from './printer_settings';
import Printers from './printers';

import './styles/web_2_print.scss';

class Web2PrintModal extends React.Component {
  state = {
    backButtonInfo: null,
    error: null,
    imageUrls: this.props.imageUrls,
    isLoading: true,
    printers: [],
    printLink: this.props.pdfUrl,
    printerSettings: null,
    printerSettingsPopulated: {},
    requestSubmitted: false,
    selectedPrinter: { label: '', value: '', key: '' },
    submitDisabled: true,
  }

  async componentDidMount() {
    sendAction(TrackedAction.WebToPrint);
    const { assetKey, launchedFromModal, updateFetchControllers } = this.props;

    const assetResponse = await this.fetchAsset();

    const organization = assetResponse.included.find((item) => (item.type === 'organizations'));
    const brandfolder = assetResponse.included.find((item) => (item.type === 'brandfolders'));

    const printerOptions = { organizationKey: organization.id };
    const shareLinkOptions = {
      assetKey,
      assetName: assetResponse.data.attributes.name,
      brandfolderKey: brandfolder.id,
    };

    const printersPromise = getPrinters(printerOptions, updateFetchControllers);
    const printerSettingsPromise = getPrinterSettings(printerOptions, updateFetchControllers);
    const shareLinkPromise = launchedFromModal
      ? postShareManifest(shareLinkOptions, updateFetchControllers)
      : undefined;

    Promise.all([printersPromise, printerSettingsPromise, shareLinkPromise])
      .then((responses) => this.initilizeState(responses, assetResponse))
      .catch(() => this.setState({ isLoading: false }));
  }

  componentWillUnmount() {
    this.props.abortFetchControllers();
  }

  fetchAsset = () => {
    const { assetKey, updateFetchControllers } = this.props;
    const assetOptions = {
      assetKey,
      include: 'organization,brandfolder,attachments,section',
      fields: 'large_thumbnail_url'
    };

    return getAsset(assetOptions, updateFetchControllers)
      .catch(() => this.setState({ isLoading: false }));
  }

  initilizeState = (responses, assetResponse) => {
    const { imageUrls, pdfUrl } = this.props;
    const [printers, printerSettings, shareLink] = responses;
    const { type: assetType } = assetResponse.data;
    const { name: assetName, large_thumbnail_url } = assetResponse.data.attributes;
    const sectionKey = assetResponse.included.find((item) => (item.type === 'sections')).id;
    const brandfolder = assetResponse.included.find((item) => (item.type === 'brandfolders'));
    const brandfolderSlug = brandfolder.attributes.slug;
    const printLink = shareLink ? shareLink.data.attributes.link : pdfUrl;

    this.setState({
      backButtonInfo: {
        assetName,
        assetType,
        brandfolderSlug,
        sectionKey,
      },
      imageUrls: imageUrls || [large_thumbnail_url],
      isLoading: false,
      printers,
      printerSettings: printerSettings.length ? JSON.parse(printerSettings[0].attributes.settings) : undefined,
      printerSettingsPopulated: {
        first_name: BFG.currentUser.firstName || '',
        last_name: BFG.currentUser.lastName || '',
        project_name: assetName,
        quantity: 1,
      },
      printLink,
      selectedPrinter: printers.length ? printers[0] : { label: '', value: '', key: '' },
      submitDisabled: !printers.length || !printerSettings.length,
    });
  }

  goBack = () => {
    const {
      assetName,
      assetType,
      brandfolderSlug,
      sectionKey
    } = this.state.backButtonInfo;
    const { assetKey, collectionSlug, digest } = this.props;

    const urlBaseString = `${brandfolderSlug}/sections/${sectionKey}/ui/${assetType}/${assetKey}`;
    const urlParamsString = collectionSlug ? `?collection_slug=${collectionSlug}` : "";
    const url = urlBaseString + urlParamsString;

    this.props.closeModal();

    if (this.state.requestSubmitted) {
      if (digest) {
        window.location.href = `${window.location.origin}/s/${digest}`;
      } else if (collectionSlug) {
        const href = generateAssetDeepLink({ assetKey, slug: `/${brandfolderSlug}/${collectionSlug}` });
        window.location.href = href;
      } else {
        const href = generateAssetDeepLink({ assetKey, slug: brandfolderSlug });
        window.location.href = href;
      }
    } else {
      BF.fullModal = true;
      BF.dialog.render(encodeURIComponent(assetName), url, BF.handlerGroups.showAsset);
    }
  }

  handleChange = (id, value) => {
    this.setState((prevState) => ({
      printerSettingsPopulated: {
        ...prevState.printerSettingsPopulated,
        [id]: value,
      }
    }));
  }

  updatePrinter = (option) => {
    this.setState({ selectedPrinter: option });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { printLink, printerSettings, printerSettingsPopulated, selectedPrinter } = this.state;
    const formData = { data: { attributes: { url: printLink } } };

    this.setState({ submitDisabled: true });

    formData.data.attributes.request_content = createRequestContent({ printerSettings, printerSettingsPopulated });

    const options = {
      body: formData,
      printerKey: selectedPrinter.key,
    };

    postPrinterRequests(options, this.props.updateFetchControllers)
      .then(() => {
        this.setState({ requestSubmitted: true });
        postPrinterRequestsSwal(selectedPrinter.label, this.goBack); // eslint-disable-line no-undef
      })
      .catch((error) => {
        this.setState({ error, requestSubmitted: true });
      });
  }

  render() {
    const {
      error,
      imageUrls,
      isLoading,
      printers,
      printerSettings,
      printerSettingsPopulated,
      requestSubmitted,
      selectedPrinter,
      submitDisabled
    } = this.state;

    const { closeModal, launchedFromModal, pdfUrl } = this.props;
    let submitButtonCopy = t`Send Print Request`;
    let cancelButtonCopy = <Trans>Cancel</Trans>;
    let formTitleCopy = <Trans>Print Request Details</Trans>;
    let disabledClass = '';

    if (error) {
      submitButtonCopy = t`Please Try Again`;
      formTitleCopy = <Trans>Print Request - Please Try Again</Trans>;
    } else if (requestSubmitted) {
      cancelButtonCopy = <Trans>Close</Trans>;
      submitButtonCopy = t`Request Sent!`;
      formTitleCopy = <Trans>Request Sent!</Trans>;
      disabledClass = 'elements-disabled';
    }

    return (
      <section className="send2print">
        <div className="send2print__preview">
          <ImagePreview
            imageUrls={imageUrls}
            pdfUrl={pdfUrl}
          />
        </div>
        <div className="send2print__request">
          {isLoading ? <BFLoader /> : (
            <React.Fragment>
              <h2 className="form-title">{formTitleCopy}</h2>
              <form onSubmit={this.handleSubmit}>
                <div className={`inputs-container ${disabledClass}`}>
                  <PrinterSettings
                    handleChange={this.handleChange}
                    printerSettings={printerSettings}
                    printerSettingsPopulated={printerSettingsPopulated}
                  />
                </div>
                <div className="printer-container">
                  <Printers
                    printers={printers}
                    selectedPrinter={selectedPrinter}
                    updatePrinter={this.updatePrinter}
                  />
                </div>
                <div className="button-container">
                  <button
                    className="button tertiary"
                    disabled={submitDisabled}
                    onClick={launchedFromModal ? this.goBack : closeModal}
                    type="button"
                  >
                    {cancelButtonCopy}
                  </button>
                  <input
                    className="button primary"
                    disabled={submitDisabled}
                    type="submit"
                    value={submitButtonCopy}
                  />
                </div>
              </form>
            </React.Fragment>
          )}
        </div>
      </section>
    );
  }
}

const ModalComponent = renderModal(Web2PrintModal, 'Web2PrintModal');
export default ModalComponent;

Web2PrintModal.propTypes = {
  abortFetchControllers: PropTypes.func.isRequired,
  assetKey: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired, // from wrapper
  collectionSlug: PropTypes.string,
  digest: PropTypes.string,
  imageUrls: PropTypes.PropTypes.arrayOf(PropTypes.string),
  launchedFromModal: PropTypes.bool,
  pdfUrl: PropTypes.string,
  updateFetchControllers: PropTypes.func.isRequired, // from wrapper
};

Web2PrintModal.defaultProps = {
  collectionSlug: null,
  digest: '',
  imageUrls: null,
  launchedFromModal: false,
  pdfUrl: ''
};
