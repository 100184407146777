import React, { FunctionComponent } from 'react';

import { StandardSwitchProps, SwitchVariant } from '@components/library/switch';

import { BaseSwitch } from '../base';

import styleClasses from './standard-switch.module.scss';

/**
 * @deprecated Use `CheckboxSwitch` or `ImmediateSwitch` instead
 */
export const StandardSwitch: FunctionComponent<StandardSwitchProps> = (
  props
) => {
  const baseProps = {
    ...props,
    styleClasses,
    variantName: SwitchVariant.Standard,
  };

  return <BaseSwitch {...baseProps} />;
};
