import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { useEffect, useRef, CSSProperties, FunctionComponent, ReactElement, SyntheticEvent } from 'react';

import { ListOptionNullOmitted, ListOptionValueNoNull } from '@components/library/dropdown';
import { VirtualizedList } from '@components/library/dropdown/base/VirtualizedList';
import { CheckableDropdownItem, ListDropdownItem } from '@components/library/dropdown_item';

interface MultiselectOptionsProps {
  arrowedItem: ListOptionValueNoNull | undefined;
  handleOnChange: (selectedOptionValue: ListOptionValueNoNull | undefined, e?: SyntheticEvent) => void;
  options: ListOptionNullOmitted[];
  searchable: boolean;
  searchedOptions: ListOptionNullOmitted[];
  selectedOptions: ListOptionNullOmitted[];
  styleClassNames: CSSProperties;
  virtualizeOptions: boolean;
}

export const MultiselectOptions: FunctionComponent<MultiselectOptionsProps> = ({
  arrowedItem,
  handleOnChange,
  options,
  searchable,
  searchedOptions,
  selectedOptions,
  styleClassNames,
  virtualizeOptions,
}) => {
  const noOptionsOption = { label: t`No Options`, value: '', isEmpty: true, type: 'no-options-dropdown-item' };

  const listRef = useRef(null);

  const filterOptions = (): ListOptionNullOmitted[] => {
    if (searchable && searchedOptions?.length) {
      return [...searchedOptions];
    }

    if (!searchable && options?.length) {
      return options;
    }

    return [noOptionsOption];
  };

  const itemizeOption = (
    option: ListOptionNullOmitted,
    index: number,
    style?: CSSProperties,
  ): ReactElement => {
    const isSelected = selectedOptions.findIndex((selectedOption) => selectedOption.value === option.value) > -1;

    if (option.type === 'no-options-dropdown-item') {
      // use ListDropdownItem here to avoid displaying the checkbox in the CheckableDropdownItem
      return (
        <ListDropdownItem
          key={`${index}${option.value}`}
          isDisabled
          isEmpty
          name="no-options-dropdown-item"
          style={style}
        >
          {option.label}
        </ListDropdownItem>
      );
    }

    return (
      <CheckableDropdownItem
        key={`${index}${option.value}`}
        className={classnames({ selected: isSelected, arrowed: arrowedItem === option.value })}
        id={option.value?.toString()}
        indentation={option.indentation}
        isArrowed={arrowedItem === option.value}
        isDisabled={option.isDisabled}
        isSelected={isSelected}
        isTabbable={false}
        name={option.value ?? ''}
        onChoose={handleOnChange}
        style={style}
        tabIndex={-1}
      >
        {option.label}
      </CheckableDropdownItem>
    );
  };

  useEffect(() => {
    if (virtualizeOptions && arrowedItem && listRef && listRef.current) {
      const arrowedItemIndex = searchedOptions?.findIndex((option) => option.value === arrowedItem);
      listRef.current.scrollToItem(arrowedItemIndex, 'smart');
    }
  }, [arrowedItem, listRef, searchedOptions, virtualizeOptions]);

  if (virtualizeOptions) {
    return (
      <VirtualizedList
        ref={listRef}
        createOptionsCallback={itemizeOption}
        options={filterOptions()}
        styleClassNames={styleClassNames}
      />
    );
  }

  return <>{filterOptions().map((option, index) => itemizeOption(option, index))}</>;
};
