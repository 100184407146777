import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { round } from '@components/advanced_download/helper';

const Zoom = ({ imageToThumbnailRatio, setSetter, zoomRatio }) => {
  const zoomRatioPercent = round(zoomRatio * imageToThumbnailRatio * 100, 0);

  return (
    <div className="zoom-container">
      <h4 className="zoom-title">
        <Trans>Zoom</Trans>:
      </h4>
      <button
        aria-label="zoom out"
        className="button tertiary sm zoom-out-button"
        onClick={() => setSetter({ action: 'zoom', data: { ratio: -0.1 } })}
        type="button"
      >
        <span aria-hidden="true" className="bff-zoom-out icon" />
      </button>
      <h4 className="zoom-ratio">{`${zoomRatioPercent}%`}</h4>
      <button
        aria-label="zoom in"
        className="button tertiary sm zoom-in-button"
        onClick={() => setSetter({ action: 'zoom', data: { ratio: 0.1 } })}
        type="button"
      >
        <span aria-hidden="true" className="bff-zoom-in icon" />
      </button>
      <button
        className="button tertiary sm reset-zoom"
        onClick={() => setSetter({ action: 'reset' })}
        type="button"
      >
        <Trans>Reset</Trans>
      </button>
    </div>
  );
};

Zoom.propTypes = {
  imageToThumbnailRatio: PropTypes.number.isRequired,
  setSetter: PropTypes.func.isRequired,
  zoomRatio: PropTypes.number.isRequired,
};

export default Zoom;
