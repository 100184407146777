import { OnlyForScreenReader } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, {
  useState,
  FunctionComponent,
  SyntheticEvent,
} from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { ActionsDropdown } from '@components/library/dropdown/index';
import { ActionDropdownItem } from '@components/library/dropdown_item/index';
import { generateBrandguideUrl } from '@helpers/brandguide';

import DeleteModal from './DeleteModal';
import SettingsModal from './SettingsModal';

interface CardActionsProps {
  brandguideKey: string;
  cname: string | null;
  organizationKey: string | null;
  slug: string;
  v2: boolean | null;
}

const CardActions: FunctionComponent<CardActionsProps> = ({
  brandguideKey,
  cname,
  organizationKey,
  slug,
  v2 = false
}) => {
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const editBrandguide = (_: string, e: SyntheticEvent): void => {
    e.preventDefault();
    window.open(generateBrandguideUrl({ cname, organizationKey, slug, v2 }), '_blank');
  };

  const openDeleteModal = (_: string, e: SyntheticEvent): void => {
    e.preventDefault();
    setShowDeleteModal(true);
  };

  const openSettingsModal = (_: string, e: SyntheticEvent): void => {
    e.preventDefault();
    setShowSettingsModal(true);
  };

  return (
    <I18nProviderWrapper>
      <ActionsDropdown
        className="brandguide-card-actions"
        closeCallback={(): void => setCloseDropdown(false)}
        openOnClick={false}
        openOnHover
        toggleDropdown={closeDropdown}
        userDevice={BFG?.currentUser?.device}
      >
        {v2 && (
          <ActionDropdownItem
            key="edit-brandguide"
            icon="bff-edit"
            name="edit-brandguide"
            onChoose={editBrandguide}
          >
            <Trans>Edit</Trans>
            <OnlyForScreenReader><Trans>Opens in a new tab.</Trans></OnlyForScreenReader>
          </ActionDropdownItem>
        )}
        <ActionDropdownItem
          key="edit-brandguide-settings"
          icon="bff-settings"
          name="edit-brandguide-settings"
          onChoose={openSettingsModal}
        >
          <Trans>Settings</Trans>
        </ActionDropdownItem>
        <ActionDropdownItem
          key="delete-brandguide"
          icon="bff-trash"
          isWarningItem
          name="delete-brandguide"
          onChoose={openDeleteModal}
        >
          <Trans>Delete Brandguide</Trans>
        </ActionDropdownItem>
      </ActionsDropdown>
      {showSettingsModal && (
        <SettingsModal
          brandguideKey={brandguideKey}
          closeModal={(): void => setShowSettingsModal(false)}
          organizationKey={organizationKey}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          brandguideKey={brandguideKey}
          closeModal={(): void => setShowDeleteModal(false)}
        />
      )}
    </I18nProviderWrapper>
  );
};

export default CardActions;
