import { t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { AssetTypesSingular } from '@api/v4/assets/assetTypes';
import { getSections, SectionsResponse } from '@api/v4/private/permissions/sections';
import { PublishResponseJSON } from '@api/v4/private/smartsheet/publish';
import { UserRoles } from '@api/v4/UserTypes';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { BFLoader } from '@components/common/loader/main';
import { ListOption } from '@components/library/dropdown';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';

import { parseOrganizations, parseResources, Resources } from './helper';
import { NoResources } from './no_resources';
import { PublishForm } from './publish_form';
import { PayloadContext, PostMessageDataReceived, Status, Statuses, Views } from './PublishTypes';
import { SubmitSuccess } from './submit_success';

const getSectionsOptions = {
  params: {
    include: 'organization,brandfolder',
    minimum_permission_level: UserRoles.Collaborator, // eslint-disable-line @typescript-eslint/naming-convention
    per: 3000,
    section_type: AssetTypesSingular.GenericFile, // eslint-disable-line @typescript-eslint/naming-convention
  },
};

interface PublishProps {
  apiKey: string;
}

export const Publish: FunctionComponent<PublishProps> = ({ apiKey }) => {
  const [resources, setResources] = useState<Resources | null>(null);
  const [organizations, setOrganizations] = useState<ListOption[] | null>();
  const [openerOrigin, setOpenerOrigin] = useState('');
  const [postMessageDataReceived, setPostMessageDataReceived] = useState<PostMessageDataReceived | null>(null);
  const [status, setStatus] = useState<Status | null>(null);
  const [view, setView] = useState(Views.Loading);
  const [fetchStatus, setFetchStatus] = useState<Statuses | null>(null);

  const handleReceiveMessage = (event: MessageEvent): void => {
    const isSmartsheetMessage = !!event?.data?.row?.id; // a row and associated id are required
    if (isSmartsheetMessage && event?.origin === openerOrigin) {
      setPostMessageDataReceived(event.data);
    } else if (isSmartsheetMessage) {
      console.log('event origin does not match opener origin', { event, eventOrigin: event.origin }, { openerOrigin });
      setStatus({
        body: { event, openerOrigin },
        context: PayloadContext.Setup,
        status: 500,
      });
    }
  };

  const handlePostMessage = (): void => {
    try {
      window.opener.postMessage(
        { payload: status },
        openerOrigin
      );
    } catch (error) {
      setStatus({
        body: {
          error,
          openerOrigin,
        },
        context: PayloadContext.PostMessage,
        status: 500,
      });
    }
  };

  const fetchSections = async (): Promise<void> => {
    try {
      const { json, response }: SectionsResponse = await getSections(getSectionsOptions);
      const areResourcesAvailable = json.data?.length > 0 && json.included && json.included.length > 0;
      setResources(parseResources(json));
      setOrganizations(parseOrganizations(json));
      setView(areResourcesAvailable ? Views.Form : Views.NoResources);
      setFetchStatus(areResourcesAvailable ? response.status as Statuses : 403);
    } catch (error) {
      const resolvedError = await error.json();
      setStatus({
        body: { error: resolvedError },
        context: PayloadContext.FetchResources,
        status: error.status as Statuses,
      });
      Notify.create({
        title: t`Something went wrong. Try again soon!`,
        type: 'error',
      });
    }
  };

  const isSendable = (): boolean => (
    // determine if status can be sent using postMessage
    status?.context !== PayloadContext.Setup
      && status?.context !== PayloadContext.PostMessage
  );

  useEffect(() => {
    sendAction(TrackedAction.SmartsheetPublish);
    const isSignedIn = (BFG?.currentUser?.user_id || '') !== '';
    if (isSignedIn) {
      const openerOriginParam = (new URLSearchParams(window.location.search)).get('opener_origin');
      if (openerOriginParam) {
        setOpenerOrigin(openerOriginParam);
      }
      fetchSections();
    } else {
      window.location.href = `/signin?return_to=${window.location.href}`;
    }

    return (): void => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchStatus && openerOrigin && resources) {
      window.addEventListener('message', handleReceiveMessage);
      setStatus({
        context: PayloadContext.FetchResources,
        status: fetchStatus,
      });
    }
  }, [fetchStatus, openerOrigin, resources]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!!status && isSendable()) {
      handlePostMessage();
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <I18nProviderWrapper>
      <div className="publish-container">
        {{
          [Views.Loading]: <BFLoader />,
          [Views.Form]: (
            <PublishForm
              apiKey={apiKey}
              organizations={organizations}
              postMessageDataReceived={postMessageDataReceived}
              resources={resources}
              setStatus={setStatus}
              setView={setView}
            />
          ),
          [Views.SubmitSuccess]: (
            <SubmitSuccess
              newAssetLink={(status?.body as PublishResponseJSON)?.data?.attributes?.best_link_for}
              singleFile={postMessageDataReceived?.attachments?.length === 1}
            />
          ),
          [Views.NoResources]: <NoResources />
        }[view]}
      </div>
    </I18nProviderWrapper>
  );
};
