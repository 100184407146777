import { t, Plural, Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent, ReactNode } from 'react';

import { formatLabelsFlat, formatLabelsTree, get as getLabels } from '@api/v4/resources/labels';
import { ResourceTypes } from '@api/v4/resources/resourceTypes';
import { LabelTree, LabelTreeChild } from '@api/v4/resources/types/labelsTypes';
import useDebounce from '@components/common/custom_hooks/useDebounce';
import { BFLoader } from '@components/common/loader/main';
import { StandardAccordion, AccordionAnimations } from '@components/library/accordion';
import { TextButton } from '@components/library/button';
import { StandardCheckbox } from '@components/library/checkbox';
import { InputType } from '@components/library/inputs/Input.props';
import PrimaryInput from '@components/library/inputs/PrimaryInput';
import { SelectedResources } from '@components/smartsheet/publish/PublishTypes';

interface SelectLabelsProps {
  resetSelectedLabels: () => void;
  selectedLabels: string[];
  selectedResources: SelectedResources;
  setShowLabels: SetStateDispatch<boolean>;
  showLabels: boolean;
  updateSelectedLabels: (updatedLabels: string[]) => void;
}

type BrandfolderLabelTrees = { [brandfolderKey: string]: LabelTree };

export const SelectLabels: FunctionComponent<SelectLabelsProps> = ({
  resetSelectedLabels,
  selectedResources,
  selectedLabels,
  setShowLabels,
  showLabels,
  updateSelectedLabels,
}) => {
  const [fetchControllers, setFetchControllers] = useState<AbortController[]>([]);
  const [labelsLoading, setLabelsLoading] = useState(false);
  const [labels, setLabels] = useState<BrandfolderLabelTrees>({});
  const [labelsFlat, setLabelsFlat] = useState({});
  const [searchedLabels, setSearchedLabels] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [randomNumber, setRandomNumber] = useState(null);
  const debouncedSearch = useDebounce({ delay: 300, value: searchInput });

  const selectedBrandfolderKey = selectedResources?.[ResourceTypes.Brandfolders];
  const selectedSectionKey = selectedResources?.[ResourceTypes.Sections];
  const displayedLabels = labels?.[selectedBrandfolderKey];
  const displayedLabelsFlat = labelsFlat?.[selectedBrandfolderKey];

  const updateFetchControllers = (controller: AbortController): void => {
    setFetchControllers((prevState) => [...prevState, controller]);
  };

  const abortFetchControllers = (): void => {
    fetchControllers.forEach((controller) => {
      if (!controller.signal.aborted) {
        controller.abort();
      }
    });
  };

  const fetchLabels = async (): Promise<void> => {
    // cancel any outstanding fetches
    abortFetchControllers();

    // exit if labels info is already fetched
    if (displayedLabels) return;

    try {
      setLabelsLoading(true);
      const response = await getLabels({
        fetchAll: true,
        resourceType: 'brandfolder',
        resourceKey: selectedBrandfolderKey,
        updateFetchControllers
      });

      const labelsTree: LabelTree = formatLabelsTree(response);
      const flatLabels = formatLabelsFlat(response);
      setLabels((prevState) => ({ ...prevState, [selectedBrandfolderKey]: labelsTree }));
      setLabelsFlat((prevState) => ({ ...prevState, [selectedBrandfolderKey]: flatLabels }));
    } catch (err) {
      // TODO handle err
      console.log(err);
    } finally {
      setLabelsLoading(false);
    }
  };

  const handleSearchInputChange = (): void => {
    setRandomNumber(Math.random());

    const updatedSearchedLabels = {};
    Object.keys(displayedLabelsFlat).forEach((labelKey) => {
      if (displayedLabelsFlat[labelKey].name?.toLowerCase().includes(debouncedSearch?.toLowerCase())) {
        // iterating on the path ensures that each label in the hierarchy of the searched label will display
        displayedLabelsFlat[labelKey].path.forEach((pathLabelKey) => {
          if (!updatedSearchedLabels[pathLabelKey]) {
            updatedSearchedLabels[pathLabelKey] = { ...displayedLabelsFlat[pathLabelKey] };
          }
        });
      }
    });
    setSearchedLabels(updatedSearchedLabels);
  };

  const makeCheckbox = (key: string, name: string, isChildless = false): ReactNode => {
    const isChecked = selectedLabels.includes(key);
    return (
      <StandardCheckbox
        key={key}
        checked={isChecked}
        className={isChildless ? 'no-children' : ''}
        labelCopy={name}
        onChange={(): void => {
          updateSelectedLabels(isChecked ? selectedLabels.filter((oldKey) => oldKey !== key) : [...selectedLabels, key]);
        }}
      />
    );
  };

  const searchedChildrenLength = (children): number => (
    debouncedSearch.length
      ? children.flatMap((labelObj) => (searchedLabels[labelObj.key] ?? [])).length
      : children.length
  );

  const makeAccordion = (labelChildren: LabelTreeChild[]): ReactNode => (
    labelChildren.map(({ children, key, name }) => {
      if (debouncedSearch.length && !searchedLabels[key]) {
        return null;
      }

      if (searchedChildrenLength(children)) {
        return (
          <StandardAccordion
            /** a random number ensures a rerender when the search is modified,
            all accordions open on rerender, revealing all search items */
            key={`${key}-${randomNumber}`}
            accordions={[{
              button: name,
              headerContent: makeCheckbox(key, name),
              panel: <div style={{ marginLeft: 22 }}>{makeAccordion(children)}</div>,
              showText: false
            }]}
            allowMultipleExpanded
            allowZeroExpanded
            animation={AccordionAnimations.FadeInFromTop}
            id={`${name}-accordion-${key}`}
            /** If all layers are open with many (1000+) labels, rendering is very slow,
            only open all layers if the user is searching */
            initialOpen={debouncedSearch.length > 2}
          />
        );
      }

      // display only a checkbox (don't show an accordion if there's no child)
      // this is dynamic based on search input
      return makeCheckbox(key, name, true);
    })
  );

  useEffect(() => {
    if (displayedLabels) {
      handleSearchInputChange();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (selectedBrandfolderKey) {
      // fetch labels when a Brandfolder is selected
      fetchLabels();
    }
  }, [selectedResources]);

  useEffect(() => (): void => { abortFetchControllers(); }, []);

  if (labelsLoading) {
    return <BFLoader />;
  }

  if (!displayedLabels?.children?.length || !selectedSectionKey) {
    return null;
  }

  return (
    <div className="select-labels">
      <div className="select-labels__header">
        <StandardCheckbox
          checked={showLabels}
          disabled={!selectedResources[ResourceTypes.Sections]}
          labelCopy={<Trans>Add to Labels</Trans>}
          onChange={(): void => setShowLabels((prevState) => (!prevState))}
        />
        {showLabels && (
          <div className="select-labels__header--details">
            <h4 className="selected-count">
              <Plural
                one={
                  <Trans>
                    <span>{selectedLabels.length}</span>
                    label selected
                  </Trans>
                }
                other={
                  <Trans>
                    <span>{selectedLabels.length}</span>
                    labels selected
                  </Trans>
                }
                value={selectedLabels.length}
              />
            </h4>
            <TextButton
              disabled={!selectedLabels.length}
              onClick={(): void => { resetSelectedLabels(); }}
            >
              <Trans>Deselect All</Trans>
            </TextButton>
          </div>
        )}
      </div>
      {showLabels && (
        <>
          <div className="select-labels__search">
            <span className="bff-search" />
            <PrimaryInput
              attributes={{
                className: 'search-labels-input',
                name: 'search-labels',
                onChange: (e: InputChangeEvent): void => setSearchInput(e.target.value),
                placeholder: t`Search Labels`,
                type: InputType.Search
              }}
              input={{ value: searchInput }}
            />
          </div>
          <div className="select-labels__accordion">
            {makeAccordion(displayedLabels.children)}
          </div>
        </>
      )}
    </div>
  );
};
