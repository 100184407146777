import {
  AccordionAnimations,
  Colors,
  FontIcon,
  FontIcons,
  Placements,
  StandardAccordion,
  StandardLink,
  StandardTooltip,
  TooltipTypes,
  Widths
} from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { Link } from 'react-router-dom';

import { reportsList } from './reportsList';
import './styles/reports-sidebar.scss';

interface ReportSidebarProps {
  customReports: boolean;
  organization: { name: string; slug: string };
  returnLink: { name: string; slug: string }
}

export const ReportSidebar: FunctionComponent<ReportSidebarProps> = (
  {
    organization,
    returnLink,
    customReports
  }) => {

  const path = `/organizations/${organization.slug}/standard_reports`;

  const reportLinks = reportsList().map((report) => {
    const reportName = report.reportName;

    return (
      reportName.length > 25  ?
        <li key={report.reportName} className='report-sidebar-link'>
          <StandardTooltip
            color={Colors.Primary}
            defaultOpen={false}
            id="link-tooltip"
            placement={Placements.TopCenter}
            tooltip={reportName}
            transitionDuration={350}
            triggerOffset={5}
            type={TooltipTypes.Description}
            width={Widths.Medium}
          >
            <Link className='report-sidebar-link-tooltip' to={`${path}/${report.reportUrl}`}>
              {reportName}
            </Link>
          </StandardTooltip>
        </li>
        :
        <li key={report.reportName} className='report-sidebar-link'>
          <Link className='report-sidebar-link-tooltip' to={`${path}/${report.reportUrl}`}>
            {reportName}
          </Link>
        </li>
    );
  });

  const customReportsLink = (
    <>
      <StandardLink
        className='custom-reports'
        href={`/organizations/${organization.slug}/reports`}
        id="custom-reports-link"
        target="_blank"
      >
        <Trans>View Custom Reports</Trans>
      </StandardLink>
    </>
  );

  const accordionButton = <span data-test-id='accordion-button'><Trans>Reports</Trans></span>;

  const reportsPanel = (
    <>
      <ul className='reports-sidebar-links'>{reportLinks}</ul>
      { customReports ? customReportsLink : null}
    </>
  );

  return (
    <div className='reports-sidebar' data-testid='reports-sidebar'>
      { returnLink.name.length > 25 ?
        <StandardTooltip
          color={Colors.Primary}
          defaultOpen={false}
          id="link-tooltip"
          placement={Placements.TopCenter}
          tooltip={returnLink.name}
          transitionDuration={350}
          triggerOffset={5}
          type={TooltipTypes.Description}
          width={Widths.Medium}
        >
          <a className='reports-sidebar-link-back' href={`/organizations/${returnLink.slug}`}>
            <FontIcon icon={FontIcons.ArrowLeft} />{' '}
            <span className='sidebar-link-back'>{'  '}<Trans>Return to {returnLink.name}</Trans></span>
          </a>
        </StandardTooltip>
        :
        <a className='reports-sidebar-link-back' href={`/organizations/${returnLink.slug}`}>
          <FontIcon icon={FontIcons.ArrowLeft} />{' '}
          <span className='sidebar-link-back'>{'  '}<Trans>Return to {returnLink.name}</Trans></span>
        </a>
      }
      <div className='reports-sidebar-report-links'>
        <FontIcon icon={FontIcons.Reports} />
        <StandardAccordion
          accordions={[
            {
              button: accordionButton,
              panel: reportsPanel
            }
          ]}
          allowMultipleExpanded={false}
          allowZeroExpanded
          animation={AccordionAnimations.FadeInFromTop}
          id="reports-sidebar-accordion"
          initialActiveIndex={0}
          initialOpen
        />
      </div>
    </div>
  );
};
