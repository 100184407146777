import { sanitize } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from "react";

import renderModal from '@components/show_page/modals/renderModal';

function AddLabelModal(props) {
  const [labelName, updateName] = useState([]);

  const createLabel = () => {
    const { addLabel, activeLabel } = props;
    const newLabelData = { name: sanitize(labelName), parent_key: activeLabel.parentKey };
    addLabel(newLabelData);
  };

  const submitIfEnter = (e) => {
    if (e.key === 'Enter') {
      createLabel();
    }
  };

  const { toggleModal, activeLabel } = props;
  const activeLabelParentName = activeLabel.parentName || '';
  const bodyTitle = activeLabel.parentName
    ? <Trans>Add new label under {activeLabelParentName}</Trans>
    : <Trans>Add new label at top level</Trans>

  return (
    <div className="modal-content-wrapper add-labels-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-label icon" />
        <h3 className="modal-title">
          <Trans>Add Label</Trans>
        </h3>
      </div>
      <div className="modal-content-wrapper__body">
        <h3 className="body-title">
          {bodyTitle}
        </h3>
        <label className="brandfolder-label" htmlFor="name">
          <Trans>Name</Trans>
          <input
            autoFocus
            className="brandfolder-input form-html-label"
            id="name"
            name="name"
            onChange={(e) => updateName(e.target.value)}
            onKeyUp={submitIfEnter}
            placeholder="Label Name"
            type="text"
          />
        </label>
        <div className="button-container">
          <button
            className="button tertiary sm t-close-modal"
            onClick={() => toggleModal()}
            type="button"
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            className="button sm primary t-confirm-modal"
            disabled={!labelName.length}
            onClick={createLabel}
            type="button"
          >
            <Trans>Add</Trans>
          </button>
        </div>
      </div>
    </div>
  );
}

AddLabelModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  addLabel: PropTypes.func.isRequired,
  activeLabel: PropTypes.shape({
    actionType: PropTypes.string,
    parentKey: PropTypes.string,
    parentName: PropTypes.string,
    name: PropTypes.string,
  }).isRequired
};

const ModalComponent = renderModal(AddLabelModal, 'AddLabelModal');
export default ModalComponent;
