import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import SearchPinsRow from './search_pins_row';

const ModalBodyContent = (props) => {
  const {
    dispatch,
    dragInProgress,
    editingActive,
    setDragInProgress,
    setEditingActive,
    updatedFiltersData,
  } = props;

  const displayTitleRow = () => {
    if (updatedFiltersData.length === 0) { return null; }

    return (
      <div className="pin-row-container title-row">
        <div />
        <div>
          <span className="bff-pin icon" />
          <h4><Trans>Pin</Trans></h4>
        </div>
        <div><h4><Trans>Featured</Trans></h4></div>
        <div><h4><Trans>Query</Trans></h4></div>
        <div />
      </div>
    );
  };

  const displayRows = () => {
    if (updatedFiltersData.length === 0) { return null; }

    const rows = updatedFiltersData.map((searchPin, i) => (
      <SearchPinsRow
        key={searchPin.key}
        dispatch={dispatch}
        dragInProgress={dragInProgress}
        editingActive={editingActive}
        index={i}
        searchPin={searchPin}
        setDragInProgress={setDragInProgress}
        setEditingActive={setEditingActive}
        updatedFiltersData={updatedFiltersData}
      />
    ));

    return (
      <ul className="search-pins-list bf-scroll-element">
        {rows}
      </ul>
    );
  };

  const displayNoResults = () => {
    if (updatedFiltersData.length > 0) { return null; }

    return (
      <h4 className="no-pinned-searches-copy">No pinned searches!</h4>
    );
  };

  return (
    <React.Fragment>
      {displayTitleRow()}
      {displayRows()}
      {displayNoResults()}
    </React.Fragment>
  );
};

ModalBodyContent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dragInProgress: PropTypes.bool.isRequired,
  editingActive: PropTypes.bool.isRequired,
  setDragInProgress: PropTypes.func.isRequired,
  setEditingActive: PropTypes.func.isRequired,
  updatedFiltersData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ModalBodyContent;
