interface GenerateAssetDeepLinkOptions {
  assetKey: string;
  slug?: string;
}

/**
 * Get an asset deep link, like https://www.brandfolder.com/brandfolder/#!asset/abc123.
 *
 * When slug is omitted, it uses `window.location.href` as the base of the URL.
 *
 * When slug is provided, it uses `window.location.origin` as the base of the URL.
 * Examples slugs include `/brandfolder`. `/brandfolder/collection`, `brandfolder`, `brandfolder/collection`.
 *
 * @param assetKey string
 * @param slug string | undefined
 * @returns string
 */
export const generateAssetDeepLink = (options: GenerateAssetDeepLinkOptions): string => {
  const { assetKey, slug = '' } = options;

  const { origin, pathname } = window.location;

  const pathnamePostfix = pathname.substring(pathname.length - 1) === '/' ? '' : '/';
  const slugPostfix = slug.substring(slug.length - 1) === '/' ? '' : '/';
  const slugPrefix = slug.charAt(0) === '/' ? '' : '/';

  const url = new URL(slug ? `${origin}${slugPrefix}${slug}${slugPostfix}` : `${origin}${pathname}${pathnamePostfix}`);
  // IMPORTANT: ensures there are no duplicate hashes
  url.hash = `#!asset/${assetKey}`;
  return url.toString();
};
