import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import LanguageMenuDropdown from '@components/common/language_menu/LanguageMenuDropdown';
import { Locale } from '@components/common/language_menu/languagesMap';

import './styles/_bulk_management_language_menu_dropdown.scss';

const reloadWithSelectedLocale = (selectedLocale: Locale, prevLocale: Locale | ''): boolean => {
  if (selectedLocale === prevLocale) {
    return false;
  }

  const newLocale = selectedLocale;
  const currentHrefPieces = window.location.href.split('#');
  const separator = currentHrefPieces[0].includes('?') ? '&' : '?';
  let newUrl = `${currentHrefPieces[0]}${separator}ugt_locale=${newLocale}#${currentHrefPieces[1]}`;

  if (prevLocale && currentHrefPieces[0].includes('ugt_locale=')) {
    newUrl = window.location.href.replace(`ugt_locale=${prevLocale}`, `ugt_locale=${newLocale}`);
  }

  window.location.href = newUrl;
  return true;
};

export const getCurrentUrlLocale = (): Locale | '' => {
  const currentHref = window.location.href.split('#')[0];
  const ugtParamString = 'ugt_locale=';
  const localeIndex = currentHref.indexOf(ugtParamString);
  if (localeIndex > -1) {
    return currentHref.substr(localeIndex + ugtParamString.length, 2) as Locale;
  }

  return '';
};

const BulkManagementLanguageMenuDropdown: FunctionComponent = () => {
  const { ugtLocaleDefault } = BFG.locales;

  if (!BFG.multiLanguageAssetDetailsEnabled) {
    return null;
  }

  const storedUgtLocale = determineUGTLocale();

  return (
    <LanguageMenuDropdown
      copy={t`Select from the list below in order to edit fields for that language.`}
      defaultLocale={ugtLocaleDefault}
      onLocaleChange={(selectedLocale: Locale): boolean => reloadWithSelectedLocale(selectedLocale, getCurrentUrlLocale())}
      openOnHover={false}
      selectedLocale={getCurrentUrlLocale() || storedUgtLocale || ugtLocaleDefault}
      showSelectedLocaleName
      wrapperClassnames='bulk-management-ugt'
    />
  );
};

interface BulkCustomFieldsLanguageMenuDropdownProps {
  setUgtLocale: SetStateDispatch<Locale>;
  ugtLocale: Locale;
}

export const BulkCustomFieldsLanguageMenuDropdown: FunctionComponent<BulkCustomFieldsLanguageMenuDropdownProps> = ({
  setUgtLocale,
  ugtLocale
}) => {
  if (!BFG.multiLanguageAssetDetailsEnabled) return null;

  return (
    <LanguageMenuDropdown
      copy={t`Select from the list below in order to edit fields for that language.`}
      defaultLocale={BFG.locales?.ugtLocaleDefault}
      onLocaleChange={(selectedLocale: Locale): boolean => {
        if (selectedLocale === ugtLocale) return false;

        setUgtLocale(selectedLocale);
        return true;
      }}
      openOnHover={false}
      selectedLocale={ugtLocale}
      showSelectedLocaleName
      wrapperClassnames='bulk-management-ugt'
    />
  );
};

export default BulkManagementLanguageMenuDropdown;
