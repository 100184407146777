import { t } from '@lingui/macro';

import { isGettyClient } from '@helpers/getty-strings';

import { Category, ChangeAgent, EventType } from './AssetEventTypes';

export const PEOPLE_TAGGING_EMAIL = 'people_tagging_feature@brandfolder.com';

const categoryTranslations: Record<Category, string> = {
  [Category.Approved]: t`Approved`,
  [Category.Asset]: t`Asset`,
  [Category.AttachmentCreated]: t`Attachment created`,
  [Category.AttachmentDeleted]: t`Attachment deleted`,
  [Category.AttachmentUpdated]: t`Attachment updated`,
  [Category.Availability]: t`Availability status`,
  [Category.AvailabilityEnd]: t`Expiration date`,
  [Category.AvailabilityStart]: t`Publish date`,
  [Category.BrandfolderId]: t`Brandfolder`,
  [Category.Collections]: t`Collections`,
  [Category.Color]: t`Color info`,
  [Category.Comments]: t`Comments`,
  [Category.CustomFieldValues]: t`Custom field values`,
  [Category.Description]: t`Description`,
  [Category.ExternalMedium]: t`Media info`,
  [Category.Font]: t`Font info`,
  [Category.GenericFile]: t`Asset info`,
  [Category.IndexOnly]: '',
  [Category.Labels]: t`Labels`,
  [Category.Merge]: t`Merge`,
  [Category.Name]: t`Name`,
  [Category.Person]: t`Personal info`,
  [Category.Press]: t`Press info`,
  [Category.SectionId]: t`Section`,
  [Category.Tags]: t`Tags`,
  [Category.ThumbnailOverride]: t`Thumbnail override`,
  [Category.Unapproved]: t`Unapproved`,
  [Category.Uncategorized]: t`Uncategorized`,
  [Category.Versions]: t`Versions`,
  [Category.ViewOnly]: t`View only`,
  [Category.Watermark]: t`Watermark`,
  [Category.Workspaces]: t`Workspaces`
};

const changeAgentTranslations: Record<ChangeAgent, string> = {
  [ChangeAgent.AssetAutomations]: t`Asset Automations`,
  [ChangeAgent.AttachmentIdentifiers]: t`Attachment Identifiers`, // svc-image-hasher
  [ChangeAgent.AttachmentMetadata]: t`Attachment Metadata`, // svc-filemetadata
  [ChangeAgent.AvailabilityDetection]: t`Availability Detection`, // AssetAvailabilityJob cron
  [ChangeAgent.BrandfolderSupport]: t`Brandfolder Support`, // For Rails console activity or scripts
  [ChangeAgent.BrandfolderUi]: t`Brandfolder UI`,
  [ChangeAgent.CanvaIntegration]: t`Canva Integration`, // Has JS hardcoded values
  [ChangeAgent.ContentAutomationIntegration]: t`Content Automation Integration`,
  [ChangeAgent.CsvMetasheets]: t`CSV Metasheets`,
  [ChangeAgent.DesignHuddleIntegration]: t`Custom Templating Integration`, // Has JS hardcoded values
  [ChangeAgent.DuplicateDetection]: t`Duplicate Detection`,
  [ChangeAgent.FiletypeDetection]: t`Filetype Detection`,
  [ChangeAgent.FtpIngest]: t`FTP Ingest`,
  [ChangeAgent.GcsIngest]: t`GCS Ingest`,
  [ChangeAgent.GettylinkIntegration]: t`Getty Link Integration`,
  [ChangeAgent.GuestUpload]: t`Guest Upload`,
  [ChangeAgent.MachineLearningDisneyModel]: t`Disney Machine Learning`,
  [ChangeAgent.MachineLearningGoogleVideoIntelligence]: t`Video Intelligence`,
  [ChangeAgent.MachineLearningGoogleVision]: t`Auto Tagging`,
  [ChangeAgent.MachineLearningGoogleVisionCelebrities]: t`Celebrity Detection`,
  [ChangeAgent.MachineLearningGoogleVisionLandmarks]: t`Landmark Detection`,
  [ChangeAgent.MachineLearningGoogleVisionLogos]: t`Logo Detection`,
  [ChangeAgent.MachineLearningHiveModel]: t`Hive Logo Detection`,
  [ChangeAgent.MachineLearningPeopleTagging]: t`People Tagging`,
  [ChangeAgent.MetadataConversions]: t`Metadata Conversions`,
  [ChangeAgent.PrintuiIntegration]: t`Printui Integration`,
  [ChangeAgent.SftpIngest]: t`SFTP Ingest`,
  [ChangeAgent.SmartsheetPublishIntegration]: t`Smartsheet Publish Integration`,
  [ChangeAgent.StoryteqIntegration]: t`Storyteq Integration`,
};

const eventTypeTranslations: Record<EventType, string> = {
  [EventType.Create]: t`Created`,
  [EventType.Delete]: t`Deleted`,
  [EventType.Update]: t`Updated`
};

export const categoryEndUserString = (category: Category | string): string => {
  if ((category as Category) === Category.BrandfolderId && isGettyClient()) {
    return t`Library`;
  }

  return categoryTranslations[category] ?? category;
};

export const changeAgentEndUserString = (changeAgent: ChangeAgent | string, actorEmail: string): string => {
  if ((changeAgent as ChangeAgent) === ChangeAgent.BrandfolderSupport && isGettyClient()) {
    return t`Media Manager Support`;
  }

  if ((changeAgent as ChangeAgent) === ChangeAgent.BrandfolderUi && isGettyClient()) {
    return t`Media Manager UI`;
  }

  if (actorEmail === PEOPLE_TAGGING_EMAIL) {
    return t`People Tagging`;
  }

  return changeAgentTranslations[changeAgent] ?? changeAgent;
};

export const eventTypeEndUserString = (eventType: EventType): string => eventTypeTranslations[eventType];
