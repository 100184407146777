import {
  AlertLooks,
  CloseButton,
  FontIconColors,
  FontIcons,
  StandardAlert,
} from '@brandfolder/react';
import { t } from '@lingui/macro';
import classNames from 'classnames';

import React, { FunctionComponent, useState } from 'react';

import { LimitTypes } from './customLimitsTypes';
import { assignLimitsUnits } from './helpers/assignLimitsUnits';
import './styles/allocation-alert.scss';

export type AlertLocation = 'header' | 'footer';

interface AllocationAlertProps {
  limit: number;
  limitType: LimitTypes;
  location: AlertLocation;
  maxPlanLimit: string | number;
}

export const AllocationAlert: FunctionComponent<AllocationAlertProps> = ({
  limit,
  limitType,
  location,
  maxPlanLimit,
}) => {
  const [closeHeaderAlert, setCloseHeaderAlert] = useState(false);
  const percentUsed = (Number(limit) * 100) / Number(maxPlanLimit);
  const success = percentUsed === 100;
  const danger = percentUsed > 100;
  const warning = percentUsed < 100;
  const unallocatedCount = Number(maxPlanLimit) - Number(limit);
  const amountUnallocated = assignLimitsUnits(limitType, unallocatedCount);
  const overLimitCount = Number(limit) - Number(maxPlanLimit);
  const amountOverLimit = assignLimitsUnits(limitType, overLimitCount);
  const ALERTS = {
    danger: {
      alertLook: AlertLooks.Error,
      color: FontIconColors.Danger,
      footer: t`${amountOverLimit} over`,
      header: t`You allocated more than your current limit. Please remove ${amountOverLimit} or upgrade.`,
    },
    warning: {
      alertLook: AlertLooks.Warning,
      color: FontIconColors.Warning,
      footer: t`${amountUnallocated} unallocated`,
      header: t`You allocated less than your current limit. Please add ${amountUnallocated}.`,
    },
    success: {
      alertLook: AlertLooks.Success,
      footer: t`100% allocated`,
    },
  };

  const alertClassNames = classNames({
    'limits-allocation-alert': true,
    'header-alert': location === 'header',
    'footer-alert': location === 'footer',
    'hide-alert':
      (location === 'header' && closeHeaderAlert) ||
      (location === 'header' && success),
  });

  const allocationAlert = (
    alertType: 'danger' | 'warning' | 'success'
  ): JSX.Element => (
    <StandardAlert
      className={alertClassNames}
      icon={alertType !== 'success' && FontIcons.Warning}
      look={ALERTS[alertType].alertLook}
      showHeading={false}
    >
      <span>{ALERTS[alertType][location]}</span>
      {location === 'header' && alertType !== 'success' && (
        <CloseButton
          aria-label="close alert"
          className="icon-close"
          color={ALERTS[alertType].color}
          fontIconProps={{ iconSize: 12 }}
          onClick={(): void => setCloseHeaderAlert(true)}
        />
      )}
    </StandardAlert>
  );

  return (
    <>
      {success && allocationAlert('success')}
      {warning && allocationAlert('warning')}
      {danger && allocationAlert('danger')}
    </>
  );
};
