import { ApiData } from '@brandfolder/types';
import { useEffect, useState } from 'react';

import { fetchJson } from '@api/ApiHelper';
import { ApiResponseObject } from '@api/v4/ApiResponseTypes';

import { Asset } from '@api/v4/assets/assetTypes';
import { Attachment } from '@api/v4/attachments/attachmentTypes';
import getResourceAssets from '@api/v4/resources/assets';

import { AssetViewModel } from './model';

export interface AssetsResponse {
  assets: ApiResponseObject<Asset>[];
  included: ApiResponseObject<Attachment>[];
}

export interface AttachmentsResponse {
  attachments: ApiResponseObject<Attachment>[];
  included: ApiResponseObject<Asset>[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
async function getAssetAttachments(
  assetKey: string,
  params: Record<string, unknown>
) {
  return fetchJson({
    method: 'GET',
    url: `/api/v4/assets/${assetKey}/attachments`,
    params,
  }).then((response: ApiData<Attachment>) => {
    if (!response.meta) {
      return {
        attachments: response.data,
        included: response.included,
      };
    }

    return {
      attachments: response.data,
      included: response.included,
      total: response.meta.total_count,
      totalPages: response.meta.total_pages,
      currentPage: response.meta.current_page,
    };
  });
}

async function fetchAssetAttachments(
  assetKey: string
): Promise<AttachmentsResponse> {
  const params = {
    fields: ['thumbnail_url', 'view_thumbnail_retina'],
    digest: BFG.manifestDigest,
    per: 3000,
  };

  const attachmentsResponse = (await getAssetAttachments(
    assetKey,
    params
  )) as AttachmentsResponse;

  return attachmentsResponse;
}

async function fetchAttachments(
  assetKey: string
): Promise<AttachmentsResponse> {
  const attachments = await fetchAssetAttachments(assetKey);
  return attachments;
}

async function mapAssetsResponseToAssetViewModels(
  assetsResponse: AssetsResponse
): Promise<AssetViewModel[]> {
  const assetViewModels = await Promise.all(
    assetsResponse.assets.map(async (asset) => {
      const attachments = await fetchAttachments(asset.id).then(
        (attachmentsResponse) => {
          return attachmentsResponse?.attachments?.map((attachment) => {
            return attachment;
          });
        }
      );

      return {
        ...asset,
        attachments: attachments || [],
      };
    })
  );

  return assetViewModels;
}

async function fetchSectionAssets(
  sectionKey: string
): Promise<AssetViewModel[]> {
  const sortMethod = BFG.brandfolderSettings.default_order_method.split(' ')[0];
  const sortOrder = BFG.brandfolderSettings.default_order_method.split(' ')[1];
  const params = {
    ...(BFG.manifestDigest && { digest: BFG.manifestDigest }),
    fields: [
      'asset_data',
      'is_downloadable',
      'large_thumbnail_url',
      'view_only',
    ],
    order: sortOrder,
    per: 3000,
    sort_by: sortMethod,
  };

  const fetchOptions = {
    isCollection: BFG.resource.type === 'collection',
    resourceType: BFG.resource.type,
    resourceKey: BFG.resource.key,
    sectionKey,
  };

  const assetsResponse = (await getResourceAssets(
    params,
    fetchOptions
  )) as AssetsResponse;

  return mapAssetsResponseToAssetViewModels(assetsResponse);
}

async function fetchAssets(sectionKeys: string[]): Promise<AssetViewModel[]> {
  const assets = await Promise.all(sectionKeys.map(fetchSectionAssets));
  return assets.flat();
}

export interface UseFetchAssetsProps {
  sectionKeys: string[];
}

export interface UseFetchAssetsReturn {
  assets: AssetViewModel[];
  error: boolean;
  loading: boolean;
}

export const useFetchAssets: (
  props: UseFetchAssetsProps
) => UseFetchAssetsReturn = ({ sectionKeys }) => {
  const [assets, setAssets] = useState<AssetViewModel[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setError(false);
    fetchAssets(sectionKeys)
      .then((fetchedAssets) => {
        setAssets(fetchedAssets);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [sectionKeys]);

  return { assets, error, loading };
};
