import fetchJSON from '@api/api_helper';

const url = ({ resourceKey, resourceType }) => (
  `/api/v4/${resourceType}s/${resourceKey}/download_requests`
);

export const create = ({
  key: resourceKey,
  type: resourceType,
  assetKeys: asset_keys,
  company,
  'email-address': request_email,
  name: requester_name,
  'usage-details': usage_details,
}) => (
  fetchJSON(url({ resourceKey, resourceType }), {
    method: 'POST',
    body: {
      data: {
        attributes: {
          asset_keys,
          company,
          request_email,
          requester_name,
          usage_details,
        }
      }
    }
  }).catch(async (response) => { throw (await response.json()); })
);
