import { FontIcons } from '@brandfolder/react';
import { t } from '@lingui/macro';

import { DialogOptions } from '@components/common/providers/DialogContextProvider';

type LimitsDialogOptions = {
  admin: {
    icon: string,
    title: string
  },
  collaborator: {
    icon: string,
    title: string
  },
  storage: {
    icon: string,
    title: string
  }
};

const limitsTypeOptions = (): LimitsDialogOptions => ({
  admin: {
    icon: 'Person',
    title: t`Set Admin limits`,
  },
  collaborator: {
    icon: 'Person',
    title: t`Set Collaborator limits`,
  },
  storage: {
    icon: 'Ingest',
    title: t`Set storage limits`,
  },
});

export const planLimitsDialogOptions = (
  limitType = 'admin'
): DialogOptions => ({
  id: 'limits-table-dialog',
  dialogClassName: 'set-limits-table-dialog',
  focusLock: false,
  height: 943,
  showFooter: false,
  title: limitsTypeOptions()[limitType].title,
  titleIcon: `bff-${FontIcons[limitsTypeOptions()[limitType].icon]}`,
  width: 840,
});
