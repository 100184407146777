import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { useFetch, useNotifyFetchError } from '@api/ApiHelper';
import { ActionSummaryTd } from '@components/bulk_management/automation/ActionSummaryTd';
import { Views } from '@components/bulk_management/automation/AutomationEnums';
import { AutomationsListResponse } from '@components/bulk_management/automation/AutomationTypes';
import {
  automationFilterOptions,
  automationSortOptions,
  automationTooltips
} from '@components/bulk_management/automation/helpers';
import { summarizeManageTrigger } from '@components/bulk_management/automation/summary-helpers';
import { defaultPagination, mapMetaToPagination, Pagination } from '@components/bulk_management/shared';

import useDebounce from '@components/common/custom_hooks/useDebounce';
import { defaultKnowledgeBaseLinks, gettyKnowledgeBaseLinks } from '@components/kb-article-links';
import { PrimaryButton, SecondaryButton, TextWarningButton, TextButton } from '@components/library/button';
import { ListDropdown } from '@components/library/dropdown';
import { FontIcons } from '@components/library/icon';
import { StandardSearchField, searchStatus } from '@components/library/search-field';
import { StandardTable, StandardTableColumn, StandardTableRow } from '@components/library/table';
import { MoreInfoTooltip, StandardTooltip } from '@components/library/tooltip';
import { isGettyClient } from '@helpers/getty-strings';

interface HomeViewProps {
  libraryName: string;
  setAutomationKey: SetStateDispatch<string>;
  setIsCopy: SetStateDispatch<boolean>;
  setView: SetStateDispatch<Views>;
}

let knowledgeBaseUrl;

if (isGettyClient()) {
  knowledgeBaseUrl = gettyKnowledgeBaseLinks.assetAutomationsLink;
} else {
  knowledgeBaseUrl = defaultKnowledgeBaseLinks.assetAutomationsLink;
}

const columns: StandardTableColumn[] = [
  {
    heading: (
      <>
        <Trans>Name</Trans>
        <MoreInfoTooltip iconSize={16} id="automation-name-heading" tooltip={automationTooltips().name} />
      </>
    ),
    rowKey: 'automation',
  },
  {
    heading: (
      <>
        <Trans>Trigger</Trans>
        <MoreInfoTooltip iconSize={16} id="automation-trigger-heading" tooltip={automationTooltips().trigger} />
      </>
    ),
    rowKey: 'trigger',
    width: '20%',
  },
  {
    heading: (
      <>
        <Trans>Action</Trans>
        <MoreInfoTooltip iconSize={16} id="automation-action-heading" tooltip={automationTooltips().action} />
      </>
    ),
    rowKey: 'action',
    width: '20%',
  },
  {
    heading: (
      <>
        <Trans>Run</Trans>
        <MoreInfoTooltip iconSize={16} id="automation-run-heading" tooltip={automationTooltips().run} />
      </>
    ),
    rowKey: 'run',
    width: '125px',
  },
  {
    heading: '',
    rowKey: 'copy',
    width: '50px',
  },
  {
    heading: '',
    rowKey: 'delete',
    width: '50px',
  },
];

export const HomeView: FunctionComponent<HomeViewProps> = ({
  libraryName,
  setAutomationKey,
  setIsCopy,
  setView
}) => {
  const headerCopy1 = libraryName === 'Library'
    ? <Trans>Add asset data and organize your Library automatically by defining a trigger that causes a series of custom actions.</Trans>
    : <Trans>Add asset data and organize your Brandfolder automatically by defining a trigger that causes a series of custom actions.</Trans>;
  const headerCopy2 = (
    <Trans>
      Asset automations will run as soon as you update assets that affect the trigger. You can also run asset automations manually by selecting 'run' once up to every 24 hours to update existing assets.
    </Trans>
  );
  const [pagination, setPagination] = useState(defaultPagination);
  const [searchQuery, setSearchQuery] = useState('');
  const [sort, setSort] = useState('created_at|desc');
  const [filter, setFilter] = useState('all');
  const [disableDebounce, setDisableDebounce] = useState(false);
  const debouncedSearch = useDebounce({
    delay: 600,
    disableDebounce: disableDebounce || process?.env?.NODE_ENV === 'circle',
    value: searchQuery
  });
  const [runAutomationKey, setRunAutomationKey] = useState('');
  const [deleteAutomationKey, setDeleteAutomationKey] = useState('');

  const listAutomationsFetch = useFetch<AutomationsListResponse>({
    fetchOnMount: false, // the effect on per/currentPage causes fetch on mount
    fields: ['can_execute'],
    params: {
      page: pagination.currentPage,
      per: pagination.per,
      sort_by: sort.split('|')[0], // eslint-disable-line @typescript-eslint/naming-convention
      order: sort.split('|')[1],
      ...debouncedSearch && { search: debouncedSearch },
      ...filter !== 'all' && { 'filter[type]': filter }
    },
    url: `/api/v4/private/brandfolders/${BFG.resource.key}/automations`
  });
  const automations = listAutomationsFetch.response?.data;
  const noResults = listAutomationsFetch.response?.data?.length === 0;

  const runAutomationFetch = useFetch<Record<string, never>>({
    fetchOnMount: false,
    url: `/api/v4/private/automations/${runAutomationKey}/run`
  });

  const deleteAutomationFetch = useFetch<Record<string, never>>({
    fetchOnMount: false,
    url: `/api/v4/private/automations/${deleteAutomationKey}`,
    method: 'DELETE'
  });

  useNotifyFetchError(runAutomationFetch);
  useNotifyFetchError(deleteAutomationFetch);

  useEffect(() => {
    listAutomationsFetch.fetch();
  }, [debouncedSearch, filter, pagination.per, pagination.currentPage, sort]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteAutomationFetch.response) {
      setDeleteAutomationKey('');
      listAutomationsFetch.fetch();
    }
  }, [deleteAutomationFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (runAutomationFetch.response) {
      setRunAutomationKey('');
      listAutomationsFetch.fetch();
    }
  }, [runAutomationFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (runAutomationKey) {
      runAutomationFetch.fetch();
    }
  }, [runAutomationKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteAutomationKey) {
      deleteAutomationFetch.fetch();
    }
  }, [deleteAutomationKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (disableDebounce) {
      // reset to debounce after clearing search
      setDisableDebounce(false);
    }
  }, [disableDebounce]);

  const resetSearchFields = (): void => {
    setDisableDebounce(true);
    setSearchQuery('');
    setFilter('all');
  };

  const rows: StandardTableRow[] = automations ? automations.map(({
    attributes,
    id
  }) => ({
    automation: (
      <TextButton
        className="automation-name-button"
        icon={FontIcons.Edit}
        onClick={(): void => { setView(Views.Details); setAutomationKey(id); }}
      >
        {attributes.name}
      </TextButton>
    ),
    trigger: (
      <span className="automation-trigger-summary">
        {summarizeManageTrigger(attributes.triggers?.[0]?.type)}
      </span>
    ),
    action: <ActionSummaryTd actionsSummary={attributes.actions_summary} id={id} />,
    run: attributes.can_execute ? (
      <SecondaryButton
        className="automation-run-button"
        onClick={(): void => setRunAutomationKey(id)}
        size="xsmall"
      >
        <Trans>Run</Trans>
      </SecondaryButton>
    ) : (
      <StandardTooltip
        id={`cannot-run-tooltip-${id}`}
        tooltip={t`This automation has already been run in the past 24 hours.`}
      >
        <SecondaryButton
          aria-disabled
          size="xsmall"
          useDisabledStyleForAriaDisabled
        >
          <Trans>Run</Trans>
        </SecondaryButton>
      </StandardTooltip>
    ),
    copy: (
      <StandardTooltip
        id={`automation-copy-button-tooltip-${id}`}
        tooltip={t`Duplicate`}
      >
        <TextButton
          aria-label={t`Copy automation`}
          className="automation-copy-button"
          icon={FontIcons.Copy}
          onClick={(): void => {
            setView(Views.Details);
            setAutomationKey(id);
            setIsCopy(true);
          }}
        />
      </StandardTooltip>
    ),
    delete: (
      <TextWarningButton
        aria-label={t`Delete automation`}
        className="automation-delete-button"
        icon={FontIcons.Trash}
        onClick={(): void => setDeleteAutomationKey(id)}
      />
    )
  })) : [];

  const searchStatusOptions = {
    isLoading: !!debouncedSearch && listAutomationsFetch.loading,
    resultQuantity: rows.length,
    resultsShown: !!debouncedSearch && !listAutomationsFetch.loading && !listAutomationsFetch.error,
  };

  return (
    <div className="home-view">
      <header className="header">
        <div className="header__title--container">
          <h2 className="header__title"><Trans>Asset Automation</Trans></h2>
          <p className="header__copy">{headerCopy1} {headerCopy2}</p>
          <p className="header__help-docs">
            <Trans>
              Get all the details and view some examples in our&nbsp;
              <a
                href={knowledgeBaseUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                Knowledge Base
              </a>.
            </Trans>
          </p>
        </div>
        <div className="header__create-button--container">
          <PrimaryButton
            className="header__create-button"
            icon={FontIcons.Plus}
            onClick={(): void => setView(Views.Create)}
          >
            <Trans>Create New Asset Automation</Trans>
          </PrimaryButton>
        </div>
      </header>
      <div className="home-view__controls">
        <StandardSearchField
          id="automations-search"
          label={t`Search Asset Automations`}
          onChange={(e: InputChangeEvent): void => setSearchQuery(e.target.value)}
          placeholder={t`Search`}
          searchStatus={searchStatus(searchStatusOptions)}
          showLabel
          value={searchQuery}
        />
        <div className="home-view__controls--dropdowns">
          <ListDropdown
            id="automations-sort-dropdown"
            label={t`Sort By`}
            onChange={(listOption): void => {
              setSort(listOption.value.toString());
            }}
            options={automationSortOptions()}
            value={sort}
            virtualizeOptions={false}
          />
          <ListDropdown
            id="automations-filter-dropdown"
            label={t`Filter By`}
            onChange={(listOption): void => {
              setFilter(listOption.value.toString());
            }}
            options={automationFilterOptions()}
            value={filter}
            virtualizeOptions={false}
          />
        </div>
      </div>
      <div className="home-view__automations">
        <StandardTable
          caption={t`Existing Asset Automations`}
          columns={columns}
          empty={noResults}
          emptyContent={debouncedSearch || (filter !== 'all') ? (
            <div>
              <p>
                <Trans>Sorry! No results were found. Try a different search.</Trans>
              </p>
              <TextButton onClick={resetSearchFields}>
                <Trans>Reset search</Trans>
              </TextButton>
            </div>
          ) : (
            <div>
              <p>
                <Trans>You haven't added anything yet.</Trans>
              </p>
              <TextButton onClick={(): void => setView(Views.Create)}>
                <Trans>Create an asset automation</Trans>
              </TextButton>
            </div>
          )}
          error={!!listAutomationsFetch.error}
          errorContent={(
            <div>
              <p><Trans>Oops! Something went wrong.</Trans></p>
              <TextButton onClick={(): void => { listAutomationsFetch.fetch(); }}>
                <Trans>Try again</Trans>
              </TextButton>
            </div>
          )}
          id="manage-automations"
          loading={listAutomationsFetch.loading}
          rows={rows}
          scrollX={false}
        />
        {!noResults && !listAutomationsFetch.error && (
          <Pagination
            {...mapMetaToPagination(listAutomationsFetch.response?.meta, pagination.per)}
            onPageChange={(currentPage): void => {
              setPagination({ ...pagination, currentPage });
            }}
            onPerChange={(per): void => {
              setPagination({ ...pagination, per });
            }}
          />
        )}
      </div>
    </div>
  );
};
