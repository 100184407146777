import React, { FunctionComponent } from 'react';

import { StandardTabs, BaseTabsProps } from '@components/library/tabs';

import styles from './styles/step_tabs.module.scss';

/**
 * Step tabs
 * @param {BaseTabsProps} props BaseTabsProps
 */
export const StepTabs: FunctionComponent<BaseTabsProps> = (props) => {
  const tabs = props.tabs.map((tab, index) => {
    const t = { ...tab };
    const step = index + 1;
    t.tabButton = (
      <>
        <span className={styles.step}>{step < 10 ? `0${step}` : step}</span>
        {t.tabButton}
      </>
    );
    return t;
  });

  return (
    <StandardTabs {...props} tabs={tabs} />
  );
};
