import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { forwardRef, MutableRefObject } from 'react';

import { StandardSearchFieldProps } from '@components/library/search-field/SearchFieldProps';
import { StandardTextField } from '@components/library/text-field';
import { InputType, TextFieldElement } from '@components/library/text-field/TextFieldTypes';

import styleClasses from './standard-search-field.module.scss';

const baseName = 'search-field';
const variantName = 'standard';

export const StandardSearchField = forwardRef((
  props: StandardSearchFieldProps & TextFieldElement,
  ref: MutableRefObject<HTMLInputElement | HTMLTextAreaElement>
) => {
  const {
    ariaSearchLabel = t`Search`,
    className,
    searchStatus,
    showSearchStatus = false,
    ...remainingProps
  } = props;

  const standardTextFieldProps = {
    ...remainingProps,
    styleClasses,
    variantName,
  };

  return (
    <div
      className={classnames(
        `bf-${baseName}__${variantName}`, // base-variant class for post consumption styling
        styleClasses[`${baseName}__${variantName}`], // class for css modules
        className, // user specified class
        {
          [styleClasses.disabled]: props.disabled,
          [styleClasses['show-search-status']]: showSearchStatus,
        }
      )}
      role="search"
    >
      <StandardTextField
        {...standardTextFieldProps}
        ref={ref}
        id={props.id}
        label={props.label}
        showError={props.showError ?? false}
        showLabel={props.showLabel ?? false}
        type={props.type || InputType.Search}
      />
      <span
        aria-label={ariaSearchLabel}
        className={`${styleClasses['search-icon']} bff-search search-icon`}
        role="img"
      />
      <span
        className={classnames(
          'search-status',
          {
            [styleClasses['search-status']]: showSearchStatus,
            [styleClasses['hide-search-status']]: !showSearchStatus
          }
        )}
        role="status"
      >
        {typeof searchStatus === 'string' ? <p>{searchStatus}</p> : searchStatus}
      </span>
    </div>
  );
});
