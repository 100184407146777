import * as React from 'react';

// simple grid setup view
const CardGrid = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div className="card-grid">
    { children }
  </div>
);

export default CardGrid;
