import {
  DisplayWidths,
  PortalCard,
  PortalCardSubTypes,
  PortalCardTypes,
  ValidationErrorsEnum,
  WidgetResource,
  validationErrorMessages
} from '@api/v4/private/PortalCardTypes';

type ValidationSettings = {
  [key in DisplayWidths]: {
    aspectRatio: number;
    descriptionLength: number;
    nameLength: number;
  }
};

export const validationSettings: ValidationSettings = {
  'one-third': {
    aspectRatio: (300 / 220),
    nameLength: 60,
    descriptionLength: 60,
  },
  'two-thirds': {
    aspectRatio: (630 / 220),
    nameLength: 60,
    descriptionLength: 150,
  },
  full: {
    aspectRatio: (960 / 220),
    nameLength: 100,
    descriptionLength: 250,
  }
};

const validLink = (link: string): boolean => (
  /^https?/i.test(link)
);

interface ValidateNameArguments {
  name: string;
  displayWidth?: DisplayWidths;
}
export const validateName = (
  { name, displayWidth }: ValidateNameArguments,
  cardType?: PortalCardTypes
): ValidationErrorsEnum[] => {
  if (!name) {
    return [ValidationErrorsEnum.MissingName];
  }
  if (name && displayWidth && name.length > validationSettings[displayWidth].nameLength) {
    return [ValidationErrorsEnum.NameLengthExceeded];
  }
  if (name && cardType && [PortalCardTypes.PortalLink].includes(cardType) && name.length > 100) {
    return [ValidationErrorsEnum.NameLengthExceeded];
  }
  if (name && cardType && [PortalCardTypes.PortalWidget].includes(cardType) && name.length > 300) {
    return [ValidationErrorsEnum.NameLengthExceeded];
  }
  return [];
};

interface ValidateDescriptionArguments {
  description: string;
  displayWidth?: DisplayWidths;
}
const validateDescription = ({ description, displayWidth }: ValidateDescriptionArguments): ValidationErrorsEnum[] => {
  if (description && displayWidth && description.length > validationSettings[displayWidth].descriptionLength) {
    return [ValidationErrorsEnum.DescriptionLengthExceeded];
  }
  return [];
};

export const validateButtonText = (
  { buttonText }: { buttonText?: string }
): ValidationErrorsEnum[] => (!buttonText ? [ValidationErrorsEnum.MissingButtonText] : []);

export const validateSrcUrl = ({ srcUrl }: { srcUrl?: string }): ValidationErrorsEnum[] => {
  if (!srcUrl) {
    return [ValidationErrorsEnum.MissingSrcUrl];
  }
  if (!srcUrl.includes('smartsheet.com') && !srcUrl.includes('marketo.com')) {
    return [ValidationErrorsEnum.SrcUrlNotAllowed];
  }
  return [];
};

const validateLink = ({ linkUrl }: { linkUrl: string }, cardType: PortalCardTypes): ValidationErrorsEnum[] => {
  const requiresValidation = [PortalCardTypes.PortalLink, PortalCardTypes.PortalTile].includes(cardType);
  if (requiresValidation && !linkUrl) {
    return [ValidationErrorsEnum.MissingLink];
  }
  if (requiresValidation && !validLink(linkUrl)) {
    return [ValidationErrorsEnum.InvalidLink];
  }
  return [];
};

const validateWidgetResource = (
  { widgetResource }: { widgetResource?: WidgetResource }, cardType: PortalCardTypes
): ValidationErrorsEnum[] => {
  const requiresValidation = [PortalCardTypes.PortalWidget].includes(cardType);
  if (requiresValidation && (!widgetResource || !widgetResource.key || !widgetResource.type)) {
    return [ValidationErrorsEnum.MissingResource];
  }
  return [];
};

const validateSubType = (
  { subType }: { subType?: PortalCardSubTypes }, cardType: PortalCardTypes
): ValidationErrorsEnum[] => {
  const requiresValidation = [PortalCardTypes.PortalWidget].includes(cardType);
  if (requiresValidation && !subType) {
    return [ValidationErrorsEnum.MissingSubType];
  }
  return [];
};

export const validate = (
  cardType: PortalCardTypes, editState: PortalCard, subType?: PortalCardSubTypes | null
): ValidationErrorsEnum[] => {
  const buttonTextValidationErrors = subType === PortalCardSubTypes.EmbeddedFormTile
    ? validateButtonText({ buttonText: editState.buttonText || '' }) : [];
  const srcUrlValidationErrors = subType === PortalCardSubTypes.EmbeddedFormTile
    ? validateSrcUrl({ srcUrl: editState.srcUrl || '' }) : [];
  const linkValidationErrors = subType === PortalCardSubTypes.NavigationTile
    ? validateLink({ linkUrl: editState.linkUrl || '' }, cardType) : [];

  const validationErrors = [
    ...validateName({ name: editState.name, displayWidth: editState.displayWidth }, cardType),
    ...validateDescription({ description: editState.description || '', displayWidth: editState.displayWidth }),
    ...linkValidationErrors,
    ...buttonTextValidationErrors,
    ...srcUrlValidationErrors,
    ...validateWidgetResource({ widgetResource: editState.widgetResource }, cardType),
    ...validateSubType({ subType: editState.subType || undefined }, cardType)
  ];

  return validationErrors;
};

export const handleValidations = (
  cardType: PortalCardTypes,
  setValidationErrors: (errors: ValidationErrorsEnum[]) => void,
  editState: PortalCard,
  subType?: PortalCardSubTypes | null
): boolean => {
  const submissionErrors = validate(cardType, editState, subType);
  if (submissionErrors.length > 0) {
    setValidationErrors(submissionErrors);
    return true;
  }
  setValidationErrors([]);
  return false;
};

export const nameError = (validationErrors: ValidationErrorsEnum[]): string | null => {
  const validationErrorStrings = validationErrorMessages();
  if (validationErrors.includes(ValidationErrorsEnum.MissingName)) {
    return validationErrorStrings[ValidationErrorsEnum.MissingName];
  }
  if (validationErrors.includes(ValidationErrorsEnum.NameLengthExceeded)) {
    return validationErrorStrings[ValidationErrorsEnum.NameLengthExceeded];
  }
  return null;
};

export const linkError = (validationErrors: ValidationErrorsEnum[]): string | null => {
  const validationErrorStrings = validationErrorMessages();
  if (validationErrors.includes(ValidationErrorsEnum.MissingLink)) {
    return validationErrorStrings[ValidationErrorsEnum.MissingLink];
  }
  if (validationErrors.includes(ValidationErrorsEnum.InvalidLink)) {
    return validationErrorStrings[ValidationErrorsEnum.InvalidLink];
  }
  return null;
};

export const descriptionError = (validationErrors: ValidationErrorsEnum[]): string | null => {
  if (validationErrors.includes(ValidationErrorsEnum.DescriptionLengthExceeded)) {
    return validationErrorMessages()[ValidationErrorsEnum.DescriptionLengthExceeded];
  }
  return null;
};

export const widgetResourceError = (validationErrors: ValidationErrorsEnum[]): string | null => {
  if (validationErrors.includes(ValidationErrorsEnum.MissingResource)) {
    return validationErrorMessages()[ValidationErrorsEnum.MissingResource];
  }
  return null;
};

export const subTypeError = (validationErrors: ValidationErrorsEnum[]): string | null => {
  if (validationErrors.includes(ValidationErrorsEnum.MissingSubType)) {
    return validationErrorMessages()[ValidationErrorsEnum.MissingSubType];
  }
  return null;
};

export const buttonTextError = (validationErrors: ValidationErrorsEnum[]): string | null => {
  if (validationErrors.includes(ValidationErrorsEnum.MissingButtonText)) {
    return validationErrorMessages()[ValidationErrorsEnum.MissingButtonText];
  }
  return null;
};

export const srcUrlError = (validationErrors: ValidationErrorsEnum[]): string | null => {
  const validationErrorStrings = validationErrorMessages()
  if (validationErrors.includes(ValidationErrorsEnum.MissingSrcUrl)) {
    return validationErrorStrings[ValidationErrorsEnum.MissingSrcUrl];
  }
  if (validationErrors.includes(ValidationErrorsEnum.SrcUrlNotAllowed)) {
    return validationErrorStrings[ValidationErrorsEnum.SrcUrlNotAllowed];
  }
  return null;
};
