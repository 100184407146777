import getJson from './api_helper';

export const remove = (searchKey) => {
  const url = `/api/search_filters/${searchKey}`;

  return getJson(url, {
    method: 'DELETE'
  });
};

export const add = ({ label, query, resourceKey, resourceType }) => {
  const url = '/api/search_filters';

  return getJson(url, {
    method: 'POST',
    body: {
      search_filter: {
        label,
        query,
      },
      resource_key: resourceKey,
      resource_type: resourceType,
    },
  });
};
