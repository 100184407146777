import { t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import {
  UserOptinClient,
  UserOptinsDeleteResponse,
  UserOptinsListResponse,
  UserOptinsPostResponse
} from '@api/v4/private/userOptins';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { StandardCheckbox } from '@components/library/checkbox';

import classes from './styles/user-optin.module.scss';

export const UserOptin: FunctionComponent = () => {
  const getOptin = useFetch<UserOptinsListResponse>({ url: '/api/v4/private/user_optins?page=1&per=1' });
  const postOptin = useFetch<UserOptinsPostResponse>({ fetchOnMount: false, method: 'POST', url: '/api/v4/private/user_optins' });
  const [userOptin, setUserOptin] = useState<ApiResponseObject<UserOptinClient, 'user_optins'> | null>(null);

  // order is important, this needs to come after userOptin useState
  const deleteOptin = useFetch<UserOptinsDeleteResponse>({ fetchOnMount: false, method: 'DELETE', url: userOptin ? `/api/v4/private/user_optins/${userOptin.id}` : '' });

  useEffect(() => {
    if (deleteOptin.error) {
      Notify.create({
        title: t`Error opting out of marketing notifications. Please try again.`,
        type: 'error'
      });
    }
  }, [deleteOptin.error]);

  useEffect(() => {
    if (deleteOptin.response) {
      setUserOptin(null);
      Notify.create({
        title: t`Successfully opted out of marketing notifications.`,
        type: 'success'
      });
    }
  }, [deleteOptin.response]);

  useEffect(() => {
    if (getOptin.error) {
      Notify.create({
        title: t`Error retrieving your marketing notification setting.`,
        type: 'error'
      });
    }
  }, [getOptin.error]);

  useEffect(() => {
    if (getOptin.response?.data[0]) {
      setUserOptin({
        attributes: {
          createdAt: getOptin.response.data[0].attributes.created_at,
          deletedAt: getOptin.response.data[0].attributes.deleted_at,
          optedIn: getOptin.response.data[0].attributes.opted_in
        },
        id: getOptin.response.data[0].id,
        type: 'user_optins'
      });
    }
  }, [getOptin.response]);

  useEffect(() => {
    if (postOptin.error) {
      Notify.create({
        title: t`Error opting in to marketing notifications. Please try again.`,
        type: 'error'
      });
    }
  }, [postOptin.error]);

  useEffect(() => {
    if (postOptin.response) {
      setUserOptin({
        attributes: {
          createdAt: postOptin.response.data.attributes.created_at,
          deletedAt: postOptin.response.data.attributes.deleted_at,
          optedIn: postOptin.response.data.attributes.opted_in
        },
        id: postOptin.response.data.id,
        type: 'user_optins'
      });
      Notify.create({
        title: t`Successfully opted in to marketing notifications.`,
        type: 'success'
      });
    }
  }, [postOptin.response]);

  const optedIn = userOptin ? userOptin.attributes.optedIn : false;

  return (
    <I18nProviderWrapper>
      <div className={classes.userOptin}>
        <StandardCheckbox
          checked={optedIn}
          disabled={deleteOptin.loading || getOptin.loading || postOptin.loading}
          id="user-optin"
          labelCopy={t`I would like to receive news and information via email`}
          onChange={(): void => {
            if (optedIn) {
              deleteOptin.fetch();
            } else {
              postOptin.fetch();
            }
          }}
        />
      </div>
    </I18nProviderWrapper>
  );
};
