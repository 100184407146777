/* eslint-disable react/require-default-props */
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import MentionableInput from '@components/common/mentions/MentionableInput';
import { manageDropdownListener } from '@helpers/use_effect_callbacks';

const NewComment = ({
  onSubmit,
  versionKey,
  mentionableUsers,
  setMentionableUsers,
  setShowCommentsPostButton,
  showCommentsPostButton
}) => {
  const [commentCopy, setCommentCopy] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState([]);

  const commentInput = useRef();
  const commentContainer = useRef();

  const handleSubmit = () => {
    onSubmit({ versionKey, note: commentCopy, mentionedUsers });
    setCommentCopy('');
    setShowCommentsPostButton(false);
  };

  const dropdownEventListenerCallback = (e) => {
    e.stopPropagation();
    setShowCommentsPostButton(false);
  };

  useEffect(() => {
    if (!commentCopy) {
      return manageDropdownListener(
        commentContainer,
        showCommentsPostButton,
        dropdownEventListenerCallback
      );
    }
    return undefined;
  }, [commentCopy, commentContainer, showCommentsPostButton]);

  return (
    <div
      ref={commentContainer}
      className={`comment-input-container ${showCommentsPostButton ? 'active' : ''}`}
    >
      <MentionableInput
        content={commentCopy}
        htmlAttributes={{
          className: "edit-message",
          onFocus: () => setShowCommentsPostButton(true),
          placeholder: t`Leave a comment`,
        }}
        inputRef={commentInput}
        onChange={(content, ids) => { setCommentCopy(content); setMentionedUsers(ids); }}
        onSubmit={handleSubmit}
        providedData={mentionableUsers}
        setParentMentionableUsers={setMentionableUsers}
      />
      {showCommentsPostButton
        && (
          <button
            className="button primary sm post-button"
            onClick={handleSubmit}
            type="submit"
          >
            <Trans>Post</Trans>
          </button>
        )
      }
    </div>
  );
};

NewComment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  versionKey: PropTypes.string.isRequired,
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  setMentionableUsers: PropTypes.func.isRequired,
  setShowCommentsPostButton: PropTypes.func.isRequired,
  showCommentsPostButton: PropTypes.bool.isRequired
};

NewComment.defaultProps = {
  mentionableUsers: null
};

export default NewComment;
