import React from "react";
import AttachmentBrowser from "./attachment_browser";
import OptionsMenu from "./options_menu";
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import "./styles/adobe_plugin.scss";

export default class Plugin extends React.Component {
  state = {
    selectedResource: null,
    resourceType: '',
    menu_open: true
  };

  selectResource = (resource, resourceType) => {
    this.setState({ selectedResource: resource, resourceType });
    this.toggleMenu();
  };

  toggleMenu = () => {
    this.setState((prevState) => ({ menu_open: !prevState.menu_open }));
  };

  renderPlugin() {
    if (!this.state.menu_open) {
      return (
        <AttachmentBrowser
          jwt={this.props.jwt}
          selectedResource={this.state.selectedResource}
          resourceType={this.state.resourceType}
          toggleMenu={this.toggleMenu}
        />
      );
    }

    return (
      <I18nProviderWrapper>
        <OptionsMenu
          jwt={this.props.jwt}
          selectedResource={this.state.selectedResource}
          resourceType={this.state.resourceType}
          adobe_application={this.props.adobe_application}
          selectResource={this.selectResource}
          toggleMenu={this.toggleMenu}
        />
      </I18nProviderWrapper>
    );
  }

  render() {
    return <div className="adobe-plugin-component">{this.renderPlugin()}</div>;
  }
}
