import fetchJSON from '@api/api_helper';
import { Locale } from '@components/common/language_menu/languagesMap';

const url = (): string => `/api/v4/private/users/${BFG.currentUser.user_id}`;

const setUserStaticSiteLocale = (locale: Locale): Promise<Response> => {
  const appendedOptions = {
    method: 'PUT',
    body: {
      data: {
        attributes: {
          locale
        }
      }
    }
  };
  return fetchJSON(url(), appendedOptions);
};

export default setUserStaticSiteLocale;
