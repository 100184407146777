/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import MentionableInput from '@components/common/mentions/MentionableInput';

const NewReply = ({
  mentionableUsers,
  onReply,
  parentId,
  setMentionableUsers,
  setShowEditMessage,
  showEditMessage,
  versionKey
}) => {
  const [editCopy, setEditCopy] = useState('');
  const [postDisabled, setPostDisabled] = useState(true);
  const [mentionedUsers, setMentionedUsers] = useState([]);

  const replyTextarea = useRef();

  const handleSubmit = () => {
    onReply({ note: editCopy, parent_id: parentId, versionKey, mentionedUsers });
    setEditCopy('');
    setShowEditMessage(false);
  };

  useEffect(() => {
    if (editCopy) {
      setPostDisabled(false);
    } else {
      setPostDisabled(true);
    }
  }, [editCopy]);

  useEffect(() => {
    if (!showEditMessage) {
      setEditCopy('');
    }
  }, [showEditMessage]);

  useEffect(() => {
    if (showEditMessage) {
      replyTextarea.current.focus();
    }
  }, [showEditMessage]);

  return (
    <div className={classnames(
      'edit-message',
      { active: showEditMessage },
      { removeHiddenOverflow: replyTextarea }
    )}
    >
      <div className="edit-message__header">
        <p>
          <Trans>Leave a reply</Trans>
        </p>
        <button
          className="close-reply-button"
          onClick={() => setShowEditMessage((prevState) => (!prevState))}
          type="button"
        >
          <span className="bff-close" />
        </button>
      </div>
      <MentionableInput
        content={editCopy}
        htmlAttributes={{
          className: "edit-message"
        }}
        inputRef={replyTextarea}
        onChange={(content, ids) => { setEditCopy(content); setMentionedUsers(ids); }}
        onSubmit={handleSubmit}
        providedData={mentionableUsers}
        setParentMentionableUsers={setMentionableUsers}
      />
      <button
        className="edit-message__button button primary sm"
        disabled={postDisabled}
        onClick={handleSubmit}
        type="button"
      >
        <Trans>Post</Trans>
      </button>
    </div>
  );
};

NewReply.propTypes = {
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  onReply: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  setMentionableUsers: PropTypes.func.isRequired,
  setShowEditMessage: PropTypes.func.isRequired,
  showEditMessage: PropTypes.bool.isRequired,
  versionKey: PropTypes.string.isRequired,
};

NewReply.defaultProps = {
  mentionableUsers: null
};

export default NewReply;
