import { Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { MAX_PAGE_SIZE, useFetch } from '@api/ApiHelper';
import { CustomFieldValuesListResponse, FlattenedCustomFieldKeyValuesMap } from '@api/v4/assets/customFieldTypes';
import { flattenCustomFieldKeyValuesList } from '@api/v4/assets/helpers';
import { CustomFieldKeysListResponse } from '@api/v4/resources/CustomFieldKeysTypes';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { BFLoader } from '@components/common/loader/main';
import { getResourceIsCollectionOrShareLink } from '@helpers/resource';
import { getCurrentUserIsAnonymousOrGuest } from '@helpers/user';

import { CustomFieldAccordionRow } from './CustomFieldAccordionRow';

interface CustomFieldsAccordionProps {
  count: number;
  assetKey?: string;
  closeModal?: () => void;
  customFieldKeyValuesList?: FlattenedCustomFieldKeyValuesMap;
  loading?: boolean;
}

export const CustomFieldsAccordion: FunctionComponent<CustomFieldsAccordionProps> = (props) => {
  const [showMore, setShowMore] = useState(false);
  const [localCustomFieldKeyValuesList, setLocalCustomFieldKeyValuesList] = useState<FlattenedCustomFieldKeyValuesMap>({});
  const { assetKey, closeModal, count } = props;
  const showCustomFieldsSection = getResourceIsCollectionOrShareLink() && getCurrentUserIsAnonymousOrGuest(BFG.currentUser) ? BFG.showCustomFieldsOverviewTab : true;
  const url = `/api/v4/assets/${assetKey}/custom_field_values`;
  const customFieldValuesFetch = useFetch<CustomFieldValuesListResponse>({
    fetchOnMount: false,
    include: 'custom_field_key',
    params: {
      digest: BFG.manifest?.digest,
      per: MAX_PAGE_SIZE,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ugt_locale: determineUGTLocale()
    },
    url,
  });

  const customFieldKeysFetch = useFetch<CustomFieldKeysListResponse>({
    params: {
      include: 'dependent_custom_fields',
      digest: BFG.manifest?.digest,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ugt_locale: determineUGTLocale(),
    },
    url: `/api/v4/brandfolders/${BFG.resource.key}/custom_field_keys`,
  });

  const customFieldKeyValuesList = localCustomFieldKeyValuesList;
  const loading = props.loading || customFieldValuesFetch.loading || customFieldKeysFetch.loading;

  useEffect(() => {
    if (!props?.customFieldKeyValuesList && showCustomFieldsSection && props.assetKey) {
      customFieldValuesFetch.fetch();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const mapCopy = { ...props.customFieldKeyValuesList };

    if (customFieldKeysFetch.response?.included?.length) {
      customFieldKeysFetch.response.included.forEach(({ attributes }) => {
        const childKey = attributes.child_key;
        const parentKey = attributes.parent_key;
        if (mapCopy[childKey]) {
          mapCopy[parentKey] = {
            ...mapCopy[parentKey],
            dependentFields: [
              ...mapCopy[parentKey]?.dependentFields || [],
              {
                id: mapCopy[childKey].customFieldKey.id,
                name: mapCopy[childKey].customFieldKey.name,
                value: mapCopy[childKey].customFieldValues,
                position: mapCopy[childKey].customFieldKey.position
              }
            ].sort((a, b) => a.position - b.position)
          };
          delete mapCopy[attributes.child_key];
        }
      });
    }
    setLocalCustomFieldKeyValuesList(mapCopy);
  }, [customFieldKeysFetch.response, props.customFieldKeyValuesList]);

  useEffect(() => {
    if (customFieldValuesFetch.response) {
      setLocalCustomFieldKeyValuesList(flattenCustomFieldKeyValuesList(customFieldValuesFetch.response));
    }
  }, [customFieldValuesFetch.response]);

  if (loading) {
    return <BFLoader />;
  }

  if (!Object.keys(customFieldKeyValuesList).length) {
    return null;
  }

  return (
    <>
      {showCustomFieldsSection && (
        <>
          <span className="s-asset-details-title modal-label">
            <Trans>Custom Fields</Trans>
          </span>
          <table className="table s-custom-fields-section">
            <tbody>
              {Object.keys(customFieldKeyValuesList)
                .slice(0, showMore ? undefined : count)
                .map((key) =>
                  <CustomFieldAccordionRow
                    key={key}
                    closeModal={closeModal}
                    customField={customFieldKeyValuesList[key]}
                  />
                )}
              {Object.keys(customFieldKeyValuesList).length > count && (
                <tr
                  className="s-hidden-fields"
                  onClick={(): void => setShowMore((prevState) => !prevState)}
                >
                  <td colSpan={4}>
                    {showMore ? <Trans>Hide Some Fields</Trans> : <Trans>Show All Fields</Trans>}
                    <span
                      aria-hidden="true"
                      className={showMore ? 'bff-close' : 'bff-caret-down'}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};
