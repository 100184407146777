import fetchJSON from '@api/api_helper';

export default (_options = {}) => {
  const options = Object.assign({
    includeFolders: true,
  }, _options);

  const url = '/api/v4/organizations';

  const reqOpts = {
    params: {
      fields: 'cname'
    },
  };

  if (options.includeFolders) {
    reqOpts.params.include = 'brandfolders,portals,brandguides';
  }

  return fetchJSON(url, reqOpts).then((json) => {
    if (!json.data.length || !json.included) { return []; }

    let included = {
      brandfolders: {},
      portals: {},
      brandguides: {}
    };
    json.included.forEach((i) => {
      const { id, attributes, type } = i;
      const subType = {
        ...included[type],
        [id]: {
          id,
          ...attributes,
          type: type.substr(0, type.length - 1),
        }
      };
      included = {
        ...included,
        [type]: subType
      };
    });

    return json.data.map((org) => {
      const { id, attributes } = org;
      const brandfolders = org.relationships?.brandfolders?.data?.map(({ id: bfId }) => ({
        ...included.brandfolders[bfId],
        organization: id
      }));
      const brandguides = org.relationships?.brandguides?.data?.map(({ id: bgId }) => ({
        ...included.brandguides[bgId],
        organization: id
      }));
      const portals = org.relationships?.portals?.data?.map(({ id: pId }) => ({
        ...included.portals[pId],
        organization: id
      }));
      return {
        id,
        ...attributes,
        brandfolders,
        portals,
        brandguides,
        type: 'organization',
      };
    });
  });
};
