import getJSON from '@api/api_helper';

export default ({
  brandfolderKey,
  sectionKeys: section_keys,
  assetKeys: asset_keys,
  labelKeys: label_keys,
  search,
}) => {
  const url = `/api/v4/private/brandfolders/${brandfolderKey}/bulk_assets_info`;
  return getJSON(url, {
    method: 'POST',
    body: {
      asset_keys,
      section_keys,
      label_keys,
      search,
    }
  });
};
