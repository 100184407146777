import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { FontIcons } from '@components/library/icon';
import BaseButton from '../BaseButton';
import styleClassNames from './styles.module.scss';

interface TextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string | FontIcons;
  iconSize?: number;
  isLoading?: boolean;
  loadingCopy?: string;
  size?: ButtonSize;
}

type ButtonSize = 'small' | 'medium' | 'large';

const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>((props, ref) => {
  const baseProps = {
    ...props,
    styleClassNames,
    variantName: 'text',
  };

  return <BaseButton {...baseProps} ref={ref} />;
});

export default TextButton;
