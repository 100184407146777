import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes } from 'react';

import classes from '../styles/dialog_background.module.scss';

interface DialogBackgroundProps extends HTMLAttributes<HTMLDivElement> {
    handleClose: () => void;
}

/**
 * Dialog background is an invisible element to allow closing the dialog on background click
 * @param {DialogBackgroundProps} props DialogBackgroundProps
 */
export const DialogBackground: FunctionComponent<DialogBackgroundProps> = (props) => {
  const { handleClose, id, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={classnames("dialog--background", classes["dialog--background"], divProps.className)}
      data-testid={id ? `${id}-background` : undefined}
      onClick={(): void => handleClose()}
      role="presentation"
    />
  );
};
