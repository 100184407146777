import { fetchJson } from '@api/ApiHelper';
import {
  PressReleasableResource,
  PressReleaseSendOptions,
  PressReleasesGet,
  PressReleasesResponse,
  PressReleaseUpsert,
  SinglePressReleaseResponse,
} from '@components/press_releases';

interface PressReleaseApiUrlOptions {
  resource?: PressReleasableResource;
  pressReleaseKey?: string;
}

/**
 * Helper to get resource or individual scoped press release(s)
 * @param { PressReleaseApiUrlOptions } options
 * @returns { string } string
 */
const getPressReleaseApiUrl = (options: PressReleaseApiUrlOptions): string => {
  const { resource, pressReleaseKey } = options;

  if (pressReleaseKey) {
    return `/api/v4/private/press_releases/${pressReleaseKey}`;
  }

  if (resource?.resourceType && resource.resourceKey) {
    const { resourceType, resourceKey } = resource;
    return `/api/v4/private/${resourceType}s/${resourceKey}/press_releases`;
  }

  throw new Error('no resource or pressReleaseKey provided');
};

/**
 * Index press releases by getting from /api/v4/private/:resource_type/:resource_key/press_releases
 * @param {PressReleasableResource} resource PressReleasableResource
 * @param {PressReleasesGet} options PressReleasesGet
 * @returns {Promise<PressReleasesResponse>} Promise<PressReleasesResponse>
 */
export const listPressReleases = (
  resource: PressReleasableResource,
  options: PressReleasesGet
): Promise<PressReleasesResponse> => {
  let url = getPressReleaseApiUrl({ resource });
  url += options?.per && options?.page ? `?per=${options.per}&page=${options.page}` : '';
  return fetchJson<PressReleasesResponse>({
    url,
    method: 'GET'
  });
};

/**
 * Get individual press release by posting to /api/v4/private/press_releases/:press_release_key
 * @param { string } pressReleaseKey string
 * @returns { Promise<SinglePressReleaseResponse> } Promise<SinglePressReleaseResponse>
 */
export const getPressRelease = (pressReleaseKey: string): Promise<SinglePressReleaseResponse> => {
  const url = `${getPressReleaseApiUrl({ pressReleaseKey })}?fields=brandfolder_user_sources,collection_user_sources,content_html,content_json,from_name`;
  return fetchJson<SinglePressReleaseResponse>({
    url,
    method: 'GET',
  });
};

/**
 * Update individual press release at /api/v4/private/press_releases/:press_release_key
 * @param { string } pressReleaseKey string
 * @param { PressRelease } pressReleaseAttributes PressRelease
 * @param { PressReleaseSendOptions } sendOptions PressReleaseSendOptions
 * @returns { Promise<SinglePressReleaseResponse> } Promise<SinglePressReleaseResponse>
 */
export const updatePressRelease = (
  pressReleaseKey: string,
  pressReleaseAttributes: Omit<PressReleaseUpsert, 'id'>,
  sendOptions?: PressReleaseSendOptions
): Promise<SinglePressReleaseResponse> => {
  const url = getPressReleaseApiUrl({ pressReleaseKey });
  return fetchJson<SinglePressReleaseResponse>({
    url,
    method: 'PUT',
    body: {
      ...sendOptions && sendOptions,
      data: {
        attributes: {
          ...pressReleaseAttributes
        }
      }
    }
  });
};

/**
 * Create press release on resource by posting to /api/v4/private/:resource_type/:resource_key/press_releases
 * @param {PressReleasableResource} resource PressReleasableResource
 * @param {PressRelease} pressReleaseAttributes PressRelease
 * @param { PressReleaseSendOptions } sendOptions PressReleaseSendOptions
 * @returns {Promise<SinglePressReleaseResponse>} Promise<SinglePressReleaseResponse>
 */
export const createPressRelease = (
  resource: PressReleasableResource,
  pressReleaseAttributes: PressReleaseUpsert,
  sendOptions?: PressReleaseSendOptions
): Promise<SinglePressReleaseResponse> => {
  const url = getPressReleaseApiUrl({ resource });
  return fetchJson<SinglePressReleaseResponse>({
    url,
    method: 'POST',
    body: {
      ...sendOptions && sendOptions,
      data: {
        attributes: {
          ...pressReleaseAttributes
        }
      }
    }
  });
};

/**
 * Delete individual press release at /api/v4/private/press_releases/:press_release_key
 * @param { string } pressReleaseKey string
 * @returns { Promise<void> } Promise<void>
 */
export const deletePressRelease = (pressReleaseKey: string): Promise<void> => {
  const url = getPressReleaseApiUrl({ pressReleaseKey });
  return fetchJson<void>({
    url,
    method: 'DELETE'
  });
};
