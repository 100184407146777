/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import downloadAsset from '@api/v3/assets/download';
import { removeFromCollection, removeFromLabel } from '@api/v4/bulk_actions/assets';
import { ActionsDropdown } from '@components/library/dropdown/index';
import { ActionDropdownItem } from '@components/library/dropdown_item/index';
import { WorkspaceEvents } from '@components/workspace/WorkspaceEnums';
import oldType from '@helpers/oldType';
import { Brandfolder, Collection, bulkRefreshSections } from '@helpers/show_page_helpers';

import './styles/_asset_actions_menu.scss';

const AssetActionsMenu = ({
  actionsMenuItemsShown,
  activeLabel,
  asset,
  handleOpenModal,
  handleRemoveSelectedAssetFromSet,
  onVisitAsset,
  section,
  task
}) => {
  const isWorkspace = BFG.hasFeature('workspace') && BFG.resource.is_workspace;
  const isTask = isWorkspace && !!task && task.workspace_key === BFG.resource.key;

  const [closeDropdown, setCloseDropdown] = useState(false);

  // super annoying, but necessary, since the entire
  // asset card is clickable and interferes with us
  const clickHandler = (action) => (
    (name, e) => {
      setCloseDropdown(true);
      e.stopPropagation();
      e.preventDefault();
      action(name, e);
    }
  );

  const onEdit = () => {
    const assetType = oldType(asset.attributes.type);
    const urlBaseString = `${Brandfolder.slug}/sections/${section.key}/ui/${assetType}/${asset.id}?edit=true`;
    const urlParamsString = Collection.slug ? `&collection_slug=${Collection.slug}` : "";
    const url = urlBaseString + urlParamsString;

    const leftArrow = document.getElementsByClassName("left")[0];
    const rightArrow = document.getElementsByClassName("right")[0];
    rightArrow.style.display = "none"; // hide because asset modal left/right arrows don't work when opening asset modal from asset card actions menu
    leftArrow.style.display = "none"; // hide because asset modal left/right arrows don't work when opening asset modal from asset card actions menu

    BF.fullModal = true;
    BF.dialog.render(
      encodeURIComponent(`${asset.attributes.name}`),
      url,
      BF.handlerGroups.showAsset,
      "edit"
    );
  };

  const onShare = () => {
    handleOpenModal('share-modal');
  };

  const onAnalyze = () => {
    const collectionSlug = Collection.key ? `/collections/${Collection.slug}` : "";
    const url = `/insights?page=/insights-app/${Brandfolder.slug}${collectionSlug}/assets/${asset.id} `;
    window.open(url, '_blank');
  };

  const onDelete = () => {
    handleOpenModal('delete-modal');
  };

  const onRemove = async () => {
    await removeFromCollection({
      assetKeys: [asset.id],
      collectionKeys: [BFG.resource.key],
    });
    handleRemoveSelectedAssetFromSet();
    bulkRefreshSections([asset.id]);

    if (isTask) {
      // update the workspace dashbar counts and percentages
      const workspaceUpdatedEvent = new CustomEvent(WorkspaceEvents.WorkspaceUpdated, {
        detail: {
          refresh: true
        }
      });
      window.dispatchEvent(workspaceUpdatedEvent);
    }
  };

  const onUnlabel = async () => {
    await removeFromLabel([asset.id], activeLabel.key);
    bulkRefreshSections([asset.id]);
  };

  const downloadCallback = () => {
    const $downloadIndicator = $('.download_progress_indicator');
    $downloadIndicator.css("visibility", "visible").addClass('active');
    const url = `/api/v3/assets/${asset.id}/download`;
    const collectionKey = BFG.resource?.type === 'collection' ? BFG.resource.key : '';
    downloadAsset(asset.id, { collectionKey, manifest: BFG.manifestDigest }).then((response) => {
      if (response.data && !!response.data.redirect_url) {
        window.location.href = response.data.redirect_url;
        $downloadIndicator.css("visibility", "hidden");
        Notify.create({ title: `Downloaded ${response.data.attachment_count} ${BF.fx.should_pluralize(response.data.attachment_count, 'file')}`, type: "success" });
        Insight.createEvent('downloaded', asset.id, 'asset');
      } else {
        BF.BulkAction.result_poller(url.replace('download', 'bulk-convert-download-poller'), response.data, 500);
      }
    }).catch(() => {
      Notify.create({ title: "Something went wrong. Try again soon!", type: "error" });
      $downloadIndicator.css("visibility", "hidden");
    });
  };

  const onDownload = () => {
    if (BFG.downloadAlertEnabled) {
      BF.fx.dispatchWindowEvent('launchDownloadAlert', null, {
        assetKey: asset.id,
        attachmentKey: undefined,
        downloadCallback
      });
    } else {
      downloadCallback();
    }
  };

  const { canEdit, canShare, canAnalyze, canDelete, canRemove, canUnlabel, canDownload, canOpen } = actionsMenuItemsShown;
  const menuItems = [
    {
      icon: 'bff-download',
      isDisplayed: canDownload,
      name: 'download',
      onChoose: clickHandler(onDownload),
      content: <Trans>Download</Trans>,
    },
    {
      icon: 'bff-show',
      isDisplayed: canOpen,
      name: 'view',
      onChoose: () => onVisitAsset(asset.id),
      content: <Trans>View</Trans>,
    },
    {
      icon: 'bff-edit',
      isDisplayed: canEdit,
      name: 'open-asset-edit',
      onChoose: clickHandler(onEdit),
      content: <Trans>Edit</Trans>,
    },
    {
      icon: 'bff-share',
      isDisplayed: canShare,
      name: 'share',
      onChoose: clickHandler(onShare),
      content: <Trans>Share</Trans>,
    },
    {
      icon: 'bff-insights',
      isDisplayed: canAnalyze,
      name: 'analytics',
      onChoose: clickHandler(onAnalyze),
      content: <Trans>View Analytics</Trans>,
    },
    {
      isDisplayed: canUnlabel,
      name: 'unlabel',
      onChoose: clickHandler(onUnlabel),
      content: activeLabel ? <Trans>Remove from {activeLabel.name}</Trans> : '',
    },
    {
      icon: 'bff-trash',
      isDisplayed: canDelete,
      isWarningItem: true,
      name: 'delete',
      onChoose: clickHandler(onDelete),
      content: <Trans>Delete</Trans>,
    },
    {
      isDisplayed: canRemove,
      name: 'remove',
      onChoose: clickHandler(onRemove),
      content: isWorkspace ? <Trans>Remove from Workspace</Trans> : <Trans>Remove from Collection</Trans>,
    },
  ];

  const actionItems = menuItems.map((action) => (
    <ActionDropdownItem
      key={action.name}
      className={action.className}
      icon={action.icon}
      isDisplayed={action.isDisplayed}
      isWarningItem={action.isWarningItem}
      name={action.name}
      onChoose={action.onChoose}
    >
      {action.content}
    </ActionDropdownItem>
  ));

  return (
    <ActionsDropdown
      className="asset-card-actions"
      closeCallback={() => setCloseDropdown(false)}
      openOnClick
      openOnHover={false}
      toggleDropdown={closeDropdown}
      userDevice={BFG.currentUser.device}
    >
      {actionItems}
    </ActionsDropdown>
  );
};

AssetActionsMenu.propTypes = {
  actionsMenuItemsShown: PropTypes.shape({
    canEdit: PropTypes.bool,
    canShare: PropTypes.bool,
    canAnalyze: PropTypes.bool,
    canDelete: PropTypes.bool,
    canRemove: PropTypes.bool,
    canUnlabel: PropTypes.bool,
    canDownload: PropTypes.bool,
    canOpen: PropTypes.bool,
  }).isRequired,
  activeLabel: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
  asset: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleRemoveSelectedAssetFromSet: PropTypes.func.isRequired,
  section: PropTypes.shape({
    key: PropTypes.string,
  }),
  onVisitAsset: PropTypes.func,
  task: PropTypes.shape({
    workspace_key: PropTypes.string
  }),
};

AssetActionsMenu.defaultProps = {
  activeLabel: null,
  onVisitAsset: () => {},
  section: {},
  task: undefined
};

export default AssetActionsMenu;
