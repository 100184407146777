/* eslint-disable react/no-array-index-key */
import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { SelectableTableProps, TableBreakpoints } from '@components/library/table';
import { Table, TableWrapper, TBody, Td, TFoot, Th, THead, Tr, TrEmpty, TrError, TrLoader, TrSelectable } from './components';

import classes from './styles/selectable-table.module.scss';

export const SelectableTable: FunctionComponent<SelectableTableProps> = (props) => {
  const {
    breakpoints = [TableBreakpoints.tableSm],
    breakpointsOn = true,
    caption,
    columns,
    empty,
    emptyContent,
    error,
    errorContent,
    footer,
    hover = true,
    id,
    loading,
    maxHeight,
    onRowSelect,
    rows,
    scrollY,
    selectable,
    ...otherProps
  } = props;

  const [bodyRows, setBodyRows] = useState<JSX.Element[]>([]);
  const [headRow, setHeadRow] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const ths = columns.map((column, columnIndex) => (
      <Th
        // eslint-disable-next-line react/no-array-index-key
        key={`${id}-th-${columnIndex}`}
        centered={column.centered}
        scope="col"
        style={{
          maxWidth: column.maxWidth,
          minWidth: column.minWidth,
          width: column.width
        }}
        title={column.headingTitle}
      >
        {column.heading}
      </Th>
    ));
    setHeadRow(ths);
  }, [columns]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const trs = selectable ? rows.map((row, rowIndex) => (
      <TrSelectable
        key={`${id}-tr-${rowIndex}`}
        hover={hover}
        onRowSelect={onRowSelect}
        row={row}
      >
        {columns.map((column, columnIndex) => (
          <Td
            key={`${id}-td-${columnIndex}`}
            centered={column.centered}
            ellipsis={column.ellipsis}
            heading={column.heading}
            style={{
              maxWidth: column.maxWidth,
              minWidth: column.minWidth,
              width: column.width
            }}
            title={column.cellTitle}
          >
            {row[column.rowKey]}
          </Td>
        ))}
      </TrSelectable>
    )) : rows.map((row, rowIndex) => (
      <Tr
        key={`${id}-tr-${rowIndex}`}
        hover={hover}
      >
        {columns.map((column, columnIndex) => (
          <Td
            key={`${id}-td-${columnIndex}`}
            centered={column.centered}
            ellipsis={column.ellipsis}
            heading={column.heading}
            style={{
              maxWidth: column.maxWidth,
              minWidth: column.minWidth,
              width: column.width
            }}
            title={column.cellTitle}
          >
            {row[column.rowKey]}
          </Td>
        ))}
      </Tr>
    ));
    setBodyRows(trs);
  }, [columns, onRowSelect, rows, selectable]); // eslint-disable-line react-hooks/exhaustive-deps

  const columnsLength = columns.length;
  const rowsLength = bodyRows.length;

  const breakpointClasses = breakpointsOn && breakpoints ? breakpoints.map((breakpoint) => classes[breakpoint]) : [];

  const table = (
    <Table
      {...otherProps}
      caption={caption}
      captionProps={{
        ...props.captionProps,
        className: classnames(classes.srOnly, props.captionProps?.className)
      }}
      className={classnames(
        breakpointsOn && breakpointClasses.length > 0 && classes.stack,
        breakpointClasses
      )}
      id={id}
    >
      <THead>
        <Tr>
          {headRow}
        </Tr>
      </THead>
      <TBody>
        {(empty || rowsLength === 0) && !loading && (
          <TrEmpty colSpan={columnsLength}>
            {emptyContent}
          </TrEmpty>
        )}
        {error && !loading && (
          <TrError colSpan={columnsLength}>
            {errorContent}
          </TrError>
        )}
        {loading && (
          <TrLoader colSpan={columnsLength} />
        )}
        {!empty && !error && !loading && rowsLength > 0 && bodyRows.map((bodyRow) => bodyRow)}
      </TBody>
      {footer ? (
        <TFoot>
          <Tr>
            <Td colSpan={columnsLength}>
              {footer}
            </Td>
          </Tr>
        </TFoot>
      ) : null}
    </Table>
  );

  const renderedTable = scrollY ? (
    <TableWrapper
      scrollY={scrollY}
      style={{ maxHeight: scrollY ? (maxHeight || '500px') : undefined }}
    >
      {table}
    </TableWrapper>
  ) : table;

  return (
    <>
      {renderedTable}
    </>
  );
};
