export const REUSABLE_EMAIL = '*';

/**
 * Matches at least one @ symbol and at least one period symbol.
 * https://regexr.com/6pr78
 */
export const emailAddressRegex = /^\S+@\S+\.\S+$/;

export const validateEmail = (email: string, allowResuableInvite: boolean): boolean => {
  if (allowResuableInvite && email === REUSABLE_EMAIL) return true;
  return emailAddressRegex.test(email);
};
