import { t, Trans } from '@lingui/macro';
import { Result } from '@uppy/transloadit';
import { PickerResponse } from 'filestack-js/build/main/lib/picker';
import React, { FunctionComponent, ReactNode } from 'react';

import { WatermarkActionOptions } from '@components/bulk_management/automation/AutomationTypes';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { UploadArea } from '@components/common/filestack_uploader/UploadArea';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';
import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { TextWarningButton } from '@components/library/button';
import { FontIcons } from '@components/library/icon';
import { WatermarkPositions, defaultWatermarkPostion } from '@components/shared/watermarks';
import { WatermarkPositionsEnum } from '@components/shared/watermarks/WatermarkPositionTypes';

import classes from './styles/watermark-selector.module.scss';

interface WatermarkSelectorProps {
  updateWatermark: (watermark: WatermarkActionOptions | null) => void;
  watermark: WatermarkActionOptions | null;
  error?: ReactNode;
}

export const WatermarkSelector: FunctionComponent<WatermarkSelectorProps> = ({ error, updateWatermark, watermark }) => {
  const onError = (): void => {
    Notify.create({
      title: t`Error uploading your watermark. Please try again.`,
      type: 'error'
    });
  };

  const updateWatermarkUppy = (files: Result[]): void => {
    if (files.length === 0) {
      onError();
    } else {
      updateWatermark({
        watermark_gravity: watermark ? watermark.watermark_gravity : defaultWatermarkPostion,
        watermark_filename: files[0].name,
        watermark_url: files[0].url
      });
    }
  };

  const updateWatermarkFilestack = (files: PickerResponse): void => {
    if (files.filesUploaded.length === 0) {
      onError();
    } else {
      updateWatermark({
        watermark_gravity: watermark ? watermark.watermark_gravity : defaultWatermarkPostion,
        watermark_filename: files.filesUploaded[0].filename,
        watermark_url: files.filesUploaded[0].url
      });
    }
  };

  const handleWatermarkPosition = (watermarkPosition: WatermarkPositionsEnum): void => {
    updateWatermark({
      watermark_gravity: watermarkPosition,
      watermark_filename: watermark ? watermark.watermark_filename : '',
      watermark_url: watermark ? watermark.watermark_url : ''
    });
  };

  const uploadAreaId = 'watermark-selector';

  const uploadProvider = (): ReactNode => (
    <div className={classes['watermark-selector__uploader']}>
      {BFG.hasFeature('uppy_uploader_features') ? (
        <>
          <div
            className={classes['watermark-selector__uppy']}
            id={uploadAreaId}
          >
            <Trans>Click to upload</Trans>
          </div>
          <UppyUploader
            button
            handleUpload={updateWatermarkUppy}
            restrictions={{
              allowedFileTypes: ['image/*'],
              maxNumberOfFiles: 1
            }}
            template="watermark"
            trigger={`#${uploadAreaId}`}
            uniqIdentifier="uppy-watermark-watermark"
          />
        </>
      ) : (
          <UploadArea
            className={classes['watermark-selector__filestack']}
            id={uploadAreaId}
            onUploadDone={updateWatermarkFilestack}
            pickerOptions={{
              accept: ['image/png'],
              maxFiles: 1,
              onFileUploadFailed: onError
            }}
            uploadType={UploadAreaTypes.Watermark}
          />
      )}
    </div>
  );

  return (
    <section className={classes['watermark-selector']}>
      <div className={classes['watermark-selector__file']}>
        <div className={classes['watermark-selector__header']}>
          <h6 className={classes['watermark-selector__title']}>
            <Trans>Select Watermark Image</Trans>
          </h6>
          {error && (
            <p className={classes['watermark-selector__error']} role="alert">
              {error}
            </p>
          )}
        </div>
        {watermark?.watermark_url
          ? (
            <div className={classes['watermark-selector__preview']}>
              <FilestackPreviewImage
                alt={watermark.watermark_filename || ''}
                className={classes['watermark-selector__image']}
                src={watermark.watermark_url}
              />
              <TextWarningButton
                aria-label={t`Reset watermark`}
                className={classes['watermark-selector__remove']}
                icon={FontIcons.Trash}
                iconSize={20}
                onClick={(): void => {
                  updateWatermark({
                    watermark_gravity: watermark ? watermark.watermark_gravity : defaultWatermarkPostion,
                    watermark_filename: '',
                    watermark_url: ''
                  });
                }}
              />
            </div>
          )
          : uploadProvider()}
      </div>
      <div className={classes['watermark-selector__positions']}>
        <WatermarkPositions
          gravity={watermark?.watermark_gravity || defaultWatermarkPostion}
          onChange={handleWatermarkPosition}
        />
      </div>
    </section>
  );
};
