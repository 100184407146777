import { CustomFieldValue } from '@api/v4/assets/customFieldTypes';
import {
  InitialModalEditFormContentState,
  ModalEditFormContentState,
} from '@components/asset/modal/tabs/edit/EditTabTypes';

export interface CreatedCustomFieldKeyValuePair {
  customFieldKey: {
    name: string;
    id: string;
  };
  customFieldValue: CustomFieldValue;
}

export interface CreatedCustomFieldValuesMap {
  [customFieldKeyId: string]: Array<{
    value: string;
  }>;
}

export interface UpdatedCustomFieldValuesMapEntry {
  value: string;
}
export interface CustomFieldChanges {
  /**
   * users are only able to create keys when controlled custom fields is _disabled_
   * multi-value is not available on uncontrolled custom fields
   * therefore, when we create custom field keys, we will only have one value per key
   */
  createdCustomFieldKeyValuePears?: Array<CreatedCustomFieldKeyValuePair>;
  createdCustomFieldValues?: CreatedCustomFieldValuesMap;
  deletedCustomFieldValueIds?: string[];
  updatedCustomFieldValues?: {
    [customFieldValueId: string]: UpdatedCustomFieldValuesMapEntry;
  };
}

export const getCustomFieldChanges = (
  editState: ModalEditFormContentState,
  initialData: InitialModalEditFormContentState
): CustomFieldChanges => {
  if (!initialData?.flattenedCustomFieldKeyValuesMap || !editState?.flattenedCustomFieldKeyValuesMap) {
    // if there maps aren't initialized it means the user never switched to the custom field tab --> no changes
    return null;
  }
  const changes: CustomFieldChanges = {};
  const editedMap = editState.flattenedCustomFieldKeyValuesMap;
  const initialMap = initialData.flattenedCustomFieldKeyValuesMap;
  const initialCustomFieldKeyIds = Object.keys(initialMap);
  Object.keys(initialMap).forEach((customFieldKeyId) => {
    if (!editedMap[customFieldKeyId]
      || editedMap[customFieldKeyId].customFieldKey.name !== initialMap[customFieldKeyId].customFieldKey.name) {
      throw new Error(`CustomFieldKey ${customFieldKeyId} state does not match between initial and edit state - custom field keys should never be deleted or updated via the edit tab`);
    }

    const editedCustomField = editedMap[customFieldKeyId];
    const initialCustomField = initialMap[customFieldKeyId];
    initialCustomField.customFieldValues.forEach((initialCustomFieldValue) => {
      const matchingCustomFieldValue = editedCustomField.customFieldValues.find((editedCFV) => (
        editedCFV.key === initialCustomFieldValue.key || editedCFV.value === initialCustomFieldValue.value
      ));
      if (!matchingCustomFieldValue || matchingCustomFieldValue.value === '') {
        if (!changes.deletedCustomFieldValueIds) {
          changes.deletedCustomFieldValueIds = [initialCustomFieldValue.key];
        } else {
          changes.deletedCustomFieldValueIds.push(initialCustomFieldValue.key);
        }
        return;
      }

      if (matchingCustomFieldValue.value === initialCustomFieldValue.value) return;

      changes.updatedCustomFieldValues = {
        ...changes.updatedCustomFieldValues,
        [matchingCustomFieldValue.key]: {
          value: matchingCustomFieldValue.value
        }
      };
    });

    const newCustomFieldValues = editedCustomField.customFieldValues.filter((editedCFV) => (
      !initialCustomField.customFieldValues.some((initialCFV) => initialCFV.key === editedCFV.key)
    ));
    if (newCustomFieldValues?.length > 0) {
      changes.createdCustomFieldValues = {
        ...changes.createdCustomFieldValues,
        [customFieldKeyId]: newCustomFieldValues.map((newCFV) => ({ value: newCFV.value }))
      };
    }
  });

  const newCustomFieldKeyIds = [];
  Object.keys(editedMap).forEach((editedCustomFieldKeyId) => {
    if (!initialCustomFieldKeyIds.some((initialKeyId) => initialKeyId === editedCustomFieldKeyId)) {
      if (initialData.customFieldKeys.map((cfk) => cfk.id).includes(editedCustomFieldKeyId)) {
        const newValues = editedMap[editedCustomFieldKeyId].customFieldValues.map((cfv) => ({
          value: cfv.value
        }));
        const newValuesNoEmpty = newValues.filter(({ value }) => value !== '');
        if (newValuesNoEmpty.length > 0) {
          changes.createdCustomFieldValues = {
            ...changes.createdCustomFieldValues,
            // customFieldKeyId wasn't in initialData map so all values must be new
            [editedCustomFieldKeyId]: newValuesNoEmpty
          };
        }
      } else {
        newCustomFieldKeyIds.push(editedCustomFieldKeyId);
      }
    }
  });
  if (newCustomFieldKeyIds?.length > 0) {
    const newPairs: CreatedCustomFieldKeyValuePair[] = [];
    newCustomFieldKeyIds.forEach((newKeyId) => {
      if (!!editedMap[newKeyId].customFieldKey?.name && !!editedMap[newKeyId].customFieldValues?.[0]?.value) {
        newPairs.push({
          customFieldKey: editedMap[newKeyId].customFieldKey,
          customFieldValue: editedMap[newKeyId].customFieldValues?.[0]
        });
      }
    });

    if (newPairs.length > 0) {
      changes.createdCustomFieldKeyValuePears = newPairs;
    }
  }

  return changes;
};
