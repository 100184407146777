import { t } from '@lingui/macro';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import VersionHeader from '@components/asset/modal/tabs/workflow/attachment_details/versions_sidebar/version_header';
import VersionFeed from '@components/asset/modal/tabs/workflow/attachment_details/versions_sidebar/version_feed';
import { postAnnotations } from '@api/v4/resources/versions/annotations';
import { updateAnnotations } from '@components/asset/modal/tabs/workflow/helper';

const sortOptions = () => [
  { label: t`Newest`, value: 'newest', type: 'asc' },
  { label: t`Oldest`, value: 'oldest', type: 'desc' },
  { label: t`Author (A-Z)`, value: 'author_a_z', type: 'asc' },
  { label: t`Author (Z-A)`, value: 'author_z_a', type: 'desc' },
  { label: t`Type`, value: 'type', type: 'asc' },
];
const defaultSorOption = () => sortOptions().find((option) => option.value === 'newest');

const VersionsSidebar = ({
  mentionableUsers,
  setActiveVersion,
  setAnnotationsResponse,
  setMentionableUsers,
  setTempAnnotation,
  users,
  versionCard,
  versionCards,
}) => {
  const [selectedSortOption, setSelectedSortOption] = useState(defaultSorOption());

  const handlePostAnnotations = ({ versionKey, mentionedUsers, note, parent_id }) => (
    postAnnotations({
      versionKey,
      mentionedUsers,
      note,
      params: { fields: 'version,parent' },
      ...parent_id && { parent_id },
    })
      .then((response) => {
        setAnnotationsResponse((prevState) => (updateAnnotations(response, prevState)));
      })
      .catch((err) => {
        console.log(err);
      })
  );

  return (
    <section className="version-sidebar">
      <VersionHeader
        handlePostAnnotations={handlePostAnnotations}
        mentionableUsers={mentionableUsers}
        selectedSortOption={selectedSortOption}
        setActiveVersion={setActiveVersion}
        setMentionableUsers={setMentionableUsers}
        setSelectedSortOption={setSelectedSortOption}
        sortOptions={sortOptions()}
        versionCard={versionCard}
        versionCards={versionCards}
      />
      <ul className="sidebar-content">
        {users && (
          <VersionFeed
            handlePostAnnotations={handlePostAnnotations}
            mentionableUsers={mentionableUsers}
            selectedSortOption={selectedSortOption}
            setAnnotationsResponse={setAnnotationsResponse}
            setMentionableUsers={setMentionableUsers}
            setTempAnnotation={setTempAnnotation}
            users={users}
            versionCard={versionCard}
          />
        )}
      </ul>
    </section>
  );
};

VersionsSidebar.propTypes = {
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  setActiveVersion: PropTypes.func.isRequired,
  setAnnotationsResponse: PropTypes.func.isRequired,
  setMentionableUsers: PropTypes.func.isRequired,
  setTempAnnotation: PropTypes.func.isRequired,
  versionCard: PropTypes.shape({
    type: PropTypes.oneOf(['reservation', 'version'])
  }).isRequired,
  users: PropTypes.shape({}).isRequired,
  versionCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

VersionsSidebar.defaultProps = {
  mentionableUsers: null
};

export default VersionsSidebar;
