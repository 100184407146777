import { ResourceType } from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';

/**
 * Get whether current global resource is a Collection.
 * @returns boolean
 */
export const getCurrentResourceIsCollection = (): boolean => {
  if (BFG.resource.type === 'collection') return true;
  return false;
};

/**
 * Get whether a resource is a Collection.
 * @returns boolean
 */
export const getResourceIsCollection = (resourceType?: ResourceType): boolean => {
  if (!resourceType) {
    return getCurrentResourceIsCollection();
  }

  return resourceType === 'collection' || resourceType === 'subcollection' || resourceType === 'workspace';
};

/**
 * Get whether a resource is a Share Link (aka Share Manifest).
 * @returns boolean
 */
export const getResourceIsShareLink = (): boolean => {
  if (BFG.manifestDigest || BFG.manifest?.key) return true;
  return false;
};

/**
 * Get whether a resource is a Collection or Share Link.
 * @returns boolean
 */
export const getResourceIsCollectionOrShareLink = (): boolean => getResourceIsShareLink() || getResourceIsCollection();
