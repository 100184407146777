import { t, Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";
import { CSSTransition } from 'react-transition-group';

import getSuggestions from '@api/v3/brandfolders/autosuggest_search';
import { PrimaryButton, TertiaryButton } from '@components/library/button/index';
import { ListDropdown } from '@components/library/dropdown/index';
import { searchOptions } from '@components/show_page/sections/section_search/helper';
import SearchActions from '@components/show_page/sections/section_search/search_actions';
import { SearchOperatorOptions } from '@components/show_page/sections/section_search/SearchTypes';
import { UserViewOptionsKeys } from '@components/show_page/sections/view_options/ViewOptionTypes';
import { debounce } from '@helpers/debounce';
import { Brandfolder, Collection } from "@helpers/show_page_helpers";

import ToggleDrawerButton from '../labels/toggle_drawer_button';
import SectionsContext from '../sectionsContext';

import Autocomplete from './autocomplete';
import SearchFilterPinsMenu from './search_filter_pins_menu';

import "./styles/search-container.scss";

class SectionSearch extends React.Component {
  constructor(props) {
    super(props);

    const { searchQuery } = this.props;
    this.state = {
      searchQuery,
      suggestions: [],
      showAutocomplete: false,
    };
  }

  getSearchSuggestions = debounce((searchQuery) => {
    const { brandfolderKey } = this.props;

    const collection = Boolean(Collection.key);

    getSuggestions({
      collection,
      query: searchQuery,
      resourceKey: collection ? Collection.key : brandfolderKey
    }).then((suggestions) => {
      this.setState({
        showAutocomplete: true,
        suggestions
      });
    });
  });

  onQueryChange = (searchQuery) => {
    const oldSearchQuery = this.state.searchQuery;
    const initialSearchQuery = this.props.searchQuery;

    // set searchQuery in state so that the input updates as the user types
    this.setState({
      searchQuery
    }, () => {
      // if going from having a previous searchQuery value to blank
      // submit search with blank value to get all results back
      if (oldSearchQuery !== '' && initialSearchQuery !== '' && searchQuery === '') {
        const { submitSearchQuery } = this.props;
        submitSearchQuery(searchQuery, true);
      } else {
        // else we have a searchQuery, so get suggestions (debounced)
        this.getSearchSuggestions(searchQuery);
      }
    });
  }

  getSearchOptions = () => {
    const isNarrow = (this.props.windowDimensions?.innerWidth ?? window.innerWidth) < 580;
    return searchOptions(isNarrow);
  }

  shareSearch = () => {
    BF.Share.shareResource.call();

    if (Collection.key) {
      BF.fx.dataLayerPush({
        collection: BF.fx.collectionSlug(),
        brandfolder: BF.fx.slug(),
        event: "CollectionShare"
      });
    } else {
      BF.fx.dataLayerPush({
        brandfolder: BF.fx.slug(),
        event: "BrandfolderShare"
      });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { submitSearchQuery } = this.props;
    const { searchQuery, showAutocomplete } = this.state;
    submitSearchQuery(searchQuery, true);
    if (showAutocomplete) {
      this.hideAutoComplete(500);
    }
  }

  hideAutoComplete = (delay = 0) => {
    // use conditional to avoid asnyc when unnecesary (setTimeout will go async even if delay = 0)
    if (delay) {
      setTimeout(() => this.setState({ showAutocomplete: false }), delay);
    } else {
      this.setState({ showAutocomplete: false });
    }
  }

  render() {
    const {
      addRemovePinnedSearch,
      editable,
      loading,
      searchFilters,
      searchSubmitted,
      toggleFiltersDrawer,
      updateSearchQuery,
      updateUserViewOptions,
      userViewOptions,
    } = this.props;

    const {
      searchQuery,
      suggestions,
      showAutocomplete: autocompleteOpen,
    } = this.state;

    const searchDisabled = !searchQuery || searchQuery.length < 1;
    const showAutocomplete = autocompleteOpen && !searchDisabled && suggestions.length > 0 && searchQuery.length > 0;

    // Our friends at Cambro aren't fans of the autocomplete, so we'll
    // just disable it for them to make everyone's lives easier.
    const isCambro = BF.fx.organization().key === 'p824zf-2eqqzc-eaeg8';
    const { searchOperator } = userViewOptions;

    return (
      <SectionsContext.Consumer>
        {({
          submitSearchQuery
        }) => (
          <div
            className="search-container"
            id="showpage-search-container"
          >
            <div className="filter-button-container">
              {BFG.hasFeature("labels") && (
                <ToggleDrawerButton toggleFiltersDrawer={toggleFiltersDrawer}>
                  <span className="bff-label" />
                  <p className="drawer-title"><Trans>Labels</Trans></p>
                </ToggleDrawerButton>
              )}
              {!BFG.hasFeature("disable_advanced_filters") && (
                <TertiaryButton
                  className="search-filter-toggle advanced-filter-toggle"
                  icon="bff-filters"
                  onClick={() => toggleFiltersDrawer('section-search')}
                >
                  <Trans>Filters</Trans>
                </TertiaryButton>
              )}
              {(editable || searchFilters.length > 0) && (
                <SearchFilterPinsMenu
                  editable={editable}
                  searchFilters={searchFilters}
                  submitSearchQuery={submitSearchQuery}
                />
              )}
            </div>
            <div className="search-input-container">
              <ListDropdown
                className="search-options-dropdown"
                id="search-options-dropdown"
                onChange={(option) => updateUserViewOptions(UserViewOptionsKeys.SearchOperator, option.value)}
                options={this.getSearchOptions()}
                value={searchOperator}
                virtualizeOptions={false}
              />
              <div className="input-wrapper">
                <label htmlFor="brandfolderSearch">
                  <span aria-label="hidden" className="bff-search" />
                </label>
                <input
                  autoComplete="off"
                  data-resource-key={Collection.key ? Collection.key : Brandfolder.key}
                  data-resource-type={Collection.key ? t`Collection` : "Brandfolder"}
                  id="brandfolderSearch"
                  maxLength={10000}
                  onBlur={() => this.hideAutoComplete(500)}
                  onChange={({ target }) => this.onQueryChange(target.value)}
                  onKeyUp={(e) => {
                    // onKeyUp is deliberate here, as onKeyDown and onKeyPress will constantly submit and crash the app
                    // when the end users' enter key is held down, stuck, or a cat sat on their keyboard
                    if (e.key === 'Enter') {
                      this.onSubmit(e);
                    }
                  }}
                  placeholder={t`Search`}
                  type="text"
                  value={searchQuery || ""}
                />
                {searchSubmitted && searchQuery?.length > 0 && (
                  <SearchActions
                    addRemovePinnedSearch={addRemovePinnedSearch}
                    editable={editable}
                    searchFilters={searchFilters}
                    searchQuery={searchQuery}
                    shareSearch={this.shareSearch}
                    submitSearchQuery={submitSearchQuery}
                  />
                )}
                {showAutocomplete && !isCambro && (searchOperator !== SearchOperatorOptions.Quoted) && (
                  <Autocomplete
                    onClose={this.hideAutoComplete}
                    onSelect={(search) => updateSearchQuery(null, search)}
                    suggestions={suggestions}
                  />
                )}
              </div>
              <CSSTransition
                classNames="fade-in"
                in={!searchDisabled}
                timeout={300}
                unmountOnExit
              >
                <>
                  <PrimaryButton
                    className="search-button"
                    disabled={searchDisabled}
                    isLoading={loading}
                    onClick={this.onSubmit}
                    size="small"
                    type="submit"
                  >
                    <Trans>Search</Trans>
                  </PrimaryButton>
                  <button
                    className={`button primary sm search-button-mobile ${searchDisabled ? "disabled" : ""}`}
                    disabled={searchDisabled}
                    onClick={this.onSubmit}
                    type="submit"
                  >
                    <span aria-label="hidden" className="bff-search" />
                  </button>
                </>
              </CSSTransition>
            </div>
          </div>
        )}
      </SectionsContext.Consumer>
    );
  }
}

SectionSearch.propTypes = {
  addRemovePinnedSearch: PropTypes.func.isRequired,
  brandfolderKey: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  searchFilters: PropTypes.arrayOf(PropTypes.shape({})),
  searchSubmitted: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
  submitSearchQuery: PropTypes.func.isRequired,
  toggleFiltersDrawer: PropTypes.func.isRequired,
  updateSearchQuery: PropTypes.func.isRequired,
  updateUserViewOptions: PropTypes.func.isRequired,
  userViewOptions: PropTypes.shape({
    searchOperator: PropTypes.string
  }).isRequired,
  windowDimensions: PropTypes.shape({
    innerHeight: PropTypes.number,
    innerWidth: PropTypes.number,
  })
};

SectionSearch.defaultProps = {
  searchFilters: [],
  searchQuery: '',
  windowDimensions: {},
};

export default SectionSearch;
