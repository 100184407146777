import React, { FunctionComponent } from 'react';
import { ViewOnlyIcon } from '@components/view-only/ViewOnlyIcon';

interface AssetBoardCardDescriptionProps {
  leftText: string;
  pillStyling: string;
  rightText: string;
  viewOnly?: boolean;
}

const AssetBoardCardDescription: FunctionComponent<AssetBoardCardDescriptionProps> = (props) => {
  const { leftText, pillStyling, rightText, viewOnly } = props;

  return (
    <div className="description-container">
      <span className="left-text">{leftText}</span>
      <span className="right-text">
        <span className={pillStyling}>{rightText}</span>
      </span>
      {viewOnly && (
        <ViewOnlyIcon />
      )}
    </div>
  );
};

export default AssetBoardCardDescription;
