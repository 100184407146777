import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { sortByPosition } from '@api/v4/assets/helpers';
import { getCustomFieldKeys } from '@api/v4/resources/custom_fields';
import { CustomFieldKeyClient } from '@api/v4/resources/CustomFieldKeysTypes';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { BFLoader } from '@components/common/loader/main';
import { CustomFieldsForm } from '@components/settings/brandfolder/custom-fields-tab/CustomFieldsForm';
import { DisplayedCustomFieldKey } from '@components/settings/brandfolder/custom-fields-tab/CustomFieldsFormTypes';
import { isGettyClient } from '@helpers/getty-strings';

interface CustomFieldsTabProps {
  brandfolderKey: string;
  controlledCustomFieldsEnabled: boolean;
  hasCustomFieldsFeature: boolean;
  brandfolderName?: string;
  libraryName?: string;
}

type UpdateControlledCustomFieldsToggledEvent = Event & { detail: { toggled: boolean } };

export const CustomFieldsTab: FunctionComponent<CustomFieldsTabProps> = ({
  brandfolderKey,
  brandfolderName,
  controlledCustomFieldsEnabled,
  hasCustomFieldsFeature,
  libraryName
}) => {
  const [controlledCustomFieldsToggled, setControlledCustomFieldsToggled] = useState(controlledCustomFieldsEnabled);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState<DisplayedCustomFieldKey[]>([]);

  const params = {
    fields: 'multi_value_enabled,multi_value_peristed,required',
    include: 'dependent_custom_fields',
    per: 3000,
    order: 'asc',
    sort_by: 'name',
    ugt_locale: determineUGTLocale()
  };

  const getCustomFieldKeysOptions = {
    params,
    resourceKey: brandfolderKey,
    resourceType: 'brandfolder',
  };

  const fetchCustomFieldKeys = async (): Promise<void> => {
    try {
      const response = await getCustomFieldKeys(getCustomFieldKeysOptions);
      const customFieldKeysData: CustomFieldKeyClient[] = sortByPosition(response.data)?.map((key) => ({
        allowedValues: key.attributes.allowed_values,
        key: key.id,
        multiValueEnabled: key.attributes.multi_value_enabled || false,
        name: key.attributes.name,
        position: key.attributes.position,
        required: key.attributes.required || false
      }));

      setRows(customFieldKeysData);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateControlledCustomFieldsToggle = (e: UpdateControlledCustomFieldsToggledEvent): void => setControlledCustomFieldsToggled(e.detail.toggled);

  useEffect(() => {
    window.addEventListener('controlledCustomFieldsToggled', handleUpdateControlledCustomFieldsToggle);
    fetchCustomFieldKeys();

    return (): void => {
      window.removeEventListener('controlledCustomFieldsToggled', handleUpdateControlledCustomFieldsToggle);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showForm = !isLoading && hasCustomFieldsFeature && controlledCustomFieldsToggled;

  const showControlledCustomFieldsDisabledMessage = !isLoading && hasCustomFieldsFeature && !controlledCustomFieldsToggled;
  const showCustomFieldsFeatureDisabledMessage = !isLoading && !hasCustomFieldsFeature;

  return (
    <I18nProviderWrapper>
      <div className="brandfolder-settings__control-custom-fields">
        {isLoading && <BFLoader />}
        {showForm && (
          <>
            <CustomFieldsForm
              brandfolderKey={brandfolderKey}
              fetchCustomFieldKeys={fetchCustomFieldKeys}
              rows={rows}
              setRows={setRows}
            />
            <p className="custom-field-mobile-instructions">
              <Trans>To access Custom Field settings, please visit your ${libraryName} on a larger screen.</Trans>
            </p>
          </>
        )}
        {showControlledCustomFieldsDisabledMessage && (
          <>
            <h2 className="brandfolder-settings__control-custom-fields--title">
              <Trans>Custom Fields Settings</Trans>
            </h2>
            <p
              dangerouslySetInnerHTML={{ __html: t`No settings available because Controlled Custom Fields are <span class="bold-font">disabled</span>. <br/>Admins and Collaborators can input their own keys and values on each asset.` }}
              className="brandfolder-settings__control-custom-fields--messaging"
            />
            <p
              className="brandfolder-settings__control-custom-fields--messaging"
            >
              <Trans>To enable settings for your Custom Fields, visit the Advanced tab and turn on the "Control Custom Fields" setting.</Trans>
            </p>
            <div className="brandfolder-settings__control-custom-fields--example">
              <div className="flex-half">
                <h3 className="brandfolder-settings__control-custom-fields--subtitle"><Trans>What are Custom Fields?</Trans></h3>
                <p className="brandfolder-settings__control-custom-fields--messaging"><Trans>Custom Fields provide a controlled taxonomy tool by adding key/value pairs to your assets. By enabling Controlled Custom Fields, Admins can specify keys and value options to apply to assets.</Trans></p>
                <p className="brandfolder-settings__control-custom-fields--messaging"><Trans>It is recommended to enable Controlled Custom Fields.</Trans></p>
              </div>
              <div className="flex-half">
                <p className="italic-font"><Trans>Example Key/Value Custom Field pairing</Trans></p>
                <div>
                  <img
                    alt={t`example of custom fields key value pair`}
                    className="brandfolder-settings__control-custom-fields--example-image"
                    src="https://cdn.bfldr.com/27C9EC93/at/x4s8tc5xnb6w85n3wqfjx866/custom-fields-example?format=jpg&auto=webp&dpr=2"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {showCustomFieldsFeatureDisabledMessage && (
          <>
            <h2 className="brandfolder-settings__control-custom-fields--title">
              <Trans>Custom Fields Settings</Trans>
            </h2>
            <p className="brandfolder-settings__control-custom-fields--messaging">
              <Trans>No settings available because your subscription doesn't support Custom Fields.</Trans>
            </p>
            <div className="brandfolder-settings__control-custom-fields--example">
              <div className="flex-half">
                <h3 className="brandfolder-settings__control-custom-fields--subtitle">
                  <Trans>What are Custom Fields?</Trans>
                </h3>
                <Trans comment="list">
                  <p className="brandfolder-settings__control-custom-fields--messaging">
                    Custom Fields provide a controlled taxonomy tool by adding key/value pairs to your assets. They help:
                  </p>
                  <ul className="brandfolder-settings__control-custom-fields--list">
                    <li><span className="bff-confirmation" />Classify and define your assets</li>
                    <li><span className="bff-confirmation" />Categorize assets</li>
                    <li><span className="bff-confirmation" />Improve searchability</li>
                  </ul>
                </Trans>
                <a
                  className="brandfolder-settings__control-custom-fields--cta"
                  href={`mailto:${BFG.supportEmail}?subject=Upgrade Inquiry&body=I'd like to inquire about adding the Custom Fields feature.`}
                >
                  {isGettyClient()
                    ? <Trans>Inquire about adding the Custom Fields feature to the ${brandfolderName} Library.</Trans>
                    : <Trans>Inquire about adding the Custom Fields feature to the ${brandfolderName} Brandfolder.</Trans>
                  }
                </a>
              </div>
              <div className="flex-half">
                <p className="italic-font"><Trans>Example Key/Value Custom Field pairing</Trans></p>
                <div>
                  <img
                    alt={t`example of custom fields key value pair`}
                    className="brandfolder-settings__control-custom-fields--example-image"
                    src="https://cdn.bfldr.com/27C9EC93/at/x4s8tc5xnb6w85n3wqfjx866/custom-fields-example?format=jpg&auto=webp&dpr=2"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </I18nProviderWrapper>
  );
};
