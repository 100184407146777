import fetchJSON from '@api/api_helper';
import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { AssetTypes } from '@api/v4/assets/assetTypes';
import { PostMessageDataReceived } from '@components/smartsheet/publish/PublishTypes';

import { snakeifyKeys } from '@helpers/humanize';

interface PublishAttributes {
  approved: boolean;
  best_link_for: string;
  description: string;
  name: string;
  thumbnail_url: string;
}

interface PublishAssetOptions {
  brandfolderKey: string;
  data: PostMessageDataReceived;
  sectionKey: string;
}

export type PublishResponseJSON = ApiDatumResponse<PublishAttributes, AssetTypes.GenericFile>
export interface PublishResponse {
  json: PublishResponseJSON;
  response: Response;
}

export const publishAsset = ({
  brandfolderKey,
  data,
  sectionKey,
}: PublishAssetOptions): Promise<PublishResponse> => (
  fetchJSON(
    '/api/v4/private/smartsheet/publish',
    {
      body: {
        brandfolder_key: brandfolderKey,
        data: snakeifyKeys(data),
        section_key: sectionKey,
      },
      method: 'POST',
      params: { fields: 'best_link_for' },
    },
    null,
    true, // get full response so we can read statuses
  )
    .then((response: PublishResponse) => response)
    .catch((response) => { throw response; })
);
