import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface AllowCollectionGuestAssetCroppingProps {
  defaultEnabled: boolean | null;
}

const AllowCollectionGuestAssetCropping: FunctionComponent<AllowCollectionGuestAssetCroppingProps> = ({
  defaultEnabled
}) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled === null ? true : defaultEnabled}
      errorTitle={t`Error updating allow Collection guests to crop assets. Please contact support.`}
      id="allow-collection-guest-asset-cropping"
      successTitle={t`Allow Collection guests to crop assets updated!`}
      title={t`Allow Collection guests to crop assets`}
      updateResourceKey="allowCollectionGuestAssetCropping"
    />
  </I18nProviderWrapper>
);

export default AllowCollectionGuestAssetCropping;
