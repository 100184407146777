import { sanitize } from '@brandfolder/utilities';
import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { getPortalCards } from '@api/v4/private/portal_cards';
import { BackendPortalCard, PortalCardTypes } from '@api/v4/private/PortalCardTypes';
import { getPortal } from '@api/v4/private/portals';
import { Portal } from '@api/v4/private/PortalTypes';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import HeaderBackground from './portal/header_background';
import LinkEmptyTemplate from './portal_links/link_empty_template';
import LinkSidebar from './portal_links/link_sidebar';
import Tiles from './portal_tiles/tiles';
import TilesEmptyTemplate from './portal_tiles/tiles_empty_template';
import WidgetEmptyTemplate from './portal_widgets/widget_empty_template';
import Widgets from './portal_widgets/widgets';

import './styles/main.scss';

interface PortalViewProps {
  admin: boolean;
  libraryName: string;
}

const PortalView: React.FunctionComponent<PortalViewProps> = ({ admin, libraryName }): JSX.Element => {
  const [editMode, setEditMode] = useState(false);
  const [portal, setPortal] = useState<Portal>({ name: '' });
  const [portalCards, setPortalCards] = useState<ApiResponseObject<BackendPortalCard>[] | null>(null);
  const [refreshCardsTrigger, setRefreshCardsTrigger] = useState(false);
  const toggleEditMode = (): void => {
    if (admin) {
      setEditMode((prevState) => !prevState);
    }
  };
  const triggerCardsRefresh = (): void => setRefreshCardsTrigger((prevState) => !prevState);
  const portalId = (BFG.resource.type === 'portal') ? BFG.resource.key : null;

  const sortedCards = (type: PortalCardTypes): ApiResponseObject<BackendPortalCard>[] => {
    if (!portalCards) { return []; }
    return portalCards.filter((card) => card.attributes.type === type)
      .sort((a, b) => (a.attributes.position !== undefined && b.attributes.position !== undefined && a.attributes.position > b.attributes.position ? 1 : -1));
  };

  const tiles = sortedCards(PortalCardTypes.PortalTile);
  const links = sortedCards(PortalCardTypes.PortalLink);
  const widgets = sortedCards(PortalCardTypes.PortalWidget);

  useEffect(() => {
    if (portalId) {
      const portalParams = {
        params: {
          fields: 'description,brand_hex_color,header_url,header_hex_color,header_name_overlay,search_url'
        }
      };
      getPortal(portalId, portalParams)
        .then((response) => {
          const { attributes } = response.data;
          const retrievedPortal = {
            name: attributes.name,
            brandHexColor: attributes.brand_hex_color,
            description: attributes.description,
            headerHexColor: attributes.header_hex_color,
            headerNameOverlay: attributes.header_name_overlay,
            headerUrl: attributes.header_url,
            privacy: attributes.privacy,
            searchUrl: attributes.search_url,
          };
          setPortal(retrievedPortal);
        });
    }
  }, [portalId]);

  useEffect(() => {
    getPortalCards({ portalId })
      .then((response) => {
        setPortalCards(response.data);
        if (admin && response.data.length === 0) {
          // default to edit mode to help admins understand capabilities
          setEditMode(true);
        }
      });
  }, [refreshCardsTrigger, portalId, admin]);

  return (
    <I18nProviderWrapper>
      <DndProvider key="tiles" backend={Backend}>
        <div className="portal">
          <HeaderBackground
            admin={admin}
            editMode={editMode}
            headerHexColor={portal.headerHexColor}
            headerNameOverlay={portal.headerNameOverlay}
            libraryName={libraryName}
            portal={portal}
            toggleEditMode={toggleEditMode}
          />
          <div className="portal__main">
            <div
              // eslint-disable-next-line @typescript-eslint/naming-convention, react/no-danger
              dangerouslySetInnerHTML={{ __html: sanitize(portal.description) }}
              className="portal__main--description"
            />
            <div className="portal__main--container">
              {(admin && portalCards && !tiles.length) ? (
                <TilesEmptyTemplate
                  triggerCardsRefresh={triggerCardsRefresh}
                />
              ) : (
                <Tiles
                  editMode={editMode}
                  tiles={tiles}
                  triggerCardsRefresh={triggerCardsRefresh}
                />
              )}
              <div className="portal-links-container">
                {(editMode || (admin && portalCards && !links.length))
                  && (
                    <LinkEmptyTemplate
                      triggerCardsRefresh={triggerCardsRefresh}
                    />
                  )}
                {links.length > 0 && (
                  <LinkSidebar
                    editMode={editMode}
                    links={links}
                    tiles={tiles}
                    triggerCardsRefresh={triggerCardsRefresh}
                  />
                )}
              </div>
            </div>
            {(admin && portalCards && !widgets.length)
              ? (
                <WidgetEmptyTemplate
                  brandHexColor={portal.brandHexColor}
                  triggerCardsRefresh={triggerCardsRefresh}
                />
              ) : (
                <Widgets
                  brandHexColor={portal.brandHexColor}
                  editMode={editMode}
                  libraryName={libraryName}
                  triggerCardsRefresh={triggerCardsRefresh}
                  widgets={widgets}
                />
              )}
          </div>
        </div>
      </DndProvider>
    </I18nProviderWrapper>
  );
};

export default PortalView;
