import fetchJSON from '@api/api_helper';

const getUrl = (assetKey) => `/api/v4/brandfolders/${assetKey}/share_manifests`;

export const post = (options, updateFetchControllers) => {
  const appendedOptions = {
    method: 'POST',
    body: {
      data: {
        attributes: {
          asset_keys: [options.assetKey],
          availability_end: null,
          expires: false,
          internal: false,
          name: `Print Request: ${options.assetName}`,
          require_identification: false,
        },
      },
    },
  };

  return fetchJSON(getUrl(options.brandfolderKey), appendedOptions, updateFetchControllers);
};
