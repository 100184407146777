import fetchJSON from '@api/api_helper';

const getUrl = (attachmentKey) => `/api/v4/attachments/${attachmentKey}/versions`;
const getrollbackUrl = (versionKey) => `/api/v4/versions/${versionKey}/rollback`;

const processResponse = (response) => {
  const history = !response.data.length ? [] : response.data.map((version) => {
    const { item, user } = version.relationships;
    return {
      id: version.id,
      attachment: item.data.id,
      author: user && user.data ? user.data.id : null,
      type: "version",
      ...version.attributes,
    };
  });

  const users = {};
  if (response.included.length) {
    response.included.forEach((includedItem) => {
      if (includedItem.type === 'users') {
        users[includedItem.id] = { ...includedItem.attributes };
      }
    });
  }

  return { history, users };
};

export default (options, updateFetchControllers) => (
  fetchJSON(getUrl(options.attachmentKey), options, updateFetchControllers)
    .then((response) => (processResponse(response)))
);

export const postVersions = (options, updateFetchControllers) => {
  const url = getUrl(options.attachmentKey);
  const data = {
    status: options.updatedStatus,
    note: options.checkinCheckoutNote
  };
  const appendedOptions = {
    body: { data: { attributes: data } },
    method: 'POST'
  };

  if (options.params) {
    appendedOptions.params = options.params;
  }

  return fetchJSON(url, appendedOptions, updateFetchControllers);
};

export const rollbackVersions = (options, updateFetchControllers) => {
  const appendedOptions = { method: 'POST' };

  return fetchJSON(getrollbackUrl(options.versionKey), appendedOptions, updateFetchControllers);
};
