import { SearchTextfield, StandardCombobox } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useRef } from 'react';

import {
  sortedNames,
  TagCountResponse,
  TagSortByOptions
} from '@components/bulk_management/tags/helpers';
import {
  BulkCustomFieldsLanguageMenuDropdown
} from '@components/bulk_management/user_generated_translations/BulkManagementLanguageMenuDropdown';
import { Locale } from '@components/common/language_menu/languagesMap';

interface TagsSortingOptionsProps {
  queryString: string;
  resetSearch: boolean;
  setQueryString: SetStateDispatch<string>;
  setResetSearch: SetStateDispatch<boolean>;
  setSortBy: SetStateDispatch<TagSortByOptions>;
  setSortedTagNames: SetStateDispatch<string[]>;
  setUgtLocale: SetStateDispatch<Locale>;
  sortBy: TagSortByOptions;
  tags: TagCountResponse;
  ugtLocale: Locale;
}

export const TagsSortingOptions: FunctionComponent<TagsSortingOptionsProps> = ({
  queryString,
  resetSearch,
  setQueryString,
  setResetSearch,
  setSortBy,
  setSortedTagNames,
  setUgtLocale,
  sortBy,
  tags,
  ugtLocale
}) => {
  const searchRef = useRef(null);
  const tagCount = !!tags ? Object.keys(tags).length : 0;

  useEffect(() => {
    if (tags) {
      setSortedTagNames(sortedNames(tags, queryString, sortBy));
    } else {
      setSortedTagNames(null);
    }
  }, [queryString, setSortedTagNames, sortBy, tags]);

  useEffect(() => {
    if (resetSearch) {
      searchRef.current?.focus();
      setResetSearch(false);
    }
  }, [resetSearch, setResetSearch]);

  return (
    <div className="sorting-options">
      <SearchTextfield
        ref={searchRef}
        id="tag-search"
        label={<Trans>Search tags</Trans>}
        onChange={(e): void => setQueryString(e.target.value)}
        placeholder={t`Search ${tagCount} tags`}
        showLabel={false}
        value={queryString}
      />
      <div className="sorting-options-container-right">
        <BulkCustomFieldsLanguageMenuDropdown
          setUgtLocale={setUgtLocale}
          ugtLocale={ugtLocale}
        />
        <div className="sort-options">
          <StandardCombobox
            id={'bulk-tags-sort-options'}
            initialSelectedIndex={0}
            labels={{
              iconButtonLabel: t`Toggle list of tag sorting options`,
              label: <Trans>Sort by</Trans>,
              listboxLabel: t`List of tag sorting options`
            }}
            onSelection={(_option, _index, value): void => {
              setSortBy(value as TagSortByOptions);
            }}
            options={[
              { children: <Trans>Name</Trans>, key: 'name', value: 'name' },
              { children: <Trans>Asset Count</Trans>, key: 'assetCount', value: 'assetCount' }
            ]}
          />
        </div>
      </div>
    </div>
  );
};
