import React, { useState } from "react";
import PropTypes from "prop-types";

function WatermarkGrid(props) {
  const [selectedPosition, updateSelectedPosition] = useState(4);

  const positionMap = {
    0: [0, 0],
    1: [0.5, 0],
    2: [1, 0],
    3: [0, 0.5],
    4: [0.5, 0.5],
    5: [1, 0.5],
    6: [0, 1],
    7: [0.5, 1],
    8: [1, 1]
  };

  const updateWatermarkPosition = (position) => {
    const [x, y] = positionMap[position];
    updateSelectedPosition(position);
    props.updateWatermarkX(x);
    props.updateWatermarkY(y);
  };

  /* eslint-disable react/no-array-index-key */
  const gridBoxes = () => (
    [...Array(9)].map((_, i) => (
      <button
        key={`gridbox-${i}`}
        value={i}
        className={`gridbox ${selectedPosition === i ? "selected" : ""}`}
        onClick={(e) => { updateWatermarkPosition(e.target.getAttribute("value")); }}
        type="button"
      />
    ))
  );
  /* eslint-enable react/no-array-index-key */

  return (
    <div className="watermark-grid">
      {gridBoxes()}
    </div>
  );
}

WatermarkGrid.propTypes = {
  updateWatermarkX: PropTypes.func.isRequired,
  updateWatermarkY: PropTypes.func.isRequired,
  attachment: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }).isRequired
};

export default WatermarkGrid;
