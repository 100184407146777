import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import { BaseDialogProps } from '@components/library/dialog';
import { BaseDialog } from '@components/library/dialog/base_dialog';

import classes from './form_dialog.module.scss';

type BaseDialogPropsExtended = Omit<BaseDialogProps, 'actions' | 'position' | 'showFooter' | 'type'>

interface FormDialogProps extends BaseDialogPropsExtended {
    children: ReactNode;
}

/**
 * A dialog for forms (defaults to a modal). Children React elements should include your form, inputs, and buttons.
 * @param {FormDialogProps} props FormDialogProps
 */
export const FormDialog: FunctionComponent<FormDialogProps> = (props) => {
  const { children, ...baseProps } = props;

  return (
    <BaseDialog
      {...baseProps}
      dialogClassName={classnames(classes["form-dialog"], baseProps.dialogClassName)}
      showFooter={false}
    >
      {children}
    </BaseDialog>
  );
};
