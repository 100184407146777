import { t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { ContentAutomationGetResponse } from '@api/v4/assets/contentAutomations';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

export interface RenderEditorProps {
  digest: string;
}

const getAssetKeyFromUrl = (): string => {
  const url = window.location.href;
  return url ? new URL(url).pathname.split('/').pop() : '';
};

const buildUrl = (digest: string): string => {
  const digestString = digest ? `?digest=${digest}` : '';
  return `/api/v4/assets/${getAssetKeyFromUrl()}/content_automations${digestString}`;
};

const showError = (): void => {
  Notify.create({
    title: t`There was an error fetching the template. Please try again.`,
    type: 'error'
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const RenderEditor: FunctionComponent<RenderEditorProps> = ({ digest }) => {
  const [templateEditorUrl, setTemplateEditorUrl] = useState('');

  const getTemplateEditorUrl = useFetch<ContentAutomationGetResponse>({
    url: buildUrl(digest),
  });

  useEffect(() => {
    if (getTemplateEditorUrl.response) {
      setTemplateEditorUrl(
        getTemplateEditorUrl.response.data.attributes.template_editor_url
      );
    }
  }, [getTemplateEditorUrl.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (getTemplateEditorUrl.error) {
      showError();
    }
  }, [getTemplateEditorUrl.error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    templateEditorUrl && (
      <I18nProviderWrapper>
        <iframe className="renderedEditor" src={templateEditorUrl} title={t`Content Automation`}></iframe>
      </I18nProviderWrapper>
    )
  );
};
