import React, { FunctionComponent, useEffect, useState } from 'react';
import { get as getPrinterSettings } from '@api/v4/organizations/printer_settings';
import ThumbnailOverrideComponent from './ThumbnailOverride';
import Watermark from './Watermark';
import ThreeSixtyViewer from './ThreeSixyViewer';
import AssetBackgroundColor from './AssetBackgroundColor';
import GelatoProductUid from './GelatoProductUid';
import './styles/_advanced.scss';

interface AdvancedSectionProps {
  includeAdvancedOptions: boolean;
  isTask?: boolean;
}

const AdvancedSection: FunctionComponent<AdvancedSectionProps> = ({
  includeAdvancedOptions,
  isTask
}) => {
  const [showGelato, setShowGelato] = useState(false);

  useEffect(() => {
    const getOrganizationPrinterSettings = async (): Promise<void> => {
      try {
        const printerSettings = await getPrinterSettings({ organizationKey: BFG.organization_key });
        setShowGelato(printerSettings?.[0]?.attributes?.is_gelato && printerSettings?.[0]?.attributes?.gelato_branded_store_url != "" ? true : false);
      } catch (err) {
        // do nothing and don't show Gelato
      }
    };
    getOrganizationPrinterSettings();
  }, []);

  return (
    <div className="asset-advanced">
      <div className="m-asset-advanced-container">
        <ThumbnailOverrideComponent isTask={isTask} />
        {includeAdvancedOptions && (
          <>
            {BFG.hasFeature('legacy_watermarking') && <Watermark />}
            <ThreeSixtyViewer />
            <AssetBackgroundColor isTask={isTask} />
            {BFG.hasFeature('web_to_print') && showGelato && <GelatoProductUid />}
          </>
        )}
      </div>
    </div>
  );
};

export default AdvancedSection;
