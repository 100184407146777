// types
import type { BaseTab, BaseTabsProps } from './TabsProps';

export type { BaseTab, BaseTabsProps };

// components
export { StandardTabs } from './standard_tabs';
export { StepTabs } from './steps_tabs';

// helpers
export { getElementIsTab } from './helpers';
