import { fetchJson } from '@api/ApiHelper';
import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { CustomFieldKeyServer } from '@api/v4/resources/CustomFieldKeysTypes';
import { Locale } from '@components/common/language_menu/languagesMap';

interface UpdateCustomFieldKeysOptions {
  attributes: Partial<CustomFieldKeyServer>;
  customFieldKey: string;
  ugtLocale?: Locale | '';
}

const getUrl = (customFieldKey: string): string => `/api/v4/custom_field_keys/${customFieldKey}`;

export const updateCustomFieldKeys = ({
  attributes,
  customFieldKey,
  ugtLocale
}: UpdateCustomFieldKeysOptions): Promise<ApiDatumResponse<CustomFieldKeyServer, 'custom_field_keys'>> => {
  const url = getUrl(customFieldKey);
  return fetchJson({
    body: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ugt_locale: ugtLocale || undefined,
      data: {
        attributes
      }
    },
    method: 'PATCH',
    url
  });
};

export const deleteCustomFieldKey = ({ customFieldKey }): Promise<unknown> => (
  fetchJson({
    method: 'DELETE',
    url: getUrl(customFieldKey),
  })
);
