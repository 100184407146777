import * as React from 'react';
import BaseInput from './BaseInput';
import { InputProps, InputType, InputAttributes } from './Input.props';

import './primary_styles.scss';

const defaultAttributeProps: Partial<InputAttributes> = {
  type: InputType.Text
};

const Input = (props: InputProps): JSX.Element => {
  const fullProps = {
    ...props,
    attributes: {
      ...defaultAttributeProps,
      ...props.attributes,
      name: props.attributes?.name || ''
    }
  };
  const {
    attributes,
    children,
    input,
    submitAttempted,
    validationRules,
    options,
    selectValue
  } = fullProps;
  return (
    <BaseInput
      attributes={attributes}
      className={attributes.type === 'textarea' ? 'bf-textarea--primary' : 'bf-input--primary'}
      input={input}
      options={options}
      selectValue={selectValue}
      submitAttempted={submitAttempted}
      validationRules={validationRules}
    >
      {children}
    </BaseInput>
  );
};

export default Input;
