import { LimitTypes } from '../customLimitsTypes';

export const assignLimitsUnits = (
  limitType: LimitTypes,
  usage: number | string
): number | string => {
  switch (limitType) {
    case 'storage':
      const roundedUsage = Math.round(Number(usage) * 100) / 100;
      return `${roundedUsage.toLocaleString()} GB`;

    default:
      return usage.toLocaleString();
  }
};
