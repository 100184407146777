import { t } from '@lingui/macro';

import { fetchJson } from '../ApiHelper';

import {
  GelatoCatalogs,
  GelatoCatalog,
  GelatoProduct,
  GelatoProducts,
  GelatoProductSearchFilters
} from './GelatoTypes';

const GELATO_API_URL = '/api/v4/private/gelato';

/**
 * Get catalogs from Gelato
 * API docs: https://dashboard.gelato.com/docs/products/catalog/list/
 * @returns {Promise<GelatoCatalogs>} Promise<GelatoCatalogs>
 */
export const getGelatoCatalogs = async (): Promise<GelatoCatalogs> => {
  try {
    const response = await fetchJson<GelatoCatalogs>({
      error: t`Error retrieving Gelato catalog. Please try again.`,
      url: `${GELATO_API_URL}/catalogs`
    });
    return await Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * Get a single catalog with product attributes from Gelato
 * API docs: https://dashboard.gelato.com/docs/products/catalog/get/
 * @param {string} catalogUid string
 * @returns {Promise<GelatoCatalog>} Promise<GelatoCatalog>
 */
export const getGelatoCatalog = async (catalogUid: string): Promise<GelatoCatalog> => {
  try {
    const response = await fetchJson<GelatoCatalog>({
      error: t`Error retrieving Gelato product filters. Please try again.`,
      url: `${GELATO_API_URL}/catalogs/${catalogUid}`
    });
    return await Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * Search a catalogs products in Gelato
 * API docs: https://dashboard.gelato.com/docs/products/product/search/
 * @param {string} catalogUid string
 * @returns {Promise<GelatoProducts>} Promise<GelatoProduct>
 */
export const getGelatoProducts = async (
  catalogUid: string,
  filters: GelatoProductSearchFilters
): Promise<GelatoProducts> => {
  try {
    const response = await fetchJson<GelatoProducts>({
      error: t`Error retrieving Gelato product. Please try again.`,
      body: filters,
      method: 'POST',
      url: `${GELATO_API_URL}/catalogs/${catalogUid}/products:search`
    });
    return await Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * Get a single product from Gelato
 * API docs: https://dashboard.gelato.com/docs/products/product/get/
 * @param {string} productUid string
 * @returns {Promise<GelatoProduct>} Promise<GelatoProduct>
 */
export const getGelatoProduct = async (productUid: string): Promise<GelatoProduct> => {
  try {
    const response = await fetchJson<GelatoProduct>({
      error: t`Error retrieving Gelato product. Please try again.`,
      url: `${GELATO_API_URL}/products/${productUid}`
    });
    return await Promise.resolve(response);
  } catch (err) {
    return Promise.reject(err);
  }
};
