import { getCurrentUserIsAdminPlus, getCurrentUserIsAnonymous } from '@helpers/user';

import { hasSomeViewOnlySelected } from './has-some-view-only-selected';

interface BulkActionEditableOption {
  editable: boolean;
}

interface BulkApprovalsEnabledOptions extends BulkActionEditableOption {
  approver: boolean;
}
export const bulkApprovalsEnabled = ({ approver, editable }: BulkApprovalsEnabledOptions): boolean => (
  editable && approver && BFG.hasFeature('asset_approvals')
);

export const bulkAvailabilityEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  editable && BFG.resource.type === 'brandfolder' && BFG.hasFeature('asset_availability')
);

export const bulkViewOnlyEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  editable
  && BFG.resource.type === 'brandfolder'
  && BFG.hasFeature('view_only_assets')
  && getCurrentUserIsAdminPlus(BFG.currentUser)
);

interface BulkMoveEnabledOptions extends BulkActionEditableOption {
  selectedAssetTypes: Set<string>;
}
export const bulkMoveEnabled = (
  { editable, selectedAssetTypes }: BulkMoveEnabledOptions
): boolean => (
  editable
  && BFG.resource.type === 'brandfolder'
  && selectedAssetTypes.size === 1
);

interface BulkRemoveLabelEnabledOptions extends BulkActionEditableOption {
  activeLabelKey: string;
}
export const bulkRemoveLabelEnabled = ({ activeLabelKey, editable }: BulkRemoveLabelEnabledOptions): boolean => (
  editable
  && BFG.resource.type === 'brandfolder'
  && !!activeLabelKey
);

export const bulkDeleteEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  editable
  && BFG.resource.type === 'brandfolder'
);

export const bulkPeopleTaggingEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  editable
  && BFG.brandfolderSettings.people_tagging
);

export const bulkTaggingEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  editable
  && !BFG.brandfolderSettings.people_tagging
);

interface BulkMergeEnabledOptions extends BulkActionEditableOption {
  designHuddleAssetsExist: boolean;
  selectedAssetKeys: Set<string>;
  selectedAssetTypes: Set<string>;
}
export const bulkMergeEnabled = ({
  designHuddleAssetsExist,
  editable,
  selectedAssetKeys,
  selectedAssetTypes
}: BulkMergeEnabledOptions): boolean => {
  const onlyGenericFiles = selectedAssetTypes.size === 1 && selectedAssetTypes.has('GenericFile');
  const withinSizeLimit = selectedAssetKeys.size > 1 && selectedAssetKeys.size < 50;
  return editable && withinSizeLimit && onlyGenericFiles && !designHuddleAssetsExist;
};

/**
 * determines whether to show _any_ "Add to" actions
 * showPageLite = share manifests/download requests
 * @returns boolean
 */
export const bulkAddToEnabled = (): boolean => (
  !BFG.showPageLite && !getCurrentUserIsAnonymous(BFG.currentUser)
);

export const bulkAddToCollectionsEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  editable
  && ['brandfolder', 'collection'].includes(BFG.resource.type)
  && !BFG.resource.is_subcollection
);

interface BulkAddToSubcollectionsEnabledOptions extends BulkActionEditableOption {
  hasSubCollections: boolean;
}
export const bulkAddToSubcollectionsEnabled = ({
  editable, hasSubCollections
}: BulkAddToSubcollectionsEnabledOptions): boolean => (
  editable
  && BFG.resource.type === 'collection'
  && !BFG.resource.is_workspace
  && hasSubCollections
);

export const bulkAddToLabelsEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  editable && BFG.hasFeature('labels')
);

export const bulkAddToShareLinksEnabled = (): boolean => (
  BFG.hasFeature('share_links')
);

export const bulkAddToWorkspacesEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  editable && BFG.hasFeature('workspace') && !BFG.resource.is_workspace
);

export const bulkCollectionRemovalEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  editable && BFG.resource.type === 'collection'
);

export const bulkShareEnabled = ({ editable }: BulkActionEditableOption): boolean => (
  BFG.hasFeature('share_links')
  && !BFG.showPageLite
  && (editable || !BFG.viewOnly)
);

interface BulkRequestDownloadEnabledOptions {
  orgSearchPage: boolean;
  selectedAssetKeys: Set<string>;
  selectedViewOnlyAssetKeys: Set<string>;
}
export const bulkRequestDownloadEnabled = ({
  orgSearchPage,
  selectedAssetKeys,
  selectedViewOnlyAssetKeys
}: BulkRequestDownloadEnabledOptions): boolean => {
  const hasOver1000AssetsSelected = selectedAssetKeys.size > 1000;
  const hasViewOnlyAssetsSelected = BFG.viewOnly
    || hasSomeViewOnlySelected({ selectedAssetKeys, selectedViewOnlyAssetKeys });
  return !BFG.downloadRequestKey
    && !orgSearchPage
    && hasViewOnlyAssetsSelected
    && !hasOver1000AssetsSelected;
};

export const bulkApproveDownloadEnabled = (): boolean => (
  !!BFG.downloadRequestKey
);

//bulkShareLinkRemovalEnabled --> allowRemoveFromShareLink (@helpers/share-links.ts)

interface BulkDownloadEnabledOptions extends BulkActionEditableOption {
  displayDownload: boolean
}
export const bulkDownloadEnabled = ({ displayDownload, editable }: BulkDownloadEnabledOptions): boolean => (
  (editable || !BFG.viewOnly)
  && !BFG.downloadRequestKey
  && displayDownload
  // BFG.disableCollectionAdminDownload accounts for current user permission not just setting
  && !BFG.disableCollectionAdminDownload
);
