import { t } from '@lingui/macro';

import {
  ActionSummary,
  AllowedMultiActions,
  AllowedSingularActions,
  AllowedMultiTriggers,
  AllowedSingularTriggers,
  KeyLabelPair,
  TriggerSummary,
  WatermarkActionOptions,
  ManageActionSummary,
  ActionsSummaryEntry,
  TriggerSelectorTypes,
  ManageTriggerSummary,
  ManageTriggerString,
  ActionSelectorTypes,
  ManageActionString,
  CustomFieldKeyValueMap
} from '@components/bulk_management/automation/AutomationTypes';
import { SelectorTypes } from '@components/bulk_management/automation/AutomationEnums';

// Annonyingly enough, the `TriggerSummary/ActionSummary` typing on these
// isn't actually providing any type safety because the return type of
// bfTranslate is `any` and bfTranslate needs to apply on static
// strings to pull them into the PO files (see translations.rake)
// The way I wrote this initially was without the bfTranslate to
// get the helpful autocomplete from TriggerSummary/ActionSummary
// and then added bfTranslate later.
const triggerSingularPrefixMap: Record<AllowedSingularTriggers, TriggerSummary> = {
  [SelectorTypes.CustomField]: t`Custom field is added:`,
  [SelectorTypes.Label]: t`Added to label`,
  // [SelectorTypes.NameContains]: bfTranslate('Name contains'),
  [SelectorTypes.Section]: t`Assets added to section`,
  [SelectorTypes.Tag]: t`Tag is added:`
};

const triggerPluralPrefixMap: Record<AllowedMultiTriggers, TriggerSummary> = {
  [SelectorTypes.Label]: t`Added to any label:`,
  [SelectorTypes.Section]: t`Added to any section:`,
  [SelectorTypes.Tag]: t`Any tags are added:`
};

const actionSingularPrefixMap: Record<AllowedSingularActions, ActionSummary> = {
  [SelectorTypes.Collection]: t`Add to collection`,
  [SelectorTypes.CustomField]: t`Add custom field`,
  [SelectorTypes.Label]: t`Add to label`,
  [SelectorTypes.Section]: t`Add to section`,
  [SelectorTypes.Tag]: t`Add tag`,
  [SelectorTypes.Watermark]: t`Add watermark`
};

const actionPluralPrefixMap: Record<AllowedMultiActions, ActionSummary> = {
  [SelectorTypes.Collection]: t`Add to collections`,
  [SelectorTypes.CustomField]: t`Add custom fields`,
  [SelectorTypes.Label]: t`Add to labels`,
  [SelectorTypes.Tag]: t`Add tags`
};

const summarize = (
  trigger: boolean,
  fieldKeys: KeyLabelPair[],
  fieldType: SelectorTypes,
  values: CustomFieldKeyValueMap,
  options?: WatermarkActionOptions | null
): string => {
  const singularMap = trigger ? triggerSingularPrefixMap : actionSingularPrefixMap;
  const pluralMap = trigger ? triggerPluralPrefixMap : actionPluralPrefixMap;
  try {
    if ((!fieldKeys || fieldKeys.length === 0) && fieldType !== SelectorTypes.Watermark) {
      throw new Error('Must have at least one fieldKey to summarize');
    }

    const prefix: TriggerSummary | ActionSummary = fieldKeys.length > 1 ? pluralMap[fieldType] : singularMap[fieldType];
    if (!prefix) {
      throw new Error('Summary prefix not found, confirm that you have a valid SelectorType');
    }

    if (fieldType === SelectorTypes.CustomField) {
      if (trigger) {
        // we know that there can only be max one custom field key for
        // a custom field trigger
        if (!values || !values[fieldKeys[0].key] || values[fieldKeys[0].key].length === 0) {
          throw new Error('SelectorType of CustomField must have at least one value');
        }

        const triggerValues = values[fieldKeys[0].key];
        if (triggerValues.length > 1) {
          const suffix = `'${fieldKeys[0].label}: ${triggerValues.join(', ')}'`;

          // custom fields are special because you can't have a multi-custom field _key_ trigger
          // but you could have one custom field key with with multiple values selected for a trigger
          // in that case we want to use the term "any" because it communicates
          // that the values have an OR relationship
          const multiValueCustomFieldPrefix: TriggerSummary = t`Any custom fields are added:`;
          return `${multiValueCustomFieldPrefix} ${suffix}`;
        }
      }

      const customFieldChunks: string[] = [];
      fieldKeys.forEach((fieldKey) => {
        if (!values || !values[fieldKey.key] || values[fieldKey.key].length === 0) {
          throw new Error('SelectorType of CustomField must have at least one value');
        }

        const valuesList = values[fieldKey.key].join(', ');
        const suffix = `'${fieldKey.label}: ${valuesList}'`;
        customFieldChunks.push(suffix);
      });

      return `${prefix} ${customFieldChunks.join(', ')}`;
    }

    if (fieldType === SelectorTypes.Watermark && options) {
      return `${prefix} '${options.watermark_filename}'`;
    }

    if (fieldKeys.length > 1) {
      return `${prefix} ${fieldKeys.map((fieldKey) => `'${fieldKey.label}'`).join(', ')}`;
    }

    return `${prefix} '${fieldKeys[0].label}'`;
  } catch (e) {
    console.log(e.message);
    return '';
  }
};

export const summarizeTrigger = (
  fieldKeys: KeyLabelPair[],
  fieldType: SelectorTypes,
  values: CustomFieldKeyValueMap
): string => (
  summarize(true, fieldKeys, fieldType, values)
);

export const summarizeAction = (
  fieldKeys: KeyLabelPair[],
  fieldType: SelectorTypes,
  values: CustomFieldKeyValueMap,
  options: WatermarkActionOptions | null
): string => (
  summarize(false, fieldKeys, fieldType, values, options)
);

export const summarizeTriggerType = (fieldType: SelectorTypes): string => (
  // without value details, assume singular and remove trailing colon
  triggerSingularPrefixMap[fieldType]?.split(':')?.[0]
);

const manageTriggerPrefixMap: Record<TriggerSelectorTypes, ManageTriggerString> = {
  [SelectorTypes.CustomField]: t`Custom Field`,
  [SelectorTypes.Label]: t`Label`,
  // [SelectorTypes.NameContains]: bfTranslate('Name'),
  [SelectorTypes.Section]: t`Section`,
  [SelectorTypes.Tag]: t`Tag`
};

const manageActionSingularPostfixMap: Record<ActionSelectorTypes, ManageActionString> = {
  [SelectorTypes.Collection]: t`Collection`,
  [SelectorTypes.CustomField]: t`Custom Field`,
  [SelectorTypes.Label]: t`Label`,
  [SelectorTypes.Section]: t`Section`,
  [SelectorTypes.Tag]: t`Tag`,
  [SelectorTypes.Watermark]: t`Watermark`
};

const manageActionPluralPostfixMap: Record<ActionSelectorTypes, ManageActionString> = {
  [SelectorTypes.Collection]: t`Collections`,
  [SelectorTypes.CustomField]: t`Custom Fields`,
  [SelectorTypes.Label]: t`Labels`,
  [SelectorTypes.Section]: t`Sections`,
  [SelectorTypes.Tag]: t`Tags`,
  [SelectorTypes.Watermark]: t`Watermarks`
};

export const summarizeManageTrigger = (trigger: TriggerSelectorTypes): string => {
  const prefix = manageTriggerPrefixMap[trigger];
  // if (trigger === SelectorTypes.NameContains) {
  //   return (`${prefix} ${bfTranslate('Contains')}` as ManageTriggerSummary) as string;
  // }
  return (`${prefix} ${t`is Added`}` as ManageTriggerSummary) as string;
};

export const summarizeManageAction = (actionSummary: ActionsSummaryEntry): string => {
  const { actionable_type: actionableType, count } = actionSummary;

  const postfix = count === 1
    ? manageActionSingularPostfixMap[actionableType]
    : manageActionPluralPostfixMap[actionableType];

  if (actionableType === SelectorTypes.Collection || actionableType === SelectorTypes.Section) {
    return (`${t`Add to`} ${count} ${postfix}` as ManageActionSummary) as string;
  }
  return (`${t`Add`} ${count} ${postfix}` as ManageActionSummary) as string;
};
