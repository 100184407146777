import { FontIcon } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import { decode } from 'html-entities';
import React, { FunctionComponent } from 'react';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';

import { useFetch } from '@api/ApiHelper';
import { BulkManagementLink } from '@components/bulk_management/shared';
import AdminableResourcesContext from '@components/bulk_management/user_groups/adminable-resources-context';
import { AdminableResourcesResponse } from '@components/bulk_management/user_groups/helpers';
import { UserGroupsCreate } from '@components/bulk_management/user_groups/UserGroupsCreate';
import { UserGroupsEdit } from '@components/bulk_management/user_groups/UserGroupsEdit';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import classes from './styles/user-groups.module.scss';

interface UserGroupsProps {
  enabledLinks: BulkManagementLink[];
  organizationKey: string;
}

export const UserGroups: FunctionComponent<UserGroupsProps> = (props) => {
  const { enabledLinks, organizationKey } = props;

  const isTest = process.env.NODE_ENV === 'test';
  const base = isTest ? '' : `/${BFG.resource.slug}/bulk_management`;
  const path = isTest ? '/' : `${base}/user_groups`;
  const resourceName = decode(BFG.resource.name);

  const adminableResourcesFetch = useFetch<AdminableResourcesResponse>({
    url: `/api/v4/private/organizations/${BFG.organization_key}/adminable_resources`,
    fetchOnMount: true
  });

  return (
    <I18nProviderWrapper>
      <BrowserRouter basename="/">
        <main className={classes.main}>
          <nav className={classes.nav}>
            <a
              className={classnames(classes.navLink, classes.backLink)}
              href={`/${BFG.resource.slug}`}
            >
              <Trans>Return to {resourceName}</Trans>
            </a>

            {enabledLinks.map((link) => {
              const icon = (
                <FontIcon
                  className={classnames(classes.icon, `bff-${link.icon}`)}
                  icon={link.icon}
                />
              );

              if (link.href.includes('user_groups')) {
                return (
                  <NavLink key={link.id} className={classes.navLink} to={link.href}>
                    {icon}
                    {link.title}
                  </NavLink>
                );
              }

              return (
                <a key={link.id} className={classes.navLink} href={`${base}#${link.href}`}>
                  {icon}
                  {link.title}
                </a>
              );
            })}
          </nav>
          <div className={classes.content}>
            <Routes>
              <Route path={path}>
                <Route element={<UserGroupsCreate organizationKey={organizationKey} />} index />
                <Route
                  element={(
                    <AdminableResourcesContext.Provider value={adminableResourcesFetch}>
                      <UserGroupsEdit />
                    </AdminableResourcesContext.Provider>
                  )}
                  path=":userGroupKey"
                />
              </Route>
            </Routes>
          </div>
        </main>
      </BrowserRouter>
    </I18nProviderWrapper>
  );
};
