import { t } from '@lingui/macro';
import React, { useContext, Dispatch } from 'react';

import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import detectChanges from '@components/asset/modal/tabs/edit/change_detection/changeEngine';
import {
  ActionTypes,
  LocalePayload,
  ReducerAction,
  ReducerState,
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import LanguageMenuDropdown from '@components/common/language_menu/LanguageMenuDropdown';
import { Locale } from '@components/common/language_menu/languagesMap';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';
import { unsavedChangesOptions } from '@helpers/sweet_alert_options';

interface LanguageMenuDropdownScopedContext {
  state: ReducerState;
  dispatch: Dispatch<ReducerAction<LocalePayload>>;
}

const EditLanguageMenuDropdown = (): JSX.Element => {
  const { state, dispatch }: LanguageMenuDropdownScopedContext = useContext(assetModalContext);
  const { ugtLocaleDefault } = BFG.locales;

  if (!state.initialData.assetKey) { // don't show dropdown if new asset form
    return null;
  }

  const updateUGTLocale = (locale: Locale): boolean => {
    if (locale === state.ugtLocale) {
      return false;
    }

    if (detectChanges(state, false).length) {
      window.swal(unsavedChangesOptions({}), (confirm) => {
        if (confirm) {
          sendAction(TrackedAction.AssetModalEditTabUGTLocaleChange);
          dispatch({ type: ActionTypes.UpdateLocale, payload: { ugtLocale: locale } });
        }
      });
      return true; // close dropdown
    }

    sendAction(TrackedAction.AssetModalEditTabUGTLocaleChange);
    dispatch({ type: ActionTypes.UpdateLocale, payload: { ugtLocale: locale } });
    return true;
  };

  return (
    <LanguageMenuDropdown
      copy={t`Select from the list below in order to manually edit asset fields for that language.`}
      defaultLocale={ugtLocaleDefault}
      onLocaleChange={updateUGTLocale}
      openOnHover={false}
      selectedLocale={state.ugtLocale || ugtLocaleDefault}
      showSelectedLocaleName
    />
  );
};

export default EditLanguageMenuDropdown;
