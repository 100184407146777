import { BFGUserRoles, UserRoles } from '@api/v4/UserTypes';

/**
 * Get whether a user has admin privileges for editing Share Links
 * @param userRole BFGUserRoles | undefined
 * @param superUser boolean | undefined
 * @returns boolean
 */
export const userHasAdminPrivileges = (userRole?: BFGUserRoles, superUser?: boolean): boolean => {
  if (userRole && ([UserRoles.Admin, UserRoles.Owner] as BFGUserRoles[]).includes(userRole)) return true;
  if (superUser) return true;
  return false;
};

/**
 * Get whether a user created a Share Link/Manifest
 * @param userKey string | undefined
 * @param manifestCreatorUserKey string | undefined
 * @returns boolean
 */
export const userCreatedShareLink = (userKey?: string, manifestCreatorUserKey?: string): boolean => {
  if (!userKey || !manifestCreatorUserKey) return false;
  return userKey === manifestCreatorUserKey;
};

/**
 * Get whether a user can edit a Share Link/Manifest
 * @param userRole BFGUserRoles | undefined
 * @param userKey string | undefined
 * @param manifestCreatorUserKey  string | undefined
 * @param superUser boolean | undefined
 * @returns boolean
 */
export const userCanEditShareLink = (
  userRole?: BFGUserRoles,
  userKey?: string,
  manifestCreatorUserKey?: string,
  superUser?: boolean
): boolean => {
  if (userHasAdminPrivileges(userRole, superUser)) return true;
  if (!manifestCreatorUserKey) return false;
  if (userCreatedShareLink(userKey, manifestCreatorUserKey)) return true;
  return false;
};

/**
 * Determine whether "Remove from Share Link" button should show
 * @param userRole BFGUserRoles | undefined
 * @param userKey string | undefined
 * @param manifestCreatorUserKey  string | undefined
 * @param superUser boolean | undefined
 * @returns boolean
 */
export const allowRemoveFromShareLink = (
  userRole?: BFGUserRoles,
  userKey?: string,
  manifestCreatorUserKey?: string,
  superUser?: boolean
): boolean => {
  if (BFG.downloadRequestKey) return false;

  const searchParams = new URLSearchParams(window.location.search);
  return (BFG.showPageLite && userCanEditShareLink(userRole, userKey, manifestCreatorUserKey, superUser))
    || (searchParams.has('digest') && searchParams.has('user_can_edit'));
};