// wrap promises with a .then() that catches errors
// useful for when you want Promise.all() to not reject on the first reject

export interface ReflectInterface {
  response: any;
  status: PromiseStatus;
}

export enum PromiseStatus {
  Fulfilled,
  Rejected
}

export const promiseReflect = async (promise: Promise<any>): Promise<ReflectInterface> => {
  try {
    const response = await promise;
    return { response, status: PromiseStatus.Fulfilled };
  } catch (error) {
    return { response: error, status: PromiseStatus.Rejected };
  }
};
