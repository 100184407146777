import fetchJSON from '@api/api_helper';

const url = (annotationKey) => `/api/v4/annotations/${annotationKey}`;

export const deleteAnnotation = ({ annotationKey }, updateFetchControllers) => {
  const appendedOptions = {
    method: 'DELETE',
  };

  return fetchJSON(url(annotationKey), appendedOptions, updateFetchControllers);
};
