import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import RadioButton from '@components/common/radio_button/main';

const Filetypes = ({
  filetypes,
  selectedCategory,
  selectedFiletype,
  selectedPreset,
  setSelectedFiletype
}) => {
  if (!selectedPreset && selectedCategory.value !== 'freeform') { return null; }

  return (
    <ul className={classnames(
      "filetypes-container",
      "radio-button-container",
      "options__content--container",
      { disabled: (selectedPreset && selectedPreset.value.format) },
    )}
    >
      {filetypes.map((filetype) => (
        <li key={filetype} className="filetype-list-item">
          <RadioButton
            checkedButton={selectedFiletype}
            labelCopy={filetype.toUpperCase()}
            name="filetype-output"
            onChangeFunction={() => setSelectedFiletype(filetype.toUpperCase())}
            valueCopy={filetype.toUpperCase()}
          />
        </li>
      ))}
    </ul>
  );
};

Filetypes.propTypes = {
  filetypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategory: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  selectedFiletype: PropTypes.string.isRequired,
  selectedPreset: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({}),
  }),
  setSelectedFiletype: PropTypes.func.isRequired,
};

Filetypes.defaultProps = {
  selectedPreset: null,
};

export default Filetypes;
