import convert from 'color-convert';
import { RGB } from 'color-convert/conversions';
import React, { FunctionComponent, useContext } from "react";

import { InputType } from '@components/library/inputs/Input.props';
import Input from '@components/library/inputs/PrimaryInput';
import Label from '@components/library/labels/PrimaryLabel';

import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import ColorCircle from '@components/asset/modal/tabs/edit/side_bar/color_assets/colorCircle';
import { ColorData } from '@api/v4/assets/assetTypes';
import { RgbString } from './colorSideBar';

interface RGBScopedContext {
  state: {
    editState: RGBProps;
  };
}

interface RGBProps {
  colorData: {
    r: string;
    g: string;
    b: string;
  };
}

const RGBInputs: FunctionComponent<{
  handleColorUpdate: (partial: Partial<ColorData>) => void;
  autofillEmptyColors: (partial: Partial<ColorData>) => void;
  prepRgbColorValues: (values: RgbString) => RGB;
}> = ({ handleColorUpdate, autofillEmptyColors, prepRgbColorValues }) => {
  const { state }: RGBScopedContext = useContext(assetModalContext);
  if (!state.editState.colorData) {
    return null;
  }
  const { r, g, b } = state.editState.colorData;

  const getBackgroundColor = (): string => {
    /**
     * color-convert returns #000000 when given invalid or empty inputs
     * so to determine whether it was actually invalid we need to make sure
     * that black wasn't the actual input
     */
    const isValidBlackCombination = (r === "0" && g === "0" && b === "0");
    const hex = convert.rgb.hex(
      prepRgbColorValues({ r, g, b })
    );
    return hex === "000000" && !isValidBlackCombination
      ? ""
      : hex;
  };

  const autofillOnBlur = (): void => {
    autofillEmptyColors({ r, g, b });
  };

  return (
    <>
      <div className="edit-tab-sidebar-color-label-swatch-container">
        <Label attributes={{ className: "edit-tab-sidebar-label" }}>RGB</Label>
        <ColorCircle
          getBackgroundColor={getBackgroundColor}
          id="color-circle-rgb"
        />
      </div>
      <div className="edit-tab-sidebar-color-input-and-color">
        <div className="edit-tab-sidebar-color-flex-inputs edit-tab-sidebar-color-flex-inputs--rgb">
          <Input
            attributes={{
              'aria-label': "rgb r",
              className: "edit-tab-sidebar-color-rgba",
              max: 255,
              min: 0,
              name: "r",
              onChange: (e: InputChangeEvent): void => handleColorUpdate({ r: e.target.value }),
              onBlur: autofillOnBlur,
              type: InputType.Number,
              value: r,
            }}
            input={{ value: r }}
          />
          <Input
            attributes={{
              'aria-label': "rgb g",
              className: "edit-tab-sidebar-color-rgba",
              max: 255,
              min: 0,
              name: "g",
              onChange: (e: InputChangeEvent): void => handleColorUpdate({ g: e.target.value }),
              onBlur: autofillOnBlur,
              type: InputType.Number,
              value: g,
            }}
            input={{ value: g }}
          />
          <Input
            attributes={{
              'aria-label': "rgb b",
              className: "edit-tab-sidebar-color-rgba",
              max: 255,
              min: 0,
              name: "b",
              onChange: (e: InputChangeEvent): void => handleColorUpdate({ b: e.target.value }),
              onBlur: autofillOnBlur,
              type: InputType.Number,
              value: b,
            }}
            input={{ value: b }}
          />
        </div>
      </div>
    </>
  );
};

export default RGBInputs;
