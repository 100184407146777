import { BadgePill, MoreInfoTooltip, Widths } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { BrandfolderHexColors } from '@components/library/utils';
import { moreInfoLabel } from '@components/standard-messages';

type ResourceLabels = 'brandfolders' | 'brandguides' | 'organizations' | 'portals';

const translatedResourceTypes = (): Record<ResourceLabels, string> => ({
  brandfolders: t`Brandfolder`,
  brandguides: t`Brandguide`,
  organizations: t`Organization`,
  portals: t`Portal`,
});

interface ResourceLabelProps {
  resourceType: string;
}

export const ResourceLabel: FunctionComponent<ResourceLabelProps> = ({
  resourceType,
}) => (
  <span className="resource-label">
    <BadgePill
      backgroundColor={BrandfolderHexColors.DirtyMarshmallow}
      fontSize={12}
      radius={30}
    >
      {translatedResourceTypes()[resourceType]?.toUpperCase()}
    </BadgePill>
    {resourceType === 'organizations' && (
      <MoreInfoTooltip
        iconLabel={moreInfoLabel()}
        iconSize={13}
        id="limits-org-tooltip"
        tooltip={<Trans>Organization level seats count towards the plan limit</Trans>}
        triggerOffset={12}
        width={Widths.Small}
      />
    )}
  </span>
);
