import { t, Trans } from '@lingui/macro';
import { Result } from '@uppy/transloadit';
import { PickerResponse } from 'filestack-js/build/main/lib/picker';
import React, { ReactElement, useContext } from 'react';

import { WatermarkObject } from '@api/v4/assets/assetTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import {
  ActionTypes,
  WatermarkDispatch,
  WatermarkPayload,
  WatermarkPayloadOperations
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { UploadArea } from '@components/common/filestack_uploader/UploadArea';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';
import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { WatermarkPositions, defaultWatermarkPostion } from '@components/shared/watermarks';
import { WatermarkPositionsEnum } from '@components/shared/watermarks/WatermarkPositionTypes';

interface WatermarkScopedContext {
  state: {
    editState: {
      watermark: WatermarkObject;
    };
  };
  dispatch: WatermarkDispatch;
}

const WatermarkComponent = (): ReactElement => {
  const { state, dispatch }: WatermarkScopedContext = useContext(assetModalContext);
  const { watermark } = state.editState;

  const deleteAssetWatermark = (): void => {
    const payload = {
      operation: WatermarkPayloadOperations.Delete
    };

    dispatch({ type: ActionTypes.WatermarkAction, payload });
  };

  const updateWatermarkUppy = (files: Result[]): void => {
    if (files.length === 0) return;

    const payload: WatermarkPayload = {
      ...watermark,
      gravity: defaultWatermarkPostion,
      filename: files[0].name,
      operation: WatermarkPayloadOperations.Update,
      url: files[0].url
    };

    dispatch({ type: ActionTypes.WatermarkAction, payload });

    let title = t`Watermark will begin processing once changes are saved`;

    if (BFG.hasFeature('watermarking')) {
      title = t`Watermark will begin processing once changes are saved. Watermark will only be applied to supported file types: JPG, JPEG, and PNG.`;
    }

    Notify.create({
      title,
      type: 'success',
    });
  };

  const updateWatermarkFilestack = (files: PickerResponse): void => {
    if (files.filesUploaded.length === 0) return;

    const payload: WatermarkPayload = {
      ...watermark,
      gravity: defaultWatermarkPostion,
      filename: files.filesUploaded[0].filename,
      operation: WatermarkPayloadOperations.Update,
      url: files.filesUploaded[0].url
    };

    dispatch({ type: ActionTypes.WatermarkAction, payload });

    let title = t`Watermark will begin processing once changes are saved`;

    if (BFG.hasFeature('watermarking')) {
      title = t`Watermark will begin processing once changes are saved. Watermark will only be applied to supported file types: JPG, JPEG, and PNG.`;
    }

    Notify.create({
      title,
      type: 'success',
    });
  };

  const onError = (): void => {
    Notify.create({ type: 'error', title: t`Error Uploading Files` });
  };

  const handleWatermarkPosition = (watermarkPosition: WatermarkPositionsEnum): void => {
    const payload: WatermarkPayload = {
      ...watermark,
      gravity: watermarkPosition,
      operation: WatermarkPayloadOperations.Update
    };

    dispatch({ type: ActionTypes.WatermarkAction, payload });

    if (watermark.url) {
      let title = t`Watermark position will reprocess once changes are saved`;

      if (BFG.hasFeature('watermarking')) {
        title = t`Watermark position will reprocess once changes are saved. Watermark will only be applied to supported file types: JPG, JPEG, and PNG.`;
      }

      Notify.create({
        title,
        type: 'success',
      });
    }
  };

  const uploadAreaId = 'watermark-trigger-watermark';

  const uploadProvider = (): ReactElement => (
    BFG.hasFeature('uppy_uploader_features') ? (
      <>
        <div
          className="uppy-asset-watermark"
          id={uploadAreaId}
        >
          <Trans>Click to upload</Trans>
        </div>
        <UppyUploader
          button
          handleUpload={updateWatermarkUppy}
          restrictions={{ maxNumberOfFiles: 1, allowedFileTypes: ['image/*'] }}
          template="watermark"
          trigger={`#${uploadAreaId}`}
          uniqIdentifier="uppy-watermark-watermark"
        />
      </>
    ) : (
      <UploadArea
        id={uploadAreaId}
        onUploadDone={updateWatermarkFilestack}
        pickerOptions={{
          accept: ['image/png'],
          maxFiles: 1,
          onFileUploadFailed: onError
        }}
        uploadType={UploadAreaTypes.Watermark}
      />
    )
  );

  return (
    <>
      <div className="asset-advanced__feature">
        <span>
          <Trans>Custom Watermark</Trans>
        </span>
        <div className="watermark-upload">
          {
          (watermark.url && watermark.filename) ? (
            <div className="asset-advanced__feature--upload-complete">
              <FilestackPreviewImage
                alt={t`Watermark preview image`}
                height="50px"
                src={watermark.url}
              />
              <span className='asset-advanced__feature--filename'>
                {watermark.filename}
              </span>
              <span
                className="bff-trash remove-watermark"
                onClick={(): void => deleteAssetWatermark()}
                onKeyPress={(): void => deleteAssetWatermark()}
                role="button"
                tabIndex={0}
                title={t`Remove Custom Asset Watermark`}
              />
            </div>
          ) : uploadProvider()
        }
        </div>
      </div>
      {watermark.url && BFG.hasFeature('watermarking') && (
        <div className="asset-advanced__feature watermark-positions__wrapper">
          <WatermarkPositions
            gravity={watermark.gravity || defaultWatermarkPostion}
            onChange={handleWatermarkPosition}
            showHeading={false}
          />
        </div>
      )}
    </>
  );
};

export default WatermarkComponent;
