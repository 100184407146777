import { PickerInstance, PickerOptions, PickerUploadDoneCallback } from 'filestack-js/build/main/lib/picker';
import { useEffect, useState } from 'react';

import { FilestackCredentials, FilestackCredentialsPostOptions } from '@api/v4/private/resources/fs_creds';
import { useFilestackCredentials } from '@components/common/custom_hooks/useFilestackCredentials';
import { initializeFilestackPicker } from '@components/common/filestack_uploader/initialize';

interface FilestackPickerState {
  error: Error | null;
  loading: boolean;
  picker: PickerInstance | null;
  render: () =>  void;
}

interface UseFilestackPickerOptions {
  onUploadDone: PickerUploadDoneCallback;
  /** Override fetching /fs_creds by passing in valid credentials (i.e. from a .slim file) */
  credentials?: FilestackCredentials;
  credentialsOptions?: FilestackCredentialsPostOptions;
  pickerOptions?: PickerOptions;
  printuiAssetKey?: string;
  printuiDigestKey?: string;
  resourceKey?: string;
  resourceType?: string;
  uploadLinkKey?: string;
}

/**
 * Hook for getting a Filestack picker instance after credentials have loaded.
 * @param options UseFilestackPickerOptions
 * @returns FilestackPickerState
 */
export const useFilestackPicker = (options: UseFilestackPickerOptions): FilestackPickerState => {
  const {
    credentials,
    credentialsOptions,
    onUploadDone,
    pickerOptions,
    printuiAssetKey,
    printuiDigestKey,
    resourceKey,
    resourceType,
    uploadLinkKey
  } = options;

  const {
    error: filestackCredentialsError,
    fetch,
    filestackCredentials
  } = useFilestackCredentials({
    credentials,
    credentialsOptions,
    printuiAssetKey,
    printuiDigestKey,
    resourceKey,
    resourceType,
    uploadLinkKey
  });

  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const [picker, setPicker] = useState<PickerInstance | null>(null);
  const [renderPicker, setRenderPicker] = useState(false);

  const render = (): void => {
    setRenderPicker(true);
  };

  useEffect(() => {
    if (renderPicker) {
      setLoading(true);
      fetch();
    }
  }, [renderPicker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filestackCredentialsError) {
      setError(filestackCredentialsError);
      setLoading(false);
    }
  }, [filestackCredentialsError]);

  useEffect(() => {
    if (filestackCredentials && renderPicker) {
      const initializedPicker = initializeFilestackPicker({
        filestackCredentials,
        onUploadDone,
        pickerOptions
      });

      setLoading(false);
      setPicker(initializedPicker);
      setRenderPicker(false);
    }
  }, [filestackCredentials, renderPicker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return (): void => {
      if (picker) {
        picker.close();
      }
    };
  }, [picker]);

  return { error, loading, picker, render };
};
