import { Plural } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { HeadingType } from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';

interface ResourceHeadingProps {
  length: number;
  headingType: HeadingType;
}

export const ResourceHeading: FunctionComponent<ResourceHeadingProps> = ({ length, headingType }) => {
  let headingText;
  switch (headingType) {
    case 'brandguide':
      headingText = <Plural one="Brandguide" other="Brandguides" value={length} />;
      break;
    case 'collection':
      headingText = <Plural one="Collection" other="Collections" value={length} />;
      break;
    case 'subcollection':
      headingText = <Plural one="Subcollection" other="Subcollections" value={length} />;
      break;
    case 'workspace':
      headingText = <Plural one="Workspace" other="Workspaces" value={length} />;
      break;
    case 'portal':
      headingText = <Plural one="Portal" other="Portals" value={length} />;
      break;
  }

  return (
    <h4 className={classnames(
      'resource-sidebar__header',
      `resource-sidebar__header--${headingType}`,
      { ['resource-sidebar__header--nested']: headingType !== 'portal' && headingType !== 'brandguide' },
      { ['resource-sidebar__header--double-nested']: headingType === 'subcollection' },
    )}>
      {headingText}
    </h4>
  );
};
