import { t } from '@lingui/macro';
import { ResourceTypes } from '@api/v4/resources/resourceTypes';
import { ErrorInterface } from '@api/v4/apiHelperTypes';
import { PublishResponseJSON } from '@api/v4/private/smartsheet/publish';

export type SelectedResources = Partial<{ [key in ResourceTypes]: string | null }>
export type SelectedLabels = { [labelKey: string]: boolean }

export interface PostMessageDataReceived {
  proof: { externalUrl: string; id: number; name: string };
  row: { id: number; url: string };
  sheet: { id: number; url: string };
  attachments?: Attachment[];
  customFields?: CustomField[];
  tags?: Tag[];
  labelKeys?: string[];
}

export interface Status {
  context: PayloadContext;
  status: Statuses;
  body?: PayloadBody | PublishResponseJSON;
}

export type Statuses = 200 | 202 | 400 | 401 | 403 | 404 | 500

type Attachment = { filename: string; size: number; url: string }
type CustomField = { name: string; value: string }
type Tag = { name: string }

type PayloadBody = {
  assetUrl?: string;
  error?: ErrorInterface | unknown;
  event?: MessageEvent;
  message?: string;
  openerOrigin?: string;
}

export const Messages = {
  FetchResourcesError: t`Error fetching resources`,
  FetchResourcesSuccess: t`Resources successfully fetched`,
  OriginMismatch: t`Event origin does not match opener origin`,
  PostMessageError: t`Error executing postMessage`,
  PublishAssetError: t`Error publishing asset to Brandfolder`,
  PublishAssetSuccess: t`Asset successfully created in Brandfolder!`,
  Ready: t`Ready!`,
} satisfies Readonly<Record<string, string>>;


export enum PayloadContext {
  FetchResources = 'fetch-resources',
  PostMessage = 'post-message',
  PublishAsset = 'publish-asset',
  Setup = 'setup',
}

export enum Views {
  Form = 'form',
  Loading = 'loading',
  NoResources = 'noResources',
  SubmitSuccess = 'submitSuccess'
}
