import { t, Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";
import ReactPaginate from 'react-paginate';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import ClipboardCopy from "../../common/clipboard_copy/main";

import InputCustomizer from "./input_customizer";

import "../styles/form_customizer.scss";

class FormCustomizer extends React.Component {
  state = {
    currentPage: 1,
    editMode: false,
    printuiInputs: this.props.printui_inputs,
    textFrameData: {},
    imageFrameData: {},
    refreshPreviewVersion: null,
    zoomSize: "fit-to-screen"
  }

  componentDidMount() {
    this.addJobIdToUrl();
  }

  updateFrameData = (frameSubmission, type = "text") => {
    const frameId = Object.keys(frameSubmission)[0];
    if (type === "image") {
      this.setState((prevState) => (
        { imageFrameData: { ...prevState.imageFrameData,
          [frameId]: frameSubmission[frameId] } }));
    } else {
      this.setState((prevState) => (
        { textFrameData: { ...prevState.textFrameData,
          [frameId]: frameSubmission[frameId] } }));
    }
  }

  updatePrintuiInputs = (printuiInputData) => {
    const newInput = this.formatPrintuiInput(printuiInputData);
    const existingInputs = this.state.printuiInputs.filter((input) => input.key !== newInput.key);
    const updatedInputs = existingInputs.concat(newInput);
    this.setState({ printuiInputs: updatedInputs });
  }

  matchingPrintuiInput = (frameId) => (
    this.state.printuiInputs.find((input) => input.field_identifier === frameId)
  );

  pageCount = () => Object.keys(this.props.frames).length;

  goToPage = (data) => {
    const { textFrameData, imageFrameData } = this.state;
    if (Object.keys(textFrameData).length > 0 || Object.keys(imageFrameData).length > 0) {
      this.submitTemplate("reloadPreview");
    }
    // React select starts at 0, but we printui & component start at 1
    this.setState({ currentPage: (data.selected + 1) });
  };

  submitTemplate = (reloadPreview) => {
    const { asset_key, job_id, digest } = this.props;
    const { textFrameData, imageFrameData } = this.state;
    const data = {
      asset_key,
      job_id,
      text_frame_data: textFrameData,
      image_frame_data: imageFrameData,
      digest
    };
    $.ajax({
      type: "PUT",
      url: "/api/v4/features/printui/update_frames",
      contentType: "application/json",
      context: this,
      data: JSON.stringify(data),
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: (response, _, xhr) => {
        if (xhr.status === 202) {
          Notify.create({
            type: "success",
            title: "Processing updates",
            body: "Template may not immediately reflect changes" });
          setTimeout(() => Notify.makeAll('dormant'), 2000);
          if (reloadPreview) {
            setTimeout(() => this.setState({ refreshPreviewVersion: Date.now() }), 5000);
          }
        }
        if (reloadPreview) {
          // Refresh Preview
          this.setState({ refreshPreviewVersion: Date.now() });
        } else {
          // Finished screen
          window.location.href = this.props.finish_url;
        }
      },
      error: () => {
        Notify.create({
          type: "error",
          title: t`Update Failed`,
          body: t`Please reload and try again.`
        });
      }
    });
  }

  formatPrintuiInput(printuiInputData) {
    const data = printuiInputData.data;
    return {
      key: data.id,
      field_identifier: data.attributes.field_identifier,
      options: data.attributes.options
    };
  }

  addJobIdToUrl() {
    const url = window.location.href;
    if (url.indexOf("job_id=") < 0) {
      const urlWithJobId = `${url}&job_id=${this.props.job_id}`;
      const stateObj = { job_id: this.props.job_id };
      window.history.replaceState(stateObj, url, encodeURI(decodeURI(urlWithJobId)));
    }
  }

  nextLabel() {
    const { currentPage } = this.state;
    return (
      <div className={`page-arrow next ${currentPage === this.pageCount() ? "disabled" : ""}`}>
        Next
        <span aria-hidden="true" className="bff-arrow-right" />
      </div>
    );
  }

  previousLabel() {
    const { currentPage } = this.state;
    return (
      <div className={`page-arrow previous ${currentPage === 1 ? "disabled" : ""}`}>
        <span aria-hidden="true" className="bff-arrow-left" />
        Previous
      </div>
    );
  }

  renderEditModeToggleButton() {
    const { editMode } = this.state;
    const editButton = <span><span className="bff-edit" />Edit Form Fields</span>;
    return (
      <button
        className="edit-mode-toggle button secondary"
        onClick={() => { this.setState({ editMode: !editMode }); }}
        type="button"
      >
        {editMode ? <Trans>Close Form Editing</Trans> : editButton}
      </button>
    );
  }

  renderRefreshButton() {
    return (
      <button
        className="refresh-preview-button button secondary"
        onClick={() => this.submitTemplate("reloadPreview")}
        type="button"
      >
        <span className="bff-refresh" />
        Refresh Preview
      </button>
    );
  }

  renderFinishButton() {
    return (
      <button
        className="finish-button button primary sm"
        onClick={() => this.submitTemplate()}
        type="button"
      >
        Finish
      </button>
    );
  }

  renderPagination() {
    return (
      <React.Fragment>
        {this.pageCount() > 1
          ? (
            <ReactPaginate
              activeClassName="active"
              breakClassName="break-me"
              breakLabel={<a href="">...</a>}
              containerClassName="pagination"
              marginPagesDisplayed={1}
              nextLabel={this.nextLabel()}
              onPageChange={this.goToPage}
              pageCount={this.pageCount()}
              pageRangeDisplayed={3}
              previousLabel={this.previousLabel()}
              subContainerClassName="page-box"
            />
          ) : null }
      </React.Fragment>
    );
  }

  renderCopyUrlButton() {
    // Not entire button is clickable - limitations of CopyClipboard when button inside
    return (
      <button className="copy-url-button button secondary sm" type="button">
        <ClipboardCopy showFeedbackAsTooltip textToCopy={window.location.href}>
          <span className="full-width"><span className="bff-share" /><Trans>Share Link</Trans></span>
        </ClipboardCopy>
      </button>
    );
  }

  renderInputs() {
    const {
      asset_key,
      client,
      digest,
      embed_link,
      font_settings,
      frames,
      job_id,
      org_slug,
      template_settings
    } = this.props;

    const { currentPage, textFrameData, imageFrameData } = this.state;
    const pageFrames = frames[currentPage];

    if (pageFrames.length > 0) {
      return pageFrames.map((frame) => (
        <InputCustomizer
          key={frame.id}
          assetKey={asset_key}
          client={client}
          digestKey={digest}
          editMode={this.state.editMode}
          embedLink={embed_link}
          fontSettings={font_settings}
          frame={frame}
          imageFrameData={imageFrameData}
          jobId={job_id}
          orgSlug={org_slug}
          printuiInput={this.matchingPrintuiInput(frame.id)}
          templateSettings={template_settings}
          textFrameData={textFrameData}
          updateFrameData={this.updateFrameData}
          updatePrintuiInputs={this.updatePrintuiInputs}
        />
      ));
    }
    return (
      <div className="inputs-row">
        <h3><Trans>This page does not have editable fields</Trans></h3>
      </div>
    );
  }

  renderTemplatePreview() {
    const { editMode, refreshPreviewVersion, currentPage, zoomSize } = this.state;
    const previewWidth = editMode ? "one-third" : "one-half";
    const imageUrl = this.props.image_links[(currentPage - 1)];
    return (
      <div className={`template-preview-container ${previewWidth}`}>
        <div className="template-zoom-options">
          <button
            className="zoom-to-fit-button button tertiary sm"
            onClick={() => { this.setState({ zoomSize: "fit-to-screen" }); }}
            type="button"
          >
            Fit to Screen
          </button>
          <button
            className="zoom-icon-button"
            onClick={() => { this.setState({ zoomSize: "large" }); }}
            type="button"
          >
            <span className="bff-zoom-in" />
          </button>
          <button
            className="zoom-icon-button"
            onClick={() => { this.setState({ zoomSize: "larger" }); }}
            type="button"
          >
            <span className="bff-zoom-in" />
            <span className="bff-plus" />
          </button>
        </div>
        <img
          alt="Template"
          className={`template-image ${zoomSize}`}
          src={`${imageUrl}&forceRefreshTimestamp=${refreshPreviewVersion}`}
        />
      </div>
    );
  }

  renderActionButtons() {
    const { editable } = this.props;
    return (
      <React.Fragment>
        {editable ? this.renderEditModeToggleButton() : null}
        {this.renderPagination()}
        {this.renderFinishButton()}
        {this.renderCopyUrlButton()}
        {this.renderRefreshButton()}
      </React.Fragment>
    );
  }

  render() {
    const { editMode } = this.state;
    const formWidth = editMode ? "two-thirds" : "one-half";
    return (
      <I18nProviderWrapper>
        <div className="template-wrapper">
          <section className="template-actions">
            {this.renderActionButtons()}
          </section>
          <section className="template-form">
            <div className={`inputs-container ${formWidth}`}>
              {formWidth === "two-thirds"
                ? (
                  <div className="form-titles">
                    <div className="one-half">
                      <h3><Trans>Form Editor</Trans></h3>
                    </div>
                    <div>
                      <h3><Trans>Form Output</Trans></h3>
                    </div>
                  </div>
                ) : ""}
              {this.renderInputs()}
            </div>
            {this.renderTemplatePreview()}
          </section>
        </div>
      </I18nProviderWrapper>
    );
  }
}

FormCustomizer.propTypes = {
  frames: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  printui_inputs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    field_identifier: PropTypes.string,
    options: PropTypes.object,
    validations: PropTypes.object
  })).isRequired,
  asset_key: PropTypes.string.isRequired,
  job_id: PropTypes.string.isRequired,
  finish_url: PropTypes.string.isRequired,
  image_links: PropTypes.arrayOf(PropTypes.string).isRequired,
  org_slug: PropTypes.string.isRequired,
  embed_link: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  digest: PropTypes.string,
  font_settings: PropTypes.shape({}).isRequired,
  editable: PropTypes.bool.isRequired,
  template_settings: PropTypes.shape({
    allowFontSizing: PropTypes.bool
  }).isRequired
};

FormCustomizer.defaultProps = {
  digest: undefined
};

export default FormCustomizer;
