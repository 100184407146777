import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { subscriptionFeatures } from './constants';

import SubscriptionFeature from './SubscriptionFeature';

export interface SubscriptionFeaturesProps {
  featureStatuses: { [key: string]: boolean };
  organizationName: string;
}

const SubscriptionFeatures: FunctionComponent<SubscriptionFeaturesProps> = ({
  featureStatuses,
  organizationName,
}) => (
  <div className="subscription__features">
    <h2><Trans>Features</Trans></h2>
    <div className="subscription__features-content">
      {subscriptionFeatures().map((feature) => {
        const featureWithStatus = {
          ...feature,
          isEnabled: featureStatuses[feature.id],
        };
        return (
          <SubscriptionFeature
            key={feature.name}
            feature={featureWithStatus}
            organizationName={organizationName}
          />
        );
      })}
    </div>
  </div>
);

export default SubscriptionFeatures;
