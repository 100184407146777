import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, MutableRefObject, useEffect } from 'react';
import EmailEditor, { Design, EditorRef } from 'react-email-editor';

import { Container, InputError } from '@components/library/input_container';
import Label from '@components/library/labels/PrimaryLabel';
import { StandardTextField } from '@components/library/text-field';

import { PressReleaseUpsert, PressReleaseValidationErrors } from './PressReleaseTypes';

import classes from './styles/press_release_form_step1.module.scss';

interface PressReleaseFormStep1Props {
  emailEditorRef: MutableRefObject<EditorRef | null>;
  onEmailEditorBlur: () => void;
  pressRelease: PressReleaseUpsert | null;
  setEmailEditorLoaded: SetStateDispatch<boolean>;
  setPressRelease: SetStateDispatch<PressReleaseUpsert>;
  validationErrors: PressReleaseValidationErrors;
}

/**
 * Step 1: Subject, from name, and content
 * @param {PressReleaseFormStep1Props} props PressReleaseFormStep1Props
 */
export const PressReleaseFormStep1: FunctionComponent<PressReleaseFormStep1Props> = (props) => {
  const {
    emailEditorRef,
    onEmailEditorBlur,
    pressRelease,
    setEmailEditorLoaded,
    setPressRelease,
    validationErrors
  } = props;

  const onLoad = (): void => {
    if (emailEditorRef?.current?.editor && pressRelease) {
      const isEditing = !!pressRelease.content_json;

      // needs to only run if updating
      if (isEditing && pressRelease.content_json) {
        try {
          const contentJson = JSON.parse(pressRelease.content_json) as Design;
          emailEditorRef.current.editor.loadDesign(contentJson);
        } catch (err) {
          Notify.create({
            title: t`Error loading your email. Please try again.`,
            type: 'error'
          });
        }
      }

      // even though the email editor is loaded (according to its callback)
      // it isn't fully loaded yet (it shows a loading spinner then the controls, so it's not actually loaded)
      // if the user jumps to step 2 or 3 the json and html data will be undefined until actually loaded, causing issues
      // this timeout tries to address that in a hacky way since their callback is useless
      // (it seems to load in 2.5 seconds on create and 1 second on update)
      setTimeout(() => {
        setEmailEditorLoaded(true);
      }, isEditing ? 1000 : 2500);
    }
  };

  // this useEffect is needed for when a user closes one email then opens another
  // react-email-editor doesn't load or reload the email design
  useEffect(() => {
    if (emailEditorRef?.current?.editor) {
      onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailEditorRef?.current?.editor]);

  return (
    <>
      <div className={classes.formControl}>
        <StandardTextField
          className={classes.input}
          error={validationErrors.subject}
          id="subject"
          label={t`Subject Line`}
          name="subject"
          onChange={(e: InputChangeEvent): void => {
            if (pressRelease) {
              setPressRelease({ ...pressRelease, subject: e.target.value });
            }
          }}
          onFocus={(): void => onEmailEditorBlur()}
          placeholder=""
          value={pressRelease?.subject}
        />
        <StandardTextField
          className={classes.input}
          id="from"
          label={t`From (optional)`}
          name="from"
          onChange={(e: InputChangeEvent): void => {
            if (pressRelease) {
              setPressRelease({ ...pressRelease, from_name: e.target.value }); // eslint-disable-line @typescript-eslint/naming-convention
            }
          }}
          onFocus={(): void => onEmailEditorBlur()}
          placeholder=""
          value={pressRelease?.from_name}
        />
      </div>
      <div>
        <Container error={validationErrors.content} submitAttempted={validationErrors.submitAttempted}>
          <Label><Trans>Message Content</Trans></Label>
          <div className={classnames('email-editor', classes.emailEditor, validationErrors.content && validationErrors.submitAttempted && classes.invalid)}>
            <EmailEditor
              ref={emailEditorRef}
              minHeight={250}
              onLoad={onLoad}
              projectId={12782}
              tools={{
                html: {
                  enabled: false
                }
              }}
            />
          </div>
          <InputError error={validationErrors.content} submitAttempted={validationErrors.submitAttempted} />
        </Container>
      </div>
    </>
  );
};
