import { sanitize } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from "react";

import renderModal from '@components/show_page/modals/renderModal';

function ManageLabelModal(props) {
  const [labelName, updateName] = useState(props.activeLabel.name);

  const updateLabel = () => {
    const { editLabel, activeLabel } = props;
    const newLabelData = { name: sanitize(labelName) };
    editLabel(activeLabel.key, newLabelData);
  };

  const deleteLabel = () => {
    const { removeLabel, activeLabel } = props;
    removeLabel(activeLabel.key, activeLabel.name);
  };

  const submitIfEnter = (e) => {
    if (e.key === 'Enter') {
      updateLabel();
    }
  };

  return (
    <div className="modal-content-wrapper manage-labels-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-label icon" />
        <h3 className="modal-title">
          <Trans>Edit Label</Trans>
      </h3>
      </div>
      <div className="modal-content-wrapper__body">
        <h3 className="body-title">
          {props.activeLabel.name}
        </h3>
        <label htmlFor="name">
          <Trans>Name</Trans>
          <input
            autoFocus
            className="input form-html-label"
            id="name"
            name="name"
            onChange={(e) => updateName(e.target.value)}
            onKeyUp={submitIfEnter}
            type="text"
            value={labelName}
          />
        </label>
        <div className="button-container ">
          <button
            className="delete-button"
            onClick={deleteLabel}
            type="button"
          >
            <span className="bff-trash" />
            <Trans>Delete</Trans>
          </button>
          <div>
            <button
              className="button tertiary sm t-close-modal"
              onClick={() => props.toggleModal()}
              type="button"
            >
              <Trans>Close</Trans>
            </button>
            <button
              className="button primary sm t-save"
              disabled={props.activeLabel.name === labelName}
              onClick={updateLabel}
              type="button"
            >
              <Trans>Save</Trans>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

ManageLabelModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  removeLabel: PropTypes.func.isRequired,
  editLabel: PropTypes.func.isRequired,
  activeLabel: PropTypes.shape({
    actionType: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

const ModalComponent = renderModal(ManageLabelModal, 'ManageLabelModal');
export default ModalComponent;
