import React, {
  FunctionComponent,
  ReactNode,
} from 'react';
import classnames from 'classnames';

interface RadioButtonProps {
  id: string;
  name: string;
  styleClassNames: { [key: string]: string };
  variantName: string;
  checked?: boolean;
  containerId?: string;
  disabled?: boolean;
  onChange?: (e: InputChangeEvent) => void;
  value?: string | string[] | number;
}

const baseName = 'radio-button';

const BaseRadioButton: FunctionComponent<RadioButtonProps> = (
  props,
) => {
  const {
    children,
    containerId,
    id,
    name,
    styleClassNames,
    variantName,
    checked = false,
    disabled = false,
    onChange,
    value,
  } = props;

  return (
    <div
      className={classnames(
        `bf-${baseName}__${variantName}`, // base-variant class for post consumption styling
        styleClassNames[variantName], // class for css modules
        {
          [styleClassNames.disabled]: disabled,
        },
      )}
    >
      <input
        aria-checked={checked}
        aria-labelledby={containerId}
        checked={checked}
        className={classnames(
          `bf-${baseName}__${variantName}--input`,
          [styleClassNames.input]
        )}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <label
        className={classnames(
          `bf-${baseName}__${variantName}--label`,
          [styleClassNames.label]
        )}
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  );
};

export default BaseRadioButton;
