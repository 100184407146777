import React from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import AssetManager from '@components/workbench/individual/asset_manager/main';
import Metadata from '@components/workbench/individual/metadata/metadata';

class MetadataExtractor extends React.Component {
  render() {
    return (
      <I18nProviderWrapper>
        <AssetManager extractMetadata>
          <Metadata />
        </AssetManager>
      </I18nProviderWrapper>
    );
  }
}

export default MetadataExtractor;
