import React, { FunctionComponent, ReactNode } from 'react';

import { BaseDialogProps, DialogTypes, DrawerAnimations } from '@components/library/dialog';
import { BaseDialog } from '@components/library/dialog/base_dialog';

type BaseDialogPropsExtended = Omit<BaseDialogProps, 'animation' | 'height' | 'next' | 'prev' | 'type' | 'width'>

interface DrawerDialogProps extends BaseDialogPropsExtended {
    animation: DrawerAnimations;
    children: ReactNode;
}

/**
 * A drawer for displaying nav links, forms, shopping carts, etc.
 * When passing in postion "left" prop, you should also be passing in animation "fade-in-from-left".
 * @param {DrawerDialogProps} props DrawerDialogProps
 */
export const DrawerDialog: FunctionComponent<DrawerDialogProps> = (props) => {
  const { animation, children, ...drawerDialogProps } = props;

  return (
    <BaseDialog
      {...drawerDialogProps}
      animation={animation}
      dialogClassName={drawerDialogProps.dialogClassName}
      type={DialogTypes.Drawer}
    >
      {children}
    </BaseDialog>
  );
};
