import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import { I18nProviderWrapper } from '../I18nProviderWrapper';

import { GifWizard } from './GifWizard';
import { UploadArea, UploadAreaProps } from './UploadArea';

interface HeaderImageUploaderProps {
  uploaderProps: UploadAreaProps;
}

export const HeaderImageUploader: FunctionComponent<HeaderImageUploaderProps> = ({ uploaderProps }) => {
  const [open, setOpen] = useState(false);

  return (
    <I18nProviderWrapper>
      <UploadArea {...uploaderProps} onClick={(): void => { setOpen(true); }} />
      <GifWizard
        gifHelpText={<Trans>GIFs with 4:1 aspect ratio and less than 10MB in size are recommended.</Trans>}
        id="header-image-customization"
        open={open}
        setOpen={setOpen}
        stillHelpText={<Trans>JPG, PNG, or WebPs with minimum size of 1920 x 480px are recommended</Trans>}
        title={t`Header Image`}
        uploaderProps={uploaderProps}
      />
    </I18nProviderWrapper>
  );
};
