/* eslint-disable react/require-default-props */
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// import Tooltip from "@components/common/tooltip/main";

const SearchFilterPins = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const { isVisible, setShowSearchPinsMenu, searchFilters, submitSearchQuery } = props;

  const applySearchCriteria = (pin) => {
    const inLabel = pin.label.toLowerCase().includes(searchInput.toLowerCase());
    const inQuery = pin.query.toLowerCase().includes(searchInput.toLowerCase());
    return inLabel || inQuery;
  };

  const generateSearchFilterPins = () => {
    const searchFilterItems = [];

    searchFilters.forEach((pin) => {
      if (!applySearchCriteria(pin)) { return undefined; }

      searchFilterItems.push(
        <li key={pin.key} className="search-filter-pin">
          <button
            onClick={() => submitSearchQuery(pin.query, null, { resetSearchOperator: true, searchKey: pin.key })}
            type="button"
          >
            <span className="bff-pin" />
            {/* <Tooltip
              className="search-pin-tooltip"
              delayShow={900}
              tooltipId={`search-pin-copy-${pin.key}`}
              tooltipContent={`${pin.label}: ${pin.query}`}
            > */}
            <h4 className="pin-copy">{pin.label}</h4>
            {/* </Tooltip> */}
          </button>
        </li>
      );

      return undefined;
    });

    if (!searchFilterItems.length) {
      return (
        <li className="no-search-pin-item">
          <h4 className="no-search-pin-copy"><Trans>No pinned searches found!</Trans></h4>
        </li>
      );
    }

    return searchFilterItems;
  };

  const placeholderCopy = t`Search ${searchFilters.length} pins`;

  return (
    <div className={`search-pins-menu ${isVisible ? 'visible' : ''}`}>
      <header className="search-pins-menu__header">
        <h3 className="search-pins-menu__header--title">
          <Trans>{searchFilters.length} Pinned Searches</Trans>
        </h3>
        <div className="search-pins-menu__header--search">
          <input
            className="brandfolder-input"
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder={placeholderCopy}
            value={searchInput}
          />
          <span className="bff-search" />
        </div>
        <button
          className="search-pins-menu__header--close"
          onClick={() => setShowSearchPinsMenu(false)}
          type="button"
        >
          <span className="bff-close" />
        </button>
      </header>
      <ul className="search-pins-menu__list bf-scroll-element">
        {generateSearchFilterPins()}
      </ul>
    </div>
  );
};

SearchFilterPins.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  searchFilters: PropTypes.arrayOf(PropTypes.shape({})),
  setShowSearchPinsMenu: PropTypes.func.isRequired,
  submitSearchQuery: PropTypes.func.isRequired,
};

SearchFilterPins.defaultProps = {
  searchFilters: [],
};

export default SearchFilterPins;
