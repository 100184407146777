import classnames from "classnames";
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from './styles/card.module.scss';

interface CardHeaderProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  showBorder?: boolean;
}

export const CardHeader: FunctionComponent<CardHeaderProps> = (props) => {
  const { children, showBorder = true } = props;

  return (
    <div className={classnames(classes.cardHeader, showBorder && classes.cardHeaderBorder)}>
      {children}
    </div>
  );
};
