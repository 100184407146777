
import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes } from 'react';

import './base_styles.scss';

interface InputErrorProps extends HTMLAttributes<HTMLParagraphElement> {
  error?: string;
  submitAttempted?: boolean;
}

export const InputError: FunctionComponent<InputErrorProps> = (props) => {
  const { className, error, submitAttempted, ...otherProps } = props;

  return (
    <p {...otherProps} className={classnames('input-error', { active: submitAttempted && error }, className)}>
      {error}
    </p>
  );
};
