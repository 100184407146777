import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState, FunctionComponent } from 'react';

import {
  EditOrCreate,
} from '@components/bulk_management/automation/AutomationEnums';
import {
  Action,
  FormState,
} from '@components/bulk_management/automation/AutomationTypes';
import { FieldSelector } from '@components/bulk_management/automation/form/FieldSelector';
import { FieldSummary } from '@components/bulk_management/automation/form/FieldSummary';
import { getFieldDetails } from '@components/bulk_management/automation/helpers';

interface AutomationFieldProps {
  errors: FormikErrors<FormState>;
  selectorValues: Partial<FormState> & Partial<Action>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setTouched: (touched: FormikTouched<FormState>, shouldValidate?: boolean) => void;
  touched: FormikTouched<FormState>;
  validateForm: (values?: any) => Promise<FormikErrors<FormState>>;
  actionIndex?: number;
  actions?: Action[];
  editOrCreate?: EditOrCreate;
  isOneAction?: boolean;
  isSectionDisabled?: boolean;
  isTriggerSet?: boolean;
  remove?: (index: number) => void;
  setIsTriggerSet?: SetStateDispatch<true>;
}

export const AutomationField: FunctionComponent<AutomationFieldProps> = (props) => {
  const { editOrCreate, selectorValues } = props;
  const { actionableId } = selectorValues;
  // we always default to open when creating but only default trigger to open when copying
  const initialOpenState = editOrCreate === EditOrCreate.Create
    || (editOrCreate === EditOrCreate.Copy && !actionableId);
  const [open, setOpen] = useState(initialOpenState);

  useEffect(() => {
    // TODO: right now this is a little awkward because it allows you to close the trigger
    // if it's not complete and then go and make changes to an action
    // the change to action would then kick open the trigger again
    // but the issue should resolve itself once we put validation in place
    // and disable editing actions until the trigger is set
    const {
      fieldKeys,
      values
    } = getFieldDetails(selectorValues);

    if (fieldKeys === null && values === null) {
      setOpen(true);
    }
  }, [selectorValues]);

  return (
    <>
      <FieldSelector
        {...props}
        closeField={setOpen}
        isOpen={open}
      />
      {!open && (
        <FieldSummary
          {...props}
          setFieldOpen={setOpen}
        />
      )}
    </>
  );
};
