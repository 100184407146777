import { t, Trans } from '@lingui/macro';
import { decode } from 'html-entities';
import React, { useEffect, useReducer, useState } from 'react';

import getAsset, { deleteAsset } from '@api/v4/assets/assets';
import {
  AssetResponse,
  AssetTypes
} from '@api/v4/assets/assetTypes';
import { removeFromCollection } from '@api/v4/bulk_actions/assets';
import { TaskServerResponseObject } from '@api/v4/tasks';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import {
  ActionTypes,
  PrintUI,
  WebFonts,
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import GenericFileEditForm from '@components/asset/modal/tabs/edit/main_pane/genericFileEditForm';
import reducer from '@components/asset/modal/tabs/edit/modalEditFormReducer';
import {
  ColorSideBar,
  ExternalMediumSideBar,
  GenericFileSideBar,
  PeopleSideBar,
  PressSideBar,
  TextSideBar,
  FontSideBar
} from '@components/asset/modal/tabs/edit/side_bar/assetSideBars';
import { AsyncComponentProps } from '@components/common/AsyncComponentProps';
import FetchControllersWrapper from '@components/common/fetch_controllers_wrapper';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import languagesMap, { LanguageMapEntry } from '@components/common/language_menu/languagesMap';
import UGTDisclaimer from '@components/common/language_menu/ugtDisclaimer';
import { BFLoader } from '@components/common/loader/main';
import { ToastContextProvider, ToastRenderer } from '@components/common/toast';
import { PrimaryButton, WarningButton } from '@components/library/button/index';

import { sendAction, TrackedAction } from '@helpers/datadog-rum';
import { bulkRefreshSections, refreshSection } from '@helpers/show_page_helpers';
import { deleteOptions, removeOptions, unsavedChangesOptions } from '@helpers/sweet_alert_options';

import detectChanges from './change_detection/changeEngine';
import handlePartialSuccess from './error_handling';
import { determineUGTLocale, readableSubmissionActions } from './helpers';
import { updateAiCaptionInGCS } from './main_pane/generative_ai_image_captions/fetch';
import submit from './submission';
import validate from './validation';

import './styles/_main.scss';

interface ModalEditFormContentProps extends AsyncComponentProps {
  assetKey: string;
  assetType?: AssetTypes;
  isCreate?: boolean;
  isTask?: boolean;
  labelKey?: string;
  orgApiKey?: string;
  overLimits?: boolean;
  printui?: PrintUI;
  sectionKey?: string;
  taskKey?: string | null;
  webFonts?: WebFonts;
}

interface AssetTypeContentMapping {
  [assetType: string]: {
    sidebar: JSX.Element;
    includeAdvancedOptions: boolean;
  };
}

/**
 * avoid syncing modal header title for now
 * todo: ensure that header is updated again after switching back to overview tab
const syncModalHeaderTitle = (newAssetName: string | undefined): void => {
  // update asset name text in modal header using vanilla JS
  const modalHeaderTitleNode = document.querySelectorAll('.s-attachment-title h2')[0];
  const previousAssetName = modalHeaderTitleNode ? modalHeaderTitleNode.textContent : undefined;

  if (newAssetName && previousAssetName && newAssetName !== previousAssetName) {
    modalHeaderTitleNode.textContent = newAssetName;
  }
};
*/
const ModalEditFormContent = (props: ModalEditFormContentProps): JSX.Element => {
  const {
    abortFetchControllers,
    assetKey,
    assetType,
    isTask,
    isCreate,
    labelKey,
    orgApiKey,
    overLimits,
    printui,
    sectionKey,
    taskKey,
    updateFetchControllers,
    webFonts,
  } = props;

  const initialReducerState = {
    initialData: null,
    editState: null,
    errorState: [],
    ugtLocale: determineUGTLocale(),
  };

  const [state, dispatch] = useReducer(reducer, initialReducerState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reliableAssetKey, setReliableAssetKey] = useState(assetKey);

  const assetTypeContentMapping: AssetTypeContentMapping = {
    [AssetTypes.Color]: {
      sidebar: <ColorSideBar />,
      includeAdvancedOptions: false,
    },
    [AssetTypes.ExternalMedium]: {
      sidebar: <ExternalMediumSideBar updateFetchControllers={updateFetchControllers} />,
      includeAdvancedOptions: false,
    },
    [AssetTypes.Font]: {
      sidebar: <FontSideBar webFonts={webFonts} />,
      includeAdvancedOptions: false,
    },
    [AssetTypes.GenericFile]: {
      sidebar: <GenericFileSideBar isTask={isTask} />,
      includeAdvancedOptions: true,
    },
    [AssetTypes.People]: {
      sidebar: <PeopleSideBar />,
      includeAdvancedOptions: false,
    },
    [AssetTypes.Press]: {
      sidebar: <PressSideBar />,
      includeAdvancedOptions: false,
    },
    [AssetTypes.Text]: {
      sidebar: <TextSideBar />,
      includeAdvancedOptions: false,
    },
  };

  useEffect(() => {
    const getAssetAsync = async (): Promise<void> => {
      const options = {
        assetKey,
        fields: 'assigned_users,data,content_automation_editor_link,design_huddle_editor_link,extension,thumbnail_override,watermark,cdn_url',
        include: isTask ? 'attachments,tags,task' : 'attachments,tags',
        params: {
          ugt_locale: state.ugtLocale, // eslint-disable-line @typescript-eslint/naming-convention
        }
      };

      const response: AssetResponse = await getAsset(options);

      // if we're editing an asset with a task, we need to get and set a tasks' task_users array
      // so that we can populate checked checkboxes on WorkspaceUserSection.tsx
      if (isTask && taskKey) {
        // only one task per asset
        const task = response.included.find((include) => include.type === 'tasks') as TaskServerResponseObject;
        if (task) {
          const filteredIncludes = response.included.filter((include) => include.type !== 'tasks');
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const taskUserKeys = response.data.attributes.assigned_users ? response.data.attributes.assigned_users.map(({ user_key }) => (user_key)) : [];
          task.attributes.task_users = [...taskUserKeys];
          response.included = [...filteredIncludes, task];
        }
      }

      dispatch({ type: ActionTypes.Initialize, payload: { printui, response } });
    };

    if (isCreate && isTask) {
      dispatch({ type: ActionTypes.NewTask, payload: { assetType } });
    } else if (isCreate && !isTask) {
      dispatch({ type: ActionTypes.NewAsset, payload: { assetType } });
    } else {
      getAssetAsync();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetKey, assetType, isCreate, state.ugtLocale, updateFetchControllers]);

  useEffect(() => {
    const triggerUnsavedChangesAlert = (event): void => {
      const isNavigating = Boolean(event?.detail?.direction);
      window.swal(unsavedChangesOptions({}), (confirm) => {
        if (confirm) {
          BFG.editTabUnsavedChanges = false;
          if (isNavigating) {
            BF.Asset.nextPrev(assetKey, event.detail.direction, state.ugtLocale); // navigate away
          } else {
            BF.dialog.dismiss(); // close asset modal
          }
        }
      });
    };

    window.addEventListener('unsavedChangesAlert', triggerUnsavedChangesAlert);

    return (): void => {
      abortFetchControllers(); // cancel outstanding fetch requests on unmount
      window.removeEventListener('unsavedChangesAlert', triggerUnsavedChangesAlert);
      BF.dialog.unmountTabComponents();
      // when the asset modal is unmounted (because it is closed), we need to always set editTabUnsavedChanges to false
      // this accounts for adding/creating/new asset in addition to the editing (within triggerUnsavedChangesAlert above)
      // this fixes when a user goes to add a new asset like a color, makes some changes, but closes the modal since
      // triggerUnsavedChangesAlert is not triggered during an add/create/new asset...
      // otherwise the user cannot then view an asset right after the above described add, change, then close modal operation
      // (this happens when BFG.editTabUnsavedChanges is checked within /show_page/sections/asset/asset.jsx)
      BFG.editTabUnsavedChanges = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitChangesAsync = async (): Promise<void> => {
    const validationErrors = validate(assetType || state.initialData.assetType, state.editState, state.initialData);
    if (validationErrors.length > 0) {
      dispatch({ type: ActionTypes.ValidationError, payload: { validationErrors } });
      setIsSubmitting(false);
      return;
    }

    if (isCreate && !isTask) {
      sendAction(TrackedAction.AssetModalEditTabCreateAsset);
    } else if (isCreate && isTask) {
      sendAction(TrackedAction.WorkspaceCreateTask);
    } else if (!isCreate && isTask) {
      sendAction(TrackedAction.WorkspaceUpdateTask);
    } else {
      sendAction(TrackedAction.AssetModalEditTabModifyAsset);
    }

    if (state.editState.aiGeneratedCaptionId) {
      updateAiCaptionInGCS(orgApiKey, state.editState.aiGeneratedCaptionId);
    }

    const requiredSubmissions = detectChanges(state, isSubmitting, isTask);

    try {
      const submissionResults = await submit(
        reliableAssetKey,
        updateFetchControllers,
        [...requiredSubmissions],
        state.ugtLocale,
        sectionKey,
        labelKey,
        isTask,
        taskKey
      );

      if (submissionResults.failures.length === 0) {
        if (isCreate) {
          refreshSection(sectionKey);
        } else {
          bulkRefreshSections([assetKey]);
        }

        const ugtLocaleForReload = determineUGTLocale(); // gets ugtLocale from local storage
        BF.Asset.fetchAsset(submissionResults.assetKey, ugtLocaleForReload).done((asset) => {
          BF.dialog.unmountTabComponents();
          BF.Asset.show(asset.data, ugtLocaleForReload);
        });
      } else {
        if (!isTask) {
          sendAction(TrackedAction.AssetModalEditTabSubmissionPartialSuccess);
        } else {
          sendAction(TrackedAction.WorkspaceCreateTaskPartialSuccess);
        }
        if (submissionResults.assetKey) {
          setReliableAssetKey(submissionResults.assetKey);
        }
        handlePartialSuccess(requiredSubmissions, submissionResults, dispatch);
        setIsSubmitting(false);
        Notify.create({
          type: 'error',
          title: t`Some of your changes were not saved. Please try again or contact support.`
        });
        submissionResults.failures.forEach((failure) => {
          const error = readableSubmissionActions[failure];
          sendAction(`Error submitting asset: ${error}`, {
            tags: {
              assetType: state.initialData.assetType,
              isNewAsset: (isCreate && !isTask) || false,
              isNewTask: (isCreate && isTask) || false,
              error
            },
            extra: {
              assetType: state.initialData.assetType,
              isNewAsset: (isCreate && !isTask) || false,
              isNewTask: (isCreate && isTask) || false,
              error
            }
          });
        });
      }
    } catch (error) {
      setIsSubmitting(false);
      sendAction(`Error submitting asset: ${error}`, {
        tags: {
          assetType: state.initialData.assetType,
          isNewAsset: (isCreate && !isTask) || false,
          isNewTask: (isCreate && isTask) || false,
          error
        },
        extra: {
          assetType: state.initialData.assetType,
          isNewAsset: (isCreate && !isTask) || false,
          isNewTask: (isCreate && isTask) || false,
          error
        }
      });
      Notify.create({
        type: 'error',
        title: t`Something went wrong. Please try again or contact support.`
      });
    }
  };

  const confirmDeleteAndRemove = (isCollection: boolean): void => {
    if (isCollection) {
      window.swal(removeOptions({ thingToRemove: state.initialData.asset.name }), async (confirm) => {
        if (confirm) {
          await removeFromCollection({ assetKeys: [assetKey], collectionKeys: [BFG.resource.key] });
          const resourceName = decode(BFG.resource.name);
          Notify.create({
            title: t`1 asset removed from: ${resourceName}.`,
            type: 'success'
          });
          bulkRefreshSections([assetKey]);
          BF.dialog.dismiss();
        }
      });
    } else {
      window.swal(deleteOptions({ thingToDelete: state.initialData.asset.name }), async (confirm) => {
        if (confirm) {
          await deleteAsset(assetKey);
          bulkRefreshSections([assetKey]);
          BF.dialog.dismiss();
        }
      });
    }
  };

  const OverLimitsDisclaimer = (): JSX.Element => (
    <div className="limits-disclaimer">
      <span className="bff-warning limits-disclaimer__icon" />
      <p className="limits-disclaimer__copy">
        <Trans>
          You've already uploaded the maximum number of assets for your current plan.&nbsp;
          <a
            className="upgrade-limits-link"
            href={`/organizations/${BFG.org_slug}/subscription`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Upgrade to increase your asset limit
          </a>
        </Trans>
      </p>
    </div>
  );

  const renderModalEditFormCoreContent = (providedAssetType: AssetTypes): JSX.Element => {
    const isCollection = BFG.resource.type === 'collection';
    const saveButton = (
      <span className="button-text">
        <Trans>Save Changes</Trans>
      </span>
    );
    const buttonContent = (
      <span className="button-text">
        {isCollection ? <Trans>Remove Asset</Trans> : <Trans>Delete Asset</Trans>}
      </span>
    );
    const selectedLanguage: LanguageMapEntry = languagesMap.find((langDetails) => (
      langDetails.locale === state.ugtLocale
    ));
    const disclaimerActive = (selectedLanguage && state.ugtLocale !== BFG.locales.ugtLocaleDefault) || overLimits
      ? 'disclaimer-active'
      : '';
    return (
      <assetModalContext.Provider value={{ state, dispatch }}>
        <div className="edit-tab-container">
          {(disclaimerActive && BFG.multiLanguageAssetDetailsEnabled) && (
            <UGTDisclaimer
              selectedLanguage={selectedLanguage}
            />
          )}
          {(overLimits && <OverLimitsDisclaimer />)}
          <div className={`edit-tab-flex-container ${disclaimerActive}`}>
            {assetTypeContentMapping[providedAssetType].sidebar}
            <GenericFileEditForm
              includeAdvancedOptions={assetTypeContentMapping[providedAssetType].includeAdvancedOptions}
              isCreate={isCreate}
              isTask={isTask}
              orgApiKey={orgApiKey}
            />
          </div>
          <div className="edit-tab-buttons-container">
            <PrimaryButton
              className={`asset-modal-edit-submit ${isTask ? 'task-edit-button' : ''}`}
              disabled={state.errorState.length > 0 || detectChanges(state, isSubmitting, isTask).length === 0}
              isLoading={isSubmitting}
              loadingCopy={t`Saving`}
              onClick={(): void => {
                setIsSubmitting(true);
                submitChangesAsync();
              }}
            >
              {isCreate && isTask && <Trans>Create Task</Trans>}
              {isCreate && !isTask && <Trans>Create Asset</Trans>}
              {!isCreate && saveButton}
            </PrimaryButton>
            {!isCreate && (
              <WarningButton
                className={`asset-modal-edit-${isCollection ? 'remove' : 'delete'}`}
                onClick={(): void => confirmDeleteAndRemove(isCollection)}
              >
                {buttonContent}
              </WarningButton>
            )}
          </div>
        </div>
      </assetModalContext.Provider>
    );
  };

  if (state.initialData) {
    if (isCreate) {
      return renderModalEditFormCoreContent(assetType || AssetTypes.GenericFile);
    }
    return renderModalEditFormCoreContent(state.initialData.assetType);
  }

  return <BFLoader />;
};

const ModalEditForm = (props: ModalEditFormContentProps): JSX.Element => (
  <I18nProviderWrapper>
    <ToastContextProvider>
      <FetchControllersWrapper>
        <ModalEditFormContent {...props} />
      </FetchControllersWrapper>
      <ToastRenderer />
    </ToastContextProvider>
  </I18nProviderWrapper>
);

export default ModalEditForm;
