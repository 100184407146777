import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { SelectableTrProps, Tr } from './Tr';
import { SelectableTableRow } from '../SelectableTableProps';

import classes from '../styles/selectable-table.module.scss';

interface TrSelectableProps extends SelectableTrProps {
  onRowSelect?: (row: SelectableTableRow) => void;
  row: SelectableTableRow;
}

export const TrSelectable: FunctionComponent<TrSelectableProps> = (props) => {
  const { children, onRowSelect, row, ...otherProps } = props;

  return (
    <Tr
      {...otherProps}
      aria-label={row.selectableAriaLabel}
      aria-pressed={row.selected}
      className={classnames('trSelectable', classes.trSelectable)}
      onClick={(): void => onRowSelect(row)}
      onKeyDown={(e): void => {
        if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
          onRowSelect(row);
        }
      }}
      role="button"
      tabIndex={0}
    >
      {children}
    </Tr>
  );
};
