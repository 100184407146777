export enum UploadAreaTypes {
  Asset = 'asset',
  Attachment = 'attachment',
  AttachmentVersion = 'attachment-version',
  AttachmentVersionStatus = 'attachment-version-status',
  BackgroundImage = 'background-image', // via _header_logo_edit.html.slim
  CardImage = 'card-image', // via _header_logo_edit.html.slim
  EmailLogoImage = 'email-logo-image', // via _header_logo_edit.html.slim
  FaviconImage = 'favicon-image', // via _header_logo_edit.html.slim
  GenericUpload = 'generic-upload',
  GuestUpload = 'guest-upload',
  HeaderImage = 'header-image', // via _header_logo_edit.html.slim
  LogoImage = 'logo-image', // via _header_logo_edit.html.slim
  Section = 'section',
  Watermark = 'watermark'
}
