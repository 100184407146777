import React from 'react';
import PropTypes from 'prop-types';

import NewReply from '@components/asset/modal/tabs/workflow/attachment_details/versions_sidebar/new_reply';

const ReplyContainer = ({
  cardEvent,
  mentionableUsers,
  onReply,
  parentId,
  setMentionableUsers,
  setShowEditMessage,
  showEditMessage,
}) => {
  const { version } = cardEvent;

  return (
    <NewReply
      mentionableUsers={mentionableUsers}
      onReply={onReply}
      parentId={parentId}
      setMentionableUsers={setMentionableUsers}
      setShowEditMessage={setShowEditMessage}
      showEditMessage={showEditMessage}
      versionKey={version}
    />
  );
};

ReplyContainer.propTypes = {
  cardEvent: PropTypes.shape({
    created_at: PropTypes.string,
    id: PropTypes.string,
    updated_at: PropTypes.string,
    version: PropTypes.string,
  }).isRequired,
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  onReply: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  showEditMessage: PropTypes.bool.isRequired,
  setMentionableUsers: PropTypes.func.isRequired,
  setShowEditMessage: PropTypes.func.isRequired,
};

ReplyContainer.defaultProps = {
  mentionableUsers: null
};

export default ReplyContainer;
