import { keyPressHelper } from '@brandfolder/utilities';
import React from "react";

import SearchBar from "./search_bar";
import AttachmentList from "./attachment_list";

export default class AttachmentBrowser extends React.Component {
  state = {
    searchQuery: null
  };

  updateSearchQuery = (searchQuery) => {
    this.setState({ searchQuery });
  };

  renderSearchNav() {
    return (
      <div className="adobe-attachment-browser-nav">
        <SearchBar
          resourceType={this.props.resourceType}
          searchQuery={this.state.searchQuery}
          selectedResource={this.props.selectedResource}
          updateSearchQuery={this.updateSearchQuery}
        />
        <div
          className="adobe-menu-toggle"
          onClick={this.props.toggleMenu}
          onKeyPress={keyPressHelper(this.props.toggleMenu)}
          role="button"
          tabIndex="0"
        >
          <span className="bff-menu" />
        </div>
      </div>
    );
  }

  renderAttachmentList() {
    return (
      <AttachmentList
        jwt={this.props.jwt}
        resourceType={this.props.resourceType}
        searchQuery={this.state.searchQuery}
        selectedResource={this.props.selectedResource}
      />
    );
  }

  render() {
    return (
      <div className="adobe-attachment-browser">
        {this.renderSearchNav()}
        {this.renderAttachmentList()}
      </div>
    );
  }
}
