import { CloseButton, brandfolderColorPalette } from '@brandfolder/react';
import classNames from 'classnames';
import React, { FunctionComponent, useEffect } from 'react';
import { styled } from 'styled-components';
import { ToastLooks } from './toastTypes';

const Toast = styled.div`
  align-items: center;
  border-radius: 4px;
  background: ${brandfolderColorPalette.neutrals[800]};
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  color: ${brandfolderColorPalette.neutrals[0]};
  display: flex;
  height: 44px;
  padding: 8px 12px;
  width: fit-content;

  .toast-text {
    font-size: 14px;
    margin: 0 16px 0 8px;
  }

  .bff-check-circle-filled {
    color: ${brandfolderColorPalette.success[600]};
    font-size: 16px;
  }

  .bff-alert-filled {
    color: ${brandfolderColorPalette.error[600]};
  }

  .bf-button__close {
    padding: 6px;

    &:hover {
      background-color: transparent;
    }

    .bff-close {
      color: ${brandfolderColorPalette.neutrals[0]};
      font-size: 12px;
    }
  }
`;

export interface AlertToastProps {
  closeToast: () => void;
  look?: ToastLooks;
  message?: string;
}

export const AlertToast: FunctionComponent<AlertToastProps> = ({
  closeToast,
  look = ToastLooks.Success,
  message = 'Success!',
}) => {

  useEffect(() => {
    const timeId = setTimeout(() => {
      closeToast();
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [closeToast]);

  const iconClasses = classNames({
    'bff-check-circle-filled': look === ToastLooks.Success,
    'bff-alert-filled': look === ToastLooks.Error,
  })

  return (
    <Toast>
      <span className={iconClasses} />
      <span className="toast-text">
        {message}
      </span>
      <CloseButton aria-label="close" onClick={closeToast} />
    </Toast>
  );
};
