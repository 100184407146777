import React, { FunctionComponent } from 'react';

import { Checkbox } from '@components/library/checkbox/index';
import BaseCheckbox from '../BaseCheckbox';
import styleClassNames from './styles.module.scss';

interface StandardCheckboxProps {
  containerId?: string;
}

export const StandardCheckbox: FunctionComponent<StandardCheckboxProps & Checkbox> = (
  props
) => {
  const baseProps = {
    ...props,
    styleClassNames,
    variantName: 'standard',
  };

  return <BaseCheckbox {...baseProps} />;
};
