import { Submit } from '@components/asset/modal/tabs/edit/EditTabTypes';

export const deletedAttachmentSuccess = {
  action: Submit.DeletedAttachment,
  results: "deleted-attachment-key"
};

export const deletedCustomFieldSuccess = {
  action: Submit.DeletedCustomFieldValue,
  results: "deleted-custom-field-value-key"
};

export const newCustomFieldsSuccess = {
  action: Submit.NewCustomFields,
  results: {
    data: [{
      id: "new-custom-field-value-key",
      type: "custom_field_values",
      attributes: {
        key: "new",
        value: "custom value"
      }
    }]
  }
};

export const tagsSuccess = {
  action: Submit.Tags,
  results: {}
};

export const updatedAssetSuccess = {
  action: Submit.UpdatedAsset,
  results: {
    id: "asset-id",
    type: "asset",
    data: {
      attributes: {
        name: "asset",
        description: "description",
        thumbnail_url: "thumbnail_url"
      }
    }
  }
};

export const updatedAttachmentSuccess = {
  action: Submit.UpdatedAttachment,
  results: {
    data: {
      id: 'updated-attachment-key',
      type: 'attachments',
      attributes: {
        extension: 'jpg',
        filename: 'updatedAttachment.jpg',
        height: 0,
        mimetype: '',
        position: 1,
        size: 0,
        url: 'updatedAttachment.jpg',
        width: 0
      }
    }
  }
};

export const updatedCustomFieldSuccess = {
  action: Submit.UpdatedCustomField,
  results: {
    data: {
      id: "custom-fields-updated-value",
      type: "custom_field_value",
      attributes: {
        value: "updated value"
      }
    }
  }
};
