import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, MouseEvent } from 'react';

import { Asset } from '@api/v4/assets/assetTypes';
import { getConfigurableExpiryUrl } from '@api/v4/attachments';
import { Attachment, isVideoType, PreviewType } from '@api/v4/attachments/attachmentTypes';
import { FontIcon, FontIcons } from '@components/library/icon';
import { WEB_FRIENDLY_ATTACHMENT_TYPES } from '@helpers/attachment_conversion';
import { getIsFilestackUrl } from '@helpers/filestack';

import classes from './styles/open-in-new-tab.module.scss';

interface GetShowOpenInNewTabOptions {
  asset: Asset;
  isDownloadable: boolean;
  isPreviewRenderError: boolean;
  threeSixtyViewer: boolean;
  selectedAttachment?: Attachment;
}

export const getShowOpenInNewTab = (options: GetShowOpenInNewTabOptions): boolean => {
  const { asset, isDownloadable, isPreviewRenderError, selectedAttachment, threeSixtyViewer } = options;
  const isProcessing = selectedAttachment?.is_processing;
  const previewType = selectedAttachment?.preview_type;
  const selectedExtension = selectedAttachment?.extension?.toLowerCase() || '';
  const showOpenInNewTab = selectedAttachment
  && (WEB_FRIENDLY_ATTACHMENT_TYPES.includes(selectedExtension) || previewType === PreviewType.PDF || previewType === PreviewType.OfficeDoc)
  && !!isDownloadable
  && !asset.view_only
  && !isPreviewRenderError
  && !threeSixtyViewer
  && !BFG.brandfolderSettings?.hide_asset_open_in_new_tab
  && !isVideoType(previewType)
  && !isProcessing
  && (previewType !== PreviewType.Audio);

  return showOpenInNewTab || false;
};

interface GetEnableOpenInNewTabOptions {
  showOpenInNewTab: boolean;
  selectedAttachment?: Attachment;
}

export const getEnableOpenInNewTab = (options: GetEnableOpenInNewTabOptions): boolean => {
  const { showOpenInNewTab, selectedAttachment } = options;

  const previewType = selectedAttachment?.preview_type;

  return showOpenInNewTab && previewType !== PreviewType.PDF;
};

type HTMLAnchorElementOmittedProps = Omit<HTMLAttributes<HTMLAnchorElement>, 'href' | 'rel' | 'target'>;

interface OpenInNewTabProps extends HTMLAnchorElementOmittedProps {
  asset: Asset;
  isDownloadable: boolean;
  isPreviewRenderError: boolean;
  threeSixtyViewer: boolean;
  selectedAttachment?: Attachment;
  selectedAttachmentKey?: string;
  useConfigurableExpiryUrls?: boolean;
  isImage?: boolean;
}

export const OpenInNewTab: FunctionComponent<OpenInNewTabProps> = (props) => {
  const {
    asset,
    children,
    isDownloadable,
    isPreviewRenderError,
    selectedAttachment,
    selectedAttachmentKey,
    threeSixtyViewer,
    useConfigurableExpiryUrls = false,
    isImage = false,
    ...otherProps
  } = props;

  const getOpenInNewTabHref = (attachment: Attachment, attachmentKey: string): string | undefined => {
    const previewType = attachment?.preview_type;
    const extension = attachment?.extension?.toLowerCase() || '';

    if (previewType === PreviewType.OfficeDoc || (previewType === PreviewType.Image && getIsFilestackUrl(attachment.url))) {
      const shareManifestKey = BFG.manifest?.key ? `?share_manifest_key=${BFG.manifest.key}` : '';
      return `/viewer/${BFG.brandfolder_slug}/${attachmentKey}${shareManifestKey}`;
    }
    if (previewType === PreviewType.Image || previewType === PreviewType.PDF || WEB_FRIENDLY_ATTACHMENT_TYPES.includes(extension)) {
      return attachment.url;
    }
    return undefined;
  };

  const showOpenInNewTab = getShowOpenInNewTab({
    asset,
    isDownloadable,
    isPreviewRenderError,
    selectedAttachment,
    threeSixtyViewer
  });

  const href = getOpenInNewTabHref(selectedAttachment, selectedAttachmentKey);

  const handleOnClick = async (e: MouseEvent<HTMLAnchorElement>): Promise<void> => {
    e.preventDefault();
    const expiryUrl = await getConfigurableExpiryUrl(selectedAttachmentKey);
    window.open(expiryUrl.attributes.configurable_expiry_url, '_blank');
  };

  if (!showOpenInNewTab || !href) return null;

  return (
    <a
      {...otherProps}
      className={classnames(
        classes['open-in-new-tab'],
        otherProps?.className
      )}
      href={isImage && selectedAttachment?.configurable_expiry_url || useConfigurableExpiryUrls && 'JavaScript:void(0)' || href}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={useConfigurableExpiryUrls ? (e): Promise<void> => handleOnClick(e) : undefined}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children || (
        <>
          <FontIcon aria-hidden icon={FontIcons.NewTab} iconSize={12} />{' '}
          <Trans>Open in new tab</Trans>
        </>
      )}
    </a>
  );
};
