// TODO: refactor this to clear up how we should be managing pill state
/* eslint-disable */
import React from 'react';
import { FormControl } from 'react-bootstrap';
import ReactDOM from 'react-dom';

export default class PillInput extends React.Component {
  state = {
    newPill: "",
    pills: this.pillArray,
    pendingDelete: false,
    active: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.pills != nextProps.pills) {
      this.setState({ pills: nextProps.pills });
    }
  }

  get pillArray() {
    return this.props.pills;
  }

  onChangeCallback(params) {
    if (this.props.onChangeCallback) {
      return this.props.onChangeCallback(params);
    }
  }

  redirectFocus(event) {
    ReactDOM.findDOMNode(this.refs.inputbox).focus();
    this.setState({ active: true });
  }

  preventFormSubmission(event) {
    // re-purpose Enter and Tab keys to submit the pill
    if (
      event.charCode === 13 ||
      (event.keyCode === 9 && !!event.target.value)
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.addNewPill(event);
    }
  }

  addNewPill(event) {
    const newPills = [];
    event.target.value.split(";").forEach((pill) => {
      if (!!pill && !this.state.pills.includes(pill)) {
        newPills.push(pill);
      }
    });
    if (newPills.length > 0) {
      const pillState = {
        ...this.state,
        newPill: "",
        pills: [
          ...this.state.pills,
          ...newPills
        ]
      };
      this.setState(pillState);
      this.onChangeCallback(pillState);
    }
  }

  deletePill(value) {
    let filteredPills = this.state.pills.filter(pill => {
      return pill !== value;
    });
    $("form#custom-fields-form").attr("data-locked", "true");
    this.state.pills = filteredPills;
    this.setState({ pills: filteredPills });
    this.onChangeCallback(this.state);
  }

  deletePreviousPill(event) {
    if (event.keyCode === 8 && !event.target.value) {
      if (this.state.pendingDelete) {
        this.state.pills.pop();
        this.setState({ pills: this.state.pills, pendingDelete: false });
        this.onChangeCallback(this.state);
      } else {
        this.setState({ pendingDelete: true });
      }
    }
  }

  handlePillChange(event) {
    if (event.target.value.indexOf(";") > -1) {
      this.addNewPill(event);
    } else {
      this.setState({ newPill: event.target.value, pendingDelete: false });
    }
  }

  toggleActive(event) {
    this.setState({ active: false });
  }

  renderPills() {
    if (this.state.pills.length > 0) {
      return this.state.pills.map((value, index) => {
        let pillStyle = {
          float: "left",
          padding: "3px"
        };

        if (index === this.state.pills.length - 1 && this.state.pendingDelete) {
          pillStyle.borderColor = "#42c3e7";
        } else {
          pillStyle.borderColor = "#dddfe3";
        }

        return (
          <div className="react-token existing" key={index} style={pillStyle}>
            <span className="react-token-label"> {value} </span>
            <span
              style={{ margin: "4px 0px 0px 6px" }}
              className="close"
              onClick={() => {
                this.deletePill(value);
              }}
            >
              <span className="bff-close" />
            </span>
          </div>
        );
      });
    }
  }

  render() {
    let inputStyle = {
      background: "transparent",
      float: "left",
      border: "none",
      width: "100%",
      minWidth: "222px",
      marginBottom: "0px",
      padding: "8px 10px"
    };

    let formGroupStyle = this.state.active
      ? { borderColor: "#6f6f6f", overflow: "hidden" }
      : { overflow: "hidden" };

    return (
      <div className="pill-input-component" onClick={event => this.redirectFocus(event)}>
        <div
          className="react-tokenfield form-control react-token-input"
          style={formGroupStyle}
        >
          {this.renderPills()}
          <FormControl
            ref="inputbox"
            className="react-token-input"
            style={inputStyle}
            placeholder={this.props.placeholderText || ""}
            type="text"
            value={this.state.newPill}
            onKeyPress={event => this.preventFormSubmission(event)}
            onKeyUp={event => this.deletePreviousPill(event)}
            onKeyDown={event => this.preventFormSubmission(event)}
            onChange={event => this.handlePillChange(event)}
            onBlur={event => {
              this.addNewPill(event);
              this.toggleActive(event);
            }}
          />
        </div>
      </div>
    );
  }
}
