import { fetchJson } from '@api/ApiHelper';
import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { Resource } from '@api/v4/resources/resourceTypes';
import { SortOptions } from '@components/show_page/sections/view_options/helper';


interface UpdateBrandfolderSettingsOptions {
  brandfolderKey: string;
  controlledCustomFieldsEnabled?: boolean;
  defaultOrderMethod?: SortOptions;
}

export const updateBrandfolderSettings = async ({
  brandfolderKey,
  controlledCustomFieldsEnabled,
  defaultOrderMethod,
}: UpdateBrandfolderSettingsOptions): Promise<ApiDatumResponse<Resource>> => (
  fetchJson({
    body: {
      data: {
        attributes: {
          ...controlledCustomFieldsEnabled !== undefined && { controlled_custom_fields_enabled: controlledCustomFieldsEnabled },
          ...defaultOrderMethod !== undefined && { default_order_method: defaultOrderMethod },
        }
      }
    },
    method: 'PUT',
    url: `/api/v4/brandfolders/${brandfolderKey}`
  })
);
