import { t } from '@lingui/macro';
import React, { useCallback, useEffect, useState } from 'react';

import { get } from '@api/v4/resources/metadata_conversions';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import Form from './form';
import Table from './table';

import './styles/metadata_conversions.scss';

const MetadataConversions = () => {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [editableKey, setEditableKey] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const isCollection = Boolean(BF.fx.collection().key);
  const resourceType = isCollection ? 'collection' : 'brandfolder';
  const resourceFunc = isCollection ? BF.fx.collection : BF.fx.brandfolder;
  const { key } = resourceFunc();

  const refreshData = useCallback(async () => {
    const metadataCustomFields = await get({
      resourceType,
      resourceKey: key,
    });
    const sortedMetadataConversions = metadataCustomFields.sort((a, b) => (
      (a.metadata_term.toLowerCase() < b.metadata_term.toLowerCase()) ? -1 : 1));

    setItems(sortedMetadataConversions);
  }, [key, resourceType]);

  useEffect(() => {
    refreshData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredItems = items.filter(({ metadata_term, key_term }) => (
    metadata_term && metadata_term.toLowerCase().includes(search.toLowerCase()))
    || (key_term && key_term.toLowerCase().includes(search.toLowerCase())));

  const selectedItem = items.filter(({ id }) => id === editableKey)[0];

  const renderForm = () => (
    <Form
      editableKey={editableKey}
      refreshData={refreshData}
      resourceKey={key}
      resourceType={resourceType}
      selectedItem={selectedItem}
      setEditableKey={setEditableKey}
      setShowForm={setShowForm}
    />
  );

  const renderTable = () => (
    <>
      <input
        className="brandfolder-input searchbar"
        onChange={({ target }) => setSearch(target.value)}
        placeholder={t`Search`}
        type="text"
        value={search}
      />

      <Table
        items={filteredItems}
        refreshData={refreshData}
        setEditableKey={setEditableKey}
        setShowForm={setShowForm}
      />
    </>
  );

  return (
    <I18nProviderWrapper>
      <div className="metadata_conversions">
        <div className="header">
          <h2>{t`Metadata Conversions`}</h2>
          { !showForm && (
            <button
              className="button primary"
              onClick={() => setShowForm(!showForm)}
              type="button"
            >
              <span className="bff-plus" style={{ marginRight: "10px" }} />
              {t`Create New`}
            </button>
          )}
        </div>
        <hr />
        {!showForm && (
          <div className="description">
            <p className="first">{t`Generate Custom Fields or Tags based on embedded metadata from each asset's first attachment`}</p>
            <p>{t`This conversion will not apply to existing assets, but will apply to assets uploaded after the conversion is created.`}</p>
            <p className="last">
              {BFG.currentUser.su
                ? t`To retroactively apply conversions, click the "Apply" button.`
                : t`Please contact support to apply metadata conversions to existing assets.`
              }
            </p>
          </div>
        )}
        { showForm ? renderForm() : renderTable() }
      </div>
    </I18nProviderWrapper>
  );
};

export default MetadataConversions;
