import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, ReactNode, useState } from 'react';

import { AssetsResponse, AssetTypesPascalCaseSingular } from '@api/v4/assets/assetTypes';
import { FlattenedCustomFieldKeyValuesMap } from '@api/v4/assets/customFieldTypes';
import { DependentCustomField } from '@api/v4/resources/CustomFieldKeysTypes';
import { handleUploadAssetToSection } from '@components/common/filestack_uploader/helpers';
import { UploadArea } from '@components/common/filestack_uploader/UploadArea';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';
import AssetUppy from '@components/common/uppy/asset_uppy';
import { PrimaryButton } from '@components/library/button';
import { FontIcon, FontIcons } from '@components/library/icon';
import { CanvaButtonApiV2 } from '@components/show_page/sections/asset/canva/CanvaTypes';
import { CreateNewAssetDialog } from '@components/show_page/sections/asset/CreateNewAssetDialog';
import { getHasRequiredCustomFields } from '@components/show_page/sections/asset/required-custom-fields-dialog-helper';
import { RequiredCustomFieldsDialog } from '@components/show_page/sections/asset/RequiredCustomFieldsDialog';
import '@components/show_page/sections/asset/styles/asset-uploader.scss';
import oldType from '@helpers/oldType';

import { CreateNewAssetTypes } from './custom-templates';

interface AssetUppyProps {
  activeLabelKey: string;
  canvaApi: CanvaButtonApiV2 | null;
  customFieldKeys: FlattenedCustomFieldKeyValuesMap | null;
  dependentCustomFields: DependentCustomField[] | null;
  defaultAssetType: string;
  libraryName: string;
  onAssetProcessing: (numAssets: number) => void;
  onNewAssets: (response: AssetsResponse) => void;
  sectionKey: string;
  showAsGrid: boolean;
}

interface AssetTypeDetails {
  buttonText: string;
  icon?: FontIcons;
  type?: CreateNewAssetTypes;
}

const onNewAssetNonGeneric = (
  activeLabelKey: string,
  defaultAssetType: string,
  sectionKey: string,
): void => {
  let newAssetUrl = `/${BFG.brandfolder_slug}/sections/${sectionKey}/ui/${oldType(defaultAssetType)}/new`;
  const urlParams = {
    ...BFG.resource.type === 'collection' && { collection_slug: BFG.resource.slug }, // eslint-disable-line @typescript-eslint/naming-convention
    ...activeLabelKey && { label_key: activeLabelKey }, // eslint-disable-line @typescript-eslint/naming-convention
  };

  if (Object.keys(urlParams).length > 0) {
    newAssetUrl = `${newAssetUrl}?${new URLSearchParams(urlParams).toString()}`;
  }

  BF.dialog.render(
    `New ${defaultAssetType === 'ExternalMedium' ? 'External Medium' : defaultAssetType}`,
    newAssetUrl,
    BF.handlerGroups.newAsset
  );
};

export const AssetUploader: FunctionComponent<AssetUppyProps> = ({
  activeLabelKey,
  canvaApi,
  customFieldKeys,
  dependentCustomFields,
  defaultAssetType,
  libraryName,
  onAssetProcessing,
  onNewAssets,
  sectionKey
}) => {
  const [showCreateNewAssetDialog, setShowCreateNewAssetDialog] = useState(false);
  const [showRequiredCustomFieldsDialog, setShowRequiredCustomFieldsDialog] = useState(false);

  const canvaEnabled = BFG.hasFeature('canva_button');
  const workspaceEnabled = BFG.hasFeature('workspace') && BFG.resource.is_workspace;
  const customTemplatesEnabled = BFG.hasFeature('custom_templates');
  const canvaOnly = canvaEnabled && !workspaceEnabled && !customTemplatesEnabled;
  const workspaceOnly = workspaceEnabled && !canvaEnabled && !customTemplatesEnabled;
  const featureDetection = canvaEnabled || workspaceEnabled || customTemplatesEnabled;

  const getAssetTypeDetails = (): AssetTypeDetails => {
    if (canvaOnly) {
      return {
        buttonText: t`Create from Canva`,
        icon: FontIcons.Palette,
        type: CreateNewAssetTypes.CanvaDesign,
      };
    }

    if (workspaceOnly) {
      return {
        buttonText: t`Create task`,
        icon: FontIcons.Task,
        type: CreateNewAssetTypes.Task
      };
    }

    return {
      buttonText: t`Create new asset`
    };
  };

  const renderCreateNewAsset = (): ReactNode => {
    const assetTypeDetails = getAssetTypeDetails();
    return (
      <>
        <PrimaryButton
          className="create-new-asset-button"
          icon={assetTypeDetails.icon}
          onClick={(): void => setShowCreateNewAssetDialog(true)}
        >
          {assetTypeDetails.buttonText}
        </PrimaryButton>
        <CreateNewAssetDialog
          canvaApi={canvaApi}
          hideBackButton={canvaOnly || workspaceOnly}
          initialStep={assetTypeDetails.type}
          onAssetProcessing={onAssetProcessing}
          onNewAssets={onNewAssets}
          sectionKey={sectionKey}
          setShowCreateNewAssetDialog={setShowCreateNewAssetDialog}
          showCreateNewAssetDialog={showCreateNewAssetDialog}
        />
      </>
    );
  };

  const dependentFieldIds = dependentCustomFields?.map((field) => field.child_key);
  const hasRequiredCustomFields = getHasRequiredCustomFields(customFieldKeys, dependentFieldIds, defaultAssetType);

  let requiredCustomFieldsUploadButton = null;
  if (hasRequiredCustomFields) {
    requiredCustomFieldsUploadButton = (
      <>
        <button
          className="generic-uploader--required-custom-fields"
          onClick={(): void => setShowRequiredCustomFieldsDialog(true)}
          type="button"
        >
          <FontIcon aria-hidden icon={FontIcons.Plus} />
          <p><Trans>Add assets</Trans></p>
        </button>
        <RequiredCustomFieldsDialog
          activeLabelKey={activeLabelKey}
          customFieldKeys={customFieldKeys}
          dependentCustomFields={dependentCustomFields}
          libraryName={libraryName}
          onAssetProcessing={onAssetProcessing}
          onNewAssets={onNewAssets}
          sectionKey={sectionKey}
          setShowRequiredCustomFieldsDialog={setShowRequiredCustomFieldsDialog}
          showRequiredCustomFieldsDialog={showRequiredCustomFieldsDialog}
        />
      </>
    );
  }

  if (!hasRequiredCustomFields
    && BFG.hasFeature('uppy_uploader_features')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    && defaultAssetType === AssetTypesPascalCaseSingular.GenericFile) {
    return (
      <div className="base-card uppy asset-uploader-component">
        <AssetUppy
          activeLabelKey={activeLabelKey}
          newResources={onNewAssets}
          onAssetProcessing={onAssetProcessing}
          sectionKey={sectionKey}
        />
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  if (defaultAssetType === AssetTypesPascalCaseSingular.GenericFile) {
    return (
      <div
        className={classnames(
          'base-card filestack-container asset-uploader-component',
          'set-min-height',
          {
            'canva-filestack-container': canvaEnabled && !workspaceEnabled,
            'create-options': workspaceEnabled && canvaEnabled,
            'create-task': workspaceEnabled && !canvaEnabled,
          },
        )}
      >
        {requiredCustomFieldsUploadButton || (
          <UploadArea
            id={sectionKey}
            onUploadDone={(files): void => {
              handleUploadAssetToSection({
                activeLabelKey,
                files,
                onAssetProcessing,
                onNewAssets,
                sectionKey
              });
            }}
            uploadType={UploadAreaTypes.Asset}
          />
        )}
        {featureDetection && renderCreateNewAsset()}
      </div>
    );
  }

  return (
    <div className="base-card generic asset-uploader-component">
      <button
        className="generic-uploader"
        onClick={(): void => onNewAssetNonGeneric(activeLabelKey, defaultAssetType, sectionKey)}
        type="button"
      >
        <span className="bff-plus" />
        <p><Trans>Add an Asset</Trans></p>
      </button>
    </div>
  );
};
