import { FontIcons } from '@brandfolder/react';
import { t } from '@lingui/macro';

import { fetchJson } from '@api/ApiHelper';
import {
  CustomizedTemplateApprovalStatuses,
  DesignHuddleExportPostResponse
} from '@api/v4/private/resources/design_huddles';
import { getCurrentUserIsAdminPlus, getCurrentUserIsAnonymous } from '@helpers/user';

/**
 * Get a resource url (either Brandfolder or Collection).
 * Can be used for redirecting with window.location.assign.
 * @returns string
 */
export const getResourceUrl = (): string => {
  if (BFG.resource.type === 'collection') {
    // /:brandfolder_slug/:collection_slug
    return `/${BFG.brandfolder_slug}/${BFG.resource.slug}`;
  }
  // /:brandfolder_slug
  return `/${BFG.resource.slug}`;
};

/**
 * Determine whether user can download a template
 * based on approval status and permission level
 * @returns boolean
 */
export const isDownloadAvailable = (approvalStatus: CustomizedTemplateApprovalStatuses): boolean => (
  !BFG.brandfolderSettings?.custom_templates_approvals_required
    || approvalStatus === CustomizedTemplateApprovalStatuses.Approved
    || getCurrentUserIsAdminPlus(BFG.currentUser)
);

/**
 * Determine whether a custom template can be visited
 * based on approvals setting and permission level
 * @returns boolean
 */
export const customTemplatesVisitable = (): boolean => (
  !(BFG.brandfolderSettings?.custom_templates_approvals_required && getCurrentUserIsAnonymous(BFG.currentUser))
);

export const approvedOrPending = (approvalState: CustomizedTemplateApprovalStatuses): boolean => (
  BFG.brandfolderSettings?.custom_templates_approvals_required
  && [CustomizedTemplateApprovalStatuses.Approved, CustomizedTemplateApprovalStatuses.Pending].includes(approvalState)
);

type ApprovalTransitions =
  | CustomizedTemplateApprovalStatuses.Approved
  | CustomizedTemplateApprovalStatuses.Pending
  | CustomizedTemplateApprovalStatuses.Unapproved;

export const APPROVAL_STATUS_STRINGS = (): Record<ApprovalTransitions, { fontIcon: FontIcons; label: string; successMessage: string }> => ({
  [CustomizedTemplateApprovalStatuses.Approved]: {
    fontIcon: FontIcons.Approve,
    label: t`Approved`,
    successMessage: t`You approved this template. Its creator will be emailed a link to download.`
  },
  [CustomizedTemplateApprovalStatuses.Pending]: {
    fontIcon: FontIcons.Clock,
    label: t`Awaiting approval`,
    successMessage: t`Your request has been sent to an admin. If approved, check your email for links to download.`
  },
  [CustomizedTemplateApprovalStatuses.Unapproved]: {
    fontIcon: FontIcons.Unapprove,
    label: t`Unapproved`,
    successMessage: t`You unapproved this template.`
  }
});

export const fetchThumbnailFromExport = async (
  projectId: string,
  resourcePath: string
): Promise<string> => {
  const createNewExportResponse = await fetchJson<DesignHuddleExportPostResponse>({
    body: {
      data: {
        dpi: 100,
        filename: 'thumbnail.jpg',
        format: 'jpg',
        project_id: projectId, // eslint-disable-line @typescript-eslint/naming-convention
      }
    },
    method: 'POST',
    url: `/api/v4/private/${resourcePath}/design_huddles/exports`
  });

  return createNewExportResponse.data.thumbnail_url;
};
