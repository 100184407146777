const oldType = (type) => {
  switch (type) {
    case "GenericFile":
      return "generic_files";
    case "Text":
      return "texts";
    case "ExternalMedium":
      return "external_media";
    case "Person":
      return "people";
    case "Press":
      return "press";
    case "Color":
      return "colors";
    case "Font":
      return "fonts";
    default:
      break;
  }
};

export default oldType;
