import { MutableRefObject } from 'react';
import { DropdownKeys } from '@components/library/dropdown';

type ListenerCallback = () => void;

export const manageDropdownListener = (
  ref: MutableRefObject<HTMLElement | null>,
  toggle: boolean,
  dropdownListenerCallback: (e: MouseEvent | KeyboardEvent) => void,
): ListenerCallback => {
  const handleInteractions = (e: MouseEvent | KeyboardEvent): void => {
    if (
      (e as KeyboardEvent).key === 'Escape'
      || (e as KeyboardEvent).key === 'Tab'
      || e.type === 'closeBFDropdowns'
      || (e.type === 'click' && ref.current && !ref.current.contains(e.target as HTMLElement)) // click outside of dropdown
    ) {
      dropdownListenerCallback(e);
    }
  };

  if (toggle) {
    window.addEventListener('click', handleInteractions);
    window.addEventListener('keyup', handleInteractions);
    window.addEventListener('closeBFDropdowns', handleInteractions);
  } else {
    window.removeEventListener('click', handleInteractions);
    window.removeEventListener('keyup', handleInteractions);
    window.removeEventListener('closeBFDropdowns', handleInteractions);
  }

  return (): void => {
    window.removeEventListener('click', handleInteractions);
    window.removeEventListener('keyup', handleInteractions);
    window.removeEventListener('closeBFDropdowns', handleInteractions);
  };
};

export const manageKeydownListener = (
  toggle: boolean,
  keydownCallback?: (key: string, e: KeyboardEvent) => void
): ListenerCallback | undefined => {
  if (!keydownCallback) return undefined;

  const handleKeypress = (e: KeyboardEvent): void => {
    const dropdownKeysStrings = Object.values(DropdownKeys) as string[];
    if (dropdownKeysStrings.includes(e.key)) {
      keydownCallback(e.key, e);
    }
  };

  if (toggle) {
    window.addEventListener('keydown', handleKeypress);
  } else {
    window.removeEventListener('keydown', handleKeypress);
  }

  return (): void => {
    window.removeEventListener('keydown', handleKeypress);
  };
};
