import { BrandfolderHexColors, ButtonLink, ButtonLooks, FontIconColors, FontIcons } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const BackButtonLink = styled(ButtonLink)`
  color: ${BrandfolderHexColors.PurplePopsicle};
  min-height: unset;
  padding: 0;

  span {
    font-weight: 500;
  }

  &:hover {
    background-color: transparent;
    color: #314CA9;
  }
`;

export interface BackButtonProps {
  handleNavigateBack?: () => void;
  href?: string;
}

export const BackButton: FunctionComponent<BackButtonProps> = ({
  handleNavigateBack = null,
  href = null
}) => (
  <BackButtonLink
    className="back-button"
    href={href}
    look={ButtonLooks.Default}
    onClick={(): void => {
      if (handleNavigateBack) {
        handleNavigateBack();
      }
    }}
    startIcon={FontIcons.ArrowLeft}
    startIconProps={{ iconSize: 12, color: FontIconColors.Primary }}
  >
    <Trans>Back</Trans>
  </BackButtonLink>
);
