import { t, Trans } from '@lingui/macro';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';

import {
  getGelatoCatalog,
  getGelatoCatalogs,
  getGelatoProducts,
  GelatoProductSearchFilters,
  GelatoProducts,
  GelatoProduct
} from '@api/gelato';
import { BFLoader } from '@components/common/loader/main';
import { ListDropdown, ListOption } from '@components/library/dropdown';

import { formatCamelOrPascalCaseToSpaces } from './helpers/formatting';

import './styles/GelatoPrintConfig.scss';

interface ListOptionWithTitle {
  options: ListOption[];
  title: string;
}

const DEFAULT_PRODUCT_ATTRIBUTE_FILTERS = {
  attributeFilters: {
    CoatingType: [],
    ColorType: [],
    Orientation: [],
    PaperFormat: [],
    PaperType: [],
    ProductStatus: [],
    ProtectionType: [],
    ShapeEdgeType: [],
    SpotFinishingType: [],
    Variable: []
  },
  limit: 5,
  offset: 0
};

interface GelatoPrintConfigProps {
  handleGelatoProductUid: (productUid: string) => void;
}

/**
 * Only PDF (check attachments)
 * Must be public
 */
export const GelatoPrintConfig: FunctionComponent<GelatoPrintConfigProps> = ({ handleGelatoProductUid }) => {
  const [catalog, setCatalog] = useState<ListOption | undefined>(undefined);
  const [catalogs, setCatalogs] = useState<ListOption[]>([]);
  const [catalogsLoading, setCatalogsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [productAttributeFilters, setProductAttributeFilters] = useState<GelatoProductSearchFilters>(DEFAULT_PRODUCT_ATTRIBUTE_FILTERS);
  const [productAttributes, setProductAttributes] = useState<ListOptionWithTitle[]>([]);
  const [productAttributesLoading, setProductAttributesLoading] = useState(false);
  const [productSearch, setProductSearch] = useState<GelatoProducts | undefined>(undefined);
  const [productSearchLoading, setProductSearchLoading] = useState(false);
  const [selectedProductUid, setSelectedProductUid] = useState('');

  useEffect(() => {
    const getCatalogs = async (): Promise<void> => {
      setCatalogsLoading(true);
      try {
        const response = await getGelatoCatalogs();

        const catalogOptions = response.data.map((c) => {
          const option: ListOption = { label: c.title, value: c.catalogUid };
          return option;
        });

        setCatalogs(catalogOptions);
      } catch (err) {
        setError(err);
      } finally {
        setCatalogsLoading(false);
      }
    };
    getCatalogs();
  }, []);

  useEffect(() => {
    const getCatalogProductAttributes = async (): Promise<void> => {
      if (catalog) {
        setProductAttributeFilters(DEFAULT_PRODUCT_ATTRIBUTE_FILTERS);
        setProductAttributesLoading(true);
        try {
          const response = await getGelatoCatalog(catalog.value as string);

          const productOptions = response.productAttributes.map((product) => {
            const options: ListOption[] = [];

            if (Array.isArray(product.values)) {
              product.values.map((value) => { // eslint-disable-line array-callback-return
                options.push({ value: value.productAttributeValueUid, label: value.title });
              });
            } else {
              Object.keys(product.values).map((key) => { // eslint-disable-line array-callback-return
                options.push({ value: product.values[key].productAttributeValueUid, label: product.values[key].title });
              });
            }

            const option: ListOptionWithTitle = { options, title: product.productAttributeUid };
            return option;
          });

          setProductAttributes(productOptions);
        } catch (err) {
          setError(err);
        } finally {
          setProductAttributesLoading(false);
        }
      } else {
        setProductAttributes([]);
        setProductAttributeFilters(DEFAULT_PRODUCT_ATTRIBUTE_FILTERS);
      }
    };
    getCatalogProductAttributes();
  }, [catalog]);

  useEffect(() => {
    const getProductSearchResults = async (): Promise<void> => {
      if (catalog) {
        setProductSearchLoading(true);
        try {
          const response = await getGelatoProducts(catalog.value as string, productAttributeFilters);
          setProductSearch(response);
        } catch (err) {
          setError(err);
        } finally {
          setProductSearchLoading(false);
        }
      } else {
        setProductSearch(undefined);
      }
    };
    getProductSearchResults();
  }, [catalog, productAttributeFilters]);

  const renderProductAttribute = (product: ListOptionWithTitle): JSX.Element | null => {
    const { options, title } = product;

    const listOptions: ListOption[] = options.map((option) => ({
      ...option,
      // gelato api returns some blank labels, but with a value (ugh)
      label: option.label || option.value
    }));

    // NOTE: a gelato catalog product attribute may have only one option with a value of "none", "activated", or "no"
    // we don't need to search on "none", "activated", or "no" so we hide those selects
    if (listOptions.length === 0 || (listOptions.length === 1 && ['none', 'activated', 'no'].includes(listOptions[0].value as string))) {
      return null;
    }

    // these product attributes should not display at the request of natalie at gelato
    // (these are internal api attributes only)
    if (title === 'ProductStatus' || title === 'State' || title === 'Variable') {
      return null;
    }

    const filter = productAttributeFilters.attributeFilters[title] as string[];
    const value = filter && filter.length > 0 ? listOptions.find((option) => option.value === filter[0]) : undefined;

    return (
      <Fragment key={title}>
        <div className="gelato-listdropdown">
          <label className="bf-label bf-label--primary" htmlFor={title}>{formatCamelOrPascalCaseToSpaces(title)}</label>
          <ListDropdown
            className="gelato-listdropdown__dropdown"
            onChange={(option: ListOption | null): void => {
              setProductAttributeFilters({
                ...productAttributeFilters,
                attributeFilters: {
                  ...productAttributeFilters.attributeFilters,
                  [title]: option ? [option.value] : []
                }
              });
            }}
            openOnClick
            openOnHover={false}
            options={listOptions}
            placeholder={t`Select ${formatCamelOrPascalCaseToSpaces(title).toLowerCase()}`}
            searchable
            value={value}
          />
        </div>
      </Fragment>
    );
  };

  const renderProductSelection = (product: GelatoProduct): JSX.Element | null => {
    const { attributes, productUid } = product;

    const renderedAttributes = Object.entries(attributes).map((attribute) => {
      const title = attribute[0];

      // these product attributes should not display at the request of natalie at gelato
      // (these are internal api attributes only)
      if (title === 'ProductStatus' || title === 'State' || title === 'Variable') {
        return null;
      }

      return (
        <span key={title} className="attribute">
          <span className="attribute__label">{formatCamelOrPascalCaseToSpaces(title)}:</span>{' '}
          <span className="attribute__value">{attribute[1]}</span>
        </span>
      );
    });

    return (
      <li key={productUid} className="gelato-product">
        <button
          aria-pressed={selectedProductUid === productUid}
          className="gelato-product__button"
          onClick={(): void => {
            handleGelatoProductUid(productUid);
            setSelectedProductUid(productUid);
          }}
          type="button"
        >
          {renderedAttributes}
          <span className="attribute"><span className="attribute__label">Product UID:</span> <span className="attribute__value">{productUid}</span></span>
        </button>
      </li>
    );
  };

  const step1 = (
    <>
      <h3 className="gelato-print-config__step-titles"><Trans>Step 1: Select a catalog</Trans></h3>
      <div className="gelato-listdropdown">
        <label className="bf-label bf-label--primary" htmlFor="catalogs"><Trans>Catalog</Trans></label>
        <ListDropdown
          className="gelato-listdropdown__dropdown"
          onChange={(option): void => {
            setCatalog(option);
          }}
          openOnClick
          openOnHover={false}
          options={catalogs}
          placeholder={t`Select a catalog...`}
          searchable
          value={catalog}
        />
      </div>
    </>
  );

  const step2 = (
    <>
      <h3 className={`gelato-print-config__step-titles ${!catalog ? 'disabled' : ''}`}>
        <Trans>Step 2: Select your product attributes</Trans>
      </h3>
      {productAttributesLoading && <BFLoader />}
      {catalog && !productAttributesLoading && (
        <div className="gelato-listdropdowns">
          {productAttributes.map((product) => renderProductAttribute(product))}
        </div>
      )}
    </>
  );

  const step3 = (
    <>
      <h3 className={`gelato-print-config__step-titles ${!catalog ? 'disabled' : ''}`}>
        <Trans>Step 3: Select a product</Trans>
      </h3>
      {(productAttributesLoading || productSearchLoading) && <BFLoader />}
      {!productAttributesLoading && !productSearchLoading && productSearch && (
        <>
          {productSearch.products.length > 0 && (
            <ul>
              {productSearch.products.map((product) => renderProductSelection(product))}
            </ul>
          )}
          {productSearch.products.length === 0 && (
            <p className="gelato-print-config__error-state">
              <span className="bff-warning" />
              <Trans>No products found. Please change your product attributes and try again.</Trans>
            </p>
          )}
        </>
      )}
    </>
  );

  return (
    <div className="gelato-print-config">
      {catalogsLoading ? <BFLoader /> : (
        <>
          {error && <p className="gelato-print-config__error-state"><span className="bff-warning" />{error.message}</p>}
          {!error && (
            <div className="gelato-print-config__wrapper">
              {step1}
              {step2}
              {step3}
            </div>
          )}
        </>
      )}
    </div>
  );
};
