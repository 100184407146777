import fetchJSON from '@api/api_helper';

import { BoardsResponse, BoardResponse } from '@api/v4/boards/boardsTypes';
import { AssetsResponse } from '@api/v4/assets/assetTypes';
import { RequestOptions } from '@api/v4/apiHelperTypes';

const getAllBoardsUrl = (): string => '/api/v4/boards';

const getBoardUrl = (boardId: string): string => `/api/v4/boards/${boardId}/assets`;

const mutateBoardUrl = (boardId: string): string => `/api/v4/boards/${boardId}`;

interface GetBoardsParams {
  options?: RequestOptions;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
// the ' = {} ' at the end of the destuctured parameters ensures it can be called with no arguments
// NOT related to the GetBoardsParams interface
export const getAllBoards = ({ options = {} }: GetBoardsParams = {}): Promise<BoardsResponse> => {
  const appendedOptions = {
    ...options,
    params: {
      ...options.params,
      // 3000 records per request
      per: 3000
    }
  };
  return fetchJSON(getAllBoardsUrl(), appendedOptions, (): void => { /* no op */ });
};

interface GetBoardParms extends GetBoardsParams {
  boardId: string;
}

// this does not have = {} because we want to ensure an error is thrown if boardId is not supplied
export const getBoard = ({ boardId, options = {} }: GetBoardParms): Promise<AssetsResponse> => {
  const appendedOptions = {
    ...options,
    params: {
      ...options.params,
      // 3000 records per request
      per: 3000
    }
  };
  return fetchJSON(getBoardUrl(boardId), appendedOptions, (): void => { /* no op */ });
};

interface UpdateBoardParams {
  boardId: string;
  operation?: string;
  assetIds?: Set<string>;
  boardTitle?: string;
}

// if we send an empty string boardTitle, the API interprets that as no title change.
// TODO make 'TITLE' an operation and require it on the interface
export const updateBoard = ({ boardId, boardTitle = '', assetIds = new Set(), operation = '' }: UpdateBoardParams): Promise<BoardResponse> => {
  const appendedOptions = {
    body: {
      data: {
        attributes: {
          name: boardTitle.trim(),
          asset_ids: Array.from(assetIds),
          operation
        }
      },
      include: '',
      fields: 'thumbnail_url,asset_count'
    },
    method: 'PUT'
  };

  return fetchJSON(mutateBoardUrl(boardId), appendedOptions, () => { /* no op */ })
    .then((response): Promise<BoardResponse> => response)
    .catch((error) => {
      console.log(error);
      console.log('update on api/v4/boards has failed');
      return error;
    });
};

export const deleteBoard = (options: { boardId: string }): Promise<object> => {
  const { boardId } = options;
  const appendedOptions = {
    method: 'DELETE'
  };

  return fetchJSON(mutateBoardUrl(boardId), appendedOptions, () => { /* no op */ })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      console.log('delete on api/v4/boards has failed');
    });
};

interface CreateBoardParams {
  boardTitle: string;
  assetIds?: Set<string>;
}
export const createBoard = ({ boardTitle, assetIds = new Set() }: CreateBoardParams): Promise<BoardResponse> => {
  const appendedOptions = {
    body: {
      data: {
        attributes: {
          name: boardTitle.trim(),
          asset_ids: Array.from(assetIds)
        }
      },
      include: '',
      fields: 'thumbnail_url,asset_count'
    },
    method: 'POST'
  };

  return fetchJSON(getAllBoardsUrl(), appendedOptions, () => { /* no op */ })
    .then((response): BoardResponse => response)
    .catch((error) => {
      console.log(error);
      console.log('create on api/v4/boards has failed');
    });
};
