import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes } from 'react';

import classes from './styles/asset-details-rule.module.scss';

type AssetDetailsRuleProps = HTMLAttributes<HTMLHRElement>;

export const AssetDetailsRule: FunctionComponent<AssetDetailsRuleProps> = (props) => (
  <hr
    {...props}
    className={classnames(
      classes['asset-details-rule'],
      props.className
    )}
    role="presentation"
  />
);
