/* eslint-disable @typescript-eslint/naming-convention */
import { fetchJson } from '@api/ApiHelper';
import { snakifyObjectKeys } from '@components/library/utils';
import { TaskServerBase } from '@api/v4/tasks';
import { Locale } from '@components/common/language_menu/languagesMap';
import { TaskChanges } from '@components/asset/modal/tabs/edit/change_detection/taskChangeEngine';
import { TaskClientBase, TaskServerGetResponse, TaskServerPostResponse, TaskServerPutResponse } from './TaskTypes';

/**
 * Create/POST a Task under a Workspace.
 * See app/controllers/api/v4/workspaces/tasks_controller.rb and
 * app/lib/workspaces/task_generator.rb for allowed and required params.
 * @param assetName string
 * @param sectionKey string
 * @param task Partial<TaskChanges>
 * @param ugtLocale Locale | ''
 * @param workspaceKey string
 * @returns Promise<TaskServerPostResponse>
 */
export const createTask = async (
  assetName: string,
  sectionKey: string,
  task: Partial<TaskChanges>,
  ugtLocale: Locale | '',
  workspaceKey: string
): Promise<TaskServerPostResponse> => {
  try {
    const attributes = snakifyObjectKeys<Partial<TaskClientBase>, Partial<TaskServerBase>>(task.data);

    const result = await fetchJson<TaskServerPostResponse>({
      body: {
        data: {
          attributes: [{
            ...attributes,
            attachments: task.attachments || [],
            name: assetName,
            user_keys: attributes.task_users
          }]
        },
        section_key: sectionKey // eslint-disable-line @typescript-eslint/naming-convention
      },
      method: 'POST',
      params: {
        queue_priority: 'high',
        ugt_locale: ugtLocale
      },
      url: `/api/v4/workspaces/${workspaceKey}/tasks`
    });

    return await Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * Get a Task with included Users
 * @param taskKey string
 * @returns Promise<TaskServerGetResponse>
 */
export const getTask = async (taskKey: string): Promise<TaskServerGetResponse> => {
  try {
    const result = await fetchJson<TaskServerGetResponse>({
      include: 'users',
      method: 'GET',
      url: `/api/v4/tasks/${taskKey}`
    });

    return await Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * Update/PUT a Task.
 * @param task Partial<TaskClientBase>
 * @param taskKey string
 * @param ugtLocale Locale | ''
 * @returns Promise<TaskServerPutResponse>
 */
export const updateTask = async (
  task: Partial<TaskClientBase>,
  taskKey: string,
  ugtLocale: Locale | ''
): Promise<TaskServerPutResponse> => {
  try {
    const attributes = snakifyObjectKeys<Partial<TaskClientBase>, Partial<TaskServerBase>>(task);

    const result = await fetchJson<TaskServerPutResponse>({
      body: {
        data: {
          attributes: {
            ...attributes,
            user_keys: attributes.task_users
          }
        }
      },
      method: 'PUT',
      params: {
        queue_priority: 'high',
        ugt_locale: ugtLocale
      },
      url: `/api/v4/tasks/${taskKey}`
    });

    return await Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};
