import { Trans } from '@lingui/macro';
import React, { useState } from 'react';

import { PortalCardSubTypes } from '@api/v4/private/PortalCardTypes';
import { TextButton } from '@components/library/button/index';

import LinkForm from './link_form';

interface LinkEmptyTemplateProps {
  triggerCardsRefresh: () => void;
}

const LinkEmptyTemplate: React.FunctionComponent<LinkEmptyTemplateProps> = ({
  triggerCardsRefresh
}) => {
  const [linkSubtype, setLinkSubType] = useState<PortalCardSubTypes>(PortalCardSubTypes.InternalLink);
  const [showForm, setShowForm] = useState(false);
  const toggleForm = (): void => setShowForm((prevState) => !prevState);

  return (
    <aside className="link-sidebar edit">
      <div className="link-sidebar__edit-links">
        <h3 className="h3-bold-styling"><Trans>Other Portals</Trans></h3>
        <p><Trans>Use this area to link out to other portals within your Org.</Trans></p>
        <TextButton
          icon="bff-plus"
          iconSize={12}
          onClick={(): void => {
            setLinkSubType(PortalCardSubTypes.InternalLink);
            toggleForm();
          }}
        >
          <Trans>Add Portal Link</Trans>
        </TextButton>
      </div>
      <div className="link-sidebar__edit-links">
        <h3 className="h3-bold-styling"><Trans>Additional Links</Trans></h3>
        <p><Trans>Use this area for easy access to additional links.</Trans></p>
        <TextButton
          icon="bff-plus"
          iconSize={12}
          onClick={(): void => {
            setLinkSubType(PortalCardSubTypes.ExternalLink);
            toggleForm();
          }}
        >
          <Trans>Add Link</Trans>
        </TextButton>
      </div>
      {showForm && (
        <LinkForm
          closeModal={toggleForm}
          defaultSubType={linkSubtype}
          triggerCardsRefresh={triggerCardsRefresh}
        />
      )}
    </aside>
  );
};

export default LinkEmptyTemplate;
