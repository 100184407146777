import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface ShowTagsProps {
  defaultEnabled: boolean | null;
}

const ShowTags: FunctionComponent<ShowTagsProps> = ({ defaultEnabled }) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled === null || defaultEnabled}
      description={<Trans>Only applies to assets in Collections and Share Links</Trans>}
      errorTitle={t`Error updating Tags viewing permissions. Please contact support.`}
      id="show-tags"
      successTitle={t`Tags viewing permissions updated!`}
      title={t`Allow guest and public users to see Tags`}
      updateResourceKey="showTags"
    />
  </I18nProviderWrapper>
);

export default ShowTags;
