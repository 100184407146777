import fetchJSON from '@api/api_helper';

export default (assetId, { collectionKey, manifest }) => (
  fetchJSON(`/api/v3/assets/${assetId}/download`, {
    params: {
      asset_keys: assetId,
      collection_key: collectionKey,
      manifest,
    }
  })
);
