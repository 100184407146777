import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { StandardAvatarProps } from '@components/library/avatar';
import { BaseAvatar } from '../base';

import classes from './styles/standard-avatar.module.scss';

export const StandardAvatar: FunctionComponent<StandardAvatarProps> = (props) => {
  const { className, ...otherProps } = props;

  return (
    <BaseAvatar
      {...otherProps}
      className={classnames(
        classes.avatar,
        className
      )}
      variantName="standard"
    />
  );
};
