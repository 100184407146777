import {
  BadgePill,
  BrandfolderHexColors,
  FontColors,
  FontIcon,
  FontIconPositions,
  FontIcons,
  FontWeights,
  Placements,
  StandardText,
  StandardTooltip,
  brandfolderColorPalette
} from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const PoweredByAiBadgeWrapper = styled.div`
  .powered-by-ai-badge-wrapper {
    .bf-pill {
      height: 20px;

      .bf-pill__content {
        padding: 6px;

        .bf-text {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
`;

export const PoweredByAiBadge: FunctionComponent = () => (
  <PoweredByAiBadgeWrapper>
    <StandardTooltip
      id="powered-by-ai-tooltip"
      placement={Placements.TopCenter}
      tooltip={
        <Trans>
          Our AI recognizes photos that have people, but photos will only be tagged with a person’s name after they’ve been reviewed and tagged on this page.
        </Trans>
      }
      wrapperProps={{
        className: 'powered-by-ai-badge-wrapper'
      }}
    >
      <BadgePill
        backgroundColor={brandfolderColorPalette.primary[100]}
        fontColor={BrandfolderHexColors.PurplePopsicle}
      >
        <FontIcon
          icon={FontIcons.SparkleFilled}
          iconSize={12}
          position={FontIconPositions.Left}
        />
        <StandardText color={FontColors.Primary} weight={FontWeights.Medium}>
          <Trans>Powered by AI</Trans>
        </StandardText>
      </BadgePill>
    </StandardTooltip>
  </PoweredByAiBadgeWrapper>
);
