import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { OverflowAvatar, StandardAvatar } from '@components/library/avatar';
import { BaseAvatarsProps } from '@components/library/avatars';

const defaultShowLimit = 4;

const baseName = 'avatars';

export const BaseAvatars: FunctionComponent<BaseAvatarsProps> = (props) => {
  const {
    after,
    limit = defaultShowLimit,
    overflowAvatarProps,
    showAfter = true,
    showOverflow = true,
    standardAvatarProps,
    users,
    variantName,
    ...otherProps
  } = props;

  const count = limit && users.length > limit ? users.length - limit : 0;

  return (
    <div
      {...otherProps}
      className={classnames(
        `bf-${baseName}__${variantName}`,
        otherProps && otherProps.className
      )}
    >
      {users.slice(0, limit || defaultShowLimit).map((user) => (
        <StandardAvatar
          key={user.email}
          {...standardAvatarProps}
          {...user}
        />
      ))}
      {!!count && showOverflow && (
        <OverflowAvatar
          {...overflowAvatarProps}
          count={count}
        />
      )}
      {after && showAfter && after}
    </div>
  );
};
