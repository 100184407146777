import { fetchJson } from '@brandfolder/react';
import { Dispatch, SetStateAction } from 'react';

import { CreateLimitData } from '../customLimitsTypes';

const limitsPostUrl = (
  limitType: string,
  orgKey: string,
  resourceType: string
): string =>
  `api/v4/organizations/${orgKey}/limits/${resourceType}/${limitType}`;

export const postLimits = async (
  folder: CreateLimitData,
  limitType: string,
  orgKey: string,
  setLimitsSuccess: Dispatch<SetStateAction<boolean>>
): Promise<void> => {
  const resourceType = folder.resourceType.slice(0, -1);
  const bearerToken = BFG.BF_Token;

  try {
    await fetchJson({
      body: {
        data: [
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            resource_id: folder.resource_id,
            value: folder.value,
          },
        ],
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      headers: { Authorization: 'Bearer ' + bearerToken },
      method: 'POST',
      url: limitsPostUrl(limitType, orgKey, resourceType),
    }).then(() => setLimitsSuccess(true));
  } catch (err) {
    setLimitsSuccess(false);
  }
};
