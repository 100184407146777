import BlitlineService from 'simple_blitline_node';
import { v4 as uuidv4 } from 'uuid';

import MetadataStandards from './metadata_standards';

const BLITLINE_VERSION = 1.23;
const WEB_VIEWABLE = ['png', 'jpg', 'gif'];
const NO_CMYK_SUPPORT = ['png', 'gif'];
const FILETYPE_ALIASES = { jpeg: 'jpg', tif: 'tiff', postscript: 'ai', 'svg+xml': 'svg' };

// http://helpdocs.blitline.com/article/Mk6sdI16XI-formats
const SUPPORTED_FILETYPES = [
  'png', 'jpg', 'tiff', 'bmp', 'pdf', 'psd',
  'gif', 'webp', 'cr2', 'cr3', 'dcm', 'dicom', 'ai',
  'svg', 'eps', 'arw', 'nef', 'orf', 'rw2'
];

const CONVERSION_MAPPINGS = {
  png: ['png', 'jpg', 'gif', 'svg'],
  jpg: ['jpg', 'png', 'gif', 'svg'],
  tiff: ['tiff', 'png', 'jpg'],
  bmp: ['bmp', 'png', 'jpg'],
  pdf: ['pdf', 'png', 'jpg'],
  psd: ['psd', 'png', 'jpg'],
  webp: ['webp', 'png', 'jpg'],
  cr2: ['cr2', 'png', 'jpg'],
  cr3: ['cr3', 'png', 'jpg'],
  dcm: ['dcm', 'png', 'jpg'],
  dicom: ['dicom', 'png', 'jpg'],
  ai: ['ai', 'png', 'jpg'],
  svg: ['svg', 'png', 'jpg'],
  eps: ['eps', 'png', 'jpg'],
  gif: ['gif', 'png', 'jpg'],
  arw: ['arw', 'png', 'jpg'],
  nef: ['nef', 'png', 'jpg'],
  orf: ['orf', 'png', 'jpg'],
  rw2: ['rw2', 'png', 'jpg']
};

export default class Blitline {
  constructor(url, filetype) {
    this.client = new BlitlineService();
    this.url = url;
    this.filetype = Blitline.normalizeFiletype(filetype);
    this.extractMetadata = this.extractMetadata.bind(this);
    this.extractColors = this.extractColors.bind(this);
    this.extractTags = this.extractTags.bind(this);
    this.extractText = this.extractText.bind(this);
    this.thumbnail = this.thumbnail.bind(this);
    this.convertTo = this.convertTo.bind(this);
  }

  static hasSupportFor(filetype) {
    const normalized_filetype = this.normalizeFiletype(filetype);
    return SUPPORTED_FILETYPES.includes(normalized_filetype);
  }

  static conversionOptionsFor(filetype) {
    const normalized_filetype = this.normalizeFiletype(filetype);
    return CONVERSION_MAPPINGS[normalized_filetype];
  }

  static webViewable(filetype) {
    const normalized_filetype = this.normalizeFiletype(filetype);
    return WEB_VIEWABLE.includes(normalized_filetype);
  }

  static cmykCompatible(filetype) {
    const normalized_filetype = this.normalizeFiletype(filetype);
    return !NO_CMYK_SUPPORT.includes(normalized_filetype);
  }

  static normalizeFiletype(filetype) {
    const normalized_filetype = filetype.toLowerCase();
    return FILETYPE_ALIASES[normalized_filetype] || filetype;
  }

  extractMetadata() {
    this.client.addJob({
      application_id: BLITLINE_APP_ID, // eslint-disable-line no-undef
      v: BLITLINE_VERSION,
      src: this.url,
      pre_process: { peek: true }
    });

    return this.client.postJobs().then((data) => {
      const job_id = data.results[0].job_id;
      return this.pollForJobResults(job_id);
    }).then((response) => response.results.original_meta.original_exif).catch(() => null);
  }

  extractColors() {
    this.client.addJob({
      application_id: BLITLINE_APP_ID, // eslint-disable-line no-undef
      v: BLITLINE_VERSION,
      src: this.url,
      extract_colors: { max_colors: 6 },
      functions: [{ name: 'no_op', params: {} }]
    });
    return this.client.postJobs().then((data) => {
      const job_id = data.results[0].job_id;
      return this.pollForJobResults(job_id);
    }).then((response) => {
      const uniq_colors = [];
      let colors = response.results.original_meta.extracted_colors.data;
      colors = colors.filter((color) => {
        const { hex_color } = color.color;
        const should_filter = !uniq_colors.includes(hex_color);
        uniq_colors.push(hex_color);
        return should_filter;
      });
      return colors;
    }).catch(() => null);
  }

  extractTags() {
    this.client.addJob({
      application_id: BLITLINE_APP_ID, // eslint-disable-line no-undef
      v: BLITLINE_VERSION,
      src: this.url,
      detect_labels: true,
      functions: [{ name: 'no_op', params: {} }]
    });

    return this.client.postJobs().then((data) => {
      const job_id = data.results[0].job_id;
      return this.pollForJobResults(job_id);
    }).then((response) => {
      const confidence_threshold = AUTO_TAG_CONFIDENCE_THRESHOLD; // eslint-disable-line no-undef
      let tags = response.results.original_meta.detect_labels.labels;
      tags = tags.filter((tag) => tag.confidence > confidence_threshold);
      return tags.map((tag) => tag.name);
    }).catch(() => null);
  }

  extractText() {
    this.client.addJob({
      application_id: BLITLINE_APP_ID, // eslint-disable-line no-undef
      v: BLITLINE_VERSION,
      src: this.url,
      get_tika: true,
      functions: [{ name: 'no_op', params: {} }]
    });

    return this.client.postJobs().then((data) => {
      const job_id = data.results[0].job_id;
      return this.pollForJobResults(job_id);
    }).then((response) => {
      try {
        return response.results.original_meta.tika_results[0].text;
      } catch (error) {
        return null;
      }
    }).catch(() => null);
  }

  thumbnail() {
    let job_id;
    const key = `${uuidv4()}-thumbnail`;
    const function_name = this.mimetype === 'image/gif' ? 'resize_gif_to_fit' : 'resize_to_fit';
    this.client.addJob({
      application_id: BLITLINE_APP_ID, // eslint-disable-line no-undef
      v: BLITLINE_VERSION,
      src: this.url,
      src_type: 'smart_image',
      src_data: { colorspace: 'srgb' },
      functions: [{
        name: function_name,
        params: {
          width: 1000,
          height: 1000,
          only_shrink_larger: true
        },
        save: {
          image_identifier: key, // eslint-disable-line no-undef
          quality: 100,
          s3_destination: {
            bucket: AWS_BUCKET_NAME, // eslint-disable-line no-undef
            key: `workbench/${key}`
          }
        }
      }]
    });
    return this.client.postJobs().then((data) => {
      job_id = data.results[0].job_id;
      return this.pollForJobResults(job_id);
    }).then((response) => {
      if (response.results.images[0].s3_url) {
        // default s3_url is not https and cannot be used in AJAX download.
        // return https://s3.amazonaws.com/blahblahblah instead
        return `https://s3.amazonaws.com/${AWS_BUCKET_NAME}/workbench/${key}`; // eslint-disable-line no-undef
      }
      throw new Error(`Workbench thumbnail failed. Job ID: ${job_id}`);
    }).catch(() => null);
  }

  convertTo(filetype, colorspace = null, width = null, height = null, metadata = null) {
    const normalized_filetype = Blitline.normalizeFiletype(filetype);
    let job_id;
    let needs_extra_colorspace_conversion = false;
    const key = uuidv4();
    const blitline_job = {
      application_id: BLITLINE_APP_ID, // eslint-disable-line no-undef
      v: BLITLINE_VERSION,
      src: this.url,
      src_data: {},
      functions: []
    };

    const blitline_function = {
      name: 'no_op',
      params: {},
      save: {
        image_identifier: key,
        extension: `.${normalized_filetype}`,
        save_metadata: true,
        quality: 100,
        s3_destination: {
          bucket: AWS_BUCKET_NAME, // eslint-disable-line no-undef
          key: `workbench/${key}`
        }
      }
    };

    if (!!metadata && Object.keys(metadata).length > 0) {
      const filtered_metadata = {};
      const standard_properties = MetadataStandards.iptc_property_names();
      const filtered_properties = Object.keys(metadata).filter((property) => standard_properties.includes(property.toLowerCase()));

      filtered_properties.forEach((property) => {
        filtered_metadata[property] = metadata[property];
      });

      if (Object.keys(filtered_metadata).length > 0) {
        blitline_function.save.set_exif = filtered_metadata;
      }
    }

    if (!!width && !!height) {
      blitline_function.name = this.filetype === 'gif' ? 'resize_gif_to_fit' : 'resize_to_fit';
      Object.assign(blitline_function.params, { width, height });
    }

    if (colorspace) {
      if (colorspace === 'cmyk' && !Blitline.cmykCompatible(this.filetype) && Blitline.cmykCompatible(filetype)) {
        needs_extra_colorspace_conversion = true;
      }
      Object.assign(blitline_job.src_data, { colorspace });
    }

    blitline_job.functions.push(blitline_function);
    this.client.addJob(blitline_job);

    return this.client.postJobs().then((data) => {
      job_id = data.results[0].job_id;
      return this.pollForJobResults(job_id);
    }).then((response) => {
      if (!response.results.error) {
        // default s3_url is not https and cannot be used in AJAX download.
        // return https://s3.amazonaws.com/blahblahblah instead
        const converted_url = `https://s3.amazonaws.com/${AWS_BUCKET_NAME}/workbench/${key}`; // eslint-disable-line no-undef
        if (needs_extra_colorspace_conversion) {
          this.url = converted_url;
          this.filetype = filetype;
          return this.convertTo(filetype, colorspace, null, null, metadata);
        }
        return converted_url;
      }
      throw new Error(`Workbench conversion failed. Job ID: ${job_id}`);
    }).catch(() => null);
  }

  pollForJobResults(job_id) {
    return $.ajax({
      type: 'GET',
      url: `https://cache.blitline.com/listen/${job_id}`
    }).then((data) => JSON.parse(data)).catch(() => null);
  }
}
