/* eslint-disable @typescript-eslint/naming-convention */
import { FetchJsonOptions } from '@brandfolder/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchJson } from '@api/ApiHelper';

import { PER_PAGE_COUNT, supportedFileExtensions } from './constants';
import {
  AutocompleteFetchState,
  AutocompleteRequestParams,
  AutocompleteResponse,
  FaceFetchType,
  FilterValues,
  MergeFacesFetchState,
  MergeFacesRequestParams,
  MergeFacesResponse,
  PeoplePageFetchState,
  PeoplePageRequestParams,
  PeoplePageResponse,
  Person,
  PersonDetailsFetchState,
  PersonDetailsRequestParams,
  PersonDetailsResponse,
  RemoveTagFetchState,
  RemoveFaceTagRequestParams,
  RemoveFaceTagResponse,
  SortValues,
  ThumbnailFetchState,
  ThumbnailRequestParams,
  ThumbnailResponse,
  UpdatePeopleTagFetchState,
  UpdatePeopleTagRequestParams,
  UpdatePeopleTagResponse,
  RemovedFaceTag,
} from './customPeopleTagTypes';
import { usePeoplePageSearchState } from './hooks/usePeoplePageQueryState';
import { usePersonDetailsQueryState } from './hooks/usePersonDetailsQueryState';
import { ToastLooks, useCreateToast } from '@components/common/toast';
import { t } from '@lingui/macro';

const localDevBrandfolderKey = ''; // replace me with the bf_key from the HeyTeam Brandfolder in stage (DO NOT COMMIT) 
const localOrgKey = ''; // replace me with the org_key from the Product Testing org in stage (DO NOT COMMIT) 
const localDevelopment = process.env.NODE_ENV === 'development';

const payloadKeys = {
  bf_key: localDevelopment ? localDevBrandfolderKey : BFG.brandfolder_key,
  org_key: localDevelopment ? localOrgKey : BFG.organization_key,
  current_user: BFG.currentUser.email,
};

const cleanPayload = (
  requestParams: PeoplePageRequestParams
): PeoplePageRequestParams =>
  Object.keys(requestParams).reduce((acc, key) => {
    if (!!requestParams[key]) {
      return { ...acc, [key]: requestParams[key] };
    }
    return acc;
  }, {} as PeoplePageRequestParams);

const headers = {
  'Content-Type': 'application/json',
  Accept: '*/*',
  Authorization: `Bearer ${BFG.org_token}`,
};

const getRequestOptions = (
  payload: PeoplePageRequestParams | PersonDetailsRequestParams
): Pick<FetchJsonOptions, 'body' | 'headers' | 'method'> => ({
  body: {
    ...payloadKeys,
    ...payload,
  },
  headers,
  method: 'POST',
});

const fetchFaceData = <T, Y>(
  payload: T,
  fetchType: FaceFetchType
): Promise<Y> => {
  return fetchJson({
    url: `${BFG.DATA_INSIGHTS_URL}/people-tagging/${fetchType}`,
    ...getRequestOptions(payload),
  });
};

export const useFetchPeoplePageData = (): PeoplePageFetchState => {
  const [peopleData, setPeopleData] = useState<PeoplePageResponse>(undefined);
  const [peopleDataLoading, setPeopleDataLoading] = useState(undefined);
  const [initialLoadingState, setInitialLoadingState] = useState(true);
  const [peopleDataError, setPeopleDataError] = useState(true);
  const [state] = usePeoplePageSearchState();
  const perPage = PER_PAGE_COUNT;

  const generateData = async (): Promise<void> => {
    const requestParams = {
      asset_id: state.ids?.split(', '),
      search_query: state.search,
    };
    const payload = cleanPayload(requestParams);
    try {
      setPeopleDataLoading(true);
      const response = await fetchFaceData<PeoplePageRequestParams, PeoplePageResponse>(
        {
          ...payload,
          sort_by: (state.sort as SortValues) || SortValues.Newest,
          status: (state.filter as FilterValues) || FilterValues.All,
          page_num: Number(state.page) || 1,
          per_page_count: perPage,
        },
        'people-page-faces'
      );
      setPeopleData(response);
    } catch (error) {
      setPeopleData(undefined);
      setPeopleDataError(true);
    } finally {
      setPeopleDataLoading(false);
      setInitialLoadingState(false);
    }
  };

  useEffect(() => {

    const search = setTimeout(() => {
      generateData();
    }, 300);

    return () => clearTimeout(search);
  }, [state.filter, state.page, state.search, state.sort, state.ids, perPage]);

  return {
    peopleData,
    peopleDataLoading,
    initialLoadingState,
    peopleDataError,
    refetchPeopleData: generateData,
  };
};

export const useFetchPersonDetailsData = (): PersonDetailsFetchState => {
  const { id: person_id } = useParams();
  const [state] = usePersonDetailsQueryState();
  const [personDetailsLoading, setPersonDetailsLoading] = useState(true);
  const [personDetailsError, setPersonDetailsError] = useState(true);
  const [personDetailsData, setPersonDetailsData] = useState<PersonDetailsResponse>(undefined);
  const perPage = PER_PAGE_COUNT;

  const fetchPersonDetailsData = async (): Promise<void> => {
    setPersonDetailsLoading(true);
    const payload: PersonDetailsRequestParams = {
      page_num: Number(state.page) || 1,
      per_page_count: perPage,
      person_id,
      sort_by: (state.sort as SortValues) || SortValues.Newest,
    };
    try {
      const response = await fetchFaceData<PersonDetailsRequestParams, PersonDetailsResponse>(
        payload,
        'person-details'
      );
      setPersonDetailsData(response);
    } catch (error) {
      setPersonDetailsData(undefined);
      setPersonDetailsError(true);
    } finally {
      setPersonDetailsLoading(false);
    }
  };
  useEffect(() => {
    fetchPersonDetailsData();
  }, [state.page, state.sort, perPage, person_id]);

  return {
    personDetailsData,
    personDetailsError,
    personDetailsLoading,
    refetchPersonDetails: fetchPersonDetailsData,
  };
};

export const useUpdatePersonTag = (): UpdatePeopleTagFetchState => {
  const [updatePersonTagLoading, setUpdatePersonTagLoading] = useState(undefined);
  const [updatePersonTagSuccess, setUpdatePersonTagSuccess] = useState(undefined);
  const { toast } = useCreateToast();

  const updatePersonTag = async (id: string, tag: string): Promise<void> => {
    setUpdatePersonTagLoading(true);
    const payload = {
      person_id: id,
      tag_name: tag,
    };
    try {
      const response = await fetchFaceData<UpdatePeopleTagRequestParams, UpdatePeopleTagResponse>(
        payload,
        'add-people-tag'
      );
      if (response?.data.tag_name) {
        setUpdatePersonTagSuccess(true);
      }
    } catch (error) {
      setUpdatePersonTagSuccess(false);
      toast(
        t`Something went wrong. Please try again.`,
        ToastLooks.Error
      );
    } finally {
      setUpdatePersonTagLoading(false);
    }
  };

  return {
    updatePersonTag,
    updatePersonTagLoading,
    updatePersonTagSuccess,
  };
};

export const useAutocompletePeopleTags = (tag: string, personId: string): AutocompleteFetchState => {
  const [autocompleteResults, setAutocompleteResults] = useState<Person[]>([]);

  useEffect(() => {
    if (!tag) {
      setAutocompleteResults([]);
      return;
    }

    const fetchTags = async (): Promise<void> => {
      try {
        const response = await fetchFaceData<AutocompleteRequestParams, AutocompleteResponse>(
          {
            query: tag,
          },
          'autocomplete'
        );
        // filter out the person we're currently on
        const filteredResults = response.data.filter(
          (result: Person) => result.person_id !== personId
        );
        setAutocompleteResults(filteredResults);
      } catch {
        console.error('error fetching autocomplete results');
      }
    };

    if (tag) {
      // quick fix - handles the async nature of the search
      // TODO: worth looking into debounce/ a different solution
      const search = setTimeout(() => {
        fetchTags();
      }, 200);
      return () => clearTimeout(search);
    }
  }, [personId, tag]);

  return {
    autocompleteResults,
  };
};

export const useMergeFaces = (): MergeFacesFetchState => {
  const [mergeFacesLoading, setMergeFacesLoading] = useState(false);
  const [mergeFacesSuccess, setMergeFacesSuccess] = useState(undefined);

  const mergeFaces = async (target_person_id: string, person_id: string): Promise<void> => {
    setMergeFacesLoading(true);
    try {
      const response = await fetchFaceData<MergeFacesRequestParams, MergeFacesResponse>(
        {
          person_id,
          target_person_id,
        },
        'merge'
      );
      if (response.data === 'Success') {
        setMergeFacesSuccess(true);
      }
    } catch {
      setMergeFacesSuccess(false);
    } finally {
      setMergeFacesLoading(false);
    }
  };

  return {
    mergeFaces,
    mergeFacesLoading,
    mergeFacesSuccess,
  };
};

export const useFetchHeroImage = (currentPersonId: string): ThumbnailFetchState => {
  const [thumbnailImage, setThumbnailImage] = useState(undefined);
  const [tagName, setTagName] = useState(undefined);
  const [thumbnailLoading, setThumbnailLoading] = useState(true);

  useEffect(() => {
    const getThumbnail = async (): Promise<void> => {
      const payload = {
        person_id: currentPersonId,
      };

      try {
        const response = await fetchFaceData<ThumbnailRequestParams, ThumbnailResponse>(
          payload,
          'get-hero-image'
        );
        if (response?.data.hero_image) {
          setThumbnailImage(response.data.hero_image);
          setTagName(response.data.tag_name);
        }
      } catch {
        setThumbnailImage(undefined);
        setTagName(undefined);
      } finally {
        setThumbnailLoading(false);
      }
    };
    getThumbnail();
  }, [currentPersonId]);

  return {
    tagName,
    thumbnailImage,
    thumbnailLoading,
  };
};

export const useCheckForFaces = (
  assetId: string,
  assetExt: string
): { assetHasFaces: boolean } => {
  const [assetHasFaces, setAssetHasFaces] = useState<boolean>(undefined);
  const hasPeopleTaggingFeature = BFG.context.hasFeature('people_tagging') &&
    BFG.brandfolderSettings.people_tagging &&
    supportedFileExtensions.includes(assetExt);

  useEffect(() => {
    if (!hasPeopleTaggingFeature) return;
    const checkFaces = async (): Promise<void> => {
      try {
        const response = await fetchFaceData<PeoplePageRequestParams, PeoplePageResponse>(
          { asset_id: [assetId] },
          'people-page-faces'
        );
        setAssetHasFaces(!!response.data?.length);
      } catch (error) {
        setAssetHasFaces(false);
      }
    };
    checkFaces();
  }, [assetId, hasPeopleTaggingFeature]);

  return {
    assetHasFaces,
  };
};

export const useRemoveFaceTag = (): RemoveTagFetchState => {
  const [removeTagLoading, setRemoveTagLoading] = useState(false);
  const [removeTagSuccess, setRemoveTagSuccess] = useState(undefined);
  const [removedTag, setRemovedTag] = useState<RemovedFaceTag>(undefined);

  const resetTag = (): void => {
    setRemoveTagSuccess(undefined);
    setRemovedTag(undefined);
  };

  const removeFaceTag = async (face_id: string, person_id: string): Promise<void> => {
    setRemoveTagLoading(true);
    try {
      const response = await fetchFaceData<RemoveFaceTagRequestParams, RemoveFaceTagResponse>(
        {
          face_id,
          person_id
        },
        'remove-face'
      );
      setRemovedTag(response.data);
      setRemoveTagSuccess(true);
    } catch {
      setRemoveTagSuccess(false);
    } finally {
      setRemoveTagLoading(false);
    }
  };

  return {
    removeFaceTag,
    removedTag,
    removeTagLoading,
    removeTagSuccess,
    resetTag
  };
};
