import {
  ButtonLooks,
  DialogSizes,
  FontAlignments,
  FontIcons,
  FontWeights,
  SearchTextfield,
  StandardButton,
  StandardDialog,
  StandardPagination,
  StandardTable,
  StandardTableColumn,
  StandardTableRow,
  StandardText
} from '@brandfolder/react';
import { localizeDate } from '@brandfolder/utilities';
import { t, Trans, Plural } from '@lingui/macro';
import { decode } from 'html-entities';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { UserRoles } from '@api/v4/private/resources/users';
import { PermissionsWithUserGroupsResponse } from '@api/v4/user_groups/user-group-permissions/UserGroupPermissionsTypes';
import useDebounce from '@components/common/custom_hooks/useDebounce';
import { ListDropdown } from '@components/library/dropdown';
import { TextButton } from '@components/library/button';
import { userPermissionOptions } from '@components/bulk_management/user-settings/helpers';
import { TABLE_DEFAULT_PER } from '@components/bulk_management/user_groups/helpers';
import { ServerResourceType } from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';
import { getStandardPaginationLabels } from '@translations';

import './styles/user-management-user-groups-table.scss';

interface UserManagementUserGroupsTableProps {
  resourceName: string,
  selectedResourceKey: string,
  selectedResourceType: ServerResourceType
  reloadUserGroups: boolean;
  setReloadUserGroups: SetStateDispatch<boolean>;
}

type FilterValues = UserRoles.Guest | UserRoles.Collaborator | UserRoles.Admin | 'all';

export const UserManagementUserGroupsTable: FunctionComponent<UserManagementUserGroupsTableProps> = ({
  resourceName,
  reloadUserGroups,
  selectedResourceKey,
  selectedResourceType,
  setReloadUserGroups
}) => {
  const [rows, setRows] = useState<StandardTableRow[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState<FilterValues>('all');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [deleteUserGroupPermissionKey, setDeleteUserGroupPermissionKey] = useState('');
  const [userGroupPermissionDeleteOpenDialog, setUserGroupPermissionDeleteOpenDialog] = useState(false);
  const [userGroupName, setUserGroupName] = useState('');

  const debouncedSearch = useDebounce({
    delay: 600,
    disableDebounce: process?.env?.NODE_ENV === 'circle',
    value: searchValue
  });

  const userGroupsFetch = useFetch<PermissionsWithUserGroupsResponse>({
    url:`api/v4/${selectedResourceType}s/${selectedResourceKey}/user_group_permissions`,
    fields: 'created_at',
    include: 'user_group',
    params: {
      search: debouncedSearch === '' ? undefined : debouncedSearch,
      permission_level: filter === 'all' ? undefined : filter,
      per: TABLE_DEFAULT_PER,
      page
    },
    fetchOnMount: false
  });

  const userGroupPermissionDelete = useFetch({
    url: `api/v4/user_group_permissions/${deleteUserGroupPermissionKey}`,
    fetchOnMount: false,
    method: 'DELETE'
  });

  useEffect(() => {
    if (reloadUserGroups) {
      userGroupsFetch.fetch();
      setReloadUserGroups(false);
    }
  }, [reloadUserGroups]);

  useEffect(() => {
    if (selectedResourceKey && selectedResourceType) {
      userGroupsFetch.fetch();
    }
  }, [selectedResourceKey, selectedResourceType, debouncedSearch, filter, page]);

  useEffect(() => {
    if (userGroupsFetch.response) {
      const tableRows = userGroupsFetch.response.included ? userGroupsFetch.response.included.map((userGroup) => {
        const matchedPermission = userGroupsFetch.response.data.find((permission) => (
          userGroup.id === permission.relationships.user_group.data.id
        ));
        return ({
          userGroupName: decode(userGroup.attributes.name),
          createdOn: localizeDate(userGroup.attributes.created_at),
          permissionLevel: userPermissionOptions().find((opt) => opt.value === matchedPermission.attributes.permission_level)?.label || '',
          removeUserGroup: (
            <StandardButton
              look={ButtonLooks.Warning}
              onClick={(): void => {
                setDeleteUserGroupPermissionKey(matchedPermission.id);
                setUserGroupName(userGroup.attributes.name);
              }}
              startIcon={FontIcons.Remove}
            >
              <Trans>Remove</Trans>
            </StandardButton>
          )
        });
      }) : [];
      setRows(tableRows);
      setTotalPages(userGroupsFetch?.response?.meta?.total_pages || 0);
    }
  }, [userGroupsFetch.response]);

  useEffect(() => {
    if (deleteUserGroupPermissionKey) {
      setUserGroupPermissionDeleteOpenDialog(true);
    }
  }, [deleteUserGroupPermissionKey]);

  useEffect(() => {
    if (userGroupPermissionDelete.response) {
      setDeleteUserGroupPermissionKey('');
      setUserGroupPermissionDeleteOpenDialog(false);
      userGroupsFetch.fetch();
    }
  }, [userGroupPermissionDelete.response]);

  const columns: StandardTableColumn[] = [{
    children: <Trans>User group name</Trans>,
    heading: <Trans>User group name</Trans>,
    rowKey: 'userGroupName'
  }, {
    children: <Trans>Created on</Trans>,
    heading: <Trans>Created on</Trans>,
    rowKey: 'createdOn'
  }, {
    children: <Trans>Permission level</Trans>,
    heading: <Trans>Permission level</Trans>,
    rowKey: 'permissionLevel'
  }, {
    rowKey: 'removeUserGroup'
  }];

  const deleteUserGroupPermissionDialog = (
    <StandardDialog
      height={300}
      id="user-group-permission-delete-dialog"
      labels={{
        closeButtonLabel: t`Close dialog`
      }}
      open={userGroupPermissionDeleteOpenDialog}
      setOpen={setUserGroupPermissionDeleteOpenDialog}
      showFooter={false}
      size={DialogSizes.Small}
      title={t`Remove user group from this resource?`}
      titleIcon={FontIcons.Trash}
    >
      <div className="user-group-permission-delete-dialog-content">
        <StandardText align={FontAlignments.Center}>
          <Trans>
            Members of this group will no longer have access to this resource. This can be undone by adding the user group to the resource again.
          </Trans>
        </StandardText>
        <StandardText align={FontAlignments.Center} weight={FontWeights.Bold}>
          {decode(userGroupName)}
        </StandardText>
        <StandardButton
          className="user-group-permission-delete-dialog-content__button"
          loading={userGroupPermissionDelete.loading}
          look={ButtonLooks.Danger}
          onClick={(): void => {
            userGroupPermissionDelete.fetch();
          }}
        >
          <Trans>Remove user group</Trans>
        </StandardButton>
      </div>
    </StandardDialog>
  );

  const userGroupsCount = userGroupsFetch.response?.meta?.total_count || 0;

  return (
    <section className="user-management-user-groups-table">
      <div className="user-management-user-groups-table__header">
        <h3 className="user-management-user-groups-table__header--table-title">
          {!userGroupsFetch.response && <Trans>User Groups</Trans>}
          {userGroupsFetch.response && (
            <Plural
              one={`1 ${resourceName} User Group`}
              other={`# ${resourceName} User Groups`}
              value={userGroupsCount}
            />
          )}
        </h3>
      </div>
      <div className="user-management-user-groups-table__filters-container">
        <SearchTextfield
          className="user-management-user-groups-table__search"
          id="search-user-management-user-groups"
          label={<Trans>Search groups</Trans>}
          onChange={(e: InputChangeEvent): void => { setSearchValue(e.target.value); }}
          placeholder={t`Search groups`}
          value={searchValue}
        />
        <ListDropdown
          className="user-management-user-groups-table__filter"
          id="user-management-user-groups-permission-level-dropdown"
          label={t`Filter by`}
          onChange={(selectedOption): void => {
            setFilter(selectedOption.value as FilterValues);
          }}
          options={[
            {
              label: t`All`,
              value: 'all'
            },
            ...userPermissionOptions()
          ]}
          value={filter}
          virtualizeOptions={false}
        />
      </div>
      {userGroupsFetch.response && deleteUserGroupPermissionDialog}
      <StandardTable
        caption={t`List of user groups`}
        columns={columns}
        empty={rows.length === 0 && !userGroupsFetch.loading}
        emptyContent={(
          <div className="user-management-user-groups-table__empty-content">
            <p><Trans>No user groups to display</Trans></p>
          </div>
        )}
        error={!!userGroupsFetch.error}
        errorContent={(
          <div>
            <p><Trans>Oops! Something went wrong.</Trans></p>
              <TextButton onClick={(): void => { userGroupsFetch.fetch(); }}>
                <Trans>Try again</Trans>
              </TextButton>
          </div>
        )}
        id="user-management-user-groups-table"
        loaderLabel={t`Loading`}
        loading={userGroupsFetch.loading}
        rows={rows}
        scrollX={false}
      />
      {totalPages > 1 && (
        <div className="user-management-user-groups-table__pagination">
          <StandardPagination
            disabled={userGroupsFetch.loading}
            labels={getStandardPaginationLabels()}
            onPageChange={(newPage): void => setPage(newPage)}
            total={totalPages}
          />
        </div>
      )}
    </section>
  );
};
