import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, {
  useEffect,
  useRef,
  useState,
  FunctionComponent,
} from 'react';

import { TertiaryButton } from '@components/library/button/index';
import { ListDropdown } from '@components/library/dropdown/index';
import { LabelPosition, StandardSwitch } from '@components/library/switch';
import { getSortOption, SortOptions, sortOptions, ViewOptionsSortOption } from '@components/show_page/sections/view_options/helper';
import ViewOptionsDropdown from '@components/show_page/sections/view_options/view_options_dropdown';
import {
  UpdateUserViewOptions,
  UserViewOptions,
  UserViewOptionsKeys,
} from '@components/show_page/sections/view_options/ViewOptionTypes';
import { manageDropdownListener } from '@helpers/use_effect_callbacks';

import './styles/view-options-menu.scss';

export interface ViewOptionsProps {
  defaultSortOrder: SortOptions;
  paginatePer: number;
  searchSubmitted: boolean;
  updateUserViewOptions: UpdateUserViewOptions;
  userViewOptions: UserViewOptions;
}

const ViewOptions: FunctionComponent<ViewOptionsProps> = ({
  defaultSortOrder,
  paginatePer,
  searchSubmitted,
  updateUserViewOptions,
  userViewOptions
}) => {
  const [renderViewOptions, setRenderViewOptions] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const thisComponent = useRef<HTMLDivElement | null>(null);

  const dropdownEventListenerCallback = (e: MouseEvent | KeyboardEvent): void => {
    e.stopPropagation();
    setRenderViewOptions(false);
  };

  useEffect(() => (
    manageDropdownListener(thisComponent, renderViewOptions, dropdownEventListenerCallback)
  ), [thisComponent, renderViewOptions, dropdownEventListenerCallback]);

  useEffect(() => {
    setIsVisible(renderViewOptions); // make visible after element renders
  }, [renderViewOptions]);

  return (
    <div className="view-options-container">
      <StandardSwitch
        className="view-options__show-sections"
        isChecked={userViewOptions?.showSections || false}
        labelPosition={LabelPosition.Right}
        onChange={(): void => { updateUserViewOptions(UserViewOptionsKeys.ShowSections); }}
      >
        <Trans>Organize by section</Trans>
      </StandardSwitch>
      <ListDropdown
        anchorElementLabel={t`Sort by:`}
        className="view-options__sort-dropdown"
        onChange={(option: ViewOptionsSortOption): void => updateUserViewOptions(UserViewOptionsKeys.SortOption, option.value)}
        options={sortOptions(searchSubmitted)}
        value={getSortOption(searchSubmitted, userViewOptions?.sortOption, defaultSortOrder)}
        virtualizeOptions={false}
      />
      <div ref={thisComponent} className="view-options-menu">
        <TertiaryButton
          className={classnames(
            'view-options-menu-button',
            { active: renderViewOptions },
          )}
          onClick={(): void => setRenderViewOptions(!renderViewOptions)}
        >
          <span className="bff-grid-view" />
          <Trans>View Options</Trans>
        </TertiaryButton>
        {renderViewOptions && (
          <ViewOptionsDropdown
            defaultSortOrder={defaultSortOrder}
            isVisible={isVisible}
            paginatePer={paginatePer}
            searchSubmitted={searchSubmitted}
            setRenderViewOptions={setRenderViewOptions}
            updateUserViewOptions={updateUserViewOptions}
            userViewOptions={userViewOptions}
          />
        )}
      </div>
    </div>
  );
};

export default ViewOptions;
