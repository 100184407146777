import React, { FunctionComponent } from 'react';

import { FontData } from '@api/v4/assets/assetTypes';

import { PrimaryButton } from '@components/library/button/index';

import { getFontSource, getFontUrl } from '../helpers/font';

interface FontLinkProps {
  assetData: FontData;
  assetName: string;
}

const FontLink: FunctionComponent<FontLinkProps> = ({
  assetData,
  assetName,
}) => {
  return (
    <PrimaryButton
      icon="bff-font-type"
      onClick={(): void => { window.open(getFontUrl(assetData, assetName), '_blank'); }}
      size="medium"
    >
      {getFontSource(assetData)}
    </PrimaryButton>
  );
};

export default FontLink;
