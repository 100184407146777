import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { ColorData } from '@api/v4/assets/assetTypes';

import { PrimaryButton } from '@components/library/button/index';

import { getColorUrl } from '../helpers/colors';

interface ColorLinkProps {
  assetData: ColorData;
}

const ColorLink: FunctionComponent<ColorLinkProps> = ({
  assetData
}) => (
  <PrimaryButton
    icon="bff-color-hexa"
    onClick={(): void => { window.open(getColorUrl(assetData), '_blank'); }}
    size="large"
  >
    <Trans>View on ColorHexa</Trans>
  </PrimaryButton>
);

export default ColorLink;
