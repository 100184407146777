/* eslint-disable react/require-default-props */
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { downloadPoller as downloadConversion } from '@api/downloading/download_poller';

import { convertWithFilestack, defaultImage, defaultCroppedData } from './helper';

const DownloadButton = ({
  attachmentKey,
  croppedData,
  fixedDimensions,
  handlePrintuiImageSubmission,
  image,
  imageToThumbnailRatio,
  printuiAssetKey,
  printuiDigestKey,
  selectedFiletype,
  selectedPreset,
}) => {
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);
  const [storedTimeouts, setStoredTimeouts] = useState([]);

  useEffect(() => (() => storedTimeouts.forEach((timeout) => clearTimeout(timeout))), []);

  const downloadCallback = async () => {
    setStoredTimeouts((prevState) => {
      const timeouts = prevState ? [...prevState] : [];
      timeouts.push(setTimeout(() => setIsDownloadDisabled(false), 10000));
    });

    const { height, width, x, y } = croppedData;
    const downloadParams = {
      crop_gravity: 'north_west',
      crop_width: Math.round(width * imageToThumbnailRatio),
      crop_height: Math.round(height * imageToThumbnailRatio),
      crop_x_offset: Math.round(x * imageToThumbnailRatio),
      crop_y_offset: Math.round(y * imageToThumbnailRatio),
    };

    if (fixedDimensions) {
      const { height: presetHeight, width: presetWidth } = selectedPreset.value;
      downloadParams.width = presetWidth;
      downloadParams.height = presetHeight;
    } else {
      // provide width and height so conversion doesn't default to original image dimensions
      downloadParams.width = downloadParams.crop_width;
      downloadParams.height = downloadParams.crop_height;
    }

    const extension = selectedFiletype || 'PNG';
    const params = { dl: true, extension, ...downloadParams };
    if (handlePrintuiImageSubmission && !attachmentKey) {
      // return params to parent component instead of download
      const result = await convertWithFilestack(params, selectedFiletype, image?.url, printuiAssetKey, printuiDigestKey);
      const [conversionUrl, filestackExtension, errorMessage] = result;
      if (errorMessage) {
        // return nil for files that Filestack can't process, ex: too large
        Notify.create({
          title: t`Unable to process image`,
          body: errorMessage,
          type: "error",
        });
        return;
      }
      // Note: image.extension is the extension from the Filestack upload (do not use)
      //       the processed file is a jpg/png
      //       Printui validates that the filename's extension matches the submitted file's extension
      // Note: unique sha gets appened in server side client - so non-unique is technically okay here
      const filename = `${image?.name?.split(".")[0] || "filestack_conversion"}.${filestackExtension}`;
      const updatedParams = {
        ...params,
        name: filename,
        url: conversionUrl,
      };
      handlePrintuiImageSubmission(updatedParams, true /* externalUrl */);
    } else if (handlePrintuiImageSubmission) {
      // return params to parent component instead of download
      handlePrintuiImageSubmission(params, false /* cdnUrl */);
    } else {
      const pollerUrl = `/${BF.fx.brandfolder().slug}/attachments/${attachmentKey}/poller`;
      downloadConversion(pollerUrl, { params }, 500);
    }
  };

  const handleDownload = () => {
    setIsDownloadDisabled(true);
    if (BFG.downloadAlertEnabled && !handlePrintuiImageSubmission) {
      BF.fx.dispatchWindowEvent('launchDownloadAlert', null, {
        assetKey: undefined,
        attachmentKey,
        downloadCallback
      });
    } else {
      downloadCallback();
    }
  };

  return (
    <React.Fragment>
      <button
        className="button primary download-button md"
        disabled={isDownloadDisabled}
        onClick={handleDownload}
        type="button"
      >
        {handlePrintuiImageSubmission ? t`Use Image` : t`Download`}
      </button>
    </React.Fragment>
  );
};

DownloadButton.propTypes = {
  attachmentKey: PropTypes.string,
  croppedData: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  fixedDimensions: PropTypes.bool.isRequired,
  handlePrintuiImageSubmission: PropTypes.func,
  image: PropTypes.shape({
    height: PropTypes.number,
    mimetype: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string,
    width: PropTypes.number,
  }),
  imageToThumbnailRatio: PropTypes.number.isRequired,
  printuiAssetKey: PropTypes.string,
  printuiDigestKey: PropTypes.string,
  selectedFiletype: PropTypes.string.isRequired,
  selectedPreset: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      aspect_ratio: PropTypes.number,
      height: PropTypes.number,
      width: PropTypes.number,
      format: PropTypes.string,
      pad: PropTypes.bool,
      crop_gravity: PropTypes.string,
    }),
  }),
};

DownloadButton.defaultProps = {
  attachmentKey: null,
  croppedData: defaultCroppedData,
  handlePrintuiImageSubmission: null,
  image: defaultImage,
  printuiAssetKey: null,
  printuiDigestKey: null,
  selectedPreset: null,
};

export default DownloadButton;
