import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import { decode } from 'html-entities';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { mixed, object, SchemaOf, string } from 'yup';

import { fetchJson, useFetch } from '@api/ApiHelper';
import { createAssets } from '@api/v4/assets/assets';
import { SectionsApiData, SectionApiResponseObject } from '@api/v4/brandfolders/SectionTypes';
import {
  DesignHuddleExportNewPostResponse,
  DesignHuddleSaveAssetFileTypes
} from '@api/v4/private/resources/design_huddles';
import { BFLoader } from '@components/common/loader/main';
import { getResourceUrl } from '@components/design_huddles/editors/helpers';
import { PrimaryButton, TertiaryButton, TextButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { ListOpenDirections, ListOption, ListDropdown } from '@components/library/dropdown';
import { FontIcon, FontIcons } from '@components/library/icon';
import Label from '@components/library/labels/PrimaryLabel';
import { StandardTextField } from '@components/library/text-field';
import { isGettyClient } from '@helpers/getty-strings';

import './styles/save-to-brandfolder.scss';

export interface SaveToBrandfolderProps {
  projectId: string;
  sectionKey: string | null;
  setShowSaveToBrandfolder: SetStateDispatch<boolean>;
  showSaveToBrandfolder: boolean;
  templateKey: string | null;
}

interface SaveToBrandfolderValues {
  fileType: DesignHuddleSaveAssetFileTypes;
  name: string;
  sectionId: string;
}

enum Fields {
  FileType = 'fileType',
  Name = 'name',
}

const validationSchema = (): SchemaOf<SaveToBrandfolderValues> => object().shape({
  fileType: mixed(),
  name: string()
    .required(t`Name is required`),
  sectionId: string()
    .min(1, t`Minimum 1 character`)
    .max(50, t`Maximum 50 characters`)
    .required(t`Section is required`)
});

export const SaveToBrandfolder: FunctionComponent<SaveToBrandfolderProps> = (props) => {
  const resourceKey = BFG.resource.key;
  const resourceType = BFG.resource.type;

  const {
    projectId,
    sectionKey,
    setShowSaveToBrandfolder,
    showSaveToBrandfolder,
    templateKey
  } = props;

  const fetchSections = useFetch<SectionsApiData>({
    fetchOnMount: false,
    params: {
      limit: 3000,
      // TODO: fix the /sections api for collections (order and sort_by break the api)
      // we need programmatically in the sections useEffect below
      // order: 'asc',
      // sort_by: 'name'
    },
    url: `/api/v4/${resourceType}s/${resourceKey}/sections`
  });

  const [options, setOptions] = useState<ListOption[]>([]);
  const [sections, setSections] = useState<SectionApiResponseObject[]>([]);
  const [format, setFormat] = useState('');
  const [submittingMessage, setSubmittingMessage] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (showSaveToBrandfolder) {
      fetchSections.fetch();
    }
  }, [showSaveToBrandfolder]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchSections.response) {
      setSections(fetchSections.response.data);
    }
  }, [fetchSections.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sections.length > 0) {
      const sortedSections = sections.sort((a, b) => (a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase() ? 1 : -1));
      setOptions(sortedSections.map((section) => ({
        label: section.attributes.name,
        value: section.id
      })));
    }
  }, [sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialSectionId = (): string => {
    const foundSectionKey = sections.find((section) => section.id === sectionKey);
    if (foundSectionKey) {
      return sectionKey;
    }
    return sections[0]?.id || '';
  };

  const getSelectedSectionName = (sectionId): string => {
    const foundSectionName = sections.filter((section) => section.id === sectionId);
    setSectionName(foundSectionName[0].attributes.name);

    return foundSectionName[0].attributes.name;
  };

  const handleConfirmationMessage = (values: SaveToBrandfolderValues): void => {
    getSelectedSectionName(values.sectionId);
    setFormat(values.fileType);
    setTemplateName(values.name);
  };

  const onSubmit = async (values, formikHelpers): Promise<void> => {
    const { setSubmitting } = formikHelpers;

    try {
      const { fileType, name, sectionId } = values;

      setSubmittingMessage(t`Saving to Brandfolder...`);
      const createNewExportResponse = await fetchJson<DesignHuddleExportNewPostResponse>({
        body: {
          data: {
            filename: `${name}.${fileType || ''}`,
            format: fileType,
            project_id: projectId, // eslint-disable-line @typescript-eslint/naming-convention
            ...templateKey && { template_key: templateKey } // eslint-disable-line @typescript-eslint/naming-convention
          }
        },
        method: 'POST',
        url: `/api/v4/private/${resourceType}s/${resourceKey}/design_huddles/exports/new`
      });

      setSubmittingMessage(t`Saving asset...`);
      await createAssets({
        url: `/api/v4/${resourceType}s/${resourceKey}/assets?fast_jsonapi=true`,
        headers: { 'X-Brandfolder-Change-Agent': 'Custom Templating Integration' },
        section_key: sectionId, // eslint-disable-line @typescript-eslint/naming-convention
        data: {
          attributes: [{
            name: createNewExportResponse.data.relationships.assets.data.attributes.filename,
            attachments: [
              {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                external_id: createNewExportResponse.data.relationships.attachments.data.attributes.external_id,
                filename: createNewExportResponse.data.relationships.attachments.data.attributes.filename,
                source: createNewExportResponse.data.relationships.attachments.data.attributes.source,
                url: createNewExportResponse.data.relationships.attachments.data.attributes.url,
              }
            ],
          }]
        }
      });
      handleConfirmationMessage(values as SaveToBrandfolderValues);
      setShowConfirmation(true);
    } catch (err) {
      Notify.create({
        title: t`There was an error saving your asset. Please try again.`,
        type: 'error'
      });
    } finally {
      setSubmitting(false);
      setSubmittingMessage('');
    }
  };

  const fileTypeOptions: ListOption[] = [
    {
      label: t`JPG`,
      value: DesignHuddleSaveAssetFileTypes.JPG
    },
    {
      label: t`PDF`,
      value: DesignHuddleSaveAssetFileTypes.PDF
    },
    {
      label: t`PNG`,
      value: DesignHuddleSaveAssetFileTypes.PNG
    }
  ];

  const initialValues: SaveToBrandfolderValues = {
    name: '',
    fileType: DesignHuddleSaveAssetFileTypes.JPG,
    sectionId: getInitialSectionId()
  };

  const resourceName = decode(BFG.resource.name);

  return (
    <StandardDialog
      id="save-to-brandfolder-dialog"
      open={showSaveToBrandfolder}
      setOpen={setShowSaveToBrandfolder}
      showFooter={false}
      title={t`Save to Brandfolder`}
      titleIcon={`bff-${FontIcons.Template}`}
    >
      {submittingMessage && (
        <div className="save-to-brandfolder__loader">
          <BFLoader />
          <p>{submittingMessage}</p>
        </div>
      )}
      {!showConfirmation && !submittingMessage && (
        <div>
          <TextButton
            className="save-to-brandfolder__back"
            icon={FontIcons.CaretLeft}
            id="save-to-brandfolder-back"
            onClick={(): void => setShowSaveToBrandfolder(false)}
          >
            <Trans>Back to template editor</Trans>
          </TextButton>
          {fetchSections.loading && (
            <div className="save-to-brandfolder__loader">
              <BFLoader />
            </div>
          )}
          {!fetchSections.loading && fetchSections.error && (
            <p className="save-to-brandfolder__error">
              <Trans>Error loading sections. Please refresh the page and try again.</Trans>
            </p>
          )}
          {!fetchSections.error && !fetchSections.loading && sections.length > 0 && (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema()}
            >
                {(formikBag): ReactNode => {
                  const { errors, setFieldValue, touched, values } = formikBag;
                  const { fileType, sectionId } = values;

                  return (
                    <Form className="save-to-brandfolder__form">
                      <Field name={Fields.Name}>
                        {({ field }): JSX.Element => (
                          <StandardTextField
                            {...field}
                            error={touched.name && errors.name}
                            id="save-to-brandfolder-name"
                            label={t`Name`}
                            placeholder=""
                          />
                        )}
                      </Field>
                      <ListDropdown
                        className="save-to-brandfolder__section"
                        error={touched.sectionId && errors.sectionId}
                        id="save-to-brandfolder-section"
                        isLoading={fetchSections.loading}
                        label={t`Section`}
                        onChange={(listOption): void => {
                          setFieldValue('sectionId', listOption.value as string, true);
                        }}
                        options={options}
                        value={sectionId}
                        virtualizeOptions={false}
                      />
                      <Label
                        attributes={{
                          htmlFor: 'save-to-brandfolder-file-type-dropdown',
                        }}
                      >
                        <Trans>File Type</Trans>
                      </Label>
                      <ListDropdown
                        className="save-to-brandfolder__file-type"
                        id="save-to-brandfolder-file-type-dropdown"
                        onChange={(listOption): void => {
                          setFieldValue('fileType', listOption.value as DesignHuddleSaveAssetFileTypes);
                        }}
                        openDirection={ListOpenDirections.Up}
                        options={fileTypeOptions}
                        value={fileType}
                        virtualizeOptions={false}
                      />
                      <div className="save-to-brandfolder__submit">
                        <PrimaryButton
                          id="save-to-brandfolder-submit"
                          size="medium"
                          type="submit"
                        >
                          <Trans>Save Asset</Trans>
                        </PrimaryButton>
                      </div>
                    </Form>
                  );
                }}
            </Formik>
          )}
        </div>
      )}
      {showConfirmation && !submittingMessage && (
        <div className="save-to-brandfolder__success">
          <FontIcon
            aria-hidden
            className="save-to-brandfolder__icon"
            icon={FontIcons.CheckMarkCircle}
            iconSize={40}
          />
          <h3 className="save-to-brandfolder__h3">
            <Trans>"{templateName}.{format}" was successfully saved to {sectionName} in {resourceName}!</Trans>
          </h3>
          <p className="save-to-brandfolder__p save-to-brandfolder__p--centered">
            {isGettyClient()
              ? <Trans>Would you like to view those assets in Media Manager?</Trans>
              : <Trans>Would you like to view those assets in Brandfolder?</Trans>}
          </p>
          <div className="save-to-brandfolder__button-group">
            <TertiaryButton
              className="save-to-brandfolder__no"
              id="save-to-brandfolder-no"
              onClick={(): void => {
                setShowConfirmation(false);
                setShowSaveToBrandfolder(false);
              }}
            >
              <Trans>Back to Template</Trans>
            </TertiaryButton>
            <PrimaryButton
              className="save-to-brandfolder__yes"
              id="save-to-brandfolder-yes"
              onClick={(): void => {
                window.location.assign(getResourceUrl());
              }}
            >
              <Trans>View Asset</Trans>
            </PrimaryButton>
          </div>
        </div>
      )}
    </StandardDialog>
  );
};
