/**
 * Capitalize a string. Accepts string, e.g. 'full-name' or 'full name' or 'full_name'.
 * Outputs capitalized and spaced, e.g. 'Full Name'.
 * @param text string
 * @returns string
 */
export const capitalize = (text: string): string => (
  text.split('-' || '_' || ' ').map((word) => (
    `${word[0].toUpperCase()}${word.substr(1)}`
  )).join(' ')
);

/**
 * Convert a string to kebab-case
 * @example Brandfolder Smartsheet => brandfolder-smartsheet
 * @param text string
 * @returns string
 */
export const toKebabCase = (text: string): string => text
  .split('')
  .map((letter) => {
    if (/[A-Z]/.test(letter)) {
      return ` ${letter.toLowerCase()}`;
    }
    return letter;
  })
  .join('')
  .trim()
  .replace(/[_\s]+/g, '-');

/**
 * Convert a string to camelCase
 * @example brandfolder-smartsheet => brandfolderSmartsheet
 * @param text string
 * @returns string
 */
export const toCamelCase = (text: string): string => toKebabCase(text)
  .split('-')
  .map((word, index) => {
    if (index === 0) return word;
    return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
  })
  .join('');

/**
 * Convert a string to PascalCase
 * @example brandfolder-smartsheet => BrandfolderSmartsheet
 * @param text string
 * @returns string
 */
export const toPascalCase = (text: string): string => toKebabCase(text)
  .split('-')
  .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
  .join('');

/**
 * Convert a string to snake_case
 * @example brandfolder-smartsheet => brandfolder_smartsheet
 * @param text string
 * @returns string
 */
export const toSnakeCase = (text: string): string => toKebabCase(text)
  .split('-')
  .join('_');

/**
 * Convert a string to Sentence case
 * @example brandfolder-smartsheet => Brandfolder smartsheet
 * @param text string
 * @returns string
 */
export const toSentenceCase = (text: string): string => {
  const interim = toKebabCase(text).replace(/-/g, ' ');
  return interim.slice(0, 1).toUpperCase() + interim.slice(1);
};

/**
 * Convert a string to Title Case
 * @example brandfolder-smartsheet => Brandfolder Smartsheet
 * @param text string
 * @returns string
 */
export const toTitleCase = (text: string): string => (
  toKebabCase(text)
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ')
);

const isObject = (item: unknown): boolean => item !== null && item.constructor.name === 'Object';

/**
  * Convert object keys to camelCase
  * helpful to convert serverside objects for frontend use
  * return null or undefined if it receives null or undefined
*/
export const camelizeObjectKeys = <InputObject extends unknown, CamelCasedObject extends unknown>(
  inputObject: InputObject
): CamelCasedObject | undefined | null => {
  if (inputObject === undefined || inputObject === null) return inputObject === undefined ? undefined : null;

  const camelCaseObject = {} as CamelCasedObject;

  Object.keys(inputObject).forEach((key) => {
    const value = inputObject[key];
    camelCaseObject[toCamelCase(key)] = isObject(value)
      ? camelizeObjectKeys(value as Record<string, unknown>) // continue recursion drilling if value is object
      : value; // return value itself if it's not an object
  });

  return camelCaseObject;
};

/**
  * Convert object keys to snake_case
  * helpful to convert to clientside objects to be passed to server
  * return null or undefined if it receives null or undefined
*/
export const snakifyObjectKeys = <InputObject extends unknown, SnakeCasedObject extends unknown>(
  inputObject: InputObject | undefined | null
): SnakeCasedObject | undefined | null => {
  if (inputObject === undefined || inputObject === null) return inputObject === undefined ? undefined : null;

  const snakeCaseObject = {} as SnakeCasedObject;

  Object.keys(inputObject).forEach((key) => {
    const value = inputObject[key];
    snakeCaseObject[toSnakeCase(key)] = isObject(value)
      ? snakifyObjectKeys(value as Record<string, unknown>) // continue recursion drilling if value is object
      : value; // return value itself if it's not an object
  });

  return snakeCaseObject;
};
