import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumbs from './breadcrumbs';
import BreadcrumbsClose from './breadcrumbs_close';

import './styles/main.scss';

const LabelsBreadcrumbs = ({ activateLabel, activeLabelKey, labelsFlat }) => {
  const onLabelSelect = (id) => {
    if (id === activeLabelKey) { return undefined; }

    const newId = id === 'resource' ? null : id;
    activateLabel(newId);
    return undefined;
  };

  return (
    <section className="labels-breadcrumbs-container">
      <span className="bff-label" />
      <Breadcrumbs
        activeLabelKey={activeLabelKey}
        labelsFlat={labelsFlat}
        onLabelSelect={onLabelSelect}
      />
      <BreadcrumbsClose onLabelSelect={onLabelSelect} />
    </section>
  );
};

LabelsBreadcrumbs.propTypes = {
  activateLabel: PropTypes.func.isRequired,
  activeLabelKey: PropTypes.string.isRequired,
  labelsFlat: PropTypes.shape({}).isRequired,
};

export default LabelsBreadcrumbs;
