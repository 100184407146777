import { FontIcon } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import { decode } from 'html-entities';
import React, { FunctionComponent, ReactNode } from 'react';

import { BulkManagementLink } from '../shared';

import './styles/bulk_management_tile.scss';

interface DashboardTileProps {
  link: BulkManagementLink;
}

export const DashboardTile: FunctionComponent<DashboardTileProps> = ({ link }) => {
  const renderEnabledLink = (): ReactNode => {
    return (
      <a
        className='bulk-management-tile__link bulk-management-tile__link--enabled'
        href={link.href}
        id={link.id}
      >
        {/* TODO - there should be a separate <Trans> for each of the links, the current setup doesn't localize link.title */}
        <Trans>Manage {link.title}</Trans>
      </a>
    );
  };

  const renderNotEnabledLink = (): ReactNode => {
    return (
      <a
        className='bulk-management-tile__link bulk-management-tile__link--not-enabled'
        href={`mailto:${BFG.supportEmail}?subject=Request Feature&body=I'd like to inquire about adding the ${link.title} feature to ${decode(BFG.resource.name)}`}
        id={link.id}
      >
        <Trans>Request Feature</Trans>
      </a>
    );
  };

  return (
    <div className={link.is_enabled ? 'bulk-management-tile' : 'bulk-management-tile not-enabled'} id='bulk-management-tile-links'>
      <FontIcon aria-hidden className='bulk-management-tile__icon' icon={link.icon} iconSize={20}/>
      <h3 className='bulk-management-tile__name'>{link.title}</h3>
      {!link.is_enabled && <p className='bulk-management-tile__disabled-notification'>
        <Trans>Not Enabled</Trans>
      </p>}
      <p className='bulk-management-tile__description'>
        {link.description}
        {/* TODO - remove code referencing ftp ingest */}
        {link.id === 'ftp_ingest' && (
          <strong>&nbsp;<Trans>This feature will be deprecated on Oct. 1, 2022</Trans></strong>
        )}
     </p>
      {link.is_enabled ? renderEnabledLink() : renderNotEnabledLink()}
    </div>
  );
};
