import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Tooltip from '@components/common/tooltip/main';
import { StandardSwitch, SwitchSize } from '@components/library/switch';
import { toKebabCase } from '@components/library/utils';
import { getCurrentUserIsAnonymous } from '@helpers/user';

const ToggleContainer = ({ handleUpdate, param, state }) => {
  const { internal, require_share_identification_setting, restrict_shares_setting } = state;
  const isAnonymous = getCurrentUserIsAnonymous(BFG.currentUser)
  const toggleRowMap = {
    internal: {
      className: 'privacy-toggle',
      copy: <Trans>Make link private</Trans>,
      display: !restrict_shares_setting && !isAnonymous,
    },
    require_identification: {
      className: 'require-id-toggle',
      copy: <Trans>Require email address to access</Trans>,
      display: !restrict_shares_setting && !require_share_identification_setting && !internal && !isAnonymous,
    },
    view_only: {
      className: 'view-only-toggle',
      copy: <Trans>Disable download functionality (view only mode)</Trans>,
      display: !isAnonymous && BFG.hasFeature('request_download_assets') && !BFG.viewOnlyCollection && !internal,
      // tooltipCopy: bfTranslate('When enabled, visitors may view assets, but must request access to download individual assets.'),
    },
    expires: {
      className: 'expires-toggle',
      copy: <Trans>Expire link</Trans>,
      display: !isAnonymous,
    },
    notifications_optin: {
      className: 'notifications-toggle',
      copy: <Trans>Notify me of updates to Share Link</Trans>,
      display: !isAnonymous
    },
    gallery_view: {
      className: 'gallery-view-toggle',
      copy: <Trans>Gallery View</Trans>,
      //TEMPORARY: Feat. flag for rollout strategy
      display: !isAnonymous && !internal,
    }
  };

  const { className, copy, display, tooltipCopy } = toggleRowMap[param];

  return (
    <CSSTransition
      in={display}
      timeout={300}
      unmountOnExit
    >
      {/* transition element can't have padding/margins - these properties can't transition,
          so extra parent container added here for JUST the transition,
          with padding/margin applied to the child element */}
      <div className="toggle-container-transition">
        <div className={`toggle-container ${className || ''} ${state[param] ? 'active' : ''}`}>
          <div className="copy-container">
            <h5>{copy}</h5>
            {tooltipCopy && (
              <Tooltip
                tooltipContent={tooltipCopy}
                tooltipId={param}
              >
                <span className="bff-tool-tip icon" />
              </Tooltip>
            )}
          </div>
          <StandardSwitch
            className={className}
            id={`${toKebabCase(param)}-toggle`}
            isChecked={state[param] || false}
            onChange={() => handleUpdate({ type: param })}
            size={SwitchSize.Large}
          />
        </div>
      </div>
    </CSSTransition>
  );
};

ToggleContainer.propTypes = {
  handleUpdate: PropTypes.func.isRequired,
  param: PropTypes.oneOf([
    'expires',
    'internal',
    'require_identification',
    'view_only',
    'notifications_optin',
    'gallery_view'
  ]).isRequired,
  state: PropTypes.shape({
    internal: PropTypes.bool,
    require_share_identification_setting: PropTypes.bool,
    restrict_shares_setting: PropTypes.bool,
    notifications_optin: PropTypes.bool,
    gallery_view: PropTypes.bool
  }).isRequired,
};

export default ToggleContainer;
