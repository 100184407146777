import { SearchFilterOperator } from '@components/show_page/sections/section_search/SearchTypes';

const Brandfolder = BF.fx.brandfolder();
const Collection = BF.fx.collection();
const Resource = BF.fx.resource();

const defaultSearchFilterOperators = {
  tags: SearchFilterOperator.OR,
  custom_fields: {},
};

const bulkRefreshSections = (selectedAssetKeys, delay, extraSectionKeysArray) => {
  const selectedSections = extraSectionKeysArray || []; // extraSectionKeys (Array) necessary for BulkMove - need to refresh section where assets are being moved, but won't have selected asset

  selectedAssetKeys.forEach((asset) => {
    const assetElement = document.getElementById(asset);

    if (assetElement) {
      const section = assetElement.dataset.section;

      if (selectedSections.indexOf(section) < 0) {
        selectedSections.push(section);
      }
    }
  });

  if (selectedSections.length) {
    if (typeof delay === 'number' && delay > 0) {
      setTimeout(() => {
        selectedSections.forEach((section) => {
          BF.fx.dispatchWindowEvent('refreshSection', section);
        });
      }, delay);
    } else {
      selectedSections.forEach((section) => {
        BF.fx.dispatchWindowEvent('refreshSection', section);
      });
    }
  }
};

const refreshSection = (sectionKey) => {
  BF.fx.dispatchWindowEvent('refreshSection', sectionKey);
};

const pluralizeObject = (itemArray) => {
  const qty = itemArray instanceof Set ? itemArray.size : itemArray.length;
  const plural = qty === 1 ? '' : 's';

  return { qty, plural };
};

const processCustomFields = (sectionAssets) => {
  // de-dupe and sort custom fields
  const customFieldKeyNames = new Set(); // a Set ensures uniqueness
  sectionAssets.forEach(({ attributes }) => {
    if (attributes.prioritized_custom_fields) {
      attributes.prioritized_custom_fields.forEach((keyValueObject) => {
        customFieldKeyNames.add(keyValueObject.key.name);
      });
    }
  });

  // used as a map so that values at asset level display correctly
  // (correct order and with blanks in the correct place)
  return [...customFieldKeyNames].sort((a, b) => {
    if (a < b) { return -1; }
    if (a > b) { return 1; }
    return 0;
  });
};

export {
  Brandfolder,
  Collection,
  Resource,
  bulkRefreshSections,
  defaultSearchFilterOperators,
  refreshSection,
  pluralizeObject,
  processCustomFields,
};
