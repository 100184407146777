import { t, Trans } from '@lingui/macro';
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';

import { PrimaryButton, TertiaryButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { FontIcon, FontIconColors, FontIcons } from '@components/library/icon';

import { BrandfoldersResponseObject } from './InsightsTypes';

export interface ConnectSuccessDialogProps {
  brandfoldersData: BrandfoldersResponseObject[];
  handleViewBrandfoldersToSync: () => void;
  isConnectionSuccessDialogOpen: boolean;
  setIsConnectionSuccessDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export const ConnectSuccessDialog: FunctionComponent<ConnectSuccessDialogProps> = (
  { brandfoldersData,
    handleViewBrandfoldersToSync,
    isConnectionSuccessDialogOpen,
    setIsConnectionSuccessDialogOpen
  }) => {
  const brandfolderSlug = brandfoldersData?.[0]?.attributes.slug;
  let insightsDataLink: string;

  if (brandfolderSlug) {
    insightsDataLink = `https://brandfolder.com/insights/?page=/insights-app/${brandfolderSlug}/insights`;
  } else {
    insightsDataLink = 'https://brandfolder.com/insights/?page=/insights-app/';
  }

  return (
    <StandardDialog
      dialogBodyClassName="connect-smartsheet-success-dialog-body"
      dialogHeaderClassName="connect-smartsheet-success-dialog-header"
      height={523}
      id="smartsheet-connect-success-dialog"
      onClose={(): void => {
        handleViewBrandfoldersToSync();
      }}
      open={isConnectionSuccessDialogOpen}
      setOpen={setIsConnectionSuccessDialogOpen}
      showFooter={false}
      showTitle={false}
      title=""
      width={965}
    >
      <div className="connect-smartsheet-success-dialog__body">
        <FontIcon aria-hidden color={FontIconColors.Text} icon={FontIcons.CheckMarkCircle} iconSize={35}></FontIcon>
        <p className="connect-smartsheet-success-dialog__title">
          <Trans>You are successfully connected to Smartsheet.</Trans>
        </p>
        <span className="connect-smartsheet-success-dialog__note">
          <Trans>
            <p className="connect-smartsheet-success-dialog__note-text">
              Note: If you edit or remove content from your synced sheet, the data may not sync. Get some tips on how to effectively use the data in your sheet&nbsp;
            </p>
            <a
              className="connect-smartsheet-success-dialog-link"
              href="https://help.smartsheet.com/4421309127831-Insights-Data-in-Smartsheet"
              target="_blank"
            >
              here
            </a>
          </Trans>
        </span>
        <div className="connect-smartsheet-success-dialog__buttons">
          <TertiaryButton
            className="connect-smartsheet-success-dialog__view-insights"
            onClick={(): void => {
              window.open(
                insightsDataLink,
                '_blank'
              );
              setIsConnectionSuccessDialogOpen(false);
            }}
          >
            <Trans>View Insights Data</Trans>
          </TertiaryButton>
          <PrimaryButton
            className="connect-smartsheet-success-dialog__select"
            onClick={(): void => {
              handleViewBrandfoldersToSync();
            }}
          >
            <Trans>View Brandfolders to Sync</Trans>
          </PrimaryButton>
        </div>
      </div>
    </StandardDialog>
  );
};
