/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import moment from 'moment';
import 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';

import { ListDropdown } from '@components/library/dropdown';
import Label from '@components/library/labels/PrimaryLabel';
import { getMomentLocale } from '@helpers/locale';

import './styles/base_styles.scss';

const defaultTimezone = moment.tz.guess() || 'Europe/London';
const timezoneOptions = moment.tz.names().map((name) => ({ value: name, label: name }));
const momentLocale = getMomentLocale();

const initialTimestamp = (timestamp) => {
  // return default if falsey
  if (!timestamp) {
    return moment().locale(momentLocale);
  }

  // check if it's a moment object
  if (moment.isMoment(timestamp)) {
    return timestamp.locale(momentLocale);
  }

  // check if it's a string or a native date object, e.g. new Date()
  if (typeof timestamp === 'string' || typeof timestamp.getMonth === 'function') {
    return moment(timestamp).isValid()
      ? moment(timestamp).locale(momentLocale)
      : timestamp;
  }

  return timestamp;
};

const DateTimePicker = ({
  allowPastDates,
  className,
  onChange,
  onDateValidityChange,
  showTime,
  showTimezones,
  timestamp,
}) => {
  const [displayDateError, setDisplayDateError] = useState(false);
  const [displayTimeError, setDisplayTimeError] = useState(false);
  const [selectedTimestamp, setSelectedTimestamp] = useState();
  const [selectedTimezone, setSelectedTimezone] = useState(defaultTimezone);

  useEffect(() => {
    setSelectedTimestamp(initialTimestamp(timestamp));
  }, []);

  const generatePickerDateValidationFunction = () => {
    if (allowPastDates) return () => true;
    const yesterday = moment().subtract(1, 'day');
    return (current) => current.isAfter(yesterday);
  };

  const checkValidDate = (date) => {
    const parsedDate = !moment.isMoment(date) ? moment(date, "MM/DD/YYYY", true) : date; // parse the input date string
    const currentDate = moment();

    if (!parsedDate.isValid() || (!allowPastDates && parsedDate.isBefore(currentDate, 'day'))) {
      setDisplayDateError(true);
      onDateValidityChange(false); // Notify parent about invalid date
      return false;
    }
    setDisplayDateError(false);
    setDisplayTimeError(false);
    onDateValidityChange(true);
    return true;
  };

  const checkValidTime = (time) => {
    if ((typeof time === 'string' || !time.isValid())) {
      setDisplayTimeError(true);
      onDateValidityChange(false); // Notify parent about invalid date
      return false;
    }
    setDisplayTimeError(false);
    setDisplayDateError(false);
    onDateValidityChange(true);
    return true;
  }

  const handleUpdateTime = (time) => {
    if(!checkValidTime(time)) {
      return;
    }

    setSelectedTimestamp((prevState) => (
      moment([
        prevState.year(),
        prevState.month(),
        prevState.date(),
        time.hour(),
        time.minute(),
      ]).locale(momentLocale)
    ));
  };

  const handleUpdateDate = (date) => {
    if(!checkValidDate(date)) {
      return;
    }

    setSelectedTimestamp((prevState) => (
      moment([
        date.year(),
        date.month(),
        date.date(),
        prevState.hour(),
        prevState.minute(),
      ]).locale(momentLocale)
    ));
  };

  const handleBlurDate = (value) => {
    if (typeof value === 'string') {
      handleUpdateDate(moment(value));
    }
  };

  const handleBlurTime = (value) => {
    if (typeof value === 'string') {
      handleUpdateTime(moment(value));
    }
  };

  const formatTimestamp = (rawTimestamp) => {
    // formatting this is finicky, these steps and details are important
    const timeString = rawTimestamp.format('YYYY-MM-DD HH:mm'); // create a string- string is independent of timezone
    return moment.tz(timeString, selectedTimezone).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'); // e.g. 2013-11-18T03:55:00.000Z, create a new time in selected timezone
  };

  useEffect(() => {
    if (selectedTimestamp) {
      onChange(formatTimestamp(selectedTimestamp));
    }
  }, [selectedTimestamp, selectedTimezone]);

  const dateSelectorId = 'date-selector';
  const timeSelectorId = 'time-selector';
  const timezoneSelectorId = 'timezone-selector';

  return (
    <div className={`date-time-picker ${className}`}>
      <div className="date-time-picker__date date-time-picker__container">
        <Label attributes={{ htmlFor: dateSelectorId }}><Trans>Date</Trans></Label>
        <Datetime
          className="t-date-selector"
          closeOnSelect
          dateFormat="L"
          inputProps={{ className: `bf-input bf-input--primary${displayDateError ? ' invalid' : ''}`, id: dateSelectorId}}
          isValidDate={generatePickerDateValidationFunction()}
          onBlur={handleBlurDate}
          onChange={handleUpdateDate}
          timeFormat={false}
          value={selectedTimestamp}
        />
        <span className="bff-calendar icon" />
      </div>
      {showTime && (
        <div className="date-time-picker__time date-time-picker__container">
          <Label attributes={{ htmlFor: timeSelectorId }}><Trans>Time</Trans></Label>
          <Datetime
            closeOnSelect
            dateFormat={false}
            inputProps={{ className: `bf-input bf-input--primary${displayTimeError ? ' invalid' : ''}`, id: timeSelectorId }}
            onBlur={handleBlurTime}
            onChange={handleUpdateTime}
            timeFormat="h:mm a"
            value={selectedTimestamp}
          />
        </div>
      )}
      {showTimezones && (
        <div className="date-time-picker__timezone date-time-picker__container">
          <Label attributes={{ htmlFor: timezoneSelectorId }}><Trans>Timezone</Trans></Label>
          <ListDropdown
            id={timezoneSelectorId}
            onChange={(selection) => setSelectedTimezone(selection ? selection.value : defaultTimezone)}
            options={timezoneOptions}
            value={selectedTimezone}
            virtualizeOptions={false}
          />
        </div>
      )}
    </div>
  );
};

DateTimePicker.propTypes = {
  allowPastDates: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDateValidityChange: PropTypes.func,
  showTime: PropTypes.bool,
  showTimezones: PropTypes.bool,
  timestamp: PropTypes.any, // eslint-disable-line
};

DateTimePicker.defaultProps = {
  allowPastDates: false,
  className: '',
  onDateValidityChange: () => {},
  showTime: true,
  showTimezones: true,
  timestamp: moment().locale(momentLocale),
};

export default DateTimePicker;
