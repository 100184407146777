import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { object, SchemaOf, string } from 'yup';

import { fetchJson } from '@api/ApiHelper';
import { ShareManifestServer } from '@api/v4/share_manifests/ShareManifestTypes';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { PrimaryButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';

import classes from './styles/require-identification.module.scss';

interface RequireIdentificationProps {
  csrfToken: string;
  manifest: ShareManifestServer;
  name: string;
  showRequireIdentification: boolean;
  subject: string;
}

interface RequireIdentificationFormValues {
  email: string;
}

const initialValues: RequireIdentificationFormValues = {
  email: ''
};

const validationSchema = (): SchemaOf<RequireIdentificationFormValues> => object().shape({
  email: string().email(t`Email must be valid.`).required(t`Email is required.`)
});

export const RequireIdentification: FunctionComponent<RequireIdentificationProps> = (props) => {
  const { csrfToken, manifest, name, showRequireIdentification, subject } = props;
  const { digest } = manifest;

  const [open, setOpen] = useState(showRequireIdentification);

  return (
    <I18nProviderWrapper>
      <StandardDialog
        dialogBodyClassName={classes.dialogBody}
        dialogHeaderClassName={classes.dialogHeader}
        id="share-manifest-require-identification"
        open={open}
        showClose={false}
        showFooter={false}
        title={t`${name} from ${subject}`}
        width={550}
      >
        <p className={classes.p}>
          <Trans>To access this Share Link, please enter your email address.</Trans>
        </p>
        <Formik
          initialValues={initialValues}
          isInitialValid={false}
          onSubmit={async ({ email }): Promise<void> => {
            try {
              Insight.updateSession(email);

              const body = new URLSearchParams();
              body.append('email', email);

              await fetchJson({
                body,
                contentType: 'application/x-www-form-urlencoded',
                headers: {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'X-CSRF-Token': csrfToken,
                },
                method: 'POST',
                url: `/share_manifests/${digest}/guest_view`
              });

              setOpen(false);
            } catch (err) {
              Notify.create({
                title: t`Error submitting your email. Please try again.`,
                type: 'error'
              });
            }
          }}
          validationSchema={validationSchema()}
        >
          {(formikBag): JSX.Element => {
            const { errors, isValid, touched, values } = formikBag;
            return (
              <Form className={classes.form}>
                <Field name="email">
                  {({ field }): JSX.Element => (
                    <InputContainer
                      attributes={{
                        autoFocus: true,
                        id: 'share-manifest-require-identification-email',
                        type: InputType.Email,
                        ...field
                      }}
                      input={{
                        error: errors.email,
                        touched: touched.email,
                        value: values.email
                      }}
                      labelCopy="Email Address"
                      submitAttempted={touched.email}
                    />
                  )}
                </Field>
                <PrimaryButton
                  className={classes.button}
                  disabled={!isValid}
                  id="share-manifest-require-identification-submit"
                  type="submit"
                >
                  <Trans>Open Share Link</Trans>
                </PrimaryButton>
              </Form>
            );
          }}
        </Formik>
      </StandardDialog>
    </I18nProviderWrapper>
  );
};
