import { t } from '@lingui/macro';
import React, { useState, FunctionComponent } from 'react';

import { AssetsResponseObject } from '@api/v4/assets/assetTypes';

import AssetBoardActions from '../components/asset_board_actions';
import AssetBoardActionsOverlay from '../components/asset_board_actions_overlay';
import AssetBoardCard from '../components/asset_board_card';
import AssetBoardCardDescription from '../components/asset_board_card_description';
import AssetBoardCardThumbnailArea from '../components/asset_board_card_thumbnail_area';

import RemoveAssetModal from './remove_asset_modal';

interface AssetCardProps {
  asset: AssetsResponseObject;
  boardId: string;
  boardName: string;
}

type AssetCardComponentType = FunctionComponent<AssetCardProps>;

const AssetCard: AssetCardComponentType = ({ asset, boardId, boardName }): JSX.Element => {
  const [showRemoveAssetModal, setShowRemoveAssetModal] = React.useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);

  const handleShowRemoveModal = (): void => {
    setShowRemoveAssetModal(true);
    setCloseDropdown(true);
  };

  return (
    <>
      {showRemoveAssetModal && (
        <RemoveAssetModal
          assetId={asset.id}
          boardId={boardId}
          boardName={boardName}
          closeModal={(): void => setShowRemoveAssetModal(false)}
        />
      )}
      <AssetBoardCard className="asset-board-asset-card">
        <AssetBoardCardThumbnailArea resource={asset} setShowOverlay={setShowOverlay}>
          <AssetBoardActionsOverlay
            buttonText={t`View`}
            onClickHandler={(): WindowProxy | null => window.open(asset.attributes.best_link_for, '_blank')}
            showOverlay={showOverlay}
          >
            <AssetBoardActions
              closeDropdown={closeDropdown}
              handleShowRemoveModal={handleShowRemoveModal}
              updateCloseDropdown={(): void => setCloseDropdown(false)}
            />
          </AssetBoardActionsOverlay>
        </AssetBoardCardThumbnailArea>
        <AssetBoardCardDescription
          leftText={asset.attributes.name}
          pillStyling="file-type-pill"
          rightText={asset.attributes.extension || ''}
          viewOnly={asset.attributes.view_only}
        />
      </AssetBoardCard>
    </>
  );
};

export default AssetCard;
