export { StandardSwitch } from './standard';

export interface BaseSwitchProps {
  isChecked: boolean;
  styleClasses: { [key: string]: string };
  variantName: string;
  className?: string;
  id?: string;
  isDisabled?: boolean;
  labelPosition?: LabelPosition;
  name?: string;
  onChange?: (e: SwitchChangeEvent) => void;
  size?: SwitchSize;
  value?: string;
}

export type StandardSwitchProps = Omit<BaseSwitchProps, 'styleClasses' | 'variantName'>
export type SwitchChangeEvent = React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>

export enum SwitchSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

/** @deprecated */
export enum LabelPosition {
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Top = 'top',
}

export enum SwitchVariant {
  Standard = 'standard'
}
