import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTHead, BaseTHeadProps } from '../../base';

import classes from '../styles/standard-table.module.scss';

export const THead: FunctionComponent<BaseTHeadProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <BaseTHead
      {...otherProps}
      className={classnames('standardThead', classes.thead, props.className)}
    >
      {children}
    </BaseTHead>
  );
};
