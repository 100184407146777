import classnames from 'classnames';
import React, { BaseHTMLAttributes, FunctionComponent, useEffect, useState } from 'react';

import classes from '../styles/tab_slider.module.scss';

interface TabSliderProps extends BaseHTMLAttributes<HTMLSpanElement> {
  activeIndex: number;
  numberOfTabs: number;
}

/**
 * Tab slider animation
 * @param {TabSliderProps} props TabSliderProps
 */
export const TabSlider: FunctionComponent<TabSliderProps> = (props) => {
  const { activeIndex, numberOfTabs, ...spanProps } = props;

  const getWidth = (): number => Number((100 / numberOfTabs).toFixed(3));

  const [left, setLeft] = useState('0%');
  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    const w = getWidth();
    setWidth(w);
    setLeft(`${w * activeIndex}%`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, numberOfTabs]);

  return (
    <span
      {...spanProps}
      className={classnames(classes.tabSlider, spanProps.className)}
      role="presentation"
      style={{
        left,
        // if one tab, defaults to 100% via tab_slider.module.scss
        width: numberOfTabs > 1 ? `${width}%` : undefined
      }}
    />
  );
};
