// types
import type {
  BaseTableColumn,
  BaseTableProps,
  BaseTableRow,
  BaseTBodyProps,
  BaseTdProps,
  BaseTFootProps,
  BaseThProps,
  BaseTrProps,
  BaseTHeadProps
} from './BaseTableProps';

export type {
  BaseTableColumn,
  BaseTableProps,
  BaseTableRow,
  BaseTBodyProps,
  BaseTdProps,
  BaseTFootProps,
  BaseThProps,
  BaseTrProps,
  BaseTHeadProps
};

// components
export { BaseTable } from './components/BaseTable';
export { BaseTBody } from './components/BaseTBody';
export { BaseTd } from './components/BaseTd';
export { BaseTFoot } from './components/BaseTFoot';
export { BaseTh } from './components/BaseTh';
export { BaseTHead } from './components/BaseTHead';
export { BaseTr } from './components/BaseTr';
