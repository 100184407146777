import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@components/common/tooltip/main';

import './styles/checkbox.scss';

const renderLabel = (props) => (
  <React.Fragment>
    <span
      className="checkbox-title"
      onClick={props.toggle}
    >
      {props.label ? props.label : props.labelHtml}
    </span>
    {props.tooltipCopy ? (
      <Tooltip
        tooltipId={`checkbox-question-${props.label ? props.label.replace(" ", "") : props.labelHtml.props.children.replace(" ", "")}`}
        tooltipContent={props.tooltipCopy}
      >
        <span
          className="bff-tool-tip icon"
          aria-label="require identification info"
          style={{ marginLeft: "10px" }}
        />
      </Tooltip>
    ) : (
      ""
    )}
  </React.Fragment>
);

const Checkbox = (props) => (
  <div className={`checkbox-content-container no-print ${props.size} align-${props.labelAlign} ${props.className}`}>
    {props.labelAlign === "right" ? renderLabel(props) : " "}
    <span className="input-container">
      <input
        className={`c-custom-checkbox ${props.partiallySelected}`}
        checked={props.checked}
        disabled={props.disabled}
        data-testid={`checkbox-input-${props.datakey}`}
        id={props.datakey}
        onChange={props.toggle}
        type="checkbox"
        value={props.checked ? "checked" : 0}
      />
      <label
        className={`checkbox-overlay ${props.checked ? 'checked' : 'c-custom-checkbox-hover'}`}
        data-key={props.datakey}
        htmlFor={props.datakey}
        onClick={props.toggle}
      />
    </span>
    {props.labelAlign !== "right" && (props.label || props.labelHtml) ? renderLabel(props) : " "}
  </div>
);

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  datakey: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelAlign: PropTypes.string,
  labelHtml: PropTypes.shape({}),
  partiallySelected: PropTypes.string,
  size: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  // tooltipCopy: PropTypes.string,
};

Checkbox.defaultProps = {
  className: '',
  datakey: null,
  disabled: false,
  labelAlign: null,
  label: null,
  labelHtml: null,
  partiallySelected: '',
  size: 'sm',
  // tooltipCopy: null,
};

export default Checkbox;
