import { CircleLoader, LoaderSizes, brandfolderColorPalette } from '@brandfolder/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const LoadingOverlayWrapper = styled.div`
  background-color: ${brandfolderColorPalette.neutrals[0]};
  height: 100%; 
  left: 0; 
  opacity: 0.5; 
  position: fixed; 
  top: 0; 
  width: 100%;
  z-index: 2;

  .bf-loader__circle {
    height: 100vh;
  }
`;

export const LoadingOverlay: FunctionComponent = () => (
  <LoadingOverlayWrapper>
    <CircleLoader label="update" size={LoaderSizes.Large} />
  </LoadingOverlayWrapper>
);
