import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { mapBackendPortalCardToPortalCard } from '@api/v4/private/portal_cards';
import { BackendPortalCard } from '@api/v4/private/PortalCardTypes';
import { PrimaryButton } from '@components/library/button/index';

import Tile from './tile';
import TileForm from './tile_form';

interface TileProps {
  editMode: boolean;
  tiles: ApiResponseObject<BackendPortalCard>[];
  triggerCardsRefresh: () => void;
}

const Tiles: FunctionComponent<TileProps> = ({ editMode, tiles, triggerCardsRefresh }) => {
  const [showTileForm, setShowTileForm] = useState(false);
  const toggleTileForm = (): void => setShowTileForm((prevState) => !prevState);

  return (
    <section className={`tiles ${!editMode ? 'preview-mode' : ''}`}>
      {tiles.map((tile, i) => (
        <Tile
          key={tile.id}
          card={mapBackendPortalCardToPortalCard(tile.attributes)}
          draggedIndex={i}
          editMode={editMode}
          portalCardId={tile.id}
          triggerCardsRefresh={triggerCardsRefresh}
        />
      ))}
      {editMode && (
        <div className="tiles__item tiles__item--one-third-width edit-mode">
          <PrimaryButton
            className="tile-create-button"
            icon="bff-plus"
            onClick={toggleTileForm}
          >
            <Trans>Create Tile</Trans>
          </PrimaryButton>
        </div>
      )}
      {showTileForm
        && (
          <TileForm
            closeModal={toggleTileForm}
            triggerCardsRefresh={triggerCardsRefresh}
          />
        )
      }
    </section>
  );
};

export default Tiles;
