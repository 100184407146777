import downloadBlob from '@api/downloading/download_blob';

export const downloadAttachment = (attachmentKey, filename) => {
  const url = `/${BF.fx.brandfolder().slug}/attachments/${attachmentKey}/${encodeURIComponent(filename)}?
    dl=true&resource_key=${BF.fx.resource().key}&resource_type=${BF.fx.resource().type}`;
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Download failed for: ${url}`);
      }
      return response.blob();
    })
    .then((blob) => {
      downloadBlob(blob, filename);
    });
};
