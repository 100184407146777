import { Plural, Trans } from '@lingui/macro';
import * as React from 'react';

import { getAllBoards } from '@api/v4/boards';

import { BoardsResponse, BoardResponseObject } from '@api/v4/boards/boardsTypes';

import { BFLoader } from '@components/common/loader/main';

import BoardCard from './board_card/board_card';
import CardGrid from './components/card_grid';
import CreateBoardModal from './create_board_modal';

type BoardAssetCountComponent = React.FunctionComponent<{ boards: BoardResponseObject[] }> ;

const BoardAssetCount: BoardAssetCountComponent = ({ boards }): JSX.Element => {
  const sumAssets = boards.reduce((sum, board) => (
    board.attributes.asset_count ? sum + board.attributes.asset_count : 0
  ), 0);
  const boardsCount = boards.length;
  return (
    <p className="board-asset-count">
      <Plural one="1 asset" other="# assets" value={sumAssets} />
      &nbsp;|&nbsp;
      <Plural one="1 board" other="# boards" value={boardsCount} />
    </p>
  );
};

// Create a context to share data without prop drilling
// we share the assets collection and the method to mutate them
// for when we mutate them through the API, we can update the local react state
export interface AllBoardsContextTypes {
  boards: BoardResponseObject[];
  setBoards: React.Dispatch<React.SetStateAction<BoardResponseObject[]>>;
}

// give setBoards a default no-op
// just as a placeholder until we set it
const initialState: AllBoardsContextTypes = {
  boards: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setBoards: (boards: BoardResponseObject[]) => { /* no op */ }
};
export const AllBoardsViewContext = React.createContext(initialState);

interface AllBoardsViewProps {
  libraryName: string;
}

const AllBoardsView: React.FunctionComponent<AllBoardsViewProps> = ({ libraryName }): React.ReactElement => {
  // hold our boards from AllBoardsApi response
  const [boards, setBoards] = React.useState<BoardResponseObject[]>([]);
  // open and close CreateBoardModal
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const EmptySectionPlaceholder = "https://cdn.brandfolder.io/27C9EC93/at/q6y1hf-fcrlsw-12xbfy/person-digging-in-empty-box.svg";

  React.useEffect(() => {
    (async (): Promise<void> => {
      try {
        const { data }: BoardsResponse = await getAllBoards({ options: { params: { fields: 'thumbnail_url,asset_count,extension' } } });
        setBoards(data);
        setLoading(false);
      } catch (err) {
        console.log('getAllBoards fetch failed in AssetBoard');
        console.log(err);
      }
    })();
  }, []);

  return (
    <>
      {
        loading
          ? <BFLoader />
          : (
            <div className="all-boards-view">
              <div className="header-section">
                <h1>
                  <Trans>Your Boards</Trans>
                </h1>
                <button
                  className="button primary create-board"
                  onClick={(): void => setShowModal(true)}
                  type="button"
                >
                  <span className="bff-plus" />
                  <Trans>Create Board</Trans>
                </button>
              </div>
              {
            boards.length > 0
              ? (
                <AllBoardsViewContext.Provider value={{ boards, setBoards }}>
                  {BoardAssetCount({ boards })}
                  <CardGrid>
                    {
                      boards.map((board) => (
                        <BoardCard
                          key={board.id}
                          board={board}
                        />
                      ))
                    }
                  </CardGrid>
                </AllBoardsViewContext.Provider>
              )
              : (
                <div className="no-boards-view">
                  <img src={EmptySectionPlaceholder} />
                  <h2>
                    <Trans>You don't have any Boards yet.</Trans>
                  </h2>
                  <p>
                    <Trans>Boards are a great way to bookmark and catalog assets from {libraryName} into your own collections for future reference.</Trans>
                  </p>
                </div>
              )
          }
              {
            showModal
            && (
            <CreateBoardModal
              closeModal={(): void => setShowModal(false)}
              setBoards={setBoards}
            />
            )
          }
            </div>
          )
      }
    </>
  );
};

export default AllBoardsView;
