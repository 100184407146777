/* eslint-disable @typescript-eslint/naming-convention */
import {
  ButtonLooks,
  FontIcons,
  StandardButton,
  StandardDialog,
  StandardPagination,
  StandardText,
  SearchTextfield,
  StandardTable,
  StandardTableColumn,
  StandardTableRow,
  DialogSizes,
  FontAlignments,
  FontWeights,
  IconButton
} from '@brandfolder/react';
import { t, Trans, Plural } from '@lingui/macro';
import classnames from 'classnames';
import React, { useEffect, useReducer, useState, FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { IndexDetailedUsersResponse } from '@api/v4/private/resources/users';

import { UserDetails } from '@components/bulk_management/user-settings/users-table/UserDetails';
import useDebounce from '@components/common/custom_hooks/useDebounce';
import { TextButton } from '@components/library/button';
import { getStandardPaginationLabels, standardError } from '@translations';

import classes from './styles/user-groups.module.scss';

interface UserGroupMembersTableProps {
  reloadMembers: boolean;
  setReloadMembers: SetStateDispatch<boolean>
  userGroupKey: string;
}

interface ParamsState {
  order: Order;
  page: number;
  per: number;
  sort_by: SortBy;
}

const initialParams: ParamsState = {
  order: 'desc',
  page: 1,
  per: 10,
  sort_by: 'name'
};

type Order = 'asc' | 'desc';
type SortBy = 'name';

type ParamsAction =
| { page: number; type: 'page'; }
| { order: Order, sort_by: SortBy; type: 'sort' }
| { type: 'reset' };

const reducer = (state: ParamsState, action: ParamsAction): ParamsState => {
  const { type } = action;
  switch (type) {
    case 'page':
      return { ...state, page: action.page };
    case 'reset':
      return { ...initialParams };
    case 'sort':
      return { ...state, order: action.order, sort_by: action.sort_by };
    default:
      return { ...state };
  }
};

export const UserGroupsUsersTable: FunctionComponent<UserGroupMembersTableProps> = ({
  reloadMembers,
  setReloadMembers,
  userGroupKey,
}) => {
  const [state, dispatch] = useReducer(reducer, initialParams);
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState<StandardTableRow[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [userGroupMemberKey, setUserGroupMemberKey] = useState('');
  const [deleteMembersOpen, setDeleteMembersOpen] = useState(false);
  const [userName, setUserName] = useState('');

  const debouncedSearch = useDebounce({
    delay: 600,
    disableDebounce: process?.env?.NODE_ENV === 'circle',
    value: searchValue
  });

  const membersFetch = useFetch<IndexDetailedUsersResponse>({
    url: `api/v4/private/user_groups/${userGroupKey}/users`,
    params: {
      ...state,
      search: debouncedSearch || undefined
    },
    fetchOnMount: false
  });

  const reset = (): void => {
    setSearchValue('');
    dispatch({ type: 'reset' });
  };

  const membersDelete = useFetch({
    url: `api/v4/user_group_members/${userGroupMemberKey}`,
    fetchOnMount: false,
    method: 'DELETE'
  });

  const memberCount = membersFetch.response?.meta?.total_count || 0;

  useEffect(() => {
    membersFetch.fetch();
  }, [state, debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reloadMembers) {
      setReloadMembers(false);
      reset();
    }
  }, [reloadMembers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userGroupMemberKey) {
      setDeleteMembersOpen(true);
    }
  }, [userGroupMemberKey]);

  useEffect(() => {
    if (membersDelete.response) {
      setUserGroupMemberKey('');
      setDeleteMembersOpen(false);
      membersFetch.fetch();
    }
  }, [membersDelete.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (membersFetch.response) {
      const tableRows = membersFetch.response.data.map((member) => {
        return  ({
          user: <UserDetails user={member.attributes}/>,
          delete: (
            <IconButton
              className={classnames(classes.textIconButton, classes.textButton)}
              icon={FontIcons.Remove}
              label={t`Remove user`}
              look={ButtonLooks.TextWarning}
              onClick={(): void => {
                setUserGroupMemberKey(member.attributes.user_group_member_key);
                setUserName(member.attributes.full_name);
              }}
            />
          )
        });
      });
      setRows(tableRows);
      setTotalPages(membersFetch?.response?.meta?.total_pages || 0 );
    }
  }, [membersFetch.response]);

  const columns: StandardTableColumn[] = [{
    children: <Trans>User</Trans>,
    heading: <Trans>User</Trans>,
    rowKey: 'user',
    width: '60%'
  }, {
    rowKey: 'delete',
    tdClassName: classnames(classes.removeUserColumn)
  }];

  const deleteMemberConfirmationDialog = (
    <StandardDialog
      height={300}
      id='member-delete-confirmation-dialog'
      labels={{
        closeButtonLabel: t`Close dialog`
      }}
      onClose={(): void => { setUserGroupMemberKey(''); setUserName(''); }}
      open={deleteMembersOpen}
      setOpen={setDeleteMembersOpen}
      showFooter={false}
      size={DialogSizes.Small}
      title={t`Remove this user from the group?`}
      titleIcon={FontIcons.Trash}
    >
      <div className={classnames(classes.memberDeleteConfirmationFooter)}>
        <StandardText align={FontAlignments.Center}>
          <Trans>They will no longer have access to any resources the group has permission to. This can be undone by adding this user again.</Trans>
        </StandardText>
        <StandardText align={FontAlignments.Center} className={classes.deleteConfirmationDialogUserName} weight={FontWeights.Bold}>
          {userName}
        </StandardText>
        <StandardButton
          className={classnames(classes.confirmationDeleteButton)}
          loading={membersDelete.loading}
          look={ButtonLooks.Danger}
          onClick={(): void => {
            membersDelete.fetch();
          }}
        >
          <Trans>Remove user</Trans>
        </StandardButton>
      </div>
    </StandardDialog>
  );

  return (
    <section className={classnames(classes.userGroupMembersSection, 'user-groups-member-info')}>
      <div className={classes.userGroupsHeadingWrapper}>
        <h3 className={classnames(classes.userGroupMembersTableHeading, 'user-groups-member-info__title')}>
          <Plural one="# User" other="# Users" value={memberCount} />
        </h3>
        <div className={classnames(classes.membersTableSearch, 'user-groups-member-info__search')}>
          <SearchTextfield
            className={classnames(classes.memberSearch, 'user-groups-member-info__search-input')}
            id="member-search"
            label={t`Search users`}
            onChange={(e: InputChangeEvent): void => { setSearchValue(e.target.value); }}
            placeholder={t`Search users`}
            showLabel={false}
            value={searchValue}
          />
        </div>
      </div>
      <div className={classes.membersTableContainer}>
        {membersFetch.response && deleteMemberConfirmationDialog}
        <StandardTable
          caption={t`List of user group members`}
          columns={columns}
          emptyContent={debouncedSearch ? (
            <>
              <StandardText
                className={classes.empty}
              >
                <Trans>Sorry! No results were found.</Trans>
              </StandardText>
              <StandardButton
                className={classes.create}
                look={ButtonLooks.Default}
                onClick={(): void => {
                  setSearchValue('');
                  const searchInput = document.getElementById('member-search');
                  if (searchInput) {
                    searchInput.focus();
                  }
                }}
              >
                <Trans>Try a different search</Trans>
              </StandardButton>
            </>
          ) : (
            <>
              <StandardText
                className={classes.empty}
              >
                <Trans>You haven't added any users yet.</Trans>
              </StandardText>
              <StandardButton
                className={classes.create}
                look={ButtonLooks.Default}
                onClick={(): void => {
                  const taggerTextarea = document.getElementById('email-address-tagger-combobox');
                  if (taggerTextarea) {
                    taggerTextarea.focus();
                  }
                }}
              >
                <Trans>Add users</Trans>
              </StandardButton>
              <img
                alt=""
                className={classes.img}
                src="https://cdn.brandfolder.io/27C9EC93/at/q6y1hf-fcrlsw-12xbfy/person-digging-in-empty-box.svg"
              />
            </>
          )}
          error={!!membersFetch.error}
          errorContent={(
            <>
              {standardError()}&nbsp;
              <TextButton
                onClick={(): void => reset()}
              >
                <Trans>Try again</Trans>
              </TextButton>
            </>
          )}
          id="members-table"
          loaderLabel={t`Loading members`}
          loading={membersFetch.loading}
          rows={rows}
          scrollX={false}
          tableProps={{ className: classes.usersTable, id: '' }}
          trProps={{ className:classes.usersTableRows }}
        />
      </div>
      {totalPages > 1 && (
        <div className={classnames(classes.membersPagination, 'user-groups-member-info__pagination')}>
          <StandardPagination
            disabled={membersFetch.loading}
            labels={getStandardPaginationLabels()}
            onPageChange={(page): void => {
              dispatch({ page, type: 'page' });
            }}
            total={totalPages}
          />
        </div>
      )}
    </section>
  );
};
