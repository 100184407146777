/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import Row from './row';

import './styles/metadata_conversions_table.scss';

const Table = ({
  items,
  refreshData,
  setEditableKey,
  setShowForm,
}) => {
  const listItems = items.map(({
    id,
    metadata_term,
    key_term,
    convert_to_tags,
    created_at
  }) => (
    <Row
      key={id}
      convertToTags={convert_to_tags}
      createdAt={created_at}
      id={id}
      keyTerm={key_term}
      metaTerm={metadata_term}
      onRemove={refreshData}
      setEditableKey={setEditableKey}
      setShowForm={setShowForm}
    />
  ));

  return (
    <ul className="metadata_conversions--table">
      <li className="headers">
        <h3><Trans>Embedded Metadata Field</Trans></h3>
        <h3><Trans>Converted to</Trans></h3>
        <span />
        <span>
          <span className="button-header" />
          <span className="button-header" />
        </span>
      </li>
      {listItems}
    </ul>
  );
};

Table.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    metadata_term: PropTypes.string,
    key_term: PropTypes.string,
    convert_to_tags: PropTypes.bool,
    created_at: PropTypes.string,
  })),
  refreshData: PropTypes.func,
  setEditableKey: PropTypes.func.isRequired,
  setShowForm: PropTypes.func.isRequired,
};

Table.defaultProps = {
  items: [],
  refreshData: () => {},
};

export default Table;
