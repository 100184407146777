import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { Td } from './Td';
import { Tr } from './Tr';
import { BaseTrEmptyProps } from '../../base/BaseTableProps';

import classes from '../styles/standard-table.module.scss';

export const TrEmpty: FunctionComponent<BaseTrEmptyProps> = (props) => {
  const { children, colSpan } = props;
  return (
    <Tr className={classnames('trEmpty', 'standardTrEmpty', classes.trEmpty)} hover={false}>
      <Td colSpan={colSpan}>
        {children}
      </Td>
    </Tr>
  );
};
