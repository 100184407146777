import React from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import classnames from 'classnames';

import './styles/custom_drag_layer.scss';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 1000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

const getItemStyles = ({ initialOffset, currentOffset }) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

const CustomDragLayer = ({
  isDragging,
  initialOffset,
  currentOffset,
  item,
  selectedAssetKeys,
}) => {
  if (!isDragging || !item) {
    return null;
  }

  const { name, attachmentCount, reorder } = item;

  let count = selectedAssetKeys.size;
  if (!selectedAssetKeys.has(item.id)) {
    count += 1;
  }

  const isMultiple = !reorder && count > 1;

  return (
    <div style={layerStyles}>
      <div style={getItemStyles({ initialOffset, currentOffset })}>
        <div className={classnames('asset-drag-preview', { multiple: isMultiple })}>
          <div className="asset-drag-preview__left">
            <p>{name}</p>
          </div>
          <div className="asset-drag-preview__right">
            {attachmentCount > 1 ? (
              <div className="asset-drag-preview__count">
                <span className="bff-paperclip" aria-label="hidden" />
                <span className="asset-drag-preview__count-text">{count}</span>
              </div>
            ) : null}
          </div>
          {isMultiple ? (
            <div className="asset-drag-preview__badge">
              <span>{count}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

DragLayer.propTypes = {
  isDragging: PropTypes.bool,
  initialOffset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  currentOffset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  selectedAssetKeys: PropTypes.instanceOf(Set),
};
DragLayer.defaultProps = {
  isDragging: false,
  selectedAssetKeys: [],
};

export default DragLayer((monitor) => ({
  isDragging: monitor.isDragging,
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  item: monitor.getItem(),
}))(CustomDragLayer);
