import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseIconProps, FontIconColors, FontIconPositions, FontIcons } from '@components/library/icon';
import { BaseIcon } from '../base';

import classes from './styles/font-icon.module.scss';

type FontIconProps = {
  icon: FontIcons;
  color?: FontIconColors;
  iconSize?: number;
  /** When position={FontIconPositions.Left}, CSS padding-right is set to 6px. When position={FontIconPositions.Right}, CSS padding-left is set to 6px. */
  position?: FontIconPositions;
};

type FontIconCombinedProps = BaseIconProps & FontIconProps;

export const FontIcon: FunctionComponent<FontIconCombinedProps> = (props) => {
  const {
    className,
    color,
    icon,
    iconSize,
    position,
    ...otherProps
  } = props;

  let updatedProps = otherProps;

  if (iconSize) {
    updatedProps = {
      ...updatedProps,
      style: {
        fontSize: `${iconSize}px`,
        ...!!updatedProps.style && updatedProps.style
      },
    };
  }

  return (
    <BaseIcon
      {...updatedProps}
      className={classnames(
        `bff-${icon}`,
        color && classes[`bf-icon__${color}`],
        position && classes[`bf-icon__position-${position}`],
        className
      )}
      variantName="font"
    />
  );
};
