/* eslint-disable react/require-default-props */
import { plural, t, Plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { removeFromLabel } from '@api/v4/bulk_actions/assets';
import { BFLoader } from '@components/common/loader/main';
import {
  bulkRefreshSections
} from '@helpers/show_page_helpers';

import renderModal from '../modals/renderModal';

const BulkRemoveLabelModal = (props) => {
  const [isLoading, toggleIsLoading] = useState(false);
  const [disableSubmitButton, toggleDisableSubmitButton] = useState(false);

  useEffect(() => (
    () => { props.closeModal(); }
  ), []);

  const {
    activeLabelKey,
    addRemoveSelected,
    closeModal,
    labels,
    selectedAssetKeys
  } = props;

  const activeLabel = labels.labelsFlat[activeLabelKey].name;
  const assetCount = selectedAssetKeys.size;
  const loaderActive = isLoading ? 'loader-active' : '';

  const removeLabel = () => {
    toggleIsLoading(true);
    toggleDisableSubmitButton(true);

    removeFromLabel(Array.from(selectedAssetKeys), activeLabelKey)
      .then(() => {
        BF.fx.updateAssetPageCount(assetCount, false);
        bulkRefreshSections(selectedAssetKeys, 2000);
        addRemoveSelected([]);
        closeModal();
        Notify.create({
          title: plural(assetCount, {
            one: `${activeLabel} label removed from ${assetCount} asset!`,
            other: `${activeLabel} label removed from ${assetCount} assets!`
          }),
          body: t`Updates processing: a page refresh may be required to reflect changes`,
          type: 'success',
        });
      })
      .catch(() => {
        toggleIsLoading(false);
        toggleDisableSubmitButton(false);
        Notify.create({
          title: t`An error occurred when removing label. Please try again.`,
          type: 'error'
        });
      });
  };

  return (
    <div className="modal-content-wrapper bulk-label-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-label icon" />
        <h2 className="modal-title"><Trans>Remove Label</Trans></h2>
      </div>
      <div className={`modal-content-wrapper__body ${loaderActive}`}>
        {isLoading
          ? <BFLoader />
          : (
            <div>
              <h3 className="body-title">
                {/* TODO: don't rely on bold-text class for spacing */}
                <Plural
                  one={
                    <Trans>
                      Are you sure you want to remove
                      <span className="bold-text">{activeLabel}</span>
                      label from
                      <span className="bold-text">{assetCount} asset?</span>
                    </Trans>
                  }
                  other={
                    <Trans>
                      Are you sure you want to remove
                      <span className="bold-text">{activeLabel}</span>
                      label from
                      <span className="bold-text">{assetCount} assets?</span>
                    </Trans>
                  }
                  value={assetCount}
                />
              </h3>
              <div className="button-container">
                <button
                  className="button tertiary sm t-close-modal"
                  onClick={closeModal}
                  type="button"
                >
                  <Trans>Cancel</Trans>
                </button>
                <button
                  className="button sm warning t-confirm-modal"
                  disabled={disableSubmitButton}
                  onClick={removeLabel}
                  type="button"
                >
                  <Trans>Remove</Trans>
                </button>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

BulkRemoveLabelModal.propTypes = {
  activeLabelKey: PropTypes.string.isRequired,
  addRemoveSelected: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  labels: PropTypes.shape({}),
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
};

BulkRemoveLabelModal.defaultProps = {
  labels: {},
};

const ModalComponent = renderModal(BulkRemoveLabelModal, 'BulkRemoveLabelModal');
export default ModalComponent;
