import { StandardButton, ButtonLooks, ButtonSizes } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, ReactNode } from 'react';

export interface CustomFieldsRowDeleteProps {
  children?: ReactNode;
  loading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const CustomFieldsRowDelete: FunctionComponent<CustomFieldsRowDeleteProps> = ({
  children,
  loading,
  onCancel,
  onSubmit,
}) => {
  const handleCancel = (): void => {
    onCancel();
  };

  const handleSubmit = (): void => {
    onSubmit();
  };

  return (
    <>
      <div className="delete-custom-fields-mode">
        &nbsp; {/* Placeholder for spacing */}
      </div>
      <div className="delete-custom-fields-mode overlay">
        {children}
        <div className="delete-buttons">
          {!loading && (
            <StandardButton
              className="delete-key-button"
              look={ButtonLooks.Tertiary}
              onClick={handleCancel}
              size={ButtonSizes.Medium}
            >
              <Trans>Cancel</Trans>
            </StandardButton>
          )}
          <StandardButton
            disabled={loading}
            loading={loading}
            look={ButtonLooks.Danger}
            onClick={handleSubmit}
            size={ButtonSizes.Medium}
          >
            <Trans>Delete key</Trans>
          </StandardButton>
        </div>
      </div>
    </>
  );
};
