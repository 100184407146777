/* eslint-disable @typescript-eslint/naming-convention */
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import {
  AssetData,
  AssetResponse,
  AssetTypes,
  ColorData,
  ExternalMediumData,
  FontData,
  GenericFileData,
  isExpectedDataShape,
  LinkObject,
  PersonData,
  PressData,
  Tag,
  WatermarkObject
} from '@api/v4/assets/assetTypes';
import { CustomFieldValue, CustomFieldValuesListData } from '@api/v4/assets/customFieldTypes';
import { Attachment } from '@api/v4/attachments/attachmentTypes';
import {
  ActionTypes,
  AssetDetailsPayload,
  CustomFieldPayloadOperations,
  FlattenedAttachment,
  FlattenedCustomFieldKeyValuesMap,
  InitialModalEditFormContentState,
  isAssetDetailsAction,
  isAttachmentDetailsAction,
  isDeleteAttachmentAction,
  isDeleteCustomFieldValuesAction,
  isFetchCustomFieldKeysAction,
  isFetchCustomFieldValuesListAction,
  isInitialAction,
  isNewAssetAction,
  isNewTaskAction,
  isReplaceAttachmentAction,
  isRepositionAttachmentAction,
  isResolveAssetAction,
  isResolveAttachmentUpdateAction,
  isResolveDeletedAttachmentAction,
  isResolveDeletedCustomFieldValueAction,
  isTaskDetailsAction,
  isThumbnailOverrideAction,
  isUpdateCustomFieldKeyAction,
  isUpdateCustomFieldValuesAction,
  isUpdateLocaleAction,
  isUploadNewAttachment,
  isValidationErrorAction,
  isWatermarkAction,
  ModalEditFormContentState,
  ModalEditFormReducerAction,
  ReducerState,
  ThumbnailOverridePayloadOperations,
  UpdateCustomFieldValuesPayload,
  ValidationErrors,
  WatermarkPayloadOperations,
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import { TaskClient, TaskServer } from '@api/v4/tasks';
import { camelizeObjectKeys } from '@components/library/utils';
import { getCustomFieldChanges } from '@components/asset/modal/tabs/edit/change_detection/custom-fields-change-detection';
import { isValidHex, newAssetInitialState, newAssetEmptyEditState, getHasMissingRequiredCustomFieldValuesPayload, sortChildKeysBelowParentKeys } from './helpers';
import { containsSpecialCharacters } from '@helpers/custom-field-validation';

const getFirstAttachmentKey = (attachments: FlattenedAttachment[]): string => {
  const posZeroAttachments = attachments.filter((attachment) => attachment.position === 0);
  // either we have one attachment with position zero and we want to get that one
  // or we have many attachments with position zero and we want to default to the first one
  return posZeroAttachments[0] ? posZeroAttachments[0].key : '';
};

const getIncludedResources = <T>(included: ApiResponseObject<T>[], type: string): ApiResponseObject<T>[] => (
  included.filter((item) => item.type === type)
);

const validateResponse = (response: AssetResponse): void => {
  if (!response.data) {
    throw new Error('response does not have data');
  }
};

const flattenAttachments = (attachments: ApiResponseObject<Attachment>[]): FlattenedAttachment[] => (
  attachments.map(({ id, attributes }) => ({
    extension: attributes.extension,
    filename: attributes.filename,
    key: id,
    mimetype: attributes.mimetype,
    size: attributes.size,
    temporaryAttachment: false,
    url: attributes.url,
    height: attributes.height,
    position: attributes.position,
    thumbnailUrl: attributes.thumbnail_url,
    width: attributes.width
  }))
);

const getResponseDetails = (response: AssetResponse): Partial<InitialModalEditFormContentState> => {
  validateResponse(response);
  // eslint-disable-next-line no-param-reassign
  response = response.included ? response : { ...response, included: [] };

  const task = BFG.hasFeature('workspace') && [...response.included].find((includedItem) => (includedItem.type === 'tasks')) as ApiResponseObject<TaskServer, string>;
  // if task worskapce key doesn't match the current resource workspace id, the task belongs to a different workspace
  const hasTask = task && BFG.resource.is_workspace && task.attributes.workspace_key === BFG.resource.key;

  return {
    asset: {
      ...response.data.attributes,
      description: response.data.attributes.description?.replace(/\r/g, '\n'), // remove carriage return (\r) for trumbowyg
    },
    assetKey: response.data.id,
    assetType: response.data.type as AssetTypes,
    attachments: flattenAttachments(getIncludedResources<Attachment>(response.included, 'attachments')),
    customFieldKeys: null,
    flattenedCustomFieldKeyValuesMap: null,
    tags: getIncludedResources<Tag>(response.included, 'tags').map(({ attributes }) => attributes),
    ...hasTask && {
      task: {
        ...camelizeObjectKeys<TaskServer, TaskClient>(task.attributes)
      }
    },
  };
};

const transformAssetResponse = (
  {
    asset,
    assetType,
    tags,
    task,
    attachments,
  }: Partial<InitialModalEditFormContentState>
): Partial<ModalEditFormContentState> => {
  const getExpectedData = <T extends AssetData>(expectedAssetType: AssetTypes): T | null => (
    isExpectedDataShape<T>(assetType, expectedAssetType, asset.data) ? asset.data : null
  );

  const rawPressData = getExpectedData<PressData>(AssetTypes.Press);
  let pressData = rawPressData;
  const formattedPublishDate = rawPressData?.published_date ? moment(rawPressData.published_date).format('MM/DD/YYYY') : undefined;
  if (formattedPublishDate) {
    pressData = {
      ...pressData,
      published_date: formattedPublishDate
    };
  }
  return {
    assetDescription: asset.description || '',
    assetName: asset.name,
    attachments: attachments.sort((attachmentA, attachmentB) => attachmentA.position - attachmentB.position),
    colorData: getExpectedData<ColorData>(AssetTypes.Color),
    externalMediumData: getExpectedData<ExternalMediumData>(AssetTypes.ExternalMedium),
    fontData: getExpectedData<FontData>(AssetTypes.Font),
    firstAttachmentKey: getFirstAttachmentKey(attachments),
    genericFileData: getExpectedData<GenericFileData>(AssetTypes.GenericFile),
    personData: getExpectedData<PersonData>(AssetTypes.People),
    pressData,
    tags: tags.map((attributes) => ({ ...attributes, id: attributes.name })),
    task,
    thumbnailUrl: asset.thumbnail_url,
    thumbnailOverride: asset.thumbnail_override,
    watermark: asset.watermark ? asset.watermark : {}
  };
};

const flattenCustomFieldKeyValuesList = (response: CustomFieldValuesListData): FlattenedCustomFieldKeyValuesMap => {
  const keyValuesMap: FlattenedCustomFieldKeyValuesMap = {};
  response.data.forEach(({ id, attributes, relationships }) => {
    const customFieldKeyId = relationships?.custom_field_key?.data?.id;
    if (customFieldKeyId) {
      if (keyValuesMap[customFieldKeyId]) {
        const oldKeyValue = keyValuesMap[customFieldKeyId];
        keyValuesMap[customFieldKeyId] = {
          ...oldKeyValue,
          customFieldValues: [
            ...oldKeyValue.customFieldValues,
            {
              key: id,
              value: attributes.value
            }
          ]
        };
      } else {
        keyValuesMap[customFieldKeyId] = {
          customFieldKey: {
            id: customFieldKeyId,
            name: attributes.key
          },
          customFieldValues: [{
            key: id,
            value: attributes.value,
          }]
        };
      }
    }
  });
  return keyValuesMap;
};

const handleErrorStateUpdates = (
  state: ReducerState,
  assetDetails?: AssetDetailsPayload,
  customFieldsDetails?: {
    newState?: ReducerState;
    customFieldsUpdated?: UpdateCustomFieldValuesPayload
  }
): ValidationErrors[] => {
  const newErrorState = [...state.errorState];

  if (assetDetails) {
    if (assetDetails.assetName && state.errorState.includes(ValidationErrors.MissingAssetName)) {
      newErrorState.splice(newErrorState.indexOf(ValidationErrors.MissingAssetName), 1);
    }

    if (assetDetails.assetDescription && state.errorState.includes(ValidationErrors.MissingDescription)) {
      newErrorState.splice(newErrorState.indexOf(ValidationErrors.MissingDescription), 1);
    }

    if (assetDetails.externalMediumData
      && assetDetails.externalMediumData.url
      && state.errorState.includes(ValidationErrors.MissingMediaUrl)
    ) {
      newErrorState.splice(newErrorState.indexOf(ValidationErrors.MissingMediaUrl), 1);
    }

    if (assetDetails.fontData
      && (assetDetails.fontData.other_font_id || assetDetails.fontData.web_font_id)
    ) {
      if (state.errorState.includes(ValidationErrors.MissingFontInput)) {
        newErrorState.splice(newErrorState.indexOf(ValidationErrors.MissingFontInput));
      }
    }

    if (assetDetails.colorData) {
      if (state.errorState.includes(ValidationErrors.InvalidHexValue)) {
        if (assetDetails.colorData.hex) {
          if (isValidHex(assetDetails.colorData.hex)) {
            newErrorState.splice(newErrorState.indexOf(ValidationErrors.InvalidHexValue), 1);
          }
        } else if (assetDetails.colorData.hex === '') {
          newErrorState.splice(newErrorState.indexOf(ValidationErrors.InvalidHexValue), 1);
        }
      }
    }

    if (assetDetails.task && assetDetails.task.taskDescription && state.errorState.includes(ValidationErrors.DescriptionMaxLengthExceeded)) {
      newErrorState.splice(newErrorState.indexOf(ValidationErrors.DescriptionMaxLengthExceeded), 1);
    }

    if (assetDetails.task && assetDetails.task.dueDate && state.errorState.includes(ValidationErrors.InvalidDueDate)) {
      newErrorState.splice(newErrorState.indexOf(ValidationErrors.InvalidDueDate), 1);
    }
  }

  if (customFieldsDetails?.customFieldsUpdated && state.errorState.includes(ValidationErrors.MissingRequiredCustomFields)) {
    if (!getHasMissingRequiredCustomFieldValuesPayload(customFieldsDetails.customFieldsUpdated)) {
      newErrorState.splice(newErrorState.indexOf(ValidationErrors.MissingRequiredCustomFields), 1);
    }
  }

  if (customFieldsDetails?.newState && state.errorState.includes(ValidationErrors.InvalidKeyName)) {
    const { createdCustomFieldKeyValuePears } = getCustomFieldChanges(customFieldsDetails.newState.editState, customFieldsDetails.newState.initialData);

    const keysWithSpecialCharacters = createdCustomFieldKeyValuePears?.some(({ customFieldKey }) => containsSpecialCharacters(customFieldKey.name));
    if (!keysWithSpecialCharacters) {
      newErrorState.splice(newErrorState.indexOf(ValidationErrors.InvalidKeyName), 1);
    }
  }

  return newErrorState;
};

/* helper for updating positions after deleting attachment, note that it mutates attachmentsCopy */
const handleDeleteAttachment = (attachmentsCopy: FlattenedAttachment[], index: number): { thumbnailUrl: string } | false => {
  attachmentsCopy.splice(index, 1);
  for (let i = 0; i < attachmentsCopy.length; i += 1) {
    // eslint-disable-next-line no-param-reassign
    attachmentsCopy[i].position = i;
  }
  // update asset thumbnail image if first attachment is deleted
  const updateAssetThumbnail = index === 0;
  const thumbnailUrl = attachmentsCopy[0]?.thumbnailUrl || attachmentsCopy[0]?.url || '';
  return updateAssetThumbnail && { thumbnailUrl };
};

const modalEditFormReducer = (state: ReducerState, action: ModalEditFormReducerAction): ReducerState => {
  if (isInitialAction(action)) {
    const { printui, response } = action.payload;
    const responseDetails = getResponseDetails(response);
    return {
      ...state,
      initialData: { ...responseDetails, printui } as InitialModalEditFormContentState,
      editState: {
        ...transformAssetResponse(responseDetails),
        tagSearchSuggestions: null,
        customFieldMaps: null,
        createCustomFieldMaps: [],
        terribleTrumbowygFlag: state.ugtLocale
      } as ModalEditFormContentState,
      errorState: []
    };
  }

  if (isNewAssetAction(action)) {
    const { assetType } = action.payload;
    return {
      ugtLocale: BFG.locales.ugtLocaleDefault,
      initialData: newAssetInitialState(assetType),
      editState: newAssetEmptyEditState(),
      errorState: []
    };
  }

  if (isNewTaskAction(action)) {
    const { assetType } = action.payload;
    return {
      ugtLocale: BFG.locales.ugtLocaleDefault,
      initialData: newAssetInitialState(assetType),
      editState: newAssetEmptyEditState(),
      errorState: []
    };
  }

  if (action.type === ActionTypes.ResolveTags) {
    return {
      ...state,
      initialData: {
        ...state.initialData,
        tags: state.editState.tags as Tag[]
      }
    };
  }

  if (isRepositionAttachmentAction(action)) {
    const { hoveredIndex, draggedIndex } = action.payload;
    const attachments = state.editState.attachments.map((attachment, i) => ({
      ...attachment,
      position: i // reset positions to be sequential
    }));
    const draggedItem = attachments[draggedIndex];

    attachments.splice(draggedIndex, 1); // remove dragged attachmemt from attachments
    attachments.splice(hoveredIndex, 0, draggedItem); // insert dragged attachment into hovered spot

    const updatedAttachments = attachments.map((attachment, i) => {
      const newAttachment = attachment;
      if (newAttachment) {
        newAttachment.position = i;
      }
      return newAttachment;
    });

    // update asset thumbnail image if first attachment is updated
    const updateAssetThumbnail = hoveredIndex === 0 || draggedIndex === 0;
    const attachmentThumbnailUrl = updatedAttachments[0]?.thumbnailUrl || updatedAttachments[0].url;

    return {
      ...state,
      editState: {
        ...state.editState,
        attachments: updatedAttachments,
        ...updateAssetThumbnail && { thumbnailUrl: attachmentThumbnailUrl }
      }
    };
  }

  if (isUploadNewAttachment(action)) {
    const isFirstAttachment = !state.editState.attachments.length;

    return {
      ...state,
      editState: {
        ...state.editState,
        attachments: [...state.editState.attachments, ...action.payload.attachments],
        ...isFirstAttachment && { thumbnailUrl: action.payload.attachments[0].url },
      }
    };
  }

  if (isAttachmentDetailsAction(action)) {
    const { index, filename } = action.payload;
    const attachmentsCopy = [...state.editState.attachments];
    attachmentsCopy[index] = {
      ...attachmentsCopy[index],
      filename
    };
    return {
      ...state,
      editState: {
        ...state.editState,
        attachments: attachmentsCopy,
      }
    };
  }

  if (isReplaceAttachmentAction(action)) {
    const { index, replacement } = action.payload;
    const replacementWithOriginalKey = {
      ...replacement,
      key: state.editState.attachments[index].key
    };
    const attachmentsCopy = [...state.editState.attachments];
    attachmentsCopy[index] = replacementWithOriginalKey;
    return {
      ...state,
      editState: {
        ...state.editState,
        attachments: attachmentsCopy,
        ...(index === 0) && { thumbnailUrl: attachmentsCopy[0]?.url || '' }
      }
    };
  }

  if (isDeleteAttachmentAction(action)) {
    const { index } = action.payload;
    const attachmentsCopy = state.editState.attachments.map((att) => ({ ...att }));
    const hasThumbnailUpdate = handleDeleteAttachment(attachmentsCopy, index);
    return {
      ...state,
      editState: {
        ...state.editState,
        attachments: attachmentsCopy,
        ...hasThumbnailUpdate && { thumbnailUrl: hasThumbnailUpdate.thumbnailUrl }
      }
    };
  }

  if (isResolveDeletedAttachmentAction(action)) {
    const { attachmentKey } = action.payload;
    const attachmentsCopy = [...state.initialData.attachments];
    const index = attachmentsCopy.findIndex((attachment) => attachment.key === attachmentKey);
    const hasThumbnailUpdate = handleDeleteAttachment(attachmentsCopy, index);
    return {
      ...state,
      initialData: {
        ...state.initialData,
        attachments: attachmentsCopy,
        ...hasThumbnailUpdate && { thumbnailUrl: hasThumbnailUpdate.thumbnailUrl }
      }
    };
  }

  if (isResolveAttachmentUpdateAction(action)) {
    const { id, attributes } = action.payload;
    const attachmentsCopy = [...state.initialData.attachments];
    const targetAttachmentIndex = attachmentsCopy.findIndex((attachment) => attachment.key === id);
    attachmentsCopy[targetAttachmentIndex] = {
      ...attachmentsCopy[targetAttachmentIndex],
      filename: attributes.filename,
      url: attributes.url,
      extension: attributes.extension,
      position: attributes.position
    };
    return {
      ...state,
      initialData: {
        ...state.initialData,
        attachments: attachmentsCopy
      }
    };
  }

  if (isAssetDetailsAction(action)) {
    return {
      ...state,
      editState: {
        ...state.editState,
        ...action.payload,
      },
      errorState: handleErrorStateUpdates(state, action.payload)
    };
  }

  if (isTaskDetailsAction(action)) {
    return {
      ...state,
      editState: {
        ...state.editState,
        ...action.payload,
      },
      errorState: handleErrorStateUpdates(state, action.payload)
    };
  }

  if (isResolveAssetAction(action)) {
    const { asset, newAttachmentKeys } = action.payload;
    let newAttachments = [];
    if (newAttachmentKeys) {
      newAttachments = newAttachmentKeys.map((key) => state.editState.attachments.find((attachment) => attachment.key === key));
    }
    return {
      ...state,
      initialData: {
        ...state.initialData,
        asset: {
          ...state.initialData.asset,
          ...asset,
          // We don't get data back from the asset response, so copy editState data if it exists
          // Note that the data fields (colorData, externalMediumData etc) on the editState are
          // exclusive to each other.
          data: state.editState.colorData
            || state.editState.externalMediumData
            || state.editState.fontData
            || state.editState.genericFileData
            || state.editState.personData
            || state.editState.pressData
            || undefined,
          thumbnail_override: state.editState.thumbnailOverride || undefined,
          watermark: state.editState.watermark
        },
        attachments: [
          ...state.initialData.attachments,
          ...newAttachments
        ]
      }
    };
  }

  if (isFetchCustomFieldKeysAction(action)) {
    return {
      ...state,
      initialData: {
        ...state.initialData,
        customFieldKeys: sortChildKeysBelowParentKeys(action.payload.response),
        dependentCustomFields: action.payload.response.included?.map((dcf) => ({ ...dcf.attributes })) || null
      },
      editState: {
        ...state.editState,
      }
    };
  }

  if (isFetchCustomFieldValuesListAction(action)) {
    return {
      ...state,
      initialData: {
        ...state.initialData,
        flattenedCustomFieldKeyValuesMap: flattenCustomFieldKeyValuesList(action.payload.response)
      },
      editState: {
        ...state.editState,
        flattenedCustomFieldKeyValuesMap: flattenCustomFieldKeyValuesList(action.payload.response)
      }
    };
  }

  if (isUpdateCustomFieldValuesAction(action)) {
    const { operation, customFieldKeyId, customFieldKeyName, customFieldRequired, customFieldTouched, customFieldValue } = action.payload;
    const customFieldValueMapCopy = { ...state.editState.flattenedCustomFieldKeyValuesMap };

    // if entry for key does not exist, create it (means going from no value to value/create)
    if (!customFieldValueMapCopy[customFieldKeyId]) {
      customFieldValueMapCopy[customFieldKeyId] = {
        customFieldKey: {
          id: customFieldKeyId,
          name: customFieldKeyName || state.initialData.customFieldKeys.find((cfk) => cfk.id === customFieldKeyId)?.attributes.name
        },
        customFieldRequired: customFieldRequired || state.initialData.customFieldKeys.find((cfk) => cfk.id === customFieldKeyId)?.attributes.required,
        customFieldValues: []
      };
    }

    const customFieldValueEntry = customFieldValueMapCopy[customFieldKeyId];
    if (operation === CustomFieldPayloadOperations.Create) {
      const cfValObj: CustomFieldValue = {
        key: customFieldValue.key || `create-cfv-${uuidv4()}`,
        value: customFieldValue.value
      };

      // only append if value doesn't already exist
      const existingValue = customFieldValueEntry.customFieldValues
        .find((cfv) => cfv.value === customFieldValue.value);

      if (!existingValue) {
        customFieldValueMapCopy[customFieldKeyId] = {
          ...customFieldValueEntry,
          customFieldValues: [
            ...customFieldValueEntry.customFieldValues,
            cfValObj,
          ]
        };
      }

      return {
        ...state,
        editState: {
          ...state.editState,
          flattenedCustomFieldKeyValuesMap: customFieldValueMapCopy
        },
        errorState: handleErrorStateUpdates(state, undefined, { customFieldsUpdated: action.payload }) //action.payload is the current transaction
      };
    }

    const operationIndex = customFieldValueEntry.customFieldValues.findIndex((cfv) => cfv.key === customFieldValue.key);
    const customFieldValuesCopy = [...customFieldValueEntry.customFieldValues];
    if (operation === CustomFieldPayloadOperations.Delete) {
      customFieldValuesCopy.splice(operationIndex, 1);
    }

    if (operation === CustomFieldPayloadOperations.Update) {
      customFieldValuesCopy[operationIndex] = customFieldValue;
      customFieldValueMapCopy[customFieldKeyId] = {
        ...customFieldValueEntry,
        customFieldValues: customFieldValuesCopy,
        customFieldRequired,
        customFieldTouched
      };
    }

    customFieldValueMapCopy[customFieldKeyId] = {
      ...customFieldValueEntry,
      customFieldValues: customFieldValuesCopy,
      customFieldRequired,
      customFieldTouched
    };

    // Clear previous child field values of updated custom field
    const childFields = state.initialData?.dependentCustomFields?.filter((dcf) => dcf.parent_key === customFieldKeyId);
    childFields?.forEach((childField) => {
      const childKey = childField.child_key;
      const childFieldValueEntry = customFieldValueMapCopy[childKey];
      customFieldValueMapCopy[childKey] = {
        ...childFieldValueEntry,
        customFieldValues: []
      };
    });

    return {
      ...state,
      editState: {
        ...state.editState,
        flattenedCustomFieldKeyValuesMap: customFieldValueMapCopy
      },
      errorState: handleErrorStateUpdates(state, undefined, { customFieldsUpdated: action.payload })
    };
  }

  if (isUpdateCustomFieldKeyAction(action)) {
    const { customFieldKeyId, customFieldKeyName } = action.payload;

    const newState = {
      ...state,
      editState: {
        ...state.editState,
        flattenedCustomFieldKeyValuesMap: {
          ...state.editState.flattenedCustomFieldKeyValuesMap,
          [customFieldKeyId]: {
            ...state.editState.flattenedCustomFieldKeyValuesMap[customFieldKeyId],
            customFieldKey: {
              id: customFieldKeyId,
              name: customFieldKeyName
            }
          }
        }
      }
    };

    return { ...newState, errorState: handleErrorStateUpdates(state, undefined, { newState }) };

  }

  if (isDeleteCustomFieldValuesAction(action)) {
    const { customFieldKeyId, customFieldRequired, customFieldTouched, deleteKey } = action.payload;
    const customFieldKeyDetails = state.initialData.customFieldKeys?.find((cfk) => cfk.id === customFieldKeyId)?.attributes;
    if (!customFieldKeyDetails && !deleteKey) {
      // if custom field key does not exist in initial data, it must be a new key
      // therefore it is an uncontrolled custom field
      // therefore it should not have the opportunity to call this action
      throw new Error(
        'custom field key not found in initial data - new custom field keys should not allow remove values'
      );
    }

    if (deleteKey) {
      // if we're "deleting" a key, it must be from a row that the user added while editing
      const mapCopy: FlattenedCustomFieldKeyValuesMap = {};
      Object.keys(state.editState.flattenedCustomFieldKeyValuesMap).forEach((cfkId) => {
        if (cfkId !== customFieldKeyId) {
          mapCopy[cfkId] = {
            ...state.editState.flattenedCustomFieldKeyValuesMap[cfkId]
          };
        }
      });

      return {
        ...state,
        editState: {
          ...state.editState,
          flattenedCustomFieldKeyValuesMap: {
            ...mapCopy
          }
        }
      };
    }

    if (customFieldKeyDetails.multi_value_enabled) {
      // multi value custom fields are always delete/create actions, so we don't want to leave a
      // value to update in the array, just delete it
      return {
        ...state,
        editState: {
          ...state.editState,
          flattenedCustomFieldKeyValuesMap: {
            ...state.editState.flattenedCustomFieldKeyValuesMap,
            [customFieldKeyId]: {
              ...state.editState.flattenedCustomFieldKeyValuesMap[customFieldKeyId],
              customFieldValues: [],
              customFieldRequired,
              customFieldTouched
            }
          }
        }
      };
    }

    // Clear values on fields that depend on this field
    const childFields = state.initialData?.dependentCustomFields?.filter((dcf) => dcf.parent_key === customFieldKeyId);
    childFields?.forEach((childField) => {
      const childKey = childField.child_key;
      state.editState.flattenedCustomFieldKeyValuesMap[childKey] = {
        ...state.editState.flattenedCustomFieldKeyValuesMap[childKey],
        customFieldValues: []
      };
    });

    // otherwise there's a single value and we want to set it to the empty string
    // so if the user adds a value after deleting we want to keep the value id
    // so we can submit an update instead of a redundant delete + create
    const existingValue = state.editState.flattenedCustomFieldKeyValuesMap[customFieldKeyId].customFieldValues[0];
    return {
      ...state,
      editState: {
        ...state.editState,
        flattenedCustomFieldKeyValuesMap: {
          ...state.editState.flattenedCustomFieldKeyValuesMap,
          [customFieldKeyId]: {
            ...state.editState.flattenedCustomFieldKeyValuesMap[customFieldKeyId],
            customFieldValues: [{
              ...existingValue,
              value: ''
            }],
            customFieldRequired,
            customFieldTouched
          }
        }
      }
    };
  }

  if (isResolveDeletedCustomFieldValueAction(action)) {
    const { customFieldValueKey } = action.payload;
    const copyInitialCustomFieldsMultiValue = state.initialData.flattenedCustomFieldKeyValuesMap
      ? { ...state.initialData.flattenedCustomFieldKeyValuesMap }
      : null;

    if (copyInitialCustomFieldsMultiValue) {
      Object.keys(copyInitialCustomFieldsMultiValue).forEach((cfkId) => {
        copyInitialCustomFieldsMultiValue[cfkId].customFieldValues.forEach((cfv, index) => {
          if (cfv.key === customFieldValueKey) {
            copyInitialCustomFieldsMultiValue[cfkId].customFieldValues.splice(index, 1);
          }
        });
      });
    }

    return {
      ...state,
      initialData: {
        ...state.initialData,
        flattenedCustomFieldKeyValuesMap: copyInitialCustomFieldsMultiValue
      }
    };
  }

  if (isValidationErrorAction(action)) {
    return {
      ...state,
      errorState: [...state.errorState, ...action.payload.validationErrors]
    };
  }

  if (isThumbnailOverrideAction(action)) {
    const { url = null, filename = null, operation } = action.payload;
    let copyThumbnailOverride = { ...state.editState.thumbnailOverride };
    switch (operation) {
      case ThumbnailOverridePayloadOperations.Update:
        copyThumbnailOverride = { url, filename };
        break;
      case ThumbnailOverridePayloadOperations.Delete:
        copyThumbnailOverride = {} as LinkObject;
        break;
      default:
        return state;
    }
    return {
      ...state,
      editState: {
        ...state.editState,
        thumbnailOverride: copyThumbnailOverride
      }
    };
  }

  if (isWatermarkAction(action)) {
    const { gravity = null, url = null, filename = null, operation } = action.payload;
    let copyWatermark = { ...state.editState.watermark };
    switch (operation) {
      case WatermarkPayloadOperations.Update:
        copyWatermark = {
          ...copyWatermark,
          ...gravity && { gravity },
          ...url && { url },
          ...filename && { filename }
        };
        break;
      case WatermarkPayloadOperations.Delete:
        copyWatermark = {} as WatermarkObject;
        break;
      default:
        return state;
    }
    return {
      ...state,
      editState: {
        ...state.editState,
        watermark: copyWatermark
      }
    };
  }

  if (isUpdateLocaleAction(action)) {
    const { ugtLocale } = action.payload;
    if (ugtLocale === state.ugtLocale) {
      return state;
    }

    return {
      ...state,
      ugtLocale
    };
  }

  return state;
};

export default modalEditFormReducer;
