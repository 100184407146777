import { FontIcon, FontIcons, HoverTooltip, TooltipLooks } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { TertiaryIconButton } from '@components/library/button';

interface PendingInvitationsCopyIconProps {
  invitationLink: string;
}

export const PendingInvitationsCopyIcon: FunctionComponent<PendingInvitationsCopyIconProps> = ({ invitationLink }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const handleCopy = async (): Promise<void> => {
    if (window.navigator?.clipboard) {
      await window.navigator.clipboard.writeText(invitationLink);
      setCopied(true);
    }
  };

  return (
    <HoverTooltip
      look={TooltipLooks.Primary}
      text={copied ? t`Copied` : t`Copy invitation link`}
    >
      {copied ? (
        <FontIcon
          aria-label={t`Copied invitation link to clipboard`}
          className="pending-invites-icons pending-invites-checked"
          icon={FontIcons.CheckMark}
        />
      ) : (
        <TertiaryIconButton
          aria-label={t`Copy invitation link`}
          className="pending-invites-icons"
          icon={FontIcons.Copy}
          onClick={(): void => { handleCopy(); }}
        />
      )}
    </HoverTooltip>
  );
};
