import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { reportsGeneratedMsg } from './reportsCopy';

import './styles/reports-no-results.scss';

export const ReportsNoResults: FunctionComponent = () => (
  <div className='reports-no-results-container'>
    <img
      alt=""
      src="https://cdn.bfldr.com/27C9EC93/at/s95463rng5kvhqx3f99g4wx/reports.svg?auto=webp&format=svg&width=224&height=160">
    </img>
    <h3><Trans>No reports available</Trans></h3>
    <span><Trans>Your organization's reports will be available soon.</Trans></span>
    <br />
    <span>{reportsGeneratedMsg()}</span>
  </div>
);
