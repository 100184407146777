import { keyPressHelper } from "@brandfolder/utilities";
import React from "react";

import BrandfolderList from "./brandfolder_list";

export default class OptionsMenu extends React.Component {
  state = {
    brandfolder_list_open: false
  };

  syncLinkedFiles = () => {
    window.syncLinkedFiles();
    this.props.toggleMenu();
  };

  toggleBrandfolderList = () => {
    this.setState((prevState) => ({
      brandfolder_list_open: !prevState.brandfolder_list_open
    }));
  };

  logout = () => {
    window.location = `/api/adobe_plugin/${this.props.adobe_application}/signout`;
  };

  renderBrandfolderList() {
    if (
      !this.props.selectedResource
      || this.state.brandfolder_list_open
    ) {
      return (
        <BrandfolderList
          jwt={this.props.jwt}
          logout={this.logout}
          resourceType={this.props.resourceType}
          selectResource={this.props.selectResource}
          selectedResource={this.props.selectedResource}
          toggleBrandfolderList={this.toggleBrandfolderList}
        />
      );
    }
    return (
      <div className="adobe-options-list">
        <ul>
          <li
            onClick={this.props.toggleMenu}
            onKeyPress={keyPressHelper(this.props.toggleMenu)}
            role="button"
            tabIndex="0"
          >
            <span className="bff-arrow-left" />Back to Files
          </li>
          <li
            onClick={this.syncLinkedFiles}
            onKeyPress={keyPressHelper(this.syncLinkedFiles)}
            role="button"
            tabIndex="0"
          >
            <span className="bff-refresh" />Sync Linked Files
          </li>
          <li
            onClick={this.toggleBrandfolderList}
            onKeyPress={keyPressHelper(this.toggleBrandfolderList)}
            role="button"
            tabIndex="0"
          >
            <span className="bff-grid-view" /> Change Brandfolder
          </li>
          <li
            onClick={this.logout}
            onKeyPress={keyPressHelper(this.logout)}
            role="button"
            tabIndex="0"
          >
            <span className="bff-check-in" /> Logout
          </li>
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div className="adobe-options-menu">
        {this.renderBrandfolderList()}
      </div>
    );
  }
}
