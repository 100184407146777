import { ReducerState } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { isDirty } from '@components/asset/modal/tabs/edit/helpers';
import {
  AssetData,
  ColorData,
  ExternalMediumData,
  FontData,
  GenericFileData,
  PersonData,
  PressData,
  Asset,
} from '@api/v4/assets/assetTypes';

type GetDataChangesFn = <T>(state: ReducerState, assetType: string) => Partial<AssetData> | false;

const getAssetData = <T extends AssetData>(asset: Asset | null): T | null => {
  if (asset) {
    return asset.data as T;
  }

  return null;
};

const getChanges: GetDataChangesFn = <AssetTypeData>(state, assetType) => {
  const initialData = getAssetData<AssetTypeData>(state.initialData.asset);

  if (!isDirty<AssetTypeData>(initialData, state.editState[`${assetType}Data`])) {
    return false;
  }

  const editedData = state.editState[`${assetType}Data`];

  if (!initialData) {
    // for CREATING assets and tasks, return edited data without comparing to initialData since all data is new
    return editedData;
  }

  const changes: Partial<AssetTypeData> = {};
  // compare the value of each data key between initial and edited state
  Object.keys(editedData).forEach((dataKey) => {
    if (editedData[dataKey] !== initialData[dataKey]) {
      changes[dataKey.toString()] = editedData[dataKey];
    }
  });

  return changes;
};

export const getDataChanges = (
  state: ReducerState
): Partial<AssetData> | false => {
  if (state.editState.colorData) return getChanges<ColorData>(state, 'color');
  if (state.editState.externalMediumData) return getChanges<ExternalMediumData>(state, 'externalMedium');
  if (state.editState.fontData) return getChanges<FontData>(state, 'font');
  if (state.editState.personData) return getChanges<PersonData>(state, 'person');
  if (state.editState.pressData) return getChanges<PressData>(state, 'press');
  if (state.editState.genericFileData) return getChanges<GenericFileData>(state, 'genericFile');

  return false;
};
