import React, { FunctionComponent } from 'react';

import { ActionsSummaryEntry } from '@components/bulk_management/automation/AutomationTypes';
import { summarizeManageAction } from './summary-helpers';

interface ActionSummaryTdProps {
  actionsSummary: ActionsSummaryEntry[];
  id: string;
}

export const ActionSummaryTd: FunctionComponent<ActionSummaryTdProps> = ({ actionsSummary, id }) => (
  <ul className="home-view__action-summary">
    {actionsSummary.map((as) => {
      const { actionable_type: actionableType, count } = as;
      return (
        <li key={`${id}-${actionableType}-${count}`}>{summarizeManageAction(as)}</li>
      );
    })}
  </ul>
);
