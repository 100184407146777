import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import BaseButton from '../BaseButton';
import styleClassNames from './styles.module.scss';

type CloseButtonsProps = ButtonHTMLAttributes<HTMLButtonElement>;

const CloseButton = forwardRef<HTMLButtonElement, CloseButtonsProps>((props, ref) => {
  const baseProps = {
    ...props,
    icon: 'bff-close',
    styleClassNames,
    variantName: 'close',
  };

  return <BaseButton {...baseProps} ref={ref} />;
});

export default CloseButton;
