import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { manageDropdownListener } from '@helpers/use_effect_callbacks';

import './styles/main.scss';

interface DropdownWrapperProps {
  children: JSX.Element;
  dropdownContents: JSX.Element;
  close?: boolean;
  closeCallback?: () => void;
  cssTransitionClasses?: string;
  openOnClick?: boolean;
  openOnHover?: boolean;
  wrapperClassnames?: string;
}

const DropdownWrapper = ({
  children,
  dropdownContents,
  close = false,
  closeCallback = undefined,
  cssTransitionClasses = '',
  openOnClick = true,
  openOnHover = true,
  wrapperClassnames = '',
}: DropdownWrapperProps): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const { device } = BFG.currentUser;
  const isTouchScreen = device === 'mobile-view' || device === 'tablet-view';

  /**
   * https://stackoverflow.com/a/34622109/10944678
   * @param e MouseEvent | KeyboardEvent
   * @returns boolean
   */
  const getTabbedInToDropdown = (e: MouseEvent | KeyboardEvent): boolean => {
    if (e.type === 'keyup' && (e as KeyboardEvent).key === 'Tab') {
      const target = e.target as HTMLDivElement;
      if (menuRef.current && target !== menuRef.current && menuRef.current.contains(target)) {
        return true;
      }
    }
    return false;
  };

  const handleOpenAndClose = (e: any): void => {
    switch (e.type) {
      case 'keypress':
        if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') setShowDropdown((prevState) => !prevState);
        break;
      case 'click':
        if (openOnClick || isTouchScreen) setShowDropdown((prevState) => !prevState);
        break;
      case 'mouseenter':
      case 'mouseleave':
        if (openOnHover && !isTouchScreen) setShowDropdown(e.type === 'mouseenter');
        break;
      default:
    }
  };

  const dropdownEventListenerCallback = (e: MouseEvent | KeyboardEvent): void => {
    e.stopPropagation();

    if (!getTabbedInToDropdown(e)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => setShowDropdown(false), []); // close dropdown when unmounting

  useEffect(() => (
    manageDropdownListener(menuRef, showDropdown, dropdownEventListenerCallback)
  ), [menuRef, showDropdown, dropdownEventListenerCallback]);

  useEffect(() => {
    if (close) {
      setShowDropdown(false); // allows consumer/parent component to close dropdown
      if (closeCallback) {
        closeCallback();
      }
    }
  }, [close]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={menuRef}
      className={`bf-dropdown-wrapper ${wrapperClassnames} ${showDropdown === true ? 'open' : ''}`}
      onMouseEnter={handleOpenAndClose}
      onMouseLeave={handleOpenAndClose}
    >
      <div
        className="selected-option-click-container"
        onClick={handleOpenAndClose}
        onKeyPress={handleOpenAndClose}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
      <CSSTransition
        classNames={cssTransitionClasses}
        in={showDropdown}
        timeout={200}
        unmountOnExit
      >
        {dropdownContents}
      </CSSTransition>
    </div>
  );
};

export default DropdownWrapper;
