import { t, Trans } from '@lingui/macro';
import { v4 as uuidv4 } from 'uuid';

import { UseFetchOptions } from '@api/ApiHelper';
import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { formatLabelsTree } from '@api/v4/resources/labels';
import { Fields, NameDisplayTypes, SelectorTypes } from '@components/bulk_management/automation/AutomationEnums';
import {
  Action,
  ActionEntry,
  ActionServer,
  AutomationBody,
  AutomationTooltips,
  CollectionsServer,
  EmptyAction,
  FieldDetails,
  FormState,
  KeyLabelPair,
  LabelsServer,
  TriggerServer,
} from '@components/bulk_management/automation/AutomationTypes';
import { ListOption, ListOptionValueNoNull } from '@components/library/dropdown';

export const selectorTypesLabelMap: Record<SelectorTypes, string> = {
  [SelectorTypes.Collection]: t`Collection`,
  [SelectorTypes.CustomField]: t`Custom Field`,
  [SelectorTypes.Label]: t`Label`,
  // [SelectorTypes.NameContains]: bfTranslate('Name Contains'),
  [SelectorTypes.Section]: t`Section`,
  [SelectorTypes.Tag]: t`Tag`,
  [SelectorTypes.Watermark]: t`Watermark`
};

export const selectorTypeOptions = (
  actionableId: string,
  triggerType: SelectorTypes,
  existingActions: Action[]
): ListOption[] => {
  // action options
  if (actionableId) {
    const optionCollection = { label: t`Add to Collection`, value: SelectorTypes.Collection };
    const optionCustomField = BFG.hasFeature('custom_fields')
      ? [{ label: t`Add Custom Field`, value: SelectorTypes.CustomField }]
      : [];
    const optionLabel = { label: t`Add to Label`, value: SelectorTypes.Label };
    const optionSection = { label: t`Add to Section`, value: SelectorTypes.Section };
    const optionTag = { label: t`Add to Tag`, value: SelectorTypes.Tag };
    const optionWatermark = BFG.hasFeature('watermarking')
      ? [{ label: t`Add Watermark`, value: SelectorTypes.Watermark }]
      : [];

    let fullOptions: ListOption[];
    // return allowed actions based on the selected trigger
    if (triggerType === SelectorTypes.CustomField) {
      fullOptions = [
        optionCollection,
        optionSection,
        optionLabel,
        ...optionCustomField
      ];
    } else if (triggerType === SelectorTypes.Label) {
      fullOptions = [
        optionCollection,
        ...optionCustomField
      ];
    } else if (triggerType === SelectorTypes.Section) {
      fullOptions = [
        optionCollection,
        // ...optionCustomField
      ];
    } else if (triggerType === SelectorTypes.Tag) {
      fullOptions = [
        // optionTag,
        optionCollection,
        optionSection,
        optionLabel,
        ...optionWatermark,
        ...optionCustomField
      ];
    }
    // else if (triggerType === SelectorTypes.NameContains) {
    //   fullOptions = [optionCollection, optionSection, optionLabel, ...optionCustomField];
    // }

    if (existingActions && fullOptions) {
      // only return those options that have not been selected
      // in OTHER existing actions - include the option that has
      // been selected for the action specified by actionableId
      // so the dropdown knows what information to display
      return fullOptions.filter((option) => (
        !existingActions.find((action) => (
          action.actionableType === option.value && action.actionableId !== actionableId
        ))
      ));
    }

    return fullOptions;
  }

  // trigger options
  return [
    ...BFG.hasFeature('custom_fields') ? [{ label: t`Custom Field is added`, value: SelectorTypes.CustomField }] : [],
    { label: t`Label is added`, value: SelectorTypes.Label },
    { label: t`Assets added to a section`, value: SelectorTypes.Section },
    { label: t`Tag is added`, value: SelectorTypes.Tag },
    // { label: bfTranslate('Name Contains'), value: SelectorTypes.NameContains },
  ];
};

export const automationFilterOptions = (): ListOption[] => {
  const options: ListOption[] = [];
  options.push({ label: t`All`, value: 'all' });
  Object.entries(selectorTypesLabelMap).forEach(([selectorType, label]) => {
    if (selectorType !== SelectorTypes.Watermark
      || (selectorType === SelectorTypes.Watermark && BFG.hasFeature('watermarking'))) {
      options.push({ label, value: selectorType });
    }
  });
  return options;
};

export const automationSortOptions = (): ListOption[] => [{
  label: t`Name`,
  value: 'name|asc'
}, {
  label: t`Creation date (newest)`,
  value: 'created_at|desc'
}, {
  label: t`Creation date (oldest)`,
  value: 'created_at|asc'
}, {
  label: t`Updated (newest)`,
  value: 'updated_at|desc'
}, {
  label: t`Updated (oldest)`,
  value: 'updated_at|asc'
}];

export const emptyAction = (): EmptyAction => ({
  actionableId: `temp-id-${uuidv4()}`,
  actionableKeys: null,
  actionableOptions: null,
  actionableType: null,
  actionableValues: null
});

export const initialFormState = (emptyActionArg: EmptyAction): FormState => ({
  actions: [{ ...emptyActionArg }],
  name: '',
  triggerKeys: null,
  triggerType: null,
  values: null
});

export const resetActionsAndName = (currFormState: FormState, emptyActionArg: EmptyAction): FormState => ({
  ...currFormState,
  actions: [{ ...emptyActionArg }],
  name: ''
});

const getActionEntries = (actions: Action[]): ActionEntry[] => {
  if (!actions || actions.length === 0) {
    return undefined;
  }

  return actions.map((action) => ({
    actionable_type: action.actionableType || null,
    actionable_keys: action.actionableKeys?.map(({ key }) => key) || [],
    actionable_values: action.actionableValues || null,
    options: action.actionableOptions || null
  }));
};

export const getAutomationBody = (formValues: FormState | null): AutomationBody | undefined => {
  if (!formValues) {
    return undefined;
  }

  const { name, triggerType, triggerKeys, values, actions } = formValues;
  if (triggerType === SelectorTypes.CustomField) {
    const createBody: AutomationBody<SelectorTypes.CustomField> = {
      name,
      trigger: {
        trigger_type: SelectorTypes.CustomField,
        scope: {
          custom_field_keys: triggerKeys.map(({ key }) => key),
          values: triggerKeys.flatMap(({ key }) => values[key])
        }
      },
      actions: getActionEntries(actions)
    };

    return createBody;
  }

  if (triggerType === SelectorTypes.Label) {
    const createBody: AutomationBody<SelectorTypes.Label> = {
      name,
      trigger: {
        trigger_type: SelectorTypes.Label,
        scope: {
          label_keys: triggerKeys.map(({ key }) => key)
        }
      },
      actions: getActionEntries(actions)
    };

    return createBody;
  }

  if (triggerType === SelectorTypes.Section) {
    const createBody: AutomationBody<SelectorTypes.Section> = {
      name,
      trigger: {
        trigger_type: SelectorTypes.Section,
        scope: {
          section_keys: triggerKeys.map(({ key }) => key)
        }
      },
      actions: getActionEntries(actions)
    };

    return createBody;
  }

  if (triggerType === SelectorTypes.Tag) {
    const createBody: AutomationBody<SelectorTypes.Tag> = {
      name,
      trigger: {
        trigger_type: SelectorTypes.Tag,
        scope: {
          tag_names: triggerKeys.map(({ key }) => key)
        }
      },
      actions: getActionEntries(actions)
    };

    return createBody;
  }

  return undefined;
};

export const transformIncludedActions = (includedActions: ApiResponseObject<ActionServer, 'action'>[]): Action[] => (
  includedActions.map((action) => {
    const actionableKeys = [];
    const actionableValues = action.attributes.actionable_type === SelectorTypes.CustomField ? {} : undefined;
    action.attributes.actionable_values.forEach((actionable) => {
      actionableKeys.push({ key: actionable.key || actionable.name, label: actionable.name });
      if (actionable.values) {
        actionableValues[actionable.key] = [...actionable.values];
      }
    });

    const actionableOptions = action.attributes.actionable_type === SelectorTypes.Watermark
      ? action.attributes.options
      : undefined;

    return {
      actionableId: action.id,
      actionableKeys,
      actionableType: action.attributes.actionable_type,
      actionableValues,
      actionableOptions
    };
  })
);

export const transformTriggers = (
  triggers: TriggerServer[]
): Pick<FormState, 'triggerKeys' | 'triggerType' | 'values'> => {
  if (triggers[0]?.type === SelectorTypes.CustomField) {
    // we know that there can only be one custom field key and thus only one trigger
    const trigger = triggers[0];
    return {
      triggerKeys: [{ key: trigger.key, label: trigger.name }],
      triggerType: trigger.type,
      values: { [trigger.key]: [...trigger.values] }
    };
  }

  return {
    // NOTE: OR trigger.name here is for populating keys in browser when they're null from the API for tags
    triggerKeys: triggers.map((trigger) => ({ key: trigger.key || trigger.name, label: trigger.name })),
    triggerType: triggers[0].type,
  };
};

export const getFieldDetails = (selectorValues: Partial<FormState> & Partial<Action>): FieldDetails => {
  const {
    actionableId,
    actionableKeys,
    actionableOptions,
    actionableType,
    actionableValues,
    triggerKeys,
    triggerType,
    values: triggerValues
  } = selectorValues;

  const fieldType = actionableId ? actionableType || null : triggerType || null;
  const fieldTypeString = actionableId ? Fields.ActionableType : Fields.TriggerType;
  const fieldKeys = actionableId ? actionableKeys : triggerKeys;
  const fieldKeysString = actionableId ? Fields.ActionableKeys : Fields.TriggerKeys;
  const options = actionableId ? actionableOptions : null;
  const values = actionableId ? actionableValues : triggerValues;
  const valuesString = actionableId ? Fields.ActionableValues : Fields.Values;

  return {
    fieldKeys,
    fieldKeysString,
    fieldType,
    fieldTypeString,
    options,
    values,
    valuesString
  };
};

export const getAppropriateCustomFieldSelector = (
  isTrigger: boolean,
  isRestricted: boolean,
  isMultiValuesAllowed: boolean
): NameDisplayTypes => {
  if (isRestricted && (isMultiValuesAllowed || isTrigger)) {
    return NameDisplayTypes.MultiselectDropdown;
  }

  if (isRestricted && !isMultiValuesAllowed) {
    return NameDisplayTypes.ListDropdown;
  }

  if (!isRestricted && (isMultiValuesAllowed || isTrigger)) {
    return NameDisplayTypes.PillSelector;
  }

  return NameDisplayTypes.Textfield;
};

export const fetchCollectionsOptions: UseFetchOptions = {
  fetchOnMount: false,
  params: {
    fields: 'parent_id',
    order: 'asc',
    sort_by: 'name', // eslint-disable-line @typescript-eslint/naming-convention
    per: 3000,
  },
  url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/collections`
};

export const fetchCustomFieldKeysOptions: UseFetchOptions = {
  fetchOnMount: false,
  fields: 'multi_value_enabled',
  params: {
    order: 'asc',
    sort_by: 'name', // eslint-disable-line @typescript-eslint/naming-convention
  },
  url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/custom_field_keys`,
};

export const fetchCustomFieldValuesOptions = (customFieldKeyId: string | undefined): UseFetchOptions => ({
  fetchOnMount: false,
  params: {
    order: 'asc',
    sort_by: 'name', // eslint-disable-line @typescript-eslint/naming-convention
    unique_values: 'true', // eslint-disable-line @typescript-eslint/naming-convention
  },
  url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/custom_field_keys/${customFieldKeyId}/custom_field_values`,
});

export const fetchSectionsOptions: UseFetchOptions = {
  fetchOnMount: false,
  params: {
    order: 'asc',
    sort_by: 'name', // eslint-disable-line @typescript-eslint/naming-convention
    per: 1000,
  },
  url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/sections`
};

export const fetchLabelsOptions: UseFetchOptions = {
  fetchOnMount: false,
  params: {
    order: 'asc',
    sort_by: 'name', // eslint-disable-line @typescript-eslint/naming-convention
    per: 3000,
  },
  url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/labels`
};

export const fetchTagsOptions: UseFetchOptions = {
  fetchOnMount: false,
  params: {
    names: true,
    order: 'asc',
    sort_by: 'name', // eslint-disable-line @typescript-eslint/naming-convention
    per: 1000,
  },
  url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/tags`
};

export const makeOptions = <T extends { name: string }, U>(
  data: Array<ApiResponseObject<T, U>> | undefined
): ListOption[] => (data?.map((item) => ({ label: item.attributes.name, value: item.id })));

/**
 * Creates ListOption array from labels data with "indentation" attribute.
 * Sorts by position and depth (nesting)
 * @param data Array<ApiResponseObject<LabelsServer, 'labels'>> | undefined
 * @returns ListOption[]
 */
export const makeLabelsOptions = (
  data: ApiResponseObject<LabelsServer, 'labels'>[] | undefined
): ListOption[] => {
  if (!data || data?.length == 0) return [];

  const labelsTree = formatLabelsTree(data);
  const sortedLabels = [];

  const sortLabels = (labelObj): ListOption[] => {
    sortedLabels.push({
      ...labelObj.attributes.depth > 1 && { indentation: labelObj.attributes.depth - 1 },
      label: labelObj.attributes.name,
      value: labelObj.id
    });

    if (labelObj.children.length == 0) {
      return;
    }

    return labelObj.children.map((nestedLabelObj) => sortLabels(nestedLabelObj));
  };

  labelsTree.children.map((labelObj) => sortLabels(labelObj));

  return sortedLabels;
};

/**
 * Creates ListOption array from a list of collections.
 * Takes any Subcollections and indents them under their parent.
 * @param data Array<ApiResponseObject<CollectionsServer, 'collections'>> | undefined
 * @returns ListOption[]
 */
export const makeCollectionOptions = (
  data: ApiResponseObject<CollectionsServer, 'collections'>[] | undefined,
  fieldKeys: KeyLabelPair[] | null
): ListOption[] => {
  if (!data) return [];

  const collections = data
    .filter((collection) => collection.attributes.parent_id === null)
    .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));

  const subcollections = data
    .filter((collection) => collection.attributes.parent_id !== null)
    .sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));

  let listOptions: ListOption[] = collections.map((collection) => ({
    label: collection.attributes.name,
    value: collection.id
  }));

  if (subcollections.length > 0) {
    subcollections.forEach((subcollection) => {
      const parentCollectionFieldKey = fieldKeys ? fieldKeys.find((fieldKey) => fieldKey.key === subcollection.attributes.parent_id) : undefined;
      const subcollectionFieldKey = fieldKeys ? fieldKeys.find((fieldKey) => fieldKey.key === subcollection.id) : undefined;

      const parentCollectionIndex = listOptions.findIndex((listOption) => listOption.value === subcollection.attributes.parent_id);

      if (parentCollectionFieldKey && subcollectionFieldKey) {
        const parentCollection = listOptions.find((listOption) => listOption.value === parentCollectionFieldKey.key);

        listOptions = [
          ...listOptions.slice(0, parentCollectionIndex),
          {
            ...parentCollection,
            isDisabled: true
          },
          {
            indentation: 1,
            label: subcollection.attributes.name,
            value: subcollection.id
          },
          ...listOptions.slice(parentCollectionIndex + 1)
        ];
      } else {
        listOptions = [
          ...listOptions.slice(0, parentCollectionIndex + 1),
          {
            indentation: 1,
            label: subcollection.attributes.name,
            value: subcollection.id
          },
          ...listOptions.slice(parentCollectionIndex + 1)
        ];
      }
    });
  }

  return listOptions;
};

/**
 * Generates an array of KeyLabelPair for Collections action.
 * Handles adding the parent Collection if a child Subcollection is selected.
 * @param listOptionValues ListOptionValueNoNull[]
 * @param collections ApiResponseObject<CollectionsServer, 'collections'>[]
 * @param collectionOptions ListOption[]
 * @param fieldKeys KeyLabelPair[] | null
 * @returns KeyLabelPair[]
 */
export const generateCollectionKeys = (
  listOptionValues: ListOptionValueNoNull[],
  collections: ApiResponseObject<CollectionsServer, 'collections'>[],
  collectionOptions: ListOption[],
  fieldKeys: KeyLabelPair[] | null
): KeyLabelPair[] => {
  const keys: KeyLabelPair[] = [];

  if (listOptionValues.length > 0) {
    listOptionValues.forEach((listOptionValue) => {
      const collection = collections.find((c) => c.id === listOptionValue);
      const collectionOption = collectionOptions.find((c) => c.value === listOptionValue);

      if (collection.attributes.parent_id) {
        const parentCollectionFieldKey = fieldKeys ? fieldKeys.find((fieldKey) => fieldKey.key === collection.attributes.parent_id) : undefined;
        const parentCollectionOption = collectionOptions.find((c) => c.value === collection.attributes.parent_id);

        // the parent key must also be added to fieldKeys if not already added
        if (!parentCollectionFieldKey) {
          keys.push({
            key: parentCollectionOption.value.toString(),
            label: parentCollectionOption.label.toString()
          });
        }
      }

      keys.push({
        key: collectionOption.value.toString(),
        label: collectionOption.label.toString()
      });
    });
  }

  return keys;
};

export const automationTooltips = (): Record<AutomationTooltips, string> => ({
  action: t`An action happens after the trigger occurs as assets are added or updated. You can specify multiple actions to happen following a trigger.`,
  name: t`This can be a summary or description of the purpose of the asset automation.`,
  run: t`All asset automations run automatically as soon as an asset is created or updated. Manually running an asset automation will retroactively apply it to all assets that met the trigger conditions before the asset automation was created.`,
  trigger: t`A trigger is an event that initiates one or more subsequent actions.`
});
