/* eslint-disable max-len */
import { t } from '@lingui/macro';

type SubscriptionFeatures = {
  description: string,
  id: string,
  name: string
};

export const subscriptionFeatures = (): SubscriptionFeatures[] => [
  {
    description: t`Sharing and tracking of distributed assets`,
    id: 'share-links',
    name: t`Share Links`,

  },
  {
    description: t`Extract and view file metadata`,
    id: 'metadata',
    name: t`Metadata`,

  },
  {
    description: t`Transform, transcode, resize, and preview videos`,
    id: 'advanced-video',
    name: t`Advanced Video`,

  },
  {
    description: t`Get embed codes for your assets and power web and mobile experiences`,
    id: 'embed-assets',
    name: t`Embed Assets/CDN`,

  },
  {
    description: t`Track custom fields and values, such as product data`,
    id: 'custom-fields',
    name: t`Custom Fields`,

  },
  {
    description: t`View asset usage data such as views, downloads, search terms, and shares`,
    id: 'insights',
    name: t`Insights`,

  },
  {
    description: t`Allow assets to be approved before being published`,
    id: 'approvals',
    name: t`Approvals`,

  },
  {
    description: t`Allow assets to be approved before being published`,
    id: 'advanced-approvals',
    name: t`Advanced Approvals`,

  },
  {
    description: t`Set publish and expiration dates for assets`,
    id: 'availability',
    name: t`Availability`,

  },
  {
    description: t`Create asset request links for requesting files from guest users`,
    id: 'guest-upload',
    name: t`Guest Upload`,

  },
  {
    description: t`In-document search and text extraction (PDF and Office)`,
    id: 'document-search',
    name: t`Document Search`,

  },
  {
    description: t`Large centered watermark applied in the asset modal over attachment`,
    id: 'legacy-watermarking',
    name: t`Legacy Watermarking`,

  },
  {
    description: t`Rule-based or asset modal applied small watermark in a corner of the asset (png, jpg, and jpeg only)`,
    id: 'watermarking',
    name: t`Watermarking`,

  },
  {
    description: t`Create and customize online brand guides`,
    id: 'brandguide',
    name: t`Brandguide`,

  },
  {
    description: t`Identity management integration with SAML/SSO providers and team mappings`,
    id: 'saml-sso',
    name: t`SAML/SSO`,

  },
  {
    description: t`Import designed templates and allow users to edit content`,
    id: 'templates',
    name: t`Templates`,

  },
  {
    description: t`Don\'t see a feature you\'re looking for? Talk to your representative for more information`,
    id: 'other',
    name: t`Other`,
  }
];
