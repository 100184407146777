import { Plural, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { fetchJson } from '@api/ApiHelper';
import { InsightsEventActions, InsightsEventTypes } from '@api/insights/v1/events';

import classes from './styles/attachment-group.module.scss';

interface AttachmentGroupProps {
  assetKeys: string[];
  attachmentGroupAttachmentCount: number;
  attachmentGroupDownloadUrl: string | null;
  shareManifestKey: string;
}

export const AttachmentGroup: FunctionComponent<AttachmentGroupProps> = (props) => {
  const {
    assetKeys,
    attachmentGroupAttachmentCount,
    attachmentGroupDownloadUrl,
    shareManifestKey
  } = props;

  const postAssetDownloadEvent = (assetKey: string): Promise<null> => (
    fetchJson({
      body: {
        data: {
          attributes: {
            data: {
              action_name: InsightsEventActions.DOWNLOADED,
              resource_key: assetKey,
              resource_type: InsightsEventTypes.ASSET,
              source: {
                resource_key: BFG.resource.key,
                resource_type: BFG.resource.type,
                share_manifest_key: shareManifestKey
              }
            }
          }
        }
      },
      method: 'POST',
      url: `${BFG.INSIGHTS_API_URL}/api/v1/events`
    })
  );

  return (
    <section className={classes.attachmentGroup}>
      <p id="share-link-attachment-group-text">
        <Plural
          one="# attachment has been prepared for you in a ZIP file."
          other="# attachments have been prepared for you in a ZIP file."
          value={attachmentGroupAttachmentCount}
        />
      </p>
      <a
        className={classes.link}
        download
        href={attachmentGroupDownloadUrl}
        id="share-link-attachment-group-zip-download"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={(): Promise<null[]> => Promise.all(assetKeys.map((assetKey) => postAssetDownloadEvent(assetKey)))}
      >
        <Trans>Download ZIP</Trans>
      </a>
    </section>
  );
};
