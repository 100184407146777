import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, MutableRefObject } from 'react';

import { CustomFieldValue } from '@api/v4/assets/customFieldTypes';
import { CustomFieldKeyValuesEntry } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { MultiselectDropdown, ListOption } from '@components/library/dropdown/index';

interface MultiValueRestrictedProps {
  currValues: ListOption[];
  existingCustomFieldMap: CustomFieldKeyValuesEntry;
  handleCreate: (customFieldValue: CustomFieldValue) => void;
  handleDelete: (customFieldValue: CustomFieldValue, customFieldRequired?: boolean) => void;
  options: ListOption[];
  required: boolean;
  overflowParentRef?: MutableRefObject<HTMLElement>;
}

export const MultiValueRestricted: FunctionComponent<MultiValueRestrictedProps> = ({
  currValues,
  existingCustomFieldMap,
  handleCreate,
  handleDelete,
  options,
  overflowParentRef,
  required
}) => {
  const hasValue = currValues && currValues.length > 0;
  const touched = existingCustomFieldMap?.customFieldTouched;
  const showRequired = required && touched && !hasValue;

  return (
    <MultiselectDropdown
      error={showRequired && <Trans>Value required</Trans>}
      onChange={(_selectedOptions, isAdded, updatedValue): void => {
        const customFieldValueId = existingCustomFieldMap?.customFieldValues.find((cfv) => (cfv.value === updatedValue))?.key;
        const customFieldValue = { key: customFieldValueId, value: updatedValue.toString() };
        if (isAdded) {
          handleCreate(customFieldValue);
        } else {
          handleDelete(customFieldValue, required);
        }
      }}
      options={options}
      overflowParentRef={overflowParentRef}
      placeholder={t`Select values`}
      searchable
      values={currValues}
    />
  );
};
