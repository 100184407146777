import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect } from 'react';

import { useFetch } from '@api/ApiHelper';
import { Asset, AssetResponse } from '@api/v4/assets/assetTypes';
import { DesignHuddleProjectGetResponse } from '@api/v4/private/resources/design_huddles';
import { TertiaryButton } from '@components/library/button';

import './styles/export.scss';

interface UpdateThumbnailProps {
  assetKey: string;
  projectId: string;
}

export const UpdateThumbnail: FunctionComponent<UpdateThumbnailProps> = ({ assetKey, projectId }) => {
  const getProject = useFetch<DesignHuddleProjectGetResponse>({
    fetchOnMount: false,
    url: `/api/v4/private/${BFG.resource.type}s/${BFG.resource.key}/design_huddles/projects/${projectId}`
  });

  const getFilenameFromUrl = (url: string | undefined): string => {
    return url ? new URL(url).pathname.split('/').pop() : '';
  };

  const attributes: Partial<Asset> = {
    thumbnail_override: { // eslint-disable-line @typescript-eslint/naming-convention
      filename: getFilenameFromUrl(getProject?.response?.data?.thumbnail_url),
      url: getProject?.response?.data?.thumbnail_url || ''
    }
  };

  const putAsset = useFetch<AssetResponse>({
    body: {
      data: {
        attributes
      }
    },
    fetchOnMount: false,
    method: 'PUT',
    url: `/api/v4/assets/${assetKey}`
  });

  const showError = (): void => {
    Notify.create({
      title: t`There was an error updating the thumbnail. Please try again.`,
      type: 'error'
    });
  };

  useEffect(() => {
    if (getProject.response) {
      putAsset.fetch();
    }
  }, [getProject.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (getProject.error) {
      showError();
    }
  }, [getProject.error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (putAsset.response) {
      Notify.create({
        title: t`Successfully updated thumbnail!`,
        type: 'success'
      });
    }
  }, [putAsset.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (putAsset.error) {
      showError();
    }
  }, [putAsset.error]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = getProject.loading || putAsset.loading;

  return (
    <span className="project__button-wrapper">
      <TertiaryButton
        className="project__button"
        id="project-update-thumbnail"
        isLoading={isLoading}
        onClick={(): void => {
          getProject.fetch();
        }}
      >
        <Trans>Update Thumbnail</Trans>
      </TertiaryButton>
    </span>
  );
};
