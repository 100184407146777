export const acceptedFileExtensions = [
  'psd',
  'rw2',
  'dng',
  'webp',
  'nef',
  'arw',
  'cr2',
  'cr3',
  'jpeg',
  'jpg',
  'svg',
  'png',
  'tiff',
  'tif',
  'gif',
  'heic',
];
