import { Plural, plural, t, Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { CSSTransition } from 'react-transition-group';

import { updateAvailability } from '@api/v4/bulk_actions/assets';
import DateTimePicker from '@components/library/date_time_picker/BaseDateTimePicker';
import { StandardSwitch, SwitchSize } from '@components/library/switch';
import { bulkRefreshSections, pluralizeObject } from '@helpers/show_page_helpers';

import renderModal from '../modals/renderModal';

const defaultExpiration = moment().add(7, 'days').set({ hour: 23, minute: 59, second: 0 }).format('YYYY-MM-DD HH:mm');
const titletext = () => t`An asset has external links.`;
const textintro = () => t`At least one of your selected assets has external links. `;
const textbody = () => {
  const textbodystring = t`This will cause broken image links on sites that depend on the availability of these assets. Check the usage tab to see active external links before changing availability.`;
  return `${textbodystring}\n\n`
};

const BulkAvailabilityModal = ({
  activeCdnTracers,
  closeModal,
  selectedAssetKeys,
}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [availabilityActive, setAvailabilityActive] = useState(false);
  const [availability, setAvailability] = useState(null);
  const [expiresActive, setExpiresActive] = useState(false);
  const [expires, setExpires] = useState(null);
  const [isAvailabilityDateValid, setIsAvailabilityDateValid] = useState(true);
  const [isExpirationDateValid, setIsExpirationDateValid] = useState(true);

  const assetQtyObject = pluralizeObject(selectedAssetKeys);
  const text = `${textintro()} ${textbody()}`;
  const sweetAlertOptions = {
    title: titletext(),
    text,
    type: "warning",
    showCancelButton: true,
    cancelButtonText: t`Cancel`,
    confirmButtonText: t`Update`,
    closeOnConfirm: true,
  };

  useEffect(() => (() => closeModal()), []);

  useEffect(() => {
    const isInvalidDate = !isAvailabilityDateValid || !isExpirationDateValid;
    const isAvailabilityAfterExpiration = availability && expires && moment(availability).isAfter(expires);

    if (isInvalidDate) {
      setSubmitDisabled(true);
    } else if (isAvailabilityAfterExpiration) {
      setSubmitDisabled(true);
      Notify.create({
        title: t`The availability date must be before the expiration date. Please try again.`,
        type: 'error'
      });
    } else {
      setSubmitDisabled(false);
    }
  }, [availability, expires, isAvailabilityDateValid, isExpirationDateValid]);

  const handleAvailabilityDateValidityChange = (isValid) => {
    setIsAvailabilityDateValid(isValid);
  };

  const handleExperationDateValidityChange = (isValid) => {
    setIsExpirationDateValid(isValid);
  };

  const handleAvailability = (timestamp) => {
    setAvailability(timestamp);
  }

  const handleExpires = (timestamp) => {
    setExpires(timestamp);
  }

  const sendAvailabilityUpdate = () => {
    updateAvailability({
      asset_keys: [...selectedAssetKeys], // convert Set to an array via spread operator
      availability_start: availability,
      availability_end: expires,
    })
      .then(({response, json}) => {
        bulkRefreshSections(selectedAssetKeys);
        closeModal();
        if (response.status == 202) {
          Notify.create({
            title: plural(assetQtyObject.qty, {
              one: "Processing availability updates for # asset!",
              other: "Processing availability updates for # assets!",
            }),
            body: t`Updates will be available shortly, but may not yet be visible.`,
            type: 'success'
          });
        } else if (response.status == 200) {
          Notify.create({
            title: plural(assetQtyObject.qty, {
              one: "Availability updated for # asset!",
              other: "Availability updated for # assets!",
            }),
            type: 'success'
          });
        }
      })
      .catch(() => {
        setSubmitDisabled(false);
        Notify.create({
          title: t`An error occurred when updating asset availability. Please try again.`,
          type: 'error'
        });
      });
  };

  const handleUpdate = () => {
    setSubmitDisabled(true);
    if ((availability || expires) && activeCdnTracers) {
      window.swal(sweetAlertOptions, (updateSelected) => {
        if (updateSelected) {
          sendAvailabilityUpdate();
        } else {
          setSubmitDisabled(false);
        }
      });
    } else {
      sendAvailabilityUpdate();
    }
  };

  useEffect(() => {
    if (!availabilityActive) setAvailability(null);
  }, [availabilityActive]);

  useEffect(() => {
    if (!expiresActive) setExpires(null);
  }, [expiresActive]);

  return (
    <div className="modal-content-wrapper bulk-availability-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-clock icon" />
        <h3 className="modal-title">
          <Trans>Schedule availability for</Trans>
          <span className="bold-text">
            <Plural one="# asset" other="# assets" value={selectedAssetKeys.size} />
          </span>
        </h3>
        <button
          className="close-button"
          onClick={closeModal}
          type="button"
        >
          <span className="bff-close" />
        </button>
      </div>
      <div className="modal-content-wrapper__body">
        <div className="toggle-row">
          <div className="toggle-container">
            <h4 className="toggle-title">
              <Trans>Enable Availability Date</Trans>
            </h4>
            <StandardSwitch
              className="t-enable-availability"
              isChecked={availabilityActive}
              onChange={() => {
                if (availabilityActive) {
                  setIsAvailabilityDateValid(true);
                }
                setAvailabilityActive((prevState) => (!prevState)); 
              }}
              size={SwitchSize.Large}
            />
          </div>
          <CSSTransition
            in={availabilityActive}
            timeout={300}
            unmountOnExit
          >
            <div className="date-time-picker-transition">
              <DateTimePicker
                className="availability-picker"
                onChange={handleAvailability}
                onDateValidityChange={handleAvailabilityDateValidityChange}
                allowPastDates={true}
                timestamp={availability}
              />
            </div>
          </CSSTransition>
        </div>
        <div className="toggle-row">
          <div className="toggle-container">
            <h4 className="toggle-title">
              <Trans>Enable Expiration Date</Trans>
            </h4>
            <StandardSwitch
              className="t-enable-expiration"
              isChecked={expiresActive}
              onChange={() => setExpiresActive((prevState) => {
                if (expiresActive) {
                  setIsExpirationDateValid(true);
                }
                if (!prevState) setExpires(defaultExpiration);
                return !prevState;
              })}
              size={SwitchSize.Large}
            />
          </div>
          <CSSTransition
            in={expiresActive}
            timeout={300}
            unmountOnExit
          >
            <div className="date-time-picker-transition">
              <DateTimePicker
                className="expiration-picker"
                onChange={handleExpires}
                onDateValidityChange={handleExperationDateValidityChange}
                timestamp={expires}
                allowPastDates={true}
              />
            </div>
          </CSSTransition>
        </div>
        <div className="button-container share-modal-button-container">
          <button
            className="button primary sm t-confirm-modal"
            disabled={submitDisabled || (!availabilityActive && !expiresActive)}
            onClick={handleUpdate}
            type="button"
          >
            <Trans>Save</Trans>
          </button>
        </div>
      </div>
    </div>
  );
};

BulkAvailabilityModal.propTypes = {
  activeCdnTracers: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
};

const ModalComponent = renderModal(BulkAvailabilityModal, 'BulkAvailabilityModal');
export default ModalComponent;
