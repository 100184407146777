import React, { FunctionComponent, useRef, useState } from 'react';
import classnames from 'classnames';
import dragDropTypes from '@components/common/drop_zone/drag_drop_types';
import { useDrag, useDrop } from 'react-dnd';
import { updatePortalCard } from '@api/v4/private/portal_cards';
import { PortalCard, PortalCardSubTypes, PortalCardTypes } from '@api/v4/private/PortalCardTypes';
import { TertiaryIconButton } from '@components/library/button/index';
import { EmbeddedWidgetsCard } from "@components/shared/embedded_widgets";
import TileForm from './tile_form';
import { TileNavigationCard } from './tile_navigation_card';

interface TileProps {
  card: PortalCard;
  draggedIndex: number;
  editMode: boolean;
  portalCardId: string;
  triggerCardsRefresh: () => void;
}

interface HoveredItem {
  id: string;
  insertedCard: PortalCard;
  insertedCardId: string;
  startingIndex: number;
  type: string;
}

const Tile: FunctionComponent<TileProps> = ({ card, draggedIndex, editMode, portalCardId, triggerCardsRefresh }) => {
  const { displayWidth } = card;

  const [showForm, setShowForm] = useState(false);
  const toggleForm = (): void => setShowForm((prevState) => !prevState);
  const previewRef = useRef(null);
  const dragRef = useRef(null);
  const placeholderImage = "https://cdn.brandfolder.io/27C9EC93/at/q6y18n-8wjz1k-fi9mwk/tile-image-placeholder.png";
  const [insertTileIndicator, setInsertTileIndicator] = useState<string | null>(null);

  const updatePosition = (insertedCardId: string, insertedCard: PortalCard, position: number): void => {
    updatePortalCard(insertedCardId, {
      ...insertedCard,
      position,
      type: PortalCardTypes.PortalTile
    }).then(triggerCardsRefresh);
  };

  const [{ isHovered }, drop] = useDrop({
    accept: dragDropTypes.TILE,
    collect: (monitor) => ({
      isHovered: monitor.isOver()
    }),
    drop(item: HoveredItem) {
      const { startingIndex, insertedCardId, insertedCard } = item;
      if (startingIndex !== draggedIndex) {
        updatePosition(insertedCardId, insertedCard, draggedIndex);
      }
    },

    hover: (item, monitor) => {
      if (draggedIndex === item.startingIndex) {
        return;
      }

      const isOver = monitor.isOver();

      const right = draggedIndex > item.startingIndex;
      const left = draggedIndex < item.startingIndex;

      let insertCaretDirection = '';

      if (left) {
        insertCaretDirection = 'left';
      }

      if (right) {
        insertCaretDirection = 'right';
      }

      if (isOver && insertCaretDirection) {
        setInsertTileIndicator(`select-${insertCaretDirection}`);
      } else {
        setInsertTileIndicator(null);
      }
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      startingIndex: draggedIndex,
      type: dragDropTypes.TILE,
      insertedCardId: portalCardId,
      insertedCard: card
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
  });

  if (editMode) {
    drop(preview(previewRef));
    drag(dragRef);
  }

  const renderTile = (): JSX.Element => (
    <div
      ref={previewRef}
      className={classnames(
        "tiles__item",
        `tiles__item--${displayWidth}-width`,
        { [`${insertTileIndicator}`]: isHovered && insertTileIndicator },
        { dragging: isDragging }
      )}
    >
      <button
        ref={dragRef}
        className={`tiles__item--reorder-button card-button ${editMode ? "" : "hidden"}`}
        type="button"
      >
        <span className="bff-reorder" />
      </button>
      {editMode && (
        <TertiaryIconButton
          className="tiles__item--edit-settings-button card-button"
          height={50}
          icon="bff-edit"
          onClick={toggleForm}
          width={50}
        />
      )}
      {/* Navigation tiles are currently saved as null for sub_type in postgres */}
      {(card.subType === PortalCardSubTypes.NavigationTile || card.subType === null) ? (
        <TileNavigationCard card={card} placeholderImage={placeholderImage} />
      ) : (
        <EmbeddedWidgetsCard
          buttonText={card.buttonText}
          description={card.description}
          name={card.name}
          srcUrl={card.srcUrl}
        />
      )}
    </div>
  );

  return (
    <>
      {renderTile()}
      {showForm
        && (
          <TileForm
            closeModal={toggleForm}
            portalCardId={portalCardId}
            triggerCardsRefresh={triggerCardsRefresh}
          />
        )
      }
    </>
  );
};

export default Tile;
