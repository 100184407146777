import Uppy from '@uppy/core';
import Dashboard, { DashboardOptions } from '@uppy/dashboard';
import Dropbox from '@uppy/dropbox';
import Transloadit, { Result } from '@uppy/transloadit';
import { FunctionComponent, useEffect, useMemo } from 'react';

import { sendAction } from '@helpers/datadog-rum';
import { TransloaditTemplateMap } from '@helpers/uppy';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import './styles/uppy_uploader.scss';

interface UppyUploaderProps {
  handleUpload: (files: Result[]) => void;
  uniqIdentifier: string;
  trigger: `#${string}`;
  button?: boolean;
  closeOnComplete?: boolean;
  locale?: {
    strings: {
      dropHereOr: string;
      browse: string;
    }
  };
  restrictions?: {
    maxNumberOfFiles?: number;
    maxFileSize?: number;
    allowedFileTypes?: string[];
  };
  template?: string;
}

export const UppyUploader: FunctionComponent<UppyUploaderProps> = ({
  handleUpload,
  uniqIdentifier,
  button = false,
  closeOnComplete = false,
  locale = {
    strings: {
      dropPaste: 'Drag file(s) here or %{browse}',
      dropHereOr: 'Drag file(s) here or %{browse}',
      browse: 'click to browse'
    }
  },
  restrictions = {},
  template = 'file_ingest',
  trigger = undefined
}) => {
  // useMemo to maintain single instance of Uppy per component lifecycle
  const uppy = useMemo(() => new Uppy({
    allowMultipleUploadBatches: true,
    id: `${uniqIdentifier}-uppy`,
    restrictions: { maxNumberOfFiles: 3000, ...restrictions },
    locale,
  }), [locale, restrictions, uniqIdentifier]);

  const templateName = template.replace(/-/g, '_');
  const dashboardOptions: DashboardOptions = {
    browserBackButtonClose: button,
    closeAfterFinish: button,
    closeModalOnClickOutside: button,
    inline: false,
    proudlyDisplayPoweredByUppy: false,
    showProgressDetails: true,
    trigger,
    width: 10
  };

  const handleError = (file, error): void => {
    sendAction('Error Uploading File to Transloadit', {
      tags: {},
      extra: { file, error }
    });
  };

  useEffect(() => {
    uppy.use(Transloadit, {
      params: {
        auth: { key: BFG.TRANSLOADIT_API_KEY }, // Transloadit API key (not secret)
        // eslint-disable-next-line @typescript-eslint/naming-convention
        template_id: TransloaditTemplateMap()[templateName]
                      || TransloaditTemplateMap().file_ingest
      },
      waitForEncoding: true // ensures request has processed with metadata
    }).on('upload-error', (file, body) => {
      handleError(file, body);
    }).on('transloadit:complete', (assembly) => {
      const files = assembly.results[':original']
        || assembly.results[templateName];
      handleUpload(files);
    }).on('complete', () => {
      if (closeOnComplete) {
        uppy.close();
      } else {
        uppy.reset();
      }
    }).use(Dashboard, dashboardOptions).use(Dropbox, {
      companionUrl: Transloadit.COMPANION,
      companionAllowedHosts: Transloadit.COMPANION_PATTERN,
      target: Dashboard
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dashboard: Dashboard = uppy.getPlugin('Dashboard');
  if (dashboard) {
    dashboard.render({});
  }

  return null;
};
