/* eslint-disable @typescript-eslint/naming-convention */
export enum InsightsEventActions {
  ADDED_ATTACHMENTS = 'added_attachments',
  DELETED = 'deleted',
  DELETED_ATTACHMENT = 'deleted_attachment',
  DOWNLOADED = 'downloaded',
  SEARCHED = 'searched',
  SHARED = 'shared',
  UPDATED = 'updated',
  VIEWED = 'viewed'
}

/**
 * These are Insight `resourceType`, to be paired with `resourceKey`
 */
export enum InsightsEventTypes {
  ASSET = 'asset',
  SHARE_MANIFEST = 'share_manifest'
}

/**
 * These are Insight `sourceType`, to be paired with `sourceKey`
 *
 * See `var SOURCE` inside app/views/layouts/_set_js_vars.html.slim
 */
export enum InsightsEventSourceResources {
  BRANDFOLDER = 'brandfolder',
  COLLECTION = 'collection',
  ORGANIZATION = 'organization',
  SHARE_MANIFEST = 'share_manifest'
}
