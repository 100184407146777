import { TaskPriorities, TaskStatuses } from '@api/v4/tasks';

/* eslint-disable @typescript-eslint/naming-convention */
export enum ResourceTypes {
  Brandfolder,
  Collection
}

export enum AvailabilityFilters {
  Approved = 'approved',
  Draft = 'draft',
  Expired = 'expired',
  PendingApproval = 'pending_approval',
  Unapproved = 'unapproved',
  Unpublished = 'unpublished',
}

export enum OrientationFilters {
  Landscape = 'landscape',
  Panorama = 'panorama',
  Portait = 'portrait',
  Square = 'square'
}

export interface Filters {
  availability?: AvailabilityFilters | null;
  comments?: boolean | null; // I think this is an abandoned field, can delete if that's the case
  created_at?: Date | null;
  custom_fields?: { [key: string]: any };
  filetypes?: string[];
  filterType?: string[];
  label?: string | null; // I think this is an abandoned field, can delete if that's the case
  orientation?: OrientationFilters[];
  remove_custom_field?: any;
  section?: string | null;
  sku?: string;
  tags?: string[];
  task_assigned_to?: string; // user_key(s)
  task_due_date?: string; // yyyy-MM-dd HH:mm:ss||yyyy-MM-dd||yyyy-MM||yyyy||epoch_millis
  task_priority?: TaskPriorities;
  task_status?: TaskStatuses;
  view_only?: boolean;
}

export interface SearchFilter {
  created_at: Date;
  featured: boolean;
  filterable_id: number;
  filterable_type: ResourceTypes;
  id: number;
  key: string;
  label: string;
  position: number;
  query: string;
  updated_at: Date;
}

export interface SearchQueryOptions {
  searchKey?: string;
}

export enum SearchOperatorOptions {
  AND = 'AND',
  OR = 'OR',
  Quoted = 'Quoted',
  Strict = 'Strict'
}

export enum SearchFilterOperator {
  AND = 'AND',
  OR = 'OR'
}

export interface CustomFieldSearchOperators {
  [key: string]: SearchFilterOperator;
  all: SearchFilterOperator;
}

export interface SearchFilterOperators {
  tags: SearchFilterOperator;
  custom_fields: CustomFieldSearchOperators;
}

export enum FilterType {
  Availability = 'availability',
  Comments = 'comments',
  CreatedAt = 'created_at',
  CustomFields = 'custom_fields',
  FileTypes = 'filetypes',
  Orientation = 'orientation',
  RemoveCustomFields = 'remove_custom_field',
  Section = 'section',
  Sku = 'sku',
  Tags = 'tags',
  TaskAssignedTo = 'task_assigned_to',
  TaskDueDate = 'task_due_date',
  TaskPriority = 'task_priority',
  TaskStatus = 'task_status',
  ViewOnly = 'view_only'
}

export type SearchOption = { value: SearchOperatorOptions; label: string };
export type StatusOption = { value: AvailabilityFilters | string, label: string };

/**
 * IMPORTANT: These values don't get sent to Elasticsearch
 */
export enum DueDateFilters {
  All = '',
  Within7Days = 7,
  Within14Days = 14,
  Within30Days = 30,
  Custom = 'custom'
}

export enum DueDateFilterLabels {
  All = 'All',
  Within7Days = 'Within 7 days',
  Within14Days = 'Within 14 days',
  Within30Days = 'Within 30 days',
  Custom = 'Custom'
}
