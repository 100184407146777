import {
  ButtonLooks,
  CircleLoader,
  DndTable,
  DND_HANDLE_ROW_KEY,
  DragAndDropFormats,
  FontIcons,
  IconButton,
  InputLabelPositions,
  ListboxOption,
  LoaderSizes,
  MoreInfoTooltip,
  SearchTextfield,
  StandardButton,
  StandardCombobox,
  StandardSwitch,
  StandardTable,
  StandardTableRow,
  StandardTabs,
  SwitchSize
} from '@brandfolder/react';
import React, { FunctionComponent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import { deleteCustomFieldKey, updateCustomFieldKeys } from '@api/v4/custom-field-keys';
import { AddDependentFieldsView } from '@components/bulk_management/custom_fields/AddDependentFieldsView';
import { CustomFieldKeyCount } from '@components/bulk_management/custom_fields/CustomFieldKeyCount';
import { CustomFieldKeyMode } from '@components/bulk_management/custom_fields/CustomFieldKeyMode';
import { CustomFieldKeyRow } from '@components/bulk_management/custom_fields/CustomFieldKeyRow';
import { CustomFieldsRowDelete } from '@components/bulk_management/custom_fields/CustomFieldsRowDelete';
import { CustomFieldsRowEdit } from '@components/bulk_management/custom_fields/CustomFieldsRowEdit';
import { DependencyViewAccordion } from '@components/bulk_management/custom_fields/DependencyViewAccordion';
import {
  sortedByName,
  sortedByAssetCount,
  sortedByValueCount,
  sortedByPriority,
  sortedByPreferredOrder
} from '@components/bulk_management/custom_fields/helpers';
import {
  BulkCustomFieldsLanguageMenuDropdown
} from '@components/bulk_management/user_generated_translations/BulkManagementLanguageMenuDropdown';
import { findCurrentLanguageInMap, Locale } from '@components/common/language_menu/languagesMap';
import { TextButton } from '@components/library/button';
import { standardError } from '@translations';

import './styles/main.scss';

import { Plural, Trans, t } from '@lingui/macro';

export const tableId = 'manage-custom-fields';

export interface CustomFieldsManagementProps {
  customFieldKeys: CustomFieldKeyRow[];
  loading: boolean;
  setSelectedCustomFieldKey: SetStateDispatch<CustomFieldKeyCount>;
  setUgtLocale: SetStateDispatch<Locale>;
  ugtLocale: Locale;
}

export const CustomFieldsManagement: FunctionComponent<CustomFieldsManagementProps> = ({
  customFieldKeys,
  loading,
  setSelectedCustomFieldKey,
  setUgtLocale,
  ugtLocale,
}) => {
  const keyIdToRowMapRef = useRef(new Map<number, StandardTableRow>()); // for maintaining row instances to reduce re-rendering
  const [visibleKeys, setVisibleKeys] = useState(customFieldKeys);
  const [sortedRows, setSortedRows] = useState<StandardTableRow[]>([]);
  const [queryString, setQueryString] = useState<string>();
  const [sortBy, setSortBy] = useState('preferredOrder');
  const [isTranslateMode, setIsTranslateMode] = useState(false);
  const [showAddDependentFields, setShowAddDependentFields] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    setVisibleKeys(customFieldKeys);
    setIsTranslateMode(BFG.multiLanguageAssetDetailsEnabled && ugtLocale !== BFG.locales?.ugtLocaleDefault);
  }, [customFieldKeys, ugtLocale]);

  const updateSearch = (event: InputChangeEvent): void => {
    setQueryString(event.currentTarget.value);
  };

  const handleSorting = (listOption: ListboxOption | null): void => {
    setSortBy(listOption.value as string);
  };

  const updateVisibleKeys = (key: CustomFieldKeyRow, newKey?: CustomFieldKeyRow): void => {
    let updatedKeys: CustomFieldKeyRow[];

    if (newKey) {
      // Update the key
      const updatedIndex = visibleKeys.findIndex((customFieldKey) => customFieldKey.key === key.key);
      updatedKeys = visibleKeys.map((visibleKey, index) => {
        if (index === updatedIndex) {
          return newKey;
        } else {
          return visibleKey;
        }
      });
    } else {
      // Remove the key
      updatedKeys = visibleKeys.filter((customFieldKey) => customFieldKey.key !== key.key);
    }

    setVisibleKeys(updatedKeys);
  };

  const renderSortingOptions = (): ReactNode => {
    const options: ListboxOption[] = [
      { key: 'name', value: 'name', children: t`Name` },
      { key: 'assetCount', value: 'assetCount', children: t`Asset Count` },
      { key: 'valueCount', value: 'valueCount', children: t`Value Count` },
      { key: 'priority', value: 'priority', children: t`Priority` },
      { key: 'preferredOrder', value: 'preferredOrder', children: t`Preferred Order` },
    ];
    return (
      <div className="sorting-options">
        <SearchTextfield
          className="bf-input searchbar"
          id="custom-fields-search"
          label={t`Search`}
          onChange={updateSearch}
          // this is not a good translation but is better than not translating at all
          placeholder={t`Search ${customFieldKeys.length} custom field keys`}
          showLabel={false}
        />
        <div className="sorting-options-container-right">
          <BulkCustomFieldsLanguageMenuDropdown
            setUgtLocale={setUgtLocale}
            ugtLocale={ugtLocale}
          />
          <div className="sort-options">
            <StandardCombobox
              id="custom-field-key-sorting"
              initialSelectedIndex={options.findIndex((option) => option.value === sortBy)}
              labelPosition={InputLabelPositions.Left}
              labels={{
                iconButtonLabel: t`Show sort by options`,
                label: t`Sort by`,
                listboxLabel: t`Sort by options`
              }}
              onSelection={handleSorting}
              options={options}
              showEmptyOption={false}
            />
          </div>
        </div>
      </div>
    );
  };

  const queriedKeys = (): CustomFieldKeyRow[] => {
    return visibleKeys.filter((key) => key.name.toLowerCase().includes(queryString.toLowerCase()));
  };

  const keysToSort = (): CustomFieldKeyRow[] => {
    return queryString ? queriedKeys() : visibleKeys;
  };

  const sortedKeys = (): CustomFieldKeyRow[] => {
    switch (sortBy) {
      case 'name':
        return sortedByName(keysToSort());
      case 'assetCount':
        return sortedByAssetCount(keysToSort());
      case 'valueCount':
        return sortedByValueCount(keysToSort());
      case 'priority':
        return sortedByPriority(keysToSort());
      case 'preferredOrder':
      default:
        return sortedByPreferredOrder(keysToSort(), visibleKeys);
    }
  };

  const getValueCopy = (values: number): ReactElement => {
    if (values === -1) {
      return <Trans>View values</Trans>;
    }

    if (values === 0) {
      return <>-</>;
    }

    return (
      <Plural
        one="# value"
        other="# values"
        value={values}
      />
    );
  };

  const updateKey = async (key: CustomFieldKeyRow, newKey: CustomFieldKeyRow, isMove?: boolean): Promise<void> => {
    const attributes = { ...newKey };

    // Copy old values now in case of revert because set state changes the original reference
    const oldKey = { ...key, loading: false };

    if (!isMove) {
      // Update visible now to add perceived updated effect
      updateVisibleKeys(key, { ...newKey, loading: true });
    }

    try {
      await updateCustomFieldKeys({ attributes, customFieldKey: key.key, ugtLocale });
      if (!isMove) {
        updateVisibleKeys(key, { ...newKey, mode: CustomFieldKeyMode.SHOW, loading: false });
      }
      if (window.BF_Environment === 'test') {
        Notify.create({ type: 'success', title: 'updated' });
      }
    } catch (error) {
      const bodyText = error || standardError();
      Notify.create({ type: 'error', title: t`Failed to update.`, body: bodyText });

      if (!isMove) {
        // Rollback visibility change
        updateVisibleKeys(key, oldKey);
      }

      throw error;
    }
  };

  const renameKey = async (key: CustomFieldKeyRow, newName: string): Promise<void> => {
    try {
      await updateKey(key, { ...key, name: newName });
    } catch (error) {
      console.error('Failed to rename key:', error);
    }
  };

  const deleteKey = async (key: CustomFieldKeyRow): Promise<void> => {
    updateVisibleKeys(key, { ...key, loading: true });

    try {
      await deleteCustomFieldKey({ customFieldKey: key.key });
      updateVisibleKeys(key);
    } catch (e) {
      updateVisibleKeys(key, { ...key, loading: false });
      Notify.create({ type: 'error', title: t`Failed to delete. Please try again.` });
    }
  };

  const setMode = (key: CustomFieldKeyRow, mode: CustomFieldKeyMode): void => {
    updateVisibleKeys(key, { ...key, mode });
  };

  const togglePrioritization = async (key: CustomFieldKeyRow): Promise<void> => {
    try {
      await updateKey(key, { ...key, prioritized: !key.prioritized });
    } catch (error) {
      console.error('Failed to toggle prioritization:', error);
    }
  };

  const renderKeyCell = (name: string): ReactNode => {
    return (
      <a href={`/${BFG.resource.slug}?q=custom_fields.${encodeURIComponent(name)}.strict:(*)&ugt_locale=${ugtLocale}`}>
        <span className="custom-fields-name">
          {name}
        </span>
      </a>
    );
  };

  const renderDeleteWarning = (customFieldKey: CustomFieldKeyRow): ReactNode => {
    const { translations } = customFieldKey;
    const localeKeys = Object.keys(translations);
    if (localeKeys.length <= 1) return undefined;

    const customFieldKeyNames = localeKeys
      .filter((key) => translations[key].name !== undefined)
      .map((key) => {
        const languageName = findCurrentLanguageInMap(key).language;
        return `${translations[key].name} (${languageName})`;
      });

    return (
      <p className="custom-fields-row__warning">
        <span className="bff-warning" />
        <Trans>This will delete</Trans>
        <span className="custom-fields-row__warning__key-names">
          {` ${customFieldKeyNames.join(', ')}`}
        </span>
      </p>
    );
  };

  const moveRow = (dragIndex: number, hoverIndex: number): void => {
    setSortedRows((prevRows) => {
      const newRows = [...prevRows];
      const theRow = newRows.splice(dragIndex, 1)[0];
      newRows.splice(hoverIndex, 0, theRow);
      return [...newRows];
    });
  };

  const onRowDropped = async (
    event: DragEvent,
    format: DragAndDropFormats | string,
    row: StandardTableRow,
    index: number
  ): Promise<void> => {
    const keyIdToRowMap = keyIdToRowMapRef.current;
    const rowToKeyIdMap = new WeakMap<StandardTableRow, number>(); // used for getting a sorted list of key IDs
    let droppedKey: CustomFieldKeyRow;
    keyIdToRowMap.forEach((keyRow, keyId) => {
      rowToKeyIdMap.set(keyRow, keyId);

      if (keyRow === row) {
        droppedKey = visibleKeys.find((k) => k.id === keyId);
      }
    });
    if (!droppedKey) {
      // Shouldn't happen, but just in case the key from the row can't be looked up
      console.error('key not found after row was dropped after a drag operation');
      Notify.create({ type: 'error', title: standardError() });
      return;
    }

    const rearrangedKeys = sortedRows.map((r) => {
      return visibleKeys.find((k) => k.id === rowToKeyIdMap.get(r));
    });
    const originalPositions = visibleKeys.map((k) => k.position);

    // Update visible peers now to add perceived updated effect
    setVisibleKeys(rearrangedKeys.map((peerKey, peerIndex) => {
      return { ...peerKey, position: peerIndex };
    }));

    try {
      await updateKey(droppedKey, { ...droppedKey, position: index }, true);
    } catch (error) {
      // Rollback visibility change on peers
      setVisibleKeys(visibleKeys.map((peerKey, peerIndex) => {
        return { ...peerKey, position: originalPositions[peerIndex] };
      }));
    }
  };

  const buildRowFor = (key: CustomFieldKeyRow, index: number): StandardTableRow => {
    const {
      name,
      uniq_values: values,
      counted_assets: assets,
      prioritized,
      mode,
      loading: rowLoading,
    } = key;
    const position = (key.position != null ? key.position : index) + 1;

    switch (mode) {
      case CustomFieldKeyMode.EDIT:
        return {
          [DND_HANDLE_ROW_KEY]: null,
          key: null,
          values: null,
          assets: null,
          prioritized: null,
          controls: (
            <CustomFieldsRowEdit
              loading={rowLoading}
              name={name}
              onCancel={(): void => { setMode(key, CustomFieldKeyMode.SHOW); }}
              onSubmit={(newName): void => { renameKey(key, newName); }}
            />
          )
        };
      case CustomFieldKeyMode.DELETE:
        return {
          [DND_HANDLE_ROW_KEY]: null,
          key: null,
          values: null,
          assets: null,
          prioritized: null,
          controls: (
            <CustomFieldsRowDelete
              loading={rowLoading}
              onCancel={(): void => { setMode(key, CustomFieldKeyMode.SHOW); }}
              onSubmit={(): void => { deleteKey(key); }}
            >
              {renderKeyCell(name)}
              {renderDeleteWarning(key)}
            </CustomFieldsRowDelete>
          )
        };
      case CustomFieldKeyMode.SHOW:
      default:
        return {
          [DND_HANDLE_ROW_KEY]: position,
          key: renderKeyCell(name),
          values: (
            <TextButton
              id={`custom-field-values-link-${name}`}
              onClick={(): void => { setSelectedCustomFieldKey(key); }}
            >
              {getValueCopy(values)}
            </TextButton>
          ),
          assets: assets > 0 ? t`${assets}` : '-',
          prioritized: (
            <StandardSwitch
              isChecked={prioritized}
              name={t`Toggle prioritization`}
              onChange={(): void => { togglePrioritization(key); }}
              size={SwitchSize.Medium}
            />
          ),
          controls: (
            <div className="button-container">
              <IconButton
                className="edit-custom-fields"
                label={isTranslateMode ? t`Translate` : t`Rename`}
                look={ButtonLooks.Tertiary}
                onClick={(): void => { setMode(key, CustomFieldKeyMode.EDIT); }}
                type="button"
              >
                <span className="bff-edit" />
              </IconButton>
              <IconButton
                className="delete-custom-fields"
                label={t`Delete`}
                look={ButtonLooks.Tertiary}
                onClick={(): void => { setMode(key, CustomFieldKeyMode.DELETE); }}
                type="button"
              >
                <span className="bff-trash" />
              </IconButton>
            </div>
          ),
        };
    }
  };

  useEffect(() => {
    // Update rows based on visibleKeys and sortBy changes
    const keyIdToRowMap = keyIdToRowMapRef.current;
    visibleKeys.forEach((key, index) => {
      if (!keyIdToRowMap.has(key.id)) {
        keyIdToRowMap.set(key.id, {}); // Set to empty object to initialize
      }

      // Shallow merge row updates
      const theObj = keyIdToRowMap.get(key.id);
      Object.assign(theObj, buildRowFor(key, index));
    });

    // Sort the keys and update the sortedRows array
    const keys = sortedKeys();
    setSortedRows(() => {
      const newRows: StandardTableRow[] = new Array(keys.length);
      let lastIndex = 0;
      keys.forEach((key) => {
        const index = lastIndex++;
        newRows[index] = keyIdToRowMap.get(key.id);
      });
      return newRows;
    });
  }, [sortBy, keyIdToRowMapRef, queryString, visibleKeys]);

  if (loading) {
    return (
      <div className="manage-custom-fields">
        <CircleLoader
          className="manage-custom-fields__page-loader"
          label={t`Loading custom fields`}
          size={LoaderSizes.Large}
        />
      </div>
    );
  }

  if (customFieldKeys.length < 1) {
    return (
      <div className="manage-custom-fields">
        <Trans>{BFG.resource.name} does not have any custom fields</Trans>
      </div>
    );
  }

  const enableDrag = sortBy === 'preferredOrder' && !queryString;
  const Table = enableDrag ? DndTable : StandardTable;
  const dndProps = enableDrag ? { moveRow, onRowDropped } : {};

  const prioritizedHeaderChildren = (
    <>
      <Trans>Prioritized</Trans>
      {' '}
      <MoreInfoTooltip
        iconLabel={t`More Info`}
        id="prioritizedTooltip"
        tooltip={t`Choose up to 5 priority custom fields to appear when "Show custom fields" is enabled, as advanced filters in your asset library, and on contact sheets.`}
      />
    </>
  );

  const heading = showAddDependentFields ?
    t`Add dependent custom fields` : t`Custom Field Keys for ${BFG.resource.name}`;

  return (
    <div className="manage-custom-fields">
      <h1 className="manage-custom-fields-header">{heading}</h1>
      <hr />
      <StandardTabs
        caption={t`custom field keys`}
        className={showAddDependentFields ? 'hide-tabs' : undefined}
        id="custom-field-tabs"
        initialActiveIndex={activeTabIndex}
        onChange={setActiveTabIndex}
        tabs={[
          {
            disabled: false,
            tabButton: <Trans>Standard view</Trans>,
            tabPanel:
              <>
                {renderSortingOptions()}
                <Table
                  caption={t`Custom Field Keys for ${BFG.resource.name}`}
                  columns={[
                    { rowKey: DND_HANDLE_ROW_KEY, children: '', tdClassName: 'custom-field-key-position' },
                    { rowKey: 'key', children: <Trans>Keys</Trans>, tdClassName: 'quarter-width' },
                    { rowKey: 'values', children: <Trans># Values</Trans>, centered: true, tdClassName: 'fifteen-width' },
                    { rowKey: 'assets', children: <Trans># Assets</Trans>, centered: true, tdClassName: 'fifteen-width' },
                    { rowKey: 'prioritized', children: prioritizedHeaderChildren, centered: true, tdClassName: 'fifteen-width' },
                    { rowKey: 'controls', tdClassName: 'custom-fields-row__controls' },
                  ]}
                  id={tableId}
                  rows={sortedRows}
                  trProps={{ className: 'custom-fields-row' }}
                  {...dndProps}
                />
              </>
          },
          {
            disabled: false,
            tabButton: <Trans>Dependency view</Trans>,
            tabPanel:
              <div className='dependency-view'>
                <StandardButton
                  className='add-dcf-button'
                  onClick={(): void => setShowAddDependentFields(true)}
                  startIcon={FontIcons.Plus}
                >
                  <Trans>Add dependent custom fields</Trans>
                </StandardButton>
                <DependencyViewAccordion isActiveView={!showAddDependentFields && activeTabIndex === 1} />
              </div>,
          },
        ]}
      />
      <AddDependentFieldsView
        handleRemoveVisibility={(): void => {
          setActiveTabIndex(1);
          setShowAddDependentFields(false);
        }}
        isVisible={showAddDependentFields}
      />
    </div >
  );
};
