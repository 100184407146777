export enum CustomizedTemplateApprovalStatuses {
  NoApprovalRequested = 'no_approval_requested',
  Pending = 'pending',
  Approved = 'approved',
  Unapproved = 'denied'
}

export enum DesignHuddleLimits {
  Inches = 52,
  Millimeters = 1300,
  Minimum = 1,
  Pixels = 10000
}

export enum DesignHuddleFileTypes {
  JPG = 'jpg',
  MP4 = 'mp4',
  PDF = 'pdf',
  PNG = 'png'
}

export enum DesignHuddleSaveAssetFileTypes {
  JPG = 'jpg',
  PDF = 'pdf',
  PNG = 'png'
}

export enum DesignHuddleUnitTypes {
  // pixels is intentionally first
  Pixels = 'px',
  Inches = 'in',
  Millimeters = 'mm'
}

export enum DesignHuddleUnitTypeNames {
  Pixels = 'Pixels',
  Inches = 'Inches',
  Millimeters = 'Millimeters'
}

export enum DesignHuddleMediaTypes {
  Digital = 'digital',
  Presentation = 'presentation',
  Print = 'print',
  Video = 'video'
}

export enum DesignHuddleMediaTypeNames {
  Digital = 'Digital',
  Presentation = 'Presentation',
  Print = 'Print',
  Video = 'Video'
}

export enum DesignHuddleStatuses {
  Active = 'active',
  Archived = 'archived',
  Hidden = 'hidden'
}

export enum DesignHuddleStatusNames {
  Active = 'Active',
  Archived = 'Archived',
  Hidden = 'Hidden'
}

export enum DesignHuddleTemplateFields {
  DateCreated = 'date_created',
  DateUpdated = 'date_updated',
  Dimensions = 'dimensions',
  InternalProjectId = 'internal_project_id',
  MediaType = 'media_type',
  PrimaryTemplateCategory = 'primary_template_category',
  PrimaryTemplateCategoryItem = 'primary_template_category_item',
  ProjectCount = 'project_count',
  SourceProject = 'source_project',
  TemplateCode = 'template_code',
  TemplateId = 'template_id',
  TemplateStatus = 'template_status',
  TemplateTitle = 'template_title',
  ThumbnailUrl = 'thumbnail_url'
}
