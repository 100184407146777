import { useEffect, useRef, useState } from 'react';

/**
 * A React hook for detecting matching a media query.
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
 * @example const matches = useMatchMedia('(min-width: 768px)');
 * @param query string
 * @param defaultMatches boolean | undefined
 * @returns boolean
 */
export const useMatchMedia = (query: string, defaultMatches = false): boolean => {
  const initialMatch = window.matchMedia(query).matches;
  const isMounted = useRef(false);

  const [matches, setMatches] = useState(initialMatch || defaultMatches);

  const listener = (e: MediaQueryListEvent): void => {
    if (isMounted.current) {
      setMatches(e.matches);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    const mediaQueryList = window.matchMedia(query);
    mediaQueryList.addEventListener('change', listener);
    setMatches(mediaQueryList.matches);

    return (): void => {
      isMounted.current = false;
      mediaQueryList.removeEventListener('change', listener);
    };
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  return matches;
};
