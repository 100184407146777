import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';

import { AssetTypes, ColorData, isCompatibleThumbnailAssetType } from '@api/v4/assets/assetTypes';

import FontClass from '@components/asset/shared/fontClass';

import { GalleryCardOverlay } from './GalleryCardOverlay';
import { AssetViewModel } from './model';

import classes from './styles/attachment-gallery.module.scss';

export interface ExpandedViewCardProps {
  alt?: string | null;
  asset: AssetViewModel;
  attachmentId?: string;
  src?: string | null;
}

type AssetThumbnailSignature = ((assetViewModel: AssetViewModel) => JSX.Element);

type ImgProps = {
  alt: string | null;
  src: string | null;
};

export const ExpandedViewCard: FunctionComponent<ExpandedViewCardProps> = ({
  alt = null,
  asset,
  attachmentId = null,
  src = null,
})=> {
  const [showOverlay, setShowOverlay] = useState(false);
  const { type } = asset;
  const handleShowOverlay = (state: boolean): void => {
    setShowOverlay(state);
  };

  const renderCompatibleAssetThumbnail = (imgProps: ImgProps) : JSX.Element => (
    <img
      alt={imgProps.alt}
      className={classes.expandedViewImage}
      src={imgProps.src}
    />
  );

  const renderColor = (assetViewModel: AssetViewModel): JSX.Element => {
    const { hex } = assetViewModel.attributes.asset_data as ColorData;

    return (
      <div
        className={classes.cardThumbnail}
        data-testid='expanded-view-color-card'
        style={{
          backgroundColor: `#${hex}`,
          background: `#${hex}`,
          height: '420px'
        }}
      />
    );
  };

  const renderFont = (assetViewModel: AssetViewModel): JSX.Element => (
    <FontClass
      asset={assetViewModel}
      className={classes.expandedViewFontCard}
    >
      Aa Bb Cc Dd Ee Ff Gg Hh Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu Vv Ww Xx Yy Zz 0123456789
    </FontClass>
  );

  const renderText = (assetViewModel: AssetViewModel): JSX.Element => {
    const { description, name } = assetViewModel.attributes;

    const formattedDescription = (description && description.replace(/\n/g, '<br>')) || '';

    return (
      <div className={classes.expandedViewTextCard}>
        <h4 className={classes.expandedViewTextCardAssetName} id={classes.expandedViewTextCardAssetName}>
          {name}
        </h4>
        <div
          className={classes.textThumbnailDescription}
          data-testid='expanded-view-description'
          // eslint-disable-next-line @typescript-eslint/naming-convention
          dangerouslySetInnerHTML={{ __html: formattedDescription }}
        />
      </div>
    );
  };

  const assetThumbnails: { [assetType: string]: AssetThumbnailSignature } = {
    [AssetTypes.Color]: renderColor,
    [AssetTypes.Font]: renderFont,
    [AssetTypes.Text]: renderText,
  };

  return (
    <div className={
      classNames({
        [classes.expandedViewCardContainer]: isCompatibleThumbnailAssetType(asset.type),
        [classes.fullWidthCardContainer]: !isCompatibleThumbnailAssetType(asset.type),
      })}
      data-testid='expanded-view-card'
      onBlur={(): void => handleShowOverlay(false)}
      onFocus={(): void => handleShowOverlay(true)}
      onMouseEnter={(): void => handleShowOverlay(true)}
      onMouseLeave={(): void => handleShowOverlay(false)}
    >
      <GalleryCardOverlay 
        asset={asset} 
        attachmentId={attachmentId}
        showOverlay={showOverlay}  
      />
      { isCompatibleThumbnailAssetType(type) && src
        ? renderCompatibleAssetThumbnail({ alt, src })
        : assetThumbnails[type](asset)
      }
    </div>
  );
};
