export enum FontIconColors {
  // None is intentionally first
  None = '',
  Danger = 'danger',
  Primary = 'primary',
  Text = 'text',
  Warning = 'warning',
  White = 'white'
}

export enum FontIconPositions {
  // None is intentionally first
  None = '',
  Left = 'left',
  Right = 'right'
}

export enum FontIcons {
  AddPage = 'add-page',
  Alert = 'alert',
  Approve = 'approve',
  ArrowDown = 'arrow-down',
  ArrowDownUp = 'arrow-down-up',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowUp = 'arrow-up',
  Attention = 'attention',
  BarGraph = 'bar-graph',
  Boards = 'boards',
  BrandCollective = 'brand-collective',
  BrandGuide = 'brand-guide',
  Build = 'build',
  Calendar = 'calendar',
  Canva = 'canva',
  Canvas = 'canvas',
  CaretDown = 'caret-down',
  CaretLeft = 'caret-left',
  CaretRight = 'caret-right',
  CaretUp = 'caret-up',
  CaretLeftDouble = 'caret-left-double',
  CaretRightDouble = 'caret-right-double',
  Chat = 'chat',
  CheckIn = 'check-in',
  CheckMark = 'check-mark',
  CheckMarkCircle = 'check-mark-circle',
  CheckOut = 'check-out',
  Clock = 'clock',
  Close = 'close',
  CloudDownload = 'cloud-download',
  ColorHexa = 'color-hexa',
  Columns = 'columns',
  Comment = 'comment',
  Confirmation = 'confirmation',
  Copy = 'copy',
  CreditCard = 'credit-card',
  Crop = 'crop',
  Cursor = 'cursor',
  CustomField = 'custom-fields',
  DollarSign = 'dollar-sign',
  Download = 'download',
  Draft = 'draft',
  Edit = 'edit',
  Ellipsis = 'ellipsis',
  Email = 'email',
  Embed = 'embed',
  Enter = 'enter',
  FaceHappy = 'face-happy',
  FaceSad = 'face-sad',
  FacebookIcon = 'facebook-icon',
  FacebookIconSquare = 'facebook-icon-square',
  Filters = 'filters',
  FontType = 'font-type',
  Form = 'form',
  FullScreen = 'full-screen',
  GeneralSettings = 'general-settings',
  GridView = 'grid-view',
  Hide = 'hide',
  HorizontalOrientation = 'horizontal-orientation',
  Image = 'image',
  Info = 'info',
  Ingest = 'ingest',
  Insights = 'insights',
  Instagram = 'instagram',
  Integrations = 'integrations',
  KnowledgeBase = 'knowledge-base',
  Label = 'label',
  Language = 'language',
  Link = 'link',
  LinkedIn = 'linked-in',
  LinkedinIconSquare = 'linkedin-icon-square',
  ListView = 'list-view',
  LocationMarker = 'location-marker',
  Logo = 'logo',
  ManageSubscription = 'manage-subscription',
  ManageVersion = 'manage-versions',
  Menu = 'menu',
  Merge = 'merge',
  Metadata = 'metadata',
  Monitor = 'monitor',
  Move = 'move',
  NewTab = 'new-tab',
  Notifications = 'notifications',
  NotificationSettings = 'notification-settings',
  Organization = 'organization',
  Palette = 'palette',
  PanoramicOrientation = 'panoramic-orientation',
  Paperclip = 'paperclip',
  Payment = 'payment',
  People = 'people',
  Person = 'person',
  Phone = 'phone',
  Pin = 'pin',
  Pinterest = 'pinterest',
  Plus = 'plus',
  Portal = 'portal',
  PressRelease = 'press-release',
  Print = 'print',
  PriorityMedium = 'priority-medium',
  PriorityHigh = 'priority-high',
  Private = 'private',
  Public = 'public',
  QuoteEnd = 'quote-end',
  QuoteStart = 'quote-start',
  RatioLock = 'ratio-lock',
  Refresh = 'refresh',
  RefreshSpinning = 'refresh-spinning',
  Remove = 'remove',
  Reorder = 'reorder',
  RequestDownload = 'request-download',
  RulesEngine = 'rules-engine',
  Save = 'save',
  ScoreMeter = 'score-meter',
  Search = 'search',
  Section = 'section',
  Sent = 'sent',
  Settings = 'settings',
  Share = 'share',
  Show = 'show',
  Smartsheet = 'smartsheet',
  SpinnerGif = 'spinner-gif',
  SquareOrientation = 'square-orientation',
  Star = 'star',
  StarHollow = 'star-hollow',
  Stealth = 'stealth',
  Switch = 'switch',
  Tag = 'tag',
  Task = 'task',
  Template = 'template',
  TemplateEditor = 'template-editor',
  Tip = 'tip',
  ToolTip = 'tool-tip',
  Trash = 'trash',
  TwitterIcon = 'twitter-icon',
  Unapprove = 'unapprove',
  Unlink = 'unlink',
  Upload = 'upload',
  UserAccepted = 'user-accepted',
  UserCollaborator = 'user-collaborator',
  UserGuest = 'user-guest',
  UserInvited = 'user-invited',
  UserSettings = 'user-settings',
  VerticalOrientation = 'vertical-orientation',
  Video = 'video',
  VisitMedia = 'visit-media',
  Warning = 'warning',
  Watermark = 'watermark',
  Workspace = 'workspace',
  XCircle = 'x-circle',
  YouTubeIcon = 'you-tube-icon',
  ZipFile = 'zip-file',
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out'
}
