import PropTypes from 'prop-types';
import React from 'react';
import Breakpoints from 'react-breakpoints';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import NavigationDropdownWrapper from './navigation_dropdown_wrapper';

const breakpoints = {
  mobile: 768,
  desktop: 769,
};

// need to wrap in breakpoint to detect mobile
const NavigationDropdownBreakPoints = (props) => (
  <I18nProviderWrapper>
    <Breakpoints breakpoints={breakpoints}>
      <NavigationDropdownWrapper {...props} />
    </Breakpoints>
  </I18nProviderWrapper>
);

NavigationDropdownBreakPoints.defaultProps = {
  libraryName: "Brandfolder",
};

NavigationDropdownBreakPoints.propTypes = {
  libraryName: PropTypes.string,
  rootDomain: PropTypes.string.isRequired,
};

export default NavigationDropdownBreakPoints;
