import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '@components/common/tooltip/main';

import LinkModal from './link-modal';

class Link extends React.Component {
  state = {
    modalOpen: false,
  }

  componentDidMount() {
    this._isMounted = true; // eslint-disable-line no-underscore-dangle
  }

  componentWillUnmount() {
    this._isMounted = false; // eslint-disable-line no-underscore-dangle
  }

  showEditModal = () => {
    this.setState({ modalOpen: true });
  }

  onUpdate = ({ name, url }) => {
    const { initial } = this.props;

    this.props.onUpdate({
      name,
      url,
      initial,
    });
  }

  onModalClose = () => {
    if (!this._isMounted) { return; } // eslint-disable-line no-underscore-dangle
    this.setState({ modalOpen: false });
  }

  renderIcons() {
    const { name, url } = this.props;
    const { hostname } = new URL(url)

    // Hardcoded CDN images for common icons
    // Implementation due to hitting limit on Google favicons
    if (url.includes('facebook')) {
      return (
        <img
          alt="Facebook icon"
          className="links__icon"
          src="https://cdn.brandfolder.io/27C9EC93/at/qei3sn-eaf84o-97xobb/facebook-icon.svg"
        />
      );
    }

    if (url.includes('twitter')) {
      return (
        <img
          alt="Twitter icon"
          className="links__icon"
          src="https://cdn.brandfolder.io/27C9EC93/at/qei3q5-4ydtyg-g9951h/twitter-icon.svg"
        />
      );
    }

    if (url.includes('youtube')) {
      return (
        <img
          alt="YouTube icon"
          className="links__icon"
          src="https://cdn.brandfolder.io/27C9EC93/at/qei22d-5erup4-4k4qci/youtube-icon.svg"
        />
      );
    }

    return (
      <img
        alt={name}
        src={`https://www.google.com/s2/favicons?domain=${hostname}`}
      />
    );
  }

  renderInner() {
    const { url, editing } = this.props;

    if (editing) {
      return (
        <button
          className="links__link-action"
          onClick={this.showEditModal}
          type="button"
        >
          {this.renderIcons()}
        </button>
      );
    }

    return (
      <a
        className="links__link-action"
        href={url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {this.renderIcons()}
      </a>
    );
  }

  render() {
    const { modalOpen } = this.state;
    const { url, name, editing, id, initial } = this.props;

    return (
      <React.Fragment>
        {modalOpen ? (
          <LinkModal
            brandfolderKey={this.props.brandfolderKey}
            id={id}
            name={initial ? '' : name}
            onClose={this.onModalClose}
            onUpdate={this.onUpdate}
            url={initial ? '' : url}
          />
        ) : null}

        <li className={`links__link ${editing ? 'links__link--editing' : ''}`}>
          {name === '' ? this.renderInner() : (
            <Tooltip
              tooltipContent={editing ? t`Edit ${name}` : name}
              tooltipId={`link-name-${id}`}
            >
              {this.renderInner()}
            </Tooltip>
          )}
        </li>
      </React.Fragment>
    );
  }
}

Link.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  initial: PropTypes.bool,
  brandfolderKey: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  onUpdate: PropTypes.func,
};

Link.defaultProps = {
  editing: false,
  initial: false,
  onUpdate: () => {},
};

export default Link;
