import { ShareManifestClient, ShareManifestServer } from '@api/v4/share_manifests/ShareManifestTypes';

export const mapShareManifestServerToClient = (server: ShareManifestServer): ShareManifestClient => ({
  availabilityEnd: server.availability_end,
  availabilityStart: server.availability_start,
  contextableId: server.contextable_id,
  contextableType: server.contextable_type,
  createdAt: server.created_at,
  createdBy: server.created_by,
  customLogoUrl: server.custom_logo_url,
  deletedAt: server.deleted_at,
  digest: server.digest,
  expired: server.expired || false,
  expires: server.expires,
  id: server.id,
  internal: server.internal,
  key: server.key,
  link: server.link,
  message: server.message,
  name: server.name,
  notificationsOptin: server.notifications_optin || false,
  numberOfAssets: server.number_of_assets,
  requireIdentification: server.require_identification,
  requireShareIdentificationSetting: server.require_share_identification_setting,
  restrictSharesSetting: server.restrict_shares_setting,
  shareableId: server.shareable_id,
  shareableType: server.shareable_type,
  galleryView: server.gallery_view,
  timeZone: server.time_zone,
  updatedAt: server.updated_at,
  userKey: server.user_key,
  viewOnly: server.view_only
});
