import { ListboxOption } from '@brandfolder/react';
import { FormikErrors, FormikTouched } from 'formik';

import { UserRoles } from '@api/v4/private/resources/users';
import { ServerResourceType } from '@components/bulk_management/user-settings/resource-sidebar';
import { object, SchemaOf, string } from 'yup';

export type FormTabs = 'invite' | 'message';

export interface FormResource {
  type: ServerResourceType;
  key: string;
}

export type EmailsAndUserGroups = Omit<ListboxOption, 'children' | 'disabled' | 'error' | 'label' | 'placeholder' | 'search' | 'tooltip'>;

export const emailsAndUserGroupsSchema: SchemaOf<EmailsAndUserGroups> = object().shape({
  key: string(),
  type: string(),
  value: string()
});

export interface InvitationFormValues {
  resources: FormResource[];
  emailsAndUserGroups: EmailsAndUserGroups[];
  permissionLevel: UserRoles;
  personalMessage: string;
  preventEmail: boolean;
}

export interface PermissionFormValues { //had to create this for UserGroupsAddPermissionForm
  emails: string[]
  resources: FormResource[];
  permissionLevel: UserRoles;
  personalMessage: string;
  preventEmail: boolean;
  userGroups: string[];
}

export interface MessageFormValues {
  resources: FormResource[];
  recipients: UserRoles[];
  personalMessage: string;
}

export const currentTabValuesIsInvite = (
  tab: FormTabs,
  values: InvitationFormValues | MessageFormValues
): values is InvitationFormValues => (
  tab === 'invite'
);

export const currentTabErrorsIsInvite = (
  tab: FormTabs,
  errors: FormikErrors<InvitationFormValues | MessageFormValues>
): errors is FormikErrors<InvitationFormValues> => (
  tab === 'invite'
);

export const currentTabTouchedIsInvite = (
  tab: FormTabs,
  touched: FormikTouched<InvitationFormValues | MessageFormValues>
): touched is FormikTouched<InvitationFormValues> => (
  tab === 'invite'
);

export const currentTabValuesIsMessage = (
  tab: FormTabs,
  values: InvitationFormValues | MessageFormValues
): values is MessageFormValues => (
  tab === 'message'
);

export const currentTabErrorsIsMessage = (
  tab: FormTabs,
  errors: FormikErrors<InvitationFormValues | MessageFormValues>
): errors is FormikErrors<MessageFormValues> => (
  tab === 'message'
);

export const currentTabTouchedIsMessage = (
  tab: FormTabs,
  touched: FormikTouched<InvitationFormValues | MessageFormValues>
): touched is FormikTouched<MessageFormValues> => (
  tab === 'message'
);
