import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTBody, BaseTBodyProps } from '../../base';

import classes from '../styles/standard-table.module.scss';

export const TBody: FunctionComponent<BaseTBodyProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <BaseTBody
      {...otherProps}
      className={classnames('standardTbody', classes.tbody, props.className)}
    >
      {children}
    </BaseTBody>
  );
};
