import { Trans } from '@lingui/macro';
import { decode } from 'html-entities';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { BulkManagementLink } from '../shared';

import { DashboardTile } from './dashboard-tile';

import './styles/bulk_management.scss';

interface DashboardProps {
  links: BulkManagementLink[];
}

export const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const { links } = props;
  const resourceName = decode(BFG.resource.name);

  return (
    <I18nProviderWrapper>
      <div className='manage-bulk-management' id='bulk-management-dashboard'>
        <h2>
          <Trans>{resourceName} Bulk Management</Trans>
        </h2>
        <section className='bulk-management-tiles-container'>
          {links.map((link) => {
            if (link.href === 'dashboard') {
              return null;
            }
            return (
              <DashboardTile key={link.id} link={link} />
            );
          })}
        </section>
      </div>
    </I18nProviderWrapper>
  );
};
