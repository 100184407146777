import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useRef } from 'react';

import { BaseProgressProps } from '@components/library/progress';

import classes from './styles/base-progress.module.scss';

const baseName = 'progress';

export const BaseProgress: FunctionComponent<BaseProgressProps> = (props) => {
  const {
    id,
    label,
    value,
    animated,
    ariaValueMin = 0,
    maxValue = 100,
    ariaValueMax = maxValue,
    styleClassLabel,
    styleClassProgress,
    ...otherProps
  } = props;

  const progressBar = useRef<HTMLProgressElement | null>(null);

  useEffect(() => {
    if (animated) {
      setTimeout(() => {
        if (progressBar && progressBar.current) {
          progressBar.current.setAttribute('value', value.toString());
        }
      }, 1000);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {label && (
        <label
          className={classnames(
            `${baseName}-label`,
            classes['progress-label'],
            styleClassLabel
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <progress
        {...otherProps}
        ref={progressBar}
        aria-valuemax={ariaValueMax}
        aria-valuemin={ariaValueMin}
        aria-valuenow={value}
        className={classnames(
          `${baseName}-value`,
          classes['progress-value'],
          styleClassProgress
        )}
        id={id}
        max={maxValue}
        value={animated ? 0 : value}
      />
    </>
  );
};
