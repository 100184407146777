import React from 'react';
import PropTypes from 'prop-types';
import BaseLabel from './BaseLabel';

import './primary_styles.scss';

const Label = ({ attributes, children }) => (
  <BaseLabel
    {...attributes}
    className={`bf-label--primary ${attributes?.className}`}
  >
    {children}
  </BaseLabel>
);

/* eslint-disable react/no-unused-prop-types */
Label.propTypes = {
  children: PropTypes.node,
  htmlFor: PropTypes.string, // must match input id string
};
/* eslint-enable react/no-unused-prop-types */

Label.defaultProps = {
  children: undefined,
};

export default Label;
