import { t, Trans } from '@lingui/macro';
import React, { useState, useEffect } from 'react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';

import { move, destroy, update } from '@api/v4/labels';
import {
  get as getLabels,
  create as createLabel,
  formatLabelsTree,
} from '@api/v4/resources/labels';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { BFLoader } from '@components/common/loader/main';

import AddLabelModal from './add_label_modal';
import ManageLabelModal from './manage_label_modal';

import './styles/main.scss';

function LabelTree() {
  const [labels, setLabels] = useState([]);
  const [labelsLoading, setLabelsLoading] = useState(true);
  const [updatedNodes, refreshTreeData] = useState([]);
  const [activeLabel, activateLabel] = useState({});
  const [showModal, toggleModal] = useState(false);
  const [expandedLabels, toggleExpanded] = useState([]);

  useEffect(() => {
    setLabelsLoading(true);
    getLabels({ fetchAll: true }).then((data) => formatLabelsTree(data, expandedLabels)).then((labelsTree) => {
      setLabels(labelsTree.children);
      setLabelsLoading(false);
    }).catch(() => {
      setLabelsLoading(false);
    });
  }, [updatedNodes]); // eslint-disable-line react-hooks/exhaustive-deps

  const moveNode = (treeData) => {
    const labelKeyToMove = treeData.node.key;
    const newParentKey = treeData.nextParentNode ? treeData.nextParentNode.key : null;
    const oldParentKey = treeData.node.parentId || null;
    const parentChanged = newParentKey !== oldParentKey;
    const positionChanged = treeData.treeIndex !== treeData.prevTreeIndex;
    let position = 0;
    if (treeData.nextParentNode) {
      position = treeData.nextParentNode.children.indexOf(treeData.node);
    } else {
      position = treeData.treeData.indexOf(treeData.node);
    }
    if (parentChanged || positionChanged) {
      move(labelKeyToMove, newParentKey, position).then(() => {
        setLabels(treeData.treeData);
      });
    }
  };

  const visibleToggle = (treeData) => {
    const labelKey = treeData.node.key;
    if (treeData.expanded) {
      toggleExpanded(expandedLabels.concat(labelKey));
    } else {
      toggleExpanded(expandedLabels.filter((key) => key !== labelKey));
    }
  };

  const openEditModal = (rowInfo) => {
    const { key, name } = rowInfo.node;
    activateLabel({ key, actionType: "Edit", name });
    toggleModal(true);
  };

  const editLabel = (key, updateLabelData) => {
    update(key, updateLabelData).then((data) => {
      refreshTreeData(updatedNodes.concat(`edited ${key}`));
      activateLabel({});
      Notify.create({ type: "success", title: `Updated ${data.data.attributes.name}` });
      toggleModal(false);
    });
  };

  const removeLabel = (key, labelName) => {
    const swalOptions = {
      title: t`Are you sure you want to delete ${labelName}?`,
      text: t`This will also delete any sub-labels.`,
      type: "warning",
      showCancelButton: true,
      cancelButtonText: t`Cancel`,
      confirmButtonText: t`Delete`,
      closeOnConfirm: true,
    };
    window.swal(swalOptions, (confirmSelected) => {
      if (confirmSelected) {
        destroy(key).then(() => {
          refreshTreeData(updatedNodes.concat(`removed ${key}`));
          activateLabel({});
          Notify.create({ type: "success", title: `Deleted ${labelName}` });
          toggleModal(false);
        });
      }
    });
  };

  const openAddModal = (rowInfo) => {
    const { key, name, path } = rowInfo.node;
    activateLabel({ actionType: "Add", parentKey: key, parentName: name, path });
    toggleModal(true);
  };

  const addLabel = (newLabelData) => {
    createLabel(newLabelData).then((data) => {
      const parentKey = newLabelData.parent_key;
      if (parentKey && !expandedLabels.includes(parentKey)) {
        toggleExpanded(expandedLabels.concat(parentKey));
      }
      refreshTreeData(updatedNodes.concat(`added ${data.data.id}`));
      toggleModal(false);
      activateLabel({});
      Notify.create({ type: "success", title: `Added ${data.data.attributes.name}` });
    });
  };

  const editButton = (rowInfo) => (
    <span
      className="bff-edit"
      label="Edit"
      onClick={() => openEditModal(rowInfo)}
      role="button"
    />
  );

  const addButton = (rowInfo) => (
    <span
      label="Add"
      onClick={() => openAddModal(rowInfo)}
      role="button"
    >
      <Trans>Add sub-label</Trans>
    </span>
  );

  const renderModal = () => {
    const actionType = activeLabel.actionType;
    if (showModal && actionType && actionType.toLowerCase() === "add") {
      return (
        <AddLabelModal
          activeLabel={activeLabel}
          addLabel={addLabel}
          toggleModal={toggleModal}
        />
      );
    }
    if (showModal && actionType && actionType.toLowerCase() === "edit") {
      return (
        <ManageLabelModal
          activeLabel={activeLabel}
          editLabel={editLabel}
          removeLabel={removeLabel}
          toggleModal={toggleModal}
        />
      );
    }
    return null;
  };

  const brandfolderName = BF.fx.brandfolder().name;

  return (
    <I18nProviderWrapper>
      <div className="manage-labels">
        <h2>
          <Trans>Labels for {brandfolderName}</Trans>
        </h2>
        <hr />
        {
          labelsLoading ? <BFLoader /> : (
            <>
              <div className="top-level-addition">
                <a onClick={() => openAddModal({ node: {} })}>
                  <span className="bff-plus" />
                  <Trans>Add top level label</Trans>
                </a>
              </div>
              <div style={{ height: '90vh' }}>
                <SortableTree
                  generateNodeProps={(rowInfo) => ({
                    buttons: [addButton(rowInfo), editButton(rowInfo)],
                  })}
                  onChange={(treeData) => setLabels(treeData)}
                  onMoveNode={moveNode}
                  onVisibilityToggle={visibleToggle}
                  treeData={labels}
                />
                {renderModal()}
              </div>
            </>
          )
        }
      </div>
    </I18nProviderWrapper>
  );
}

export default LabelTree;
