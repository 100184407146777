import React, { ButtonHTMLAttributes, CSSProperties, forwardRef, ReactNode } from 'react';
import classnames from 'classnames';

import { ButtonLoader as BlueButtonLoader, RedButtonLoader, WhiteButtonLoader } from '@components/common/loader/main';
import { FontIcon, FontIcons } from '@components/library/icon';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleClassNames: StyleClassNames;
  variantName: string;
  buttonLoaderColor?: ButtonLoaderColors;
  icon?: string | FontIcons;
  iconSize?: number;
  isLoading?: boolean;
  loadingCopy?: string;
  loadingIcon?: string | FontIcons;
  loadingIconSize?: number;
  size?: ButtonSize;
  useDisabledStyleForAriaDisabled?: boolean;
}

type StyleClassNames = {
  [key: string]: string;
};
type ButtonSize = 'xsmall' | 'small' | 'medium' | 'large';
export type ButtonLoaderColors = 'blue' | 'red' | 'white';

const baseName = 'button';

const BaseButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    buttonLoaderColor = 'blue',
    children,
    className,
    icon,
    iconSize,
    isLoading,
    loadingCopy,
    loadingIcon,
    loadingIconSize,
    onClick,
    size,
    styleClassNames,
    tabIndex = 0,
    type = 'button',
    useDisabledStyleForAriaDisabled = false,
    variantName,
    ...buttonProps
  } = props;

  const CustomLoadingIcon = ({ className: customLoadingIconClassName }): JSX.Element => (
    <FontIcon
      aria-hidden
      className={customLoadingIconClassName}
      icon={loadingIcon as FontIcons}
      iconSize={loadingIconSize}
    />
  );

  const renderButtonLoader = (): ReactNode => {
    let buttonLoaderComponent;
    switch (loadingIcon ? 'custom' : buttonLoaderColor) {
      case 'custom':
        buttonLoaderComponent = CustomLoadingIcon;
        break;
      case 'red':
        buttonLoaderComponent = RedButtonLoader;
        break;
      case 'white':
        buttonLoaderComponent = WhiteButtonLoader;
        break;
      case 'blue':
      default:
        buttonLoaderComponent = BlueButtonLoader;
    }

    if (loadingCopy) {
      return <>{buttonLoaderComponent({ className: styleClassNames['button-loader'] })}{loadingCopy}</>;
    }

    return <>{buttonLoaderComponent({ className: styleClassNames['button-loader'] })}{children}</>;
  };

  const renderContent = (): ReactNode => {
    if (!isLoading) {
      return children;
    }

    return renderButtonLoader();
  };

  const renderIcon = (): ReactNode => {
    if (isLoading || !icon) return null;

    const inlineIconStyle: CSSProperties = iconSize ? { fontSize: `${iconSize}px` } : undefined;

    if (icon.includes('bff-')) {
      return (
        <span
          aria-hidden
          className={`${icon} ${styleClassNames.icon || ''}`}
          role="img"
          style={inlineIconStyle}
        />
      );
    }
    return (
      <FontIcon
        aria-hidden
        className={styleClassNames.icon}
        icon={icon as FontIcons}
        style={inlineIconStyle}
      />
    );
  };

  return (
    <button
      {...buttonProps}
      ref={ref}
      aria-disabled={isLoading || buttonProps['aria-disabled']}
      className={classnames(
        `bf-${baseName}__${variantName}`, // base-variant class for post consumption styling
        styleClassNames[variantName], // class for css modules
        className, // user specified class
        size ? { [styleClassNames[size]]: size } : undefined,
        { [styleClassNames.loading]: isLoading },
        { loading: isLoading },
        { [styleClassNames.disabled]: useDisabledStyleForAriaDisabled }
      )}
      onClick={isLoading ? undefined : onClick}
      tabIndex={tabIndex}
      type={type}
    >
      {renderIcon()}
      {renderContent()}
    </button>
  );
});

export default BaseButton;
