import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { addToLabel } from '@api/v4/bulk_actions/assets';

const LabelContent = ({
  onLabelSelect,
  toggleExpand,
  hasChildren,
  labelId,
  indentationLevel,
  name,
  isHovered,
  connectDropTarget,
  leftIndentation = `${50 + indentationLevel * 20}px`,
}) => (
  connectDropTarget(
    <div className={`label-name-container ${isHovered ? 'hover' : ''}`}>
      {hasChildren ? (
        <button
          className="expand-button"
          onClick={() => toggleExpand(labelId)}
          style={{ left: `${10 + indentationLevel * 20}px` }}
          type="button"
        >
          <span className="bff-caret-down icon" />
        </button>
      ) : null}
      <button
        className="select-button"
        onClick={() => {
          toggleExpand(labelId, true, true);
          onLabelSelect(labelId);
        }}
        type="button"
      >
        <div
          className="select-contents"
          style={{ left: `${leftIndentation}`, maxWidth: `calc(100% - ${leftIndentation})` }}
        >
          <span className="bff-label icon" />
          <h4
            className="name-copy"
            title={name}
          >
            {name}
          </h4>
        </div>
      </button>
    </div>
  )
);

LabelContent.propTypes = {
  onLabelSelect: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  labelId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hasChildren: PropTypes.bool,
  indentationLevel: PropTypes.number,

  isHovered: PropTypes.bool,
  connectDropTarget: PropTypes.func.isRequired,
};

LabelContent.defaultProps = {
  hasChildren: false,
  indentationLevel: 0,
  isHovered: false,
};

const assetTarget = {
  drop({ labelId }, monitor) {
    const { id, selectedAssetKeys } = monitor.getItem();
    addToLabel(Array.from(new Set([...selectedAssetKeys, id])), labelId);
  }
};

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isHovered: monitor.isOver(),
});

export default DropTarget('asset-label', assetTarget, dropCollect)(LabelContent);
