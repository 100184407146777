import { Trans } from '@lingui/macro';
import React, { useState } from 'react';

import { Portal } from '@api/v4/private/PortalTypes';

import PortalSettings from './portal_settings';
import SearchBar from './search_bar';

interface HeaderBackgroundProps {
  admin: boolean;
  editMode: boolean;
  headerHexColor: string | undefined;
  headerNameOverlay: boolean | undefined;
  libraryName: string;
  portal: Portal;
  toggleEditMode: () => void;
}

const HeaderBackground: React.FunctionComponent<HeaderBackgroundProps> = ({
  admin,
  editMode,
  headerHexColor,
  headerNameOverlay,
  libraryName,
  portal,
  toggleEditMode,
}) => {
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const toggleForm = (): void => setShowForm((prevState) => !prevState);

  return (
    <header className="portal-header">
      {headerNameOverlay
        ? (
          <div
            className="portal-header__image"
            data-private
            style={{ backgroundImage: portal.headerUrl ? `url(${portal.headerUrl})` : '' }}
          >
            <div className="portal-header__text">
              <Trans>
                <h1
                  className="portal-header__text--title"
                  style={{ color: headerHexColor ? `#${headerHexColor}` : undefined }}
                >
                  Welcome to
                </h1>
                <h1
                  className="portal-header__text--title"
                  style={{ color: headerHexColor ? `#${headerHexColor}` : undefined }}
                >
                  <span className="portal-header__text--title-bold">{portal.name}</span>
                </h1>
              </Trans>
            </div>
          </div>
        ) : (
          <div
            className="portal-header__image--responsive"
            data-private
            style={{ backgroundImage: portal.headerUrl ? `url(${portal.headerUrl})` : '' }}
          />
        )}
      {admin
        && (
          <PortalSettings
            editMode={editMode}
            libraryName={libraryName}
            setShowSettingsDropdown={setShowSettingsDropdown}
            showForm={showForm}
            showSettingsDropdown={showSettingsDropdown}
            toggleEditMode={toggleEditMode}
            toggleForm={toggleForm}
          />
        )}
      <SearchBar
        searchUrl={portal.searchUrl}
      />
    </header>
  );
};

export default HeaderBackground;
