import { t } from '@lingui/macro';
import React, { useContext, FunctionComponent } from 'react';

import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import { ActionTypes, AssetDetailsDispatch, ValidationErrors } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { StandardTextField } from '@components/library/text-field';

interface AssetNameScopedContext {
  state: {
    editState: {
      assetName: string;
    };
    errorState: ValidationErrors[];
  };
  dispatch: AssetDetailsDispatch;
}

const AssetNameInput: FunctionComponent<{ assetNameCopy?: string }> = ({ assetNameCopy = '' }) => {
  const { state, dispatch }: AssetNameScopedContext = useContext(assetModalContext);
  const { assetName } = state.editState;
  const hasNameError = state.errorState.includes(ValidationErrors.MissingAssetName);
  return (
    <StandardTextField
      className="name-input"
      error={hasNameError ? t`Asset name required` : ''}
      id="name"
      label={assetNameCopy || t`Asset Name`}
      name="name"
      onChange={(e: InputChangeEvent): void => dispatch(
        { type: ActionTypes.UpdateAsset, payload: { assetName: e.target.value } }
      )}
      placeholder=""
      value={assetName}
    />
  );
};

export default AssetNameInput;
