import { StandardPagination, brandfolderColorPalette } from '@brandfolder/react';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;

    .page-totals-wrapper {
      align-items: center;
      display: flex;
      
      .page-totals {
        color: ${brandfolderColorPalette.neutrals[500]};
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
        margin-right: 16px;
      }
    }
`;

interface PaginationProps {
  page: number;
  perPage: number;
  setPage: (x: { page: string }) => void;
  total: number;
  children?: React.ReactNode;
  text?: string;
}

export const Pagination: FunctionComponent<PaginationProps> = ({
  page,
  perPage,
  setPage,
  total,
  children = null,
  text = 'people',
}) => {
  const totalPages = Math.ceil(total / perPage);
  const start = (page - 1) * perPage + 1;
  const end = Math.min(start + perPage - 1, total);
  return (
    <PaginationWrapper className={!total ? 'hidden' : 'visible'}>
      <div className="page-totals-wrapper">
        <span className="page-totals">
          {total ? `${start} - ${end} of ${total} ${text}` : text}
        </span>
        {children}
      </div>
      <StandardPagination
        initial={page || 1}
        labels={{ nextLabel: 'Next', previousLabel: 'Previous' }}
        onPageChange={(num): void => setPage({ page: num.toString() })}
        total={totalPages}
      />
    </PaginationWrapper>
  );
};
