import { FontIcon, FontIcons } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, ReactNode, useState, useEffect } from 'react';

import { MAX_PAGE_SIZE, useFetch } from '@api/ApiHelper';
import {
  Asset,
  AssetData,
  AssetTypes,
  AssetTypesSingular,
  PersonAsset,
  PersonData,
  PressAsset,
  Tag
} from '@api/v4/assets/assetTypes';
import { CustomFieldValuesListResponse, FlattenedCustomFieldKeyValuesMap } from '@api/v4/assets/customFieldTypes';
import { flattenCustomFieldKeyValuesList } from '@api/v4/assets/helpers';
import { TaskClient, TaskServer } from '@api/v4/tasks';
import { getAssetBackgroundColor } from '@components/asset/modal/tabs/asset_details/helpers/colors';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import ClipboardCopy from '@components/common/clipboard_copy/main';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { StandardAvatars } from '@components/library/avatars';
import { DashEmptyLabel } from '@components/library/empty-label';
import { StandardTooltip } from '@components/library/tooltip';
import { camelizeObjectKeys, toTitleCase, AvatarUser } from '@components/library/utils';
import { generateAssetDeepLink } from '@helpers/assets';
import { localizeDate } from '@helpers/localize';
import { getUserDisplayName } from '@helpers/user';

import AssetPreview from './asset_preview/AssetPreview';
import { AssetDetailsMetadata } from './AssetDetailsMetadata';
import { AssetDetailsTabs } from './AssetDetailsTabs';
import AssetTags from './assetTags';
import { CustomFieldsAccordion } from './CustomFieldsAccordion';
import PersonDetails from './personDetails';

export interface AssetDetailsProps {
  approvedBy: string;
  asset: Asset;
  assetType: AssetTypes;
  attachmentKeys: string[];
  awaitingApproval: string;
  backgroundColor: string;
  collectionExpiration: string;
  expired: boolean;
  expirationDate: string;
  formattedDescription: string;
  isDownloadable: boolean;
  isPreviewRenderError: boolean;
  publishDate: string;
  tags: Tag[];
  threeSixtyViewer: boolean;
  unpublished: boolean;
  updatedAndCreated: string;
  assetData?: AssetData; // only on colors, external_media, fonts, and people
  assignedUsers?: AvatarUser[] | null; // only on @generic_file
  task?: TaskServer | null; // only on @generic_file
}

/**
 * NOTE: Anytime you do work in this component make sure there is parity with
 * app/javascript/components/asset/modal/tabs/asset_details/orgSearchResultsAssetDetails.jsx.
 * @param props AssetDetailsProps
 * @returns JSX.Element
 */
export const AssetDetails: FunctionComponent<AssetDetailsProps> = ({
  approvedBy,
  asset,
  assetData,
  assetType,
  assignedUsers,
  attachmentKeys,
  awaitingApproval,
  backgroundColor,
  collectionExpiration,
  expired,
  expirationDate,
  formattedDescription,
  isDownloadable,
  publishDate,
  tags,
  task,
  threeSixtyViewer,
  unpublished,
  updatedAndCreated
}) => {
  const isWorkspace = BFG.hasFeature('workspace') && BFG.resource.is_workspace;
  const hasTask = isWorkspace && !!task && task.workspace_key === BFG.resource.key;
  const url = `/api/v4/assets/${asset.asset_key}/custom_field_values`;

  const href = generateAssetDeepLink({ assetKey: asset.asset_key });

  const notSpecified = t`Not Specified`;
  const { loading, response } = useFetch<CustomFieldValuesListResponse>({
    include: 'custom_field_key',
    params: {
      digest: BFG.manifest?.digest,
      per: MAX_PAGE_SIZE,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ugt_locale: determineUGTLocale()
    },
    url,
  });

  const [customFieldKeyValuesList, setCustomFieldKeyValuesList] = useState<FlattenedCustomFieldKeyValuesMap>({});

  useEffect((): void => {
    if (response) {
      setCustomFieldKeyValuesList(flattenCustomFieldKeyValuesList(response));
    }
  }, [response]);

  const renderAssetSpecificDescription = (): JSX.Element | null => {
    switch (asset.type?.toLowerCase()) {
      case AssetTypesSingular.People:
        return (
          <PersonDetails
            email={(assetData as PersonData).email}
            linkedin={(asset as PersonAsset).linkedin_url}
            name={asset.name}
            phoneNumber={(assetData as PersonData).phone_number}
            title={(assetData as PersonData).title}
            twitter={(asset as PersonAsset).twitter}
            twitterUrl={(asset as PersonAsset).twitter_url}
          />
        );
      case AssetTypesSingular.Press:
        return (
          <div className="m-show-description">
            {(asset as PressAsset).url ? (
              <a href={(asset as PressAsset).url} target="blank">
                {' '}
                {(asset as PressAsset).url}
              </a>
            ) : (
              null
            )}
            {(asset as PressAsset).published_date ? <div> {(asset as PressAsset).published_date} </div> : null}
          </div>
        );
      default:
        return null;
    }
  };

  const renderAssetDetailsTabPanel = (): ReactNode => (
    <>
      <AssetDetailsMetadata
        approvedBy={approvedBy}
        awaitingApproval={awaitingApproval}
        collectionExpiration={collectionExpiration}
        expirationDate={expirationDate}
        expired={expired}
        isWorkspace={isWorkspace}
        publishDate={publishDate}
        unpublished={unpublished}
        updatedAndCreated={updatedAndCreated}
      />
      {tags.length > 0 && <AssetTags orgSearchPage={false} tags={tags} />}
      {renderAssetSpecificDescription()}
      {!!formattedDescription && (
        <div className="m-show-description s-asset-description">
          <span className="s-asset-details-title modal-label">
            {t`Description`}
          </span>
          {/* eslint-disable-next-line @typescript-eslint/naming-convention, react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: formattedDescription }} />
        </div>
      )}
      <CustomFieldsAccordion count={6} customFieldKeyValuesList={customFieldKeyValuesList} loading={loading} />
      {!!asset.asset_key && (
        <div className="m-show-asset-key asset-copy-links">
          <span className="s-asset-details-title modal-label">
            {t`Asset Key`}
          </span>
          <p className="asset-copy-links__content">{asset.asset_key}</p>
          <ClipboardCopy check showFeedbackAsTooltip={false} textToCopy={asset.asset_key}>
            <FontIcon aria-hidden icon={FontIcons.Copy} />
            {t`Copy asset key`}
          </ClipboardCopy>
        </div>
      )}
      {!BFG.showPageLite && (
        <div className="m-show-deeplink asset-copy-links">
          <span className="s-asset-details-title modal-label">
            {t`Link to Asset Card`}
          </span>
          <p className="asset-copy-links__content">{href}</p>
          <ClipboardCopy check showFeedbackAsTooltip={false} textToCopy={href}>
            <FontIcon aria-hidden icon={FontIcons.Copy} />
            {t`Copy link`}
          </ClipboardCopy>
        </div>
      )}
    </>
  );

  const renderTaskDetailsTabPanel = (): ReactNode => {
    if (!hasTask) return null;

    const { dueDate, filetype, dimensions, taskDescription, taskPriority, taskStatus } = camelizeObjectKeys<TaskServer, TaskClient>(task);

    const users: AvatarUser[] = assignedUsers ? assignedUsers.map((user) => ({
      ...user,
      name: getUserDisplayName(user)
    })) : [];

    return (
      <div className="task-details">
        <div className="task-details__attributes">
          <div className="task-details__attributes--row">
            <p className="task-details__attributes--row-title">{t`Due Date`}</p>
            <p className="task-details__attributes--row-content">{dueDate ? localizeDate(dueDate) : (
              <DashEmptyLabel
                aria-label={notSpecified}
              />
            )}
            </p>
          </div>
          <div className="task-details__attributes--row">
            <p className="task-details__attributes--row-title">{t`Priority`}</p>
            <p className="task-details__attributes--row-content">{taskPriority ? toTitleCase(taskPriority) : (
              <DashEmptyLabel
                aria-label={notSpecified}
              />
            )}
            </p>
          </div>
          <div className="task-details__attributes--row">
            <p className="task-details__attributes--row-title">{t`File Type`}</p>
            <p className="task-details__attributes--row-content">{filetype?.toUpperCase() || (
              <DashEmptyLabel
                aria-label={notSpecified}
              />
            )}
            </p>
          </div>
          <div className="task-details__attributes--row">
            <p className="task-details__attributes--row-title">{t`Size or Dimensions`}</p>
            {dimensions && dimensions.length >= 20 ? (
              <StandardTooltip
                id="task-size-or-dimensions"
                tooltip={dimensions}
                wrapperProps={{ className: 'task-details__attributes--row-tooltip' }}
              >
                <p className="task-details__attributes--row-content">{dimensions || (
                  <DashEmptyLabel
                    aria-label={notSpecified}
                  />
                )}
                </p>
              </StandardTooltip>
            ) : (dimensions || (
              <DashEmptyLabel
                aria-label={notSpecified}
              />
            ))}
          </div>
          <div className="task-details__attributes--row">
            <p className="task-details__attributes--row-title">{t`Status`}</p>
            <p className="task-details__attributes--row-content">{taskStatus ? toTitleCase(taskStatus) : (
              <DashEmptyLabel
                aria-label={notSpecified}
              />
            )}
            </p>
          </div>
          <div className="task-details__attributes--row">
            <p className="task-details__attributes--row-title">{t`Assigned to`}</p>
            {users.length > 0 ? (
              <StandardAvatars
                users={users}
              />
            ) : (
              <DashEmptyLabel
                aria-label={notSpecified}
              />
            )}
          </div>
        </div>
        <div className="task-details__description">
          {taskDescription.length > 0 && (
            <>
              <p className="task-details__description--title">{t`Description`}</p>
              <p>{taskDescription}</p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <I18nProviderWrapper>
      <AssetPreview
        asset={asset}
        assetBackgroundColor={getAssetBackgroundColor(backgroundColor)}
        assetType={assetType}
        attachmentKeys={attachmentKeys}
        customFieldKeyValuesList={customFieldKeyValuesList}
        isDownloadable={isDownloadable}
        isWorkspace={isWorkspace}
        tags={tags}
        threeSixtyViewer={threeSixtyViewer}
      />
      {isWorkspace ? (
        <AssetDetailsTabs
          assetTabPanel={renderAssetDetailsTabPanel()}
          taskTabPanel={renderTaskDetailsTabPanel()}
        />
      ) : renderAssetDetailsTabPanel()}
    </I18nProviderWrapper>
  );
};
