import {
  ButtonLooks,
  ButtonSizes,
  FontIcon,
  FontIcons,
  ButtonLink
} from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

interface ReportsButtonProps {
  orgSlug: string
}

const ReportsButton: FunctionComponent<ReportsButtonProps> = ({
  orgSlug
}) => {
  return (
    <I18nProviderWrapper>
      <ButtonLink
        href={`/organizations/${orgSlug}/standard_reports`}
        look={ButtonLooks.Tertiary}
        size={ButtonSizes.Small}>
        <FontIcon icon={FontIcons.Reports} />
        <Trans>Reports</Trans>
      </ButtonLink>
    </I18nProviderWrapper>
  );
};

export default ReportsButton;
