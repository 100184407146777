import { StandardDatePickerLabels } from '@brandfolder/react';
import { t } from '@lingui/macro';

export const getStandardDatePickerLabels = (labels: Partial<StandardDatePickerLabels> = {}): StandardDatePickerLabels => {
  return {
    changeDate: t`Change date`,
    chooseDate: t`Choose date`,
    closeButton: t`Close`,
    invalidDateError: t`Invalid date`,
    label: t`Due Date`,
    nextMonthButton: t`Next month`,
    pastDateError: t`Past date not allowed`,
    previousMonthButton: t`Previous month`,
    requiredLabel: t`Required`,
    ...labels
  };
};
