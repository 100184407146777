import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { TertiaryButton } from '@components/library/button';

import { PressReleaseUpsert } from './PressReleaseTypes';

import classes from './styles/press_release_form_step4.module.scss';

interface PressReleaseFormStep4Props {
  handleClose: SetStateDispatch<boolean>;
  pressRelease: PressReleaseUpsert;
}

/**
 * Step 4: Success/Confirmation
 * @param {PressReleaseFormStep4Props} PressReleaseFormStep4Props
 */
export const PressReleaseFormStep4: FunctionComponent<PressReleaseFormStep4Props> = ({ handleClose, pressRelease }) => (
  <div className={classes.success} id="success">
    <span className={classnames('bff-check-mark', classes.icon)} role="img" />
    <h3 className={classes.h3}><Trans>Success!</Trans></h3>
    <p className={classes.p}>
      {pressRelease?.subject
        ? (
          <Trans>
            Your email has been sent: <strong>{pressRelease.subject}</strong>
          </Trans>
        ) : <Trans>Your email has been sent.</Trans>
      }
    </p>
    <TertiaryButton
      id="success-close"
      onClick={(): void => {
        handleClose(false);
      }}
    >
      <Trans>Close</Trans>
    </TertiaryButton>
  </div>
);
