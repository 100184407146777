/* eslint-disable @typescript-eslint/naming-convention */
import fetchJSON from '@api/api_helper';
import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { Resource } from '@api/v4/resources/resourceTypes';
import { Locale } from '@components/common/language_menu/languagesMap';
import { Watermark } from '@components/settings/brandfolder/advanced-tab/watermark/watermarkTypes';
import { SearchOperatorOptions } from '@components/show_page/sections/section_search/SearchTypes';

interface UpdateResourceProps {
  key: string;
  allowCollectionGuestAssetCropping?: boolean;
  customTemplatesApprovalsRequired?: boolean;
  defaultSearchOperator?: SearchOperatorOptions.OR | SearchOperatorOptions.AND | SearchOperatorOptions.Quoted;
  defaultShareManifestPrivate?: boolean;
  defaultStrictSearch?: boolean;
  defaultShareManifestExpiration?: number | null;
  downloadAlert?: string;
  downloadAlertEnabled?: boolean;
  embedAsset?: boolean;
  multiLanguageAssetDetailsEnabled?: boolean;
  peopleTagging?: boolean;
  resourceType?: string;
  showCustomFieldsOverviewTab?: boolean;
  showMetadata?: boolean;
  showTags?: boolean;
  ugtLocaleDefault?: Locale;
  watermark?: Watermark;
  watermarkEnabled?: boolean;
  whitelistedLocales?: Locale[];
}

const url = (key: string, resourceTypeString?: string): string => `/api/v4/${resourceTypeString || 'brandfolders'}/${key}`;

export const update = ({
  allowCollectionGuestAssetCropping: allow_collection_guest_asset_cropping = undefined,
  customTemplatesApprovalsRequired: custom_templates_approvals_required = undefined,
  defaultSearchOperator: default_search_operator = undefined,
  defaultShareManifestPrivate: default_share_manifest_private = undefined,
  defaultStrictSearch: default_strict_search = undefined,
  defaultShareManifestExpiration: default_share_manifest_expiration_seconds = undefined,
  downloadAlert: download_alert = undefined,
  downloadAlertEnabled: download_alert_enabled = undefined,
  embedAsset: embed_asset = undefined,
  key,
  multiLanguageAssetDetailsEnabled: multi_language_asset_details_enabled = undefined,
  peopleTagging: people_tagging = undefined,
  resourceType = '',
  showCustomFieldsOverviewTab: show_custom_fields_overview_tab = undefined,
  showMetadata: show_metadata = undefined,
  showTags: show_tags = undefined,
  ugtLocaleDefault: default_locale = undefined,
  watermark: watermark = undefined,
  watermarkEnabled: watermark_enabled = undefined,
  whitelistedLocales: whitelisted_locales = undefined
}: UpdateResourceProps): Promise<ApiDatumResponse<Resource>> => {
  let options = {};
  if (allow_collection_guest_asset_cropping !== undefined) {
    options = {
      ...options,
      allow_collection_guest_asset_cropping
    };
  }
  if (show_custom_fields_overview_tab !== undefined) {
    options = {
      ...options,
      show_custom_fields_overview_tab
    };
  }
  if (show_metadata !== undefined) {
    options = {
      ...options,
      show_metadata
    };
  }
  if (show_tags !== undefined) {
    options = {
      ...options,
      show_tags
    };
  }
  return (
    fetchJSON(url(key, resourceType && `${resourceType}s`), {
      method: 'PUT',
      body: {
        data: {
          attributes: {
            ...custom_templates_approvals_required !== undefined && { custom_templates_approvals_required },
            ...default_locale && { default_locale },
            ...whitelisted_locales && { whitelisted_locales },
            ...default_search_operator && { default_search_operator },
            ...default_share_manifest_private !== undefined && { default_share_manifest_private },
            ...default_strict_search !== undefined && { default_strict_search },
            ...default_share_manifest_expiration_seconds !== undefined && { default_share_manifest_expiration_seconds },
            ...download_alert && { download_alert },
            ...download_alert_enabled !== undefined && { download_alert_enabled },
            ...embed_asset !== undefined && { embed_asset },
            ...multi_language_asset_details_enabled !== undefined && { multi_language_asset_details_enabled },
            ...people_tagging !== undefined && { people_tagging },
            ...watermark && { watermark },
            ...watermark_enabled !== undefined && { watermark_enabled },
            ...!!Object.keys(options).length && { options }
          }
        }
      }
    })
      .then((response) => response)
      .catch(async (response) => { throw (await response.json()); })
  );
};
