import { t, Trans } from '@lingui/macro';
import { Result } from '@uppy/transloadit';
import { PickerResponse } from 'filestack-js/build/main/lib/picker';
import React, { useContext, FunctionComponent } from 'react';

import { LinkObject } from '@api/v4/assets/assetTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';

import {
  ActionTypes,
  ThumbnailOverrideDispatch,
  ThumbnailOverridePayloadOperations
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { FilestackUploader } from '@components/common/filestack_uploader/FilestackUploader';
import { UppyUploader } from '@components/common/uppy/UppyUploader';

interface ThumbnailOverrideProps {
  isTask?: boolean;
}

interface ThumbnailOverrideScopedContext {
  state: {
    editState: {
      thumbnailOverride: LinkObject;
    };
  };
  dispatch: ThumbnailOverrideDispatch;
}

const ThumbnailOverrideComponent: FunctionComponent<ThumbnailOverrideProps> = ({ isTask }) => {
  const { state, dispatch }: ThumbnailOverrideScopedContext = useContext(assetModalContext);
  const { thumbnailOverride } = state.editState;
  const uploadAreaId = 'thumbnail-override-trigger-thumbnail-override';

  const deleteAssetThumbnailOverride = (): void => {
    const payload = {
      operation: ThumbnailOverridePayloadOperations.Delete
    };
    dispatch({ type: ActionTypes.ThumbnailOverrideAction, payload });
  };

  const updateAssetThumbnailOverrideUppy = (files: Result[]): void => {
    if (files.length === 0) return;
    const payload = {
      operation: ThumbnailOverridePayloadOperations.Update,
      url: files[0].url,
      filename: files[0].name
    };
    dispatch({ type: ActionTypes.ThumbnailOverrideAction, payload });
  };

  const updateAssetThumbnailOverrideFileStack = (files: PickerResponse): void => {
    if (files.filesUploaded.length === 0) return;
    const payload = {
      operation: ThumbnailOverridePayloadOperations.Update,
      url: files.filesUploaded[0].url,
      filename: files.filesUploaded[0].filename
    };
    dispatch({ type: ActionTypes.ThumbnailOverrideAction, payload });
  };

  const onError = (): void => {
    Notify.create({ type: 'error', title: t`Error Uploading Files` });
  };

  const uploadProvider = (): React.ReactElement => (
    <>
      {BFG.hasFeature('uppy_uploader_features') ? (
        <>
          <div
            className="uppy-thumbnail-override-settings"
            id={uploadAreaId}
          >
            <Trans>Click to upload</Trans>
          </div>
          <UppyUploader
            button
            handleUpload={updateAssetThumbnailOverrideUppy}
            restrictions={{ maxNumberOfFiles: 1, allowedFileTypes: ['image/*'] }}
            template="thumbnail-override"
            trigger={`#${uploadAreaId}`}
            uniqIdentifier="uppy-thumbnail-override-thumbnail-override"
          />
        </>
      ) : (
        <div
          id={uploadAreaId}
        >
          <FilestackUploader
            dropzoneContainerId={uploadAreaId}
            onUploadDone={updateAssetThumbnailOverrideFileStack}
            pickerOptions={{
              accept: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
              maxFiles: 1,
              onFileUploadFailed: onError
            }}
          />
        </div>
      )}
    </>
  );

  return (
    <div className="asset-advanced__feature">
      <span>
        {isTask ? <Trans>Custom Task Thumbnail</Trans> : <Trans>Custom Asset Thumbnail</Trans>}
      </span>
      <div className="thumbnail-override-upload-area">
        {thumbnailOverride.url && thumbnailOverride.filename ? (
          <div className="asset-advanced__feature--upload-complete">
            <FilestackPreviewImage
              alt={t`Asset thumbnail override image`}
              height="50px"
              src={thumbnailOverride.url}
            />
            <span className='asset-advanced__feature--filename'>
              {thumbnailOverride.filename}
            </span>
            <span
              className="bff-trash remove-thumbnail-override"
              onClick={(): void => deleteAssetThumbnailOverride()}
              onKeyPress={(): void => deleteAssetThumbnailOverride()}
              role="button"
              tabIndex={0}
              title={t`Remove Custom Asset Thumbnail`}
            />
          </div>
        ) : uploadProvider()}
      </div>
    </div>
  );
};

export default ThumbnailOverrideComponent;
