import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';

import { getPortalCard, createPortalCard, updatePortalCard } from '@api/v4/private/portal_cards';
import { PortalCardTypes, PortalCardSubTypes, ValidationErrorsEnum, PortalCard } from '@api/v4/private/PortalCardTypes';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { FilestackUploader } from '@components/common/filestack_uploader/FilestackUploader';
import {
  CloseButton,
  PrimaryButton,
  TextWarningButton,
} from '@components/library/button/index';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';
import renderModal from '@components/show_page/modals/renderModal';

import { handleDeletion } from '../helpers/card_deletion_helper';
import { makeValidUrl } from '../helpers/make_valid_url';
import { handleValidations, nameError, linkError } from '../portal_cards/validations';

interface LinkFormProps {
  closeModal: () => void;
  defaultSubType?: PortalCardSubTypes;
  triggerCardsRefresh: () => void;
  portalCardId?: string;
}

const LinkForm: React.FunctionComponent<LinkFormProps> = ({
  closeModal,
  defaultSubType,
  portalCardId,
  triggerCardsRefresh
}): JSX.Element => {
  const type = PortalCardTypes.PortalLink;
  const [iconUrl, setIconUrl] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [name, setName] = useState('');
  const [subType, setSubType] = useState(defaultSubType || PortalCardSubTypes.InternalLink);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsEnum[]>([]);
  const portalId = (BFG.resource.type === 'portal') ? BFG.resource.key : null;
  const invalidSubmission = (validationErrors.length > 0);

  useEffect(() => {
    if (portalCardId) {
      getPortalCard({ portalCardId })
        .then((response) => {
          const { attributes } = response.data;
          setIconUrl(attributes.icon_url || '');
          setLinkUrl(attributes.link_url || '');
          setName(attributes.name);
          if (attributes.sub_type) {
            setSubType(PortalCardSubTypes[attributes.sub_type]);
          }
        });
    }
  }, [portalCardId]);

  const requestBody: PortalCard = {
    iconUrl,
    linkUrl,
    name,
    subType,
    type
  };

  const submitCreateCard = ((): void => {
    if (portalId) {
      if (handleValidations(type, setValidationErrors, requestBody)) return;

      createPortalCard(portalId, requestBody)
        .then(() => {
          triggerCardsRefresh();
          closeModal();
        })
        .catch(() => {
          Notify.create({
            title: t`Something went wrong!`,
            body: t`Please try creating this link again.`,
            type: 'error'
          });
          closeModal();
        });
    }
  });

  const submitUpdateCard = ((): void => {
    if (portalCardId) {
      if (handleValidations(type, setValidationErrors, requestBody)) return;

      updatePortalCard(portalCardId, requestBody)
        .then(() => {
          triggerCardsRefresh();
          closeModal();
        })
        .catch(() => {
          Notify.create({
            title: t`Something went wrong!`,
            body: t`Please try updating this link again.`,
            type: 'error'
          });
          closeModal();
        });
    }
  });

  // TODO: Change all the references to tile-
  // Don't want to totally break styles, so holding on changing the names
  return (
    <div className="modal-content-wrapper portal-modal tile-form">
      <div className="portal-modal__header">
        {portalCardId ? (
          <span aria-hidden="true" className="bff-edit" />
        ) : (
          <span aria-hidden="true" className="bff-plus" />
        )}
        <h3 className="modal-title">
          {portalCardId
            ? <Trans>Edit {name}</Trans>
            : subType === PortalCardSubTypes.ExternalLink
              ? <Trans>Create External Link</Trans>
              : <Trans>Create Navigation Link</Trans>
          }
        </h3>
        <CloseButton onClick={closeModal} />
      </div>
      <div className="portal-modal__body">
        <div className="tile-form__name">
          <div className="tile-form__character-limit">
            <InputContainer
              attributes={{
                name: 'link-name',
                onChange: (e: InputChangeEvent): void => setName(e.target.value),
                type: InputType.Text,
              }}
              input={{ value: name, error: nameError(validationErrors) }}
              labelCopy={<><Trans>Name</Trans> *</>}
              submitAttempted={invalidSubmission}
            />
          </div>
        </div>
        <InputContainer
          attributes={{
            name: 'link-url',
            onChange: (e: InputChangeEvent): void => setLinkUrl(e.target.value),
            onBlur: (): void => setLinkUrl(makeValidUrl(linkUrl)),
            type: InputType.Text,
          }}
          input={{ value: linkUrl, error: linkError(validationErrors) }}
          labelCopy={<><Trans>Link URL</Trans> *</>}
          submitAttempted={invalidSubmission}
        />
        <div className="portal-modal__filestack-container">
          <div className="portal-modal__filestack-container--description">
            <h3><Trans>Link Icon</Trans></h3>
            <p><Trans>Recommended size: 30 x 30px</Trans></p>
          </div>
          {iconUrl ? (
            <div className="portal-modal__filestack-container--thumbnail">
              <div className="portal-modal__filestack-container--thumbnail-container">
                <FilestackPreviewImage alt="link icon" className="portal-modal__filestack-container--thumbnail--image" data-private src={iconUrl} />
              </div>
              <TextWarningButton
                icon="bff-trash"
                onClick={(): void => setIconUrl('')}
              />
            </div>
          )
            : (
              <FilestackUploader
                onUploadDone={(files): void => setIconUrl(files.filesUploaded[0].url)}
                pickerOptions={{
                  accept: 'image/*',
                  maxFiles: 1,
                  transformations: {
                    crop: {
                      aspectRatio: (64 / 64),
                      force: true
                    }
                  }
                }}
              />
            )
        }
        </div>
        <div className="portal-modal__button">
          {portalCardId
            && (
              <div className="portal-modal__buttons portal-modal__buttons--two-buttons">
                <TextWarningButton
                  icon="bff-trash"
                  onClick={(): void => handleDeletion(portalCardId, name, closeModal, triggerCardsRefresh)}
                >
                  <Trans>Delete Link</Trans>
                </TextWarningButton>
                <PrimaryButton
                  onClick={submitUpdateCard}
                  size="medium"
                >
                  <Trans>Update Link</Trans>
                </PrimaryButton>
              </div>
            )
          }
          {!portalCardId && (
            <PrimaryButton
              onClick={submitCreateCard}
              size="small"
            >
              <Trans>Create Link</Trans>
            </PrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(LinkForm, 'LinkForm');
export default ModalComponent;
