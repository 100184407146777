import React from 'react';
import PropTypes from 'prop-types';
import './styles/primary_logo_link.scss';

export default class PrimaryLogoLink extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {};
  }

  logo() {
    if (this.props.theme === 'dark') {
      // Dark font
      return "https://cdn.bfldr.com/I6FML9WY/at/pgltaq-5fsycg-8zia3c/brandfolder-logo.svg?auto=webp&format=png&height=23&width=100";
    }
    // light font
    return "https://cdn.bfldr.com/I6FML9WY/at/phvzz7-e706cg-accx8/brandfolder-logo-white-2x.svg?auto=webp&format=png&height=24&width=100";
  }

  render() {
    return (
      <div className="primary-logo-link-component">
        <a
          className="primary-logo-link"
          href="https://brandfolder.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="Brandfolder" src={this.logo()} />
        </a>
      </div>
    );
  }
}

PrimaryLogoLink.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark'])
};

PrimaryLogoLink.defaultProps = {
  theme: 'dark'
};
