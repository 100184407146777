import classnames from 'classnames';
import React, { BaseHTMLAttributes, FunctionComponent, ReactNode } from 'react';

import classes from '../styles/tab_list.module.scss';

type BaseDivAttributesOmitted = Omit<BaseHTMLAttributes<HTMLDivElement>,
'aria-label' | 'aria-orientation' | 'role'>

interface TabListProps extends BaseDivAttributesOmitted {
  /** description of the tabs, like "Steps for creating an email" (required for accessibility) */
  caption: string;
  /** content is required */
  children: ReactNode;
}

/**
 * Wrapper around TabButtons
 * @param {TabListProps} props TabListProps
 */
export const TabList: FunctionComponent<TabListProps> = (props) => {
  const { children, caption, ...divProps } = props;

  return (
    <div
      {...divProps}
      aria-label={caption}
      // TODO: vertical support
      aria-orientation="horizontal"
      className={classnames(classes.tabList, divProps.className)}
      role="tablist"
    >
      {children}
    </div>
  );
};
