/**
 * @deprecated
 */
const formattedUppyAttachment = (file) => ({
  url: file.ssl_url,
  filename: file.original_name,
  mimetype: file.mime,
  size: file.size,
  height: file.meta.height,
  width: file.meta.width,
  folder_path: file.original_path,
  external_id: `transloadit|${file.id}`,
  source: 'uppy'
});

const uniqueFilesByKey = (files, key) => {
  const uniqFiles = {};
  files.forEach((file) => {
    uniqFiles[file[key]] = file;
  });
  return Object.values(uniqFiles);
};

const formattedUppyAssets = (files, activeLabelKey) => files.map((file) => ({
  attachments: [formattedUppyAttachment(file)],
  ...activeLabelKey && { labels: [activeLabelKey] },
  name: file.original_basename,
}));

/**
 * @deprecated
 */
const formattedUppyAttachments = (files) => files.map((file) => (
  formattedUppyAttachment(file)
));

const duplicateSweetAlertOptions = {
  title: "Duplicate Files Detected",
  text: "Would you like to upload only one of each file?\n\n",
  type: "warning",
  showCancelButton: true,
  cancelButtonText: "No",
  confirmButtonText: "Yes",
  closeOnConfirm: true,
  customClass: "merging"
};

const TransloaditTemplateMap = () => {
  /* eslint-disable no-undef, key-spacing */
  if (BF_Environment === "development") {
    return {
      background_image:            "a0d72be0b3c84cfa8472a5b89efde945",
      card_image:                  "3206aae0349511e9869d6f52a2f3c8f4",
      email_logo:                  "6f59ac30349511e9be92adb72a190c5e",
      favicon_image:               "05638de0349611e9869d6f52a2f3c8f4",
      file_conversion:             "c35ae4b024e711e9b55f7b5484700320",
      file_conversion_watermark:   "94cd683024b911e9a8a7bd3965351113",
      file_ingest:                 "c1eed8f0200211e9862e4359eaf314a7",
      header_image:                "6670fc90348b11e9abf74174c765dd63",
      image_thumbnailer:           "8bea7f20203411e9a06077a28298ede6",
      image_watermark_thumbnailer: "5213c35020e411e990ff73ab14e458cc",
      logo_image:                  "2d29be80349611e9b4f4bfe8815a00e7",
      url_ingest:                  "0ea666001f5811e9bbd6430a93fce38e",
      url_screenshot_thumbnailer:  "007557b020f411e990ff73ab14e458cc",
      video_watermark:             "2cf293a53c46476b927a6a1cc2c8f8d8",
      watermark:                   "c1eed8f0200211e9862e4359eaf314a7"
    };
  }
  if (BF_Environment === "staging") {
    return {
      background_image:            "1a2f0a7139db4502975575c0b1d711bc",
      card_image:                  "320f9320361711e9a189a70e8c617ef5",
      email_logo:                  "22981ce0361811e98dd8055edf2e1ea9",
      favicon_image:               "4d5f3170361811e9909627f5887d15e6",
      file_conversion:             "b27ff200297611e98a5f3bbfdcb3df53",
      file_conversion_watermark:   "9f44b270297611e9b86377e66fb1900d",
      file_ingest:                 "8d164f00297611e992c7cb7c6782894b",
      header_image:                "f79e1c00361811e9824a2fe31b0d9d7d",
      image_thumbnailer:           "54219ec0297611e992c7cb7c6782894b",
      image_watermark_thumbnailer: "6aa85fd0297611e9b86377e66fb1900d",
      logo_image:                  "d9ce77a0361911e9943eb13d2c9e2d78",
      url_ingest:                  "384868a0297611e992c7cb7c6782894b",
      url_screenshot_thumbnailer:  "7d8fb300297611e9b86377e66fb1900d",
      video_watermark:             "5262dc5357ed412787e63eb86aee18f0",
      watermark:                   "8d164f00297611e992c7cb7c6782894b"
    };
  }
  return {
    background_image:            "3f5bbdabcb1340e0a225074d9f1b8828",
    card_image:                  "bd28fa70356111e9be950322b2fd1e7e",
    email_logo:                  "c51fbf10356211e99a35b7f34e23c325",
    favicon_image:               "a7277980356211e9869d6f52a2f3c8f4",
    file_conversion:             "2a635230297711e999523d7af4851778",
    file_conversion_watermark:   "1e8aa300297711e9b86377e66fb1900d",
    file_ingest:                 "11e41c80297711e9a14f1f411cdee99f",
    header_image:                "83c8c1b0356211e9b0e5d98333d44e1a",
    image_thumbnailer:           "ddcb51c0297611e9b72875093256a4ce",
    image_watermark_thumbnailer: "f1c881c0297611e9850f019573220028",
    logo_image:                  "dd6ac560356211e9b0e5d98333d44e1a",
    url_ingest:                  "d0050360297611e9a9d5e329af6c385f",
    url_screenshot_thumbnailer:  "03c91e70297711e9a14f1f411cdee99f",
    video_watermark:             "515f335ac7104403bfe38fcb932c5afe",
    watermark:                   "11e41c80297711e9a14f1f411cdee99f"
  };
  /* eslint-enable no-undef, key-spacing */
};

export {
  formattedUppyAttachment,
  formattedUppyAttachments,
  formattedUppyAssets,
  uniqueFilesByKey,
  duplicateSweetAlertOptions,
  TransloaditTemplateMap
};
