import React, {
  FunctionComponent,
  InputHTMLAttributes,
  KeyboardEvent,
  MutableRefObject,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { MentionsInput, Mention } from 'react-mentions';
import classnames from 'classnames';

import { getUsers } from '@api/v4/brandfolders/users';
import { User } from '@api/v4/UserTypes';
import FetchControllersWrapper from '@components/common/fetch_controllers_wrapper';
import { AsyncComponentProps } from '@components/common/AsyncComponentProps';

import './styles/_mentionable_input.scss';

interface MentionableInputProps {
  content: string;
  focusOnMount?: boolean;
  htmlAttributes?: InputHTMLAttributes<HTMLElement>;
  inputRef?: MutableRefObject<HTMLElement>;
  onChange?: (updatedContent: string, mentions: string[]) => void;
  onSubmit?: (e: KeyboardEvent) => void;
  providedData?: Suggestion[];
  setParentMentionableUsers?: SetStateDispatch<Suggestion[]>;
}

interface MentionAttributes {
  childIndex: number;
  display: string;
  id: string;
  index: number;
  plainTextIndex: number;
}

interface Suggestion {
  display: string;
  email: string;
  id: string;
}

const MentionableInput: FunctionComponent<MentionableInputProps & AsyncComponentProps> = ({
  abortFetchControllers,
  content,
  focusOnMount,
  htmlAttributes,
  providedData,
  inputRef,
  onChange,
  onSubmit,
  setParentMentionableUsers,
  updateFetchControllers
}) => {
  const [mentionableUsers, setMentionableUsers] = useState<Suggestion[] | null>(providedData || null);
  const guestCommentingEnabled = BFG?.brandfolderSettings?.guest_commenting;

  useEffect(() => (abortFetchControllers), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setMentionableUsers(providedData || null);
  }, [providedData]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const getDisplayName = ({ first_name, last_name, email }: User): string => {
    if (first_name && last_name) {
      return `${first_name}${last_name}`;
    }

    const atIndex = email.lastIndexOf('@');
    return `${email.substring(0, atIndex)}`;
  };

  const renderSuggestion = (suggestion: Suggestion): ReactNode => (
    <>
      <span className="mentionable-input__suggestions__item__display-name">{suggestion.display}</span>
      <span className="mentionable-input__suggestions__item__email">{suggestion.email}</span>
    </>
  );

  const displayTransform = (id: string, display: string): string => (`@${display}`);

  const getUsersAsync = async (): Promise<Suggestion[]> => {
    try {
      const users = await getUsers({
        resourceKey: BFG.resource.key,
        params: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          permission_level: guestCommentingEnabled ? undefined : 'collaborator',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          exclude_current_user: 'true',
          per: 3000
        },
        updateFetchControllers
      });
      const transformedUsers = users.data.map(({ id, attributes }) => ({
        id,
        display: getDisplayName(attributes),
        email: attributes.email
      }));
      return transformedUsers.sort((a: Suggestion, b: Suggestion) => (
        a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1
      ));
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    if (inputRef && focusOnMount) {
      inputRef.current.focus();
    }
  });

  const handleKeyPress = (e: KeyboardEvent): void => {
    if (e.key === 'Enter' && !e.shiftKey && onSubmit) {
      e.preventDefault();
      onSubmit(e);
    }
  };

  const handleSearch = async (query: string): Promise<Suggestion[]> => {
    const users = mentionableUsers || await getUsersAsync();
    if (users !== mentionableUsers) {
      if (setParentMentionableUsers) {
        setParentMentionableUsers(users);
      } else {
        setMentionableUsers(users);
      }
    }

    const caseInsensitiveQuery = query.toLowerCase();
    const startsWithMatches: Suggestion[] = [];
    const existsMatches: Suggestion[] = [];
    const emailMatches: Suggestion[] = [];
    users.forEach((user) => {
      const caseInsensitiveDisplay = user.display.toLowerCase();
      const caseInsensitiveEmail = user.email.toLowerCase();
      if (caseInsensitiveDisplay.startsWith(caseInsensitiveQuery)) {
        startsWithMatches.push(user);
      } else if (caseInsensitiveDisplay.includes(caseInsensitiveQuery)) {
        existsMatches.push(user);
      } else if (caseInsensitiveEmail.includes(caseInsensitiveQuery)) {
        emailMatches.push(user);
      }
    });
    return [...startsWithMatches, ...existsMatches, ...emailMatches];
  };

  const handleChange = (
    e: InputChangeEvent,
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionAttributes[]
  ): void => {
    if (onChange) {
      onChange(e.target.value, mentions.map(({ id }) => id));
    }
  };

  return (
    <MentionsInput
      {...htmlAttributes}
      className={classnames(
        'mentionable-input',
        { 'static-mentionable': htmlAttributes?.disabled },
        htmlAttributes?.className
      )}
      inputRef={inputRef}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      value={content}
    >
      <Mention
        appendSpaceOnAdd
        className="mentionable-input__mention"
        data={(query: string, callback: () => void): void => { handleSearch(query).then(callback); }}
        displayTransform={displayTransform}
        renderSuggestion={renderSuggestion}
        trigger="@"
      />
    </MentionsInput>
  );
};

const WrappedMentionsInput: FunctionComponent<MentionableInputProps> = (props) => (
  <FetchControllersWrapper>
    <MentionableInput {...props} />
  </FetchControllersWrapper>
);

export default WrappedMentionsInput;
