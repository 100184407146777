import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import './styles/footer.scss';

interface FooterProps {
  copyright: string;
  privacyPolicy: string;
  termsOfService: string;
}

export const Footer: FunctionComponent<FooterProps> = ({
  copyright,
  privacyPolicy,
  termsOfService
}) => (
  <div className="footer-component">
    <div className="footer-component__left">
      <div className="copyright-container">
        <p className="copyright-text">
          ©{(new Date()).getFullYear()} {copyright}
          <span className="dot" role="presentation">·</span>
        </p>
      </div>
      <div className="links-container">
        <a
          href={privacyPolicy}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Trans>Privacy Policy</Trans>
        </a>
        <span className="dot" role="presentation">·</span>
        <a
          href={termsOfService}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Trans>Terms of Service</Trans>
        </a>
      </div>
    </div>
    <div className="footer-component__right">
      <a
        className="bf-logo-container"
        href="https://brandfolder.com/home"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Trans>
          <span>Powered by</span>
          <img
            alt="branfolder logo"
            className="bf-logo-horiz"
            src="https://cdn.bfldr.com/27C9EC93/at/hf36pp9f9p46c9wpgr45shx/brandfolder-logo.svg"
          />
        </Trans>
      </a>
    </div>
  </div>
);
