import { t } from '@lingui/macro';
import React, { FunctionComponent, useContext, useState } from 'react';

import { FontData } from '@api/v4/assets/assetTypes';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType, SelectOnChangeEvent } from '@components/library/inputs/Input.props';

import assetModalContext from '../asset_modal_context';
import { AssetDetailsDispatch, ActionTypes, WebFonts, ValidationErrors } from '../EditTabTypes';

import './styles/_font_sidebar.scss';

interface FontSideBarProps {
  webFonts: WebFonts;
}

interface FontSideBarScopedContext {
  dispatch: AssetDetailsDispatch;
  state: {
    editState: {
      fontData: FontData;
    };
    errorState: ValidationErrors[];
  };
}

const emptyFontData: FontData = {
  other_font_id: '',
  web_font_id: '',
  web_font_type: ''
};

const FontSideBar: FunctionComponent<FontSideBarProps> = ({ webFonts }) => {
  const { state, dispatch }: FontSideBarScopedContext = useContext(assetModalContext);
  const fontData = state.editState.fontData || emptyFontData;

  const [isWebFont, setIsWebFont] = useState(
    !!webFonts[fontData.web_font_id]
    || (!fontData.web_font_id && !fontData.other_font_id)
  );
  // handle existing edge web fonts that are now missing an entry
  if (!isWebFont && fontData.web_font_id && fontData.web_font_type === 'adobe') {
    dispatch({
      type: ActionTypes.UpdateAsset,
      payload: {
        fontData: {
          ...fontData,
          web_font_id: '',
          web_font_type: '',
          other_font_id: fontData.web_font_id
        }
      }
    });
  }

  const fontTypeOptions = [
    { value: 'webfont', label: t`Webfont` },
    { value: 'other', label: t`Manually enter font` }
  ];
  const webFontOptions = Object.keys(webFonts).map((value) => (
    {
      value,
      label: webFonts[value].label
    }
  ));
  const fontTypeSelectValue = isWebFont
    ? fontTypeOptions[0]
    : fontTypeOptions[1];

  const hasInputError = state.errorState.includes(ValidationErrors.MissingFontInput);

  let webFontInput;
  if (isWebFont) {
    webFontInput = (
      <InputContainer
        attributes={{
          type: InputType.Select,
          name: 'webfont-select',
          onChange: (e: SelectOnChangeEvent): void => dispatch({
            type: ActionTypes.UpdateAsset,
            payload: {
              fontData: {
                ...fontData,
                other_font_id: '',
                web_font_id: e.value || '',
                web_font_type: e.value ? webFonts[e.value].type : ''
              }
            }
          })
        }}
        input={{ error: hasInputError ? t`Font selection required` : '', value: fontData.web_font_id }}
        labelCopy={t`Webfont`}
        options={webFontOptions}
        selectValue={
          fontData.web_font_id
            ? { value: fontData.web_font_id, label: webFonts[fontData.web_font_id].label }
            : undefined
        }
        submitAttempted={hasInputError}
      />
    );
  }

  const otherFontInput = (
    <InputContainer
      attributes={{
        type: InputType.Text,
        name: 'other-font',
        onChange: (e: InputChangeEvent): void => dispatch({
          type: ActionTypes.UpdateAsset,
          payload: {
            fontData: {
              ...fontData,
              web_font_id: '',
              web_font_type: '',
              other_font_id: e.target.value
            }
          }
        })
      }}
      input={{ error: hasInputError ? t`Font input required` : '', value: fontData.other_font_id }}
      labelCopy={t`Font Name`}
      submitAttempted={hasInputError}
    />
  );

  return webFontOptions && webFontOptions.length > 0 && (
    <div className="edit-tab-sidebar one-third bf-scroll-element">
      <InputContainer
        attributes={{
          type: InputType.Select,
          name: 'font-type-select',
          onChange: (e: SelectOnChangeEvent): void => setIsWebFont((e.value === 'webfont'))
        }}
        input={{ error: '', value: fontTypeSelectValue }}
        labelCopy={t`Font Type`}
        options={fontTypeOptions}
        selectValue={fontTypeSelectValue}
      />
      {isWebFont ? webFontInput : otherFontInput}
    </div>
  );
};

export default FontSideBar;
