import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes } from 'react';

import { TextButton } from '@components/library/button';
import { InputType } from '@components/library/inputs/Input.props';
import PrimaryInput from '@components/library/inputs/PrimaryInput';

import classes from './styles/press_release_search.module.scss';

interface PressReleasesSearchProps extends HTMLAttributes<HTMLDivElement> {
  onChange: (e: InputChangeEvent) => void;
  onClick: () => void;
  onKeyUp: () => void;
  value: string;
}

/**
 * Search input with search icon button
 * TODO: This can probably be used throughout the app, move to component library
 * @param {PressReleasesSearchProps} props PressReleasesSearchProps
 */
export const PressReleasesSearch: FunctionComponent<PressReleasesSearchProps> = ({ className, onChange, onClick, onKeyUp, value, ...otherProps }) => (
  <div {...otherProps} className={classnames(classes.search, className)}>
    <PrimaryInput
      attributes={{
        className: classes.searchInput,
        name: 'search',
        onChange: (e: InputChangeEvent): void => {
          onChange(e);
        },
        onKeyUp: (e): void => {
          if (e.key === 'Enter') {
            onKeyUp();
          }
        },
        placeholder: t`Search`,
        type: InputType.Search
      }}
      input={{
        value
      }}
    />
    <TextButton
      className={classes.searchButton}
      onClick={(): void => {
        onClick();
      }}
    >
      <span className="bff-search" />
    </TextButton>
  </div>
);
