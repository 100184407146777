import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';

import { update as updateBrandfolderResource } from '@api/v4/resources/resources';
import useDebounce from '@components/common/custom_hooks/useDebounce';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import languagesMap, { LanguageMapEntry, Locale } from '@components/common/language_menu/languagesMap';
import {
  GlobalLocaleType,
  WhitelistedLocalesData,
  WhitelistedLocalesMap,
  updateGlobalLocales,
} from '@components/settings/brandfolder/advanced-tab/locale-setting/helpers';
import UGTLocaleDefault from '@components/settings/brandfolder/advanced-tab/locale-setting/UGTLocaleDefault';
import WhitelistedLocales from '@components/settings/brandfolder/advanced-tab/locale-setting/WhitelistedLocales';

import '../styles/advanced-pane-styles.scss';

const { ugtLocaleDefault, whitelistedLocales } = BFG.locales;
const { key } = BF.fx.brandfolder();

const initialWhitelistedLocalesMap = (languages: LanguageMapEntry[]): WhitelistedLocalesMap => {
  const localesMap = {};
  languages.forEach((languageEntry) => {
    if (whitelistedLocales.includes(languageEntry.locale)) {
      localesMap[languageEntry.locale] = true;
    } else {
      localesMap[languageEntry.locale] = false;
    }
  });

  return localesMap;
};

const LocaleSettings = (): JSX.Element => {
  const [localeDefault, setUGTLocaleDefault] = useState(ugtLocaleDefault);
  const [whitelistedLocalesMap, setWhitelistedLocalesMap] = useState(initialWhitelistedLocalesMap(languagesMap));
  const [initialLoad, setInitialLoad] = useState(true);

  // store previous whitelistedLocalesMap in case update fails and we need to reset UI
  const [localeMapReset, setLocaleMapReset] = useState(initialWhitelistedLocalesMap(languagesMap));

  const debouncedWhitelistedLocalesMap = useDebounce({
    delay: 1500,
    value: whitelistedLocalesMap,
  });

  // create array of active locales for PUT payload
  const whitelistedLocalesData = (localesMap: WhitelistedLocalesMap): WhitelistedLocalesData => {
    const locales: WhitelistedLocalesData = [];
    Object.keys(localesMap)
      .map((locale: Locale) => { // eslint-disable-line array-callback-return
        if (localesMap[locale]) {
          locales.push(locale);
        }
      });
    return locales;
  };

  const handleUpdateWhitelistedLocales = (newWhitelistedLocalesMap: WhitelistedLocalesMap): void => {
    updateBrandfolderResource({
      key,
      whitelistedLocales: whitelistedLocalesData(newWhitelistedLocalesMap),
    })
      .then((): void => {
        setLocaleMapReset(newWhitelistedLocalesMap);
        updateGlobalLocales(GlobalLocaleType.Whitelisted, newWhitelistedLocalesMap);
        Notify.create({
          title: t`Preferred languages updated!`,
          type: 'success'
        });
      })
      .catch((error): void => {
        console.log(error);
        setWhitelistedLocalesMap(localeMapReset);
        Notify.create({
          title: t`Error updating preferred languages, please contact support`,
          type: 'error'
        });
      });
  };

  const handleUpdateLocaleDefault = (selectedLocaleDefault: Locale): void => {
    const previousUGTLocaleDefault = localeDefault;
    setUGTLocaleDefault(selectedLocaleDefault);

    // add selected locale default to whitelistedLocalesMap if it's not currently in the map
    if (!whitelistedLocalesMap[selectedLocaleDefault]) {
      setWhitelistedLocalesMap((prevState) => (
        {
          ...prevState,
          [selectedLocaleDefault]: true
        }
      ));
    }

    updateBrandfolderResource({
      key,
      ugtLocaleDefault: selectedLocaleDefault,
    })
      .then((): void => {
        updateGlobalLocales(GlobalLocaleType.Default, selectedLocaleDefault);
        Notify.create({
          title: t`Default language updated!`,
          type: 'success'
        });
      })
      .catch((error): void => {
        console.log(error);
        setUGTLocaleDefault(previousUGTLocaleDefault);
        Notify.create({
          title: t`Error updating default language, please contact support`,
          type: 'error'
        });
      });
  };

  useEffect(() => {
    if (initialLoad) { // avoid triggering a notify alert when debouncedWhitelistedLocalesMap first loads
      setInitialLoad(false);
    } else {
      handleUpdateWhitelistedLocales(debouncedWhitelistedLocalesMap);
    }
  }, [debouncedWhitelistedLocalesMap]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <I18nProviderWrapper>
      <div className="locale-settings-container">
        <UGTLocaleDefault
          handleUpdateLocaleDefault={handleUpdateLocaleDefault}
          localeDefault={localeDefault}
        />
        <WhitelistedLocales
          localeDefault={localeDefault}
          setWhitelistedLocalesMap={setWhitelistedLocalesMap}
          whitelistedLocalesMap={whitelistedLocalesMap}
        />
      </div>
    </I18nProviderWrapper>
  );
};

export default LocaleSettings;
