/* eslint-disable react/no-danger */
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import './styles/embedded_widgets_preview.scss';

/**
 * Check whether the iframe url is valid or not
 * TODO: use a more robust solution that checks more than app.smartsheet.com
 * @param {string | null} srcUrl string | null
 * @returns {boolean} boolean
 */
const isValidPreviewUrl = (srcUrl: string | null): boolean => srcUrl?.includes("app.smartsheet.com") || false;

interface EmbeddedWidgetsPreviewProps {
  srcUrl: string;
}

/**
 * Preview an embedded widget iframe
 * @param {EmbeddedWidgetsPreview} props EmbeddedWidgetsPreview
 */
export const EmbeddedWidgetsPreview: FunctionComponent<EmbeddedWidgetsPreviewProps> = ({ srcUrl }) => {
  if (!isValidPreviewUrl(srcUrl)) return null;

  return (
    <div className="embedded-preview">
      <p className="embedded-preview__heading"><Trans>Form preview</Trans></p>
      <iframe src={srcUrl} title={t`Preview`} />
    </div>
  );
};
