import fetchJSON from '@api/api_helper';

const getUrl = (attachmentKey) => `/api/v4/attachments/${attachmentKey}`;

export const getConfigurableExpiryUrl = (attachmentKey) => {
  return fetchJSON(`${getUrl(attachmentKey)}/configurable_expiry_url`)
    .then(({ data }) => data)
    .catch(async (response) => { throw (await response.json()); });
};

export const get = (attachmentKey, options = { params: {} }) => {
  const allOptions = {
    ...options,
    params: {
      ...options.params,
      manifest_digest: BFG.manifestDigest ?? null,
      resource_type: BFG.resource.type ?? null,
      resource_key: BFG.resource.key ?? null
    }
  }
  return fetchJSON(getUrl(attachmentKey), allOptions)
    .then(({ data }) => data)
    .catch(async (response) => { throw (await response.json()); });
};

const updateAttachment = (options) => {
  const url = getUrl(options.attachmentKey);
  const appendedOptions = {
    method: 'PUT',
    body: { data: options.data },
    context: options.context
  };

  return fetchJSON(url, appendedOptions);
};

export const deleteAttachment = (attachmentKey) => {
  const url = getUrl(attachmentKey);
  const appendedOptions = {
    method: 'DELETE'
  };

  return fetchJSON(url, appendedOptions);
};

export default updateAttachment;
