import { t, Trans } from '@lingui/macro';
import React, { useState, useEffect } from 'react';

import { get } from '@api/v4/resources/conversion_presets';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import PresetForm from './preset_form';
import PresetTable from './preset_table';

import './styles/cropping.scss';

export default () => {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const isCollection = Boolean(BF.fx.collection().key);
  const resourceType = isCollection ? 'collection' : 'brandfolder';
  const resourceFunc = isCollection ? BF.fx.collection : BF.fx.brandfolder;
  const { key, name: resourceName } = resourceFunc();

  const fetchPresets = async () => {
    setItems(await get({
      resourceType,
      resourceKey: key,
    }));
  };

  useEffect(() => {
    fetchPresets();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredItems = items.filter(({ name }) => name.includes(search));

  return (
    <I18nProviderWrapper>
      <div className="cropping">
        <h2>
          <Trans>Download Presets for {resourceName}</Trans>
        </h2>
        <hr />
        <PresetForm
          onAddPreset={fetchPresets}
          resourceKey={key}
          resourceType={resourceType}
        />
        <input
          className="bf-input searchbar"
          onChange={({ target }) => setSearch(target.value)}
          placeholder={t`Search for download presets`}
          type="text"
          value={search}
        />
        <PresetTable
          items={filteredItems}
          onPresetChange={fetchPresets}
        />
      </div>
    </I18nProviderWrapper>
  );
};
