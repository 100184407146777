import { t } from '@lingui/macro';
import StoryteqPlatform from '@storyteq/platform-integration';
import React, { useEffect, FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

interface StoryteqProps {
  displayElement: string;
  notificationText: string;
  redirectUrl: string;
  templateId: string;
  token: string;
}

const StoryteqContainer: FunctionComponent<StoryteqProps> = ({
  displayElement,
  notificationText,
  redirectUrl,
  templateId,
  token,
}) => {
  useEffect(() => {
    const createMediaPage = new StoryteqPlatform.createMediaPage( // eslint-disable-line new-cap
      displayElement,
      token,
      templateId,
      {
        assetManagerScope: [], // Scope the asset selection to a set of asset providers
        publish: [{ type: 'brandfolder' }], // Upon rendering completion, publish to this channel
      },
    );
    createMediaPage
      .on('loaded', () => console.log('loaded'))
      .on('created', (media) => {
        const templateName = media?.media?.name ?? 'Template';
        const title = t`Processing new video for ${templateName}.`;
        Notify.create({
          type: 'success',
          title,
          body: notificationText,
        });
        // We want timeout so user can:
        // 1. understand this is processing not complete (they'll see spinner)
        // 2. have time to view the notification and understand where the videos will be uploaded
        setTimeout(() => { window.location.href = redirectUrl; }, 4000);
      });
  }, [displayElement, notificationText, redirectUrl, templateId, token]);

  return (<I18nProviderWrapper></I18nProviderWrapper>);
};

export default StoryteqContainer;
