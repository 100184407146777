import React from 'react';
import PropTypes from 'prop-types';

export default class FetchControllersWrapper extends React.Component {
  state = {
    fetchControllers: [],
  }

  abortFetchControllers = () => {
    this.state.fetchControllers.forEach((controller) => {
      if (!controller.signal.aborted) {
        controller.abort();
      }
    });
  }

  updateFetchControllers = (controller) => {
    this.setState((prevState) => ({
      fetchControllers: [...prevState.fetchControllers, controller]
    }));
  }

  render() {
    const children = React.Children.map(this.props.children, (child) => (
      React.cloneElement(child, {
        abortFetchControllers: this.abortFetchControllers,
        updateFetchControllers: this.updateFetchControllers,
      })
    ));

    return (
      <React.Fragment>
        { children }
      </React.Fragment>
    );
  }
}

FetchControllersWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
