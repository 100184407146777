import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseAccordionProps, SingleAccordionProps } from '@components/library/accordion';
import { BaseAccordion } from '../base';

import classes from './styles/standard_accordions.module.scss';

type StandardAccordionProps = BaseAccordionProps;

/**
 * Standard accordions
 * @param {StandardAccordionProps} props StandardAccordionProps
 */
export const StandardAccordion: FunctionComponent<StandardAccordionProps> = (props) => {
  const accordions = props.accordions.map((accordion) => {
    const { buttonProps } = accordion;

    return ({
      ...accordion,
      buttonIcon: <span
        aria-label="Arrow"
        className={classnames("bff-caret-down", classes.icon)}
        role="img"
      />,
      buttonProps: {
        ...buttonProps,
        className: classnames(classes.accordionButton, buttonProps?.className)
      }
    } as SingleAccordionProps);
  });
  return (
    <BaseAccordion
      {...props}
      accordions={accordions}
    />
  );
};
