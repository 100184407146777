import { FontIcons } from '@brandfolder/react';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { DialogProvider } from '@components/common/providers/DialogContextProvider';

import './usage_info_card.scss';
import UsageInfoCardContent from './UsageInfoCardContent';

export interface Usage {
  current?: number;
  limit?: number | string;
}

export interface InfoCard {
  icon: FontIcons | string;
  title: string;
  usage: Usage;
  customClass?: string;
  dangerAlert?: string;
  hasSetLimits?: boolean;
  isEnabled?: boolean;
  link?: { name: string; linkTo: string };
  orgKey?: string;
  setLimitsAction?: () => void;
  subtitle?: string;
  warningAlert?: string;
}

const UsageInfoCard: FunctionComponent<InfoCard> = (card) => {
  const { title, customClass, isEnabled } = card;
  const cardWrapperClasses = classNames({
    usageInfoCard: true,
    isDisabled: !isEnabled,
    [customClass]: customClass
  });

  return (
    <I18nProviderWrapper>
      <DialogProvider>
        <div key={title} className={cardWrapperClasses}>
          <UsageInfoCardContent {...card} />
        </div>
      </DialogProvider>
    </I18nProviderWrapper>
  );
};

export default UsageInfoCard;
