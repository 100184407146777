import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { BFLoader } from '@components/common/loader/main';
import { TextButton } from '@components/library/button/index';

if (BFG.environment !== 'js-test') {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
}

interface PDFViewerProps {
  fileUrl: string;
  backupUrl: string;
}

const PDFViewer: FunctionComponent<PDFViewerProps> = ({ fileUrl, backupUrl }) => {
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasError, setHasError] = useState(false);

  const nextPage = (): void => {
    if (pageNumber === pageCount) {
      setPageNumber(1);
    } else {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const prevPage = (): void => {
    if (pageNumber === 1) {
      setPageNumber(pageCount);
    } else {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const pageNavigation = (
    <div
      className={classnames(
        'pdf-viewer-nav',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { 'pdf-viewer-nav__single-page': pageCount === 1 }
      )}
    >
      {pageCount > 1 && (
        <TextButton
          className="pdf-viewer-nav--button"
          icon="bff-arrow-left"
          onClick={prevPage}
        >
          <Trans>Previous</Trans>
        </TextButton>
      )}
      <span className='pdf-viewer-nav--page-count'>
        <Trans>Page {pageNumber} of {pageCount}</Trans>
      </span>
      {pageCount > 1 && (
        <TextButton
          className="pdf-viewer-nav--button"
          onClick={nextPage}
        >
          <>
            <Trans>Next</Trans>
            <span className="bff-arrow-right" />
          </>
        </TextButton>
      )}
    </div>
  );

  return (
    <div
      className="pdf-viewer--container"
      data-private
      data-testid="pdf-viewer--container"
    >
      {pageCount > 0 && pageNavigation}
      <Document
        file={hasError ? backupUrl : fileUrl}
        loading={<BFLoader />}
        onLoadError={(): void => setHasError(true)}
        onLoadSuccess={({ numPages }: { numPages: number }): void => setPageCount(numPages)}
      >
        <Page
          key={`page_${pageNumber}`}
          canvasBackground="white"
          loading={<BFLoader />}
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
    </div>
  );
};

export default PDFViewer;
