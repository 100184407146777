import React, { forwardRef, MutableRefObject } from 'react';

import { StandardTextFieldProps } from '@components/library/text-field/TextFieldProps';
import { TextFieldElement } from '@components/library/text-field/TextFieldTypes';
import { BaseTextField } from '../base';

import styleClasses from './standard-text-field.module.scss';

export const StandardTextField = forwardRef((
  props: StandardTextFieldProps & TextFieldElement,
  ref: MutableRefObject<HTMLInputElement | HTMLTextAreaElement>
) => {
  const baseProps = {
    ...props,
    styleClasses,
    variantName: 'standard',
  };

  return <BaseTextField {...baseProps} ref={ref} />;
});
