import { fetchJson } from '@api/ApiHelper';
import { Locale } from '@components/common/language_menu/languagesMap';
import { CustomFieldValuesListResponse } from '@api/v4/assets/customFieldTypes';

export const createCustomFieldValues = (
  values: { value: string; assetKey: string }[],
  customFieldKeyId: string,
  ugtLocale?: Locale | '',
  guestUploadLinkKey?: string
): Promise<CustomFieldValuesListResponse> => {
  const url = `/api/v4/custom_field_keys/${customFieldKeyId}/custom_field_values`;
  const formattedValuesWithAssets = values.map(({ value, assetKey }) => ({
    attributes: {
      value
    },
    relationships: {
      asset: {
        data: {
          type: 'assets',
          id: assetKey
        }
      }
    }
  }));
  return fetchJson({
    url,
    method: 'POST',
    body: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ugt_locale: ugtLocale || undefined,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      upload_link_key: guestUploadLinkKey || undefined,
      data: formattedValuesWithAssets
    }
  });
};
