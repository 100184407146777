import React, { useState, useEffect } from 'react';

import { AssetsResponse } from '@api/v4/assets/assetTypes';
import { getPortalCardData } from '@api/v4/private/portal_cards';
import { PortalCardSubTypes } from '@api/v4/private/PortalCardTypes';
import { RecentlyCreatedAssets } from './recently_created_assets'; // eslint-disable-line import/no-cycle
import { RecentlyUpdatedAssets } from './recently_updated_assets'; // eslint-disable-line import/no-cycle
import { TopScoredAssets } from './top_scored_assets'; // eslint-disable-line import/no-cycle
import { FeaturedTaggedAssets } from './featured_tagged_assets'; // eslint-disable-line import/no-cycle
import WidgetForm from './widget_form';

interface Widget {
  brandHexColor: string | undefined;
  editMode: boolean;
  name: string;
  libraryName: string;
  portalCardId: string;
  subType: PortalCardSubTypes;
  triggerCardsRefresh: () => void;
}

export interface WidgetContextTypes {
  brandHexColor: string | undefined;
  editMode: boolean;
  name: string;
  portalCardId: string | null;
  subType: PortalCardSubTypes | null;
  toggleForm: () => void;
  triggerCardsRefresh: () => void;
  widgetDataResponse: AssetsResponse | undefined;
}

const initialWidgetState: WidgetContextTypes = {
  brandHexColor: '',
  editMode: false,
  name: '',
  portalCardId: null,
  subType: null,
  toggleForm: () => {
    // do nothing
  },
  triggerCardsRefresh: () => {
    // do nothing
  },
  widgetDataResponse: undefined
};

export const WidgetsContext = React.createContext(initialWidgetState);

interface SubType {
  additionalFields: string;
  component: JSX.Element;
  sortBy: 'created_at' | 'score' | 'updated_at';
  order: 'ASC' | 'DESC';
}

interface SubTypes {
  [key: string]: SubType;
}

const subtypeMap: SubTypes = {
  featured_tagged_assets: { // eslint-disable-line @typescript-eslint/naming-convention
    component: <FeaturedTaggedAssets />,
    additionalFields: 'best_link_for',
    sortBy: 'score',
    order: 'DESC'
  },
  recently_created_assets: { // eslint-disable-line @typescript-eslint/naming-convention
    component: <RecentlyCreatedAssets />,
    additionalFields: 'best_link_for,created_at',
    sortBy: 'created_at',
    order: 'DESC'
  },
  recently_updated_assets: { // eslint-disable-line @typescript-eslint/naming-convention
    component: <RecentlyUpdatedAssets />,
    additionalFields: 'best_link_for,updated_at',
    sortBy: 'updated_at',
    order: 'DESC'
  },
  top_scored_assets: { // eslint-disable-line @typescript-eslint/naming-convention
    component: <TopScoredAssets />,
    additionalFields: 'best_link_for,score',
    sortBy: 'score',
    order: 'DESC'
  }
};

const Widget: React.FunctionComponent<Widget> = ({ // eslint-disable-line @typescript-eslint/no-redeclare
  brandHexColor,
  editMode,
  libraryName,
  name,
  portalCardId,
  subType,
  triggerCardsRefresh
}) => {
  const [widgetDataResponse, setWidgetDataResponse] = useState<AssetsResponse | undefined>(undefined);
  const [showForm, setShowForm] = useState(false);
  const toggleForm = (): void => setShowForm((prevState) => !prevState);
  const widgetSettings = subtypeMap[subType];

  useEffect(() => {
    const options = {
      portalCardId,
      params: {
        fields: widgetSettings.additionalFields,
        sort_by: widgetSettings.sortBy, // eslint-disable-line @typescript-eslint/naming-convention
        order: widgetSettings.order
      }
    };
    getPortalCardData(options)
      .then((response) => {
        setWidgetDataResponse(response);
      });
  }, [portalCardId, subType]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderWidget = (): JSX.Element => (
    <WidgetsContext.Consumer>
      {(widgetContext): JSX.Element => widgetContext && widgetSettings.component}
    </WidgetsContext.Consumer>
  );

  return (
    <>
      {showForm && (
        <WidgetForm
          closeModal={toggleForm}
          libraryName={libraryName}
          portalCardId={portalCardId}
          triggerCardsRefresh={triggerCardsRefresh}
        />
      )}
      <WidgetsContext.Provider
        value={{
          brandHexColor,
          editMode,
          name,
          portalCardId,
          subType,
          toggleForm,
          triggerCardsRefresh,
          widgetDataResponse
        }}
      >
        {renderWidget()}
      </WidgetsContext.Provider>
    </>
  );
};

export default Widget;
