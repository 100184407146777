import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { Privacy } from '@api/v4/resources/resourceTypes';

export type PortalResponse = ApiDatumResponse<BackendPortal>

export interface Portal {
  name: string;
  brandHexColor?: string;
  description?: string;
  headerHexColor?: string;
  headerNameOverlay?: boolean;
  headerUrl?: string;
  privacy?: Privacy;
  searchUrl?: string;
  slug?: string;
}

export interface BackendPortal {
  name: string;
  brand_hex_color?: string;
  description?: string;
  header_hex_color?: string;
  header_name_overlay?: boolean;
  header_url?: string;
  privacy?: Privacy;
  search_url?: string;
  slug?: string;
}

export enum ValidationErrors {
  DescriptionLengthExceeded="Description length exceeded",
  InvalidBrandHexColor="Invalid brand hex color",
  InvalidHeaderHexColor="Invalid header hex color",
  InvalidSearchUrl="Invalid search url",
  InvalidSlug="Invalid slug",
  MissingName="Name required",
  MissingPrivacy="Missing privacy",
  MissingSlug="Slug required",
  NameLengthExceeded="Name length exceeded",
}
