import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { TextWarningButton } from '@components/library/button';

import CustomFieldsSelector from './customfieldsSelector';

const CustomfieldsFilter = (props) => {
  const [customFieldKeys, setCustomFieldKeys] = useState([]);
  const { customFields, removeFilters, searchableThings, selectedUGTLocale, updateFilters } = props;

  useEffect(() => {
    if (searchableThings.custom_fields !== undefined) {
      setCustomFieldKeys(searchableThings.custom_fields);
    }
  }, [searchableThings]);

  const flattenCustomFields = () => {
    const customFieldsFlat = [];

    Object.keys(customFields).forEach((customFieldKey) => {
      const values = customFields[customFieldKey];
      values.forEach((customFieldValue) => {
        customFieldsFlat.push({ key: customFieldKey, value: customFieldValue });
      });
    });

    return customFieldsFlat;
  };

  const updateCustomFields = (key, keyvalue) => {
    const visibleCustomFields = [...flattenCustomFields()];

    const hasCustomField = visibleCustomFields.filter((customField) => (
      customField.key === key && customField.value === keyvalue.replace('"', '\\"')
    ));

    if (hasCustomField.length === 0) {
      visibleCustomFields.push({ key, value: keyvalue });
      updateFilters(key, keyvalue);
    }
  };

  if (customFieldKeys.length <= 0) { return null; }

  return (
    <div className="filter-section custom-fields">
      <div className="flex-row title">
        <p><Trans>Custom Fields</Trans></p>
      </div>
      {Object.keys(customFields).length > 0 && (
        <div className="custom-field-rows">
          {flattenCustomFields().map((customField) => (
            <div key={customField.value} className="flex-row">
              <p>{customField.key}</p>
              <p>{customField.value.replace('\\"', '"')}</p>
              <TextWarningButton
                className="remove-filter-button"
                onClick={() => removeFilters(customField.key, customField.value.replace('\\"', '"'))}
              >
                <span className="bff-close" />
              </TextWarningButton>
            </div>
          ))}
        </div>
      )}
      <CustomFieldsSelector
        advancedFiltersContainerRef={props.advancedFiltersContainerRef}
        customFieldKeys={customFieldKeys}
        selectedUGTLocale={selectedUGTLocale}
        updateCustomFields={updateCustomFields}
      />
    </div>
  );
};

CustomfieldsFilter.propTypes = {
  advancedFiltersContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  customFields: PropTypes.shape({}).isRequired,
  removeFilters: PropTypes.func.isRequired,
  searchableThings: PropTypes.shape({}).isRequired,
  selectedUGTLocale: PropTypes.string.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default CustomfieldsFilter;
