import { t } from '@lingui/macro';
import React from 'react';

import {
  Locale
} from '@components/common/language_menu/languagesMap';
import LanguageMenuDropdown from '@components/common/language_menu/LanguageMenuDropdown';
import {
  UpdateUserViewOptions,
  UserViewOptionsKeys,
} from '@components/show_page/sections/view_options/ViewOptionTypes';

interface ViewOptionsLanguagesProps {
  activeLocale: Locale;
  updateUserViewOptions: UpdateUserViewOptions;
}

const ViewOptionsLanguages = ({
  activeLocale,
  updateUserViewOptions,
}: ViewOptionsLanguagesProps): JSX.Element => {
  const handleUpdateLocale = (locale: Locale): boolean => {
    // only update locale IF selected locale is different from active locale
    if (locale !== activeLocale) {
      updateUserViewOptions(UserViewOptionsKeys.SelectedUGTLocale, locale);
      return true;
    }

    return false;
  };

  return (
    <LanguageMenuDropdown
      copy={t`Display asset info and tags in one of the following languages.`}
      defaultLocale={BFG.locales.ugtLocaleDefault}
      onLocaleChange={handleUpdateLocale}
      openOnClick={false}
      selectedLocale={activeLocale}
    />
  );
};

export default ViewOptionsLanguages;
