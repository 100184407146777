import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { OnlyForScreenReaderProps } from '@components/library/screen-reader';
import { BaseScreenReader } from '../base';
import classes from './only-for-screen-reader.module.scss';

export const OnlyForScreenReader: FunctionComponent<OnlyForScreenReaderProps> = (
  props
) => (
  <BaseScreenReader
    {...props}
    className={classnames(
      classes['only-for-screen-reader'],
      props.className
    )}
    variantName="only-for"
  />
);
