import {
  ButtonLooks,
  FontIcon,
  FontIcons,
  HelpText,
  NextPreviousPagination,
  OnlyForScreenReader,
  StandardButton,
  StandardLink,
  StandardTable,
  StandardTableColumn,
  StandardTableRow,
} from '@brandfolder/react';
import { localizeDate, localizeDateTime } from '@brandfolder/utilities';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import { ApiDataResponse, ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { defaultKnowledgeBaseLinks, gettyKnowledgeBaseLinks } from '@components/kb-article-links';
import { standardError } from '@translations';

import { categoryEndUserString, changeAgentEndUserString, eventTypeEndUserString, PEOPLE_TAGGING_EMAIL } from './asset-event-translations';
import { AssetEvent, Category, EventType } from './AssetEventTypes';
import { timeDifferenceInDays } from './time-difference-days';
import { isGettyClient } from '@helpers/getty-strings';

import './styles/_asset_history_log_table.scss';

interface AssetHistoryLogTableProps {
  active: boolean;
  assetKey: string;
  showTitle?: boolean;
}

let knowledgeBaseUrl;

if (isGettyClient()) {
  knowledgeBaseUrl = gettyKnowledgeBaseLinks.historyLogLink
} else {
  knowledgeBaseUrl = defaultKnowledgeBaseLinks.historyLogLink
};

const localDevToken = ''; // replace me with BF_Token from production Brandfolder browser console (DO NOT COMMIT)
const localDevAssetKey = ''; // replace me with a production Brandfolder asset key
const localDevBrandfolderKey = ''; // replace me with localDevAssetKey's parent Brandfolder
const localDevelopmentFetchOptions: UseFetchOptions = {
  url: `${BFG.ASSET_HISTORY_SVC_BASE_URL}/api/v0/assets/${localDevAssetKey}/events`,
  customToken: localDevToken,
  headers: {
    /* eslint-disable @typescript-eslint/naming-convention */
    'X-Brandfolder-Resource-Type': 'brandfolders',
    'X-Brandfolder-Resource-Key': localDevBrandfolderKey
    /* eslint-enable @typescript-eslint/naming-convention */
  }
};
const localDevHint = 'Developing locally? Make sure to fill out localDevToken, localDevAssetKey, and localDevBrandfolderKey to point to prod in AssetHistoryLogTable.tsx';

const HISTORY_TABLE_PAGE_SIZE = 15;

export const AssetHistoryLogTable: FunctionComponent<AssetHistoryLogTableProps> = ({
  active,
  assetKey,
  showTitle = false
}) => {
  const [rows, setRows] = useState<StandardTableRow[]>();
  const [page, setPage] = useState(1);
  const [previousCursors, setPreviousCursors] = useState<string[]>([]);
  const [nextCursor, setNextCursor] = useState('');
  const [refresh, setRefresh] = useState(false);
  const localDevelopment = process.env.NODE_ENV === 'development';
  const fetchOptions: UseFetchOptions = localDevelopment ? localDevelopmentFetchOptions : {
    url: `${BFG.ASSET_HISTORY_SVC_BASE_URL}/api/v0/assets/${assetKey}/events`,
    headers: {
      /* eslint-disable @typescript-eslint/naming-convention */
      'X-Brandfolder-Resource-Type': `${BFG.resource?.type}s`,
      'X-Brandfolder-Resource-Key': BFG.resource?.key
    }
  };
  const params = {
    ...nextCursor && { 'page[next_cursor]': nextCursor },
    'page[limit]': HISTORY_TABLE_PAGE_SIZE
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  const { error, fetch, loading, response } = useFetch<ApiDataResponse<AssetEvent, 'asset_events'>>({
    ...fetchOptions,
    fetchOnMount: false,
    params,
    queuePriorityHigh: false // this isn't a boulder API, we don't want to tack on queue priority
  });

  const getLastEvent = (): ApiResponseObject<AssetEvent, 'asset_events'> => {
    if (!response) return null;

    return response.data[response.data.length - 1];
  };

  const previousPage = (): void => {
    const cursors = [...previousCursors];
    const previousCursor = cursors.pop();
    setNextCursor(previousCursor);
    setPreviousCursors(cursors);
  };

  const nextPage = (): void => {
    setPreviousCursors([...previousCursors, nextCursor]);
    setNextCursor(getLastEvent()?.attributes.event_time);
  };

  const handlePageChange = (newPage: number): void => {
    setPage(newPage);
    if (response) {
      if (newPage > page) {
        nextPage();
      } else if (newPage < page) {
        previousPage();
      }
    }
  };

  const past180Days = (): boolean => {
    const lastEvent = getLastEvent();
    if (!lastEvent) return false;
    return timeDifferenceInDays(new Date(lastEvent.attributes.event_time), new Date(Date.now())) > 180;
  };

  const noHistory = (): boolean => (
    // rows here (not response.data) because it is no history if all the history
    // is just index only events
    rows?.length === 0 && previousCursors.length === 0
  );

  const endOfHistory = (): boolean => (
    /**
     * partial page OR
     * we happened to get an amount of data exactly divisble by page size
     * and we didn't know we had nothing else until we requested this page
     *
     * if the first page has no visible rows then we're in the No History case
     * which is covered by noHistory()
     */
    (response?.data.length < HISTORY_TABLE_PAGE_SIZE && rows?.length > 0)
    || (rows?.length === 0 && previousCursors.length > 0)
  );

  useEffect(() => {
    if (nextCursor !== undefined && active) {
      fetch();
    }
  }, [active, nextCursor]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (response) {
      const noIndexEvents = response.data.filter(({ attributes: { category } }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        category !== Category.IndexOnly
      ));
      const responseRows = noIndexEvents.map((assetEvent) => {
        const {
          category,
          event_type: eventType,
          change_agent: changeAgent,
          actor_email: actorEmail,
          event_time: eventTime
        } = assetEvent.attributes;

        const createdDeleted = eventType === EventType.Create || eventType === EventType.Delete
          ? ` - ${eventTypeEndUserString(eventType)}`
          : '';

        const actorEmailExcludePeopleTagging = actorEmail === PEOPLE_TAGGING_EMAIL ? undefined : actorEmail;

        return ({
          update: `${categoryEndUserString(category)}${createdDeleted}`,
          source: changeAgentEndUserString(changeAgent, actorEmail) || '-',
          user: actorEmailExcludePeopleTagging || '-',
          date: localizeDate(eventTime),
          time: localizeDateTime(eventTime, undefined, {
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short'
          })
        });
      });
      setRows(responseRows);
      setRefresh(false);
    }
  }, [response]);

  useEffect(() => {
    setRefresh(false);
  }, [error]);

  const columns: StandardTableColumn[] = [{
    rowKey: 'update',
    children: t`Update`
  }, {
    rowKey: 'source',
    children: t`Source`
  }, {
    rowKey: 'user',
    children: t`User`
  }, {
    rowKey: 'date',
    children: t`Date`
  }, {
    rowKey: 'time',
    children: t`Time`
  }];

  const renderFooterConter = (): ReactNode => {
    const endResults = t`End of results.`;
    const timePeriodHint = t`Asset history is limited to 180 days.`;
    let helpText: ReactNode = (
      <>
        <FontIcon icon={FontIcons.Warning} />
        {timePeriodHint}
      </>
    );

    if (past180Days()) {
      helpText = `${endResults} ${timePeriodHint}`;
    }

    if (endOfHistory()) {
      helpText = endResults;
    }

    return (
      <div className="asset-history-log__footer">
        <HelpText className="asset-history-log__help-text" id="asset-history-log__help-text">
          {helpText}
        </HelpText>
      </div>
    );
  };

  const nextDisabled = response && (response.data.length < HISTORY_TABLE_PAGE_SIZE || past180Days());
  const firstPage = previousCursors.length === 0;

  return (
    <div className="asset-history-log">
      <StandardTable
        caption={t`Asset History`}
        columns={columns}
        empty={noHistory()}
        emptyContent={(
          <span className="asset-history-log__empty">
            <Trans>No history available.</Trans>&nbsp;
            <StandardLink href={knowledgeBaseUrl} target='_blank'>
              <Trans>Learn more.</Trans>
              <OnlyForScreenReader>
                <Trans>Opens in a new tab</Trans>
              </OnlyForScreenReader>
            </StandardLink>
          </span>
        )}
        error={!!error}
        errorContent={localDevelopment ? localDevHint : standardError()}
        footer={response && !error && !loading}
        footerContent={renderFooterConter()}
        id={'asset-history-log-table'}
        loaderLabel={t`Loading asset history`}
        loading={loading}
        rows={rows || []}
        scrollY
        showCaption={showTitle}
      />
      {response && (
        <div className={classNames({
          /* eslint-disable @typescript-eslint/naming-convention */
          'asset-history-log__button-container': true,
          'asset-history-log__button-container--shift-left': !nextDisabled,
          'asset-history-log__button-container--with-refresh': firstPage
          /* eslint-enable @typescript-eslint/naming-convention */
        })}>
          {!error && !noHistory() && (
            <NextPreviousPagination
              buttonProps={{
                look: ButtonLooks.TextSecondary
              }}
              disabled={loading}
              labels={{
                nextLabel: t`Next`,
                previousLabel: t`Previous`
              }}
              nextDisabled={nextDisabled}
              onPageChange={handlePageChange}
            />
          )}
          {firstPage && (
            <StandardButton
              className="asset-history-log__refresh-button"
              disabled={loading && refresh}
              look={ButtonLooks.Primary}
              onClick={(): void => {
                setRefresh(true);
                fetch();
              }}
              startIcon={(loading && refresh) ? FontIcons.RefreshSpinning : FontIcons.Refresh}
            >
              <Trans>Refresh</Trans>
            </StandardButton>
          )}
        </div>
      )}
    </div>
  );
};
