import { plural, t, Plural, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect } from 'react';

import { useFetch } from '@api/ApiHelper';
import { ShareManifestPutResponse } from '@api/v4/share_manifests/ShareManifestTypes';
import { DangerButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';

import classes from './styles/bulk_share_link_asset_removal_modal.module.scss';

interface BulkShareLinkAssetRemovalProps {
  assetKeys: string[];
  galleryView: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const BulkShareLinkAssetRemovalModal: FunctionComponent<BulkShareLinkAssetRemovalProps> = ({
  assetKeys,
  galleryView = false,
  open,
  setOpen,
}) => {
  const removeAssetsFetch = useFetch<ShareManifestPutResponse>({
    body: {
      data: {
        attributes: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          asset_keys: assetKeys
        }
      }
    },
    fetchOnMount: false,
    method: 'POST',
    url: `/api/v4/share_manifests/${BFG.manifestDigest}/remove_assets`
  });
  let dialogTitle;
  const assetCount = assetKeys.length;

  if (galleryView === false) {
    dialogTitle = plural(assetCount, {
      one: 'Remove asset',
      other: 'Remove assets'
    });
  } else {
    dialogTitle = t`Remove all attachments from asset`;
  }

  useEffect(() => {
    if (open) {
      sendAction(TrackedAction.ShareLinkAssetRemoval);
    }
  }, [open]);

  useEffect(() => {
    if (removeAssetsFetch.error) {
      Notify.create({
        title: t`There was an error removing assets from your Share Link. Please try again.`,
        type: 'error'
      });
      sendAction('Error removing assets', {
        tags: {},
        extra: { assetKeys }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeAssetsFetch.error]);

  useEffect(() => {
    if (removeAssetsFetch.response) {
      Notify.create({
        title: t`Successfully removed assets from your Share Link. Reloading...`,
        type: 'success'
      });
      window.location.reload();
    }
  }, [removeAssetsFetch.response, setOpen]);

  return (
    <StandardDialog
      dialogBodyClassName={classes['bulk-share-links-removal']}
      height={370}
      id="bulk-share-links-asset-removal-dialog"
      open={open}
      setOpen={setOpen}
      showFooter={false}
      title={dialogTitle}
      titleIcon="bff-trash"
      width={660}
    >
      <div className={classes['bulk-share-links-removal']}>
        { galleryView === false && (
          <>
            <h3 className={classes['bulk-share-links-removal__heading']}>
              <Plural
                one={
                  <Trans>
                    Are you sure you want to remove <strong>{assetCount} asset</strong> from your Share Link?
                  </Trans>
                }
                other={
                  <Trans>
                    Are you sure you want to remove <strong>{assetCount} assets</strong> from your Share Link?
                  </Trans>
                }
                value={assetCount}
              />
            </h3>
          </>
        )}
        {galleryView === true && (
          <>
            <h3 className={classes['bulk-share-links-removal__heading']}>
              <Trans>
                Are you sure you want to remove&nbsp;
                <strong className={classes['bulk-share-links-removal__strong']}>
                  all attachments
                </strong>&nbsp;
                from this asset?
              </Trans>
            </h3>
          </>
        )}
        <p className={classes['bulk-share-links-removal__hint']}>
          <Plural
            one="This asset will no longer be accessible to users from the Share Link."
            other="These assets will no longer be accessible to users from the Share Link."
            value={assetCount}
          />
        </p>
        <div className={classes['bulk-share-links-removal__buttons-container']}>
          <DangerButton
            className={classes['bulk-share-links-removal__remove-button']}
            disabled={!!removeAssetsFetch.response}
            icon="bff-trash"
            isLoading={removeAssetsFetch.loading}
            loadingCopy={plural(assetCount, {
              one: 'Removing asset',
              other: 'Removing assets'
            })}
            onClick={(): void => { removeAssetsFetch.fetch(); }}
          >
            <Plural
              one="Remove asset"
              other="Remove assets"
              value={assetCount}
            />
          </DangerButton>
        </div>
      </div>
    </StandardDialog>
  );
};
