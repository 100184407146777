import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTFootProps, BaseTFoot } from '../../base';

import classes from '../styles/selectable-table.module.scss';

export const TFoot: FunctionComponent<BaseTFootProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <BaseTFoot
      {...otherProps}
      className={classnames('selectabeTfoot', classes.tfoot, props.className)}
    >
      {children}
    </BaseTFoot>
  );
};
