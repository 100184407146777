import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import './styles/tooltip.scss';

const Tooltip = (props) => (
  <div className={`tooltip-component ${props.className}`}>
    <span
      data-delay-show={props.delayShow}
      data-delay-hide={props.delayHide}
      data-effect={props.effect}
      data-event={`mouseenter ${props.clickable ? "focus click" : ""}`}
      data-event-off={`mouseleave ${props.clickable ? "keypress click" : ""}`}
      data-for={props.tooltipId}
      data-offset={props.offset}
      data-place={props.placement}
      data-tip=""
      tabIndex={props.clickable ? 0 : undefined}
      role="button"
    >
      {props.children}
    </span>
    <ReactTooltip id={props.tooltipId} type={props.type} clickable={props.clickable}>
      <span className="tooltip-red">
        {props.tooltipContent}
      </span>
    </ReactTooltip>
  </div>
);

export default Tooltip;

Tooltip.defaultProps = {
  className: '',
  clickable: false,
  delayShow: 0,
  delayHide: 0,
  effect: 'solid', // options: solid, float
  offset: null, // e.g. `{'left': 10}` (syntax is important here)
  placement: 'top',
  tooltipContent: '',
  tooltipId: '',
  type: 'dark', // options: dark, light
};

Tooltip.propTypes = {
  className: PropTypes.string,
  clickable: PropTypes.bool,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  effect: PropTypes.string,
  offset: PropTypes.string,
  placement: PropTypes.string,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tooltipId: PropTypes.string,
  type: PropTypes.string,
};
