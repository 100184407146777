import { t, Trans } from '@lingui/macro';
import { DatePickerPlacements, StandardDatePicker } from '@brandfolder/react';
import { Field, Form, Formik } from 'formik';
import { decode } from 'html-entities';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { object, SchemaOf, string } from 'yup';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import { ApiDatum } from '@api/v4/ApiResponseTypes';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { PrimaryButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { FontIcons } from '@components/library/icon';
import { StandardTextField } from '@components/library/text-field';
import { toKebabCase } from '@components/library/utils';
import { TextSanitizationTooltipLabel } from '@components/shared/sanitization';
import { getStandardDatePickerLabels } from '@translations';

import './styles/workspace-create-form.scss';

import { sendAction, TrackedAction } from '@helpers/datadog-rum';

const createWorkspaceUrl = `/api/v4/brandfolders/${BFG.brandfolder_key}/collections`;
const createWorkspaceOptions = ({ dueDate, name, slug, tagline }): UseFetchOptions => ({
  body: {
    data: {
      attributes: {
        ...dueDate && { due_date: dueDate },
        is_workspace: true,
        name,
        slug,
        ...tagline && { tagline },
      },
    },
  },
  fetchOnMount: false,
  method: 'POST',
  url: createWorkspaceUrl
});

interface WorskpaceFormValues {
  dueDate: string;
  name: string;
  slug: string;
  tagline: string;
}

enum Fields {
  DueDate = 'dueDate',
  Name = 'name',
  Slug = 'slug',
  Tagline = 'tagline'
}

interface WorkspaceAttributes extends WorskpaceFormValues {
  isWorkspace: boolean;
  public: boolean;
  stealth: boolean;
}

// switch from camelcase to snake case to interface with server
type WorkspaceFormValuesOmitted = Omit<WorkspaceAttributes, 'isWorkspace' | 'dueDate'>;
interface WorkspaceAttributesServer extends WorkspaceFormValuesOmitted {
  due_date: string;
  is_workspace: boolean;
}

type CreateWorkspaceResponse = ApiDatum<WorkspaceAttributesServer, 'collections'>;

export const errorMessages = (): { nameRequired: string, nameTooShort: string, nameTooLong: string, slugInvalid: string, slugRequired: string, slugTooShort: string, slugTooLong: string } => ({
  nameRequired: t`A valid name is required`,
  nameTooShort: t`Name is too short (minimum is 3 characters)`,
  nameTooLong: t`Name is too long (maximum is 50 characters)`,
  slugInvalid: t`A valid slug is required (no spaces or symbols)`,
  slugRequired: t`A valid slug is required`,
  slugTooShort: t`Slug is too short (minimum is 2 characters)`,
  slugTooLong: t`Slug is too long (maximum is 50 characters)`
});

// allow letters, numbers, dashes and underscores for slug
const validUrlPathRegex = /^[A-Za-z0-9_-]*$/;
// disallow anything other than letters, numbers dashes and underscores for slug
const invalidUrlPathRegex = /[^A-Za-z0-9_-]/g;

const validationSchema: SchemaOf<WorskpaceFormValues> = object().shape({
  dueDate: string(),
  name: string()
    .min(3, errorMessages().nameTooShort)
    .max(50, errorMessages().nameTooLong)
    .required(errorMessages().nameRequired),
  slug: string()
    .min(2, errorMessages().slugTooShort)
    .max(50, errorMessages().slugTooLong)
    .matches(validUrlPathRegex, errorMessages().slugInvalid)
    .required(errorMessages().slugRequired),
  tagline: string()
    .max(500),
});

export interface WorkspaceCreateFormProps {
  brandfolderSlug: string;
  isOpenDialog: boolean;
  setIsOpenDialog: SetStateDispatch<boolean>;
}

export const WorkspaceCreateForm: FunctionComponent<WorkspaceCreateFormProps> = ({
  brandfolderSlug,
  isOpenDialog,
  setIsOpenDialog
}) => {
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [name, setName] = useState('');
  const [networkSlugError, setNetworkSlugError] = useState('');
  const createWorkspaceResponse = useFetch<CreateWorkspaceResponse>({ fetchOnMount: false, url: createWorkspaceUrl });

  const handleReset = (): void => {
    setSubmitAttempted(false);
    setName('');
    setNetworkSlugError('');
  };

  const handleSubmit = (values: WorskpaceFormValues): void => {
    setNetworkSlugError('');
    setName(values.name); // store 'name' in state so success message can use it
    createWorkspaceResponse.fetch(createWorkspaceOptions(values));
  };

  const nameToSlug = (nameValue: string): string => toKebabCase(nameValue).replace(invalidUrlPathRegex, '');

  useEffect(() => {
    if (isOpenDialog && createWorkspaceResponse.response) {
      sendAction(TrackedAction.WorkspaceCreateSuccess);
      Notify.create({
        body: `${t`Add assets to`} ${name} ${t`from`} ${decode(BFG.resource.name)} ${t`using bulk selection`}.`,
        title: `${name} ${t`created`}!`,
        type: 'success',
      });
      window.location.reload();
    }
  }, [createWorkspaceResponse.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOpenDialog) return;
    if (createWorkspaceResponse.error && createWorkspaceResponse.error?.errors?.toLowerCase()?.includes('slug')) {
      sendAction(TrackedAction.WorkspaceCreateError);
      setNetworkSlugError(createWorkspaceResponse.error?.errors);
    } else if (createWorkspaceResponse.error) {
      sendAction(TrackedAction.WorkspaceCreateError);
      setNetworkSlugError('');
      Notify.create({
        body: t`please try again or contact support`,
        title: t`Error creating Workspace`,
        type: 'error',
      });
    }
  }, [createWorkspaceResponse.error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StandardDialog
      dialogBodyClassName="workspace-create-form"
      id="workspace-create-form"
      onClose={handleReset}
      open={isOpenDialog}
      setOpen={setIsOpenDialog}
      showFooter={false}
      title={t`Create new Workspace`}
      titleIcon={`bff-${FontIcons.Plus}`}
      width={600}
    >
      <Formik
        initialValues={{ dueDate: '', name: '', slug: undefined, tagline: '' }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={submitAttempted}
        validationSchema={validationSchema}
      >
        {(formikBag): JSX.Element => {
          const { errors, setFieldValue, submitCount, values } = formikBag;

          // will only fire once after first submit
          // submitAttempted used to run validations only after a submission attempt
          if (isOpenDialog && !submitAttempted && submitCount === 1) setSubmitAttempted(true);

          return (
            <Form className="workspace-create-form__container">
              <Field name={Fields.Name}>
                {({ field }): JSX.Element => (
                  <StandardTextField
                    {...field}
                    error={errors.name}
                    id="workspace-name"
                    label={<Trans>Workspace Name</Trans>}
                    name={Fields.Name}
                    placeholder=""
                  />
                )}
              </Field>
              <Field name={Fields.Slug}>
                {({ field }): JSX.Element => (
                  <StandardTextField
                    {...field}
                    error={errors.slug || networkSlugError}
                    id="workspace-url"
                    label={<Trans>Workspace URL</Trans>}
                    name={Fields.Slug}
                    onChange={(e: React.SyntheticEvent): void => {
                      field.onChange(e);
                      if (networkSlugError) setNetworkSlugError('');
                    }}
                    onFocus={(): void => { if (field.value === undefined) setFieldValue(Fields.Slug, nameToSlug(values.name)); }}
                    placeholder=""
                    prefixed={`${brandfolderSlug}/`}
                    value={field.value === undefined ? nameToSlug(values.name) : field.value}
                  />
                )}
              </Field>
              <Field name={Fields.DueDate}>
                {(): JSX.Element => (
                  <StandardDatePicker
                    allowPastDates={false}
                    id="workspace-due-date"
                    labels={getStandardDatePickerLabels()}
                    locale={determineUGTLocale()}
                    onSelection={(date): void => { setFieldValue(Fields.DueDate, date.toISOString()); }}
                    showLabel
                    useLayerOptions={{
                      placement: DatePickerPlacements.RightStart
                    }}
                  />
                )}
              </Field>
              <Field name={Fields.Tagline}>
                {({ field }): JSX.Element => (
                  <StandardTextField
                    {...field}
                    className="workspace-create-form__textarea"
                    error={errors.tagline}
                    id="workspace-description"
                    label={<TextSanitizationTooltipLabel><Trans>Description</Trans></TextSanitizationTooltipLabel>}
                    multiLine
                    placeholder={t`Add a detailed description`}
                  />
                )}
              </Field>
              <div className="workspace-create-form__button">
                <PrimaryButton
                  isLoading={createWorkspaceResponse.loading}
                  loadingCopy={t`Creating...`}
                  onFocus={(): void => {
                    // set slug value if the user hasn't touched the slug input field
                    // this is the case when the user submits the 'suggested' slug based on the inputted name
                    if (values.slug === undefined) setFieldValue(Fields.Slug, nameToSlug(values.name));
                  }}
                  size="medium"
                  type="submit"
                >
                  <Trans>Create Workspace</Trans>
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </StandardDialog>
  );
};
