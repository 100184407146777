import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTBodyProps } from '../BaseTableProps';

export const BaseTBody: FunctionComponent<BaseTBodyProps> = (props) => {
  const { children, className, ...otherProps } = props;
  return (
    <tbody {...otherProps} className={classnames('baseTbody', className)}>{children}</tbody>
  );
};
