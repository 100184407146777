import React, { forwardRef, MutableRefObject, ReactNode } from 'react';
import classnames from 'classnames';

import { BaseTextFieldProps } from '@components/library/text-field/TextFieldProps';
import { isInput, isTextArea, InputType, LabelPosition, TextFieldElement } from '@components/library/text-field/TextFieldTypes';

const baseName = 'text-field';

export const BaseTextField = forwardRef(({
  className,
  disabled,
  error,
  id,
  label,
  labelPosition = LabelPosition.Top,
  multiLine,
  placeholder = 'input value...',
  prefixed,
  required,
  showError = true,
  showLabel = true,
  styleClasses,
  tooltip,
  type = InputType.Text,
  variantName,
  ...textFieldProps
}: BaseTextFieldProps & TextFieldElement, refProp: MutableRefObject<HTMLInputElement | HTMLTextAreaElement>) => {
  const displayError = !!error && showError;

  const staticTextFieldProps = {
    'aria-describedby': displayError ? `${id}_error` : undefined,
    'aria-label': !showLabel ? label as string : undefined,
    'aria-invalid': displayError || undefined,
    className: classnames(
      styleClasses[`${variantName}--input`],
      `${variantName}--input`,
      {
        [styleClasses.error]: displayError
      }
    ),
    id,
    placeholder,
  };

  const labelHTML = (): ReactNode => (
    <label
      className={classnames(
        styleClasses[`${variantName}--label`],
        `${variantName}--label`,
      )}
      htmlFor={id}
    >
      {label}
      {required && <span className={styleClasses['required-asterix']}>*</span>}
      {tooltip && <span className={styleClasses.tooltip}>{tooltip}</span>}
    </label>
  );

  const textFieldHTML = (): ReactNode => {
    if (isTextArea(multiLine, textFieldProps)) {
      return (
        <textarea
          {...staticTextFieldProps}
          {...textFieldProps}
          ref={refProp as MutableRefObject<HTMLTextAreaElement>}
          aria-required={required}
          disabled={disabled}
        />
      );
    }

    if (isInput(multiLine, textFieldProps)) {
      return (
        <input
          {...staticTextFieldProps}
          {...textFieldProps}
          ref={refProp as MutableRefObject<HTMLInputElement>}
          aria-required={required}
          disabled={disabled}
          type={type}
        />
      );
    }

    return null;
  };

  const errorHTML = (): ReactNode => (
    <span
      className={`${styleClasses['error-container']} error-container`}
      id={`${id}_error`}
      role="alert"
    >
      {error}
    </span>
  );

  return (
    <div className={classnames(
      `bf-${baseName}__${variantName}`, // base-variant class for post consumption styling
      styleClasses[variantName], // class for css modules
      className, // user specified class
      [styleClasses[labelPosition]],
      {
        [styleClasses.disabled]: disabled,
        [styleClasses.error]: displayError,
        [styleClasses['no-label']]: !showLabel,
      }
    )}
    >
      {labelPosition === LabelPosition.Top && (
        <>
          {(showLabel || showError) && (
            <div className={`${styleClasses['top-container']} top-container`}>
              {showLabel && labelHTML()}
              {displayError && errorHTML()}
            </div>
          )}
          {prefixed ? (
            <div className={classnames(
              styleClasses[`${variantName}--prefix`],
              `${variantName}--prefix`,
            )}
            >
              <span>{prefixed}</span>
              {textFieldHTML()}
            </div>
          ) : textFieldHTML()}
        </>
      )}
      {labelPosition === LabelPosition.Left && (
        <>
          {showError && errorHTML()}
          <div className="bottom-container">
            {showLabel && labelHTML()}
            {textFieldHTML()}
          </div>
        </>
      )}
    </div>
  );
});
