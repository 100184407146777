import { sanitize } from '@brandfolder/utilities';
import { t, Plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ReactTags from 'react-tag-autocomplete';

import { BFLoader } from '@components/common/loader/main';
import { PrimaryButton, TertiaryButton } from '@components/library/button/index';
import { bulkRefreshSections, pluralizeObject, Resource } from '@helpers/show_page_helpers';

import { unsavedChangesOptions } from '@helpers/sweet_alert_options';

const TagComponent = (props) => useMemo(() => (
  <div className={props.classNames.selectedTag}>
    <span className={props.classNames.selectedTagName}>{props.tag.name}</span>
    <button
      className="react-tags__remove"
      onClick={props.onDelete}
      type="button"
    >
      ×
    </button>
  </div>
), [props.tag.name, props.classNames.selectedTag, props.classNames.selectedTagName]);

class BulkTagModalContent extends React.Component {
  state = {
    animateNewTag: false,
    disableSubmitButton: true,
    duplicateTag: false,
    existing_tag_names: null,
    inputValue: '',
    isLoading: true,
    isSubmitting: false,
    suggestedTags: [],
    tagDelimiters: [9, 13, 188], // tab: 9, enter: 13, comma: 188
    tagsArray: []
  }

  componentDidMount() {
    this.commonTagNames();
  }

  customFilterSuggestions = (textInputValue, possibleSuggestionsArray) => {
    // suggestion tag filters/sort: (baseline is all tags tagged in resource)
    // 1. remove if tag does not include query
    // 2. sort by tag count (higher count display first)
    // 3. if same count, sort by index (tags with query earliest in tag display first)

    // using state for query value because default filter function (textInputValue)
    // applies filter for each letter combination of query, e.g. if
    // textInputValue == 'alt', this filter function runs three times, once for
    // 'alt' then for 'al' then for 'a'
    const query = this.state.inputValue.toLowerCase();
    const suggestions = [];

    possibleSuggestionsArray.forEach((tag) => {
      const tagText = tag.text.toLowerCase();

      if (query && tagText.indexOf(query) > -1) {
        tag.queryIndex = tagText.indexOf(query); // eslint-disable-line no-param-reassign
        suggestions.push(tag);
      }
    });

    suggestions.sort((a, b) => (
      b.count - a.count || a.queryIndex - b.queryIndex
    ));

    return suggestions;
  }

  handleClose = () => {
    if (this.props.hasUnsavedChanges) {
      window.swal(unsavedChangesOptions({
        onStayCopy: t`'Continue Tagging`,
      }), (confirm) => {
        if (confirm) this.props.closeModal();
      });
    } else {
      this.props.closeModal();
    }
  }

  commonTagNames() {
    const data = { data: { asset_keys: Array.from(this.props.selectedAssetKeys) } };

    $.ajax({
      type: 'POST',
      url: `/api/v4/bulk_actions/assets/common_tags?ugt_locale=${this.props.ugtLocale}`,
      contentType: 'application/json',
      data: JSON.stringify(data),
      context: this,
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: (response) => {
        if (response.common_tags.length > 0) {
          const tagsArray = response.common_tags.split(',').map((tag) => ({
            id: sanitize(tag),
            name: sanitize(tag),
          }));

          this.setState({ existing_tag_names: sanitize(response.common_tags), tagsArray });
        } else {
          // Changing existing_tag_names from null to '' necessary for disableSubmitButton
          this.setState({ existing_tag_names: '', tagsArray: [] });
        }
      },
      error: () => {
        this.setState({ isLoading: false });
      },
      complete: () => {
        this.fetchSuggestedTags();
      }
    });
  }

  fetchSuggestedTags(fetchCounter = 0) {
    if (fetchCounter > 30) { return; }
    // These are not scoped to locale, do not want to add field and bloat payload
    $.ajax({
      type: 'GET',
      url: `/api/v4/${Resource.type}s/${Resource.key}/searchable_things`,
      contentType: 'application/json',
      headers: { Authorization: `Bearer ${BF_Token}` },
      context: this,
      success: (filters, _, response) => {
        if (response.status === 202) {
          setTimeout(() => {
            fetchCounter += 1; // eslint-disable-line no-param-reassign
            this.fetchSuggestedTags(fetchCounter);
          }, 500 * fetchCounter);
        }

        const existingTags = this.state.existing_tag_names;
        const suggestedTags = [];

        // remove existing tags from suggested tags
        if (typeof filters.tags !== 'undefined') {
          filters.tags.forEach((tag) => {
            if (tag && existingTags !== null && existingTags.indexOf(tag) === -1) {
              suggestedTags.push({
                name: tag,
                queryIndex: -1
              });
            }
          });

          this.setState({ suggestedTags });
        }
      },
      error: () => {},
      complete: () => {
        this.setState({ isLoading: false });
      }
    });
  }

  updateTags() {
    this.setState({ isSubmitting: true });

    const { existing_tag_names, tagsArray } = this.state;
    const { ugtLocale, selectedAssetKeys } = this.props;
    const submitted_tag_names = [...tagsArray].map((tag) => tag.name).join(',');
    const data = {
      data: {
        asset_keys: Array.from(selectedAssetKeys),
        bulk_tag: { existing_tag_names, submitted_tag_names }
      }
    };

    $.ajax({
      type: "PUT",
      url: `/api/v4/bulk_actions/assets/tag?ugt_locale=${ugtLocale}&queue_priority=high`,
      contentType: "application/json",
      data: JSON.stringify(data),
      context: this,
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: () => {
        Notify.create({
          title: t`Processing tag updates!`,
          body: t`Tag updates will be available shortly, but may not yet be visible.`,
          type: 'success',
        });

        this.setState({ disableSubmitButton: true });
        bulkRefreshSections(this.props.selectedAssetKeys, 7000); // 7ish second delay
      },
      error: () => {
        Notify.create({
          title: t`An error occurred when updating asset tags. Please try again.`,
          type: 'error'
        });
      },
      complete: () => {
        this.setState({ isSubmitting: false });
        this.props.setHasUnsavedChanges(false);
      }
    });
  }

  sortTags(a, b) {
    if (a.toUpperCase() > b.toUpperCase()) {
      return 1;
    }

    if (b.toUpperCase() > a.toUpperCase()) {
      return -1;
    }

    return 0;
  }

  disableSubmitButton() {
    // Default is null, changed to empty string once common tags returned
    if (this.state.existing_tag_names === null) { return true; }

    const { tagsArray } = this.state;
    let { existing_tag_names } = this.state;
    let submitted_tag_names = [...tagsArray].map((tag) => tag.name).join(',');

    submitted_tag_names = submitted_tag_names.split(',').sort((a, b) => this.sortTags(a, b)).join(',');
    existing_tag_names = existing_tag_names.split(',').sort((a, b) => this.sortTags(a, b)).join(',');

    const areTagsDifferent = existing_tag_names !== submitted_tag_names;
    this.props.setHasUnsavedChanges(areTagsDifferent);

    return !areTagsDifferent;
  }

  handleInputChange(text) {
    this.setState({ inputValue: text, duplicateTag: this.detectDuplicateTag(text) });
  }

  detectDuplicateTag(tag) {
    const tagsTextArray = [...this.state.tagsArray].map((t) => t.name.toUpperCase());
    const tagText = typeof tag.name === 'undefined' ? tag : tag.name;

    return tagsTextArray.indexOf(tagText.toUpperCase()) > -1;
  }

  deleteTags(i) {
    this.setState((state) => {
      const tags = [...state.tagsArray];
      tags.splice(i, 1);

      return {
        tagsArray: tags,
        animateNewTag: false,
      };
    }, () => {
      this.setState({ disableSubmitButton: this.disableSubmitButton() });
    });
  }

  addNewTags(tag) {
    if (this.state.duplicateTag || sanitize(tag.name) === '') {
      this.setState({ inputValue: '', duplicateTag: false });
      return;
    }

    const suggestedTagsUpdated = [];

    this.state.suggestedTags.forEach((suggestedTag) => {
      if (suggestedTag.name !== tag.name) {
        suggestedTagsUpdated.push(suggestedTag);
      }
    });

    const newTags = [];
    tag.name.split(",").forEach((tagName) => {
      if (tagName) {
        newTags.push({ name: sanitize(tagName) });
      }
    });

    this.setState((state) => ({
      tagsArray: [...state.tagsArray, ...newTags],
      suggestedTags: suggestedTagsUpdated,
      animateNewTag: true,
      inputValue: '',
      duplicateTag: false
    }), () => {
      this.setState({ disableSubmitButton: this.disableSubmitButton() },
        () => {
          setTimeout(() => {
            this.setState({ animateNewTag: false });
          }, 800);
        });
    });
  }

  tagInfoClass() {
    return this.state.inputValue === '' || this.state.duplicateTag ? '' : 'active';
  }

  renderClass(newClass) {
    const stateVar = newClass.replace(/-([a-z])/g, (g) => g[1].toUpperCase()); // convert dash-case to camelCase

    return this.state[stateVar] ? newClass : '';
  }

  render() {
    const assetCount = this.props.selectedAssetKeys.size;

    const {
      disableSubmitButton,
      isLoading,
      isSubmitting,
      suggestedTags,
      tagsArray,
      tagDelimiters
    } = this.state;

    return (
      <div className={`modal-content-wrapper bulk-tag-modal ${this.renderClass('animate-new-tag')}`}>
        <div className="modal-content-wrapper__body">
          <h3 className="body-title">
            {/* TODO: don't rely on bold-text class to add spacing */}
            <Plural
              one={
                <Trans>
                  Add and remove<span className="bold-text">common</span>
                  tags for
                  <span className="bold-text">{assetCount} asset</span>
                </Trans>
              }
              other={
                <Trans>
                  Add and remove<span className="bold-text">common</span>
                  tags for
                  <span className="bold-text">{assetCount} asset</span>
                </Trans>
              }
              value={assetCount}
            />
          </h3>
          {isLoading ? (
            <BFLoader />
          ) : (
            <div className="modal-row">
              <div className={`tag-info-container ${this.tagInfoClass()}`}>
                <span className="tag-info"><Trans>Hit "Enter" to create new tag</Trans></span>
              </div>
              <div className={`duplicate-warning-container ${this.renderClass('duplicate-tag')}`}>
                <span className="bff-warning" />
                <h5 className="duplicate-warning"><Trans>Tag already exists</Trans></h5>
              </div>
              <ReactTags
                allowBackspace={false}
                allowDeleteFromEmptyInput={false}
                allowNew
                autofocus
                classNames={{ tags: 'tags-container', searchInput: 'inputs' }}
                delimiters={tagDelimiters}
                handleAddition={(tag) => this.addNewTags(tag)}
                handleDelete={(i) => this.deleteTags(i)}
                handleFilterSuggestions={this.customFilterSuggestions}
                handleInputChange={(text) => this.handleInputChange(text)}
                minQueryLength={1}
                placeholder={t`Type to add new common tag`}
                suggestions={suggestedTags}
                tagComponent={TagComponent}
                tags={tagsArray}
              />
            </div>
          )}
        </div>
        <div className="button-container">
          <TertiaryButton
            className="t-close-modal"
            disabled={isLoading || isSubmitting}
            onClick={this.handleClose}
            size="small"
          >
            <Trans>Close</Trans>
          </TertiaryButton>
          <PrimaryButton
            className="t-confirm-modal"
            disabled={isLoading || disableSubmitButton}
            isLoading={isSubmitting}
            onClick={() => this.updateTags()}
            size="small"
          >
            <Trans>Save</Trans>
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

BulkTagModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  hasUnsavedChanges: PropTypes.bool.isRequired,
  ugtLocale: PropTypes.string.isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
  setHasUnsavedChanges: PropTypes.func.isRequired,
};

export default BulkTagModalContent;
