/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { getStorage, setStorage, StorageTypes } from '@helpers/storage';

import LabelTree from './label_tree';
import './styles/main.scss';
import ToggleDrawerButton from './toggle_drawer_button';

const LabelsDrawer = (props) => {
  const [expandedLabels, setExpandedLabels] = useState({});

  const {
    activateLabel,
    activeLabelKey,
    labels,
    filtersDrawerOpen,
    selectedAssetKeys,
    toggleFiltersDrawer
  } = props;
  const resourceName = BF.fx.resource().name;
  const activeClass = activeLabelKey ? '' : 'active';
  const labelsDrawerClass = filtersDrawerOpen === 'labels' ? 'open' : '';

  const setPreviousLabels = () => {
    const drawerOpenLocal = getStorage(StorageTypes.Local, 'drawerOpen');
    const labelsDrawerOpenLocal = drawerOpenLocal ? drawerOpenLocal === 'labels' : getStorage(StorageTypes.Local, 'labelsDrawerOpen') === true;
    const expandedLabelsLocal = getStorage(StorageTypes.Local, 'expandedLabels');

    if (labelsDrawerOpenLocal) {
      toggleFiltersDrawer('labels-drawer');

      if (expandedLabelsLocal && Object.keys(expandedLabelsLocal).length) {
        setExpandedLabels(expandedLabelsLocal);
      }
    }
  };

  const toggleExpand = (key, removeExistingKey = true, toggleState) => {
    const updatedExpandedLabels = { ...expandedLabels };

    if (updatedExpandedLabels[key] && removeExistingKey && !toggleState) {
      delete updatedExpandedLabels[key];
    } else if (!updatedExpandedLabels[key]) {
      updatedExpandedLabels[key] = true;
    }

    setStorage(StorageTypes.Local, 'expandedLabels', updatedExpandedLabels);
    setExpandedLabels(updatedExpandedLabels);
  };

  const toggleExpandForActiveLabel = (key) => {
    const activeLabelPath = [...labels.labelsFlat[key].path];

    if (activeLabelPath.length > 1) {
      activeLabelPath.pop(); // remove key for activeLabel so only parents are expanded
      activeLabelPath.forEach((labelKey) => { toggleExpand(labelKey, false); });
    }
  };

  useEffect(() => {
    setPreviousLabels();
  }, []);

  useEffect(() => {
    if (activeLabelKey) {
      toggleExpandForActiveLabel(activeLabelKey);
    }
  }, [activeLabelKey]);

  const onLabelSelect = (key) => {
    if (key === activeLabelKey) { return undefined; }

    const newKey = key === 'resource' ? null : key;
    activateLabel(newKey);
    return undefined;
  };

  return (
    <section className={`labels-drawer-container ${labelsDrawerClass}`} data-cx-customization={`labels-drawer-${BFG.resource.type}`}>
      <div className={`labels-drawer-container-maxheight bf-scroll-element ${selectedAssetKeys.size ? 'select-bar-active' : ''}`}>
        <div className="labels-toggle-tab">
          <ToggleDrawerButton toggleFiltersDrawer={toggleFiltersDrawer}>
            <p className="drawer-title modal-label"><Trans>Labels</Trans></p>
          </ToggleDrawerButton>
        </div>
        <div className="labels-expand-container bf-sticky-element">
          <p className="drawer-title modal-label"><Trans>Labels</Trans></p>
          <ToggleDrawerButton toggleFiltersDrawer={toggleFiltersDrawer} />
        </div>
        <div className={`label-item ${activeClass}`}>
          <div className="label-name-container">
            <button
              className="select-button"
              onClick={() => onLabelSelect('resource')}
              type="button"
            >
              <h4 className="name-copy">{resourceName}</h4>
            </button>
          </div>
        </div>
        {filtersDrawerOpen === 'labels' && (
          <LabelTree
            activeLabelKey={activeLabelKey}
            expandedLabels={expandedLabels}
            labelsTree={labels.labelsTree}
            onLabelSelect={onLabelSelect}
            toggleExpand={toggleExpand}
          />
        )}
      </div>
    </section>
  );
};

LabelsDrawer.propTypes = {
  activateLabel: PropTypes.func.isRequired,
  activeLabelKey: PropTypes.string,
  labels: PropTypes.shape({}),
  filtersDrawerOpen: PropTypes.oneOf(['labels', 'advanced-filters', 'none']).isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
  toggleFiltersDrawer: PropTypes.func.isRequired,
};

LabelsDrawer.defaultProps = {
  activeLabelKey: null,
  labels: {},
};

export default LabelsDrawer;
