/* eslint-disable @typescript-eslint/naming-convention */
import { AssetTypes } from '@api/v4/assets/assetTypes';
import { TaskPriorities, TaskStatuses } from '@api/v4/tasks';
import {
  InitialModalEditFormContentState,
  ModalEditFormContentState,
  ReducerState
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import { Locale } from '@components/common/language_menu/languagesMap';

export const initialData: InitialModalEditFormContentState = {
  asset: {
    description: 'initial description',
    name: 'initial name',
    thumbnail_url: 'www.thumbnail.com/thumbnail.jpg',
    extension: 'jpg',
  },
  assetKey: 'asset-key',
  assetType: AssetTypes.GenericFile,
  attachments: [
    {
      key: 'attachment-to-update',
      filename: 'filename',
      extension: 'jpg',
      mimetype: 'image/jpeg',
      size: 0,
      temporaryAttachment: false,
      url: 'source.com/filename'
    },
    {
      key: 'attachment-to-delete',
      filename: 'filename',
      extension: 'jpg',
      mimetype: 'image/jpeg',
      size: 0,
      temporaryAttachment: false,
      url: 'source.com/filename'
    }
  ],
  customFieldKeys: [{
    id: 'custom-field-to-update',
    type: 'custom_field_keys',
    attributes: {
      name: 'update-key',
      prioritized: false,
      allowed_values: [],
      restricted: false
    }
  }],
  dependentCustomFields: [],
  flattenedCustomFieldKeyValuesMap: {
    'cfk-1': {
      customFieldKey: {
        id: 'cfk-1',
        name: 'key 1'
      },
      customFieldValues: [{
        key: 'cfv-to-delete',
        value: 'to delete'
      }, {
        key: 'cfv-to-update',
        value: 'to update'
      }]
    }
  },
  printui: {
    id: 78,
    ogranization_id: 3,
    section_id: 32,
    client: 'abcd',
    auth: 'adsf',
    active: true,
    key: 'asdv-asdf-dfas',
    approvals: true,
    created_at: 'asd',
    updated_at: 'asd',
    data: {
      printuiSettings: {}
    }
  },
  tags: [
    {
      name: 'to-delete',
      auto_generated: false
    }
  ],
  task: null,
};

export const editState: ModalEditFormContentState = {
  aiGeneratedCaptionId: null,
  assetDescription: 'edited description',
  assetName: 'edited name',
  attachments: [
    {
      key: 'new-attachment-key',
      filename: '',
      extension: '',
      mimetype: '',
      size: 0,
      temporaryAttachment: true,
      url: ''
    },
    {
      key: 'another-new-attachment',
      filename: '',
      extension: '',
      mimetype: '',
      size: 0,
      temporaryAttachment: true,
      url: ''
    },
    {
      key: 'attachment-to-update',
      filename: 'edited filename',
      extension: 'jpg',
      mimetype: 'image/jpeg',
      size: 0,
      temporaryAttachment: false,
      url: 'source.com/edited filename',
      position: 2
    }
  ],
  colorData: null,
  externalMediumData: null,
  firstAttachmentKey: '',
  flattenedCustomFieldKeyValuesMap: {
    'cfk-1': {
      customFieldKey: {
        id: 'cfk-1',
        name: 'key 1'
      },
      customFieldValues: [{
        key: 'cfv-to-update',
        value: 'updated'
      }, {
        key: 'cfv-created',
        value: 'created'
      }]
    }
  },
  fontData: null,
  genericFileData: {
    three_sixty_viewer: true,
    asset_background_color: '#123123',
    gelato_product_uid: undefined
  },
  personData: null,
  pressData: null,
  tags: [
    {
      id: 'added',
      name: 'added',
      auto_generated: false
    }
  ],
  tagSearchSuggestions: [],
  task: {
    assetKey: '',
    createdAt: '',
    deletedAt: null,
    dimensions: '',
    dueDate: '',
    filetype: '',
    taskDescription: '',
    taskPriority: TaskPriorities.Low,
    taskStatus: TaskStatuses.NotStarted,
    taskUsers: ['username1', 'username2'],
    updatedAt: '',
    workspaceKey: ''
  },
  terribleTrumbowygFlag: Locale.English,
  thumbnailUrl: 'www.thumbnail.com/thumbnail.jpg',
  thumbnailOverride: {},
  watermark: {},
};

export const reducerState: ReducerState = {
  ugtLocale: Locale.English,
  initialData,
  editState,
  errorState: []
};
