/* eslint-disable react/require-default-props */
import { MoreInfoTooltip } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { update } from '@api/v4/metadata_conversions';
import { create } from '@api/v4/resources/metadata_conversions';
import { ListDropdown } from '@components/library/dropdown';

import './styles/metadata_conversions_form.scss';

const Form = ({
  resourceType,
  resourceKey,
  refreshData,
  editableKey,
  setEditableKey,
  setShowForm,
  selectedItem,
}) => {
  const [convert_to_tags, setConvertToTags] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [metadata_term, setMetaTerm] = useState('');
  const [key_term, setKeyTerm] = useState('');
  const [error, setError] = useState(null);

  const submit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    setError(null);
    if (editableKey) {
      update({
        id: editableKey,
        metadata_term,
        key_term,
        convert_to_tags: !!convert_to_tags,
      }).then(() => {
        refreshData();
        setEditableKey(null);
        setShowForm(false);
        Notify.create({ type: "success", key_term: "Mapping Updated!" });
      }).catch(({ errors }) => {
        setError(errors);
      }).finally(() => {
        setSubmitting(false);
      });
    } else {
      create({
        resourceType,
        resourceKey,
        metadata_term,
        key_term,
        convert_to_tags: !!convert_to_tags,
      }).then(() => {
        refreshData();
        setEditableKey(null);
        setShowForm(false);
        Notify.create({ type: "success", key_term: "Mapping Created!" });
      }).catch(({ errors }) => {
        setError(errors);
      }).finally(() => {
        setSubmitting(false);
      });
    }
  };

  const closeForm = () => {
    setEditableKey(null);
    setShowForm(false);
  };

  useEffect(() => {
    if (selectedItem) {
      setMetaTerm(selectedItem.metadata_term);
      setKeyTerm(selectedItem.key_term);
    }
  }, [selectedItem]);

  const metaFieldSuggestions = ["aspect_ratio", "average_color", "bit_depth", "caption_abstract", "color_type", "colorspace", "compression", "date_file_modified", "description", "file_access_date", "file_inode_change_date", "file_modify_date", "file_size", "file_type_extension", "file_type", "filter", "frame_count", "has_clipping_path", "height", "image_description", "image_height", "image_size", "image_width", "interlace", "keywords", "megapixels", "mime_type", "subject", "width"];

  return (
    <>
      <div className="metadata_conversions--cancel-edit" onClick={closeForm} onKeyPress={closeForm} role="button" tabIndex={0}>
        <span className="bff-arrow-left" />
        <span><Trans>Back</Trans></span>
      </div>
      <form
        className="metadata_conversions--form"
        onSubmit={submit}
      >
        <label
          className="brandfolder-label"
          htmlFor="metadata-conversion-target"
        >
          <Trans>Convert metadata into</Trans>
        </label>
        <ListDropdown
          className="metadata-conversion-target"
          name="metadata-conversion-target"
          onChange={(option) => setConvertToTags(option.value)}
          options={[
            { value: false, label: t`Custom Fields` },
            { value: true, label: t`Tags` }
          ]}
          searchable={false}
          value={convert_to_tags}
          virtualizeOptions={false}
        />

        <div>
          <label
            className="brandfolder-label"
            htmlFor="mapping-metadata_term"
          >
            <Trans>Select embedded metadata field</Trans>
            <input
              className="brandfolder-input"
              disabled={submitting}
              id="mapping-metadata_term"
              list="suggestions"
              name="mapping-metadata_term"
              onChange={({ target }) => setMetaTerm(target.value)}
              required
              type="text"
              value={metadata_term}
            />
            <datalist id="suggestions">
              { metaFieldSuggestions.map((field) => <option key={field} value={field} />)}
            </datalist>
          </label>
        </div>

        { !convert_to_tags
          && (
            <div>
              <label
                className="brandfolder-label"
                htmlFor="mapping-key_term"
              >
                <Trans>Existing Custom Field Key for Metadata</Trans>
                <MoreInfoTooltip
                  iconLabel={t`More Info`}
                  id="existing-custom-field-key-for-metadata-label"
                  tooltip={t`Provide an existing custom field key. Inputting a new (unused) custom field key results in unsuccessful conversions.`}
                />
                <input
                  className="brandfolder-input"
                  disabled={submitting}
                  id="mapping-key_term"
                  name="mapping-key_term"
                  onChange={({ target }) => setKeyTerm(target.value)}
                  placeholder={t`Existing custom field key`}
                  required
                  type="text"
                  value={key_term}
                />
              </label>
            </div>
          )
        }

        <div className={`metadata_conversions--button-container ${error && 'has-error'}`}>
          {!!error && (
            <p className="error-message">{error}</p>
          )}
          <button
            className="button primary md"
            disabled={submitting}
            type="submit"
          >
            {editableKey ? <Trans>Update Conversion</Trans> : <Trans>Create Conversion</Trans>}
          </button>
        </div>
      </form>
    </>
  );
};

Form.propTypes = {
  resourceKey: PropTypes.string.isRequired,
  refreshData: PropTypes.func,
  resourceType: PropTypes.string,
  editableKey: PropTypes.string,
  setEditableKey: PropTypes.func.isRequired,
  setShowForm: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.string,
    metadata_term: PropTypes.string,
    key_term: PropTypes.string,
  })
};

Form.defaultProps = {
  editableKey: null,
  refreshData: () => {},
  resourceType: 'brandfolder',
  selectedItem: null,
};

export default Form;
