import { FontIcon, FontIcons } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { io } from 'socket.io-client';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { CircleStatus, Statuses } from '@components/library/status';

import classes from './styles/notifications-link.module.scss';

interface NotificationsLinkProps {
  href: string;
  notificationsSocketIoServer: string | null;
}

const userHasNotification = (message: string): boolean => {
  return message === 'The user has a new notification.';
};

export const NotificationsLink: FunctionComponent<NotificationsLinkProps> = ({ href, notificationsSocketIoServer }) => {
  const [showStatus, setShowStatus] = useState(false);

  useEffect(() => {
    if (notificationsSocketIoServer) {
      const socket = io(notificationsSocketIoServer, {
        auth: {
          token: `Bearer ${BFG.BF_Token}`
        },
        reconnectionAttempts: 5,
        transports: ['websocket']
      });

      socket.on('notification', (message) => {
        setShowStatus(userHasNotification(message));
      });

      return (): void => {
        socket.disconnect();
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <I18nProviderWrapper>
      <a
        className={classnames(
          'notifications-link',
          classes['notifications-link']
        )}
        href={href}
        id="notifications-link"
      >
        <span className={classes['notifications-link__wrapper']}>
          <FontIcon
            aria-hidden
            className={classnames({
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'link-text': true,
              [classes['notifications-link__icon']]: true,
              [classes['notifications-link__icon--active']]: showStatus
            })}
            icon={FontIcons.Notifications}
          />
          {showStatus && (
            <CircleStatus
              className={classes['notifications-link__status']}
              size={10}
              status={Statuses.HasNotification}
            />
          )}
        </span>
        <span className={classes['notifications-link__text']}>
          <Trans>Notifications</Trans>
        </span>
      </a>
    </I18nProviderWrapper>
  );
};
