import { keyPressHelper } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import React, { useState, FunctionComponent, KeyboardEvent } from 'react';

import { getAvailableLanguages } from '@components/asset/modal/tabs/edit/helpers';
import DropdownWrapper from '@components/common/dropdown_wrapper/main';
import languagesMap, {
  LanguageMapEntry,
  Locale,
  findCurrentLanguageInMap
} from '@components/common/language_menu/languagesMap';

import './styles/_language_menu.scss';

export interface LanguageMenuDropdownProps {
  onLocaleChange: (locale: Locale | undefined) => boolean; // returns whether or not dropdown should close
  selectedLocale: Locale;
  allowAllLocales?: boolean;
  copy?: string;
  defaultLocale?: Locale;
  openOnClick?: boolean;
  openOnHover?: boolean;
  showSelectedLocaleName?: boolean;
  wrapperClassnames?: string;
}

const LanguageMenuDropdown: FunctionComponent<LanguageMenuDropdownProps> = (props) => {
  const {
    allowAllLocales,
    copy = '',
    defaultLocale,
    onLocaleChange,
    openOnClick,
    openOnHover,
    selectedLocale,
    showSelectedLocaleName,
    wrapperClassnames
  } = props;

  const [closeDropdown, setCloseDropdown] = useState(false);
  const availableLanguages = (): LanguageMapEntry[] => (allowAllLocales ? languagesMap : getAvailableLanguages());

  const keypressUpdateLocale = keyPressHelper(onLocaleChange);

  const renderLanguageMenuItem = (langMapEntry?: LanguageMapEntry, isDefault?: boolean, isSelected?: boolean,): JSX.Element => {
    const isActiveItem = langMapEntry?.locale === selectedLocale;

    return (
      <li
        key={langMapEntry?.ISOCode}
        className={
          `language-menu__list--item language-menu__list--item--${langMapEntry?.locale} ${isActiveItem ? 'active' : ''}`
        }
        onClick={(): void => {
          if (!isSelected) {
            const shouldClose = onLocaleChange(langMapEntry?.locale);
            setCloseDropdown(shouldClose);
          }
        }}
        onKeyPress={(e: KeyboardEvent<HTMLLIElement>): void => {
          if (!isSelected) {
            const shouldClose = keypressUpdateLocale(e, langMapEntry?.locale);
            setCloseDropdown(shouldClose || false);
          }
        }}
        role="button"
        tabIndex={0}
      >
        {langMapEntry?.language} ({langMapEntry?.ISOCode})
        <span className="default-language">{isDefault ? <Trans>(default)</Trans> : ''}</span>
      </li>
    );
  };

  const dropdownContents = (
    <div className="language-menu__dropdown">
      {copy && (
        <p className="language-menu__description">
          {copy}
        </p>
      )}
      <ul className="language-menu__list">
        {availableLanguages().map((item) => renderLanguageMenuItem(item, item.locale === defaultLocale))}
      </ul>
    </div>
  );

  const selectedLanguageEntry = findCurrentLanguageInMap(selectedLocale, availableLanguages());
  const selectedLanguageListItem = renderLanguageMenuItem(selectedLanguageEntry, selectedLocale === defaultLocale, true /* isSelected */);
  const completeWrapperClassnames = wrapperClassnames ? `language-menu ${wrapperClassnames}` : 'language-menu';

  return (
    <DropdownWrapper
      close={closeDropdown}
      closeCallback={(): void => setCloseDropdown(false)}
      cssTransitionClasses="fade-in-translate"
      dropdownContents={dropdownContents}
      openOnClick={openOnClick}
      openOnHover={openOnHover}
      wrapperClassnames={completeWrapperClassnames}
    >
      <>
        <div className="current-language-container">
          <span className="bff-language" />
          {showSelectedLocaleName
            ? (selectedLanguageListItem)
            : (
              <span className="language-menu__list--item">
                {selectedLanguageEntry?.language} ({selectedLanguageEntry?.ISOCode})
              </span>
            )}
        </div>
        <span className="bff-caret-down" />
      </>
    </DropdownWrapper>
  );
};

export default LanguageMenuDropdown;
