import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface EmbedAssetProps {
  defaultEnabled: boolean | null;
}

const EmbedAsset: FunctionComponent<EmbedAssetProps> = ({ defaultEnabled }) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled === null ? true : defaultEnabled}
      description={t`This setting must be enabled for Brandguide use. Disabling it will result in a degraded Brandguide experience.`}
      errorTitle={t`Error updating CDN Embed Links setting. Please contact support.`}
      id="embed-asset-setting"
      successTitle={t`CDN Embed Links updated!`}
      title={t`CDN Embed Links`}
      updateResourceKey="embedAsset"
    />
  </I18nProviderWrapper>
);

export default EmbedAsset;
