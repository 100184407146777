import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseStatusProps, Statuses } from '@components/library/status';

const baseName = 'status';

export const BaseStatus: FunctionComponent<BaseStatusProps> = (props) => {
  const {
    label,
    size = 10,
    status = Statuses.InProgress,
    variantName,
    ...otherProps
  } = props;

  return (
    <span
      {...otherProps}
      aria-label={label}
      className={classnames(
        `bf-${baseName}__${variantName}`,
        `bf-${baseName}__${variantName}--${status}`,
        otherProps.className
      )}
      role="img"
      style={{
        height: size,
        width: size,
        ...otherProps.style
      }}
    />
  );
};
