import { AlertLooks, StandardAlert } from '@brandfolder/react';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { TagCountResponse } from '@components/bulk_management/tags/helpers';
import { TagsTable } from '@components/bulk_management/tags/TagsTable';
import {
  getCurrentUrlLocale
} from '@components/bulk_management/user_generated_translations/BulkManagementLanguageMenuDropdown';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { Locale } from '@components/common/language_menu/languagesMap';
import { UGTAlert } from '@components/common/language_menu/UGTAlert';
import { genericError } from '@components/standard-messages';

const tagFetchOptions = (params: { autoGenerated?: boolean, ugtLocale: Locale }): UseFetchOptions => ({
  url: `/api/v4/private/brandfolders/${BFG.resource?.key}/tag_counts`,
  fetchOnMount: false,
  params: {
    /* eslint-disable @typescript-eslint/naming-convention */
    ugt_locale: params.ugtLocale,
    auto_generated: params.autoGenerated || undefined
    /* eslint-enable @typescript-eslint/naming-convention */
  }
});

export const BulkTags: FunctionComponent = () => {
  const [autoGenerated, setAutoGenerated] = useState(false);
  const [ugtLocale, setUgtLocale] = useState<Locale>(
    getCurrentUrlLocale() || determineUGTLocale() || BFG.locales?.ugtLocaleDefault
  );
  /**
   * do not use this as an example!
   * I'm making an intentional choice here to store the tag count responses in a state variable
   * so the TagsManagement child can update them when individual tags
   * are modified without having to kick off the very
   * slow bulk tag count query again
   */
  const [autoGeneratedTags, setAutoGeneratedTags] = useState<TagCountResponse>();
  const [userGeneratedTags, setUserGeneratedTags] = useState<TagCountResponse>();

  const autoGeneratedTagsFetch = useFetch<TagCountResponse>(tagFetchOptions({ autoGenerated: true, ugtLocale }));
  const userGeneratedTagsFetch = useFetch<TagCountResponse>(tagFetchOptions({ ugtLocale }));

  useEffect(() => {
    if (!BFG.resource?.key || !ugtLocale) return;

    if (!autoGenerated && !userGeneratedTags) {
      userGeneratedTagsFetch.fetch();
    } else if (autoGenerated && !autoGeneratedTags) {
      autoGeneratedTagsFetch.fetch();
    }
  }, [autoGenerated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!BFG.resource?.key || !ugtLocale) return;

    if (!autoGenerated) {
      userGeneratedTagsFetch.fetch();
    } else if (autoGenerated) {
      autoGeneratedTagsFetch.fetch();
    }
  }, [ugtLocale]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (autoGeneratedTagsFetch.response) {
      setAutoGeneratedTags(autoGeneratedTagsFetch.response);
    }
  }, [autoGeneratedTagsFetch.response]);

  useEffect(() => {
    if (userGeneratedTagsFetch.response) {
      setUserGeneratedTags(userGeneratedTagsFetch.response);
    }
  }, [userGeneratedTagsFetch.response]);

  if (!BFG.resource?.key) {
    return (
      <StandardAlert
        look={AlertLooks.Error}
      >
        {genericError()}
      </StandardAlert>
    );
  }

  return (
    <I18nProviderWrapper>
      <div>
        <UGTAlert locale={ugtLocale} />
        <TagsTable
          autoGenerated={autoGenerated}
          error={autoGenerated ? !!autoGeneratedTagsFetch.error : !!userGeneratedTagsFetch.error}
          loading={autoGenerated ? autoGeneratedTagsFetch.loading : userGeneratedTagsFetch.loading}
          setAutoGenerated={setAutoGenerated}
          setTags={autoGenerated ? setAutoGeneratedTags : setUserGeneratedTags}
          setUgtLocale={setUgtLocale}
          tags={autoGenerated ? autoGeneratedTags : userGeneratedTags}
          ugtLocale={ugtLocale}
        />
      </div>
    </I18nProviderWrapper>
  );
};
