import { keyPressHelper } from "@brandfolder/utilities";
import React from "react";

import {
  getFilenameSansExt,
  handleThumbnailError
} from '@components/asset/modal/tabs/edit/helpers';
import Tooltip from "../common/tooltip/main";

export default class Attachment extends React.Component {
  handleClick = () => {
    if (!this.props.placing_file) {
      this.props.showProgressBar();
      // will be defined on the window at runtime
      // eslint-disable-next-line no-undef
      placeRemoteFile(this.props.attachment);
    }
  };

  render() {
    const { extension, filename, thumbnail_url } = this.props.attachment;
    const tooltipId = `adobe-attachment-tooltip-${thumbnail_url}`;
    return (
      <div
        className="adobe-attachment"
        onClick={this.handleClick}
        onKeyPress={keyPressHelper(this.handleClick)}
        role="button"
        tabIndex="0"
      >
        <Tooltip
          offset="{'right': 50}"
          placement="bottom"
          tooltipContent={filename}
          tooltipId={tooltipId}
        >
          <img
            ref="image" // eslint-disable-line react/no-string-refs
            alt={filename}
            className="adobe-attachment-thumbnail"
            onError={(event) => {
              handleThumbnailError(
                event,
                extension,
                getFilenameSansExt(filename, extension)
              );
            }}
            src={thumbnail_url}
          />
        </Tooltip>
      </div>
    );
  }
}
