import { t } from '@lingui/macro';

import fetchJSON from '@api/api_helper';

import { EmbeddedWidget, EmbeddedWidgetBackend, ResourceTypes } from './EmbeddedWidgetTypes';

const mapEmbeddedWidgetToBackend = (widget: EmbeddedWidget): EmbeddedWidgetBackend => ({
  button_text: widget.buttonText,
  description: widget.description,
  height: widget.height,
  is_active: widget.isActive,
  name: widget.name,
  src_url: widget.srcUrl,
  width: widget.width
});

const mapEmbeddedWidgetBackendToWidget = (backend: EmbeddedWidgetBackend): EmbeddedWidget => ({
  buttonText: backend.button_text,
  description: backend.description,
  height: backend.height,
  isActive: backend.is_active,
  key: backend.key,
  name: backend.name,
  srcUrl: backend.src_url,
  width: backend.width
});

interface Errors {
  detail: string;
  title: string;
}

interface EmbeddedWidgetResponse {
  data?: {
    id: string;
    type: 'embedded_widgets';
    attributes: EmbeddedWidgetBackend;
  };
  errors?: Errors[];
}

interface GetEmbeddedWidget {
  resourceKey: string;
  resourceType: ResourceTypes;
}

/**
 * Get an embedded widget for a Brandfolder or Collection
 * @param {GetEmbeddedWidget} params GetEmbeddedWidget
 */
export const getEmbeddedWidget = async (params: GetEmbeddedWidget): Promise<EmbeddedWidget | null> => {
  const { resourceKey, resourceType } = params;
  try {
    const result = await fetchJSON(`/api/v4/private/${resourceType}/${resourceKey}/embedded_widgets`) as EmbeddedWidgetResponse;
    return await Promise.resolve(result.data ? mapEmbeddedWidgetBackendToWidget(result.data.attributes) : null);
  } catch (err) {
    // if embedded widget hasn't been created for a brandfolder or collection, don't error and resolve with null
    if (err.status === 404) {
      return Promise.resolve(null);
    }
    return Promise.reject(new Error(t`Error retrieving form.`));
  }
};

interface PostEmbeddedWidget {
  embeddedWidget: EmbeddedWidget;
  resourceKey: string;
  resourceType: ResourceTypes;
}

/**
 * Create an embedded widget for a Brandfolder or Collection
 * @param {PostEmbeddedWidget} params PostEmbeddedWidget
 * @returns {EmbeddedWidget} EmbeddedWidget
 */
export const postEmbeddedWidget = async (params: PostEmbeddedWidget): Promise<EmbeddedWidget> => {
  const { embeddedWidget, resourceKey, resourceType } = params;
  try {
    const result = await fetchJSON(`/api/v4/private/${resourceType}/${resourceKey}/embedded_widgets`, {
      body: { data: { attributes: mapEmbeddedWidgetToBackend(embeddedWidget) } },
      method: 'POST'
    }) as EmbeddedWidgetResponse;
    if (result.data) {
      return await Promise.resolve(mapEmbeddedWidgetBackendToWidget(result.data.attributes));
    }
    throw new Error();
  } catch (err) {
    return Promise.reject(new Error(t`Error creating form.`));
  }
};

interface PutEmbeddedWidget {
  embeddedWidget: EmbeddedWidget;
  resourceKey: string;
  resourceType: ResourceTypes;
}

/**
 * Update an embedded widget for a Brandfolder or Collection
 * @param {PutEmbeddedWidget} params PutEmbeddedWidget
 * @returns {EmbeddedWidget} EmbeddedWidget
 */
export const putEmbeddedWidget = async (params: PutEmbeddedWidget): Promise<EmbeddedWidget> => {
  const { embeddedWidget, resourceKey, resourceType } = params;
  try {
    const result = await fetchJSON(`/api/v4/private/${resourceType}/${resourceKey}/embedded_widgets/${embeddedWidget.key}`, {
      body: { data: { attributes: mapEmbeddedWidgetToBackend(embeddedWidget) } },
      method: 'PUT'
    }) as EmbeddedWidgetResponse;
    if (result.data) {
      return await Promise.resolve(mapEmbeddedWidgetBackendToWidget(result.data.attributes));
    }
    throw new Error();
  } catch (err) {
    return Promise.reject(new Error(t`Error updating form.`));
  }
};
