import { plural, t } from '@lingui/macro';
import React, { useState, FunctionComponent } from 'react';

import { BoardResponseObject } from '@api/v4/boards/boardsTypes';

import AssetBoardActions from '../components/asset_board_actions';
import AssetBoardActionsOverlay from '../components/asset_board_actions_overlay';
import AssetBoardCard from '../components/asset_board_card';
import AssetBoardCardDescription from '../components/asset_board_card_description';
import AssetBoardCardThumbnailArea from '../components/asset_board_card_thumbnail_area';
import { ViewRouterContext } from '../view_router';

import DeleteBoardModal from './delete_board_modal';
import RenameBoardModal from './rename_board_modal';

// This spawns a network request for each board, and also uses findValidThumbnail to iterate over at least 1 asset of each board
// We can avoid this if we can find a way to return the thumbnail and asset_count in the initial getAllBoards request
interface BoardCardProps {
  board: BoardResponseObject;
}

const BoardCard: FunctionComponent<BoardCardProps> = ({ board }) => {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  // we could drill this prop down 2 levels from view_router instead,
  // would make this component more re-usable
  const ViewRouterState = React.useContext(ViewRouterContext);

  const assetCount = board.attributes.asset_count;
  const assetCopy = plural(assetCount, {
    one: '# asset',
    other: '# assets'
  });
  const handleShowRenameModal = (): void => {
    setShowRenameModal(true);
    setCloseDropdown(true);
  };
  const handleShowDeleteModal = (): void => {
    setShowDeleteModal(true);
    setCloseDropdown(true);
  };

  return (
    <>
      {showRenameModal && (
        <RenameBoardModal
          boardId={board.id}
          boardName={board.attributes.name}
          closeModal={(): void => setShowRenameModal(false)}
        />
      )}
      {showDeleteModal && (
        <DeleteBoardModal
          boardId={board.id}
          boardName={board.attributes.name}
          closeModal={(): void => setShowDeleteModal(false)}
        />
      )}
      <AssetBoardCard className="asset-board-board-card">
        <AssetBoardCardThumbnailArea resource={board} setShowOverlay={setShowOverlay}>
          <AssetBoardActionsOverlay
            buttonText={t`View Board`}
            onClickHandler={(): void => {
              if (ViewRouterState.setBoardToView) {
                ViewRouterState.setBoardToView({
                  boardName: board.attributes.name,
                  boardId: board.id
                });
              }
            }}
            showOverlay={showOverlay}
          >
            <AssetBoardActions
              closeDropdown={closeDropdown}
              handleShowRemoveModal={handleShowDeleteModal}
              handleShowRenameModal={handleShowRenameModal}
              isBoardCard
              updateCloseDropdown={(): void => setCloseDropdown(false)}
            />
          </AssetBoardActionsOverlay>
        </AssetBoardCardThumbnailArea>
        <AssetBoardCardDescription leftText={board.attributes.name} pillStyling="" rightText={assetCopy} />
      </AssetBoardCard>
    </>
  );
};

export default BoardCard;
