export const valuesAsString = (values: { value: string }[]): string => values.map((valueObject) => (
  valueObject.value
)).join(', ');

export const customFieldQuery = (key: string, values: string[]): string => {
  const escapedValues = values.map((value) => value.replace(/"/g, '\\"'));
  let customFieldValueQuery = valuesAsString(escapedValues.map((value) => ({ value })));
  if (escapedValues.length > 1) {
    customFieldValueQuery = escapedValues.map((value) => `"${value}"`).join(' OR ');
  } else {
    customFieldValueQuery = `"${customFieldValueQuery}"`;
  }

  return `custom_fields.${key}.strict:(${customFieldValueQuery})`;
};
