import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { CSSTransition } from 'react-transition-group';

import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { FilestackUploader } from '@components/common/filestack_uploader/FilestackUploader';
import { TextWarningButton } from '@components/library/button';
import { MoreInfoTooltip } from '@components/library/tooltip';

import '../styles/CustomLogoUpload.scss';

export interface CustomLogoUploadProps {
  handleUpdate: (url: string | null) => void;
  isGalleryViewOpen: boolean;
  customLogoUrl: string | null;
}

const customLogoUploadTransitionTime = 300;

export const CustomLogoUpload: FunctionComponent<CustomLogoUploadProps> = ({
  handleUpdate,
  isGalleryViewOpen,
  customLogoUrl = null,
}) => {
  return (
    <CSSTransition
      in={isGalleryViewOpen}
      timeout={customLogoUploadTransitionTime}
      unmountOnExit
    >
      <div className="custom-logo-upload-container">
        <h5 className="logo-label">
          <Trans>Logo</Trans>
          <MoreInfoTooltip
            className='custom-logo-upload-tooltip'
            iconSize={13}
            id="custom-logo-upload-tooltip"
            tooltip={t`Upload a logo for a branded Share Link experience`}
          />
        </h5>
        <p id="logo-recommendation"><Trans>Recommended size: 128x128px, PNG, JPG.</Trans></p>
        {customLogoUrl ? (
          <div className="custom-logo-upload__filestack-container--thumbnail">
            <div className="custom-logo-upload__filestack-container--thumbnail-container">
              <FilestackPreviewImage
                alt="Header"
                className="filestack-container--thumbnail--image"
                data-private
                src={customLogoUrl}
              />
            </div>
            <TextWarningButton
              className="portal-delete-button stage-3-delete"
              icon="bff-trash"
              onClick={(): void => handleUpdate(null)}
            >
              <Trans>Delete Image</Trans>
            </TextWarningButton>
          </div>
        ) : (<FilestackUploader
          onUploadDone={(files): void => {
            const uploadUrl = files.filesUploaded[0].url;
            handleUpdate(uploadUrl);
          }}
          pickerOptions={{
            accept: 'image/*',
            maxFiles: 1,
          }}
        />)}
      </div>
    </CSSTransition>
  );
};
