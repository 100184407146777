import {
  AlertLooks,
  ButtonSizes,
  CircleLoader,
  StandardAlert,
  StandardButton,
  StandardTextfield
} from '@brandfolder/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import React, { useState, FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { object, SchemaOf, string } from 'yup';

import { useFetch } from '@api/ApiHelper';
import { CollectionsListResponse } from '@api/v4/collections';
import { SectionsListResponse } from '@api/v4/brandfolders/SectionTypes';
import { GcsIngestSourceResponse, GcsIngestSourceType } from '@api/v4/gcs-ingest-sources';

import { ListDropdown } from '@components/library/dropdown';
import { loaderLabel } from '@translations';

interface SftpFormValues {
  password: string;
  sectionKey: string;
  rootdir: string;
  collectionKey?: string;
}

const validationSchema = (): SchemaOf<SftpFormValues> => object().shape({
  collectionKey: string(),
  sectionKey: string()
    .required(t`Section is required`),
  rootdir: string()
    .min(3, t`Minimum 3 characters`)
    .max(200, t`Maximum 200 characters`)
    .matches(/^[a-z0-9_-]+$/i, t`No special characters or spaces allowed`)
    .required(t`Name is required`),
  password: string()
    .min(8, t`Minimum 8 characters`)
    .max(100, t`Maximum 100 characters`)
    .required(t`Password is required`)
});

const CreateSftpIngestForm: FunctionComponent<{ setShowForm: SetStateDispatch<boolean> }> = ({ setShowForm }) => {
  const [submissionValues, setSubmissionValues] = useState<SftpFormValues | undefined>(undefined);

  const collectionsFetch = useFetch<CollectionsListResponse>({
    url: `/api/v4/brandfolders/${BFG.resource.key}/collections`,
    params: {
      per: 3000,
      sort_by: 'created_at', // eslint-disable-line @typescript-eslint/naming-convention
      order: 'DESC'
    }
  });
  const sectionsFetch = useFetch<SectionsListResponse>({
    url: `/api/v4/brandfolders/${BFG.resource.key}/sections`,
    params: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      default_asset_type: 'GenericFile'
    }
  });
  const createGcsIngestSourceFetch = useFetch<GcsIngestSourceResponse>({
    url: `/api/v4/sections/${submissionValues?.sectionKey}/gcs_ingest_sources`,
    body: {
      data: {
        attributes: {
          rootdir: submissionValues?.rootdir,
          password: submissionValues?.password,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          collection_key: submissionValues?.collectionKey || undefined,
          type: GcsIngestSourceType.SFTP
        }
      }
    },
    method: 'POST',
    fetchOnMount: false
  });

  const defaultValues: SftpFormValues = {
    rootdir: '',
    password: '',
    sectionKey:  '',
    collectionKey: ''
  };
  const { formState, handleSubmit, register, setValue, watch } = useForm<SftpFormValues, unknown, SftpFormValues>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema())
  });
  const { errors } = formState;
  const watchSection = watch('sectionKey');
  const watchCollection = watch('collectionKey');

  useEffect(() => {
    if (submissionValues) {
      createGcsIngestSourceFetch.fetch();
    }
  }, [submissionValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createGcsIngestSourceFetch.response) {
      setShowForm(false);
    }
  }, [createGcsIngestSourceFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sectionsFetch.response) {
      setValue('sectionKey', sectionsFetch.response.data[0]?.id);
    }
  }, [sectionsFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  const collectionsLoaded = collectionsFetch.response && !collectionsFetch.error;
  const collectionOptions = collectionsLoaded
    ? collectionsFetch.response.data.map((collection) => ({ label: collection.attributes.name, value: collection.id }))
    : [];

  const sectionsLoaded = sectionsFetch.response && !sectionsFetch.error;
  const sectionOptions = sectionsLoaded
    ? sectionsFetch.response.data.map((section) => ({ label: section.attributes.name, value: section.id }))
    : [];

  return (
    <div className="ftp-ingest-form">
      {createGcsIngestSourceFetch.error && (
        <StandardAlert
          className="ftp-ingest-error-message"
          heading={<Trans>Oops!</Trans>}
          look={AlertLooks.Error}
          showHeading
          showIcon
        >
          <Trans>There was an error with your SFTP creation. Please try again with a unique SFTP name.</Trans>
        </StandardAlert>
      )}
      {sectionsFetch.response ? (
        <form
          id="sftp-ingest-form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit((values) => {
            setSubmissionValues(values);
          })}
        >
          <div className="ftp-ingest-form__input-container">
            <StandardTextfield
              {...register('rootdir')}
              error={errors.rootdir?.message}
              id="ftp-ingest-form__rootdir-input"
              label={<Trans>SFTP name</Trans>}
              maxLength={200}
              placeholder=""
            />
          </div>
          <div className="ftp-ingest-form__input-container">
            <StandardTextfield
              {...register('password')}
              error={errors.password?.message}
              helpText={<Trans>Passwords cannot be recovered or reset. Please make a record of your password.</Trans>}
              id="ftp-ingest-form__password-input"
              label={<Trans>Password</Trans>}
              maxLength={100}
              placeholder=""
              type="password"
            />
          </div>
          <div className="ftp-ingest-form__input-container">
            <ListDropdown
              error={errors.sectionKey?.message}
              isLoading={sectionsFetch.loading}
              label={<Trans>Add assets to section</Trans>}
              onChange={(listOption): void => {
                setValue('sectionKey', listOption.value as string);
              }}
              options={sectionOptions}
              searchable
              value={watchSection}
              virtualizeOptions={false}
            />
          </div>
          {collectionOptions.length > 0 && (
            <div className="ftp-ingest-form__input-container">
              <ListDropdown
                error={errors.collectionKey?.message}
                isLoading={collectionsFetch.loading}
                label={<Trans>Add assets to Collection (optional)</Trans>}
                onChange={(listOption): void => {
                  setValue('collectionKey', listOption.value as string);
                }}
                options={collectionOptions}
                placeholder={t`Select Collection`}
                searchable
                value={watchCollection || undefined}
                virtualizeOptions={false}
              />
            </div>
          )}
          <div className="ftp-ingest-form__button-container">
            <StandardButton
              className="ftp-ingest-form__submit-button"
              loaderProps={{
                label: t`Creating SFTP ingest`
              }}
              loading={createGcsIngestSourceFetch.loading}
              size={ButtonSizes.Medium}
              type="submit"
            >
              <Trans>Create SFTP ingest</Trans>
            </StandardButton>
          </div>
        </form>
      ) : <CircleLoader label={loaderLabel()} />}
    </div>
  );
};

export default CreateSftpIngestForm;
