import fetchJSON from '@api/api_helper';

const url = (action) => `/api/v4/bulk_actions/assets/${action}`;

export default (options, updateFetchControllers) => (
  fetchJSON(url(options.attachmentKey), options, updateFetchControllers)
);

export const removeFromCollection = ({ assetKeys, collectionKeys }) => (
  fetchJSON(url('destroy_collection_associations'), {
    method: 'DELETE',
    body: {
      data: {
        asset_keys: assetKeys,
        collection_keys: collectionKeys,
      }
    }
  })
);

export const postToggleApproval = (options, updateFetchControllers) => {
  const toggleApprovalUrl = url(options.approvalType);
  const appendedOptions = {
    body: { data: options.data },
    context: options.context,
    method: 'POST',
    note: options.checkinCheckoutNote,
    status: options.updatedStatus,
  };

  if (options.params) {
    appendedOptions.params = options.params;
  }

  return fetchJSON(toggleApprovalUrl, appendedOptions, updateFetchControllers);
};

export const addToLabel = (asset_keys, label_key) => (
  fetchJSON(url('add_to_label'), {
    method: 'POST',
    body: {
      data: {
        asset_keys, // asset_keys: ['abcd', 'efgh']
        label_key
      }
    }
  }).catch(async (response) => { throw (await response.json()); })
);

export const removeFromLabel = (asset_keys, label_key) => (
  fetchJSON(url('remove_from_label'), {
    method: 'DELETE',
    body: {
      data: {
        asset_keys, // asset_keys: ['abcd', 'efgh']
        label_key
      }
    }
  }).catch(async (response) => { throw (await response.json()); })
);

export const updateAvailability = ({
  asset_keys,
  availability_start,
  availability_end,
}) => (
  fetchJSON(url('availability'), {
    method: 'PUT',
    body: {
      data: {
        asset_keys,
        asset_availability: {
          availability_start,
          availability_end,
        }
      }
    }
  },null, true).catch(async (response, _) => { throw (await response); })
);
