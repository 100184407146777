import React, { FunctionComponent, useState } from 'react';
import { ImageExtended, ThumbnailImageProps } from 'react-grid-gallery';
import LazyLoad from 'react-lazy-load';

import { GalleryCard } from './AttachmentGallery';
import { GalleryCardOverlay } from './GalleryCardOverlay';
import { GalleryImage } from './GalleryImage';

import './styles/attachment-gallery.module.scss';
import classes from './styles/attachment-gallery.module.scss';

export const ImageComponent: FunctionComponent<ThumbnailImageProps<GalleryCard & ImageExtended>> = ({
  imageProps,
  item
}) => {
  const { handleExpandedViewSelection } = item.item;
  const [showOverlay, setShowOverlay] = useState(false);

  const handleShowOverlay = (state: boolean): void => {
    setShowOverlay(state);
  };

  return (      
    <LazyLoad offset="0px 0px 200px 0px">
      <div 
        aria-hidden='true' 
        className={classes.galleryCard} 
        onBlur={(): void => handleShowOverlay(false)}
        onFocus={(): void => handleShowOverlay(true)}
        onMouseEnter={(): void => handleShowOverlay(true)}
        onMouseLeave={(): void => handleShowOverlay(false)}
      >
        <GalleryCardOverlay
          asset={item.item.asset}
          attachmentId={item.item.attachmentId || null}
          handleExpandedViewSelection={handleExpandedViewSelection}
          handleSelection={item.item.handleSelection}
          isSelected={item.item.isSelected}
          showOverlay={showOverlay}
        />
        <GalleryImage imageProps={imageProps} item={item.item} />
      </div>
    </LazyLoad>
  );
};
