import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTrProps } from '../BaseTableProps';

export const BaseTr: FunctionComponent<BaseTrProps> = (props) => {
  const { children, className, ...otherProps } = props;
  return (
    <tr {...otherProps} className={classnames('baseTr', className)}>{children}</tr>
  );
};
