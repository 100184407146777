import PropTypes from 'prop-types';
import React from 'react';

import { UppyUploader } from '@components/common/uppy/UppyUploader';
import {
  formattedUppyAttachments,
  uniqueFilesByKey,
  duplicateSweetAlertOptions } from '@helpers/uppy';

class GuestUploadUppy extends React.Component {
  triggerUpload = (files) => {
    const { appendFiles } = this.props;
    const uniqFiles = uniqueFilesByKey(files, "md5hash");
    if (files.length !== uniqFiles.length) {
      window.swal(duplicateSweetAlertOptions, (removeDuplicates) => {
        if (removeDuplicates) {
          appendFiles(formattedUppyAttachments(uniqFiles));
        } else {
          appendFiles(formattedUppyAttachments(files));
        }
      });
    } else {
      appendFiles(formattedUppyAttachments(files));
    }
  }

  trigger() {
    return `uppy-guest-upload-trigger-${this.props.uploadLinkKey}`;
  }

  renderArea() {
    return (
      <div className="uppy-guest-upload-area" id={this.trigger()}>
        <p>Click to add an asset</p>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderArea()}
        <UppyUploader
          {...this.props}
          button
          handleUpload={this.triggerUpload}
          template="file_ingest"
          trigger={`#${this.trigger()}`}
          uniqIdentifier={`guest-upload-uppy-${this.props.uploadLinkKey}`}
        />
      </React.Fragment>
    );
  }
}

GuestUploadUppy.propTypes = {
  uploadLinkKey: PropTypes.string.isRequired,
  appendFiles: PropTypes.func.isRequired
};

export default GuestUploadUppy;
