/* eslint-disable react/require-default-props */
import { localizeFileSize } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { localizeNumber, getMomentLocale } from '@helpers/locale';

import { defaultImage } from './helper';

import '@components/advanced_download/styles/header.scss';

const Header = ({
  closeModal,
  goBack,
  goBackToAsset,
  image,
}) => {
  const { filename, height, size, updated_at, width } = image;

  return (
    <header className="header">
      <button
        className="header--item go-back-button"
        onClick={goBack}
        type="button"
      >
        <span className="bff-arrow-left" />
        {goBackToAsset ? <Trans>Back to Overview</Trans> : <Trans>Back</Trans>}
      </button>
      <ul className="header__list">
        {filename ? (<li className="header__list--item"><h4>{filename}</h4></li>) : null}
        {size ? (<li className="header__list--item"><h4>{localizeFileSize({ locale: determineUGTLocale(), size })}</h4></li>) : null}
        {(width && height) ? (<li className="header__list--item"><h4>{localizeNumber(width)} x {localizeNumber(height)}</h4></li>) : null}
        {updated_at ? (<li className="header__list--item"><h4>{moment(updated_at).locale(getMomentLocale()).format('L')}</h4></li>) : null}
      </ul>
      <button
        className="header--item close-button"
        onClick={closeModal}
        type="button"
      >
        <span className="bff-close" />
      </button>
    </header>
  );
};

Header.propTypes = {
  closeModal: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goBackToAsset: PropTypes.string,
  image: PropTypes.shape({
    filename: PropTypes.string,
    height: PropTypes.number,
    size: PropTypes.number,
    updated_at: PropTypes.string,
    width: PropTypes.number,
  })
};

Header.defaultProps = {
  goBackToAsset: null,
  image: defaultImage,
};

export default Header;
