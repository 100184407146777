import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTFootProps } from '../BaseTableProps';

export const BaseTFoot: FunctionComponent<BaseTFootProps> = (props) => {
  const { children, className, ...otherProps } = props;
  return (
    <tfoot {...otherProps} className={classnames('baseTfoot', className)}>{children}</tfoot>
  );
};
