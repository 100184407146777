import { StandardAlert, AlertLooks, StandardLink } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import './siws_sign_in_error.scss';

interface SiwsSignInErrorProps {
    credentials_error: Boolean;
}

export const SiwsSignInError: FunctionComponent<SiwsSignInErrorProps> = ({credentials_error}) => {
  return (
        <I18nProviderWrapper>
            <StandardAlert
                className={'siws-sign-in-error'}
                heading={credentials_error ? <Trans>Invalid email or password</Trans> : <Trans>Brandfolder account not found</Trans>}
                labels={{
                  closeButtonLabel: 'Close'
                }}
                look={AlertLooks.Error}
                showHeading
                showIcon
            >
                { credentials_error && (
                    <>
                        <div className={`siws-sign-in-error-message`}>
                            <Trans>Please check the email address or password you entered, or reset your password.</Trans>
                        </div>
                        <StandardLink className={`contact-brandfolder`} href="/password_recover?e=super%40bf.ex"><Trans>Reset password</Trans></StandardLink>
                    </>
                )}
                { !credentials_error && (
                    <>
                        <div className="siws-sign-in-error-message">
                            <Trans>We couldn't find a Brandfolder account associated with your Smartsheet account.</Trans>
                        </div>
                        <div className="siws-sign-in-error-message">
                            <Trans>To get started with Brandfolder, get in touch with the Brandfolder sales team.</Trans>
                        </div>
                        <StandardLink className="contact-brandfolder" href="https://brandfolder.com/quote"><Trans>Contact Brandfolder</Trans></StandardLink>
                    </>
                )}
            </StandardAlert>
        </I18nProviderWrapper>
  );
};

export default SiwsSignInError;
