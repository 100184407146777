import { t, Trans } from '@lingui/macro';
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';

import { PrimaryButton, TertiaryButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';

export interface ConnectDeniedDialogProps {
  isCtaDialogOpen: boolean;
  setIsCtaDialogOpen: Dispatch<SetStateAction<boolean>>;
  handleSmartsheetConnectClick: () => void;
}

export const ConnectDeniedDialog: FunctionComponent<ConnectDeniedDialogProps> = ({
  isCtaDialogOpen, setIsCtaDialogOpen, handleSmartsheetConnectClick
}) => {
  return (
    <StandardDialog
      dialogBodyClassName="smartsheet-cta-dialog-body"
      dialogHeaderClassName="smartsheet-cta-dialog-header"
      footer={
        <div className="smartsheet-cta-dialog__buttons">
          <TertiaryButton
            className="smartsheet-cta-dialog__explore"
            onClick={(): void => {
              window.open(
                'https://brandfolder.com/product/smartsheet-and-brandfolder/',
                '_blank'
              );
              setIsCtaDialogOpen(false);
            }}
          >
            <Trans>Explore Smartsheet</Trans>
          </TertiaryButton>
          <PrimaryButton
            className="smartsheet-cta-dialog__connect"
            loadingCopy={t`Connecting`}
            onClick={(): void => {
              handleSmartsheetConnectClick();
              setIsCtaDialogOpen(false);
            }}
          >
            <Trans>Connect Smartsheet</Trans>
          </PrimaryButton>
        </div>
      }
      height={721}
      id="smartsheet-cta-dialog"
      onClose={(): void => setIsCtaDialogOpen(false)}
      open={isCtaDialogOpen}
      setOpen={setIsCtaDialogOpen}
      title={t`You chose not to connect to Smartsheet`}
      width={721}
    >
      <div className="smartsheet-cta-dialog__body">
        <p>
          <Trans>
            Get the most out of Brandfolder Workspaces by connecting Smartsheet and bringing marketing, creative, and content together in one place.
          </Trans>
        </p>
      </div>
    </StandardDialog>
  );
};
