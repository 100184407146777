import React from "react";
import PropTypes from 'prop-types';
import ReactModal from 'react-modal'; // http://reactcommunity.org/react-modal/
import './styles/modal_styles.scss';


// bind modal to app element for accessibility (http://reactcommunity.org/react-modal/accessibility)
const bodyEl = document.getElementsByTagName('body');
ReactModal.setAppElement(bodyEl[0]);

// HIGHER ORDER COMPONENT => https://reactjs.org/docs/higher-order-components.html
function renderModal(ModalComponent, modalClass = '') {
  return class extends React.Component {
    state = {
      isOpen: true
    }

    updateModalState = (obj) => {
      Object.keys(obj).forEach((key) => this.setState({ [key]: obj[key] }));
    }

    toggleModal = () => {
      this.setState((prevState) => ({ isOpen: !prevState.show }));
    }

    showModal = () => {
      this.setState({ isOpen: true });
    }

    closeModal = () => {
      this.setState({ isOpen: false });
      // resetShowModal is null when no BulkSelectBar
      // null inside of Asset Show Modal, ex: _share_asset partial
      // Function must be defined (but not functional)
      // since it's referenced as a prop in React Modals
      if (this.props.resetShowModal) {
        this.props.resetShowModal();
      }
    }

    render() {
      const className = modalClass || this.props.modalClass;
      const { libraryName } = this.props;

      return (
        <ReactModal
          isOpen={this.state.isOpen}
          closeTimeout={500}
          overlayClassName="react-modal-overlay"
          className={`react-modal-content ${className}`}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
          onRequestClose={this.closeModal}
          shouldReturnFocusAfterClose
          shouldFocusAfterRender
          ariaHideApp
        >
          <ModalComponent
            updateModalState={this.updateModalState}
            showModal={this.showModal}
            closeModal={this.closeModal}
            toggleModal={this.toggleModal}
            libraryName={libraryName}
            {...this.props}
          />
        </ReactModal>
      );
    }
  };
}

renderModal.propTypes = {
  modalClass: PropTypes.string,
  resetShowModal: PropTypes.func.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

renderModal.defaultProps = {
  modalClass: '',
  shouldCloseOnOverlayClick: true,
};

export default renderModal;
