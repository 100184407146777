import { StandardLink } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface PeopleTaggingProps {
  defaultEnabled: boolean | null;
}

const description = (
  <>
    <Trans>Allow photos of people to be automatically tagged after a person has been identified and tagged by an Admin or Contributor.</Trans>
    {'  '}
    <StandardLink className="terms-link" href="https://www.smartsheet.com/legal/user-agreement" target="_blank">
      <Trans>Terms apply</Trans>
    </StandardLink>
  </>
);

const PeopleTagging: FunctionComponent<PeopleTaggingProps> = ({ defaultEnabled }) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled === null ? true : defaultEnabled}
      description={description}
      errorTitle={t`Error updating Auto-Tag People setting. Please contact support.`}
      id="people-tagging-setting"
      successTitle={t`Auto-Tag People updated!`}
      title={t`Auto-Tag People`}
      updateResourceKey="peopleTagging"
    />
  </I18nProviderWrapper>
);

export default PeopleTagging;
