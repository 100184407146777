// top level button to toggle showing dropdown
import { keyPressHelper } from '@brandfolder/utilities';
import React from 'react';
import PropTypes from 'prop-types';

import './styles/search_dropdown.scss';

/* Props:
  title: Text to display within button
*/
const DropdownToggleButton = ({ ariaLabel, onToggle, title = '' }) => (
  <button
    aria-label={ariaLabel}
    className="search-dropdown__toggle"
    data-testid="search-dropdown-toggle"
    onKeyPress={keyPressHelper(onToggle)}
    tabIndex="0"
    type="button"
  >
    <span className="search-dropdown__title">{title}</span>
    <span className="bff-caret-down" />
  </button>
);

DropdownToggleButton.propTypes = {
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
  ariaLabel: PropTypes.string,
};

DropdownToggleButton.defaultProps = {
  ariaLabel: undefined,
  onToggle: () => { /* no op */ },
};

export default DropdownToggleButton;
