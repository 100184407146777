/* eslint-disable @typescript-eslint/naming-convention */
import { ButtonLooks, FontIcons, IconButton, IconButtonProps, StandardTooltip } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { FunctionComponent, SyntheticEvent, useState } from 'react';

import { CustomFieldKeyWithId, CustomFieldValue, DependentFieldValue } from '@api/v4/assets/customFieldTypes';

import { customFieldQuery, valuesAsString } from './helpers/custom-field-strings';

const searchButtonIconProps: IconButtonProps = {
  icon: FontIcons.Search,
  id: 'search-button',
  label: 'Search Button',
  look: ButtonLooks.Tertiary,
  type: 'button'
};

interface CustomFieldRow {
  customFieldKey: CustomFieldKeyWithId;
  customFieldValues: CustomFieldValue[];
  dependentFields?: DependentFieldValue[];
}

interface CustomFieldAccordionRowProps {
  customField: CustomFieldRow;
  closeModal: () => void;
}

export const CustomFieldAccordionRow: FunctionComponent<CustomFieldAccordionRowProps> =
  ({ customField, closeModal }) => {
    const { customFieldKey, customFieldValues, dependentFields } = customField;
    const [isOpen, setIsOpen] = useState(false);
    const rowClasses = classNames({
      's-custom-field': true,
      'hasDependents': dependentFields?.length,
      'isOpen': isOpen && dependentFields?.length,
    });

    const filterOnCustomFields = (e: SyntheticEvent, key: string, values: string[]): void => {
      e.preventDefault();
      e.stopPropagation();

      if (closeModal) {
        closeModal(); // method to close modal in org search context
      } else {
        BF.dialog.dismiss(); // method to close modal in all other contexts
      }

      const query = customFieldQuery(key, values);
      BF.fx.fillInAndFilterQuery(query);
    };

    const toggleShowDependentRows = (): void => setIsOpen(!isOpen);

    return !!customFieldKey && (
      <>
        <tr
          key={customFieldKey.id}
          className={`${rowClasses} cf-override-${BFG.currentUser.role}-${customFieldKey.name.replace(' ', '_')}`}
          onClick={!!dependentFields?.length ? toggleShowDependentRows : undefined}
        >
          <td className="toggle-caret">
            {!!dependentFields?.length && (
              <span
                aria-hidden="true"
                className={`${isOpen ? 'bff-caret-down' : 'bff-caret-right'}`}
              />
            )}
          </td>
          <td className="col-width-40 field-key">{customFieldKey.name}</td>
          <td className="col-width-60 field-value">{valuesAsString(customFieldValues)}</td>
          <td className="search-button">
            <StandardTooltip id="search-button-tooltip" tooltip={<Trans>Search custom field</Trans>}>
              <IconButton
                {...searchButtonIconProps}
                onClick={!BFG.showPageLite
                  ? (e): void =>
                    filterOnCustomFields(e, customFieldKey.name, customFieldValues.map(({ value }) => value))
                  : undefined}
              />
            </StandardTooltip>
          </td>
        </tr>
        {
          isOpen &&
          dependentFields?.map(({ id, name, value }) => (
            <tr
              key={id}
              className={rowClasses}
            >
              <td className="toggle-caret" />
              <td className="col-width-40 dependent-field field-key">{name}</td>
              <td className="col-width-60 dependent-field field-value">{valuesAsString(value)}</td>
              <td className="dependent-field search-button">
                <StandardTooltip id="search-button-tooltip" tooltip={<Trans>Search custom field</Trans>}>
                  <IconButton
                    {...searchButtonIconProps}
                    onClick={!BFG.showPageLite
                      ? (e): void => filterOnCustomFields(e, name, value.map((val) => val.value))
                      : undefined}
                  />
                </StandardTooltip>
              </td>
            </tr>
          ))
        }
      </>
    );
  };
