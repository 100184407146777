import React, { FunctionComponent } from 'react';

import { Asset } from '@api/v4/assets/assetTypes';


export const TextPreview: FunctionComponent<Asset> = (
  asset
) => (
  // placeholder component
  // currently text assets load directly via `tabs/asset_details/text` component
  // leaving this as is for now since we can't load text assets locally. This is because
  // Text subtype collides with the Translation gem's class of Text
  <div
    className="hi"
    data-private
  >
   Text
  </div>
);
