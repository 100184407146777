import { t, Trans, plural } from '@lingui/macro';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { CollectionGetResponse } from '@api/v4/collections';
import { DashEmptyLabel, EmptyLabelSizes } from '@components/library/empty-label';
import { StandardProgress } from '@components/library/progress';
import { OnlyForScreenReader } from '@components/library/screen-reader';
import { CircleStatus, Statuses } from '@components/library/status';
import { localizePercentage } from '@components/library/utils';
import { WorkspaceEvents } from '@components/workspace/WorkspaceEnums';
import { WorkspaceUpdatedEvent } from '@components/workspace/WorkspaceTypes';
import { localizeDate, localizeDateTime } from '@helpers/localize';

import './styles/workspace-progress-status.scss';

interface TaskCounts {
  completed: number;
  inProgress: number;
  notStarted: number;
}

const defaultTaskCounts: TaskCounts = {
  completed: null,
  inProgress: null,
  notStarted: null,
};

export const WorkspaceProgressStatus: FunctionComponent = () => {
  const [dueDate, setDueDate] = useState<string | null>(null);
  const [percentComplete, setPercentComplete] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [taskCounts, setTaskCounts] = useState<TaskCounts>(defaultTaskCounts);

  const workspaceProgressFetch = useFetch<CollectionGetResponse>({
    fields: ['completed_task_count', 'due_date', 'in_progress_task_count', 'not_started_task_count', 'progress'],
    url: `/api/v4/collections/${BFG.resource.key}`,
  });

  const handleWorkspaceUpdated = (e: WorkspaceUpdatedEvent): void => {
    if (e.detail?.dueDate) {
      setDueDate(e.detail.dueDate);
    }
    if (e.detail?.refresh) {
      setRefresh(e.detail?.refresh);
    }
  };

  useEffect(() => {
    window.addEventListener(WorkspaceEvents.WorkspaceUpdated, handleWorkspaceUpdated);

    return (): void => {
      window.removeEventListener(WorkspaceEvents.WorkspaceUpdated, handleWorkspaceUpdated);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (workspaceProgressFetch.response) {
      const {
        completed_task_count: completedTaskCount,
        due_date: dueDateResponse,
        in_progress_task_count: inProgressTaskCount,
        not_started_task_count: notStartedTaskCount,
        progress
      } = workspaceProgressFetch.response.data.attributes;

      setTaskCounts({
        completed: completedTaskCount,
        inProgress: inProgressTaskCount,
        notStarted: notStartedTaskCount,
      });

      if (dueDateResponse) {
        setDueDate(dueDateResponse);
      }

      setPercentComplete(progress);
    }
  }, [workspaceProgressFetch.response]);

  useEffect(() => {
    if (refresh) {
      workspaceProgressFetch.fetch();
      setRefresh(false);
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRemainingDays = (): string => {
    const dueDateMS = new Date(dueDate).getTime();
    if (dueDateMS < Date.now()) return t`Past due`;

    const remainingDays = Math.ceil((dueDateMS - Date.now()) / (1000 * 60 * 60 * 24));
    return plural(remainingDays, { one: '# day', other: '# days' });
  };

  const renderStatus = (heading: ReactNode, content: ReactNode): ReactNode => (
    <div className="workspace-progress-status__state">
      <h2 className="workspace-progress-status__state--heading">{heading}</h2>
      <p className="workspace-progress-status__state--content">{content}</p>
    </div>
  );

  const localizedPercentComplete = localizePercentage(BFG.locales.staticSiteLocale, percentComplete);
  const remainingDaysTilDue = dueDate ? getRemainingDays() : null;

  return (
    <div className="workspace-progress-status">
      <div className="workspace-progress-status__completion">
        {/* screen readers will read: "Workspace 8% Complete" (the progress element won't be read) */}
        <p>
          <Trans>
            <OnlyForScreenReader>Workspace{' '}</OnlyForScreenReader>
            <span className="workspace-progress-status__state--content">
              {localizedPercentComplete}
            </span>{' '}
            <span className="workspace-progress-status__state--heading">
              Complete
            </span>
          </Trans>
        </p>
        <StandardProgress
          animated
          aria-hidden
          id="workspace-progress-bar-graph"
          styleClassLabel="workspace-progress-status__completion--label"
          value={percentComplete}
        />
      </div>
      <div className="workspace-progress-status__due-date">
        {/* screen readers will read: "Workspace Due Date (Past Due) Friday, July 1, 2021" */}
        {/* OR */}
        {/* screen readers will read: "Workspace Due Date Not Specified" */}
        {renderStatus(
          dueDate ? (
            <Trans>
              <OnlyForScreenReader>Workspace{' '}</OnlyForScreenReader>
              Due Date&nbsp;
              {remainingDaysTilDue}
            </Trans>
          ) : (
            <Trans>
              <OnlyForScreenReader>Workspace{' '}</OnlyForScreenReader>
              Due Date
            </Trans>
          ),
          dueDate ? (
            <span
              // example: Friday, July 1, 2022
              aria-label={localizeDate(dueDate, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            >
              {localizeDateTime(dueDate, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric'
              })}
            </span>
          ) : (
            <DashEmptyLabel
              aria-label={t`Not Specified`}
              data-testid="due-date-loading"
              size={EmptyLabelSizes.Medium}
            />
          )
        )}
      </div>
      <ul className="workspace-progress-status__breakdown">
        <li className="workspace-progress-status__complete">
          {/* screen readers will read: "Tasks Completed 0" */}
          {/* OR */}
          {/* screen readers will read: "Tasks Completed Not Specified" while loading */}
          {renderStatus(
            <Trans>
              <OnlyForScreenReader>
                Tasks{' '}
              </OnlyForScreenReader>
              Completed
            </Trans>,
            <>
              {/* TODO: find a way to improve localization here */}
              <CircleStatus aria-hidden status={Statuses.Completed} />
              {taskCounts.completed === null ? (
                <DashEmptyLabel
                  aria-label={t`Not Specified`}
                  size={EmptyLabelSizes.Medium}
                />
              ) : <>{' '}{taskCounts.completed}</>}
            </>
          )}
        </li>
        <li className="workspace-progress-status__in-progress">
          {/* screen readers will read: "Tasks In Progress 0" */}
          {/* OR */}
          {/* screen readers will read: "Tasks In Progress Not Specified" while loading */}
          {renderStatus(
            <Trans>
              <OnlyForScreenReader>
                Tasks{' '}
              </OnlyForScreenReader>
              In Progress
            </Trans>,
            <>
              <CircleStatus aria-hidden status={Statuses.InProgress} />
              {taskCounts.inProgress === null ? (
                <DashEmptyLabel
                  aria-label={t`Not Specified`}
                  size={EmptyLabelSizes.Medium}
                />
              ) : <>{' '}{taskCounts.inProgress}</>}
            </>
          )}
        </li>
        <li className="workspace-progress-status__not-started">
          {/* screen readers will read: "Tasks Not Started 0" */}
          {/* OR */}
          {/* screen readers will read: "Tasks Not Started Not Specified" while loading */}
          {renderStatus(
            <Trans>
              <OnlyForScreenReader>
                Tasks{' '}
              </OnlyForScreenReader>
              Not Started
            </Trans>,
            <>
              <CircleStatus aria-hidden status={Statuses.NotStarted} />
              {taskCounts.notStarted === null ? (
                <DashEmptyLabel
                  aria-label={t`Not Specified`}
                  size={EmptyLabelSizes.Medium}
                />
              ) : <>{' '}{taskCounts.notStarted}</>}
            </>
          )}
        </li>
      </ul>
    </div>
  );
};
