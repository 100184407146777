import { i18n } from '@lingui/core';

import { Locale } from '@components/common/language_menu/languagesMap';

import { messages as englishMessages } from '../locales/en/messages';

type FullyQualifiedLocales = 'en'
| 'bg'
| 'zh-Hans'
| 'cs'
| 'nl'
| 'fi'
| 'fr'
| 'de'
| 'el'
| 'hu'
| 'it'
| 'ja'
| 'ko'
| 'nb'
| 'pl'
| 'pt-BR'
| 'ro'
| 'ru'
| 'es'
| 'sv'
| 'th'
| 'tr'
| 'vi';

const loadAndActivate = (locale: Locale, messages: Record<string, any>): void => {
  i18n.load(locale, messages);
  i18n.activate(locale);
};

// fixes unhelpful 'I18nProvider did not render' error - ensures that at least one locale is loaded
loadAndActivate(Locale.English, englishMessages);

interface DynamicActivateOptions {
  providedLocale?: Locale;
  publicPath?: string;
}

const fullLocale = (locale: Locale): FullyQualifiedLocales => {
  /**
   * the locale that is passed in via the language map is always a two-character locale
   * which is not accurate for something like Mandarin (zh-Hans) or Brazilian Portuguese (pt-BR) so we transform here
   * to avoid breaking other parts of the translation pipeline that are expecting zh and pt
   */
  if (locale === Locale.Chinese || locale === Locale.ChineseChina || locale === Locale.ChineseTaiwan) {
    // this would be inaccurate if we began supporting zh-cn or zh-tw in Brandfolder but we currently do not
    return 'zh-Hans';
  }

  if (locale === Locale.Portuguese) {
    return 'pt-BR';
  }

  return locale;
};

export const dynamicActivate = async (options?: DynamicActivateOptions): Promise<void> => {
  const { providedLocale } = { ...options };
  const locale = providedLocale || Locale.English;
  const { messages } = await import(`../locales/${fullLocale(locale)}/messages`);

  loadAndActivate(locale, messages);
};
