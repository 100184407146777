/**
 * Find an API key at https://brandfolder.com/profile#integrations.
 * DO NOT push to Git an actual API key (just for local testing only).
 */
const localApiKey = '<hardcoded-api-token>';

/**
 * Find Brandfolder key by running BFG.brandfolder_key in a browser console
 * while on a Brandfolder, Collection, or Workspace.
 * DO NOT push to Git an actual API key (just for local testing only).
 */
const localBrandfolderKey = '<hardcoded-brandfolder-key>';

/**
 * Find resource key by running BFG.resource.key in a browser console
 * while on a Brandfolder, Collection, or Workspace.
 * DO NOT push to Git an actual API key (just for local testing only).
 */
const localResourceKey = '<hardcoded-resource-key>';

/**
 * Get where the environment is local (development) or not.
 * @returns boolean
 */
export const getIsLocal = (): boolean => {
  return process?.env?.NODE_ENV === 'development' || false;
};

/**
 * Use localApiKey or apiKey for Bearer token for API calls.
 * @param apiKey string | undefined
 * @returns string
 */
export const getIntegrationsContentSyncApiKey = (apiKey: string): string => {
  return getIsLocal() ? localApiKey : apiKey;
};

/**
 * Use localBrandfolderKey orBFG.brandfolder_key.
 * @returns string
 */
export const getIntegrationsContentSyncBrandfolderKey = (): string => {
  return getIsLocal() ? localBrandfolderKey : BFG.brandfolder_key;
};

/**
 * Use localResourceKey or BFG.resource.key.
 * @returns
 */
export const getIntegrationsContentSyncResourceKey = (): string => {
  return getIsLocal() ? localResourceKey : BFG.resource.key;
};

/**
 * TODO: These should probably be in a .env
 * @returns string
 */
export const getIntegrationsContentSyncUrl = (): string => {
  return BFG.environment === 'staging'
    ? 'https://bf-stage.com/api/v1/contentsync'
    : 'https://brandfolder.com/api/v1/contentsync';
};

export interface UploadPreferences {
  data: {
    attributes: {
      organization_key: string; // eslint-disable-line @typescript-eslint/naming-convention
      brandfolder_key: string; // eslint-disable-line @typescript-eslint/naming-convention
      section_key: string; // eslint-disable-line @typescript-eslint/naming-convention
    }
  }
}
