import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

class GuestUploadsRow extends React.Component {
  state = {
    deleteMode: false
  }

  toggleDeleteMode = () => {
    this.setState((prevState) => ({
      deleteMode: !prevState.deleteMode,
      editMode: !prevState.deleteMode ? false : prevState.editMode
    }));
  }

  deleteLink = () => {
    const { link, deleteGuestUploadLink } = this.props;
    deleteGuestUploadLink(link.key);
  }

  renderLink() {
    const { key, created_at, description, named_section, created_by, automatic_approval } = this.props.link;
    return (
      <React.Fragment>
        <span className="ten">
          <a
            href={`/guest_upload/${key}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Visit
          </a>
        </span>
        <span className="twenty">
          <a
            href={`/${BF.fx.brandfolder().slug}?q=section.strict:"${encodeURIComponent(named_section)}"`}
            rel="noopener noreferrer"
            target="_blank"
          >
          {named_section}
          </a>
        </span>
        <span className="twenty">
          {description?.length > 0 ? description : '-'}
        </span>
        <span className="ten" title={created_by}>
          {created_by}
        </span>
        <span className="ten center">
          { this.props.formatDate(created_at) }
        </span>
        <span className="ten center">
        {automatic_approval.toString()}
        </span>
        <button
          className="delete-upload-button five center"
          onClick={this.toggleDeleteMode}
          type="button"
        >
          <span className="bff-trash" />
        </button>
      </React.Fragment>
    );
  }

  renderDelete() {
    const { key } = this.props.link;
    return (
      <span className="delete-upload-mode">
        <div className="upload-link-info">
          <span className="ten">
            <a
              href={`/guest_upload/${key}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Trans>Visit</Trans>
            </a>
          </span>
        </div>
        <div className="delete-buttons">
          <button
            className="button tertiary cancel"
            onClick={this.toggleDeleteMode}
            type="button"
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            className="button warning"
            onClick={this.deleteLink}
            type="button"
          >
            <Trans>Delete Link</Trans>
          </button>
        </div>
      </span>
    );
  }

  render() {
    if (this.state.deleteMode) { return this.renderDelete(); }
    return this.renderLink();
  }
}

GuestUploadsRow.propTypes = {
  link: PropTypes.shape({
    key: PropTypes.string,
    created_at: PropTypes.string,
    description: PropTypes.string,
    enabled: PropTypes.bool,
    created_by: PropTypes.string,
    named_section: PropTypes.string,
    automatic_approval: PropTypes.bool,
  }).isRequired,
  deleteGuestUploadLink: PropTypes.func.isRequired,
  formatDate: PropTypes.func.isRequired
};

export default GuestUploadsRow;
