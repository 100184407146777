/**
 * Debounce a function call by a number of milliseconds (defaults to 300ms)
 * Useful for resize event listeners and autocomplete/typeahead inputs.
 * Taken from https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940.
 * @param {F} func Function
 * @param {number} milliseconds number
 */
export const debounce = <F extends (...args: unknown[]) => unknown>(func: F, milliseconds = 300): (...args: Parameters<F>) => ReturnType<F> => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>): void => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), milliseconds);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};
