import { FontIcon, FontIcons, OnlyForScreenReader, StandardLink } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface EnableMultiLanguageAssetDetails {
  defaultEnabled: boolean | null;
}

const MultiLanguageAssetDetails: FunctionComponent<EnableMultiLanguageAssetDetails> = ({
  defaultEnabled
}) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled || false}
      description={
        <StandardLink
          className="mlad-article-link"
          href="https://help.smartsheet.com/360048044553-Multi-Language-Asset-Details-and-Bulk-Translations"
          target="_blank"
        >
          <span className="mlad-article-link--underline">
            <Trans>Learn more</Trans>
          </span>
          <OnlyForScreenReader><Trans>Opens in a new tab</Trans></OnlyForScreenReader>
          <FontIcon aria-hidden icon={FontIcons.NewTab} />
        </StandardLink>
      }
      errorTitle={t`Error updating Multi Language Asset Details setting. Please contact support.`}
      id='enable-multi-language-asset-details'
      successTitle={t`Multi Language Asset Details setting updated!`}
      title={t`Multi Language Asset Details`}
      updateResourceKey='multiLanguageAssetDetailsEnabled'
    />
  </I18nProviderWrapper>
);

export default MultiLanguageAssetDetails;
