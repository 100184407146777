import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseThProps } from '../BaseTableProps';

export const BaseTh: FunctionComponent<BaseThProps> = (props) => {
  const { children, className, ...otherProps } = props;
  return (
    <th {...otherProps} className={classnames('baseTh', className)}>{children}</th>
  );
};
