import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { PermissionsGetResponse } from '@api/v4/user_groups/user-group-permissions/UserGroupPermissionsTypes';
import { basePermissionFetchOptions } from '@components/bulk_management/user_groups/helpers';
import { UserGroupsAddPermissionsForm } from '@components/bulk_management/user_groups/UserGroupsAddPermissionsForm';
import { UserGroupsPermissionsTable } from '@components/bulk_management/user_groups/UserGroupsPermissionsTable';

import classes from './styles/user-groups.module.scss';

interface PermissionsTabProps {
  userGroupKey: string;
}

export const PermissionsTab: FunctionComponent<PermissionsTabProps> = ({ userGroupKey }) => {
  const permissionsFetch = useFetch<PermissionsGetResponse>(basePermissionFetchOptions(userGroupKey));

  return (
    <div className={classnames(classes.permissionsPage, 'user-groups-permissions-page')}>
      <UserGroupsAddPermissionsForm permissionsFetch={permissionsFetch} userGroupKey={userGroupKey}/>
      <UserGroupsPermissionsTable permissionsFetch={permissionsFetch} userGroupKey={userGroupKey}/>
    </div>
  );
};
