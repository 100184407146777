import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import AssetNameInput from '@components/asset/modal/tabs/edit/side_bar/assetNameInput';

const TextSideBar: FunctionComponent = () => (
  <div className="edit-tab-sidebar one-third bf-scroll-element">
    <AssetNameInput assetNameCopy={t`Name`} />
  </div>
);

export default TextSideBar;
