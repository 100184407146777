import { t, Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Datetime from 'react-datetime';
import 'moment-timezone';

import { ListDropdown, ListOpenDirections } from '@components/library/dropdown';
import { getMomentLocale } from '@helpers/locale';

import 'react-datetime/css/react-datetime.css';

import './styles/date_time_picker.scss';

export default class DateTimePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayDateError: false,
      displayTimeError: false,
      hasRecentlyShownDateError: false,
      hasRecentlyShownTimeError: false,
      timezoneArray: [],
      validDate: null
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { allowPastDates } = this.props;

    const yesterday = moment().subtract(1, 'day');
    const validDate = allowPastDates ? () => true : (current) => current.isAfter(yesterday);
    const timezoneNames = moment.tz.names();
    const timezoneArray = timezoneNames.map((name) => ({ value: name, label: name }));

    this.setState({ validDate, timezoneArray });
  }

  componentDidUpdate(prevProps, prevState) {
    const { displayDateError, displayTimeError} = this.state;
    if (displayDateError && !prevState.displayDateError) {
      Notify.create({
        type: "error",
        title: t`Please select a valid date`
      });
    }

    if (displayTimeError && !prevState.displayTimeError) {
      Notify.create({
        type: "error",
        title: t`Please select a valid time`
      });
    }
  }

  handleUpdateDateTime(selectedDate, selectedTime, selectedZone) {
    const { updateDateTime, allowPastDates, onDateValidityChange } = this.props;

    const parsedDate = moment(selectedDate, 'MM/DD/YYYY', true);
    const parsedTime = moment(selectedTime, 'hh:mm a', true);
    const currentDate = moment();

    let displayDateError = false;
    let displayTimeError = false;
    let isValid = true;

    if (!parsedDate.isValid() || (!allowPastDates && parsedDate.isBefore(currentDate, "day"))) {
      displayDateError = true;
      isValid = false;
    }

    if (!parsedTime.isValid() || (!allowPastDates && parsedTime.isBefore(currentDate, "day"))) {
      displayTimeError = true;
      isValid = false;
    }

    this.setState({ displayDateError, displayTimeError });
    onDateValidityChange(isValid);

    if (isValid) {
      selectedZone = selectedZone || moment.tz.guess();
      updateDateTime(selectedDate, selectedTime, selectedZone);
    }
  }

  render() {
    const {
      displayCriterium,
      openDirection,
      selectedDate,
      selectedTime,
      selectedZone
    } = this.props;

    if (!displayCriterium) { return null; }

    const { validDate, timezoneArray } = this.state;
    const momentLocale = getMomentLocale(BFG.locales.staticSiteLocale);
    const inputDateClassName = this.state.displayDateError ? 'inputs invalid' : 'inputs';
    const inputTimeClassName = this.state.displayTimeError ? 'inputs invalid' : 'inputs';

    return (
      <div className="date-time-picker-wrapper">
        <div className="date-picker-container picker-container">
          <label className="selection-label">
            <Trans>Date</Trans>
          </label>
          <Datetime
            className="t-date-selector"
            closeOnSelect
            inputProps={{ className: inputDateClassName }}
            isValidDate={validDate}
            locale={momentLocale}
            onChange={(date) => {
              this.handleUpdateDateTime(typeof date === 'string' ? date : date.format('MM/DD/YYYY'), selectedTime, selectedZone);
            }}
            timeFormat={false}
            utc
            value={selectedDate}
          />
          <span className="bff-calendar icon" />
        </div>
        <div className="time-picker-container picker-container">
          <label className="selection-label">
            <Trans>Time</Trans>
          </label>
          <Datetime
            closeOnSelect
            dateFormat={false}
            inputProps={{ className: inputTimeClassName }}
            isValidDate={validDate}
            locale={momentLocale}
            onChange={(time) => {
              this.handleUpdateDateTime(selectedDate, typeof time === 'string' ? time : time.format('hh:mm a'), selectedZone);
            }}
            utc
            value={selectedTime}
          />
        </div>
        <div className="timezone-container select-wrapper picker-container">
          <label className="selection-label">
            <Trans>Timezone</Trans>
          </label>
          <ListDropdown
            id="date-time-picker-timezone"
            onChange={(timezone) => {
              this.handleUpdateDateTime(selectedDate, selectedTime, timezone ? timezone.value : timezone);
            }}
            openDirection={openDirection || ListOpenDirections.Down}
            options={timezoneArray}
            searchable
            value={selectedZone}
            virtualizeOptions
          />
        </div>
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  allowPastDates: PropTypes.bool,
  displayCriterium: PropTypes.bool,
  onDateValidityChange: PropTypes.func,
  openDirection: PropTypes.any,
  selectedDate: PropTypes.string,
  selectedTime: PropTypes.string,
  selectedZone: PropTypes.string,
  updateDateTime: PropTypes.func.isRequired
};

DateTimePicker.defaultProps = {
  allowPastDates: false,
  displayCriterium: true,
  onDateValidityChange: () => {},
  openDirection: null,
  selectedDate: moment().format('MM/DD/YYYY'),
  selectedTime: moment().format('hh:mm a'),
  selectedZone: moment.tz.guess()
};
