import classnames from 'classnames';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { AccordionPanelProps, AccordionAnimations } from '@components/library/accordion';

import classes from '../styles/accordion_panel.module.scss';

interface AccordionPanelComponentProps {
  accordionButtonId: string;
  children: ReactNode;
  id: string;
  open: boolean | undefined;
  animation?: AccordionAnimations;
  loading?: boolean;
  panelProps?: AccordionPanelProps;
  unmountOnExit?: boolean;
}

/**
 * Accordion panel content.
 * @param {AccordionPanelProps} props AccordionPanelProps
 */
export const AccordionPanel: FunctionComponent<AccordionPanelComponentProps> = (props) => {
  const {
    accordionButtonId,
    animation = AccordionAnimations.FadeInFromTop,
    children,
    id,
    loading,
    open,
    unmountOnExit,
    panelProps
  } = props;

  const [hidden, setHidden] = useState(true);
  const [initial, setInitial] = useState(true);

  return (
    <CSSTransition
      classNames={{
        enter: classes[`${animation}Enter`],
        enterActive: classes[`${animation}EnterActive`],
        enterDone: classes[`${animation}EnterDone`],
        exit: classes[`${animation}Exit`],
        exitActive: classes[`${animation}ExitActive`],
      }}
      in={open}
      onEnter={(): void => setHidden(false)}
      onEntered={(): void => setInitial(false)}
      onExited={(): void => setHidden(true)}
      timeout={!initial && !loading ? 200 : 0}
      unmountOnExit={unmountOnExit}
    >
      <section
        {...panelProps}
        aria-labelledby={accordionButtonId}
        className={classnames('accordion-panel', classes.accordionPanel, panelProps?.className)}
        hidden={hidden}
        id={id}
      >
        {children}
      </section>
    </CSSTransition>
  );
};
