import PropTypes from 'prop-types';
import React from 'react';

import { generateAssetDeepLink } from '@helpers/assets';

import './styles/attachment_card.scss';

// Note: ./simple_asset_card is a rip-off of this component and also relies on the styles
// so that assets can be rendered to look like attachments
const AttachmentCard = ({ assetKey, attachment }) => {
  const href = generateAssetDeepLink({ assetKey });

  return (
    <li className="attachment-card-component">
      <a href={href} rel="noopener noreferrer" target="_blank">
        <div className="card-head">
          <div className="card-head__thumbnail">
            <img alt="thumbnail" data-private src={attachment.thumbnail_url} />
          </div>
        </div>
        <div className="card-description">
          <p className="attachment-name">{attachment.filename}</p>
          <div className="file-type-pill">
            <span>{attachment.extension}</span>
          </div>
        </div>
      </a>
    </li>
  );
};

AttachmentCard.propTypes = {
  assetKey: PropTypes.string.isRequired,
  attachment: PropTypes.shape({}).isRequired,
};

export default AttachmentCard;
