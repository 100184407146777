import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseDialogProps } from '@components/library/dialog';
import { BaseDialog } from '@components/library/dialog/base_dialog';

import classes from './iframe_dialog.module.scss';

type BaseDialogPropsExtended = Omit<BaseDialogProps, 'actions' | 'children' | 'loading' | 'position' | 'showFooter' | 'type'>

interface IframeDialogProps extends BaseDialogPropsExtended {
    /** className is applied to the iframe element */
    className?: string;
    /** the src of the iframe element */
    src: string;
    /** the title of the modal and the title on the iframe element */
    title: string;
}

/**
 * A modal for displaying iframes, such as a Smartsheet form iframe
 * @param {IframeDialogProps} props IframeDialogProps
 */
export const IFrameDialog: FunctionComponent<IframeDialogProps> = (props) => {
  const { className, src, title, ...baseProps } = props;

  return (
    <BaseDialog
      {...baseProps}
      dialogBodyClassName={classnames(classes["iframe-dialog--body"], classes["iframe-dialog--body"], baseProps.dialogBodyClassName)}
      dialogClassName={classnames(classes["iframe-dialog"], baseProps.dialogClassName)}
      showFooter={false}
      title={title}
    >
      <iframe
        className={classnames(classes["iframe-dialog--iframe"], className)}
        src={src}
        title={title}
      />
    </BaseDialog>
  );
};
