interface DefaultPropValueObject {
  table: {
    defaultValue: {
      summary: any;
    };
  };
}

export const defaultPropValue = (value: any): DefaultPropValueObject => ({
  table: { defaultValue: { summary: value } }
});

interface ComponentDescriptionObject {
  docs: {
    description: {
      component: string;
    };
  };
}

export const componentDescription = (description: string): ComponentDescriptionObject => ({
  docs: {
    description: {
      component: description,
    },
  },
});
