import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTable, BaseTableProps } from '../../base';

import classes from '../styles/standard-table.module.scss';

export const Table: FunctionComponent<BaseTableProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <BaseTable
      {...otherProps}
      className={classnames('standardTable', classes.table, props.className)}
    >
      {children}
    </BaseTable>
  );
};
