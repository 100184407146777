import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseScreenReaderProps } from '@components/library/screen-reader';

const baseName = 'screen-reader';

export const BaseScreenReader: FunctionComponent<BaseScreenReaderProps> = ({
  children,
  className,
  tag: Tag = 'span',
  variantName,
  ...screenReaderProps
}) => (
  <Tag
    {...screenReaderProps}
    className={classnames(
      `bf-${baseName}__${variantName}`,
      className
    )}
  >
    {children}
  </Tag>
);
