import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { Organization } from './customReportTypes';
import { Home } from './Home';
import { PageNotFound } from './PageNotFound';
import { ReportsIndex } from './ReportsIndex';

interface ReportProps {
  customReports: boolean;
  organization: Organization;
  orgApiKey: string;
}

export const Reports: FunctionComponent<ReportProps> = ({ organization, orgApiKey, customReports }) => {
  const path = `/organizations/${organization.slug}/standard_reports`;
  return (
    <I18nProviderWrapper>
      <BrowserRouter basename='/'>
        <Routes>
          <Route path={path}>
            <Route element={<Home customReports={customReports} organization={organization}/>} index />
            <Route element={
              <ReportsIndex
                customReports={customReports}
                orgApiKey={orgApiKey}
                organization={organization}
                reportDescription={t`User engagement with assets, including views, downloads and shares.`}
                reportTitle={t`User Engagement With Assets`}
                reportType={'user_engagement_with_assets_report'}
              />}
              path='user-engagement-with-assets'
            />
            <Route element={
              <ReportsIndex
                customReports={customReports}
                orgApiKey={orgApiKey}
                organization={organization}
                reportDescription={t`See your top-performing assets including views, shares, and downloads. Provides good indicators of healthy and active assets.`}
                reportTitle={t`Asset Performance by Brandfolder`}
                reportType={'asset_performance_report'}
              />}
              path='asset-performance-by-brandfolder'
            />
            <Route element={
              <ReportsIndex
                customReports={customReports}
                orgApiKey={orgApiKey}
                organization={organization}
                reportDescription={t`See your most and least active users.`}
                reportTitle={t`User Activity by Organization`}
                reportType={'user_activity_report'}
              />}
              path='user-activity-by-organization'
            />
            <Route element={
              <ReportsIndex
                customReports={customReports}
                orgApiKey={orgApiKey}
                organization={organization}
                reportDescription={t`Top-performing Brandfolders and Collections based on all asset activity within them.`}
                reportTitle={t`Brandfolder and Collection Activity`}
                reportType={'brandfolder_and_collection_activity_report'}
              />}
              path='brandfolder-and-collection-activity'
            />
            <Route element={
              <ReportsIndex
                customReports={customReports}
                orgApiKey={orgApiKey}
                organization={organization}
                reportDescription={t`See your most and least active share links.`}
                reportTitle={t`Share Link Activity`}
                reportType={'sharelink_activity_report'}
              />}
              path='share-link-activity'
            />
            <Route element={
              <ReportsIndex
                customReports={customReports}
                orgApiKey={orgApiKey}
                organization={organization}
                reportDescription={t`Shows when a user was last active in your Brandfolder. Can be used to reallocate your organization’s seats.`}
                reportTitle={t`User Last Active Date`}
                reportType={'user_last_active_date_report'}
              />}
              path='user-last-active-date'
            />
            <Route element={
              <ReportsIndex
                customReports={customReports}
                orgApiKey={orgApiKey}
                organization={organization}
                reportDescription={t`Shows what your users are searching for.`}
                reportTitle={t`Popular Search Terms`}
                reportType={'popular_search_terms_report'}
              />}
              path='popular-search-terms'
            />
            <Route element={
              <ReportsIndex
                customReports={customReports}
                orgApiKey={orgApiKey}
                organization={organization}
                reportDescription={t`View unique resource page views.`}
                reportTitle={t`Unique Page Views`}
                reportType={'unique_page_views_report'}
              />}
              path='unique-page-views'
            />
            <Route element={
              <ReportsIndex
                customReports={customReports}
                orgApiKey={orgApiKey}
                organization={organization}
                reportDescription={t`View CDN link activity by both hits and bandwidth usage.`}
                reportTitle={t`CDN Link Activity`}
                reportType={'cdn_link_activity_report'}
              />}
              path='cdn-link-activity'
            />
            <Route element={<PageNotFound organization={organization} /> } path='*'/>
          </Route>
        </Routes>
      </BrowserRouter>
    </I18nProviderWrapper>
  );
};
