import React, { FunctionComponent, useState } from 'react';

import { PanelRow } from './DependencyViewAccordion';
import { DependencyViewAccordionDcfRowCell } from './DependencyViewAccordionDcfRowCell';

export interface DependencyViewAccordionPanelProps {
  checkedFields: string[];
  deleteDependentFields: (deleteFieldIds: string[]) => void;
  panelRow: PanelRow;
  setCheckedFields: SetStateDispatch<string[]>;
}

export const DependencyViewAccordionPanel: FunctionComponent<DependencyViewAccordionPanelProps> =
  ({ panelRow, deleteDependentFields, checkedFields, setCheckedFields }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dependentFields = panelRow.dependentRows.filter((row) => row.dependentCustomFields?.length);
    const hasDependentFields = !!dependentFields?.length;

    return (
      <>
        <tr className="dcf-accordion-panel" role="grid">
          <td onClick={(): void => setIsOpen(!isOpen)} role="gridcell">
            {hasDependentFields && dependentFields.length > 1 ? (
              <span
                aria-hidden="true"
                className={`${isOpen ? 'bff-caret-down' : 'bff-caret-right'}`}
              />
            ) : (
              <span className="no-caret" />
            )}
            <span className="dcf-accordion-panel-rowCell">
              {panelRow.customFieldName}
            </span>
          </td>
          <td>
            {hasDependentFields && panelRow.dependentRows[0].value && (
              <span className="dcf-accordion-panel-rowValue">
                <span className="bff-connector" />
                <span>{panelRow.dependentRows[0].value}</span>
              </span>
            )}
          </td>
          <td className="col-width-50">
            {hasDependentFields && (
              <DependencyViewAccordionDcfRowCell
                checkedFields={checkedFields}
                deleteDependentFields={deleteDependentFields}
                dependentCustomFields={
                  panelRow.dependentRows[0]?.dependentCustomFields
                }
                setCheckedFields={setCheckedFields}
              />
            )}
          </td>
        </tr>
        {isOpen &&
          panelRow.dependentRows?.map(
            (row, index) =>
              index !== 0 && (
                <tr key={row.value} className="dcf-accordion-panel dcf-row">
                  <td />
                  <td>
                    <span className="dcf-accordion-panel-rowValue">
                      <span className="bff-connector" />
                      <span>{row.value}</span>
                    </span>
                  </td>
                  <td className="col-width-50">
                    {hasDependentFields && (
                      <DependencyViewAccordionDcfRowCell
                        checkedFields={checkedFields}
                        deleteDependentFields={deleteDependentFields}
                        dependentCustomFields={row.dependentCustomFields}
                        setCheckedFields={setCheckedFields}
                      />
                    )}
                  </td>
                </tr>
              )
          )}
      </>
    );
  };
