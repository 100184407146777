import { fetchJson } from '@api/ApiHelper';

/**
 * Check is the current user already has an Insights session cookie.
 * @returns boolean
 */
export const hasInsightsSession = (): boolean => document.cookie.indexOf('_insights_session') >= 0;

/**
 * Create an Insights session (creates a browser cookie).
 */
export const createInsightsSession = (): Promise<unknown> => {
  return fetchJson({
    credentials: 'include',
    method: 'POST',
    url: `${BFG.INSIGHTS_API_URL}/ingestion/api/v1/sessions`
  });
};
