import { keyPressHelper } from '@brandfolder/utilities';
import React, {
  useEffect,
  useRef,
  FunctionComponent,
  KeyboardEvent,
  LiHTMLAttributes,
  SyntheticEvent,
  ReactNode,
  CSSProperties,
} from 'react';
import classnames from 'classnames';

import { BaseDropdownItemProps } from '@components/library/dropdown_item/DropdownItemProps';
import { FontIcon, FontIcons } from '@components/library/icon';

const baseName = 'dropdown-item';

const BaseDropdownItem: FunctionComponent<
BaseDropdownItemProps & LiHTMLAttributes<HTMLLIElement>
> = (props) => {
  const {
    children,
    className,
    icon = '',
    iconSize,
    id,
    indentation = 0,
    isArrowed,
    isDisabled = false,
    isDisplayed = true,
    isEmpty = false,
    isSelected,
    isTabbable = true,
    isWarningItem = false,
    name,
    onChoose,
    styleClassNames = {}, // default value for testing (required prop)
    variantName = '', // default value for testing (required prop)
    ...liProps
  } = props;

  const itemRef = useRef<HTMLLIElement | null>(null);

  // Allows for negative values
  // Negative values will result in no indentation displayed (same as 0)
  // Values greather than 10 result in indentation of 10
  const indentationConstrained = indentation > 10 ? 10 : indentation;

  const observerCallback = (entries: any): void => {
    if (isArrowed && itemRef?.current && entries[0].intersectionRatio < 0.9) {
      // if less than 90% of item is showing, it will scroll to display the item
      itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (itemRef?.current) {
      const observer = new IntersectionObserver(observerCallback);
      observer.observe(itemRef.current);
      return (): void => {
        observer.disconnect();
      };
    }
    return undefined;
  }, [isArrowed, itemRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const onKeyPressHandler = keyPressHelper(onChoose);

  if (!isDisplayed) return null;

  const renderIcon = (): ReactNode => {
    if (!icon) return null;

    const inlineIconStyle: CSSProperties = iconSize
      ? { fontSize: `${iconSize}px` }
      : undefined;

    if (icon.includes('bff-')) {
      return (
        <span
          aria-hidden
          className={`${icon} ${styleClassNames.icon || ''}`}
          role="img"
          style={inlineIconStyle}
        />
      );
    }
    return (
      <FontIcon
        aria-hidden
        className={styleClassNames.icon}
        icon={icon as FontIcons}
        style={inlineIconStyle}
      />
    );
  };

  return (
    <li
      {...liProps}
      ref={itemRef}
      aria-selected={isArrowed}
      className={classnames(
        `bf-${baseName}__${variantName}`, // base-variant class for post consumption styling
        styleClassNames[variantName], // class for css modules
        className, // user specified class
        { [styleClassNames[`${variantName}-warning`]]: isWarningItem },
        { [styleClassNames.selected]: isSelected },
        { [styleClassNames.arrowed]: isArrowed },
        { [styleClassNames.disabled]: isDisabled },
        { [styleClassNames.empty]: isEmpty },
        { [styleClassNames[`indented${indentationConstrained}`]]: indentationConstrained }
      )}
      id={id || name?.toString()}
      onClick={(e: SyntheticEvent): void => {
        if (onChoose) {
          onChoose(name, e);
        }
      }}
      onKeyPress={(e: KeyboardEvent<HTMLLIElement>): void => {
        onKeyPressHandler(e, name);
      }}
      role="option"
      tabIndex={isTabbable ? 0 : undefined}
    >
      {icon && renderIcon()}
      {children}
    </li>
  );
};

export default BaseDropdownItem;
