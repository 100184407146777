import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from './styles/card.module.scss';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const Card: FunctionComponent<CardProps> = (props) => {
  const { children } = props;

  return (
    <div className={classes.card}>
      {children}
    </div>
  );
};
