import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import {
  Action,
  FormState,
} from '@components/bulk_management/automation/AutomationTypes';
import { FieldSummaryIcon } from '@components/bulk_management/automation/form/FieldSummaryIcon';
import { getFieldDetails } from '@components/bulk_management/automation/helpers';
import { summarizeAction, summarizeTrigger } from '@components/bulk_management/automation/summary-helpers';
import { TextButton } from '@components/library/button';
import { FontIcons } from '@components/library/icon';

interface FieldSummaryProps {
  selectorValues: Partial<FormState> & Partial<Action>;
  setFieldOpen: SetStateDispatch<boolean>;
}

export const FieldSummary: FunctionComponent<FieldSummaryProps> = ({
  selectorValues,
  setFieldOpen
}) => {
  const {
    actionableId,
  } = selectorValues;

  const {
    fieldKeys,
    fieldType,
    options,
    values,
  } = getFieldDetails(selectorValues);

  return (
    <div className="automation-field-summary">
      <div className="automation-field-summary__details">
        <FieldSummaryIcon fieldType={fieldType} />
        <p className="automation-field-summary__details__text">{actionableId
          ? summarizeAction(fieldKeys, fieldType, values, options)
          : summarizeTrigger(fieldKeys, fieldType, values)}
        </p>
      </div>
      <TextButton
        aria-label={actionableId ? t`edit action` : t`edit trigger`}
        className="edit-automation-field-button"
        icon={FontIcons.Edit}
        iconSize={14}
        onClick={(): void => setFieldOpen(true)}
      >
        {actionableId ? <Trans>Edit Action</Trans> : <Trans>Edit Trigger</Trans>}
      </TextButton>
    </div>
  );
};
