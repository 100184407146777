import { TileButton, TileButtons } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import { FormikErrors, FormikTouched } from 'formik';
import React, { FunctionComponent } from 'react';

import {
  DesignHuddleDimensionsClient,
  DesignHuddleLimits,
  DesignHuddleUnitTypes
} from '@api/v4/private/resources/design_huddles';
import { ListDropdown, ListOption } from '@components/library/dropdown';
import { InputType, StandardTextField } from '@components/library/text-field';
import { ValidationAlert } from '@components/show_page/sections/asset/custom-templates/ValidationAlert';
import {
  AllCanvasSizes,
  CanvasSizes,
  CreateNewAssetTypes,
  CreateProjectValues
} from '@components/show_page/sections/asset/custom-templates';
import {
  getCanvasSizeTranslation,
  getCustomDimensions,
  getCustomDimensionsUnitTypes
} from '@components/show_page/sections/asset/custom-templates/helpers';

import './styles/choose-canvas-size.scss';

interface ChooseCanvasSizeProps {
  assetType: CreateNewAssetTypes;
  canvasSize: CanvasSizes;
  canvasSizes: AllCanvasSizes;
  dimensions: DesignHuddleDimensionsClient;
  errors: FormikErrors<CreateProjectValues>;
  hasSubmitted: boolean;
  initialValues: CreateProjectValues;
  isCustom: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  touched: FormikTouched<CreateProjectValues>;
  validateField: (field: string) => void;
}

export const ChooseCanvasSize: FunctionComponent<ChooseCanvasSizeProps> = (props) => {
  const {
    assetType,
    canvasSize,
    canvasSizes,
    dimensions,
    errors,
    hasSubmitted,
    initialValues,
    isCustom,
    setFieldValue,
    setFieldTouched,
    touched,
    validateField
  } = props;

  const getDimension = (): number => {
    if (dimensions.unitTypeId === DesignHuddleUnitTypes.Inches) {
      return DesignHuddleLimits.Inches;
    }
    if (dimensions.unitTypeId === DesignHuddleUnitTypes.Millimeters) {
      return DesignHuddleLimits.Millimeters;
    }
    return DesignHuddleLimits.Pixels;
  };

  const getOptions = (): ListOption[] => Object.keys(canvasSizes).map((key) => ({
    label: getCanvasSizeTranslation(key as CanvasSizes),
    value: key
  }));

  const validate = (): void => {
    setTimeout(() => {
      validateField('dimensions');
    }, 50);
  };

  return (
    <>
      <ListDropdown
        className="choose-canvas-size__dropdown"
        label={<Trans>Choose a canvas size</Trans>}
        onChange={(listOption): void => {
          if (listOption.value === CanvasSizes.Custom) {
            setFieldValue('dimensions', getCustomDimensions(assetType));
          } else {
            setFieldValue('dimensions', initialValues.dimensions);
          }
          setFieldValue('canvasSize', listOption.value as CanvasSizes);
          setFieldTouched('dimensions', undefined);
        }}
        options={getOptions()}
        value={canvasSize}
        virtualizeOptions={false}
      />
      {/* predefined sizes */}
      {!isCustom && (
      <>
        {hasSubmitted && errors.dimensions && (
          <ValidationAlert>
            {errors.dimensions}
          </ValidationAlert>
        )}
        <TileButtons className="choose-canvas-size__canvas-sizes">
          {canvasSizes[canvasSize].map((cs) => {
            const { height, unitTypeId, width } = cs;

            return (
              <TileButton
                key={cs.id}
                aria-pressed={cs.id === dimensions?.id}
                description={
                  <Trans>
                    {width}{unitTypeId}&nbsp;
                    by&nbsp;
                    {height}{unitTypeId}
                  </Trans>
                }
                heading={cs.heading}
                icon={cs.icon}
                onClick={(): void => {
                  const value: DesignHuddleDimensionsClient = {
                    height: cs.height,
                    id: cs.id,
                    width: cs.width,
                    unitTypeId: cs.unitTypeId
                  };
                  setFieldValue('dimensions', value);
                  setFieldTouched('dimensions.height', true);
                  setFieldTouched('dimensions.width', true);
                  setFieldTouched('dimensions.unitTypeId', true);
                  validate();
                }}
              />
            );
          })}
        </TileButtons>
      </>
      )}
      {/* custom */}
      {isCustom && (
      <div className="choose-canvas-size__custom">
        <StandardTextField
          error={touched.dimensions?.width && errors.dimensions?.width}
          id="dimensions-width"
          label={t`Width`}
          max={getDimension()}
          min={DesignHuddleLimits.Minimum}
          name="dimensionsWidth"
          onChange={(e): void => {
            const value: DesignHuddleDimensionsClient = {
              ...dimensions,
              width: Number(e.target.value)
            };
            setFieldValue('dimensions', value);
            setFieldTouched('dimensions.width', true);
            validate();
          }}
          placeholder=""
          step={dimensions.unitTypeId === DesignHuddleUnitTypes.Inches ? 'any' : 1}
          type={InputType.Number}
          value={`${dimensions.width}`}
        />
        <StandardTextField
          error={touched.dimensions?.height && errors.dimensions?.height}
          id="dimensions-height"
          label={t`Height`}
          max={getDimension()}
          min={DesignHuddleLimits.Minimum}
          name="dimensionsHeight"
          onChange={(e): void => {
            const value: DesignHuddleDimensionsClient = {
              ...dimensions,
              height: Number(e.target.value)
            };
            setFieldValue('dimensions', value);
            setFieldTouched('dimensions.height', true);
            validate();
          }}
          placeholder=""
          step={dimensions.unitTypeId === DesignHuddleUnitTypes.Inches ? 'any' : 1}
          type={InputType.Number}
          value={`${dimensions.height}`}
        />
        <ListDropdown
          className="choose-canvas-size__dimensions-unit-type"
          disabled={getCustomDimensionsUnitTypes(assetType).length === 1}
          error={touched.dimensions?.unitTypeId && errors.dimensions?.unitTypeId}
          label={t`Unit type`}
          onChange={(listOption): void => {
            const value: DesignHuddleDimensionsClient = {
              ...dimensions,
              unitTypeId: listOption.value as DesignHuddleUnitTypes
            };
            setFieldValue('dimensions', value);
            setFieldTouched('dimensions.unitTypeId', true);
            validate();
          }}
          options={getCustomDimensionsUnitTypes(assetType)}
          value={dimensions.unitTypeId}
          virtualizeOptions={false}
        />
      </div>
      )}
    </>
  );
};
