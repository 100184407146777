import { ButtonLooks, FontIcons, OnlyForScreenReader, StandardButton } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { FetchState, useFetch, useNotifyFetchError } from '@api/ApiHelper';
import { IndexDetailedUsersResponse } from '@api/v4/private/resources/users';

import {
  hasDescendantResources,
  removeFromAllConfirmText,
  removeFromResource,
  removeUserConsequences,
  userCanAdminBrandfolder,
} from '@components/bulk_management/user-settings/helpers';
import {
  Brandfolder,
  ResourceType,
  ServerResourceType
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';
import { StandardDialog } from '@components/library/dialog';
import { ActionsDropdown } from '@components/library/dropdown';
import { ActionDropdownItem } from '@components/library/dropdown_item';

interface MoreActionsProps {
  brandfolders: Brandfolder[];
  extendedResourceType: ResourceType;
  insightsUrl: string;
  isSelf: boolean;
  selectedResourceKey: string;
  selectedResourceType: ServerResourceType;
  setReloadPlanLimits: SetStateDispatch<boolean>;
  userKey: string;
  userPermissionKey: string;
  usersFetch: FetchState<IndexDetailedUsersResponse>;
}

export const MoreActions: FunctionComponent<MoreActionsProps> = ({
  brandfolders,
  extendedResourceType,
  insightsUrl,
  isSelf,
  selectedResourceKey,
  selectedResourceType,
  setReloadPlanLimits,
  userKey,
  userPermissionKey,
  usersFetch
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmRemoveAllOpen, setConfirmRemoveAllOpen] = useState(false);
  const deleteUserPermissionFetch = useFetch({
    url: `/api/v4/user_permissions/${userPermissionKey}`,
    method: 'DELETE',
    fetchOnMount: false
  });
  const removeFromAllFetch = useFetch({
    url: `/api/v4/private/${selectedResourceType}s/${selectedResourceKey}/users/${userKey}/remove_all_access`,
    method: 'DELETE',
    fetchOnMount: false
  });

  useNotifyFetchError(removeFromAllFetch);
  useNotifyFetchError(deleteUserPermissionFetch);

  useEffect(() => {
    if (deleteUserPermissionFetch.response) {
      setConfirmDeleteOpen(false);
      usersFetch.fetch();
      setReloadPlanLimits(true);
    }
  }, [deleteUserPermissionFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (removeFromAllFetch.response) {
      setConfirmRemoveAllOpen(false);
      usersFetch.fetch();
      setReloadPlanLimits(true);
    }
  }, [removeFromAllFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasDescendants = hasDescendantResources(selectedResourceKey, extendedResourceType, brandfolders);

  const deleteConfirmationDialog = (
    <StandardDialog
      dialogBodyClassName="user-delete-confirmation-dialog"
      dialogFooterClassName="user-delete-confirmation-dialog__footer"
      dialogHeaderClassName="user-delete-confirmation-header"
      footer={
        <StandardButton
          look={ButtonLooks.Danger}
          onClick={(): void => {
            deleteUserPermissionFetch.fetch();
          }}
        >
          <Trans>Remove user</Trans>
        </StandardButton>
      }
      id="user-delete-confirmation-dialog"
      open={confirmDeleteOpen}
      setOpen={setConfirmDeleteOpen}
      showFooter={true}
      title={t`Remove user`}
      titleIcon={`bff-${FontIcons.Remove}`}
      width={500}
    >
      <div className="user-delete-confirmation-dialog__body">
        <h3><Trans>Remove this user?</Trans></h3>
        <p className="user-delete-confirmation-dialog__confirm-text">
          {removeUserConsequences()[extendedResourceType]}&nbsp;
          <Trans>This can be undone by adding this user again.</Trans>
        </p>
      </div>
    </StandardDialog>
  );

  const removeFromAllConfirmationDialog = (
    <StandardDialog
      dialogBodyClassName="user-delete-confirmation-dialog"
      dialogFooterClassName="user-delete-confirmation-dialog__footer"
      dialogHeaderClassName="user-delete-confirmation-header"
      footer={
        <StandardButton
          look={ButtonLooks.Danger}
          onClick={(): void => {
            removeFromAllFetch.fetch();
          }}
        >
          <Trans>Remove user from all</Trans>
        </StandardButton>
      }
      id="user-delete-confirmation-dialog"
      open={confirmRemoveAllOpen}
      setOpen={setConfirmRemoveAllOpen}
      showFooter={true}
      title={t`Remove user from all`}
      titleIcon={`bff-${FontIcons.Remove}`}
      width={500}
    >
      <div className="user-delete-confirmation-dialog__body">
        <h3><Trans>Remove this user?</Trans></h3>
        <p className="user-delete-confirmation-dialog__confirm-text">
          {removeFromAllConfirmText()[extendedResourceType]}
        </p>
      </div>
    </StandardDialog>
  );

  if (!['collection', 'brandfolder', 'organization'].includes(selectedResourceType)) {
    // don't show insights, don't have subresources for Remove from All so no need to show dropdown
    return (
      <>
        {deleteConfirmationDialog}
        <StandardButton
          className="remove-from-resource"
          look={ButtonLooks.Warning}
          onClick={(): void => setConfirmDeleteOpen(true)}
          startIcon={FontIcons.Remove}
        >
          {removeFromResource()[extendedResourceType]}
        </StandardButton>
      </>
    );
  }

  if (isSelf && !userCanAdminBrandfolder(brandfolders)) {
    return null; // will have no items so don't show 'More'
  }

  return (
    <>
      {deleteConfirmationDialog}
      {hasDescendants && removeFromAllConfirmationDialog}
      <ActionsDropdown
        anchorElement={(
          <StandardButton
            className="remove-from-resource__anchor-element"
            id={`user-permission-${userPermissionKey}-more-options`}
            look={ButtonLooks.Tertiary}
            startIcon={FontIcons.Ellipsis}
          >
            <Trans>More</Trans>
          </StandardButton>
        )}
        openOnClick
        openOnHover={false}
      >
        {!isSelf && (
          <>
            <ActionDropdownItem
              icon={FontIcons.Remove}
              name="remove-this-resource"
              onChoose={(): void => setConfirmDeleteOpen(true)}
            >
              {removeFromResource()[extendedResourceType]}
            </ActionDropdownItem>
            {hasDescendants && (
              <ActionDropdownItem
                icon={FontIcons.Remove}
                name="remove-all"
                onChoose={(): void => setConfirmRemoveAllOpen(true)}
              >
                <Trans>Remove from all</Trans>
              </ActionDropdownItem>
            )}
          </>
        )}
        {/* collection-only admins cannot view insights so hide the option from them */}
        {userCanAdminBrandfolder(brandfolders) && (
          <ActionDropdownItem
            icon={FontIcons.Insights}
            name="user-insights"
            onChoose={(): void => { window.open(insightsUrl, '_blank'); }}
          >
            <Trans>View user Insights data</Trans>
            <OnlyForScreenReader><Trans>Opens in a new tab.</Trans></OnlyForScreenReader>
          </ActionDropdownItem>
        )}
      </ActionsDropdown>
    </>
  );
};
