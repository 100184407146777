import { t, Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";

import { ListDropdown } from '@components/library/dropdown';

import ImageSelector from "./image_selector";
import TextArea from "./text_area";
import TextDropdown from "./text_dropdown";
import TextInput from "./text_input";
import TextSelector from "./text_selector";

class InputCustomizer extends React.Component {
  state = {
    type: this.type,
    validations: this.validations,
    showValidations: false,
    options: this.options,
    optionsCount: this.optionsCount
  }

  get customSettings() {
    // Want to nest one level under customSettings in case grouping is implemented
    // ex: { { West: {type, validations, options},
    //       { East: {type, validations, options} }
    const { printuiInput } = this.props;
    return (printuiInput && printuiInput.options) ? printuiInput.options.custom_settings : null;
  }

  get type() {
    if (this.isImage) {
      return this.props.frame.type;
    }
    if (this.customSettings) {
      return this.customSettings.type;
    }
    return "text_input";
  }

  get isImage() {
    return ["image", "pdf", "eps"].includes(this.props.frame.type);
  }

  get validations() {
    return this.customSettings ? this.customSettings.validations : {};
  }

  get options() {
    return this.customSettings ? this.customSettings.options : {};
  }

  get optionsCount() {
    return Object.keys(this.options).length || 2;
  }

  get validationKeys() {
    return ["inputType", "length_max", "rows",
      "image_replacement", "embed_settings", "embed_query_restriction"];
  }

  defineWidth = () => {
    const { points } = this.props.frame;
    const xCoordinates = points.map((point) => parseFloat(point[0]));
    const width = Math.abs(Math.max(...xCoordinates) - Math.min(...xCoordinates));
    return parseInt(width, 10);
  }

  defineHeight = () => {
    const { points } = this.props.frame;
    const yCoordinates = points.map((point) => parseFloat(point[1]));
    const height = Math.abs(Math.max(...yCoordinates) - Math.min(...yCoordinates));
    return parseInt(height, 10);
  }

  updateType(value) {
    if (value === "text_asset") {
      this.setState((prevState) => (
        { type: value,
          validations: { ...prevState.validations, embed_query_restriction: "asset_type:text" } }));
    } else {
      this.setState({ type: value });
    }
  }

  submitInput() {
    const { printuiInput, assetKey, frame } = this.props;
    let url; let method;
    if (printuiInput) {
      url = `/api/v4/printui_inputs/${printuiInput.key}`;
      method = 'PUT';
    } else {
      url = `/api/v4/assets/${assetKey}/printui_inputs`;
      method = 'POST';
    }
    $.ajax({
      type: method,
      url,
      contentType: 'application/json',
      context: this,
      data: JSON.stringify(this.buildData()),
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: (response) => {
        Notify.makeAll('dormant');
        Notify.create({
          type: "success",
          title: `${frame.name === "$ID/" ? "field" : frame.name} updated.` });
        this.props.updatePrintuiInputs(response);
        this.setState({ showValidations: false });
      },
      error: () => {
        Notify.create({
          type: "error",
          title: `${frame.name === "$ID/" ? "field"
            : frame.name} was not updated. Please try again.` });
      }
    });
  }

  buildData() {
    const { frame } = this.props;
    const { type, validations, options } = this.state;
    return {
      data: {
        attributes: {
          field_identifier: frame.id,
          options: {
            custom_settings: {
              type,
              validations,
              options
            }
          }
        }
      }
    };
  }

  updateValidation(key) {
    this.setState((prevState) => (
      { validations: { ...prevState.validations, inputType: key } }));
  }

  validationDisplayFor(validationKey) {
    switch (validationKey) {
      case "length_max":
        return (this.state.validations.inputType === "number")
          ? t`Maximum Value` : t`Character Limit`;
      case "tel":
        return t`Phone Number`;
      case "embed_query_restriction":
        return t`Embed Restriction Query`;
      default:
        return validationKey.charAt(0).toUpperCase() + validationKey.slice(1);
    }
  }

  renderTypeSelect() {
    if (this.isImage) { return null; }
    return (
      <ListDropdown
        className={`SelectTextInputType-${this.props.frame.id}`}
        name="form_type"
        onChange={(option) => this.updateType(option.value)}
        options={[
          { value: "text_input", label: t`Text Input` },
          { value: "text_area", label: t`Text Rows` },
          { value: "text_dropdown", label: t`Text Dropdown` },
          { value: "text_asset", label: t`Text Asset` }]}
        value={this.state.type}
        virtualizeOptions={false}
      />
    );
  }

  renderDropdownOptions() {
    if (this.state.type === "text_dropdown") {
      return [...Array(this.state.optionsCount)].map((_, i) => (
        this.renderDropdownOption(i)
      ));
    }
    return null;
  }

  /* eslint-disable react/sort-comp */
  updateDropdownOptions = (value, i) => {
    this.setState((prevState) => ({ options: { ...prevState.options, [i]: value } }));
  }
  /* eslint-enable react/sort-comp */

  renderDropdownOption(i) {
    return (
      <div key={`${this.props.frame.id}-dropdown-${i}`} className="dropdown-option-row">
        <label className="bf-label bf-label--primary" htmlFor={`dropdown-option-${i}`}>
          <Trans>Option {i + 1}</Trans>
        </label>
        <input
          className="bf-input bf-input--primary dropdown-option-input"
          id={`${this.props.frame.id}-dropdown-${i}`}
          name={`${this.props.frame.id}-dropdown-${i}`}
          onChange={(e) => { this.updateDropdownOptions(e.target.value, i); }}
          type="text"
          value={this.state.options[i] || ""}
        />
      </div>
    );
  }

  renderDropdownSettings() {
    const { type, optionsCount, options } = this.state;
    if (type === "text_dropdown") {
      return (
        <div className="control-option-count full-width">
          <div
            className="add-option-button"
            onClick={() => { this.setState({ optionsCount: (optionsCount + 1) }); }}
          >
            <p>+&nbsp;<Trans>Add Option</Trans></p>
          </div>
          { (optionsCount > 0) ? (
            <div
              className="remove-option-button"
              onClick={() => {
                const newOptionsCount = optionsCount - 1;
                delete options[newOptionsCount.toString()]; // updates state
                this.setState({ optionsCount: newOptionsCount });
              }}
            >
              <p>-&nbsp;<Trans>Remove Option</Trans></p>
            </div>
          ) : null }
        </div>
      );
    }
    return null;
  }

  renderValidations() {
    /* eslint-disable no-restricted-properties */
    const { validations, showValidations, type } = this.state;
    if (showValidations && type !== "text_dropdown") {
      return this.validationKeys.map((key) => {
        const value = validations[key];
        switch (key) {
          case "length_max":
            if (["text_input", "text_dropdown"].includes(type)) {
              return (
                <div key={`${this.props.frame.id}-${key}`}>
                  <label className="bf-label bf-label--primary">{this.validationDisplayFor(key)}
                    <input
                      className="bf-input bf-input--primary character-limit-validation"
                      defaultValue={window.isNaN(parseInt(value, 10)) ? "" : parseInt(value, 10)}
                      name="Max Length"
                      onChange={(e) => { this.setState({ validations: { ...validations, [key]: parseInt(e.target.value, 10) } }); }}
                      placeholder={100}
                      type="number"
                    />
                  </label>
                </div>
              );
            }
            break;
          case "rows":
            if (type === "text_area") {
              return (
                <div key={`${this.props.frame.id}-${key}`}>
                  <label className="bf-label bf-label--primary">{this.validationDisplayFor(key)}
                    <input
                      className="row-limit-validation"
                      defaultValue={window.isNaN(parseInt(value, 10)) ? "" : parseInt(value, 10)}
                      name={this.validationDisplayFor(key)}
                      onChange={(e) => { this.setState({ validations: { ...validations, [key]: parseInt(e.target.value, 10) } }); }}
                      placeholder={5}
                      type="number"
                    />
                  </label>
                </div>
              );
            }
            break;
          case "embed_query_restriction":
            if ((this.isImage && ["allow_replacement", "allow_only_embed", undefined, null].includes(validations.image_replacement)) || type === "text_asset") {
              const placeholder = this.isImage ? 'tags.strict:("logo" "product shots" "image")' : 'asset_type:text';
              return (
                <div key={`${this.props.frame.id}-${key}`} className="embed-restriction-query-container" >
                  <label className="bf-label bf-label--primary">{this.validationDisplayFor(key)}
                    <input
                      className="bf-input bf-input--primary full-width embed-restriction-query"
                      defaultValue={value}
                      onChange={(e) => { this.setState({ validations: { ...validations, [key]: e.target.value } }); }}
                      placeholder={placeholder}
                      type="text"
                    />
                  </label>
                </div>
              );
            }
            break;
          case "image_replacement":
            if (this.isImage) {
              return (
                <div key={`${this.props.frame.id}-${key}`}>
                  <label className="bf-label bf-label--primary"><Trans>Image Replacement</Trans></label>
                  <ListDropdown
                    className={`SelectImageReplacement-${this.props.frame.id}`}
                    name="form_images"
                    onChange={(option) => {
                      this.setState((prevState) => (
                        { validations: { ...prevState.validations, [key]: option.value } }));
                    }}
                    options={[
                      { value: "allow_replacement", label: t`Allow Brandfolder Embed and Upload` },
                      { value: "allow_only_embed", label: t`Allow Only Brandfolder Embed` },
                      { value: "allow_only_upload", label: t`Allow Only User Upload` },
                      { value: "deny_replacement", label: t`Deny Image Replacement` }]}
                    placeholder={t`Image Upload Options`}
                    value={value}
                    virtualizeOptions={false}
                  />
                </div>
              );
            }
            break;
          case "inputType":
            if (["text_input", "text_dropdown"].includes(type)) {
              return (
                <div key={`${this.props.frame.id}-${key}`}>
                  <ListDropdown
                    className={`SelectInputValidation-${this.props.frame.id}`}
                    name="input_validations"
                    onChange={(option) => {
                      this.setState((prevState) => (
                        { validations: { ...prevState.validations, [key]: option.value } }));
                    }}
                    options={[
                      { value: "email", label: t`Email` },
                      { value: "url", label: t`Url` },
                      { value: "number", label: t`Number` },
                      { value: "deny_replacement", label: t`Deny Text Replacement` },
                      { value: "", label: t`No Validation` }]}
                    placeholder="Validation Type"
                    value={value}
                    virtualizeOptions={false}
                  />
                </div>
              );
            }
            break;
          default:
            return null;
        }
        return null;
      });
    }
    return null;
    /* eslint-enable no-restricted-properties */
  }

  renderValidationSettings() {
    if (this.state.type === "text_dropdown") {
      return null;
    }
    if (this.state.showValidations && this.state.type !== "text_asset") {
      return (
        <div
          className="validation-options-container"
          onClick={() => { this.setState({ showValidations: false, validations: {} }); }}
        >
          <span className="validation-options">
            <Trans>Remove Validations</Trans>
          </span>
        </div>
      );
    }
    const hasValidations = Object.keys(this.state.validations).length > 0;
    return (
      <div
        className="open-validations validation-options-container"
        onClick={() => { this.setState({ showValidations: true }); }}
      >
        <span className="validation-options">
          {hasValidations ? <Trans>Edit Validations</Trans> : <>+&nbsp;<Trans>Add Validations</Trans></>}
        </span>
      </div>
    );
  }

  renderSave() {
    return (
      <button
        className="button primary sm save-button"
        onClick={() => this.submitInput()}
        type="button"
      >
        <Trans>Save</Trans>
      </button>
    );
  }

  renderEditing() {
    const { frame } = this.props;
    return (
      <div className="customize-inputs-container" data-frameid={frame.id}>
        <div className="input-headers">
          <label className="bf-label bf-label--primary">{(frame.name && frame.name !== "$ID/") ? frame.name : ""}</label>
        </div>
        {this.renderTypeSelect()}
        {this.renderDropdownOptions()}
        {this.renderDropdownSettings()}
        {this.renderValidations()}
        {this.renderValidationSettings()}
        {this.renderSave()}
      </div>
    );
  }

  renderInput() {
    const {
      client,
      digestKey,
      editMode,
      embedLink,
      fontSettings,
      frame,
      imageFrameData,
      jobId,
      orgSlug,
      printuiInput,
      textFrameData,
      updateFrameData,
      templateSettings
    } = this.props;

    const { type, validations, options } = this.state;

    const component = (() => {
      switch (type) {
        case "text_input":
          return (
            <TextInput
              editMode={editMode}
              fontSettings={fontSettings}
              frame={frame}
              printuiInput={printuiInput}
              templateSettings={templateSettings}
              textFrameData={textFrameData}
              updateFrameData={updateFrameData}
              validations={validations}
            />);
        case "text_area":
          return (
            <TextArea
              editMode={editMode}
              fontSettings={fontSettings}
              frame={frame}
              printuiInput={printuiInput}
              templateSettings={templateSettings}
              textFrameData={textFrameData}
              updateFrameData={updateFrameData}
              validations={validations}
            />);
        case "text_dropdown":
          return (
            <TextDropdown
              editMode={editMode}
              fontSettings={fontSettings}
              frame={frame}
              options={options}
              templateSettings={templateSettings}
              textFrameData={textFrameData}
              updateFrameData={updateFrameData}
            />);
        case "text_asset":
          return (
            <TextSelector
              editMode={editMode}
              fontSettings={fontSettings}
              frame={frame}
              orgSlug={orgSlug}
              printuiInput={printuiInput}
              templateSettings={templateSettings}
              textFrameData={textFrameData}
              updateFrameData={updateFrameData}
            />);
        case "image": case "pdf": case "eps":
          return (
            <ImageSelector
              client={client}
              editMode={editMode}
              embedLink={embedLink}
              frame={frame}
              imageFrameData={imageFrameData}
              jobId={jobId}
              orgSlug={orgSlug}
              printuiAssetKey={assetKey}
              printuiDigestKey={digestKey}
              printuiInput={printuiInput}
              templateSettings={templateSettings}
              updateFrameData={updateFrameData}
              validations={validations}
            />);
        default:
          return (
            <TextInput
              editMode={editMode}
              frame={frame}
              printuiInput={printuiInput}
              textFrameData={textFrameData}
              updateFrameData={updateFrameData}
              validations={validations}
            />);
      }
    })(this.state.type);

    return (
      <div className="display-inputs-container" data-frameid={this.props.frame.id}>
        {component}
      </div>
    );
  }

  render() {
    const { editMode } = this.props;
    const width = editMode ? "one-half" : "full-width";
    const hideEdit = editMode ? "" : "hidden";
    return (
      <div className="inputs-row">
        <div className={`${width} ${hideEdit}`}>
          { editMode ? this.renderEditing() : null }
        </div>
        <div className={width}>
          {this.renderInput()}
        </div>
      </div>
    );
  }
}

InputCustomizer.propTypes = {
  frame: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
  }).isRequired,
  printuiInput: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    field_identifier: PropTypes.string,
    options: PropTypes.shape({
      custom_settings: PropTypes.shape({
        type: PropTypes.string
      })
    })
  }),
  editMode: PropTypes.bool.isRequired,
  updatePrintuiInputs: PropTypes.func.isRequired,
  updateFrameData: PropTypes.func.isRequired,
  assetKey: PropTypes.string.isRequired,
  digestKey: PropTypes.string,
  orgSlug: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  imageFrameData: PropTypes.shape({}).isRequired,
  embedLink: PropTypes.string.isRequired,
  fontSettings: PropTypes.shape({}).isRequired,
  templateSettings: PropTypes.shape({
    allowFontSizing: PropTypes.bool
  }).isRequired,
  textFrameData: PropTypes.shape({}),
};

InputCustomizer.defaultProps = {
  digestKey: undefined,
  printuiInput: null,
  textFrameData: {},
};

export default InputCustomizer;
