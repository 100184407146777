import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { EditOrCreate, Views } from '@components/bulk_management/automation/AutomationEnums';
import { AutomationForm } from '@components/bulk_management/automation/form/AutomationForm';

import { TextButton } from '@components/library/button';
import { FontIcons } from '@components/library/icon';

interface CreateViewProps {
  setView: SetStateDispatch<Views>;
}

export const CreateView: FunctionComponent<CreateViewProps> = ({
  setView,
}) => (
  <div>
    <TextButton
      className="return-to-home-button"
      icon={FontIcons.ArrowLeft}
      onClick={(): void => setView(Views.Home)}
    >
      <Trans>Back to Asset Automation Home</Trans>
    </TextButton>
    <h2><Trans>Create Asset Automation</Trans></h2>
    <AutomationForm editOrCreate={EditOrCreate.Create} goHome={(): void => setView(Views.Home)} />
  </div>
);
