import { Trans } from '@lingui/macro';
import React, { useLayoutEffect, useState, FunctionComponent, MutableRefObject } from 'react';

import { BrandfolderAccordion } from '@components/bulk_management/user-settings/resource-sidebar/BrandfolderAccordion';
import { ResourceHeading } from '@components/bulk_management/user-settings/resource-sidebar/ResourceHeading';
import { ResourceTooltipName } from '@components/bulk_management/user-settings/resource-sidebar/ResourceTooltipName';
import {
  Brandfolder,
  Organization,
  Portal,
  ResourceDetails,
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';
import { getAssetLibraryTitlePlural } from '@helpers/getty-strings';

import '../styles/resource-sidebar.scss';

interface ResourceSidebarProps {
  bodyRef: MutableRefObject<HTMLDivElement>;
  brandfolders: Brandfolder[];
  organization: Organization;
  selectedResourceKey: string;
  setResource: SetStateDispatch<ResourceDetails>;
  usersLoaded: boolean;
  // eslint-disable-next-line react/require-default-props
  portals?: Portal[];
}

export const ResourceSidebar: FunctionComponent<ResourceSidebarProps> = ({
  bodyRef,
  brandfolders,
  organization,
  portals = undefined,
  selectedResourceKey,
  setResource,
  usersLoaded
}) => {
  const [bodyHeight, setBodyHeight] = useState<number | string>('100%');
  useLayoutEffect(() => {
    setBodyHeight(bodyRef.current?.scrollHeight ? `${bodyRef.current.scrollHeight}px` : '100%');
  }, [bodyRef, usersLoaded]);

  const brandguides = [];
  brandfolders.forEach((bf) => {
    bf.brandguides.forEach((brandguide) => {
      brandguides.push(brandguide);
    });
  });

  return (
    <div
      className="user-settings__sidebar__scroll-container bf-scroll-element"
      style={{ maxHeight: bodyHeight }}
    >
      <div className="resource-sidebar">
        <h4 className="resource-sidebar__header resource-sidebar__header--organization">
          <Trans>Organization</Trans>
        </h4>
        <div className="resource-sidebar__header--organization-name">
          <ResourceTooltipName
            headingType="organization"
            hidePrivacy
            resource={organization}
            selectedResourceKey={selectedResourceKey}
            setResource={setResource}
          />
        </div>
        <h4 className="resource-sidebar__header resource-sidebar__header--brandfolder">
          {getAssetLibraryTitlePlural()}
        </h4>
        {brandfolders?.map((brandfolder, index) => (
          <BrandfolderAccordion
            key={brandfolder.key}
            brandfolder={brandfolder}
            index={index}
            selectedResourceKey={selectedResourceKey}
            setResource={setResource}
          />
        ))}
        {brandguides && brandguides.length > 0 && (
          <>
            <ResourceHeading headingType="brandguide" length={brandguides.length} />
            <ul className="resource-sidebar__brandguides">
              {brandguides.map((item) => {
                return (
                  <li key={item.key} className="resource-sidebar__brandguides--button">
                    <ResourceTooltipName
                      headingType="brandguide"
                      resource={item}
                      selectedResourceKey={selectedResourceKey}
                      setResource={setResource}
                    />
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {portals && portals.length > 0 && (
          <>
            <ResourceHeading headingType="portal" length={portals.length} />
            <ul className="resource-sidebar__portals">
              {portals.map((item) => {
                return (
                  <li key={item.key} className="resource-sidebar__portals--button">
                    <ResourceTooltipName
                      headingType="portal"
                      resource={item}
                      selectedResourceKey={selectedResourceKey}
                      setResource={setResource}
                    />
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
