import React, {
  Dispatch,
  FunctionComponent,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { styled } from 'styled-components';

import {
  AlertToast,
  ToastAction,
  ToastActionType,
  ToastContext,
  ToastLooks,
  reducer,
} from '@components/common/toast';

/**
 * Provider that keeps context of all toast content.
 * This provider includes a method to create a toast component via the 'useCreateToast' hook.
 *
 * Example:
 * ```
 * import {
 *    ToastContextProvider,
 *    ToastRenderer
 * } from '@components/common/toast';
 *
 * <ToastContextProvider>
 *   <ShowToastButton />
 *   <ToastRenderer />
 * </ToastContextProvider>
 * ```
 *
 * ```
 * import { useCreateToast } from '@src/Context/useDialogContext';
 *
 * export const ShowToastButton = () => {
 *  const { toast } = useCreateToast();
 *
 *  const handleOnClick = () => {
 *    toast('This is a toast message', ToastLooks.Success);
 *  }
 *  return <Button onClick={handleOnClick}>Open toast</Button>;
 * }
 * ```
 */

interface ToastStateContext {
  state: ToastContext;
  dispatch: Dispatch<ToastAction>;
}

const initialContext = { toasts: [] };

const Context = createContext<ToastStateContext>({
  state: initialContext,
  dispatch: () => {
    // dispatch toast actions
  },
});

const useToastContext = (): ToastStateContext => {
  return useContext(Context);
};

const useMemoizedContext = (): ToastStateContext => {
  const [state, dispatch] = useReducer(reducer, initialContext);
  return useMemo(() => ({ state, dispatch }), [state, dispatch]);
};

export const useCreateToast = (): {
  toast: (message: string, look: ToastLooks) => void;
} => {
  const { dispatch } = useToastContext();
  return {
    toast: (message: string, look: ToastLooks): number => {
      const id = Math.random();

      const removeToast = (): void => {
        dispatch({
          type: ToastActionType.RemoveToast,
          payload: { value: { id } },
        });
      };

      const content = (
        <AlertToast closeToast={removeToast} look={look} message={message} />
      );

      dispatch({
        type: ToastActionType.AddToast,
        payload: {
          value: { id, content },
        },
      });
      return id;
    },
  };
};

export const ToastContextProvider: FunctionComponent = ({ children }) => {
  const { state, dispatch } = useMemoizedContext();

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

const ToastsWrapper = styled.ul`
  bottom: 5%;
  left: 50%;
  list-style: none;
  position: fixed;
  transform: translate(-50%, -50%);

  li {
    margin-top: 8px;
  }
`;

export const ToastRenderer: FunctionComponent = () => {
  const { state } = useToastContext();
  return (
    <ToastsWrapper>
      {state.toasts.map(({ id, content }) => (
        <li key={id}>{content}</li>
      ))}
    </ToastsWrapper>
  );
};
