import React from "react";

export default class SearchBar extends React.Component {
  handleSearchUpdate = (event) => {
    if (event.key === "Enter") {
      this.props.updateSearchQuery(event.target.value);
    }
  };

  render() {
    const name = this.props.selectedResource.attributes.name;
    return (
      <input
        className="adobe-search-bar"
        onKeyUp={this.handleSearchUpdate}
        placeholder={`Search ${name}`}
      />
    );
  }
}
