// custom hook, provides debounce functionality in react hooks
// based on: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
import { useEffect, useState } from 'react';

interface DebounceArguments<T> {
  delay?: number;
  disableDebounce?: boolean;
  value: T;
}

const useDebounce = <T>({
  delay = 2000,
  disableDebounce = false,
  value,
}: DebounceArguments<T>): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (disableDebounce) { // set and return value immediately if debounce funcitonality disabled
      setDebouncedValue(value);
      return undefined;
    }

    const timeoutID = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return (): void => clearTimeout(timeoutID);
  }, [value, delay, disableDebounce]);

  return debouncedValue;
};

export default useDebounce;
