import { useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type AllowedSearchState = Record<string, string | string[]>;

/** 
  serialize URLSearchParams entries into an object
  where there are duplicate keys in the entries,
  combine the values into an array
  Example:
  [
    ['filter', 'all'],
    ['filter', 'tagged'],
    ['sort', 'name-ascending'],
  ]
  to
  {
    filter: ['all', 'tagged'],
    sort: 'name-ascending'
  }
  */

export const serializeUrlParamsToRecord = <State extends AllowedSearchState>(search: URLSearchParams): State => {
  const paramEntries = {} as any
  for (const [key, val] of search) {
    if (!paramEntries[key]) {
      paramEntries[key] = [val];
    } else {
      paramEntries[key].push(val);
    }
  }
  return Object.keys(paramEntries).reduce((records, key: keyof State) => {
    const value = paramEntries[key];
    if (Array.isArray(value) && value.length === 1) {
      records[key] = value[0];
    } else {
      records[key] = value;
    }

    return records;
  }, {} as State);
};

export const mergeState = <State extends AllowedSearchState, NextState extends State>(
  state: State,
  nextState: NextState
): AllowedSearchState => {
  const mergedState = Object.assign({}, state, nextState);
  const next = {} as AllowedSearchState;
  // remove properties from nextState that are null or undefined
  for (const key of Object.keys(mergedState)) {
    if (!mergedState[key]) {
      continue;
    }
    next[key] = mergedState[key];
  }
  return next;
};

/**
 * useQueryState to use the URL querystring as a source of state
 */
export const useQueryState = <State extends AllowedSearchState>(): [
  State,
  (nextState: State) => void
] => {
  const [search, setSearch] = useSearchParams();

  const state = useMemo(
    () => serializeUrlParamsToRecord<State>(search),
    [search]
  );
  const setState = useCallback(
    (nextState: State) => setSearch(mergeState(state, nextState)),
    [state, setSearch]
  );

  return [state, setState];
};
