import { plural, t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from "react";

import Tooltip from '@components/common/tooltip/main';
import { WorkspaceEvents } from '@components/workspace/WorkspaceEnums';
import {
  Collection,
  Brandfolder,
  bulkRefreshSections
} from "@helpers/show_page_helpers";

export default class BulkAction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      approved: this.props.assetsApproved,
      disableAction: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.assetsApproved !== prevProps.assetsApproved) {
      this.initialApprovalStatus();
    }
  }

  initialApprovalStatus() {
    this.setState({ approved: this.props.assetsApproved });
  }

  buildDataBody() {
    const { actionType, selectedAssetKeys } = this.props;

    if (actionType === 'BulkApproval') {
      return { data: { asset_keys: Array.from(selectedAssetKeys) } };
    }

    if (actionType === 'BulkCollectionRemoval') {
      return { data: { asset_keys: Array.from(selectedAssetKeys), collection_keys: [Collection.key] } };
    }

    return null;
  }

  removeAssetsFromCollection() {
    this.setState({ disableAction: true });

    const collectionName = Collection.name;
    const brandfolderName = Brandfolder.name;
    const assetCount = this.props.selectedAssetKeys.size;

    $.ajax({
      type: "DELETE",
      url: '/api/v4/bulk_actions/assets/destroy_collection_associations?queue_priority=high',
      contentType: "application/json",
      data: JSON.stringify(this.buildDataBody()),
      context: this,
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: () => {
        this.setState({ disableAction: false });
        BF.fx.updateAssetPageCount(assetCount, false);
        this.props.addRemoveSelected([]);
        bulkRefreshSections(this.props.selectedAssetKeys);
        Notify.create({
          title: plural(assetCount, {
            one: `Removed # asset from ${collectionName}`,
            other: `Removed # assets from ${collectionName}`
          }),
          body: plural(assetCount, {
            one: `Removed asset still remains in ${brandfolderName}.`,
            other: `Removed assets still remain in ${brandfolderName}.`
          }),
          type: 'success'
        });

        const isWorkspace = BFG.hasFeature('workspace') && BFG.resource.is_workspace;
        if (isWorkspace) {
          // update the workspace dashbar counts and percentages
          const workspaceUpdatedEvent = new CustomEvent(WorkspaceEvents.WorkspaceUpdated, {
            detail: {
              refresh: true
            }
          });
          window.dispatchEvent(workspaceUpdatedEvent);
        }
      },
      error: () => {
        this.setState({ disableAction: false });
        Notify.create({
          title: plural(assetCount, {
            one: `An error occurred when removing # asset from ${collectionName}. Please try again.`,
            other: `An error occurred when removing # assets from ${collectionName}. Please try again.`
          }),
          type: 'error'
        });
      }
    });
  }

  approveAssets() {
    this.setState({ disableAction: true });
    const assetCount = this.props.selectedAssetKeys.size;

    $.ajax({
      type: "POST",
      url: '/api/v4/bulk_actions/assets/approve?queue_priority=high',
      contentType: "application/json",
      data: JSON.stringify(this.buildDataBody()),
      context: this,
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: (response) => {
        this.setState((prevState) => ({ approved: !prevState.approved, disableAction: false }));
        bulkRefreshSections(this.props.selectedAssetKeys);
        const approvalCount = response.required_approvals === 'N/A' ? 0 : response.required_approvals;
        const bodyText = approvalCount
          ? plural(approvalCount, {
              one: 'This asset requires # total approval.',
              other: 'This asset requires # total approvals.'
            })
          : '';
        const title = plural(assetCount, {
          one: 'Approved # asset!',
          other: 'Approved # assets!'
        });
        this.makeNotification(title, bodyText);
      },
      error: () => {
        this.setState({ disableAction: false });
        Notify.create({
          title: t`An error occurred when approving assets. Please try again.`,
          type: 'error'
        });
      }
    });
  }

  unapproveAssets() {
    this.setState({ disableAction: true });
    const { activeCdnTracers, selectedAssetKeys } = this.props;
    const assetCount = selectedAssetKeys.size;
    const that = this;

    if (activeCdnTracers && activeCdnTracers > 0) {
      const sweet_alert_options = {
        title: t`An asset has active external links.`,
        text: t`At least one of your selected assets has external links. \n Unapproving will cause broken image links on sites that depend on the availability of these assets. Check the usage tab to see active external links before unapproving.\n\n`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Unapprove",
        closeOnConfirm: true,
        customClass: "merging"
      };
      swal(sweet_alert_options, (unapprove_selected) => { // eslint-disable-line no-undef
        if (unapprove_selected) {
          $.ajax({
            type: "POST",
            url: '/api/v4/bulk_actions/assets/unapprove?queue_priority=high',
            contentType: "application/json",
            data: JSON.stringify(that.buildDataBody()),
            context: this,
            headers: { Authorization: `Bearer ${BF_Token}` },
            success: (response) => {
              that.setState((prevState) => ({ approved: !prevState.approved, disableAction: false }));
              bulkRefreshSections(that.props.selectedAssetKeys);
              const approvalCount = response.required_approvals === 'N/A' ? 0 : response.required_approvals;
              const bodyText = (approvalCount === 'N/A' || !approvalCount)
                ? ''
                : plural(approvalCount, {
                  one: 'This asset requires # approval. All previous approvals are void.',
                  other: 'This asset requires # approvals. All previous approvals are void.'
                })
              const title = plural(assetCount, {
                one: 'Unapproved # asset!',
                other: 'Unapproved # assets!'
              });
              that.makeNotification(title, bodyText);
            },
            error: () => {
              that.setState({ disableAction: false });
              Notify.create({
                title: t`An error occurred when unapproving assets. Please try again.`,
                type: 'error'
              });
            }
          });
        }
      });
    } else {
      $.ajax({
        type: "POST",
        url: '/api/v4/bulk_actions/assets/unapprove?queue_priority=high',
        contentType: "application/json",
        data: JSON.stringify(this.buildDataBody()),
        context: this,
        headers: { Authorization: `Bearer ${BF_Token}` },
        success: (response) => {
          this.setState((prevState) => ({ approved: !prevState.approved, disableAction: false }));
          bulkRefreshSections(this.props.selectedAssetKeys);
          const approvalCount = response.required_approvals === 'N/A' ? 0 : response.required_approvals;
          const bodyText = approvalCount
            ? plural(approvalCount, {
                one: 'This asset requires # approval. All previous approvals are void.',
                other: 'This asset requires # approvals. All previous approvals are void.'
              })
            : '';
          const title = plural(assetCount, {
            one: 'Unapproved # asset!',
            other: 'Unapproved # assets!'
          });
          this.makeNotification(title, bodyText);
        },
        error: () => {
          this.setState({ disableAction: false });
          Notify.create({
            title: t`An error occurred when unapproving assets. Please try again.`,
            type: 'error'
          });
        }
      });
    }
  }

  makeNotification(title, bodyText) {
    Notify.create({
      title,
      body: bodyText,
      type: 'success'
    });
  }

  approvalStatus() {
    if (this.state.approved) {
      return { icon: 'bff-unapprove', copy: t`Unapprove`, action: () => this.unapproveAssets() };
    }

    return { icon: 'bff-approve', copy: t`Approve`, action: () => this.approveAssets() };
  }

  renderBulkApproval(disabledClass) {
    if (!this.props.approver) { return null; }

    return (
      <Tooltip
        className="Approve-tooltip-component"
        offset={`{'top': -20}`}
        tooltipContent={this.approvalStatus().copy}
        tooltipId="BulkApproval-tooltip"
        type="light"
      >
        <div
          className={`bulk-bar-item hover-item ${disabledClass}`}
          onClick={this.approvalStatus().action}
        >
          <span
            aria-hidden="false"
            aria-label={this.approvalStatus().copy}
            className={`icon ${this.approvalStatus().icon}`}
          />
          <h4 className="action-text">{this.approvalStatus().copy}</h4>
        </div>
      </Tooltip>
    );
  }

  renderBulkCollectionRemoval(disabledClass) {
    return (
      <Tooltip
        className="Remove-tooltip-component"
        offset={`{'top': -20}`}
        tooltipContent={t`Remove`}
        tooltipId="BulkCollectionRemoval-tooltip"
        type="light"
      >
        <div
          className={`bulk-bar-item hover-item with-text ${disabledClass}`}
          onClick={() => this.removeAssetsFromCollection()}
        >
          <span aria-hidden="true" className="icon bff-trash" />
          <h4 className="action-text"><Trans>Remove</Trans></h4>
        </div>
      </Tooltip>
    );
  }

  render() {
    const { disableAction } = this.state;
    const { actionType } = this.props;
    const disabledClass = disableAction ? 'disabled-section' : '';

    if (actionType === 'BulkApproval') {
      return this.renderBulkApproval(disabledClass);
    }

    if (actionType === 'BulkCollectionRemoval') {
      return this.renderBulkCollectionRemoval(disabledClass);
    }

    return null;
  }
}

BulkAction.propTypes = {
  actionType: PropTypes.string.isRequired,
  activeCdnTracers: PropTypes.number,
  addRemoveSelected: PropTypes.func.isRequired,
  approver: PropTypes.bool.isRequired,
  assetsApproved: PropTypes.bool.isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
};

BulkAction.defaultProps = {
  activeCdnTracers: null,
};
