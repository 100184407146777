import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './styles/autocomplete.scss';

const Autocomplete = ({ suggestions, onSelect, onClose }) => {
  const selectedIndexRef = useRef(null);
  const setSelectedIndex = useState(null)[1];

  const onKeyDown = (event) => {
    if (!suggestions.length) { return undefined; }
    let index = selectedIndexRef.current;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (selectedIndexRef.current === null) {
          index = 0;
        } else {
          index = selectedIndexRef.current === suggestions.length - 1 ? 0 : selectedIndexRef.current + 1;
        }
        selectedIndexRef.current = index;
        setSelectedIndex(index);
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (selectedIndexRef.current === null) {
          index = suggestions.length - 1;
        } else {
          index = selectedIndexRef.current === 0 ? suggestions.length - 1 : selectedIndexRef.current - 1;
        }
        selectedIndexRef.current = index;
        setSelectedIndex(index);
        break;
      case 'Enter':
        if (suggestions[selectedIndexRef.current]) {
          onSelect(suggestions[selectedIndexRef.current].value);
        }
        break;
      case 'Escape':
        onClose();
        break;
      default:
        break;
    }

    return undefined;
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [suggestions]);

  if (!suggestions.length) { return null; }

  const suggestionEls = suggestions.map(({ label, value }, index) => (
    <li
      key={label}
      className={index === selectedIndexRef.current ? 'selected' : null}
    >
      <button
        type="button"
        onClick={() => onSelect(value)}
        onMouseOver={() => {
          selectedIndexRef.current = index;
          setSelectedIndex(index);
        }}
        onFocus={() => {
          selectedIndexRef.current = index;
          setSelectedIndex(index);
        }}
      >
        {label}
      </button>
    </li>
  ));

  return (
    <ul
      className="search-autocomplete"
      onMouseLeave={() => {
        selectedIndexRef.current = null;
        setSelectedIndex(null);
      }}
    >
      {suggestionEls}
    </ul>
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

Autocomplete.defaultProps = {
  suggestions: [],
  onSelect: () => {},
  onClose: () => {},
};

export default Autocomplete;
