export interface Resource {
  name: string;
  tagline: string;
  privacy: Privacy;
  slug: string;
}

export enum ResourceTypes {
  Brandfolders = 'brandfolders',
  Collections = 'collections',
  Organizations = 'organizations',
  Sections = 'sections',
}

export enum Privacy {
  Private = 'private',
  Public = 'public',
  Stealth = 'stealth',
}
