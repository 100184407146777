import { CustomFieldKeysResponseObject } from '@api/v4/resources/CustomFieldKeysTypes';
import { Locale } from '@components/common/language_menu/languagesMap';
import { ListOptionValue } from '@components/library/dropdown';

interface GetCustomFieldKeysOptionsReturn {
  params: {
    order: string;
    per: number;
    sort_by: string; // eslint-disable-line @typescript-eslint/naming-convention
    ugt_locale?: Locale; // eslint-disable-line @typescript-eslint/naming-convention
  };
  resourceKey: string;
  resourceType: string;
}

export interface CustomFieldValues {
  [key: string]: ListOptionValue[];
}

export const getCustomFieldKeysOptions = (
  selectedUGTLocale?: Locale
): GetCustomFieldKeysOptionsReturn => ({
  params: {
    order: 'asc',
    per: 5, // max of 5 prioritized custom fields and they are sorted to be first in response
    sort_by: 'position', // eslint-disable-line @typescript-eslint/naming-convention
    ...selectedUGTLocale && { ugt_locale: selectedUGTLocale } // eslint-disable-line @typescript-eslint/naming-convention
  },
  resourceKey: BFG.resource.key,
  resourceType: BFG.resource.type,
});

export const syncCustomFields = (
  filteredCustomFieldsStringified: string,
  prioritizedCustomFieldKeys: CustomFieldKeysResponseObject[],
  setSelectedValues: SetStateDispatch<CustomFieldValues>,
): void => {
  let filteredCustomFields: CustomFieldValues = {};

  // use try/catch to avoid runtime error if there's bad parsing with JSON.parse
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/JSON_bad_parse
  try {
    filteredCustomFields = JSON.parse(filteredCustomFieldsStringified);
  } catch (err) {
    console.log(`error invoking JSON.parse with filteredCustomFieldsStringified: ${err}`);
  }

  setSelectedValues((prevState) => {
    const updatedSelectedValues = { ...prevState };

    prioritizedCustomFieldKeys.forEach((keyObject) => {
      const keyName = keyObject.attributes.name;
      const keyId = keyObject.id;
      const filteredCustomFieldValues = filteredCustomFields[keyName];

      // update selected values with whatever comes in from filteredCustomFields
      // this may add additional selected values or remove selected values
      updatedSelectedValues[keyId] = filteredCustomFieldValues;
    });

    return updatedSelectedValues;
  });
};
