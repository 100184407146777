import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { StructuredFileMetadata } from '@api/uploaders';
import { FilestackCredentialsPostOptions } from '@api/v4/private/resources/fs_creds';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { FontIcon, FontIcons } from '@components/library/icon';
import { getFilestackHandleFromUrl } from '@helpers/filestack';

import './styles/guest-upload-file-list.scss';

interface GuestUploadFileListProps {
  allFiles: StructuredFileMetadata[];
  brandfolderKey: string;
  uploadLinkKey: string;
}

// NOTE: This is very restrictive for guest upload previews/signatures
const filestackHandleSpecificPostOpts = (url: string): FilestackCredentialsPostOptions => ({
  expiry_seconds: 10, // shorten for guest upload to prevent nefarious hosting of problematic files
  handle: getFilestackHandleFromUrl(url), // credentials specific to a single file
  operations: ['read'], // restrict to read
});

// NOTE: security risk to host XSS/XXE/SSRF on publicly accessible guest upload links
// Preventing filestack preview for these files to avoid presenting a signed/valid url for an attacker to visit that is hosted on cdn.fs.brandfolder.com
const SupportedGuestUploadPreviewMimetypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/webp',
];

const fallbackImgUrl = (filename: string) : string => {
  const extension = filename.split('.').pop();
  const modifiedExtension = extension ? extension.replace('.', '') : '-';
  const source = (modifiedExtension) ? `${modifiedExtension}.svg` : 'file.svg';
  return `${BFG.icon_service_url}/assets/types/${source}`;
};

export const GuestUploadFileList: FunctionComponent<GuestUploadFileListProps> = ({
  allFiles,
  brandfolderKey,
  uploadLinkKey
}) => (
  <ul className="guest-upload__file-list bf-scroll-element">
    {allFiles.map((file, index: number) => (
      <li key={`guest-upload-file${index}`} className="guest-upload__file-list--item">
        <div className="guest-upload__file-list--item-container-left">
          <div className="guest-upload__file-list--item-thumbnail">
            {
              SupportedGuestUploadPreviewMimetypes.includes(file.mimetype)
                ? <FilestackPreviewImage
                  alt="thumbnail of guest upload image"
                  className="no-select"
                  credentialsOptions={filestackHandleSpecificPostOpts(file.url)}
                  resourceKey={brandfolderKey}
                  resourceType="brandfolder"
                  src={file.url}
                  uploadLinkKey={uploadLinkKey}
                />
                : <img
                  alt="fallback thumbnail of guest upload image"
                  className="no-select"
                  src={fallbackImgUrl(file.filename)}
                  width="100%"
                />
            }
          </div>
          <p className="guest-upload__file-list--item-filename">{file.filename}</p>
        </div>
        <p className="guest-upload__file-list--item-confirmation">
          <FontIcon aria-hidden icon={FontIcons.CheckMark} />
          <Trans>Uploaded</Trans>
        </p>
      </li>
    ))}
  </ul>
);
