import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { ShareManifestContextableTypes, ShareManifestServer } from '@api/v4/share_manifests/ShareManifestTypes';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { InsightsEvents } from '../insights-events';
import { Metadata } from '../metadata';

import classes from './styles/sidebar.module.scss';

interface SidebarProps {
  assetsHidden: number;
  assetsShown: number;
  manifest: ShareManifestServer;
  requireShareIdentification: boolean;
  resourceName: string;
  resourcePath: string;
  resourceType: ShareManifestContextableTypes;
}

export const Sidebar: FunctionComponent<SidebarProps> = (props) => {
  const {
    assetsHidden,
    assetsShown,
    manifest,
    requireShareIdentification,
    resourceName,
    resourcePath,
    resourceType
  } = props;

  const isPreview = new URLSearchParams(window.location.search).get('preview') === 'true';

  if (isPreview) return null;

  return (
    <I18nProviderWrapper>
      <div className={classNames(classes.sidebar, 'no-print')}>
        <Metadata
          assetsHidden={assetsHidden}
          assetsShown={assetsShown}
          manifest={manifest}
          requireShareIdentification={requireShareIdentification}
          resourceName={resourceName}
          resourcePath={resourcePath}
          resourceType={resourceType}
        />
        <InsightsEvents
          manifest={manifest}
        />
      </div>
    </I18nProviderWrapper>
  );
};
