import React, { FunctionComponent } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { ToastContextProvider, ToastRenderer } from '@components/common/toast';

import { Brandfolder, Organization } from './customPeopleTagTypes';

import { PageNotFound } from './PageNotFound';
import { Home } from './people_page/Home';
import { PersonDetailsPage } from './person_details_page/PersonDetailsPage';

interface PeopleTagsProps {
  brandfolder: Brandfolder;
  organization: Organization;
}

export const PeopleTags: FunctionComponent<PeopleTagsProps> = ({ brandfolder, organization }) => {
  const path = `/${brandfolder.slug}/people_tags`;
  return (
    <I18nProviderWrapper>
      <ToastContextProvider>
        <BrowserRouter basename="/">
          <Routes>
            <Route path={path}>
              <Route
                element={
                  <Home
                    brandfolder={brandfolder}
                    organization={organization}
                  />
                }
                index
              />
              <Route element={<PersonDetailsPage />} path={`${path}/:id`} />
              <Route element={<PageNotFound organization={organization} />} path="*" />
            </Route>
          </Routes>
        </BrowserRouter>
        <ToastRenderer />
      </ToastContextProvider>
    </I18nProviderWrapper>
  );
};
