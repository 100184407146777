/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { defaultCroppedData } from '@components/advanced_download/helper';
import Checkbox from '@components/common/checkbox/main';

const CropDimensionsInputs = ({
  aspectRatio,
  croppedData,
  fixedDimensions,
  imageToThumbnailRatio,
  selectedPreset,
  setAspectRatio,
  setSetter,
}) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (croppedData) {
      const newWidth = Math.round(croppedData.width * imageToThumbnailRatio);
      const newHeight = Math.round(croppedData.height * imageToThumbnailRatio);
      setWidth(newWidth);
      setHeight(newHeight);
    } else {
      setHeight(0);
      setWidth(0);
    }
  }, [croppedData]);

  const disabledAspectRatio = Boolean(fixedDimensions || (selectedPreset && selectedPreset.value.aspect_ratio));
  const lockClass = aspectRatio ? 'private' : 'public';
  let disabledAspectRatioClass = '';
  let aspectRatioCopy = '';
  if (disabledAspectRatio) {
    const { aspect_width, aspect_height } = selectedPreset.value;
    disabledAspectRatioClass = 'disabled';
    if (aspect_width && aspect_height) {
      aspectRatioCopy = `${aspect_width}:${aspect_height}`;
    }
  }

  const handleToggle = (e) => {
    e.preventDefault();
    setAspectRatio((prevAspectRatio) => {
      if (prevAspectRatio) {
        return null;
      }
      return croppedData.width / croppedData.height;
    });
  };

  if (fixedDimensions) {
    return (
      <React.Fragment>
        <label className="brandfolder-label disabled">
          <Trans>Crop Width (px)</Trans>
          <input
            className="brandfolder-input"
            readOnly
            type="number"
            value={selectedPreset.value.width}
          />
        </label>
        <button
          aria-label={`${lockClass === 'private' ? 'unlock' : 'lock'} aspect ratio`}
          className="toggle-aspect-ratio disabled"
          disabled={disabledAspectRatio}
          onClick={handleToggle}
          type="button"
        >
          <span className={`bff-${lockClass} icon`} />
        </button>
        <label className="brandfolder-label disabled">
          <Trans>Crop Height (px)</Trans>
          <input
            className="brandfolder-input"
            readOnly
            type="number"
            value={selectedPreset.value.height}
          />
        </label>
        <Checkbox
          checked={Boolean(aspectRatio)}
          className="disabled"
          datakey="lock"
          label={`Lock aspect ratio ${aspectRatioCopy}`}
          size="md"
          toggle={disabledAspectRatio ? () => {} : handleToggle}
        />
      </React.Fragment>
    );
  }

  const handleBlur = (dimension) => {
    const value = dimension === 'width' ? width : height;
    const scaledValue = value / imageToThumbnailRatio;
    setSetter({ action: 'setData', data: { [dimension]: scaledValue } });
  };

  if (!selectedPreset || !fixedDimensions) {
    return (
      <React.Fragment>
        <label className="brandfolder-label">
          <Trans>Crop Width (px)</Trans>
          <input
            className="brandfolder-input"
            onBlur={() => handleBlur('width')}
            onChange={(e) => setWidth(e.target.value)}
            type="number"
            value={width}
          />
        </label>
        <button
          aria-label={`${lockClass === 'private' ? 'unlock' : 'lock'} aspect ratio`}
          className={`toggle-aspect-ratio ${disabledAspectRatioClass}`}
          disabled={disabledAspectRatio}
          onClick={handleToggle}
          type="button"
        >
          <span className={`bff-${lockClass} icon`} />
        </button>
        <label className="brandfolder-label">
          <Trans>Crop Height (px)</Trans>
          <input
            className="brandfolder-input"
            onBlur={() => handleBlur('height')}
            onChange={(e) => setHeight(e.target.value)}
            type="number"
            value={height}
          />
        </label>
        <Checkbox
          checked={Boolean(aspectRatio)}
          className={disabledAspectRatioClass}
          datakey="lock"
          label={`Lock aspect ratio ${aspectRatioCopy}`}
          size="md"
          toggle={disabledAspectRatio ? () => {} : handleToggle}
        />
      </React.Fragment>
    );
  }

  return null;
};

CropDimensionsInputs.propTypes = {
  aspectRatio: PropTypes.number,
  croppedData: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  fixedDimensions: PropTypes.bool.isRequired,
  imageToThumbnailRatio: PropTypes.number.isRequired,
  selectedPreset: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({}),
  }),
  setAspectRatio: PropTypes.func.isRequired,
  setSetter: PropTypes.func.isRequired,
};

CropDimensionsInputs.defaultProps = {
  aspectRatio: null,
  croppedData: defaultCroppedData,
  selectedPreset: null,
};

export default CropDimensionsInputs;
