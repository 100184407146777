import { t, plural, Plural, Trans } from '@lingui/macro';
import React, { useCallback, useEffect, useRef, useState, FunctionComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CustomFieldValue, CustomFieldValuesListResponse } from '@api/v4/assets/customFieldTypes';
import { createCustomFieldValues } from '@api/v4/custom_fields/custom-field-keys';
import { getCustomFieldKeys } from '@api/v4/resources/custom_fields';
import { CustomFieldKeyServer, DependentCustomField, DependentCustomFieldMap } from '@api/v4/resources/CustomFieldKeysTypes';
import { FlattenedCustomFieldKeyValuesMap, ValidationErrors } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { flattenCustomFieldKeysList } from '@components/asset/modal/tabs/edit/helpers';
import { CustomFieldKeyValueRow } from '@components/asset/modal/tabs/edit/main_pane/custom_fields/custom-field-row/CustomFieldKeyValueRow';
import { Locale } from '@components/common/language_menu/languagesMap';
import { BFLoader } from '@components/common/loader/main';
import { PrimaryButton, SecondaryButton, TertiaryButton } from '@components/library/button';
import { fetchNewKeys, getNewKeyNames, isValid, unsavedChanges } from '@components/show_page/bulk_actions/sub_components/bulk-custom-fields-helper';
import { containsSpecialCharacters } from '@helpers/custom-field-validation';
import { unsavedChangesOptions } from '@helpers/sweet_alert_options';

interface BulkCustomFieldsListProps {
  closeModal: () => void;
  hasUnsavedChanges: boolean;
  selectedAssetKeys: Set<string>;
  setHasUnsavedChanges: SetStateDispatch<boolean>;
  ugtLocale: Locale | undefined;
}

interface CustomFieldKeyAttributeMap {
  [customFieldKeyId: string]: CustomFieldKeyServer;
}

const submitSuccessNotification = (qtyAssets: number): void => {
  Notify.create({
    type: 'success',
    title: t`Processing Custom Field Updates!`,
    body: plural(qtyAssets, {
      one: 'Updating custom fields for # asset',
      other: 'Updating custom fields for # assets'
    })
  });
};

const submitErrorNotification = (): void => {
  Notify.create({
    type: 'error',
    title: t`Please try again!`
  });
};

const fetchKeysErrorNotification = (): void => {
  Notify.create({
    type: 'error',
    title: t`Something went wrong`,
    body: t`Please try again and contact support if the issue persists`
  });
};

const disallowedCharactersNotification = (): void => {
  Notify.create({
    title: t`Please fix invalid fields before submitting`,
    body: t`Key names may not contain colons, quotation marks, or periods`,
    type: 'error'
  });
};

const tempKeyPrefix = 'create-cfk';
const tempValuePrefix = 'create-cfv';

export const BulkCustomFieldsList: FunctionComponent<BulkCustomFieldsListProps> = ({
  closeModal,
  hasUnsavedChanges,
  selectedAssetKeys,
  setHasUnsavedChanges,
  ugtLocale
}) => {
  const [customFieldKeys, setCustomFieldKeys] = useState<CustomFieldKeyAttributeMap>({});
  const [customFieldsMap, setCustomFieldsMap] = useState<FlattenedCustomFieldKeyValuesMap>({});
  const [dependentCustomFields, setDependentCustomFields] = useState<DependentCustomField[]>([]);
  const [newCustomFieldFocus, setNewCustomFieldFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const listContainerRef = useRef(null);
  const [disallowedKeys, setDisallowedKeys] = useState<string[]>([]);

  const assetCount = selectedAssetKeys.size;
  const controlledCustomFieldsEnabled = !!BFG.brandfolderSettings?.controlled_custom_fields_enabled;
  const translating = ugtLocale !== BFG.locales.ugtLocaleDefault && BFG.multiLanguageAssetDetailsEnabled;

  const dependentCustomFieldsMap = dependentCustomFields.reduce((acc, dcf) => {
    acc[dcf.child_key] = dcf;
    return acc;
  }, {} as DependentCustomFieldMap);

  const getCustomFieldKeysAsync = useCallback(async (): Promise<void> => {
    const customFieldKeysParams = {
      fields: 'multi_value_enabled',
      include: 'dependent_custom_fields',
      order: 'asc',
      per: 3000,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      sort_by: 'name',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ugt_locale: ugtLocale || undefined
    };
    const options = { params: customFieldKeysParams, resourceKey: BFG.resource.key, resourceType: BFG.resource.type };
    try {
      const response = await getCustomFieldKeys(options);
      const tempCustomFieldKeys: CustomFieldKeyAttributeMap = {};
      const sortedCustomFieldKeys: CustomFieldKeyAttributeMap = {};
      const tempCustomFieldsMap = flattenCustomFieldKeysList(response);
      response.data?.forEach((customFieldKey) => {
        tempCustomFieldKeys[customFieldKey.id] = { ...customFieldKey.attributes };
      });
      for (const customFieldKeyId in tempCustomFieldsMap) {
        sortedCustomFieldKeys[customFieldKeyId] = tempCustomFieldKeys[customFieldKeyId];
      }
      setDependentCustomFields(response.included?.map((dependentCustomField) => dependentCustomField.attributes) || []);
      setCustomFieldKeys(sortedCustomFieldKeys);
      setCustomFieldsMap(tempCustomFieldsMap);
    } catch (err) {
      console.log(err);
      fetchKeysErrorNotification();
    } finally {
      setIsLoading(false);
    }
  }, [ugtLocale]);

  useEffect(() => {
    getCustomFieldKeysAsync();
  }, [getCustomFieldKeysAsync]);

  const handleClose = (): void => {
    if (hasUnsavedChanges) {
      window.swal(unsavedChangesOptions({}), (confirm: boolean): void => {
        if (confirm) closeModal();
      });
    } else {
      closeModal();
    }
  };

  const handleDeleteRow = (deleteCustomFieldKeyId: string): void => {
    const customFieldsMapCopy = {};
    Object.keys(customFieldsMap).forEach((customFieldKeyId) => {
      if (customFieldKeyId !== deleteCustomFieldKeyId) {
        customFieldsMapCopy[customFieldKeyId] = {
          ...customFieldsMap[customFieldKeyId]
        };
      }
    });
    setCustomFieldsMap(customFieldsMapCopy);
  };

  const handleRemoveValues = (removeCustomFieldKeyId: string): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    customFieldsMapCopy[removeCustomFieldKeyId] = {
      ...customFieldsMap[removeCustomFieldKeyId],
      customFieldValues: []
    };

    // Clear values on fields that depend on this field
    const childFields = dependentCustomFields.filter((dcf) => dcf.parent_key === removeCustomFieldKeyId);
    childFields.forEach((childField) => {
      const childKey = childField.child_key;
      customFieldsMapCopy[childKey] = {
        ...customFieldsMap[childKey],
        customFieldValues: []
      };
    });

    setCustomFieldsMap(customFieldsMapCopy);
  };

  const handleResetValues = (customFieldsMapArg = customFieldsMap): void => {
    const customFieldsMapCopy = {};
    Object.keys(customFieldsMapArg).forEach((customFieldKeyId) => {
      customFieldsMapCopy[customFieldKeyId] = {
        ...customFieldsMapArg[customFieldKeyId],
        customFieldValues: []
      };
    });
    setCustomFieldsMap(customFieldsMapCopy);
  };

  const handleKeyUpdate = (updateCustomFieldKey: { id: string; name: string }): void => {
    const customFieldsMapCopy = {};

    Object.keys(customFieldsMap).forEach((customFieldKeyId) => {
      if (customFieldKeyId === updateCustomFieldKey.id) {
        customFieldsMapCopy[customFieldKeyId] = {
          ...customFieldsMap[customFieldKeyId],
          customFieldKey: updateCustomFieldKey
        };
      } else {
        customFieldsMapCopy[customFieldKeyId] = {
          ...customFieldsMap[customFieldKeyId]
        };
      }
    });
    setCustomFieldsMap(customFieldsMapCopy);
  };

  const handleCreate = (
    customFieldKeyId: string | undefined = `${tempKeyPrefix}-${uuidv4()}`,
    customFieldValue: CustomFieldValue | CustomFieldValue[] | undefined = undefined
  ): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    if (customFieldsMapCopy[customFieldKeyId]) {

      if (Array.isArray(customFieldValue)) {
        const customFieldValues = customFieldValue.map((field) => (
          { key: field.key || `${tempValuePrefix}-${uuidv4()}`, value: field.value }
        ));

        customFieldsMapCopy[customFieldKeyId] = {
          ...customFieldsMap[customFieldKeyId],
          customFieldValues
        };

      } else {
        customFieldsMapCopy[customFieldKeyId] = {
          ...customFieldsMap[customFieldKeyId],
          customFieldValues: [
            ...customFieldsMap[customFieldKeyId].customFieldValues,
            {
              key: customFieldValue?.key || `${tempValuePrefix}-${uuidv4()}`,
              value: customFieldValue?.value || ''
            }
          ]
        };
      }

    } else {
      // create a new row for uncontrolled custom fields
      customFieldsMapCopy[customFieldKeyId] = {
        customFieldKey: { id: customFieldKeyId, name: '' },
        customFieldValues: []
      };
    }

    setCustomFieldsMap(customFieldsMapCopy);
  };

  const genHandleCreate = (customFieldKeyId: string) => (customFieldValue: CustomFieldValue): void => {
    handleCreate(customFieldKeyId, customFieldValue);
  };

  const genHandleDelete = (customFieldKeyId: string) => (customFieldValue: CustomFieldValue): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    const valuesCopy: CustomFieldValue[] = [];
    customFieldsMap[customFieldKeyId].customFieldValues.forEach((cfv) => {
      if (cfv.key !== customFieldValue.key) {
        valuesCopy.push(cfv);
      }
    });
    customFieldsMapCopy[customFieldKeyId] = {
      ...customFieldsMap[customFieldKeyId],
      customFieldValues: valuesCopy
    };
    setCustomFieldsMap(customFieldsMapCopy);
  };

  const genHandleUpdate = (customFieldKeyId: string) => (customFieldValue: CustomFieldValue): void => {
    if (!customFieldValue.key) {
      return handleCreate(customFieldKeyId, customFieldValue);
    }

    const customFieldsMapCopy = { ...customFieldsMap };
    const valuesCopy = customFieldsMapCopy[customFieldKeyId].customFieldValues.map((cfv) => {
      if (cfv.key === customFieldValue.key) {
        return customFieldValue;
      }

      return cfv;
    });

    const resetSingleEntryValues = valuesCopy.length === 1 && !valuesCopy[0].value; // clear out both value AND the temp key if the value is empty
    customFieldsMapCopy[customFieldKeyId] = {
      ...customFieldsMap[customFieldKeyId],
      customFieldValues: resetSingleEntryValues ? [] : valuesCopy
    };

    setCustomFieldsMap(customFieldsMapCopy);
    return undefined;
  };

  const submitCustomFields = async (): Promise<void> => {
    setIsSubmitting(true);
    setDisallowedKeys([]);

    const customFieldsMapCopy = { ...customFieldsMap };
    const hasSpecialCharacters = [];
    let matchedDisallowedKeys = false;

    try {
      if (!controlledCustomFieldsEnabled) {

        const newKeyNames = getNewKeyNames(customFieldsMap, tempKeyPrefix);

        newKeyNames.forEach((keyName) => {
          if (containsSpecialCharacters(keyName)) {
            hasSpecialCharacters.push(keyName);
            matchedDisallowedKeys = true;
          }
        });
        // fetch/create keys for any uncontrolled custom fields the user

        if (matchedDisallowedKeys) {
          setDisallowedKeys(hasSpecialCharacters);
          throw new Error('cannot contain quotes, periods, or colons.');
        }
        // may have created (by adding new rows)
        const newKeys = await fetchNewKeys(customFieldsMap, tempKeyPrefix, ugtLocale);
        // update state to reflect new keys
        newKeys.forEach((newKey) => {
          customFieldsMapCopy[newKey.key] = {
            ...customFieldsMapCopy[newKey.tempKey],
            customFieldKey: {
              ...customFieldsMapCopy[newKey.tempKey].customFieldKey,
              id: newKey.key
            }
          };
          delete customFieldsMapCopy[newKey.tempKey];
        });
      }

      // fetch/create key/value pairs for all custom fields (controlled, uncontrolled, single-value, multi-value)
      const promises: Promise<CustomFieldValuesListResponse>[] = [];
      Object.keys(customFieldsMapCopy).forEach((cfKey) => {
        const cfValues = customFieldsMapCopy[cfKey].customFieldValues;
        if (cfValues.length && !cfKey.includes(tempKeyPrefix)) {
          const values = [...selectedAssetKeys].flatMap((assetKey) => (
            cfValues.map(({ value }) => ({ value, assetKey }))
          ));
          promises.push(createCustomFieldValues(values, cfKey, ugtLocale));
        }
      });

      await Promise.all(promises);
      handleResetValues(customFieldsMapCopy);
      submitSuccessNotification(selectedAssetKeys.size);
    } catch (err) {
      console.log(err);
      if (err.message.includes('quotes')) {
        disallowedCharactersNotification();
      } else {
        submitErrorNotification();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const unsavedRowChanges = unsavedChanges(customFieldsMap, tempKeyPrefix);
  useEffect(() => {
    setHasUnsavedChanges(unsavedRowChanges);
  }, [unsavedRowChanges]);

  // new, uncontrolled custom field keys added via the ui (add new row)
  const newUncontrolledCustomFieldKeys = customFieldsMap
    ? Object.keys(customFieldsMap).filter((cfkId) => (
      !Object.keys(customFieldKeys).some((initialKeyId) => initialKeyId === cfkId)
    ))
    : [];

  // Filter out dependent custom fields that don't have their corresponding value in their parent field selected
  const dependentFields = Object.keys(dependentCustomFieldsMap);
  const filteredCustomFieldKeyIds = Object.keys(customFieldKeys).filter((cfkId) => {
    const parentKey = dependentCustomFieldsMap[cfkId]?.parent_key;
    const parentField = parentKey && customFieldsMap[parentKey];
    const parentValues = parentField?.customFieldValues.map((cfv) => cfv.value) || [];
    return !dependentFields.includes(cfkId) || parentValues.includes(dependentCustomFieldsMap[cfkId].value);
  });

  return (
    <div className="modal-content-wrapper bulk-custom-fields-modal">
      <div className="modal-content-wrapper__body">
        <h3 className="body-title">
          {/* TODO: don't rely on bold-text class to add spacing */}
          <Plural
            one={
              <Trans>
                Add custom fields to <span className="bold-text">{assetCount} asset</span>
              </Trans>
            }
            other={
              <Trans>
                Add custom fields to <span className="bold-text">{assetCount} assets</span>
              </Trans>
            }
            value={assetCount}
          />
        </h3>
        {isLoading ? (
          <BFLoader />
        ) : (
          <>
            <header className="custom-fields-list--heading">
              <div className="custom-fields-list--heading--key"><p><Trans>Key</Trans></p></div>
              <div className="custom-fields-list--heading--values"><p><Trans>Values</Trans></p></div>
            </header>
            <ul
              ref={listContainerRef}
              className={`custom-field-list bf-scroll-element ${!controlledCustomFieldsEnabled && 'max-height'}`}
            >
              {newUncontrolledCustomFieldKeys.reverse().map((keyId, index) => (
                <CustomFieldKeyValueRow
                  key={keyId}
                  controlledCustomFieldsEnabled={controlledCustomFieldsEnabled}
                  customFieldKey={{
                    attributes: {
                      name: customFieldsMap[keyId].customFieldKey.name,
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      allowed_values: []
                    },
                    id: keyId,
                    type: 'custom_field_keys'
                  }}
                  errorState={disallowedKeys.includes(customFieldsMap[keyId].customFieldKey.name) ? [ValidationErrors.InvalidKeyName] : []}
                  existingCustomFieldMap={customFieldsMap[keyId]}
                  focused={index === 0 && newCustomFieldFocus}
                  handleDeleteRow={handleDeleteRow}
                  handleKeyUpdate={handleKeyUpdate}
                  handleUpdate={genHandleUpdate(keyId)}
                  setNewCustomFieldFocus={setNewCustomFieldFocus}
                  translating={translating}
                />
              ))}
              {customFieldsMap && filteredCustomFieldKeyIds.map((keyId) => (
                <CustomFieldKeyValueRow
                  key={keyId}
                  controlledCustomFieldsEnabled={controlledCustomFieldsEnabled}
                  customFieldKey={{
                    attributes: customFieldKeys[keyId],
                    id: keyId,
                    type: 'custom_field_keys'
                  }}
                  existingCustomFieldMap={customFieldsMap[keyId]}
                  handleCreate={genHandleCreate(keyId)}
                  handleDelete={genHandleDelete(keyId)}
                  handleRemoveValues={handleRemoveValues}
                  handleUpdate={genHandleUpdate(keyId)}
                  overflowParentRef={listContainerRef}
                  translating={translating}
                />
              ))}
            </ul>
            {!controlledCustomFieldsEnabled && (
              <SecondaryButton
                className="add-custom-field-button"
                icon="bff-plus"
                onClick={(): void => {
                  handleCreate();
                  setNewCustomFieldFocus(true);
                }}
              >
                <Trans>Add Custom Field</Trans>
              </SecondaryButton>
            )}
          </>
        )}
      </div>
      <div className="button-container">
        <TertiaryButton
          className="t-close-modal"
          disabled={isLoading || isSubmitting}
          onClick={handleClose}
          size="small"
        >
          <Trans>Close</Trans>
        </TertiaryButton>
        <PrimaryButton
          className="t-confirm-modal"
          disabled={isLoading || !unsavedRowChanges || !isValid(customFieldsMap, tempKeyPrefix)}
          isLoading={isSubmitting}
          onClick={(): void => { submitCustomFields(); }}
          size="small"
        >
          <Trans>Save</Trans>
        </PrimaryButton>
      </div>
    </div>
  );
};
