import fetchJSON from '@api/api_helper';

export const get = (brandfolderKey) => {
  const url = `/api/v4/brandfolders/${brandfolderKey}/links`;
  return fetchJSON(url).then(({ data }) => (
    data.map((link) => {
      const { id, attributes } = link;
      return {
        id,
        ...attributes,
      };
    }).sort((a, b) => a.position - b.position)
  ));
};

export const create = (brandfolderKey, link) => (
  fetchJSON(`/api/v4/brandfolders/${brandfolderKey}/links`, {
    method: 'POST',
    body: {
      data: {
        attributes: link,
      }
    }
  }).catch(async (response) => { throw (await response.json()); })
);

export const update = ({ url, name, id }) => (
  fetchJSON(`/api/v4/links/${id}`, {
    method: 'PUT',
    body: {
      data: {
        attributes: {
          url,
          name,
        }
      }
    }
  }).catch(async (response) => { throw (await response.json()); })
);

export const remove = (id) => (
  fetchJSON(`/api/v4/links/${id}`, {
    method: 'DELETE',
  })
);
