/* eslint-disable @typescript-eslint/naming-convention */
// GET <resource>/<id>/assets
import { CamelCasedProperties } from 'type-fest';

import { StructuredFileMetadata } from '@api/uploaders';
import { ApiDatumResponse, ApiDataResponse, ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { User } from '@api/v4/UserTypes';
import { WatermarkPositionsEnum } from '@components/shared/watermarks/WatermarkPositionTypes';

export type AssetsResponse = ApiDataResponse<Asset>;

export type AssetResponse = ApiDatumResponse<Asset>;

export type AssetsResponseObject = ApiResponseObject<Asset>;

export type AssetData = ColorData | FontData | PersonData | PressData | ExternalMediumData | GenericFileData;

export interface Asset {
  description: string;
  name: string;
  approved?: boolean;
  asset_data?: AssetData;
  asset_key?: string;
  attachment_count?: number;
  assigned_users?: User[]; // via app/jobs/asset_rollup_job.rb
  best_link_for?: string;
  cdn_url?: string;
  content_automation_editor_link?: string | null;
  created_at?: string;
  data?: AssetData;
  design_huddle_editor_link?: string | null;
  display_image?: string | null;
  extension?: string;
  is_downloadable?: boolean;
  score?: number;
  thumbnail_override?: LinkObject;
  thumbnail_url?: string | null;
  type?: AssetTypesPascalCaseSingular;
  updated_at?: string;
  view_only?: boolean;
  watermark_url?: string;
  watermark?: WatermarkObject;
}

export type AssetClient = CamelCasedProperties<Asset>;

// Applies to asset types returned from Brandfolder endpoints
export enum AssetTypes {
  GenericFile = 'generic_files',
  Color = 'colors',
  People = 'people',
  Press = 'press',
  Text = 'texts',
  Font = 'fonts',
  ExternalMedium = 'external_media',
}

export enum ThumbnailAssetTypes {
  GenericFile = 'generic_files',
  People = 'people',
  Press = 'press',
  ExternalMedium = 'external_media',
}

// Applies to asset types when passing into Brandfolder endpoints, e.g. as a param
export enum AssetTypesSingular {
  GenericFile = 'generic_file',
  Color = 'color',
  People = 'person',
  Press = 'press',
  Text = 'text',
  Font = 'font',
  ExternalMedium = 'external_medium',
}

export enum AssetTypesPascalCaseSingular {
  GenericFile = 'GenericFile',
  Color = 'Color',
  People = 'Person',
  Press = 'Press',
  Text = 'Text',
  Font = 'Font',
  ExternalMedium = 'ExternalMedium',
}

export interface LinkObject {
  url?: string;
  filename?: string;
}

export interface WatermarkObject extends LinkObject {
  gravity?: WatermarkPositionsEnum | null;
}

export interface GenericFileData {
  three_sixty_viewer?: boolean;
  asset_background_color?: string;
  gelato_pages_override?: string;
  gelato_product_uid?: string;
}

export interface ColorData {
  c: string;
  m: string;
  y: string;
  k: string;
  r: string;
  g: string;
  b: string;
  hex: string;
  pantone: string;
  pantone_u: string;
}

export interface ExternalMediumData {
  url: string;
  thumbnail?: string;
}

export interface PersonData {
  email: string;
  linkedin: string;
  phone_number: string;
  title: string;
  twitter: string;
}

export interface PersonAsset extends Asset {
  bio: string | null;
  linkedin_url: string | null;
  twitter: string | null;
  twitter_url: string | null;
}

export interface FontData {
  other_font_id: string;
  web_font_id: string;
  web_font_type: string;
}

export interface PressData {
  url: string;
  published_date?: string;
}

export interface PressAsset extends Asset {
  published_date: string;
  url: string;
}

export interface Tag {
  name: string;
  auto_generated: boolean;
  source?: string;
}

/* Type predicates */
const isGenericFileData = (assetType: AssetTypes, data: AssetData): data is GenericFileData => (
  assetType === AssetTypes.GenericFile
);

const isColorData = (assetType: AssetTypes, data: AssetData): data is ColorData => (
  assetType === AssetTypes.Color
);

const isFontData = (assetType: AssetTypes, data: AssetData): data is FontData => (
  assetType === AssetTypes.Font
);

const isPersonData = (assetType: AssetTypes, data: AssetData): data is PersonData => (
  assetType === AssetTypes.People
);

const isPressData = (assetType: AssetTypes, data: AssetData): data is PressData => (
  assetType === AssetTypes.Press
);

const isExternalMediumData = (assetType: AssetTypes, data: AssetData): data is ExternalMediumData => (
  assetType === AssetTypes.ExternalMedium
);

export const isCompatibleThumbnailAssetType = (type: string): boolean => (
  Object.values(ThumbnailAssetTypes).some((v) => v === type)
);

const typePredicateMap = {
  [AssetTypes.GenericFile]: isGenericFileData,
  [AssetTypes.Color]: isColorData,
  [AssetTypes.ExternalMedium]: isExternalMediumData,
  [AssetTypes.Font]: isFontData,
  [AssetTypes.People]: isPersonData,
  [AssetTypes.Press]: isPressData,
};

export const isExpectedDataShape = <T extends AssetData>(assetType: AssetTypes, expectedAssetType: AssetTypes, data: AssetData): data is T => (
  typePredicateMap[expectedAssetType](assetType, data)
);

export interface AssetCreateBodyAttributes {
  attachments: Array<StructuredFileMetadata>;
  name: string;
  labels?: string[];
}

export interface AssetCreateBody {
  data: {
    attributes: Array<AssetCreateBodyAttributes>;
  };
  section_key: string;
}
