import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { TextButton } from '@components/library/button/index';
import filterLogic from '@components/show_page/sections/section_search/filterLogic';
import { Filters, SearchFilterOperator, SearchOperatorOptions } from '@components/show_page/sections/section_search/SearchTypes';

interface SearchDetailsProps {
  filters: Filters;
  filterStrings: Array<string>;
  resetFilters: () => void;
  searchOperator: SearchOperatorOptions;
  searchFilterOperators: SearchFilterOperator;
  searchQuery: string;
  showFullQuery: boolean;
  toggleShowFullQuery: () => void;
}

const SearchDetails: FunctionComponent<SearchDetailsProps> = ({
  filters,
  filterStrings,
  resetFilters,
  searchOperator,
  searchFilterOperators,
  searchQuery,
  showFullQuery,
  toggleShowFullQuery,
}) => {
  if (filterStrings.length === 0) return <div />; // empty div needed for styling (spacing)

  const stripOperatorsBetweenQuotes = (updatedString: string): string => (
    updatedString.split('"').map((charBlock: string, i: number) => (
      i % 2 === 1 // odd index (blocks of text between quotes are at odd indices)
        ? charBlock.split(` ${searchOperator} `).join(' ') // remove search operator ('AND', 'OR') for character blocks between quotes
        : charBlock
    )).join('"')
  );

  const applySearchOperators = (): string => {
    const initialString = unescape(filterLogic(filters, searchQuery, searchFilterOperators));
    const trimmedSearchQuery = searchQuery?.trim();
    if (!trimmedSearchQuery) {
      return initialString;
    }

    let updatedString: string;
    const queryContainsAdvSyntax = trimmedSearchQuery.includes('strict:(') || trimmedSearchQuery.includes('moderate:(');
    if (!queryContainsAdvSyntax && [SearchOperatorOptions.AND, SearchOperatorOptions.OR].includes(searchOperator)) {
      updatedString = trimmedSearchQuery.split(' ').join(` ${searchOperator} `);
      updatedString = `(${updatedString})`;
    } else {
      // 'exactly this phrase' (Strict) and 'contains this phrase' (Quoted)
      updatedString = (trimmedSearchQuery.startsWith('"') && trimmedSearchQuery.endsWith('"')) || queryContainsAdvSyntax
        ? trimmedSearchQuery // don't add double quotes if it's already double quoted or uses advanced syntax
        : `"${trimmedSearchQuery}"`;
    }

    return initialString.replace(`(${trimmedSearchQuery})`, stripOperatorsBetweenQuotes(updatedString));
  };

  return (
    <div className="search-details-container">
      <div className="filters-results-row results-row">
        <div className="filters-results-wrap">
          <div className="filters-results-list show-results">
            <span><Trans>Filtering by:</Trans>&nbsp;</span>
            {filterStrings.map((item, i) => (
              <span key={`item-${i}`}>
                {item}
                {i < filterStrings.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
          <div className="clickable-options">
            <TextButton
              className="exact-query-group"
              icon={showFullQuery ? 'bff-hide' : 'bff-show'}
              iconSize={16}
              onClick={toggleShowFullQuery}
            >
              {showFullQuery ? <Trans>Hide Query</Trans> : <Trans>View Search Query</Trans>}
            </TextButton>
            <TextButton
              className="reset-filters"
              onClick={resetFilters}
            >
              <Trans>Clear Filters</Trans>
            </TextButton>
          </div>
        </div>
      </div>
      {showFullQuery && (
        <div className="filters-results-row query-row">
          <div className="filters-results-wrap">
            <div className="filters-results-list exact-query">
              <Trans>Query:</Trans>&nbsp;
              {applySearchOperators()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchDetails;
