import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { StandardTooltip } from '@brandfolder/react';
import { TextButton } from '@components/library/button/index';
import { ResourcePrivacyBadge } from '@components/bulk_management/user-settings/resource-sidebar/ResourcePrivacyBadge';
import {
  HeadingType,
  ResourceBase,
  ResourceDetails,
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';

import '../styles/resource-sidebar.scss';

interface ResourceTooltipNameProps {
  headingType: HeadingType;
  resource: ResourceBase;
  selectedResourceKey: string;
  setResource: SetStateDispatch<ResourceDetails>;
  hidePrivacy?: boolean;
}

export const ResourceTooltipName: FunctionComponent<ResourceTooltipNameProps> = ({
  headingType,
  hidePrivacy,
  resource,
  selectedResourceKey,
  setResource
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const resourceName = useRef(null);
  const { adminable, key, name, privacy } = resource;

  useEffect(() => {
    setTimeout(() => {
      if (resourceName?.current) {
        const { offsetWidth, scrollWidth } = resourceName?.current;
        if (offsetWidth < scrollWidth) {
          setShowTooltip(true);
        }
      }
    }, 10); // Need a short delay to allow time to fetch and render the <ResourceAccordion> component
  }, []);

  return (
    <TextButton
      ref={resourceName}
      className={classnames(
        'resource-sidebar__button',
        { ['active-resource']: selectedResourceKey === key },
        { ['resource-sidebar__button--nested']: !['portal', 'brandguide', 'organization'].includes(headingType) }
      )}
      onClick={(): void => setResource({
        adminable,
        headingType: headingType,
        key,
        name,
        type: headingType === 'subcollection' || headingType === 'workspace' ? 'collection' : headingType })}
    >
      {!hidePrivacy && <ResourcePrivacyBadge privacy={privacy} />}
      {showTooltip ? (
        <StandardTooltip id={`${name}-${key}`} tooltip={name} transitionDuration={100}>
          <p className="resource-sidebar__button--no-accordion">{name}</p>
        </StandardTooltip>
      ) : (
        <span className="resource-sidebar__button--no-accordion">{name}</span>
      )}
    </TextButton>
  );
};
