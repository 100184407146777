import React from "react";
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import ShowPage from './show-page';

export default (props) => (
  <I18nProviderWrapper>
    <DndProvider backend={HTML5Backend}>
      <ShowPage {...props} />
    </DndProvider>
  </I18nProviderWrapper>
);
