/* eslint-disable @typescript-eslint/naming-convention */
import { removeFileExtension } from '@brandfolder/utilities';
import { plural, t } from '@lingui/macro';
import {
  PickerFileMetadata,
  PickerResponse,
} from 'filestack-js/build/main/lib/picker';
import _ from 'underscore';

import { StructuredFileMetadata } from '@api/uploaders';
import { AssetsResponse } from '@api/v4/assets/assetTypes';
import { Attachment } from '@api/v4/attachments/attachmentTypes';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';
import { removeUnderscores } from '@helpers/humanize';
import { notifyOverLimits } from '@helpers/notify_over_limits';
import { mergeAssetOptions } from '@helpers/sweet_alert_options';
import { successOptions } from '@helpers/sweet_alert_options';
import { structureFilestackFiles } from '@helpers/uploaders';

export const filteredFiles = (
  uploadedFiles: PickerFileMetadata[]
): PickerFileMetadata[] => {
  let files = _.filter(
    uploadedFiles,
    (file) =>
      file.filename.charAt(0) !== '.' &&
      !file.filename.match(/thumbs\.db|\.jsx?$/i) &&
      !file.mimetype.match(
        /application\/javascript|text\/javascript|application\/x-actionscript|text\/x-actionscript|application\/ecmascript|text\/ecmascript/i
      )
  );
  // sort alphabetically for upload
  files = _.sortBy(files, 'filename');
  files = _.sortBy(files, 'folder_path');
  if (uploadedFiles.length !== files.length) {
    const restrictedFilesCount = uploadedFiles.length - files.length;
    window.swal({
      title: plural(restrictedFilesCount, { one: '# Restricted File Detected!', other: '# Restricted Files Detected!'}),
      text: t`We reject certain filetypes, including HTML and Javascript`,
      type: 'error',
    });
  }
  return files;
};

export const formatNames = (files: StructuredFileMetadata[]): string[] =>
  files.map((file) => removeFileExtension(file.filename));

export const addFiles = (
  pickerFiles: PickerResponse,
  onUpload: (
    files: StructuredFileMetadata[] | Record<string, StructuredFileMetadata[]>
  ) => void
): void => {
  const files = filteredFiles(pickerFiles.filesUploaded);
  const structuredFiles = structureFilestackFiles(files);
  const formattedNames = formatNames(structuredFiles);

  if (formattedNames.length !== new Set(formattedNames).size) {
    window.swal(mergeAssetOptions(), (mergeSelected) => {
      if (mergeSelected) {
        const uniqueFiles = _(structuredFiles).groupBy((file) =>
          removeFileExtension(file.filename)
        );
        return onUpload(uniqueFiles);
      }
      return onUpload(structuredFiles);
    });
  } else {
    return onUpload(structuredFiles);
  }
};

/**
 * Handles <UploadArea> usage from .slim files
 * @param files PickerResponse
 * @param uploadType UploadAreaTypes
 * @returns void
 */
export const handleCustomImages = (
  files: PickerResponse,
  uploadType: UploadAreaTypes
): void => {
  if (!files.filesUploaded.length) {
    return;
  }

  const filtered = filteredFiles(files.filesUploaded);
  const structured = structureFilestackFiles(filtered);

  const file = structured[0];
  const $parent = $(`.j-upload-settings-well.${uploadType}`);
  $parent.find('.filepicker_object').val(JSON.stringify(file));
  $parent.find('.filepicker-complete .filename').text(file.filename);
  $parent.find('.filepicker-complete .filename').attr('href', file.url);
  $parent.find('.filepicker-complete-pane').addClass('complete');
  $parent.find('.filestack-uploader-component').hide();
};

interface HandleUploadAssetAttachmentOptions {
  attachment: Attachment;
  attachmentKey: string;
  files: PickerResponse;
  onSuccess: () => void;
  setLoading: SetStateDispatch<boolean>;
}

/**
 * Handle uploading attachment versions for asset workflow check out/check in
 * @param options HandleUploadAssetAttachmentOptions
 * @returns void
 */
export const handleUploadAssetAttachment = (
  options: HandleUploadAssetAttachmentOptions
): void => {
  const { attachment, attachmentKey, files, onSuccess, setLoading } = options;

  setLoading(true);

  const filtered = filteredFiles(files.filesUploaded);
  const structured = structureFilestackFiles(filtered);
  const file = structured[0];

  $.ajax({
    url: `/api/v4/attachments/${attachmentKey}`,
    type: 'PUT',
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify({
      data: {
        attributes: {
          filename: file.filename || null,
          url: file.url || null,
          mimetype: file.mimetype || null,
          size: file.size || null,
          external_id: file.external_id || null,
          source: 'filestack',
          folder_path: file.folder_path || null,
        },
      },
    }),
    context: this,
    beforeSend(xhr) {
      xhr.setRequestHeader('Authorization', `Bearer ${BF_Token}`);
    },
    success: (response) => {
      window.swal(
        successOptions({
          customClass: 'attachment-updated',
          showCancelButton: false,
          showConfirmButton: true,
          successCopy: t`Your attachment has been updated!`,
        }),
        () => {}
      );

      const imageUrl = response.data.attributes.url;
      // update asset image on tab if updating the first attachment on asset modal Edit tab
      if (attachment.position === 0) {
        $('.j-asset-description img.m-asset-img-thumb').attr('src', imageUrl);
        // bulkRefreshSections([assetKey]);
      }

      onSuccess();
      setLoading(false);
    },
    error: (error) => {
      console.log('Error Updating Attachment', error);
      setLoading(false);
    },
  });
};

interface HandleUploadAssetToSectionOptions {
  activeLabelKey: string;
  files: PickerResponse;
  onAssetProcessing: (count: number) => void;
  onNewAssets: (response: AssetsResponse) => void;
  sectionKey: string;
}

export const handleUploadAssetToSection = (
  options: HandleUploadAssetToSectionOptions
): void => {
  const { activeLabelKey, files, onAssetProcessing, onNewAssets, sectionKey } =
    options;

  const filtered = filteredFiles(files.filesUploaded);
  const structured = structureFilestackFiles(filtered);

  let uniqueFiles:
  | Array<StructuredFileMetadata>
  | _.Dictionary<StructuredFileMetadata[]> = [...structured];

  const completeFileUpload = (): void => {
    const brandfolderKey = BF.fx.brandfolder().key;
    const collectionKey = BF.fx.collection() ? BF.fx.collection().key : null;
    const uploadEndpoint = collectionKey
      ? `/api/v4/collections/${collectionKey}/assets?fast_jsonapi=true&queue_priority=high`
      : `/api/v4/brandfolders/${brandfolderKey}/assets?fast_jsonapi=true&queue_priority=high`;

    const assetData = [];

    _.each(uniqueFiles, (file, iteratee) => {
      // Account for merged attachments
      if (Array.isArray(file)) {
        const attachmentData = file;
        attachmentData.map((attachment) => ({
          ...attachment,
          source: 'filestack',
        }));
        assetData.push({
          attachments: attachmentData,
          ...(activeLabelKey && { labels: [activeLabelKey] }),
          name: iteratee,
        });
      } else {
        const asset_name = removeUnderscores(
          removeFileExtension(file.filename)
        ).trim();
        const attachmentData = file;
        attachmentData.source = 'filestack';
        assetData.push({
          attachments: [attachmentData],
          ...(activeLabelKey && { labels: [activeLabelKey] }),
          name: asset_name,
        });
      }
    });

    onAssetProcessing(assetData.length);

    const formData = {
      data: { attributes: assetData },
      section_key: sectionKey,
    };

    $.ajax({
      url: uploadEndpoint,
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(formData),
      context: this,
      beforeSend(xhr) {
        xhr.setRequestHeader('Authorization', `Bearer ${BF_Token}`);
      },
      success: (response) => {
        onNewAssets(response);

        if (response?.meta?.over_limits) {
          notifyOverLimits();
        }

        onAssetProcessing(0);
      },
      error: (error) => {
        console.log('Error uploading File(s)', error);
        onAssetProcessing(0);
      },
    });
  };

  const formattedNames = structured.map((file) =>
    removeFileExtension(file.filename)
  );
  if (formattedNames.length !== _.uniq(formattedNames).length) {
    $('html').attr('style', '');
    window.swal(mergeAssetOptions(), (merge_selected) => {
      if (merge_selected) {
        const unique = _(structured).groupBy((file) =>
          removeFileExtension(file.filename)
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // uniqueFiles = [] as Array<StructuredFileMetadata[]>;
        // Object.entries(unique).forEach(([key, attachments]) => {
        //   uniqueFiles.push([...attachments]);
        // });
        uniqueFiles = { ...unique };
        completeFileUpload();
      } else {
        uniqueFiles = [...structured];
        completeFileUpload();
      }
    });
  } else {
    completeFileUpload();
  }
};
