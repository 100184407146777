import { t, Trans } from '@lingui/macro';
import React, { useContext, useState, useRef, FunctionComponent } from 'react';

import { GenericFileData } from '@api/v4/assets/assetTypes';

import {
  ActionTypes,
  AssetDetailsDispatch,
} from '@components/asset/modal/tabs/edit/EditTabTypes';

import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';

import RadioButton from '@components/common/radio_button/main';

// these colors are treated differently than hex inputs
// and format matters
// we use a map so we can use them as constants too
const ReservedColors = {
  Default: 'default', // eslint-disable-line @typescript-eslint/naming-convention
  White: 'white' // eslint-disable-line @typescript-eslint/naming-convention
};

interface AssetBackgroundColorScopedContext {
  state: {
    editState: {
      genericFileData: GenericFileData | null;
    };
  };
  dispatch: AssetDetailsDispatch;
}

const AssetBackgroundColor: FunctionComponent<{ isTask?: boolean }> = ({
  isTask
}) => {
  // a try at keeping things immutable.
  Object.freeze(ReservedColors);

  const { state, dispatch }: AssetBackgroundColorScopedContext = useContext(assetModalContext);
  const { genericFileData } = state.editState;
  const { asset_background_color: assetBackgroundColor } = { ...genericFileData };

  const shouldDefaultBeChecked = !assetBackgroundColor || (assetBackgroundColor === ReservedColors.Default);
  const isDefaultChecked = shouldDefaultBeChecked ? ReservedColors.Default : '';

  const isWhiteChecked = assetBackgroundColor === ReservedColors.White ? ReservedColors.White : '';

  const isHexChecked = assetBackgroundColor?.substring(0, 1) === '#' ? '#' : '';

  const ReservedColorValues = Object.keys(ReservedColors).map((color) => ReservedColors[color]);

  const defaultHexValue = (!assetBackgroundColor || ReservedColorValues.includes(assetBackgroundColor)) ? '' : assetBackgroundColor;

  const [hexValue, setHexValue] = useState(defaultHexValue);

  const inputEl = useRef(null);

  const updateBackgroundColor = (value: string, isHexInput: boolean): void => {
    let trimmedValue = value.trim();

    if (isHexInput) {
      if (!trimmedValue.startsWith('#')) trimmedValue = '#'.concat(trimmedValue);
      setHexValue(trimmedValue);
    } else {
      // if we choose another option, clear hex input
      setHexValue('');
    }

    const payload = {
      genericFileData: {
        ...genericFileData,
        asset_background_color: trimmedValue // eslint-disable-line @typescript-eslint/naming-convention
      }
    };
    dispatch({ type: ActionTypes.UpdateAsset, payload });
  };

  return (
    <div className="asset-advanced__feature asset-background-color">
      <span>
        {isTask ? <Trans>Task Background Color</Trans> : <Trans>Asset Background Color</Trans>}
      </span>
      <div className="radio-button-container">
        <RadioButton
          checkedButton={isDefaultChecked}
          labelCopy={t`Default`}
          name="asset-background-color"
          onChangeFunction={(): void => updateBackgroundColor(ReservedColors.Default, false)}
          valueCopy={ReservedColors.Default}
        />
        <span className="default-background-color">
          <Trans comment="parenthetical">(transparent)</Trans>
        </span>
        <RadioButton
          checkedButton={isWhiteChecked}
          labelCopy={t`White`}
          name="asset-background-color"
          onChangeFunction={(): void => updateBackgroundColor(ReservedColors.White, false)}
          valueCopy={ReservedColors.White}
        />
        <>
          <RadioButton
            checkedButton={isHexChecked}
            labelCopy={t`Hex`}
            name="asset-background-color"
            onChangeFunction={(): void => { updateBackgroundColor('#', true); inputEl.current.focus(); }}
            valueCopy="#"
          />
          <input
            ref={inputEl}
            className="advanced-input"
            onChange={(e): void => updateBackgroundColor(e.currentTarget.value, true)}
            onFocus={(): void => updateBackgroundColor('#', true)}
            value={hexValue}
          />
        </>
      </div>
    </div>
  );
};

export default AssetBackgroundColor;
