import { ApiDataResponse, ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { LabelsServer } from '@components/bulk_management/automation/AutomationTypes';

export interface CreateLabelOptions {
  name: string;
  position?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  parent_key?: string;
}

export type GetLabelsResponse = ApiDataResponse<LabelsServer, 'labels'>;
export type GetLabelsResponseData = ApiResponseObject<LabelsServer, 'labels'>;

export interface LabelTree {
  children: Array<LabelTreeChild>;
}

export interface LabelTreeChild extends GetLabelsResponseData {
  title: string;
  name: string;
  key: string;
  children: Array<LabelTreeChild>;
  expanded: boolean;
}

// type guard for LabelTreeChild vs LabelTree
export const isLabelTreeChild = (tree: LabelTree | LabelTreeChild): tree is LabelTreeChild => (tree as LabelTreeChild).id !== undefined;
