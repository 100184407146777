/**
 * timeDifferenceInDays - get how many days between two dates
 * @param {Date} dateA the dateA date
 * @param {Date} dateB the dateB date
 * @returns {number} positive integer
 */
export const timeDifferenceInDays = (dateA: Date, dateB: Date): number => {
  const timeA = dateA.getTime();
  const timeB = dateB.getTime();
  const differenceMilliseconds = timeB - timeA;
  const milliseconds = 1000;
  const seconds = 60;
  const minutes = 60;
  const hours = 24;
  return Math.abs(Math.trunc(differenceMilliseconds / (milliseconds * seconds * minutes * hours)));
};
