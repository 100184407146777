import { t } from '@lingui/macro';

import { determineUGTLocale, getAvailableLanguages } from '@components/asset/modal/tabs/edit/helpers';
import { Locale } from '@components/common/language_menu/languagesMap';
import { ListOption } from '@components/library/dropdown';
import { SearchOperatorOptions } from '@components/show_page/sections/section_search/SearchTypes';
import { UserViewOptions, UserViewOptionsKeys } from '@components/show_page/sections/view_options/ViewOptionTypes';
import { getStorage, setStorage, StorageTypes } from '@helpers/storage';

export enum SortOptions {
  UploadDateNewest = 'created_at DESC',
  UploadDateOldest = 'created_at ASC',
  UpdatedNewest = 'updated_at DESC',
  UpdatedOldest = 'updated_at ASC',
  NameAtoZ = 'name ASC',
  NameZtoA = 'name DESC',
  MostPopular = 'score DESC',
  LeastPopular = 'score ASC',
  CreationDateNewest = 'date_time_original DESC',
  CreationDateOldest = 'date_time_original ASC',
  Position = 'position ASC',
  Relevancy = 'relevancy DESC',
  TaskDueDateFurthest = 'task_due_date DESC',
  TaskDueDateSoonest = 'task_due_date ASC'
}

export interface ViewOptionsSortOption extends ListOption {
  label: string;
  value: SortOptions;
}

export const sortOptions = (searchSubmitted = false): ViewOptionsSortOption[] => {
  let options: ViewOptionsSortOption[] = [
    { value: SortOptions.UploadDateNewest, label: t`Upload date (newest)` },
    { value: SortOptions.UploadDateOldest, label: t`Upload date (oldest)` },
    { value: SortOptions.UpdatedNewest, label: t`Updated (newest)` },
    { value: SortOptions.UpdatedOldest, label: t`Updated (oldest)` },
    { value: SortOptions.NameAtoZ, label: t`Name (A-Z)` },
    { value: SortOptions.NameZtoA, label: t`Name (Z-A)` },
    { value: SortOptions.MostPopular, label: t`Most popular` },
    { value: SortOptions.LeastPopular, label: t`Least popular` },
  ];

  if (BFG.hasFeature('sort_by_original_create_date')) {
    options = [
      { value: SortOptions.CreationDateNewest, label: t`Creation date (newest)` },
      { value: SortOptions.CreationDateOldest, label: t`Creation date (oldest)` },
      ...options
    ];
  }

  if (BFG.brandfolderSettings?.allow_positioning) {
    options = [
      { value: SortOptions.Position, label: t`Position` },
      ...options
    ];
  }

  const isWorkspace = BFG.hasFeature('workspace') && BFG.resource.is_workspace;
  if (isWorkspace) {
    options = [
      { value: SortOptions.TaskDueDateSoonest, label: t`Due date (soonest)` },
      { value: SortOptions.TaskDueDateFurthest, label: t`Due date (furthest)` },
      ...options
    ];
  }

  if (searchSubmitted) {
    options = [
      { value: SortOptions.Relevancy, label: t`Relevancy` },
      ...options
    ];
  }

  return options;
};

export const resolveDefaultSortOrder = (defaultSortOrder: SortOptions): SortOptions => {
  const isWorkspace = BFG.hasFeature('workspace') && BFG.resource.is_workspace;

  if (isWorkspace) {
    return SortOptions.TaskDueDateFurthest;
  } if (defaultSortOrder.includes('position') && !BFG.brandfolderSettings?.allow_positioning) {
    return sortOptions()?.[0]?.value || SortOptions.UploadDateNewest;
  }
  return defaultSortOrder;
};

const fallbackLocale = (): Locale => {
  const BFG = window.BFG; // test workaround to use window BFG mock
  const { staticSiteLocale, ugtLocaleDefault } = BFG.locales;

  // don't try to proactively set UGT locale unless multi-language setting is enabled
  if (!BFG.multiLanguageAssetDetailsEnabled) return ugtLocaleDefault;

  const staticSiteLocaleInWhitelisted = getAvailableLanguages().find((languageEntry) => (
    languageEntry.locale === staticSiteLocale
  ));
  return staticSiteLocaleInWhitelisted?.locale || ugtLocaleDefault;
};

export const getDefaultViewOptions = (defaultSortOrder: SortOptions, paginatePer: number, searchOperator?: SearchOperatorOptions): UserViewOptions => {
  const BFG = window.BFG; // test workaround to use window BFG mock
  const defaultSearchOperator = BFG.brandfolderSettings?.default_search_operator || SearchOperatorOptions.OR;
  const defaultStrictSearch = BFG.brandfolderSettings?.default_strict_search;

  return {
    assetsPerPage: paginatePer,
    // searchOperator will be passed in if we're on a search share link that has a search operator
    searchOperator: searchOperator || defaultStrictSearch ? SearchOperatorOptions.Strict : SearchOperatorOptions[defaultSearchOperator],
    selectedUGTLocale: fallbackLocale(),
    showAsGrid: true,
    showCustomFields: false,
    showTaskDetails: true,
    showSections: true,
    showEmptySections: true,
    sortOption: resolveDefaultSortOrder(defaultSortOrder)
  };
};

export const setUserViewOptions = (defaultSortOrder: SortOptions, paginatePer: number, searchOperator?: SearchOperatorOptions): UserViewOptions => {
  const BFG = window.BFG; // test workaround to use window BFG mock
  const defaultValues = { ...getDefaultViewOptions(defaultSortOrder, paginatePer, searchOperator) };
  // resource specific view options for current user, don't load for share views
  if (BFG.showPageLite) return defaultValues;

  const userViewOptionsAll = getStorage(StorageTypes.Local, `userViewOptions_${BFG.currentUser.user_id}`);
  const userViewOptions = userViewOptionsAll?.[BFG.resource.key] || {};

  if (userViewOptions && Object.keys(userViewOptions).includes(UserViewOptionsKeys.SelectedUGTLocale)) {
    // i.e. ensure that UGT found in local storage is useable
    userViewOptions.selectedUGTLocale = determineUGTLocale();
  }

  const processedUserViewOptions = {};
  [...Object.values(UserViewOptionsKeys)].forEach((option) => {
    // if we're on a search share link that has a search operator, use that value instead of the local storage value
    if (searchOperator && option === UserViewOptionsKeys.SearchOperator) {
      processedUserViewOptions[option] = searchOperator;
    } else {
      processedUserViewOptions[option] = userViewOptions[option] ?? defaultValues[option];
    }
  });

  return processedUserViewOptions;
};

export const updateUserViewOptionsInLocalStorage = (userViewOptions: UserViewOptions): void => {
  // update local storage with new user view options for this resource after state has updated
  const userId = BFG.currentUser.user_id;
  const resourceKey = BFG.resource.key;
  const userViewOptionsAll = { ...getStorage<UserViewOptions>(StorageTypes.Local, `userViewOptions_${userId}`) };

  if (!userViewOptionsAll[resourceKey]) {
    userViewOptionsAll[resourceKey] = {};
  }

  if (!Object.keys(userViewOptions).length) {
    delete userViewOptionsAll[resourceKey]; // clean up record so local storage doesn't balloon
  } else {
    userViewOptionsAll[resourceKey] = userViewOptions;
  }

  setStorage(StorageTypes.Local, `userViewOptions_${userId}`, userViewOptionsAll);
};

/**
 * When a user is not searching but has relevancy as their preference, use the defaultSortOrder.
 */
export const getSortOption = (searchSubmitted: boolean, sortOption: string, defaultSortOrder: SortOptions): string => {
  if (searchSubmitted) return sortOption;
  if (!sortOption) return defaultSortOrder;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  return sortOption === SortOptions.Relevancy ? defaultSortOrder : sortOption;
};
