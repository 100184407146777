import * as React from 'react';

import {
  LabelTree as LabelTreeType,
  LabelTreeChild as LabelTreeChildType
} from '@api/v4/resources/types/labelsTypes';

import Checkbox from '@components/common/checkbox/main';

interface SelectLabelParams {
  selectedLabels: string[];
  setSelectedLabels: SetStateDispatch<string[]>;
  label: string;
}

// select or deselect labels
const selectLabel = ({ selectedLabels, setSelectedLabels, label }: SelectLabelParams): void => {
  // selectedLabels is a useState value, dont mutate it, copy it
  const copy = [...selectedLabels];
  const index = copy.indexOf(label);
  if (index > -1) {
    copy.splice(index, 1);
  } else {
    copy.push(label);
  }
  setSelectedLabels(copy);
};

interface LabelTreeProps {
  labelTree: LabelTreeType| LabelTreeChildType;
  selectedLabels: string[];
  setSelectedLabels: SetStateDispatch<string[]>;
}

const LabelTree: React.FunctionComponent<LabelTreeProps> = ({
  labelTree,
  selectedLabels,
  setSelectedLabels
}): React.ReactElement => (
  <ul className="label-tree-list">
    {
      labelTree.children && labelTree.children.length > 0
        ? Array.from(labelTree.children).map((child: LabelTreeChildType) => {
          if (child.children.length > 0) {
            return (
              <LabelBranchComponent
                key={`branch-${child.key}`}
                label={child}
                toggle={(): void => selectLabel({ selectedLabels, setSelectedLabels, label: child.key })}
                selectedLabels={selectedLabels}
                setSelectedLabels={setSelectedLabels}
                checked={selectedLabels.includes(child.key)}
              />
            );
          }
          return (
            <LabelLeafComponent
              key={`leaf-${child.key}`}
              depth={child.attributes.depth}
              position={child.attributes.position}
              checked={selectedLabels.includes(child.key)}
              toggle={(): void => selectLabel({ selectedLabels, setSelectedLabels, label: child.key })}
              name={child.attributes.name}
            />
          );
        })
        : <h3 className="no-labels-warning">No labels available.</h3>
    }
  </ul>
);

// terminal label with no children
interface LabelLeafComponentProps {
  depth: LabelTreeChildType['attributes']['depth'];
  position: LabelTreeChildType['attributes']['position'];
  name: LabelTreeChildType['attributes']['name'];
  checked: boolean;
  toggle: () => void;
}

const LabelLeafComponent: React.FunctionComponent<LabelLeafComponentProps> = ({
  depth,
  position,
  checked,
  name,
  toggle
}): React.ReactElement => (
  <li
    className={`label-tree-entry level-${depth}-${position}`}
  >
    <div
      style={{ marginLeft: `${16 + depth * 20}px` }}
      className="label-tree-label"
    >
      <LabelContent name={name} toggle={toggle} checked={checked} />
    </div>
  </li>
);

interface LabelBranchComponentProps {
  label: LabelTreeChildType;
  toggle: () => void;
  checked: boolean;
  selectedLabels: string[];
  setSelectedLabels: SetStateDispatch<string[]>;
}

const LabelBranchComponent: React.FunctionComponent<LabelBranchComponentProps> = ({
  label,
  toggle,
  checked,
  selectedLabels,
  setSelectedLabels,
}): React.ReactElement => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <li
      className={`label-tree-entry level-${label.attributes.depth}-${label.attributes.position}`}
    >
      <div
        style={{
          marginLeft: `${label.attributes.depth * 20}px`,
          maxWidth: `calc(100% - ${label.attributes.depth * 20}px)`
        }}
        className={`label-tree-label ${expanded ? 'expanded' : ''}`}
      >
        <div className="label-tree-row">
          <span
            className="bff-caret-down icon"
            onClick={(): void => setExpanded(!expanded)}
            role="button"
            tabIndex={0}
            onKeyDown={(): void => setExpanded(!expanded)}
          />
          <LabelContent
            name={label.attributes.name}
            toggle={toggle}
            checked={checked}
          />
        </div>
      </div>
      <LabelTree
        labelTree={label}
        selectedLabels={selectedLabels}
        setSelectedLabels={setSelectedLabels}
      />
    </li>
  );
};

interface LabelContentProps {
  checked: boolean;
  name: LabelTreeChildType["name"];
  toggle: () => void;
}

const LabelContent: React.FunctionComponent<LabelContentProps> = ({
  checked,
  name,
  toggle
}): React.ReactElement => (
  <>
    <Checkbox size="sm" checked={checked} toggle={toggle} />
    <span className="bff-label icon" />
    <p className="name-copy">{name}</p>
  </>
);

export default LabelTree;
