import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { FormControl } from 'react-bootstrap';

import PillInput from '@components/common/pill_input/main';
import { WarningButton } from '@components/library/button';
import { StandardCheckbox } from '@components/library/checkbox';
import {
  DisplayedCustomFieldKey,
  UpdateRowArguments
} from '@components/settings/brandfolder/custom-fields-tab/CustomFieldsFormTypes';

interface CustomFieldRowProps {
  displayedCustomFieldKey: DisplayedCustomFieldKey;
  index: number;
  removeRow: (index: number) => void;
  updateRow: (updateRowObject: UpdateRowArguments) => void;
  valid: boolean;
}

interface PillState {
  active: boolean;
  newPill: string;
  pendingDelete: boolean;
  pills: string[];
}

export const CustomFieldRow: FunctionComponent<CustomFieldRowProps> = ({
  displayedCustomFieldKey,
  index,
  removeRow,
  updateRow,
  valid,
}) => {
  const {
    allowedValues: allowedValuesProp,
    multiValueEnabled: multiValueEnabledProp,
    multiValuePersisted,
    name: nameProp,
    required: requiredProp
  } = displayedCustomFieldKey;

  const handleUpdateRow = ({ allowedValues, multiValueEnabled, name, required }: UpdateRowArguments): void => {
    updateRow({
      index,
      ...allowedValues !== undefined && { allowedValues },
      ...multiValueEnabled !== undefined && { multiValueEnabled },
      ...name !== undefined && { name },
      ...required !== undefined && { required },
    });
  };

  return (
    <div className="brandfolder-settings__control-custom-fields--list-item">
      <div className="custom-field-key-input column-1">
        <FormControl
          className="custom-field-input"
          id={nameProp}
          name="name"
          onChange={(e): void => handleUpdateRow({ name: e.target.value })}
          placeholder={t`Key`}
          style={{ borderColor: !valid && nameProp !== '' && '#D0021B' }}
          type="text"
          value={nameProp}
        />
      </div>
      <div className="custom-field-values-input column-2">
        <PillInput
          className="custom-field-input"
          id="allowed_values"
          name="allowed_values"
          onChangeCallback={(pillState: PillState): void => handleUpdateRow({ allowedValues: pillState.pills })}
          pills={allowedValuesProp}
          placeholderText={allowedValuesProp.length === 0 && t`Input value`}
          type="text"
        />
      </div>
      <StandardCheckbox
        ariaLabel={t`allow multiple values for custom field ${nameProp}`}
        checked={multiValueEnabledProp}
        className="column-checkbox column-3"
        disabled={multiValuePersisted}
        onChange={(): void => handleUpdateRow({ multiValueEnabled: !multiValueEnabledProp })}
      />
      <StandardCheckbox
        ariaLabel={t`Make custom field required`}
        checked={requiredProp}
        className="column-checkbox column-4"
        onChange={(): void => handleUpdateRow({ required: !requiredProp })}
      />
      <WarningButton
        aria-label={t`remove ${nameProp}`}
        className="remove-control-custom-field column-5"
        icon="bff-trash"
        onClick={(): void => removeRow(index)}
      />
    </div>
  );
};
