import { StandardComboboxLabels } from '@brandfolder/react';
import { t } from '@lingui/macro';

import { loaderLabel } from './loader';

export const getStandardComboboxLabels = (labels: Partial<StandardComboboxLabels> = {}): StandardComboboxLabels => {
  return {
    emptyLabel: t`No options`,
    iconButtonLabel: t`Toggle options`,
    label: t`Options`,
    listboxLabel: t`List of options`,
    loaderLabel: loaderLabel(),
    requiredLabel: t`Required`,
    ...labels
  };
};
