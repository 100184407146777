// custom hook to get previous props or state
// see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
import { useEffect, useRef } from 'react';

export const usePrevious = <T>(val: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = val;
  });
  return ref.current;
};
