import { Trans } from '@lingui/macro'
import React from 'react';
import LazyLoad from 'react-lazy-load';

import FontClass from '@components/asset/shared/fontClass';
import '@components/show_page/sections/styles/section.scss';

// Note: This was copied from the sections/asset/card component 6/20
const AssetThumbnail = ({ asset }) => {
  let handleThumbnailError = (e) => {
    // Hacky, but don't want to implement state unless necessary
    const { extension, name } = asset.attributes;
    const modifiedExtension = extension ? extension.replace('.', '') : '-';
    const source = (modifiedExtension) ? `${modifiedExtension}.svg` : `file.svg`;
    e.target.src = `${BFG.icon_service_url}/assets/types/${source}`;
    e.target.alt = name;
    e.target.style.cssText = "padding: 25px;";
  };

  const renderBanner = () => {
    switch (asset.attributes.availability) {
      case "pending_approval": case "pending_approval_view_only":
        return <div className="unapproved-ribbon"><Trans>Pending</Trans></div>;
      case "unpublished": case "unpublished_view_only":
        return <div className="unapproved-ribbon"><Trans>Draft</Trans></div>;
      case "expired": case "expired_view_only":
        return <div className="unapproved-ribbon"><Trans>Expired</Trans></div>;
      default:
        return undefined;
    }
  };

  const renderGeneralThumbnail = () => {
    const { thumbnail_url, name, background_color } = asset.attributes;
    const style = background_color
      ? { backgroundColor: background_color, background: background_color }
      : { backgroundColor: "transparent" };
    return (
      <div className="card-thumbnail" style={style}>
        {asset.attributes.availability !== "available" ? renderBanner() : ""}
        <LazyLoad height="100%" offset="5000px 0px">
          <img
            alt={name}
            className={`image-div ${(thumbnail_url || '').toString().includes("/assets/types") ? "thumbnail_fallback" : ""}`}
            data-private
            onError={(e) => {
              if (typeof handleThumbnailError === "function") {
                handleThumbnailError(e);
              }
              handleThumbnailError = null;
            }}
            src={thumbnail_url}
          />
        </LazyLoad>
      </div>
    );
  };

  const hasThumbnailOverride = () => {
    const overrideType = ["Color", "colors", "Font", "fonts"].includes(asset.attributes.type);
    // Types will return a url with "/assets/types/empty" unless thumbnail override has been set
    const hasThumbnail = !asset?.attributes?.thumbnail_url?.includes("/assets/types/empty");
    return (overrideType && hasThumbnail);
  };

  const renderAssetThumbnail = () => {
    const { name, availability } = asset.attributes;
    const { type } = asset.attributes;

    if (hasThumbnailOverride(type)) {
      return renderGeneralThumbnail();
    }

    let colorHtml;
    switch (type) {
      case "Color":
        if (asset.attributes) {
          colorHtml = asset.attributes.html;
        }

        if (colorHtml) {
          return (
            <div
              className="card-thumbnail card-thumbnail__color"
              style={{ backgroundColor: colorHtml, background: colorHtml }}
            >
              {availability !== "available" ? renderBanner() : ""}
            </div>
          );
        }

        return (
          <div className="card-thumbnail">
            {availability !== "available" ? renderBanner() : ""}
          </div>
        );
      case "Font":
        return (
          <div className="card-thumbnail card-thumbnail__font">
            {availability !== "available" ? renderBanner() : ""}
            <FontClass asset={asset} showPage>
              <Trans comment="Example text for fonts">Aa Bb</Trans>
            </FontClass>
          </div>
        );
      case "Text":
        return (
          <div className="card-thumbnail" style={{ background: 'none', display: 'block' }}>
            {availability !== "available" ? renderBanner() : ""}
            <h4 className="asset-name">{name}</h4>
          </div>
        );
      default:
        return renderGeneralThumbnail();
    }
  };

  return renderAssetThumbnail();
};

export default AssetThumbnail;
