import { Trans } from '@lingui/macro';
import PropTypes, { shape } from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

import { getBrandfolderPermissionLevel } from '@api/v4/brandfolders/permission-level';
import { UserRoles } from '@api/v4/UserTypes';
import { generateAssetDeepLink } from '@helpers/assets';

import ClipboardCopy from '../../common/clipboard_copy/main';

import AssetDetails from './tabs/asset_details/orgSearchResultsAssetDetails';
import AssetEmbed from './tabs/embed/main';
import MetadataTab from './tabs/metadata/main';
import CdnUsage from './tabs/usage/cdnUsage';

class AssetModalContentWrap extends React.Component {
  /* NOTE  We have an action for when the user clicks edit on a tab now (see updateActiveTab)
  but it just went to the asset in the Brandfolder (not in the edit state) so
  until that can be improved I'm leaving edit off the tabs options */
  /* NOTE For now we are hiding anything that shouldn't be seen by a guest user
  until we have access to user permissions: status and usage (and edit) */
  state = {
    activeImage: this.props.attachments[0],
    activeTab: 'overview',
    tabs: ['overview', 'metadata']
  };

  componentDidMount() {
    this.displayEmbedTab();
  }

  displayEmbedTab = async () => {
    // modal defaults to not displaying embed tab
    const { asset, brandfolder } = this.props;
    const { is_downloadable } = asset.attributes;
    let isUserCollabPlus = false;

    try {
      const permissionLevel = await getBrandfolderPermissionLevel({ brandfolderKey: brandfolder.id });
      isUserCollabPlus = [UserRoles.Owner, UserRoles.Admin, UserRoles.Collaborator]
        .includes(permissionLevel.data.id);
    } finally {
      this.setState((prevState) => ({
        tabs: [...prevState.tabs, ...(is_downloadable && isUserCollabPlus ? ['embed'] : [])]
      }))
    }
  }

  updateActiveImage = (image) => {
    this.setState({ activeImage: image });
  }

  updateActiveTab = (tab) => {
    const {
      asset,
      brandfolder
    } = this.props;
    if (tab === "edit") {
      const href = generateAssetDeepLink({ assetKey: asset.id, slug: brandfolder.attributes.slug });
      window.location = href;
    } else {
      this.setState({ activeTab: tab });
    }
  }

  renderTabs() {
    const { tabs } = this.state;
    const { attributes, type } = this.props.asset;
    /* TODO Look at this and make sure to update this.  This logic may need to be updated */
    if (type === "external_media" || type === "color" || type === "font" || type === "text") {
      const metadata = tabs.indexOf("metadata");
      if (metadata > 0) { tabs.splice(metadata, 1); }

      // For external_media check for embed code
      if (type === "external_media" && (!attributes.asset_data.embed_code || !attributes.asset_data.embed_code.length > 0)) {
        const embed = tabs.indexOf("embed");
        if (embed > 0) { tabs.splice(embed, 1); }
      }
    }

    if (this.props.asset.attributes.attachment_count !== 0) {
      if (tabs.length > 1) {
        return tabs.map((tab) => (
          <li
            key={`tab-${tab}`}
            className={`${tab} ${tab === this.state.activeTab ? "active" : ""}`}
            onClick={() => this.updateActiveTab(tab)}
          >
            <span>{tab}</span>
          </li>
        ));
      }
    }

    return undefined;
  }

  renderMobileTabs() {
    const { tabs } = this.state;
    const { attributes, type } = this.props.asset;
    /* TODO Look at this and make sure to update this.  This logic may need to be updated */
    if (type === "external_media" || type === "color" || type === "font" || type === "text") {
      const metadata = tabs.indexOf("metadata");
      if (metadata > 0) { tabs.splice(metadata, 1); }

      // For external_media check for embed code
      if (type === "external_media" && (!attributes.asset_data.embed_code || !attributes.asset_data.embed_code.length > 0)) {
        const embed = tabs.indexOf("embed");
        if (embed > 0) { tabs.splice(embed, 1); }
      }
    }

    if (tabs.length > 1) {
      return tabs.map((tab) => (
        <li
          key={`tab-${tab}`}
          className={`nav-item ${tab}`}
          onClick={() => this.updateActiveTab(tab)}
        >
          <span>{tab.charAt(0).toUpperCase() + tab.substr(1)}</span>
        </li>
      ));
    }

    return undefined;
  }

  renderTab() {
    const {
      asset,
      attachments,
      brandfolder,
      closeModal,
      libraryName,
      tags,
    } = this.props;
    const { embed_code, url } = asset.attributes.asset_data;
    const { activeImage } = this.state;

    const href = generateAssetDeepLink({ assetKey: asset.id, slug: brandfolder.attributes.slug });

    switch (this.state.activeTab) {
      case "overview":
        return (
          <AssetDetails
            activeImage={activeImage && activeImage.id ? activeImage : attachments[0]}
            asset={asset}
            attachments={attachments}
            closeModal={closeModal}
            libraryName={libraryName}
            tags={tags}
            updateActiveImage={this.updateActiveImage}
          />
        );
      case "metadata":
        return <MetadataTab assetId={asset.id} orgSearchPage />;
      case "embed":
        if (asset.type === "external_media") {
          const youtubeUrl = url.indexOf('youtu.be') > -1 || url.indexOf('youtube.com') > -1 || url.indexOf('list=') > -1;
          let site;
          if (youtubeUrl) {
            site = 'youtube';
          } else if (url.indexOf('vimeo.com') > -1) {
            site = 'vimeo';
          } else {
            site = 'embedly';
          }

          let embed = embed_code;
          if ((site === "youtube" || site === "vimeo") && embed) {
            const embedStart = embed_code.split('src="')[0];
            const embedMiddle = embed_code.split('src="')[1];
            const embedEnd = embedMiddle.substring(embedMiddle.indexOf('"') + 1);

            if (site === "youtube") {
              embed = `${embedStart} src="//www.youtube.com/embed/${asset.attributes.site_id}" ${embedEnd}`;
            } else {
              embed = `${embedStart} src="//player.vimeo.com/video/${asset.attributes.site_id}" ${embedEnd}`;
            }
          }
          return (
            <div className="asset-embed asset-form-padding flex-wrapper asset-embed-component">
              <div className="embed-details embed-details-full-width">
                <div className="embed-text">
                  <h3>
                    <Trans>Embed this asset</Trans>
                  </h3>
                  <p className="full">
                    <Trans>
                      Embed this media by copying and pasting the following HTML code into your own web page.
                    </Trans>
                  </p>
                </div>
                <hr />
                <div className="copy-link-container">
                  <h3>
                    <Trans>Embed Code</Trans>
                  </h3>
                  <ClipboardCopy check showFeedbackAsTooltip={false} textToCopy={embed}>
                    <span className="bff-copy" />
                    <Trans>Copy to clipboard</Trans>
                  </ClipboardCopy>
                </div>
                <pre className="embed-link">
                  <code>
                    <span>{embed}</span>
                  </code>
                </pre>
              </div>
            </div>
          );
        }

        return (
          <AssetEmbed
            assetCdnUrl={asset.attributes.cdn_url}
            assetKey={asset.id}
            assetName={asset.attributes.name}
            brandfolderSlug={this.props.brandfolder.attributes.slug}
            cdnKey={asset.attributes.brandfolder_cdn_key}
            libraryName={libraryName}
          />
        );
      case "usage":
        return (
          <div className="flex-wrapper asset-usage-tab-container">
            <div className="modal-asset-one-third flex-one-third usage-list">
              <h3><Trans>Asset Locations</Trans></h3>
              <p><Trans>To view asset locations</Trans></p>
              <a
                className="noline"
                href={href}
                rel="noopener noreferrer"
                target="_blank"
              >
                <button className="button primary lg view-in-brandfolder-button" type="button">
                  <Trans>View in Brandfolder</Trans>
                </button>
              </a>
            </div>
            <div className="modal-asset-two-thirds flex-two-thirds usage-details">
              <div className="flex-wrapper title-container">
                <h3>{asset.attributes.name}</h3>
                <a href={`/insights/?page=/insights-app/${brandfolder.attributes.slug}/assets/${asset.id}`}>
                  <button className="button primary" type="button">
                    <Trans>View Insights</Trans>
                  </button>
                </a>
              </div>
              <hr />
              <div className="flex-wrapper">
                <div className="flex-half">
                  <div className="t-associated-share-links">
                    <p><Trans>To view asset share links</Trans></p>
                    <a
                      className="noline"
                      href={href}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <button className="button primary lg view-in-brandfolder-button" type="button">
                        <Trans>View in Brandfolder</Trans>
                      </button>
                    </a>
                  </div>
                </div>
                <div className="flex-half">
                  <CdnUsage assetKey={asset.id} />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div>
            <Trans>Error</Trans>
          </div>
        );
    }
  }

  renderTabContent() {
    const { activeTab, tabs } = this.state;
    return tabs.map((tab) => (
      <div
        key={`tab-content-${tab}`}
        className={`tab-pane ${tab === activeTab ? "active" : ""}`}
      >
        {this.renderTab()}
      </div>
    ));
  }

  render() {
    const { asset, attachments } = this.props;
    const { activeTab, tabs } = this.state;
    return (
      <Modal.Body
        className="modal-body"
        id="dialogBody"
      >
        <div className="m-show-actions-for-attachments">
          <div className="s-attachment-header">
            { BFG.embedView ? null
              : (
                <React.Fragment>
                  <ul className={`s-attachment-tabs tabs-${tabs.length} s-org-attachment-tabs nav`}>
                    { this.renderTabs() }
                  </ul>
                  <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbarNav"
                      data-toggle="collapse"
                      type="button"
                    >
                      <span className="active-tab">
                        {activeTab.charAt(0).toUpperCase() + activeTab.substr(1)}
                      </span>
                      <span className="bff-caret-down" />
                    </div>
                    <div className="collapse navbar-collapse" id="navbarNav">
                      <ul className="navbar-nav">
                        { this.renderMobileTabs() }
                      </ul>
                    </div>
                  </nav>
                </React.Fragment>
              )
            }
            <div className="s-attachment-title">
              <h2 title={asset.attributes.name}>{asset.attributes.name}</h2>
              {attachments.length > 0
                ? (
                  <div className="file-type-pill">
                    <span aria-label="hidden" className="bff-paperclip attachment-count" />
                    <span className="count">{attachments.length}</span>
                  </div>
                ) : ''
              }
            </div>

          </div>
          <div className="tab-content">
            {this.renderTabContent()}
          </div>
        </div>
      </Modal.Body>
    );
  }
}

AssetModalContentWrap.propTypes = {
  asset: PropTypes.shape({
    attributes: shape({
      name: PropTypes.string.isRequired
    }),
    id: PropTypes.string.isRequired,
    type: PropTypes.isRequired
  }).isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  brandfolder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: shape({
      slug: PropTypes.string.isRequired
    })
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  libraryName: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

AssetModalContentWrap.defaultProps = {
  attachments: []
};

export default AssetModalContentWrap;
