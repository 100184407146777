
import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import './base_styles.scss';

interface ContainerProps {
  children: ReactNode;
  className?: string;
  error?: string;
  id?: string;
  submitAttempted?: boolean;
}

export const Container: FunctionComponent<ContainerProps> = (props) => {
  const { children, className, error, id, submitAttempted } = props;

  return (
    <div className={classnames('bf-input-container', { invalid: submitAttempted && error }, className)} id={id}>
      {children}
    </div>
  );
};
