import fetchJSON from '@api/api_helper';

const get = (params, options = {}) => {
  const {
    activeSection,
    isCollection,
    resourceKey,
    resourceType,
    sectionKey,
    showSections,
  } = options;

  const {
    labelKeys,
    onlyKeys,
    search,
  } = params;

  let url = '/api/v4';

  // if sectionless, use resource/:resource_key/assets endpoint
  // UNLESS.. sectionless AND user selects a section pill, need to use sections/:section_key... endpoint
  const performSectionlessFetch = !showSections && activeSection === 'all';

  if (performSectionlessFetch || isCollection) {
    url += `/${resourceType}s/${resourceKey}`;
  }

  if (performSectionlessFetch) {
    // label keys need to be included in the body of a POST to avoid overly long
    // GET urls, since the POST at '.../assets' is a #create action, we use '.../assets/search'
    // here so that the POST hits the #index action of '.../assets'
    url += (!!search || labelKeys) ? '/assets/search' : '/assets';
  } else {
    url += `/sections/${sectionKey}/asset${onlyKeys ? '_keys' : 's'}`;

    if ((!!search || labelKeys) && !onlyKeys) {
      url += '/search';
    }
  }

  // label keys arrays can be lengthy, so they must be included in the body of a POST to avoid
  // making the GET url too long
  const method = (!!search || labelKeys) ? 'POST' : 'GET';

  const fetchController = options.fetchController || (() => {});

  let body = {};
  if (method === 'POST') {
    body = { ...params };

    if (labelKeys) {
      body.label_keys = labelKeys; // camel to snake case
      delete body.labelKeys; // eslint-disable-line no-param-reassign
    }
  }

  return fetchJSON(url, {
    method,
    ...Object.keys(body).length ? { body } : { params },
  }, fetchController).then((response) => {
    if (!response.meta) {
      return {
        assets: response.data,
        included: response.included
      };
    }

    return {
      assets: response.data,
      included: response.included,
      total: response.meta.total_count,
      totalPages: response.meta.total_pages,
      currentPage: response.meta.current_page,
    };
  });
};

export default get;
