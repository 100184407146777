import fetchJSON from '@api/api_helper';
import { UserDataResponse } from './UsersTypes';

const getUrl = (resourceKey: string): string => {
  let resourceString = 'brandfolders';
  if (BFG.resource.type === 'collection') {
    resourceString = 'collections';
  }
  return `/api/v4/${resourceString}/${resourceKey}/users`;
};

interface GetUsersOptions {
  resourceKey: string;
  params?: { [key: string]: string | number | undefined };
  updateFetchControllers?: () => void;
}

export const getUsers = ({
  resourceKey,
  params = {},
  updateFetchControllers = undefined
}: GetUsersOptions): Promise<UserDataResponse> => (
  fetchJSON(getUrl(resourceKey), { params }, updateFetchControllers)
);
