import { plural, t, Plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { BFLoader } from '@components/common/loader/main';
import Tooltip from '@components/common/tooltip/main';
import { ListDropdown } from '@components/library/dropdown';
import Label from '@components/library/labels/PrimaryLabel';
import { isGettyClient } from '@helpers/getty-strings';
import {
  bulkRefreshSections,
  Brandfolder
} from '@helpers/show_page_helpers';

import renderModal from '../modals/renderModal';

import './styles/BulkMoveModal.scss';

class BulkMoveModal extends React.Component {
  state = {
    brandfolderOptions: [],
    defaultAssetType: null,
    destinationBrandfolderKey: Brandfolder.key,
    destinationSectionKey: null,
    disableSubmitButton: true,
    errorState: false,
    isLoading: true,
    sameBrandfolder: true,
    sectionOptions: []
  }

  componentDidMount() {
    this.setDefaultAssetType();
  }

  componentWillUnmount() {
    this.props.closeModal();
  }

  setDefaultAssetType() {
    // this.props.selectedAssetTypes is a Set
    if (this.props.selectedAssetTypes.size > 1) {
      this.setState({ errorState: 'multipleTypes', isLoading: false });
    } else {
      const defaultAssetType = this.props.selectedAssetTypes.values().next().value;

      this.setState({ defaultAssetType }, () => {
        this.getTransferrableBrandfolders();
        this.getTransferrableSections();
      });
    }
  }

  getTransferrableBrandfolders() {
    const defaultAssetType = this.state.defaultAssetType;

    $.ajax({
      type: "POST",
      url: `/api/v4/bulk_actions/assets/transferrable_brandfolders?queue_priority=high`,
      contentType: "application/json",
      data: JSON.stringify({
        organization_key: BF.fx.organization().key,
        default_asset_type: defaultAssetType,
        brandfolder_key: Brandfolder.key,
        per: 1000
      }),
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: (response) => {
        const sortedBrandfolders = response.data.sort((a, b) => {
          const compA = a.attributes.name.toLowerCase();
          const compB = b.attributes.name.toLowerCase();
          return compA.localeCompare(compB);
        });
        this.setState({ brandfolderOptions: sortedBrandfolders });
      },
      error: () => {
        this.setState({ errorState: true });
      },
      complete: () => {
        this.setState({ isLoading: false });
      }
    });
  }

  getTransferrableSections(destinationBrandfolderKey = null) {
    if (this.state.defaultAssetType) {
      $.ajax({
        type: "POST",
        url: `/api/v4/bulk_actions/assets/transferrable_sections?queue_priority=high`,
        contentType: "application/json",
        context: this,
        data: JSON.stringify({
          data: { asset_keys: Array.from(this.props.selectedAssetKeys) },
          brandfolder_key: this.state.destinationBrandfolderKey || destinationBrandfolderKey,
          default_asset_type: this.state.defaultAssetType,
          per: 1000
        }),
        headers: { Authorization: `Bearer ${BF_Token}` },
        success: (response) => {
          if (response.data.length < 1) {
            this.setState({ errorState: 'noSectionOptions' });
          } else {
            this.setState({
              sectionOptions: response.data,
              errorState: false
            });
          }
        },
        error: () => {
          this.setState({ errorState: true });
        },
        complete: () => {
          this.setState({ isLoading: false });
        }
      });
    } else {
      this.setState({ errorState: 'missingDefaultAssetType' });
    }
  }

  selectOptionsFor(resourceType) {
    const resources = (resourceType.toLowerCase() === 'brandfolders')
      ? this.state.brandfolderOptions
      : this.state.sectionOptions;

    return resources.map((resource) => ({ value: resource.id, label: resource.attributes.name }));
  }

  updateSelectedOption(option, stateVariable) {
    const updatedValue = option == null ? null : option.value;

    if (stateVariable === 'destinationBrandfolderKey') {
      if (updatedValue !== null) {
        this.getTransferrableSections(updatedValue);
      }

      this.setState({
        destinationBrandfolderKey: updatedValue,
        destinationSectionKey: null,
        disableSubmitButton: true,
        errorState: false
      });
    } else if (stateVariable === 'destinationSectionKey') {
      this.setState({
        destinationSectionKey: updatedValue,
        disableSubmitButton: option == null,
      });
    }
  }

  buildDataBody() {
    return { data: {
      asset_keys: Array.from(this.props.selectedAssetKeys),
      bulk_move: {
        destination_section_key: this.state.destinationSectionKey
      } } };
  }

  moveAssets() {
    this.setState({ disableSubmitButton: true, isLoading: true });

    const assetCount = this.props.selectedAssetKeys.size;

    $.ajax({
      type: "PUT",
      url: '/api/v4/bulk_actions/assets/move?queue_priority=high',
      contentType: "application/json",
      data: JSON.stringify(this.buildDataBody()),
      context: this,
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: () => {
        bulkRefreshSections(this.props.selectedAssetKeys, null, [this.state.destinationSectionKey]);
        this.props.closeModal();
        Notify.create({
          title: plural(assetCount, {
            one: `${assetCount} asset moved!`,
            other: `${assetCount} assets moved!`
          }),
          type: 'success'
        });
        if (!this.state.sameBrandfolder) {
          BF.fx.updateAssetPageCount(assetCount, false);
        }
        this.props.addRemoveSelected([]);
      },
      error: (jqXHR) => {
        this.props.closeModal();

        if (jqXHR.status === 422) {
          const uncontrolledTitle = isGettyClient()
            ? plural(assetCount, {
              one: `An error occurred when moving ${assetCount} asset. The destination Library needs to have controlled custom fields enabled first.`,
              other: `An error occurred when moving ${assetCount} assets. The destination Library needs to have controlled custom fields enabled first.`
            }) : plural(assetCount, {
              one: `An error occurred when moving ${assetCount} asset. The destination Brandfolder needs to have controlled custom fields enabled first.`,
              other: `An error occurred when moving ${assetCount} assets. The destination Brandfolder needs to have controlled custom fields enabled first.`
            });
          switch (jqXHR.responseText) {
            case 'destination_asset_type_mismatch':
              Notify.create({
                title: plural(assetCount, {
                  one: `An error occurred when moving ${assetCount} asset. The destination section type does not match the type of the selected asset.`,
                  other: `An error occurred when moving ${assetCount} assets. The destination section type does not match the type of the selected assets.`
                }),
                type: 'error'
              });
              return;
            case 'destination_uncontrolled_cf':
              Notify.create({
                title: uncontrolledTitle,
                type: 'error'
              });
              return;
          }
        }

        Notify.create({
          title: plural(assetCount, {
            one: `An error occurred when moving ${assetCount} asset. Please try again.`,
            other: `An error occurred when moving ${assetCount} assets. Please try again.`
          }),
          type: 'error'
        });
      }
    });
  }

  renderSectionSelection = () => {
    const { sameBrandfolder, destinationBrandfolderKey } = this.state;
    let disabledClass = '';

    // enable when Brandfolder has been selected
    if (!sameBrandfolder && destinationBrandfolderKey == null) {
      disabledClass = 'disabled-section';
    }

    if (this.state.errorState) {
      return (
        <div className="modal-row">
          { this.renderErrorState() }
        </div>
      );
    }
    return (
      <div className={`modal-row ${disabledClass}`}>
        <Label attributes={{ htmlFor: "move-section" }}><Trans>Select Section</Trans></Label>
        <Tooltip
          tooltipContent={t`Only sections with compatible asset types are shown below.`}
          tooltipId="select-section-info"
        >
          <span aria-label="select section" className="bff-tool-tip icon"  />
        </Tooltip>
        <ListDropdown
          className="bulk-move-section-selector"
          onChange={(option) => this.updateSelectedOption(option, 'destinationSectionKey')}
          options={this.selectOptionsFor('sections')}
          placeholder={t`Select a section`}
          searchable
          value={this.state.destinationSectionKey}
          virtualizeOptions={false}
        />
      </div>
    );
  }

  renderBrandfolderSelection = () => {
    const { libraryName } = this.props;

    return (
      <div className="modal-row">
        <h4 className="row-title">
          {isGettyClient()
            ? <Trans>Select Library</Trans>
            : <Trans>Select Brandfolder</Trans>
          }
        </h4>
        <ListDropdown
          className="bulk-move-brandfolder-selector"
          onChange={(option) => this.updateSelectedOption(option, 'destinationBrandfolderKey')}
          options={this.selectOptionsFor('brandfolders')}
          placeholder={isGettyClient() ? t`Select a Library` : t`Select a Brandfolder`}
          searchable
          value={this.state.destinationBrandfolderKey}
          virtualizeOptions={false}
        />
      </div>
    );
  }

  changeDestinationTypeSelection(destination) {
    if (destination === 'sameBrandfolder') {
      this.setState({
        sameBrandfolder: true,
        disableSubmitButton: true,
        destinationSectionKey: null,
        destinationBrandfolderKey: Brandfolder.key,
        errorState: false
      }, () => {
        this.getTransferrableSections();
      });
    } else if (destination === 'differentBrandfolder') {
      this.setState({
        sameBrandfolder: false,
        disableSubmitButton: true,
        destinationSectionKey: null,
        destinationBrandfolderKey: null,
        errorState: false,
        sectionOptions: []
      });
    }
  }

  renderDestinationTypeSelection() {
    const { moveBrandfolderEnabled } = this.props;

    if (!this.state.brandfolderOptions.length || !moveBrandfolderEnabled) { return null; }

    return (
      <div className="radio-button-container">
        <label className="radio-row">
          <h4><Trans>To another Section</Trans></h4>
          <input
            checked={this.state.sameBrandfolder}
            className="radio-input"
            name="radio"
            onChange={() => this.changeDestinationTypeSelection('sameBrandfolder')}
            type="radio"
            value="another-section-in-this-brandfolder"
          />
          <span className="radio-dot" />
        </label>
        <label className="radio-row">
          <h4>{isGettyClient() ? t`To another Library` : t`To another Brandfolder`}</h4>
          <input
            checked={!this.state.sameBrandfolder}
            className="radio-input"
            name="radio"
            onChange={() => this.changeDestinationTypeSelection('differentBrandfolder')}
            type="radio"
            value="another-brandfolder"
          />
          <span className="radio-dot" />
        </label>
      </div>
    );
  }

  renderMoveContent() {
    if (this.state.sameBrandfolder) {
      return (
        <div className="modal-row">
          {this.renderSectionSelection()}
        </div>
      );
    }

    return (
      <div className="modal-row">
        {this.renderBrandfolderSelection()}
        {this.renderSectionSelection()}
      </div>
    );
  }

  renderErrorState() {
    const { errorState } = this.state;
    const assetCount = this.props.selectedAssetKeys.size;

    if (!errorState) {
      return null;
    }

    if (errorState === 'multipleTypes') {
      return (
        <React.Fragment>
          <p className="move-error-copy"><Trans>Unable to move assets.</Trans></p>
          <p className="move-error-copy">
            <Plural
              one="Selected asset must be from the same type of section in order to move it."
              other="Selected assets must be from the same type of section in order to move them."
              value={assetCount}
            />
          </p>
        </React.Fragment>
      );
    }

    if (errorState === "noSectionOptions") {
      return (
        <React.Fragment>
          <p className="move-error-copy">
            {isGettyClient()
              ? (
                <Plural
                  one="Unable to move this asset within this Library."
                  other="Unable to move these assets within this Library."
                  value={assetCount}
                />
              ) : (
                <Plural
                  one="Unable to move this asset within this Brandfolder."
                  other="Unable to move these assets within this Brandfolder."
                  value={assetCount}
                />
              )
            }
          </p>
          <p className="move-error-copy">
            <Plural
              one="There are no eligible sections to receive this asset."
              other="There are no eligible sections to receive these assets."
              value={assetCount}
            />
          </p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <p className="move-error-copy"><Trans>An error occured.</Trans></p>
        <p className="move-error-copy">
          <Trans>
            Please try again and contact support if the issue persists.
          </Trans>
        </p>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading } = this.state;
    const loaderActive = isLoading ? 'loader-active' : '';
    const assetCount = this.props.selectedAssetKeys.size;

    return (
      <div className="modal-content-wrapper bulk-move-modal">
        <div className="modal-content-wrapper__header">
          <span aria-hidden="true" className="bff-move icon"/>
          <h3 className="modal-title">
            {/* TODO: don't rely on bold-text class for spacing */}
            <Plural
              one={
                <Trans>
                  Move
                  <span className="bold-text">
                    {assetCount} asset
                  </span>
                </Trans>
              }
              other={
                <Trans>
                  Move
                  <span className="bold-text">
                    {assetCount} assets
                  </span>
                </Trans>
              }
              value={assetCount}
            />
          </h3>
          <button
            className="close-button"
            onClick={this.props.closeModal}
            type="button"
          >
            <span className="bff-close" />
          </button>
        </div>
        <div className={`modal-content-wrapper__body ${loaderActive}`}>
          {isLoading
            ? <BFLoader />
            : (
              <div>
                <div className="modal-row">
                  { this.renderDestinationTypeSelection() }
                </div>
                { this.renderMoveContent() }
                <div className="button-container">
                  <button
                    className="button sm primary t-confirm-modal"
                    disabled={this.state.disableSubmitButton}
                    onClick={() => this.moveAssets()}
                    type="button"
                  >
                    <Trans>Move</Trans>
                  </button>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

BulkMoveModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  libraryName: PropTypes.string,
  moveBrandfolderEnabled: PropTypes.bool,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
  selectedAssetTypes: PropTypes.shape({}).isRequired,
};

BulkMoveModal.defaultProps = {
  libraryName: "Brandfolder",
  moveBrandfolderEnabled: true
};

const ModalComponent = renderModal(BulkMoveModal, 'BulkMoveModal');
export default ModalComponent;
