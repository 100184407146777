/* eslint-disable @typescript-eslint/naming-convention */

export enum Rating {
  Positive = 'positive',
  Negative = 'negative',
}

export interface UpdateCaptionRequestParams {
  bf_key: string;
  caption_id: string;
  org_key: string;
  is_applied?: string;
  rating?: Rating;
}

export interface SelectedAsset {
  extension: string;
  key: string;
}

export interface Caption {
  caption: string;
  captionId: string;
}

export interface GenerateCaptionFetchState {
  generateError: boolean;
  isGenerating: boolean;
  response: Caption;
}
