import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTr, BaseTrProps } from '../../base';

import classes from '../styles/selectable-table.module.scss';

export interface SelectableTrProps extends BaseTrProps {
  hover?: boolean;
}

export const Tr: FunctionComponent<SelectableTrProps> = (props) => {
  const { children, hover, ...otherProps } = props;
  return (
    <BaseTr
      {...otherProps}
      className={classnames('selectabeTr', classes.tr, hover && classes.trHover, props.className)}
    >
      {children}
    </BaseTr>
  );
};
