import { FontIcons, StandardButton, StandardDialog } from '@brandfolder/react';
import { Trans, t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import './styles/_download_alert_dialog.scss';

interface DownloadAlertDialogProps {
  downloadAlert: string;
  downloadCallback: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const DownloadAlertDialog: FunctionComponent<DownloadAlertDialogProps> = ({
  downloadAlert,
  downloadCallback,
  open,
  setOpen
}) => (
  <StandardDialog
    dialogProps={{
      children: undefined,
      className: 'download-alert-dialog'
    }}
    footer={(
      <StandardButton
        className="agree-and-download-button"
        onClick={(): void => {
          downloadCallback();
          setOpen(false);
        }}
      >
        <Trans>Agree and download</Trans>
      </StandardButton>
    )}
    id="download-alert-dialog"
    labels={{
      closeButtonLabel: t`Cancel`,
    }}
    open={open}
    setOpen={setOpen}
    title={t`Download Agreement`}
    titleIcon={FontIcons.Download}
  >
    <p className="download-alert-content bf-scroll-element">
      {downloadAlert}
    </p>
  </StandardDialog>
);
