import fetchJSON from '@api/api_helper';

interface SuggestedTagsResponse {
  data: {
    name: string;
  }[];
}

const getSuggestedTags = (
  assetKey: string,
  options = { params: {}, updateFetchControllers: undefined }
): Promise<SuggestedTagsResponse> => {
  const { params, updateFetchControllers } = options;
  return fetchJSON(`/api/v4/private/assets/${assetKey}/suggested_tags`, { params }, updateFetchControllers);
};

export default getSuggestedTags;
