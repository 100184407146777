import { t } from '@lingui/macro';
import React, { FunctionComponent, useContext } from 'react';

import { PersonData } from '@api/v4/assets/assetTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import { ActionTypes, AssetDetailsDispatch } from '@components/asset/modal/tabs/edit/EditTabTypes';
import AssetNameInput from '@components/asset/modal/tabs/edit/side_bar/assetNameInput';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';

import AttachmentListContainer from './attachmentListContainer';

interface PeopleSideBarPropsScopedContext {
  state: {
    editState: {
      personData: PersonData;
    };
  };
  dispatch: AssetDetailsDispatch;
}

const emptyPersonData: PersonData = {
  title: '',
  email: '',
  phone_number: '',
  linkedin: '',
  twitter: ''
};

const PeopleSideBar: FunctionComponent = () => {
  const { state, dispatch }: PeopleSideBarPropsScopedContext = useContext(assetModalContext);
  const personData = state.editState.personData || emptyPersonData;

  const handlePersonUpdate = (partial: Partial<PersonData>): void => {
    dispatch({
      type: ActionTypes.UpdateAsset,
      payload: { personData: { ...personData, ...partial } }
    });
  };

  return (
    <div className="people-sidebar edit-tab-sidebar one-third bf-scroll-element">
      <AssetNameInput assetNameCopy={t`Name`} />
      <InputContainer
        attributes={{
          name: 'title',
          onChange: (e: InputChangeEvent): void => handlePersonUpdate({ title: e.target.value }),
          type: InputType.Text,
          value: personData.title,
        }}
        input={{ value: personData.title }}
        labelCopy="Title"
      />
      <InputContainer
        attributes={{
          name: 'email',
          onChange: (e: InputChangeEvent): void => handlePersonUpdate({ email: e.target.value }),
          type: InputType.Text,
          value: personData.email,
        }}
        input={{ value: personData.email }}
        labelCopy="Email Address"
      />
      <InputContainer
        attributes={{
          name: 'phone',
          onChange: (e: InputChangeEvent): void => handlePersonUpdate({ phone_number: e.target.value }),
          type: InputType.Text,
          value: personData.phone_number,
        }}
        input={{ value: personData.phone_number }}
        labelCopy="Phone"
      />
      <InputContainer
        attributes={{
          name: 'linkedin',
          onChange: (e: InputChangeEvent): void => handlePersonUpdate({ linkedin: e.target.value }),
          type: InputType.Text,
          value: personData.linkedin,
        }}
        input={{ value: personData.linkedin }}
        labelCopy="LinkedIn URL"
      />
      <div className="twitter-input">
        <InputContainer
          attributes={{
            name: 'twitter',
            onChange: (e: InputChangeEvent): void => handlePersonUpdate({ twitter: e.target.value }),
            type: InputType.Text,
            value: personData.twitter,
          }}
          input={{ value: personData.twitter }}
          labelCopy="Twitter"
        />
        <span className="twitter-handle">@</span>
      </div>
      <AttachmentListContainer />
    </div>
  );
};

export default PeopleSideBar;
