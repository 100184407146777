import React, { FunctionComponent } from "react";

interface ListDropdownChildNodeProps {
  searchResult: string;
  searchInput: string;
}

export const ListDropdownChildNode: FunctionComponent<ListDropdownChildNodeProps> = ({
  searchResult,
  searchInput
}) => {
  const boldTextIndex = searchResult.toLowerCase().indexOf(searchInput.toLowerCase());

  const fallbackResults = (): JSX.Element => {
    return (
      <span>{searchResult}</span>
    );
  }

  const leadingMatch = (): JSX.Element => {

    return (
      <>
        <span>{searchResult.substring(0, searchInput.length)}</span>
        <b>{searchResult.substring(searchInput.length)}</b>
      </>
    );
  };

  const substringMatch = (): JSX.Element => {
    const nonBoldText = searchResult.substring(boldTextIndex, boldTextIndex + searchInput.length);

    return (
    <>
      {
        boldTextIndex === searchResult.length - 1 ? (
          <>
            <span>{searchResult.substring(0, boldTextIndex)}</span>
            <b>{searchResult[searchResult.length - 1]}</b>
          </>
        ) :
        (
          <>
            <b>{searchResult.substring(0, boldTextIndex)}</b>
            <span>{nonBoldText}</span>
            <b>{searchResult.substring(boldTextIndex + searchInput.length)}</b>
          </>
        )
      }
    </>

    );
  };

  const renderSearchResults = (): JSX.Element => {
    if (searchInput.length > 0 && boldTextIndex === 0) {
      return leadingMatch();
    }
    else if (searchInput.length > 0 && boldTextIndex > 0) {
      return substringMatch();
    }
    else {
      return fallbackResults();
    }
  };

  return (
    <>
      { renderSearchResults() }
    </>
  );
}
