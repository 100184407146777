import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseEmptyLabelProps } from '@components/library/empty-label';

const baseName = 'empty-label';

export const BaseEmptyLabel: FunctionComponent<BaseEmptyLabelProps> = ({
  children,
  className,
  variantName,
  ...EmptyLabelProps
}) => (
  <span
    {...EmptyLabelProps}
    className={classnames(
      `bf-${baseName}__${variantName}`,
      className
    )}
  >
    {children}
  </span>
);
