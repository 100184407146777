import { ApiDataResponse, ApiDatum } from '@api/v4/ApiResponseTypes';
import { User, UserRoles } from '@api/v4/UserTypes';
export { UserRoles } from '@api/v4/UserTypes';

/* eslint-disable @typescript-eslint/naming-convention */
export interface IncludedUserPermission {
  key: string;
  permission_level: UserRoles;
  insights_url: string;
}

export interface DetailedUser extends User {
  company?: string;
  country?: string;
  created_with_saml?: boolean;
  created_with_sso?: boolean;
  department?: string;
  full_name?: string;
  last_active_at?: string;
  locale?: string;
  resource_user_permission?: ApiDatum<IncludedUserPermission, 'user_permission'>;
  title?: string;
  user_group_member_key?: string;
}

export type IndexDetailedUsersResponse = ApiDataResponse<DetailedUser, 'users'> & { user_count: number };
