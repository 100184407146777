/**
 * NOTE: If "dashbarUpdated" is updated, you'll also have to manually touch
 * app/assets/javascripts/brandfolder/invitation.js and
 * app/assets/javascripts/brandfolder/user_permission.js
 */
export enum WorkspaceEvents {
  DashbarUpdated = 'dashbarUpdated',
  WorkspaceUpdated = 'workspaceUpdated',
  WorkspaceConnectionReset = 'workspaceConnectionReset'
}

export enum WorksheetAccessLevels {
  Owner = 'OWNER',
  Admin = 'ADMIN'
}
