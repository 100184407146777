import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';

import { PrimaryButton } from '@components/library/button/index';
import { ListDropdown } from '@components/library/dropdown';
import PrimaryInput from '@components/library/inputs/PrimaryInput';
import renderModal from '@components/show_page/modals/renderModal';
import { isGettyClient } from '@helpers/getty-strings';

import './styles/_new_brandfolder_modal_styles.scss';

interface NewBrandfolderModalProps {
  closeModal: () => void;
  libraryName: string;
  name: string;
  slug: string;
}

const defaultTemplate = 'default';

const NewBrandfolderModal: FunctionComponent<NewBrandfolderModalProps> = ({
  closeModal,
  name,
  slug,
}) => {
  // whenever you update these fields, make sure it matches the INITIAL_CONFIG_DEFAULTS hash
  // in app/models/brandfolder.rb
  const templates = [
    { value: 'sales', label: t`Sales` },
    { value: 'asset_library', label: t`Asset Library` },
    { value: 'marketing', label: t`Marketing` },
    { value: 'presskit', label: t`Press Kit` },
    { value: 'archive', label: t`Archive` },
    { value: 'default', label: t`Default` }
  ];

  const [newBrandfolderName, setShowNewBrandfolderName] = useState(name);
  const [selectedTemplate, setSelectedTemplate] = useState(defaultTemplate);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitComplete, setSubmitComplete] = useState(false);

  const handleSubmit = (): void => {
    const data = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      brandfolder_name: newBrandfolderName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      template_type: selectedTemplate
    };
    setIsSubmitting(true);
    $.ajax({
      url: `/organizations/${slug}/brandfolders`,
      data: JSON.stringify(data),
      type: 'POST',
      contentType: 'application/json',
      headers: {
        'Authorization': `Bearer ${BF_Token}`,
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success() {
        setIsSubmitting(false);
        setSubmitComplete(true);
        window.location.reload();
      },
      error() {
        setIsSubmitting(false);
        setSubmitComplete(false);
        Notify.create({
          title: t`Something went wrong. Try again soon!`,
          type: 'error'
        });
      },
      complete() {
        Notify.create({
          title: t`Brandfolder Created, reloading...`,
          type: 'success'
        });
      }
    });
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const displayTemplateOptions = BFG.currentUser.su && !BFG.currentUser.scope;

  useEffect(() => (): void => {
    closeModal();
  }, [closeModal]);

  return (
    <div className="modal-content-wrapper">
      <div className="modal-content-wrapper__header">
        <h3>
          {isGettyClient() ? <Trans>Create new Library</Trans> : <Trans>Create new Brandfolder</Trans>}
        </h3>
        <button
          className="close-button"
          onClick={closeModal}
          type="button"
        >
          <span className="bff-close" />
        </button>
      </div>
      <div className="modal-content-wrapper__body">
        <PrimaryInput
          attributes={{
            name: 'new-brandfolder-name-input',
            onChange: (e: InputChangeEvent): void => setShowNewBrandfolderName(e.target.value),
            onKeyUp: handleKeyUp
          }}
          input={{
            value: newBrandfolderName
          }}
        />
        {displayTemplateOptions && (
          <ListDropdown
            onChange={(option): void => setSelectedTemplate(option.value?.toString())}
            options={templates}
            searchable={false}
            value={selectedTemplate}
            virtualizeOptions={false}
          />
        )}
        <div className="button-container">
          <PrimaryButton
            disabled={submitComplete}
            isLoading={isSubmitting}
            loadingCopy={t`Creating`}
            onClick={handleSubmit}
            size="medium"
          >
            <Trans>Create</Trans>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(NewBrandfolderModal, 'new-brandfolder-modal');
export default ModalComponent;
