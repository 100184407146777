import React, { FunctionComponent } from 'react';

import BaseRadioButton from '../BaseRadioButton';
import styleClassNames from './styles.module.scss';

interface StandardRadioButtonProps {
  id: string;
  name: string;
  checked?: boolean;
  containerId?: string;
  disabled?: boolean;
  onChange?: (e: InputChangeEvent) => void;
  value?: string | string[] | number;
}

const StandardRadioButton: FunctionComponent<StandardRadioButtonProps> = (
  props
) => {
  const baseProps = {
    ...props,
    styleClassNames,
    variantName: 'standard',
    value: props.value || props.id,
  };

  return <BaseRadioButton {...baseProps} />;
};

export default StandardRadioButton;
