import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useState } from 'react';

import { StandardRadioButtonGroup } from '@components/library/radio_button_group';

import classes from './watermark_positions.module.scss';
import { defaultWatermarkPostion, watermarkPositions, WatermarkPositionsEnum } from './WatermarkPositionTypes';

interface WatermarkPositionsProps {
  onChange: (gravity: WatermarkPositionsEnum) => void;
  gravity?: WatermarkPositionsEnum;
  showHeading?: boolean;
}

export const WatermarkPositions: FunctionComponent<WatermarkPositionsProps> = (props) => {
  const { gravity, showHeading = true, onChange } = props;

  const [watermarkPosition, setWatermarkPosition] = useState(gravity || defaultWatermarkPostion);

  return (
    <div
      className={classnames(
        'watermark-positions',
        classes.watermarkPositions
      )}
    >
      {showHeading && (
        <h4 className="watermark-positions__heading">
          <Trans>Watermark Position</Trans>
        </h4>
      )}
      <StandardRadioButtonGroup
        checkedId={watermarkPosition}
        className={classnames(
          'watermark-positions__options',
          classes.radioButtonGroup
        )}
        name="watermark-positions"
        onChange={(e: InputChangeEvent): void => {
          setWatermarkPosition(e.target.value as WatermarkPositionsEnum);
          onChange(e.target.value as WatermarkPositionsEnum);
        }}
        radioButtons={watermarkPositions()}
      />
    </div>
  );
};
