import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { ListDropdown } from '@components/library/dropdown';

const Printers = ({ printers, selectedPrinter, updatePrinter }) => {
  if (printers.length === 0) {
    return (
      <h5 className="not-found-text">
        <Trans>
          No printers available, contact
          <a className="support-link" href={`mailto:${BFG.supportEmail}`}>support</a>
          to set up at least one printer
        </Trans>
      </h5>
    );
  }

  if (printers.length === 1) {
    const emails = printers[0].value.split(',');
    const emailsHTML = emails.map((email, i) => (
      <React.Fragment key={email}>
        <a className="email-link" href={`mailto:${email}`}>{email}</a>
        {i !== emails.length - 1 ? ',' : null}
      </React.Fragment>
    ));

    return (
      <React.Fragment>
        <Trans>
          <h5>Print request will be sent to:</h5>
          <div className="printer-selection">
            <h5>{selectedPrinter.label} ({emailsHTML})</h5>
          </div>
        </Trans>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h5><Trans>Print request will be sent to:</Trans></h5>
      <div className="printer-selection">
        <ListDropdown
          className="printer-dropdown"
          onChange={updatePrinter}
          options={printers}
          value={selectedPrinter}
          virtualizeOptions={false}
        />
      </div>
    </React.Fragment>
  );
};

Printers.propTypes = {
  printers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    key: PropTypes.string,
  })).isRequired,
  selectedPrinter: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
  updatePrinter: PropTypes.func.isRequired,
};

export default Printers;
