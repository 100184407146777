import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { TextButton } from '@components/library/button';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';

import { WorkspaceCreateForm } from './WorkspaceCreateForm';

interface WorkspaceCreateButtonProps {
  atOverWorkspaceLimit: boolean;
  brandfolderSlug: string;
}

export const WorkspaceCreateButton: FunctionComponent<WorkspaceCreateButtonProps> = ({ atOverWorkspaceLimit, brandfolderSlug }) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const triggerMailTo = (): void => {
    const element = document.createElement('a');
    element.setAttribute('target', '_blank');
    element.setAttribute('href', `mailto:${BFG.supportEmail}?subject=Upgrade Inquiry&body=I'd like to inquire about adding more Workspaces to ${BFG.org_name}.`);
    element.style.display = 'none';

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleClick = (): void => {
    if (atOverWorkspaceLimit) {
      triggerMailTo();
    } else {
      sendAction(TrackedAction.WorkspaceCreateOpen);
      setIsOpenDialog((prevState) => !prevState);
    }
  };

  return (
    <I18nProviderWrapper>
      <TextButton
        icon={atOverWorkspaceLimit ? undefined : 'bff-plus'}
        id="workspace-create-button"
        onClick={handleClick}
      >
        {atOverWorkspaceLimit ? <Trans>Upgrade for Workspaces</Trans> : <Trans>New Workspace</Trans>}
      </TextButton>
      <WorkspaceCreateForm
        brandfolderSlug={brandfolderSlug}
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
      />
    </I18nProviderWrapper>
  );
};
