import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { formattedUppyAttachment } from '@helpers/uppy';

class AssetWatermarkUppy extends React.Component {
  handleCustomizationFiles = (files) => {
    if (!files.length) { return; }

    const file = formattedUppyAttachment(files[0]);
    const $parent = $(`.j-upload-settings-well.${this.uploadType()}`);
    $('.m-asset-advanced-container').find('.j-upload-settings-well').data(`has-${this.uploadType}`, true);
    $parent.find('.filepicker_object').val(JSON.stringify({ url: file.url, filename: file.filename }));
    $parent.find('.filepicker-complete .filename').text(file.filename);
    $parent.find('.filepicker-complete .filename').attr('href', file.url);
    $parent.find('.filepicker-complete-pane').addClass('complete');
    $parent.find('#watermark-upload').hide();
    Notify.create({
      title: t`Processing watermark.`,
      body: t`Click 'Update Asset' to begin processing watermark.`,
      type: "success"
    });
  }

  uploadType = () => this.props.uploadType.toLowerCase();

  trigger = () => `uppy-watermark-trigger-${this.uploadType()}`;

  renderArea = () => (
    <div className="uppy-asset-watermark" id={this.trigger()}>
      <p><Trans>Click to upload</Trans></p>
    </div>
  );

  render() {
    return (
      <React.Fragment>
        {this.renderArea()}
        <UppyUploader
          {...this.props}
          button
          handleUpload={this.handleCustomizationFiles}
          restrictions={{ maxNumberOfFiles: 1, allowedFileTypes: ['image/*'] }}
          template={this.uploadType()}
          trigger={`#${this.trigger()}`}
          uniqIdentifier={`uppy-watermark-${this.uploadType()}`}
        />
      </React.Fragment>
    );
  }
}

AssetWatermarkUppy.propTypes = {
  uploadType: PropTypes.string.isRequired
};

export default AssetWatermarkUppy;
