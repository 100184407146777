import React from 'react';
import PropTypes from 'prop-types';

import copyToClipboard from '@helpers/copy_to_clipboard';
import './styles/text.scss';

class Text extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copying: false,
    };
  }

  copy() {
    copyToClipboard(this.props.text);
    this.setState({ copying: true }, () => {
      setTimeout(() => {
        this.setState({ copying: false });
      }, 2000);
    });
  }

  render() {
    const disabled = !this.props.text;
    const rows = this.props.text ? this.props.text.split('\n').length : 5;
    const text = this.props.text || '';

    return (
      <div className="workbench-text">
        <label
          htmlFor="extracted_text"
          className={disabled ? 'disabled' : ''}
        >
          Extracted text:
        </label>
        <textarea
          id="extracted_text"
          disabled={disabled}
          readOnly
          value={text}
          rows={rows}
        />
        <button
          onClick={() => this.copy()}
          disabled={disabled}
          className="button dark-blue large"
          type="button"
        >
          {this.state.copying ? 'Copied to clipboard!' : 'Copy text'}
        </button>
        {this.props.renderResetButton()}
      </div>
    );
  }
}

Text.propTypes = {
  asset: PropTypes.shape({}),
  renderResetButton: PropTypes.func,
  text: PropTypes.string
};

Text.defaultProps = {
  asset: null,
  renderResetButton: () => {},
  text: null
};

export default Text;
