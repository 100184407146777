import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { EditorRef } from 'react-email-editor';

import { ApiErrorResponse, ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { createPressRelease, getPressRelease, updatePressRelease } from '@api/v4/private/resources/press_releases';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { PrimaryButton } from '@components/library/button';
import { defaultPressRelease } from '@components/press_releases/data';
import { getIsEmailEmpty } from '@components/press_releases/helpers';
import { unsavedChangesOptions } from '@helpers/sweet_alert_options';

import { PressReleaseForm } from './PressReleaseForm';
import { PressReleasesList } from './PressReleasesList';
import {
  IdentifiedPressRelease,
  PressReleaseFormSteps,
  PressReleaseSendOptions,
  PressReleaseUpsert,
  PressReleaseValidationErrors
} from './PressReleaseTypes';

import classes from './styles/press_releases.module.scss';
import { isGettyClient } from '@helpers/getty-strings';

const defaultValidationErrors: PressReleaseValidationErrors = {
  content: '',
  recipients: '',
  subject: '',
  submitAttempted: false,
  valid: false
};

export const PressReleases: FunctionComponent = () => {
  const emailEditorRef = useRef<EditorRef | null>(null);

  const [drafting, setDrafting] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [emailEditorLoaded, setEmailEditorLoaded] = useState(false);
  const [initialPressRelease, setInitialPressRelease] = useState<PressReleaseUpsert | null>(null);
  const [nextStep, setNextStep] = useState<PressReleaseFormSteps | null>(null);
  const [open, setOpen] = useState(false);
  const [pressRelease, setPressRelease] = useState<PressReleaseUpsert | null>(null);
  const [reset, setReset] = useState(false);
  const [saving, setSaving] = useState(false);
  const [sending, setSending] = useState(false);
  const [step, setStep] = useState<PressReleaseFormSteps>(1);
  const [testing, setTesting] = useState(false);
  const [update, setUpdate] = useState<ApiResponseObject<IdentifiedPressRelease, 'press_release'> | null>(null);
  const [validationErrors, setValidationErrors] = useState<PressReleaseValidationErrors>(defaultValidationErrors);

  const closePressRelease = (): void => {
    setValidationErrors(defaultValidationErrors);
    setOpen(false);
    setInitialPressRelease(null);
    setPressRelease(null);
    setNextStep(null);
    setStep(1);
    setEmailEditorLoaded(false);
    setDuplicating(false);

    emailEditorRef.current = null;
  };

  const handleClose = (): void => {
    // trimming the content_html and not comparing content_json is intentional here
    // react-email-editor may rearrange the content_json for no reason (blargh), causing a false positive change
    const initialPressReleaseString = JSON.stringify({
      ...initialPressRelease,
      content_html: initialPressRelease?.content_html?.trim(),
      content_json: ''
    } as PressReleaseUpsert);

    const pressReleaseString = JSON.stringify({
      ...pressRelease,
      content_html: pressRelease?.content_html?.trim(),
      content_json: ''
    } as PressReleaseUpsert);

    // when the user goes from step 1 to step 2 when creating but doesn't touch/create an email
    // react-email-editor will still output content_html and content_json
    // so we need to check content_html is not an empty email (it's an actual email) and that initial !== changed
    if (!getIsEmailEmpty(pressRelease) && initialPressReleaseString !== pressReleaseString) {
      window.swal(unsavedChangesOptions({}), (confirm) => {
        if (confirm) {
          closePressRelease();
        }
      });
    } else {
      closePressRelease();
    }
  };

  const fetchDetailedPressRelease = async (release?: IdentifiedPressRelease): Promise<PressReleaseUpsert> => {
    if (!release || !release.id) {
      return defaultPressRelease;
    }

    const pressReleaseResponse = await getPressRelease(release.id);
    return {
      ...pressReleaseResponse.data.attributes,
      id: pressReleaseResponse.data.id
    };
  };

  const handleOpen = async (release?: IdentifiedPressRelease, duplicate?: boolean): Promise<void> => {
    try {
      const detailedPressRelease = await fetchDetailedPressRelease(release);
      // if duplicating, we set the initial to the default so that the user is prompted if closing the dialog
      setInitialPressRelease(duplicate ? defaultPressRelease : detailedPressRelease);
      // if duplicating, we're intentionally not populating brandfolder_user_sources or collection_user_sources here
      // this forces the user to reselect their brandfolder, collection(s), and subcollection(s)
      // we do it this way in case any brandfolders, collections, and subcollections have been deleted
      // or if the user doesn't have them in their permissible resources (i.e. their permissions changed)
      setPressRelease(
        duplicate
          ? {
            ...detailedPressRelease,
            brandfolder_user_sources: [],
            collection_user_sources: [],
            id: ''
          }
          : detailedPressRelease
      );
      // if we're opening to edit an email, set valid to true
      // otherwise we're making a new email or duplicating an email, so we are not valid
      setValidationErrors((!release || duplicate) ? defaultValidationErrors : { ...defaultValidationErrors, valid: true });

      setOpen(true);

      if (duplicate) {
        setDuplicating(true);
      }
    } catch (err) {
      Notify.create({
        title: duplicate ? t`Error duplicating email. Please try again.` : t`Error opening email. Please try again.`,
        type: 'error'
      });
    }
  };

  const checkValidation = (redirectToError = true): boolean => {
    let errors = defaultValidationErrors;
    let invalidStep: PressReleaseFormSteps | null = null;
    let valid = true;

    if (pressRelease) {
      // step 1: subject
      if (!pressRelease.subject) {
        if (redirectToError) {
          errors = {
            ...errors,
            subject: t`Subject is required`,
            submitAttempted: true
          };
        }

        invalidStep = redirectToError ? 1 : null;
        valid = false;
      }

      // step 1: content
      if (getIsEmailEmpty(pressRelease)) {
        if (redirectToError) {
          errors = {
            ...errors,
            content: t`Email content is required`,
            submitAttempted: true
          };
        }

        invalidStep = redirectToError ? 1 : null;
        valid = false;
      }

      // step 2: recipients
      const hasPermissionLevels = pressRelease.permission_levels.length > 0;
      const hasRecipients = (pressRelease.brandfolder_user_sources && pressRelease.brandfolder_user_sources.length > 0) || (pressRelease.collection_user_sources && pressRelease.collection_user_sources.length > 0);
      if (!hasPermissionLevels || !hasRecipients) {
        if (redirectToError) {
          errors = {
            ...errors,
            recipients: isGettyClient()
              ? t`One or more permission levels and one or more Libraries, Collections, or Subcollections are required`
              : t`One or more permission levels and one or more Brandfolders, Collections, or Subcollections are required`,
            submitAttempted: true
          };
        }

        invalidStep = redirectToError ? 2 : null;
        valid = false;
      }
    }

    setDrafting(false);
    setSaving(false);
    setSending(false);
    setTesting(false);
    setValidationErrors({ ...errors, valid });

    if (redirectToError && invalidStep !== null) {
      setNextStep(invalidStep);
    }

    return valid;
  };

  const handleSavePressRelease = async (): Promise<void> => {
    if (!checkValidation() || !pressRelease) return;

    try {
      setSaving(true);

      let savedPressRelease: ApiResponseObject<IdentifiedPressRelease, 'press_release'> | null = null;
      let sendOptions: PressReleaseSendOptions = {};
      if (sending) {
        sendOptions = { send: true };
      } else if (testing) {
        sendOptions = { send_test: true };
      }

      if (pressRelease.id) {
        const updatedPressReleaseResponse = await updatePressRelease(pressRelease.id, pressRelease, sendOptions);
        savedPressRelease = updatedPressReleaseResponse.data;
      } else {
        const createdPressReleaseResponse = await createPressRelease({
          resourceKey: BFG.resource.key,
          resourceType: BFG.resource.type as 'brandfolder' | 'collection'
        }, { ...pressRelease, request_send_at: null, sent_at: null }, sendOptions);
        savedPressRelease = createdPressReleaseResponse.data;
      }

      setInitialPressRelease({
        ...pressRelease,
        ...savedPressRelease.attributes,
        id: savedPressRelease.id
      });
      setPressRelease({
        ...pressRelease,
        ...savedPressRelease.attributes,
        id: savedPressRelease.id
      });
      setValidationErrors(defaultValidationErrors);

      // add or update the press release in the list
      setUpdate(savedPressRelease);

      // when saving as draft on step 3, we close the email dialog
      if (drafting) {
        closePressRelease();

        Notify.create({
          title: pressRelease.id ? t`Email draft saved!` : t`Email draft created!`,
          type: 'success'
        });
      } else if (sending) {
        // when sending on step 3, show step 4
        setNextStep(4);
      } else if (testing) {
        // when testing on step 3, show a toast and keep the dialog open
        Notify.create({
          title: pressRelease.id ? t`Test email sent successfully!` : t`Email created and test sent successfully!`,
          type: 'success'
        });
      } else {
        // we're saving on step 2 going to step 3
        setNextStep(3);
      }
    } catch (error) {
      const err = error as ApiErrorResponse;
      // if the recipient count is over 999, we'll get a 422 status code
      if (err?.errors?.length > 0) {
        Notify.create({
          title: err.errors[0].detail,
          type: 'error'
        });

        // kick them back to change their recipients
        setNextStep(2);
      } else {
        // these are separated for ease of translating (do not make dynamic via ${type})
        let title = pressRelease.id ? t`Error updating your email. Please try again.` : t`Error creating your email. Please try again.`;
        if (sending) {
          title = pressRelease.id ? t`Error sending your email. Please try again.` : t`Error creating and sending your email. Please try again.`;
        } if (testing) {
          title = pressRelease.id ? t`Error testing your email. Please try again.` : t`Error creating and testing your email. Please try again.`;
        }

        Notify.create({
          title,
          type: 'error'
        });
      }
    } finally {
      setDrafting(false);
      setReset(false);
      setSaving(false);
      setSending(false);
      setTesting(false);
    }
  };

  const setEmailEditorDataToState = (callback?: () => void): void => {
    emailEditorRef?.current?.editor.exportHtml((data) => {
      const { design, html } = data;
      setPressRelease({ ...pressRelease, content_json: JSON.stringify(design), content_html: html });
      if (callback) callback();
    });
  };

  const onEmailEditorBlur = (): void => {
    if (emailEditorRef?.current?.editor && pressRelease) {
      setEmailEditorDataToState();
    }
  };

  // we are simply getting the JSON and HTML out of the editor and setting them in state
  const handleStep1 = (): void => {
    if (emailEditorRef?.current?.editor && pressRelease) {
      setEmailEditorDataToState(() => setNextStep(2));
    } else {
      Notify.create({
        title: t`Error loading email editor. Please try again.`,
        type: 'error'
      });
    }
  };

  // a newly created email needs to be saved before proceeding to step 3
  // an email being edited will save as the user goes to step 3
  const handleStep2 = (): void => {
    handleSavePressRelease();
  };

  const handleStep3 = (action: 'drafting' | 'sending' | 'testing'): void => {
    if (action === 'drafting') {
      setDrafting(true);
    } else if (action === 'sending') {
      setSending(true);
    } else if (action === 'testing') {
      setTesting(true);
    }
  };

  useEffect(() => {
    if (nextStep !== null) {
      setStep(nextStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep]);

  useEffect(() => {
    if (drafting || sending || testing) {
      handleSavePressRelease();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drafting, sending, testing]);

  useEffect(() => {
    if (pressRelease) {
      checkValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressRelease]);

  return (
    <I18nProviderWrapper>
      <div className={classes.page}>
        <header className={classes.header} id="header">
          <h1 className={classes.h1} id="heading"><Trans>Email Users</Trans></h1>
          <PrimaryButton
            className={classes.create}
            icon="bff-press-release"
            iconSize={18}
            id="create-email"
            onClick={(): void => {
              handleOpen();
            }}
            size="medium"
          >
            <Trans>Create Email</Trans>
          </PrimaryButton>
        </header>
        <main>
          <PressReleasesList
            handleOpen={handleOpen}
            reset={reset}
            setStep={setStep}
            setUpdate={setUpdate}
            update={update}
          />
          {pressRelease && (
            <PressReleaseForm
              drafting={drafting}
              duplicating={duplicating}
              emailEditorLoaded={emailEditorLoaded}
              emailEditorRef={emailEditorRef}
              handleClose={handleClose}
              handleStep1={handleStep1}
              handleStep2={handleStep2}
              handleStep3={handleStep3}
              onEmailEditorBlur={onEmailEditorBlur}
              open={open}
              pressRelease={pressRelease}
              saving={saving}
              sending={sending}
              setEmailEditorLoaded={setEmailEditorLoaded}
              setNextStep={setNextStep}
              setPressRelease={setPressRelease}
              step={step}
              testing={testing}
              validationErrors={validationErrors}
            />
          )}
        </main>
      </div>
    </I18nProviderWrapper>
  );
};
