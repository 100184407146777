import fetchJSON from '@api/api_helper';


const url = (labelKey): string => `/api/v4/labels/${labelKey}`;
const moveUrl = (labelKey): string => `/api/v4/labels/${labelKey}/move`;

// update name or position, NOT path (depth)
// example options: { name: 'Sale Team', position: 3 }
export const update = (labelKey, options): Promise<any> => (
  fetchJSON(url(labelKey), {
    method: 'PUT',
    body: {
      data: {
        attributes: {
          ...options
        }
      }
    }
  }).catch(async (response) => { throw (await response.json()); })
);


export const destroy = (labelKey): Promise<any> => (
  fetchJSON(url(labelKey), {
    method: 'DELETE'
  }).catch(async (response) => { throw (await response.json()); })
);


// update path (depth)
// parent_key = null will move label to top level
// position (optional)
// eslint-disable-next-line
export const move = (labelKey, parent_key, position): Promise<any> => (
  fetchJSON(moveUrl(labelKey), {
    method: 'PUT',
    body: {
      data: {
        attributes: {
          // eslint-disable-next-line
          parent_key,
          position
        }
      }
    }
  }).catch(async (response) => { throw (await response.json()); })
);
