import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

interface AssetBoardActionsOverlayProps {
  children: React.ReactNode;
  onClickHandler: () => WindowProxy | null | void;
  buttonText: string;
  showOverlay: boolean;
}

type AssetBoardActionsOverlayComponentType = React.FunctionComponent<AssetBoardActionsOverlayProps>;
const AssetBoardActionsOverlay: AssetBoardActionsOverlayComponentType = (options): JSX.Element => {
  const { children, onClickHandler, buttonText, showOverlay } = options;
  return (
    <CSSTransition
      in={showOverlay}
      timeout={200}
      unmountOnExit
      classNames="fade-in"
    >
      <div className="board-action-overlay">
        <button
          onClick={onClickHandler}
          className="button view-board"
          type="button"
        >
          {buttonText}
        </button>
        <div className="board-action-container">
          { children }
        </div>
      </div>
    </CSSTransition>
  );
};

export default AssetBoardActionsOverlay;
