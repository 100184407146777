import { fetchJson } from '@api/ApiHelper';
import { ApiDatum } from '@api/v4/ApiResponseTypes';
import { Locale } from '@components/common/language_menu/languagesMap';
import { CsvOptionsBase, CsvResourceTypes } from '@api/v4/private/resources/csv';

export interface CsvDownloadServer {
  download_key: string;
  path?: string;
}

export type CsvDownloadGetClient = CsvDownloadServer;

interface CsvDownloadGetOptions {
  downloadKey: string;
  resourceType: CsvResourceTypes;
}

export type CsvDownloadGetResponse = ApiDatum<CsvDownloadServer | null, 'csv_downloads'>;

/**
 * Get the download status (like a 202 when processing, or a 200 when CSV url is ready)
 * @param {CsvDownloadGetOptions} options CsvDownloadGetOptions
 * @returns {Promise<CsvDownloadGetResponse>} Promise<CsvDownloadGetResponse>
 */
export const getCsvDownload = async (options: CsvDownloadGetOptions): Promise<CsvDownloadGetResponse> => {
  const { key } = BFG.resource;
  const { downloadKey, resourceType } = options;

  return fetchJson<CsvDownloadGetResponse>({
    url: `/api/v4/private/${resourceType}s/${key}/csv/downloads/${downloadKey}`
  });
};

interface CsvDownloadPostOptions extends CsvOptionsBase {
  locale: Locale;
  resourceType: CsvResourceTypes;
}

type CsvDownloadPostResponse = ApiDatum<CsvDownloadServer | null, 'csv_downloads'>;

/**
 * Create/kick off a CSV download job
 * @param {CsvDownloadPostOptions} options CsvDownloadPostOptions
 * @returns {Promise<CsvDownloadPostResponse>} Promise<CsvDownloadPostResponse>
 */
export const postCsvDownload = async (options: CsvDownloadPostOptions): Promise<CsvDownloadPostResponse> => {
  const { key } = BFG.resource;
  const { actionableType, context, locale, resourceType } = options;

  return fetchJson<CsvDownloadPostResponse>({
    body: {
      data: {
        attributes: {
          actionable_type: actionableType,
          download_context: context,
          ugt_locale: locale
        }
      }
    },
    method: 'POST',
    url: `/api/v4/private/${resourceType}s/${key}/csv/downloads`
  });
};
