// types
import type {
  BaseScreenReaderProps,
  HideFromScreenReaderProps,
  OnlyForScreenReaderProps
} from './ScreenReaderTypes';

export type {
  BaseScreenReaderProps,
  HideFromScreenReaderProps,
  OnlyForScreenReaderProps
};

// components
export { HideFromScreenReader } from './hide-from';
export { OnlyForScreenReader } from './only-for';
