import { MoreInfoTooltip, StandardTable, Widths } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { ReactElement } from 'react';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { ShareManifestServer } from '@api/v4/share_manifests';

import '@components/bulk_management/share_links/styles/ShareLinksTable.scss';
import { TextWarningButton } from '@components/library/button';
import { StandardCheckbox } from '@components/library/checkbox';

import { localizeDate } from '@helpers/localize';

export interface ShareLinksTableProps {
  loading: boolean;
  resourceName: string;
  selectedShares: string[] | null;
  setSelectedShares: SetStateDispatch<string[]>;
  setDeleteSelectedSharesConfirmationOpen: SetStateDispatch<boolean>;
  shares: ApiResponseObject<ShareManifestServer>[];
  shiftHeld: boolean;
  updateSelectAll: () => void;
  updateSelectedShares: (id: string) => void;
  updateMultipleSelectedShares: (ids: string[]) => void;
}

export const ShareLinksTable = ({
  loading,
  resourceName,
  setDeleteSelectedSharesConfirmationOpen,
  setSelectedShares,
  selectedShares,
  shares,
  shiftHeld,
  updateSelectAll,
  updateMultipleSelectedShares,
  updateSelectedShares,
}: ShareLinksTableProps): ReactElement => {

  const deleteShare = (shareId: string[]): void => {
    setSelectedShares(shareId);
    setDeleteSelectedSharesConfirmationOpen((isOpen) => !isOpen);
  };

  const selectShares = (shareId: string, i: number): void => {
    if (shiftHeld) {
      const indexOfLastAddedShare = shares.findIndex((share) =>
        share.id === selectedShares[selectedShares.length - 1]
      );

      if (i > indexOfLastAddedShare) {
        const newIds = shares.slice(indexOfLastAddedShare + 1, i + 1).map((share) => share.id);
        updateMultipleSelectedShares(newIds);
      } else if (i < indexOfLastAddedShare) {
        const newIds = shares.slice(i, indexOfLastAddedShare).map((share) => share.id);
        updateMultipleSelectedShares(newIds);
      } else {
        updateSelectedShares(shareId);
      }
    } else {
      updateSelectedShares(shareId);
    }
  };

  const allSharesSelected = (): boolean => {
    return selectedShares.length === shares.length && selectedShares.length !== 0;
  };

  const rows = shares.map((share, i) => {
    const {
      availability_end,
      created_at,
      created_by,
      digest,
      internal,
      name,
      number_of_assets,
      require_identification,
      shareable_type
    } = share.attributes;
    const { id } = share;
    const isSelected = selectedShares.includes(id);

    return {
      selectCheckbox:
        <StandardCheckbox
          // eslint-disable-next-line react/no-array-index-key
          checked={isSelected}
          id={id}
          onChange={(shareId):void => selectShares(shareId, i)}
          />,
      name:
        <a className='share-link-name' href={`/s/${digest}`}>
          {name}
        </a>,
      createdBy: created_by,
      assets: number_of_assets,
      access: internal ? t`Restricted` : t`Public`,
      created: localizeDate(created_at),
      expires: availability_end ? localizeDate(availability_end) : ('-'),
      requireIdentification: require_identification ? t`Required` : ('-'),
      edit: shareable_type === 'AssetGroup' && (
        <a href={`/s/${digest}?show_edit_share_mode=true`} rel="noopener noreferrer" target="_blank">
          <Trans>Edit</Trans>
        </a>
      ),
      delete:
        <TextWarningButton
          className="delete-share"
          onClick={():void => deleteShare([id])}
          type="button"
        >
          <span className="bff-trash" />
        </TextWarningButton>,
    };
  });

  return (
    <div id='share-links-table-container'>
      <StandardTable
        caption={t`Share Links for ${resourceName}`}
        columns={[
          {
            children: <StandardCheckbox
              checked={allSharesSelected()}
              id="bulk-delete-select-all"
              onChange={updateSelectAll}
            />,
            rowKey: 'selectCheckbox',
            width: '1%'
          },
          {
            children: <Trans>Name</Trans>,
            heading: <Trans>Name</Trans>,
            rowKey: 'name',
            width: '30%'
          },
          {
            children: <Trans>Created by</Trans>,
            heading: <Trans>Created by</Trans>,
            rowKey: 'createdBy',
            width: '15%'
          },
          {
            centered: true,
            children: <Trans>Assets</Trans>,
            heading: <Trans>Assets</Trans>,
            rowKey: 'assets',
            width: '10%'
          },
          {
            centered: true,
            children: <Trans>Access</Trans>,
            heading: <Trans>Access</Trans>,
            rowKey: 'access',
            width: '10%'
          },
          {
            centered: true,
            children: <Trans>Created</Trans>,
            heading: <Trans>Created</Trans>,
            rowKey: 'created',
            width: '7%'
          },
          {
            centered: true,
            children: <Trans>Expires</Trans>,
            heading: <Trans>Expires</Trans>,
            rowKey: 'expires',
            width: '8%'
          },
          {
            centered: true,
            children:
              <div className='share-link-id-col-header-container'>
                <p className='col-header-text'><Trans>ID</Trans></p>
                <MoreInfoTooltip
                  className='id-more-info'
                  iconLabel={t`More Info`}
                  id='share-link-id'
                  tooltip={<Trans>Indicates if an email address is required to view share link</Trans>}
                  width={Widths.Small}
                ></MoreInfoTooltip>
              </div>
            ,
            heading: <Trans>ID</Trans>,
            rowKey: 'requireIdentification',
            width: '5%'
          },
          {
            centered: true,
            rowKey: 'edit',
            width: '5%'
          },
          {
            centered: true,
            rowKey: 'delete',
            width: '5%'
          }
        ]}
        id='shareLinksManagementTable'
        loaderLabel={t`Loading...`}
        loading={loading}
        rows={rows}
        scrollX={false}
      />
    </div>
  );
};
