/* eslint-disable @typescript-eslint/naming-convention */
import { fetchJson } from '@api/ApiHelper';

import { hasInsightsSession } from '../sessions';
import { InsightsEventActions, InsightsEventSourceResources, InsightsEventTypes } from './InsightsEventsEnums';
import { CreateInsightsEventResponse, InsightsEventSource } from './InsightsEventsTypes';

interface CreateInsightsEventOptions {
  actionName: InsightsEventActions;
  resourceKey: string;
  resourceType: InsightsEventTypes;
  sourceKey: string;
  sourceType: InsightsEventSourceResources;
}

/**
 * Internal only. Use `createInsightsEvent` instead.
 * @param options CreateInsightsEventOptions
 * @returns Promise<CreateInsightsEventResponse>
 */
const createEvent = async (options: CreateInsightsEventOptions): Promise<CreateInsightsEventResponse> => {
  const { actionName, resourceKey, resourceType, sourceKey, sourceType } = options;

  const source: InsightsEventSource = {
    resource_key: sourceKey,
    resource_type: sourceType
  };

  if (BFG.manifest && BFG.manifest.key) {
    source.share_manifest_key = BFG.manifest.key;
  }

  return fetchJson({
    body: {
      data: {
        attributes: {
          data: {
            action_name: actionName,
            resource_key: resourceKey,
            resource_type: resourceType,
            source: source
          }
        }
      }
    },
    credentials: 'include',
    method: 'POST',
    url: `${BFG.INSIGHTS_API_URL}/ingestion/api/v1/events`
  });
};

/**
 * Create a single Insights event. Returns null if the current user is super (doesn't log to Insights).
 * @param options CreateInsightsEventOptions
 * @returns Promise<CreateInsightsEventResponse | null>
 */
export const createInsightsEvent = async (options: CreateInsightsEventOptions): Promise<CreateInsightsEventResponse | null> => {
  if (BFG.currentUser && BFG.currentUser.su) { return null; }

  if (hasInsightsSession()) {
    await createEvent(options);
  } else {
    // await createInsightsSession();
    await createEvent(options);
  }
};

/**
 * Convenience promise to create an asset Insights event.
 * @param assetKey string
 * @returns Promise<CreateInsightsEventResponse | null>
 */
export const createAssetShareInsightsEvent = (assetKey: string): Promise<CreateInsightsEventResponse | null> => createInsightsEvent({
  actionName: InsightsEventActions.SHARED,
  resourceKey: assetKey,
  resourceType: InsightsEventTypes.ASSET,
  sourceKey: BFG.resource.key,
  sourceType: BFG.resource.type as InsightsEventSourceResources
});
