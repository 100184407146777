import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import updateAttachment from '@api/v4/attachments';
import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { bulkRefreshSections } from '@helpers/show_page_helpers';
import { formattedUppyAttachment } from '@helpers/uppy';

// Two Uses:
// 1. Asset Edit Tab next to Attachment
// 3. Check In

class VersionUppy extends React.Component {
  triggerUpload = (files) => {
    const file = formattedUppyAttachment(files[0]);
    this.uploadVersion(file);
  }

  uploadVersion = (file) => {
    const { attachmentKey } = this.props;
    const options = {
      attachmentKey,
      data: { attributes: file },
      context: this
    };
    updateAttachment(options)
      .then((response) => {
        if (response && !response.ok) {
          this.handleSuccess(response);
        } else {
          Notify.create({
            title: t`An error occurred when updating the attachment. Please try again.`,
            type: 'error'
          });
        }
      });
  }

  handleSuccess = (response) => {
    window.swal(this.successOptions(), () => { });
    const {
      assetKey,
      attachmentKey,
      handleToggleCheckinCheckout,
      position,
      updateTab,
      updateVersionUploading
    } = this.props;
    const filename = response.data.attributes.filename;
    const imageUrl = response.data.attributes.url;
    const hoverContent = `<img src='${imageUrl}' style='max-width:230px;max-height:230px;'>`;
    const $attachmentContainer = $(`li.m-edit-attachment[data-attachment-key='${attachmentKey}']`);

    $attachmentContainer.find('span.name-popover').text(filename);
    $attachmentContainer.find('span.name-popover').attr('data-content', hoverContent);

    if (position === 0) {
      $('.j-asset-description img.m-asset-img-thumb').attr('src', imageUrl);
      bulkRefreshSections([assetKey]);
    }
    if (this.checkInVersionUpload()) {
      updateTab(response.data);
      handleToggleCheckinCheckout();
      if (updateVersionUploading) updateVersionUploading({ versionUploading: false }); // status tab
    }
  };

  successOptions = () => ({
    type: 'success',
    title: t`Success!`,
    text: t`Your attachment has been updated!`,
    customClass: 'attachment-updated',
    showConfirmButton: true,
    allowOutsideClick: true
  });

  checkInVersionUpload() {
    return typeof this.props.handleToggleCheckinCheckout === "function";
  }

  locationIdentifier() {
    return this.checkInVersionUpload() ? "-check-in" : "";
  }

  trigger() {
    const { attachmentKey } = this.props;
    return `uppy-version-upload-trigger-${attachmentKey}${this.locationIdentifier()}`;
  }

  renderTrigger = () => {
    const buttonText = this.checkInVersionUpload()
      ? <Trans>Check In & Upload</Trans> : <Trans>To update this attachment, click to browse</Trans>;
    return (
      <button
        className={this.checkInVersionUpload() ? "button primary lg" : "uppy-edit-version-button"}
        id={this.trigger()}
        type="button"
      >
        {buttonText}
      </button>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderTrigger()}
        <UppyUploader
          {...this.props}
          button
          handleUpload={this.triggerUpload}
          restrictions={{ maxNumberOfFiles: 1 }}
          template="file_ingest"
          trigger={`#${this.trigger()}`}
          uniqIdentifier={`version-uppy-${this.props.attachmentKey}${this.locationIdentifier()}`}
        />
      </React.Fragment>
    );
  }
}

VersionUppy.propTypes = {
  assetKey: PropTypes.string.isRequired,
  attachmentKey: PropTypes.string.isRequired,
  position: PropTypes.number,
  handleToggleCheckinCheckout: PropTypes.func,
  updateTab: PropTypes.func,
  updateVersionUploading: PropTypes.func
};

VersionUppy.defaultProps = {
  position: 9999
};

export default VersionUppy;
