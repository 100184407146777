import { ButtonLooks, DialogSizes, StandardButton, StandardDialog, brandfolderColorPalette } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const MergeFacesDialogWrapper = styled(StandardDialog)`
  .bf-dialog__type--modal {
    height: auto
  }

  .bf-dialog__header {
    background-color: ${brandfolderColorPalette.neutrals[0]};
    padding: 0 15px;
  }

  .bf-dialog__body {
    padding: 0 30px;
  }

  .merge-people-faces-dialog-body {
    align-items: center;
    display: flex;
    flex-direction: column;

    img {
      border-radius: 4px;
      height: 100px;
      object-fit: cover;
      width: 100px;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    h4 {
      color: ${brandfolderColorPalette.neutrals[900]};
      font-size: 24px;
      font-weight: 700;
      padding: 36px 0 24px 0;
    }

    p {
      color: ${brandfolderColorPalette.neutrals[700]};
      font-size: 14px;
      font-weight: 450;
      line-height: 20px;
    }
  }
  
  .bf-dialog__footer {
    text-align: end;
    
    button {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`;

interface MergeFacesDialogProps {
  currentPersonThumbnail: string;
  handleCancelMerge: () => void;
  handleMerge: () => void;
  isOpen: boolean;
  targetPersonThumbnail: string;
}

export const MergeFacesDialog: FunctionComponent<MergeFacesDialogProps> = ({
  currentPersonThumbnail,
  handleCancelMerge,
  handleMerge,
  isOpen,
  targetPersonThumbnail
}) => (
  <MergeFacesDialogWrapper
    className="merge-people-faces-dialog"
    footer={
      <>
        <StandardButton
          look={ButtonLooks.Tertiary}
          onClick={handleCancelMerge}
        >
          <Trans>Don't merge</Trans>
        </StandardButton>
        <StandardButton onClick={handleMerge}>
          <Trans>Merge people</Trans>
        </StandardButton>
      </>
    }
    id="merge-people-faces"
    labels={{
      closeButtonLabel: 'Close'
    }}
    open={isOpen}
    setOpen={handleCancelMerge}
    showTitle={false}
    size={DialogSizes.Xsmall}
    title="Merge people faces"
  >
    <div className="merge-people-faces-dialog-body">
      <div>
        <img alt="selected person" src={targetPersonThumbnail} />
        <img alt="current person" src={currentPersonThumbnail} />
      </div>
      <h4>
        <Trans>Merge these people?</Trans>
      </h4>
      <p>
        <Trans>
          Another person has already been tagged with this name. If they’re the same person, you can merge them. If they’re different people, please give this person a unique name.
        </Trans>
      </p>
    </div>
  </MergeFacesDialogWrapper>
);
