import React from 'react';
import PropTypes from 'prop-types';

import './styles/radio_button.scss';

const RadioButton = (props) => (
  <label className="radio-row">
    <h4>{props.labelCopy}</h4>
    <input
      className="radio-input"
      type="radio"
      name={props.name}
      value={props.valueCopy}
      checked={props.checkedButton === props.valueCopy}
      onChange={props.onChangeFunction}
    />
    <span className="radio-dot" />
  </label>
);

RadioButton.propTypes = {
  checkedButton: PropTypes.string.isRequired,
  labelCopy: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChangeFunction: PropTypes.func.isRequired,
  valueCopy: PropTypes.string.isRequired
};

RadioButton.defaultProps = {
};

export default RadioButton;
