import { t, plural, Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment';

import { StandardSwitch, SwitchSize } from '@components/library/switch';
import DateTimePicker from '@components/library/date_time_picker/BaseDateTimePicker';
import { bulkRefreshSections, pluralizeObject } from '@helpers/show_page_helpers';
import { updateAvailability } from '@api/v4/bulk_actions/assets';

const defaultExpires = moment().add(7, 'days').set({ hour: '23', minute: '59', second: '00' });
const title = t`This asset has external links.`;
const text = t`This action may cause broken image links on sites that depend on the availability of these assets. Check the usage tab to see active external links before changing availability.\n\n`;
const sweetAlertOptions = () => ({
  title,
  text,
  type: "warning",
  showCancelButton: true,
  cancelButtonText: t`Cancel`,
  confirmButtonText: t`Update`,
  closeOnConfirm: true,
});

const Availability = ({
  asset,
  refreshAsset,
}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [availabilityActive, setAvailabilityActive] = useState(false);
  const [availability, setAvailability] = useState(null);
  const [expiresActive, setExpiresActive] = useState(false);
  const [expires, setExpires] = useState(null);
  const [areUnsavedDates, setAreUnsavedDates] = useState(false);
  const [previousDates, setPreviousDates] = useState({ availability: null, expires: null });
  const [isAvailabilityDateValid, setIsAvailabilityDateValid] = useState(true);
  const [isExpirationDateValid, setIsExpirationDateValid] = useState(true);

  const assetQty = [asset.id].length;
  const assetAvailabilityUpdatedTitle = plural(assetQty, {
    one: 'Availability updated for # asset!',
    other: 'Availability updated for # assets!'
  });

  const {
    activeCdnTracers,
    availability_start,
    availability_end,
  } = asset.attributes;

  useEffect(() => {
    if (availability_start) {
      setAvailabilityActive(true);
      setAvailability(availability_start);
    }

    if (availability_end) {
      setExpiresActive(true);
      setExpires(availability_end);
    }

    setPreviousDates({
      availability: availability_start,
      expires: availability_end,
    });
  }, []);

  useEffect(() => {
    const isInvalidDate = !isAvailabilityDateValid || !isExpirationDateValid;
    const isAvailabilityAfterExpiration = availability && expires && moment(availability).isAfter(expires);

    if (isInvalidDate) {
      setSubmitDisabled(true);
    } else if (isAvailabilityAfterExpiration) {
      setSubmitDisabled(true);
      Notify.create({
        title: t`The availability date must be before the expiration date. Please try again.`,
        type: 'error'
      });
    } else {
      setSubmitDisabled(false);
    }
  }, [availability, expires, isAvailabilityDateValid, isExpirationDateValid]);

  useEffect(() => {
    setAreUnsavedDates(availability !== previousDates.availability || expires !== previousDates.expires);
  }, [availability, expires]);

  const sendAvailabilityUpdate = () => {
    const updatedAvailability = availabilityActive ? availability : null;
    const updatedExpiration = expiresActive ? expires : null;
    updateAvailability({
      asset_keys: [asset.id],
      availability_start: updatedAvailability,
      availability_end: updatedExpiration,
    })
      .then(() => {
        bulkRefreshSections([asset.id]);
        refreshAsset();
        setSubmitDisabled(false);
        setAreUnsavedDates(false);
        setPreviousDates({ availability: updatedAvailability, expires: updatedExpiration });
        Notify.create({
          title: assetAvailabilityUpdatedTitle,
          type: 'success'
        });
      })
      .catch((err) => {
        console.log(err);
        setSubmitDisabled(false);
        Notify.create({
          title: t`An error occurred when updating asset availability. Please try again.`,
          type: 'error'
        });
      });
  };

  const handleUpdate = () => {
    setSubmitDisabled(true);
    if ((availability || expires) && activeCdnTracers) {
      window.swal(sweetAlertOptions(), (updateSelected) => {
        if (updateSelected) {
          sendAvailabilityUpdate();
        } else {
          setSubmitDisabled(false);
        }
      });
    } else {
      sendAvailabilityUpdate();
    }
  };

  const toggleAvailabilityActive = () => {
    setIsAvailabilityDateValid(true);
    setAvailabilityActive((prevState) => {
      setAvailability(prevState ? null : availability_start);
      return !prevState;
    });
  };

  const toggleExpiresActive = () => {
    setIsExpirationDateValid(true);
    setExpiresActive((prevState) => {
      setExpires(prevState ? null : (availability_end || defaultExpires));
      return !prevState;
    });
  };

  const handleAvailabilityDateValidityChange = (isValid) => {
    setIsAvailabilityDateValid(isValid);
  };

  const handleExpirationDateValidityChange = (isValid) => {
    setIsExpirationDateValid(isValid);
  };

  return (
    <div className="availability-container">
      <div className="availability-row">
        <div className="shielding-row">
          <div className="toggle-row">
            <h4><Trans>Enable Availability Date</Trans></h4>
            <StandardSwitch
              isChecked={availabilityActive}
              onChange={toggleAvailabilityActive}
              size={SwitchSize.Large}
            />
          </div>
          <CSSTransition
            in={availabilityActive}
            timeout={300}
            unmountOnExit
          >
            <div className="date-time-picker-transition">
              <DateTimePicker
                onChange={(timestamp) => setAvailability(timestamp)}
                onDateValidityChange={handleAvailabilityDateValidityChange}
                timestamp={availability}
              />
            </div>
          </CSSTransition>
        </div>
      </div>
      <div className="availability-row">
        <div className="shielding-row">
          <div className="toggle-row">
            <h4><Trans>Enable Expiration Date</Trans></h4>
            <StandardSwitch
              isChecked={expiresActive}
              onChange={toggleExpiresActive}
              size={SwitchSize.Large}
            />
          </div>
          <CSSTransition
            in={expiresActive}
            timeout={300}
            unmountOnExit
          >
            <div className="date-time-picker-transition">
              <DateTimePicker
                onChange={(timestamp) => setExpires(timestamp)}
                onDateValidityChange={handleExpirationDateValidityChange}
                timestamp={expires}
              />
            </div>
          </CSSTransition>
        </div>
      </div>
      <div className="availability-row button-container">
        <CSSTransition
          classNames="fade-in"
          in={areUnsavedDates}
          timeout={200}
          unmountOnExit
        >
          <button
            className="button primary save-button"
            disabled={submitDisabled}
            onClick={handleUpdate}
            type="button"
          >
            <Trans>Save Changes</Trans>
          </button>
        </CSSTransition>
      </div>
    </div>
  );
};

Availability.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      activeCdnTracers: PropTypes.number,
      availability_end: PropTypes.string,
      availability_start: PropTypes.string,
    })
  }).isRequired,
  refreshAsset: PropTypes.func.isRequired,
};

export default Availability;
