import React, { Component }   from 'react';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import AttachmentCard         from './attachment_card';

import './styles/data_styles.scss';

export default class Main extends Component {
  state = {
    dups: this.props.dups,
    sims: this.props.sims,
    attachment: this.props.attachment
  }

  createAttachmentCard(attachmentCardArray) {
    return attachmentCardArray.map( (attachment, i) => {
      return <AttachmentCard key={ i }
                            assetKey={ attachment.asset_key}
                            assetName={attachment.asset_name}
                            attachment={ attachment }
                            brandfolderSlug={BF.fx.brandfolder().slug}/>
    })
  }

  render() {
    const {dups, sims, attachment} = this.state;

    return (
      <I18nProviderWrapper>
        <section className='similar-attachments-component'>
          <div className='attachment-container'>
            {this.createAttachmentCard([Object.assign({}, this.state.attachment)])}
          </div>
          <h2 className='section-title'>{`Duplicate Attachments (${dups.length})`}</h2>
          <div className='duplicates-container'>
            <ul className='card-container'>{this.createAttachmentCard([...this.state.dups])}</ul>
          </div>
          <h2 className='section-title'>{`Similar Attachments (${sims.length})`}</h2>
          <div className='similars-container'>
            <ul className='card-container'>{this.createAttachmentCard([...this.state.sims])}</ul>
          </div>
        </section>
      </I18nProviderWrapper>
    )
  }
}
