export interface TagCountResponse {
  [tagName: string]: number;
}

export type TagSortByOptions = 'name' | 'assetCount';

export interface ManageTagsData {
  /* eslint-disable @typescript-eslint/naming-convention */
  manage_tags: {
    management_action: 'delete' | 'update';
    brandfolder_key: string;
    old_name: string;
    new_name: string;
    auto_generated: boolean;
  }
  /* eslint-enable @typescript-eslint/naming-convention */
}

const queriedNames = (tags: TagCountResponse, queryString: string): string[] => (
  Object.keys(tags).filter((tagName) => (
    tagName.toLowerCase().includes(queryString.toLowerCase())
  ))
);

const namesSortedByName = (tags: TagCountResponse, queryString: string): string[] => {
  const namesToSort = queryString ? queriedNames(tags, queryString) : Object.keys(tags);
  return namesToSort.sort((a, b) => ((a.toLowerCase() < b.toLowerCase()) ? -1 : 1));
};

const namesSortedByCount = (tags: TagCountResponse, queryString: string): string[] => {
  const namesToSort = queryString ? queriedNames(tags, queryString) : Object.keys(tags);
  return namesToSort.sort((a, b) => ((tags[a] < tags[b]) ? 1 : -1));
};

export const sortedNames = (tags: TagCountResponse, queryString: string, sortBy: TagSortByOptions): string[] => (
  sortBy === 'assetCount' ? namesSortedByCount(tags, queryString) : namesSortedByName(tags, queryString)
);

export const updateVisibleTagsAfterRename = (
  tags: TagCountResponse,
  setTags: SetStateDispatch<TagCountResponse>,
  oldName: string,
  newName: string | null
): void => {
  if (!tags) return;
  const tagCount = tags[oldName];
  const updatedTags = { ...tags };
  updatedTags[newName] = tagCount;
  delete updatedTags[oldName];

  setTags(updatedTags);
};

export const updateVisibleTagsAfterDelete = (
  tags: TagCountResponse,
  setTags: SetStateDispatch<TagCountResponse>,
  tagName: string
): void => {
  if (!tags) return;
  const updatedTags = { ...tags };
  delete updatedTags[tagName];

  setTags(updatedTags);
};
