import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import { FormikErrors } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import {
  DesignHuddleTemplate,
  DesignHuddleTemplatesGetListOptions,
  DesignHuddleTemplatesGetListResponse
} from '@api/v4/private/resources/design_huddles/DesignHuddleTypes';
import { BFLoader } from '@components/common/loader/main';
import { ValidationAlert } from '@components/show_page/sections/asset/custom-templates/ValidationAlert';
import { CreateProjectValues } from '@components/show_page/sections/asset/custom-templates';

import './styles/create-from-template.scss';

interface CreateFromTemplateProps {
  errors: FormikErrors<CreateProjectValues>;
  hasSubmitted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  templateId: number | null;
  validateField: (field: string) => void;
}

export const CreateFromTemplate: FunctionComponent<CreateFromTemplateProps> = (props) => {
  const {
    errors,
    hasSubmitted,
    setFieldValue,
    setFieldTouched,
    templateId,
    validateField
  } = props;

  const params: DesignHuddleTemplatesGetListOptions = {
    limit: 1000,
    order: 'ASC',
    page: 1,
    sort: 'template_title'
  };

  const fetchTemplates = useFetch<DesignHuddleTemplatesGetListResponse>({
    params,
    url: `/api/v4/private/${BFG.resource.type}s/${BFG.resource.key}/design_huddles/templates`
  });

  const [templates, setTemplates] = useState<DesignHuddleTemplate[]>([]);

  useEffect(() => {
    if (fetchTemplates.response) {
      setTemplates(fetchTemplates.response.data.items);
    }
  }, [fetchTemplates.response]);

  const validate = (): void => {
    setTimeout(() => {
      validateField('templateId');
    }, 50);
  };

  return (
    <div
      className={classnames(
        'create-from-template',
        fetchTemplates.loading && 'create-from-template--loading'
      )}
    >
      {!fetchTemplates.loading && hasSubmitted && errors.templateId && (
        <ValidationAlert>
          {errors.templateId}
        </ValidationAlert>
      )}
      {fetchTemplates.loading && (
        <BFLoader />
      )}
      {!fetchTemplates.loading && templates.length > 0 && (
        <>
          <p className="create-new-asset-dialog__p">
            <Trans>Choose a template</Trans>
          </p>
          <div className="card-list">
            {templates.map((template) => (
              <button
                key={template.template_id}
                aria-pressed={template.template_id === templateId}
                className="base-card"
                onClick={(): void => {
                  setFieldValue('templateId', template.template_id);
                  setFieldTouched('templateId', true);
                  validate();
                }}
                type="button"
              >
                <div className="card asset-card">
                  <div className="card-thumbnail">
                    <img
                      alt={template.template_title}
                      src={template.thumbnail_url}
                    />
                  </div>
                </div>
                <p className="description description-foot">{template.template_title}</p>
              </button>
            ))}
          </div>
        </>
      )}
      {!fetchTemplates.error && !fetchTemplates.loading && templates.length === 0 && (
        <p className="create-from-template__empty">
          <Trans>You have no templates. Please choose a different option.</Trans>
        </p>
      )}
      {!fetchTemplates.loading && fetchTemplates.error && (
        <p className="create-from-template__error">
          <Trans>Error loading templates. Please refresh the page and try again.</Trans>
        </p>
      )}
    </div>
  );
};
