const abbreviate = {
  'Pacific Standard Time' : 'PST',
  'Pacific Daylight Time' : 'PDT',
  'Eastern Standard Time' : 'EST',
  'Eastern Daylight Time' : 'EDT',
  'Central Standard Time' : 'CST',
  'Central Daylight Time' : 'CDT',
  'Mountain Standard Time' : 'MST',
  'Mountain Daylight Time' : 'MDT'
};

export const getTimezone = (): string => {
  const localTime = new Date();
  const timezoneMatch = localTime.toString().match(/\(([^\)]+)\)$/);
  const timezoneString = timezoneMatch !== null ? timezoneMatch[1] : '';
  const timezoneAbbrv = abbreviate[timezoneString];

  return timezoneAbbrv || timezoneString;
};
