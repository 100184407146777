import classnames from 'classnames';
import React, { ButtonHTMLAttributes, FunctionComponent, ReactNode, useEffect, useRef } from 'react';

import classes from '../styles/tab_button.module.scss';

type ButtonHTMLAttributesOmitted = Omit<ButtonHTMLAttributes<HTMLButtonElement>,
'aria-controls' | 'aria-selected' | 'ref' | 'role' | 'tabIndex' | 'type'>

export interface TabButtonProps extends ButtonHTMLAttributesOmitted {
  active: boolean;
  activeIndex: number;
  /** content is required */
  children: ReactNode;
  focusIndex: number | null;
  /** required for accessibility */
  id: string;
  index: number;
  /** the id of the TabPanel (required for accessibility) */
  tabPanelId: string;
}

/**
 * Tab buttons.
 * This component should be used when NOT routing/linking.
 * @param {TabButtonProps} props TabButtonProps
 */
export const TabButton: FunctionComponent<TabButtonProps> = (props) => {
  const { active = false, activeIndex, children, focusIndex, id, index, tabPanelId, ...buttonProps } = props;

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (activeIndex === index && ref?.current) {
      ref.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    if (focusIndex !== null && focusIndex === index && ref?.current) {
      ref.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusIndex]);

  return (
    <button
      {...buttonProps}
      ref={ref}
      aria-controls={tabPanelId}
      aria-selected={active}
      className={classnames(classes.tabButton, buttonProps.className)}
      id={id}
      role="tab"
      tabIndex={!active ? -1 : undefined}
      type="button"
    >
      {children}
    </button>
  );
};
