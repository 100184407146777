import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { debounce } from '@helpers/debounce';
import { PressReleaseFormSteps, PressReleaseUpsert } from './PressReleaseTypes';

import classes from './styles/press_release_form_step3.module.scss';

interface PressReleaseFormStep3Props {
  pressRelease: PressReleaseUpsert;
  step: PressReleaseFormSteps;
}

/**
 * Step 3: Review
 * @param {PressReleaseFormStep3Props} props PressReleaseFormStep3Props
 */
export const PressReleaseFormStep3: FunctionComponent<PressReleaseFormStep3Props> =
  ({ pressRelease, step }) => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const [height, setHeight] = useState<number | undefined>(undefined);

    const resizeIframe = (iframe: HTMLIFrameElement | null): void => {
      if (iframe) {
        const iframeHeight = iframe.contentWindow?.document?.body?.scrollHeight;
        if (iframeHeight) {
          setHeight(iframeHeight + 20);
        }
      }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (iframeRef && iframeRef.current && pressRelease && step === 3) {
        // set the iframe height when iframeRef is initialized
        resizeIframe(iframeRef.current);

        // set the height of the iframe on window resize (debounced)
        const debouncedResize = debounce(() => {
          resizeIframe(iframeRef.current);
        }, 300);

        window.addEventListener('resize', debouncedResize);

        return (): void => {
          window.removeEventListener('resize', debouncedResize);
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    return (
      <>
        <iframe
          ref={iframeRef}
          className={classnames('iframe-preview', classes.preview)}
          srcDoc={pressRelease?.content_html || undefined}
          style={{ height }}
          title={pressRelease?.subject}
        />
      </>
    );
  };
