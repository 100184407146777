import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { ListDropdown } from '@components/library/dropdown';
import { Collection } from '@helpers/show_page_helpers';

import './styles/main.scss';

class SectionSort extends React.Component {
  renderSectionsButton = () => {
    const { editable } = this.props;

    if (editable && BFG.context.hasFeature("manage_sections") && !Collection.key) {
      return (
        <button
          className="button tertiary edit section-pill j-manage-sections-link"
          type="button"
        >
          <span className="bff-edit" />
          <Trans>Sections</Trans>
        </button>
      );
    }

    /**
     * NOTE: If you update this translation, you'll need to update Capybara spec at
     * spec/features/specs/new_world/locales_spec.rb
     */
    return <h3 className="sections-label"><Trans>Sections:</Trans></h3>;
  }

  renderSectionsButtonsList = () => {
    const {
      activeSection,
      disabledSections,
      editable,
      sections,
      sectionsToShow,
    } = this.props;
    const staticClasses = 'section-pill c-custom-border c-custom-opaque-background';

    const sectionButtons = sections.map((section) => {
      const disabledSection = disabledSections.includes(section.section_key);
      const sectionToShow = !sectionsToShow.includes(section.id);
      const isHidden = !editable && (sectionToShow || disabledSection) ? "hidden" : "";
      const isDisabled = disabledSections.includes(section.section_key) ? "disabled" : "";
      const isActive = section.section_key.toString() === activeSection ? "active" : "";

      return (
        <button
          key={section.id}
          className={`${staticClasses} ${isActive} ${isHidden} ${isDisabled}`}
          onClick={() => this.props.toggleActiveSection(section.section_key)}
          type="button"
        >
          {section.name}
        </button>
      );
    });

    const allIsActive = activeSection === "all" ? "active" : "";

    return (
      <>
        <button
          className={`${staticClasses} ${allIsActive}`}
          onClick={() => this.props.toggleActiveSection("all")}
          type="button"
        >
          <Trans>All</Trans>
        </button>
        {sectionButtons}
      </>
    );
  }

  renderSectionsDropdownList = () => {
    const { sections, activeSection, toggleActiveSection } = this.props;
    const selectAllOption = { value: 'all', label: 'All' };
    let selectedSection = selectAllOption;

    const options = sections.map((section) => {
      const active = activeSection === section.section_key;
      const option = { value: section.section_key, label: section.name };

      if (active) {
        selectedSection = option;
      }

      return option;
    });

    options.unshift(selectAllOption);

    return (
      <div className="select-list-wrapper">
        <ListDropdown
          className="select-list"
          onChange={(e) => toggleActiveSection(e?.value?.toString() || 'all')}
          options={options}
          value={selectedSection}
          virtualizeOptions={false}
        />
      </div>
    );
  }

  render() {
    const { sectionDropdownSetting } = this.props;
    const displaySettingClass = sectionDropdownSetting
      ? 'display-sections-dropdown'
      : 'display-sections-buttons';

    return (
      <ul
        className={`${displaySettingClass}`}
        id="section-sort-list"
      >
        {this.renderSectionsButton()}
        {sectionDropdownSetting
          ? this.renderSectionsDropdownList()
          : this.renderSectionsButtonsList()}
      </ul>
    );
  }
}

SectionSort.propTypes = {
  activeSection: PropTypes.string.isRequired,
  disabledSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  editable: PropTypes.bool.isRequired,
  sectionDropdownSetting: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    section_key: PropTypes.string,
  })).isRequired,
  sectionsToShow: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleActiveSection: PropTypes.func.isRequired,
};

SectionSort.defaultProps = {
  sectionDropdownSetting: null
};

export default SectionSort;
