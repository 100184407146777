import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import {
  CustomizedTemplate,
  CustomizedTemplateResponse,
  CustomizedTemplateApprovalStatuses,
  DesignHuddleProject,
  DesignHuddleProjectGetResponse
} from '@api/v4/private/resources/design_huddles';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { BFLoader } from '@components/common/loader/main';
import { ApprovalStatus } from '@components/design_huddles/editors/ApprovalStatus';
import { CopyLink } from '@components/design_huddles/editors/CopyLink';
import { Export } from '@components/design_huddles/editors/Export';
import {
  approvedOrPending,
  fetchThumbnailFromExport,
  isDownloadAvailable
} from '@components/design_huddles/editors/helpers';
import { SaveProject } from '@components/design_huddles/editors/SaveProject';
import { SaveTemplate } from '@components/design_huddles/editors/SaveTemplate';
import { TitleDetails } from '@components/design_huddles/editors/TitleDetails';
import { UpdateThumbnail } from '@components/design_huddles/editors/UpdateThumbnail';
import { PrimaryButton, TertiaryButton } from '@components/library/button';
import { getCurrentUserIsAnonymous } from '@helpers/user';

import './styles/project.scss';

interface ProjectProps {
  accessToken: string;
  adminView: boolean;
  adminCollableView: boolean;
  assetKey: string | null;
  customizedTemplateKey: string | null;
  projectId: string;
  projectRedirectPath: string | null;
  projectView: boolean | null,
  resourceView: boolean | null;
  sectionKey: string | null;
  templateView: boolean | null;
}

export const Project: FunctionComponent<ProjectProps> = (props) => {
  const {
    accessToken,
    adminView,
    adminCollableView,
    assetKey,
    customizedTemplateKey,
    projectId,
    projectRedirectPath,
    projectView,
    resourceView,
    sectionKey,
    templateView
  } = props;

  const resourcePath = assetKey ? `assets/${assetKey}` : `${BFG.resource.type}s/${BFG.resource.key}`;
  const fetchProject = useFetch<DesignHuddleProjectGetResponse>({
    url: `/api/v4/private/${resourcePath}/design_huddles/projects/${projectId}`
  });
  const fetchCustomizedTemplate = useFetch<CustomizedTemplateResponse>({
    url: `/api/v4/private/customized_design_huddle_templates/${customizedTemplateKey}`,
    fetchOnMount: false
  });

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [project, setProject] = useState<DesignHuddleProject | null>(null);
  const [showSaveProject, setShowSaveProject] = useState(false);
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [showSaveToBrandfolder, setShowSaveToBrandfolder] = useState(false);
  const [customizedTemplate, setCustomizedTemplate] = useState<CustomizedTemplate | null>(null);
  const [previewThumbnailUrl, setPreviewThumbnailUrl] = useState<string | null>(null);
  const approvalStatus = customizedTemplate?.approval_state || CustomizedTemplateApprovalStatuses.NoApprovalRequested;

  const loadDesignHuddleEditor = (): void => {
    DSHDEditorLib.configure({
      domain: 'designhuddle.brandfolder-apps.com',
      access_token: accessToken, // eslint-disable-line @typescript-eslint/naming-convention
      admin: adminView
    });

    DSHDEditorLib.insert('project-editor', {
      project_id: projectId // eslint-disable-line @typescript-eslint/naming-convention
    });

    setEditorLoaded(true);
  };

  const loadDesignHuddleScript = (): void => {
    const script = document.createElement('script');
    script.src = 'https://cdn.designhuddle.com/editor/v1/lib.js';
    script.onload = (): void => loadDesignHuddleEditor();
    document.body.append(script);
  };

  const setThumbnailAsync = async (): Promise<void> => {
    try {
      const thumbnailUrl = await fetchThumbnailFromExport(projectId, resourcePath);
      setPreviewThumbnailUrl(thumbnailUrl);
    } catch (e) {
      Notify.create({
        title: t`Could not load preview image. Please refresh to try again.`,
        type: 'error'
      });
      setPreviewThumbnailUrl('');
    }
  };

  useEffect(() => {
    if (projectRedirectPath && window.location.pathname !== projectRedirectPath) {
      window.history.pushState('redirect', 'Project Path', projectRedirectPath);
    }
    if (!editorLoaded && !approvedOrPending(approvalStatus)) {
      loadDesignHuddleScript();
    }

    if (resourceView) {
      // prompt the user if they want to close the tab/window
      // but only if they're in show_project_from_resource controller
      window.onbeforeunload = (): string => '';
    }

    if (templateView && customizedTemplateKey) {
      fetchCustomizedTemplate.fetch();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showSaveTemplate) {
      setShowSaveProject(false);
    }
  }, [showSaveTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchProject.response) {
      setProject(fetchProject.response.data);
    }
  }, [fetchProject.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchCustomizedTemplate.response) {
      setCustomizedTemplate(fetchCustomizedTemplate.response.data.attributes);
    }
  }, [fetchCustomizedTemplate.response]);

  useEffect(() => {
    if (customizedTemplate) {
      if (approvedOrPending(customizedTemplate.approval_state)) {
        // remove stale iframe element from initial render
        const previewSection = document.getElementById('project-preview');
        const iframes = previewSection.getElementsByTagName('iframe');
        if (iframes.length > 0) {
          previewSection.removeChild(iframes[0]);
        }
        setEditorLoaded(false);

        // fetch thumbnail url to display
        setThumbnailAsync();
      } else if (!editorLoaded) {
        loadDesignHuddleScript();
      }
    }
  }, [customizedTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <I18nProviderWrapper>
      <section className="project__actions">
        <div className="project__secondary-actions">
          {!getCurrentUserIsAnonymous(BFG.currentUser) && <CopyLink />}
          {project && !resourceView && isDownloadAvailable(approvalStatus) && (
            <Export
              projectId={project.project_id}
              projectTitle={project.project_title}
              templateKey={customizedTemplateKey}
            />
          )}
          {assetKey && project && projectView && (
            <UpdateThumbnail
              assetKey={assetKey}
              projectId={project.project_id}
            />
          )}
        </div>
        <TitleDetails
          approvalStatus={approvalStatus}
          project={project}
          projectView={projectView}
          templateView={templateView}
        />
        {adminView && !templateView && (
          <div className="project__primary-actions">
            {!resourceView && (
              <>
                <TertiaryButton
                  className="project__button"
                  id="project-generate-template"
                  onClick={(): void => setShowSaveTemplate(true)}
                >
                  <Trans>Generate Template</Trans>
                </TertiaryButton>
                <SaveTemplate
                  projectId={projectId}
                  sectionKey={sectionKey}
                  setShowSaveTemplate={setShowSaveTemplate}
                  showSaveTemplate={showSaveTemplate}
                />
              </>
            )}
            {/* this should only show on show_project_from_resource */}
            {project && resourceView && (
              <>
                <PrimaryButton
                  className="project__button"
                  id="project-save-project"
                  onClick={(): void => setShowSaveProject(true)}
                >
                  <Trans>Save Project</Trans>
                </PrimaryButton>
                <SaveProject
                  project={project}
                  projectId={projectId}
                  sectionKey={sectionKey}
                  setShowSaveProject={setShowSaveProject}
                  setShowSaveTemplate={setShowSaveTemplate}
                  showSaveProject={showSaveProject}
                  showSaveTemplate={showSaveTemplate}
                />
                <SaveTemplate
                  projectId={projectId}
                  sectionKey={sectionKey}
                  setShowSaveTemplate={setShowSaveTemplate}
                  showSaveTemplate={showSaveTemplate}
                />
              </>
            )}
          </div>
        )}
        {templateView && (
          <ApprovalStatus
            approvalStatus={approvalStatus}
            approvalsRequired={BFG.brandfolderSettings.custom_templates_approvals_required}
            customizedTemplateKey={customizedTemplateKey}
            customizedTemplateLoading={fetchCustomizedTemplate.loading}
            saveToBrandfolderProps={{
              projectId,
              sectionKey,
              setShowSaveToBrandfolder,
              showSaveToBrandfolder
            }}
            setCustomizedTemplate={setCustomizedTemplate}
            userHasCollabPerms={adminCollableView}
          />
        )}
      </section>
      {approvedOrPending(approvalStatus) ? (
        <section className="project__preview" id="project-preview">
          {fetchProject.error && <p><Trans>Oops! Something went wrong.</Trans></p>}
          {project && previewThumbnailUrl !== null && (
            <img
              alt={t`Template thumbnail ${project.project_title}`}
              className="project__preview__thumbnail"
              src={previewThumbnailUrl || project.thumbnail_url}
            />
          )}
          {(fetchProject.loading || !(project && previewThumbnailUrl !== null)) && !fetchProject.error && (
            <BFLoader />
          )}
        </section>
      ) : (
        <section
          className="project__editor"
          id="project-editor"
        />
      )}
    </I18nProviderWrapper>
  );
};
