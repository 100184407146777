import { StandardDialogLabels } from '@brandfolder/react';
import { t } from '@lingui/macro';

import { loaderLabel } from './loader';

export const getStandardDialogLabels = (labels: Partial<StandardDialogLabels> = {}): StandardDialogLabels => {
  return {
    closeButtonLabel: t`Close`,
    loaderLabel: loaderLabel(),
    ...labels
  };
};
