import { StandardTextarea } from '@brandfolder/react';import { Plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

class CustomFieldValueRow extends React.Component {
  state = {
    editMode: false,
    deleteMode: false,
    value: this.props.value
  }

  toggleEditMode = () => {
    this.setState((prevState) => ({
      editMode: !prevState.editMode,
      deleteMode: !prevState.editMode ? false : prevState.deleteMode,
      value: this.props.value
    }));
  }

  toggleDeleteMode = () => {
    this.setState((prevState) => ({
      deleteMode: !prevState.deleteMode,
      editMode: !prevState.deleteMode ? false : prevState.editMode
    }));
  }

  updateValue = (event) => {
    this.setState({ value: event.currentTarget.value });
  }

  renameValue = () => {
    const { updateValues, value: oldValue } = this.props;
    const { value } = this.state;

    if (value && value !== oldValue) {
      updateValues(oldValue, value);
      this.setState({ editMode: false });
    } else {
      Notify.create({
        title: t`Please enter a value.`,
        type: 'error',
      });
    }
  }

  deleteValue = () => {
    const { updateValues, value } = this.props;
    updateValues(value, null);
    this.setState({ deleteMode: false });
  }

  showKey() {
    const { count } = this.props;

    return (
      <React.Fragment>
        <div className="show-custom-fields-values-mode">
          <div className="custom-fields-values-information">
            <div className="custom-field-key quarter-width">
              <p>{this.props.customFieldKey.name}</p>
            </div>
            { this.renderValue() }
            <div className="twenty-width">
              <p>
                <Plural
                  one="# Asset"
                  other="# Assets"
                  value={count}
                />
              </p>
            </div>
          </div>
          <div className="button-container">
            <button
              className="button tertiary sm edit-custom-fields"
              onClick={this.toggleEditMode}
              type="button"
            >
              <Trans>Rename</Trans>              
            </button>
            <button
              className="delete-custom-fields"
              onClick={this.toggleDeleteMode}
              onKeyUp={this.toggleDeleteMode}
              type="button"
            >
              <span className="bff-trash" />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderValue() {
    const { value, customFieldKey, ugtLocale } = this.props;
    return (
      <div className="twenty-width">
        <a href={`/${BF.fx.brandfolder().slug}?q=custom_fields.${encodeURIComponent(customFieldKey.name)}.strict:("${encodeURIComponent(value)}")&ugt_locale=${ugtLocale}`}>
          <span className="custom-fields-value">
            {value}
          </span>
        </a>
      </div>
    );
  }

  renderEdit() {
    const { count } = this.props;
    const { value } = this.state;

    return (
      <div className="edit-custom-field-values-mode">
        <StandardTextarea
          id={`id-${value}`}
          label={value}
          onChange={this.updateValue}
          showLabel={false}
          value={value}
        />
        <div className="edit-buttons">
          <button
            className="button tertiary md cancel"
            onClick={this.toggleEditMode}
            type="button"
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            className="button primary md"
            onClick={this.renameValue}
            type="button"
          >
            <Plural
              one="Rename value"
              other="Rename values (#)"
              value={count}
            />
          </button>
        </div>
      </div>
    );
  }

  renderDelete() {
    const { count } = this.props;

    return (
      <div className="delete-custom-field-values-mode">
        {this.renderValue()}
        <div className="delete-buttons">
          <button
            className="button tertiary md cancel"
            onClick={this.toggleDeleteMode}
            type="button"
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            className="button warning md"
            onClick={this.deleteValue}
            type="button"
          >
            <Plural
              one="Delete value"
              other="Delete values (#)"
              value={count}
            />
          </button>
        </div>
      </div>
    );
  }

  renderRow() {
    const { editMode, deleteMode } = this.state;
    if (editMode) { return this.renderEdit(); }
    if (deleteMode) { return this.renderDelete(); }
    return this.showKey();
  }

  render() {
    return this.renderRow();
  }
}

CustomFieldValueRow.propTypes = {
  customFieldKey: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  value: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  ugtLocale: PropTypes.string.isRequired,
  updateValues: PropTypes.func.isRequired,
};

export default CustomFieldValueRow;
