/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';

import { acceptedFileExtensions } from './constants';
import {
  Caption,
  GenerateCaptionFetchState,
  Rating,
  SelectedAsset,
  UpdateCaptionRequestParams,
} from './generateCaptionTypes';

export const updateAiCaptionInGCS = async (
  orgApiKey: string,
  captionId: string,
  rating?: Rating
): Promise<void> => {
  const baseParams: UpdateCaptionRequestParams = {
    bf_key: BFG.brandfolder_key,
    org_key: BFG.organization_key,
    caption_id: captionId,
  };

  const updateParams = rating ? { rating } : { is_applied: 'true' };

  try {
    const sendCaptionRating = await fetch(
      `${BFG.DATA_INSIGHTS_URL}/generative/update_caption`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: `Bearer ${orgApiKey}`,
        },
        body: JSON.stringify({
          ...baseParams,
          ...updateParams,
        }),
      }
    );
    if (sendCaptionRating.status !== 200) throw new Error();
  } catch (error) {
    console.error(error);
  }
};

export const useGenerateCaptionRequest = (
  asset: SelectedAsset,
  orgApiKey: string
): GenerateCaptionFetchState => {
  const [generatedCaption, setGeneratedCaption] = useState<Caption>(undefined);
  const [generateError, setHasGenerateError] = useState(false);
  const [isGenerating, setIsGenerating] = useState(true);

  useEffect(() => {
    if (!acceptedFileExtensions.includes(asset.extension)) return;
    const generateCaptionRequest = async (): Promise<void> => {
      // TODO: Get this working locally
      // Cause might be related to the BE validation of bf_key & org_key
      try {
        const aiDescriptionResponse = await fetch(
          `${BFG.DATA_INSIGHTS_URL}/generative/generate_caption`,
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Accept: '*/*',
              Authorization: `Bearer ${orgApiKey}`,
            },
            body: JSON.stringify({
              asset_key: asset.key,
              bf_key: BFG.brandfolder_key,
              org_key: BFG.organization_key,
            }),
          }
        );

        if (aiDescriptionResponse.status !== 200) throw new Error();
        const response = await aiDescriptionResponse.json();
        const capitalizedDescription =
          response.data[0].caption.charAt(0).toUpperCase() +
          response.data[0].caption.slice(1);

        setGeneratedCaption({
          caption: capitalizedDescription,
          captionId: response.data[0].caption_id,
        });
      } catch (error) {
        setHasGenerateError(true);
      } finally {
        setIsGenerating(false);
      }
    };
    generateCaptionRequest();
  }, [
    asset.extension,
    asset.key,
    orgApiKey,
  ]);

  return {
    generateError,
    isGenerating,
    response: generatedCaption,
  };
};
