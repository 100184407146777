import { whitelabelPresenter } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { isGettyClient } from '@helpers/getty-strings';
import { localizeNumber } from '@helpers/locale';

import './styles/main.scss';

class CdnUsage extends React.Component {
    state = {
      ajaxCalls: [],
      loading: true,
      tracers: {}
    };

    componentDidMount() {
      this.addTabListener();
    }

    componentWillUnmount() {
      this.removeTabListener();
      this.abortAjaxCalls();
    }

    addTabListener = () => {
      window.addEventListener(
        `usageAssetTabHover${this.props.assetKey}`,
        this.requestCDNTracers,
        false
      );
    }

    removeTabListener = () => {
      window.removeEventListener(
        `usageAssetTabHover${this.props.assetKey}`,
        this.requestCDNTracers,
        false
      );
    }

    requestCDNTracers = () => {
      if (this.state.loading) {
        const request = $.ajax({
          context: this,
          type: "GET",
          url: `/api/v4/private/assets/${
            this.props.assetKey
          }/cdn_tracers`,
          timeout: 10000,
          beforeSend: (xhr) => {
            xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
          },
          success: (response) => {
            const sortedTracers = {};
            response.data.forEach((tracer, i) => {
              const referrer = tracer.attributes.referrer;
              let host;
              try {
                host = new URL(referrer).host;
              } catch {
                return;
              }

              if (!sortedTracers[host]) {
                sortedTracers[host] = [];
              }

              sortedTracers[host].push(tracer);
              if (i === response.data.length - 1) {
                this.setState({ tracers: sortedTracers });
              }
            });
            this.setState({ loading: false });
          },
          error: () => {
            // TODO Create error state
            this.setState({ loading: false });
          }
        });
        this.updateAjaxCalls(request);
      }
    }

    updateAjaxCalls(xhr) {
      this.setState((prevState) => {
        const updatedAjaxCalls = [...prevState.ajaxCalls];
        updatedAjaxCalls.push(xhr);

        return { ajaxCalls: updatedAjaxCalls };
      });
    }

    abortAjaxCalls() {
      this.state.ajaxCalls.forEach((ajaxCall) => {
        if (ajaxCall.readyState !== 4) {
          ajaxCall.abort();
        }
      });
    }

    renderCDNLinks() {
      return Object.keys(this.state.tracers).map((domain) => (
        <li key={domain} className="grid-row">
          <div className="sub-row domain">
            <a
              className="domain-url mobile-column-label"
              href={new URL(this.state.tracers[domain][0]?.attributes.referrer) ?? domain}
              rel="noopener noreferrer"
              target="_blank"
            >
              {domain}
            </a>
          </div>
          <h4 className="sub-row">
            <Trans>Pages</Trans>
          </h4>
          {this.state.tracers[domain].map((tracer) => {
            let pathName;
            try {
              pathName = new URL(tracer.attributes.referrer).pathname;
            } catch {
              return null;
            }

            // no path, only domain - already displayed above
            if (!pathName) { return null; }

            const formattedDate = moment(tracer.attributes.visited_at).format('M/DD/YYYY');
            return (
              <div key={tracer.attributes.referrer} className="cdn-item sub-row">
                <div className="link">
                  <a
                    href={`${tracer.attributes.referrer}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {pathName}
                  </a>
                </div>
                <span className="last-visited-date links-quarter-column mobile-column-label">
                  {formattedDate}
                </span>
                <div className="active-status links-quarter-column mobile-column-label">
                  <span>{localizeNumber(tracer.attributes.hit_count)}</span>
                </div>
              </div>
            );
          })}
        </li>
      ));
    }

    renderBody() {
      const { loading, tracers } = this.state;
      const platformName = whitelabelPresenter(isGettyClient() ? 'getty' : '').platformName;

      if (loading) {
        return (
          <div className="flex-two-thirds no-similar-details loader-container">
            <video
              className="brandfolder-loader"
              height="90"
              poster="https://cdn.brandfolder.io/4OQZ5PW1/as/pxmju5-qfr48-21nt37/generic-loader.gif"
              width="90"
            />
          </div>
        );
      }

      return (
        <div className="cdn-usage-wrap">
          <div className="cdn-title">
            <p>
              <Trans>CDN Links</Trans>
            </p>
            {Object.keys(tracers).length > 0
              ? (
                <p className="cdn-title__smaller">
                  <span className="indicator-circle" />
                  <Trans>CDN links viewed in the past month</Trans>
                </p>
              ) : ''
            }
          </div>
          {Object.keys(tracers).length > 0 ? (
            <ul className="usage-grid bf-scroll-element">
              <li className="grid-row grid-header">
                <h4 className="link"><Trans>Location</Trans></h4>
                <h4 className="links-quarter-column"><Trans>Last visited</Trans></h4>
                <h4 className="links-quarter-column"><Trans>Past 30 days</Trans></h4>
              </li>
              {this.renderCDNLinks()}
            </ul>
          ) : (
            <div className="no-links-container">
              <img
                alt="person digging in empty box"
                className="empty-box"
                src="https://cdn.brandfolder.io/27C9EC93/at/q6y1hf-fcrlsw-12xbfy/person-digging-in-empty-box.svg"
              />
              <h3><Trans>You don't have any CDN Links yet!</Trans></h3>
              <p>
                <Trans>CDN (Content Delivery Network) Links allow you to embed assets on external websites, while housing the single source of truth version in {platformName}.</Trans>
              </p>
              <Trans comment="bulleted list">
                <p className="list-title">With CDN Links, you can:</p>
                <ul>
                  <li>
                    <span className="bff-confirmation" />
                    Edit in {platformName} and see the changes reflected on external sites
                  </li>
                  <li>
                    <span className="bff-confirmation" />
                    Track asset usage outside of {platformName}
                  </li>
                </ul>
              </Trans>
              <br />
              {BFG.context.hasFeature('embed_asset')
                ? <p><Trans>Visit the Embed tab to create and customize your CDN links and learn more about how they work. Embedding is only available for published and approved assets.</Trans></p>
                : <p><Trans>If you have the embed assets feature turned on, visit the Embed tab to create and customize your CDN links and learn more about how they work. Embedding is only available for published and approved assets.</Trans></p>
              }
            </div>
          )}
        </div>
      );
    }

    render() {
      return (
        <I18nProviderWrapper>
          {this.renderBody()}
        </I18nProviderWrapper>
      )
    }
}

CdnUsage.propTypes = {
  assetKey: PropTypes.string.isRequired,
  libraryName: PropTypes.string.isRequired
};

export default CdnUsage;
