import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { CustomFieldKeyServer, CustomFieldKeysResponseObject } from '../resources/CustomFieldKeysTypes';
import {
  CustomFieldValuesListResponse,
  FlattenedCustomFieldKeysMap,
  FlattenedCustomFieldKeyValuesMap,
} from './customFieldTypes';

const sortOnName = (nameA: string, nameB: string): number => {
  // localeCompare sorts and accounts for nuances between languages
  // ignorePunctuation will ignore case, so that uppercase and lowercase are mixed together
  const locale = determineUGTLocale();
  return nameA.localeCompare(nameB, locale, { ignorePunctuation: true });
};

export const sortOnPrioritizedThenName = (
  a: CustomFieldKeyServer,
  b: CustomFieldKeyServer,
): number => {
  const prioritizedA = a.prioritized;
  const prioritizedB = b.prioritized;
  const nameA = a.name;
  const nameB = b.name;

  if (prioritizedA === prioritizedB) return sortOnName(nameA, nameB);
  if (prioritizedA) return -1;
  if (prioritizedB) return 1;
  return sortOnName(nameA, nameB);
};

export const sortByPosition = (
  response: CustomFieldKeysResponseObject[]
): CustomFieldKeysResponseObject[] => (
  response?.sort((a, b) => a.attributes.position - b.attributes.position)
);

export const flattenCustomFieldKeyValuesList = (response: CustomFieldValuesListResponse): FlattenedCustomFieldKeyValuesMap => {
  const keysMap: FlattenedCustomFieldKeysMap = {};
  const keyValuesMap: FlattenedCustomFieldKeyValuesMap = {};
  if (response.included) {
    response.included.forEach(({ id, attributes }) => { keysMap[id] = attributes; });
  }
  response.data.forEach(({ id, attributes, relationships }) => {
    const customFieldKeyId = relationships?.custom_field_key?.data?.id;
    if (customFieldKeyId) {
      keyValuesMap[customFieldKeyId] = {
        ...keyValuesMap[customFieldKeyId] || {
          customFieldKey: {
            id: customFieldKeyId,
            ...keysMap[customFieldKeyId],
          }
        },
        customFieldValues: [
          ...keyValuesMap?.[customFieldKeyId]?.customFieldValues || [],
          {
            key: id,
            value: attributes.value
          }
        ]
      };
    }
  });

  const mapKeys = Object.keys(keyValuesMap);
  const allHavePositions = mapKeys.every((key) => keyValuesMap[key].customFieldKey.position != null);
  const sortedKeys = mapKeys.sort((a, b) => {
    if (allHavePositions) {
      return (
        keyValuesMap[a].customFieldKey.position -
        keyValuesMap[b].customFieldKey.position
      );
    }
    return sortOnPrioritizedThenName(keyValuesMap[a].customFieldKey, keyValuesMap[b].customFieldKey);
  });

  const sortedKeyValuesMap = {};
  sortedKeys.forEach((key) => {
    sortedKeyValuesMap[key] = {
      ...keyValuesMap[key],
      // sort values alphabetically
      customFieldValues: keyValuesMap[key].customFieldValues.sort((a, b) => sortOnName(a.value, b.value))
    };
  });
  return sortedKeyValuesMap;
};
