import { t } from '@lingui/macro';

export const isGettyClient = (): boolean => (
  BFG.getty_client === 'true'
);

export const getAssetLibraryTitle = (): string => (
  isGettyClient() ? t`Library` : t`Brandfolder`
);

export const getAssetLibraryTitlePlural = (): string => (
  isGettyClient() ? t`Libraries` : t`Brandfolders`
);
