import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import getAssetAttachment from '@api/v4/assets/attachments';
import ClipboardCopy from '@components/common/clipboard_copy/main';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import AttachmentSidebar from '../shared/attachment_sidebar/main';
import VisibleAttachment from '../shared/visible_attachment/main';

import MetadataRow from './MetadataRow';
import './styles/main.scss';

class MetadataTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
      visibleAttachment: null,
      loading: true,
    };

    this.itpcMetadata = ["creator", "description", "headline", "keywords", "title"];

    this.activeRequests = [];
  }

  /* NOTE :: When you have a parent modal component that grabs the asset info
  you won't need to grab the attachments on each tab, you can grab them when
  you load the modal and then just pass them down. Attachments will also no
  longer need to be stored in state when this happens */
  componentDidMount() {
    let params = {
      fields: "best_metadata,other_metadata,thumbnail_url,extension,extracted_colors,best_guess_background_color,extract_document_text"
    };

    params = BFG.manifestDigest ? { ...params, digest: BFG.manifestDigest } : params;
    params = this.props.watermarkResourceKey ? { ...params, watermark_resource_key: this.props.watermarkResourceKey } : params;

    getAssetAttachment({
      assetKey: this.props.assetId,
      params
    }, this.updateFetchController).then((response) => {
      const sortedAttachments = response.data.sort((a, b) => (
        a.attributes.position > b.attributes.position ? 1 : -1
      ));
      this.setState({
        attachments: sortedAttachments,
        loading: false,
        visibleAttachment: response.data[0],
      });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.abortFetchControllers();
  }

  updateActiveImage = ({ id: attachmentId }) => {
    if (this.state.visibleAttachment.id !== attachmentId) {
      this.setState((prevState) => {
        const visibleAttachment = prevState.attachments.find((attachment) => (
          attachment.id === attachmentId
        ));
        return { visibleAttachment };
      });
    }
  }

  searchMetadata = (objectKey, objectValue) => {
    const searchTerm = `metadata.${objectKey}:"${objectValue}"`;
    BF.dialog.dismiss();
    BF.fx.fillInAndFilterQuery(searchTerm);
  }

  updateFetchControllers = (fetchController) => {
    this.activeRequests = [...this.activeRequests, fetchController];
  };

  abortFetchControllers = () => {
    this.activeRequests.forEach((fetchController) => {
      if (!fetchController.signal.aborted) {
        fetchController.abort();
      }
    });
  };

  downloadTextTranscription = () => {
    const transcription = this.state.visibleAttachment.attributes.other_metadata.transcription;
    const element = document.createElement("a");
    const file = new Blob([transcription], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "video_transcription.txt";
    document.body.appendChild(element);
    element.click();
  }

  matchMetadata = (metadataKey) => (
    BFG.context.hasFeature('editable_metadata') && (['collaborator', 'admin', 'owner'].indexOf(BFG.currentUser.role) > -1) && this.itpcMetadata.includes(metadataKey)
  );

  renderColorBreakdown() {
    const colorArray = this.state.visibleAttachment.attributes.extracted_colors;

    return colorArray.map((color, i) => this.renderColorBlock(color, i));
  }

  renderColorBlock = (color, i) => {
    const colorStyle = {};
    colorStyle.background = color.color ? color.color.hex_color : color;
    const keyIndex = i || 'background';
    return (
      <div
        key={`color-bar-element-${keyIndex}-${colorStyle.background}`}
        className="color-breakdown-element"
      >
        <div
          className="color-block"
          style={{ background: colorStyle.background }}
        />
        <ClipboardCopy
          check
          showFeedbackAsTooltip={false}
          textToCopy={colorStyle.background}
        >
          {colorStyle.background}
        </ClipboardCopy>
      </div>
    );
  }

  renderMetadata = (passed_metadata, type) => {
    const { orgSearchPage } = this.props;

    // clickable via button
    const metadataShowPage = (metadataKey, metadataValue) => (
      <MetadataRow
        key={`${this.state.visibleAttachment.id}-${metadataKey}`}
        attachmentKey={this.state.visibleAttachment.id}
        initialMetadataValue={metadataValue}
        isITPC={this.matchMetadata(metadataKey)}
        metadataKey={metadataKey}
        searchMetadata={this.searchMetadata}
      />
    );

    // clickable via anchor tag
    const metadataOrgSearch = (metadataKey, metadataValue) => {
      const orgSlug = window.location.pathname.split("organizations/")[1].split("/search")[0];

      return (
        <span>
          <a href={`/organizations/${orgSlug}/search?q=metadata.${metadataKey}:"${encodeURI(metadataValue)}"`}>
            {metadataValue}
          </a>
        </span>
      );
    };

    return Object.keys(passed_metadata).map((metadataKey) => {
      const objectValue = passed_metadata[metadataKey];
      // Removing file_size of 0 bytes for when exiftool can't get the size due to buffering
      // It's confusing and scary for the end user, so we're removing it
      // https://exiftool.org/forum/index.php?topic=13605.0;topicseen
      if (metadataKey == 'file_size' && objectValue == '0 bytes') {
        return
      }
      return (
        <div
          key={`best-meta-${metadataKey}`}
          className={`meta-row ${this.itpcMetadata.includes(metadataKey) ? 'align-center' : ''}`}
        >
          <span> {metadataKey}: </span>
          {type !== 'best' || BFG.showPageLite ? <span> {objectValue} </span> : null}
          {type === 'best' && !BFG.showPageLite && !orgSearchPage ? metadataShowPage(metadataKey, objectValue) : null}
          {type === 'best' && orgSearchPage ? metadataOrgSearch(metadataKey, objectValue) : null}
        </div>
      );
    });
  }

  render() {
    const { attachments, loading, visibleAttachment } = this.state;
    const best_metadata = visibleAttachment?.attributes?.best_metadata;
    const other_metadata = visibleAttachment?.attributes?.other_metadata;
    const activeCard = (visibleAttachment && Object.keys(visibleAttachment).length)
      ? { id: visibleAttachment.id, type: 'attachment' }
      : null;

    return (
      <I18nProviderWrapper>
        <div className="flex-wrapper metadata-tab-container">
          <AttachmentSidebar
            activeCard={activeCard}
            attachments={attachments.length ? attachments : null}
            updateActiveCard={this.updateActiveImage}
          />
          {loading ? (
            <div className="flex-two-thirds no-similar-details loader-container">
              <video
                className="brandfolder-loader"
                height="90"
                poster="https://cdn.brandfolder.io/4OQZ5PW1/as/pxmju5-qfr48-21nt37/generic-loader.gif"
                width="90"
              />
            </div>
          ) : (
            <div className="modal-asset-two-thirds flex-two-thirds metadata-details bf-scroll-element">
              {visibleAttachment && visibleAttachment.attributes
                ? (
                  <>
                    <VisibleAttachment
                      extension={visibleAttachment.attributes.extension}
                      filename={visibleAttachment.attributes.filename}
                      height={visibleAttachment.attributes.height}
                      size={visibleAttachment.attributes.size}
                      thumbnail={visibleAttachment.attributes.thumbnail_url}
                      width={visibleAttachment.attributes.width}
                    />
                    {/* We don"t have this yet
                    <div className="attachment-tags">
                      <label>Tags</label>
                    </div>
                    <hr />
                      */}
                    <div className="attachment-colors">
                      <p><Trans>Extracted color palette</Trans></p>
                      <div className="color-breakdown">
                        {visibleAttachment.attributes.extracted_colors
                          ? this.renderColorBreakdown()
                          : <p className="brandfolder-label"><Trans>No colors extracted</Trans></p>}
                      </div>
                      <div className="background-color-breakdown">
                        <p><Trans>Background color</Trans></p>
                        <div className="color-breakdown">
                          {visibleAttachment.attributes.best_guess_background_color
                            ? this.renderColorBlock(visibleAttachment.attributes.best_guess_background_color)
                            : <p className="brandfolder-label"><Trans>No background color extracted</Trans></p>}
                        </div>
                      </div>
                    </div>
                    {BFG.context.hasFeature('document_search') && visibleAttachment.attributes.extract_document_text
                    && visibleAttachment.attributes.extract_document_text.length > 0
                      ? (
                        <>
                          <hr />
                          <div className="attachment-extracted-text">
                            <p className="brandfolder-label"><Trans>Text from attachment</Trans></p>
                            <div className="attachment-metadata-box">
                              {visibleAttachment.attributes.extract_document_text}
                            </div>
                          </div>
                        </>
                      )
                      : ''}
                    {((best_metadata && Object.keys(best_metadata).length > 0) || (other_metadata && Object.keys(other_metadata).length > 0)) && (
                      <>
                        <hr />
                        <div className="attachment-metadata">
                          <p><Trans>Metadata</Trans></p>
                          <div className="attachment-metadata-box">
                            {(best_metadata && Object.keys(best_metadata).length > 0) && this.renderMetadata(best_metadata, "best")}
                            {(other_metadata && Object.keys(other_metadata).length > 0) && this.renderMetadata(other_metadata, "other")}
                          </div>
                          {BFG.context.hasFeature('intelligent_video_speech_transcription') && this.state.visibleAttachment.attributes.other_metadata?.transcription && (
                            <button
                              className="transcription-button button primary sm"
                              onClick={(() => this.downloadTextTranscription())}
                              type="button"
                            >
                              <Trans>Download Transcription</Trans>
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : ''
              }
            </div>
          )}
        </div>
      </I18nProviderWrapper>
    );
  }
}

MetadataTab.propTypes = {
  assetId: PropTypes.string.isRequired,
  orgSearchPage: PropTypes.bool,
  watermarkResourceKey: PropTypes.string,
};

MetadataTab.defaultProps = {
  orgSearchPage: false,
  watermarkResourceKey: undefined,
};

export default MetadataTab;
