import { t, Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React, { useState } from "react";

const FontInput = ({ frame, fontSettings, updateFontSize, templateSettings, denyTextReplacement = false }) => {
  const [warningDelivered, updatedWarningDelivered] = useState(false);

  const originalFontSize = () => {
    try {
      return fontSettings[frame.id].size;
    } catch (err) {
      return null;
    }
  };

  const validateFontSize = (fontSize) => {
    if (!warningDelivered && fontSize && originalFontSize() && (fontSize > originalFontSize())) {
      Notify.makeAll('dormant');
      Notify.create({
        type: "error",
        title: t`Font size larger than original`,
        body: t`If text disappears, shrink font size.`
      });
      updatedWarningDelivered(true);
    }
  };

  const handleFontChange = (fontSize) => {
    validateFontSize(parseInt(fontSize, 10));
    updateFontSize(fontSize);
  };

  if (templateSettings.allowFontSizing && !denyTextReplacement) {
    return (
      <div className="font-size-input">
        <label className="bf-label bf-label--primary" htmlFor={`${frame.id}-fontSize`}>
          <Trans>Font Size (px)</Trans>
        </label>
        <input
          defaultValue={originalFontSize()}
          onChange={(e) => handleFontChange(e.target.value)}
          type="number"
        />
      </div>
    );
  }

  return null;
};

FontInput.propTypes = {
  // eslint-disable-next-line react/require-default-props
  denyTextReplacement: PropTypes.bool,
  frame: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  updateFontSize: PropTypes.func.isRequired,
  fontSettings: PropTypes.shape({}).isRequired,
  templateSettings: PropTypes.shape({
    allowFontSizing: PropTypes.bool
  }).isRequired,
};

export default FontInput;
