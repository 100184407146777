import { t } from '@lingui/macro';

import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { createAssets, updateAsset } from '@api/v4/assets/assets';
import { AssetResponse, AssetsResponseObject } from '@api/v4/assets/assetTypes';
import {
  CreateCustomFieldParams,
  CustomFieldValue,
  CustomFieldValueData,
} from '@api/v4/assets/customFieldTypes';
import updateAttachment, { deleteAttachment } from '@api/v4/attachments';
import { AttachmentResponseObject } from '@api/v4/attachments/attachmentTypes';
import bulkTag, { BulkTagResponse } from '@api/v4/bulk_actions/tag';
import { deleteCustomFieldValue, updateCustomFieldValue } from '@api/v4/custom-field-values';
import { createCustomFieldValues } from '@api/v4/custom_fields/custom-field-keys';
import { createCustomFieldKey } from '@api/v4/resources/custom_fields';
import { TaskServerResponseObject } from '@api/v4/tasks';
import { createTask, updateTask } from '@api/v4/tasks/tasks';
import { Locale } from '@components/common/language_menu/languagesMap';
import { WorkspaceEvents } from '@components/workspace/WorkspaceEnums';
import { WorkspaceUpdated } from '@components/workspace/WorkspaceTypes';

import { AssetChanges } from './change_detection/assetChangeEngine';
import { UpdatedAttachmentData } from './change_detection/attachmentChangeEngine';
import {
  CreatedCustomFieldKeyValuePair,
  CreatedCustomFieldValuesMap
} from './change_detection/custom-fields-change-detection';
import { SubmitTagsData } from './change_detection/tagChangeEngine';
import { TaskChanges } from './change_detection/taskChangeEngine';
import { FlatCustomFieldMaps, Submit } from './EditTabTypes';

type DeleteAttachmentData = string;

export interface SubmissionData {
  action: Submit;
  data:
  | CreateCustomFieldParams[]
  | CreatedCustomFieldKeyValuePair
  | CreatedCustomFieldValuesMap
  | CustomFieldValue
  | DeleteAttachmentData
  | FlatCustomFieldMaps
  | Partial<AssetChanges>
  | Partial<TaskChanges>
  | SubmitTagsData
  | UpdatedAttachmentData;
}

interface CreateAssetData {
  assetChanges: Partial<AssetChanges>;
  section_key: string;
}

interface SubmissionResult<T> {
  action: Submit;
  success: boolean;
  results?: T;
}

type SubmissionPromise<T> = Promise<SubmissionResult<T>>;

type SubmitFailure = Submit;

interface SubmitSuccess {
  action: Submit;
  results: any;
}

export interface SubmitData {
  failures: SubmitFailure[] | null;
  successes: SubmitSuccess[];
  assetKey?: string;
}

const createAssetAsync = async (
  { assetChanges, section_key }: CreateAssetData,
  updateFetchControllers: () => void,
  labelKey?: string
): Promise<SubmissionResult<AssetsResponseObject>> => {
  let url = '/api/v4/';
  switch (BFG.resource.type) {
    case 'collection':
      url += `collections/${BFG.resource.key}/assets`;
      break;
    case 'brandfolder':
    default:
      url += `brandfolders/${BFG.resource.key}/assets`;
      break;
  }
  const options = {
    url,
    section_key,
    data: {
      attributes: [
        {
          ...assetChanges,
          ...labelKey && { labels: [labelKey] }
        }
      ]
    }
  };

  try {
    const results = await createAssets(options);
    return {
      action: Submit.UpdatedAsset,
      success: true,
      results: results.data[0]
    };
  } catch (e) {
    return {
      action: Submit.UpdatedAsset,
      success: false
    };
  }
};

const createTaskAsync = async (
  assetName: string,
  sectionKey: string,
  taskChanges: Partial<TaskChanges>,
  ugtLocale: Locale | ''
): Promise<SubmissionResult<TaskServerResponseObject>> => {
  const submissionResult: SubmissionResult<TaskServerResponseObject> = {
    action: Submit.NewTask,
    success: false
  };

  try {
    const results = await createTask(assetName, sectionKey, taskChanges, ugtLocale, BFG.resource.key);

    return {
      ...submissionResult,
      results: results.data[0],
      success: true
    };
  } catch (err) {
    return submissionResult;
  }
};

const submitAssetUpdate = async (
  assetKey: string,
  assetChanges: Partial<AssetChanges>,
  ugtLocale: Locale | '',
): Promise<SubmissionResult<AssetResponse>> => {
  const options = {
    assetKey,
    attributes: assetChanges,
    params: { ugt_locale: ugtLocale },
  };
  try {
    const results = await updateAsset(options);
    return {
      action: Submit.UpdatedAsset,
      success: true,
      results
    };
  } catch (e) {
    return {
      action: Submit.UpdatedAsset,
      success: false
    };
  }
};

const submitTaskUpdate = async (
  taskChanges: Partial<TaskChanges>,
  taskKey: string,
  ugtLocale: Locale | ''
): Promise<SubmissionResult<TaskServerResponseObject>> => {
  const submissionResult: SubmissionResult<TaskServerResponseObject> = {
    action: Submit.UpdatedTask,
    success: false
  };

  try {
    const results = await updateTask(taskChanges.data, taskKey, ugtLocale);

    // update the workspace dashbar counts and percentages
    const taskUpdatedEvent = new CustomEvent<WorkspaceUpdated>(WorkspaceEvents.WorkspaceUpdated, {
      detail: {
        refresh: true
      }
    });
    window.dispatchEvent(taskUpdatedEvent);

    return {
      ...submissionResult,
      results: results.data,
      success: true
    };
  } catch (err) {
    return submissionResult;
  }
};

const submitNewCustomFieldKeyValuePair = async (
  assetKey: string,
  createdCustomFieldKeyValuePair: CreatedCustomFieldKeyValuePair,
  ugtLocale: Locale | ''
): Promise<SubmissionResult<unknown>> => {
  try {
    const keyResults = await createCustomFieldKey(
      BFG.resource.type as 'brandfolder' | 'collection',
      BFG.resource.key,
      [createdCustomFieldKeyValuePair.customFieldKey.name],
      ugtLocale
    );

    const updatedKeyId = keyResults.data?.[0]?.id;
    if (!updatedKeyId) {
      return {
        action: Submit.NewCustomFieldKeyValuePair,
        success: false
      };
    }
    await createCustomFieldValues(
      [{ value: createdCustomFieldKeyValuePair.customFieldValue.value, assetKey }],
      updatedKeyId,
      ugtLocale
    );

    /**
     * Supporting partial success state: would need to fetch custom field keys again here
     * to update in the initialData state. We are choosing not to support the partial success state
     * for custom fields at this time to decrease scope. We will address if it becomes a problem for customers.
    */

    return {
      action: Submit.NewCustomFieldKeyValuePair,
      success: true,
      results: {
        oldCustomFieldKeyId: createdCustomFieldKeyValuePair.customFieldKey.id,
        newCustomFieldKeyId: updatedKeyId,
        oldCustomFieldValueId: createdCustomFieldKeyValuePair.customFieldValue.key,
      }
    };
  } catch (e) {
    // just catch either failure, if a user fails to create a custom field value,
    // it's unlikely that they would then succeed on deleting the new custom field key
    // so just treat either failure as one
    return {
      action: Submit.NewCustomFieldKeyValuePair,
      success: false
    };
  }
};

const submitNewCustomFieldValues = async (
  assetKey: string,
  createdCustomFieldValues: CreatedCustomFieldValuesMap,
  ugtLocale: Locale | ''
): Promise<SubmissionResult<unknown>> => {
  try {
    const createCustomFieldValuesRequests = [];
    Object.keys(createdCustomFieldValues).forEach((customFieldKeyId) => {
      const valuesWithAssetKey = createdCustomFieldValues[customFieldKeyId].map((cfv) => ({
        value: cfv.value,
        assetKey
      }));
      const createPromise = createCustomFieldValues(valuesWithAssetKey, customFieldKeyId, ugtLocale);
      createCustomFieldValuesRequests.push(createPromise);
    });

    await Promise.all(createCustomFieldValuesRequests);

    return {
      action: Submit.NewCustomFieldValues,
      success: true,
    };
  } catch (e) {
    return {
      action: Submit.NewCustomFieldValues,
      success: false
    };
  }
};

const submitUpdatedCustomFieldValue = async (
  customFieldValue: CustomFieldValue,
  ugtLocale: Locale | ''
): Promise<SubmissionResult<CustomFieldValueData>> => {
  try {
    const results = await updateCustomFieldValue(customFieldValue, ugtLocale);
    return {
      action: Submit.UpdatedCustomFieldValue,
      success: true,
      results
    };
  } catch (e) {
    return {
      action: Submit.UpdatedCustomFieldValue,
      success: false
    };
  }
};

const submitTags = async (
  assetKey: string,
  { initialTags, editedTags }: SubmitTagsData,
  updateFetchControllers: () => void,
  ugtLocale: Locale | '',
): Promise<SubmissionResult<BulkTagResponse>> => {
  const data = {
    asset_keys: [assetKey],
    bulk_tag: {
      existing_tag_names_array: initialTags.map((tag) => tag.name),
      submitted_tag_names_array: editedTags.map((tag) => tag.name)
    }
  };
  try {
    await bulkTag(data, updateFetchControllers, ugtLocale);
    return {
      action: Submit.Tags,
      success: true,
    };
  } catch (e) {
    return {
      action: Submit.Tags,
      success: false
    };
  }
};

const submitUpdateAttachment = async (
  { key, url, filename, position }: UpdatedAttachmentData
): Promise<SubmissionResult<ApiDatumResponse<AttachmentResponseObject>>> => {
  try {
    const results = await updateAttachment({ attachmentKey: key, data: { attributes: { url, filename, position } } });
    return {
      action: Submit.UpdatedAttachment,
      success: true,
      results
    };
  } catch (e) {
    return {
      action: Submit.UpdatedAttachment,
      success: false
    };
  }
};

const submitDeleteCustomFieldValue = async (key: string, ugtLocale: Locale | ''): Promise<SubmissionResult<string>> => {
  try {
    await deleteCustomFieldValue(key, ugtLocale);
    return {
      action: Submit.DeletedCustomFieldValue,
      success: true,
      results: key
    };
  } catch (e) {
    if (e?.message?.includes("Value can't be blank")) {
      Notify.create({
        type: 'error',
        title: t`There was an error deleting a Custom Field Value. There may be an issue due to the translation of this value in a non-default locale. If you wish to remove the value completely, ensure that all translations are removed first.`
      });
    }
    return {
      action: Submit.DeletedCustomFieldValue,
      success: false
    };
  }
};

const submitDeleteAttachment = async (attachmentKey: string): Promise<SubmissionResult<string>> => {
  try {
    await deleteAttachment(attachmentKey);
    return {
      action: Submit.DeletedAttachment,
      success: true,
      results: attachmentKey
    };
  } catch (e) {
    return {
      action: Submit.DeletedAttachment,
      success: false
    };
  }
};

const submit = async (
  assetKey: string,
  updateFetchControllers: () => void,
  requiredSubmissions: SubmissionData[],
  ugtLocale: Locale | '',
  section_key?: string,
  labelKey?: string,
  isTask?: boolean,
  taskKey?: string
): Promise<SubmitData> => {
  const isCreate = assetKey === '';
  let reliableAssetKey = assetKey;
  let reliableTaskKey = taskKey;
  let remainingSubmissions = [...requiredSubmissions];

  const submissionMap = {
    [Submit.UpdatedAsset]:
      (data: Partial<AssetChanges>): SubmissionPromise<AssetResponse> => submitAssetUpdate(reliableAssetKey, data, ugtLocale),
    [Submit.UpdatedTask]:
      (data: Partial<TaskChanges>): SubmissionPromise<TaskServerResponseObject> => submitTaskUpdate(data, reliableTaskKey, ugtLocale),
    [Submit.NewCustomFieldKeyValuePair]:
      (data: CreatedCustomFieldKeyValuePair): SubmissionPromise<unknown> => submitNewCustomFieldKeyValuePair(reliableAssetKey, data, ugtLocale),
    [Submit.NewCustomFieldValues]:
      (data: CreatedCustomFieldValuesMap): SubmissionPromise<unknown> => submitNewCustomFieldValues(reliableAssetKey, data, ugtLocale),
    [Submit.UpdatedCustomFieldValue]:
      (data: CustomFieldValue): SubmissionPromise<CustomFieldValueData> => submitUpdatedCustomFieldValue(data, ugtLocale),
    [Submit.DeletedCustomFieldValue]:
      (data: string): SubmissionPromise<string> => submitDeleteCustomFieldValue(data, ugtLocale),
    [Submit.Tags]:
      (data: SubmitTagsData): SubmissionPromise<BulkTagResponse> => submitTags(reliableAssetKey, data, updateFetchControllers, ugtLocale),
    [Submit.DeletedAttachment]:
      (data: DeleteAttachmentData): SubmissionPromise<string> => submitDeleteAttachment(data),
    [Submit.UpdatedAttachment]:
      (data: UpdatedAttachmentData): SubmissionPromise<ApiDatumResponse<AttachmentResponseObject>> => submitUpdateAttachment(data)
  };

  let reliableAssetKeyPromise: Promise<void> = new Promise((resolve) => resolve());
  const submissionErrors: SubmitFailure[] = [];
  const successResults: SubmitSuccess[] = [];

  if (isCreate) {
    const assetChanges = remainingSubmissions.find((s) => s.action === Submit.UpdatedAsset).data as Partial<AssetChanges>;
    const updateAssetActionIndex = remainingSubmissions.findIndex((s) => s.action === Submit.UpdatedAsset);

    if (!isTask) {
      remainingSubmissions.splice(updateAssetActionIndex, 1);

      reliableAssetKeyPromise = createAssetAsync(
        { assetChanges, section_key },
        updateFetchControllers,
        labelKey
      ).then((submissionResponse) => {
        if (submissionResponse.success) {
          reliableAssetKey = submissionResponse.results.id;
          successResults.push({ action: submissionResponse.action, results: submissionResponse.results });
        } else {
          submissionErrors.push(Submit.UpdatedAsset);
        }
      });
    } else {
      const taskChanges = remainingSubmissions.find((s) => s.action === Submit.UpdatedTask).data as Partial<TaskChanges>;
      const updateTaskActionIndex = remainingSubmissions.findIndex((s) => s.action === Submit.UpdatedTask);
      remainingSubmissions.splice(updateTaskActionIndex, 1);

      // remove assetName change from remaining submissions
      if (Object.keys(assetChanges).length === 1) {
        remainingSubmissions.splice(updateAssetActionIndex, 1);
      } else {
        const updatedAssetChanges = { ...remainingSubmissions.find((s) => s.action === Submit.UpdatedAsset) } as SubmissionData;
        const updatedAssetChangesData = { ...updatedAssetChanges.data as AssetChanges };
        delete updatedAssetChangesData.name; // remove the assetName update so it's not submitted as a change later
        delete updatedAssetChangesData.attachments; // attachments are handled by createTask/createTaskAsync

        // if by deleting both assetName and attachments there's nothing left, just remove the remainingSubmission
        if (Object.keys(updatedAssetChangesData).length === 0) {
          remainingSubmissions.splice(updateAssetActionIndex, 1);
        } else {
          updatedAssetChanges.data = updatedAssetChangesData;
          remainingSubmissions.splice(updateAssetActionIndex, 1, updatedAssetChanges);
        }
      }

      reliableAssetKeyPromise = createTaskAsync(assetChanges.name, section_key, taskChanges, ugtLocale).then((submissionResponse) => {
        if (submissionResponse.success) {
          reliableAssetKey = submissionResponse.results.attributes.asset_key;
          reliableTaskKey = submissionResponse.results.id;

          successResults.push({ action: submissionResponse.action, results: submissionResponse.results });

          // update the workspace dashbar counts and percentages
          const taskCreatedEvent = new CustomEvent<WorkspaceUpdated>(WorkspaceEvents.WorkspaceUpdated, {
            detail: {
              refresh: true
            }
          });
          window.dispatchEvent(taskCreatedEvent);
        } else {
          submissionErrors.push(Submit.UpdatedTask);
        }
      });
    }
  }

  if (submissionErrors.length > 0) {
    // asset creation has failed, let's not try to submit anything else
    return {
      successes: successResults,
      failures: submissionErrors,
      assetKey: reliableAssetKey
    };
  }

  const handleAttachmentDeleteSubmission = async (): Promise<void> => {
    const deleteAttachmentSubmissions = remainingSubmissions.filter(({ action }) => action === Submit.DeletedAttachment);
    if (deleteAttachmentSubmissions.length === 0) return;

    const deleteRequests = (): SubmissionPromise<string>[] => deleteAttachmentSubmissions.map(({ data }) => (
      submissionMap[Submit.DeletedAttachment](data as DeleteAttachmentData)
    ));
    const deleteAttachmentResults = await Promise.all(deleteRequests());
    deleteAttachmentResults.forEach((deleteAttachmentResult) => {
      if (deleteAttachmentResult.success) {
        const { action, results } = deleteAttachmentResult;
        successResults.push({ action, results });
      } else {
        submissionErrors.push(deleteAttachmentResult.action);
      }
    });
    remainingSubmissions = remainingSubmissions.filter(({ action }) => action !== Submit.DeletedAttachment);
  };

  const handleAssetUpdateSubmission = async (): Promise<void> => {
    const updateAssetSubmission = remainingSubmissions.find(({ action }) => action === Submit.UpdatedAsset);
    if (!updateAssetSubmission) return;

    const updateAssetResult = await submissionMap[Submit.UpdatedAsset](updateAssetSubmission.data as Partial<AssetChanges>);
    if (updateAssetResult.success) {
      const { action, results } = updateAssetResult;
      successResults.push({ action, results });
    } else {
      submissionErrors.push(updateAssetResult.action);
    }
    remainingSubmissions = remainingSubmissions.filter(({ action }) => action !== Submit.UpdatedAsset);
  };

  const handleAttachmentUpdateSubmission = async (): Promise<void> => {
    const updateAttachmentSubmissions = remainingSubmissions.filter(({ action }) => action === Submit.UpdatedAttachment);
    if (updateAttachmentSubmissions.length === 0) return;

    for (let i = 0; i < updateAttachmentSubmissions.length; i += 1) {
      const { data } = updateAttachmentSubmissions[i];
      // eslint-disable-next-line no-await-in-loop
      const updateAttachmentResult = await submissionMap[Submit.UpdatedAttachment](data as UpdatedAttachmentData);
      if (updateAttachmentResult.success) {
        const { action, results } = updateAttachmentResult;
        successResults.push({ action, results });
      } else {
        submissionErrors.push(updateAttachmentResult.action);
      }
    }

    remainingSubmissions = remainingSubmissions.filter(({ action }) => action !== Submit.UpdatedAttachment);
  };

  const handleOrderedSubmissions = async (): Promise<void> => {
    if (!remainingSubmissions.find(({ action }) => action === Submit.UpdatedAttachment)) return;

    await handleAttachmentDeleteSubmission();
    // we could do an early return here if there were any errors
    // and not even try any other submissions but with the messaging that returns for errors
    // which implies that some changes were unsuccessful implies that the positioning of attachments could be saved incorrectly
    // so I think it's fair to try and submit the other things and live with the fallout of slightly off attachment positioning
    await handleAssetUpdateSubmission();
    await handleAttachmentUpdateSubmission();
  };

  const requests = (): Promise<any>[] => (
    remainingSubmissions.map(({ action, data }) => submissionMap[action](data))
  );

  // eslint-disable-next-line no-useless-catch
  try {
    await reliableAssetKeyPromise;
    await handleOrderedSubmissions();

    const submissionResults = await Promise.all(requests());
    submissionResults.forEach((submissionResult) => {
      if (submissionResult.success) {
        successResults.push({ action: submissionResult.action, results: submissionResult.results });
      } else {
        submissionErrors.push(submissionResult.action);
      }
    });

    return {
      successes: successResults,
      failures: submissionErrors,
      assetKey: reliableAssetKey
    };
  } catch (e) {
    throw e;
  }
};

export default submit;
