import React, { useEffect, useRef, useState } from 'react';

import { AssetsResponseObject } from '@api/v4/assets/assetTypes';
import { TertiaryIconButton } from '@components/library/button/index';
import { WidgetsContext } from './widget'; // eslint-disable-line import/no-cycle

export const TopScoredAssets: React.FunctionComponent = () => {
  const header = useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    setHeaderHeight(header.current?.offsetHeight || 0);
  }, [headerHeight]);

  const renderAssetListItem = (asset: AssetsResponseObject): JSX.Element => (
    <li key={asset.id} className="widgets__list--item">
      <span className="widgets__list--item-score">
        {asset.attributes.score}<span className="bff-score-meter" />
      </span>
      <a href={asset.attributes.best_link_for}>{asset.attributes.name}</a>
    </li>
  );

  return (
    <WidgetsContext.Consumer>
      {(widgetsContext): JSX.Element => widgetsContext && (
        <div className="widgets-third">
          <div ref={header} className={`widgets__header ${widgetsContext.editMode === true ? 'editing' : ''}`}>
            <h3>{widgetsContext.name}</h3>
            {widgetsContext.editMode && (
              <TertiaryIconButton
                className="widgets__edit-settings-button card-button"
                height={50}
                icon="bff-edit"
                onClick={widgetsContext.toggleForm}
                width={50}
              />
            )}
          </div>
          <ul className="widgets__list bf-scroll-element" style={{ maxHeight: `calc(700px - ${headerHeight}px)` }}>
            {(widgetsContext.widgetDataResponse && Array.isArray(widgetsContext.widgetDataResponse.data)) && widgetsContext.widgetDataResponse.data.map(
              (asset: AssetsResponseObject) => renderAssetListItem(asset)
            )}
          </ul>
        </div>
      )}
    </WidgetsContext.Consumer>
  );
};
