import React, { FunctionComponent } from 'react';

import { SelectorTypes } from '@components/bulk_management/automation/AutomationEnums';
import { FontIcon, FontIcons } from '@components/library/icon';

interface FieldSummaryIconProps {
  fieldType: SelectorTypes
}

export const FieldSummaryIcon: FunctionComponent<FieldSummaryIconProps> = ({ fieldType }) => {
  switch (fieldType) {
    case SelectorTypes.Collection:
      return <FontIcon aria-hidden icon={FontIcons.GridView} />;
    case SelectorTypes.CustomField:
      return <FontIcon aria-hidden icon={FontIcons.CustomField} />;
    case SelectorTypes.Label:
      return <FontIcon aria-hidden icon={FontIcons.Label} />;
    case SelectorTypes.Section:
      return <FontIcon aria-hidden icon={FontIcons.Section} />;
    case SelectorTypes.Tag:
      return <FontIcon aria-hidden icon={FontIcons.Tag} />;
    case SelectorTypes.Watermark:
      return <FontIcon aria-hidden icon={FontIcons.Watermark} />;
    default:
      return null;
  }
};