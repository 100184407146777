import { ButtonLooks, FontIcons, IconButton } from '@brandfolder/react';
import React, { FunctionComponent, ReactElement, useContext } from 'react';

import {
  AssetTypes,
  ExternalMediumData,
  FontData,
  PressData
} from '@api/v4/assets/assetTypes';
import { getColorUrl } from '@components/asset/modal/tabs/asset_details/helpers/colors';
import { getFontUrl } from '@components/asset/modal/tabs/asset_details/helpers/font';
import { Sizes, StandardCheckbox } from '@components/library/checkbox';
import { ViewOnlyIcon } from '@components/view-only/ViewOnlyIcon';

import { default as copyToClipboard } from '@helpers/copy_to_clipboard.js';

import { ViewOnlyContext } from './GalleryView';
import { AssetViewModel } from './model';
import classes from './styles/attachment-gallery.module.scss';

export interface GalleryCardProps {
  asset: AssetViewModel;
  attachmentId?: string;
  showOverlay: boolean;
  handleExpandedViewSelection?: (
    asset: AssetViewModel,
    attachmentId?: string | null
  ) => void;
  handleSelection?: (id: string) => void;
  isSelected?: boolean;
}

export interface ColorDataProperties {
  b: string;
  c: string;
  g: string;
  hex: string;
  k: string;
  m: string;
  pantone: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  pantone_u: string;
  r: string;
  y: string;
}

export const GalleryCardOverlay: FunctionComponent<GalleryCardProps> = ({
  asset,
  attachmentId,
  showOverlay,
  handleExpandedViewSelection,
  handleSelection,
  isSelected,
}) => {
  const downloadLink = (): string => {
    if (!BFG.resource) return '';
    const digestParam = BFG.manifest
      ? `&manifest_digest=${BFG.manifest.digest}`
      : '';
    // eslint-disable-next-line max-len
    return `/${BFG.resource.slug}/attachments/${attachmentId}?dl=true&resource_key=${BFG.resource.key}&resource_type=${BFG.resource.type}${digestParam}`;
  };

  const trackAttachmentDownload = (): void => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const options = {
      resource_type: BFG.resource?.type,
      resource_key: BFG.resource?.key,
    };
    Insight.createEvent('downloaded', asset.id, 'asset', options);
    if (BFG.manifest?.type === 'share_manifest') {
      // ensures that download event is tracked on share manifest
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Insight.createEvent('downloaded', asset.id, 'asset', {
        resource_type: 'share_manifest',
        resource_key: BFG.manifest.key,
      }); // eslint-disable-line max-len
    }
  };

  const handleDownload = (): void => {
    trackAttachmentDownload();
    window.location.href = downloadLink();
  };

  const handleLinkToUrl = (url: string): void => {
    window.open(url, '_blank', 'noreferrer');
  };

  const handleCopyToClipboard = (text: string): void => {
    copyToClipboard(text);
  };

  const handleGallerySelection = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    if (event.target === event.currentTarget) {
      handleExpandedViewSelection(asset, attachmentId);
    }
  };

  const renderColorData = (): ReactElement => {
    const colorData = asset.attributes.asset_data as ColorDataProperties;
    const hex = `#${colorData.hex}`;
    const rgb = `R${colorData.r}, G${colorData.g}, B${colorData.b}`;

    return (
      <div
        className={classes.colorDataGradientContainer}
        onClick={(e): void => handleGallerySelection(e)}
      >
        <p className={classes.colorDataText}>
          {asset.attributes.name}
          {' - '}
          {hex}
          {' - '}
          {rgb}
        </p>
      </div>
    );
  };

  const renderIconButton = (
    icon: FontIcons,
    buttonAction,
    content?: string
  ): ReactElement => {
    return (
      <IconButton
        className={classes.overlayActionButton}
        icon={icon}
        iconProps={{
          iconSize: 20,
        }}
        label="Icon Button"
        loaderLabel="Loading"
        look={ButtonLooks.White}
        onClick={content ? (): void => buttonAction(content) : buttonAction}
        showIconButton
        type="button"
      />
    );
  };

  const isShareManifestViewOnly = useContext(ViewOnlyContext);
  const isDownloadable =
    asset.type === AssetTypes.GenericFile &&
    asset.attributes.view_only === false;

  const renderOverlayAction = (): ReactElement => {
    switch (asset.type) {
      case AssetTypes.ExternalMedium:
        const externalMediumData = asset.attributes
          .asset_data as ExternalMediumData;
        return renderIconButton(
          FontIcons.Link,
          handleLinkToUrl,
          externalMediumData.url
        );
      case AssetTypes.Font:
        const fontData = asset.attributes.asset_data as FontData;
        const fontName = asset.attributes.name
          .replace(/\s+/g, '+')
          .toLowerCase();
        return renderIconButton(
          FontIcons.Link,
          handleLinkToUrl,
          getFontUrl(fontData, fontName)
        );
      case AssetTypes.Press:
        const pressData = asset.attributes.asset_data as PressData;
        return renderIconButton(FontIcons.Link, handleLinkToUrl, pressData.url);
      case AssetTypes.Color:
        const colorData = asset.attributes.asset_data as ColorDataProperties;
        return renderIconButton(
          FontIcons.Link,
          handleLinkToUrl,
          getColorUrl(colorData)
        );
      case AssetTypes.Text:
        return renderIconButton(
          FontIcons.Copy,
          handleCopyToClipboard,
          asset.attributes.description.replace(/(<([^>]+)>)/gi, '')
        );
      case AssetTypes.GenericFile:
        if (!isDownloadable) {
          return <ViewOnlyIcon />;
        }
        if (!isShareManifestViewOnly) {
          return renderIconButton(FontIcons.Download, handleDownload);
        }
    }
  };

  return (
    <div
      className={classes.galleryCardOverlay}
      data-testid="gallery-card-overlay"
      onClick={
        handleExpandedViewSelection
          ? (e): void => handleGallerySelection(e)
          : undefined
      }
      role="button"
      tabIndex={0}
    >
      <div className={classes.galleryCardOverlayLeft}>
        {handleSelection && (
          <StandardCheckbox
            checked={isSelected}
            className="column-checkbox column-3"
            onChange={(): void => handleSelection(asset.id)}
            size={Sizes.Medium}
          />
        )}
      </div>
      <div className={classes.galleryCardOverlayRight}>
        {showOverlay && renderOverlayAction()}
      </div>
      {asset.type === AssetTypes.Color && renderColorData()}
    </div>
  );
};
