import { t, Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { postReservations } from '@api/v4/attachments/reservations';
import SubmitButton from '@components/asset/modal/tabs/workflow/attachment_details/checkin_checkout/submit_button';
import { updateReservations } from '@components/asset/modal/tabs/workflow/helper';
import { isGettyClient } from '@helpers/getty-strings';
import { getMomentLocale } from '@helpers/locale';

const CheckInCheckOut = ({
  activeAttachment,
  activeAttachmentReservations,
  asset,
  initializeTab,
  setAttachmentReservations,
  setVersionsActionLoading,
  updateFetchControllers,
  versionCard,
  versionsActionLoading,
}) => {
  const [textareaValue, setTextareaValue] = useState('');
  const textareaValueRef = useRef(textareaValue);

  const { filename } = versionCard.version_details;
  const { currentStatus } = activeAttachmentReservations;

  const reservationsOptions = {
    attachmentKey: activeAttachment.id,
    updatedStatus: currentStatus === 'checked_in' ? 'checked_out' : 'checked_in',
    params: { include: 'user' }
  };

  useEffect(() => {
    textareaValueRef.current = textareaValue;
  }, [textareaValue]);

  const handleToggleCheckinCheckout = () => {
    setVersionsActionLoading(true);
    postReservations(
      { ...reservationsOptions, checkinCheckoutNote: textareaValueRef.current },
      updateFetchControllers
    ).then((response) => {
      setVersionsActionLoading(false);
      setTextareaValue('');
      if (response && !response.ok) {
        setAttachmentReservations((prevState) => (
          updateReservations(response, prevState, activeAttachment.id)
        ));
        if (currentStatus === 'checked_out') {
          initializeTab();
        }
      }
    })
    .catch((err) => {
      setVersionsActionLoading(false);
      console.log(err);
    });
  };

  const displayCheckoutContent = () => (
    <>
      <h3 className="title">
        <Trans>
          Check Out <span className="filename">{filename}</span>
        </Trans>
      </h3>
      <p className="copy">
        {/* using t over trans when I would use an HTML entity */}
        {t`To update or make edits to this attachment outside of Brandfolder, download it via the "Check Out" button below.`}
      </p>
      <h4 className="textarea-title">
        {t`(Optional) Why are you checking out this attachment?`}
      </h4>
    </>
  );

  const displayCheckinContent = () => {
    const reservationsSorted = [...activeAttachmentReservations.history].sort((a, b) => (
      new Date(b.created_at) - new Date(a.created_at)
    ));
    const latestReservation = reservationsSorted[0];
    const latestAuthor = latestReservation.author;
    const latestUpdate = latestReservation.created_at;
    const latestEmail = activeAttachmentReservations.users[latestAuthor].email;
    const isLatestUser = BFG.currentUser.user_id === latestAuthor;
    // TODO: implement version to replace moment in design system with Intl.RelativeTimeFormat
    const timeString = moment(latestUpdate).locale(getMomentLocale()).fromNow();

    return (
      <>
        <h3 className="title">
          <Trans>Check In <span className="filename">{filename}</span></Trans>
        </h3>
        {isLatestUser ? (
          <>
            <p className="copy">
              <Trans>You checked this attachment out {timeString}.</Trans>
            </p>
            <p className="copy">
              {
                isGettyClient()
                  ? t`To check this attachment back in to Media Manager, upload it via the "Check In" button below.`
                  : t`To check this attachment back in to Brandfolder, upload it via the "Check In" button below.`
              }
            </p>
          </>
        ) : (
          <>
            <p className="copy">
              <Trans>
                <a href={`mailto:${latestEmail}`}>{latestEmail}</a>{' '}
                checked this attachment out {timeString}. Please follow up with them to retrieve the most current version.
              </Trans>
            </p>
            <p className="copy">
              {t`If you’d like to check this attachment back in yourself, upload it via the "Check In" button below.`}
            </p>
          </>
        )}
        <h4 className="textarea-title">
          {t`What changes have been made to this attachment? (optional)`}
        </h4>
      </>
    );
  };

  return (
    <div className="checkin-checkout">
      <div className="checkin-checkout__outer-container bf-scroll-element">
        <div className="checkin-checkout__container">
          {currentStatus === 'checked_in'
            ? displayCheckoutContent()
            : displayCheckinContent()
          }
          <textarea
            className="bf-textarea message-textarea"
            onChange={(e) => setTextareaValue(e.target.value)}
            value={textareaValue}
          />
          <SubmitButton
            activeAttachment={activeAttachment}
            asset={asset}
            checkedInStatus={currentStatus}
            handleToggleCheckinCheckout={handleToggleCheckinCheckout}
            setVersionsActionLoading={setVersionsActionLoading}
            versionCard={versionCard}
            versionsActionLoading={versionsActionLoading}
          />
          {currentStatus === 'checked_out' && (
            <div className="checkin-without-upload">
              <p className="copy">
                <Trans>Do you want to check in this attachment without uploading a new file?</Trans>
              </p>
              <p className="copy">
                <button
                  className="checkin-without-upload__button"
                  onClick={handleToggleCheckinCheckout}
                  type="button"
                >
                  {t`Mark as 'Checked In'`}
                </button>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CheckInCheckOut.propTypes = {
  activeAttachment: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  activeAttachmentReservations: PropTypes.shape({
    currentStatus: PropTypes.string,
    history: PropTypes.arrayOf(PropTypes.shape({})),
    users: PropTypes.shape({}),
  }).isRequired,
  asset: PropTypes.shape({
    asset_key: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  initializeTab: PropTypes.func.isRequired,
  setAttachmentReservations: PropTypes.func.isRequired,
  setVersionsActionLoading: PropTypes.func.isRequired,
  updateFetchControllers: PropTypes.func.isRequired,
  versionCard: PropTypes.shape({
    version_details: PropTypes.shape({
      url: PropTypes.string,
      filename: PropTypes.string,
      metadata: PropTypes.shape({
        mime_type: PropTypes.string,
      }),
    })
  }).isRequired,
  versionsActionLoading: PropTypes.bool.isRequired,
};

export default CheckInCheckOut;
