import fetchJSON from '@api/api_helper';

const getUrl = (orgKey) => `/api/v4/organizations/${orgKey}/printers`;

export const get = (options, updateFetchControllers) => fetchJSON(getUrl(options.organizationKey), options, updateFetchControllers)
  .then((response) => {
    if (!response.data) { throw new Error(); }

    const printers = [];

    response.data.forEach((printer) => {
      if (printer.type === 'printers') {
        const { name, emails } = printer.attributes;

        printers.push({ label: name, value: emails, key: printer.id });
      }
    });

    return printers;
  }).catch((err) => err);
