import fetchJSON from '@api/api_helper';
import { ApiDataResponse } from '@api/v4/ApiResponseTypes';
import { Resource, ResourceTypes } from '@api/v4/resources/resourceTypes';
import { AssetTypesSingular } from '@api/v4/assets/assetTypes';
import { UserRoles } from '@api/v4/UserTypes';

interface SectionAttributes {
  name: string;
}

interface GetSectionsOptions {
  params: {
    include: string;
    minimum_permission_level: UserRoles;
    per: number;
    section_type: AssetTypesSingular;
  };
}

export type SectionsResponseJSON = ApiDataResponse<SectionAttributes, ResourceTypes.Sections, Resource, ResourceTypes>
export interface SectionsResponse {
  json: SectionsResponseJSON;
  response: Response;
}

export const getSections = (options: GetSectionsOptions): Promise<SectionsResponse> => (
  fetchJSON(
    '/api/v4/private/permissions/sections',
    options,
    null,
    true, // get full response so we can read statuses
  )
    .then((response: SectionsResponse) => response)
    .catch((response) => { throw response; })
);
