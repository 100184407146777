import { getArraysHasIntersection } from '@helpers/arrays';

interface HasSomeViewOnlySelectedOptions {
  selectedAssetKeys: Set<string>;
  selectedViewOnlyAssetKeys: Set<string>;
}

export const hasSomeViewOnlySelected = ({
  selectedAssetKeys,
  selectedViewOnlyAssetKeys
}: HasSomeViewOnlySelectedOptions): boolean => {
  const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');

  return viewOnlyAssetsEnabled
    && selectedViewOnlyAssetKeys.size > 0
    && getArraysHasIntersection(Array.from(selectedViewOnlyAssetKeys), Array.from(selectedAssetKeys));
};
