/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ListDropdown } from '@components/library/dropdown';

const PrinterSettings = ({ handleChange, printerSettings, printerSettingsPopulated }) => {
  const [dropdownOption, setDropdownOption] = useState({});
  if (!printerSettings) {
    return (
      <h5 className="not-found-text">
        {/* TODO: don't rely on support-link class for spacing */}
        <Trans>
          No printer settings available, contact
          <a className="support-link" href={`mailto:${BFG.supportEmail}`}>support</a>
          to setup printer settings
        </Trans>
      </h5>
    );
  }

  return printerSettings.map(({ copy, data, id, size, type }) => {
    if (type === 'input') {
      return (
        <React.Fragment key={id}>
          <label className="brandfolder-label" htmlFor={id}>{copy}
            <input
              className={`brandfolder-input ${size} ${id}`}
              defaultValue={printerSettingsPopulated[id] || ''}
              id={id}
              onChange={(e) => handleChange(id, e.target.value)}
              required
            />
          </label>
          { id === 'quantity' ? <div className="form-placeholder-div" /> : null }
        </React.Fragment>
      );
    }

    if (type === 'textarea') {
      return (
        <label key={id} className="brandfolder-label label-textarea" htmlFor={id}>{copy}
          <textarea
            className={`brandfolder-textarea ${size} ${id}`}
            defaultValue={printerSettingsPopulated[id] || ''}
            id={id}
            onChange={(e) => handleChange(id, e.target.value)}
            required
          />
        </label>
      );
    }

    if (type === 'text') {
      return <p key={id} className="form-text">{copy}</p>;
    }

    if (type === 'dropdown') {
      const options = data.map((option) => ({ label: option, value: option }));

      if (options.length > 0) {
        if (!printerSettingsPopulated[id]) {
          handleChange(id, options[0].value); // initialize first option in state
        }

        return (
          <label key={id} className="brandfolder-label dropdown-label" htmlFor={id}>
            {copy}
            <ListDropdown
              className={`${id}-dropdown ${size}`}
              onChange={(selection) => {
                handleChange(id, selection ? selection.value : null);
                setDropdownOption((prev) => ({
                  ...prev,
                  [id]: selection ? selection.value : null
                }));
              }}
              options={options}
              searchable
              value={dropdownOption[id] || options[0]}
              virtualizeOptions={false}
            />
          </label>
        );
      }
    }

    return null;
  });
};

PrinterSettings.propTypes = {
  handleChange: PropTypes.func.isRequired,
  printerSettings: PropTypes.arrayOf(PropTypes.shape({
    copy: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    order: PropTypes.number,
    size: PropTypes.string,
  })),
  printerSettingsPopulated: PropTypes.shape({}),
};

PrinterSettings.defaultProps = {
  printerSettings: null,
};

export default PrinterSettings;
