import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes } from 'react';

import { CloseButton } from '@components/library/button';

import classes from '../styles/dialog_header.module.scss';

interface DialogHeaderProps extends HTMLAttributes<HTMLDivElement> {
    /** required for accessibility, this should match the id of the dialog component */
    id: string;
    /** required for accessibility */
    title: string;
    handleClose?: () => void;
    /** Defaults to true. Do NOT hide the close button if possible. Hiding it breaks accessibility rules. */
    showClose?: boolean;
    /** defualts to true, setting false will visually hide the title with css but will allow access via screen readers */
    showTitle?: boolean;
    titleIcon?: string;
}

/**
 * Dialog header
 * @param {DialogHeaderProps} props DialogHeaderProps
 */
export const DialogHeader: FunctionComponent<DialogHeaderProps> = (props) => {
  const { children, handleClose, id, showClose = true, showTitle = true, title, titleIcon, ...divProps } = props;

  return (
    <div {...divProps} className={classnames("dialog--header", classes["dialog--header"], divProps.className)}>
      <div
        className={classnames("dialog--title", classes["dialog--title"])}
      >
        {/* NOTE: the first DOM element of the dialog ALWAYS needs to be the heading element for proper announcment/voice over by screen readers */}
        <h2
          className={classnames(
            "dialog--heading",
            classes["dialog--heading"],
            !showTitle && classes["dialog--heading__hidden"]
          )}
          id={id}
        >
          {title}
        </h2>
        {children}
        {/* More about handling icon fonts accessibly: https://www.w3.org/WAI/GL/wiki/Providing_a_Semantically_Identified_Icon_Font_with_role%3Dimg */}
        {titleIcon && (
          <span
            aria-hidden="true"
            className={classnames("dialog--icon", titleIcon, classes["dialog--icon"])}
            role="img"
          />
        )}
      </div>
      {showClose && (
        <CloseButton
          aria-label="Close"
          className={classnames("dialog--close", classes["dialog--close"])}
          onClick={(): void => handleClose()}
        />
      )}
    </div>
  );
};
