import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTHeadProps } from '../BaseTableProps';

export const BaseTHead: FunctionComponent<BaseTHeadProps> = (props) => {
  const { children, className, ...otherProps } = props;
  return (
    <thead {...otherProps} className={classnames('baseThead', className)}>{children}</thead>
  );
};
