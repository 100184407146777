import {
  ButtonLooks,
  CloseButton,
  FontIcon,
  FontIcons,
  ShimmerLoader,
  StandardButton,
  brandfolderColorPalette,
} from '@brandfolder/react';
import { Trans, t } from '@lingui/macro';
import React, {
  FunctionComponent,
  useEffect,
} from 'react';

import styled, { keyframes } from 'styled-components';

import { ToastLooks, useCreateToast } from '@components/common/toast';

import { CaptionFeedbackControls } from './CaptionFeedbackControls';
import { useGenerateCaptionRequest } from './fetch';
import { SelectedAsset } from './generateCaptionTypes';

export const shimmer = keyframes`
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
`;

const GenerativeAiFeatureWrapper = styled.div`
  border: 1px solid ${brandfolderColorPalette.neutrals[500]};
  border-radius: 8px;
  padding: 16px;

  hr {
    margin: 16px 0;
  }

  .bf-loader__shimmer--br {
    animation: ${shimmer}  2s linear infinite;
    background: linear-gradient(135deg, #6486FF 0%, #A07EFF 79.04%);
    background-size: calc(100% + 1000px) 100%;
    border-radius: 12px;
    height: 20px;
    opacity: 0.15000000596046448;
    width: 50%;
    -webkit-animation: ${shimmer}  2s linear infinite;

    &:not(:last-child) {
      margin-bottom: 16px;
      width: 100%;
    }
  }

  .header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    &-text {
      color: ${brandfolderColorPalette.neutrals[400]};
      display: flex;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;
      
      h6 {
        font-weight: 700;
        margin-left: 8px;
      }
    }

    .bf-button__close {
      padding: 0;
    
      &:hover {
        background-color: transparent;
      }
    
      .bff-close {
        color: ${brandfolderColorPalette.neutrals[700]};
        font-size: 12px;
      }
    }
  }

  .caption-rating-text,
  .generated-caption {
    font-size: 14px;
    font-weight: 450;
    line-height: 20px;
  }

  .footer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .caption-actions {
      .bf-button__standard {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        min-height: unset;
        padding: 10px 12px;

        &:not(:last-child) {
          margin-right: 12px;
        }
      }

      .append-button {
        border-color: ${brandfolderColorPalette.neutrals[300]};

        &:hover {
          background-color: ${brandfolderColorPalette.neutrals[100]};
          color: ${brandfolderColorPalette.neutrals[700]};
        }
      }
    }
  }
`;

export interface GenerativeAiImageCaptionsProps {
  asset: SelectedAsset;
  closeCaption: () => void;
  orgApiKey: string;
  updateDescription: (description: string, captionId: string) => void;
  currentDescription?: string;
}
export const GenerativeAiImageCaptions: FunctionComponent<GenerativeAiImageCaptionsProps> = ({
  asset,
  closeCaption,
  orgApiKey,
  updateDescription,
  currentDescription = '',
}) => {
  const {
    isGenerating,
    generateError,
    response,
  } = useGenerateCaptionRequest(asset, orgApiKey);
  const { toast } = useCreateToast();

  useEffect(() => {
    if (generateError) {
      closeCaption();
      toast(
        t`Something went wrong. Please try again.`,
        ToastLooks.Error
      );
    }
  }, [generateError, closeCaption]);

  const handleUpdateDescription = (description: string): void => {
    updateDescription(description, response.captionId);
    closeCaption();
  };

  return (
    <GenerativeAiFeatureWrapper>
      {isGenerating && <ShimmerLoader numLines={2} />}
      {response && (
        <>
          <div className="header">
            <div className="header-text">
              <FontIcon icon={FontIcons.SparkleFilled} />
              <h6>
                <Trans>Suggested description</Trans>
              </h6>
            </div>
            <CloseButton
              aria-label="close"
              onClick={closeCaption}
            />
          </div>
          <p className="generated-caption">{response.caption}</p>
          <hr />
          <div className="footer">
            <CaptionFeedbackControls
              captionId={response.captionId}
              orgApiKey={orgApiKey}
            />
            <div className="caption-actions">
              {!!currentDescription ? (
                <>
                  <StandardButton
                    className="append-button"
                    look={ButtonLooks.Tertiary}
                    onClick={(): void => {
                      handleUpdateDescription(currentDescription.concat(`<p>${response.caption}</p>`));
                    }}
                  >
                    <Trans>Append</Trans>
                  </StandardButton>
                  <StandardButton
                    onClick={(): void => {
                      handleUpdateDescription(response.caption);
                    }}
                  >
                    <Trans>Replace</Trans>
                  </StandardButton>
                </>
              ) : (
                <StandardButton
                  onClick={(): void => {
                    handleUpdateDescription(response.caption);
                  }}
                >
                  <Trans>Insert</Trans>
                </StandardButton>
              )}
            </div>
          </div>
        </>
      )}
    </GenerativeAiFeatureWrapper>
  );
};
