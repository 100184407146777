/* eslint-disable @typescript-eslint/naming-convention */
import {
  ButtonLink,
  ButtonLooks,
  FontIcon,
  FontIcons,
  StandardButton,
  StandardTable,
  StandardTableColumn,
  StandardText
} from '@brandfolder/react';
import { Trans, t } from '@lingui/macro';
import { Result } from '@uppy/transloadit';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
  ReactNode,
} from 'react';

import { fetchWithPolling, fetchJson } from '@api/ApiHelper';
import { CsvActionableTypesEnum, CsvContextsEnum } from '@api/v4/private/resources/csv';
import { CsvUploadServer, getCsvUploads } from '@api/v4/private/resources/csv/uploads';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { defaultKnowledgeBaseLinks, gettyKnowledgeBaseLinks } from '@components/kb-article-links';
import { FontIconColors } from '@components/library/icon';
import { delay } from '@components/library/utils';
import { localizeNumber } from '@helpers/locale';
import { localizeDateTime } from '@helpers/localize';
import { isGettyClient } from '@helpers/getty-strings';

import './styles/add-dependent-fields-view.scss';

interface DcfText {
  content?: string;
  heading?: string;
  back?: string;
  download?: string;
  upload?: string;
  learnMore?: string;
}

export const addDcfText = (): { [key: string]: DcfText } => ({
  step1: {
    heading: t`step 1`,
    content: t`Download a CSV template of your current custom field keys and values.`
  },
  step2: {
    heading: t`step 2`,
    // eslint-disable-next-line max-len
    content: t`Add dependent custom fields to existing values by listing them in the dependent custom fields column in the CSV template. If you’re adding multiple dependent custom fields to a value, separate each with a semicolon.`
  },
  step3: {
    heading: t`step 3`,
    content: t`Upload your updated CSV.`
  },
  help: {
    heading: t`Need help getting started?`,
    content: t`We'll walk you through the template and how to add dependent custom fields.`
  },
  buttons: {
    back: t`Back`,
    download: t`Download CSV`,
    upload: t`Click to upload a CSV`
  },
  links: {
    learnMore: t`Learn more`
  }
});

let knowledgeBaseUrl;

if (isGettyClient()) {
  knowledgeBaseUrl = gettyKnowledgeBaseLinks.dependentCustomFieldsLink
} else {
  knowledgeBaseUrl = defaultKnowledgeBaseLinks.dependentCustomFieldsLink
};

const AddDependentFieldsStep = ({ heading, content }): ReactElement => (
  <>
    <StandardText className="step-heading">{heading}</StandardText>
    <StandardText className="step-info">{content}</StandardText>
  </>
);

const downloadFile = (href: string): void => {
  const element = document.createElement('a');
  element.setAttribute('download', 'true');
  element.setAttribute('href', href);
  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

interface CsvDownloadJob {
  download_key: string;
}

interface CsvDownloadResponse {
  download_url: string
}

interface CsvUploadJob {
  upload_key: string;
}

interface CsvUploadJob {
  upload_key: string;
}

export interface AddDependentFieldsViewProps {
  handleRemoveVisibility: () => void;
  isVisible: boolean;
}

export const AddDependentFieldsView: FunctionComponent<AddDependentFieldsViewProps> =
  ({ handleRemoveVisibility, isVisible }) => {
    const [csvDownloadUrl, setCsvDownloadUrl] = useState('');
    const [csvUploads, setCsvUploads] = useState<CsvUploadServer[]>([]);
    const [isPolling, setIsPolling] = useState(false);
    const bearerToken = BFG.BF_Token;
    const resourceKey = BFG.resource?.key;
    const dcfText = addDcfText();
    const tableColumns: StandardTableColumn[] = [
      {
        children: <Trans>Upload date</Trans>,
        rowKey: 'uploadDate'
      },
      {
        children: <Trans>Uploaded by</Trans>,
        rowKey: 'uploadedBy'
      },
      {
        children: <Trans>Filename</Trans>,
        ellipsis: true,
        maxWidth: 300,
        rowKey: 'filename'
      },
      {
        children: <Trans>Succeeded</Trans>,
        rowKey: 'succeeded',
        tdClassName: 'column-align-right'
      },
      {
        centered: true,
        children: <Trans>Invalid</Trans>,
        rowKey: 'invalid',
        tdClassName: 'column-align-right'
      },
      {
        centered: true,
        children: <Trans>Errors</Trans>,
        rowKey: 'errors',
        tdClassName: 'column-align-right'
      },
      {
        centered: true,
        children: <Trans>Total</Trans>,
        rowKey: 'total',
        tdClassName: 'column-align-right'
      },
      {
        children: <Trans>Status</Trans>,
        rowKey: 'status',
        tdClassName: 'column-status',
      }
    ];
    const fetchCsvUploads = async (): Promise<void> => {
      const { data } = await getCsvUploads({
        actionableType: CsvActionableTypesEnum.DependentCustomField,
        context: CsvContextsEnum.DependentCustomFields,
        locale: determineUGTLocale(),
        resourceType: 'brandfolder'
      });
      const allCsvUploads = data?.map(({ attributes, id }) => ({ ...attributes, id })) || [];
      setCsvUploads(allCsvUploads);
    };

    useEffect(() => {
      if (isVisible) {
        fetchCsvUploads();
      }
    }, [isVisible]);

    useEffect(() => {
      if (csvDownloadUrl) {
        downloadFile(csvDownloadUrl);
      }
    }, [csvDownloadUrl]);

    const validateCsvDownloadUrlResponse = (response: CsvDownloadResponse): boolean =>
      !!response?.download_url;

    const getCsvDownload = async (): Promise<void> => {
      try {
        setIsPolling(true);

        const { download_key } = await fetchJson<CsvDownloadJob>({
          params: {
            resource_type: 'brandfolder',
            resource_key: resourceKey,
          },
          method: 'POST',
          url: `/${BFG.resource.slug}/dependent_custom_fields/csv/download`,
          headers: { Authorization: 'Bearer ' + bearerToken },
          ignoreStatusCondition: true
        });

        // GET via polling to see if the csv download url is ready
        const { polledData } = await fetchWithPolling<CsvDownloadResponse>(
          () =>
            fetchJson({
              url: `/${BFG.resource.slug}/dependent_custom_fields/csv/download_status`,
              params: {
                download_key,
                resource_type: 'brandfolder',
                resource_key: resourceKey,
              },
              method: 'POST',
              headers: { Authorization: 'Bearer ' + bearerToken },
            }),
          validateCsvDownloadUrlResponse,
          3 * 1000, // poll every 3 seconds
          20 * 1000, // poll for 20 seconds
        );
        setCsvDownloadUrl(polledData.download_url);
      } catch (err) {
        Notify.create({
          title: t`An error occurred while attempting to download the CSV.`,
          type: 'error',
        });
      } finally {
        setIsPolling(false);
      }
    };

    const getCsvUpload = async (files: Result[]): Promise<void> => {
      if (files?.length) {
        try {
          await fetchJson<CsvUploadJob>({
            body: {
              csv_url: files[0].url,
              resource_type: 'brandfolder',
              resource_key: BFG.resource?.key,
            },
            method: 'POST',
            url: `/${BFG.resource.slug}/dependent_custom_fields/csv/upload`,
            headers: { Authorization: 'Bearer ' + bearerToken },
          });

          Notify.create({
            title: t`CSV Uploaded`,
            body: t`It may take a few moments for your changes to be reflected.`,
            type: 'success'
          });

          // give the job a couple of seconds before refreshing the list
          await delay(2000);

          fetchCsvUploads();
        } catch (err) {
          Notify.create({
            title: t`Error uploading your CSV. Please try again.`,
            type: 'error'
          });
        }
      }
    };

    const renderRowFailuresDownloadLink = (failureCount: number, failuresDownloadUrl: string): ReactNode => (
      <ButtonLink
        className="view-row-failures-link"
        download
        href={failuresDownloadUrl}
        look={ButtonLooks.Default}
      >
        {failureCount}
      </ButtonLink>
    );

    const rows = csvUploads?.map((csv) => ({
      uploadDate: localizeDateTime(csv.created_at),
      uploadedBy: csv.creator_email,
      filename: csv.filename,
      succeeded: localizeNumber(csv.succeeded_count),
      invalid: !!csv.validation_failure_count ?
        renderRowFailuresDownloadLink(
          localizeNumber(csv.validation_failure_count),
          csv.validation_failure_download_url
        ) : localizeNumber(csv.validation_failure_count),
      errors: localizeNumber(csv.failed_count),
      total: localizeNumber(csv.total_count),
      status: csv.status
    }));

    const uppyId = 'uppy-upload-trigger';
    return isVisible ? (
      <div className="add-dependent-fields-view">
        <StandardButton
          className="back-button"
          look={ButtonLooks.Default}
          onClick={handleRemoveVisibility}
          startIcon={FontIcons.ArrowLeft}
          startIconProps={{ color: FontIconColors.Primary, iconSize: 10 }}
        >
          {dcfText.buttons.back}
        </StandardButton>
        <div className="add-dependent-fields-view-content">
          <div className="add-dcf-steps">
            <div className="step">
              <AddDependentFieldsStep
                content={dcfText.step1.content}
                heading={dcfText.step1.heading}
              />
              <StandardButton
                className="download-csv-button"
                loading={isPolling}
                look={ButtonLooks.Default}
                onClick={getCsvDownload} // eslint-disable-line @typescript-eslint/no-misused-promises
                startIcon={FontIcons.Download}
                startIconProps={{ color: FontIconColors.Primary, iconSize: 12 }}
              >
                {dcfText.buttons.download}
              </StandardButton>
            </div>
            <div className="step">
              <AddDependentFieldsStep
                content={dcfText.step2.content}
                heading={dcfText.step2.heading}
              />
            </div>
            <div className="step">
              <AddDependentFieldsStep
                content={dcfText.step3.content}
                heading={dcfText.step3.heading}
              />
              <div className="uppy-csv-area" id={uppyId}>
                <span className="bff-plus" />
                <p>{dcfText.buttons.upload}</p>
              </div>
              <UppyUploader
                button
                handleUpload={(files): void => { getCsvUpload(files); }}
                restrictions={{
                  // 100mb: file size bumped up for getty
                  maxFileSize: 100000000,
                  maxNumberOfFiles: 1,
                  allowedFileTypes: ['.csv']
                }}
                template="file_ingest"
                trigger={`#${uppyId}`}
                uniqIdentifier={`dcf-uppy-${BFG.resource.key}`}
              />
            </div>
          </div>
          <div className="help-card">
            <FontIcon
              color={FontIconColors.Primary}
              icon={FontIcons.LightbulbFilled}
              iconSize={28}
            />
            <div className="help-card-text">
              <span>{dcfText.help.heading}</span>
              <StandardText>
                {dcfText.help.content}
              </StandardText>
              <ButtonLink
                className="learn-more-button"
                href={knowledgeBaseUrl}
                look={ButtonLooks.Default}
                target="_blank"
              >
                {dcfText.links.learnMore}
              </ButtonLink>
            </div>
          </div>
          <hr />
          <StandardTable
            caption={t`Dependent custom fields upload table`}
            columns={tableColumns}
            id='dcf-upload-table'
            rows={rows}
            scrollY
          />
        </div>
      </div >
    ) : null;
  };
