import { t } from '@lingui/macro';

import { deletePortalCard } from '@api/v4/private/portal_cards';

const submitCardDelete = ((
  portalCardId: string,
  closeModal: () => void,
  triggerCardsRefresh: () => void
): void => {
  deletePortalCard(portalCardId)
    .then(() => {
      triggerCardsRefresh();
      closeModal();
    })
    .catch(() => {
      Notify.create({
        title: t`Something went wrong!`,
        body: t`Please try deleting again.`,
        type: 'error'
      });
      closeModal();
    });
});

export const handleDeletion = (
  portalCardId: string,
  name: string,
  closeModal: () => void,
  triggerCardsRefresh: () => void
): void => {
  const swalOptions = {
    title: t`Are you sure you want to delete ${name}?`,
    text: t`This action can not be undone.`,
    type: 'warning',
    showCancelButton: true,
    cancelButtonText: t`Cancel`,
    confirmButtonText: t`Delete`,
    closeOnConfirm: true,
  };
  window.swal(swalOptions, (confirmSelected) => {
    if (confirmSelected) {
      submitCardDelete(portalCardId, closeModal, triggerCardsRefresh);
    } else {
      closeModal();
    }
  });
};
