import { ApiDataResponse, ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { Section } from '@api/v4/brandfolders/SectionTypes';
import { CollectionServer } from '@api/v4/collections';

export interface GcsIngestSource {
  active?: boolean;
  ftp_permission?: boolean; // eslint-disable-line @typescript-eslint/naming-convention
  rootdir?: string;
  type?: GcsIngestSourceType,
}

export interface SftpIngestSource extends GcsIngestSource {
  type?: GcsIngestSourceType.SFTP;
}

export enum GcsIngestSourceType {
  GCS = 'GcsIngest',
  FTP = 'FtpIngest',
  SFTP = 'SftpIngest',
}

export type GcsIngestSourceResponse = ApiDatumResponse<GcsIngestSource, 'gcs_ingest_sources'>;
export type SftpIngestSourceListResponse = ApiDataResponse<GcsIngestSource, 'sftp_ingests', CollectionServer | Section, 'collections' | 'sections'>;