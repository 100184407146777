import React from 'react';
import PropTypes from 'prop-types';

const Annotation = ({ x, y, onClick, number }) => (
  <button
    type="button"
    className="annotation"
    style={{
      top: `${Math.round(y * 100)}%`,
      left: `${Math.round(x * 100)}%`,
    }}
    onClick={(event) => {
      event.stopPropagation();
      onClick();
    }}
  >
    {number}
  </button>
);

Annotation.propTypes = {
  x: PropTypes.string.isRequired,
  y: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  number: PropTypes.number,
};

Annotation.defaultProps = {
  onClick: () => {},
  number: 1,
};

export default Annotation;
