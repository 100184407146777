import fetchJSON from '@api/api_helper';
import { fetchJson } from '@api/ApiHelper';
import { ApiDataResponse } from '@api/v4/ApiResponseTypes';
import { CustomFieldKeyServer, CustomFieldKeysListResponse, CustomFieldKeysResponseObject } from '@api/v4/resources/CustomFieldKeysTypes';
import { Locale } from '@components/common/language_menu/languagesMap';

const customFieldKeysUrl = (resourceType: string, resourceKey: string): string => `/api/v4/${resourceType}s/${resourceKey}/custom_field_keys`;

// TODO create typed param object
export const getCustomFieldKeys = ({ params = {}, resourceType, resourceKey }): Promise<CustomFieldKeysListResponse> => (
  fetchJSON(customFieldKeysUrl(resourceType, resourceKey), { params })
    .then((response) => response)
    .catch(async (response) => { throw (await response.json()); })
);

export const submitNewCustomFields = ({
  attributes,
  resourceType,
  resourceKey,
  ugtLocale = ''
}): Promise<CustomFieldKeysResponseObject[]> => (
  fetchJSON(customFieldKeysUrl(resourceType, resourceKey), {
    method: 'POST',
    body: {
      ugt_locale: ugtLocale || undefined,
      data: {
        attributes
      }
    }
  })
    .then(({ data }) => data)
    .catch(async (response) => { throw (await response.json()); })
);

export const createCustomFieldKey = (
  resourceType: 'brandfolder' | 'collection',
  resourceKey: string,
  customFieldKeyNames: string[],
  ugtLocale?: Locale | ''
): Promise<ApiDataResponse<CustomFieldKeyServer, 'custom_field_keys'>> => {
  const ugtLocaleParamString = ugtLocale ? `?ugt_locale=${ugtLocale}` : '';
  const url = `${customFieldKeysUrl(resourceType, resourceKey)}${ugtLocaleParamString}`;
  return fetchJson({
    url,
    method: 'POST',
    body: {
      data: {
        attributes: customFieldKeyNames.map((name) => ({ name })),
      }
    }
  });
};
