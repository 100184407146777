import * as React from 'react';

import Label from '@components/library/labels/PrimaryLabel';
import Input from '@components/library/inputs/PrimaryInput';
import { capitalize } from '@components/library/utils';
import { InputType, InputAttributes } from '../inputs/Input.props';
import { InputError } from './InputError';
import { InputContainerProps } from './InputContainer.props';
import { Container } from './Container';

import './base_styles.scss';

const defaultAttributeProps: Partial<InputAttributes> = {
  type: InputType.Text
};

const InputContainer = (props: InputContainerProps): JSX.Element => {
  const fullProps = {
    ...props,
    attributes: {
      ...defaultAttributeProps,
      ...props.attributes
    }
  };

  const {
    attributes,
    options,
    selectValue,
    children,
    input,
    labelCopy,
    submitAttempted,
    validationRules,
    className,
    id
  } = fullProps;

  const inputId = attributes.id || attributes.name || '';

  return (
    <Container className={className} error={input?.error || undefined} id={id} submitAttempted={submitAttempted}>
      <Label
        attributes={{ htmlFor: inputId }}
      >
        {labelCopy || capitalize(attributes.name || '')}
      </Label>
      <Input
        attributes={attributes as InputAttributes}
        input={input}
        options={options}
        selectValue={selectValue}
        submitAttempted={submitAttempted}
        validationRules={validationRules}
      />
      {children}
      <InputError error={input?.error || undefined} submitAttempted={submitAttempted} />
    </Container>
  );
};

export default InputContainer;
