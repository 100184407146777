import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from "react";

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import './styles/clipboard_copy.scss';

export default class ClipboardCopy extends React.Component {
  state = {
    copied: false
  };

  copyToClipboard() {
    const { onCopy, textToCopy } = this.props;

    // Because document.execCommand('copy') is deprecated, it has been replaced with the clipboard from the Navigator object.
    // IMPORTANT: Navigator.clipboard only works if you're on localhost or on a secure (https) domain.
    // You will have to modify your chrome flags so lvh.me enables the clipboard to copy.
    // INSTRUCTIONS:
    // 1. As a shortcut, in your browser, type: chrome://flags/
    // 2. Search for 'Insecure origins treated as secure'
    // 3. In the input, you will type: 'http://lvh.me:3000' (MAKE SURE THIS IS ENABLED)
    // 4. This should now enable it!

    if (!window.navigator?.clipboard) {
      const textField = document.createElement('textarea');
      textField.innerText = textToCopy;

      document.body.appendChild(textField);
      textField.select();

      document.execCommand('copy');

      textField.remove();

      if (onCopy) {
        onCopy();
      }

      this.setState({ copied: true }, () => setTimeout(() => this.setState({ copied: false }), 2000));
    } else {
      window.navigator.clipboard.writeText(textToCopy).then(() => {

        if (onCopy) {
          onCopy();
        }

        this.setState({ copied: true }, () => setTimeout(() => this.setState({ copied: false }), 2000));
      });
    }
  }

  renderFeedbackAsTooltip() {
    if (this.state.copied) {
      return (
        <span className="feedback-as-tooltip-container">
          <span className="feedback-as-tooltip"><Trans>Copied!</Trans></span>
          {this.props.children}
        </span>
      );
    }

    return this.props.children;
  }

  renderFeedbackAsReplacement() {
    if (this.state.copied) {
      return <span aria-label="copied" className="bff-check-mark feedback-as-replacement" />;
    }

    return this.props.children;
  }

  render() {
    return (
      <I18nProviderWrapper>
        <span
          className={`clipboard-copy-component ${this.props.needIcon ? `bff-copy` : ''}`}
          onClick={(e) => this.copyToClipboard(e)}
          rel={this.props.needIcon ? "tooltip" : undefined}
          title={this.props.needIcon ? "Copy to Clipboard" : undefined}
        >
          {this.props.showFeedbackAsTooltip
            ? this.renderFeedbackAsTooltip()
            : this.renderFeedbackAsReplacement()
          }
        </span>
      </I18nProviderWrapper>
    );
  }
}

ClipboardCopy.propTypes = {
  needIcon: PropTypes.bool,
  onCopy: PropTypes.func, // eslint-disable-line react/require-default-props
  showFeedbackAsTooltip: PropTypes.bool,
  textToCopy: PropTypes.string
};

ClipboardCopy.defaultProps = {
  needIcon: false,
  showFeedbackAsTooltip: true,
  textToCopy: null
};
