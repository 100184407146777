import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function WatermarkOverlay(props) {
  const [playerPosition, updatePlayerPosition] = useState({ height: 0, width: 0 });

  const handleResize = () => {
    const playerLocation = props.player.el().getBoundingClientRect();
    if (playerLocation.height) {
      updatePlayerPosition(playerLocation);
    }
  };

  const actualVideoWidth = () => parseInt((16 / 9) * playerPosition.height, 10);
  const visualPlayerPadding = () => (
    (playerPosition.width > actualVideoWidth())
      ? parseInt((playerPosition.width - actualVideoWidth()) / 2, 10)
      : 0
  );

  const adjustedWatermarkWidth = () => parseInt(
    (props.watermark_width / props.attachment.width) * actualVideoWidth(), 10
  );
  const adjustedWatermarkHeight = () => parseInt(
    (props.watermark_height / props.attachment.height) * playerPosition.height, 10
  );

  const xPlacement = () => {
    switch (props.watermark_x) {
      case 0:
        return visualPlayerPadding();
      case 1:
        return playerPosition.width - visualPlayerPadding() - adjustedWatermarkWidth();
      default:
        return ((playerPosition.width - adjustedWatermarkWidth()) / 2);
    }
  };

  const yPlacement = () => {
    switch (props.watermark_y) {
      case 0:
        return 0;
      case 1:
        return playerPosition.height - adjustedWatermarkHeight();
      default:
        return ((playerPosition.height - adjustedWatermarkHeight()) / 2);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => { window.removeEventListener("resize", handleResize); };
  }, []);

  useEffect(() => {
    handleResize();
  }, [props.watermark_x, props.watermark_y, props.watermark_height]);

  const style = () => ({
    opacity: props.watermark_opacity || 1,
    height: adjustedWatermarkHeight(),
    width: adjustedWatermarkWidth(),
    position: "absolute",
    top: `${yPlacement().toString()}px`,
    left: `${xPlacement().toString()}px`,
    zIndex: 999999
  });

  return <img src={props.watermark.url} id="watermark-image" style={style()} alt="watermark" />;
}

WatermarkOverlay.propTypes = {
  player: PropTypes.shape({
    currentHeight: PropTypes.func,
    currentWidth: PropTypes.func,
    el: PropTypes.func
  }),
  watermark: PropTypes.shape({
    url: PropTypes.string
  }).isRequired,
  attachment: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }).isRequired,
  watermark_x: PropTypes.number,
  watermark_y: PropTypes.number,
  watermark_height: PropTypes.number.isRequired,
  watermark_width: PropTypes.number.isRequired,
  watermark_opacity: PropTypes.number
};

WatermarkOverlay.defaultProps = {
  player: undefined,
  watermark_x: 0.5,
  watermark_y: 0.5,
  watermark_opacity: 1
};

export default WatermarkOverlay;
