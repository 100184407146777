/* eslint-disable @typescript-eslint/naming-convention */
import {
  ButtonLooks,
  DialogSizes,
  FontAlignments,
  FontIcons,
  FontWeights,
  IconButton,
  StandardButton,
  StandardDialog,
  StandardPagination,
  StandardTable,
  StandardTableColumn,
  StandardTableRow,
  StandardText
} from '@brandfolder/react';
import { localizeDate } from '@brandfolder/utilities';
import { t, Trans, Plural } from '@lingui/macro';
import classnames from 'classnames';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { FetchState, useFetch } from '@api/ApiHelper';
import { UserRoles } from '@api/v4/private/resources/users';
import { PermissionsGetResponse } from '@api/v4/user_groups/user-group-permissions/UserGroupPermissionsTypes';
import { userPermissionOptions } from '@components/bulk_management/user-settings/helpers';
import { basePermissionFetchOptions, getDisplayResourceType } from '@components/bulk_management/user_groups/helpers';
import { TextButton } from '@components/library/button';
import { ListDropdown } from '@components/library/dropdown';
import { isGettyClient } from '@helpers/getty-strings';
import { getStandardPaginationLabels, standardError } from '@translations';

import classes from './styles/user-groups.module.scss';

interface UserGroupsPermissionsTableProps {
  permissionsFetch: FetchState<PermissionsGetResponse>;
  userGroupKey: string;
}

type FilterValues = UserRoles.Guest | UserRoles.Collaborator | UserRoles.Admin | 'all';

export const UserGroupsPermissionsTable: FunctionComponent<UserGroupsPermissionsTableProps> = ({
  permissionsFetch,
  userGroupKey
}) => {
  const [filter, setFilter] = useState<FilterValues>('all');
  const [rows, setRows] = useState<StandardTableRow[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [deletePermissionKey, setDeletePermissionKey] = useState('');
  const [deletePermissionsOpenDialog, setDeletePermissionsOpenDialog] = useState(false);
  const [resourceName, setResourceName] = useState('');

  const permissionCount = permissionsFetch.response?.meta?.total_count || 0;

  const permissionsDelete = useFetch({
    url: `/api/v4/user_group_permissions/${deletePermissionKey}`,
    fetchOnMount: false,
    method: 'DELETE'
  });

  useEffect(() => {
    const fetchOptions = basePermissionFetchOptions(userGroupKey);
    permissionsFetch.fetch({
      ...fetchOptions,
      params: {
        ...fetchOptions.params,
        'filter[permission_level]': filter === 'all' ? undefined : filter,
        page: page
      }
    });
  }, [filter, page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (permissionsFetch.response) {
      const tableRows = permissionsFetch.response.included ? permissionsFetch.response.included.map((resource) => {
        const matchedPermission = permissionsFetch.response.data.find((permission) => (
          resource.id === permission.relationships?.permissible?.data.id
        ));
        return ({
          resourceName: resource.attributes.name,
          resourceType: getDisplayResourceType(resource.type, resource.attributes, isGettyClient()),
          createdOn: localizeDate(matchedPermission.attributes.created_at),
          permissionLevel: userPermissionOptions().find((opt) => opt.value === matchedPermission.attributes.permission_level)?.label || '',
          removePermission: (
            <IconButton
              className={classnames(classes.textIconButton, classes.textButton)}
              icon={FontIcons.Remove}
              label={t`Remove permission`}
              look={ButtonLooks.TextWarning}
              onClick={(): void => {
                setDeletePermissionKey(matchedPermission.id);
                setResourceName(resource.attributes.name);
              }}
            />
          )
        });
      }) : [];
      setRows(tableRows);
      setTotalPages(permissionsFetch?.response?.meta?.total_pages || 0);
      // close delete confirmation dialog if we're getting response back after refetching after deleting
      setDeletePermissionKey('');
      setDeletePermissionsOpenDialog(false);
    }
  }, [permissionsFetch.response]);

  useEffect(() => {
    if (deletePermissionKey) {
      setDeletePermissionsOpenDialog(true);
    }
  }, [deletePermissionKey]);

  useEffect(() => {
    if (permissionsDelete.response) {
      permissionsFetch.fetch(basePermissionFetchOptions(userGroupKey));
    }
  }, [permissionsDelete.response]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: StandardTableColumn[] = [{
    children: <Trans>Resource name</Trans>,
    heading: <Trans>Resource name</Trans>,
    rowKey: 'resourceName',
  }, {
    children: <Trans>Resource type</Trans>,
    heading: <Trans>Resource type</Trans>,
    rowKey: 'resourceType',
  }, {
    children: <Trans>Created on</Trans>,
    heading: <Trans>Created on</Trans>,
    rowKey: 'createdOn',
  }, {
    children: <Trans>Permission level</Trans>,
    heading: <Trans>Permission level</Trans>,
    rowKey: 'permissionLevel',
  }, {
    children: '',
    heading: '',
    rowKey: 'removePermission',
    tdClassName: classnames(classes.removePermissionColumn)
  }];

  const deletePermissionConfirmationDialog = (
    <StandardDialog
      height={300}
      id="permission-delete-confirmation-dialog"
      labels={{
        closeButtonLabel: t`Close dialog`
      }}
      onClose={(): void => { setDeletePermissionKey(''); }}
      open={deletePermissionsOpenDialog}
      setOpen={setDeletePermissionsOpenDialog}
      showFooter={false}
      size={DialogSizes.Small}
      title={t`Remove this permission from the group?`}
      titleIcon={FontIcons.Trash}
    >
      <div className={classnames(classes.permissionDeleteConfirmationFooter)}>
        <StandardText align={FontAlignments.Center}>
          <Trans>
            Members of this group will no longer have access to this resource. This can be undone by adding the resource again.
          </Trans>
        </StandardText>
        <StandardText
          align={FontAlignments.Center}
          className={classes.permissionsDeleteConfirmationDialogResourceName}
          weight={FontWeights.Bold}
        >
          {resourceName}
        </StandardText>
        <StandardButton
          className={classnames(classes.deletePermissionConfirmationDeleteButton)}
          loading={permissionsDelete.loading}
          look={ButtonLooks.Danger}
          onClick={(): void => {
            permissionsDelete.fetch();
          }}
        >
          <Trans>Remove permission</Trans>
        </StandardButton>
      </div>
    </StandardDialog>
  );

  return (
    <section className={classnames(classes.userGroupsPermissionsTable, 'user-groups-permissions-table-section')}>
      <div className={classnames(classes.permissionsTableHeader)}>
        <h3 className={classnames(classes.permissionHeader, 'user-groups-permissions-table-section__permission-count')}>
          <Plural one="# Permission" other="# Permissions" value={permissionCount} />
        </h3>
        <div className={classnames(classes.permissionsTableFilter, 'user-groups-permissions-table-section__filter')}>
          <ListDropdown
            id="permission-filter"
            label={t`Filter by`}
            onChange={(selected): void => {
              setFilter(selected.value as FilterValues);
            }}
            options={[
              {
                label: t`All`,
                value: 'all'
              },
              ...userPermissionOptions()
            ]}
            value={filter}
            virtualizeOptions={false}
          />
        </div>
      </div>
      <div className={classnames(classes.permissionsTable)}>
        {permissionsFetch.response && deletePermissionConfirmationDialog}
        <StandardTable
          caption={t`List of user group permissions`}
          columns={columns}
          empty={rows.length === 0 && !permissionsFetch.loading}
          emptyContent={filter !== 'all' ? (
            <StandardText
              className={classes.empty}
            >
              <Trans>Sorry! No results were found.</Trans>
            </StandardText>
          ) : (
            <>
              <StandardText
                className={classes.empty}
              >
                <Trans>You haven't added any permissions yet.</Trans>
              </StandardText>
              <img
                alt=""
                className={classes.img}
                src="https://cdn.brandfolder.io/27C9EC93/at/q6y1hf-fcrlsw-12xbfy/person-digging-in-empty-box.svg"
              />
            </>
          )}
          error={!!permissionsFetch.error}
          errorContent={(
            <>
              {standardError()}&nbsp;
              <TextButton
                onClick={(): void => { permissionsFetch.fetch(); }}
              >
                <Trans>Try again</Trans>
              </TextButton>
            </>
          )}
          id="permissions-table"
          loaderLabel={t`Loading`}
          loading={permissionsFetch.loading}
          rows={rows}
        />
      </div>
      {totalPages > 1 && (
        <div className={classnames(classes.permissionsPagination)}>
          <StandardPagination
            disabled={permissionsFetch.loading}
            labels={getStandardPaginationLabels()}
            onPageChange={(newPage): void => setPage(newPage)}
            total={totalPages}
          />
        </div>
      )}
    </section>
  );
};
