import { Trans } from '@lingui/macro';
import React, { createContext, FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { AttachmentGallery } from '@components/share_manifests/components/gallery-view/AttachmentGallery';
import { useFetchAssets } from '@components/share_manifests/components/gallery-view/useFetchAssets';

import classes from './styles/attachment-gallery.module.scss';

export interface GalleryViewProps {
  sectionKeys: string[];
  viewOnly: boolean;
}

export const ViewOnlyContext = createContext(false);

export const GalleryView: FunctionComponent<GalleryViewProps> = ({
  sectionKeys,
  viewOnly,
}) => {
  const { assets, error, loading } = useFetchAssets({ sectionKeys });

  if (error)
    return (
      <div role="alert">
        <Trans>There was an error fetching asset data</Trans>
      </div>
    );

  if (loading) return null;

  return (
    <I18nProviderWrapper>
      <div
        className={classes.galleryViewContainer}
      >
        { assets.length ? (
          <ViewOnlyContext.Provider value={viewOnly}>
            <AttachmentGallery
              assets={assets}
            />
          </ViewOnlyContext.Provider>
        ) : (
          <div role="alert"><Trans>No assets found</Trans></div>
        )}
      </div>
    </I18nProviderWrapper>
  );
};
