import React, { FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';

import { BFLoaderOverlay } from '@components/common/loader/main';
import { BaseTabsProps } from '@components/library/tabs';
import { TabButton } from './components/TabButton';
import { TabList } from './components/TabList';
import { TabPanel } from './components/TabPanel';
import { TabSlider } from './components/TabSlider';

import classes from './styles/tab_decorator.module.scss';

/**
 * Base tabs
 * @param {BaseTabsProps} props BaseTabsProps
 */
export const BaseTabs: FunctionComponent<BaseTabsProps> = (props) => {
  const {
    activatePanelOnPrevNext = true,
    caption,
    changeToIndex,
    id,
    initialActiveIndex,
    loading,
    onChange,
    showSlider,
    showUnderline,
    tabButtonClassName,
    tabListClassName,
    tabPanelClassName,
    tabSliderClassName,
    tabs
  } = props;

  const [activeIndex, setActiveIndex] = useState(initialActiveIndex || 0);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);

  const length = tabs.length;
  const zeroBasedLength = length - 1;

  const handleTabChange = (index: number): void => {
    setActiveIndex(index);

    if (onChange) {
      onChange(index);
    }
  };

  /**
   * Handling left arrow, right arrow, spacebar, home, and end keys are required for accessibility compliance
   * https://www.w3.org/TR/wai-aria-practices-1.2/#keyboard-interaction-20
   */
  const handleTabButtonKeyUp = (e: KeyboardEvent<HTMLButtonElement>, index: number): void => {
    // only handle tab button previous/next if we have more than one tab button
    if (length > 1) {
      // left arrow key goes to the previous tab button or wraps around to the last tab button
      if (e.key === 'ArrowLeft') {
        if (activatePanelOnPrevNext) {
          const prev = activeIndex - 1;
          const prevIndex = prev < 0 ? zeroBasedLength : prev;
          setActiveIndex(prevIndex);

          if (onChange) {
            onChange(prevIndex);
          }
        } else {
          const prev = (focusIndex || 0) - 1;
          const prevIndex = prev < 0 ? zeroBasedLength : prev;
          setFocusIndex(prevIndex);
        }
      }

      // right arrow key goes to the next tab button or wraps back around to the first tab button
      if (e.key === 'ArrowRight') {
        if (activatePanelOnPrevNext) {
          const next = activeIndex + 1;
          const nextIndex = next > zeroBasedLength ? 0 : next;
          setActiveIndex(nextIndex);

          if (onChange) {
            onChange(nextIndex);
          }
        } else {
          const next = (focusIndex || 0) + 1;
          const nextIndex = next > zeroBasedLength ? 0 : next;
          setFocusIndex(nextIndex);
        }
      }

      // space/spacebar (enter is handled by onClick below)
      if (e.key === ' ' || e.key === 'Spacebar') {
        handleTabChange(index);
      }

      // home goes to the first tab button
      if (e.key === 'Home') {
        if (activatePanelOnPrevNext) {
          setActiveIndex(0);

          if (onChange) {
            onChange(0);
          }
        } else {
          setFocusIndex(0);
        }
      }

      // end goes to the last tab button
      if (e.key === 'End') {
        if (activatePanelOnPrevNext) {
          setActiveIndex(zeroBasedLength);

          if (onChange) {
            onChange(zeroBasedLength);
          }
        } else {
          setFocusIndex(zeroBasedLength);
        }
      }
    }
  };

  useEffect(() => {
    // let the parent component change the tab (if it's a valid tab)
    if (typeof changeToIndex === 'number' && changeToIndex >= 0 && changeToIndex < length && changeToIndex !== activeIndex) {
      setActiveIndex(changeToIndex);

      if (onChange) {
        onChange(changeToIndex);
      }
    }
  }, [changeToIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialActiveIndex !== undefined) {
      handleTabChange(initialActiveIndex);
    }
  }, [initialActiveIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // if a tab is removed and that was the active tab
    // reset the activeIndex back to the first tab
    if (activeIndex > tabs.length - 1) {
      setActiveIndex(0);
    }
  }, [tabs.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TabList caption={caption} className={tabListClassName}>
        {tabs.map((tab, index) => (
          <TabButton
            key={`${id}-${index}`} // eslint-disable-line react/no-array-index-key
            active={activeIndex === index}
            activeIndex={activeIndex}
            className={tabButtonClassName}
            disabled={tab.disabled}
            focusIndex={focusIndex}
            id={`${id}-${index}`}
            index={index} // eslint-disable-line react/no-array-index-key
            onClick={(): void => handleTabChange(index)}
            onKeyUp={(e): void => handleTabButtonKeyUp(e, index)}
            tabPanelId={`${id}-panel-${index}`}
          >
            {tab.tabButton}
          </TabButton>
        ))}
        {showSlider && <TabSlider activeIndex={activeIndex} className={tabSliderClassName} numberOfTabs={length} />}
        {showUnderline && (
          <span
            className={classes.tabDecorator}
            role="presentation"
          />
        )}
      </TabList>
      {tabs.map((tab, index) => (
        <TabPanel
          key={`${id}-panel-${index}`} // eslint-disable-line react/no-array-index-key
          active={activeIndex === index}
          className={tabPanelClassName}
          id={`${id}-panel-${index}`} // eslint-disable-line react/no-array-index-key
          tabButtonId={`${id}-${index}`}
        >
          {tab.tabPanel}
          {loading && <BFLoaderOverlay />}
        </TabPanel>
      ))}
    </>
  );
};
