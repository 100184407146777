import { FlattenedAttachment } from '@components/asset/modal/tabs/edit/EditTabTypes';

export interface CroppedData {
  height: number;
  rotate: number;
  scaleX: number;
  scaleY: number;
  width: number;
  x: number;
  y: number;
}

export interface Image extends Partial<FlattenedAttachment> {
  updated_at: string; // eslint-disable-line @typescript-eslint/naming-convention
}

export interface SelectedPreset {
  label: string;
  value: {
    height: number;
    width: number;
  }
}

type UnscaledCropAndPadValues = {
  padTop: number;
  padLeft: number;
  padRight: number;
  padBottom: number;
  cropX: number;
  cropY: number;
  cropWidth: number;
  cropHeight: number;
};

type ParamArgs = {
  croppedData: CroppedData;
  fixedDimensions: boolean;
  image: Image;
  imageToThumbnailRatio: number;
  selectedPreset: SelectedPreset;
};

type CropParamsArgs = Omit<ParamArgs, 'fixedDimensions' | 'selectedPreset' >;

export const unscaledCropAndPadValues = ({
  croppedData,
  image,
  imageToThumbnailRatio
}: CropParamsArgs): UnscaledCropAndPadValues | null => {
  if (!croppedData.width || !croppedData.height || !image.width || !image.height) return null;

  const cropXFull = Math.round(croppedData.x * imageToThumbnailRatio);
  const cropWidthFull = Math.round(croppedData.width * imageToThumbnailRatio);
  const cropYFull = Math.round(croppedData.y * imageToThumbnailRatio);
  const cropHeightFull = Math.round(croppedData.height * imageToThumbnailRatio);

  const padTop = cropYFull > 0 ? 0 : cropYFull * -1;
  const padLeft = cropXFull > 0 ? 0 : cropXFull * -1;
  const padRight = cropXFull + cropWidthFull > image.width ? cropXFull + cropWidthFull - image.width : 0;
  const padBottom = cropYFull + cropHeightFull > image.height ? cropYFull + cropHeightFull - image.height : 0;

  return {
    padTop,
    padLeft,
    padRight,
    padBottom,
    cropX: cropXFull < 0 ? 0 : cropXFull,
    cropY: cropYFull < 0 ? 0 : cropYFull,
    cropWidth: padLeft === 0 && padRight === 0 ? cropWidthFull : cropWidthFull - padLeft - padRight,
    cropHeight: padTop === 0 && padBottom === 0 ? cropHeightFull : cropHeightFull - padTop - padBottom
  };
};

export const isPadded = ({
  croppedData,
  image,
  imageToThumbnailRatio
}): boolean => {
  if (!croppedData.width || !croppedData.height || !image.width || !image.height) return false;

  const {
    padTop,
    padLeft,
    padRight,
    padBottom,
  } = unscaledCropAndPadValues({ croppedData, image, imageToThumbnailRatio });

  return padTop > 0 || padRight > 0 || padBottom > 0 || padLeft > 0;
};

export const cropParams = ({
  croppedData,
  image,
  imageToThumbnailRatio
}: CropParamsArgs): { crop: string; } => {
  const {
    cropX,
    cropY,
    cropWidth,
    cropHeight
  } = unscaledCropAndPadValues({ croppedData, image, imageToThumbnailRatio });

  return { crop: `${cropWidth},${cropHeight},x${cropX},y${cropY}` };
};

export const padParams = ({
  croppedData,
  fixedDimensions,
  image,
  imageToThumbnailRatio,
  selectedPreset
}: ParamArgs): { pad: string; } | object => {
  const width = selectedPreset?.value?.width || 0;
  const height = selectedPreset?.value?.height || 0;
  const {
    padTop,
    padLeft,
    padRight,
    padBottom,
    cropWidth,
    cropHeight
  } = unscaledCropAndPadValues({ croppedData, image, imageToThumbnailRatio });
  const padded = isPadded({ croppedData, image, imageToThumbnailRatio });

  if (fixedDimensions && padded) {
    // If crop is out of image bound AND final image size is fixed
    // fastly resizes the image to the fixed height and width and THEN adds the padding
    // The padding and fixed dimensions must be adjusted to account for this
    const scaledPadTop = Math.round((padTop / (cropHeight + padTop)) * height);
    const scaledPadRight = Math.round((padRight / (cropWidth + padRight)) * width);
    const scaledPadBottom = Math.round((padBottom / (cropHeight + padBottom)) * height);
    const scaledPadLeft = Math.round((padLeft / (cropWidth + padLeft)) * width);

    return { pad: `${scaledPadTop},${scaledPadRight},${scaledPadBottom},${scaledPadLeft}` };
  } else if (padded) {
    // If crop is out of image bounds, add padding for overflow
    return { pad: `${padTop},${padRight},${padBottom},${padLeft}` };
  }

  return { };
};

export const resizeParams = ({
  croppedData,
  fixedDimensions,
  image,
  imageToThumbnailRatio,
  selectedPreset
}: ParamArgs): { width: string; height: string; } | object => {
  const {
    padTop,
    padLeft,
    padRight,
    padBottom,
    cropWidth,
    cropHeight
  } = unscaledCropAndPadValues({ croppedData, image, imageToThumbnailRatio });
  const width = selectedPreset?.value?.width || 0;
  const height = selectedPreset?.value?.height || 0;

  if (fixedDimensions && isPadded({ croppedData, image, imageToThumbnailRatio })) {
    // If crop is out of image bound AND final image size is fixed
    // fastly resizes the image to the fixed height and width and THEN adds the padding
    // The padding and fixed dimensions must be adjusted to account for this
    return {
      width: Math.round((cropWidth / (padLeft + cropWidth + padRight)) * width).toString(),
      height: Math.round((cropHeight / (padTop + cropHeight + padBottom)) * height).toString()
    };
  } else if (fixedDimensions) {
    // If dimensions are fixed, specify width and height to resize to these dimensions
    return {
      width: width.toString(),
      height: height.toString()
    };
  }

  return { };
};

// Commenting out for now, unnecessary to set png background and pad transparent (this is done automatically by Fastly)
// this could be useful in the future to add a user-selected background and pad color
//
// export const backgroundColorParams = (
//   selectedFiletype: string
// ): { 'bg-color': string; } | object => ( // eslint-disable-line @typescript-eslint/naming-convention
//   // set the background (image and padding) to transparent for pngs
//   // https://developer.fastly.com/reference/io/bg-color/
//   selectedFiletype.toLowerCase().includes('png')
//     ? { 'bg-color': '0,0,0,0' } // eslint-disable-line @typescript-eslint/naming-convention
//     : { }
// );