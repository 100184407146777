import { Organization } from '@brandfolder/types';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { InfoCard } from '@components/limits/usage_info_card/UsageInfoCard';

import './styles/subscription_dashboard.scss';

import SubscriptionFeatures from './SubscriptionFeatures/SubscriptionFeatures';
import SubscriptionHeader from './SubscriptionHeader/SubscriptionHeader';
import SubscriptionInfoCards from './SubscriptionInfoCards/SubscriptionInfoCards';

interface SubscriptionsDashboardProps {
  featureStatuses: { [key: string]: boolean };
  organization: Organization;
  subscriptionInfoCards: InfoCard[]
}

const SubscriptionsDashboard: FunctionComponent<SubscriptionsDashboardProps> = ({
  featureStatuses,
  organization,
  subscriptionInfoCards
}) =>
  <I18nProviderWrapper>
    <SubscriptionHeader organization={organization} />
    <SubscriptionInfoCards subscriptionInfoCards={subscriptionInfoCards} />
    <SubscriptionFeatures featureStatuses={featureStatuses} organizationName={organization.name} />
  </I18nProviderWrapper>;

export default SubscriptionsDashboard;
