import { t, Trans } from '@lingui/macro';
import React, { useContext, useEffect, useState, FunctionComponent } from 'react';

import { updateBoard } from '@api/v4/boards';
import { BoardResponseObject } from '@api/v4/boards/boardsTypes';
import { CloseButton, PrimaryButton } from '@components/library/button/index';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';
import renderModal from '@components/show_page/modals/renderModal';

import { AllBoardsViewContext } from '../all_boards_view';
// reflect success or error message

interface UpdateBoardHandlerParams {
  boardId: string;
  boardTitle: string;
  closeModal: () => void;
  boards: BoardResponseObject[];
  setBoards: React.Dispatch<React.SetStateAction<BoardResponseObject[]>>;
}
const updateBoardHandler = async (options: UpdateBoardHandlerParams): Promise<void> => {
  const { boardId, boardTitle, closeModal, boards, setBoards } = options;
  try {
    // reponse is created board
    await updateBoard({ boardId, boardTitle });
    // find target board and update its name attribute
    // possibly refactor so board context is a map, with keys being
    // boardId so we can save lookup time
    const updatedBoards = boards.map((board) => {
      if (board.id === boardId) {
        const boardCopy = {
          ...board
        };
        boardCopy.attributes.name = boardTitle;
        return boardCopy;
      }
      return board;
    });
    setBoards(updatedBoards);
    Notify.create({ title: t`Board name successfully changed.`, type: 'success' });
    closeModal();
  } catch (error) {
    Notify.create({ title: t`Something went wrong, renaming board failed.`, type: 'error' });
  } finally {
    closeModal();
  }
};

interface RenameBoardModalProps {
  boardName: string;
  boardId: string;
  closeModal: () => void;
}
const RenameBoardModal: FunctionComponent<RenameBoardModalProps> = (options) => {
  const { boardName, boardId, closeModal } = options;
  const [newBoardTitle, setNewBoardTitle] = useState(boardName);
  const AllBoardsViewState = useContext(AllBoardsViewContext);

  useEffect(() => closeModal, []); // fire closeModal when unmounting

  return (
    <div className="modal-content-wrapper rename-board-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-edit icon" />
        <h3 className="modal-title">
          <Trans>Rename your Board</Trans>
        </h3>
        <CloseButton onClick={closeModal} />
      </div>
      <div className="modal-content-wrapper__body">
        <div className="rename-board-input-container">
          <InputContainer
            attributes={{
              name: 'board-rename',
              onChange: (e: InputChangeEvent): void => setNewBoardTitle(e.target.value),
              type: InputType.Text,
            }}
            input={{ value: newBoardTitle }}
            labelCopy={t`Board Name`}
          />
        </div>
        <div className="button-container">
          <PrimaryButton
            className="t-confirm-modal"
            disabled={newBoardTitle.length === 0 || newBoardTitle === boardName}
            onClick={(): void => {
              updateBoardHandler({
                boardId,
                boardTitle: newBoardTitle.trim(),
                closeModal,
                boards: AllBoardsViewState.boards,
                setBoards: AllBoardsViewState.setBoards
              });
            }}
            size="small"
          >
            <Trans>Rename</Trans>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(RenameBoardModal, 'RenameBoardModal');
export default ModalComponent;
