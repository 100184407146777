import { t } from '@lingui/macro';
import convert from 'color-convert';
import { CMYK, RGB } from 'color-convert/conversions';
import React, { FunctionComponent, useContext } from 'react';

import { ColorData } from '@api/v4/assets/assetTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import { ActionTypes, AssetDetailsDispatch } from '@components/asset/modal/tabs/edit/EditTabTypes';
import AssetNameInput from '@components/asset/modal/tabs/edit/side_bar/assetNameInput';
import CMYKInputs from '@components/asset/modal/tabs/edit/side_bar/color_assets/cmykInputs';
import HexInput from '@components/asset/modal/tabs/edit/side_bar/color_assets/hexInput';
import RGBInputs from '@components/asset/modal/tabs/edit/side_bar/color_assets/rgbInputs';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';

import '../styles/_main.scss';

import './styles/_color_side_bar.scss';

interface ColorSideBarPropsScopedContext {
  dispatch: AssetDetailsDispatch;
  state: {
    editState: {
      colorData: ColorData;
    };
  };
}

export interface CmykString {
  c: string;
  m: string;
  y: string;
  k: string;
}

export interface RgbString {
  r: string;
  g: string;
  b: string;
}

const emptyColorData: ColorData = {
  c: '',
  m: '',
  y: '',
  k: '',
  r: '',
  g: '',
  b: '',
  pantone: '',
  pantone_u: '',
  hex: ''
};

const ColorSideBar: FunctionComponent<{}> = () => {
  const { state, dispatch }: ColorSideBarPropsScopedContext = useContext(assetModalContext);

  const colorData = state.editState.colorData || emptyColorData;

  if (!state.editState.colorData) {
    dispatch({
      type: ActionTypes.UpdateAsset,
      payload: { colorData }
    });
  }

  const handleColorUpdate = (partial: Partial<ColorData>): void => {
    dispatch({
      type: ActionTypes.UpdateAsset,
      payload: { colorData: { ...colorData, ...partial } }
    });
  };

  const prepRgbColorValues = (values: RgbString): RGB => [parseInt(values.r, 10), parseInt(values.g, 10), parseInt(values.b, 10)];
  const prepCmykColorValues = (values: CmykString): CMYK => [parseInt(values.c, 10), parseInt(values.m, 10), parseInt(values.y, 10), parseInt(values.k, 10)];

  const autofillEmptyColors = (partial: Partial<ColorData>): void => {
    const isCMYKBlack = (converted: number[]): boolean => (
      converted[0] === 0 && converted[1] === 0 && converted[2] === 0 && converted[3] === 100
    );

    const isRGBBlack = (converted: number[]): boolean => (
      converted[0] === 0 && converted[1] === 0 && converted[2] === 0
    );

    const isHexBlack = (converted: string): boolean => (converted === '000000' || converted === '000');

    const autofilledColors: Partial<ColorData> = {};

    if (partial.hex) {
      if (!colorData.r && !colorData.g && !colorData.b) {
        const converted = convert.hex.rgb(partial.hex);
        if (isHexBlack(partial.hex) || !isRGBBlack(converted)) {
          autofilledColors.r = converted[0].toString();
          autofilledColors.g = converted[1].toString();
          autofilledColors.b = converted[2].toString();
        }
      }

      if (!colorData.c && !colorData.m && !colorData.y && !colorData.k) {
        const converted = convert.hex.cmyk(partial.hex);
        if (isHexBlack(partial.hex) || !isCMYKBlack(converted)) {
          autofilledColors.c = converted[0].toString();
          autofilledColors.m = converted[1].toString();
          autofilledColors.y = converted[2].toString();
          autofilledColors.k = converted[3].toString();
        }
      }
    }

    if (partial.r && partial.g && partial.b) {
      const preppedRGB = prepRgbColorValues({ r: partial.r, g: partial.g, b: partial.b });
      if (!colorData.hex) {
        const converted = convert.rgb.hex(preppedRGB);
        if (isRGBBlack(preppedRGB) || !isHexBlack(converted)) {
          autofilledColors.hex = converted;
        }
      }

      if (!colorData.c && !colorData.m && !colorData.y && !colorData.k) {
        const converted = convert.rgb.cmyk(preppedRGB);
        if (isRGBBlack(preppedRGB) || !isCMYKBlack(converted)) {
          autofilledColors.c = converted[0].toString();
          autofilledColors.m = converted[1].toString();
          autofilledColors.y = converted[2].toString();
          autofilledColors.k = converted[3].toString();
        }
      }
    }

    if (partial.c && partial.m && partial.y && partial.k) {
      const preppedCMYK = prepCmykColorValues({ c: partial.c, m: partial.m, y: partial.y, k: partial.k });

      if (!colorData.hex) {
        const converted = convert.cmyk.hex(preppedCMYK);
        if (isCMYKBlack(preppedCMYK) || !isHexBlack(converted)) {
          autofilledColors.hex = converted;
        }
      }

      if (!colorData.r && !colorData.g && !colorData.b) {
        const converted = convert.cmyk.rgb(preppedCMYK);
        if (isCMYKBlack(preppedCMYK) || !isRGBBlack(converted)) {
          autofilledColors.r = converted[0].toString();
          autofilledColors.g = converted[1].toString();
          autofilledColors.b = converted[2].toString();
        }
      }
    }

    handleColorUpdate(autofilledColors);
  };

  return (
    <div className="edit-tab-sidebar one-third bf-scroll-element">
      <AssetNameInput assetNameCopy={t`Color Name`} />
      <HexInput
        autofillEmptyColors={autofillEmptyColors}
        handleColorUpdate={handleColorUpdate}
      />
      <RGBInputs
        autofillEmptyColors={autofillEmptyColors}
        handleColorUpdate={handleColorUpdate}
        prepRgbColorValues={prepRgbColorValues}
      />
      <CMYKInputs
        autofillEmptyColors={autofillEmptyColors}
        handleColorUpdate={handleColorUpdate}
        prepCmykColorValues={prepCmykColorValues}
      />
      <InputContainer
        attributes={{
          name: 'pantone',
          onChange: (e: InputChangeEvent): void => handleColorUpdate({ pantone: e.target.value }),
          type: InputType.Text,
          value: colorData.pantone,
        }}
        className="edit-tab-color-input--full-width"
        input={{ value: colorData.pantone }}
        labelCopy="Pantone Coated"
      />
      <InputContainer
        attributes={{
          name: 'pantone_u',
          onChange: (e: InputChangeEvent): void => handleColorUpdate({ pantone_u: e.target.value }),
          type: InputType.Text,
          value: colorData.pantone_u,
        }}
        className="edit-tab-color-input--full-width"
        input={{ value: colorData.pantone_u }}
        labelCopy="Pantone Uncoated"
      />
    </div>
  );
};

export default ColorSideBar;
