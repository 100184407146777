/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!! README !!!!!!!!!!!!!!!!!!
 * If you make changes to this file or any other
 * lazy rendering-related file, please be sure
 * to manually test the bulk tags display with
 * enough tags to trigger multiple pages.
 * That means > 250 tags or temporarily change
 * the page size to test that multiple pages are
 * rendered correctly.
 *
 * You also may want to run the lazy_render_spec
 * locally and ensure that it passes.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import RowShroud from './row_shroud';

interface RowPageProps {
  renderRows: () => ReactNode;
  isInitialPage?: boolean;
  pageSize?: number;
  rerender?: boolean;
  rowHeight?: number;
  setRerender?: SetStateDispatch<boolean>;
}

const defaultPageSize = 250;
// see clubhouse bug 556
// https://app.clubhouse.io/brandfolder/story/556/bulk-tags-max-out-at-40-pages-no-matter-page-size
const maxPageCount = 40;

const defaultProps: Partial<RowPageProps> = {
  isInitialPage: false,
  pageSize: defaultPageSize,
  rerender: false,
  rowHeight: 71,
  setRerender: () => { /* no oop */ },
};

export function getPageSize(entriesLength: number): number {
  return Math.max(defaultPageSize, entriesLength / maxPageCount);
}

export function getNumPages(pageSize: number, entriesLength: number): number {
  return Math.ceil(entriesLength / parseFloat(pageSize.toString()));
}

export function getPageSlice<T>(pageIndex: number, pageSize: number, toSlice: T[]): T[] {
  const startingRowIndex = pageIndex * pageSize;
  return toSlice.slice(startingRowIndex, startingRowIndex + pageSize);
}

export const RowPage: FunctionComponent<RowPageProps> = (initialProps) => {
  const props: RowPageProps = {
    ...defaultProps,
    ...initialProps
  };
  const {
    renderRows,
    rerender,
    setRerender,
    pageSize,
    rowHeight,
    isInitialPage
  } = props;

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]); // eslint-disable-line react-hooks/exhaustive-deps

  return isRendered || isInitialPage
    ? <>{renderRows()}</>
    : (
      <RowShroud
        onShown={(): void => setIsRendered(true)}
        pageSize={pageSize}
        rowHeight={rowHeight}
      />
    );
};
