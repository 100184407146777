import { Trans, Plural } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { Brandfolder, Collection } from '../../../../../helpers/show_page_helpers';

import './styles/main.scss';

class Insights extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentEvents: {},
      previousEvents: {}
    };

    // current/previous reference timeframe
    this.currentAjaxCalls = [];
    this.previousAjaxCalls = [];
  }

  componentDidMount() {
    this.addTabListener();
  }

  componentWillUnmount() {
    this.removeTabListener();
    this.abortAjaxCalls();
  }

  get previousDates() {
    return {
      start: this.timeAgo(60),
      end: this.timeAgo(30)
    };
  }

  get currentDates() {
    return {
      start: this.previousDates.end,
      end: new Date()
    };
  }

  requestInsights = () => {
    const { currentEvents, previousEvents } = this.state;
    if (typeof currentEvents.total_events !== "number" && !this.activeAjaxCall("current")) {
      this.fetchInsights("current");
    }
    if (typeof previousEvents.total_events !== "number" && !this.activeAjaxCall("previous")) {
      this.fetchInsights("previous");
    }
  }

  addTabListener = () => {
    window.addEventListener(
      `usageAssetTabHover${this.props.asset_key}`,
      this.requestInsights,
      false
    );
  }

  removeTabListener = () => {
    window.removeEventListener(
      `usageAssetTabHover${this.props.asset_key}`,
      this.requestInsights,
      false
    );
  }

  timeAgo(days) {
    return new Date(new Date().setDate(new Date().getDate() - days));
  }

  addAjaxCall(xhr, timeFrame) {
    this[`${timeFrame}AjaxCalls`] = this[`${timeFrame}AjaxCalls`].concat(xhr);
  }

  activeAjaxCall(timeFrame) {
    return !!this[`${timeFrame}AjaxCalls`].find((xhr) => xhr.readyState !== 4);
  }

  abortAjaxCalls() {
    this.currentAjaxCalls.concat(this.previousAjaxCalls).forEach((ajaxCall) => {
      if (ajaxCall.readyState !== 4) { ajaxCall.abort(); }
    });
  }

  fetchInsights(timeFrame) {
    /* eslint-disable no-undef */
    const { asset_key } = this.props;
    const dateRange = (timeFrame === "previous") ? this.previousDates : this.currentDates;
    const request = $.ajax({
      type: "GET",
      url: `${BFG.INSIGHTS_API_URL}/api/v1/events/counts`,
      contentType: "application/json",
      context: this,
      timeFrame,
      timeout: 30000,
      data: {
        source: SOURCE,
        resource_type: "asset",
        resource_key: asset_key,
        start_date: dateRange.start,
        end_date: dateRange.end
      },
      beforeSend(xhr) {
        xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
      },
      success(response) {
        this.setState({ [`${timeFrame}Events`]: response.data });
      },
    });
    this.addAjaxCall(request, timeFrame);
    /* eslint-enable no-undef */
  }

  percentages() {
    const { previousEvents, currentEvents } = this.state;
    if (previousEvents.total_events && currentEvents.total_events) {
      return {
        total_events: this.calculatePercentage('total_events'),
        total_views: this.calculatePercentage('total_views'),
        total_downloads: this.calculatePercentage('total_downloads'),
        total_shares: this.calculatePercentage('total_shares')
      };
    }
    return {};
  }

  calculatePercentage(key) {
    const { previousEvents, currentEvents } = this.state;
    const percentage = ((currentEvents[key] - previousEvents[key]) / previousEvents[key]);
    return Math.round(parseFloat(percentage * 100));
  }

  renderPecentageChange(calculation) {
    if (calculation === undefined) {
      return (
        <div className="percentage-change-none">
          <span>
            <Trans>Trends</Trans>
          </span>
          <p><Trans>calculating...</Trans></p>
        </div>
      );
    }
    if (calculation === Infinity) {
      return (
        <div className="percentage-change-none">
          <span>
            <Trans>Trends</Trans>
          </span>
          <p><Trans>available in 30 days</Trans></p>
        </div>
      );
    }
    if (calculation === 0 || Number.isNaN(calculation)) {
      return (
        <div className="percentage-change-none">
          <Trans>
            <span>0%</span>
            <p>over the past 30 days</p>
          </Trans>
        </div>
      );
    }
    if (calculation < 0) {
      return (
        <div className="percentage-change-negative">
          <span className="bff-arrow-right" />
          <Trans>
            <span>
              {calculation}
              %
            </span>
            <p>over the past 30 days</p>
          </Trans>
        </div>
      );
    }
    return (
      <div className="percentage-change-positive">
        <span className="bff-arrow-right" />
        <Trans>
          <span>
            {calculation}
            %
          </span>
          <p>over the past 30 days</p>
        </Trans>
      </div>
    );
  }

  renderScore() {
    const { score } = this.props;
    if (score) {
      return (
        <span>
          {score}
          <span className="max-score">
            /100
          </span>
        </span>
      );
    }
    return score;
  }

  render() {
    const { asset_key, asset_score_helpdoc } = this.props;
    const collectionSlug = Collection.key ? `/collections/${Collection.slug}` : "";
    const insightsLink = `/insights?page=/insights-app/${Brandfolder.slug}${collectionSlug}/assets/${asset_key} `;
    return (
      <I18nProviderWrapper>
        <div className="scoring">
          <div className="title-container">
            <p className="scoring-title"><Trans>Insights from the last 30 days</Trans></p>
            <button
              className="button primary"
              onClick={() => { window.open(insightsLink, "_blank"); }}
              type="button"
            >
              <Trans>View Insights</Trans>
            </button>
          </div>
          <div className="scoring-container">
            <div className="asset-score asset-score-desktop">
              <div className="asset-score-result">
                <div>
                  <span>{ this.renderScore() }</span>
                  <span className="bff-score-meter" />
                </div>
                <p><Trans>Asset score</Trans></p>
              </div>
              <a href={asset_score_helpdoc} rel="noopener noreferrer" target="_blank">
                <Trans>How is this calculated?</Trans>
              </a>
            </div>
            <div className="score-breakdown">
              <div className="asset-score asset-score-tablet">
                <div className="asset-score-result">
                  <div>
                    <span>{ this.renderScore() }</span>
                    <span className="bff-score-meter" />
                  </div>
                  <p><Trans>Asset score</Trans></p>
                </div>
                <a href={asset_score_helpdoc} rel="noopener noreferrer" target="_blank">
                  <Trans>How is this calculated?</Trans>
                </a>
              </div>
              <div className="individual-score asset-views">
                <div>
                  <span>{ this.state.currentEvents.total_views }</span>
                  <p>
                    <Plural one="Asset view" other="Asset views" value={this.state.currentEvents.total_views} />
                  </p>
                </div>
                <div className="percentage-change">
                  <span>
                    { this.renderPecentageChange(this.percentages().total_views) }
                  </span>
                </div>
              </div>
              <div className="individual-score asset-downloads">
                <div>
                  <span>{ this.state.currentEvents.total_downloads }</span>
                  <p>
                    <Plural
                      one="Asset download"
                      other="Asset downloads"
                      value={this.state.currentEvents.total_downloads}
                    />
                  </p>
                </div>
                <div className="percentage-change">
                  <span>
                    { this.renderPecentageChange(this.percentages().total_downloads) }
                  </span>
                </div>
              </div>
              <div className="individual-score asset-shares">
                <div>
                  <span>{ this.state.currentEvents.total_shares }</span>
                  <p>
                    <Plural one="Asset share" other="Asset shares" value={this.state.currentEvents.total_shares} />
                  </p>
                </div>
                <div className="percentage-change">
                  <span>
                    { this.renderPecentageChange(this.percentages().total_shares) }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </I18nProviderWrapper>
    );
  }
}

Insights.propTypes = {
  asset_key: PropTypes.string.isRequired,
  asset_score_helpdoc: PropTypes.string.isRequired,
  score: PropTypes.number,
};

Insights.defaultProps = {
  score: 0,
};

export default Insights;
