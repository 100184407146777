import { keyPressHelper } from "@brandfolder/utilities";
import React from "react";
import _ from "underscore";

import Brandfolder from "./brandfolder";

export default class BrandfolderList extends React.Component {
  state = {
    loading: true,
    error: false,
    brandfolders: [],
    orphanCollections: []
  };

  async componentDidMount() {
    await this.getBrandfolders();
    this.getCollections();
  }

  getBrandfolders() {
    return $.ajax({
      url: "/api/v4/brandfolders?per=3000",
      type: "GET",
      context: this,
      headers: { Authorization: `Bearer ${this.props.jwt}` },
      success: (response) => {
        const sortedBrandfolders = _.sortBy(response.data, (brandfolder) => (
          brandfolder.attributes.name.toUpperCase()
        ));
        const brandfolders = sortedBrandfolders.map((brandfolder) => ({
          brandfolder,
          collections: []
        }));
        this.setState({ brandfolders });
      },
      error: () => {
        this.setState({ error: true, loading: false });
      }
    });
  }

  getCollections() {
    return $.ajax({
      url: "/api/v4/collections?is_workspace=false&per=3000&include=brandfolder",
      type: "GET",
      context: this,
      headers: { Authorization: `Bearer ${this.props.jwt}` },
      error: () => {
        this.setState({ error: true, loading: false });
      },
      success: (response) => {
        this.setState((prevState) => {
          const brandfoldersCopy = prevState.brandfolders;
          const orphanCollectionsCopy = prevState.orphanCollections;
          response.data.forEach((collection) => {
            const brandfolderKey = collection?.relationships?.brandfolder?.data?.id;
            const matchingBrandfolder = brandfoldersCopy.find(({ brandfolder }) => brandfolder.id === brandfolderKey);
            if (matchingBrandfolder) {
              matchingBrandfolder.collections.push(collection);
            } else {
              orphanCollectionsCopy.push(collection);
            }
          });

          brandfoldersCopy.map((brandfolder) => {
            const sortedCollections = _.sortBy(brandfolder.collections, (collection) => (
              collection.attributes.name.toUpperCase()
            ));
            return {
              ...brandfolder,
              collections: sortedCollections
            };
          });

          const sortedOrphanCollections = _.sortBy(orphanCollectionsCopy.collections, (collection) => (
            collection.attributes.name.toUpperCase()
          ));
          orphanCollectionsCopy.collections = sortedOrphanCollections;

          return {
            loading: false,
            brandfolders: brandfoldersCopy,
            orphanCollections: orphanCollectionsCopy
          };
        });
      }
    });
  }

  renderBackButtonOrNotification() {
    if (this.props.selectedResource) {
      return (
        <div
          className="adobe-brandfolder-list-header"
          onClick={this.props.toggleBrandfolderList}
          onKeyPress={keyPressHelper(this.props.toggleBrandfolderList)}
          role="button"
          tabIndex="0"
        >
          <p><span className="bff-arrow-left" />Back to Menu</p>
        </div>
      );
    }

    return (
      <div className="adobe-brandfolder-list-header">
        <p>Select a Brandfolder</p>
        <div
          className="adobe-brandfolder-list-header__logout"
          onClick={this.props.logout}
          onKeyPress={keyPressHelper(this.props.logout)}
          role="button"
          tabIndex="0"
        >
          <span className="bff-check-in" /> Logout
        </div>
      </div>
    );
  }

  renderBrandfolders() {
    if (this.state.loading) {
      return (
        <div className="adobe-notification">
          <img
            alt="loading icon"
            className="bff-spinner-gif"
            src="https://cdn.bfldr.com/27C9EC93/at/qdipip-a094w0-9udpnk/bff-spinner-gif"
          />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className="adobe-notification">
          <span className="bff-warning" />
          <p>Unable to load Brandfolders at this time</p>
        </div>
      );
    }

    if (this.state.brandfolders.length === 0 && this.state.orphanCollections.length === 0) {
      return (
        <div className="adobe-notification">
          <span className="bff-comment" />
          <p>No Brandfolders to choose from</p>
        </div>
      );
    }

    const brandfolders = this.state.brandfolders;
    const brandfolder_list = brandfolders.map(({ brandfolder, collections }) => (
      <div key={`${brandfolder.id}-list`}>
        <li
          key={brandfolder.id}
          onClick={() => this.props.selectResource(brandfolder, "brandfolder")}
          onKeyPress={keyPressHelper(() => this.props.selectResource(brandfolder, "brandfolder"))}
          role="button"
          tabIndex="0"
        >
          <Brandfolder
            name={brandfolder.attributes.name}
          />
        </li>
        <ul className="adobe-collection-list">
          {collections.map((collection) => (
            <li
              key={collection.id}
              onClick={() => this.props.selectResource(collection, "collection")}
              onKeyPress={keyPressHelper(() => this.props.selectResource(collection, "collection"))}
              role="button"
              tabIndex="0"
            >
              <Brandfolder
                name={collection.attributes.name}
              />
            </li>
          ))}
        </ul>
      </div>
    ));
    const orphanCollectionList = this.state.orphanCollections.length > 0 && (
      <>
        <li
          key="other-collections"
        >
          <Brandfolder
            name="Other Collections"
          />
        </li>
        <ul className="adobe-collection-list">
          {this.state.orphanCollections.map((collection) => (
            <li
              key={collection.id}
              onClick={() => this.props.selectResource(collection, "collection")}
              onKeyPress={keyPressHelper(() => this.props.selectResource(collection, "collection"))}
              role="button"
              tabIndex="0"
            >
              <Brandfolder
                name={collection.attributes.name}
              />
            </li>
          ))}
        </ul>
      </>
    );
    return <ul>{brandfolder_list}{orphanCollectionList}</ul>;
  }

  render() {
    return (
      <div>
        {this.renderBackButtonOrNotification()}
        <div className="adobe-brandfolder-list">
          {this.renderBrandfolders()}
        </div>
      </div>
    );
  }
}
