import React from "react";
import PropTypes from "prop-types";
import VideoWatermarkUppy from '@components/common/uppy/video_watermark_uppy';
import Tooltip from "@components/common/tooltip/main";
import WatermarkAdjustments from './watermark_adjustments';
import WatermarkOverlay from './watermark_overlay';
import './styles/main';

function WatermarkSettings(props) {
  const hasWatermark = props.watermark && props.watermark.url
    && props.watermark_height && props.watermark_width;

  const updateWatermark = (file) => {
    let { height, width } = file;
    if (height > props.attachment.height) {
      // Prevent watermarks bigger than video;
      width = parseInt(width * (props.attachment.height / height), 10);
      height = props.attachment.height;
    }
    props.updateWatermark(file);
    props.updateWatermarkHeight(height);
    props.updateWatermarkWidth(width);
  };

  const watermarkUpload = (
    <div className="video-label">Watermark Image &nbsp;
      <Tooltip
        tooltipId="watermark-info"
        tooltipContent="Overlay an image onto this video,
          then selectively adjust the image's size, opacity, and position."
      >
        <span className="bff-tool-tip" />
      </Tooltip>
      <VideoWatermarkUppy uploadType="video-watermark" onUpload={updateWatermark}/>
    </div>
  );

  return hasWatermark ? (
    <React.Fragment>
      <WatermarkAdjustments {...props} />
      <WatermarkOverlay {...props} />
    </React.Fragment>
  ) : watermarkUpload;
}

WatermarkSettings.propTypes = {
  attachment: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }).isRequired,
  updateWatermark: PropTypes.func.isRequired,
  updateWatermarkHeight: PropTypes.func.isRequired,
  updateWatermarkWidth: PropTypes.func.isRequired,
  watermark: PropTypes.shape({
    url: PropTypes.string
  }),
  watermark_height: PropTypes.number,
  watermark_width: PropTypes.number
};

WatermarkSettings.defaultProps = {
  watermark: {},
  watermark_height: null,
  watermark_width: null
};

export default WatermarkSettings;
