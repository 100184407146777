/* eslint-disable */
export enum Locale {
  Bulgarian = 'bg',
  Czech = 'cs',
  German = 'de',
  Greek = 'el',
  English = 'en',
  Spanish = 'es',
  Finnish = 'fi',
  French = 'fr',
  Hungarian = 'hu',
  Italian = 'it',
  Japanese = 'ja',
  Korean = 'ko',
  Dutch = 'nl',
  NorwegianBokmal = 'nb',
  Polish = 'pl',
  Portuguese = 'pt',
  Romanian = 'ro',
  Russian = 'ru',
  Swedish = 'sv',
  Thai = 'th',
  Turkish = 'tr',
  Vietnamese = 'vi',
  Chinese = 'zh',
  ChineseChina = 'zh-cn', // not currently used in BF
  ChineseTaiwan = 'zh-tw' // not currently used in BF
}

export interface LanguageMapEntry {
  ISOCode: string;
  language: string;
  locale: Locale;
}

let languagesMap: LanguageMapEntry[] = []; // defining here to make available for helper functions below in this file
export default languagesMap = [
  {
    ISOCode: "BG",
    language: "български език",
    locale: Locale.Bulgarian,
  },
  {
    ISOCode: "CS",
    language: "český jazyk",
    locale: Locale.Czech,
  },
  {
    ISOCode: "DE",
    language: "Deutsch",
    locale: Locale.German,
  },
  {
    ISOCode: "EL",
    language: "ελληνικά",
    locale: Locale.Greek,
  },
  {
    ISOCode: "EN",
    language: "English",
    locale: Locale.English,
  },
  {
    ISOCode: "ES",
    language: "Español",
    locale: Locale.Spanish,
  },
  {
    ISOCode: "FI",
    language: "Suomi",
    locale: Locale.Finnish,
  },
  {
    ISOCode: "FR",
    language: "Français",
    locale: Locale.French,
  },
  {
    ISOCode: "HU",
    language: "magyar",
    locale: Locale.Hungarian,
  },
  {
    ISOCode: "IT",
    language: "Italiano",
    locale: Locale.Italian,
  },
  {
    ISOCode: "JA",
    language: "日本語",
    locale: Locale.Japanese,
  },
  {
    ISOCode: "KO",
    language: "한국어",
    locale: Locale.Korean,
  },
  {
    ISOCode: "NL",
    language: "Nederlands",
    locale: Locale.Dutch,
  },
  {
    ISOCode: "NO",
    language: "Norsk",
    locale: Locale.NorwegianBokmal,
  },
  {
    ISOCode: "PL",
    language: "Polski",
    locale: Locale.Polish,
  },
  {
    ISOCode: "PT",
    language: "Português",
    locale: Locale.Portuguese,
  },
  {
    ISOCode: "RO",
    language: "Română",
    locale: Locale.Romanian,
  },
  {
    ISOCode: "RU",
    language: "Русский",
    locale: Locale.Russian,
  },
  {
    ISOCode: "SV",
    language: "Svenska",
    locale: Locale.Swedish,
  },
  {
    ISOCode: "TH",
    language: "ไทย",
    locale: Locale.Thai,
  },
  {
    ISOCode: "TR",
    language: "Türkçe",
    locale: Locale.Turkish,
  },
  {
    ISOCode: "VI",
    language: "Tiếng Việt",
    locale: Locale.Vietnamese,
  },
  {
    ISOCode: "ZH",
    language: "中文",
    locale: Locale.Chinese,
  }
];

export const findCurrentLanguageInMap = (locale: string, allowedLanguages?: LanguageMapEntry[]): LanguageMapEntry | undefined => {
  const targetLanguagesMap = allowedLanguages || languagesMap;
  for (let i = 0; i < targetLanguagesMap.length; i += 1) {
    if (targetLanguagesMap[i].locale === locale) {
      return targetLanguagesMap[i];
    }
  }
  return undefined;
};

export const determineCurrentLanguage = (): LanguageMapEntry | undefined => {
  const { staticSiteLocale } = BFG.locales;

  if (findCurrentLanguageInMap(staticSiteLocale)) {
    return findCurrentLanguageInMap(staticSiteLocale);
  }

  const language = staticSiteLocale.split('-')[0];

  return findCurrentLanguageInMap(language);
};
