import { StandardDialog, StandardRadio } from '@brandfolder/react';
import { t } from '@lingui/macro';
import { PickerOptions } from 'filestack-js/build/main/lib/picker';
import React, { FunctionComponent, ReactNode, useState } from 'react';

import { UploadArea, UploadAreaProps } from './UploadArea';

interface GifWizardProps {
  id: string;
  open: boolean;
  setOpen: SetStateDispatch<boolean>;
  uploaderProps: UploadAreaProps;
  title: string;
  gifHelpText?: ReactNode;
  stillHelpText?: ReactNode;
}

export const GifWizard: FunctionComponent<GifWizardProps> = ({
  gifHelpText = null,
  id,
  open,
  setOpen,
  stillHelpText = null,
  title,
  uploaderProps
}) => {
  const [gif, setGif] = useState(false);

  const stillImagePickerOptions: PickerOptions = {
    ...uploaderProps.pickerOptions,
    accept: ['image/jpeg', 'image/png', 'image/webp']
  };

  const gifPickerOptions: PickerOptions = {
    ...uploaderProps.pickerOptions,
    accept: ['image/gif'],
    transformations: { crop: false }
  };

  return (
    <StandardDialog
      className="gif-wizard"
      contentCentered
      id={`gif-wizard--${id}`}
      labels={{
        closeButtonLabel: t`Close`
      }}
      open={open}
      setOpen={setOpen}
      showFooter={false}
      title={title}
    >
      <StandardRadio
        helpText={stillHelpText}
        id={`header-still--${id}`}
        inputProps={{
          checked: !gif
        }}
        label={t`Upload a still image`}
        onChange={(): void => { setGif(false); }}
        value="still"
      />
      {!gif && (
        <div className="customization-image-upload-area__container" data-testid="still-image-upload-area">
          <UploadArea { ...uploaderProps } pickerOptions={stillImagePickerOptions} />
        </div>
      )}
      <StandardRadio
        helpText={gifHelpText}
        id={`header-gif--${id}`}
        inputProps={{ checked: gif }}
        label={t`Upload a GIF`}
        onChange={(): void => { setGif(true); }}
        value="gif"
      />
      {gif && (
        <div className="customization-image-upload-area__container" data-testid="gif-upload-area">
          <UploadArea { ...uploaderProps } pickerOptions={gifPickerOptions} />
        </div>
      )}
    </StandardDialog>
  );
};
