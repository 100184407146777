// VARIANT COMPONENTS
export { StandardRadioButtonGroup } from './standard/main';

// TYPES

// Variant TYPES - Standard
export interface RadioButton {
  id: string;
  copy?: string;
  disabled?: boolean;
  value?: string | string[] | number;
}
