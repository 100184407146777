import React from 'react';
import PropTypes from 'prop-types';

const SidebarCard = ({ active, checkedInStatus, element, cardType, updateActiveCard }) => {
  const name = cardType === 'asset' ? element.attributes.name : element.attributes.filename;

  return (
    <li
      className={`attachment-sidebar__card ${active && 'active'}`}
      onClick={!active ? () => updateActiveCard({ id: element.id, type: cardType }) : undefined}
    >
      <div className="attachment-sidebar__card--details">
        <div className="attachment-sidebar__card--image-container">
          <img
            src={element.attributes.thumbnail_url || ""}
            alt="asset thumbnail"
            className="attachment-sidebar__card--image"
            data-private
          />
        </div>
        <div className="attachment-sidebar__card--name-container">
          <p className="attachment-sidebar__card--name">{name}</p>
          <span>{element.attributes.extension || ""}</span>
        </div>
      </div>
      {checkedInStatus === "checked_out" ? (
        <div className="attachment-sidebar__card--checkedin-status">
          <span className="bff-check-out" />
          <span>Checked out</span>
        </div>
      ) : null}
    </li>
  );
};

SidebarCard.propTypes = {
  active: PropTypes.bool,
  cardType: PropTypes.oneOf(['asset', 'attachment']).isRequired,
  checkedInStatus: PropTypes.string,
  element: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      extension: PropTypes.string, // attachment
      filename: PropTypes.string, // attachment
      name: PropTypes.string, // asset
      thumbnail_url: PropTypes.string, // asset & attachment
    })
  }),
  updateActiveCard: PropTypes.func.isRequired,
};

SidebarCard.defaultProps = {
  active: false,
  checkedInStatus: '',
  element: null,
};

export default SidebarCard;
