// VARIANT COMPONENTS
export { StandardCheckbox } from './standard/main';

export { sortCheckboxes } from './helpers';

// TYPES

// Variant TYPES - Standard


// Base TYPES
export interface Checkbox {
  ariaLabel?: string;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  dontWrapText?: boolean;
  id?: string;
  labelCopy?: React.ReactNode;
  labelPosition?: LabelPositions;
  name?: string;
  onChange?: (id: string | undefined, e: InputChangeEvent) => void;
  partiallyChecked?: boolean;
  size?: Sizes;
  tabIndex?: number;
  value?: string | string[] | number;
}

export enum LabelPositions {
  Left = 'left',
  Right = 'right',
}

export enum Sizes {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}
