import { t } from '@lingui/macro';

import { UseFetchOptions } from '@api/ApiHelper';
import { Relationships } from '@api/v4/ApiResponseTypes';
import {
  PermissionsGetResponse,
  UserGroupResource
} from '@api/v4/user_groups/user-group-permissions/UserGroupPermissionsTypes';
import { Brandfolder, Portal, ServerResourceType } from '@components/bulk_management/user-settings/resource-sidebar';

export const TABLE_DEFAULT_PER = 10;

export interface AdminableResourcesResponse {
  brandfolders: Brandfolder[];
  portals: Portal[];
}

export interface ResourceTypeKeyPair {
  type: ServerResourceType;
  key: string;
}

export const basePermissionFetchOptions = (userGroupKey: string): UseFetchOptions => ({
  url: `/api/v4/user_groups/${userGroupKey}/user_group_permissions`,
  fields: ['permission_level', 'created_at'],
  include: 'permissible',
  params: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    sort_by: 'created_at',
    order: 'desc',
    per: TABLE_DEFAULT_PER,
    page: 1,
  },
  fetchOnMount: false
});

export const getDisplayResourceType = (
  resourceType: Relationships['permissible']['data']['type'],
  resourceAttributes: UserGroupResource,
  isGetty: boolean
): string => {
  switch (resourceType) {
    case 'brandfolders' :
      return isGetty ? t`Library` : t`Brandfolder`;
    case 'collections' :
      if (resourceAttributes.parent_id) {
        return t`Subcollection`;
      }
      if (resourceAttributes.is_workspace) {
        return t`Workspace`;
      }
      return t`Collection`;
    case 'brandguides' :
      return t`Brandguide`;
    case 'portals' :
      return t`Portal`;
  }
};

const pluralToSingularType = (resourceType: Relationships['permissible']['data']['type']): ServerResourceType => {
  return resourceType.slice(0, -1) as ServerResourceType;
};

export const permissionResponseToTypeKeyPairs = (response: PermissionsGetResponse): ResourceTypeKeyPair[] => (
  response.included.map((includedResource) => ({
    key: includedResource.id,
    type: pluralToSingularType(includedResource.type)
  }))
);
