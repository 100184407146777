export const localizeNumber = (number, locale) => {
  const localeFallback = 'en';
  const localeArgument = locale || BFG?.locales?.staticSiteLocale || 'en';
  return new Intl.NumberFormat([localeArgument, localeFallback])?.format(number) || number;
};

// Locales available in moment, from https://momentjs.com/ on 9/24/19
// key: Brandfoler locale, value: moment locale
// key modified as needed to match Brandfolder locales
const momentLocalesMap = {
  af: "af", // Afrikaans
  sq: "sq", // Albanian
  ar: "ar", // Arabic
  "ar-dz": "ar-dz", // Arabic (Algeria)
  "ar-kw": "ar-kw", // Arabic (Kuwait)
  "ar-ly": "ar-ly", // Arabic (Lybia)
  "ar-ma": "ar-ma", // Arabic (Morocco)
  "ar-sa": "ar-sa", // Arabic (Saudi Arabia)
  "ar-tn": "ar-tn", // Arabic (Tunisia)
  "hy-am": "hy-am", // Armenian
  az: "az", // Azerbaijani
  bm: "bm", // Bambara
  eu: "eu", // Basque
  be: "be", // Belarusian
  bn: "bn", // Bengali
  bs: "bs", // Bosnian
  br: "br", // Breton
  bg: "bg", // Bulgarian
  my: "my", // Burmese
  km: "km", // Cambodian
  ca: "ca", // Catalan
  tzm: "tzm", // Central Atlas Tamazight
  "tzm-latn": "tzm-latn", // Central Atlas Tamazight Latin
  zh: "zh-cn", // Chinese (China) ***MODIFIED KEY***
  "zh-hk": "zh-hk", // Chinese (Hong Kong)
  "zh-tw": "zh-tw", // Chinese (Taiwan)
  cv: "cv", // Chuvash
  hr: "hr", // Croatian
  cs: "cs", // Czech
  da: "da", // Danish
  nl: "nl", // Dutch
  "nl-be": "nl-be", // Dutch (Belgium)
  "en-au": "en-au", // English (Australia)
  "en-ca": "en-ca", // English (Canada)
  "en-ie": "en-ie", // English (Ireland)
  "en-il": "en-il", // English (Israel)
  "en-nz": "en-nz", // English (New Zealand)
  "en-SG": "en-SG", // English (Singapore)
  "en-gb": "en-gb", // English (United Kingdom)
  en: "en", // English (United States)
  eo: "eo", // Esperanto
  et: "et", // Estonian
  fo: "fo", // Faroese
  fi: "fi", // Finnish
  fr: "fr", // French
  "fr-ca": "fr-ca", // French (Canada)
  "fr-ch": "fr-ch", // French (Switzerland)
  fy: "fy", // Frisian
  gl: "gl", // Galician
  ka: "ka", // Georgian
  de: "de", // German
  "de-at": "de-at", // German (Austria)
  "de-ch": "de-ch", // German (Switzerland)
  el: "el", // Greek
  gu: "gu", // Gujarati
  he: "he", // Hebrew
  hi: "hi", // Hindi
  hu: "hu", // Hungarian
  is: "is", // Icelandic
  id: "id", // Indonesian
  ga: "ga", // Irish or Irish Gaelic
  it: "it", // Italian
  "it-ch": "it-ch", // Italian (Switzerland)
  ja: "ja", // Japanese
  jv: "jv", // Javanese
  kn: "kn", // Kannada
  kk: "kk", // Kazakh
  tlh: "tlh", // Klingon
  "gom-latn": "gom-latn", // Konkani Latin script
  ko: "ko", // Korean
  ku: "ku", // Kurdish
  ky: "ky", // Kyrgyz
  lo: "lo", // Lao
  lv: "lv", // Latvian
  lt: "lt", // Lithuanian
  lb: "lb", // Luxembourgish
  mk: "mk", // Macedonian
  "ms-my": "ms-my", // Malay
  ms: "ms", // Malay
  ml: "ml", // Malayalam
  dv: "dv", // Maldivian
  mt: "mt", // Maltese (Malta)
  mi: "mi", // Maori
  mr: "mr", // Marathi
  mn: "mn", // Mongolian
  me: "me", // Montenegrin
  ne: "ne", // Nepalese
  se: "se", // Northern Sami
  nb: "nb", // Norwegian Bokmål
  nn: "nn", // Nynorsk
  fa: "fa", // Persian
  pl: "pl", // Polish
  pt: "pt", // Portuguese
  "pt-br": "pt-br", // Portuguese (Brazil)
  "x-pseudo": "x-pseudo", // Pseudo
  "pa-in": "pa-in", // Punjabi (India)
  ro: "ro", // Romanian
  ru: "ru", // Russian
  gd: "gd", // Scottish Gaelic
  sr: "sr", // Serbian
  "sr-cyrl": "sr-cyrl", // Serbian Cyrillic
  sd: "sd", // Sindhi
  si: "si", // Sinhalese
  sk: "sk", // Slovak
  sl: "sl", // Slovenian
  es: "es", // Spanish
  "es-do": "es-do", // Spanish (Dominican Republic)
  "es-us": "es-us", // Spanish (United States)
  sw: "sw", // Swahili
  sv: "sv", // Swedish
  "tl-ph": "tl-ph", // Tagalog (Philippines)
  tg: "tg", // Tajik
  tzl: "tzl", // Talossan
  ta: "ta", // Tamil
  te: "te", // Telugu
  tet: "tet", // Tetun Dili (East Timor)
  th: "th", // Thai
  bo: "bo", // Tibetan
  tr: "tr", // Turkish
  uk: "uk", // Ukrainian
  ur: "ur", // Urdu
  "ug-cn": "ug-cn", // Uyghur (China)
  uz: "uz", // Uzbek
  "uz-latn": "uz-latn", // Uzbek Latin
  vi: "vi", // Vietnamese
  cy: "cy", // Welsh
  yo: "yo", // Yoruba Nigeria
  ss: "ss", // siSwati
};

// convert to locale available in moment
// e.g. 'zn' not available in moment, but 'zh-cn' is
export const getMomentLocale = (locale = window.locale || 'en') => (
  momentLocalesMap[locale] || 'en'
);
