import React, { FunctionComponent, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';

import { fetchJson } from '@api/ApiHelper';
import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';

import { update as updateResource } from '@api/v4/resources/resources';

import useDebounce from '@components/common/custom_hooks/useDebounce';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import {
  EnableDisable
} from '@components/settings/brandfolder/advanced-tab/default-share-manifest-expiration/EnableDisable';

import ExpirationValueTextField from './ExpirationValueTextfield';
import './styles/DefaultShareManifestExpiration.scss';

interface DefaultShareManifestExpirationProps {
  defaultEnabled: number | null;
}

interface Brandfolder {
  name: string;
  privacy: string;
  slug: string;
  tagline: string;
  default_share_manifest_expiration_seconds?: number | null;
}
type DefaultShareManifestExpirationResponse = ApiDatumResponse<Brandfolder, 'brandfolders'>;

const DefaultShareManifestExpiration: FunctionComponent<DefaultShareManifestExpirationProps> = () => {
  const [enabled, setEnabled] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  //Initial value retrieved from DB
  const [defaultExpirationValue, setDefaultExpirationValue] = useState(null);
  //Input value in UI
  const [expirationValue, setExpirationValue] = useState(null);
  const debouncedValue = useDebounce({
    delay: 750,
    disableDebounce: process?.env?.NODE_ENV === 'circle',
    value: expirationValue,
  });

  const handleUpdateResourceSubmit = async (): Promise<void> => {
    try {
      await updateResource({
        defaultShareManifestExpiration: debouncedValue,
        key: BFG.resource.key,
        resourceType: BFG.resource.type,
      });

      Notify.create({
        title: t`Default expiration of share links updated.`,
        type: 'success'
      });
    } catch {
      Notify.create({
        title: t`Error updating default expiration of share links. Please contact support.`,
        type: 'error'
      });
    }
  };

  const getDefaultExpiration = async (): Promise<void> => {
    const brandfolder = await fetchJson<DefaultShareManifestExpirationResponse>({
      url: `/api/v4/brandfolders/${BFG.brandfolder_key}`,
      fields: 'default_share_manifest_expiration_seconds',
    });
    const defaultExpiration: number = brandfolder.data.attributes?.default_share_manifest_expiration_seconds;

    if (!!defaultExpiration) {
      setEnabled(true);
      setDefaultExpirationValue(defaultExpiration);
      setExpirationValue(defaultExpiration);
    }
  };

  const resetState = (): void => {
    setDefaultExpirationValue(null);
    setExpirationValue(null);
  };

  useEffect(() => {
    if (!!debouncedValue && debouncedValue !== defaultExpirationValue) {
      handleUpdateResourceSubmit();
    } else if (!enabled && !expirationValue && submitDisabled) {
      handleUpdateResourceSubmit();
    }
  }, [debouncedValue]);

  useEffect(() => {
    getDefaultExpiration();
  }, []);

  return (
    <I18nProviderWrapper>
      <div className="default-share-manifest-expiration-container">
        <EnableDisable
          enabled={enabled}
          id="default-share-manifests-expiration"
          resetState={resetState}
          setEnabled={setEnabled}
          setSubmitDisabled={setSubmitDisabled}
        />
        {enabled &&
          (<>
            <div className="default-share-manifest-expiration-label-container">
              <div className="quarter-column"></div>
              <p className="three-quarters-column default-share-manifest-expiration-label">
                <Trans>New share links will expire in:</Trans>
              </p>
            </div>
            <div className="default-share-manifest-expiration-inputs-container">
              <div className="quarter-column"></div>
                <div className="three-quarters-column default-share-manifest-expiration-inputs">
                  <ExpirationValueTextField
                    defaultExpirationValue={expirationValue}
                    setDefaultExpirationValue={setExpirationValue}
                  />
                </div>
            </div>
          </>)
        }
      </div>
    </I18nProviderWrapper>
  );
};

export default DefaultShareManifestExpiration;
