import { HelpText, SearchTextfield, StandardTable, StandardTableColumn, StandardTableRow } from '@brandfolder/react';
import { Plural, t, Trans } from '@lingui/macro';
import { decode } from 'html-entities';
import React, { useEffect, useState, FunctionComponent, useLayoutEffect } from 'react';

import { useFetch } from '@api/ApiHelper';
import { IndexDetailedUsersResponse, UserRoles } from '@api/v4/private/resources/users';

import { defaultPagination, mapMetaToPagination, Pagination } from '@components/bulk_management/shared';
import {
  getUserPermissionFromUser,
  resourceUserBlurb,
  userPermissionOptions
} from '@components/bulk_management/user-settings/helpers';
import { Brandfolder, ResourceType } from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';
import { MoreActions } from '@components/bulk_management/user-settings/users-table/MoreActions';
import { UserDetails } from '@components/bulk_management/user-settings/users-table/UserDetails';
import { UserPermission } from '@components/bulk_management/user-settings/users-table/UserPermission';
import useDebounce from '@components/common/custom_hooks/useDebounce';

import { TextButton } from '@components/library/button';
import { ListDropdown } from '@components/library/dropdown';

import '../styles/users-table.scss';

export interface UsersTableProps {
  brandfolders: Brandfolder[];
  reloadUsers: boolean;
  resourceName: string;
  selectedExtendedResourceType: ResourceType;
  selectedResourceKey: string;
  selectedResourceType: 'organization' | 'brandfolder' | 'collection' | 'portal' | 'brandguide';
  setReloadPlanLimits: SetStateDispatch<boolean>;
  setReloadUsers: SetStateDispatch<boolean>;
  setUsersLoaded: SetStateDispatch<boolean>;
}

type FilterValues = UserRoles.Guest | UserRoles.Collaborator | UserRoles.Admin | 'all';

export const UsersTable: FunctionComponent<UsersTableProps> = ({
  brandfolders,
  reloadUsers,
  resourceName,
  selectedExtendedResourceType,
  selectedResourceKey,
  selectedResourceType,
  setReloadPlanLimits,
  setReloadUsers,
  setUsersLoaded,
}) => {
  const [rows, setRows] = useState<StandardTableRow[]>([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState<FilterValues>('all');
  const [totalPages, setTotalPages] = useState(0);

  const debouncedSearch = useDebounce({
    delay: 600,
    disableDebounce: process?.env?.NODE_ENV === 'circle',
    value: searchValue
  });

  const usersFetch = useFetch<IndexDetailedUsersResponse>({
    url: `/api/v4/private/${selectedResourceType}s/${selectedResourceKey}/users`,
    fields: 'user_count',
    params: {
      page: pagination.currentPage,
      per: pagination.per,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      permission_level: filter === 'all' ? undefined : filter,
      search: debouncedSearch === '' ? undefined : debouncedSearch
    },
    fetchOnMount: false // will fetch on mount due to pagination effect below
  });

  useEffect(() => {
    if (selectedResourceKey && selectedResourceType && pagination.currentPage && pagination.per) {
      setUsersLoaded(false);
      usersFetch.fetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResourceKey, selectedResourceType, pagination.currentPage, pagination.per, debouncedSearch, filter]);

  useEffect(() => {
    if (reloadUsers) {
      setReloadUsers(false);
      setUsersLoaded(false);
      usersFetch.fetch();
    }
  }, [reloadUsers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (usersFetch.response) {
      const populatedRows = usersFetch.response.data.map((user) => {
        const userPermission = getUserPermissionFromUser(user);
        return ({
          user: <UserDetails user={user.attributes} />,
          permissionLevel: (
            <UserPermission
              isSelf={BFG.currentUser?.user_id === user.id}
              permissionLevel={userPermission.permission_level}
              setReloadPlanLimits={setReloadPlanLimits}
              userPermissionKey={userPermission.key}
              usersFetch={usersFetch}
            />
          ),
          moreActions: (
            <MoreActions
              brandfolders={brandfolders}
              extendedResourceType={selectedExtendedResourceType}
              insightsUrl={userPermission.insights_url}
              isSelf={BFG.currentUser?.user_id === user.id}
              selectedResourceKey={selectedResourceKey}
              selectedResourceType={selectedResourceType}
              setReloadPlanLimits={setReloadPlanLimits}
              userKey={user.id}
              userPermissionKey={userPermission.key}
              usersFetch={usersFetch}
            />
          )
        });
      });
      setRows(populatedRows);
      setTotalPages(usersFetch?.response.meta.total_pages || 0);
    }
  }, [usersFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    setUsersLoaded(true);
  }, [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadingOrError = usersFetch.error || usersFetch.loading;
  const columns: StandardTableColumn[] = [{
    children: <Trans>User</Trans>,
    heading: <Trans>User</Trans>,
    rowKey: 'user',
    width: '58%'
  }, {
    children: <Trans>Permission level</Trans>,
    heading: <Trans>Permission level</Trans>,
    rowKey: 'permissionLevel',
  }, {
    rowKey: 'moreActions',
    width: selectedResourceType !== 'brandfolder' && selectedResourceType !== 'collection' ? '12%' : undefined
  }];

  /**
   * usersFetch returns only the users with individual access to the resource
   * while user_count represents the total number of unique users with access to the resource -
   * both users with individual access and users with access via a user group.
   * Thus, the number of users with access through a user group is the
   * difference between all users with access (user_count)
   * and the number of users returned by usersFetch (meta.total_count)
   */
  const userGroupUserCount = usersFetch.response
    ? usersFetch.response.user_count - usersFetch.response.meta.total_count
    : 0;
  const userCount = usersFetch.response?.user_count;
  const displayResourceName = decode(resourceName);

  return (
    <div className="manage-users-table">
      <div className="manage-users-table__heading">
        <div className="manage-users-table__heading--description">
          <h3 className="manage-users-table__users-count">
            {usersFetch.response
              ? (
                <Plural
                  one={`${userCount} ${displayResourceName} User`}
                  other={`${userCount} ${displayResourceName} Users`}
                  value={userCount}
                />
              ) : <Trans>{displayResourceName} Users</Trans>}
          </h3>
          {userGroupUserCount > 0 && (
            <HelpText id={'user-group-help-text'}>
              <Plural
                one={`${userGroupUserCount} user has access via user groups.`}
                other={`${userGroupUserCount} users have access via user groups.`}
                value={userGroupUserCount}
              />
            </HelpText>
          )}
          <p className="manage-users-table__inheritance-blurb">
            {resourceUserBlurb()[selectedExtendedResourceType]}
          </p>
        </div>
        <div className="manage-users-table__filters">
          <SearchTextfield
            className="manage-users-table__search"
            id="manage-users-search"
            label={<Trans>Search users</Trans>}
            onChange={(e: InputChangeEvent): void => { setSearchValue(e.target.value); }}
            placeholder={t`Search`}
            value={searchValue}
          />
          <ListDropdown
            label={<Trans>Filter by</Trans>}
            onChange={(selectedOption): void => {
              setFilter(selectedOption.value as FilterValues);
            }}
            options={[
              { label: <Trans>All</Trans>, value: 'all' },
              ...userPermissionOptions()
            ]}
            value={filter}
            virtualizeOptions={false}
          />
        </div>
      </div>
      <StandardTable
        caption={t`${displayResourceName}: user details`}
        columns={columns}
        empty={rows.length === 0 && !loadingOrError}
        emptyContent={(
          <div className="manage-users-table__empty-state">
            <p><Trans>No users to display.</Trans></p>
          </div>
        )}
        error={!!usersFetch.error}
        errorContent={(
          <div>
            <p><Trans>Oops! Something went wrong.</Trans></p>
            <TextButton onClick={(): void => { usersFetch.fetch(); }}>
              <Trans>Try again</Trans>
            </TextButton>
          </div>
        )}
        id="manage-users-table"
        loaderLabel={t`Loading users`}
        loading={usersFetch.loading}
        rows={rows}
        scrollX={false}
      />
      {usersFetch.loading && (
        <div className="manage-users-table__loading-state" />
      )}
      <div className="manage-users-table__pagination">
        {totalPages > 1 && !usersFetch.error && !usersFetch.loading && (
          <Pagination
            {...mapMetaToPagination(usersFetch.response?.meta, pagination.per)}
            onPageChange={(currentPage): void => {
              setPagination({ ...pagination, currentPage });
            }}
            onPerChange={(per): void => {
              setPagination({ ...pagination, per });
            }}
          />
        )}
      </div>
    </div>
  );
};
