import { StandardButton, StandardTextarea } from '@brandfolder/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { create as submitApproval } from '@api/v4/resources/share_manifests';
import renderModal from '@components/show_page/modals/renderModal';

import './styles/download-access-response.scss';

const schema = object().shape({
  message: string()
});

const DownloadAccessResponse = ({
  closeModal,
  requestDownloadName,
  requestDownloadRequestee,
  selectedAssetKeys,
}) => {
  const defaultValues = { message: '' };
  const { handleSubmit, register } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });
  const [submitting, setSubmitting] = useState(false);

  // close modal on unmount
  useEffect(() => (() => closeModal()), []);

  const assetQuantity = selectedAssetKeys.size;
  const successOptions = {
    type: 'success',
    title: t`Success!`,
    text: plural(assetQuantity, {
      one: `You have approved # asset for download from the ${requestDownloadName} Share Link`,
      other: `You have approved # assets for download from the ${requestDownloadName} Share Link`
    }),
    showCancelButton: true,
    showConfirmButton: false,
    cancelButtonText: t`Close`,
    allowOutsideClick: true,
    customClass: 'swal-request-download-confirmation',
  };

  return (
    <div className="modal-content-wrapper request-download-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-request-download icon" />
        <h3 className="modal-title"><Trans>Download Access Approval</Trans></h3>
        <button
          className="close-button"
          onClick={closeModal}
          type="button"
        >
          <span className="bff-close" />
        </button>
      </div>
      <div className="modal-content-wrapper__body">
        <div>
          <h3 className="body-title">
            <Trans>Send {requestDownloadRequestee} a message to accompany your approval.</Trans>
          </h3>
          <form
            id="download-access-response-form"
            onSubmit={handleSubmit((values) => {
              setSubmitting(true);
              const { key, type } = BFG.resource;
              submitApproval({
                key,
                type,
                assetKeys: [...selectedAssetKeys],
                downloadRequestKey: BFG.downloadRequestKey,
                message: values.message,
                name: requestDownloadName,
                requestApproval: true
              })
              .then(() => {
                setSubmitting(false);
                window.swal(successOptions);
              })
              .catch((err) => {
                setSubmitting(false);
                console.log(err);
              });
            })}
          >
            <StandardTextarea
              {...register('message')}
              disabled={submitting}
              id="message"
              label={<Trans>Message</Trans>}
            />
            <div className="download-access-response__button-container">
              <StandardButton disabled={submitting} loading={submitting} type="submit">
                <Trans>Approve</Trans>
              </StandardButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

DownloadAccessResponse.propTypes = {
  closeModal: PropTypes.func.isRequired,
  requestDownloadName: PropTypes.string.isRequired,
  requestDownloadRequestee: PropTypes.string.isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
};

const ModalComponent = renderModal(DownloadAccessResponse, 'request-download-access-modal');
export default ModalComponent;
