import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import { DropTarget, DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { getElementIsTab } from '@components/library/tabs';
import oldType from '@helpers/oldType';
import { Brandfolder, Collection } from '@helpers/show_page_helpers';

import SectionsContext from '../sectionsContext';

import Card from './card';

class Asset extends React.PureComponent {
  componentDidMount() {
    this.props.connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  get leftArrow() {
    return document.getElementsByClassName("left")[0];
  }

  get rightArrow() {
    return document.getElementsByClassName("right")[0];
  }

  assetType = (useOldType = true) => {
    const { asset, section } = this.props;
    const type = section?.default_asset_type || asset.attributes.type;
    return useOldType ? oldType(type) : type;
  }

  visitAsset = (assetKey, direction, showSections) => {
    // showSections passed as argument (vs. using prop directly) so that it updates when props update
    // Note: DO NOT USE PROPS since these need to be dynamic during Navigation after the first
    // asset is shown, must be done in jQuery to play nicely with existing asset pipeline functions
    if (BFG.editTabUnsavedChanges) {
      const eventDetails = { direction };
      BF.fx.dispatchWindowEvent('unsavedChangesAlert', null, eventDetails);
      return;
    }

    BF.dialog.unmountTabComponents();

    const { parentBrandfolders, section, userViewOptions } = this.props;
    const slug = Brandfolder.slug ? Brandfolder.slug : parentBrandfolders.find((brandfolder) => (
      brandfolder.id === section.brandfolder_id
    )).slug;
    const assetName = assetKey
      ? (document.getElementById(assetKey).getElementsByClassName("asset-name")?.first?.innerHTML || "")
      : "";
    const sectionKey = section.section_key;
    const urlParamsObj = { ugt_locale: userViewOptions.selectedUGTLocale || '' };

    if (Collection.slug) {
      urlParamsObj.collection_slug = Collection.slug;
    }

    if (BFG.manifestDigest) {
      urlParamsObj.manifest_digest = BFG.manifestDigest;
    } else if (BFG.downloadRequestKey) {
      urlParamsObj.download_request_key = BFG.downloadRequestKey;
    } else {
      urlParamsObj.edit = true;
    }

    const urlBaseString = `${window.location.protocol}//${window.location.host}/${slug}/sections/${sectionKey}/ui/${this.assetType()}/${assetKey}`;
    const urlParamsString = Object.keys(urlParamsObj).map((param) => `${param}=${urlParamsObj[param]}`).join('&');
    const url = `${urlBaseString}?${urlParamsString}`;

    BF.fullModal = true;
    BF.dialog.render(
      encodeURIComponent(assetName),
      url,
      BF.handlerGroups.showAsset
    );

    this.bindEventsToKeydowns(assetKey, showSections);
    this.bindEventsToArrows(assetKey, showSections);
    this.sendInsights(assetKey);
  };

  handleSelectionClick = (e) => {
    const { asset, position, shiftSelect, toggleSelected } = this.props;

    e.stopPropagation();
    e.persist();

    const viewOnlyAssetKeys = asset.attributes?.view_only ? asset.id : undefined

    if (e.shiftKey) {
      shiftSelect(asset.id, position, viewOnlyAssetKeys);
    } else {
      toggleSelected({
        assetKeys: asset.id,
        viewOnlyAssetKeys
      });
    }
  };

  editAsset = () => {
    const { asset, section, userViewOptions } = this.props;
    const sectionKey = section.section_key;
    const assetType = oldType(asset.attributes.type);
    const urlParamsObj = { ugt_locale: userViewOptions.selectedUGTLocale || '' };

    if (Collection.slug) {
      urlParamsObj.collection_slug = Collection.slug;
    }

    const urlParamsString = Object.keys(urlParamsObj).map((param) => `${param}=${urlParamsObj[param]}`).join('&');
    const urlBaseString = `${Brandfolder.slug}/sections/${sectionKey}/ui/${assetType}/${asset.id}?edit=true`;
    const url = urlBaseString + urlParamsString;

    if (window.getComputedStyle(this.rightArrow).display === "block") {
      this.rightArrow.style.display = "none";
      this.leftArrow.style.display = "none";
    }

    BF.fullModal = true;
    BF.dialog.render(
      encodeURIComponent(`${asset.attributes.name}`),
      url,
      BF.handlerGroups.showAsset,
      "edit"
    );
  };

  bindEventsToArrows(assetKey, showSections) {
    ['right', 'left'].forEach((direction) => {
      const $arrow = $(`.modal-content .${direction}`);
      const adjacentAsset = direction === 'right'
        ? document.getElementById(`${assetKey}`).nextElementSibling
        : document.getElementById(`${assetKey}`).previousSibling;
      const adjacentAssetKey = adjacentAsset ? adjacentAsset.getAttribute("id") : null;

      // add click event to left and right arrows
      if (showSections) {
        $arrow.show(); // TODO update this... currently necessary becuase we hide the left/right arrows when opening asset modal via "Edit" option in asset card actions menu
        $arrow.unbind("click");
        $arrow.on("click", (e) => {
          e.preventDefault();
          if (adjacentAssetKey) {
            this.visitAsset(adjacentAssetKey, direction === 'right' ? 'next' : 'prev', showSections);
          } else {
            BF.dialog.dismiss();
          }
        });
      } else {
        // don't show navigation arrows when sectionless - issues navigating between unlike assets (e.g. text and generic file)
        $arrow.hide();
      }
    });
  }

  bindEventsToKeydowns(assetKey, showSections) {
    // navigate on keydown: left, right and escape
    document.onkeydown = (_e) => {
      const e = _e || window.event;
      if (
        e.target.tagName.toUpperCase() !== "TEXTAREA"
        && e.target.tagName.toUpperCase() !== "INPUT"
        && !$(e.target).hasClass('trumbowyg-editor')
        && !getElementIsTab(e.target)
      ) {
        if (document.getElementsByClassName('modal-asset').length) {
          // don't allow navigation when sectionless - issues navigating between unlike assets (e.g. text and generic file)
          if (e.key === 'ArrowRight' && showSections) {
            const nextAsset = document.getElementById(`${assetKey}`).nextElementSibling;
            const nextAssetKey = nextAsset ? nextAsset.getAttribute("id") : null;
            if (nextAssetKey) {
              this.visitAsset(nextAssetKey, 'next', showSections);
            } else {
              BF.dialog.dismiss();
            }
          } else if (e.key === 'ArrowLeft' && showSections) {
            const prevAsset = document.getElementById(`${assetKey}`).previousSibling;
            const prevAssetKey = prevAsset ? prevAsset.getAttribute("id") : null;
            if (prevAssetKey) {
              this.visitAsset(prevAssetKey, 'prev', showSections);
            } else {
              BF.dialog.dismiss();
            }
          } else if (e.key === 'Escape') {
            e.stopPropagation();
            BF.dialog.dismiss();
          }
        }
      }
    };
  }

  sendInsights(assetKey) {
    Insight.createEvent('viewed', assetKey, "asset");
  }

  render() {
    const {
      asset,
      customFieldsKeys,
      section,
      selected,
      reorderable,
      connectDropTarget,
      connectDragSource,
      isHovered,
      insertCaretDirection,
      onDragComplete,
      enableContactSheets,
      downloadRequestId,
      task,
      userViewOptions,
    } = this.props;

    const {
      showCustomFields,
      showSections,
    } = userViewOptions;

    return (
      <SectionsContext.Consumer>
        {({
          addRemoveSelected,
          editable,
          filtersDrawerOpen,
          listView,
          activeLabelKey,
          activeLabelName,
          labelsDrawerOpen,
          selectedAssetKeys,
          selectedViewOnlyAssetKeys,
          windowDimensions,
        }) => {
          const cardProps = {
            activeLabel: {
              key: activeLabelKey,
              name: activeLabelName,
            },
            addRemoveSelected, // always show custom fields for contact sheets
            allowCustomFields: (BFG.hasFeature('contact_sheets') && Boolean(BFG.manifestDigest)) || showCustomFields,
            asset,
            customFieldsKeys,
            downloadRequestId,
            editAsset: this.editAsset,
            editable,
            enableContactSheets,
            filtersDrawerOpen,
            handleSelectionClick: this.handleSelectionClick,
            insertCaretDirection,
            isHovered,
            listView,
            onDragComplete,
            onVisitAsset: (assetKey) => {
              this.visitAsset(assetKey, null, showSections);
            },
            reorderable,
            section: {
              key: section.section_key,
              type: this.assetType(false),
            },
            selected,
            selectedAssetKeys,
            selectedViewOnlyAssetKeys,
            task,
            userViewOptions,
            viewAsset: (assetKey) => this.visitAsset(assetKey, null, showSections),
            windowDimensions
          };

          let content = (
            <div
              className="card-wrapper"
              data-name={asset.attributes.name}
              data-section={section ? section.section_key : ''}
              data-type={this.assetType()}
              id={asset.id}
            >
              <Card {...cardProps} />
            </div>
          );

          if (labelsDrawerOpen) {
            content = connectDragSource(content);
          }

          if (reorderable) {
            content = connectDropTarget(content);
          }

          return content;
        }}
      </SectionsContext.Consumer>
    );
  }
}

Asset.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      view_only: PropTypes.bool
    }),
  }).isRequired,
  customFieldsKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.bool.isRequired,
  section: PropTypes.shape({
    section_key: PropTypes.string,
    brandfolder_id: PropTypes.number,
    default_asset_type: PropTypes.string,
  }).isRequired,
  reorderable: PropTypes.bool,
  connectDropTarget: PropTypes.func.isRequired,
  insertCaretDirection: PropTypes.oneOf(['', 'top', 'bottom', 'left', 'right']),
  toggleSelected: PropTypes.func.isRequired,
  shiftSelect: PropTypes.func.isRequired,
  enableContactSheets: PropTypes.bool,
  task: PropTypes.shape({}),
  downloadRequestId: PropTypes.number,
  position: PropTypes.number,
  /* eslint-disable react/no-unused-prop-types */
  onMove: PropTypes.func,
  onDrop: PropTypes.func,
  onDragComplete: PropTypes.func,
  connectDragSource: PropTypes.func,
  connectDragPreview: PropTypes.func,
  isHovered: PropTypes.bool,
  isDragging: PropTypes.bool,
  selectedAssetKeys: PropTypes.instanceOf(Set),
  selectedViewOnlyAssetKeys: PropTypes.instanceOf(Set),
  userViewOptions: PropTypes.shape({
    assetsPerPage: PropTypes.number,
    selectedUGTLocale: PropTypes.string,
    showAsGrid: PropTypes.bool,
    showCustomFields: PropTypes.bool,
    showEmptySections: PropTypes.bool,
    showSections: PropTypes.bool,
    sortOption: PropTypes.string,
  }),
  /* eslint-enable react/no-unused-prop-types */
};

Asset.defaultProps = {
  connectDragPreview: () => {},
  connectDragSource: () => {},
  downloadRequestId: null,
  enableContactSheets: false,
  insertCaretDirection: '',
  isDragging: false,
  isHovered: false,
  onDragComplete: () => {},
  onDrop: () => {},
  onMove: () => {},
  position: null,
  reorderable: false,
  selectedAssetKeys: new Set(),
  selectedViewOnlyAssetKeys: new Set(),
  task: undefined,
  userViewOptions: {}
};

const assetTarget = {
  hover(props, monitor) {
    const item = monitor.getItem();
    const hoverId = props.asset.id;
    const pointerOffset = monitor.getClientOffset();

    props.onMove(item, hoverId, pointerOffset);
  },
  drop(props, monitor) {
    const item = monitor.getItem();
    const dropId = props.asset.id;

    props.onDrop(item, dropId);
  },
  canDrop(props, monitor) {
    const item = monitor.getItem();
    // Keep users from dropping between sections for now
    return item.sectionId === props.section.section_key;
  }
};

const dragSource = {
  beginDrag(props) {
    const { index, section, asset, selectedAssetKeys } = props;
    const { name, attachment_count: attachmentCount } = asset.attributes;

    // This is gross, I know, but it's necessary.
    document.querySelector('.labels-drawer-container').classList.add('highlighted');

    return {
      index,
      id: asset.id,
      sectionId: section.section_key,
      name,
      attachmentCount,
      reorder: false,
      selectedAssetKeys,
    };
  },

  endDrag() {
    document.querySelector('.labels-drawer-container').classList.remove('highlighted');
  }
};

const dragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
  connectDragPreview: connect.dragPreview(),
});

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isHovered: monitor.isOver(),
});

export default DropTarget('asset-reorder', assetTarget, dropCollect)(
  DragSource('asset-label', dragSource, dragCollect)(Asset)
);
