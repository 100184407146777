/* eslint-disable react/require-default-props */
import { keyPressHelper } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import styleClassNames from '@styles/modules/section_bar_heading.module.scss';

const AttachmentContentHeader = ({
  activeAttachmentReservations,
  activeTab,
  tabs,
  updateActiveContent,
}) => {
  const tabDisplayMap = {
    annotations: <Trans>Annotations</Trans>,
    checkInCheckOut: activeAttachmentReservations?.currentStatus === 'checked_in'
      ? <Trans>Check Out</Trans>
      : <Trans>Check In</Trans>,
    versions: <Trans>Version History</Trans>,
  };

  const onKeyPressHandler = keyPressHelper(updateActiveContent);

  return (
    <header className={`attachment-content-header ${styleClassNames['section-bar-heading']}`}>
      <ul className={`${styleClassNames['section-bar-list']}`} role="tablist">
        {tabs.map((tab) => {
          const activeClass = activeTab === tab ? styleClassNames['active-section'] : '';
          return (
            <li
              key={tab}
              className={`${tab}-tab ${styleClassNames['section-bar-item']} ${activeClass}`}
              data-title={tab}
              onClick={() => updateActiveContent(tab)}
              onKeyPress={(e) => onKeyPressHandler(e, tab)}
              role="tab"
              tabIndex={0}
            >
              {tabDisplayMap[tab]}
            </li>
          );
        })}
      </ul>
    </header>
  );
};

export default AttachmentContentHeader;

AttachmentContentHeader.propTypes = {
  activeAttachmentReservations: PropTypes.shape({
    currentStatus: PropTypes.string,
    history: PropTypes.arrayOf(PropTypes.shape({})),
    users: PropTypes.shape({}),
  }),
  activeTab: PropTypes.oneOf(['annotations', 'versions', 'checkInCheckOut']).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateActiveContent: PropTypes.func.isRequired,
};

AttachmentContentHeader.defaultProps = {
  activeAttachmentReservations: {},
};
