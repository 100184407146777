import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface ShowCustomFieldsProps {
  defaultEnabled: boolean | null;
}

const ShowCustomFields: FunctionComponent<ShowCustomFieldsProps> = ({ defaultEnabled }) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled === null ? true : defaultEnabled}
      description={t`Only applies to assets in Collections and Share Links`}
      errorTitle={t`Error updating Custom Field viewing permissions. Please contact support.`}
      id="show-custom-fields"
      successTitle={t`Custom Field viewing permissions updated!`}
      title={t`Allow guests and public users to see Custom Fields`}
      updateResourceKey="showCustomFieldsOverviewTab"
    />
  </I18nProviderWrapper>
);

export default ShowCustomFields;
