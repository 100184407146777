import { FontAlignments, HeadingLevels, StandardHeading, StandardText } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import classes from './styles/user-groups.module.scss';

export const UserGroups404: FunctionComponent = () => {
  return (
    <section className={classes.notFound}>
      <img
        alt={t`404 Not Found`}
        className={classes.image}
        src="https://cdn.brandfolder.io/27C9EC93/at/qent7i-f8t3eg-auh49h/404-page.auto"
      />
      <StandardHeading
        align={FontAlignments.Center}
        className={classes.heading}
        level={HeadingLevels.h2}
      >
        <Trans>User group not found</Trans>
      </StandardHeading>
      <StandardText align={FontAlignments.Center}>
        <Link className={classes.link} to={`/${BFG.resource.slug}/bulk_management/user_groups`}>
          <Trans>Return to user groups</Trans>
        </Link>
      </StandardText>
    </section>
  );
};
