export default (url, { filename, filetype = 'bmp' }) => {
  const fileName = filename || `sweet_file.${filetype}`;

  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Download failed for: ${url}`);
      }

      return response.blob();
    })
    .then((blob) => {
      const anchor = document.createElement('a');
      anchor.style = 'display: none';
      document.body.appendChild(anchor);
      const objUrl = window.URL.createObjectURL(blob);
      anchor.href = objUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(objUrl);
      document.body.removeChild(anchor); // anchors away!
    });
};
