import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { FilestackUploaderTypes } from '@components/common/filestack_uploader/FilestackUploaderEnums';
import { handleUploadAssetAttachment } from '@components/common/filestack_uploader/helpers';
import { UploadArea } from '@components/common/filestack_uploader/UploadArea';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';
import VersionUppy from '@components/common/uppy/version_uppy';
import download from '@helpers/download';

const SubmitButton = ({
  activeAttachment,
  asset,
  checkedInStatus,
  handleToggleCheckinCheckout,
  setVersionsActionLoading,
  versionCard,
  versionsActionLoading,
}) => {
  const handleSubmitCheckout = () => {
    const { url, filename, metadata } = versionCard.version_details;
    download(url, { filename, filetype: metadata.mime_type });
    handleToggleCheckinCheckout();
  };

  if (checkedInStatus === 'checked_in') {
    return (
      <div className="checkin-checkout__button-container">
        <button
          className="button primary lg"
          disabled={versionsActionLoading}
          onClick={handleSubmitCheckout}
          type="button"
        >
          <Trans>Check Out & Download</Trans>
        </button>
      </div>
    );
  }

  if (checkedInStatus === 'checked_out' && !BFG.hasFeature('uppy_uploader_features') && !versionsActionLoading) {
    return (
      <UploadArea
        id={`version-status-uploader-checkin-${activeAttachment.id}`}
        onUploadDone={(files) => {
          handleUploadAssetAttachment({
            attachment: activeAttachment.attributes,
            attachmentKey: activeAttachment.id,
            files,
            onSuccess: handleToggleCheckinCheckout,
            setLoading: setVersionsActionLoading
          })
        }}
        pickerOptions={{
          maxFiles: 1
        }}
        text={t`Check In & Upload`}
        type={FilestackUploaderTypes.Button}
        uploadType={UploadAreaTypes.AttachmentVersionStatus}
      />
    );
  }

  if (checkedInStatus === 'checked_out' && BFG.hasFeature('uppy_uploader_features') && !versionsActionLoading) {
    return (
      <div className="checkin-checkout__button-container">
        <VersionUppy
          assetKey={asset.id}
          attachmentKey={activeAttachment.id}
          handleToggleCheckinCheckout={handleToggleCheckinCheckout}
        />
      </div>
    );
  }

  return null;
};

SubmitButton.propTypes = {
  activeAttachment: PropTypes.shape({
    attributes: PropTypes.object,
    id: PropTypes.string
  }).isRequired,
  asset: PropTypes.shape({
    asset_key: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  checkedInStatus: PropTypes.oneOf(['checked_in', 'checked_out']).isRequired,
  handleToggleCheckinCheckout: PropTypes.func.isRequired,
  setVersionsActionLoading: PropTypes.func.isRequired,
  versionCard: PropTypes.shape({
    version_details: PropTypes.shape({
      url: PropTypes.string,
      filename: PropTypes.string,
      metadata: PropTypes.shape({
        mime_type: PropTypes.string,
      }),
    })
  }).isRequired,
  versionsActionLoading: PropTypes.bool.isRequired
};

export default SubmitButton;
