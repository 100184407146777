import { Attachment } from '@api/v4/attachments/attachmentTypes';
import { ApiDatumResponse, ApiResponseObject } from '@api/v4/ApiResponseTypes';

interface PdfAttachment {
  filename: string | undefined;
  key: string | undefined;
  pages: number;
  url: string | undefined;
}

export type AttachmentApiDatumResponse = ApiDatumResponse<Attachment, 'attachments'>;
export type AttachmentsApiDataResponse = ApiResponseObject<Attachment, 'attachments'>;

/**
 * Get the first PDF attachment's filename, key and url.
 * Note that if a PDF attachment isn't found, filename, key, and url will be undefined;
 * @param {Asset} asset Asset
 * @param {string} key string
 * @returns {PdfAttachment} PdfAttachment
 */
export const getPdfAttachment = (attachments: AttachmentsApiDataResponse[]): PdfAttachment => {
  const pdfAttachment: PdfAttachment = { filename: undefined, key: undefined, pages: 1, url: undefined };

  const attachment = attachments.find((a) => a.attributes.extension === 'pdf');

  if (attachment && attachment.attributes) {
    pdfAttachment.filename = attachment.attributes.filename;
    pdfAttachment.key = attachment.id;
    pdfAttachment.url = attachment.attributes.url;
  }

  return pdfAttachment;
};
