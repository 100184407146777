/* eslint-disable react/require-default-props */
import { t, plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import loader from './loader.svg';
import './styles/main.scss';

export const Loader = () => (
  <img
    alt="Loading"
    src={loader}
  />
);

export const BFLoader = ({ className }) => (
  <video
    aria-label={t`Loading content`}
    className={`brandfolder-loader ${className}`}
    height="90"
    poster="https://cdn.brandfolder.io/4OQZ5PW1/as/pxmju5-qfr48-21nt37/generic-loader.gif"
    width="90"
  >
    <Trans>Loading...</Trans>
    <track kind="captions" />
  </video>
);

BFLoader.propTypes = {
  className: PropTypes.string
};

BFLoader.defaultProps = {
  className: ''
};

export const BFLoaderOverlay = ({ className, loaderClassName }) => (
  <div className={`brandfolder-loader-overlay ${className}`}>
    <BFLoader className={loaderClassName} />
  </div>
);

BFLoaderOverlay.propTypes = {
  className: PropTypes.string,
  loaderClassName: PropTypes.string
};

BFLoaderOverlay.defaultProps = {
  className: '',
  loaderClassName: ''
};

export const ButtonLoader = ({ className }) => (
  <img
    alt="Loading"
    className={`bf-button-loader ${className}`}
    src="https://cdn.bfldr.com/27C9EC93/at/q7v9lw-5fd7zk-c3n3iv/downloading"
  />
);

export const WhiteButtonLoader = ({ className }) => (
  <img
    alt="Loading"
    className={`bf-button-loader ${className}`}
    src="https://cdn.bfldr.com/27C9EC93/at/mtqqp4ksxg9c2qp8r6p4pjcj/white-loading"
  />
);

export const RedButtonLoader = ({ className }) => (
  <img
    alt="Loading"
    className={`bf-button-loader ${className}`}
    src="https://cdn.bfldr.com/27C9EC93/at/ss499ggcjt8hgn83cpbrq27/red-loading"
  />
);

ButtonLoader.propTypes = {
  className: PropTypes.string
};

ButtonLoader.defaultProps = {
  className: ''
};

export const ProcessingLoader = ({ assetQuantity, onClose }) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [showClose, setShowClose] = useState(false);
  const assetCount = assetQuantity || 0;

  const messages = {
    0: plural(assetCount, { one: 'Processing # asset...', other: 'Processing # assets...'}),
    3000: plural(assetCount, {
      one: "Hang tight! We're preparing your fresh, new asset...",
      other: "Hang tight! We're preparing your fresh, new assets..."
    }),
    5000: t`Still working...`,
    25000: t`No really, still working on it...`,
    35000: t`Still processing, refresh the page shortly...`,
  };

  useEffect(() => {
    const timeouts = Object.keys(messages).map((time) => (setTimeout(() => setElapsedTime(time), time)));
    return () => timeouts.forEach((timeout) => clearTimeout(timeout));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (elapsedTime > 3000) {
      setShowClose(true);
    }
  }, [elapsedTime]);

  return (
    <React.Fragment>
      <video
        autoPlay
        className="downloading-loader"
        loop
        muted
        playsInline
        poster="https://cdn.brandfolder.io/4OQZ5PW1/as/pv2ffm-cflif4-9vjcfz/downloading.gif"
      >
        Your browser does not support HTML5 video.
      </video>
      <h4 className="processing-copy">{messages[elapsedTime]}</h4>
      {showClose && (
        <button
          className="close-button"
          onClick={onClose}
          type="button"
        >
          <span
            className="bff-close"
          />
        </button>
      )}
    </React.Fragment>
  );
};

ProcessingLoader.propTypes = {
  assetQuantity: PropTypes.number,
  onClose: PropTypes.func,
};

ProcessingLoader.defaultProps = {
  assetQuantity: 0,
  onClose: () => {},
};
