/* eslint-disable max-len */
import { t } from '@lingui/macro';
import { ButtonLooks, FontIcons, StandardButton } from '@brandfolder/react';
import { Organization } from '@brandfolder/types';
import React, { FunctionComponent } from 'react';

type SubscriptionHeaderType = {
  buttonText: string;
  description: string;
  descriptionTitle: string;
  emailBody: string;
  pageHeader: string;
  descriptionLink: DescriptionLinkType;
};

type DescriptionLinkType = {
  href: string;
  text: string;
};

const SUBSCRIPTION_HEADER_COPY = (): SubscriptionHeaderType => ({
  buttonText: t`Request Upgrade`,
  // eslint-disable-next-line max-len
  description: t`All owners can track utilization of resources, storage, features, and integrations. If no Brandfolder-level plan limits are set, each Brandfolder is able to use up to the entire organization limits.`,
  descriptionTitle: t`Subscription`,
  descriptionLink: {
    href: 'https://help.smartsheet.com/9536077541015-Managing-Your-Subscription',
    text: t`Learn more about plan limits.`
  },
  emailBody: 'I\'d like to inquire about upgrading our subscription for',
  pageHeader: t`Subscription Details for`
});

interface SubscriptionHeaderProps {
  organization: Organization;
}

const SubscriptionHeader: FunctionComponent<SubscriptionHeaderProps> = ({ organization }) =>
  <>
    <div className='subscription__header'>
      <h3 className='subscription__header--title'>
        {SUBSCRIPTION_HEADER_COPY().pageHeader}
        <a className='subscription__header--title' href={`/organizations/${organization.slug}`} target='_blank'>
          {' '}
          {organization.name}
        </a>
      </h3>
      <a
        className='request-upgrade-button'
        href={`mailto:${BFG.supportEmail}?subject=Upgrade Inquiry&body=${SUBSCRIPTION_HEADER_COPY().emailBody} ${organization.name}.`}
        target='_blank'
      >
        <StandardButton endIcon={FontIcons.Plus} look={ButtonLooks.Primary} type='button'>
          {SUBSCRIPTION_HEADER_COPY().buttonText}
        </StandardButton>
      </a>
    </div>
    <div className='subscription__description'>
      <h2>{SUBSCRIPTION_HEADER_COPY().descriptionTitle} </h2>
      <p>
        {SUBSCRIPTION_HEADER_COPY().description}
        <a
          href={SUBSCRIPTION_HEADER_COPY().descriptionLink.href}
          target="_blank"
        >
          {' '}{SUBSCRIPTION_HEADER_COPY().descriptionLink.text}
        </a>

      </p>
    </div>
  </>;

export default SubscriptionHeader;
