import { t } from '@lingui/macro';
import { CamelCasedProperties } from 'type-fest';

import { fetchJson } from '@api/ApiHelper';
import { ApiData } from '@api/v4/ApiResponseTypes';
import { CsvOptionsBase, CsvContextsEnum, CsvResourceTypes } from '@api/v4/private/resources/csv';
import { Locale } from '@components/common/language_menu/languagesMap';

export interface CsvUploadServer {
  completed_at: string;
  created_at: string;
  creator_email: string;
  failed_count: number; // for metasheets, the number of errors
  filename: string;
  status: string;
  succeeded_count: number; // for metasheets, total - failures/errors
  total_count: number; // total processed rows
  validation_failure_count: number; // for translations, csv_upload_failures.size
  validation_failure_download_url: string | null;
  validation_success_count: number | null; // for translations, csv_upload_failures.size
  id?: string; // only on CsvUploadClient when mapped
}

export type CsvUploadClient = CamelCasedProperties<CsvUploadServer>;

interface CsvUploadsListOptions extends CsvOptionsBase {
  resourceType: CsvResourceTypes;
  locale?: Locale;
}

type CsvUploadsListResponse = ApiData<CsvUploadServer, 'csv_uploads'>;

/**
 * Get all (legacy) CSV upload metasheets
 * @deprecated
 * @param {CsvUploadsListOptions} options CsvUploadsListOptions
 * @returns {Promise<CsvUploadsListResponse>} Promise<CsvUploadsListResponse>
 */
export const getCsvUploadMetasheets = async (options: CsvUploadsListOptions): Promise<CsvUploadsListResponse> => {
  const { key } = BFG.resource;
  const { resourceType } = options;

  return fetchJson<CsvUploadsListResponse>({
    url: `/api/v4/private/${resourceType}s/${key}/csv_uploads?sort_by=created_at&order=desc`
  });
};

/**
 * Get all CSV uploads
 * @param {CsvUploadsListOptions} options CsvUploadsListOptions
 * @returns {Promise<CsvUploadsListResponse>} Promise<CsvUploadsListResponse>
 */
export const getCsvUploads = async (options: CsvUploadsListOptions): Promise<CsvUploadsListResponse> => {
  const { key } = BFG.resource;
  const { actionableType, context, locale, resourceType } = options;

  if (context === CsvContextsEnum.Translations && !actionableType) {
    return Promise.reject(new Error(t`CSV upload for translations requires a actionable type.`));
  }
  if (context === CsvContextsEnum.Translations && !locale) {
    return Promise.reject(new Error(t`CSV upload for translations requires a locale.`));
  }

  let query = `?sort_by=created_at&order=desc&upload_context=${context}`;
  if (locale) {
    query = `${query}&ugt_locale=${locale}`;
  }
  if (actionableType) {
    query = `${query}&actionable_type=${actionableType}`;
  }

  return fetchJson<CsvUploadsListResponse>({
    url: `/api/v4/private/${resourceType}s/${key}/csv/uploads${query}`
  });
};

interface CsvUploadsCreateOptions extends CsvOptionsBase {
  locale: Locale;
  uploadUrl: string;
}

type CsvUploadsCreateResponse = ApiData<CsvUploadServer, 'csv_uploads'>;

/**
 * Create/POST a CSV upload (for legacy CSV uploads for metasheets only)
 * @deprecated
 * @param {CsvUploadsCreateOptions} options CsvUploadsCreateOptions
 * @returns {Promise<CsvUploadsCreateResponse>} Promise<CsvUploadsCreateResponse>
 */
export const createCsvUploadMetasheets = async (options: CsvUploadsCreateOptions): Promise<CsvUploadsCreateResponse> => {
  const { key, slug } = BFG.resource;
  const { locale, uploadUrl } = options;

  return fetchJson<CsvUploadsCreateResponse>({
    body: {
      csv_url: uploadUrl,
      resource_key: key
    },
    method: 'POST',
    url: `/${slug}/assets/csv/upload_assets?ugt_locale=${locale}`
  });
};

/**
 * Create/POST a CSV upload
 * @param {CsvUploadsCreateOptions} options CsvUploadsCreateOptions
 * @returns {Promise<CsvUploadsCreateResponse>} Promise<CsvUploadsCreateResponse>
 */
export const createCsvUpload = async (options: CsvUploadsCreateOptions): Promise<CsvUploadsCreateResponse> => {
  const { key, type } = BFG.resource;
  const { actionableType, context, locale, uploadUrl } = options;

  if (context === CsvContextsEnum.Translations && !actionableType) {
    return Promise.reject(new Error(t`CSV upload for translations requires a actionable type.`));
  }

  return fetchJson<CsvUploadsCreateResponse>({
    body: {
      data: {
        attributes: {
          actionable_type: actionableType,
          upload_context: context,
          upload_url: uploadUrl,
          ugt_locale: locale
        }
      }
    },
    method: 'POST',
    url: `/api/v4/private/${type}s/${key}/csv/uploads`
  });
};
