import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { OverflowAvatarProps } from '@components/library/avatar';

import classes from './styles/overflow-avatar.module.scss';

export const OverflowAvatar: FunctionComponent<OverflowAvatarProps> = (props) => {
  const { count, size = 40, ...otherProps } = props;
  return (
    <p
      {...otherProps}
      className={classnames(
        classes.avatar,
        otherProps && otherProps.className
      )}
      style={{
        ...otherProps.style,
        height: size,
        width: size
      }}
    >
      +{count}
    </p>
  );
};
