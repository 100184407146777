import PropTypes from 'prop-types';
import React from 'react';

import { destroy } from '@api/v4/resources/conversion_presets';

const PresetTableRow = ({
  id,
  name,
  aspectWidth,
  aspectHeight,
  width,
  height,
  format,
  onRemove,
}) => {
  const remove = async () => {
    await destroy(id);
    onRemove();
  };

  return (
    <li>
      <span className="forty">{name}</span>
      <span className="ten center">{aspectWidth && aspectHeight && `${aspectWidth}:${aspectHeight}`}</span>
      <span className="ten center">{width}</span>
      <span className="ten center">{height}</span>
      <span className="ten center">{format || 'Allow User To Select'}</span>
      <span>
        <button
          data-testid="remove-button"
          onClick={remove}
          type="button"
        >
          <span className="bff-trash" />
        </button>
      </span>
    </li>
  );
};

PresetTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  aspectWidth: PropTypes.number,
  aspectHeight: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  format: PropTypes.string,
  onRemove: PropTypes.func
};

PresetTableRow.defaultProps = {
  aspectHeight: null,
  aspectWidth: null,
  format: null,
  height: null,
  onRemove: () => {},
  width: null
};

export default PresetTableRow;
