import {
  StandardCombobox,
  InputLabelPositions,
  ListboxOption,
  MoreInfoTooltip,
  StandardAlert,
  StandardButton,
  StandardSwitch,
  StandardTextarea,
  AlertLooks,
  StandardFieldset,
  StandardLink,
  Widths
} from '@brandfolder/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';
import { array, boolean, mixed, object, SchemaOf, string } from 'yup';

import { FetchState, useFetch, UseFetchOptions } from '@api/ApiHelper';
import { UserRoles } from '@api/v4/private/resources/users';
import { PermissionsGetResponse } from '@api/v4/user_groups/user-group-permissions/UserGroupPermissionsTypes';
import {
  getBrandfolderCollectionKeys,
  getCollectionChildKeys,
  resourceDropdownHelperText
} from '@components/bulk_management/user-settings/helpers';
import {
  generateBrandfolderCollectionOptions,
  PermissionLevelTooltip
} from '@components/bulk_management/user-settings/invitations/form-helper-components';
import { PermissionFormValues } from '@components/bulk_management/user-settings/invitations/form-type-helpers';
import { ServerResourceType } from '@components/bulk_management/user-settings/resource-sidebar';
import AdminableResourcesContext from '@components/bulk_management/user_groups/adminable-resources-context';
import { basePermissionFetchOptions } from '@components/bulk_management/user_groups/helpers';
import { useAlertTimeout } from '@components/common/custom_hooks/useAlertTimeout';
import { ListOptionValueNoNull, MultiselectDropdown } from '@components/library/dropdown';
import { moreInfoLabel } from '@components/standard-messages';
import { getStandardComboboxLabels, standardError } from '@translations';

import './styles/alert-transitions.scss';
import classes from './styles/user-groups.module.scss';

enum AlertStates {
  PermissionsError,
  PermissionsSuccess,
  None
}

interface AddPermissionFormProps {
  permissionsFetch: FetchState<PermissionsGetResponse>;
  userGroupKey: string;
}

type AddPermissionFormValues = Omit<PermissionFormValues, 'emails' | 'userGroups'>;

const permissionValidationSchema = (): SchemaOf<AddPermissionFormValues> => object().shape({
  resources: array()
    .of(object().shape({
      type: mixed<ServerResourceType>(),
      key: string()
    })).min(1, t`Resource is required.`),
  permissionLevel: mixed<UserRoles>()
    .oneOf([UserRoles.Admin, UserRoles.Collaborator, UserRoles.Guest]),
  personalMessage: string(),
  preventEmail: boolean()
});

const userPermissionOptions = (): ListboxOption[] => [{
  children: <Trans>Guest</Trans>,
  key: UserRoles.Guest,
  value: UserRoles.Guest
}, {
  children: <Trans>Collaborator</Trans>,
  key: UserRoles.Collaborator,
  value: UserRoles.Collaborator
}, {
  children: <Trans>Admin</Trans>,
  key: UserRoles.Admin,
  value: UserRoles.Admin
}];

export const UserGroupsAddPermissionsForm: FunctionComponent<AddPermissionFormProps> = ({
  permissionsFetch,
  userGroupKey
}) => {
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch
  } = useForm<AddPermissionFormValues, unknown, AddPermissionFormValues>({
    defaultValues: { resources: [], permissionLevel: UserRoles.Guest, personalMessage: '', preventEmail: false },
    resolver: yupResolver(permissionValidationSchema())
  });

  watch(['resources', 'preventEmail']);

  const [waitingToReload, setWaitingToReload] = useState(false);
  const [showAlert, setShowAlert] = useState<AlertStates>(AlertStates.None);
  const [alertVisible, setAlertVisible] = useAlertTimeout({ timeout: 7000 });

  const fetchOptions: UseFetchOptions = {
    fetchOnMount: false,
    method: 'POST',
    url: `/api/v4/user_groups/${userGroupKey}/user_group_permissions`
  };
  const addPermissionsFetch = useFetch(fetchOptions);

  const resourcesFetch = useContext(AdminableResourcesContext);
  const resourceOptions = !resourcesFetch.response ? [] : generateBrandfolderCollectionOptions(
    resourcesFetch.response?.brandfolders,
    resourcesFetch.response?.portals,
    getValues('resources'),
    permissionsFetch.response
  );

  useEffect(() => {
    if (addPermissionsFetch.response) {
      setWaitingToReload(true);
      setTimeout(() => {
        reset();
        permissionsFetch.fetch(basePermissionFetchOptions(userGroupKey));
        setShowAlert(AlertStates.PermissionsSuccess);
        setAlertVisible(true);
        setWaitingToReload(false);
      }, 1000);
    }
  }, [addPermissionsFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (addPermissionsFetch.error) {
      setAlertVisible(true);
      setShowAlert(AlertStates.PermissionsError);
    }
  }, [addPermissionsFetch.error]);

  const handleMultiselectChange = (
    selectedOptions: ListOptionValueNoNull[],
    isAdded: boolean,
    updatedValue: ListOptionValueNoNull
  ): void => {
    let updatedResources = [];
    selectedOptions.forEach((option) => {
      const [type, key] = (option as string).split('|');
      updatedResources.push({ type, key });
    });

    const [type, key] = (updatedValue as string).split('|');
    if (isAdded && type === 'brandfolder') {
      const childResourceKeys = getBrandfolderCollectionKeys(key, resourcesFetch.response?.brandfolders);
      updatedResources = updatedResources.filter((resource) => (
        !childResourceKeys.includes(resource.key)
      ));
    } else if (isAdded && type === 'collection') {
      const childResourceKeys = getCollectionChildKeys(key, resourcesFetch.response?.brandfolders);
      updatedResources = updatedResources.filter((resource) => (
        !childResourceKeys.includes(resource.key)
      ));
    }
    setValue('resources', [...new Set(updatedResources)]);
    trigger('resources');
  };

  const permissionAlerts = (
    <>
      {(
        <CSSTransition
          classNames="fade-in-translate"
          in={showAlert === AlertStates.PermissionsSuccess && alertVisible}
          timeout={600}
          unmountOnExit
        >
          <StandardAlert
            className={classnames(classes.alert, 'user-groups-alert--transition')}
            look={AlertLooks.Success}
          >
            <Trans>
              We're working on adding permissions for this user group. You'll receive a&nbsp;
              <StandardLink href="/profile#notifications">notification</StandardLink>&nbsp;
              when the permissions have been applied.
            </Trans>
          </StandardAlert>
        </CSSTransition>
      )}
      {(
        <CSSTransition
          classNames="fade-in-translate"
          in={showAlert === AlertStates.PermissionsError && alertVisible}
          timeout={600}
          unmountOnExit
        >
          <StandardAlert className={classnames(classes.alert, 'user-groups-alert--transition')} look={AlertLooks.Error}>
            {standardError()}
          </StandardAlert>
        </CSSTransition>
      )}
    </>
  );

  const permissionsContent = (
    <form
      id="user-groups-add-permissions-form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit((values): void => {
        addPermissionsFetch.fetch({
          ...fetchOptions, body: {
            data: {
              attributes: {
                permission_level: values.permissionLevel,
                personal_message: values.personalMessage,
                prevent_email: values.preventEmail,
                resources: values.resources.map((resource) => ({
                  resource_key: resource.key,
                  resource_type: resource.type
                }))
              }
            }
          }
        });
      })}
    >
      <div className={classnames(classes.resourcePermissionDropdowns, 'brandfolder-collections-dropdown')}>
        <MultiselectDropdown
          className={classnames(classes.brandfolderOrCollections, 'brandfolder-collections-dropdown__multi-select')}
          error={errors.resources?.message}
          id="resource-multiselect"
          isLoading={resourcesFetch.loading}
          label={
            <div className={classes.resourceLabel}>
              <h6 className={classes.resourceHeader}><Trans>Resource</Trans></h6>
              <span className={classes.requiredAsterisk}>*</span>
              <MoreInfoTooltip
                className={classes.permissionsResourceTooltip}
                iconLabel={moreInfoLabel()}
                id="user-group-permissions-resources-more-info-tooltip"
                tooltip={resourceDropdownHelperText()}
                width={Widths.Small}
              />
            </div>
          }
          onChange={handleMultiselectChange}
          options={resourceOptions}
          searchable
          values={getValues('resources').map((resource) => `${resource.type}|${resource.key}`)}
          virtualizeOptions={resourceOptions.length > 10}
        />
        <StandardCombobox
          className={classes.permissionFormPermissionLevelDropdown}
          id="permission-form-permission-level-dropdown"
          labels={getStandardComboboxLabels({
            label: (
              <div className={classes.PermissionLevelDropdownLabel}>
                <h6 className={classes.permissionsHeader}><Trans>Permission level</Trans></h6>
                <PermissionLevelTooltip />
              </div>
            )
          })}
          onSelection={(selectedOption): void => {
            setValue('permissionLevel', selectedOption.value as UserRoles);
          }}
          options={userPermissionOptions()}
          virtualized={true}
        />
      </div>
      <div className={classes.personalMessage}>
        <StandardTextarea
          {...register('personalMessage')}
          className={classes.personalMessageTextarea}
          disabled={getValues('preventEmail')}
          id="personal-message"
          label={
            <Trans>
              Personal message&nbsp;
              <small className={classes.small}>
                (optional)
              </small>
            </Trans>
          }
        />
      </div>
      <div className={classes.submissionSection}>
        <StandardSwitch
          id="email-switch"
          isChecked={!getValues('preventEmail')}
          labelPosition={InputLabelPositions.Right}
          onChange={(): void => setValue('preventEmail', !getValues('preventEmail'))}
        >
          <Trans>Send an email invitation</Trans>
        </StandardSwitch>
        <StandardButton
          id="add-permissions-button"
          loading={addPermissionsFetch.loading || waitingToReload}
          type="submit"
        >
          <Trans>Add permissions</Trans>
        </StandardButton>
      </div>
    </form>
  );

  return (
    <>
      {permissionAlerts}
      <StandardFieldset
        className={classes.fieldset}
        disabled={addPermissionsFetch.loading || waitingToReload}
        legend={<Trans>Invite group to resources</Trans>}
        showLegend
      >
        {permissionsContent}
      </StandardFieldset>
    </>
  );
};
