import { FlattenedCustomFieldKeyValuesMap } from '@api/v4/assets/customFieldTypes';
import { Tag } from '@api/v4/assets/assetTypes';
/* eslint-disable @typescript-eslint/naming-convention */
export const tagList3D = [{
  id: 4,
  name: '3d',
  asset_id: 4,
  created_at: '2021-11-10T15:05:48.856Z',
  updated_at: '2021-11-10T15:05:48.856Z',
  deleted_at: null,
  auto_generated: false,
  source: null,
  key: 'jcgx3shh37g64m6bqfwn5kw8',
  brandfolder_id: 1,
  organization_id: 1,
  locale: 'en'
}];

export const customFieldList3D = {
  hg8sbm7mw2pxm7pchq4jt6c: {
    customFieldKey: {
      id: 'hg8sbm7mw2pxm7pchq4jt6c',
      allowed_values: [],
      name: 'Embed Link',
      prioritized: false,
      required: false,
      restricted: false
    },
    customFieldValues: [{
      key: '3sff69bxh5ztcv5rj35xx',
      value: 'https://api.rapidcompact.com/viewer?id=ycSG6XjD81'
    }]
  }
};
/* eslint-disable @typescript-eslint/naming-convention */
