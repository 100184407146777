import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { CircleStatus, ProgressStatusProps } from '@components/library/status';
import { defaultIsoLanguageRegion, localizePercentage } from '@components/library/utils';

import classes from './progress-status.module.scss';

export const ProgressStatus: FunctionComponent<ProgressStatusProps> = (props) => {
  const {
    circleProps,
    label,
    locale = navigator.language || defaultIsoLanguageRegion,
    progress,
    showCircle = true,
    status,
    ...otherProps
  } = props;

  return (
    <p
      {...otherProps}
      className={classnames(
        classes.progress,
        classes[props.status],
        props.className
      )}
    >
      {showCircle && (
        <CircleStatus
          {...circleProps}
          className={classnames(
            classes.circle,
            circleProps?.className
          )}
          label={label}
          status={status}
        />
      )}
      {localizePercentage(locale, progress)}
    </p>
  );
};
