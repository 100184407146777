import { plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import GuestUploadsRow from './guest_upload_links_row';
import './styles/main.scss';

class GuestUploadsManagement extends React.Component {
  state = {
    queryString: null,
    visibleLinks: this.props.uploadLinks
  }

  updateSearch = (event) => {
    this.setState({ queryString: event.target.value });
  }

  deleteGuestUploadLink = (uploadLinkKey) => {
    $.ajax({
      type: 'DELETE',
      url: `/api/v4/upload_links/${uploadLinkKey}`,
      contentType: 'application/json',
      headers: { Authorization: `Bearer ${BF_Token}` },
      context: this,
      success: () => {
        this.setState((prevState) => (
          { visibleLinks: prevState.visibleLinks.filter((link) => link.key !== uploadLinkKey) }
        ));
      }
    });
  }

  formatDate = (railsDate) => {
    const jsDate = new Date(railsDate);
    return railsDate
      ? `${jsDate.getMonth() + 1}/${jsDate.getDate()}/${jsDate.getFullYear().toString().substr(-2)}`
      : null;
  }

  linksToShow() {
    const { visibleLinks, queryString } = this.state;
    return (queryString && queryString.length > 0) ? this.queriedLinks() : visibleLinks;
  }

  queriedLinks() {
    const { visibleLinks, queryString } = this.state;
    return visibleLinks.filter((link) => {
      const filterableString = `${link.key} ${link.created_by} ${link.description}
      ${this.formatDate(link.availability_end)} ${this.formatDate(link.created_at)}`.toLowerCase();
      return filterableString.includes(queryString.toLowerCase());
    });
  }

  renderSortingOptions() {
    const { uploadLinks } = this.props;
    const uploadLinksCount = uploadLinks.length;
    return (
      <input
        className="bf-input searchbar"
        id="upload-link-search"
        onChange={this.updateSearch}
        placeholder={plural(uploadLinksCount, {
          one: 'Search 1 guest upload link',
          other: 'Search # guest upload links'
        })}
        type="text"
      />
    );
  }

  renderNoUploadLinks() {
    const brandfolderName = BF.fx.brandfolder().name;
    return (
      <div className="manage-upload-links">
        <Trans>{brandfolderName} does not have any guest upload links.</Trans>
      </div>
    );
  }

  renderBody() {
    if (this.props.uploadLinks && this.props.uploadLinks.length < 1) {
      return this.renderNoUploadLinks();
    }

    const brandfolderName = BF.fx.brandfolder().name;

    return (
      <div className="manage-upload-links">
        <h2>
          <Trans>Guest Upload Links for {brandfolderName}</Trans>
        </h2>
        <hr />
        {this.renderSortingOptions()}
        <div className="headers">
          <h3 className="ten"><Trans>Link</Trans></h3>
          <h3 className="twenty"><Trans>Upload Section</Trans></h3>
          <h3 className="twenty"><Trans>Description</Trans></h3>
          <h3 className="ten"><Trans>Created by</Trans></h3>
          <h3 className="ten center"><Trans>Created</Trans></h3>
          <h3 className="ten center"><Trans>Auto-approved</Trans></h3>
          <div className="five center" />
        </div>
        { this.linksToShow().map((link) => (
          <div key={link.key} className="guest-upload-row">
            <GuestUploadsRow
              deleteGuestUploadLink={this.deleteGuestUploadLink}
              formatDate={this.formatDate}
              link={link}
            />
          </div>
        ))
        }
      </div>
    );
  }

  render() {
    return (
      <I18nProviderWrapper>
        {this.renderBody()}
      </I18nProviderWrapper>
    );
  }
}

GuestUploadsManagement.propTypes = {
  uploadLinks: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default GuestUploadsManagement;
