import { StandardAlert } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { findCurrentLanguageInMap, Locale } from '@components/common/language_menu/languagesMap';

interface UGTAlertProps {
  locale: Locale;
}

export const UGTAlert: FunctionComponent<UGTAlertProps> = ({ locale }) => {
  if (
    !BFG.multiLanguageAssetDetailsEnabled
    || !BFG.locales?.ugtLocaleDefault
    || locale === BFG.locales.ugtLocaleDefault
  ) {
    return null;
  }

  const { language } = findCurrentLanguageInMap(locale);

  return (
    <StandardAlert>
      <Trans>
        You are now in edit mode for the language:&nbsp;
        <strong>{language}</strong>.&nbsp;
        Edits made will only display for users who select&nbsp;
        <strong>{language}</strong>&nbsp;
        as their language from the asset info language selector in the View Options.
      </Trans>
    </StandardAlert>
  );
};
