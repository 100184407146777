import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';

import fetchJSON from '@api/api_helper';
import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { getPortal, createPortal, updatePortal, deletePortal } from '@api/v4/private/portals';
import { Portal, ValidationErrors } from '@api/v4/private/PortalTypes';
import { Privacy, Resource } from '@api/v4/resources/resourceTypes';
import {
  CloseButton,
  PrimaryButton,
  TextWarningButton,
} from '@components/library/button/index';
import renderModal from '@components/show_page/modals/renderModal';

import PortalFormStage1 from './portal_form_stage_1';
import PortalFormStage2 from './portal_form_stage_2';
import PortalFormStage3 from './portal_form_stage_3';
import { invalidAttributes } from './validations';

interface PortalFormProps {
  closeModal: () => void;
  libraryName: string;
  organizationKey: string;
}

const PortalForm: React.FunctionComponent<PortalFormProps> = ({ closeModal, libraryName, organizationKey }) => {
  const [portal, setPortal] = useState<Portal>({
    name: '',
    privacy: Privacy.Public,
    headerNameOverlay: true
  });
  const [validationErrors, setValidationErrors] = useState<ValidationErrors[]>([]);
  const organizationId = organizationKey || BFG.organization_key;
  const portalId = (BFG.resource.type === 'portal') ? BFG.resource.key : null;
  const [stage, setStage] = useState(1);
  const incrementStage = (): void => {
    if (stage !== 3 && !invalidAttributes(portal, setValidationErrors, stage)) {
      setStage((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    const portalParams = {
      params: {
        fields: 'description,brand_hex_color,header_url,header_hex_color,header_name_overlay,search_url'
      }
    };
    if (portalId) {
      getPortal(portalId, portalParams)
        .then((response) => {
          const attributes = response.data.attributes;
          setPortal({
            name: attributes.name,
            slug: attributes.slug,
            privacy: attributes.privacy,
            description: attributes.description,
            brandHexColor: attributes.brand_hex_color,
            headerUrl: attributes.header_url,
            headerHexColor: attributes.header_hex_color,
            headerNameOverlay: attributes.header_name_overlay,
            searchUrl: attributes.search_url,
          });
        });
    }
  }, [portalId]);

  const submitCreatePortal = ((): void => {
    if (invalidAttributes(portal, setValidationErrors, null)) {
      return;
    }
    createPortal(organizationId, portal)
      .then((response) => {
        closeModal();
        const redirectRoute = `/portals/${response.data.attributes.slug}`;
        window.location.href = redirectRoute;
      })
      .catch(() => {
        Notify.create({ title: t`Something went wrong, portal creation failed.`, type: 'error' });
        closeModal();
      });
  });

  const submitUpdatePortal = ((): void => {
    if (portalId) {
      if (invalidAttributes(portal, setValidationErrors, null)) {
        return;
      }
      updatePortal(portalId, portal)
        .then((response) => {
          const attributes = response.data.attributes;
          const { name: portalName, slug } = attributes;
          Notify.create({ title: t`${portalName} updated.`, type: 'success' });
          closeModal();
          const redirectRoute = `/portals/${slug}`;
          window.location.href = redirectRoute;
        })
        .catch(() => {
          Notify.create({ title: t`Something went wrong, portal update failed.`, type: 'error' });
          closeModal();
        });
    }
  });

  const submitDeletePortal = ((): void => {
    if (portalId) {
      deletePortal(portalId)
        .then(() => {
          closeModal();
          fetchJSON(`/api/v4/organizations/${organizationId}`)
            .then((response: ApiDatumResponse<Resource>) => {
              const orgSlug = response.data.attributes.slug;
              window.location.href = `/organizations/${orgSlug}`;
            });
        })
        .catch(() => {
          Notify.create({ title: t`Something went wrong, portal deletion failed.`, type: 'error' });
          closeModal();
        });
    }
  });

  const handleDeletion = (): void => {
    const { name: portalName } = portal;
    const swalOptions = {
      title: t`Are you sure you want to delete ${portalName}?`,
      text: t`This action can not be undone.`,
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: t`Cancel`,
      confirmButtonText: t`Delete`,
      closeOnConfirm: true,
    };
    window.swal(swalOptions, (confirmSelected: boolean) => {
      if (confirmSelected) {
        submitDeletePortal();
      } else {
        closeModal();
      }
    });
  };

  const renderNextButton = (): JSX.Element | null => {
    if (!portalId && stage !== 3) {
      return (
        <PrimaryButton
          onClick={incrementStage}
          size="medium"
        >
          <Trans>Next Step</Trans>
        </PrimaryButton>
      );
    }
    return null;
  };

  const navClass = (navSection: number): string => (navSection === stage ? 'active' : '');

  return (
    <div className="modal-content-wrapper portal-modal portal-form-modal">
      <div className="portal-modal__header">
        {portalId ? (
          <span aria-hidden="true" className="bff-settings" />
        ) : (
          <span aria-hidden="true" className="bff-plus" />
        )}
        <h3 className="modal-title">
          {portalId ? <Trans>Edit {portal.name}</Trans> : <Trans>Create your Brand Portal</Trans>}
        </h3>
        <CloseButton onClick={closeModal} />
      </div>
      <div className="portal-modal__body portal-form-modal__body">
        <ul className="portal-form-modal__nav">
          <li
            className={`nav-section-1 ${navClass(1)}`}
            onClick={(): void => {
              if (!invalidAttributes(portal, setValidationErrors, stage)) {
                setStage(1);
              }
            }}
          >
            <Trans context='general settings'>General</Trans>
          </li>
          <li
            className={`nav-section-2 ${navClass(2)}`}
            onClick={(): void => {
              if (!invalidAttributes(portal, setValidationErrors, stage)) {
                setStage(2);
              }
            }}
          >
            <Trans>Privacy</Trans>
          </li>
          <li
            className={`nav-section-3 ${navClass(3)}`}
            onClick={(): void => {
              if (!invalidAttributes(portal, setValidationErrors, stage)) {
                setStage(3);
              }
            }}
          >
            <Trans>Customize</Trans>
          </li>
          <span className="slider" />
        </ul>
        <PortalFormStage1
          portal={portal}
          setPortal={setPortal}
          stage={stage}
          validationErrors={validationErrors}
        />
        <PortalFormStage2
          libraryName={libraryName}
          portal={portal}
          setPortal={setPortal}
          stage={stage}
        />
        <PortalFormStage3
          libraryName={libraryName}
          portal={portal}
          setPortal={setPortal}
          stage={stage}
          validationErrors={validationErrors}
        />
        <div className={`portal-modal__button portal-form-modal__buttons ${!portalId ? 'align-right' : ''}`}>
          {portalId && (
            <>
              <TextWarningButton
                className="portal-delete-button"
                icon="bff-trash"
                onClick={handleDeletion}
              >
                <Trans>Delete Portal</Trans>
              </TextWarningButton>
              <PrimaryButton
                onClick={submitUpdatePortal}
                size="medium"
              >
                <Trans>Save Changes</Trans>
              </PrimaryButton>
            </>
          )}
          {renderNextButton()}
          {!portalId && stage === 3 && (
            <PrimaryButton
              disabled={!portal.name}
              onClick={submitCreatePortal}
              size="medium"
            >
              <Trans>Create Brand Portal</Trans>
            </PrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(PortalForm, 'PortalForm');
export default ModalComponent;
