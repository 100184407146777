/*  eslint-disable @typescript-eslint/naming-convention */
import { Brandfolder as BF, Organization as Org } from '@brandfolder/types';

export interface Brandfolder extends BF {
  key: string;
}

export interface Organization extends Org {
  key: string;
}

export enum PeoplePageParamKeys {
  AssetIds = 'ids',
  Filter = 'filter',
  Search = 'search',
  Sort = 'sort',
  Page = 'page',
}

export enum PersonDetailsParamKeys {
  Tag = 'tag',
  Sort = 'sort',
  Page = 'page',
}

export enum FilterValues {
  All = 'all',
  Tagged = 'tagged',
  Untagged = 'untagged',
}

export enum SortValues {
  Newest = 'upload-date-descending',
  Oldest = 'upload-date-ascending',
  Least = 'frequency-least',
  Most = 'frequency-most',
  Ascending = 'name-ascending',
  Descending = 'name-descending',
}

export interface EmptyStateContent {
  [key: string]: {
    heading: string;
    imgSrc: string;
    subheading: string;
  };
}

export type FaceFetchType =
  | 'add-people-tag'
  | 'autocomplete'
  | 'get-hero-image'
  | 'merge'
  | 'people-page-faces'
  | 'person-details'
  | 'remove-face';

export type PeoplePageQueryState = {
  [key in PeoplePageParamKeys]?: FilterValues | SortValues | string;
};

export type PersonDetailsQueryState = {
  [key in PersonDetailsParamKeys]?: SortValues | string;
};

export type PeoplePageQueryDispatch = (nextState: PeoplePageQueryState) => void;

export type PersonDetailsQueryDispatch = (
  nextState: PersonDetailsQueryState
) => void;

export interface PeoplePageRequestParams {
  asset_id?: string[];
  page_num?: number;
  per_page_count?: number;
  sort_by?: SortValues;
  status?: FilterValues;
}

export interface PeoplePage {
  face_label: string;
  gcs_path: string;
  person_id: string;
}

export interface PersonDetailsRequestParams {
  page_num?: number;
  person_id: string;
  per_page_count?: number;
  sort_by?: SortValues;
}

export interface PersonDetails {
  asset_id: string;
  attachment_id: string;
  gcs_path: string;
  match_face_id: string;
}

export interface PaginationResponse {
  bf_processed: boolean;
  current_page: number;
  next_page: number;
  prev_page: number;
  total_count: number;
  total_pages: number;
}

export interface PersonDetailsResponse {
  data: PersonDetails[];
  meta: PaginationResponse;
}

export interface PeoplePageResponse {
  data: PeoplePage[];
  meta: PaginationResponse;
}

export interface PeoplePageFetchState {
  initialLoadingState: boolean;
  peopleData: PeoplePageResponse;
  peopleDataError: boolean;
  peopleDataLoading: boolean;
  refetchPeopleData: () => Promise<void>;
}

export interface PersonDetailsFetchState {
  personDetailsData: PersonDetailsResponse;
  personDetailsError: boolean;
  personDetailsLoading: boolean;
  refetchPersonDetails: () => Promise<void>;
}

interface Tag {
  tag_name: string;
}

export interface UpdatePeopleTagRequestParams extends Tag {
  person_id: string;
}

export interface UpdatePeopleTagResponse {
  data: Tag;
}

export interface UpdatePeopleTagFetchState {
  updatePersonTag: (id: string, tag: string) => Promise<void>;
  updatePersonTagLoading: boolean;
  updatePersonTagSuccess: boolean;
}

export interface AutocompleteRequestParams {
  query: string;
}

export interface Person {
  face_uri: string;
  person_id: string;
  tag_name: string;
}

export interface AutocompleteResponse {
  data: Person[];
}

export interface AutocompleteFetchState {
  autocompleteResults: Person[];
}

export interface MergeFacesRequestParams {
  person_id: string;
  target_person_id: string;
}

export interface MergeFacesResponse {
  data: 'Success';
}

export interface MergeFacesFetchState {
  mergeFaces: (targetPersonId: string, currentPersonId: string) => Promise<void>;
  mergeFacesLoading: boolean;
  mergeFacesSuccess: boolean;
}

export interface ThumbnailRequestParams {
  person_id: string;
}

interface Thumbnail extends Tag {
  hero_image: string;
}

export interface ThumbnailResponse {
  data: Thumbnail;
}

export interface ThumbnailFetchState {
  tagName: string;
  thumbnailImage: string;
  thumbnailLoading: boolean;
}

export interface RemovedFaceTag {
  cluster_deleted: boolean;
  new_person_id: string;
}

export interface RemoveFaceTagRequestParams {
  face_id: string;
  person_id: string;
}

export interface RemoveFaceTagResponse {
  data: RemovedFaceTag;
}

export interface RemoveTagFetchState {
  removeFaceTag: (face_id: string, person_id: string) => Promise<void>;
  removedTag: RemovedFaceTag;
  removeTagLoading: boolean;
  removeTagSuccess: boolean;
  resetTag: () => void;
}
