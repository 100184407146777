import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { get } from '@api/v4/links';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import Hyperlink from './link';
import LinkModal from './link-modal';

import './styles/links.scss';

class LinkList extends React.Component {
  state = {
    editing: false,
    modalOpen: false,
    links: [],
    loaded: false,
  }

  componentDidMount() {
    this.fetchLinks();
  }

  showModal = () => {
    this.setState({ modalOpen: true });
  }

  onNewLinkSave = () => {
    this.fetchLinks();
  }

  updateLink = ({ id, name, url }) => {
    this.setState(({ links }) => ({
      links: links.map((link) => {
        if (link.id !== id) { return link; }

        return {
          ...link,
          name,
          url,
        };
      })
    }));
  }

  onLinkUpdate = (id, link) => {
    if (link.initial) {
      this.onNewLinkSave();
      return;
    }

    if (!link.name && !link.url) {
      // a link was deleted!
      this.setState(({ links }) => ({
        links: links.filter(({ id: i }) => i !== id)
      }));
    } else {
      this.updateLink({ id, ...link });
    }
  }

  initialLinks() {
    if (!this.props.canEdit || !this.state.loaded) { return []; }

    return [
      {
        name: 'LinkedIn',
        url: 'http://linkedin.com',
        id: 'linkedin',
        initial: true,
      },
      {
        name: 'Facebook',
        url: 'http://facebook.com',
        id: 'facebook',
        initial: true,
      },
      {
        name: 'Twitter',
        url: 'http://twitter.com',
        id: 'twitter',
        initial: true,
      },
    ];
  }

  fetchLinks() {
    return get(this.props.brandfolder.key).then((links) => {
      this.setState((state) => ({
        links,
        loaded: true,
        editing: state.editing || links.length === 0,
      }));
    });
  }

  renderButton() {
    const { canEdit } = this.props;
    const { links, editing } = this.state;
    if (!canEdit || !links.length) { return undefined; }

    let inner;
    if (editing) {
      inner = <Trans>Done</Trans>;
    } else {
      inner = (
        <React.Fragment>
          <span aria-hidden="true" className="bff-edit" />
          <Trans>Links</Trans>
        </React.Fragment>
      );
    }

    return (
      <button
        className="button tertiary sm"
        onClick={() => this.setState((state) => ({ editing: !state.editing }))}
        type="button"
      >
        {inner}
      </button>
    );
  }

  render() {
    const { canEdit } = this.props;
    const { editing, modalOpen } = this.state;
    const brandfolderKey = this.props.brandfolder.key;

    const linksToRender = this.state.links.length ? this.state.links : this.initialLinks();
    const links = linksToRender.map((link) => (
      <Hyperlink
        {...link}
        key={link.id}
        brandfolderKey={brandfolderKey}
        editing={editing}
        id={link.id}
        onUpdate={(newLink) => this.onLinkUpdate(link.id, newLink)}
      />
    ));

    return (
      <I18nProviderWrapper>
        {links.length > 0 ? (
          <div className="links__container">
            {canEdit && modalOpen ? (
              <LinkModal
                brandfolderKey={brandfolderKey}
                name=""
                onClose={() => this.setState({ modalOpen: false })}
                onUpdate={this.onNewLinkSave}
                url=""
              />
            ) : null}
            {this.renderButton()}
            <ul className="links__list">
              {links}
              {canEdit && editing ? (
                <li>
                  <button
                    className="links__add-button"
                    onClick={this.showModal}
                    type="button"
                  >
                    +&nbsp;<Trans>Add Link</Trans>
                  </button>
                </li>
              ) : null}
            </ul>
          </div>
        ) : ''
        }
      </I18nProviderWrapper>
    );
  }
}

LinkList.propTypes = {
  brandfolder: PropTypes.shape({
    key: PropTypes.string
  }).isRequired,
  canEdit: PropTypes.bool,
};

LinkList.defaultProps = {
  canEdit: false,
};

export default LinkList;
