import { StandardPaginationLabels } from '@brandfolder/react';
import { t } from '@lingui/macro';

export const getStandardPaginationLabels = (labels: Partial<StandardPaginationLabels> = {}): StandardPaginationLabels => {
  return {
    nextLabel: t`Next`,
    previousLabel: t`Previous`,
    ...labels
  };
};
