import { Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { FetchState, useFetch, useNotifyFetchError } from '@api/ApiHelper';
import { IndexDetailedUsersResponse, UserRoles } from '@api/v4/private/resources/users';
import { PutUserPermissionResponse } from '@api/v4/resources/user-permissions';
import { userPermissionOptions } from '@components/bulk_management/user-settings/helpers';
import { ListDropdown, ListOption } from '@components/library/dropdown';

interface UserPermissionProps {
  isSelf: boolean;
  permissionLevel: UserRoles;
  setReloadPlanLimits: SetStateDispatch<boolean>;
  userPermissionKey: string;
  usersFetch: FetchState<IndexDetailedUsersResponse>;
}

export const UserPermission: FunctionComponent<UserPermissionProps> = ({
  isSelf,
  permissionLevel,
  setReloadPlanLimits,
  userPermissionKey,
  usersFetch
}) => {
  const [newPermissionLevel, setNewPermissionLevel] = useState<UserRoles | undefined>();
  const updateUserPermissionFetch = useFetch<PutUserPermissionResponse>({
    url: `/api/v4/user_permissions/${userPermissionKey}`,
    method: 'PUT',
    body: {
      data: {
        attributes: {
          permission_level: newPermissionLevel // eslint-disable-line @typescript-eslint/naming-convention
        }
      }
    },
    fetchOnMount: false
  });

  useNotifyFetchError(updateUserPermissionFetch);

  useEffect(() => {
    if (newPermissionLevel) {
      updateUserPermissionFetch.fetch();
    }
  }, [newPermissionLevel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updateUserPermissionFetch.response) {
      usersFetch.fetch();
      setReloadPlanLimits(true);
    }
  }, [updateUserPermissionFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isSelf) {
    // admins cannot update their own permissions
    return (
      <span className="manage-users-table__static-permission-level">
        {userPermissionOptions().find((opt) => opt.value === permissionLevel)?.label || <Trans>None</Trans>}
      </span>
    );
  }

  return (
    <ListDropdown
      onChange={(selectedOption: ListOption): void => {
        setNewPermissionLevel(selectedOption.value as UserRoles);
      }}
      options={userPermissionOptions()}
      value={newPermissionLevel || permissionLevel}
      virtualizeOptions={false}
    />
  );
};
