import { t } from '@lingui/macro';
import moment from 'moment';
import React, { FunctionComponent, useContext } from 'react';

import { PressData } from '@api/v4/assets/assetTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import { ActionTypes, AssetDetailsDispatch } from '@components/asset/modal/tabs/edit/EditTabTypes';
import AssetNameInput from '@components/asset/modal/tabs/edit/side_bar/assetNameInput';
import DateTimePicker from '@components/library/date_time_picker/BaseDateTimePicker';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';

import AttachmentListContainer from './attachmentListContainer';

interface PressSideBarPropsScopedContext {
  state: {
    editState: {
      pressData: PressData;
    };
  };
  dispatch: AssetDetailsDispatch;
}

const emptyPressData: PressData = {
  url: ''
};

const PressSideBar: FunctionComponent = () => {
  const { state, dispatch }: PressSideBarPropsScopedContext = useContext(assetModalContext);
  const pressData = state.editState.pressData || emptyPressData;

  return (
    <div className="press-sidebar edit-tab-sidebar one-third bf-scroll-element">
      <AssetNameInput assetNameCopy={t`Name`} />
      <DateTimePicker
        // eslint-disable-next-line react/jsx-boolean-value
        allowPastDates={true}
        className="published-date-picker"
        onChange={(timestamp): void => dispatch({
          type: ActionTypes.UpdateAsset,
          payload: { pressData: { ...pressData, published_date: moment(timestamp).format('MM/DD/YYYY') } }
        })}
        showTime={false}
        showTimezones={false}
        timestamp={pressData.published_date}
      />
      <InputContainer
        attributes={{
          name: 'url',
          onChange: (e: InputChangeEvent): void => dispatch({
            type: ActionTypes.UpdateAsset,
            payload: { pressData: { ...pressData, url: e.target.value } }
          }),
          type: InputType.Text,
          value: pressData.url,
        }}
        input={{ value: pressData.url }}
        labelCopy="URL"
      />
      <AttachmentListContainer />
    </div>
  );
};

export default PressSideBar;
