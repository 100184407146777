import { Trans } from '@lingui/macro';
import React, { useEffect, useRef, useState, FunctionComponent, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useFetch } from '@api/ApiHelper';
import { CustomFieldValue } from '@api/v4/assets/customFieldTypes';
import {
  CustomFieldKeysListResponse,
  CustomFieldKeysResponseObject,
  DependentCustomField
} from '@api/v4/resources/CustomFieldKeysTypes';
import { valuesAsString } from '@components/asset/modal/tabs/asset_details/helpers/custom-field-strings';
import { FlattenedCustomFieldKeyValuesMap } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { flattenCustomFieldKeysList, sortChildKeysBelowParentKeys } from '@components/asset/modal/tabs/edit/helpers';
import {
  CustomFieldKeyValueRow
} from '@components/asset/modal/tabs/edit/main_pane/custom_fields/custom-field-row/CustomFieldKeyValueRow';
import { TextButton } from '@components/library/button';
import { isMissingCustomFieldValue } from '@components/show_page/sections/asset/required-custom-fields-dialog-helper';

export interface GuestUploadCustomFieldsProps {
  brandfolderKey: string;
  newAssetKeys: string[];
  platformName: string;
  controlledCustomFields: boolean;
  resetForm: boolean;
  setAllRequiredCustomFields: SetStateDispatch<boolean>;
  setFinalCustomFields: SetStateDispatch<FlattenedCustomFieldKeyValuesMap>;
  setSubmissionAttempted: SetStateDispatch<boolean>;
  submissionAttempted: boolean;
  uploadLinkKey: string;
  mladLocale: string
}

export const GuestUploadCustomFields: FunctionComponent<GuestUploadCustomFieldsProps> = ({
  brandfolderKey,
  newAssetKeys,
  platformName,
  controlledCustomFields,
  resetForm,
  setAllRequiredCustomFields,
  setFinalCustomFields,
  setSubmissionAttempted,
  submissionAttempted,
  uploadLinkKey,
  mladLocale
}) => {
  const [containerHeight, setContainerHeight] = useState(0);
  const [customFieldKeys, setCustomFieldKeys] = useState<CustomFieldKeysResponseObject[]>([]);
  const [customFieldsMap, setCustomFieldsMap] = useState<FlattenedCustomFieldKeyValuesMap>();
  const requiredCustomFieldsFetch = useFetch<CustomFieldKeysListResponse>({
    url: `/api/v4/brandfolders/${brandfolderKey}/custom_field_keys`,
    fetchOnMount: false,
    fields: 'multi_value_enabled',
    params: {
      include: 'dependent_custom_fields',
      order: 'ASC',
      per: 3000,
      sort_by: 'name', // eslint-disable-line @typescript-eslint/naming-convention
      ugt_locale: mladLocale, // eslint-disable-line @typescript-eslint/naming-convention
      upload_link_key: uploadLinkKey // eslint-disable-line @typescript-eslint/naming-convention
    },
  });
  const tempValuePrefix = 'create-cfv';
  const listContainerRef = useRef(null);
  const dependentCustomFieldsMap = requiredCustomFieldsFetch.response?.included?.reduce((acc, dcf) => {
    acc[dcf.attributes.child_key] = dcf.attributes;
    return acc;
  }, {} as DependentCustomField);
  const dependentFieldIds = dependentCustomFieldsMap && Object.keys(dependentCustomFieldsMap);

  const filteredCustomFieldKeys = !dependentFieldIds?.length ?
    customFieldKeys?.filter((cfkId) => cfkId.attributes.required) :
    customFieldKeys?.filter((cfkId) => {
      const parentKey = dependentCustomFieldsMap[cfkId.id]?.parent_key;
      const parentField = parentKey && customFieldsMap[parentKey];
      const parentValues = parentField?.customFieldValues?.map((cfv) => cfv.value) || [];
      return (
        customFieldsMap[cfkId.id]?.customFieldRequired &&
        !dependentFieldIds?.includes(cfkId.id) ||
        parentValues?.includes(dependentCustomFieldsMap[cfkId.id]?.value)
      );
    });

  useEffect(() => {
    if (resetForm && requiredCustomFieldsFetch.response) {
      setCustomFieldsMap(flattenCustomFieldKeysList(requiredCustomFieldsFetch.response));
    }
  }, [resetForm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (controlledCustomFields) {
      requiredCustomFieldsFetch.fetch();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (requiredCustomFieldsFetch.response) {
      if (requiredCustomFieldsFetch.response.data.length === 0) {
        setAllRequiredCustomFields(true);
      }

      setCustomFieldKeys(sortChildKeysBelowParentKeys(requiredCustomFieldsFetch.response));
      setCustomFieldsMap(flattenCustomFieldKeysList(requiredCustomFieldsFetch.response));
      setContainerHeight(listContainerRef.current?.clientHeight);
    }
  }, [requiredCustomFieldsFetch.response]);

  const handleCreate = (
    customFieldKeyId: string,
    customFieldValue: CustomFieldValue | CustomFieldValue[]
  ): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    const selectedCustomField = requiredCustomFieldsFetch.response.data?.find(
      ({ id }) => id === customFieldKeyId
    );
    // if a default wasn't already created, add the new cf + values to the object
    if (!customFieldsMapCopy[customFieldKeyId]) {
      Object.assign(customFieldsMapCopy, {
        [customFieldKeyId]: {
          customFieldKey: {
            id: customFieldKeyId,
            name: selectedCustomField.attributes.name,
          },
          customFieldValues: [
            {
              key: selectedCustomField.id,
              value: customFieldValue || ''
            },
          ],
          customFieldTouched: true
        },
      });
    } else {
      if (Array.isArray(customFieldValue)) {
        customFieldsMapCopy[customFieldKeyId] = {
          ...customFieldsMap[customFieldKeyId],
          customFieldValues: customFieldValue.map((cfValue) => {
            return {
              key: cfValue.key || `${tempValuePrefix}-${uuidv4()}`,
              value: cfValue.value || ''
            };
          }),
          customFieldTouched: true
        };
      } else {
        // if the id already exists in the object, just update the values to reflect the change
        customFieldsMapCopy[customFieldKeyId] = {
          ...customFieldsMap[customFieldKeyId],
          customFieldValues: [
            ...customFieldsMap[customFieldKeyId]?.customFieldValues,
            {
              key: customFieldValue.key || `${tempValuePrefix}-${uuidv4()}`,
              value: customFieldValue.value || ''
            },
          ],
          customFieldTouched: true
        };
      }

      setCustomFieldsMap(customFieldsMapCopy);
    }

  };

  const genHandleDelete = (customFieldKeyId: string) => (customFieldValue: CustomFieldValue): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    const valuesCopy: CustomFieldValue[] = [];
    customFieldsMap[customFieldKeyId].customFieldValues.forEach((cfv) => {
      if (cfv.key !== customFieldValue.key) {
        valuesCopy.push(cfv);
      }
    });
    customFieldsMapCopy[customFieldKeyId] = {
      ...customFieldsMap[customFieldKeyId],
      customFieldValues: valuesCopy,
      customFieldTouched: true
    };
    setCustomFieldsMap(customFieldsMapCopy);
  };

  const genHandleCreate = (customFieldKeyId: string) => (customFieldValue: CustomFieldValue | CustomFieldValue[]): void => {
    handleCreate(customFieldKeyId, customFieldValue);
  };

  const genHandleUpdate = (customFieldKeyId: string) => (customFieldValue: CustomFieldValue): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    if (!customFieldValue.key) {
      return handleCreate(customFieldKeyId, customFieldValue);
    }

    const valuesCopy = customFieldsMapCopy[customFieldKeyId].customFieldValues.map((cfv) => {
      if (cfv.key === customFieldValue.key) {
        return customFieldValue;
      }

      return cfv;
    });

    // clear out both value AND the temp key if the value is empty
    const resetSingleEntryValues = valuesCopy.length === 1 && !valuesCopy[0].value;
    customFieldsMapCopy[customFieldKeyId] = {
      ...customFieldsMap[customFieldKeyId],
      customFieldValues: resetSingleEntryValues ? [] : valuesCopy,
      customFieldTouched: true
    };
    return setCustomFieldsMap(customFieldsMapCopy);
  };

  const handleRemoveValues = (cfk: string): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    // set selected field to empty values
    customFieldsMapCopy[cfk] = {
      ...customFieldsMap[cfk],
      customFieldValues: []
    };
    // Clear previous child field values of updated custom field
    const childFields = dependentFieldIds?.filter((id) => dependentCustomFieldsMap[id].parent_key === cfk);
    childFields?.forEach((childField) => {
      const childKey = customFieldsMapCopy[childField];
      customFieldsMapCopy[childKey?.customFieldKey.id] = {
        ...childKey,
        customFieldValues: []
      };
    });

    setCustomFieldsMap(customFieldsMapCopy);
  };

  const handleCustomFieldsSubmission = (): void => {
    let missingRequiredField = false;
    const customFieldsMapCopy: FlattenedCustomFieldKeyValuesMap = {};
    const filteredCustomFieldKeysIds = filteredCustomFieldKeys.map(({ id }) => id);

    filteredCustomFieldKeysIds.forEach((requiredKey) => {
      customFieldsMapCopy[requiredKey] = {
        ...customFieldsMap[requiredKey],
        customFieldTouched: true
      };

      if (!missingRequiredField
        && customFieldsMap[requiredKey]?.customFieldRequired
        && filteredCustomFieldKeysIds.includes(customFieldsMap[requiredKey]?.customFieldKey.id)
      ) {
        missingRequiredField = isMissingCustomFieldValue(customFieldsMap[requiredKey]);
      }
    });

    if (missingRequiredField) {
      setCustomFieldsMap(customFieldsMapCopy);
      setSubmissionAttempted(false);
      setAllRequiredCustomFields(false);
    } else {
      const finalFieldsWithValues = filteredCustomFieldKeysIds.reduce((acc, field) => {
        if (!!customFieldsMapCopy[field]?.customFieldValues?.length) {
          acc[field] = customFieldsMapCopy[field];
        }
        return acc;
      }, {});
      setFinalCustomFields(finalFieldsWithValues);
      setAllRequiredCustomFields(true);
    }
  };

  useEffect(() => {
    if (submissionAttempted) {
      handleCustomFieldsSubmission();
    }
  }, [submissionAttempted]);

  const renderCustomFields = ({ customFieldKey, customFieldValues }): ReactNode => (
    <tr
      key={customFieldKey.id}
      className="guest-upload__required-custom-fields--table-row"
    >
      <td className="guest-upload__required-custom-fields--table-name">{customFieldKey.name}</td>
      <td className="guest-upload__required-custom-fields--table-value">
        {valuesAsString(customFieldValues)}
      </td>
    </tr>
  );

  return (
    <>
      {controlledCustomFields && filteredCustomFieldKeys.length > 0 && (
        <div className="guest-upload__required-custom-fields">
          {!submissionAttempted && (
            <p>
              <Trans>Please complete the following required fields before uploading assets to this {platformName}.</Trans>
            </p>
          )}
          {submissionAttempted && !newAssetKeys && (
            <div className="guest-upload__required-custom-fields--reset">
              <Trans>
                <p>See something that doesn't look right?</p>
                <TextButton
                  onClick={(): void => {
                    setAllRequiredCustomFields(false);
                    setSubmissionAttempted(false);
                  }}
                >
                  Start over
                </TextButton>
              </Trans>
            </div>
          )}
          {filteredCustomFieldKeys.length >= 0 && (
            <header className="custom-fields-list--heading">
              <div className="custom-fields-list--heading--key"><p><Trans>Key</Trans></p></div>
              <div className="custom-fields-list--heading--values"><p><Trans>Values</Trans></p></div>
            </header>
          )}
          <div className="guest-upload__required-custom-fields--list">
            {submissionAttempted ? (
              <table className="guest-upload__required-custom-fields--table">
                <tbody>
                  {Object.keys(customFieldsMap).slice(0).map((key) => {
                    if (!!customFieldsMap[key].customFieldValues.length) {
                      return (
                        renderCustomFields(customFieldsMap[key])
                      );
                    }
                  })}
                </tbody>
              </table>
            ) : (
              <ul
                ref={listContainerRef}
                className={`custom-field-list bf-scroll-element ${containerHeight > 399 ? 'scrollable-list' : ''} ${filteredCustomFieldKeys.length >= 4 ? 'scrollable-list' : ''}`}
              >
                {filteredCustomFieldKeys?.map(({ id, attributes }) => (
                  <CustomFieldKeyValueRow
                    key={id}
                    controlledCustomFieldsEnabled
                    customFieldKey={{
                      attributes,
                      id,
                      type: 'custom_field_keys'
                    }}
                    existingCustomFieldMap={customFieldsMap[id]}
                    handleCreate={genHandleCreate(id)}
                    handleDelete={genHandleDelete(id)}
                    handleRemoveValues={handleRemoveValues}
                    handleUpdate={genHandleUpdate(id)}
                    overflowParentRef={listContainerRef}
                    // Always say translating is false
                    // whatever locale they are adding in will
                    // create in the default locale as well
                    translating={false}
                  />
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </>
  );
};
