import {
  FontIcon,
  FontIcons,
  InputLooks,
  ShimmerLoader,
  StandardButton,
  StandardTextfield,
  brandfolderColorPalette,
} from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { TextButton } from '@components/library/button';

import { BackButton } from '../common/BackButton';
import { LoadingOverlay } from '../common/LoadingOverlay';
import { MergeFacesDialog } from '../common/MergeFacesDialog';
import { SuggestedNamesList } from '../common/SuggestedNamesList';
import { Person, PersonDetailsParamKeys } from '../customPeopleTagTypes';
import { useAutocompletePeopleTags, useFetchHeroImage, useMergeFaces, useUpdatePersonTag } from '../fetch';
import { usePersonDetailsQueryState } from '../hooks/usePersonDetailsQueryState';

const TagPersonWrapper = styled.div`
  align-items: flex-start;
  display: flex;

  .person-details-suggested-names-list {
    width: 300px;
  }

  .bf-loader__shimmer,
  .default-image,
  .search-textfield,
  .tag-person-button,
  .tagged-name,
  .thumbnail-image {
    margin-right: 16px;
  }

  .bf-loader__shimmer,
  .default-image,
  .thumbnail-image {
    border-radius: 4px;
    height: 48px;
    object-fit: cover;
    width: 48px;

    &--br {
      height: 48px;
      width: 48px;
    }
  }

  .default-image {
    align-items: center;
    background-color: ${brandfolderColorPalette.neutrals[200]};
    color: ${brandfolderColorPalette.neutrals[500]};
    display: flex;
    justify-content: center;
  }
`;
const TagPersonTextfieldWrapper = styled.div`
  align-items: center;
  display: flex;

  .search-textfield {
    width: 300px;
  }
`;
const TitleDivider = styled.hr`
  margin: 40px 0;
`;

export const PersonDetailsHeader: FunctionComponent = () => {
  const [state, setState] = usePersonDetailsQueryState();
  const [showTagField, setShowTagField] = useState(false);
  const [prevRoute, setPrevRoute] = useState(undefined);
  const [showAutocompleteResults, setShowAutocompleteResults] = useState(false);
  const [targetPerson, setTargetPerson] = useState<Person>();
  const [showMergeFacesDialog, setShowMergeFacesDialog] = useState(false);
  const { id } = useParams();

  const { tagName, thumbnailImage, thumbnailLoading } = useFetchHeroImage(id);
  const { autocompleteResults } = useAutocompletePeopleTags(state.tag, id);
  const { updatePersonTag, updatePersonTagLoading, updatePersonTagSuccess } = useUpdatePersonTag();
  const { mergeFaces, mergeFacesLoading, mergeFacesSuccess } = useMergeFaces();

  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef(null);

  useEffect(() => {
    setState({ [PersonDetailsParamKeys.Tag]: tagName });
  }, [tagName]);

  useEffect(() => {
    if (location.state) {
      setPrevRoute(location.state.prevRoute);
    }
  }, [location.state]);

  useEffect(() => {
    if (autocompleteResults?.length) {
      setShowAutocompleteResults(true);
    }
  }, [autocompleteResults]);

  useEffect(() => {
    if (!autocompleteResults?.length || !state.tag || targetPerson) {
      setShowAutocompleteResults(false);
    }
  }, [autocompleteResults, state.tag, targetPerson]);

  useEffect(() => {
    if (!updatePersonTagLoading && !updatePersonTagSuccess) {
      setState({ tag: tagName });
    }
  }, [updatePersonTagSuccess, updatePersonTagLoading, tagName]);

  useEffect(() => {
    if (targetPerson && mergeFacesSuccess) {
      navigate(`/${BFG.brandfolder_slug}/people_tags/${targetPerson.person_id}?tag=${targetPerson.tag_name}`);
    }
  }, [mergeFacesSuccess, targetPerson]);

  useEffect(() => {
    if (!mergeFacesLoading && !showMergeFacesDialog) {
      setTargetPerson(undefined);
    }
  }, [mergeFacesLoading, showMergeFacesDialog]);

  const handleNavigateBack = (): void => {
    if (prevRoute) {
      navigate(prevRoute);
    } else {
      navigate(`/${BFG.brandfolder_slug}/people_tags`);
    }
  };

  const handleOnSelectTargetPerson = (person: Person): void => {
    setShowMergeFacesDialog(true);
    setTargetPerson(person);
    setShowAutocompleteResults(false);
    setState({ [PersonDetailsParamKeys.Tag]: person.tag_name });
    if (ref.current) {
      ref.current.value = person.tag_name;
    }
  };

  const handleUpdatePeopleTag = (): void => {
    updatePersonTag(id, state.tag);
    setShowTagField(false);
  };

  const handleMergeFaces = (): void => {
    mergeFaces(targetPerson.person_id, id);
    setShowMergeFacesDialog(false);
    setShowTagField(false);
  };

  const handleCancelMergeFaces = (): void => {
    setShowMergeFacesDialog(false);
    setShowTagField(false);
    setState({ [PersonDetailsParamKeys.Tag]: tagName });
    if (ref.current) {
      ref.current.value = '';
    }
  };

  return (
    <>
      <TagPersonWrapper>
        {thumbnailLoading && <ShimmerLoader numLines={1} />}
        {thumbnailImage && <img alt="person thumbnail" className="thumbnail-image" src={thumbnailImage} />}
        {!thumbnailImage && !thumbnailLoading && (
          <div className="default-image">
            <FontIcon icon={FontIcons.UserFilled} iconSize={26} />
          </div>
        )}
        <StandardButton
          className={`tag-person-button ${!showTagField && !state.tag && !thumbnailLoading ? 'visible' : 'hidden'}`}
          onClick={(): void => setShowTagField(true)}
          startIcon={FontIcons.Tag}
        >
          <Trans>Tag person</Trans>
        </StandardButton>
        <TagPersonTextfieldWrapper className={showTagField ? 'visible' : 'hidden'}>
          <div>
            <StandardTextfield
              ref={ref}
              id="search-textfield"
              label="Tag person textfield"
              look={InputLooks.Default}
              onChange={(e): void => setState({ [PersonDetailsParamKeys.Tag]: e.target.value })}
              placeholder={tagName ? tagName : t`Enter name`}
              showLabel={false}
              textfieldProps={{
                autoComplete: 'off',
                className: 'search-textfield'
              }}
              type="search"
            />
            {showAutocompleteResults && (
              <SuggestedNamesList
                handleOnSelectTargetPerson={handleOnSelectTargetPerson}
                listClasses="person-details-suggested-names-list"
                suggestions={autocompleteResults}
              />
            )}
          </div>
          <StandardButton
            disabled={!state.tag}
            onClick={handleUpdatePeopleTag}
          >
            <Trans>Save</Trans>
          </StandardButton>
        </TagPersonTextfieldWrapper>
        {!showTagField && state.tag && (
          <>
            <h1 className="tagged-name">{state.tag}</h1>
            <TextButton onClick={(): void => setShowTagField(true)}>
              <Trans>Edit</Trans>
            </TextButton>
          </>
        )}
      </TagPersonWrapper>
      <TitleDivider />
      <BackButton handleNavigateBack={handleNavigateBack} />
      {showMergeFacesDialog && (
        <MergeFacesDialog
          currentPersonThumbnail={thumbnailImage}
          handleCancelMerge={handleCancelMergeFaces}
          handleMerge={handleMergeFaces}
          isOpen={showMergeFacesDialog}
          targetPersonThumbnail={targetPerson?.face_uri}
        />
      )}
      {mergeFacesLoading && <LoadingOverlay />}
    </>
  );
};
