/* eslint-disable @typescript-eslint/naming-convention */
import {
  Filters,
  SearchFilterOperator,
  SearchFilterOperators
} from '@components/show_page/sections/section_search/SearchTypes';

const filterLogic = (filters: Filters, searchQuery?: string, searchFilterOperators?: SearchFilterOperator | SearchFilterOperators): string => {
  const filter_aspect_array = filters.orientation;
  const filter_availability = filters.availability;
  const filter_comments = filters.comments;
  const filter_created_at = filters.created_at;
  const filter_custom_fields = filters.custom_fields;
  const filter_filetypes_array = filters.filetypes;
  const filter_section = filters.section;
  const filter_sku = filters.sku;
  const filter_tags_array = filters.tags;
  const filter_task_assigned_to = filters.task_assigned_to;
  const filter_task_due_date = filters.task_due_date;
  const filter_task_priority = filters.task_priority;
  const filter_task_status = filters.task_status;
  const filter_view_only = filters.view_only;

  let extra_filters = '';
  let queryString = searchQuery ? searchQuery.trim() : '';

  if (filter_sku) {
    extra_filters += filter_sku;
  }

  if (filter_created_at) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND created_at:${filter_created_at}`;
    } else {
      extra_filters = `${extra_filters}created_at:${filter_created_at}`;
    }
  }

  if (filter_availability) {
    if (extra_filters) {
      extra_filters += ' AND ';
    }

    switch (filter_availability) {
      case 'approved':
        extra_filters += 'approved:true';
        break;
      case 'unapproved': case 'pending_approval':
        extra_filters += 'approved:false';
        break;
      case 'draft': case 'unpublished':
        extra_filters += 'unpublished:true';
        break;
      case 'expired':
        extra_filters += 'expired:true';
        break;
      default:
        break;
    }
  }

  if (filter_comments === false || filter_comments === true) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND comments:${filter_comments}`;
    } else {
      extra_filters = `${extra_filters}comments:${filter_comments}`;
    }
  }

  const filter_aspect_array_length = filter_aspect_array.length;
  if (filter_aspect_array_length === 1) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND aspect:${filter_aspect_array[0]}`;
    } else {
      extra_filters = `${extra_filters}aspect:${filter_aspect_array[0]}`;
    }
  } else if (filter_aspect_array_length > 1) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND (`;
    } else {
      extra_filters = `${extra_filters}(`;
    }
    filter_aspect_array.forEach((aspect, i) => {
      extra_filters = `${extra_filters}aspect:${aspect}`;
      if (i < (filter_aspect_array_length - 1)) {
        extra_filters = `${extra_filters} OR `;
      }
    });
    extra_filters = `${extra_filters})`;
  }

  const filter_tags_array_length = filter_tags_array.length;
  if (filter_tags_array_length === 1) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND tags.strict:"${filter_tags_array[0]}"`;
    } else {
      extra_filters = `${extra_filters}tags.strict:"${filter_tags_array[0]}"`;
    }
  } else if (filter_tags_array_length > 1) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND (`;
    } else {
      extra_filters = `${extra_filters}(`;
    }
    filter_tags_array.forEach((tag, i) => {
      extra_filters = `${extra_filters}tags.strict:"${tag}"`;
      if (i < (filter_tags_array_length - 1)) {
        extra_filters = `${extra_filters} ${(searchFilterOperators as SearchFilterOperators)?.tags || SearchFilterOperator.OR} `;
      }
    });
    extra_filters = `${extra_filters})`;
  }

  const filter_filetypes_array_length = filter_filetypes_array.length;
  if (filter_filetypes_array_length === 1) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND filetype.strict:"${filter_filetypes_array[0]}"`;
    } else {
      extra_filters = `${extra_filters}filetype.strict:"${filter_filetypes_array[0]}"`;
    }
  } else if (filter_filetypes_array_length > 1) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND (`;
    } else {
      extra_filters = `${extra_filters}(`;
    }
    filter_filetypes_array.forEach((filetype, i) => {
      extra_filters = `${extra_filters}filetype.strict:"${filetype}"`;
      if (i < (filter_filetypes_array_length - 1)) {
        extra_filters = `${extra_filters} OR `;
      }
    });
    extra_filters = `${extra_filters})`;
  }

  const custom_field_keys = Object.keys(filter_custom_fields);
  const multiValueOperator = (key): string => (
    `" ${(searchFilterOperators as SearchFilterOperators)?.custom_fields?.[key] || SearchFilterOperator.OR} "`
  );
  if (custom_field_keys.length === 1) {
    const custom_field_key = custom_field_keys[0];
    const custom_field_values = `"${filter_custom_fields[custom_field_key].join(multiValueOperator(custom_field_key))}"`;
    if (extra_filters) {
      extra_filters = `${extra_filters} AND custom_fields.${custom_field_key}.strict:(${custom_field_values})`;
    } else {
      extra_filters = `${extra_filters}custom_fields.${custom_field_key}.strict:(${custom_field_values})`;
    }
  } else if (custom_field_keys.length > 1) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND (`;
    } else {
      extra_filters = `${extra_filters}(`;
    }

    custom_field_keys.forEach((custom_field_key, i) => {
      const custom_field_values = `"${filter_custom_fields[custom_field_key].join(multiValueOperator(custom_field_key))}"`;
      extra_filters = `${extra_filters}custom_fields.${custom_field_key}.strict:(${custom_field_values})`;
      if (i < (custom_field_keys.length - 1)) {
        extra_filters = `${extra_filters} ${SearchFilterOperator.AND} `;
      }
    });
    extra_filters = `${extra_filters})`;
  }

  if (filter_section) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND section:${filter_section}`;
    } else {
      extra_filters = `section:${filter_section}`;
    }
  }

  if (filter_task_assigned_to) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND task_assigned_to:${filter_task_assigned_to}`;
    } else {
      extra_filters = `task_assigned_to:${filter_task_assigned_to}`;
    }
  }

  if (filter_task_due_date) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND task_due_date:${filter_task_due_date}`;
    } else {
      extra_filters = `task_due_date:${filter_task_due_date}`;
    }
  }

  if (filter_task_priority) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND task_priority:${filter_task_priority}`;
    } else {
      extra_filters = `task_priority:${filter_task_priority}`;
    }
  }

  if (filter_task_status) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND task_status:${filter_task_status}`;
    } else {
      extra_filters = `task_status:${filter_task_status}`;
    }
  }

  if (filter_view_only) {
    if (extra_filters) {
      extra_filters = `${extra_filters} AND view_only:${filter_view_only}`;
    } else {
      extra_filters = `view_only:${filter_view_only}`;
    }
  }

  if (!!extra_filters && !!queryString) {
    queryString = `(${queryString}) AND ${extra_filters}`;
  } else if (extra_filters && !queryString) {
    queryString = extra_filters;
  }

  return window.encodeURIComponent(queryString);
};

export default filterLogic;
