import {
  ButtonLink,
  ButtonLooks,
  ButtonSizes,
  ListItem,
  StandardDropdown
} from '@brandfolder/react';
import { Plural } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { BrandguideListResponse } from '@api/v4/brandguides/brandguidesTypes';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { generatePermissionSensitiveBrandguideUrl } from '@helpers/brandguide';

import classes from './styles/brandguide-header-list.module.scss';

export const BrandguideHeaderList: FunctionComponent = () => {
  const { loading, response } = useFetch<BrandguideListResponse>({
    url: `/api/v4/private/${BFG.resource.type}s/${BFG.resource.key}/brandguides?fields=v2`
  });

  const [options, setOptions] = useState<ListItem[]>([]);

  const renderOptions = (): void => {
    const listItems: ListItem[] = response && response.data.map((brandguide) => {
      return {
        children: (
          <ButtonLink
            className={classes.brandguideButtonLink}
            fullWidth={true}
            href={generatePermissionSensitiveBrandguideUrl({
              slug: brandguide.attributes.slug,
              cname: brandguide.attributes.cname,
              organizationKey: BFG.organization_key,
              public: brandguide.attributes.public,
              v2: brandguide.attributes.v2
            })}
            look={ButtonLooks.Default}
            size={ButtonSizes.Large}
            target="_blank"
          >
            {brandguide.attributes.name}
          </ButtonLink>
        ),
        key: brandguide.id
      };
    });
    setOptions(listItems);
  };

  useEffect(() => {
    if (response) {
      renderOptions();
    }
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  const brandguides = response?.data;

  const hasNoBrandguides = brandguides?.length === 0;
  if (hasNoBrandguides === true) return null;

  const brandguidesLength = brandguides?.length || 0;
  const dropdownText = brandguides ? <Plural one="# Brandguide" other="# Brandguides" value={brandguidesLength} /> : '';

  return (
    <I18nProviderWrapper>
      <StandardDropdown
        buttonProps={{ className: classes.brandguideDropdownButton, endIconProps: { className: classes.caret } }}
        className={classnames(classes.brandguideDropdown)}
        id="brandguide-dropdown"
        labels={{
          loaderLabel: 'Loading'
        }}
        listItems={options}
        listProps={{
          style: {
            width: 270
          }
        }}
        loading={loading}
      >
        {dropdownText}
      </StandardDropdown>
    </I18nProviderWrapper>
  );
};
