import { ButtonLooks, ButtonSizes, StandardButton } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import './styles/submit-limits-button.scss';

interface SubmitLimitsButtonProps {
  setOpen: (arg: boolean) => void;
  submitDisabled: boolean;
  submitUpdatedLimits: () => void;
}
export const SubmitLimitsButton: FunctionComponent<SubmitLimitsButtonProps> = ({
  setOpen,
  submitUpdatedLimits,
  submitDisabled
}) => (
  <div className="limits-modal-button-wrap">
    <div className="limits-modal-button-container">
      <StandardButton
        className="limits-modal-close"
        look={ButtonLooks.Secondary}
        onClick={(): void => setOpen(false)}
        size={ButtonSizes.Small}
      >
        <Trans>Cancel</Trans>
      </StandardButton>
    </div>
    <div className="limits-modal-button-container">
      <StandardButton
        className="limits-modal-close"
        disabled={submitDisabled}
        onClick={submitUpdatedLimits}
        size={ButtonSizes.Small}
      >
        <Trans>Update limits</Trans>
      </StandardButton>
    </div>
  </div>
);
