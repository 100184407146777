import { ButtonLooks, StandardButton, StandardDialog, brandfolderColorPalette } from '@brandfolder/react';

import { Trans, t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { DialogSizes } from '@components/library/dialog';

import { Person, PersonDetails } from '../customPeopleTagTypes';

import { TagPersonTextfield } from './TagPersonTextfield';

const RetagStandardDialog = styled(StandardDialog)`
  #retag-dialog,
  .bf-dialog__body,
  .bf-dialog__focus-lock,
  .bf-dialog__inner {
    height: fit-content;
    overflow: visible !important;
  }

  .bf-dialog__header {
    background-color: ${brandfolderColorPalette.neutrals[0]};
  }

  .bf-dialog__body {
    padding: 0 24px;

    .retag-dialog-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      h4 {
        color: ${brandfolderColorPalette.neutrals[900]};
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        padding: 36px 0 24px;
      }

      p {
        color: ${brandfolderColorPalette.neutrals[700]};
        font-size: 14px;
        font-weight: 450;
        line-height: 20px;
        padding-bottom: 24px;
      }
    }

    label {
      color: ${brandfolderColorPalette.neutrals[900]};
    }
  }

  .selected-asset-image {
    border-radius: 4px;
    height: 100px;
    width: 100px;
  }

  .bf-dialog__footer {
    margin-top: 0;
    padding-top: 36px;
    text-align: end;
    
    button {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
`;

const DialogAssetImage = styled.img`
  border-radius: 4px;
  height: 100px;
  object-fit: cover;
  width: 100px;
`;

interface RetagDialogProps {
  closeDialog: () => void;
  handleOnSave: () => void;
  handleSelectTargetPerson: (person: Person) => void;
  isOpen: boolean;
  personLabel: string;
  selectedAsset: PersonDetails;
  setPersonLabel: (personLabel: string) => void;
}

export const RetagDialog: FunctionComponent<RetagDialogProps> = ({
  closeDialog,
  handleOnSave,
  handleSelectTargetPerson,
  isOpen,
  personLabel,
  selectedAsset,
  setPersonLabel,
}) => {
  const { id } = useParams();

  return (
    <RetagStandardDialog
      footer={
        <>
          <StandardButton
            look={ButtonLooks.Tertiary}
            onClick={closeDialog}
          >
            <Trans>Cancel</Trans>
          </StandardButton>
          <StandardButton onClick={handleOnSave}>
            <Trans>Save</Trans>
          </StandardButton>
        </>
      }
      id="retag-dialog"
      labels={{ closeButtonLabel: 'Close' }}
      open={isOpen}
      setOpen={closeDialog}
      showTitle={false}
      size={DialogSizes.Small}
      title="Retag photo"
    >
      <div className="retag-dialog-content">
        <DialogAssetImage src={selectedAsset?.gcs_path} />
        <h4><Trans>Tag Photo</Trans></h4>
        <p><Trans>Tag this photo with a name</Trans></p>
      </div>
      <TagPersonTextfield
        currentPerson={{
          id,
          image: selectedAsset?.gcs_path,
          assetId: selectedAsset?.asset_id
        }}
        onSelectTargetPerson={handleSelectTargetPerson}
        placeholder={t`Enter name`}
        searchTag={personLabel}
        setPersonLabel={setPersonLabel}
        showLabel={true}
      />
    </RetagStandardDialog>
  );
};
