import React, { FunctionComponent } from 'react';

import { FilestackPreviewImage, FilestackPreviewImageProps } from '@components/common/filestack_uploader/FilestackPreviewImage';

interface AttachmentThumbnailImageProps extends Pick<FilestackPreviewImageProps, 'alt' | 'className' | 'onError' | 'src'> {}

/**
 * Wrapper around `FilestackPreviewImage`, which handles the image source from Filestack
 * when we upload a new attachment or replace an attachment in the asset modal.
 */
export const AttachmentThumbnailImage: FunctionComponent<AttachmentThumbnailImageProps> = (props)=> {
  const { alt, className, onError, src } = props;
  return (
    <FilestackPreviewImage
      alt={alt}
      className={className}
      data-private
      draggable={false}
      onError={onError}
      resourceKey={BFG.organizationKey || undefined}
      resourceType={BFG.organizationKey ? 'organization' : undefined}
      src={src}
    />
  );
};
