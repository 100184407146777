import { t, Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import DateTimePicker from '@components/common/date_time_picker/main';

import AccordionSection from './accordionSection';

class DateFilter extends React.Component {
  state = {
    created_at_startDate: moment().subtract(7, 'days').format('MM/DD/YYYY'),
    created_at_endDate: moment().format('MM/DD/YYYY'),
    created_at_range:
      `[${moment().subtract(7, 'days').format('YYYY-MM-DD')} TO ${moment().format('YYYY-MM-DD')}]`
  };

  updateDateStart = (selectedStartDate) => {
    this.updateDateRange(selectedStartDate, 'start');
  }

  updateDateEnd = (selectedEndDate) => {
    this.updateDateRange(selectedEndDate, 'end');
  }

  updateUploadDate(uploadDate) {
    if (uploadDate === 'range') {
      uploadDate = this.state.created_at_range; // eslint-disable-line no-param-reassign
    }

    this.props.updateFilters(uploadDate);
  }

  updateDateRange(selectedDate, whichDate) {
    let startDate = '';
    let startDateSearch = '';
    let endDate = '';
    let endDateSearch = '';

    if (whichDate === 'start') {
      startDate = moment(selectedDate).format('MM/DD/YYYY');
      startDateSearch = moment(selectedDate).format('YYYY-MM-DD');
      endDate = this.state.created_at_endDate;
      endDateSearch = moment(this.state.created_at_endDate).format('YYYY-MM-DD');
    } else if (whichDate === 'end') {
      endDate = moment(selectedDate).format('MM/DD/YYYY');
      endDateSearch = moment(selectedDate).format('YYYY-MM-DD');
      startDate = this.state.created_at_startDate;
      startDateSearch = moment(this.state.created_at_startDate).format('YYYY-MM-DD');
    }

    if (moment(startDate).isSameOrBefore(moment(endDate))) {
      const dateRange = `[${startDateSearch} TO ${endDateSearch}]`;

      this.props.updateFilters(dateRange);
      this.setState({
        created_at_startDate: startDate,
        created_at_endDate: endDate,
        created_at_range: dateRange
      });
    }
  }

  render() {
    const { createdDate } = this.props;

    return (
      <AccordionSection overflowContent title={t`Upload Date`}>
        <React.Fragment>
          <div className="flex-row radio-container">
            <input
              checked={createdDate === null}
              id="created-at-all-time-search"
              name="all time"
              onChange={() => this.updateUploadDate(null)}
              type="radio"
              value=""
            />
            <label htmlFor="created-at-all-time-search"><Trans>All</Trans></label>
            <span className="radio-dot" />
          </div>
          <div className="flex-row radio-container">
            <input
              checked={createdDate === ">now-30m"}
              id="created-at-30-minute-search"
              name="created_at"
              onChange={() => this.updateUploadDate(">now-30m")}
              type="radio"
              value=">now-30m"
            />
            <label htmlFor="created-at-1-day-search"><Trans>Last 30 minutes</Trans></label>
            <span className="radio-dot" />
          </div>
          <div className="flex-row radio-container">
            <input
              checked={createdDate === ">now-1d"}
              id="created-at-1-day-search"
              name="created_at"
              onChange={() => this.updateUploadDate(">now-1d")}
              type="radio"
              value=">now-1d"
            />
            <label htmlFor="created-at-1-day-search"><Trans>Past 24 hours</Trans></label>
            <span className="radio-dot" />
          </div>
          <div className="flex-row radio-container">
            <input
              checked={createdDate === ">now-7d"}
              id="created-at-7-days-search"
              name="created_at"
              onChange={() => this.updateUploadDate(">now-7d")}
              type="radio"
              value=">now-7d"
            />
            <label htmlFor="created-at-7-days-search"><Trans>Past 7 days</Trans></label>
            <span className="radio-dot" />
          </div>
          <div className="flex-row radio-container">
            <input
              checked={createdDate !== null
                        && this.props.createdDate.indexOf('TO') > -1}
              id="created-at-range"
              name="created_at"
              onChange={() => this.updateUploadDate('range')}
              type="radio"
              value="range"
            />
            <label htmlFor="created-at-range"><Trans>Date range</Trans></label>
            <span className="radio-dot" />
          </div>
          <div className={`flex-row date-range-picker-container ${createdDate
                            && createdDate.indexOf('TO') > -1 ? 'active' : ''}`}
          >
            <div className="range-start">
              <h4><Trans>From:</Trans></h4>
              <DateTimePicker
                allowPastDates
                selectedDate={this.state.created_at_startDate}
                updateDateTime={this.updateDateStart}
              />
            </div>
            <div className="range-end">
              <h4><Trans>To:</Trans></h4>
              <DateTimePicker
                allowPastDates
                selectedDate={this.state.created_at_endDate}
                updateDateTime={this.updateDateEnd}
              />
            </div>
          </div>
        </React.Fragment>
      </AccordionSection>
    );
  }
}

DateFilter.propTypes = {
  createdDate: PropTypes.string,
  updateFilters: PropTypes.func.isRequired,
};

DateFilter.defaultProps = {
  createdDate: null,
};

export default DateFilter;
