import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import Tooltip from '@components/common/tooltip/main';
import { TertiaryButton } from '@components/library/button/index';
import {
  SearchFilter,
  SearchQueryOptions,
} from '@components/show_page/sections/section_search/SearchTypes';

import './styles/search_actions.scss';

interface SearchActionProps {
  addRemovePinnedSearch: () => void;
  editable: boolean;
  searchFilters: SearchFilter[];
  searchQuery: string;
  shareSearch: () => void;
  submitSearchQuery: (filter: string, keypress?: boolean, options?: SearchQueryOptions) => void;
}

const SearchActions: FunctionComponent<SearchActionProps> = ({
  addRemovePinnedSearch,
  editable,
  searchFilters,
  searchQuery,
  shareSearch,
  submitSearchQuery,
}) => {
  const pinned = searchFilters.map((filter) => filter.query).includes(searchQuery);

  return (
    <div className="search-actions">
      {editable && (
        <Tooltip
          tooltipContent={pinned ? t`Unpin Search` : t`Pin Search`}
          tooltipId="brandfolder-pin"
        >
          <TertiaryButton
            aria-label={t`Pin Search`}
            className={`search-action-button ${pinned ? 'unpin' : ''}`}
            icon="bff-pin"
            iconSize={16}
            onClick={addRemovePinnedSearch}
          />
        </Tooltip>
      )}
      <Tooltip
        tooltipContent={t`Share Search`}
        tooltipId="brandfolder-share-search"
      >
        <TertiaryButton
          aria-label={t`Share Search`}
          className="search-action-button share-search"
          icon="bff-share"
          iconSize={16}
          onClick={shareSearch}
        />
      </Tooltip>
      <Tooltip
        tooltipContent={t`Clear Search`}
        tooltipId="brandfolder-clear-search"
      >
        <TertiaryButton
          aria-label={t`Clear Search`}
          className="search-action-button"
          icon="bff-close"
          iconSize={16}
          onClick={(): void => submitSearchQuery('')}
        />
      </Tooltip>
    </div>
  );
};

export default SearchActions;
