import { ButtonLooks, CircleLoader, LoaderSizes, StandardButton, brandfolderColorPalette } from '@brandfolder/react';
import { Trans, plural } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Pagination } from '../common/Pagination';
import { PER_PAGE_COUNT } from '../constants';
import { Brandfolder, Organization } from '../customPeopleTagTypes';
import { useFetchPeoplePageData } from '../fetch';
import { usePeoplePageSearchState } from '../hooks/usePeoplePageQueryState';
import '../styles/home.scss';

import { EmptyState } from './EmptyState';
import { FaceCard } from './FaceCard';

import { PeoplePageActionsBar } from './PeoplePageActionsBar';
import { PeoplePageHeader } from './PeoplePageHeader';

const PeoplePageWrapper = styled.div`
  margin: 40px auto;
  max-width: 1608px;
  min-height: 100vh;
  width: 93%;

  .bf-loader__circle {
    height: 50vh;
  }

  .clear-filter-button {
    font-weight: 500;
    margin-right: 16px;
    min-height: unset;
    padding: 0;

    &:hover {
      background-color: transparent;
      color: ${brandfolderColorPalette.primary[800]}
    }
  }
`;

const PeopleTagsFaceCardsWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 100%);
  justify-content: center;
  
  @media (min-width: 320px) {
    grid-template-columns: repeat(2, 48%);
  }
  @media (min-width: 744px) {
    grid-template-columns: repeat(3, minmax(166px, 1fr));
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(4, minmax(194px, 1fr));
  }
  @media (min-width: 1320px) {
    grid-template-columns: repeat(5, minmax(229px, 1fr));
  }
  @media (min-width: 1526px) {
    grid-template-columns: repeat(6, minmax(15%, 1fr));
  }
  @media (min-width: 1728px) {
    grid-template-columns: repeat(7, minmax(210px, 1fr));
  }
`;

interface HomeProps {
  brandfolder: Brandfolder;
  organization: Organization;
}

export const Home: FunctionComponent<HomeProps> = () => {
  const [state, setState] = usePeoplePageSearchState();
  const perPage = PER_PAGE_COUNT;

  const {
    initialLoadingState,
    peopleData,
    peopleDataLoading,
    refetchPeopleData
  } = useFetchPeoplePageData();

  const showEmptyState = !peopleData?.data.length && !peopleDataLoading && !initialLoadingState;
  const showPageLoader = initialLoadingState || !peopleData?.data.length && peopleDataLoading;

  const clearFilterButton = (
    <StandardButton
      className="clear-filter-button"
      look={ButtonLooks.TextSecondary}
      onClick={(): void => setState({ ids: '' })}
    >
      <Trans>Clear filter</Trans>
    </StandardButton>
  );

  return (
    <PeoplePageWrapper>
      <PeoplePageHeader />
      <PeoplePageActionsBar />
      <Pagination
        children={state.ids && clearFilterButton}
        page={Number(state.page) || 1}
        perPage={perPage}
        setPage={setState}
        text={plural(peopleData?.meta.total_count, {
          one: 'person',
          other: 'people',
        })}
        total={peopleData?.meta.total_count || 0}
      />
      {!peopleData?.meta.total_count &&
        state.ids &&
        clearFilterButton
      }
      {showPageLoader && (
        <CircleLoader label="people-data" size={LoaderSizes.Large} />
      )}
      <PeopleTagsFaceCardsWrapper>
        {peopleData?.data.map((faceCard) => (
          <FaceCard
            key={`${faceCard.gcs_path}_${faceCard.person_id}`}
            id={faceCard.person_id}
            imageUrl={faceCard.gcs_path}
            label={faceCard.face_label}
            loading={peopleDataLoading}
            refetch={refetchPeopleData}
          />
        ))}
      </PeopleTagsFaceCardsWrapper>
      {showEmptyState && (
        <EmptyState bfProcessed={peopleData?.meta.bf_processed} />
      )}
    </PeoplePageWrapper>
  );
};
