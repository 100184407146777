/* eslint-disable react/require-default-props */
import { BadgePill } from '@brandfolder/react';
import { t } from '@lingui/macro';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import Checkbox from '@components/common/checkbox/main';
import { customTemplatesVisitable } from '@components/design_huddles/editors/helpers';
import { BrandfolderHexColors } from '@components/library/utils';

import { ViewOnlyIcon } from '@components/view-only/ViewOnlyIcon';

import AssetActionsMenu from './asset_actions_menu';

import './styles/_asset_actions_menu.scss';

const CardListView = ({
  actionsMenuItemsShown,
  activeLabel,
  allowActionsMenu,
  asset,
  connectDragSource,
  createAndClickLink,
  customFieldsKeys,
  handleOpenModal,
  handleRemoveSelectedAssetFromSet,
  handleSelectionClick,
  filtersDrawerOpen,
  getBannerInfo,
  onVisitAsset,
  renderAssetThumbnail,
  reorderable,
  section,
  selected,
  downloadRequestId,
  showCustomFields,
  task,
  windowDimensions
}) => {
  const [showMoreTags, setShowMoreTagsCopy] = useState(false);
  const [showFade, setShowFade] = useState(false);
  const [showMoreTagsCopy, setMoreTagsCopy] = useState('');
  const tagsList = useRef();
  const {
    attachment_count,
    extension,
    printui_editor_link,
    prioritized_custom_fields,
    storyteq_editor_link,
    design_huddle_editor_link,
    content_automation_editor_link,
    name,
    tag_names,
    type,
    view_only
  } = asset.attributes;

  const hideAttachmentInfo = (extension === "empty" && attachment_count < 1)
    || type === "Text"
    || type === "texts";

  const attachmentCopy = (printui_editor_link || storyteq_editor_link || design_huddle_editor_link || content_automation_editor_link)
    ? <span className="bff-template-editor" />
    : <span>{extension}</span>;

  const renderMoreTagsCopy = () => {
    const tagNameSpacing = 6; // must match scss variable $tagNameSpacing (margin-right style)
    let tagsElementsWidth = 0;
    let tagsCount = 0;
    let hiddenTagsCount = 0;

    if (tagsList.current) {
      const tagsListWidth = tagsList.current.offsetWidth;
      const tagsHTMLCollection = tagsList.current.children; // 'array-like' node list of li elements
      tagsCount = tagsHTMLCollection.length;

      for (let i = 0; i < tagsCount; i += 1) {
        tagsElementsWidth += (tagsHTMLCollection[i].offsetWidth + tagNameSpacing);

        hiddenTagsCount = (tagsElementsWidth > tagsListWidth) && hiddenTagsCount === 0
          ? tagsCount - (i + 1)
          : hiddenTagsCount;
      }

      setShowMoreTagsCopy(tagsElementsWidth > tagsListWidth && hiddenTagsCount > 0);
      setShowFade(tagsElementsWidth > tagsListWidth);
      setMoreTagsCopy(hiddenTagsCount <= 99 ? t`+ ${hiddenTagsCount} more` : t`99+`);
    }
  };

  const prioritizedCustomFieldValues = (keyName) => {
    const keyValueObject = prioritized_custom_fields?.find((fields) => (
      fields.key.name === keyName
    ));

    if (keyValueObject?.values.length) {
      return keyValueObject.values.map(({ value }) => value).join(', ');
    }

    return '-';
  };

  useEffect(() => {
    const updateMoreTags = () => {
      if (tagsList && tagsList.current) {
        renderMoreTagsCopy();
      } else {
        setShowMoreTagsCopy(false);
        setMoreTagsCopy('');
      }
    };

    setTimeout(updateMoreTags, 500); // delay allows element dimensions to update, e.g. when advanced filters drawer slides open
  }, [tagsList, filtersDrawerOpen, windowDimensions, showCustomFields]);

  const { color, copy, displayType } = getBannerInfo();
  const visitableCustomTemplateAsset = design_huddle_editor_link && customTemplatesVisitable();

  const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');

  return (
    <div className="list-container">

      {reorderable && (
        <div className="reorder-container">
          {connectDragSource(
            <button
              className="asset-reorder button tertiary"
              type="button"
            >
              <span aria-label="Reorder" className="bff-reorder" />
            </button>
          )}
        </div>
      )}

      <div
        className="list-asset-card j-resource-card"
        id={asset.id}
        onClick={(e) => {
          if (content_automation_editor_link || storyteq_editor_link || printui_editor_link || visitableCustomTemplateAsset || type === "Press") {
            e.stopPropagation();
            createAndClickLink();
          }
        }}
      >
        {renderAssetThumbnail()}
      </div>

      <div className="list-asset-checkbox">
        <Checkbox
          checked={selected}
          size="sm"
          toggle={handleSelectionClick}
        />
      </div>

      <p className="list-asset-name asset-name">{name}</p>

      {!!color && !!copy & !!displayType && (
        <BadgePill
          backgroundColor={color}
          className={`status-ribbon ${displayType}`}
          fontColor={color === BrandfolderHexColors.WarningRed ? BrandfolderHexColors.White : undefined}
          size='small'
        >
          {copy}
        </BadgePill>
      )}

      {!hideAttachmentInfo && (
        <div className={viewOnlyAssetsEnabled && view_only && !downloadRequestId ? 'list-attachment-info-padding' : 'list-attachment-info'}>
          <div className="file-type-pill">
            {attachment_count > 1
              ? (
                <>
                  <span className="bff-paperclip" />
                  <span>{attachment_count > 100 ? '+' : attachment_count }</span>
                </>
              )
              : attachmentCopy
            }
          </div>
        </div>
      )}

      {viewOnlyAssetsEnabled && view_only && !downloadRequestId && (
        <div className='list-view-only-icon'>
          <ViewOnlyIcon />
        </div>
      )}

      {showCustomFields
        ? (
          <>
            {customFieldsKeys.map((key, i) => (
              <p
                key={`${asset.id}-${i}`} // eslint-disable-line react/no-array-index-key
                className="list-asset-custom-field"
                title={prioritizedCustomFieldValues(key)}
              >
                {prioritizedCustomFieldValues(key)}
              </p>
            ))}
          </>
        )
        : (
          <>
            <ul
              ref={tagsList}
              className={classnames(
                'list-asset-tags',
                { 'list-show-more-tags': showMoreTags },
                { 'overflow-fade': showFade },
              )}
            >
              {tag_names.length
                ? (tag_names.map((tag) => (<li key={tag} className="tag-name">{tag}</li>)))
                : (<p>-</p>)
              }
            </ul>
            {showMoreTags && (<p className="list-show-more-tags-copy">{showMoreTagsCopy}</p>)}
          </>
        )
      }

      {allowActionsMenu && (
        <div
          className="list-asset-actions action-menu"
          onClick={(e) => e.stopPropagation()}
          onKeyPress={(e) => e.stopPropagation()}
          role="button"
          tabIndex="0"
        >
          <AssetActionsMenu
            actionsMenuItemsShown={actionsMenuItemsShown}
            activeLabel={activeLabel}
            asset={asset}
            handleOpenModal={handleOpenModal}
            handleRemoveSelectedAssetFromSet={handleRemoveSelectedAssetFromSet}
            onVisitAsset={onVisitAsset}
            section={section}
            task={task?.attributes}
          />
        </div>
      )}

    </div>
  );
};

CardListView.propTypes = {
  actionsMenuItemsShown: PropTypes.shape({
    canEdit: PropTypes.bool,
    canShare: PropTypes.bool,
    canAnalyze: PropTypes.bool,
    canDelete: PropTypes.bool,
    canRemove: PropTypes.bool,
    canUnlabel: PropTypes.bool,
    canDownload: PropTypes.bool,
    canOpen: PropTypes.bool,
  }).isRequired,
  activeLabel: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }),
  allowActionsMenu: PropTypes.bool.isRequired,
  asset: PropTypes.shape({
    attributes: PropTypes.shape({
      attachment_count: PropTypes.number,
      extension: PropTypes.string,
      name: PropTypes.string,
      printui_editor_link: PropTypes.string,
      prioritized_custom_fields: PropTypes.arrayOf(PropTypes.shape({
        keys: PropTypes.shape({
          name: PropTypes.string,
          prioritized: PropTypes.bool,
          multi_value_enabled: PropTypes.bool
        }),
        values: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string })),
      })),
      storyteq_editor_link: PropTypes.string,
      design_huddle_editor_link: PropTypes.string,
      content_automation_editor_link: PropTypes.string,
      tag_names: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.string,
      view_only: PropTypes.bool
    }),
    id: PropTypes.string,
  }).isRequired,
  connectDragSource: PropTypes.func.isRequired,
  createAndClickLink: PropTypes.func.isRequired,
  customFieldsKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  filtersDrawerOpen: PropTypes.string.isRequired,
  getBannerInfo: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleRemoveSelectedAssetFromSet: PropTypes.func.isRequired,
  handleSelectionClick: PropTypes.func.isRequired,
  onVisitAsset: PropTypes.func,
  renderAssetThumbnail: PropTypes.func.isRequired,
  reorderable: PropTypes.bool.isRequired,
  section: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  downloadRequestId: PropTypes.number,
  showCustomFields: PropTypes.bool,
  task: PropTypes.shape({
    attributes: PropTypes.shape({})
  }),
  windowDimensions: PropTypes.shape({
    innerHeight: PropTypes.number,
    innerWidth: PropTypes.number,
  })
};

CardListView.defaultProps = {
  activeLabel: null,
  downloadRequestId: null,
  onVisitAsset: () => {},
  showCustomFields: false,
  task: undefined,
  windowDimensions: {},
};

export default CardListView;
