import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import {
  DesignHuddleFileTypes,
  DesignHuddleExportPdfPost,
  DesignHuddleExportPost,
  DesignHuddleExportPostResponse
} from '@api/v4/private/resources/design_huddles';
import { DownloadButton } from '@components/design_huddles/editors/DownloadButton';
import { PrimaryButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { ListDropdown, ListOption } from '@components/library/dropdown';
import { LabelPosition, StandardSwitch, SwitchSize } from '@components/library/switch';
import {
  EXPORT_CROPMARKS_PREFERENCE_KEY,
  EXPORT_FILE_TYPE_PREFERENCE_KEY,
  getStorage,
  setStorage,
  StorageTypes
} from '@helpers/storage';

import './styles/export.scss';

interface ExportProps {
  projectId: string;
  projectTitle: string;
  templateKey: string | null;
}

export const Export: FunctionComponent<ExportProps> = ({ projectId, projectTitle, templateKey }) => {
  const options: ListOption[] = [
    {
      label: DesignHuddleFileTypes.JPG.toUpperCase(),
      value: DesignHuddleFileTypes.JPG
    },
    {
      label: DesignHuddleFileTypes.PDF.toUpperCase(),
      value: DesignHuddleFileTypes.PDF
    },
    {
      label: DesignHuddleFileTypes.PNG.toUpperCase(),
      value: DesignHuddleFileTypes.PNG
    }
  ];

  const [cropmarks, setCropmarks] = useState(getStorage<boolean>(StorageTypes.Local, EXPORT_CROPMARKS_PREFERENCE_KEY) || false);
  const [fileType, setFileType] = useState(getStorage<ListOption>(StorageTypes.Local, EXPORT_FILE_TYPE_PREFERENCE_KEY) || options[0]);
  const [showExport, setShowExport] = useState(false);

  const format = fileType.value as DesignHuddleFileTypes;
  const filename = `${projectTitle}.${format}`;
  const pdfExport = format === DesignHuddleFileTypes.PDF;

  const exportPost: DesignHuddleExportPost = {
    data: {
      filename,
      format,
      project_id: projectId, // eslint-disable-line @typescript-eslint/naming-convention,
      ...templateKey && { template_key: templateKey } // eslint-disable-line @typescript-eslint/naming-convention
    }
  };

  const exportPdfPost: DesignHuddleExportPdfPost = {
    data: {
      cropmarks,
      filename,
      format,
      project_id: projectId, // eslint-disable-line @typescript-eslint/naming-convention
      ...templateKey && { template_key: templateKey } // eslint-disable-line @typescript-eslint/naming-convention
    }
  };

  const fetchExport = useFetch<DesignHuddleExportPostResponse>({
    body: pdfExport ? exportPdfPost : exportPost,
    fetchOnMount: false,
    method: 'POST',
    url: `/api/v4/private/${BFG.resource.type}s/${BFG.resource.key}/design_huddles/exports`
  });

  const downloadFile = (url: string): void => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleSetFileType = (listOption: ListOption): void => {
    setFileType(listOption);
    setStorage(StorageTypes.Local, EXPORT_FILE_TYPE_PREFERENCE_KEY, listOption);
  };

  const handleSetCropmarks = (): void => {
    const newCropmarks = !cropmarks;
    setCropmarks(newCropmarks);
    setStorage(StorageTypes.Local, EXPORT_CROPMARKS_PREFERENCE_KEY, newCropmarks);
  };

  useEffect(() => {
    if (fetchExport.error) {
      Notify.create({
        title: t`Error exporting your file. Please try again.`,
        type: 'error'
      });
    }
  }, [fetchExport.error]);

  useEffect(() => {
    if (fetchExport.response) {
      downloadFile(fetchExport.response.data.download_url);
      setShowExport(false);
    }
  }, [fetchExport.response]);

  return (
    <>
      <DownloadButton setShowExport={setShowExport} />
      <StandardDialog
        height={350}
        id="project-export-dialog"
        open={showExport}
        setOpen={setShowExport}
        showFooter={false}
        title={t`Export`}
        width={250}
      >
        <ListDropdown
          className="export-file-type"
          disabled={fetchExport.loading}
          id="project-export-file-type"
          label={t`File Type`}
          onChange={(listOption): void => handleSetFileType(listOption)}
          options={options}
          value={format}
          virtualizeOptions={false}
        />
        {pdfExport && (
          <StandardSwitch
            className='export-cropmarks'
            id="project-export-cropmarks"
            isChecked={cropmarks}
            isDisabled={fetchExport.loading}
            labelPosition={LabelPosition.Right}
            onChange={(): void => handleSetCropmarks()}
            size={SwitchSize.Medium}
          >
            <Trans>Add 0.125 inch bleed</Trans>
          </StandardSwitch>
        )}
        <PrimaryButton
          disabled={fetchExport.loading}
          id="project-export"
          onClick={(): void => {
            fetchExport.fetch();
          }}
        >
          {fetchExport.loading ? <Trans>Downloading...</Trans> : <Trans>Download</Trans>}
        </PrimaryButton>
      </StandardDialog>
    </>
  );
};
