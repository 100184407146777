import React from 'react';
import PropTypes from 'prop-types';

import copyToClipboard from '@helpers/copy_to_clipboard';
import './styles/palette.scss';

class Palette extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copying: false,
    };
  }

  copy(color) {
    copyToClipboard(color);

    this.setState({ copying: true, }, () => {
      setTimeout(() => {
        this.setState({ copying: false });
      }, 2000);
    });
  }

  renderColors() {
    return this.props.colors.map((color, i) => (
      <li key={color || i}>
        <button
          type="button"
          className="workbench-palette__swatch"
          style={{ backgroundColor: color }}
          onClick={() => this.copy(color)}
          disabled={!color}
        />

        <button
          type="button"
          className="workbench-palette__color-name"
          onClick={() => this.copy(color)}
          disabled={!color}
        >
          {color || 'HEX'}
        </button>
      </li>
    ));
  }

  render() {
    const hasColors = Boolean(this.props.colors[0]);

    return (
      <React.Fragment>
        <ul className={`workbench-palette__colors ${hasColors ? '' : 'disabled'}`}>
          {this.renderColors()}
        </ul>
        <p
          className={`workbench-palette__instructions ${hasColors ? '' : 'disabled'}`}
        >
          {this.state.copying ? 'Copied to clipboard!' : 'Click HEX to copy'}
        </p>
        <div className="color-palette-reset-button">{this.props.renderResetButton()}</div>
      </React.Fragment>
    );
  }
}

Palette.propTypes = {
  asset: PropTypes.shape({
    url: PropTypes.string,
    filetype: PropTypes.string,
  }),
  colors: PropTypes.arrayOf(PropTypes.string),
  renderResetButton: PropTypes.func
};

Palette.defaultProps = {
  asset: null,
  colors: Array(5).fill(null),
  renderResetButton: () => {}
};

export default Palette;
