import { t, Trans } from '@lingui/macro';
import React, {
  useEffect,
  useState,
  FunctionComponent,
} from 'react';

import { getBrandguide, deleteBrandguide } from '@api/v4/resources/brandguides';
import { BFLoader } from '@components/common/loader/main';
import {
  CloseButton,
  DangerButton,
} from '@components/library/button/index';
import { InputType } from '@components/library/inputs/Input.props';
import PrimaryInput from '@components/library/inputs/PrimaryInput';
import renderModal from '@components/show_page/modals/renderModal';

interface DeleteModalProps {
  brandguideKey: string;
  closeModal: () => void;
}

interface Attributes {
  name: string;
  slug: string;
}

const defaultData: Attributes = { name: '', slug: '' };

const DeleteModal: FunctionComponent<DeleteModalProps> = ({
  brandguideKey,
  closeModal,
}) => {
  const [initialData, setInitialData] = useState(defaultData);
  const [slug, setSlug] = useState<string>();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleDelete = async (): Promise<void> => {
    setIsSubmitting(true);

    try {
      await deleteBrandguide(brandguideKey);
      Notify.create({
        title: t`Brandguide deleted! Refreshing...`,
        type: 'success'
      });
      setIsSubmitDisabled(true); // maintain disabled button while page reloads
      window.location.reload();
    } catch (error) {
      console.error(error);

      Notify.create({
        title: t`An error occurred while deleting this Brandguide. Please try again or contact support.`,
        type: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchBrandguide = async (): Promise<void> => {
    try {
      const { attributes } = await getBrandguide(brandguideKey);
      setInitialData({ name: attributes.name, slug: attributes.slug });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBrandguide();
    return closeModal;
  }, []);

  useEffect(() => {
    setIsSubmitDisabled(slug !== initialData.slug);
  }, [slug]);

  const { name: initialName, slug: initialSlug } = initialData;

  return (
    <div className="modal-content-wrapper brandguide-modal">
      <div className="modal-content-wrapper__header">
        <span className="bff-trash icon" />
        <h3><Trans>Delete Brandguide</Trans></h3>
        <CloseButton onClick={closeModal} />
      </div>
      {isLoading ? (
        <div className="loader-container"><BFLoader /></div>
      ) : (
        <div className="modal-content-wrapper__body delete-modal">
          <h1 className="warning-title"><Trans>Are you sure you want to delete {initialName}?</Trans></h1>
          <p className="warning-copy">
            <Trans>There is no way to undo this action. It is immediate, and all users belonging to this Brandguide will lose access.</Trans>
          </p>
          <p className="warning-copy">
            <Trans>
              To confirm deletion, please enter the URL for the Brandguide below:&nbsp;
              <span className="slug">{initialSlug}</span>
            </Trans>
          </p>
          <PrimaryInput
            attributes={{
              name: 'slug',
              onChange: (e: InputChangeEvent): void => setSlug(e.target.value),
              placeholder: initialData.slug,
              type: InputType.Text,
            }}
            input={{ value: slug }}
          />
          <div className="button-container">
            <DangerButton
              disabled={isSubmitDisabled}
              isLoading={isSubmitting}
              onClick={(): void => { handleDelete(); }}
              size="medium"
            >
              <Trans>Delete</Trans>
            </DangerButton>
          </div>
        </div>
      )}
    </div>
  );
};

const ModalComponent = renderModal(DeleteModal, 'brandguide-settings-modal');
export default ModalComponent;
