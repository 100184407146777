import React, { useContext, useEffect, useState } from 'react';

import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import PrintuiSettingsForm from '@components/printui/settings_form';
import { get as getAttachment } from '@api/v4/attachments';
import { BFLoader } from '@components/common/loader/main';
import { convertSnakeToCamel } from '@components/asset/modal/tabs/edit/helpers';
import { AttachmentResponseObject } from '@api/v4/attachments/attachmentTypes';

import {
  FlattenedAttachment,
  PrintUI,
} from '@components/asset/modal/tabs/edit/EditTabTypes';

interface TemplatingScopedContext {
  state: {
    editState: {
      attachments: FlattenedAttachment[];
    };
    initialData: {
      printui: PrintUI;
    };
  };
}

const TemplatingSection: React.FunctionComponent<{ attachmentKey: string }> = ({
  attachmentKey
}) => {
  const [attachment, setAttachment] = useState<AttachmentResponseObject>();
  const { state }: TemplatingScopedContext = useContext(assetModalContext);

  useEffect(() => {
    getAttachment(attachmentKey, { params: { fields: 'printui_settings' } })
      .then(setAttachment)
      .catch((err) => console.log(err));
  }, []);

  if (!attachment) {
    return <BFLoader />;
  }

  const { printui_settings } = attachment?.attributes; // eslint-disable-line @typescript-eslint/camelcase

  // rails automatically converts keys to snake case, need to convert back to camel case here
  const printuiSettingsCamelCase = {};
  Object.keys(printui_settings).forEach((key) => {
    const camelCaseKey = key === 'use_html_editor' ? 'useHTMLEditor' : convertSnakeToCamel(key);
    printuiSettingsCamelCase[camelCaseKey] = printui_settings[key];
  });

  // attachment structure based on serverside attachment (i.e. no 'attributes', camelCase keys)
  const settingsFormAttachment = {
    attachment_key: attachmentKey,
    data: { printuiSettings: printuiSettingsCamelCase },
    id: attachment.id,
  };
  return (
    <PrintuiSettingsForm
      attachment={settingsFormAttachment}
      printui={state.initialData.printui}
    />
  );
};

export default TemplatingSection;
