import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';

import fetchJSON from '@api/api_helper';
import { ApiDatumResponse, ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { getPortalCard, createPortalCard, updatePortalCard } from '@api/v4/private/portal_cards';
import {
  PortalCardTypes,
  PortalCardSubTypes,
  WidgetResource,
  ValidationErrorsEnum,
  PortalCard
} from '@api/v4/private/PortalCardTypes';
import { Resource } from '@api/v4/resources/resourceTypes';
import { BFLoader } from '@components/common/loader/main';
import {
  CloseButton,
  PrimaryButton,
  TextWarningButton,
} from '@components/library/button/index';
import { ListDropdown, ListOpenDirections, ListOption } from '@components/library/dropdown';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';
import renderModal from '@components/show_page/modals/renderModal';
import { isGettyClient } from '@helpers/getty-strings';

import { handleDeletion } from '../helpers/card_deletion_helper';
import { handleValidations, nameError } from '../portal_cards/validations';

interface WidgetFormProps {
  closeModal: () => void;
  libraryName: string;
  triggerCardsRefresh: () => void;
  portalCardId?: string;
}

const WidgetForm: React.FunctionComponent<WidgetFormProps> = ({
  closeModal,
  libraryName,
  portalCardId,
  triggerCardsRefresh
}): JSX.Element => {
  const type = PortalCardTypes.PortalWidget;
  const [brandfolders, setBrandfolders] = useState<ApiResponseObject<Resource, string>[]>([]);

  const [name, setName] = useState('');
  const [subType, setSubType] = useState<PortalCardSubTypes | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsEnum[]>([]);
  const [widgetResource, setWidgetResource] = useState<WidgetResource>({ name: '', key: '', type: '' });
  const portalId = (BFG.resource.type === 'portal') ? BFG.resource.key : null;
  const organizationId = BFG.organization_key;
  const invalidSubmission = (validationErrors.length > 0);
  const [isLoading, setIsLoading] = useState(true);
  const allowedSubTypes = [
    PortalCardSubTypes.FeatureTaggedAssetsWidget,
    PortalCardSubTypes.RecentlyCreatedAssetsWidget,
    PortalCardSubTypes.RecentlyUpdatedAssetsWidget,
    PortalCardSubTypes.TopScoredAssetsWidget
  ];

  const humanReadableSubtypeMap = {
    [PortalCardSubTypes.FeatureTaggedAssetsWidget]: t`Featured Assets`,
    [PortalCardSubTypes.RecentlyCreatedAssetsWidget]: t`Recently Created Assets`,
    [PortalCardSubTypes.RecentlyUpdatedAssetsWidget]: t`Recently Updated Assets`,
    [PortalCardSubTypes.TopScoredAssetsWidget]: t`Top Scored Assets`,
  };

  useEffect(() => {
    if (portalCardId) {
      const options = {
        portalCardId,
        params: {
          include: 'portal_cardable'
        }
      };
      getPortalCard(options)
        .then((response) => {
          const { attributes } = response.data;
          const portalCardable = response.included?.filter((x) => ['brandfolders', 'collections'].includes(x.type))[0];
          setName(attributes.name);
          setSubType(attributes.sub_type || null);
          if (portalCardable) {
            setWidgetResource({
              type: portalCardable.type,
              key: portalCardable.id,
              name: portalCardable.attributes.name
            });
          }
        });
    }
  }, [portalCardId]);

  useEffect(() => {
    if (organizationId) {
      // NOTE: implement updateFetchControllers
      fetchJSON(`/api/v4/organizations/${organizationId}?include=brandfolders`, {}, (): void => { /* no op */ })
        .then((response: ApiDatumResponse<Resource>) => {
          if (response.included) {
            setBrandfolders(response.included);
          }
          setIsLoading(false);
        });
    }
  }, [organizationId]);

  const requestBody: PortalCard = {
    name,
    subType,
    type,
    widgetResource
  };

  const submitCreateCard = ((): void => {
    if (portalId) {
      if (handleValidations(type, setValidationErrors, requestBody)) return;

      createPortalCard(portalId, requestBody)
        .then(() => {
          triggerCardsRefresh();
          closeModal();
        })
        .catch(() => {
          Notify.create({
            title: t`Something went wrong!`,
            body: t`Please try creating this widget again.`,
            type: 'error'
          });
          closeModal();
        });
    }
  });

  const submitUpdateCard = ((): void => {
    if (portalCardId) {
      if (handleValidations(type, setValidationErrors, requestBody)) return;

      updatePortalCard(portalCardId, requestBody)
        .then(() => {
          triggerCardsRefresh();
          closeModal();
        })
        .catch(() => {
          Notify.create({
            title: t`Something went wrong!`,
            body: t`Please try updating this widget again.`,
            type: 'error'
          });
          closeModal();
        });
    }
  });

  const brandfolderSelectOptions = (): ListOption[] => (
    brandfolders.map((option) => (
      { value: option.id, label: option.attributes.name }
    ))
  );

  const subTypeSelectionOptions = (): ListOption[] => (
    allowedSubTypes.map((cardSubType) => ({ value: cardSubType, label: humanReadableSubtypeMap[cardSubType] }))
  );

  // Note: I can not get a selectValue to work properly for the widgetResource.
  return (
    <div className="modal-content-wrapper portal-modal">
      <div className="portal-modal__header">
        {portalCardId ? (
          <span aria-hidden="true" className="bff-edit" />
        ) : (
          <span aria-hidden="true" className="bff-plus" />
        )}
        <h3 className="modal-title">{portalCardId ? <Trans>Edit Widget</Trans> : <Trans>Create Widget</Trans>}</h3>
        <CloseButton onClick={closeModal} />
      </div>
      <div className="portal-modal__body widget-form">
        <div className="tile-form__name">
          <div className="tile-form__character-limit">
            <InputContainer
              attributes={{
                name: 'widget-name',
                onChange: (e: InputChangeEvent): void => setName(e.target.value),
                type: InputType.Text,
              }}
              input={{ value: name, error: nameError(validationErrors) }}
              labelCopy={<><Trans>Name</Trans> *</>}
              submitAttempted={invalidSubmission}
            />
          </div>
        </div>
        {isLoading
          ? <BFLoader />
          : (
            <div className="brandfolder-menu__dropdown">
              <label
                className="brandfolder-label"
                htmlFor="content-type-selection"
              >
                <Trans>Content Type</Trans>
              </label>
              <ListDropdown
                className="portal-modal-widget-dropdown"
                onChange={(option): void => setSubType(option.value as PortalCardSubTypes)}
                options={subTypeSelectionOptions()}
                placeholder={t`Select...`}
                value={subType}
                virtualizeOptions={false}
              />
              <label
                className="brandfolder-label"
                htmlFor="brandfolder-selection"
              >
                {isGettyClient() ? <Trans>Library</Trans> : <Trans>Brandfolder</Trans>}
              </label>
              <ListDropdown
                className="portal-modal-brandfolder-dropdown"
                onChange={(option): void => setWidgetResource({
                  key: option.value as string,
                  name: option.label as string,
                  type: 'brandfolders'
                })}
                openDirection={ListOpenDirections.Up}
                options={brandfolderSelectOptions()}
                placeholder={t`Select...`}
                value={widgetResource.key}
                virtualizeOptions={false}
              />
            </div>
          )
        }
        <div className="portal-modal__button">
          {portalCardId && (
            <div className="portal-modal__buttons portal-modal__buttons--two-buttons">
              <TextWarningButton
                icon="bff-trash"
                onClick={(): void => handleDeletion(portalCardId, name, closeModal, triggerCardsRefresh)}
              >
                <Trans>Delete Widget</Trans>
              </TextWarningButton>
              <PrimaryButton
                onClick={submitUpdateCard}
                size="medium"
              >
                <Trans>Update Widget</Trans>
              </PrimaryButton>
            </div>
          )}
          {!portalCardId && (
            <PrimaryButton
              onClick={submitCreateCard}
              size="small"
            >
              <Trans>Create Widget</Trans>
            </PrimaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(WidgetForm, 'WidgetForm');
export default ModalComponent;
