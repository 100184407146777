import { ColorData } from '@api/v4/assets/assetTypes';

export interface AssetBackgroundColor {
  background: string
}

/**
 * Get the asset preview background color. If "default" is passed, returns undefined.
 * @param backgroundColor string | undefined
 * @returns AssetBackgroundColor | undefined
 */
export const getAssetBackgroundColor = (backgroundColor: string | undefined): AssetBackgroundColor => {
  if (backgroundColor && backgroundColor !== 'default') {
    return {
      background: backgroundColor
    };
  }
  return undefined;
};

export const getColorUrl = (assetData: ColorData): string => {
  const { b, c, g, hex, k, m, r, y } = assetData;

  if (hex || r || c) {
    if (hex) {
      return `https://colorhexa.com/${hex.toLowerCase()}`;
    }

    if (r) {
      return `https://colorhexa.com/color.php?c=rgb+${r}+${g}+${b}`;
    }

    if (c) {
      return `https://colorhexa.com/color.php?c=cmyk+${c}+${m}+${y}+${k}`;
    }
  }

  return undefined;
};