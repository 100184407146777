import React, { forwardRef, CSSProperties, MutableRefObject, ReactElement } from 'react';
import { FixedSizeList as List } from 'react-window';
import { ListOptionNullOmitted } from '@components/library/dropdown';

interface VirtualizedListProps {
  createOptionsCallback: (option: ListOptionNullOmitted, index: number, style?: CSSProperties) => ReactElement;
  options: ListOptionNullOmitted[];
  styleClassNames: CSSProperties;
  dropdownContainerHeight?: number;
  dropdownItemHeight?: number;
}

export const VirtualizedList = forwardRef(({
  createOptionsCallback,
  dropdownContainerHeight = 190, // height (in px) of dropdown container (200px - 10px of padding)
  dropdownItemHeight = 42, // height (in px) of dropdown list item
  options,
  styleClassNames,
}: VirtualizedListProps, ref: MutableRefObject<any>) => (
  <List
    ref={ref}
    className={styleClassNames['virtualized-options-container']}
    height={dropdownContainerHeight}
    itemCount={options.length}
    itemSize={dropdownItemHeight}
    style={{ height: 'unset', maxHeight: `${dropdownContainerHeight}px` }}
    width="100%"
  >
    {({ index, style }): ReactElement => createOptionsCallback(options[index], index, style as CSSProperties)}
  </List>
));
