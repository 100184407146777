import React, { FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';

import { BaseAccordionProps } from '@components/library/accordion';
import { Accordion } from './components/Accordion';

import classes from './styles/accordions.module.scss';

interface AccordionState {
  isDisabled: boolean;
  isOpen: boolean;
}

const defaultAccordionState: AccordionState = {
  isDisabled: false,
  isOpen: false
};

/**
 * Base accordions.
 * Follows W3C ARIA practices: https://www.w3.org/TR/wai-aria-practices-1.2/#accordion.
 * W3C example: https://www.w3.org/TR/wai-aria-practices-1.2/examples/accordion/accordion.html.
 * @param {BaseAccordionProps} props BaseAccordionProps
 */
export const BaseAccordion: FunctionComponent<BaseAccordionProps> = (props) => {
  const {
    accordions,
    allowMultipleExpanded = true,
    allowZeroExpanded = false,
    animation,
    changeToIndex,
    id,
    initialActiveIndex,
    initialOpen = true,
    loading,
    onChange,
    unmountOnExit
  } = props;

  const [accordionsState, setAccordionsState] = useState<AccordionState[]>([]);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);

  const length = accordions.length;
  const zeroBasedLength = length - 1;

  const handleOpen = (isOpen: boolean, currentAccordions: AccordionState[]): boolean => {
    // if it's open and we allow no accordions open, close it
    if (isOpen && allowZeroExpanded) {
      return false;
    }
    // if it's open, there needs to be at least one other accordion open to close it
    if (isOpen && !allowZeroExpanded && currentAccordions.filter((accordion) => accordion.isOpen).length < 2) {
      return true;
    }
    // else just toggle it
    return !isOpen;
  };

  const handleDisabled = (isOpen: boolean, currentAccordions?: AccordionState[]): boolean => {
    // if it's open, at least one accordion needs to be open to enable the accordion
    if (isOpen && !allowZeroExpanded && currentAccordions && currentAccordions.filter((accordion) => accordion.isOpen).length < 2) {
      return true;
    }
    // if only one at a time and we don't allow all collapsed, disable the accordion
    // otherwise it will be false so the accordion is enabled
    return !allowMultipleExpanded && !allowZeroExpanded && isOpen;
  };

  const handleAccordionDisabled = (accordion: AccordionState, currentAccordions: AccordionState[]): AccordionState => ({
    ...accordion,
    isDisabled: handleDisabled(accordion.isOpen, currentAccordions)
  });

  const handleAccordionChange = (index: number): void => {
    // if only one at a time, close all other accordions, else make of copy of accordionsState
    let currentAccordions = !allowMultipleExpanded ? accordionsState.map(() => ({ ...defaultAccordionState })) : [...accordionsState];

    // copy the current accordion we are toggling and handle isOpen
    const currentAccordion = { ...accordionsState[index] };
    currentAccordion.isOpen = handleOpen(currentAccordion.isOpen, currentAccordions);
    currentAccordions[index] = currentAccordion;

    // IMPORTANT: we determine isDisabled after isOpen has been set on all accordions
    currentAccordions = [...currentAccordions.map((accordion) => handleAccordionDisabled(accordion, currentAccordions))];
    setAccordionsState(currentAccordions);

    if (onChange) {
      onChange(index);
    }
  };

  /**
   * Handling up arrow, down arrow, spacebar, home, and end keys are required for accessibility compliance
   * https://www.w3.org/TR/wai-aria-practices-1.2/#keyboard-interaction
   */
  const handleAccordionKeyUp = (e: KeyboardEvent<HTMLButtonElement>, index: number): void => {
    // only handle accordion button keyboard events if we have more than one accordion
    if (length > 1) {
      // up arrow key focuses on the previous accordion button (or wraps around to the last)
      if (e.key === 'ArrowUp') {
        const prev = focusIndex === null ? -1 : focusIndex - 1;
        const prevIndex = prev < 0 ? zeroBasedLength : prev;
        setFocusIndex(prevIndex);
      }

      // down arrow key focuses on the next accordion button (or wraps around to the first)
      if (e.key === 'ArrowDown') {
        const next = focusIndex === null ? 1 : focusIndex + 1;
        const nextIndex = next > zeroBasedLength ? 0 : next;
        setFocusIndex(nextIndex);
      }

      // home focuses on the first accordion button
      if (e.key === 'Home') {
        setFocusIndex(0);
      }

      // end focuses on the last accordion button
      if (e.key === 'End') {
        setFocusIndex(zeroBasedLength);
      }

      if (e.key === ' ' || e.key === 'Spacebar') {
        e.preventDefault();
        handleAccordionChange(index);
      }
    }
  };

  const handleInitialOpen = (index: number): boolean => {
    // there are 3 conditions in which an accordion would be initially open on render:

    // 1. if we allow multiple accordions to be expanded and we start initially open
    const initialAllOpen = allowMultipleExpanded && initialOpen;

    // 2. if we have an initialActiveIndex, use that to determine whether the accordion should be open
    const isInitialActive = initialActiveIndex !== undefined && initialActiveIndex === index;
    // initialActiveIndex accordion is initially open if initialOpen is true or allowZeroExpanded is false
    const initialIndexOpen = (isInitialActive && (!allowZeroExpanded || initialOpen));

    // 3. otherwise check if the first accordion should be open as a default
    const isFirstInitialActive = !initialActiveIndex && (!allowZeroExpanded || initialOpen) && index === 0;
    // set the first accordion open if we don't allow multiple accordions to be expanded
    // OR if it's the first accordion and we allow multiple accordions to be expanded but we don't allow all of them to be collapsed
    const initialFirstOpen = (!allowMultipleExpanded && isFirstInitialActive) || (allowMultipleExpanded && !allowZeroExpanded && isFirstInitialActive);

    // NOTE: if initialAllOpen is true, initialIndexOpen and initialFirstOpen are intentionally ignored
    return initialAllOpen || initialIndexOpen || initialFirstOpen;
  };

  const handleInitialAccordionOpen = (index: number): AccordionState => ({
    isDisabled: false,
    isOpen: handleInitialOpen(index)
  });


  const handleInitialAccordions = (): void => {
    // we first initialize the accorions by setting isOpen on each accordion
    let initialAccordions = accordions.map((_, index) => handleInitialAccordionOpen(index));
    // then we set isDisabled on each accordion (IMPORTANT: we need to have initialAccordions before determining this)
    initialAccordions = [...initialAccordions.map((accordion) => handleAccordionDisabled(accordion, initialAccordions))];
    setAccordionsState(initialAccordions);
  };

  // changing any of the booleans, the initialActiveIndex, or the number of accorions will cause the state to reset
  useEffect(() => {
    handleInitialAccordions();
  }, [allowMultipleExpanded, allowZeroExpanded, initialActiveIndex, initialOpen, accordions.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // let the parent component change the accordion (if it's a valid accordion)
    if (typeof changeToIndex === 'number' && changeToIndex >= 0 && changeToIndex < length) {
      handleAccordionChange(changeToIndex);
    }
  }, [changeToIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.accordions}>
      {accordions.map((accordion, index) => (
        <Accordion
          key={`${id}-${index}`} // eslint-disable-line react/no-array-index-key
          accordion={accordion}
          animation={animation}
          disabled={accordionsState[index]?.isDisabled}
          focusIndex={focusIndex}
          id={id}
          index={index}
          initialOpen={initialOpen}
          loading={loading}
          onChange={handleAccordionChange}
          onKeyUp={handleAccordionKeyUp}
          open={accordionsState[index]?.isOpen}
          unmountOnExit={unmountOnExit}
        />
      ))}
    </div>
  );
};
