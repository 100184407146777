import React, { FunctionComponent } from 'react';

import { Checkbox, StandardCheckbox } from '@components/library/checkbox/index';
import { capitalize } from '@helpers/humanize';
import BaseCheckboxGroup from '../BaseCheckboxGroup';
import styleClassNames from './styles.module.scss';

interface StandardCheckboxGroupProps {
  checkboxes: Array<Checkbox>;
  name: string;
  onChange: (id: string, e: InputChangeEvent) => void;
  className?: string;
}

export const StandardCheckboxGroup: FunctionComponent<StandardCheckboxGroupProps> = (
  props
) => {
  const {
    className,
    name,
    onChange,
    checkboxes,
  } = props;

  // important for accessibility, this id is used with 'aria-labelledby' attribute on checkbox input
  const containerId = name;

  const checkboxElements = checkboxes.map((checkbox) => (
    <StandardCheckbox
      checked={checkbox.checked}
      className={checkbox.className}
      containerId={containerId}
      disabled={checkbox.disabled}
      id={checkbox.id}
      key={checkbox.id}
      labelCopy={checkbox.labelCopy}
      labelPosition={checkbox.labelPosition}
      name={checkbox.name}
      onChange={onChange}
      partiallyChecked={checkbox.partiallyChecked}
      size={checkbox.size}
      value={checkbox.value}
    />
  ));

  const baseProps = {
    children: checkboxElements,
    containerId,
    className,
    name,
    styleClassNames,
    variantName: 'standard',
  };

  return <BaseCheckboxGroup {...baseProps} />;
};
