/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { BFLoader } from '@components/common/loader/main';
import { getStorage, StorageTypes } from '@helpers/storage';

import ExpandSidebarButton from './expand_sidebar_button';
import SidebarCard from './sidebar_card';

import './styles/main.scss';

const AttachmentSidebar = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    activeCard,
    asset,
    attachmentReservations,
    attachments,
    displayAssetCard,
    updateActiveCard,
  } = props;

  const updateEventListeners = (action) => {
    const handleUpdateSidebar = (event) => {
      if (event && event.detail) {
        setCollapsed(event.detail.isCollapsed);
      }
    };

    if (action === 'add') {
      window.addEventListener('updateSidebarCollapsed', handleUpdateSidebar);
    }

    if (action === 'remove') {
      window.removeEventListener('updateSidebarCollapsed', handleUpdateSidebar);
    }
  };

  useEffect(() => {
    const sidebarCollapsed = getStorage(StorageTypes.Local, 'assetModalAttachmentsSidebarCollapsed') === true;
    setCollapsed(sidebarCollapsed);
    updateEventListeners('add');
    return () => updateEventListeners('remove');
  }, []);

  useEffect(() => {
    if (displayAssetCard && asset && attachments) {
      setIsLoading(false);
    } else if (attachments) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [asset, attachments]);

  return (
    <section className={`attachment-sidebar ${collapsed && 'collapsed'}`}>
      <div className="attachment-sidebar__scroll-container bf-scroll-element">
        {isLoading ? (
          <BFLoader />
        ) : (
          <>
            {displayAssetCard && activeCard && asset && (
              <>
                <span className="modal-label attachment-sidebar__label">
                  <Trans>Asset</Trans>
                </span>
                <div className="attachment-sidebar__asset">
                  <SidebarCard
                    active={asset.id === activeCard.id}
                    cardType="asset"
                    element={asset}
                    updateActiveCard={updateActiveCard}
                  />
                </div>
              </>
            )}
            {activeCard && attachments && attachments.length > 0 && (
              <>
                <span className="modal-label attachment-sidebar__label">
                  <Trans>Attachments</Trans>
                </span>
                <ul className="attachment-sidebar__attachments">
                  {attachments.map((attachment) => (
                    <SidebarCard
                      key={attachment.id}
                      active={attachment.id === activeCard.id}
                      cardType="attachment"
                      checkedInStatus={attachmentReservations?.[attachment.id]?.currentStatus}
                      element={attachment}
                      updateActiveCard={updateActiveCard}
                    />
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>
      <ExpandSidebarButton setCollapsed={setCollapsed} />
    </section>
  );
};

AttachmentSidebar.propTypes = {
  activeCard: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(['asset', 'attachment']),
  }),
  asset: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
      thumbnail_url: PropTypes.string,
    })
  }),
  attachmentReservations: PropTypes.shape({}),
  attachments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      extension: PropTypes.string,
      filename: PropTypes.string,
      thumbnail_url: PropTypes.string,
    })
  })),
  displayAssetCard: PropTypes.bool,
  updateActiveCard: PropTypes.func.isRequired,
};

AttachmentSidebar.defaultProps = {
  activeCard: null,
  asset: null,
  attachmentReservations: {},
  attachments: null,
  displayAssetCard: false,
};

export default AttachmentSidebar;
