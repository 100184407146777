import classnames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import { BaseTd, BaseTdProps } from '../../base';

import classes from '../styles/selectable-table.module.scss';

interface StandardTdProps extends BaseTdProps {
  centered?: boolean;
  ellipsis?: boolean;
  heading?: ReactNode;
}

export const Td: FunctionComponent<StandardTdProps> = (props) => {
  const { centered, children, ellipsis, heading, ...otherProps } = props;

  return (
    <BaseTd
      {...otherProps}
      className={classnames(
        'selectabeTd',
        classes.td,
        centered && classes.tdCentered,
        ellipsis && classes.tdEllipsis,
        props.className
      )}
    >
      {heading && <h6 className={classes.tdHeading}>{heading}</h6>}
      <span title={ellipsis && typeof children === 'string' ? children : undefined}>
        {children}
      </span>
    </BaseTd>
  );
};
