import { t } from '@lingui/macro';
import React, { useState, KeyboardEvent } from 'react';

import { InputType } from '@components/library/inputs/Input.props';
import Input from '@components/library/inputs/PrimaryInput';

interface SearchBarProps {
  searchUrl: string | undefined;
}

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  searchUrl,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  if (!searchUrl) { return null; }

  const submitSearchRedirect = (): void => {
    const url = new URL(searchUrl);
    const parsedSearchUrl = new URLSearchParams(url.search);
    parsedSearchUrl.set('q', searchQuery);
    const redirectUrl = `${url.origin}${url.pathname}?${parsedSearchUrl.toString()}`;
    window.location.href = redirectUrl;
  };

  const handleSearch = (e): void => {
    if (e.key === 'Enter') {
      submitSearchRedirect();
    }
  };

  return (
    <>
      <Input
        attributes={{
          className: 'portal-header__searchbar searchbar',
          name: 'search-portal',
          onChange: (e: InputChangeEvent): void => setSearchQuery(e.target.value),
          onKeyUp: (e: KeyboardEvent<HTMLInputElement>): void => handleSearch(e),
          placeholder: t`Search for assets`,
          type: InputType.Text,
        }}
        input={{ value: searchQuery }}
      />
    </>
  );
};

export default SearchBar;
