import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

const ButtonsContainer = ({ handleClose, handleSubmit, saveButtonDisabled }) => (
  <div className="button-container">
    <button
      className="button sm tertiary t-cancel-modal"
      onClick={() => handleClose()}
      type="button"
    >
      <Trans>Cancel</Trans>
    </button>
    <button
      className="button sm primary t-confirm-modal"
      disabled={saveButtonDisabled}
      onClick={handleSubmit}
      type="button"
    >
      <Trans>Save</Trans>
    </button>
  </div>
);

ButtonsContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saveButtonDisabled: PropTypes.bool.isRequired,
};

export default ButtonsContainer;
