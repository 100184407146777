import { t, Trans } from '@lingui/macro';
import { Field, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { Fields, EditOrCreate } from '@components/bulk_management/automation/AutomationEnums';
import { Action, FormState } from '@components/bulk_management/automation/AutomationTypes';

import { AutomationField } from '@components/bulk_management/automation/form/AutomationField';
import { NonInteractiveSection } from '@components/bulk_management/automation/form/NonInteractiveSection';
import {
  automationTooltips,
  emptyAction,
  getAutomationBody,
  initialFormState,
  resetActionsAndName,
  selectorTypeOptions
} from '@components/bulk_management/automation/helpers';
import { validationSchema } from '@components/bulk_management/automation/validation';
import { PrimaryButton, TextButton } from '@components/library/button';
import { FontIcon, FontIcons } from '@components/library/icon';
import { BaseTableRow, StandardTable } from '@components/library/table';
import { StandardTextField } from '@components/library/text-field';
import { MoreInfoTooltip } from '@components/library/tooltip';

import './styles/automation-form.scss';

interface AutomationFormProps {
  goHome: () => void;
  automationKey?: string;
  editOrCreate?: EditOrCreate;
  initialValues?: FormState;
}

interface SubmissionDetails {
  loadingCopy: string;
  method: 'POST' | 'PUT';
  resetCopy: string;
  submissionButtonCopy: string;
  successMessage: string;
  url: string;
}

export const AutomationForm: FunctionComponent<AutomationFormProps> = ({
  automationKey = '',
  editOrCreate = EditOrCreate.Create,
  initialValues = null,
  goHome
}) => {
  const [isTriggerSet, setIsTriggerSet] = useState(editOrCreate !== EditOrCreate.Create);
  const [submissionValues, setSubmissionValues] = useState<FormState | undefined>(undefined);
  const submissionDetails: Record<EditOrCreate, SubmissionDetails> = {
    [EditOrCreate.Copy]: {
      loadingCopy: t`Creating...`,
      method: 'POST',
      resetCopy: t`Reset`,
      submissionButtonCopy: t`Create Asset Automation`,
      successMessage: t`Successfully created your asset automation.`,
      url: `/api/v4/private/brandfolders/${BFG.resource.key}/automations`
    },
    [EditOrCreate.Create]: {
      loadingCopy: t`Creating...`,
      method: 'POST',
      resetCopy: t`Clear`,
      submissionButtonCopy: t`Create Asset Automation`,
      successMessage: t`Successfully created your asset automation.`,
      url: `/api/v4/private/brandfolders/${BFG.resource.key}/automations`
    },
    [EditOrCreate.Edit]: {
      loadingCopy: t`Updating...`,
      method: 'PUT',
      resetCopy: t`Reset`,
      submissionButtonCopy: t`Update Asset Automation`,
      successMessage: t`Successfully updated your asset automation.`,
      url: `/api/v4/private/automations/${automationKey}`
    }
  };
  const { loadingCopy, method, resetCopy, submissionButtonCopy, successMessage, url } = submissionDetails[editOrCreate];

  const automationSubmissionFetch = useFetch({
    url,
    method,
    fetchOnMount: false,
    body: {
      data: {
        attributes: getAutomationBody(submissionValues)
      }
    }
  });

  useEffect(() => {
    if (submissionValues) {
      automationSubmissionFetch.fetch();
    }
  }, [submissionValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (automationSubmissionFetch.response) {
      Notify.create({ title: successMessage, type: 'success' });
      goHome();
    }
  }, [automationSubmissionFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (automationSubmissionFetch.error?.message) {
      Notify.create({ title: automationSubmissionFetch.error.message, type: 'error' });
    } else if (automationSubmissionFetch.error) {
      Notify.create({ title: t`Oops! Something went wrong.`, type: 'error' });
    }
  }, [automationSubmissionFetch.error]);

  const handleSubmit = (values: FormState): void => {
    setSubmissionValues(values);
  };

  return (
    <div className="automation-form">
      <Formik
        initialValues={initialValues ?? { ...initialFormState(emptyAction()) }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formikBag): JSX.Element => {
          const {
            errors,
            setFieldValue,
            setFieldTouched,
            setTouched,
            setValues,
            touched,
            validateForm,
            values
          } = formikBag;

          const { actions, triggerKeys, triggerType, values: triggerValues } = values;

          const generateActionRows = (push, remove): BaseTableRow[] => {
            const actionRows: BaseTableRow[] = actions.map((action: Action, i) => ({
              key: action.actionableId,
              action: (
                <AutomationField
                  key={action.actionableId}
                  actionIndex={i}
                  actions={actions}
                  editOrCreate={editOrCreate}
                  errors={errors}
                  isOneAction={actions.length === 1}
                  isSectionDisabled={!isTriggerSet}
                  remove={remove}
                  selectorValues={{ ...action, triggerType }}
                  setFieldTouched={(field, isTouched, shouldValidate): void => {
                    setFieldTouched(field, isTouched, shouldValidate);
                  }}
                  setFieldValue={(field, value, shouldValidate): void => {
                    setFieldValue(field, value, shouldValidate);
                  }}
                  setTouched={(touch, shouldValidate): void => { setTouched(touch, shouldValidate); }}
                  touched={touched}
                  validateForm={validateForm}
                />
              )
            }));

            const remainingOptions = selectorTypeOptions('action', triggerType, actions);
            if ((remainingOptions && remainingOptions.length > 0) || !triggerType) {
              // TODO: remove !triggerType and update AutomationForm tests
              // to set trigger type before testing anything with adding
              // actions - or be content with capy coverage if too many renders
              actionRows.push({
                action: (
                  <TextButton
                    className="form-buttons__add-action"
                    icon={FontIcons.Plus}
                    onClick={(): void => { push(emptyAction()); }}
                  >
                    <Trans>Add Action</Trans>
                  </TextButton>
                )
              });
            }

            return actionRows;
          };

          return (
            <Form id="automation-form">
              <Field name={Fields.Name}>
                {({ field }): JSX.Element => (
                  <StandardTextField
                    {...field}
                    aria-required
                    className={errors.name && touched.name ? 'automation-name-field field-error' : 'automation-name-field'}
                    error={errors.name && touched.name ? errors.name : undefined}
                    id="name"
                    label={t`Name`}
                    name={Fields.Name}
                    placeholder={t`Asset Automation name`}
                    required
                    tooltip={(
                      <MoreInfoTooltip
                        iconSize={16}
                        id="automation-form-name"
                        tooltip={automationTooltips().name}
                      />
                    )}
                  />
                )}
              </Field>
              <StandardTable
                caption={t`create and edit trigger table`}
                columns={[{
                  heading: (
                    <>
                      <span aria-hidden className="header-number" />
                      <Trans>Trigger</Trans>
                      <span className="header-subtitle">
                        <Trans>When this happens...</Trans>
                      </span>
                    </>
                  ),
                  rowKey: 'trigger',
                }]}
                id="automation-form-trigger-table"
                rows={[{
                  trigger: (
                    <AutomationField
                      editOrCreate={editOrCreate}
                      errors={errors}
                      isTriggerSet={isTriggerSet}
                      selectorValues={{ triggerKeys, triggerType, values: triggerValues }}
                      setFieldTouched={(field, isTouched, shouldValidate): void => {
                        setFieldTouched(field, isTouched, shouldValidate);
                      }}
                      setFieldValue={(field, value, shouldValidate): void => {
                        setFieldValue(field, value, shouldValidate);
                      }}
                      setIsTriggerSet={setIsTriggerSet}
                      setTouched={(touch, shouldValidate): void => { setTouched(touch, shouldValidate); }}
                      touched={touched}
                      validateForm={validateForm}
                    />
                  )
                }]}
                scrollX={false}
              />
              <NonInteractiveSection isInteractive={isTriggerSet}>
                <>
                  <FontIcon aria-hidden icon={FontIcons.ArrowDown} />
                  <FieldArray name={Fields.Actions}>
                    {({ push, remove }): JSX.Element => (
                      <StandardTable
                        caption={t`create and edit actions table`}
                        columns={[{
                          heading: (
                            <>
                              <span aria-hidden className="header-number" />
                              <Trans>Actions</Trans>
                              <span className="header-subtitle">
                                <Trans>Automatically do this...</Trans>
                              </span>
                            </>
                          ),
                          rowKey: 'action',
                        }]}
                        id="automation-form-action-table"
                        rows={generateActionRows(push, remove)}
                        scrollX={false}
                      />
                    )}
                  </FieldArray>
                </>
              </NonInteractiveSection>
              <div className="form-buttons">
                <TextButton
                  className="form-buttons__reset"
                  icon={FontIcons.Refresh}
                  onClick={(): void => {
                    if (editOrCreate === EditOrCreate.Create) {
                      setValues({ ...initialFormState(emptyAction()) });
                    } else {
                      setValues({ ...initialValues ?? resetActionsAndName(values, emptyAction()) });
                    }
                    setTouched({});
                    setIsTriggerSet(editOrCreate !== EditOrCreate.Create);
                  }}
                >
                  {resetCopy}
                </TextButton>
                <PrimaryButton
                  className="form-buttons__create"
                  isLoading={automationSubmissionFetch.loading}
                  loadingCopy={loadingCopy}
                  size="medium"
                  type="submit"
                >
                  {submissionButtonCopy}
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
