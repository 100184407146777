/**
 * Localizes a number into a number with a percent symbol.
 * Some locales place the number in front of the number,
 * after the number,
 * or after the number with a space in between.
 * @param locale string
 * @param value number
 * @returns string
 */
export const localizePercentage = (locale: string, value: number): string => {
  const numberFormat = Intl.NumberFormat(locale, {
    style: "percent",
    maximumFractionDigits: 2
  });
  return numberFormat.format(value / 100);
};
