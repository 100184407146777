import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import { DialogAnimations, DialogSizes, DialogTypes, DrawerAnimations } from '@components/library/dialog';

import classes from '../styles/dialog_type.module.scss';

interface DialogTypeProps extends HTMLAttributes<HTMLDivElement> {
    /** defaults to fade-in-from-top for modal dialogsand fade-in-from-right for drawer dialogs */
    animation: DialogAnimations | DrawerAnimations;
    /** required for accessibility */
    "aria-labelledby": string;
    /** children is required */
    children: ReactNode;
    /** sets the users desired default height inline (will be overridden by responsive styles at smaller sizes) */
    height?: number;
    /** required for accessibility */
    id: string;
    /** defaults to auto (auto will be as a small as the containing content) */
    size: DialogSizes;
    /** modal or drawer */
    type: DialogTypes;
    /** sets the users desired default width inline (will be overridden by responsive styles at smaller sizes) */
    width?: number;
}

/**
 * Dialog type displays dialog as either modal or drawer, and which is the heart of the dialog component
 * @param {DialogTypeProps} props DialogTypeProps
 */
export const DialogType: FunctionComponent<DialogTypeProps> = (props) => {
  const { animation, children, height, id, size, type, width, ...divProps } = props;

  // position is used for drawer dialogs
  // fade-in-from-left => left
  // fade-in-from-right => right
  const position = animation.split("-").pop();

  return (
    <div
      {...divProps}
      aria-labelledby={divProps["aria-labelledby"]}
      aria-modal
      className={classnames(
        "dialog--type",
        classes[`dialog--${type}`],
        classes[`dialog--${type}__${animation}`],
        classes[`dialog--${type}__${position}`],
        classes[`dialog--${type}__${size}`],
        divProps.className
      )}
      id={id}
      role="dialog"
      style={{
        height: height !== undefined && height > 0 ? height : undefined,
        width: width !== undefined && width > 0 ? width : undefined
      }}
    >
      {children}
    </div>
  );
};
