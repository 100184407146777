import fetchJSON from '@api/api_helper';
import { BackendPortalCard, PortalCard, PortalCardResponse, PortalCardsResponse } from '@api/v4/private/PortalCardTypes';
import { ApiDataResponse } from '@api/v4/ApiResponseTypes';
import { Asset } from '@api/v4/assets/assetTypes';

const portalCardsForPortal = (portalId: string): string => `/api/v4/private/portals/${portalId}/portal_cards`;
const portalCardUrl = (portalCardId: string): string => `/api/v4/private/portal_cards/${portalCardId}`;

// NOTE: implement updateFetchControllers
export const getPortalCards = (options): Promise<PortalCardsResponse> => (
  fetchJSON(portalCardsForPortal(options.portalId), options, (): void => { /* no op */ })
);

// NOTE: implement updateFetchControllers
export const getPortalCard = (options): Promise<PortalCardResponse> => (
  fetchJSON(portalCardUrl(options.portalCardId), options, (): void => { /* no op */ })
);

// starting with assets, this may need to evolve
// NOTE: implement updateFetchControllers
export const getPortalCardData = (options): Promise<ApiDataResponse<Asset>> => (
  fetchJSON(`${portalCardUrl(options.portalCardId)}/data`, options, (): void => { /* no op */ })
);

export const mapPortalCardToBackendPortalCard = (card: PortalCard): BackendPortalCard => ({
  button_text: card.buttonText || null,
  description: card.description?.trim(),
  display_width: card.displayWidth,
  icon_url: card.iconUrl,
  image_url: card.imageUrl,
  link_url: card.linkUrl,
  name: card.name.trim(),
  position: card.position,
  sub_type: card.subType,
  src_url: card.srcUrl || null,
  type: card.type
});

export const mapBackendPortalCardToPortalCard = (backendCard: BackendPortalCard): PortalCard => ({
  buttonText: backendCard.button_text || null,
  description: backendCard.description,
  displayWidth: backendCard.display_width,
  iconUrl: backendCard.icon_url,
  imageUrl: backendCard.image_url,
  linkUrl: backendCard.link_url,
  name: backendCard.name,
  position: backendCard.position,
  subType: backendCard.sub_type,
  srcUrl: backendCard.src_url || null,
  type: backendCard.type
});

export const createPortalCard = (portalId: string, card: PortalCard): Promise<PortalCardResponse> => {
  const backendCard = mapPortalCardToBackendPortalCard(card);
  const appendedOptions = {
    body: {
      data: {
        attributes: backendCard
      },
      resource_type: card.widgetResource?.type || null,
      resource_key: card.widgetResource?.key || null,
    },
    method: 'POST'
  };

  // NOTE: implement updateFetchControllers
  return fetchJSON(portalCardsForPortal(portalId), appendedOptions, () => { /* no op */ })
    .then((response): PortalCardResponse => response);
};

export const updatePortalCard = (portalCardId: string, card: PortalCard): Promise<PortalCardResponse> => {
  const backendCard = mapPortalCardToBackendPortalCard(card);
  const appendedOptions = {
    body: {
      data: {
        attributes: backendCard
      },
      resource_type: card.widgetResource?.type || null,
      resource_key: card.widgetResource?.key || null,
    },
    method: 'PUT'
  };

  // NOTE: implement updateFetchControllers
  return fetchJSON(portalCardUrl(portalCardId), appendedOptions, () => { /* no op */ })
    .then((response): PortalCardResponse => response);
};

export const deletePortalCard = (portalCardId: string): Promise<{}> => {
  const appendedOptions = {
    method: 'DELETE'
  };

  // NOTE: implement updateFetchControllers
  return fetchJSON(portalCardUrl(portalCardId), appendedOptions, () => { /* no op */ })
    .then((response) => response);
};
