import React, { FunctionComponent } from 'react';

import { CreateNewAssetTypes, CreateProjectTypes } from '@components/show_page/sections/asset/custom-templates';
import { CreateProject } from '@components/show_page/sections/asset/custom-templates/CreateProject';

interface CreatePrintDesignProjectProps {
  sectionKey: string;
}

export const CreatePrintDesignProject: FunctionComponent<CreatePrintDesignProjectProps> = ({ sectionKey }) => (
  <CreateProject
    assetType={CreateNewAssetTypes.PrintDesignProject}
    exclude={[CreateProjectTypes.UploadAnImage]}
    sectionKey={sectionKey}
  />
);
