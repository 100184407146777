import {
  ButtonLooks,
  FontIcons,
  IconButton,
  Placements,
  StandardButton,
  StandardCheckbox,
  StandardDialog,
  StandardPopout,
} from '@brandfolder/react';
import { Trans, t } from '@lingui/macro';
import React, { FunctionComponent, ReactNode, useState } from 'react';

interface DependentCustomFieldsRowCellProps {
  checkedFields: string[];
  deleteDependentFields: (deleteFieldIds: string[]) => void;
  dependentCustomFields: { id: string; name: string }[];
  setCheckedFields: SetStateDispatch<string[]>;
}

export const DependencyViewAccordionDcfRowCell: FunctionComponent<DependentCustomFieldsRowCellProps> =
  ({
    dependentCustomFields,
    deleteDependentFields,
    checkedFields,
    setCheckedFields,
  }) => {
    const [showMore, setShowMore] = useState(false);
    const [rowControlsOpen, setRowControlsOpen] = useState(false);
    const [showRemoveDependenciesDialog, setShowRemoveDependenciesDialog] =
      useState(false);

    const dcfCount = dependentCustomFields?.length;
    const visibleFields =
      dcfCount > 4 && !showMore
        ? dependentCustomFields.slice(0, 4)
        : dependentCustomFields;

    const renderDialog = (): ReactNode => (
      <StandardDialog
        className="remove-dependencies-dialog"
        footer={
          <div className="remove-dependencies-dialog-footer">
            <StandardButton
              look={ButtonLooks.Secondary}
              onClick={(): void => {
                setCheckedFields([]);
                setShowRemoveDependenciesDialog(false);
              }}
            >
              <Trans>Cancel</Trans>
            </StandardButton>
            <StandardButton
              disabled={!checkedFields?.length}
              onClick={(): void => {
                deleteDependentFields(checkedFields);
                setShowRemoveDependenciesDialog(false);
              }}
            >
              <Trans>Save</Trans>
            </StandardButton>
          </div>
        }
        header={
          <h4 className="remove-dependencies-dialog-header">
            <Trans>Remove dependencies</Trans>
          </h4>
        }
        id="remove-dependencies-dialog"
        labels={{ closeButtonLabel: t`Close` }}
        open={showRemoveDependenciesDialog}
        setOpen={(): void => {
          setCheckedFields([]);
          setShowRemoveDependenciesDialog(false);
        }}
        showTitle={false}
        title={t`Remove dependencies`}
        width={600}
      >
        <p className="remove-dependencies-dialog-intro">
          <Trans>Select the custom fields you want to remove as dependencies.</Trans>
        </p>
        <div className="remove-dependencies-dialog-checkboxes">
          {dependentCustomFields.map((field) => (
            <StandardCheckbox
              key={field.id}
              className="checkbox"
              id={field.id}
              label={field.name}
              onChange={(e): void => {
                if (e.currentTarget.checked) {
                  setCheckedFields([...checkedFields, e.currentTarget.id]);
                } else {
                  setCheckedFields(
                    checkedFields?.filter((c) => c !== field.id)
                  );
                }
              }}
              onMouseDown={(e): void => e.preventDefault()}
            />
          ))}
        </div>
      </StandardDialog>
    );

    return (
      <>
        {renderDialog()}
        <div className="dcf-values-wrapper">
          {visibleFields?.map((dcf) => (
            <span key={dcf.id} className="dcf-accordion-panel-rowCell">
              {dcf.name}{' '}
            </span>
          ))}
        </div>
        <div className="dcf-row-controls">
          {dcfCount > 4 && (
            <StandardButton
              className="show-more"
              look={ButtonLooks.Default}
              onClick={(): void => setShowMore(!showMore)}
            >
              {showMore ? t`Show less` : t`Show more`}
            </StandardButton>
          )}
          <StandardPopout
            className="dcf-row-controls-popout"
            closeOnClickAway
            content={
              <StandardButton
                className="remove-dependencies"
                look={ButtonLooks.Default}
                onClick={(): void => setShowRemoveDependenciesDialog(true)}
              >
                <Trans>Remove dependencies</Trans>
              </StandardButton>
            }
            id="row-controls"
            open={rowControlsOpen}
            placement={Placements.BottomEnd}
            setOpen={(): void => setRowControlsOpen(false)}
            showArrow={false}
            showCloseButton={false}
            width={185}
          >
            <IconButton
              className="open-row-controls"
              icon={FontIcons.Ellipsis}
              label={t`row controls`}
              look={ButtonLooks.Default}
              onClick={(): void => setRowControlsOpen(!rowControlsOpen)}
              showIconButton
              type="button"
            />
          </StandardPopout>
        </div>
      </>
    );
  };
