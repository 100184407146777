import React, { FunctionComponent, useState } from 'react';

import { PrimaryButton } from '@components/library/button';
import { DialogSizes, IFrameDialog } from '@components/library/dialog';

import './styles/embedded_widgets_card.scss';

interface EmbeddedWidgetsCardProps {
  buttonText: string | null | undefined;
  description: string | null | undefined;
  name: string;
  srcUrl: string | null | undefined;
  className?: string; // tiles__item--description tiles__item--embedded
}

export const EmbeddedWidgetsCard: FunctionComponent<EmbeddedWidgetsCardProps> = (props) => {
  const { buttonText, description, name, srcUrl } = props;

  const [showModal, setShowModal] = useState(false);

  if (!buttonText || !srcUrl) return null;

  return (
    <>
      <div className="embedded-widgets-card">
        <div className="embedded-widgets-card__details">
          <h3>{name}</h3>
          {!!description && <p>{description}</p>}
        </div>
        <div className="embedded-widgets-card__button">
          <PrimaryButton onClick={(): void => setShowModal((prev) => !prev)}>{buttonText}</PrimaryButton>
        </div>
      </div>
      <IFrameDialog
        id="embedded-widgets-iframe-dialog"
        onClose={(): void => setShowModal(false)}
        open={showModal}
        setOpen={setShowModal}
        size={DialogSizes.Unlimited}
        src={srcUrl}
        title={name}
        titleIcon="bff-form"
      />
    </>
  );
};
