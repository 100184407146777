import { Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React, { useState } from "react";

import { downloadAttachment as downloadOriginal } from '@api/downloading/download_attachment';
import { downloadPoller as downloadConversion } from '@api/downloading/download_poller';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import SelectListDropdown from '@components/common/select_list_dropdown/main';

import './styles/main.scss';

function SimpleVideoEditor(props) {
  const availableFormats = ['mp4', 'webm', 'ogv', 'mov', 'aac', 'wmv', '3gp',
    'flv', 'm4f', 'mj2', 'mkv', 'mxf', 'ogg', 'ts'];
  const availableHeights = Array.from(new Set([props.attachment.height, 1080, 720, 480].filter(
    (height) => height <= props.attachment.height
  )));

  const [extension, setExtension] = useState(props.attachment.extension);
  const [height, setHeight] = useState(props.attachment.height);
  const [downloading, disableDownload] = useState(false);

  const originalVideo = (
    height === props.attachment.height && extension === props.attachment.extension
  );

  const downloadVideo = () => {
    if (originalVideo) {
      return downloadOriginal(props.attachment.key, props.attachment.filename);
    }
    const pollerUrl = `/${BF.fx.brandfolder().slug}/attachments/${props.attachment.key}/poller`;
    const params = {
      dl: true,
      extension,
      height,
      width: height === props.attachment.height ? props.attachment.width : undefined,
      ...BFG.manifestDigest && { manifest_digest: BFG.manifestDigest },
    };
    return downloadConversion(pollerUrl, { params }, 500);
  };

  const downloadCallback = () => {
    disableDownload(true);
    downloadVideo();
    Insight.createEvent("downloaded", props.assetKey, "asset");
    setTimeout(() => {
      disableDownload(false);
    }, 3000);
  };

  const handleDownload = () => {
    if (BFG.downloadAlertEnabled) {
      BF.fx.dispatchWindowEvent('launchDownloadAlert', null, {
        assetKey: undefined,
        attachmentKey: props.attachment.key,
        downloadCallback
      });
    } else {
      downloadCallback();
    }
  };

  const openAdvancedModal = () => {
    const url = `${BF.fx.brandfolder().slug}/attachments/display/${props.attachment.key}`;
    BF.dialog.render(encodeURIComponent(props.attachment.filename), url);
  };

  return (
    <I18nProviderWrapper>
      <div className="advanced-video-downloading">
        <div className="non-watermarking-container">
          <div>
            <SelectListDropdown
              defaultValue={props.attachment.extension}
              dropdownLabel="File Type"
              list={availableFormats}
              onSelect={setExtension}
              scroll={false}
            />
            <SelectListDropdown
              defaultValue={props.attachment.height}
              dropdownLabel="Resolution"
              list={availableHeights}
              onSelect={(selectedHeight) => setHeight(parseInt(selectedHeight, 10))}
              scroll={false}
            />
          </div>
        </div>
        <div className="s-download-attachment full advanced-video-buttons-container">
          <button
            className="button primary"
            disabled={downloading}
            onClick={handleDownload}
            type="button"
          >
            <Trans>Download</Trans>
          </button>
          <button
            className="button tertiary advanced-options"
            onClick={() => openAdvancedModal()}
            type="button"
          >
            <Trans>Advanced Editor</Trans>
          </button>
        </div>
      </div>
    </I18nProviderWrapper>
  );
}

SimpleVideoEditor.propTypes = {
  attachment: PropTypes.shape({
    duration: PropTypes.number,
    extension: PropTypes.string,
    filename: PropTypes.string,
    height: PropTypes.number,
    key: PropTypes.string,
    width: PropTypes.number,
  }).isRequired,
  assetKey: PropTypes.string.isRequired
};

export default SimpleVideoEditor;
