import getJson from './api_helper';

const get = ({ resourceType, resourceKey, ugtLocale }) => {
  let counter = 0;
  const url = `/api/v4/${resourceType}s/${resourceKey}/searchable_things`;
  const getStuff = () => {
    if (counter > 30) { return Promise.reject(); }
    return getJson(url, {
      params: { ugt_locale: ugtLocale },
    }, undefined, true);
  };

  const handler = ({ response, json }) => (
    new Promise((resolve) => {
      counter += 1;
      if (response.status === 202) {
        setTimeout(() => {
          resolve(getStuff().then(handler));
        }, 3000 * counter);
      } else {
        resolve(json);
      }
    })
  );

  return getStuff().then(handler);
};

export default get;
