import React, { FunctionComponent, useContext, useState } from 'react';
import { Gallery } from 'react-grid-gallery';

import { AssetTypes } from '@api/v4/assets/assetTypes';
import { AttachmentResponseObject } from '@api/v4/attachments/attachmentTypes';

import BulkSelectBar from '@components/show_page/bulk_actions/BulkSelectBar';

import { ExpandedView } from './ExpandedView';
import { ImageComponent } from './GalleryCard';
import { ViewOnlyContext } from './GalleryView';
import { isMimeTypeImgCompatible } from './helpers';
import { AssetViewModel } from './model';

import './styles/attachment-gallery.module.scss';
import './styles/attachment-gallery.scss';

export interface AttachmentGalleryProps {
  assets: AssetViewModel[];
}

export interface GalleryCard {
  src: string;
  width: number;
  height: number;
  alt: string;
  item: {
    asset: AssetViewModel;
    attachmentId?: string;
    handleExpandedViewSelection: (
      asset: AssetViewModel,
      attachmentId?: string | null
    ) => void;
    handleSelection: (id: string) => void;
    isSelected: boolean;
  };
}

export const AttachmentGallery: FunctionComponent<AttachmentGalleryProps> = ({
  assets,
}) => {
  const [selectedAssetIds, setSelectedAssetIds] = useState([]);
  const [expandedViewSelection, setExpandedViewSelection] = useState<{
    asset: AssetViewModel;
    attachmentId: string | null;
  }>(null);

  const handleSelectAttachment = (id: string): void => {
    if (selectedAssetIds.includes(id)) {
      setSelectedAssetIds(
        selectedAssetIds.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedAssetIds([...selectedAssetIds, id]);
    }
  };

  const handleExpandedViewSelection = (
    asset: AssetViewModel,
    attachmentId?: string | null
  ): void => {
    const body = document.querySelector('body');
    body.style.overflow = 'hidden';
    setExpandedViewSelection({
      asset: asset,
      attachmentId: attachmentId || null,
    });
  };

  const closeExpandedView = (): void => {
    setExpandedViewSelection(null);
  };

  const clearSelected = (): void => {
    setSelectedAssetIds([]);
  };

  const toggleSelected = (assetKeys: string[]): void => {
    if (Array.isArray(assetKeys)) {
      clearSelected();
    }
  };

  const selectAll = (): void => {
    setSelectedAssetIds([...assets.map((asset) => asset.id)]);
  };

  const selectedViewOnlyAssetKeys = new Set(
    assets
      .filter((asset) => asset?.attributes?.view_only)
      .map((asset) => asset?.id)
  );

  const buildGalleryAttachmentImage = (
    asset: AssetViewModel,
    attachment: AttachmentResponseObject,
    updatedFields?: Partial<GalleryCard>
  ): GalleryCard => {
    const { attributes } = attachment;
    const galleryAttachmentImage = {
      src: '',
      width: attributes.width || 282,
      height: attributes.height || 282,
      alt: attributes.filename,
      item: {
        asset: asset,
        attachmentId: attachment.id,
        handleSelection: handleSelectAttachment,
        handleExpandedViewSelection: handleExpandedViewSelection,
        isSelected: selectedAssetIds.includes(asset.id),
      },
    };
    return { ...galleryAttachmentImage, ...updatedFields };
  };

  const buildGalleryImage = (
    asset: AssetViewModel,
    updatedFields?: Partial<GalleryCard>
  ): GalleryCard => {
    const galleryImage = {
      src: '',
      width: 282,
      height: 282,
      alt: '',
      item: {
        asset: asset,
        handleSelection: handleSelectAttachment,
        handleExpandedViewSelection: handleExpandedViewSelection,
        isSelected: selectedAssetIds.includes(asset.id),
      },
    };
    return { ...galleryImage, ...updatedFields };
  };

  const downloadsDisabled = useContext(ViewOnlyContext);

  const images = assets.flatMap((asset): GalleryCard[] | GalleryCard => {
    if (asset.attachments.length) {
      return asset.attachments.flatMap((attachment) => {
        const {
          cdn_url: cdnUrl,
          height,
          mimetype,
          thumbnail_url: thumbnailUrl,
          url,
          view_thumbnail_retina: viewThumbnailRetina,
          width,
        } = attachment.attributes;
        return buildGalleryAttachmentImage(asset, attachment, {
          src:
            isMimeTypeImgCompatible(mimetype) && !downloadsDisabled
              ? cdnUrl || url
              : viewThumbnailRetina || thumbnailUrl,
          height: height || 282,
          width: width || 282,
        });
      });
    } else {
      const {
        thumbnail_override: thumbnailOverride,
        thumbnail_url: thumbnailUrl,
        name,
      } = asset.attributes;
      switch (asset.type) {
        case AssetTypes.Font:
          return buildGalleryImage(asset);
        case AssetTypes.ExternalMedium:
          return buildGalleryImage(asset, {
            src: thumbnailOverride?.url || thumbnailUrl,
            alt: name,
          });
        case AssetTypes.GenericFile:
          return buildGalleryImage(asset, {
            src: thumbnailUrl || '',
            alt: name,
          });
        default:
          return buildGalleryImage(asset, {
            src: 'https://cdn.bfldr.com/27C9EC93/at/q7v8py-ft5thk-e0hkgw/background-transparent-square.png?auto=webp',
            alt: 'Incompatible file with gallery view',
          });
      }
    }
  });

  return (
    <>
      <Gallery
        defaultContainerWidth={1} // This is a hack to make automated tests run properly
        enableImageSelection={false}
        id="gallery-view"
        images={images.map(({ src, width, height, alt, item }) => ({
          src,
          width,
          height,
          alt,
          item,
        }))}
        margin={12}
        rowHeight={580}
        thumbnailImageComponent={ImageComponent}
      />
      {expandedViewSelection && (
        <ExpandedView
          asset={expandedViewSelection.asset}
          attachmentId={expandedViewSelection.attachmentId}
          closeExpandedView={closeExpandedView}
        />
      )}
      {selectedAssetIds && (
        <BulkSelectBar
          addRemoveSelected={toggleSelected}
          allVisibleAssetKeys={assets.map((asset) => asset.id)}
          approver={false}
          clearSelected={clearSelected}
          displayDownload={true}
          editable={false}
          selectAllVisible={selectAll}
          selectedAssetKeys={new Set(selectedAssetIds)}
          selectedViewOnlyAssetKeys={selectedViewOnlyAssetKeys}
          shareLinksGalleryView
        />
      )}
    </>
  );
};
