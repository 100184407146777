import React from "react";
import PropTypes from "prop-types";

class BoulderIFrame extends React.Component {
  componentDidMount() {
    window.addEventListener("message", this.receiveMessage);
    this.ifr.onload = () => {
      document.getElementById('receiver').contentWindow.postMessage('hello',
        `${window.location.protocol}//printui.embed.${window.location.host}`);
    };
  }

  shouldComponentUpdate() { return false; }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage);
  }

  receiveMessage = (e) => {
    // stay compliant with the standard interface we're using here:
    // https://github.com/brandfolder/chrome-extension-2/blob/ad66e9981ac70a5a9aab866beb42e9ca5803b72e/server/src/public/scripts/gmail.js
    const { printuiFrameId, type, key, url, name, description, text, crop } = e.data;
    const { frame, updateImageSelector, updateTextSelector } = this.props;
    if (printuiFrameId !== frame.id) { return; }

    const typemap = { attach: 'download', embed: 'regular' };
    if (Object.keys(typemap).indexOf(type) > 0) {
      if (text) {
        // Text Asset
        updateTextSelector({ renderIFrame: false, description, key });
      } else {
        // Image Asset
        updateImageSelector({ renderIFrame: false, url, key, filename: name, crop });
      }
    }
  }

  boulderEmbedUrl = () => {
    /* eslint-disable no-undef */
    let path = `/organizations/${this.props.orgSlug}/embedded_search?embed=printui&printui_frame=${this.props.frame.id}`;
    try {
      const searchQuery = this.props.printuiInput.options.custom_settings.validations.embed_query_restriction;
      if (searchQuery && searchQuery.length > 0) {
        path += `&q=${searchQuery}&restricted=true`;
      }
      return path;
    } catch (error) {
      return path;
    }
    /* eslint-enable no-undef */
  }

  closeIframe = () => {
    const { updateImageSelector, updateTextSelector } = this.props;
    if (updateTextSelector) {
      updateTextSelector({ renderIFrame: false });
    } else {
      updateImageSelector({ renderIFrame: false });
    }
  }

  render() {
    return (
      <div className="embed-container full-width">
        <a
          className="close-embed bff-close"
          onClick={this.closeIframe}
        />
        <iframe
          src={this.boulderEmbedUrl()}
          width="100%"
          height="100%"
          display="initial"
          className="iframe-container"
          id="receiver"
          ref={(f) => { this.ifr = f; }}
          title={`boulder-iframe-${this.props.frame.id}`}
        />
      </div>
    );
  }
}

BoulderIFrame.propTypes = {
  frame: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
  }).isRequired,
  updateImageSelector: PropTypes.func,
  updateTextSelector: PropTypes.func,
  orgSlug: PropTypes.string.isRequired,
  printuiInput: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    field_identifier: PropTypes.string,
    options: PropTypes.shape({
      custom_settings: PropTypes.shape({
        type: PropTypes.string,
        validations: PropTypes.shape({
          embed_query_restriction: PropTypes.string
        })
      })
    })
  }),
};

BoulderIFrame.defaultProps = {
  printuiInput: null,
  updateImageSelector: null,
  updateTextSelector: null
};

export default BoulderIFrame;
