import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { getUsersPermissibleResources, UsersPermissibleResources } from '@api/v3/users';
import { BFLoader } from '@components/common/loader/main';
import { StandardAccordion } from '@components/library/accordion';
import { TextButton } from '@components/library/button';
import { Checkbox, StandardCheckbox, sortCheckboxes } from '@components/library/checkbox';
import { isGettyClient } from '@helpers/getty-strings';

import { PressReleaseMessage } from './PressReleaseMessage';
import { PressReleasesSearch } from './PressReleaseSearch';
import { PressReleaseToggleAll } from './PressReleaseToggleAll';
import { PressReleaseUpsert } from './PressReleaseTypes';

import classes from './styles/press_release_collections.module.scss';

interface PressReleaseCollectionsProps {
  pressRelease: PressReleaseUpsert;
  setPressRelease: SetStateDispatch<PressReleaseUpsert>;
}

const defaultErrorMessage = (): string => t`Error loading recipients. Please try again.`;

interface CollectionsCheckbox extends Checkbox {
  isBrandfolder?: boolean;
  subCheckboxes?: CollectionsCheckbox[];
}

export const PressReleaseCollections: FunctionComponent<PressReleaseCollectionsProps> = (props) => {
  const isCollection = BFG.resource.type === 'collection';

  const { pressRelease, setPressRelease } = props;

  const [checkboxes, setCheckboxes] = useState<CollectionsCheckbox[]>([]);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [resources, setResources] = useState<UsersPermissibleResources | undefined>(undefined);
  const [search, setSearch] = useState('');
  const [searchedCheckboxes, setSearchedCheckboxes] = useState<CollectionsCheckbox[]>([]);

  const fetchPermissibleResources = async (): Promise<void> => {
    try {
      const result = await getUsersPermissibleResources({
        organizationKey: BFG.organization_key,
        userKey: BFG.currentUser.user_id
      });
      // filter out any collections that are is_workspace
      // as we don't want to show them on the press releases/email users
      const collections = result.data.collections.filter((collection) => !collection.is_workspace);
      setResources({
        ...result.data,
        collections
      });
    } catch (err) {
      setError(new Error(defaultErrorMessage()));
      setLoading(false);
    }
  };

  // 1. on component mount, fetch permissible resources
  useEffect(() => {
    fetchPermissibleResources();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatResources = (): void => {
    if (resources) {
      const brandfolderKey = BFG.brandfolder_key;
      let collectionCheckboxes: CollectionsCheckbox[] = [];

      const bf = resources.brandfolders.find((brandfolder) => brandfolder.key === brandfolderKey);
      // only need to run this if wer're on a brandfolder (not collection)
      if (bf && !isCollection) {
        const hasBrandfolderKey = pressRelease.brandfolder_user_sources?.find((source) => source === bf.key);
        collectionCheckboxes = [{
          checked: !!hasBrandfolderKey,
          id: bf.key,
          isBrandfolder: true,
          labelCopy: bf.name
        }];
      }

      // we only want collections/subcollections for the brandfolder the user is on or the collection they're on
      const allCollections = resources.collections.filter((collection) => (!collection.parent_collection_key
        && (isCollection ? collection.key === BFG.resource.key : collection.parent_key === brandfolderKey)));
      const allSubcollections = resources.collections.filter((collection) => (collection.parent_collection_key
        && (isCollection ? collection.parent_collection_key === BFG.resource.key : collection.parent_key === brandfolderKey)));

      collectionCheckboxes = [...collectionCheckboxes, ...sortCheckboxes<CollectionsCheckbox>(allCollections.map((collection) => {
        let subcollectionCheckboxes: CollectionsCheckbox[] = [];
        // check if this collection has any subcollections
        const subcollections = allSubcollections.filter((subcollection) => subcollection.parent_collection_key === collection.key);
        const hasSubcollections = subcollections.length > 0;

        if (hasSubcollections) {
          // if it does, we loop through those subcollections to create subCheckboxes
          subcollectionCheckboxes = sortCheckboxes<CollectionsCheckbox>([...subcollections.map((subcollection) => {
            const hasSubcollectionKey = pressRelease.collection_user_sources?.find((source) => source === subcollection.key);

            return {
              checked: !!hasSubcollectionKey,
              id: subcollection.key,
              labelCopy: subcollection.name,
              parentKey: subcollection.parent_collection_key
            };
          })], 'labelCopy');
        }

        const hasCollectionKey = pressRelease.collection_user_sources?.find((source) => source === collection.key);

        return {
          checked: !!hasCollectionKey,
          id: collection.key,
          labelCopy: collection.name,
          parentKey: collection.parent_key,
          subCheckboxes: hasSubcollections ? subcollectionCheckboxes : undefined
        };
      }), 'labelCopy')];

      setCheckboxes(collectionCheckboxes);
      setLoading(false);
    }
  };

  // 2. if fetch is successful, format resources into checkboxes
  useEffect(() => {
    if (resources) {
      formatResources();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  const handleBrandfolder = (id: string, e: InputChangeEvent): void => {
    const { checked } = e.target;

    const temp = [...checkboxes];
    const brandfolder = temp.find((b) => b.id === id);
    const brandfolderIndex = temp.findIndex((b) => b.id === id);
    temp[brandfolderIndex] = { ...brandfolder, checked };
    setCheckboxes(temp);

    const brandfolderUserSources = pressRelease.brandfolder_user_sources ?? [];
    if (checked) {
      setPressRelease({ ...pressRelease, brandfolder_user_sources: [...brandfolderUserSources, id] });
    } else {
      setPressRelease({ ...pressRelease, brandfolder_user_sources: brandfolderUserSources.filter((c) => c !== id) });
    }
  };

  const handleCollection = (id: string, e: InputChangeEvent, parentId?: string): void => {
    const { checked } = e.target;

    // if we're checking a subcollection
    if (parentId) {
      const collection = checkboxes.find((checkbox) => checkbox.id === parentId);
      if (collection && collection.subCheckboxes) {
        const collectionIndex = checkboxes.findIndex((checkbox) => checkbox.id === parentId);
        const subcollection = collection.subCheckboxes.find((subCheckbox) => subCheckbox.id === id);
        const subcollectionIndex = collection.subCheckboxes.findIndex((subCheckbox) => subCheckbox.id === id);
        collection.subCheckboxes[subcollectionIndex] = { ...subcollection, checked };

        const temp = [...checkboxes];
        temp[collectionIndex] = collection;
        setCheckboxes(temp);
      }
    } else {
      // else we're checking a collection
      const temp = [...checkboxes];
      const collection = temp.find((b) => b.id === id);
      if (collection) {
        const collectionIndex = temp.findIndex((b) => b.id === id);
        collection.checked = checked;
        temp[collectionIndex] = collection;
        setCheckboxes(temp);
      }
    }

    // applies to both collection and subcollection
    const collectionUserSources = pressRelease.collection_user_sources ?? [];
    if (checked) {
      setPressRelease({ ...pressRelease, collection_user_sources: [...collectionUserSources, id] });
    } else {
      setPressRelease({ ...pressRelease, collection_user_sources: collectionUserSources.filter((c) => c !== id) });
    }
  };

  const handleToggleCollections = (checked: boolean, parentId?: string): void => {
    // if we're toggling a collections' subcollection group
    if (parentId) {
      const collection = checkboxes.find((checkbox) => checkbox.id === parentId);
      if (collection) {
        const collectionIndex = checkboxes.findIndex((checkbox) => checkbox.id === parentId);

        let subcollections: CollectionsCheckbox[] = [];
        if (collection.subCheckboxes) {
          // if we're searching, only toggle subcollections that are in the search results
          if (search) {
            const searchedCollection = searchedCheckboxes.find((checkbox) => checkbox.id === parentId);
            if (searchedCollection && searchedCollection.subCheckboxes) {
              subcollections = collection.subCheckboxes.map((subCheckbox) => {
                const inSearchResults = searchedCollection?.subCheckboxes?.find((sc) => sc.id === subCheckbox.id);
                return { ...subCheckbox, checked: inSearchResults ? checked : subCheckbox.checked };
              });
            }
          } else {
            subcollections = collection.subCheckboxes.map((subCheckbox) => ({ ...subCheckbox, checked }));
          }
        }

        collection.subCheckboxes = subcollections;

        const temp = [...checkboxes];
        temp[collectionIndex] = collection;
        setCheckboxes(temp);

        // NOTE: don't loop over the checkboxes state, use subcollections we just created
        const ids = subcollections.map((subcollection) => (subcollection.id || '')).filter(Boolean);
        const userSources = pressRelease.collection_user_sources;
        if (checked) {
          setPressRelease({ ...pressRelease, collection_user_sources: userSources ? [...userSources, ...ids] : [...ids] });
        } else {
          const filteredUserSources = userSources ? userSources?.filter((source) => !ids.includes(source)) : [];
          setPressRelease({ ...pressRelease, collection_user_sources: [...filteredUserSources] });
        }
      }
    } else {
      // else we're toggle a brandfolders' collection group (and subcollections)
      const collections = checkboxes.map((checkbox) => {
        // if we're searching, only toggle that collection and its subcollection if it's in the search results
        if (search) {
          const inSearchResults = searchedCheckboxes.find((sc) => sc.id === checkbox.id);
          if (!inSearchResults) return checkbox;
        }

        const check = { ...checkbox };
        // toggle the subcollections if there are any
        if (check.subCheckboxes) {
          const subCheckboxes = check.subCheckboxes.map((subCheckbox) => ({
            ...subCheckbox,
            checked
          }));
          check.subCheckboxes = [...subCheckboxes];
        }

        // only toggle collection checkboxes (not a brandfolder checkbox)
        if (!check.isBrandfolder) {
          return { ...check, checked };
        }
        return { ...check };
      });
      setCheckboxes(collections);

      if (checked) {
        // NOTE: don't loop over the checkboxes state, use collections we just created
        const collectionIds = collections.filter((collection) => (!collection.isBrandfolder && collection.checked))
          .map((collection) => (collection.id || ''))
          .filter(Boolean);
        const subCollectionIds = collections.flatMap((collection) => collection.subCheckboxes)
          .filter(Boolean)
          .filter((subcollection) => (subcollection && subcollection.checked))
          .map((subcollection) => ((subcollection && subcollection.id) || ''))
          .filter(Boolean);

        const ids = [...collectionIds, ...subCollectionIds];
        setPressRelease({ ...pressRelease, collection_user_sources: ids });
      } else {
        setPressRelease({ ...pressRelease, collection_user_sources: [] });
      }
    }
  };

  const searchCheckboxes = (): void => {
    if (search) {
      // 1. filter in parent collection checkboxes
      const collections = checkboxes.filter((checkbox) => {
        // the parent collection matches
        if ((checkbox.labelCopy as string).toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
          return checkbox;
        }
        // one of the subcollections matches, so we need to show the parent
        if (checkbox.subCheckboxes) {
          const matches = checkbox.subCheckboxes.find((subCheckbox) => (subCheckbox.labelCopy as string).toLocaleLowerCase().includes(search.toLocaleLowerCase()));
          if (matches) {
            return checkbox;
          }
        }
        return undefined;
      }).filter(Boolean);

      // 2. filter in any of parent collection's subcollection checkboxes
      const temp = collections.filter((collection) => {
        if (collection && collection.subCheckboxes) {
          const subCheckboxes = collection.subCheckboxes.filter((subCheckbox) => (subCheckbox.labelCopy as string).toLocaleLowerCase().includes(search.toLocaleLowerCase()));
          return { ...collection, subCheckboxes };
        }
        return { ...collection };
      });

      setSearchedCheckboxes(temp);
    } else {
      setSearchedCheckboxes([]);
    }
  };

  useEffect(() => {
    searchCheckboxes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (searchedCheckboxes.length > 0) {
      // this is needed to reapply search after a checkbox is toggle while searching
      searchCheckboxes();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxes]);

  const hasSearchResults = searchedCheckboxes.length > 0;
  const isSearching = !!search;
  const showNoSearchResults = !hasSearchResults && isSearching;

  const hasResources = checkboxes.length > 0;

  const brandfolderCheckboxes = checkboxes.filter((checkbox) => checkbox.isBrandfolder);
  const hasBrandfolders = brandfolderCheckboxes.length > 0;

  const collectionsCheckboxes = checkboxes.filter((checkbox) => !checkbox.isBrandfolder);
  const hasCollections = collectionsCheckboxes.length > 0;
  const checkedCollectionsCheckboxes = collectionsCheckboxes.filter((checkbox) => checkbox.checked);
  const allCollectionsSelected = collectionsCheckboxes.length === checkedCollectionsCheckboxes.length;
  const noCollectionsSelected = checkedCollectionsCheckboxes.length === 0;

  // if searching, we render searchedCheckboxes (a temporary array), otherwise normal checkboxes
  const renderedCheckboxes = isSearching ? searchedCheckboxes : checkboxes;

  return (
    <>
      {loading && <BFLoader />}

      {!loading && error && (
        <PressReleaseMessage>
          {error.message}
        </PressReleaseMessage>
      )}

      {!loading && !error && (
        <>
          {hasResources && (
            <section id="brandfolder-collections-search">
              <PressReleasesSearch
                className={classes.search}
                onChange={(e): void => setSearch(e.target.value)}
                onClick={(): void => searchCheckboxes()}
                onKeyUp={(): void => searchCheckboxes()}
                value={search}
              />
            </section>
          )}

          {hasBrandfolders && !showNoSearchResults && (
            <section id="brandfolder-checkbox">
              <h4 className={classes.h4}>{isGettyClient() ? <Trans>Library</Trans> : 'Brandfolder'}</h4>
              <div className={classes.checkboxes}>
                {renderedCheckboxes.map((checkbox) => {
                  if (checkbox.isBrandfolder) {
                    return (
                      <StandardCheckbox
                        key={checkbox.id}
                        checked={checkbox.checked}
                        labelCopy={checkbox.labelCopy}
                        onChange={(_, e): void => {
                          if (checkbox.id) {
                            handleBrandfolder(checkbox.id, e);
                          }
                        }}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </section>
          )}

          {hasCollections && !showNoSearchResults && (
            <section className={hasBrandfolders ? classes.collections : undefined} id="collections-checkboxes">
              <PressReleaseToggleAll
                disableDeselectAll={noCollectionsSelected}
                disableSelectAll={allCollectionsSelected}
                handleDeselectAll={(): void => handleToggleCollections(false)}
                handleSelectAll={(): void => handleToggleCollections(true)}
                heading={t`Collections`}
                showToggles={renderedCheckboxes.length > 1}
              />

              <div className={classes.checkboxes}>
                {renderedCheckboxes.map((checkbox) => {
                  if (!checkbox.isBrandfolder) {
                    let subCheckboxes: JSX.Element | null = null;
                    if (checkbox.subCheckboxes) {
                      const checkedSubcollectionsCheckboxes = checkbox.subCheckboxes.filter((subCheckbox) => subCheckbox.checked);
                      const allSubcollectionsSelected = checkbox.subCheckboxes.length === checkedSubcollectionsCheckboxes.length;
                      const noSubcollectionsSelected = checkedSubcollectionsCheckboxes.length === 0;

                      subCheckboxes = (
                        <div className={classnames(classes.checkboxes, classes.subCheckboxes)}>
                          <PressReleaseToggleAll
                            disableDeselectAll={noSubcollectionsSelected}
                            disableSelectAll={allSubcollectionsSelected}
                            handleDeselectAll={(): void => handleToggleCollections(false, checkbox.id)}
                            handleSelectAll={(): void => handleToggleCollections(true, checkbox.id)}
                            heading={t`Subcollections`}
                            showToggles={checkbox.subCheckboxes.length > 1}
                          />
                          {checkbox.subCheckboxes.map((subCheckbox) => (
                            <StandardCheckbox
                              key={subCheckbox.id}
                              checked={subCheckbox.checked}
                              labelCopy={subCheckbox.labelCopy}
                              onChange={(_, e): void => {
                                if (subCheckbox.id) {
                                  handleCollection(subCheckbox.id, e, checkbox.id);
                                }
                              }}
                            />
                          ))}
                        </div>
                      );
                    }

                    const renderedCheckbox = (
                      <StandardCheckbox
                        checked={checkbox.checked}
                        labelCopy={checkbox.labelCopy}
                        onChange={(_, e): void => {
                          if (checkbox.id) {
                            handleCollection(checkbox.id, e);
                          }
                        }}
                      />
                    );

                    const renderedAccordion = checkbox.id && subCheckboxes ? (
                      <StandardAccordion
                        key={checkbox.id}
                        accordions={[{
                          button: checkbox.labelCopy,
                          headerContent: renderedCheckbox,
                          panel: subCheckboxes,
                          showText: false
                        }]}
                        allowMultipleExpanded
                        allowZeroExpanded
                        id={checkbox.id}
                      />
                    ) : (
                      <section
                        key={checkbox.id}
                        className={classes.checkbox}
                      >
                        {renderedCheckbox}
                      </section>
                    );

                    return renderedAccordion;
                  }
                  return null;
                })}
              </div>
            </section>
          )}

          {showNoSearchResults && (
            <PressReleaseMessage showIcon={false}>
              <Trans>
                No recipients found.&nbsp;
                <TextButton
                  className={classes.messageButton}
                  onClick={(): void => setSearch('')}
                >
                  Undo your search.
                </TextButton>
              </Trans>
            </PressReleaseMessage>
          )}
        </>
      )}
    </>
  );
};
