import { t, Trans } from '@lingui/macro';
import React, { useContext } from 'react';

import { GenericFileData } from '@api/v4/assets/assetTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';

import {
  ActionTypes,
  AssetDetailsDispatch,
} from '@components/asset/modal/tabs/edit/EditTabTypes';


import RadioButton from '@components/common/radio_button/main';

interface ThreeSixtyViewerScopedContext {
  state: {
    editState: {
      genericFileData: GenericFileData;
    };
  };
  dispatch: AssetDetailsDispatch;
}

const ThreeSixtyViewerComponent = (): React.ReactElement => {
  const { state, dispatch }: ThreeSixtyViewerScopedContext = useContext(assetModalContext);
  const { genericFileData } = state.editState;
  const { three_sixty_viewer: threeSixyViewer } = { ...genericFileData };

  const updateThreeSixtyViewer = (value: boolean): void => {
    const payload = {
      genericFileData: {
        ...genericFileData,
        three_sixty_viewer: value // eslint-disable-line @typescript-eslint/naming-convention
      }
    };

    dispatch({ type: ActionTypes.UpdateAsset, payload });
  };

  return (
    <div className="asset-advanced__feature threesixty-viewer-setting">
      <span><Trans>360&#176; Viewer</Trans></span>
      <div className="radio-button-container">
        <RadioButton
          checkedButton={threeSixyViewer ? 'Enable' : 'Disable'}
          labelCopy={t`Enabled`}
          name="three-sixty-viewer"
          onChangeFunction={(): void => updateThreeSixtyViewer(true)}
          valueCopy="Enable"
        />
        <RadioButton
          checkedButton={threeSixyViewer ? 'Enable' : 'Disable'}
          labelCopy={t`Disabled`}
          name="three-sixty-viewer"
          onChangeFunction={(): void => updateThreeSixtyViewer(false)}
          valueCopy="Disable"
        />
      </div>
    </div>
  );
};

export default ThreeSixtyViewerComponent;
