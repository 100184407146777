import React from "react";
import ClipboardCopy from "../../common/clipboard_copy/main";

export default class AssetColorHover extends React.Component {
  render() {
    const text = this.props.assetText;
    const { hex, r, g, b, c, m, y, k, pantone, pantone_u} = this.props.assetData;
    return (
      <div className="asset-description">
        {hex ?
          <div className="s-color-description-overlay-format">
            <ClipboardCopy textToCopy={`#${hex}`} showFeedbackAsTooltip={false}>
              <div className="s-pull-left"> HEX </div>
            </ClipboardCopy>
            <div className="s-pull-right"> {`# ${hex}`}</div>
          </div> : ''}
        {r ?
          <div className="s-color-description-overlay-format">
            <ClipboardCopy textToCopy={`rgb(${r}, ${g}, ${b})`} showFeedbackAsTooltip={false}>
              <div className="s-pull-left"> RGB </div>
            </ClipboardCopy>
            <div className="s-pull-right"> {`${r} / ${g} / ${b}`}</div>
          </div> : ''}
        {c ?
          <div className="s-color-description-overlay-format">
            <div className="s-pull-left"> CMYK </div>
            <div className="s-pull-right"> {`${c} / ${m} / ${y} / ${k}`}</div>
          </div> : ''}
        {pantone ?
          <div className="s-color-description-overlay-format">
            <div className="s-pull-left"> Pantone </div>
            <div className="s-pull-right"> {`${pantone} • ${pantone_u}`}</div>
          </div> : ''}
        {text}
        {this.props.tags}
      </div>
    );
  }
}
