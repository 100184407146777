import classnames from 'classnames';
import { PickerOptions, PickerResponse, PickerUploadDoneCallback } from 'filestack-js/build/main/lib/picker';
import React, { FunctionComponent, HTMLAttributes, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FilestackCredentialsPostOptions } from '@api/v4/private/resources/fs_creds';
import { FilestackUploader } from '@components/common/filestack_uploader/FilestackUploader';
import { FilestackUploaderTypes } from '@components/common/filestack_uploader/FilestackUploaderEnums';
import { handleCustomImages } from '@components/common/filestack_uploader/helpers';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

export interface UploadAreaProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  credentialsOptions?: FilestackCredentialsPostOptions;
  /** onUploadDone isn't provided by .slim files, but otherwise should be required */
  onUploadDone?: PickerUploadDoneCallback;
  pickerOptions?: PickerOptions;
  printuiAssetKey?: string;
  printuiDigestKey?: string;
  resourceKey?: string;
  resourceType?: string;
  text?: string;
  /** Defaults to FilestackUploaderTypes.Area */
  type?: FilestackUploaderTypes;
  uploadLinkKey?: string;
  /** Defaults to UploadAreaTypes.GenericUpload */
  uploadType?: UploadAreaTypes;
}

/**
 * `<UploadArea>` is a convenience component that wraps `<FilestackUploader>`,
 * adding a unique dropzoneContainerId and additional styling.
 *
 * IMPORTANT: This component is consumed directly by .slim files.
 *
 * @param props UploadAreaProps
 * @returns ReactElement
 */
export const UploadArea: FunctionComponent<UploadAreaProps> = (props) => {
  const {
    className,
    credentialsOptions,
    id,
    onClick,
    onUploadDone,
    pickerOptions,
    printuiAssetKey,
    printuiDigestKey,
    resourceKey,
    resourceType,
    text,
    type = FilestackUploaderTypes.Area,
    uploadLinkKey,
    uploadType = UploadAreaTypes.GuestUpload,
    ...otherProps
  } = props;

  const handleOnUploadDone = (files: PickerResponse): void => {
    if ([
      UploadAreaTypes.BackgroundImage,
      UploadAreaTypes.CardImage,
      UploadAreaTypes.EmailLogoImage,
      UploadAreaTypes.FaviconImage,
      UploadAreaTypes.HeaderImage,
      UploadAreaTypes.LogoImage
    ].includes(uploadType)) {
      handleCustomImages(files, uploadType);
    } else if (onUploadDone) {
      onUploadDone(files);
    }
  };

  /**
   * We're using useCallback so that identifier doesn't change between parent re-renders
   */
  const identifier = useCallback(() => {
    return  `${uploadType}-${id || uuidv4()}`;
  }, [])(); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * We're using useMemo so that the <UploadArea> doesn't change between parent re-renders
   * (which otherwise could result in duplicate <FilestackUploader>'s showing)
   */
  return useMemo(() => {
    return (
      <I18nProviderWrapper>
        <div
          {...otherProps}
          className={classnames({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'filestack-upload-area': true,
            [className]: !!className
          })}
          data-testid={identifier}
          id={identifier}
        >
          <FilestackUploader
            credentialsOptions={credentialsOptions}
            dropzoneContainerId={identifier}
            onClick={onClick}
            onUploadDone={handleOnUploadDone}
            pickerOptions={pickerOptions}
            printuiAssetKey={printuiAssetKey}
            printuiDigestKey={printuiDigestKey}
            resourceKey={resourceKey}
            resourceType={resourceType}
            text={text}
            type={type}
            uploadLinkKey={uploadLinkKey}
          />
        </div>
      </I18nProviderWrapper>
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
