import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@components/common/tooltip/main';

const BreadcrumbsClose = ({ onLabelSelect }) => (
  <button
    className="close-breadcrumbs-button"
    onClick={() => onLabelSelect('resource')}
    type="button"
  >
    <Tooltip
      className="close-button-tooltip"
      tooltipId="deselect-labels"
      tooltipContent="Deselect Labels"
    >
      <span className="bff-close" />
    </Tooltip>
  </button>
);

BreadcrumbsClose.propTypes = {
  onLabelSelect: PropTypes.func.isRequired,
};

export default BreadcrumbsClose;
