import { AttachmentResponseObject, PreviewType } from '@api/v4/attachments/attachmentTypes';

/* eslint-disable @typescript-eslint/naming-convention */
export const jpgAttachment: AttachmentResponseObject = {
  attributes: {
    cdn_url: 'cdn-jpg_image.jpg',
    extension: 'jpg',
    filename: 'jpg_image.jpg',
    height: 4032,
    is_processing: false,
    mimetype: 'image/jpeg',
    mux_hls_url: null,
    position: 0,
    preview_type: PreviewType.Image,
    size: 2467851,
    thumbnail_url: 'jpg_image_element.jpg',
    url: 'jpg_image.jpg',
    view_thumbnail_retina: 'jpg_image_element_retina.jpg',
    width: 3024
  },
  id: 'jpg-attachment',
  type: 'attachments'
};

export const pngAttachment: AttachmentResponseObject = {
  attributes: {
    cdn_url: 'cdn-png_image.png',
    extension: 'png',
    filename: 'png_image.png',
    height: 4032,
    is_processing: false,
    mimetype: 'image/png',
    mux_hls_url: null,
    position: 0,
    preview_type: PreviewType.Image,
    size: 2467851,
    thumbnail_url: 'png_image_element.png',
    url: 'png_image.png',
    view_thumbnail_retina: 'png_image_element_retina.png',
    width: 3024
  },
  id: 'png-attachment',
  type: 'attachments'
};

const muxAttachment: AttachmentResponseObject = {
  attributes: {
    cdn_url: 'cdn-mux.mov',
    extension: 'mov',
    filename: 'mux.MOV',
    height: 1920,
    is_processing: false,
    mimetype: 'video/quicktime',
    mux_hls_url: 'mux-stream.m3u8',
    position: 1,
    preview_type: PreviewType.VideoMux,
    size: 37576057,
    thumbnail_url: 'mux_element.png',
    url: 'mux.MOV',
    view_thumbnail_retina: 'mux_element_retina.png',
    width: 1080
  },
  id: 'mux-attachment',
  type: 'attachments'
};

const videoAttachment: AttachmentResponseObject = {
  attributes: {
    cdn_url: 'cdn-video.mov',
    extension: 'mov',
    filename: 'video.MOV',
    height: null,
    is_processing: true,
    mimetype: 'video/quicktime',
    mux_hls_url: null,
    position: 0,
    preview_type: PreviewType.VideoOther,
    size: 37576057,
    thumbnail_url: 'video_element.png',
    url: 'video.MOV',
    view_thumbnail_retina: 'video_element_retina.png',
    width: null
  },
  id: 'video-attachment',
  type: 'attachments'
};

const audioAttachment: AttachmentResponseObject = {
  attributes: {
    cdn_url: 'audio.m4a',
    extension: 'm4a',
    filename: 'audio.m4a',
    height: 810,
    is_processing: false,
    mimetype: 'audio/x-m4a',
    mux_hls_url: null,
    position: 0,
    preview_type: PreviewType.Audio,
    size: 4979491,
    thumbnail_url: 'audio_element.png',
    url: 'audio.m4a',
    view_thumbnail_retina: 'audio_element_retina.png',
    width: 1310
  },
  id: 'audio-attachment',
  type: 'attachments'
};

const pdfAttachment: AttachmentResponseObject = {
  attributes: {
    cdn_url: 'pdf_doc.pdf',
    extension: 'pdf',
    filename: 'pdf_doc.pdf',
    height: null,
    is_processing: false,
    mimetype: 'application/pdf',
    mux_hls_url: null,
    position: 0,
    preview_type: PreviewType.PDF,
    size: 10759214,
    thumbnail_url: 'pdf_doc_element.pdf',
    url: 'pdf_doc.pdf',
    view_thumbnail_retina: 'pdf_doc.pdf',
    width: null
  },
  id: 'pdf-attachment',
  type: 'attachments'
};

const officeAttachment: AttachmentResponseObject = {
  attributes: {
    cdn_url: 'cdn-office_doc.docx',
    extension: 'docx',
    filename: 'office_doc.docx',
    height: 2200,
    is_processing: false,
    mimetype: 'application/zip',
    mux_hls_url: null,
    position: 2,
    preview_type: PreviewType.OfficeDoc,
    size: 30422,
    thumbnail_url: 'office_doc_element.png',
    url: 'office_doc.docx',
    view_thumbnail_retina: 'office_doc_element_retina.png',
    width: 1700
  },
  id: 'office-attachment',
  type: 'attachments'
};

const threeDAttachment: AttachmentResponseObject = {
  attributes: {
    cdn_url: 'https: //cdn-staging.bfldr.com/HQBO0BD8/at/vh4vxkvm96x8wsxpbjr7pr6/Box.glb?auto=webp&format=png',
    extension: 'glb',
    filename: 'Box.glb',
    height: 2362,
    is_processing: false,
    mimetype: 'application/octet-stream',
    mux_hls_url: null,
    position: 0,
    preview_type: PreviewType.Image,
    size: 1612,
    thumbnail_url: 'https: //d26k9iuuh907c5.cloudfront.net/assets/types/glb.svg',
    url: 'https: //storage-us-stage-gcs.bfldr.com/vh4vxkvm96x8wsxpbjr7pr6/v/58928884/original/Box.glb?Expires=1637342312&KeyName=gcs-bfldr-stage&Signature=_n7B_RrCe3xZ4ONEW-Rxv88IfnU=',
    view_thumbnail_retina: 'https: //d26k9iuuh907c5.cloudfront.net/assets/types/glb.svg',
    width: 1824,
  },
  id: '3d-attachment',
  type: 'attachements'
};

/* eslint-disable @typescript-eslint/naming-convention */

export const getSpecifiedAttachment = (id: string): AttachmentResponseObject => {
  const idToData = {
    'jpg-attachment': jpgAttachment,
    'png-attachment': pngAttachment,
    'mux-attachment': muxAttachment,
    'video-attachment': videoAttachment,
    'pdf-attachment': pdfAttachment,
    'office-attachment': officeAttachment,
    'audio-attachment': audioAttachment,
    '3d-attachment': threeDAttachment,
  };
  return idToData[id];
};

export const getSpecifiedProcessingAttachment = (id: string): AttachmentResponseObject => {
  const att = getSpecifiedAttachment(id);
  return {
    ...att,
    attributes: {
      ...att.attributes,
      is_processing: true
    }
  };
};
