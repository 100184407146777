import React, {
  FunctionComponent,
  LiHTMLAttributes,
} from 'react';

import BaseDropdownItem from '@components/library/dropdown_item/base/BaseDropdownItem';
import { ActionDropdownItemProps } from '@components/library/dropdown_item/DropdownItemProps';
import styleClassNames from './list-dropdown-item.module.scss';

const ActionDropdownItem: FunctionComponent<ActionDropdownItemProps & LiHTMLAttributes<HTMLLIElement>> = (
  props
) => {
  const baseProps = {
    ...props,
    styleClassNames,
    variantName: 'action',
  };

  return <BaseDropdownItem {...baseProps} />;
};

export default ActionDropdownItem;
