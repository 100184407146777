import { t, Trans } from '@lingui/macro';
import * as React from 'react';

import { AssetsResponseObject } from '@api/v4/assets/assetTypes';
import { updateBoard } from '@api/v4/boards';

import renderModal from '@components/show_page/modals/renderModal';

import { SingleBoardAssetsViewContext } from '../single_board_assets_view';

interface RemoveAssetHandlerParams {
  boardId: string;
  assetId: string;
  assets: AssetsResponseObject[];
  setAssets: React.Dispatch<React.SetStateAction<AssetsResponseObject[]>>;
}
// reflect success or error message
const removeAssetHandler = async (options: RemoveAssetHandlerParams): Promise<void> => {
  const { boardId, assetId, assets, setAssets } = options;
  try {
    await updateBoard({ boardId, assetIds: new Set([assetId]), operation: 'REMOVE' });
    Notify.create({ title: t`Asset removed from board`, type: 'success' });
    const trimmedAssets = assets.filter((asset) => asset.id !== assetId);
    setAssets(trimmedAssets);
  } catch (error) {
    console.log(error);
    Notify.create({ title: t`Something went wrong. Try again soon!`, type: 'error' });
  }
};

interface RemoveAssetModalProps {
  boardName: string;
  boardId: string;
  assetId: string;
  closeModal: () => void;
}

const RemoveAssetModal: React.FunctionComponent<RemoveAssetModalProps> = (options): JSX.Element => {
  const { boardName, boardId, assetId, closeModal } = options;
  const SingleBoardAssetsViewState = React.useContext(SingleBoardAssetsViewContext);

  return (
    <div className="modal-content-wrapper remove-asset-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-trash icon" />
        <h2 className="modal-title"><Trans>Remove asset from "{boardName}" Board</Trans></h2>
      </div>
      <div className="modal-content-wrapper__body">
        <div className="button-container">
          <button
            className="button tertiary sm t-close-modal"
            onClick={(): void => closeModal()}
            type="button"
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            className="button sm warning t-confirm-modal"
            onClick={(): void => {
              removeAssetHandler({
                boardId,
                assetId,
                assets: SingleBoardAssetsViewState.assets,
                setAssets: SingleBoardAssetsViewState.setAssets
              });
            }}
            type="button"
          >
            <Trans>Remove</Trans>
          </button>
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(RemoveAssetModal, 'RemoveAssetModal');
export default ModalComponent;
