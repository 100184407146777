import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, MouseEvent } from 'react';

import { PrimaryButton, TextWarningButton } from '@components/library/button';

import classes from './form_dialog.module.scss';

interface FormDialogButtonsProps {
  creating?: boolean;
  deleting?: boolean;
  disabled?: boolean;
  handleCreate?: (e?: MouseEvent<HTMLButtonElement>) => Promise<void>;
  handleDelete?: (e?: MouseEvent<HTMLButtonElement>) => Promise<void>;
  handleUpdate?: (e?: MouseEvent<HTMLButtonElement>) => Promise<void>;
  updating?: boolean;
}

/**
 * A custom form helper for handling create, delete, and update button display.
 * You should pass in at least one of handleCreate, handleDelete, or handleUpdate.
 * @param {FormDialogButtonsProps} props FormDialogButtonsProps
 */
export const FormDialogButtons: FunctionComponent<FormDialogButtonsProps> = (props) => {
  const { creating, deleting, disabled, handleCreate, handleDelete, handleUpdate, updating } = props;

  return (
    <div className={classes['form-dialog--buttons']}>
      {(handleDelete || handleUpdate) && (
        <div
          className={classnames(
            classes['form-dialog--delete-update'],
            classes[`form-dialog--delete-update__${(handleDelete && handleUpdate) ? 'both-buttons' : 'one-button'}`]
          )}
        >
          {handleDelete && (
            <TextWarningButton
              disabled={disabled || updating}
              icon="bff-trash"
              isLoading={deleting}
              onClick={(e): void => { handleDelete(e); }}
            >
              {deleting ? <Trans>Deleting...</Trans> : <Trans>Delete</Trans>}
            </TextWarningButton>
          )}
          {handleUpdate && (
          <PrimaryButton
            disabled={deleting || disabled}
            isLoading={updating}
            onClick={(e): void => { handleUpdate(e); }}
            size="small"
          >
            {updating ? <Trans>Saving...</Trans> : <Trans>Update</Trans>}
          </PrimaryButton>
          )}
        </div>
      )}
      {handleCreate && (
        <PrimaryButton
          disabled={disabled}
          isLoading={creating}
          onClick={(e): void => { handleCreate(e); }}
          size="small"
        >
            {creating ? <Trans>Saving...</Trans> : <Trans>Create</Trans>}
        </PrimaryButton>
      )}
    </div>
  );
};
