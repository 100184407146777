/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import AutosuggestSearch from '@components/common/autosuggest_search/main';
import Checkbox from '@components/common/checkbox/main';

const FiletypesFilter = (props) => {
  const [allFileTypes, setAllFileTypes] = useState([]);
  const [visibleFileTypes, setVisibleFileTypes] = useState([]);
  const { filetypes, searchableThings, updateFilters } = props;
  const fileTypesFiltered = filetypes;

  useEffect(() => {
    if (searchableThings.filetypes !== undefined) {
      const allFileTypesUpdated = searchableThings.filetypes.map((filetype) => (
        filetype === "bftemplate"
          ? "template"
          : filetype
      ));

      setAllFileTypes(allFileTypesUpdated);
      setVisibleFileTypes(allFileTypesUpdated.slice(0, 10));
    }
  }, [searchableThings]);

  const checkAndUpdateFileTypes = (filetype) => {
    if (visibleFileTypes.length > 10) {
      const hasFilter = visibleFileTypes.slice(10).filter((filteredExtension) => (filteredExtension === filetype));

      if (hasFilter.length > 0) {
        const visibleFileTypesUpdated = allFileTypes.slice(0, 10);
        setVisibleFileTypes(visibleFileTypesUpdated);
      }
    }

    const filetypeToUdpate = filetype === "template" ? "bftemplate" : filetype;
    updateFilters(filetypeToUdpate);
  };

  const updateFileTypes = (extension) => {
    const visibleFileTypesUpdated = [...visibleFileTypes];
    extension = extension === "template" ? "bftemplate" : extension;

    updateFilters(extension);
    visibleFileTypesUpdated.push(extension);
    setVisibleFileTypes(visibleFileTypesUpdated);
  };

  if (allFileTypes.length <= 0) { return null; }

  return (
    <div className="filter-section filetypes">
      <div className="flex-row title">
        <p><Trans>File Types</Trans></p>
      </div>
      <div className="tag-columns">
        {visibleFileTypes.map((extension) => {
          const isChecked = extension === "template"
            ? fileTypesFiltered.indexOf("bftemplate") > -1
            : fileTypesFiltered.indexOf(extension) > -1;
          return (
            <div key={extension} className="tag-item">
              <Checkbox
                checked={isChecked}
                label={extension}
                size="sm"
                toggle={() => checkAndUpdateFileTypes(extension)}
              />
            </div>
          );
        })}
      </div>
      { _.difference(allFileTypes, visibleFileTypes).length === 0 ? '' : (
        <AutosuggestSearch
          allValues={allFileTypes}
          id="filetype-autosuggest"
          onSelect={updateFileTypes}
          visibleValues={visibleFileTypes}
        />
      )}
    </div>
  );
};

FiletypesFilter.propTypes = {
  filetypes: PropTypes.arrayOf(PropTypes.string),
  searchableThings: PropTypes.shape({}).isRequired,
  updateFilters: PropTypes.func.isRequired,
};

FiletypesFilter.defaultProps = {
  filetypes: [],
};

export default FiletypesFilter;
