import { Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";
import Autosuggest from "react-autosuggest";
import _ from 'underscore';

class AutosuggestSearch extends React.Component {
  state = {
    suggestions: [],
    value: ''
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter' && this.state.suggestions?.[0]) {
      this.onSuggestionSelected(e, { suggestion: this.state.suggestions[0] });
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    if (escapedValue === '') { return []; }

    const regex = new RegExp(`^${escapedValue}`, 'i');
    const suggestionsArray = _.difference(this.props.allValues, this.props.visibleValues);

    return suggestionsArray.filter((filter) => (
      regex.test(filter)
    ));
  }

  getSuggestionValue = (suggestion) => suggestion;

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    this.setState({ value: '' });
    this.props.onSelect(suggestion);
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  renderSuggestion(suggestion) {
    return (
      <span className="suggestion-item">
        <span className="suggestion-name">
          {suggestion}
        </span>
      </span>
    );
  }

  render() {
    const { value, suggestions } = this.state;
    const { id } = this.props;
    const inputProps = {
      value,
      onChange: this.onChange,
      onKeyPress: this.onKeyPress
    };

    return (
      <div className="filter-search-row">
        <span><Trans>Search:</Trans></span>
        <span className="bff-search" />
        <Autosuggest
          getSuggestionValue={this.getSuggestionValue}
          id={id}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          renderSuggestion={this.renderSuggestion}
          suggestions={suggestions}
        />
      </div>
    );
  }
}

AutosuggestSearch.propTypes = {
  id: PropTypes.string,
  allValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  visibleValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default AutosuggestSearch;
