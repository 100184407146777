export enum Widths {
  None = '', // intentionally first
  Xxsmall = 'xxsmall',
  Xsmall = 'xsmall',
  Small= 'small',
  Medium = 'medium',
  Large = 'large',
  Xlarge = 'xlarge',
  Xxlarge = 'xxlarge'
}
