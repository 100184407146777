// entry point into profile Board view
// view router and all *_view.tsx files create and share Context
// this context is primarily used by modals when they mutate the collection of boards or assets
// initial GETs of boards or assets happens in *_view.tsx files

import * as React from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { sendAction, TrackedAction } from '@helpers/datadog-rum';

import AllBoardsView from './all_boards_view';
import SingleBoardAssetsView from './single_board_assets_view';

import './styles/main.scss';

// Create a context to share data without prop drilling
interface ContextValue {
  setBoardToView: React.Dispatch<React.SetStateAction<ViewRouterState['boardToView']>> | null;
}

interface ViewRouterProps {
  libraryName: string;
}

const initialState: ContextValue = {
  setBoardToView: null
};

export const ViewRouterContext = React.createContext(initialState);

// for when we select a board to view
export interface ViewRouterState {
  boardToView: null | {
    boardId: string;
    boardName: string;
  };
}

const ViewRouter: React.FunctionComponent<ViewRouterProps> = ({ libraryName }) => {
  const [boardToView, setBoardToView] = React.useState<ViewRouterState['boardToView']>(null);

  React.useEffect(() => {
    sendAction(TrackedAction.Boards);
  }, []);

  return (
    <I18nProviderWrapper>
      <div className="boards-view-router">
        <ViewRouterContext.Provider value={{ setBoardToView }}>
          {
            boardToView
              ? (
                <SingleBoardAssetsView
                  {
                ...boardToView
              }
                  showAllBoards={(): void => setBoardToView(null)}
                />
              )
              : <AllBoardsView libraryName={libraryName} />
          }
        </ViewRouterContext.Provider>
      </div>
    </I18nProviderWrapper>
  );
};

export default ViewRouter;
