import { plural, t, Plural, Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from "react";

import { removeFromAllCollections } from '@api/v4/private/brandfolders/removeFromAllCollections';
import DateTimePicker from '@components/common/date_time_picker/main';
import { BFLoader } from '@components/common/loader/main';
import { PrimaryButton, TextWarningButton } from '@components/library/button/index';
import { StandardCheckbox } from '@components/library/checkbox/index';
import { StandardCheckboxGroup } from '@components/library/checkbox_group/index';
import { ListOpenDirections } from '@components/library/dropdown';
import { Brandfolder } from '@helpers/show_page_helpers';
import { removeOptions } from '@helpers/sweet_alert_options';

import renderModal from '../modals/renderModal';

import './styles/BulkCollectionModal.scss';

class BulkCollectionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminable: BF.fx.role() === 'admin' || BF.fx.role() === 'owner' || BFG.currentUser.su,
      allSelected: false,
      availabilitySet: false,
      availability_start_date: moment().format('MM/DD/YYYY'),
      availability_start_time: '12:01 am',
      availability_start_zone: moment.tz.guess(),
      availability_end_date: moment().add(7, 'days').format('MM/DD/YYYY'),
      availability_end_time: '11:59 pm',
      availability_end_zone: moment.tz.guess(),
      collectionSearchValue: '',
      disableSubmitButton: true,
      displayCollectionSearch: false,
      existingCollections: [],
      isAvailabilityDateValid: true,
      isExpirationDateValid: true,
      isLoading: true,
      isSubmitting: false,
      new_collection_name: '',
      newCollection: false,
      selectedCollections: []
    };
  }

  componentDidMount() {
    this.getCollections();
  }

  componentWillUnmount() {
    this.props.closeModal();
  }

  getCollections() {
    $.ajax({
      type: "GET",
      url: `/api/v4/brandfolders/${Brandfolder.key}/collections?is_workspace=false&fields=parent_id&per=2000&queue_priority=high`,
      contentType: "application/json",
      context: this,
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: (response) => {
        let collections = response.data?.filter((collection) => (
          collection?.id !== BFG.resource.key  // remove self if currently viewing a collection
        )) || [];

        if (this.props.isSubCollectionModal) {
          // render only subcollections for the parent (current resource)
          collections = collections.filter((collection) => (
            collection?.attributes?.parent_id === BFG.resource.key
          ))
        } else {
          // render only parent (top-level) collections
          collections = collections.filter((collection) => (
            collection?.attributes?.parent_id === null
          ))
        }

        this.setState({
          existingCollections: collections,
          displayCollectionSearch: collections.length >= 10,
          newCollection: !collections.length
        });
      },
      complete: () => {
        this.setState({ isLoading: false });
      }
    });
  }

  availability_start = () => {
    const { availability_start_date, availability_start_time } = this.state;
    let { availability_start_zone } = this.state;
    availability_start_zone = availability_start_zone || moment.tz.guess();

    return moment.tz(`${availability_start_date} ${availability_start_time}`, 'MM/DD/YYYY hh:mm a', availability_start_zone);
  }

  availability_end = () => {
    const { availability_end_date, availability_end_time } = this.state;
    let { availability_end_zone } = this.state;
    availability_end_zone = availability_end_zone || moment.tz.guess();

    return moment.tz(`${availability_end_date} ${availability_end_time}`, 'MM/DD/YYYY hh:mm a', availability_end_zone);
  }

  buildDataBody = () => {
    const { selectedAssetKeys } = this.props;
    const { availabilitySet, selectedCollections, new_collection_name } = this.state;
    const data = {
      asset_keys: Array.from(selectedAssetKeys),
      brandfolder_key: Brandfolder.key,
      collection_keys: selectedCollections.map((collection) => collection.id)
    }

    if (availabilitySet) {
      data.collection_asset_associations = {
        availability_start: this.availability_start().format(),
        availability_end: this.availability_end().format()
      }
    }

    if (new_collection_name) {
      data.new_collection_name = new_collection_name
    }

    return { data: data }
  }

  createCollectionAssociations = () => {
    this.setState({ isSubmitting: true });

    const url = '/api/v4/bulk_actions/assets/collection_associations?queue_priority=high';
    const { newCollection, selectedCollections, new_collection_name } = this.state;
    const assetCount = this.props.selectedAssetKeys.size;
    const collectionCount = selectedCollections.length;

    $.ajax({
      type: "POST",
      url,
      contentType: "application/json",
      data: JSON.stringify(this.buildDataBody()),
      context: this,
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: (response) => {
        this.props.closeModal();
        const body = this.state.availabilitySet
          ? t`Assets may not appear immediately due to selected publish/expiration dates.`
          : '';
        if (newCollection) {
          BF.fx.dispatchWindowEvent('newCollectionAdded', null, { newCollection: response.data[0].attributes });
          const title = plural(assetCount, {
            one: `${assetCount} asset added to ${new_collection_name}!`,
            other: `${assetCount} assets added to ${new_collection_name}!`
          });
          Notify.create({
            title,
            body,
            type: 'success'
          });
        } else if (selectedCollections.length === 1) {
          const collectionName = selectedCollections[0].attributes.name;
          const title = plural(assetCount, {
            one: `${assetCount} asset added to ${collectionName}!`,
            other: `${assetCount} assets added to ${collectionName}!`
          });
          Notify.create({
            title,
            body,
            type: 'success'
          });
        } else {
          const title = plural(collectionCount, {
            one: `Assets added to ${collectionCount} collection!`,
            other: `Assets added to ${collectionCount} collections!`
          });
          Notify.create({
            title,
            body,
            type: 'success'
          });
        }
      },
      error: (xhr) => {
        const errorText = xhr.responseText;
        if (errorText && errorText.indexOf('Insufficient Plan') > -1) {
          this.props.closeModal();
          Notify.create({
            title: t`Please upgrade to add more collections.`,
            type: 'error'
          });
        } else {
          this.setState({ disableSubmitButton: false, isSubmitting: false });
          const title = t`An error occurred when adding assets to collections. Please try again.`;
          Notify.create({
            title,
            type: 'error'
          });
        }
      }
    });
  }

  handleRemoveAll = () => {
    const { selectedAssetKeys } = this.props;
    const assetCount = selectedAssetKeys.size;

    const thingToRemove = plural(assetCount, {
      one: '# asset from all Collections',
      other: '# assets from all Collections'
    });
    const successNotif = plural(assetCount, {
      one: '# asset has been removed from all Collections',
      other: '# assets have been removed from all Collections'
    });
    const errorNotif = plural(assetCount, {
      one: 'An error ocurred when removing # asset from all Collections',
      other: 'An error ocurred when removing # assets from all Collections'
    });

    window.swal(removeOptions({ thingToRemove }), async (confirm) => {
      if (confirm) {
        try {
          await removeFromAllCollections(BFG.brandfolder_key, Array.from(selectedAssetKeys));
          Notify.create({ title: successNotif, type: 'success' });
          this.props.closeModal();
        } catch (err) {
          Notify.create({ title: errorNotif, type: 'error' });
        } finally {
          this.props.closeModal();
        }
      }
    });
  }

  enableActionButton = () => {
    const { availabilitySet, selectedCollections, newCollection, new_collection_name } = this.state;
    let enabled = true;

    // validate publish and expiration dates
    if (availabilitySet) {
      if (this.availability_start().isValid() && this.availability_end().isValid()) {
        enabled = this.availability_start().isBefore(this.availability_end()); // is expire date after than publish date
      } else {
        enabled = false;
      }
    }

    // validate collection selections if dates are validated
    if (enabled) {
      if (selectedCollections.length || (newCollection === true && new_collection_name !== '')) {
        enabled = true;
      } else {
        enabled = false;
      }
    }

    this.setState({ disableSubmitButton: !enabled });
  }

  updateDateTimeStart = (availability_start_date, availability_start_time, availability_start_zone) => {
    this.setState({
      availability_start_date,
      availability_start_time,
      availability_start_zone
    }, () => this.enableActionButton());
  }

  updateDateTimeEnd = (availability_end_date, availability_end_time, availability_end_zone) => {
    this.setState({
      availability_end_date,
      availability_end_time,
      availability_end_zone
    }, () => this.enableActionButton());
  }

  updateSelectedCollections = (collectionId) => {
    this.setState((prevState) => {
      let { allSelected } = prevState;
      let selectedCollections = [...prevState.selectedCollections];
      const existingCollections = [...prevState.existingCollections];

      if (collectionId === 'all-collections') {
        allSelected = !allSelected;
        selectedCollections = allSelected ? [...existingCollections] : [];
      } else {
        const checkedIndex = selectedCollections.findIndex((selectedCollection) => selectedCollection.id === collectionId);
        const clickedCollection = [...existingCollections].find((collection) => collection.id === collectionId);

        if (checkedIndex > -1) {
          selectedCollections.splice(checkedIndex, 1);
        } else {
          selectedCollections.push(clickedCollection);
        }

        allSelected = selectedCollections.length === existingCollections.length;
      }

      return { selectedCollections, allSelected };
    }, () => this.enableActionButton());
  }

  toggleNewCollection() {
    if (this.state.newCollection) {
      this.setState((prevState) => ({
        newCollection: false,
        displayCollectionSearch: prevState.existingCollections.length >= 10,
        new_collection_name: '',
        allSelected: false
      }), () => this.enableActionButton());
    } else {
      this.setState({
        newCollection: true,
        displayCollectionSearch: false,
        selectedCollections: []
      }, () => this.enableActionButton());
    }
  }

  handleAvailabilityDateValidityChange = (isValid) => {
    this.setState({ isAvailabilityDateValid: isValid });
  };

  handleExpirationDateValidityChange = (isValid) => {
    this.setState({ isExpirationDateValid: isValid });
  };

  toggleExpireAssets() {
    if (this.state.availabilitySet) {
      this.setState(
        {
          availabilitySet: false,
          isAvailabilityDateValid: true,
          isExpirationDateValid: true,
        },
        () => this.enableActionButton()
      );
    } else {
      this.setState({ availabilitySet: true }, () => this.enableActionButton());
    }
  }

  sortCheckboxes(collections) {
    return collections.sort((a, b) => {
      if (a.attributes.name.toUpperCase() > b.attributes.name.toUpperCase()) {
        return 1;
      }
      if (b.attributes.name.toUpperCase() > a.attributes.name.toUpperCase()) {
        return -1;
      }
      return 0;
    });
  }

  checkboxifyCollections() {
    return this.sortCheckboxes([...this.state.existingCollections]).map((collection) => {
      const checkbox = this.state.selectedCollections.find((selectedCollection) => (
        selectedCollection.id === collection.id
      ));
      const checked = (typeof checkbox) !== 'undefined';
      return { id: collection.id, checked, labelCopy: collection.attributes.name };
    });
  }

  renderRowTitle() {
    // TODO: don't rely on CSS for spacing, make the actual string spaced correctly for screen readers
    const { adminable, existingCollections } = this.state;
    const assetCount = this.props.selectedAssetKeys.size;

    if (!adminable && !existingCollections.length) { return null; }

    if (this.props.isSubCollectionModal) {
      return (
        <Plural
          one={
            <Trans>
              Add
              <span className="bold-text">
                {assetCount} asset
              </span>
              to one or more Subcollections
            </Trans>
          }
          other={
            <Trans>
              Add
              <span className="bold-text">
                {assetCount} assets
              </span>
              to one or more Subcollections
            </Trans>
          }
          value={assetCount}
        />
      );
    }

    if (existingCollections.length > 0) {
      return (
        <Plural
          one={
            <Trans>
              Add
              <span className="bold-text">
                {assetCount} asset
              </span>
              to one or more Collections
            </Trans>
          }
          other={
            <Trans>
              Add
              <span className="bold-text">
                {assetCount} assets
              </span>
              to one or more Collections
            </Trans>
          }
          value={assetCount}
        />
      );
    }

    return (
      <Plural
        one={
          <Trans>
            Add
            <span className="bold-text">
              {assetCount} asset
            </span>
            to a new Collection
          </Trans>
        }
        other={
          <Trans>
            Add
            <span className="bold-text">
              {assetCount} assets
            </span>
            to a new Collection
          </Trans>
        }
        value={assetCount}
      />
    );
  }

  renderRadioButtons() {
    if (!this.state.adminable || !this.state.existingCollections.length || BFG?.resource?.type === "collection") {
      return null;
    }

    const existingCollectionCount = this.state.existingCollections.length;

    return (
      <div className="radio-button-container">
        <label className="radio-row">
          <h4>
            <Plural
              one="Add to existing Collection"
              other="Add to existing Collections"
              value={existingCollectionCount}
            />
          </h4>
          <input
            checked={!this.state.newCollection}
            className="radio-input"
            name="radio"
            onChange={() => this.toggleNewCollection()}
            type="radio"
            value="add-to-existing-collection"
          />
          <span className="radio-dot" />
        </label>
        <label className="radio-row">
          <h4><Trans>Create new Collection</Trans></h4>
          <input
            checked={this.state.newCollection}
            className="radio-input"
            name="radio"
            onChange={() => this.toggleNewCollection()}
            type="radio"
            value="add-to-new-collection"
          />
          <span className="radio-dot" />
        </label>
      </div>
    );
  }

  renderCollectionSearch() {
    if (!this.state.displayCollectionSearch) { return null; }

    const hideCloseClass = this.state.collectionSearchValue === '' ? 'hide-close' : '';

    return (
      <div className="add-collection-search-container">
        <span className="icon bff-search" />
        <span
          className={`icon bff-close ${hideCloseClass}`}
          onClick={() => this.setState({ collectionSearchValue: '' })}
        />
        <input
          className="inputs add-collection-search"
          onChange={(e) => { this.setState({ collectionSearchValue: e.target.value }); }}
          placeholder={t`Search collections`}
          type="text"
          value={this.state.collectionSearchValue}
        />
      </div>
    );
  }

  renderCollectionList() {
    if (!this.state.existingCollections.length || this.state.newCollection) { return null; }

    const allCollectionsCheckbox = {
      checked: this.state.allSelected,
      id: 'all-collections',
      labelCopy: this.isSubCollectionModal
        ? t`All Subcollections`
        : t`All Collections`
    };
    const filteredCollections = this.checkboxifyCollections().filter((checkbox) => (
      checkbox?.labelCopy?.toUpperCase()?.includes(this.state.collectionSearchValue?.toUpperCase())
    ));

    const checkboxes = [
      ...(filteredCollections?.length > 1 ? [allCollectionsCheckbox] : []),
      ...filteredCollections,
    ];

    const containerClass = this.state.displayCollectionSearch ? 'fixed-height' : '';
    return (
      <div className={`collection-list-container ${containerClass}`}>
        <StandardCheckboxGroup
          checkboxes={checkboxes}
          name="available-collections"
          onChange={this.updateSelectedCollections}
        />
      </div>
    );
  }

  renderNewCollectionInput() {
    const { adminable, newCollection } = this.state;
    if (!adminable || !newCollection) { return null; }

    return (
      <div className="collection-selector-container">
        <label className="brandfolder-label new-resource-label">
          <Trans>Collection Name</Trans>
          <input
            className="inputs"
            onChange={(e) => {
              this.setState({ new_collection_name: e.target.value }, () => this.enableActionButton());
            }}
            type="text"
          />
        </label>
      </div>
    );
  }

  renderNoAccess() {
    if (!this.state.adminable && !this.state.existingCollections.length) {
      return (
        <h4 className="no-collections-copy">
          <Trans>
            It looks like this Brandfolder has no collections. Contact an administrator to create a Collection.
          </Trans>
        </h4>
      );
    }

    return null;
  }

  renderAvailabilitySelector() {
    if (!this.state.availabilitySet) { return null; }

    const {
      availability_start_date,
      availability_start_time,
      availability_start_zone,
      availability_end_date,
      availability_end_time,
      availability_end_zone } = this.state;

    return (
      <div className="expiration-container">
        <Trans>
          <h4 className="row-title">Set Publish Date (hide from collection
            <span className="bold-text">until</span>
            this date)
          </h4>
        </Trans>
        <DateTimePicker
          onDateValidityChange={this.handleAvailabilityDateValidityChange}
          selectedDate={availability_start_date}
          selectedTime={availability_start_time}
          selectedZone={availability_start_zone}
          updateDateTime={this.updateDateTimeStart}
        />
        <div className="horiz-line" />
        <h4 className="row-title">
          <Trans>
            Set Expiration Date (remove from collection
            <span className="bold-text">after</span>
            this date)
          </Trans>
        </h4>
        <DateTimePicker
          onDateValidityChange={this.handleExpirationDateValidityChange}
          openDirection={ListOpenDirections.Up}
          selectedDate={availability_end_date}
          selectedTime={availability_end_time}
          selectedZone={availability_end_zone}
          updateDateTime={this.updateDateTimeEnd}
        />
      </div>
    );
  }

  render() {
    const {
      adminable,
      availabilitySet,
      disableSubmitButton,
      existingCollections,
      isAvailabilityDateValid,
      isExpirationDateValid,
      isLoading
    } = this.state;
    const { closeModal } = this.props;
    const loaderActive = isLoading ? 'loader-active' : '';
    const submitButtonDisabled = disableSubmitButton || !isAvailabilityDateValid || !isExpirationDateValid;

    return (
      <div className="modal-content-wrapper bulk-collection-modal">
        <div className="modal-content-wrapper__header">
          <span aria-hidden="true" className="bff-plus icon" />
          <h3 className="modal-title">
            {this.renderRowTitle()}
          </h3>
          <button
            className="close-button"
            onClick={closeModal}
            type="button"
          >
            <span className="bff-close" />
          </button>
        </div>
        <div className={`modal-content-wrapper__body ${loaderActive}`}>
          {isLoading
            ? <BFLoader />
            : (
              <div>
                <div className="modal-row">
                  { this.renderRadioButtons() }
                  { this.renderCollectionSearch() }
                  { this.renderCollectionList() }
                  { this.renderNewCollectionInput() }
                  { this.renderNoAccess() }
                </div>
                {(adminable || existingCollections.length !== 0) && (
                  <>
                    <div className="horiz-line" />
                    <div className="modal-row">
                      <div className="checkbox-container">
                        <StandardCheckbox
                          checked={availabilitySet}
                          labelCopy={this.props.isSubCollectionModal
                            ? t`Set asset availability for Subcollections`
                            : t`Set asset availability for Collections`
                          }
                          onChange={() => this.toggleExpireAssets()}
                        />
                      </div>
                      { this.renderAvailabilitySelector() }
                    </div>
                  </>
                )}
                <div className={`button-container ${BFG.resource.type === "brandfolder" ? "button-container__two-buttons" : ""}`}>
                  {BFG.resource.type === "brandfolder" && (
                    <TextWarningButton
                      icon="bff-trash"
                      onClick={() => this.handleRemoveAll()}
                    >
                      <Trans>Remove from all Collections</Trans>
                    </TextWarningButton>
                  )}
                  <PrimaryButton
                    className="t-confirm-modal"
                    disabled={submitButtonDisabled}
                    isLoading={this.state.isSubmitting}
                    loadingCopy={t`Submitting`}
                    onClick={() => this.createCollectionAssociations()}
                    size="small"
                    type="button"
                  >
                    <Trans>Submit</Trans>
                  </PrimaryButton>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

BulkCollectionModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isSubCollectionModal: PropTypes.bool,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
};

BulkCollectionModal.defaultProps = {
  isSubCollectionModal: false,
};

const ModalComponent = renderModal(BulkCollectionModal, 'BulkCollectionModal');
export default ModalComponent;
