import { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';

export type TextFieldElement = InputHTMLAttributes<HTMLInputElement> | TextareaHTMLAttributes<HTMLTextAreaElement>

export enum InputType {
  Color = 'color',
  Date = 'date',
  DatetimeLocal = 'datetime-local',
  Email = 'email',
  File = 'file',
  Hidden = 'hidden',
  Month = 'month',
  Number = 'number',
  Password = 'password',
  Reset = 'reset',
  Search = 'search',
  Tel = 'tel',
  Text = 'text',
  Time = 'time',
  Url = 'url',
}

// TODO update styling to accomodate remaining 2 positions (right and bottom)
export enum LabelPosition {
  Left = 'label-left',
  Top = 'label-top',
}

export const isTextArea = (
  multiLine: boolean,
  textFieldProps: TextFieldElement // eslint-disable-line @typescript-eslint/no-unused-vars
): textFieldProps is TextareaHTMLAttributes<HTMLTextAreaElement> => (multiLine);

export const isInput = (
  multiLine: boolean,
  textFieldProps: TextFieldElement // eslint-disable-line @typescript-eslint/no-unused-vars
): textFieldProps is InputHTMLAttributes<HTMLInputElement> => (!multiLine);
