import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { PrimaryButton, TertiaryButton } from '@components/library/button';

interface SuccessProps {
  newAssetLink: string;
  singleFile: boolean;
}

export const SubmitSuccess: FunctionComponent<SuccessProps> = ({
  newAssetLink,
  singleFile,
}) => {
  // Ideally we should use a <Plural> here to accommodate different plural cases in other languages
  // but we only get the `singleFile` prop rather than the actual count of files uploaded
  const successCopy = singleFile
    ? <Trans>Your file has been successfully uploaded.</Trans>
    : <Trans>Your files have been successfully uploaded.</Trans>;

  const onVisitBrandfolder = (): void => {
    window.open(newAssetLink);
    window.close();
  };

  return (
    <div className="submit-success">
      <div className="submit-success__header">
        <span className="bff-confirmation icon" />
        <h4 className="submit-success__header--title"><Trans>Thank you!</Trans></h4>
      </div>
      <p className="submit-success__instructions">{successCopy}</p>
      <div className="submit-success__buttons">
        <TertiaryButton
          onClick={(): void => { window.close(); }}
          size="small"
        >
          <Trans>Done</Trans>
        </TertiaryButton>
        <PrimaryButton
          onClick={onVisitBrandfolder}
          size="medium"
        >
          <Trans>Visit the Brandfolder</Trans>
        </PrimaryButton>
      </div>
    </div>
  );
};
