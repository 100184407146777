import React from 'react';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ activeLabelKey, labelsFlat, onLabelSelect }) => {
  const activeLabelPath = labelsFlat[activeLabelKey].path;

  return activeLabelPath.map((labelKey, i) => {
    const lastLabel = (activeLabelPath.length - 1) === i;

    return (
      <React.Fragment key={labelKey}>
        <button
          className="select-breadcrumb-button"
          onClick={() => onLabelSelect(labelKey)}
          type="button"
        >
          {labelsFlat[labelKey].name}
        </button>
        {lastLabel ? null : <span className="bff-caret-down" />}
      </React.Fragment>
    );
  });
};

Breadcrumbs.propTypes = {
  activeLabelKey: PropTypes.string.isRequired,
  labelsFlat: PropTypes.shape({}).isRequired,
  onLabelSelect: PropTypes.func.isRequired,
};

export default Breadcrumbs;
