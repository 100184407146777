import { Plural, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import './styles/success-view.scss';

interface SuccessViewProps {
  assetCount: number;
  shareLink: string;
  shareLinkName: string;
}

export const SuccessView: FunctionComponent<SuccessViewProps> = ({
  assetCount,
  shareLink,
  shareLinkName,
}) => {
  return (
    <div className="submission-success">
      <h1 className="submission-success__title">
        <span className="bff-check-mark icon" />
        <Trans>Success!</Trans>
      </h1>
      <p
        className="submission-success__copy"
      >
        {/* TODO: don't rely on span css for spacing */}
        <Plural
          one={
            <Trans>
              <span>{assetCount}</span><span>asset</span><span>successfully added to Share Link:</span>
            </Trans>
          }
          other={
            <Trans>
              <span>{assetCount}</span><span>assets</span><span>successfully added to Share Link:</span>
            </Trans>
          }
          value={assetCount}
        />
        <a
          className="share-link-link"
          href={shareLink}
          rel="noreferrer noopener"
          target="_blank"
        >
          {shareLinkName}
        </a>
      </p>
    </div>
  );
};
