import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { create, update, remove } from '@api/v4/links';

class LinkForm extends React.Component {
  constructor(props) {
    super(props);

    const { url, name } = this.props;

    this.isNew = url === '' && name === '';

    this.state = {
      url,
      name,
      error: null,
    };
  }

  componentDidMount() {
    this.nameInput.focus();
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { name, url } = this.state;
    const { brandfolderKey, id } = this.props;
    // const action = this.isNew ? create : update;
    let promise;

    if (this.isNew) {
      promise = create(brandfolderKey, { name, url });
    } else {
      promise = update({ name, url, id });
    }

    promise.then(() => {
      this.props.onSubmit({ name, url });
    }).catch(({ errors }) => {
      this.setState({ error: errors });
    });
  }

  onDelete = () => {
    remove(this.props.id).then(this.props.onSubmit);
  }

  renderError() {
    const { error } = this.state;

    if (!error) { return undefined; }

    return (
      <p className="link-form__error">
        {error}
      </p>
    );
  }

  render() {
    const { name, url } = this.state;
    const { onCancel } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        {this.renderError()}
        <label className="brandfolder-label" htmlFor="link-name">
          <Trans>Link Name</Trans>
        </label>
        <input
          ref={(el) => { this.nameInput = el; }}
          className="brandfolder-input"
          name="link-name"
          onChange={({ target }) => this.setState({ name: target.value })}
          type="text"
          value={name}
        />

        <label className="brandfolder-label" htmlFor="link-url">
          <Trans>URL</Trans>
        </label>
        <input
          className="brandfolder-input"
          name="link-url"
          onChange={({ target }) => this.setState({ url: target.value })}
          type="text"
          value={url}
        />

        <div className="link-form__buttons">
          {this.isNew ? null : (
            <button
              className="button warning-hollow sm"
              onClick={this.onDelete}
              type="button"
            >
              <span
                aria-hidden="true"
                className="bff-trash"
              />
              <Trans>Delete</Trans>
            </button>
          )}
          <div className="link-form__buttons--main">
            <button
              className="button tertiary sm"
              onClick={onCancel}
              type="button"
            >
              <Trans>Cancel</Trans>
            </button>

            <button
              className="button primary sm"
              type="submit"
            >
              <Trans>Save</Trans>
            </button>
          </div>
        </div>
      </form>
    );
  }
}

LinkForm.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  id: PropTypes.string,
  brandfolderKey: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

LinkForm.defaultProps = {
  id: null,
  onCancel: () => {},
};

export default LinkForm;
