import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BFLoader } from '@components/common/loader/main';
import { Td } from './Td';
import { Tr } from './Tr';
import { BaseTrLoaderProps } from '../../base/BaseTableProps';

import classes from '../styles/standard-table.module.scss';

export const TrLoader: FunctionComponent<BaseTrLoaderProps> = (props) => {
  const { colSpan } = props;
  return (
    <Tr className={classnames('trLoader', 'standardTrLoader', classes.trLoader)} hover={false}>
      <Td colSpan={colSpan}>
        <div className={classes.loader}>
          <BFLoader />
        </div>
      </Td>
    </Tr>
  );
};
