/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { t, Trans } from '@lingui/macro';
import React, { useState, FunctionComponent, Dispatch, SetStateAction } from 'react';

import { StandardDialog, } from '@components/library/dialog';
import { ActionsDropdown, ActionsOpenDirections } from '@components/library/dropdown';
import { StandardSwitch } from '@components/library/switch/standard';
import {
  StandardTable,
  StandardTableColumn,
  StandardTableRow,
} from '@components/library/table';
import { MoreInfoTooltip } from '@components/library/tooltip/more-info';
import { Placements } from '@components/library/utils';

import { PrimaryButton, TertiaryButton } from '../library/button';

import { ActionDropdownItem } from '../library/dropdown_item';

import { DataSyncActionTypes } from './InsightsEnums';
import { BrandfoldersResponseObject, DataSyncStateMap, InsightsDataSyncResponseDatum } from './InsightsTypes';
import './styles/smartsheet-data-insights-table.scss';

const columns = (): StandardTableColumn[] => ([
  {
    heading: <Trans>Brandfolder Name</Trans>,
    rowKey: 'brandfolderName',
  },
  {
    heading: (
      <>
        <Trans>Data Sync Status</Trans>
        <MoreInfoTooltip
          iconSize={16}
          id="data-sync-status-heading"
          placement={Placements.TopCenter}
          tooltip="If sync is turned on, Brandfolder will being sending Insights data once per day at 5:00am MT."
        />
      </>
    ),
    rowKey: 'dataSyncStatus',
    width: '35%',
  },
  {
    heading: '',
    rowKey: 'dataSyncMenu',
    width: '5%',
  },
  {
    heading: (
      <>
        <Trans>Sync Data</Trans>
        <MoreInfoTooltip
          iconSize={16}
          id="data-sync-heading"
          placement={Placements.TopCenter}
          // eslint-disable-next-line max-len
          tooltip="Send daily Brandfolder asset and user data to separate automatically-generated sheets in Smartsheet. If you edit or remove content from your synced sheet, insights data may fail to sync."
        />
      </>
    ),
    rowKey: 'dataSyncSwitch',
    width: '10%',
  },
]);

interface InsightsDataSyncTableProps {
  brandfoldersData: BrandfoldersResponseObject[];
  dataSyncState: DataSyncStateMap;
  getTargetBrandfolderName: () => string;
  isAuthed: boolean;
  setDataSyncState: Dispatch<SetStateAction<DataSyncStateMap>>;
  setHasUserConfirmed: Dispatch<SetStateAction<boolean>>;
  setSyncActionType: Dispatch<SetStateAction<string>>;
  setTargetBrandfolderId: Dispatch<SetStateAction<string>>;
  targetBrandfolderId: string;
  turnOnSync: (id: string) => void;
  userSyncData: InsightsDataSyncResponseDatum;
}

const InsightsDataSyncTable: FunctionComponent<InsightsDataSyncTableProps> = ({
  brandfoldersData,
  dataSyncState,
  getTargetBrandfolderName,
  isAuthed,
  setDataSyncState,
  setHasUserConfirmed,
  setTargetBrandfolderId,
  setSyncActionType,
  targetBrandfolderId,
  turnOnSync,
  userSyncData
}) => {
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [renderResetDataDialog, setRenderResetDataDialog] = useState(false);
  const [isResetSyncDialogOpen, setIsResetSyncDialogOpen] = useState(false);
  const [renderTurnOffSyncDialog, setrenderTurnOffSyncDialog] = useState(false);
  const [isTurnOffSyncDialogOpen, setIsTurnOffSyncDialogOpen] = useState(false);

  let rows: StandardTableRow[] = [];

  const handleTurnOffSync = (id: string):void => {
    dataSyncState[id].isSyncing = false;
    setTargetBrandfolderId(id);
    setHasUserConfirmed(true);
    setIsTurnOffSyncDialogOpen(false);
  };

  const handleResetSync = (id: string): void => {
    setTargetBrandfolderId(id);
    setHasUserConfirmed(true);
    setIsResetSyncDialogOpen(false);
  };

  const displayTurnOffSyncDialog = (id: string):void => {
    setTargetBrandfolderId(id);
    setSyncActionType(DataSyncActionTypes.TurnOff);
    setrenderTurnOffSyncDialog(true);
    setIsTurnOffSyncDialogOpen(true);
  };

  const displayResetSyncDialog = (id: string): void => {
    setTargetBrandfolderId(id);
    setSyncActionType(DataSyncActionTypes.Reset);
    setRenderResetDataDialog(true);
    setIsResetSyncDialogOpen(true);
  };

  const handleDataSyncToggle = (id: string): void => {
    if (dataSyncState[id].isSyncing === true) {
      displayTurnOffSyncDialog(id);
    } else if (dataSyncState[id].isSyncing === false) {
      setDataSyncState({ ...dataSyncState });
      dataSyncState[id].isSyncing = true;
      turnOnSync(id);
    }
  };

  const navigateToDataInSmartsheet = ():void => {
    const defaultWorkspace = userSyncData.smartsheet_configuration.available_workspaces.find((workspace) => {
      return workspace.id.toString() === userSyncData.smartsheet_configuration.default_workspace_id;
    });
    window.open(
      defaultWorkspace.permalink,
      '_blank'
    );
  };

  const openOptionsMenuUp = brandfoldersData?.length - 1;

  rows = brandfoldersData?.map(({ id, attributes }, i) => ({
    brandfolderName: (
      <p>{attributes.name}</p>
    ),
    dataSyncStatus: (
      <div className="data-sync-status-container">
        {dataSyncState[id]?.syncStatus.statusIcon}
        {dataSyncState[id]?.syncStatus.statusText}
      </div>
    ),
    dataSyncMenu:
      dataSyncState[id]?.isSyncing ? (
        <ActionsDropdown
          className="data-sync--menu-container"
          closeCallback={(): void => setCloseDropdown(false)}
          id={id}
          openDirection={ openOptionsMenuUp === i ? ActionsOpenDirections.UpLeft : undefined}
          openOnClick={false}
          openOnHover
          toggleDropdown={closeDropdown}
          userDevice={BFG.currentUser.device}
        >
          <ActionDropdownItem
            key="reset-sync"
            icon="bff-add-page"
            isWarningItem={false}
            name="reset"
            onChoose={(): void => displayResetSyncDialog(id)}
          >
            <Trans>Reset data sheets</Trans>
          </ActionDropdownItem>
          <ActionDropdownItem
            key="view-in-smartsheet"
            icon="bff-new-tab"
            isWarningItem={false}
            name="view-in-smartsheet"
            onChoose={(): void => navigateToDataInSmartsheet()}
          >
            <Trans>View in Smartsheet</Trans>
          </ActionDropdownItem>
        </ActionsDropdown>
      ) : null,
    dataSyncSwitch: (
      // eslint-disable-next-line max-len
      <StandardSwitch id={`data-sync-switch--${id}`} isChecked={dataSyncState[id]?.isSyncing} onChange={(): void => handleDataSyncToggle(id)} value={id} ></StandardSwitch>
    )
  }));

  return (
    <>
      {!isAuthed || !userSyncData?.smartsheet_configuration?.default_workspace_id ? null :
        <section className="data-insights-table-container">
          <StandardTable
            caption={t`Your Brandfolder data and insights`}
            columns={columns()}
            id="brandfolder-insights-table"
            rows={rows}
          />
          {renderResetDataDialog && (
          <>
            <StandardDialog
              dialogBodyClassName="reset-data-sync-dialog-body"
              dialogHeaderClassName="reset-data-sync-dialog-header"
              height={350}
              id="reset-data-dialog"
              open={isResetSyncDialogOpen}
              setOpen={setIsResetSyncDialogOpen}
              showFooter={false}
              title={t`Reset Smartsheet Data Sheets`}
              width={720}
            >
              <div className="dialog-text-wrapper">
                <h3 className="dialog-text"><Trans>Are you sure you want to create a new sheet for your data?</Trans></h3>
                <p className="dialog-subtext"><Trans>The old sheet will no longer update</Trans></p>
              </div>
              <div className="dialog-button-wrapper">
                <TertiaryButton className="keep-existing-button" onClick={(): void => setIsResetSyncDialogOpen(false)}><Trans>Keep Existing</Trans></TertiaryButton>
                <PrimaryButton className="create-new-sheet-button" onClick={(): void => handleResetSync(targetBrandfolderId)}><Trans>Create New Data Sheet</Trans></PrimaryButton>
              </div>
            </StandardDialog>
          </>
          )}
          {renderTurnOffSyncDialog && (
          <>
            <StandardDialog
              dialogBodyClassName="stop-data-sync-dialog-body"
              dialogHeaderClassName="stop-data-sync-dialog-header"
              height={350}
              id="stop-data-sync"
              open={isTurnOffSyncDialogOpen}
              setOpen={setIsTurnOffSyncDialogOpen}
              showFooter={false}
              title={t`Stop data syncing`}
              width={720}
            >
              <div className="dialog-text-wrapper">
                <h3 className="dialog-text"><Trans>Are you sure you want to stop syncing Insights data to Smartsheet from {getTargetBrandfolderName()}?</Trans></h3>
                <p className="dialog-subtext"><Trans>The sheet will no longer update.</Trans></p>
              </div>
              <div className="dialog-button-wrapper">
                <TertiaryButton className="keep-syncing-button" onClick={(): void => setIsTurnOffSyncDialogOpen(false)}><Trans>Keep Syncing</Trans></TertiaryButton>
                <PrimaryButton className="turn-off-syncing-button" onClick={(): void => handleTurnOffSync(targetBrandfolderId)}><Trans>Turn Off Syncing</Trans></PrimaryButton>
              </div>
            </StandardDialog>
          </>
          )}
        </section>
      }
    </>
  );
};

export default InsightsDataSyncTable;
