import { findCurrentLanguageInMap, LanguageMapEntry, Locale } from '@components/common/language_menu/languagesMap';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';

export const getUgtLocaleSearchString = (): string => {
  const ugtLocaleParam = 'ugt_locale=';
  const searchString = window.location.search;
  const ugtLocaleIndex = searchString.indexOf(ugtLocaleParam);
  if (ugtLocaleIndex === -1) {
    const ugtLocale = determineUGTLocale();
    return ugtLocale ? `?ugt_locale=${determineUGTLocale()}` : '';
  }

  return `?${searchString.substr(ugtLocaleIndex, ugtLocaleParam.length + 2)}`;
};

export const getLanguageMapEntryFromSearchString = (): LanguageMapEntry | undefined => {
  const ugtParamString = getUgtLocaleSearchString();
  if (!ugtParamString) return undefined;

  const ugtLocale = ugtParamString.slice(-2) as Locale;
  const selectedLanguageEntry = findCurrentLanguageInMap(ugtLocale);

  return selectedLanguageEntry;
};
