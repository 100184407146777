import { FilestackCredentials, FilestackPolicyDecoded } from '@api/v4/private/resources/fs_creds';
import { FILESTACK_CREDENTIALS_PREFERENCE_KEY, getStorage, removeStorage, StorageTypes } from '@helpers/storage';

/**
 * Decode the base64 string created via filestack_security_object in app/helpers/filestack_helper.rb.
 * NOTE: Tried jwt-decode library but it fails since this isn't a fully formed JWT token.
 * @param policy string
 * @returns FilestackPolicyDecoded
 */
export const decodeFilestackPolicy = (policy: string): FilestackPolicyDecoded | null => {
  try {
    return JSON.parse(atob(policy));
  } catch (e) {
    return null;
  }
};

/**
 * Retrieve Filestack credentials from sessionStorage, if not expired.
 * @returns FilestackCredentials | null
 */
export const getFilestackCredentials = (): FilestackCredentials | null => {
  const creds = getStorage<FilestackCredentials>(StorageTypes.Session, FILESTACK_CREDENTIALS_PREFERENCE_KEY);
  if (creds) {
    const decoded = decodeFilestackPolicy(creds.policy);
    // JS creates a 13 digit unix timestamp
    // we need to match Ruby's 10 digit unix timestamp
    const now = Math.floor(Date.now() / 1000);
    // buffer for initializing then uploading
    const oneMinuteFromNow = now + 100;
    if (decoded && decoded.expiry > oneMinuteFromNow) {
      return creds;
    }
    removeStorage(StorageTypes.Session, FILESTACK_CREDENTIALS_PREFERENCE_KEY);
    return null;
  }
  return null;
};

/**
 * Get whether a URL is a Filestack URL.
 * @param url string | null | undefined
 * @returns boolean
 */
export const getIsFilestackUrl = (url: string | null | undefined): boolean => {
  return url && (
    url.includes('fs.brandfolder.com') ||
    url.includes('filepicker.io') ||
    url.includes('fp.brandfolder.com') ||
    url.includes('filestackcontent.com')
  );
};

/**
 * Get whether a URL is a signed Filestack URL.
 * @param url string | null | undefined
 * @returns boolean
 */
export const getIsFilestackUrlSigned = (url: string | null | undefined): boolean => {
  return url && url.includes('signature=');
};

/**
 * Get whether a URL is a Filestack URL and is signed.
 * @param url string | null | undefined
 * @returns boolean
 */
export const getIsFilestackUrlAndSigned = (url: string | null | undefined): boolean => {
  return getIsFilestackUrl(url) && getIsFilestackUrlSigned(url);
};

/**
 * Get file handle from a filestack URL.
 * @param url string
 * @returns string
 */
export const getFilestackHandleFromUrl = (url: string): string | null | undefined => {
  if (getIsFilestackUrl(url)) {
    const uri = new URL(url)
    const handle = uri.pathname.split("/")[1]
    if (handle) {
      return handle
    }
  }
};