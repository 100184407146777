import { StandardText, brandfolderColorPalette } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

import { BackButton } from '../common/BackButton';
import { PoweredByAiBadge } from '../common/PoweredByAiBadge';
import { libraryTitle } from '../constants';

const PeoplePageHeaderWrapper = styled.div`
  margin: 40px 0;
`;
const PageTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 24px;

  h1 {
    margin-right: 6px;
  }
`;
const Subtext = styled(StandardText)`
  p {
    color: ${brandfolderColorPalette.neutrals[700]};
    font-weight: 450;
    line-height: 20px;
  }
`;
const TitleDivider = styled.hr`
  margin: 40px 0;
`;

export const PeoplePageHeader: FunctionComponent = () => (
  <PeoplePageHeaderWrapper>
    <PageTitleWrapper>
      <h1>
        <Trans>People</Trans>
      </h1>
      <PoweredByAiBadge />
    </PageTitleWrapper>
    <Subtext>
      <Trans>People from this {libraryTitle}'s photos are added to this gallery once a day.</Trans>
    </Subtext>
    <TitleDivider />
    <BackButton href={`/${BFG.brandfolder_slug}`} />
  </PeoplePageHeaderWrapper>
);
