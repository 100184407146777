import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { StandardAvatarsProps } from '@components/library/avatars';
import { BaseAvatars } from '../base';

import classes from './styles/standard-avatars.module.scss';

export const StandardAvatars: FunctionComponent<StandardAvatarsProps> = (props) => (
  <BaseAvatars
    {...props}
    className={classnames(
      classes.avatars,
      props.className
    )}
    overflowAvatarProps={{
      ...props.overflowAvatarProps,
      className: classnames(
        classes.avatar,
        props.overflowAvatarProps && props.overflowAvatarProps.className
      )
    }}
    standardAvatarProps={{
      ...props.standardAvatarProps,
      className: classnames(
        classes.avatar,
        props.standardAvatarProps && props.standardAvatarProps.className
      ),
      triggerOffset: props.standardAvatarProps?.triggerOffset || 5
    }}
    variantName="standard"
  />
);
