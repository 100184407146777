import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import fetchJSON from '@api/api_helper';
import getAsset from '@api/v4/assets/assets';
import FetchControllersWrapper from '@components/common/fetch_controllers_wrapper';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { getPdfAttachment } from '@components/gelato';

import Web2PrintModal from './web_2_print_modal';

import './styles/web_2_print.scss';

export default class MenuButton extends React.Component {
  state = {
    attachment: { filename: undefined, url: undefined },
    generatingPdf: false,
    pagesOverride: 1,
    productUid: '',
    retries: 0,
    showPrintToGelato: false,
    showModal: false
  }

  componentDidMount() {
    // isGelato comes from either:
    // app/views/assets/generic_files/show.html.slim
    // app/views/printui_editor/editor_preview.html.slim
    const { isGelato } = this.props;

    if (isGelato) {
      this.getAssetAndAttachments();
    }
  }

  resetShowModal = () => {
    this.setState({ showModal: false });
  }

  renderModal = () => (
    <FetchControllersWrapper>
      <Web2PrintModal
        assetKey={this.props.assetKey}
        digest={this.props.digest}
        imageUrls={this.props.imageUrls}
        launchedFromModal={this.props.launchedFromModal}
        pdfUrl={this.props.pdfUrl}
        resetShowModal={this.resetShowModal}
      />
    </FetchControllersWrapper>
  )

  handleShowModal = () => {
    if (this.props.launchedFromModal) {
      BF.fx.dispatchWindowEvent('launchWebToPrint', null, { assetKey: this.props.assetKey });
    } else {
      this.setState((prevState) => ({ showModal: !prevState.showModal }));
    }
  }

  handlePrintUiToast = (message = '') => {
    const downloadIndicator = document.querySelectorAll('.download_progress_indicator');
    const indicatorText = document.querySelectorAll('.download_progress_indicator span');

    if (downloadIndicator[0] && indicatorText[0]) {
      indicatorText[0].textContent = message;

      if (message) {
        downloadIndicator[0].setAttribute('style', 'visibility: visible');
        downloadIndicator[0].classList.add('active');
      } else {
        downloadIndicator[0].setAttribute('style', 'visibility: hidden');
        downloadIndicator[0].classList.remove('active');
      }
    }
  }

  // if handleGelatoFromPrintUiEditor changes, you'll also want to change checkDownload in ./app/views/printui_editor/editor_preview.html.slim
  handleGelatoFromPrintUiEditor = async () => {
    const { assetKey, assetName, client, downloadPreset, gelatoBrandedStoreUrl, jobId } = this.props;
    const { attachment, retries } = this.state;

    try {
      this.setState({
        generatingPdf: true
      },
        () => {
          this.handlePrintUiToast(t`Hang tight! We're building your PDF`);
        });

      const url = `/editor/request_and_download_template?asset_key=${assetKey}&client=${client}&download_preset=${downloadPreset}&job_id=${jobId}&name=${assetName}&type=pdf`;
      const response = await fetchJSON(url);

      if (response.ready_for_download === true) {
        // done processing, pdf has been created
        // either use the same url but tack on ?return_file=true IF uses old style url
        // OR use printui getresult file url IF uses new style url
        this.setState({
          attachment: { ...attachment, url: response.file_url }
        },
          () => {
            this.handlePrintUiToast();
            this.openGelato();

            this.setState({
              generatingPdf: false,
              retries: 0
            });
          });
      } else if (retries < 10) {
        setTimeout(() => {
          this.setState({
            retries: retries + 1
          },
            () => {
              this.handleGelatoFromPrintUiEditor();
              this.handlePrintUiToast(t`Your PDF is still processing.`);
            });
        }, 5000);
      } else {
        throw Error;
      }
    } catch (err) {
      this.setState({
        generatingPdf: false,
        retries: 0
      },
        () => {
          this.handlePrintUiToast(t`Error building your PDF`);
        });
    }
  }

  openGelato = () => {
    const { gelatoBrandedStoreUrl } = this.props;
    const { attachment, pagesOverride, productUid } = this.state;

    const gelatoUrl = gelatoBrandedStoreUrl
    // /headless/import is the new express checkout, its urls are like "https://brandfolder.gelato.com"
    // /new-app/v2/headless/import is the old experience, its urls are like ""https://brandfolder.gelatoglobe.com" -- This has been deprecated with Ciloo
    const apiRoute = '/headless/import';
    const filename = `?filename=${encodeURIComponent(attachment.filename)}`;
    const product = `&productUid=${productUid}`;
    const url = `&url=${encodeURIComponent(attachment.url)}`;
    const source = `&source=${encodeURIComponent(window.location.href)}`;
    const pages = `&pages=${encodeURIComponent(pagesOverride)}`
    const windowOpen = `${gelatoUrl}${apiRoute}${filename}${product}${url}${source}${pages}`;

    window.open(windowOpen, '_blank');
  }

  getAssetAndAttachments = async () => {
    const { assetKey, assetName, client, downloadPreset, isGelato, jobId, launchedFromModal } = this.props;

    try {
      const asset = await getAsset({ assetKey, fields: 'data', include: 'attachments' });

      let attachment = { filename: '', key: '', url: '', pages: 1 };

      const productUid = asset?.data?.attributes?.data?.gelato_product_uid || '';
      let pagesOverride = asset?.data?.attributes?.data?.gelato_pages_override;

      if (assetName) {
        attachment.filename = assetName;
      } else {
        const includes = asset.included.filter((include) => include.type === 'attachments');
        // get the first PDF from the array of attachment includes
        attachment = getPdfAttachment(includes);

        // we only need to call this if we don't have a manually entered pagesOverride
        if (attachment && attachment.key && !pagesOverride && productUid) {
          const attachmentDetails = await fetchJSON(`/api/v4/attachments/${attachment.key}`, { params: { fields: 'best_metadata' } });
          pagesOverride = parseInt(attachmentDetails?.data?.attributes?.best_metadata?.page_count, 10) || 1;
        }
      }

      // sending to gelato from the asset modal
      if (isGelato && launchedFromModal && attachment.filename && attachment.url && productUid) {
        this.setState({ attachment, pagesOverride, productUid, showPrintToGelato: true });
      } else if (client && downloadPreset && isGelato && jobId && !launchedFromModal && attachment.filename && productUid) {
        // sending to gelato from the print ui editor
        this.setState({ attachment, pagesOverride, productUid, showPrintToGelato: true });
      } else {
        // gelato_product_uid most likely not set
        this.setState({ showPrintToGelato: false });
      }
    } catch (err) {
      this.setState({ showPrintToGelato: false });
    }
  }

  render() {
    const { generatingPdf, showModal, showPrintToGelato } = this.state;
    const { isGelato, launchedFromModal } = this.props;

    let title = isGelato ? t`Send to Ciloo` : t`Send Print Request`;
    if (generatingPdf) {
      title = t`Processing...`;
    }

    // isGelato && launchedFromModal is sending to gelato from the asset modal (asset is ready to go and we can call openGelato)
    // isGelato && !launchedFromModal is sending to gelato from the print ui editor (we need to build the pdf first before calling openGelato)
    // else is send print request from the print ui editor
    let onClick = this.handleShowModal;
    if (isGelato && launchedFromModal) {
      onClick = this.openGelato;
    } else if (isGelato && !launchedFromModal) {
      onClick = this.handleGelatoFromPrintUiEditor;
    }

    return (
      <I18nProviderWrapper>
        {
          (!isGelato || (isGelato && showPrintToGelato)) && (
            <button
              className={`select-button ${launchedFromModal ? 'button secondary' : ''}`}
              disabled={generatingPdf}
              onClick={onClick}
              title={title}
              type="button"
            >
              <div className="s-bulk-select-action">
                <div className="s-bulk-selection-bubble">
                  <span className="bff-print icon" />
                </div>
                <div className={`s-bulk-select-label print-button-copy ${isGelato ? 'print-label' : ''}`}>
                  {isGelato ? title : ''}
                </div>
              </div>
            </button>
          )
        }
        {showModal ? this.renderModal() : null}
      </I18nProviderWrapper>
    );
  }
}

MenuButton.propTypes = {
  assetKey: PropTypes.string.isRequired,
  assetName: PropTypes.string,
  client: PropTypes.string,
  digest: PropTypes.string,
  downloadPreset: PropTypes.string,
  gelatoBrandedStoreUrl: PropTypes.string,
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  isGelato: PropTypes.bool,
  jobId: PropTypes.string,
  launchedFromModal: PropTypes.bool,
  pdfUrl: PropTypes.string
};

MenuButton.defaultProps = {
  assetName: null,
  client: null,
  digest: '',
  downloadPreset: null,
  gelatoBrandedStoreUrl: '',
  imageUrls: null,
  isGelato: false,
  jobId: null,
  launchedFromModal: false,
  pdfUrl: null
};
