import { t, Trans } from '@lingui/macro';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isGettyClient } from '@helpers/getty-strings';

import Checkbox from '../../common/checkbox/main';

export default class MessageUsersStage1 extends Component {
  state = {
    searchInput: ''
  }

  createOptionsForSelect(options) {
    return options.map((option) => ({ value: option, label: option }));
  }

  sortResources(resourcesObject) {
    const resourceKeys = Object.keys(resourcesObject);
    const resourceArraySorted = resourceKeys.map((key) => [key, resourcesObject[key].name]);

    resourceArraySorted.sort((a, b) => this.sortHelper(a, b));

    return resourceArraySorted;
  }

  sortHelper(a, b) {
    if (a[1] < b[1]) {
      return -1;
    }
    if (a[1] > b[1]) {
      return 1;
    }
    return 0;
  }

  updateRecipients(clickedKey) {
    if (clickedKey) {
      let selectedRecipients = this.props.recipients;
      const { recipients, updateMessageState } = this.props;
      // remove or add clicked level to selectedRecipients
      if (recipients.includes(clickedKey)) {
        selectedRecipients = selectedRecipients.filter((level) => level !== clickedKey);
      } else {
        selectedRecipients.push(clickedKey);
      }

      updateMessageState({ recipients: selectedRecipients });
    }
  }

  searchInputClass() {
    return this.state.searchInput === '' ? 'search-input-empty' : '';
  }

  brandfolderNoAccessClass(brandfolderKey) {
    return this.props.resources[brandfolderKey].permitted ? '' : 'no-access';
  }

  carrotHiddenClass(collections) {
    return collections ? '' : 'hide-carrot';
  }

  expandedClass(brandfolderKey) {
    return this.props.expandedBrandfolders[brandfolderKey] ? 'expanded' : '';
  }

  renderCollections(collections, parentKey) {
    const { selectedResources, toggleAllCollections, toggleResource } = this.props;
    const { searchInput } = this.state;
    const collectionData = [];
    const collectionArraySorted = this.sortResources(collections);

    collectionArraySorted.forEach((collectionKeyNameArray) => {
      const collectionKey = collectionKeyNameArray[0];
      const collection = collections[collectionKey];

      // Apply search criteria
      if (collection.name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1) {
        collectionData.push(
          <div key={collectionKey} className="collection-select-row select-row">
            <Checkbox
              checked={!!selectedResources[collectionKey]}
              datakey={collectionKey}
              labelHtml={<h4>{collection.name}</h4>}
              size="md"
              toggle={(e) => toggleResource(e.target.getAttribute('data-key'))}
            />
          </div>
        );
      }
    });

    if (collectionData.length) {
      const titleContainer = (
        <div key="0" className="resource-title-container collection-title-container">
          <h6 className="resource-title">COLLECTIONS</h6>
          <h6 className="select-all-collections" onClick={() => toggleAllCollections(collections, parentKey, "selectAll")}>Select All</h6>
          <h6 className="title-seperator">|</h6>
          <h6 className="deselect-all-collections" onClick={() => toggleAllCollections(collections, parentKey, "deselectAll")}>Deselect All</h6>
        </div>
      );

      collectionData.splice(0, 0, titleContainer);
    }

    return collectionData;
  }

  renderDataTree() {
    const { expandedBrandfolders, resources, selectedResources, toggleResource, toggleTreeExpand, libraryName } = this.props;
    const { searchInput } = this.state;
    const searchCopy = searchInput.toLowerCase();
    const treeData = [];
    const resourceArraySorted = this.sortResources(resources);

    if (!resourceArraySorted.length) {
      return isGettyClient()
        ? <h4><Trans>No Libraries or Collections found for you to manage</Trans></h4>
        : <h4><Trans>No Brandfolders or Collections found for you to manage</Trans></h4>;
    }

    resourceArraySorted.forEach((resourceKeyNameArray) => {
      const brandfolderKey = resourceKeyNameArray[0];
      const brandfolder = resources[brandfolderKey];
      const brandfolderName = brandfolder.name.toLowerCase();
      const collections = brandfolder.collections;
      const collectionsDisabled = selectedResources[brandfolderKey] ? 'collections-disabled' : '';
      let renderBrandfolder = true;
      let renderCollection = false;
      let brandfolderChildSelected = '';

      // apply brandfolder view criteria
      if (brandfolderName.indexOf(searchCopy) === -1) {
        renderBrandfolder = false;
      }

      if (collections) {
        if (searchCopy) {
          // collapse brandfolder if collection is not in search
          delete expandedBrandfolders[brandfolderKey];
        }

        Object.keys(collections).forEach((collectionKey) => {
          const collectionName = collections[collectionKey].name.toLowerCase();

          if (searchCopy && collectionName.indexOf(searchCopy) > -1) {
            // render brandfolder if at least one collection matches search value
            renderCollection = true;
            expandedBrandfolders[brandfolderKey] = true;
          }

          // brandfolder is not selected AND at least one child is selected
          if (!selectedResources[brandfolderKey]
              && selectedResources[collectionKey]
              && selectedResources[collectionKey].parent_key === brandfolderKey) {
            brandfolderChildSelected = 'brandfolder-child-selected';
          }
        });
      }

      if (renderBrandfolder || renderCollection) {
        treeData.push(
          <div
            key={brandfolderKey}
            className={`brandfolder-select-container select-container
                        ${this.expandedClass(brandfolderKey)} ${brandfolderChildSelected}`}
          >
            <div className={`brandfolder-select-row select-row
                            ${this.brandfolderNoAccessClass(brandfolderKey)}`}
            >
              <span
                className={`bff-caret-down icon ${this.carrotHiddenClass(collections)}`}
                data-key={brandfolderKey}
                onClick={(e) => {
                  this.setState({ searchInput: '' });
                  toggleTreeExpand(e.target.getAttribute('data-key'));
                }}
              />
              <Checkbox
                checked={!!selectedResources[brandfolderKey]}
                datakey={brandfolderKey}
                labelHtml={<h4>{brandfolder.name}</h4>}
                size="md"
                toggle={(e) => toggleResource(e.target.getAttribute("data-key"))}
              />
            </div>
            {collections
              ? (
                <div className={`collection-select-container select-container
                                ${collectionsDisabled}`}
                >
                  {this.renderCollections(brandfolder.collections, brandfolderKey)}
                </div>
              ) : null
            }
          </div>
        );
      }
    });

    if (treeData.length) {
      const titleContainer = (
        <div key="0" className="resource-title-container brandfolder-title-container">
          <h6 className="resource-title">{pluralize(libraryName).toUpperCase()}</h6>
        </div>
      );

      treeData.splice(0, 0, titleContainer);
    } else {
      treeData.push(<h4 key="0" className="search-results-empty active">No results found for "{`${searchInput}`}"</h4>);
    }

    return treeData;
  }

  renderRecipientCheckboxes() {
    const { recipients } = this.props;
    const permissionLevels = ['Admin', 'Collaborator', 'Guest'];
    return permissionLevels.map((level) => (
      <div key={`user-permission-option-${Math.random()}`} className="select-row">
        <Checkbox
          checked={recipients.includes(level)}
          datakey={level}
          labelHtml={<h4>{`${level}s`}</h4>}
          size="md"
          toggle={(e) => { this.updateRecipients(e.target.getAttribute('data-key')); }}
        />
      </div>
    ));
  }

  render() {
    if (this.props.stage !== 1) { return null; }

    const { cancelMessage, updateMessageState, submitted, recipients, selectedResources, libraryName } = this.props;
    const { searchInput } = this.state;
    return (
      <React.Fragment>
        <div className={`section-body body-select-resources ${this.searchInputClass()}`}>
          <h3 className="section-title permission-title"><Trans>Select Recipients</Trans></h3>
          <a
            href="https://help.smartsheet.com/14948317521303-Messaging-Users"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="bff-tool-tip" />
          </a>
          <div className="section-title-container">
            <div className="permission-select-container full-width">
              <div className="user-permission-select-container select-container">
                {this.renderRecipientCheckboxes()}
              </div>
            </div>
          </div>
          <div className="resource-search-container">
            <h3 className="section-title">Select {libraryName}(s) or Collection(s)</h3>
            <div className="resource-search">
              <input
                className="inputs"
                onChange={(e) => this.setState({ searchInput: e.target.value })}
                placeholder={t`Search`}
                type="text"
                value={searchInput}
              />
              <span className="bff-search icon" />
              <span
                className="bff-close icon"
                onClick={() => this.setState({ searchInput: '' })}
              />
            </div>
          </div>
          <div className="resource-select-container">
            <h4 className="search-input-feedback">
              Showing results for "{`${searchInput}`}" ...
            </h4>
            { this.renderDataTree() }
          </div>
        </div>
        <div className="button-container">
          <button
            className="button tertiary sm"
            onClick={() => cancelMessage()}
            type="button"
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            className="button primary lg"
            disabled={submitted || (recipients.length < 1)
              || (Object.keys(selectedResources).length < 1)}
            onClick={() => updateMessageState({ stage: 2 })}
            type="button"
          >
            <Trans>Compose Message</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

MessageUsersStage1.defaultProps = {
  libraryName: "Brandfolder"
};

MessageUsersStage1.propTypes = {
  libraryName: PropTypes.string,
  resources: PropTypes.objectOf(PropTypes.object).isRequired,
  recipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedResources: PropTypes.objectOf(PropTypes.object).isRequired,
  stage: PropTypes.number.isRequired,
  submitted: PropTypes.bool.isRequired,
  updateMessageState: PropTypes.func.isRequired,
  expandedBrandfolders: PropTypes.objectOf(PropTypes.bool).isRequired,
  toggleAllCollections: PropTypes.func.isRequired,
  toggleResource: PropTypes.func.isRequired
};
