import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import getAssets from '@api/v4/assets/assets';
import getAttachments from '@api/v4/assets/attachments';
import getReservations from '@api/v4/attachments/reservations';
import AttachmentSidebar from '@components/asset/modal/tabs/shared/attachment_sidebar/main';
import {
  shouldDisplayAssetHistory
} from '@components/asset/modal/tabs/workflow/asset_details/asset-history-log';
import {
  WorkflowAssetDetailsTabs
} from '@components/asset/modal/tabs/workflow/asset_details/WorkflowAssetDetailsTabs';
import AttachmentDetails from '@components/asset/modal/tabs/workflow/attachment_details/main';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { BFLoader } from '@components/common/loader/main';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';

const displayAssetCardInSidebar = BFG.hasFeature('asset_approvals')
  || BFG.hasFeature('asset_availability') || shouldDisplayAssetHistory();
const assetOptions = (assetKey) => ({
  assetKey,
  fields: 'availability,availability_end,availability_start,active_cdn_tracers,thumbnail_url'
});

const attachmentOptions = (assetKey) => ({
  assetKey,
  params: { fields: 'download_url,thumbnail_url,extension,thumbnailed' }
});

const WorkflowTab = (props) => {
  const { assetKey, approver, displayAssetStatus, isGuest } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [asset, setAsset] = useState(null);
  const [attachments, setAttachments] = useState(null);
  const [activeCard, setActiveCard] = useState(null);
  const [fetchControllers, setFetchControllers] = useState([]);
  const [historyLogInitial, setHistoryLogInitial] = useState(false);
  const [initialFetchControllers, setInitialFetchControllers] = useState([]); // initial page load fetches
  const [attachmentReservations, setAttachmentReservations] = useState(null);

  const updateFetchControllers = (fetchController) => {
    setFetchControllers((prevFetchControllers) => ([...prevFetchControllers, fetchController]));
  };

  // separate method because clicking different attachments shouldn't cancel these fetches
  const updateInitialFetchControllers = (fetchController) => {
    setInitialFetchControllers((prevFetchControllers) => ([...prevFetchControllers, fetchController]));
  };

  const abortFetchControllers = (initial = null) => {
    (initial ? initialFetchControllers : fetchControllers).forEach((fetchController) => {
      if (!fetchController.signal.aborted) {
        fetchController.abort();
      }
    });
  };

  const retrieveReservations = () => {
    const reservationFetches = [];
    const attachmentKeys = [];

    attachments.forEach((attachment) => {
      const options = {
        attachmentKey: attachment.id,
        params: { include: 'user' }
      };
      reservationFetches.push(getReservations(options, updateInitialFetchControllers));
      attachmentKeys.push(attachment.id);
    });

    Promise.all(reservationFetches)
      .then((responses) => {
        if (responses) {
          const reservations = {};
          responses.forEach((response, i) => { reservations[attachmentKeys[i]] = response; });
          setAttachmentReservations(reservations);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateActiveCard = (updatedActiveCard) => {
    abortFetchControllers();
    setActiveCard(updatedActiveCard);
  };

  const refreshAsset = () => {
    getAssets(assetOptions(assetKey))
      .then((response) => {
        if (response.data) {
          setAsset(response.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const initializeTab = () => {
    Promise.all([
      getAssets(assetOptions(assetKey), updateInitialFetchControllers),
      getAttachments(attachmentOptions(assetKey), updateInitialFetchControllers),
    ])
      .then((responses) => {
        const [assetResponse, attachmentsResponse] = responses;

        if (assetResponse.data) {
          setAsset(assetResponse.data);
        }

        if (attachmentsResponse.data) {
          const sortedAttachments = attachmentsResponse.data.sort((a, b) => (
            a.attributes.position - b.attributes.position
          ));
          setAttachments(sortedAttachments);
        }

        if (attachmentsResponse?.data?.length > 0 && !historyLogInitial) {
          setActiveCard({ id: attachmentsResponse.data[0].id, type: 'attachment' });
        } else if (displayAssetCardInSidebar) {
          setActiveCard({ id: assetResponse.data.id, type: 'asset' });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    sendAction(TrackedAction.CommentsAndAnnotations);
    initializeTab();
    window.addEventListener('openAssetHistoryLog', () => {
      setHistoryLogInitial(true);
    });

    return () => {
      abortFetchControllers(true); // only abort initial page load fetches when unmounting
      abortFetchControllers();
      window.removeEventListener('openAssetHistoryLog', () => {
        setHistoryLogInitial(true);
      });
    };
  }, []);

  useEffect(() => {
    if (isGuest) {
      setAttachmentReservations({});
    } else if (attachments) {
      retrieveReservations();
    }
  }, [attachments]);

  useEffect(() => {
    if (historyLogInitial && asset && attachments) {
      handleUpdateActiveCard({ id: asset?.id, type: 'asset' });
    }
  }, [asset, attachments, historyLogInitial]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (asset && attachments && activeCard?.type === 'attachment') {
      // reset state if we're done automatically selecting activeCard
      setHistoryLogInitial(false);
    }
  }, [asset, attachments, activeCard]);

  const isAssetSectionReady = !isLoading && !isGuest && asset && activeCard?.type === 'asset';
  const isAttachmentSectionReady = !isLoading && attachments && activeCard?.type === 'attachment' && (isGuest || attachmentReservations);

  return (
    <I18nProviderWrapper>
      <div className="workflow-tab-container">
        <AttachmentSidebar
          activeCard={activeCard}
          asset={asset}
          attachmentReservations={attachmentReservations || {}}
          attachments={attachments}
          displayAssetCard={displayAssetCardInSidebar && !isGuest}
          isGuest={isGuest}
          updateActiveCard={handleUpdateActiveCard}
        />
        {(!isAssetSectionReady && !isAttachmentSectionReady) && (
          <BFLoader />
        )}
        {isAssetSectionReady && (
          <WorkflowAssetDetailsTabs
            assetKey={asset.id}
            historyLogInitial={historyLogInitial}
            workflowAssetDetailsProps={{
              approver,
              asset,
              displayAssetStatus,
              refreshAsset,
              setAsset,
              updateFetchControllers
            }}
          />
        )}
        {isAttachmentSectionReady && (
          <AttachmentDetails
            abortFetchControllers={abortFetchControllers}
            activeAttachment={attachments.find((attachment) => (attachment.id === activeCard.id))}
            activeCard={activeCard}
            asset={asset}
            attachmentReservations={attachmentReservations}
            initializeTab={initializeTab}
            isGuest={isGuest}
            setAttachmentReservations={setAttachmentReservations}
            setAttachments={setAttachments}
            updateFetchControllers={updateFetchControllers}
          />
        )}
      </div>
    </I18nProviderWrapper>
  );
};

WorkflowTab.propTypes = {
  assetKey: PropTypes.string.isRequired,
  approver: PropTypes.bool.isRequired,
  displayAssetStatus: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool.isRequired,
};

export default WorkflowTab;
