import fetchJSON from '@api/api_helper';
import { Locale } from '@components/common/language_menu/languagesMap';

export interface BulkTagResponse {
  result: string;
}

const url = (): string => '/api/v4/bulk_actions/assets/tag?queue_priority=high';

interface BulkTagData {
  asset_keys: string[];
  bulk_tag: {
    existing_tag_names_array?: string[];
    existing_tag_names?: string;
    submitted_tag_names_array?: string[];
    submitted_tag_names?: string;
  };
}

const bulkTag = (
  data: BulkTagData,
  updateFetchControllers: () => void,
  ugtLocale: Locale | '' = '',
): Promise<BulkTagResponse> => {
  const appendedOptions = {
    method: 'PUT',
    body: {
      data
    },
    params: { ugt_locale: ugtLocale },
  };

  return fetchJSON(url(), appendedOptions, updateFetchControllers);
};

export default bulkTag;
