import { t } from '@lingui/macro';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { DetailedUser, IncludedUserPermission, UserRoles } from '@api/v4/private/resources/users';
import { PendingInvitations } from '@api/v4/users/pending_invitations';
import {
  Brandfolder,
  Collection,
  ResourceType,
  ServerResourceType,
  RemoveAllTextResourceType
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';
import { ListOption } from '@components/library/dropdown';
import { REUSABLE_EMAIL } from '@helpers/emails';
import { isGettyClient } from '@helpers/getty-strings';

export const getUserPermissionFromUser = (
  user: ApiResponseObject<DetailedUser, 'users'>,
): IncludedUserPermission => (
  user.attributes.resource_user_permission.data.attributes
);

/* excluding Owner since it's only a permission level at the organization level */
export const userPermissionOptions = (): ListOption[] => [{
  label: t`Guest`,
  value: UserRoles.Guest
}, {
  label: t`Collaborator`,
  value: UserRoles.Collaborator
}, {
  label: t`Admin`,
  value: UserRoles.Admin
}];

export const userPermissionPluralOptions = (): ListOption[] => [{
  label: t`Guests`,
  value: UserRoles.Guest
}, {
  label: t`Collaborators`,
  value: UserRoles.Collaborator
}, {
  label: t`Admins`,
  value: UserRoles.Admin
}];

export const getBrandfolderCollectionKeys = (selectedBrandfolderKey: string, brandfolders: Brandfolder[]): string[] => {
  const selectedBrandfolder = brandfolders.find((bf) => bf.key === selectedBrandfolderKey);
  const collectionKeys = [];
  if (!selectedBrandfolder) return collectionKeys;
  selectedBrandfolder.collections?.forEach((collection) => {
    collectionKeys.push(collection.key);
    collection.subcollections?.forEach((subcollection) => {
      collectionKeys.push(subcollection.key);
    });
  });
  selectedBrandfolder.workspaces?.forEach((workspace) => {
    collectionKeys.push(workspace.key);
  });
  return collectionKeys;
};

export const getCollectionChildKeys = (selectedCollectionKey: string, brandfolders: Brandfolder[]): string[] => {
  let subcollectionKeys = [];
  brandfolders.forEach((brandfolder) => {
    const selectedCollection = brandfolder.collections.find((collection) => collection.key === selectedCollectionKey);

    if (selectedCollection) {
      subcollectionKeys = selectedCollection.subcollections.map(({ key }) => key);
      return;
    }
  });

  return subcollectionKeys;
};

export const collectionOrChildSelected = (
  collection: Collection,
  selectedKey: string
): boolean => {
  if (collection.key === selectedKey) return true;

  const child = collection.subcollections?.find((sc) => sc.key === selectedKey);
  return !!child;
};

export const brandfolderOrDescendantSelected = (
  brandfolder: Brandfolder,
  selectedKey: string
): boolean => {
  if (brandfolder.key === selectedKey) return true;

  let descSelected = false;
  brandfolder?.collections?.forEach((collection) => {
    if (collectionOrChildSelected(collection, selectedKey)) {
      descSelected = true;
      return;
    }
  });

  if (!descSelected) {
    descSelected = !!brandfolder?.workspaces?.find((w) => w.key === selectedKey);
  }

  return descSelected;
};

const brandfolderHasDescendantResources = (key: string, brandfolders: Brandfolder[]): boolean => {
  const brandfolder = brandfolders.find((bf) => bf.key === key);
  return brandfolder.collections.length > 0 || brandfolder.workspaces.length > 0;
};

const collectionHasDescendantResources = (key: string, brandfolders: Brandfolder[]): boolean => {
  let hasDescendantResources = false;
  brandfolders.forEach((bf) => {
    const collection = bf.collections.find((c) => c.key === key);
    if (collection) {
      hasDescendantResources = collection.subcollections?.length > 0;
      return;
    }
  });
  return hasDescendantResources;
};

export const hasDescendantResources = (key: string, type: ResourceType, brandfolders: Brandfolder[]): boolean => {
  switch (type) {
    case 'brandfolder':
      return brandfolderHasDescendantResources(key, brandfolders);
    case 'collection':
      return collectionHasDescendantResources(key, brandfolders);
    default:
      return false;
  }
};

export const reorderPendingInvitations = (
  data: ApiResponseObject<PendingInvitations, 'invitations'>[]
): ApiResponseObject<PendingInvitations, 'invitations'>[] => {
  // there can only ever be one reusable invitation so it's sufficient to find the first in the list
  const reusableInviteIndex = data.findIndex((invitation) => invitation.attributes.email === REUSABLE_EMAIL);
  if (reusableInviteIndex !== -1) {
    const reorderedInvitations = [data[reusableInviteIndex], ...data];
    reorderedInvitations.splice(reusableInviteIndex + 1, 1);
    return reorderedInvitations;
  }

  return data;
};

export const organizationInheritanceBlurb = isGettyClient()
  ? t`Users at the Organization level will have access to all Libraries and Collections.`
  : t`Users at the Organization level will have access to all Brandfolders and Collections.`;
export const brandfolderInheritanceBlurb = isGettyClient()
  ? t`Users at the Organization level will have access to this Library, even if they are not listed here.`
  : t`Users at the Organization level will have access to this Brandfolder, even if they are not listed here.`;
export const collectionInheritanceBlurb = isGettyClient()
  ? t`Users at the Organization and Libary levels will have access to this Collection, even if they are not listed here.`
  : t`Users at the Organization and Brandfolder levels will have access to this Collection, even if they are not listed here.`;
export const workspaceInheritanceBlurb = isGettyClient()
  ? t`Users at the Organization and Libary levels will have access to this Workspace, even if they are not listed here.`
  : t`Users at the Organization and Brandfolder levels will have access to this Workspace, even if they are not listed here.`;
export const portalInheritanceBlurb = t`Users at the Organization level will have access to this Portal, even if they are not listed here.`;
export const brandguideInheritanceBlurb = t`Only users explicitly listed here will have access to this Brandguide.`;

export const resourceUserBlurb = (): Record<ResourceType, string> => ({
  brandfolder: brandfolderInheritanceBlurb,
  brandguide: brandguideInheritanceBlurb,
  collection: collectionInheritanceBlurb,
  organization: organizationInheritanceBlurb,
  portal: portalInheritanceBlurb,
  subcollection: collectionInheritanceBlurb,
  workspace: workspaceInheritanceBlurb
});

export const removeFromResource = (): Record<ResourceType, string> => ({
  brandfolder: isGettyClient() ? t`Remove from this Library` : t`Remove from this Brandfolder`,
  brandguide: t`Remove from this Brandguide`,
  collection: t`Remove from this Collection`,
  organization: t`Remove from this Organization`,
  portal: t`Remove from this Portal`,
  subcollection: t`Remove from this Subcollection`,
  workspace: t`Remove from this Workspace`
});

export const removeUserConsequences = (): Record<ResourceType, string> => ({
  brandfolder: isGettyClient()
    ? t`They will no longer have access to the Library.`
    : t`They will no longer have access to the Brandfolder.`,
  brandguide: t`They will no longer have access to the Brandguide.`,
  collection: t`They will no longer have access to the Collection.`,
  organization: t`They will no longer have access to the Organization.`,
  portal: t`They will no longer have access to the Portal.`,
  subcollection: t`They will no longer have access to the Subcollection.`,
  workspace: t`They will no longer have access to the Workspace.`
});

export const resourceDropdownHelperText = (): string => isGettyClient()
  ? t`Resources include Libraries, Collections, Subcollections, Workspaces, Brandguides, and Portals.`
  : t`Resources include Brandfolders, Collections, Subcollections, Workspaces, Brandguides, and Portals.`;

/**
 * The way we gather resources, it will only ever be possible to select a
 * Brandfolder or Collection that is not adminable because they have sub-resources
 * that may need to be returned. Everything else if it is returned then it is adminable
 * I added Portal and Brandguide for the sake of ease since the UserSettings resource can
 * be of this type even if it will never run into the scenario at runtime.
 * See the implementation of app/lib/organizations/adminable_resource_gatherer.rb for more
 */
export const notAdminableAlertText = (): Record<ServerResourceType, string> => ({
  brandfolder: isGettyClient()
    ? t`You need admin permissions to manage users in this Library.`
    : t`You need admin permissions to manage users in this Brandfolder.`,
  brandguide: t`You need admin permissions to manage users in this Brandguide.`,
  collection: t`You need admin permissions to manage users in this Collection.`,
  organization: t`You need admin permissions to manage users in this Organization.`,
  portal: t`You need admin permissions to manage users in this Portal.`,
});

export const removeFromAllConfirmText = (): Record<RemoveAllTextResourceType, string> => ({
  brandfolder: isGettyClient()
    ? t`Remove this user from all resources in this Library?`
    : t`Remove this user from all resources in this Brandfolder?`,
  collection: t`Remove this user from all resources in this Collection?`,
  organization: t`Remove this user from all resources in this Organization?`
});

export const userCanAdminBrandfolder = (brandfolders: Brandfolder[]): boolean => (
  brandfolders.some((bf) => !!bf.adminable)
);
