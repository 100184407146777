import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { formattedUppyAttachment } from '@helpers/uppy';

class ThumbnailOverrideUppy extends React.Component {
  handleThumbnailOverride = (files) => {
    if (!files.length) { return; }

    const file = formattedUppyAttachment(files[0]);
    const $parent = $(`.j-upload-settings-well.${this.uploadType()}`);
    $(".m-modal-preview .m-asset-img-thumb.m-file-img-thumb").show();
    $(".m-modal-preview .m-asset-img-thumb.m-file-img-thumb").attr("src", file.url);
    $('.m-asset-advanced-container').find('.j-upload-settings-well').data(`has-${this.uploadType}`, true);
    $parent.find('.filepicker_object').val(JSON.stringify({ url: file.url, filename: file.filename }));
    $parent.find('.filepicker-complete .filename').text(file.filename);
    $parent.find('.filepicker-complete .filename').attr('href', file.url);
    $parent.find('.filepicker-complete-pane').addClass('complete');
    $parent.find('.filestack-uploader-component').hide();
  }

  uploadType = () => this.props.uploadType.toLowerCase();

  trigger = () => `uppy-thumbnail-override-trigger-${this.uploadType()}`

  renderArea = () => (
    <div className="uppy-thumbnail-override-settings" id={this.trigger()}>
      <Trans>Click To upload</Trans>
    </div>
  );

  render() {
    return (
      <React.Fragment>
        {this.renderArea()}
        <UppyUploader
          {...this.props}
          button
          handleUpload={this.handleThumbnailOverride}
          restrictions={{ maxNumberOfFiles: 1, allowedFileTypes: ['image/*'] }}
          template={this.uploadType()}
          trigger={`#${this.trigger()}`}
          uniqIdentifier={`uppy-thumbnail-override-${this.uploadType()}`}
        />
      </React.Fragment>
    );
  }
}

ThumbnailOverrideUppy.propTypes = {
  uploadType: PropTypes.string.isRequired
};

export default ThumbnailOverrideUppy;
