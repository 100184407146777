import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { FontIcon, FontIcons, FontIconColors } from '@components/library/icon';
import { MoreInfoTooltipProps } from '@components/library/tooltip';
import { Colors, Widths } from '@components/library/utils';

import { BaseTooltip } from '../base';

import classes from './styles/more-info-tooltip.module.scss';

export const MoreInfoTooltip: FunctionComponent<MoreInfoTooltipProps> = (props) => {
  const {
    className,
    iconLabel = t`More info`,
    iconSize = 13,
    width = Widths.Medium,
    wrapperProps,
    ...otherProps
  } = props;

  return (
    <BaseTooltip
      {...otherProps}
      className={classnames(
        classes.tooltip,
        classes[Colors.Primary],
        classes[width],
        className
      )}
      variantName="more-info"
      wrapperProps={{
        ...wrapperProps,
        className: classnames(
          classes.wrapper,
          wrapperProps && wrapperProps.className
        )
      }}
    >
      <FontIcon
        aria-label={iconLabel}
        color={FontIconColors.Primary}
        icon={FontIcons.ToolTip}
        iconSize={iconSize}
        tabIndex={0}
      />
    </BaseTooltip>
  );
};
