import { t } from '@lingui/macro';

import { RestrictedDimensions } from '@components/asset/modal/AssetModalTypes';

export const WEB_FRIENDLY_ATTACHMENT_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'tif', 'tiff'];
/**
 * Attachment filetypes that currently do not succeed in colorspace conversion in our system
 */
export const COLORSPACE_CONVERSION_EXCEPTIONS = ['psd', 'psb'];

export const PRESET_SIZE_LABELS = (): Record<number, string> => ({
  /* eslint-disable @typescript-eslint/naming-convention */
  300: t`Small`,
  600: t`Medium`,
  1200: t`Large`
  /* eslint-enable @typescript-eslint/naming-convention */
});

/**
 * if you update this number, you'll also needed to update the same constant in app/models/attachment.rb
 * as well as message in app/javascript/components/asset/modal/tabs/asset_details/asset_attachments/AttachmentConversionOptions.tsx
 */
export const BLITLINE_MAX = 25000;

/**
 * This number represents the long edge number in which Blitline
 * doesn't seem respond to the webhook within the 5 minutes of polling we do
 */
export const BLITLINE_TIMEOUT_ZONE_MIN = 9000;

const getIfOverResizeMax = (value: number, max?: number): boolean => (max && max < BLITLINE_MAX && value > max) || value > BLITLINE_MAX;
const getIfOverTimeoutRange = (value: number): boolean => value > BLITLINE_TIMEOUT_ZONE_MIN;

interface GetResizeMessageOptions {
  extension: string;
  height: number;
  width: number;
  restrictedDimensions?: RestrictedDimensions;
}

interface GetResizeMessageReturn {
  isOverMax: boolean;
  resizeMessage: string;
}

export const getResizeMessage = ({ extension, height, restrictedDimensions, width }: GetResizeMessageOptions): GetResizeMessageReturn => {
  const isVector = BFG.vectorFileTypes?.includes(extension) || false;

  const overResizeHeightMax = getIfOverResizeMax(height);
  const overResizeWidthMax = getIfOverResizeMax(width);
  const isOverResizeMax = overResizeHeightMax || overResizeWidthMax;

  const overRestrictedHeightMax = getIfOverResizeMax(height, restrictedDimensions?.height?.max);
  const overRestrictedWidthMax = getIfOverResizeMax(width, restrictedDimensions?.width?.max);
  const isOverRestrictedMax = overRestrictedHeightMax || overRestrictedWidthMax;

  const isOverMax = isOverResizeMax || isOverRestrictedMax;

  const overTimeoutHeightRange = getIfOverTimeoutRange(height);
  const overTimeoutWidthRange = getIfOverTimeoutRange(width);
  const isOverTimeoutRange = overTimeoutHeightRange || overTimeoutWidthRange;

  let resizeMessage = '';
  if (!isVector && overRestrictedHeightMax && !overRestrictedWidthMax) {
    resizeMessage = t`Your height is over the allowed maximum`;
  } else if (!isVector && !overRestrictedHeightMax && overRestrictedWidthMax) {
    resizeMessage = t`Your width is over the allowed maximum`;
  } else if (!isVector && overRestrictedHeightMax && overRestrictedWidthMax) {
    resizeMessage = t`Your height and width are over the allowed maximum`;
  } else if (overResizeHeightMax && !overResizeWidthMax) {
    resizeMessage = t`Your height is over the system maximum of 25,000 pixels`;
  } else if (!overResizeHeightMax && overResizeWidthMax) {
    resizeMessage = t`Your width is over the system maximum of 25,000 pixels`;
  } else if (overResizeHeightMax && overResizeWidthMax) {
    resizeMessage = t`Your height and width are over the system maximum of 25,000 pixels`;
  } else if (isOverTimeoutRange) {
    resizeMessage = t`Your download may take a long time to process`;
  }

  return { isOverMax, resizeMessage };
};

export const DOWNLOAD_SELF_EXCLUDE_LIST = ['ai', 'eps', 'psd', 'tif', 'tiff'];

/**
 * Get whether an asset should be allowed to show the radio button
 * for converting itself inside the download options dropdown
 * Added initially for Photoshop PSD/TIFF and Illustrator AI/EPS files
 * because when they download a conversion themselves (example: PSD => PSD file)
 * Blitline flattens their file layers (which isn't wanted and users consider a bug)
 * @param {string | undefined} fileExtension string | undefined
 * @returns {boolean} boolean
 */
export const getCanDownloadSelf = (fileExtension: string | undefined): boolean => (fileExtension ? !DOWNLOAD_SELF_EXCLUDE_LIST.includes(fileExtension) : false);
