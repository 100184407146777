import { t } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { getMomentLocale, localizeNumber } from '@helpers/locale';

function CsvRow({ csv_upload }) {
  const momentLocale = getMomentLocale(BFG.locales.staticSiteLocale);

  const renderCsvRow = () => {
    const {
      created_at,
      creator_email,
      failed_count,
      filename,
      status,
      succeeded_count,
      total_count,
      validation_failure_count,
      validation_failure_download_url,
    } = csv_upload?.attributes;
    return (
      <>
        <span className="twenty">
          {moment(created_at).locale(momentLocale).format('L, LT')}
        </span>
        <span className="fifteen">
          <span className="ellipsis" title={creator_email}>{creator_email}</span>
        </span>
        <span className="fifteen">
          <span className="ellipsis" title={filename}>{filename}</span>
        </span>
        <span className="ten center">
          {localizeNumber(succeeded_count)}
        </span>
        <span className="ten center">
          {validation_failure_count > 0
            ? (
              <a
                aria-label={t`Download file describing validation failures`}
                download
                href={validation_failure_download_url}
              >
                {localizeNumber(validation_failure_count)}
              </a>
            ) : localizeNumber(validation_failure_count)
          }
        </span>
        <span className="ten center">
          {localizeNumber(failed_count)}
        </span>
        <span className="ten center">
          {localizeNumber(total_count)}
        </span>
        <span className="ten center capitalize">
          {status}
        </span>
      </>
    );
  };

  return renderCsvRow();
}

CsvRow.propTypes = {
  csv_upload: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      created_at: PropTypes.string,
      status: PropTypes.string,
      creator_email: PropTypes.string,
      total_count: PropTypes.number,
      succeeded_count: PropTypes.number,
      failed_count: PropTypes.number,
    })
  }).isRequired
};

export default CsvRow;
