/**
 * A Promise to delay/sleep
 * @param timeout number
 * @param value T | undefined
 * @returns Promise<T | null>
 */
export const delay = async <T>(timeout: number, value?: T): Promise<T | null> => new Promise((resolve) => {
  const timeoutId = setTimeout(() => {
    resolve(value || null);
    clearTimeout(timeoutId);
  }, timeout);
});
