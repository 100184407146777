import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { FontIcons } from '@components/library/icon';
import BaseButton from '../BaseButton';
import styleClassNames from './styles.module.scss';

interface SecondaryButtonProp extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string | FontIcons;
  iconSize?: number;
  isLoading?: boolean;
  loadingCopy?: string;
  loadingIcon?: string | FontIcons;
  loadingIconSize?: number;
  size?: ButtonSize;
  useDisabledStyleForAriaDisabled?: boolean;
}

type ButtonSize = 'xsmall' | 'small' | 'medium' | 'large';

const SecondaryButton = forwardRef<HTMLButtonElement, SecondaryButtonProp>((props, ref) => {
  const baseProps = {
    ...props,
    styleClassNames,
    variantName: 'secondary',
  };

  return <BaseButton {...baseProps} ref={ref} />;
});

export default SecondaryButton;
