/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { AutocompleteResponse, PersonDetailsResponse, RemoveFaceTagResponse, ThumbnailResponse } from './customPeopleTagTypes';

export const mockHeroImageResponse: ThumbnailResponse = {
  data: {
    hero_image: 'https://www.heroImage.com',
    tag_name: 'Jane Doe',
  }
};

export const mockFaceId = 'the-face-id';
export const mockCurrentPerson = {
  face_uri: 'https://www.faceImage1.com',
  person_id: mockFaceId,
  tag_name: 'Lydia Williams'
};
export const mockSelectedPerson = {
  face_uri: 'https://www.faceImage2.com',
  person_id: 'person-id-2',
  tag_name: 'Lionel Lee',
};

export const mockAutoCompleteResponse: AutocompleteResponse = {
  data: [
    mockCurrentPerson,
    mockSelectedPerson,
    {
      face_uri: 'https://www.faceImage3.com',
      person_id: 'person-id-3',
      tag_name: 'Tyler Lane',
    }
  ]
};

export const mockPersonDetailsData: PersonDetailsResponse = {
  data: [
    {
      asset_id: 'asset-id-1',
      attachment_id: 'attachment-id-1',
      gcs_path:
        'https://asset-1.com',
      match_face_id: mockFaceId,
    },
    {
      asset_id: 'asset-id-2',
      attachment_id: 'attachment-id-2',
      gcs_path:
        'https://asset-2.com',
      match_face_id: mockFaceId,
    },
    {
      asset_id: 'asset-id-3',
      attachment_id: 'attachment-id-3',
      gcs_path:
        'https://asset-3.com',
      match_face_id: mockFaceId,
    },
    {
      asset_id: 'asset-id-4',
      attachment_id: 'attachment-id-4',
      gcs_path:
        'https://asset-4.com',
      match_face_id: mockFaceId,
    },
    {
      asset_id: 'asset-id-5',
      attachment_id: 'attachment-id-5',
      gcs_path:
        'https://asset-5.com',
      match_face_id: mockFaceId,
    },
  ],
  meta: {
    bf_processed: true,
    current_page: 1,
    next_page: 2,
    prev_page: 0,
    total_count: 5,
    total_pages: 1,
  },
};

export const mockRemoveTagResponse: RemoveFaceTagResponse = {
  data: {
    cluster_deleted: false,
    new_person_id: 'new-person-id',
  },
};

export const mockPeoplePageResponse = {
  data: [
    {
      face_label: null,
      gcs_path: 'https://people-face-1.png',
      person_id: 'face_6zp967s8h3593gtvvr7mm_1322_710',
    },
    {
      face_label: 'John Doe',
      gcs_path: 'https://people-face-2.png',
      person_id: 'face_xcpv4f5659pxh4sxr58p7vtv_1398_1593',
    }
  ],
  meta: {
    current_page: 1,
    next_page: null,
    prev_page: null,
    total_count: 2,
    total_pages: 1
  }
};

export const mockUpdatePersonTagResponse = {
  data: {
    tag_name: 'Jane Doe'
  }
};
