import { fetchJson } from '@api/ApiHelper';
import {
  DesignHuddleProjectGetNewResponse,
  DesignHuddleProjectPost,
  DesignHuddleProjectPostClient,
  DesignHuddleProjectPostResponse
} from '@api/v4/private/resources/design_huddles';
import { snakifyObjectKeys } from '@components/library/utils';

interface DesignHuddleProjectOptionsBase {
  resourceKey: string;
  resourceType: string;
}

interface DesignHuddleProjectPostOptions extends DesignHuddleProjectOptionsBase {
  data: DesignHuddleProjectPostClient;
}

/**
 * Create a Design Huddle project.
 * Handles all seven types of projects.
 * @param options DesignHuddleProjectPostOptions
 * @returns DesignHuddleProjectPostResponse
 */
export const designHuddleProjectPost = (
  options: DesignHuddleProjectPostOptions
): Promise<DesignHuddleProjectPostResponse> => {
  const { data, resourceKey, resourceType } = options;
  const attributes = snakifyObjectKeys<DesignHuddleProjectPostClient, DesignHuddleProjectPost>(data);
  return fetchJson<DesignHuddleProjectPostResponse>({
    url: `/api/v4/private/${resourceType}s/${resourceKey}/design_huddles/projects`,
    method: 'POST',
    body: {
      data: {
        ...attributes
      }
    }
  });
};

interface DesignHuddleProjectGetNewOptions extends DesignHuddleProjectOptionsBase {
  projectId: string;
}

/**
 * Get Design Huddle data for creating an asset within Brandfolder
 * @param options DesignHuddleProjectGetNewOptions
 * @returns DesignHuddleProjectGetNewResponse
 */
export const designHuddleProjectGetNew = (
  options: DesignHuddleProjectGetNewOptions
): Promise<DesignHuddleProjectGetNewResponse> => {
  const { projectId, resourceKey, resourceType } = options;
  return fetchJson<DesignHuddleProjectGetNewResponse>({
    url: `/api/v4/private/${resourceType}s/${resourceKey}/design_huddles/projects/${projectId}/new`
  });
};
