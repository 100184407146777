const defaultIntlLocale = 'en-US';

/**
 * Base datetime formatter function that others should call
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 * @param dateTime Date | string
 * @param locale string | string[]
 * @param options Intl.DateTimeFormatOptions | undefined
 * @returns string
 */
export const formatDateTime = (dateTime: Date | string, locale: string | string[], options?: Intl.DateTimeFormatOptions): string => {
  const locales = Array.isArray(locale) ? [...locale, defaultIntlLocale] : [locale, defaultIntlLocale];
  return new Intl.DateTimeFormat(locales, options).format(new Date(dateTime));
};

/**
 * Get localized date and time (without seconds)
 * @example localizeDateTime(2020-10-15T19:51:45.291Z) => 10/15/2020, 3:51 PM
 * @param dateTime Date | string
 * @param options Intl.DateTimeFormatOptions | undefined
 * @returns string
 */
export const localizeDateTime = (dateTime: Date | string, options?: Intl.DateTimeFormatOptions): string => {
  const { staticSiteLocale, ugtLocaleDefault } = BFG.locales;
  // navigator.language will add the users browser/OS set language before using defaultIntlLocale above
  return formatDateTime(dateTime, [staticSiteLocale, ugtLocaleDefault, navigator.language], options || {
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    month: 'numeric',
    year: 'numeric'
  });
};

export const localizeDate = (dateTime: Date | string, options?: Intl.DateTimeFormatOptions): string => {
  const { staticSiteLocale, ugtLocaleDefault } = BFG.locales;
  // navigator.language will add the users browser/OS set language before using defaultIntlLocale above
  return formatDateTime(dateTime, [staticSiteLocale, ugtLocaleDefault, navigator.language], options || {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  });
};
