/* eslint-disable @typescript-eslint/naming-convention */
import { Trans } from '@lingui/macro';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  FunctionComponent
} from 'react';

import { downloadPoller } from '@api/downloading/download_poller';
import { PreviewType } from '@api/v4/attachments/attachmentTypes';
import { RestrictedDimensions } from '@components/asset/modal/AssetModalTypes';
import DropdownWrapper from '@components/common/dropdown_wrapper/main';
import { PrimaryButton, TertiaryButton, TextButton } from '@components/library/button/index';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';
import { StandardRadioButton } from '@components/library/radio_button/index';
import SimpleVideoEditor from '@components/video/simple_video_editor/main';
import {
  BLITLINE_MAX,
  COLORSPACE_CONVERSION_EXCEPTIONS,
  PRESET_SIZE_LABELS,
  WEB_FRIENDLY_ATTACHMENT_TYPES,
  getCanDownloadSelf,
  getResizeMessage
} from '@helpers/attachment_conversion';
import { getCurrentUserIsAnonymousOrGuest } from '@helpers/user';

interface AttachmentConversionOptionsProps {
  assetKey: string;
  attachmentKey: string;
  conversionOptions: string[];
  dimensions: { width: number; height: number };
  extension: string | null;
  filename: string;
  isConvertable: boolean;
  presetSizes: number[];
  previewType: PreviewType;
  processed: boolean;
  colorSpace?: string;
  restrictedDimensions?: RestrictedDimensions;
  trackAttachmentDownload: () => void;
}

interface TrackableDimension {
  value: number;
  userInput: boolean;
}

const ALLOWED_COLOR_SPACES = ['RGB', 'CMYK'];

const AttachmentConversionOptions: FunctionComponent<AttachmentConversionOptionsProps & { setCloseDropdown: SetStateDispatch<boolean> }> = ({
  assetKey,
  attachmentKey,
  conversionOptions,
  colorSpace,
  dimensions,
  extension,
  filename,
  isConvertable,
  presetSizes,
  previewType,
  processed,
  restrictedDimensions,
  setCloseDropdown,
  trackAttachmentDownload
}) => {
  const canDownloadSelf = getCanDownloadSelf(extension);
  const defaultExtension = canDownloadSelf ? extension : (conversionOptions[0] || null);
  const options = canDownloadSelf ? [...conversionOptions, extension] : [...conversionOptions];

  const [conversionExtension, setConversionExtension] = useState<string | null>(defaultExtension);
  const [conversionColorSpace, setConversionColorSpace] = useState(ALLOWED_COLOR_SPACES.includes(colorSpace?.toUpperCase()) ? colorSpace?.toUpperCase() : undefined);
  const [showCustomSize, setShowCustomSize] = useState(false);
  const [width, setWidth] = useState<TrackableDimension>({ value: dimensions?.width || 0, userInput: false });
  const [height, setHeight] = useState<TrackableDimension>({ value: dimensions?.height || 0, userInput: false });
  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const [lockedAspectRatio, setLockedAspectRatio] = useState(true);
  const prevWidth = useRef(width);
  const prevHeight = useRef(height);
  const pollerUrl = `/${BFG.resource.slug}/attachments/${attachmentKey}/poller`;
  const customAspectRatio = BFG.hasFeature('custom_aspect_ratio_rollout') && BFG.brandfolderSettings.custom_aspect_ratio;

  const maintainImageSizeRatio = useCallback((w: TrackableDimension, h: TrackableDimension): void => {
    // TODO(emmi): don't run after width is set because of height changing and vice versa
    if (!lockedAspectRatio) return;
    if (!dimensions?.width || !dimensions?.height) return;
    const aspectRatio = dimensions.width / dimensions.height;
    if (w.value !== prevWidth.current.value && w.userInput) {
      setHeight({ value: Math.round(w.value / aspectRatio), userInput: false });
    } else if (h.value !== prevHeight.current.value && h.userInput) {
      setWidth({ value: Math.round(h.value * aspectRatio), userInput: false });
    }
  }, [dimensions, prevWidth, prevHeight, lockedAspectRatio]);

  const dimensionsInRange = useCallback((w: number, h: number): boolean => {
    const { min: minW, max: maxW } = restrictedDimensions.width;
    const { min: minH, max: maxH } = restrictedDimensions.height;
    return w <= maxW && w >= minW && h <= maxH && h >= minH;
  }, [restrictedDimensions]);

  useEffect(() => {
    if (WEB_FRIENDLY_ATTACHMENT_TYPES.includes(conversionExtension)) {
      setShowCustomSize(true);
    } else {
      setShowCustomSize(false);
    }
  }, [conversionExtension]);

  useEffect(() => maintainImageSizeRatio(width, height), [width, height, maintainImageSizeRatio]);

  useEffect(() => {
    prevWidth.current = width;
    prevHeight.current = height;
    if (showCustomSize) {
      if (!dimensionsInRange(width.value, height.value)) {
        setDownloadDisabled(true);
      } else {
        setDownloadDisabled(false);
      }
    }
  }, [dimensionsInRange, height, showCustomSize, width]);

  const handlePresetSizeClick = (size: number): void => {
    if (dimensions.width > dimensions.height) {
      setWidth({ value: size, userInput: true });
    } else {
      setHeight({ value: size, userInput: true });
    }
  };

  const handleDownloadConversion = (): void => {
    downloadPoller(pollerUrl, { params: {
      collection_slug: BFG.resource?.type === 'collection' ? BFG.resource.slug : '',
      color_mode: conversionColorSpace,
      dl: true,
      extension: conversionExtension,
      height: height.value,
      manifest_digest: BFG.manifestDigest,
      pad: !lockedAspectRatio,
      resource_key: BFG.resource?.key,
      resource_type: BFG.resource?.type,
      type: conversionExtension,
      width: width.value,
    } }, 500);
    trackAttachmentDownload();
    setCloseDropdown(true);
  };

  const extensionConversionOptions = options.map((ext) => {
    if (ext) {
      return (
        <StandardRadioButton
          key={ext}
          checked={ext === conversionExtension}
          id={ext}
          name="conversion-extension"
          onChange={(): void => setConversionExtension(ext)}
          value={ext}
        >
          {ext?.toUpperCase() || ''}
        </StandardRadioButton>
      );
    }
    return null;
  });

  const colorSpaceConversionOptions = ALLOWED_COLOR_SPACES.map((cs) => (
    <StandardRadioButton
      key={cs}
      checked={cs === conversionColorSpace}
      id={cs}
      name="conversion-color-space"
      onChange={(): void => setConversionColorSpace(cs)}
      value={cs}
    >
      {cs === 'RGB' ? 'sRGB' : cs}
    </StandardRadioButton>
  ));

  const aspectRatioLock = customAspectRatio ? (
    <TextButton
      className="resize-dimension__lock"
      data-testid="resize-dimension__lock"
      icon={lockedAspectRatio ? 'bff-private' : 'bff-public'}
      onClick={(): void => setLockedAspectRatio((prev) => {
        if (prev === false) {
          setWidth({ value: dimensions?.width || 0, userInput: false });
          setHeight({ value: dimensions?.height || 0, userInput: false });
        }
        return !prev;
      })}
    />
  ) : <span className="bff-private resize-dimension__lock resize-dimension__lock--disabled" />;

  const { isOverMax, resizeMessage } = getResizeMessage({ extension, height: height.value, restrictedDimensions, width: width.value });

  const resizeContainer = (
    <>
      <div className="resize-dimension">
        <InputContainer
          attributes={{
            className: 'resize-dimension__input',
            max: restrictedDimensions?.width?.max ?? BLITLINE_MAX,
            min: restrictedDimensions?.width?.min ?? 1,
            name: 'width',
            onChange: (e: InputChangeEvent): void => setWidth({
              value: e.target.value ? parseInt(e.target.value, 10) : 0,
              userInput: true
            }),
            type: InputType.Number,
          }}
          input={{ value: width.value.toString() }}
          labelCopy={<Trans>Width (px)</Trans>}
          submitAttempted={downloadDisabled}
        />
        {aspectRatioLock}
        <InputContainer
          attributes={{
            className: 'resize-dimension__input',
            max: restrictedDimensions?.height?.max ?? BLITLINE_MAX,
            min: restrictedDimensions?.height?.min ?? 1,
            name: 'height',
            onChange: (e: InputChangeEvent): void => setHeight({
              value: e.target.value ? parseInt(e.target.value, 10) : 0,
              userInput: true
            }),
            type: InputType.Number,
          }}
          input={{ value: height.value.toString() }}
          labelCopy={<Trans>Height (px)</Trans>}
          submitAttempted={downloadDisabled}
        />
      </div>
      {!!resizeMessage && (
        <p className={`resize-dimension__message${isOverMax ? ' resize-dimension__message--error' : ' resize-dimension__message--warning'}`}>
          <span className={`resize-dimension__message-icon ${isOverMax ? ' bff-warning' : ' bff-attention'}`} />
          <span>{resizeMessage}</span>
        </p>
      )}
    </>
  );

  const presetSizeOptions = (
    <div className="resize-dimension__preset-sizes">
      {presetSizes.map((size) => (
        <TextButton
          key={`preset-size-${size}`}
          className="resize-dimension__preset-sizes--item"
          onClick={(): void => handlePresetSizeClick(size)}
        >
          {PRESET_SIZE_LABELS()[size]}
        </TextButton>
      ))}
      <TextButton
        className="resize-dimension__preset-sizes--item"
        onClick={(): void => {
          setWidth({ value: dimensions?.width || 0, userInput: false });
          setHeight({ value: dimensions?.height || 0, userInput: false });
        }}
      >
        <Trans>Original</Trans>
      </TextButton>
    </div>
  );

  const downloadButton = (
    <div className="attachment-conversion-options__download">
      <PrimaryButton
        disabled={downloadDisabled}
        onClick={(): void => {
          if (BFG.downloadAlertEnabled) {
            BF.fx.dispatchWindowEvent('launchDownloadAlert', null, {
              downloadCallback: handleDownloadConversion
            });
          } else {
            handleDownloadConversion();
          }
        }}
        size="medium"
      >
        <Trans>Download</Trans>
      </PrimaryButton>
    </div>
  );

  const croppingButton = (
    <div className="attachment-conversion-options__cropping">
      <TertiaryButton
        disabled={!processed}
        onClick={(): void => {
          BF.fx.dispatchWindowEvent('launchAdvancedDownload', null, { assetKey, attachmentKey });
        }}
        size="medium"
      >
        <Trans>Export Options</Trans>
      </TertiaryButton>
    </div>
  );

  if (previewType === PreviewType.VideoMux) {
    return (
      <div className="attachment-conversion-options">
        <SimpleVideoEditor
          assetKey={assetKey}
          attachment={{
            key: attachmentKey,
            extension,
            height: dimensions.height,
            width: dimensions.width,
            filename
          }}
        />
      </div>
    );
  }

  const allowCollectionGuestAssetCropping = BFG.resource.type === 'collection' && getCurrentUserIsAnonymousOrGuest(BFG.currentUser) ? BFG.allowCollectionGuestAssetCropping : true;
  const showCroppingButton = BFG.hasFeature('cropping') && isConvertable && !BFG.manifestDigest && allowCollectionGuestAssetCropping;

  return (
    <div className="attachment-conversion-options">
      <div className="attachment-conversion-options__item">
        <p className="title-copy">
          <Trans>Download Options</Trans>
        </p>
      </div>
      <div className="attachment-conversion-options__item">
        <div className="attachment-conversion-options__file-format">
          {extensionConversionOptions}
        </div>
      </div>
      {colorSpace
        && ['jpg', 'jpeg', 'pdf'].includes(conversionExtension)
        && !COLORSPACE_CONVERSION_EXCEPTIONS.includes(extension)
        && BFG.hasFeature('colorspace_conversions')
        && (
          <div className="attachment-conversion-options__item">
            <div className="attachment-conversion-options__color">
              {colorSpaceConversionOptions}
            </div>
          </div>
        )}
      {showCustomSize && (
        <div className="attachment-conversion-options__item">
          {resizeContainer}
          {presetSizes.length > 0 && presetSizeOptions}
        </div>
      )}
      <div className="attachment-conversion-options__item">
        {downloadButton}
        {showCroppingButton && croppingButton}
      </div>
    </div>
  );
};

const AttachmentConversionOptionsDropdown = (props: AttachmentConversionOptionsProps): JSX.Element => {
  const [closeDropdown, setCloseDropdown] = useState(false);
  return (
    <DropdownWrapper
      close={closeDropdown}
      closeCallback={(): void => setCloseDropdown(false)}
      cssTransitionClasses="fade-in-translate"
      dropdownContents={(
        <AttachmentConversionOptions
          {...props}
          setCloseDropdown={setCloseDropdown}
        />
      )}
      openOnHover={false}
    >
      <div className="attachment-conversion-options__click-container">
        <h4 className="attachment-conversion-options__click-container--title">
          <Trans>Options</Trans>
        </h4>
        <span className="bff-ellipsis" />
      </div>
    </DropdownWrapper>
  );
};

export default AttachmentConversionOptionsDropdown;
