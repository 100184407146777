import { ButtonLink, FontIcons, IconButton, brandfolderColorPalette } from '@brandfolder/react';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { BrandfolderHexColors } from '@components/library/utils';

const ImageOverlayWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 1, 1, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &:focus,
  &:focus-within,
  &:hover {
    opacity: 1;
  }
  
  .view-options-icon {
    background-color: unset;
    border: none;
    color: ${brandfolderColorPalette.neutrals[0]};
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    
    &:hover {
      background-color: unset;
    }
  }
`;

const OptionsMenu = styled.ul`
  background-color: ${brandfolderColorPalette.neutrals[0]};
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
  list-style: none;
  margin: 40px 4px 0;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
  width: 222px;

  .option {
    button {
      align-items: center;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: flex-start;
      padding: 8px;

      &:hover {
        background-color: ${brandfolderColorPalette.neutrals[100]};
        border-radius: 4px;
        width: 100%;
      }

      .option-text {
        color: ${brandfolderColorPalette.neutrals[600]};
        margin-left: 8px;
      }
    }
  }
`;

const linkStyles = css`
  background-color: transparent;
  border: 1px solid ${BrandfolderHexColors.White};
  border-radius: 4px;
  color: ${BrandfolderHexColors.White};
  line-height: 20px;
  padding: 14px 20px;
  width: max-content;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: ${BrandfolderHexColors.White};
    color: ${BrandfolderHexColors.White};
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &:disabled {
    border-color: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
  }
`;

const OverlayButtonLink = styled(ButtonLink)`
  ${linkStyles}
`;

const OverlayRouterLink = styled(Link)`
  ${linkStyles}
`;

interface LocationState {
  label: string;
  prevRoute: string;
}

export interface OptionsMenuItem {
  label: string;
  onClick: () => void;
  icon?: ReactNode;
}

interface ImageOverlayProps {
  buttonText: string;
  children: ReactNode;
  href?: string;
  icon?: FontIcons;
  linkTo?: string;
  locationState?: LocationState;
  onClick?: () => void;
  optionsMenuItems?: OptionsMenuItem[];
}

export const ImageOverlay: FunctionComponent<ImageOverlayProps> = ({
  children,
  buttonText,
  href = null,
  icon = null,
  linkTo = null,
  locationState = null,
  onClick = null,
  optionsMenuItems = [],
}) => {
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);

  return (
    <ImageOverlayWrapper>
      <Overlay>
        {optionsMenuItems?.length && (
          <IconButton
            className="view-options-icon"
            icon={FontIcons.Ellipsis}
            label="View options"
            onClick={(): void => setShowOptionsMenu(!showOptionsMenu)}
          />
        )}
        {showOptionsMenu && (
          <OptionsMenu>
            {optionsMenuItems?.map((item) => (
              <li key={item.label} className="option">
                <button
                  onClick={item.onClick}
                  type="button"
                >
                  {item.icon}
                  <span className="option-text">{item.label}</span>
                </button>
              </li>
            ))}
          </OptionsMenu>
        )}
        {linkTo ? (
          <OverlayRouterLink state={locationState} tabIndex={0} to={linkTo}>
            {buttonText}
          </OverlayRouterLink>
        ) : (
          <OverlayButtonLink href={href} onClick={onClick} startIcon={icon} tabIndex={0}>
            {buttonText}
          </OverlayButtonLink>
        )}
      </Overlay>
      {children}
    </ImageOverlayWrapper>
  );
};
