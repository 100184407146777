import { DragAndDropFormats, FontIcon, FontIcons, mergeRefs, useDrag, useDrop } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { PickerDisplayMode, PickerResponse } from 'filestack-js/build/main/lib/picker';
import React, {
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';

import {
  ActionTypes,
  AttachmentActionDispatch,
  FlattenedAttachment
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import { useFilestackPicker } from '@components/common/custom_hooks/useFilestackPicker';
import { addFiles } from '@components/common/filestack_uploader/helpers';
import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { ActionsDropdown } from '@components/library/dropdown';
import { ActionDropdownItem } from '@components/library/dropdown_item';
import { ActionDropdownItemProps } from '@components/library/dropdown_item/DropdownItemProps';
import Input from '@components/library/inputs/BaseInput';
import { InputType } from '@components/library/inputs/Input.props';

import { AttachmentThumbnailImage } from '../../shared/AttachmentThumbnailImage';
import {
  getFilenameSansExt,
  handleThumbnailError,
  tempAttachmentTemplate,
  tempAttachmentTemplateUppy
} from '../helpers';

interface AttachmentListItemProps {
  attachment: FlattenedAttachment;
  dispatch: AttachmentActionDispatch;
  droppedIndex: number | undefined;
  index: number;
  isTemplateAsset: boolean;
  setDroppedIndex: (index: number) => void;
}

const AttachmentListItem = ({
  attachment,
  dispatch,
  droppedIndex,
  index,
  isTemplateAsset,
  setDroppedIndex
}: AttachmentListItemProps): JSX.Element => {
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [renameAction, setRenameAction] = useState(false);

  const [showHoveredImage, setShowHoveredImage] = useState(false);
  const [isThumbHovered, setIsThumbHovered] = useState(false);
  const [isNameHovered, setIsNameHovered] = useState(false);
  const [enableHoveredImage, setEnableHoveredImage] = useState(true);

  const dragRef = useRef<HTMLButtonElement | null>(null);
  const dragImageRef = useRef<HTMLLIElement | null>(null);
  const dropRef = useRef<HTMLLIElement | null>(null);

  const extenstionSansPeriod = attachment?.extension?.replace('.', '') || attachment.extension;
  const filenameSansExt = getFilenameSansExt(attachment?.filename, extenstionSansPeriod);

  // including a | in a CSS selector will blow up
  const attachmentKeyNoSource = attachment.key.includes('|') ? attachment.key.split('|')[1] : attachment.key;
  const uppyAttachmentReplaceTrigger = `attachment-replace-uppy-${attachmentKeyNoSource}`;

  const handleReplaceAttachment = (files): void => {
    files.map((file) => (
      dispatch({
        type: ActionTypes.ReplaceAttachment,
        payload: {
          index,
          replacement: BFG.hasFeature('uppy_uploader_features')
            ? tempAttachmentTemplateUppy(file)
            : tempAttachmentTemplate(file)
        }
      })
    ));
    const sweetAlertSuccessOptions = {
      type: 'success',
      title: t`Success!`,
      text: t`Your attachment has been updated!`,
      customClass: 'attachment-updated',
      showConfirmButton: true,
      allowOutsideClick: true
    };

    const sweetAlertUploadFailedOptions = {
      type: 'error',
      title: t`File Upload Failed`,
      text: t`An error occurred while uploading this attachment. Please try again or contact support.`,
      showConfirmButton: true,
      allowOutsideClick: true
    };

    if (files.length === 0) {
      return window.swal(sweetAlertUploadFailedOptions, () => { /* do nothing */ });
    }

    return window.swal(sweetAlertSuccessOptions, () => { /* do nothing */ });
  };

  const handleDeleteAttachment = (): void => {
    setCloseDropdown(true);
    const sweetAlertDeleteOptions = {
      title: t`Really?`,
      text: t`Are you sure you want to delete ${attachment.filename}?`,
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: t`Cancel`,
      confirmButtonText: t`Delete`,
      closeOnConfirm: true,
      customClass: 'deleting'
    };

    window.swal(sweetAlertDeleteOptions, (confirm) => {
      if (confirm) {
        dispatch({
          type: ActionTypes.DeleteAttachment,
          payload: { index }
        });
      }
    });
  };

  const onUploadDone = (pickerResponse: PickerResponse): void => {
    addFiles(pickerResponse, handleReplaceAttachment);
  };

  const { loading, picker, render } = useFilestackPicker({
    onUploadDone,
    pickerOptions: {
      displayMode: PickerDisplayMode.overlay,
      maxFiles: 1
    }
  });

  const { device } = BFG.currentUser;
  const disableFirstAttachment = isTemplateAsset && index === 0;
  const isTouchScreen = device === 'mobile-view' || device === 'tablet-view';
  const isDraggable = !disableFirstAttachment && !isTouchScreen;

  useEffect(() => {
    setShowHoveredImage(isThumbHovered || isNameHovered);
  }, [isThumbHovered, isNameHovered]);

  useEffect(() => {
    // accounts for the case when the cursor "catches" and hovers on the hovered image
    // if this happens, the "onMouseEnter" event won't be captured for
    // the attachment list item element that's below the hovered image
    // to handle this case- we hide the hovered image immediately with setEnableHoveredImage(false)
    // so that the attachment list item element can capture the "onMouseEnter" event
    // this useEffect enables it again immediately so it's ready for the next hover
    if (!enableHoveredImage) {
      setEnableHoveredImage(true);
    }
  }, [enableHoveredImage]);

  const handleRename = (): void => {
    setCloseDropdown(true);
    setRenameAction(true);
  };

  const handleReplace = (): void => {
    setCloseDropdown(true);
    if (BFG.hasFeature('uppy_uploader_features')) {
      document.getElementById(uppyAttachmentReplaceTrigger)?.click();
    } else {
      render();
    }
  };

  useEffect(() => {
    if (picker) {
      picker.open();
    }
  }, [picker]);

  let actionsMenuInputs: (ActionDropdownItemProps & { content: string })[] = [
    {
      content: t`Delete`,
      icon: 'bff-trash',
      isWarningItem: true,
      name: 'delete',
      onChoose: handleDeleteAttachment,
    },
  ];

  if (extenstionSansPeriod !== 'bftemplate') {
    actionsMenuInputs = [
      {
        content: t`Rename`,
        icon: 'bff-edit',
        name: 'rename',
        onChoose: handleRename,
      },
      {
        content: t`Replace`,
        icon: 'bff-upload',
        name: 'replace',
        onChoose: handleReplace,
      },
      ...actionsMenuInputs
    ];
  }

  const actionItems = actionsMenuInputs.map((action) => (
    <ActionDropdownItem
      key={action.name}
      icon={action.icon}
      isWarningItem={action.isWarningItem}
      name={action.name}
      onChoose={action.onChoose}
    >
      {action.content}
    </ActionDropdownItem>
  ));

  const { over } = useDrop({
    format: DragAndDropFormats.TextPlain,
    onDropped: (data: string): void => {
      if (!dropRef.current) {
        return;
      }

      const parsedData = data.split('|');
      const draggedIndex = parseInt(parsedData[0]);
      const hoveredIndex = index;

      if (draggedIndex === hoveredIndex) {
        return;
      }

      setDroppedIndex(hoveredIndex);

      dispatch({
        type: ActionTypes.RepositionAttachment,
        payload: { draggedIndex, hoveredIndex },
      });
    },
    ref: dropRef
  });

  const { dragging } = useDrag({
    data: `${index}|${attachment.key}`,
    dragImageRef,
    effectAllowed: 'move',
    format: DragAndDropFormats.TextPlain,
    ref: dragRef
  });

  const displayedImage = attachment.thumbnailUrl || attachment.url;
  const hasAttachmentReplaceOption = actionsMenuInputs.map(({ name }) => name).includes('replace');

  return (
    <li
      ref={isDraggable ? mergeRefs([dragImageRef, dropRef]) : undefined}
      className={classNames({
        /* eslint-disable @typescript-eslint/naming-convention */
        'attachment-item': true,
        'is-dragging': dragging,
        'is-dropped': droppedIndex === index,
        'is-over': over
        /* eslint-enable @typescript-eslint/naming-convention */
      })}
    >
      {isDraggable && (
        <button
          ref={dragRef}
          aria-label={`${attachment.filename} reorder drag button`}
          className="attachment-item--reorder-button"
          type="button"
        >
          <FontIcon icon={FontIcons.DragIndicator} />
        </button>
      )}
      <div
        className={isDraggable
          ? 'attachment-item--thumbnail attachment-item--thumbnail--is-draggable'
          : 'attachment-item--thumbnail'
        }
        onMouseEnter={!isTouchScreen ? ((): void => setIsThumbHovered(true)) : undefined}
        onMouseLeave={!isTouchScreen ? ((): void => setIsThumbHovered(false)) : undefined}
      >
        <AttachmentThumbnailImage
          alt={attachment.filename}
          className="attachment-item--thumbnail__img"
          onError={(event: SyntheticEvent<HTMLImageElement, Event>): void => {
            handleThumbnailError(
              event,
              extenstionSansPeriod,
              filenameSansExt
            );
          }}
          src={displayedImage}
        />
      </div>
      {renameAction ? (
        <>
          <Input
            attributes={{
              autoFocus: true,
              className: 'attachment-item--filename',
              name: 'filename',
              onKeyPress: (e: KeyboardEvent<HTMLInputElement>): void => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                  (e.target as HTMLElement).blur();
                }
              },
              onBlur: (): void => setRenameAction(false),
              onChange: (e: InputChangeEvent): void => (
                dispatch({
                  type: ActionTypes.UpdateAttachmentDetails,
                  payload: {
                    index,
                    filename: `${e.target.value}.${extenstionSansPeriod}`,
                  }
                })
              ),
              type: InputType.Text,
            }}
            input={{ error: '', value: filenameSansExt }}
          />
          <span>{`.${extenstionSansPeriod}`}</span>
        </>
      ) : (
        <h4
          className={isDraggable
            ? 'attachment-item--filename attachment-item--filename--is-draggable'
            : 'attachment-item--filename'
          }
          onMouseEnter={!isTouchScreen ? ((): void => setIsNameHovered(true)) : undefined}
          onMouseLeave={!isTouchScreen ? ((): void => setIsNameHovered(false)) : undefined}
          title={attachment.filename}
        >
          {attachment.filename}
        </h4>
      )}
      {!disableFirstAttachment && (
        <>
          <ActionsDropdown
            className="attachment-item--menu-container"
            closeCallback={(): void => setCloseDropdown(false)}
            disabled={loading}
            openOnClick={false}
            openOnHover
            toggleDropdown={closeDropdown}
            userDevice={BFG.currentUser.device}
          >
            {actionItems}
          </ActionsDropdown>
          {BFG.hasFeature('uppy_uploader_features') && hasAttachmentReplaceOption && (
            <>
              {/* div visually hidden and hidden from screen-readers, only in DOM to hack opening Uppy */}
              <div
                aria-hidden
                className="attachment-replace-uppy-hidden"
                id={uppyAttachmentReplaceTrigger}
              >
                <p><Trans>Click to replace attachment</Trans></p>
              </div>
              <UppyUploader
                button
                handleUpload={(files): void => handleReplaceAttachment(files)}
                restrictions={{ maxNumberOfFiles: 1 }}
                template="file_ingest"
                trigger={`#${uppyAttachmentReplaceTrigger}`}
                uniqIdentifier={`attachment-uppy-${attachment.key}`}
              />
            </>
          )}
        </>
      )}
      {enableHoveredImage && (
        <CSSTransition
          classNames="fade-in"
          in={showHoveredImage}
          timeout={200}
          unmountOnExit
        >
          <div
            aria-hidden
            className="attachment-item--hovered-container"
            onMouseEnter={!isTouchScreen ? ((): void => setEnableHoveredImage(false)) : undefined}
          >
            <AttachmentThumbnailImage
              alt={attachment.filename}
              className="hovered-image"
              onError={(event: SyntheticEvent<HTMLImageElement, Event>): void => {
                handleThumbnailError(
                  event,
                  extenstionSansPeriod,
                  filenameSansExt,
                  undefined, // isLargeView
                  true
                );
              }}
              src={displayedImage}
            />
          </div>
        </CSSTransition>
      )}
    </li>
  );
};

export default AttachmentListItem;
