import {
  AlertLooks,
  ButtonLooks,
  CircleLoader,
  FontIcons,
  FontWeights,
  HeadingLevels,
  StandardAlert,
  StandardButton,
  StandardFieldset,
  StandardHeading,
  StandardText,
  StandardTextarea,
  StandardTextfield
} from '@brandfolder/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { decode } from 'html-entities';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { object, SchemaOf, string } from 'yup';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import { ApiDatum, ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { mapUserGroupServerToClient, UserGroupServer } from '@api/v4/user_groups';
import { UserGroupClient, UserGroupServerUpdate } from '@api/v4/user_groups/UserGroupsTypes';
import { User } from '@api/v4/UserTypes';
import { PermissionsUsersTabs, Tab } from '@components/bulk_management/user_groups/PermissionsUsersTabs';
import { UserGroups404 } from '@components/bulk_management/user_groups/UserGroups404';
import { useAlertTimeout } from '@components/common/custom_hooks/useAlertTimeout';
import { StandardDialog } from '@components/library/dialog';

import './styles/alert-transitions.scss';
import classes from './styles/user-groups.module.scss';

const schema = (): SchemaOf<UserGroupServerUpdate> => object().shape({
  description: string().max(2000, t`Maximum characters allowed is 2000`),
  name: string().required(t`Group name is required`)
});

export const UserGroupsEdit: FunctionComponent = () => {
  const params = useParams();
  const { userGroupKey } = params;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const created = query.get('created');
  const preselectedTab = query.get('tab');
  let defaultTab: Tab = 'users';
  if (preselectedTab === 'permissions' || preselectedTab === 'users') {
    defaultTab = preselectedTab;
  }

  const {
    error: errorGet,
    loading: loadingGet,
    response: responseGet
  } = useFetch<ApiDatum<UserGroupServer, 'user_groups', User, 'users'>>({
    loadingDelay: 50,
    url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/organization/user_groups/${userGroupKey}?include=creator`
  });

  const fetchOptions: UseFetchOptions = {
    fetchOnMount: false,
    method: 'PUT',
    url: `/api/v4/user_groups/${userGroupKey}`
  };

  const {
    error: errorUpdate,
    fetch: fetchUpdate,
    loading: loadingUpdate,
    response: responseUpdate
  } = useFetch<ApiDatumResponse<UserGroupServer, 'user_groups'>>(fetchOptions);

  const [showUpdate, setShowUpdate] = useState(false);
  const [userGroup, setUserGroup] = useState<UserGroupClient | null>(null);
  const [reloadMembers, setReloadMembers] = useState(false);
  const [currentTab, setCurrentTab] = useState<Tab>(defaultTab);
  const [showUpdateAlert, setShowUpdateAlert] = useAlertTimeout();
  const [showCreateAlert] =  useAlertTimeout({ initialShow: created === 'true' });

  const { formState, handleSubmit, register, reset } = useForm<UserGroupServerUpdate, unknown, UserGroupServerUpdate>({
    resolver: yupResolver(schema())
  });

  const { errors } = formState;

  useEffect(() => {
    if (responseGet?.data) {
      setUserGroup(mapUserGroupServerToClient(responseGet.data.attributes));
      reset({
        description: responseGet.data.attributes.description,
        name: decode(responseGet.data.attributes.name),
      });
    }
  }, [responseGet]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (responseUpdate?.data) {
      setShowUpdateAlert(true);
      setUserGroup(mapUserGroupServerToClient(responseUpdate.data.attributes));
      setShowUpdate(false);
      reset({
        description: responseUpdate.data.attributes.description,
        name: responseUpdate.data.attributes.name,
      });
    }
  }, [responseUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingGet) return <CircleLoader label={t`Loading`} />;

  if (errorGet && !loadingGet && !userGroup) {
    return <UserGroups404 />;
  }

  const { description, memberCount, name } = userGroup;

  return (
    <div className={classnames(classes.userGroups, 'user-group-detail-page')}>
      <section>
        <StandardText className={classes.return}>
          <Link className={classes.returnLink} to={`/${BFG.resource.slug}/bulk_management/user_groups`}>
            <Trans>Return to User Groups</Trans>
          </Link>
        </StandardText>
      </section>
      <section>
        <CSSTransition
          classNames="fade-in-translate"
          in={showCreateAlert && !showUpdateAlert}
          timeout={{
            // the alert should show immediately on load
            // but should fade out
            enter: 0,
            exit: 600
          }}
          unmountOnExit
        >
          <StandardAlert
            className={classnames(classes.alert, 'user-groups-alert--transition')}
            look={AlertLooks.Success}
          >
            <Trans>User group created successfully!</Trans>
          </StandardAlert>
        </CSSTransition>
        <CSSTransition
          classNames="fade-in-translate"
          in={!errorUpdate && !loadingUpdate && responseUpdate?.data && showUpdateAlert}
          timeout={600}
          unmountOnExit
        >
          <StandardAlert
            className={classnames(classes.alert, 'user-groups-alert--transition')}
            look={AlertLooks.Success}
          >
            <Trans>User group updated successfully!</Trans>
          </StandardAlert>
        </CSSTransition>
        <StandardDialog
          id="update-user-group-dialog"
          onClose={(): void => {
            setShowUpdate(false);
          }}
          open={showUpdate}
          setOpen={(): void => {
            setShowUpdate(false);
          }}
          showFooter={false}
          title={t`Edit group`}
          titleIcon={`bff-${FontIcons.Edit}`}
          width={450}
        >
          <>
            {loadingUpdate ? (
              <>
                <CircleLoader label={t`Loading`} />
              </>
            ) : (
              <>
                {errorUpdate && (
                  <StandardAlert
                    className={classes.alert}
                    look={AlertLooks.Error}
                  >
                    <Trans>
                      Something went wrong while updating the user group. Please try again or contact customer support.
                    </Trans>
                  </StandardAlert>
                )}

                <form
                  id="user-groups-update-form"
                  noValidate
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onSubmit={handleSubmit((values) => {
                    fetchUpdate({
                      ...fetchOptions,
                      body: {
                        data: {
                          attributes: values
                        }
                      }
                    });
                  })}
                >
                  <StandardFieldset
                    className={classes.updateFieldset}
                    legend={t`Update user group`}
                    showLegend={false}
                  >
                    <StandardTextfield
                      {...register('name')}
                      className={classes.updateTextfield}
                      error={errors.name?.message}
                      id="name"
                      label={<Trans>Group name</Trans>}
                      required
                      requiredLabel={t`Required`}
                    />
                    <StandardTextarea
                      {...register('description')}
                      className={classes.updateTextfield}
                      error={errors.description?.message}
                      id="description"
                      label={
                        <Trans>
                          Description&nbsp;
                          <small className={classes.small}>
                            (optional)
                          </small>
                        </Trans>
                      }
                      maxLength={2000}
                    />
                    <StandardButton
                      className={classes.updateButton}
                      id="update-user-group"
                      type="submit"
                    >
                      <Trans>Update group</Trans>
                    </StandardButton>
                  </StandardFieldset>
                </form>
              </>
            )}
          </>
        </StandardDialog>
        <>
          <StandardHeading
            className={classes.name}
            level={HeadingLevels.h2}
            weight={FontWeights.Book}
          >
            {decode(name)}
          </StandardHeading>
          {description && (
            <StandardText className={classnames(classes.description, 'user-group__description')}>
              {description}
            </StandardText>
          )}
          <StandardButton
            className={classes.edit}
            id="edit-user-group"
            look={ButtonLooks.Secondary}
            onClick={(): void => {
              setShowUpdate(true);
            }}
            startIcon={FontIcons.Edit}
          >
            <Trans>Edit user group</Trans>
          </StandardButton>
        </>
        <PermissionsUsersTabs
          currentTab={currentTab}
          memberCount={memberCount}
          reloadMembers={reloadMembers}
          setCurrentTab={setCurrentTab}
          setReloadMembers={setReloadMembers}
          userGroupKey={userGroupKey}
        />
      </section>
    </div>
  );
};
