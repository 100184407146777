import PropTypes from 'prop-types';
import React from 'react';

import Blitline from '@helpers/blitline';
import downloadFile from '@helpers/download';

import './styles/resize.scss';

class ResizeControls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloading: false,
      preserveAspectRatio: true,
    };
  }

  download() {
    this.setState({ downloading: true });
    const {
      width, height, url, filename, filetype
    } = this.props.asset;
    const blitline = new Blitline(url, filetype);
    blitline.convertTo(filetype, null, width, height).then((convertedUrl) => {
      downloadFile(convertedUrl, { filename, filetype }).then(() => {
        this.setState({ downloading: false });
      }).catch(() => {
        this.setState({ downloading: false });
      });
    }).catch(() => {
      this.setState({ downloading: false });
    });
  }

  cleanInput(_input) {
    let input = _input;
    if (!input) { return false; }

    if (typeof input === 'string') {
      input = parseInt(input, 10);
    }

    input = Math.floor(input);

    if (Number.isNaN(input)) { return false; }

    return input;
  }

  updateDimensions(_value, dimension) {
    // Allow an empty string so editing feels more natural;
    // having an empty string will disable the download button!
    if (_value === '') {
      this.props.onPropertyChange({
        width: '',
        height: '',
      });

      return;
    }

    const value = this.cleanInput(_value);
    if (value === false) { return; }

    let width;
    let height;

    if (dimension === 'width') {
      width = value;
      if (this.state.preserveAspectRatio) {
        height = this.props.asset.scaleHeightTo(width);
      }
    } else {
      height = value;
      if (this.state.preserveAspectRatio) {
        width = this.props.asset.scaleWidthTo(height);
      }
    }

    this.props.onPropertyChange({
      width,
      height,
    });
  }

  validInput() {
    if (!this.props.asset) { return false; }
    const { width, height } = this.props.asset;
    return (typeof width === 'number' && width > 0) && (typeof height === 'number' && height > 0);
  }

  render() {
    const width = this.props.asset ? this.props.asset.width : '';
    const height = this.props.asset ? this.props.asset.height : '';
    const disabled = !this.props.asset;
    const buttonDisabled = !this.validInput() || disabled || this.state.downloading;

    return (
      <div className="workbench-resize">
        <div className="workbench-resize__control-group">
          <div className="workbench-resize__control">
            <label
              className={disabled ? 'disabled' : ''}
              htmlFor="resizeWidth"
            >
              Width (px)
            </label>
            <input
              data-testid="resizeWidth"
              disabled={disabled}
              name="resizeWidth"
              onChange={(event) => this.updateDimensions(event.target.value, 'width')}
              type="number"
              value={width}
            />
          </div>

          <div className="workbench-resize__control">
            <span className="bff-private" />
          </div>

          <div className="workbench-resize__control">
            <label
              className={disabled ? 'disabled' : ''}
              htmlFor="resizeHeight"
            >
              Height (px)
            </label>
            <input
              data-testid="resizeHeight"
              disabled={disabled}
              name="resizeHeight"
              onChange={(event) => this.updateDimensions(event.target.value, 'height')}
              type="number"
              value={height}
            />
          </div>
        </div>

        <button
          className="button dark-blue large"
          data-testid="download"
          disabled={buttonDisabled}
          onClick={() => this.download()}
          type="button"
        >
          Download
        </button>
        {this.props.renderResetButton()}
      </div>
    );
  }
}

ResizeControls.propTypes = {
  asset: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    url: PropTypes.string,
    filename: PropTypes.string,
    filetype: PropTypes.string,
    scaleWidthTo: PropTypes.func,
    scaleHeightTo: PropTypes.func,
  }),
  onPropertyChange: PropTypes.func,
  renderResetButton: PropTypes.func
};

ResizeControls.defaultProps = {
  asset: null,
  onPropertyChange: () => {},
  renderResetButton: () => {}
};

export default ResizeControls;
