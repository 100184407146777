export const SHARE_LINK_NOTIFICATIONS_PREFERENCE_KEY = 'bf-share-link-notifications-preference';
export const EXPORT_CROPMARKS_PREFERENCE_KEY = 'bf-design-huddle-export-cropmarks-preference';
export const EXPORT_FILE_TYPE_PREFERENCE_KEY = 'bf-design-huddle-export-file-type-preference';
export const FILESTACK_CREDENTIALS_PREFERENCE_KEY = 'bf-filestack-credentials-preference';

interface StorageAvailable {
  success: boolean;
  code?: number;
  message?: string;
  name?: string;
}

export enum StorageTypes {
  Local = 'localStorage',
  Session = 'sessionStorage'
}

/**
 * Get whether storage is available in the browser (either localStorage or sessionStorage).
 * Modified from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API.
 * There can be some instances where storage is full, in which case this returns { success: false }.
 * @param type StorageTypes
 * @returns StorageAvailable
 */
export const getIsStorageAvailable = (type: StorageTypes): StorageAvailable => {
  try {
    const x = '__storage_test__';
    window[type].setItem(x, x);
    window[type].removeItem(x);
    return {
      success: true
    };
  } catch (err) {
    const error = err as DOMException;
    return {
      code: error?.code,
      message: error?.message,
      name: error?.name,
      success: false
    };
  }
};

/**
 * Get if storage quota is exceeded
 * @returns boolean
 */
export const getIsStorageQuotaExceeded = (name: string): boolean => {
  return name === 'QuotaExceededError' || name === 'NS_ERROR_DOM_QUOTA_REACHED';
};

/**
 * Get storage string value for a given key.
 * Will check to make sure that storage is available in the browser.
 * INTERNAL ONLY. You probably want getStorage.
 * @param type StorageTypes
 * @param key string
 * @returns string | null
 */
const getStorageItem = (type: StorageTypes, key: string): string | null => {
  if (getIsStorageAvailable(type).success) {
    const s = window[type].getItem(key); // getItem returns undefined if not set
    return s || null; // IMPORTANT: make sure this never returns undefined
  }
  // null can be handled by JSON.parse(null) without erroring
  return null;
};

/**
 * Get storage object for a given key. Calls JSON.parse to return your expected value.
 * May return null if your value isn't found.
 * Will check to make sure that storage is available in the browser.
 * @param type StorageTypes
 * @param key string
 * @returns  T | null
 */
export const getStorage = <T>(type: StorageTypes, key: string): T | null => {
  const value = getStorageItem(type, key);
  try {
    // value here could be bad/invalid, always try/catch with JSON.parse
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
};

/**
 * Set storage item.
 * INTERNAL ONLY. You probably want setStorage.
 * @param type StorageTypes
 * @param key string
 * @param value unknown
 * @returns boolean
 */
const setStorageItem = (type: StorageTypes, key: string, value: unknown): boolean => {
  try {
    // value here could be bad/invalid, always try/catch with JSON.stringify
    window[type].setItem(key, JSON.stringify(value));
    return true;
  } catch (err) {
    // JSON.stringify probably failed
    return false;
  }
};

/**
 * Remove an item from storage
 * @param type StorageTypes
 * @param key string
 * @returns boolean
 */
export const removeStorage = (type: StorageTypes, key: string): boolean => {
  if (getIsStorageAvailable(type).success) {
    window[type].removeItem(key);
    return true;
  }
  return false;
};

/**
 * Removes any storage items that begin with '__/api/brandfolder'
 * TODO: Remove this when 'bootstrap-typeahead' is removed
 */
export const removeBootstrapTypeaheadLocalStorageItems = (key: string, value: unknown): boolean => {
  let removedAndSet = false;

  const bootstrapTypeaheadKeys = Object.keys(window.localStorage).filter((k) => k.includes('__/api/brandfolder'));
  if (bootstrapTypeaheadKeys && bootstrapTypeaheadKeys.length > 0) {
    // the 11 here is arbitrary, we just remove 10 items from local storage
    // since local storage methods are thread blocking, we don't want this to run forever
    for (let i = 0; i < bootstrapTypeaheadKeys.length && i < 11; i++) {
      const bootstrapTypeaheadKey = bootstrapTypeaheadKeys[i];
      removeStorage(StorageTypes.Local, bootstrapTypeaheadKey);
      removedAndSet = setStorageItem(StorageTypes.Local, key, value);
      if (removedAndSet) break;
    }
  }

  return removedAndSet;
};

/**
 * Cleanup storage, then try and set the key/value pair.
 * Storage is limited to 5–10mb (https://en.wikipedia.org/wiki/Web_storage).
 * @param type StorageTypes
 * @param key string
 * @param value unknown
 * @returns boolean
 */
export const cleanupStorage = (type: StorageTypes, key: string, value: unknown): boolean => {
  let removedAndSet = false;

  // try removing and setting the key and value (it may be smaller than before so we're under the limit)
  removeStorage(type, key);
  removedAndSet = setStorageItem(type, key, value);
  if (removedAndSet) return true;

  if (type === StorageTypes.Local) {
    // try removing other brandfolder storage items
    removedAndSet = removeBootstrapTypeaheadLocalStorageItems(key, value);
    if (removedAndSet) return true;
  }

  // add other storage cleanups here before the return
  return removedAndSet;
};

/**
 * Set storage value for a given key.
 * Storage is limited to 5–10mb (https://en.wikipedia.org/wiki/Web_storage).
 * @param type StorageTypes
 * @param key string
 * @param value unknown
 * @returns boolean
 */
export const setStorage = (type: StorageTypes, key: string, value: unknown): boolean => {
  const isAvailable = getIsStorageAvailable(type);
  if (isAvailable.success) {
    return setStorageItem(type, key, value);
  } else if (getIsStorageQuotaExceeded(isAvailable.name)) {
    return cleanupStorage(type, key, value);
  }
  return false;
};
