const DevEnvDatadogRumStub = {
  sendAction: (userEvent: TrackedAction | string, context?: object): void => {
    console.log(`Event Action: ${userEvent}`, context);
  },
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  sendError: (error: unknown, context?: object): void => { console.error(`Error: ${error}`, context); },
};

export default (window?.DD_RUM || DevEnvDatadogRumStub);

export const sendAction = (userEvent: TrackedAction | string, context?: object): void => {
  try {
    window?.DD_RUM?.addAction(userEvent, context);
  } catch (err) {
    console.error(err);
  }
};

export const sendError = (error: unknown, context?: object): void => {
  try {
    window?.DD_RUM?.addError(error, context);
  } catch (err) {
    console.error(err);
  }
};

export enum TrackedAction {
  AssetModalEditTabCreateAsset = 'asset_modal_edit_tab_create_asset',
  AssetModalEditTabModifyAsset = 'asset_modal_edit_tab_modify_asset',
  AssetModalEditTabSubmissionPartialSuccess = 'asset_modal_edit_tab_submission_partial_success',
  AssetModalEditTabUGTLocaleChange = 'asset_modal_edit_tab_ugt_locale_change',
  Boards = 'boards',
  BrandguideCreateError = 'brandguide_create_error',
  BrandguideCreateOpen = 'brandguide_create_open',
  BrandguideCreateSuccess = 'brandguide_create_success',
  CommentsAndAnnotations = 'comments_and_annotations',
  Cropping = 'cropping',
  GuestUploadAbandonment = 'guest_upload_abandonment',
  RequiredCustomFieldsAssetUploadError = 'required_custom_fields_asset_upload_error',
  RequiredCustomFieldsCreateError = 'required_custom_fields_create_error',
  ShareLinkAssetAdd = 'share_link_asset_add',
  ShareLinkAssetAddSuccess = 'share_link_asset_add_success',
  ShareLinkAssetAddError = 'share_link_asset_add_error',
  ShareLinkAssetRemoval = 'share_link_asset_removal',
  SmartsheetPublish = 'smartsheet_publish',
  SmartsheetPublishNoResources = 'smartsheet_publish_no_resources',
  WebToPrint = 'web_to_print',
  WorkspaceAssetAdd = 'workspace_asset_add',
  WorkspaceAssetAddError = 'workspace_asset_add_error',
  WorkspaceCreateError = 'workspace_create_error',
  WorkspaceCreateOpen = 'workspace_create_open',
  WorkspaceCreateSuccess = 'workspace_create_success',
  WorkspaceCreateTask = 'workspace_create_task',
  WorkspaceCreateTaskPartialSuccess = 'workspace_create_task_partial_success',
  // eslint-disable-next-line max-len
  WorkspaceSmartsheetConnectClickThroughToConnectedSheet = 'workspace_smartsheet_connect_click_through_to_connected_sheet',
  WorkspaceSmartsheetConnectError = 'workspace_smartsheet_connect_error',
  WorkspaceSmartsheetConnectOAuthSuccess = 'workspace_smartsheet_connect_oauth_success',
  WorkspaceUpdateTask = 'workspace_update_task',
  WorkspaceVisit = 'workspace_visit',
}
