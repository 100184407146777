import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import FocusLock from 'react-focus-lock';
import { CSSTransition } from 'react-transition-group';

import {
  BaseDialogProps,
  DialogAnimations,
  DialogFooter,
  DialogSizes,
  DialogTypes,
  DrawerAnimations,
} from '@components/library/dialog';
import {
  Dialog,
  DialogBackground,
  DialogBody,
  DialogHeader,
  DialogInner,
  DialogOverlay,
  DialogType,
  DialogWrapper,
} from '@components/library/dialog/base_dialog';

/**
 * An accessible dialog component for creating modals or drawers.
 * A dialog should only be used when we need to stop the user from interacting with the rest of the application
 * (think hard about this as it has accessibility implications... don't use a dialog if at all possible).
 * Accessibility requirements can be found at https://www.w3.org/TR/wai-aria-practices-1.2/#dialog_modal
 * @param {BaseDialogProps} props BaseDialogProps
 */
export const BaseDialog: FunctionComponent<BaseDialogProps> = (props) => {
  const {
    animation,
    animationTimeout = 300,
    beforeClose,
    children,
    closeOnBackgroundClick = true,
    contentCentered,
    dialogBackgroundClassName,
    dialogBodyClassName,
    dialogClassName,
    dialogFooterClassName,
    dialogHeaderClassName,
    dialogInnerClassName,
    dialogOverlayClassName,
    focusLock = true,
    footer,
    header,
    height,
    id,
    loading,
    next,
    onClose,
    open,
    prev,
    setOpen,
    showClose = true,
    showFooter = true,
    showTitle = true,
    size = DialogSizes.Auto,
    title,
    titleIcon,
    type = DialogTypes.Modal,
    width,
  } = props;
  const [isMounted, setIsMounted] = useState(false);

  const envAnimationTimeout = (BFG.environment === 'js-test') ? 0 : animationTimeout;

  /**
   * toggles "dialog-open" CSS class, which is needed to add overflow: hidden on the body element.
   * This prevents vertical scrolling and makes background content inert in accordiance with w3c guidelines.
   * https://www.w3.org/TR/wai-aria-practices-1.2/#dialog_modal
   * @returns {void} void
   */
  const updateBodyClass = (action: 'close' | 'open'): void => {
    const body = document.getElementsByTagName('body');

    if (
      body.length > 0 &&
      action === 'close' &&
      body[0].classList.contains('dialog-open')
    ) {
      body[0].classList.remove('dialog-open');
    }

    if (
      body.length > 0 &&
      action === 'open' &&
      !body[0].classList.contains('dialog-open')
    ) {
      body[0].classList.add('dialog-open');
    }
  };

  /**
   * Closes, the dialog, removes "dialog-open" CSS class and calls the onClose callback.
   * @returns {void} void
   */
  const close = (): void => {
    // setOpen should be first
    if (setOpen) {
      setOpen(false);
    }

    if (onClose) {
      onClose();
    }

    updateBodyClass('close');
  };

  /**
   * If beforeClose is passed as a prop, closes the dialog only if it returns true.
   * @returns {void} void
   */
  const handleClose = (forceClose?: boolean): void => {
    if (beforeClose && !forceClose) {
      const shouldClose = beforeClose();
      if (shouldClose) {
        close();
      }
    } else {
      close();
    }
  };

  /**
   * It is required dialogs should close on escape. DO NOT REMOVE.
   * https://www.w3.org/TR/wai-aria-practices-1.2/#dialog_modal
   * @param {KeyboardEvent} e KeyboardEvent
   * @returns {void} void
   */
  const handleCloseOnEsc = (e: KeyboardEvent): void => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  useEffect(() => {
    // DO NOT REMOVE
    setIsMounted(true); // ensures component is mounted before enabling CSSTransition, otherwise CSSTransition classes are not added correctly
    window.addEventListener('keyup', handleCloseOnEsc);
    return (): void => {
      window.removeEventListener('keyup', handleCloseOnEsc);
      handleClose();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      updateBodyClass('open');
    }

    if (!open && isMounted) {
      handleClose(true);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const ariaLabelledBy = `${id}-label`;
  const animate =
    animation ||
    (type === DialogTypes.Modal
      ? DialogAnimations.FadeInFromTop
      : DrawerAnimations.FadeInFromRight);

  const renderInner = (): ReactNode => (
    <>
      {!!prev && prev}
      <DialogInner className={dialogInnerClassName}>
        <DialogHeader
          className={dialogHeaderClassName}
          handleClose={handleClose}
          id={ariaLabelledBy}
          showClose={showClose}
          showTitle={showTitle}
          title={title}
          titleIcon={titleIcon}
        >
          {header}
        </DialogHeader>
        <DialogBody
          className={dialogBodyClassName}
          contentCentered={contentCentered}
          loading={loading}
        >
          {children}
        </DialogBody>
        {showFooter && (
          <DialogFooter className={dialogFooterClassName}>
            {footer}
          </DialogFooter>
        )}
      </DialogInner>
      {!!next && next}
    </>
  );

  return (
    <CSSTransition
      classNames={animate}
      in={isMounted && open}
      mountOnEnter
      timeout={envAnimationTimeout}
      unmountOnExit
    >
      <Dialog className={dialogClassName}>
        <DialogWrapper>
          <DialogType
            animation={animate}
            aria-labelledby={ariaLabelledBy}
            height={height}
            id={id}
            size={size}
            type={type}
            width={width}
          >
            {focusLock ? (
              <FocusLock crossFrame={false}>{renderInner()}</FocusLock>
            ) : (
              renderInner()
            )}
          </DialogType>
          {closeOnBackgroundClick && (
            <DialogBackground
              className={dialogBackgroundClassName}
              handleClose={handleClose}
              id={id}
            />
          )}
        </DialogWrapper>
        <DialogOverlay className={dialogOverlayClassName} size={size} />
      </Dialog>
    </CSSTransition>
  );
};
