import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { localizeNumber } from '@helpers/locale';
import { defaultCroppedData } from './helper';

const CropDimensions = ({
  canvasData,
  containerData,
  croppedData,
  fixedDimensions,
  imageToThumbnailRatio,
  selectedPreset,
  zoomRatio
}) => {
  const [windowOffset, setWindowOffset] = useState({ deltaWidth: 0, deltaHeight: 0 });
  const [initialWindowOffset, setInitialWindowOffset] = useState({ deltaWidthInitial: 0, deltaHeightInitial: 0 });

  // constants: must be updated when related styles update
  const dimensionsBoxHeight = 35;
  const displayOffsetWidth = 45;
  const displayOffsetHeight = 10;
  const cropperFooterHeight = 70;
  const modalDimensionPercentage = 0.9; // accounts for modal height/width style (90vh and 90vw)

  const onResize = (e, containerDataPassed) => {
    setWindowOffset({
      deltaWidth: e.target.innerWidth * modalDimensionPercentage - containerDataPassed.width,
      deltaHeight: e.target.innerHeight * modalDimensionPercentage - containerDataPassed.height,
    });
  };

  useEffect(() => (() => window.removeEventListener('resize', (e) => onResize(e, containerData))), []);

  useEffect(() => {
    if (!containerData) { return undefined; }

    const deltaW = window.innerWidth * modalDimensionPercentage - containerData.width;
    const deltaH = window.innerHeight * modalDimensionPercentage - containerData.height;
    setWindowOffset({ deltaWidth: deltaW, deltaHeight: deltaH });
    setInitialWindowOffset({ deltaWidthInitial: deltaW, deltaHeightInitial: deltaH });
    window.addEventListener('resize', (e) => onResize(e, containerData));
    return () => window.removeEventListener('resize', (e) => onResize(e, containerData));
  }, [containerData]);

  if (!canvasData || !croppedData || !containerData) { return null; }

  // -calculate absolute offsets for crop dimension box
  // -cropped data must be scaled using zoom Ratio
  // -container height must be adjusted to account for window resizing because container data
  // doesn't update on resize
  const cropBoxOffsetFromTop = zoomRatio * (croppedData.y + croppedData.height);
  const displayOffsets = dimensionsBoxHeight + displayOffsetHeight - cropperFooterHeight;
  const containerHeight = containerData.height + (windowOffset.deltaHeight - initialWindowOffset.deltaHeightInitial);
  const left = zoomRatio * croppedData.x + canvasData.left;
  const right = containerData.width - canvasData.left - zoomRatio * (croppedData.x + croppedData.width);
  let bottom = containerHeight - cropBoxOffsetFromTop - canvasData.top - displayOffsets;

  // position crop dimension box from left unless crop dimension box will be squished on right side
  const optionsContainerWidth = 250;
  const offsetFromLeft = left < (containerData.width - optionsContainerWidth);

  // move crop dimension box inside crop box if crop dimensions box is near edge of container
  if (bottom < (displayOffsetHeight + cropperFooterHeight)) {
    bottom += dimensionsBoxHeight + 2 * displayOffsetHeight;
  }

  const styles = {
    height: `${dimensionsBoxHeight}px`,
    ...offsetFromLeft && { left: `${left + displayOffsetWidth}px` },
    ...!offsetFromLeft && { right: `${right + displayOffsetWidth}px` },
    bottom: bottom === 0 ? 0 : `${bottom}px`,
  };

  const widthValue = (fixedDimensions && selectedPreset)
    ? selectedPreset.value.width
    : Math.round(croppedData.width * imageToThumbnailRatio);
  const heightValue = (fixedDimensions && selectedPreset)
    ? selectedPreset.value.height
    : Math.round(croppedData.height * imageToThumbnailRatio);

  return (
    <div
      className="crop-dimensions-container"
      style={styles}
    >
      <h4 className="width">{`W: ${localizeNumber(widthValue)}px`}</h4>
      <h4 className="height">{`H: ${localizeNumber(heightValue)}px`}</h4>
    </div>
  );
};

CropDimensions.propTypes = {
  canvasData: PropTypes.shape({
    height: PropTypes.number,
    left: PropTypes.number,
    naturalHeight: PropTypes.number,
    naturalWidth: PropTypes.number,
    top: PropTypes.number,
    width: PropTypes.number,
  }),
  containerData: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  croppedData: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  fixedDimensions: PropTypes.bool.isRequired,
  imageToThumbnailRatio: PropTypes.number.isRequired,
  selectedPreset: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({}),
  }),
  zoomRatio: PropTypes.number.isRequired,
};

CropDimensions.defaultProps = {
  canvasData: null,
  containerData: null,
  croppedData: defaultCroppedData,
  selectedPreset: null,
};

export default CropDimensions;
