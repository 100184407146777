import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import classnames from 'classnames';
import dragDropTypes from '@components/common/drop_zone/drag_drop_types';

import './_drop_zone.scss';

const DropZone = (props) => {
  const {
    dropHalf,
    hoveredItemKey,
  } = props; // parent props

  const {
    draggedItem,
    isHovered,
    connectDropTarget,
  } = props; // React DnD props

  const isDragged = (draggedItem && draggedItem.key) === hoveredItemKey;

  return connectDropTarget(
    <span
      key="0"
      className={classnames(
        'drop-zone',
        `drop-zone-${dropHalf}`,
        { "is-hovered": isHovered && !isDragged },
      )}
    />
  );
};

DropZone.propTypes = {
  dropHalf: PropTypes.string.isRequired,
  hoveredItemKey: PropTypes.string.isRequired,

  // props used for React DnD wrapper
  /* eslint-disable react/no-unused-prop-types */
  handleReposition: PropTypes.func.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

const setType = ({ dragDropType }) => (dragDropTypes[dragDropType]);

const dropTarget = {
  drop(props, monitor) {
    const { draggedIndex } = monitor.getItem();
    const hoveredItem = {
      key: props.hoveredItemKey,
      hoveredHalf: props.dropHalf
    };

    props.handleReposition({ hoveredItem, draggedIndex });
  }
};

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  draggedItem: monitor.getItem(),
  isHovered: monitor.isOver(),
});

export default DropTarget(setType, dropTarget, dropCollect)(DropZone);
