import { localizeDate } from '@brandfolder/utilities';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { DetailedUser } from '@api/v4/private/resources/users';

import '../styles/user-details.scss';

const Detail: FunctionComponent = ({ children }) => (
  <span className="user-details__detail">{ children }</span>
);

export const UserDetails: FunctionComponent<{ user: DetailedUser }> = ({ user }) => {
  const {
    company,
    country,
    created_with_saml: createdWithSaml,
    created_with_sso: createdWithSso,
    department,
    email,
    full_name: fullName,
    last_active_at: lastActiveAt,
    locale,
    title
  } = user;

  let emailModifier = '';
  if (createdWithSaml) {
    emailModifier = ` ${t`(SAML)`}`;
  } else if (createdWithSso) {
    emailModifier = ` ${t`(SSO)`}`;
  }

  let localeDisplay = '';
  if (country && locale) {
    localeDisplay = t`${country}, Locale: ${locale}`;
  } else if (country) {
    localeDisplay = country;
  } else if (locale) {
    localeDisplay = t`Locale: ${locale}`;
  }

  const lastActiveAtDate = lastActiveAt ? localizeDate(lastActiveAt) : '';

  return (
    <div className="user-details">
      <span className="user-details__name-heading">{`${fullName || email} ${emailModifier}`}</span>
      <Detail>
        <a
          className="user-details__email"
          href={`mailto:${email}`}
          target="_blank"
        >
          {email}
        </a>
      </Detail>
      {title && <Detail>{title}</Detail>}
      {department && <Detail>{department}</Detail>}
      {company && <Detail>{company}</Detail>}
      {localeDisplay && <Detail>{localeDisplay}</Detail>}
      {lastActiveAt && <Detail><Trans>Last login: {lastActiveAtDate}</Trans></Detail>}
    </div>
  );
};
