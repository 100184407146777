import { t } from '@lingui/macro';
import convert from 'color-convert';
import { CMYK } from 'color-convert/conversions';
import React, { FunctionComponent, useContext } from 'react';

import { ColorData } from '@api/v4/assets/assetTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import ColorCircle from '@components/asset/modal/tabs/edit/side_bar/color_assets/colorCircle';
import Tooltip from '@components/common/tooltip/main';
import { InputType } from '@components/library/inputs/Input.props';
import Input from '@components/library/inputs/PrimaryInput';
import Label from '@components/library/labels/PrimaryLabel';

import { CmykString } from './colorSideBar';

interface CMYKScopedContext {
  state: {
    editState: {
      colorData: {
        c: string;
        m: string;
        y: string;
        k: string;
      };
    };
  };
}

const CMYKInputs: FunctionComponent<{
  handleColorUpdate: (partial: Partial<ColorData>) => void;
  autofillEmptyColors: (partial: Partial<ColorData>) => void;
  prepCmykColorValues: (values: CmykString) => CMYK;
}> = ({ handleColorUpdate, autofillEmptyColors, prepCmykColorValues }) => {
  const { state }: CMYKScopedContext = useContext(assetModalContext);

  if (!state.editState.colorData) {
    return null;
  }
  const { c, m, y, k } = state.editState.colorData;

  const getBackgroundColor = (): string => {
    /**
     * color-convert returns #000000 when given invalid or empty inputs
     * so to determine whether it was actually invalid we need to make sure
     * that black wasn't the actual input
     */
    const isValidBlackCombination = (k === '100') || (c === '100' && m === '100' && y === '100');
    const hex = convert.cmyk.hex(
      prepCmykColorValues({ c, m, y, k })
    );
    return hex === '000000' && !isValidBlackCombination
      ? ''
      : hex;
  };

  const autofillOnBlur = (): void => {
    autofillEmptyColors({ c, m, y, k });
  };

  return (
    <>
      <div className="edit-tab-sidebar-color-label-swatch-container">
        <Label attributes={{ className: 'edit-tab-sidebar-label' }}>CMYK</Label>
        <Tooltip
          placement="right"
          tooltipContent={t`Displayed color for CMYK is approximate. Consider printing CMYK color before finalizing CMYK color values.`}
          tooltipId="cmyk-edit"
          type="light"
        >
          <span className="bff-warning" />
        </Tooltip>
        <ColorCircle
          getBackgroundColor={getBackgroundColor}
          id="color-circle-cmyk"
        />
      </div>
      <div className="edit-tab-sidebar-color-input-and-color">
        <div className="edit-tab-sidebar-color-flex-inputs">
          <Input
            attributes={{
              'aria-label': 'cmyk c',
              className: 'edit-tab-sidebar-color-cmyk',
              max: 100,
              min: 0,
              name: 'c',
              onChange: (e: InputChangeEvent): void => handleColorUpdate({ c: e.target.value }),
              onBlur: autofillOnBlur,
              type: InputType.Number,
              value: c,
            }}
            input={{ value: c }}
          />
          <Input
            attributes={{
              'aria-label': 'cmyk m',
              className: 'edit-tab-sidebar-color-cmyk',
              max: 100,
              min: 0,
              name: 'm',
              onChange: (e: InputChangeEvent): void => handleColorUpdate({ m: e.target.value }),
              onBlur: autofillOnBlur,
              type: InputType.Number,
              value: m,
            }}
            input={{ value: m }}
          />
          <Input
            attributes={{
              'aria-label': 'cmyk y',
              className: 'edit-tab-sidebar-color-cmyk',
              max: 100,
              min: 0,
              name: 'y',
              onChange: (e: InputChangeEvent): void => handleColorUpdate({ y: e.target.value }),
              onBlur: autofillOnBlur,
              type: InputType.Number,
              value: y,
            }}
            input={{ value: y }}
          />
          <Input
            attributes={{
              'aria-label': 'cmyk k',
              className: 'edit-tab-sidebar-color-cmyk',
              max: 100,
              min: 0,
              name: 'k',
              onChange: (e: InputChangeEvent): void => handleColorUpdate({ k: e.target.value }),
              onBlur: autofillOnBlur,
              type: InputType.Number,
              value: k,
            }}
            input={{ value: k }}
          />
        </div>
      </div>
    </>
  );
};

export default CMYKInputs;
