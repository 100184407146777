import { keyPressHelper } from '@brandfolder/utilities';
import React, {
  FunctionComponent,
  KeyboardEvent,
} from 'react';
import classnames from 'classnames';

import { Checkbox, LabelPositions, Sizes } from '@components/library/checkbox/index';

interface CheckboxProps {
  styleClassNames: { [key: string]: string };
  variantName: string;
  containerId?: string;
}

const baseName = 'checkbox';

const BaseCheckbox: FunctionComponent<CheckboxProps & Checkbox> = (
  props,
) => {
  const {
    ariaLabel,
    checked = false,
    className,
    containerId,
    disabled = false,
    dontWrapText,
    id,
    labelCopy,
    labelPosition = LabelPositions.Right,
    name,
    onChange,
    partiallyChecked = false,
    size = Sizes.Small,
    styleClassNames,
    tabIndex,
    value,
    variantName,
  } = props;

  const onKeyPressHandler = keyPressHelper(onChange);
  const checkmarkPath = 'M18 48l60 0';
  const dashPath = 'M18 48l24 24 42-48';

  return (
    <div
      className={classnames(
        `bf-${baseName}__${variantName}`, // base-variant class for post consumption styling
        styleClassNames[variantName], // class for css modules
        className, // user specified class
        styleClassNames[size],
        styleClassNames[labelPosition],
        {
          [styleClassNames['partially-checked']]: partiallyChecked,
          checked,
        }
      )}
    >
      <label
        aria-checked={checked}
        aria-disabled={disabled}
        aria-label={ariaLabel}
        className={classnames(
          `bf-${baseName}__${variantName}--label`,
          [styleClassNames.label],
          {
            [styleClassNames.disabled]: disabled,
            [styleClassNames['dont-wrap-text']]: dontWrapText,
            [styleClassNames['no-label-copy']]: !labelCopy,
          }
        )}
        onKeyPress={disabled ? undefined : (e: (KeyboardEvent<HTMLLabelElement> & InputChangeEvent)): void => onKeyPressHandler(e, id, e)}
        tabIndex={tabIndex || 0}
      >
        <input
          aria-labelledby={containerId}
          checked={checked}
          className={classnames(
            `bf-${baseName}__${variantName}--input`,
            [styleClassNames.input]
          )}
          disabled={disabled}
          id={id}
          name={name}
          onChange={disabled || !onChange ? undefined : (e: InputChangeEvent): void => onChange(id, e)}
          readOnly={!onChange}
          tabIndex={-1}
          type="checkbox"
          value={value}
        />
        <div
          className={classnames(
            styleClassNames['checkmark-container'],
            'checkmark-container',
          )}
        >
          <svg
            className={`checkmark ${styleClassNames.checkmark}`}
            viewBox="0 0 100 100"
          >
            <path
              className={`path ${styleClassNames.path}`}
              d={partiallyChecked ? checkmarkPath : dashPath}
            />
          </svg>
        </div>
        {labelCopy}
      </label>
    </div>
  );
};

export default BaseCheckbox;
