import { t, Trans } from '@lingui/macro';
import React, { useState, FunctionComponent, ChangeEvent } from 'react';

import { update as updateResource } from '@api/v4/resources/resources';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { PrimaryButton } from '@components/library/button';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';

import './styles/_download-alert-setting.scss';

interface DownloadAlertSettingProps {
  downloadAlert: string;
  downloadAlertEnabled: boolean;
  resourceKey?: string;
  resourceType?: string;
}

const DownloadAlertSetting: FunctionComponent<DownloadAlertSettingProps> = ({ downloadAlert, downloadAlertEnabled, resourceKey, resourceType }) => {
  // we need the "initial" state variables in the case where the user edits the setting more than once without
  // closing the settings modal - that is, we can't rely on the props provided to change and trigger a rerender
  const [initialDownloadAlert, setInitialDownloadAlert] = useState(downloadAlert);
  const [initialDownloadAlertEnabled, setInitialDownloadAlertEnabled] = useState(downloadAlertEnabled);
  const [alertEnabled, setAlertEnabled] = useState(downloadAlertEnabled);
  const [downloadAlertInput, setDownloadAlertInput] = useState(downloadAlert);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const notifySuccess = (): void => Notify.create({
    title: t`Download alert setting updated!`,
    type: 'success'
  });

  const notifyFailure = (): void => Notify.create({
    title: t`Error updating download alert setting. Please contact support.`,
    type: 'error'
  });

  const submitDisableDownloadAlert = async (): Promise<void> => {
    try {
      await updateResource({
        key: resourceKey || BFG?.resource?.key,
        resourceType: resourceType || BFG?.resource?.type,
        downloadAlertEnabled: false
      });

      notifySuccess();
      setInitialDownloadAlertEnabled(false);
    } catch {
      notifyFailure();
    }
  };

  const submitDownloadAlertUpdate = async (): Promise<void> => {
    try {
      await updateResource({
        key: resourceKey || BFG?.resource?.key,
        resourceType: resourceType || BFG?.resource?.type,
        downloadAlert: downloadAlertInput !== initialDownloadAlert ? downloadAlertInput : undefined,
        downloadAlertEnabled: alertEnabled !== initialDownloadAlertEnabled ? alertEnabled : undefined
      });

      if (downloadAlertInput !== initialDownloadAlert) {
        setInitialDownloadAlert(downloadAlertInput);
      }
      if (alertEnabled !== initialDownloadAlertEnabled) {
        setInitialDownloadAlertEnabled(alertEnabled);
      }
      setIsSubmitting(false);
      notifySuccess();
    } catch {
      notifyFailure();
    }
  };

  return (
    <I18nProviderWrapper>
      <div className="download-alert-setting-container">
        <div className="advanced-tab-settings__row">
          <div className="quarter-column">
            <p><Trans>Download Alert</Trans></p>
          </div>
          <div className="three-quarters-column">
            <div className="advanced-tab-settings__options">
              <div className="radio-button-container">
                <input
                  checked={alertEnabled}
                  className="radio-margin"
                  id="download_alert_enabled"
                  name="download_alert"
                  onChange={(): void => { setAlertEnabled(true); }}
                  type="radio"
                  value="true"
                />
                <label htmlFor="download_alert_enabled">
                  <Trans>Enabled</Trans>
                </label>
              </div>
              <div className="radio-button-container">
                <input
                  checked={!alertEnabled}
                  className="radio-margin"
                  id="download_alert_disabled"
                  name="download_alert"
                  onChange={(): void => {
                    setAlertEnabled(false);
                    if (initialDownloadAlertEnabled) {
                      submitDisableDownloadAlert();
                    }
                  }}
                  type="radio"
                  value="false"
                />
                <label htmlFor="download_alert_disabled"><Trans>Disabled</Trans></label>
              </div>
            </div>
          </div>
        </div>
        {alertEnabled && (
          <div className="download-alert-update__container">
            <InputContainer
              attributes={{
                className: 'download-alert-textarea',
                name: 'download-alert',
                onChange: (e: ChangeEvent<HTMLTextAreaElement>): void => {
                  setDownloadAlertInput(e.target.value);
                },
                type: InputType.Textarea,
              }}
              input={{ value: downloadAlertInput, error: '' }}
              labelCopy={t`Download Alert Message`}
            />
            <PrimaryButton
              className="download-alert-update__submit"
              disabled={downloadAlertInput === initialDownloadAlert && alertEnabled === initialDownloadAlertEnabled}
              isLoading={isSubmitting}
              loadingCopy={t`Updating`}
              onClick={(): void => {
                setIsSubmitting(true);
                submitDownloadAlertUpdate();
              }}
              size="medium"
            >
              <Trans>Update</Trans>
            </PrimaryButton>
          </div>
        )}
      </div>
    </I18nProviderWrapper>
  );
};

export default DownloadAlertSetting;
