import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import './styles/_main.scss';

import AssetNameInput from '@components/asset/modal/tabs/edit/side_bar/assetNameInput';

import AttachmentListContainer from './attachmentListContainer';

interface GenericFileSideBarProps {
  isTask?: boolean;
}

const GenericFileSideBar: FunctionComponent<GenericFileSideBarProps> = ({
  isTask = false
}) => (
  <div className="edit-tab-sidebar one-third bf-scroll-element">
    <AssetNameInput assetNameCopy={isTask ? t`Task Name` : t`Asset Name`} />
    <AttachmentListContainer isTask={isTask} />
  </div>
);

export default GenericFileSideBar;
