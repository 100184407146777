import fetchJSON from '@api/api_helper';

const getUrl = (slug) => `/${slug}/assets/csv/download_status`;

export const postCsvPoller = (options) => {
  const url = getUrl(options.slug);
  const appendedOptions = {
    body: {
      download_key: options.downloadKey,
      resource_type: options.resourceType,
      resource_key: options.resourceKey
    },
    method: 'POST'
  };

  if (options.params) {
    appendedOptions.params = options.params;
  }

  return fetchJSON(url, appendedOptions, null, true)
    .then((response) => response);
};
