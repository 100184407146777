import { Trans } from '@lingui/macro';
import * as React from 'react';

import { ActionsDropdown } from '@components/library/dropdown/index';
import { ActionDropdownItem } from '@components/library/dropdown_item/index';

interface AssetBoardActionsProps {
  closeDropdown: boolean;
  handleShowRemoveModal: () => void;
  updateCloseDropdown: () => void;
  handleShowRenameModal?: () => void;
  isBoardCard?: boolean;
}

// contains the trigger buttons and menus for the board overlay
const AssetBoardActions = ({
  closeDropdown,
  handleShowRemoveModal,
  handleShowRenameModal,
  isBoardCard,
  updateCloseDropdown,
}: AssetBoardActionsProps): JSX.Element => (
  <div className="asset-board-actions">
    <ActionsDropdown
      className="asset-card-actions"
      closeCallback={updateCloseDropdown}
      openOnClick={false}
      openOnHover
      toggleDropdown={closeDropdown}
      userDevice={BFG?.currentUser?.device}
    >
      {!!isBoardCard && (
        <ActionDropdownItem
          key="rename-asset-board"
          icon="bff-edit"
          name="rename-asset-board"
          onChoose={handleShowRenameModal}
        >
          <Trans>Rename</Trans>
        </ActionDropdownItem>
      )}
      <ActionDropdownItem
        key={isBoardCard ? 'delete-asset-board' : 'remove-asset-card'}
        icon="bff-trash"
        isWarningItem
        name={isBoardCard ? 'delete-asset-board' : 'remove-asset-card'}
        onChoose={handleShowRemoveModal}
      >
        {isBoardCard ? <Trans>Delete</Trans> : <Trans>Remove</Trans>}
      </ActionDropdownItem>
    </ActionsDropdown>
  </div>
);

export default AssetBoardActions;
