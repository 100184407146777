import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { DashEmptyLabelProps, EmptyLabelSizes } from '@components/library/empty-label';
import { BaseEmptyLabel } from '../base';

import classes from './dash-empty-label.module.scss';

export const DashEmptyLabel: FunctionComponent<DashEmptyLabelProps> = (
  props
) => {
  const { className, size = EmptyLabelSizes.Small, ...otherProps } = props;

  return (
    <BaseEmptyLabel
      {...otherProps}
      className={classnames(
        classes['dash-empty-label'],
        size && classes[size],
        className
      )}
      variantName="dash"
    />
  );
};
