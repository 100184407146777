import { Tag, Cancel } from '@brandfolder/icons';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { ImageOverlay, OptionsMenuItem } from '../common/ImageOverlay';
import { PersonDetails } from '../customPeopleTagTypes';

export interface PersonAssetCardProps {
  person: PersonDetails;
  removeFaceTag: () => void;
  retagPersonAsset: (asset: PersonDetails) => void;
}
export const PersonAssetCard: FunctionComponent<PersonAssetCardProps> = ({
  person,
  removeFaceTag,
  retagPersonAsset
}) => {
  const openAssetContentView = (assetId: string): void => {
    let path = window.location.pathname;

    path = path.replace('/people-tags', '');
    path = `/${BFG.brandfolder_slug}/#!asset/${assetId}`;

    window.open(path, '_blank');
  };

  const optionsMenu: OptionsMenuItem[] = [
    {
      label: t`Not the same person`,
      onClick: removeFaceTag,
      icon: <Cancel />,
    },
    {
      label: t`Tag as different person`,
      onClick: (): void => retagPersonAsset(person),
      icon: <Tag />,
    }

  ];

  return (
    <div key={`${person.match_face_id}-${person.asset_id}`}>
      <ImageOverlay
        key={person.asset_id}
        buttonText={t`View`}
        onClick={(): void => openAssetContentView(person.asset_id)}
        optionsMenuItems={optionsMenu}
      >
        <img alt="matching" src={person.gcs_path} />
      </ImageOverlay>
    </div>
  );
};
