import { t } from '@lingui/macro';
import React, { FunctionComponent, MutableRefObject } from 'react';
import { EditorRef } from 'react-email-editor';

import { DialogSizes, StandardDialog } from '@components/library/dialog';
import { BaseTab, StepTabs } from '@components/library/tabs';

import { PressReleaseFormFooter } from './PressReleaseFormFooter';
import { PressReleaseFormStep1 } from './PressReleaseFormStep1';
import { PressReleaseFormStep2 } from './PressReleaseFormStep2';
import { PressReleaseFormStep3 } from './PressReleaseFormStep3';
import { PressReleaseFormStep4 } from './PressReleaseFormStep4';
import { PressReleaseFormSteps, PressReleaseUpsert, PressReleaseValidationErrors } from './PressReleaseTypes';

import classes from './styles/press_release_form.module.scss';

interface PressReleaseFormProps {
  drafting: boolean;
  duplicating: boolean;
  emailEditorLoaded: boolean;
  emailEditorRef: MutableRefObject<EditorRef | null>;
  handleClose: SetStateDispatch<boolean>;
  handleStep1: () => void;
  handleStep2: () => void;
  handleStep3: (action: 'drafting' | 'sending' | 'testing') => void;
  onEmailEditorBlur: () => void;
  open: boolean;
  pressRelease: PressReleaseUpsert;
  saving: boolean;
  sending: boolean;
  setEmailEditorLoaded: SetStateDispatch<boolean>;
  setNextStep: SetStateDispatch<PressReleaseFormSteps>;
  setPressRelease: SetStateDispatch<PressReleaseUpsert>;
  step: PressReleaseFormSteps;
  testing: boolean;
  validationErrors: PressReleaseValidationErrors;
}

export const PressReleaseForm: FunctionComponent<PressReleaseFormProps> = (props) => {
  const {
    drafting,
    duplicating,
    emailEditorLoaded,
    emailEditorRef,
    handleClose,
    handleStep1,
    handleStep2,
    handleStep3,
    onEmailEditorBlur,
    open,
    pressRelease,
    saving,
    sending,
    setEmailEditorLoaded,
    setNextStep,
    setPressRelease,
    step,
    testing,
    validationErrors
  } = props;

  const isFinalStep = step === 4;

  let title = t`Create New Email`;
  if (pressRelease?.id && !isFinalStep) {
    title = t`Update Email`;
  }
  if (duplicating && !isFinalStep) {
    title = t`Create Duplicate Email`;
  }
  if (isFinalStep) {
    title = t`Email Sent`;
  }

  let titleIcon = 'bff-plus';
  if (pressRelease?.id && !isFinalStep) {
    titleIcon = 'bff-edit';
  }
  if (duplicating && !isFinalStep) {
    titleIcon = 'bff-copy';
  }
  if (isFinalStep) {
    titleIcon = '';
  }

  const firstTab: BaseTab = {
    tabButton: <>Compose</>,
    tabPanel: (
      <PressReleaseFormStep1
        emailEditorRef={emailEditorRef}
        onEmailEditorBlur={onEmailEditorBlur}
        pressRelease={pressRelease}
        setEmailEditorLoaded={setEmailEditorLoaded}
        setPressRelease={setPressRelease}
        validationErrors={validationErrors}
      />
    )
  };

  const secondTab: BaseTab = {
    disabled: !emailEditorLoaded,
    tabButton: <>Recipients</>,
    tabPanel: (
      <PressReleaseFormStep2
        pressRelease={pressRelease}
        setPressRelease={setPressRelease}
        validationErrors={validationErrors}
      />
    )
  };

  const thirdTab: BaseTab = {
    disabled: !emailEditorLoaded || !validationErrors.valid,
    tabButton: <>Review</>,
    tabPanel: (
      <PressReleaseFormStep3
        pressRelease={pressRelease}
        step={step}
      />
    )
  };

  return (
    <StandardDialog
      contentCentered={isFinalStep}
      dialogBodyClassName={classes.dialogBody}
      dialogFooterClassName={classes.dialogFooter}
      footer={!isFinalStep && (
        <PressReleaseFormFooter
          drafting={drafting}
          emailEditorRef={emailEditorRef}
          handleStep1={handleStep1}
          handleStep2={handleStep2}
          handleStep3={handleStep3}
          saving={saving}
          sending={sending}
          step={step}
          testing={testing}
          validationErrors={validationErrors}
        />
      )}
      id="press-release-form"
      loading={saving}
      open={open}
      setOpen={handleClose}
      showFooter={!isFinalStep}
      showTitle={!isFinalStep}
      size={DialogSizes.Unlimited}
      title={title}
      titleIcon={titleIcon}
    >
      {open && !isFinalStep && (
        <form className={classes.form}>
          <StepTabs
            caption={t`Steps for creating a email`}
            changeToIndex={step - 1}
            id="press-release-steps"
            onChange={(nextIndex): void => {
              const nextStep = nextIndex + 1 as PressReleaseFormSteps;
              setNextStep(nextStep);

              // when clicking on the tab button for step 2, we need to get the data out of the editor
              if (nextStep === 2 && step !== 2) {
                handleStep1();
              }
              // when clicking on the tab button for step 3, we need save the email
              if (nextStep === 3 && step !== 3) {
                handleStep2();
              }
            }}
            tabListClassName={classes.tabList}
            tabPanelClassName={classes.tabPanel}
            tabs={[firstTab, secondTab, thirdTab]}
          />
        </form>
      )}
      {isFinalStep && (
        <PressReleaseFormStep4
          handleClose={handleClose}
          pressRelease={pressRelease}
        />
      )}
    </StandardDialog>
  );
};
