const getRandomNumber = (min: number, max: number): number => {
  const minimum = Math.ceil(min);
  const maximum = Math.floor(max);
  return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
};

const getRandomElementsFromArray = <T>(array: T[], numberOfRandomElementsToExtract = 1): T[] => {
  const elements: T[] = [];

  const getRandomElement = (arr: T[]): T[] => {
    if (elements.length < numberOfRandomElementsToExtract) {
      const index = Math.floor(Math.random() * arr.length);
      const element = arr.splice(index, 1)[0];

      elements.push(element);

      return getRandomElement(arr);
    }
    return elements;
  };

  return getRandomElement([...array]);
};

const brandfolderIpsums: string[] = [
  `The #1 digital asset management platform for enterprise usability.`,
  `Brandfolder helps marketers and creatives manage and distribute all of their assets, and understand how they're performing.`,
  `The people have spoken. Brandfolder is the #1 rated DAM according to user reviews.`,
  `No training required: An intuitive way for users to find what they need, when they need it.`,
  `Your team wastes dozens of hours a week finding assets. Brandfolder makes it easy for users to access assets from an intuitive, cloud-based, single source of truth.`,
  `Distribution that scales: An easy way to share your brand's assets.`,
  `Simplify brand asset distribution by sharing an asset, a collection of assets, or even your entire Brandfolder with robust privacy controls and user-level permissioning.`,
  `Advanced logistics and insights: Understand how your assets are performing with Brand Intelligence.`,
  `Using proprietary AI and ML technologies, Brand Intelligence shows you who is using your assets, where they’re being used, and what your highest-performing assets are.`,
  `Brandfolder integrates with the tools you use everyday.`,
  `Strong brands live here.`,
  `Brandfolder is trusted by some of the world's strongest brands, from innovative startups to Fortune 500 companies.`,
  `Join the 5,000+ brands using Brandfolder to manage their assets.`,
  `Brandfolder: The most usable digital asset management platform.`,
  `Manage, distribute, and analyze your digital assets in one place with Brandfolder, the world's most powerfully simple brand asset management software.`
];

/**
 * Generate Branfolder ipsum
 * @param numberOfLines number | undefined
 * @returns string[]
 */
export const generateBrandfolderIpsum = (numberOfLines?: number): string[] => {
  const numberOfRandomLines = numberOfLines || getRandomNumber(1, brandfolderIpsums.length);
  const ipsums = getRandomElementsFromArray<string>(brandfolderIpsums, numberOfRandomLines);
  return ipsums;
};
