import { FontIcon, FontIcons } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { Organization, Report } from './customReportTypes';

import './styles/reports-menu.scss';

interface ReportsTileProps {
  organization: Organization;
  report: Report;
}

export const ReportsTile: FunctionComponent<ReportsTileProps> = ({ organization, report }) => {
  const path = `/organizations/${organization.slug}/standard_reports`;

  return (
    <>
      <FontIcon icon={FontIcons.Reports} />
      <h3>{report.reportName}</h3>
      <div className='report-tile-description'>{report.description}</div>
        <Link to={`${path}/${report.reportUrl}`}>
          <Trans>View Reports</Trans>
        </Link>
    </>
  );
};
