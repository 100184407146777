import { t } from '@lingui/macro';
import classnames from 'classnames';
import React, {
  useEffect,
  useRef,
  useState,
  CSSProperties,
  FunctionComponent,
  ReactElement,
} from 'react';

import {
  DropdownKeys,
  DropdownVariants,
  ListDropdownProps,
  ListDropdownTransitions,
  ListOpenDirections,
  ListOption,
  ListOptionNullOmitted,
  ListOptionValue,
} from '@components/library/dropdown';
import BaseDropdown from '@components/library/dropdown/base/BaseDropdown';
import { VirtualizedList } from '@components/library/dropdown/base/VirtualizedList';
import { dropdownKeydownHelper } from '@components/library/dropdown/dropdown_helper';
import { ListAnchorElement } from '@components/library/dropdown/list/ListAnchorElement';
import { ListDropdownItem } from '@components/library/dropdown_item/index';
import { ListDropdownChildNode } from '@components/common/search_result_bolding/main';

import styleClassNames from './list-dropdown.module.scss';

export const ListDropdown: FunctionComponent<ListDropdownProps> = (
  props
) => {
  const {
    anchorElement,
    anchorElementLabel,
    disabled,
    error,
    dropdownTransition = ListDropdownTransitions.FadeIn,
    dropdownTransitionTime = 300,
    onChange,
    onOpen,
    openDirection = ListOpenDirections.Down,
    options,
    placeholder = t`Select value...`,
    searchable = false,
    toggleDropdown: toggleDropdownProp, // renaming to distinguish from toggleDropdown state variable
    value: optionValue, // renaming for readability
    virtualizeOptions = true,
    cfDropdown = false,
    ...baseDropdownProps
  } = props;
  const isMounted = useRef(true);
  const listRef = useRef(null);
  const [toggleDropdown, setToggleDropdown] = useState(false); // callback used by variant to trigger opening/closing the dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // tracks open/closed status of dropdown
  const [searchInput, setSearchInput] = useState('');
  const [arrowedItem, setArrowedItem] = useState<ListOptionValue | undefined>();
  const [storedTimeouts, setStoredTimeouts] = useState<number[]>([]);

  const cancelStoredTimeouts = (): void => {
    storedTimeouts.forEach((storedTimeout) => clearTimeout(storedTimeout));
  };

  const cleanup = (): void => {
    cancelStoredTimeouts(); // cancel all outstanding timeouts when unmounting
    isMounted.current = false;
  };

  const expandOnSpacebarKeypress = !searchable;

  // build selectedOption object from undefined || ListOption || ListOptionValue
  let selectedOption: ListOption | undefined;

  if (optionValue !== undefined && optionValue !== null) { // account for cases where value === 0, '', null
    selectedOption = (optionValue as ListOption)?.value !== undefined
      ? optionValue as ListOption
      : {
        label: options?.find((option) => option.value === optionValue)?.label || '',
        value: optionValue as ListOptionValue
      };
  }

  const searchedOptions = options?.filter((option) => (
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    option.label?.toString().toLowerCase().includes(searchInput?.toLowerCase())
  ));

  const handleOnChange = (selectedOptionValue: ListOptionValue | undefined): void => {
    if (onChange && options) {
      const foundOption = options.find((option) => option.value === selectedOptionValue);
      if (foundOption) {
        onChange(foundOption);
      }
    }
    setToggleDropdown(true);
  };

  const handleKeydown = (key: DropdownKeys, e: KeyboardEvent): void => {
    if (key === DropdownKeys.Backspace) return;

    e.stopPropagation();
    if (expandOnSpacebarKeypress) {
      // preventDefault() will prevent the spacebar key from being registered by the search input
      // (user couldn't input a space in the search input)
      // so only fire preventDefault() when dropdown is NOT searchable
      e.preventDefault();
    }

    const targetedIndex = searchedOptions?.findIndex((option) => option.value === arrowedItem);
    const newIndex = dropdownKeydownHelper(key, searchedOptions?.length, targetedIndex);

    if (typeof newIndex === 'number') {
      // ternary here is important (instead of checking value to be trutyh)
      // to account for value === 0 and value === undefined (which are allowed)
      setArrowedItem(searchedOptions?.[newIndex] ? searchedOptions?.[newIndex].value : undefined);
    } else if (newIndex) {
      handleOnChange(arrowedItem);
    }
  };

  const noOptionsOption = { label: t`No Options`, value: null, isEmpty: true };

  const filterOptions = (): ListOption[] => {
    if (searchable && searchedOptions?.length) {
      return [...searchedOptions];
    }

    if (!searchable && options?.length) {
      return options;
    }

    return [noOptionsOption];
  };

  const itemizeOption = (
    option: ListOptionNullOmitted,
    index: number,
    style?: CSSProperties,
  ): ReactElement => (
    <ListDropdownItem
      key={`${index}${option.value}`}
      className={classnames({ selected: selectedOption?.value === option.value, arrowed: arrowedItem === option.value })}
      id={option.value?.toString()}
      isArrowed={arrowedItem === option.value}
      isDisabled={option.isDisabled}
      isSelected={selectedOption?.value === option.value}
      isTabbable={false}
      name={option.value ?? ''}
      // the ternary here is to allow <a> tags to work (anchor tags as options won't have an onChange event)
      onChoose={onChange && options ? (name): void => handleOnChange(name) : undefined}
      style={style}
    >
      { cfDropdown ? ListDropdownChildNode({ searchResult: option.label as string, searchInput }) : option.label }
    </ListDropdownItem>
  );

  const defaultAnchorElement = (
    <ListAnchorElement
      anchorElementLabel={anchorElementLabel}
      cfDropdown={cfDropdown}
      disabled={disabled}
      error={error}
      isDropdownOpen={isDropdownOpen}
      openDirection={openDirection}
      placeholder={placeholder}
      searchInput={searchInput}
      searchable={searchable}
      selectedOption={selectedOption}
      setSearchInput={setSearchInput}
      styleClassNames={styleClassNames}
    />
  );

  const virtualizedItemizedOptions = (
    <VirtualizedList
      ref={listRef}
      createOptionsCallback={itemizeOption}
      options={filterOptions()}
      styleClassNames={styleClassNames}
    />
  );

  useEffect(() => {
    if (virtualizeOptions && arrowedItem && listRef && listRef.current) {
      const arrowedItemIndex = searchedOptions?.findIndex((option) => option.value === arrowedItem);
      listRef.current.scrollToItem(arrowedItemIndex, 'smart');
    }
  }, [arrowedItem, listRef, searchedOptions, virtualizeOptions]);

  useEffect(() => {
    if (isDropdownOpen && onOpen) {
      onOpen();
    }

    if (!isDropdownOpen) {
      setArrowedItem(undefined);

      if (searchable) {
        const newTimeout = window.setTimeout(() => {
          if (isMounted.current) setSearchInput('');
        }, dropdownTransitionTime + 50); // setTimeout ensures search input isn't cleared until after dropdown closes (otherwise dropdown will jump back to unsearched state)
        setStoredTimeouts((timeouts) => ([...timeouts, newTimeout]));
      }
    }
  }, [isDropdownOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => cleanup, []); // eslint-disable-line react-hooks/exhaustive-deps

  const baseProps = {
    ...baseDropdownProps,
    anchorElement: anchorElement || defaultAnchorElement,
    arrowedItem,
    children: virtualizeOptions ? virtualizedItemizedOptions : <>{filterOptions().map((option, i) => itemizeOption(option, i))}</>,
    closeCallback: (): void => setToggleDropdown(false),
    disabled,
    error,
    dropdownTransition,
    dropdownTransitionTime,
    expandOnSpacebarKeypress,
    keydownCallback: handleKeydown,
    openDirection,
    setIsDropdownOpen,
    styleClassNames,
    toggleDropdown: toggleDropdownProp || toggleDropdown,
    useUlContainer: true,
    variantName: DropdownVariants.List,
    virtualizeOptions,
  };

  return <BaseDropdown {...baseProps} />;
};
