/* eslint-disable react/require-default-props */
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { defaultImage, defaultCroppedData } from '@components/advanced_download/helper';

const SizeError = ({
  croppedData,
  fixedDimensions,
  image,
  imageToThumbnailRatio,
  selectedPreset,
  setSizeError,
  sizeError,
  zoomRatio,
}) => {
  const [errorCopy, setErrorCopy] = useState(null);

  const validateCropSize = () => {
    let newSizeError = null;
    if (fixedDimensions && image) {
      const { height: cropHeight, width: cropWidth } = croppedData;
      const { height: presetHeight, width: presetWidth } = selectedPreset.value;
      const { height: attachmentHeight, width: attachmentWidth } = image;

      if ((cropWidth * imageToThumbnailRatio) < presetWidth) {
        newSizeError = {};
        newSizeError.cropWidthError = true;
      }

      if ((cropHeight * imageToThumbnailRatio) < presetHeight) {
        newSizeError = newSizeError || {};
        newSizeError.cropHeightError = true;
      }
      if (attachmentWidth < presetWidth || attachmentHeight < presetHeight) {
        newSizeError = newSizeError || {};
        newSizeError.imageSizeError = true;
      }
    }

    if (newSizeError !== sizeError) {
      setSizeError(newSizeError);
    }
  };

  const errorList = {
    widthAndHeight: t`Increase crop selection area (width and height)`,
    width: t`Increase crop selection area (width)`,
    height: t`Increase crop selection area (height)`,
    cropOption: t`Select a different crop option`,
    largerImage: t`Upload a larger image`,
  };

  const generateErrorCopy = () => {
    const { cropHeightError, cropWidthError, imageSizeError } = sizeError;
    const newErrorCopy = { titleCopy: t`Tips to improve download quality:` };

    if (imageSizeError) {
      newErrorCopy.headerCopy = t`Original image too small`;
      newErrorCopy.bodyCopyList = [errorList.largerImage, errorList.cropOption];
    } else if (cropWidthError || cropHeightError) {
      newErrorCopy.headerCopy = t`Crop area too small`;
      if (cropWidthError && cropHeightError) {
        newErrorCopy.bodyCopyList = [errorList.widthAndHeight, errorList.cropOption, errorList.largerImage];
      } else if (cropHeightError) {
        newErrorCopy.bodyCopyList = [errorList.height, errorList.cropOption, errorList.largerImage];
      } else if (cropWidthError) {
        newErrorCopy.bodyCopyList = [errorList.width, errorList.cropOption, errorList.largerImage];
      }
    }

    // add 'or' to list
    const secondToLastIndex = newErrorCopy.bodyCopyList.length - 2;
    const updatedCopy = `${newErrorCopy.bodyCopyList[secondToLastIndex]}`;
    newErrorCopy.bodyCopyList[secondToLastIndex] = updatedCopy;

    if (newErrorCopy !== errorCopy) {
      setErrorCopy(newErrorCopy);
    }
  };

  useEffect(() => {
    validateCropSize();
  }, [croppedData, zoomRatio]);

  useEffect(() => {
    if (sizeError) {
      generateErrorCopy();
    } else {
      setErrorCopy(null);
    }
  }, [sizeError]);

  if (!sizeError || !errorCopy) { return null; }

  return (
    <div className="error-container">
      <h4 className="error-header">{errorCopy.headerCopy}</h4>
      <p className="error-title">{errorCopy.titleCopy}</p>
      <ul className="error-list">{errorCopy.bodyCopyList.map((copy, i) => (<li key={i}>{copy}</li>))}</ul>
    </div>
  );
};

SizeError.propTypes = {
  croppedData: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  fixedDimensions: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  imageToThumbnailRatio: PropTypes.number.isRequired,
  selectedPreset: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.shape({}),
  }),
  sizeError: PropTypes.shape({
    cropHeightError: PropTypes.bool,
    cropWidthError: PropTypes.bool,
    imageSizeError: PropTypes.bool
  }),
  setSizeError: PropTypes.func.isRequired,
  zoomRatio: PropTypes.number.isRequired,
};

SizeError.defaultProps = {
  croppedData: defaultCroppedData,
  image: defaultImage,
  selectedPreset: null,
};

export default SizeError;
