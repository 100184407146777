import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { FontIcons } from '@components/library/icon';
import BaseButton from '../BaseButton';
import styleClassNames from './styles.module.scss';

interface SecondaryIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  height?: number;
  icon?: string | FontIcons;
  iconSize?: number;
  width?: number;
}

const SecondaryIconButton = forwardRef<HTMLButtonElement, SecondaryIconButtonProps>((props, ref) => {
  const { height, style, width, ...baseButtonProps } = props;
  const updatedStyles = (height || width) && {
    ...style,
    ...height && { height: `${height}px` },
    ...width && { minWidth: `${width}px` },
  };
  const baseProps = {
    ...baseButtonProps,
    ...updatedStyles && { style: updatedStyles },
    styleClassNames,
    variantName: 'secondary-icon',
  };

  return <BaseButton {...baseProps} ref={ref} />;
});

export default SecondaryIconButton;
