/* eslint-disable @typescript-eslint/naming-convention */
import { MoreInfoTooltip, Widths } from '@brandfolder/react';
import { plural, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch, MAX_PAGE_SIZE, fetchJson } from '@api/ApiHelper';
import { CustomFieldKeyWithId } from '@api/v4/assets/customFieldTypes';
import { CustomFieldKeysListResponse, } from '@api/v4/resources/CustomFieldKeysTypes';
import { DependentCustomField } from '@api/v4/resources/CustomFieldKeysTypes';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';

import { DependencyViewAccordionPanel } from './DependencyViewAccordionPanel';
import './styles/dependency-view-accordion.scss';

interface DependentRow {
  value: string;
  dependentCustomFields: { id: string; name: string }[]
}

export interface PanelRow {
  customFieldName: string;
  dependentRows: DependentRow[],
}

interface DependencyViewAccordionProps {
  isActiveView: boolean
}

export const DependencyViewAccordion: FunctionComponent<DependencyViewAccordionProps> = ({ isActiveView }) => {
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const [checkedFields, setCheckedFields] = useState<string[]>([]);

  const { response, fetch } = useFetch<CustomFieldKeysListResponse>({
    fetchOnMount: false,
    url: `api/v4/brandfolders/${BFG.resource?.key}/custom_field_keys`,
    fields: 'multi_value_enabled',
    include: 'dependent_custom_fields',
    params: {
      order: 'asc',
      per: MAX_PAGE_SIZE,
      ugt_locale: determineUGTLocale(),
      sort_by: 'name',
    }
  });

  const deleteDependentFields = async (deleteFields: string[]): Promise<void> => {
    try {
      const deleteFieldPromises = deleteFields.map(async (id) => {
        await fetchJson({
          url: `/api/v4/dependent_custom_fields/${id}`,
          method: 'DELETE'
        });
      });
      await Promise.all(deleteFieldPromises);

      const successMessage = plural(deleteFields.length, { one: 'Dependency removed', other: 'Dependencies removed' });
      Notify.create({
        title: successMessage,
        type: 'success'
      });
    } catch (err) {
      const errorMessage = plural(deleteFields.length, {
        one: 'Error removing the dependency',
        other: 'Error removing the dependencies'
      });
      Notify.create({
        title: errorMessage,
        type: 'error'
      });
    } finally {
      setDeleteSubmitted(true);
    }
  };

  useEffect(() => {
    if (isActiveView || deleteSubmitted) {
      fetch();
    }
  }, [isActiveView, deleteSubmitted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteSubmitted) {
      setCheckedFields([]);
      setDeleteSubmitted(false);
    }
  }, [deleteSubmitted]);

  const customFieldKeys: CustomFieldKeyWithId[] = response?.data.map(({ attributes, id }) => ({ ...attributes, id }));
  const dependentCustomFields: DependentCustomField[] = response?.included?.map(({ attributes, id }) =>
    ({ ...attributes, id }));
  const dependentCustomFieldsMap = dependentCustomFields?.reduce((dcfMap, dcf) => {
    dcfMap[dcf.child_key] = dcf;
    return dcfMap;
  }, {} as DependentCustomField) || {};
  const filteredCustomFields = customFieldKeys?.filter(({ id }) =>
    !Object.keys(dependentCustomFieldsMap).includes(id)).sort((a, b) => a.position - b.position);

  const dependentFieldValuesForRow = (cfv: string, parentId: string): CustomFieldKeyWithId[] => {
    const filteredDependentFieldIds = Object.keys(dependentCustomFieldsMap).filter((id) =>
      dependentCustomFieldsMap[id].value === cfv &&
      dependentCustomFieldsMap[id].parent_key === parentId
    );
    return customFieldKeys?.filter(({ id }) => filteredDependentFieldIds?.includes(id));
  };

  const flattenedAccordionPanels = filteredCustomFields?.reduce((panels, field) => {
    if (!panels[field.id]) {
      const valuesWithDependentFields = field.allowed_values.filter((value) => {
        return !!dependentFieldValuesForRow(value, field.id).length;
      });
      Object.assign(panels, {
        [field.id]: {
          customFieldName: field.name,
          dependentRows: valuesWithDependentFields.map((value) => {
            const dependentFieldKeys = dependentFieldValuesForRow(value, field.id)
              .sort((a, b) => a.position - b.position);
            return ({
              value,
              dependentCustomFields: dependentFieldKeys.map(({ id: cfkId, name }) =>
                ({ id: dependentCustomFieldsMap[cfkId].id, name }))
            });
          }),
        }
      });
    }
    return panels;
  }, {} as PanelRow);

  if (!response) return null;

  return (
    <table className='dcf-accordion'>
      <thead className='dcf-accordion-heading'>
        <tr>
          <th>
            <Trans>Custom field</Trans>
          </th>
          <th>
            <Trans>Value</Trans>
            <MoreInfoTooltip
              iconLabel='parent value'
              id='parent-value'
              tooltip={<Trans>The parent value that, when selected, will display the dependent custom field.</Trans>}
              width={Widths.Small}
            />
          </th>
          <th>
            <Trans>Dependent custom fields</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {flattenedAccordionPanels && Object.keys(flattenedAccordionPanels).map((id) => (
          <DependencyViewAccordionPanel
            key={flattenedAccordionPanels[id].customFieldName}
            checkedFields={checkedFields}
            deleteDependentFields={(deleteFieldIds: string[]): void => { deleteDependentFields(deleteFieldIds); }}
            panelRow={flattenedAccordionPanels[id]}
            setCheckedFields={setCheckedFields}
          />
        ))}
      </tbody>
    </table>
  );
};
