import React from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";

class AutosuggestInput extends React.Component {
  state = {
    suggestions: [],
    value: ''
  }

  getSuggestions(value) {
    const { options } = this.props;
    return options.filter((suggestion) => suggestion.toLowerCase().includes(value.toLowerCase()));
  }

  handleChange = (event, { newValue }) => {
    this.setState({ value: newValue },
      this.props.changeValue(newValue));
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

   onSuggestionsClearRequested = () => {
     this.setState({ suggestions: [] });
   };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.changeValue(suggestion);
  }

  getSuggestionValue = (suggestion) => suggestion;

  renderSuggestion = (suggestion) => (
    <span className="suggestion-item">
      <span className="suggestion-name">{suggestion}</span>
    </span>
  );

  render() {
    const { value, suggestions } = this.state;
    const { id } = this.props;
    const inputProps = { value, onChange: this.handleChange };
    return (
      <div className="react-autosuggest-input">
        <Autosuggest
          id={id}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}

AutosuggestInput.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeValue: PropTypes.func.isRequired
};

export default AutosuggestInput;
