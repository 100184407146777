import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from '../styles/standard-table.module.scss';

interface StandardTableWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  scrollX?: boolean;
  scrollY?: boolean;
}

export const TableWrapper: FunctionComponent<StandardTableWrapperProps> = (props) => {
  const { children, scrollX, scrollY, ...otherProps } = props;
  return (
    <div
      {...otherProps}
      className={classnames(
        'tableWrapper',
        'standardTableWrapper',
        scrollX && classes.tableScrollX,
        scrollY && classes.tableScrollY,
        (scrollX || scrollY) && 'bf-scroll-element',
        props.className
      )}
    >
      {children}
    </div>
  );
};
