import React from 'react';
import PropTypes from 'prop-types';

import Blitline from '@helpers/blitline';
import downloadFile from '@helpers/download';

class Controls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filetype: null,
      downloading: false,
    };
  }

  download() {
    this.setState({ downloading: true });
    const { url, filename, filetype } = this.props.asset;
    const blitline = new Blitline(url, this.props.filetype);

    // FileStack won't display SVGs without the secure: false option
    // const insecureUrl = url.replace(/(.*)\.com\/(.*)/, '$1.com/output=secure:false/$2');
    // const blitline = new Blitline(insecureUrl, this.props.filetype);
    blitline.convertTo(filetype).then((convertedUrl) => {
      downloadFile(convertedUrl, { filename, filetype: this.state.filetype }).then(() => {
        this.setState({ downloading: false });
      }).catch(() => {
        this.setState({ downloading: false });
      });
    }).catch(() => {
      this.setState({ downloading: false });
    });
  }

  render() {
    const { asset } = this.props;
    const disabled = !asset;
    const buttonDisabled = disabled || this.state.downloading;

    let options = [];
    if (asset) {
      options = Blitline
        .conversionOptionsFor(asset.filetype)
        .map((type) => (
          <option
            key={type}
            value={type}
          >
            {type.toUpperCase()}
          </option>
        ));
    }

    return (
      <div className="workbench-convert">
        <div className="workbench-convert__selector">
          <label
            htmlFor="download_as"
            className={disabled ? 'disabled' : ''}
          >
            Download as:
          </label>
          <select
            name="download_as"
            value={this.state.filetype || undefined}
            disabled={disabled}
            onBlur={(event) => this.props.onPropertyChange({ filetype: event.target.value })}
          >
            {options}
          </select>
        </div>
        <button
          onClick={() => this.download()}
          disabled={buttonDisabled}
          className="button dark-blue large"
          type="button"
        >
          Download
        </button>
        {this.props.renderResetButton()}
      </div>
    );
  }
}

Controls.propTypes = {
  asset: PropTypes.shape({
    filetype: PropTypes.string,
    filename: PropTypes.string,
    url: PropTypes.string,
  }),
  filetype: PropTypes.string,
  onPropertyChange: PropTypes.func,
  renderResetButton: PropTypes.func
};

Controls.defaultProps = {
  asset: null,
  filetype: null,
  onPropertyChange: () => {},
  renderResetButton: () => {}
};

export default Controls;
