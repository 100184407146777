import React, { useState } from "react";
import PropTypes from "prop-types";
import { downloadPoller as downloadConversion } from '@api/downloading/download_poller';
import { downloadAttachment as downloadOriginal } from '@api/downloading/download_attachment';
import SelectListDropdown from '@components/common/select_list_dropdown/main';
import ClippingOptions from './clipping_options';
import WatermarkSettings from './watermark_settings';
import './styles/main.scss';

function DownloadOptions(props) {
  const availableFormats = ['mp4', 'webm', 'ogv', 'mov', 'aac', 'wmv', '3gp',
    'flv', 'm4f', 'mj2', 'mkv', 'mxf', 'ogg', 'ts'];
  const availableHeights = Array.from(new Set([props.attachment.height, 1080, 720, 480].filter(
    (height) => height <= props.attachment.height
  )));

  const [extension, setExtension] = useState(props.attachment.extension);
  const [height, setHeight] = useState(props.attachment.height);
  const [clip_start, updateClipStart] = useState(null);
  const [clip_end, updateClipEnd] = useState(null);
  const [watermark, updateWatermark] = useState({});
  const [watermark_x, updateWatermarkX] = useState(null);
  const [watermark_y, updateWatermarkY] = useState(null);
  const [watermark_height, updateWatermarkHeight] = useState(null);
  const [watermark_width, updateWatermarkWidth] = useState(null);
  const [watermark_opacity, updateWatermarkOpacity] = useState(null);
  const [downloading, disableDownload] = useState(false);

  const originalVideo = (
    height === props.attachment.height
    && extension === props.attachment.extension
    && !clip_start
    && !clip_end
    && !watermark.url
  );

  const watermarkXPlacement = () => {
    const scaleFactor = (height / props.attachment.height);
    switch (watermark_x) {
      case 0:
        return 0;
      case 1:
        return (scaleFactor * props.attachment.width) - watermark_width;
      default:
        return ((scaleFactor * props.attachment.width) - watermark_width) / 2;
    }
  };

  const watermarkYPlacement = () => {
    switch (watermark_y) {
      case 0:
        return 0;
      case 1:
        return height - watermark_height;
      default:
        return (height - watermark_height) / 2;
    }
  };

  const downloadVideo = () => {
    if (originalVideo) {
      return downloadOriginal(props.attachment.key, props.attachment.filename);
    }
    const pollerUrl = `/${BF.fx.brandfolder().slug}/attachments/${props.attachment.key}/poller`;
    let params = { dl: true, extension, height, clip_start, clip_end };
    if (watermark.url) {
      params = Object.assign(params,
        { watermark_url: encodeURIComponent(watermark.url),
          watermark_x: watermarkXPlacement(),
          watermark_y: watermarkYPlacement(),
          watermark_height,
          watermark_width,
          watermark_opacity: (watermark_opacity || 1) });
    }
    return downloadConversion(pollerUrl, { params }, 500);
  };

  const downloadCallback = () => {
    disableDownload(true);
    downloadVideo();
    Insight.createEvent("downloaded", props.assetKey, "asset");
    setTimeout(() => {
      disableDownload(false);
    }, 3000);
  };

  const handleDownload = () => {
    if (BFG.downloadAlertEnabled) {
      BF.fx.dispatchWindowEvent('launchDownloadAlert', null, {
        assetKey: undefined,
        attachmentKey: props.attachment.key,
        downloadCallback
      });
    } else {
      downloadCallback();
    }
  };

  return (
    <div className="advanced-video-downloading editor">
      <div className="non-watermarking-container">
        <div className="editor-left">
          <SelectListDropdown
            defaultValue={props.attachment.extension}
            dropdownLabel="File Type"
            list={availableFormats}
            onSelect={setExtension}
            scroll={false}
          />
          <SelectListDropdown
            defaultValue={props.attachment.height}
            dropdownLabel="Resolution"
            list={availableHeights}
            onSelect={(selectedHeight) => setHeight(parseInt(selectedHeight, 10))}
            scroll={false}
          />
          <ClippingOptions
            {...props}
            updateClipEnd={updateClipEnd}
            updateClipStart={updateClipStart}
          />
        </div>

        <div className="editor-right">
          <WatermarkSettings
            {...props}
            updateWatermark={updateWatermark}
            updateWatermarkHeight={updateWatermarkHeight}
            updateWatermarkOpacity={updateWatermarkOpacity}
            updateWatermarkWidth={updateWatermarkWidth}
            updateWatermarkX={updateWatermarkX}
            updateWatermarkY={updateWatermarkY}
            watermark={watermark}
            watermark_height={watermark_height}
            watermark_opacity={watermark_opacity}
            watermark_width={watermark_width}
            watermark_x={watermark_x}
            watermark_y={watermark_y}
          />
        </div>
      </div>
      <div className="s-download-attachment full">
        <button
          className="button primary"
          disabled={downloading}
          onClick={handleDownload}
          type="button"
        >
          Download
        </button>
      </div>
    </div>
  );
}

DownloadOptions.propTypes = {
  attachment: PropTypes.shape({
    key: PropTypes.string,
    extension: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    duration: PropTypes.number,
    filename: PropTypes.string
  }).isRequired,
  assetKey: PropTypes.string.isRequired,
  player: PropTypes.shape({
    player: PropTypes.func,
    duration: PropTypes.func,
    tech: PropTypes.func
  })
};

DownloadOptions.defaultProps = {
  player: undefined
};

export default DownloadOptions;
