import { t, Trans, Plural } from '@lingui/macro';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactTags from 'react-tag-autocomplete';

import { isGettyClient } from '@helpers/getty-strings';

export default class InviteUsersStage1 extends Component {
  state = {
    inputValue: '',
    tagDelimiters: [9] // tab: 9, enter: 13, comma: 188
  }

  createTagsObject(text) {
    return { id: text, text };
  }

  addEmails(emails) {
    if (emails === '') { return; }

    // all of our internal code uses the "text" field, but the component uses "name"
    /* eslint-disable no-param-reassign */
    emails.text = emails.name;
    delete emails.name;
    /* eslint-enable no-param-reassign */

    const currentEmails = [...this.props.emailsArray];
    const newEmails = [];

    // split email list into array on these separators (commas, line breaks etc.)
    const separators = [
      ',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r', '\t', '\v', ' '
    ];
    const separatorsRegex = new RegExp(separators.join('|'));
    const newEmailsRaw = emails.text.split(separatorsRegex).map((d) => d.trim());

    // validate new email(s)
    newEmailsRaw.forEach((email) => {
      const validEmailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
      const validEmail = email.match(validEmailRegex);

      if (validEmail) {
        newEmails.push(this.createTagsObject(...validEmail));
      }
    });

    // dedupe new emails (compared to existing emails)
    newEmails.forEach((newEmail) => {
      let duplicateIndicator = false;

      currentEmails.forEach((currentEmail) => {
        if (newEmail.text.toLowerCase() === currentEmail.text.toLowerCase()) {
          duplicateIndicator = true;
        }
      });

      if (!duplicateIndicator) {
        currentEmails.push(newEmail);
      }
    });

    this.props.updateInviteState({ emailsArray: currentEmails });
    this.setState({ inputValue: '' });
  }

  deleteEmail(i) {
    const emailsArray = [...this.props.emailsArray];

    emailsArray.splice(i, 1);
    this.props.updateInviteState({ emailsArray });
  }

  handleInputChange(text) {
    this.setState({ inputValue: text });
  }

  render() {
    if (this.props.stage !== 1) { return null; }

    const { inputValue, tagDelimiters } = this.state;
    const { cancelInvite, emailsArray, updateInviteState, submitted } = this.props;
    const emailCount = emailsArray.length;
    const emailsEnteredClass = emailsArray.length === 0 ? 'no-emails-entered' : '';
    const addTagInstructionClass = inputValue === '' ? '' : 'active';

    const tags = emailsArray.map(({ id, text }) => ({ id, name: text }));

    return (
      <React.Fragment>
        <div className="section-title-container">
          <h3 className="section-title"><Trans>Invite users</Trans></h3>
        </div>
        <div className={`section-body body-select-users ${emailsEnteredClass}`}>
          <div className="invite-header">
            <h4 className="input-label"><Trans>Enter emails</Trans></h4>
            <h4 className={`invite-instructions ${addTagInstructionClass}`}>
              <Trans>Hit "Tab" or click "Add" to add email addresses</Trans>
            </h4>
          </div>
          <ReactTags
            addOnBlur
            allowBackspace={false}
            allowDeleteFromEmptyInput={false}
            allowNew
            autofocus={false}
            classNames={{ tags: 'tags-container' }}
            delimiters={tagDelimiters}
            handleAddition={(tag) => this.addEmails(tag)}
            handleDelete={(i) => this.deleteEmail(i)}
            handleInputChange={(text) => this.handleInputChange(text)}
            placeholder={t`Enter email addresses separated by commas`}
            tags={tags}
          />
          {/* add button functions due to onBlur action */}
          <button
            className="button secondary add-button"
            disabled={inputValue === ''}
            type="button"
          >
            <Trans>Add</Trans>
          </button>
          <div className="users-count-container">
            <h4>
              <Plural
                one="# user selected"
                other="# users selected"
                value={emailCount}
              />
            </h4>
          </div>
        </div>
        <div className="button-container">
          <button
            className="button tertiary sm"
            onClick={() => cancelInvite()}
            type="button"
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            className="button primary lg"
            disabled={submitted}
            onClick={() => updateInviteState({ stage: 2 })}
            type="button"
          >
            {isGettyClient()
              ? <Trans>Select Libraries or Collections</Trans>
              : <Trans>Select Brandfolders or Collections</Trans>}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

InviteUsersStage1.propTypes = {
  cancelInvite: PropTypes.func.isRequired,
  emailsArray: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string
  })),
  libraryName: PropTypes.string,
  stage: PropTypes.number.isRequired,
  submitted: PropTypes.bool.isRequired,
  updateInviteState: PropTypes.func.isRequired
};

InviteUsersStage1.defaultProps = {
  emailsArray: [],
  libraryName: "Brandfolder"
};
