/**
 * Get whether an element is a tab.
 * Used to override KeyboardEvent on the asset dialog/modal.
 * @param element Element | null | undefined
 * @returns boolean
 */
export const getElementIsTab = (element?: Element | null): boolean => {
  const role = element && element.getAttribute('role');
  return role === 'tab';
};
