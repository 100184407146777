import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { CustomFieldValue } from '@api/v4/assets/customFieldTypes';
import { CustomFieldKeyValuesEntry } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { StandardTextField } from '@components/library/text-field';

interface SingleValueFreeEntryProps {
  customFieldKeyId: string;
  existingCustomFieldMap: CustomFieldKeyValuesEntry;
  handleUpdate: (customFieldValue: CustomFieldValue, customFieldRequired?: boolean) => void;
  required: boolean;
}

export const SingleValueFreeEntry: FunctionComponent<SingleValueFreeEntryProps> = ({
  customFieldKeyId,
  existingCustomFieldMap,
  handleUpdate,
  required
}) => {
  const value = existingCustomFieldMap?.customFieldValues.length > 0
    ? existingCustomFieldMap?.customFieldValues[0].value
    : '';

  const touched = existingCustomFieldMap?.customFieldTouched;
  const showRequired = required && touched && !!value === false;

  return (
    <StandardTextField
      className={`custom-field-primary-input${!showRequired ? ' top-container-hidden' : ''}`}
      error={showRequired && t`Value required`}
      id={`custom-field-value-input--${customFieldKeyId}`}
      label={t`Custom field value`}
      name={`custom-field-value-input--${customFieldKeyId}`}
      onChange={(e): void => {
        const customFieldValueId = existingCustomFieldMap?.customFieldValues.length > 0
          && existingCustomFieldMap?.customFieldValues[0].key;
        handleUpdate({ key: customFieldValueId || '', value: (e as InputChangeEvent).target.value }, required);
      }}
      placeholder={t`Input value`}
      required
      showLabel={false}
      value={value}
    />
  );
};
