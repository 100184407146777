import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import InviteUsers from './invite_users';

import './styles/user_permissions_styles.scss';

export default class UserPermissions extends Component {
  render() {
    const {
      current_organization_key,
      current_resource_key,
      current_resource_type,
      current_user,
      library_name,
    } = this.props;

    return (
      <I18nProviderWrapper>
        <div className="user-permissions-component">
          <InviteUsers
            libraryName={library_name}
            currentOrganizationKey={current_organization_key}
            currentResourceKey={current_resource_key}
            currentResourceType={current_resource_type}
            currentUser={current_user}
          />
        </div>
      </I18nProviderWrapper>
    );
  }
}

UserPermissions.defaultProps = {
  library_name: "Brandfolder"
};

UserPermissions.propTypes = {
  current_organization_key: PropTypes.string.isRequired,
  current_resource_key: PropTypes.string.isRequired,
  current_resource_type: PropTypes.string.isRequired,
  current_user: PropTypes.object.isRequired,
  library_name: PropTypes.string,
};
