import { t, Trans, Plural } from '@lingui/macro';
import React, { ChangeEvent, FunctionComponent } from 'react';

import { DisplayWidths, ValidationErrorsEnum } from '@api/v4/private/PortalCardTypes';
import { PrimaryButton, TextWarningButton } from '@components/library/button/index';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';
import {
  validationSettings,
  nameError,
  descriptionError,
  buttonTextError,
  srcUrlError
} from '@components/portals/portal_cards/validations';

import './styles/embedded_widgets_form.scss';

interface EmbeddedWidgetsFormProps {
  buttonText: string;
  description: string | null;
  displayWidth: DisplayWidths | undefined;
  // height: number | null;
  id: string | undefined;
  isValid: boolean;
  name: string;
  saving: boolean;
  setButtonText: (buttonText: string) => void;
  setDescription: (description: string) => void;
  // setHeight: (height: number | null) => void;
  setName: (name: string) => void;
  setSrcUrl: (srcUrl: string) => void;
  // setWidth: (width: number | null) => void;
  showButtons: boolean;
  showCharacterLimit: boolean;
  submitCreate: () => Promise<void>;
  submitUpdate: () => Promise<void>;
  srcUrl: string;
  type: 'Smartsheet Form' | 'Tile';
  validationErrors: ValidationErrorsEnum[];
  // width: number | null;
  closeModal?: () => void;
  disabled?: boolean;
  handleDeletion?: (id: string, name: string, triggerCardsRefresh: () => void, closeModal?: () => void) => void;
  triggerRefresh?: () => void;
}

/**
 * Form for saving (both create and edit) embedded widgets forms and portal tile embedded forms
 * NOTE: Shared between embedded_widgets and portal_tiles
 * @param {EmbeddedWidgetsFormProps} props EmbeddedWidgetsFormProps
 */
export const EmbeddedWidgetsForm: FunctionComponent<EmbeddedWidgetsFormProps> = (props) => {
  const {
    buttonText,
    closeModal,
    description,
    disabled,
    displayWidth,
    handleDeletion,
    // height,
    id,
    isValid,
    name,
    saving,
    setButtonText,
    setDescription,
    // setHeight,
    setName,
    setSrcUrl,
    // setWidth,
    showButtons,
    showCharacterLimit,
    srcUrl,
    submitCreate,
    submitUpdate,
    triggerRefresh,
    type,
    validationErrors,
    // width
  } = props;

  const nameLength = name.length;
  const descriptionLength = description ? description.length : 0;

  // NOTE: for i18n
  const createText = <Trans>Create {type}</Trans>;
  const deleteText = <Trans>Delete {type}</Trans>;
  const updateText = <Trans>Update {type}</Trans>;

  const showDelete = !!(closeModal && handleDeletion && triggerRefresh);
  const portalNameRemainingCharacters = displayWidth ? validationSettings[displayWidth]?.nameLength - nameLength : 0;
  const portalDescriptionRemainingCharacters = displayWidth ? validationSettings[displayWidth]?.descriptionLength - descriptionLength : 0;

  return (
    <div className="embedded-form">
      <div className="tile-form__name">
        <div className="portal-modal__character-limit">
          <InputContainer
            attributes={{
              className: `${displayWidth && validationSettings[displayWidth]?.nameLength - nameLength < 0 ? 'invalid' : ''}`,
              disabled,
              name: 'tile-name',
              onChange: (e: InputChangeEvent): void => setName(e.target.value),
              type: InputType.Text,
            }}
            input={{ value: name, error: nameError(validationErrors) }}
            labelCopy={<><Trans>Form Name</Trans> *</>}
            submitAttempted={!isValid}
          />
          {showCharacterLimit && displayWidth && (
            <span className="portal-modal__character-limit-count">
              <Plural
                one={<Trans><span>{portalNameRemainingCharacters}</span> Character</Trans>}
                other={<Trans><span>{portalNameRemainingCharacters}</span> Characters</Trans>}
                value={portalNameRemainingCharacters}
              />
            </span>
          )}
        </div>
      </div>
      <div className="tile-form__description">
        <div className="portal-modal__character-limit">
          <InputContainer
            attributes={{
              className: `${displayWidth && validationSettings[displayWidth]?.descriptionLength - descriptionLength < 0 ? 'invalid' : ''}`,
              disabled,
              name: 'tile-description',
              onChange: (e: ChangeEvent<HTMLTextAreaElement>): void => setDescription(e.target.value),
              type: InputType.Textarea,
            }}
            input={{ value: description || '', error: descriptionError(validationErrors) }}
            labelCopy={t`Form Description`}
            submitAttempted={!isValid}
          />
          {showCharacterLimit && displayWidth && (
            <span className="portal-modal__character-limit-count">
              <Plural
                one={<Trans><span>{portalDescriptionRemainingCharacters}</span> Character</Trans>}
                other={<Trans><span>{portalDescriptionRemainingCharacters}</span> Characters</Trans>}
                value={portalDescriptionRemainingCharacters}
              />
            </span>
          )}
        </div>
      </div>
      <div className="tile-form__name">
        <div className="portal-modal__character-limit">
          <InputContainer
            attributes={{
              disabled,
              name: 'tile-button-text',
              onChange: (e: ChangeEvent<HTMLTextAreaElement>): void => setButtonText(e.target.value),
              type: InputType.Text,
            }}
            input={{ value: buttonText || '', error: buttonTextError(validationErrors) }}
            labelCopy={<><Trans>Button Text</Trans> *</>}
            submitAttempted={!isValid}
          />
          <span className="portal-modal__character-limit-count">
            {/* conditional prevents error text overlapping countdown/helper text */}
            {!buttonTextError(validationErrors) && (<span className="italic-font"><Trans>The text that prompts users to open your form</Trans></span>)}
          </span>
        </div>
      </div>
      <div className="tile-form__description">
        <div className="portal-modal__character-limit">
          <InputContainer
            attributes={{
              disabled,
              name: 'tile-src-url',
              onChange: (e: ChangeEvent<HTMLTextAreaElement>): void => setSrcUrl(e.target.value),
              type: InputType.Url
            }}
            input={{ value: srcUrl || '', error: srcUrlError(validationErrors) }}
            labelCopy={<><Trans>URL</Trans> *</>}
            submitAttempted={!isValid}
          />
          <span className="portal-modal__character-limit-count">
            {/* conditional prevents error text overlapping countdown/helper text */}
            {!srcUrlError(validationErrors) && (<span className="italic-font"><Trans>Paste your Smartsheet form URL here</Trans></span>)}
          </span>
        </div>
      </div>
      {showButtons && (
        <div className="portal-modal__button">
          {id && (
            <div className={`portal-modal__buttons portal-modal__buttons--${showDelete ? 'two-buttons' : 'one-button'}`}>
              {showDelete && (
                <TextWarningButton
                  disabled={disabled}
                  icon="bff-trash"
                  isLoading={saving}
                  onClick={(): void => {
                    if (handleDeletion && triggerRefresh) {
                      handleDeletion(id, name, triggerRefresh, closeModal);
                    }
                  }}
                >
                  {deleteText}
                </TextWarningButton>
              )}
              <PrimaryButton
                disabled={disabled}
                isLoading={saving}
                onClick={(): void => { submitUpdate(); }}
                size="medium"
              >
                {saving ? <Trans>Saving...</Trans> : updateText}
              </PrimaryButton>
            </div>
          )}
          {!id && (
            <PrimaryButton
              disabled={disabled}
              isLoading={saving}
              onClick={(): void => { submitCreate(); }}
              size="small"
            >
              {saving ? <Trans>Saving...</Trans> : createText}
            </PrimaryButton>
          )}
        </div>
      )}
    </div>
  );
};
