import { Locale } from '@components/common/language_menu/languagesMap';

export type WhitelistedLocalesMap = { [key: string]: boolean };
export type WhitelistedLocalesData = Locale[];
export type GlobalLocalePayload = WhitelistedLocalesMap | Locale;
export enum GlobalLocaleType {
  Whitelisted='whitelisted',
  Default='default'
}

interface GlobalLocalesObject {
  ugtLocaleDefault: Locale;
  staticSiteLocale: Locale;
  whitelistedLocales: string;
}

export const updateGlobalLocales = (
  globalLocaleType: GlobalLocaleType,
  payload: GlobalLocalePayload
): GlobalLocalesObject => {
  const BFG = window.BFG; // test workaround to use window BFG mock

  if (globalLocaleType === GlobalLocaleType.Whitelisted) {
    const newWhitelistedLocalesString = Object.keys(payload)
      .filter((locale) => (payload[locale] ? locale : null))
      .join(',');
    BFG.locales.whitelistedLocales = newWhitelistedLocalesString;
  }

  if (globalLocaleType === GlobalLocaleType.Default) {
    BFG.locales.ugtLocaleDefault = payload;
  }

  return BFG.locales; // returned value used for testing
};
