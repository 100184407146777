import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import './styles/approve_request_actions.scss';

const ApproveRequestActions = ({ selectAll }) => (
  <div className="share-manifest-approve-assets-actions">
    <Trans>
      <button
        className="button primary"
        onClick={() => selectAll()}
        type="button"
      >
        Select all
      </button>
      <p className="action-guidance">or select individual assets for approval</p>
    </Trans>
  </div>
);

ApproveRequestActions.propTypes = {
  selectAll: PropTypes.func.isRequired,
};

export default ApproveRequestActions;
