import React from 'react';
import PropTypes from 'prop-types';

const ToggleDrawerButton = ({ toggleFiltersDrawer, children }) => (
  <button
    className="labels-toggle-drawer-button"
    onClick={() => {
      toggleFiltersDrawer('labels-drawer');
    }}
    type="button"
  >
    <div className="carrots-container">
      <span className="bff-caret-down icon" />
      <span className="bff-caret-down icon" />
    </div>
    {children}
  </button>
);

ToggleDrawerButton.propTypes = {
  toggleFiltersDrawer: PropTypes.func.isRequired,
};

export default ToggleDrawerButton;
