import React, {
  FunctionComponent,
} from 'react';

import {
  ActionsDropdownProps,
  ActionsDropdownTransitions,
  DropdownVariants
} from '@components/library/dropdown';
import BaseDropdown from '@components/library/dropdown/base/BaseDropdown';
import styleClassNames from './actions-dropdown.module.scss';

export const ActionsDropdown: FunctionComponent<ActionsDropdownProps> = (
  props
) => {
  const {
    anchorElement,
    dropdownTransition = ActionsDropdownTransitions.FadeInTranslate,
  } = props;
  const defaultAnchorElement = <span className={`bff-ellipsis ${styleClassNames.ellipsis}`} />;

  const baseProps = {
    ...props,
    anchorElement: anchorElement || defaultAnchorElement,
    dropdownTransition,
    styleClassNames,
    variantName: DropdownVariants.Actions,
  };

  return <BaseDropdown {...baseProps} />;
};
