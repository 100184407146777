/* eslint-disable @typescript-eslint/naming-convention */
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { getIntegrationsContentSyncApiKey, getIntegrationsContentSyncUrl, UploadPreferences } from '@api/contentsync';
import { publishAsset } from '@api/v4/private/smartsheet/publish';
import { ResourceTypes } from '@api/v4/resources/resourceTypes';
import { PrimaryButton } from '@components/library/button';
import { ListDropdown, ListOption } from '@components/library/dropdown';
import Label from '@components/library/labels/PrimaryLabel';

import { Resources } from './helper';
import { PayloadContext, PostMessageDataReceived, SelectedResources, Status, Statuses, Views } from './PublishTypes';
import { SelectLabels } from './SelectLabels';

interface PublishFormProps {
  apiKey: string;
  organizations: ListOption[] | null;
  postMessageDataReceived: PostMessageDataReceived | null;
  resources: Resources | null;
  setStatus: (status: Status) => void;
  setView: (view: Views) => void;
}

const initialSelectedResources = {
  // NOTE: this order is important, maintain top down order here for autoSelectSingleOptions
  [ResourceTypes.Organizations]: null,
  [ResourceTypes.Brandfolders]: null,
  [ResourceTypes.Sections]: null
};

export const PublishForm: FunctionComponent<PublishFormProps> = ({
  apiKey,
  organizations,
  postMessageDataReceived,
  resources,
  setStatus,
  setView
}) => {
  const [selectedResources, setSelectedResources] = useState<SelectedResources>(initialSelectedResources);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [showLabels, setShowLabels] = useState(false);
  const [hideSubmit, setHideSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getUploadPreferences = useFetch<UploadPreferences>({
    customToken: getIntegrationsContentSyncApiKey(apiKey),
    queuePriorityHigh: false,
    url: `${getIntegrationsContentSyncUrl()}/panelui/upload_preferences`
  });

  useEffect(() => {
    if (getUploadPreferences.response
      && getUploadPreferences.response.data
      && Object.keys(getUploadPreferences.response.data).length > 0
    ) {
      setSelectedResources({
        // NOTE: this order is important, maintain top down order here for autoSelectSingleOptions
        [ResourceTypes.Organizations]: getUploadPreferences.response.data?.attributes?.organization_key || null,
        [ResourceTypes.Brandfolders]: getUploadPreferences.response.data?.attributes?.brandfolder_key || null,
        [ResourceTypes.Sections]: getUploadPreferences.response.data?.attributes?.section_key || null
      });
    }
  }, [getUploadPreferences.response]); // eslint-disable-line react-hooks/exhaustive-deps

  const body: UploadPreferences = {
    data: {
      attributes: {
        brandfolder_key: selectedResources.brandfolders,
        organization_key: selectedResources.organizations,
        section_key: selectedResources.sections
      }
    }
  };

  const postUploadPreferences = useFetch<UploadPreferences>({
    body,
    customToken: getIntegrationsContentSyncApiKey(apiKey),
    fetchOnMount: false,
    method: 'POST',
    queuePriorityHigh: false,
    url: `${getIntegrationsContentSyncUrl()}/panelui/upload_preferences`
  });

  const handleDropdownChange = (option: ListOption): void => {
    if (option.type && option.value === selectedResources[option.type]) return;
    setSelectedResources((prevState) => {
      if (option.type === 'brandfolders') setShowLabels(false);
      if (option.type) {
        return {
          ...prevState,
          [option.type]: option.value as string | undefined, // update selected value
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          ...(option.type === ResourceTypes.Organizations) && {
            [ResourceTypes.Brandfolders]: null,
            [ResourceTypes.Sections]: null
          }, // reset dependents
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          ...(option.type === ResourceTypes.Brandfolders) && {
            [ResourceTypes.Sections]: null
          }, // reset dependents
        };
      }
      return {
        ...prevState
      };
    });
  };

  const handleSubmit = async (): Promise<void> => {
    if (!postMessageDataReceived || !selectedResources.brandfolders || !selectedResources.sections) {
      return;
    }

    setIsLoading(true);

    try {
      const { json, response } = await publishAsset({
        brandfolderKey: selectedResources.brandfolders,
        data: {
          ...postMessageDataReceived,
          ...selectedLabels.length > 0 && { labelKeys: selectedLabels }
        },
        sectionKey: selectedResources.sections
      });

      await postUploadPreferences.fetch();

      setStatus({
        body: json,
        context: PayloadContext.PublishAsset,
        status: response.status as Statuses,
      });
      setView(Views.SubmitSuccess);
    } catch (error) {
      const resolvedError = await error.json();
      setStatus({
        body: { error: resolvedError },
        context: PayloadContext.PublishAsset,
        status: error.status as Statuses,
      });
      Notify.create({
        title: t`Something went wrong. Try again soon!`,
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const autoSelectSingleOptions = (): void => {
    const resourceKeys = Object.keys(selectedResources);
    for (let i = 0; i < resourceKeys.length; i += 1) {
      if (selectedResources[resourceKeys[i]] === null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (resourceKeys[i] === ResourceTypes.Organizations) {
          if (organizations && organizations.length === 1) handleDropdownChange(organizations[0]);
        } else {
          const parentResource = resourceKeys[i - 1];
          if (resources?.[selectedResources[parentResource]]) {
            const { options } = resources?.[selectedResources[parentResource]];
            if (options.length === 1) handleDropdownChange(options[0]);
          }
        }
        return; // end For loop after any null value
      }
    }
  };

  useEffect(() => {
    // disable submit if there's no postMessageDataReceived or any selectedResources values are null
    const isResourceNull = Object.values(selectedResources).some((resource) => !resource);
    setHideSubmit(!postMessageDataReceived || isResourceNull);

    if (organizations.length) {
      // if there's only 1 resource available, select it
      autoSelectSingleOptions();
    }
  }, [organizations, postMessageDataReceived, selectedResources]); // eslint-disable-line react-hooks/exhaustive-deps

  const isSingular = postMessageDataReceived?.attachments?.length === 1;
  const titleCopy = isSingular ? <Trans>Smartsheet is sending you a file</Trans> : <Trans>Smartsheet is sending you files</Trans>;
  const instructionsCopy = isSingular ? <Trans>Where should it be saved?</Trans> : <Trans>Where should they be saved?</Trans>;
  const submitButtonCopy = isSingular ? <Trans>Accept file</Trans> : <Trans>Accept files</Trans>;

  const selectedOrganizationResource = selectedResources[ResourceTypes.Organizations];
  const brandfolderOptions = selectedOrganizationResource && resources?.[selectedOrganizationResource] ? resources[selectedOrganizationResource].options : [];

  const selectedBrandfolderResource = selectedResources[ResourceTypes.Brandfolders];
  const sectionOptions = selectedBrandfolderResource && resources?.[selectedBrandfolderResource] ? resources[selectedBrandfolderResource].options : [];

  const selectedSectionResource = selectedResources[ResourceTypes.Sections];

  const disableBrandfolderDropdown = !selectedOrganizationResource;
  const disableSectionsDropdown = !selectedBrandfolderResource;

  return (
    <div className="publish-form">
      <div className="publish-form__header">
        <span className="bff-cloud-download icon" />
        <h4 className="publish-form__header--title">{titleCopy}</h4>
      </div>
      <p className="publish-form__instructions">{instructionsCopy}</p>
      <div className="publish-form__options">
        <Label><Trans>Organization</Trans></Label>
        <ListDropdown
          onChange={handleDropdownChange}
          options={organizations || []}
          placeholder={t`Select Organization`}
          searchable
          value={selectedOrganizationResource || ''}
        />
        <Label attributes={{ className: disableBrandfolderDropdown ? 'disabled' : '' }}>
          <Trans>Brandfolder</Trans>
        </Label>
        <ListDropdown
          disabled={disableBrandfolderDropdown}
          onChange={handleDropdownChange}
          options={brandfolderOptions}
          placeholder={t`Select Brandfolder`}
          searchable
          value={selectedBrandfolderResource || ''}
        />
        <Label attributes={{ className: disableSectionsDropdown ? 'disabled' : '' }}>
          <Trans>Section</Trans>
        </Label>
        <ListDropdown
          disabled={disableSectionsDropdown}
          onChange={handleDropdownChange}
          options={sectionOptions}
          placeholder={t`Select section within Brandfolder`}
          searchable
          value={selectedSectionResource || ''}
        />
        <SelectLabels
          resetSelectedLabels={(): void => setSelectedLabels([])}
          selectedLabels={selectedLabels}
          selectedResources={selectedResources}
          setShowLabels={setShowLabels}
          showLabels={showLabels}
          updateSelectedLabels={(updatedLabels): void => setSelectedLabels(updatedLabels)}
        />
      </div>
      {!hideSubmit && (
        <PrimaryButton
          className="submit-button"
          disabled={hideSubmit}
          isLoading={isLoading}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit}
          size="small"
        >
          {submitButtonCopy}
        </PrimaryButton>
      )}
    </div>
  );
};
