import { OnlyForScreenReader } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, ReactElement } from 'react';

import ClipboardCopy from '@components/common/clipboard_copy/main';
import { useMatchMedia } from '@components/common/custom_hooks/useMatchMedia';
import { TertiaryButton } from '@components/library/button';
import { FontIcon, FontIcons } from '@components/library/icon';
import { StandardTooltip } from '@components/library/tooltip';

export const CopyLink: FunctionComponent = () => {
  const isSmallDesktop = useMatchMedia('(max-width: 1280px)');
  const label = t`Copy Link`;

  const renderLabel = (): ReactElement => {
    const buttonLabel = <span className="project__button-label">{label}</span>;
    if (isSmallDesktop) {
      return (
        <OnlyForScreenReader>
          {buttonLabel}
        </OnlyForScreenReader>
      );
    }
    return buttonLabel;
  };

  const renderButton = (): ReactElement => {
    const button = (
      <TertiaryButton
        className="project__button"
        id="project-share"
      >
        <FontIcon
          aria-hidden
          icon={FontIcons.Share}
        />
        {renderLabel()}
      </TertiaryButton>
    );

    if (isSmallDesktop) {
      return (
        <StandardTooltip id="project-share-tooltip" tooltip={label}>
          {button}
        </StandardTooltip>
      );
    }
    return button;
  };

  const renderCopyLink = (): ReactElement => {
    return (
      <ClipboardCopy
        showFeedbackAsTooltip
        textToCopy={window.location.href}
      >
        {renderButton()}
      </ClipboardCopy>
    );
  };

  // IMPORTANT: this component needs to return this function in order for
  // the useMatchMedia magic to happen for hiding the label on re-render
  return renderCopyLink();
};
