import { ListboxOption, StandardTagger } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { CustomFieldValue } from '@api/v4/assets/customFieldTypes';
import { CustomFieldKeyValuesEntry } from '@components/asset/modal/tabs/edit/EditTabTypes';

type UpdateTagOption = 'create' | 'remove';

interface MultiValueFreeEntryProps {
  existingCustomFieldMap: CustomFieldKeyValuesEntry;
  handleCreate: (
    customFieldValue: CustomFieldValue | CustomFieldValue[],
    customFieldRequired?: boolean
  ) => void;
  handleDelete: (
    customFieldValue: CustomFieldValue,
    customFieldRequired?: boolean
  ) => void;
  required: boolean;
}

export const MultiValueFreeEntry: FunctionComponent<MultiValueFreeEntryProps> =
  ({ existingCustomFieldMap, handleCreate, handleDelete, required }) => {
    const customFieldValueTags =
      existingCustomFieldMap?.customFieldValues.map((cfv) => ({
        key: cfv.key,
        label: cfv.value,
        children: <>{cfv.value}</>,
        value: cfv.value
      }));
    const hasValue = customFieldValueTags && customFieldValueTags.length > 0;
    const touched = existingCustomFieldMap?.customFieldTouched;
    const showRequired = required && touched && !hasValue;

    const handleOnCreateTag = (
      tags: ListboxOption[],
      tag: ListboxOption
    ): void => {
      if (tags?.length && !tag) {
        const needKeyTags = tags.map((newTag) => ({
          key: newTag.key.toString(),
          value: newTag.label,
        }));
        handleCreate(needKeyTags, required);
      } else if (tag.label) {
        handleCreate({ key: tag.key.toString(), value: tag.label }, required);
      }
    };

    const handleOnUpdateTag = (
      tags: ListboxOption[],
      action: UpdateTagOption,
      tag: ListboxOption
    ): void => {
      if (action === 'create') {
        handleOnCreateTag(tags, tag);
      }
      if (action === 'remove' && tag) {
        handleDelete({ key: tag.key.toString(), value: tag.label }, required);
      }
    };

    return (
      <div className="custom-field-values-input-container">
        {showRequired && (
          <p className="custom-field-value--required" role="alert">
            <Trans>Value required</Trans>
          </p>
        )}
        <div
          className={`custom-field-values-input${
            showRequired ? ' custom-field-values-input--required' : ''
          }`}
        >
          <div className="tags-container">
            <StandardTagger
              className="custom-field-values-tagger"
              clearTags={!customFieldValueTags?.length}
              comboboxProps={{
                comboboxTextareaProps: {
                  placeholder: 'Input value(s)',
                },
              }}
              id="multi-value-entry"
              initialTags={customFieldValueTags}
              labels={{
                label: t`tags`,
                removeTagLabel: t`remove tag`,
                iconButtonLabel: t`add tag`,
                listboxLabel: t`list`
              }}
              onTagged={handleOnUpdateTag}
              showLabel={false}
            />
          </div>
        </div>
      </div>
    );
  };
