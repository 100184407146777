import {
  ButtonLooks,
  FontIcon,
  FontIcons,
  ShimmerLoader,
  StandardButton,
  brandfolderColorPalette,
} from '@brandfolder/react';
import { Trans, t } from '@lingui/macro';
import React, { FormEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { ImageOverlay } from '../common/ImageOverlay';
import { LoadingOverlay } from '../common/LoadingOverlay';
import { MergeFacesDialog } from '../common/MergeFacesDialog';
import { TagPersonTextfield } from '../common/TagPersonTextfield';
import { Person } from '../customPeopleTagTypes';
import { useMergeFaces, useUpdatePersonTag } from '../fetch';

const FaceCardWrapper = styled.div`
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .default-image {
    background-color: ${brandfolderColorPalette.neutrals[200]};
    height: calc(100% - 52px);
  }

  .bf-loader__shimmer {
    width: 100%;

    &--br {
      height: 250px;
      width: 100%;
    }
  }
`;
const ImageWrapper = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
`;
const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 52px;
  padding: 0 16px;
  
  button.show-tag-input-button {
    height: 28px;
    justify-content: flex-start;
    min-height: 28px;
    padding: 8px 4px;
    width: 100%;

    &:hover {
      background-color: unset;
      border-color: #4462C9;
    }
  }

  .people-tag-input-container {
    width: 100%;

    .tag-person-textfield {
      input{
        height: 28px;
        min-height: 28px;
        padding: 8px 4px;
        width: 100%;
      }
    }
  }
`;
const CardImage = styled.img`
  height: 210px;
  object-fit: cover;
  width: 100%;
`;
const DefaultImage = styled.div`
  align-items: center;
  color: ${brandfolderColorPalette.neutrals[500]};
  display: flex; 
  height: 210px;
  justify-content: center;
`;
const Label = styled.div`
  color: ${brandfolderColorPalette.neutrals[700]};
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export interface FaceCardProps {
  id: string;
  imageUrl: string;
  label?: string;
  loading?: boolean;
  refetch?: () => Promise<void>;
}

export const FaceCard: FunctionComponent<FaceCardProps> = ({
  id,
  imageUrl,
  label = undefined,
  loading = true,
  refetch = undefined
}) => {
  const [hasImageError, setHasImageError] = useState(false);
  const [personLabel, setPersonLabel] = useState(label);
  const [showTagField, setShowTagField] = useState(false);
  const [showMergeFacesDialog, setShowMergeFacesDialog] = useState(false);
  const [targetPerson, setTargetPerson] = useState<Person>(undefined);

  const location = useLocation();
  const formRef = useRef(null);
  const textfieldRef = useRef(null);

  const { updatePersonTag } = useUpdatePersonTag();
  const { mergeFaces, mergeFacesLoading, mergeFacesSuccess } = useMergeFaces();

  useEffect(() => {
    if (!mergeFacesLoading && mergeFacesSuccess) {
      refetch();
      setTargetPerson(undefined);
    }
  }, [mergeFacesLoading, mergeFacesSuccess]);

  const handleCloseTagField = (e): void => {
    if (formRef.current?.contains(e.relatedTarget)) {
      return;
    } else {
      setShowTagField(false);
      setPersonLabel(label);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    updatePersonTag(id, personLabel);
    setShowTagField(false);
  };

  const handleMergeFaces = (): void => {
    mergeFaces(targetPerson.person_id, id);
    setShowMergeFacesDialog(false);
  };

  const handleCancelMergeFaces = (): void => {
    setShowMergeFacesDialog(false);
    setTargetPerson(undefined);
    setPersonLabel('');
    if (textfieldRef.current) {
      textfieldRef.current.value = '';
    }
  };

  const handleOnSelectTargetPerson = (person): void => {
    setShowMergeFacesDialog(true);
    setTargetPerson(person);
    textfieldRef.current.value = person.tag_name;
  };

  return (
    <>
      <FaceCardWrapper>
        {!loading ? (
          <>
            <ImageWrapper className={hasImageError && 'default-image'}>
              <ImageOverlay
                buttonText={t`View person`}
                linkTo={id}
                locationState={{
                  label,
                  prevRoute: `${location.pathname}${location.search}`
                }}
              >
                {!hasImageError ? (
                  <CardImage
                    alt="person"
                    onError={(): void => setHasImageError(true)}
                    src={imageUrl}
                  />
                ) : (
                  <DefaultImage>
                    <FontIcon
                      icon={FontIcons.UserFilled}
                      iconSize={68}
                      title="default image"
                    />
                  </DefaultImage>
                )}
              </ImageOverlay>
            </ImageWrapper>
            <LabelWrapper className="people-tag-label-wrapper">
              {personLabel && !showTagField && <Label>{personLabel}</Label>}
              {!personLabel && !showTagField && (
                <StandardButton
                  className="show-tag-input-button"
                  look={ButtonLooks.Default}
                  onClick={(): void => setShowTagField(true)}
                >
                  <Trans>Untagged person</Trans>
                </StandardButton>
              )}
              {showTagField && (
                <div
                  className="people-tag-input-container"
                >
                  <form
                    ref={formRef}
                    id={`tag-person-form-${id}`}
                    onBlur={handleCloseTagField}
                    onSubmit={handleSubmit}
                  >
                    <TagPersonTextfield
                      currentPerson={{ id, image: imageUrl }}
                      onSelectTargetPerson={handleOnSelectTargetPerson}
                      searchTag={personLabel}
                      setPersonLabel={setPersonLabel}
                      targetPerson={targetPerson}
                      textfieldRef={textfieldRef}
                    />
                  </form>
                </div>
              )}
            </LabelWrapper>
          </>
        ) : (
          <ShimmerLoader />
        )}
      </FaceCardWrapper>
      {showMergeFacesDialog && (
        <MergeFacesDialog
          currentPersonThumbnail={imageUrl}
          handleCancelMerge={handleCancelMergeFaces}
          handleMerge={handleMergeFaces}
          isOpen={showMergeFacesDialog}
          targetPersonThumbnail={targetPerson?.face_uri}
        />
      )}
      {mergeFacesLoading && (<LoadingOverlay />)}
    </>
  );
};
