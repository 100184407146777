/* eslint-disable @typescript-eslint/naming-convention */
import {
  AlertLooks,
  ButtonLooks,
  CircleLoader,
  FontAlignments,
  FontIcons,
  FontWeights,
  IconButton,
  SearchTextfield,
  StandardAlert,
  StandardButton,
  StandardCombobox,
  StandardPagination,
  StandardTable,
  StandardTableRow,
  StandardText,
  useDebounce,
  useMounted
} from '@brandfolder/react';
import { localizeDate } from '@brandfolder/utilities';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { decode } from 'html-entities';
import React, { FunctionComponent, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';

import { useFetch } from '@api/ApiHelper';
import { ApiData } from '@api/v4/ApiResponseTypes';
import { UserGroupServer } from '@api/v4/user_groups';
import { StandardDialog } from '@components/library/dialog';
import { getStandardComboboxLabels, getStandardPaginationLabels } from '@translations';

import classes from './styles/user-groups.module.scss';

interface UserGroupsListProps {
  /** Used for `@testing-library/react` tests only */
  debounce?: number;
}

type Order = 'asc' | 'desc';
type SortBy = 'created_at' | 'name';

interface ParamsState {
  order: Order;
  page: number;
  per: number;
  query: string;
  sort_by: SortBy;
}

const initialParams: ParamsState = {
  order: 'desc',
  page: 1,
  per: 10,
  query: '',
  sort_by: 'created_at'
};

type ParamsAction =
| { page: number; type: 'page'; }
| { query: string; type: 'query'; }
| { order: Order, sort_by: SortBy; type: 'sort' }
| { type: 'reset' };

const reducer = (state: ParamsState, action: ParamsAction): ParamsState => {
  const { type } = action;
  switch (type) {
    case 'page':
      return { ...state, page: action.page };
    case 'query':
      return { ...state, query: action.query, page: initialParams.page };
    case 'reset':
      return { ...initialParams };
    case 'sort':
      return { ...state, order: action.order, sort_by: action.sort_by };
    default:
      return { ...state };
  }
};

export const UserGroupsList: FunctionComponent<UserGroupsListProps> = (props) => {
  const { debounce = 400 } = props;

  const isMounted = useMounted();

  const [state, dispatch] = useReducer(reducer, initialParams);

  const {
    fetch: fetchGroups,
    loading: loadingGroups,
    response: fetchedGroups
  } = useFetch<ApiData<UserGroupServer, 'user_groups'>>({
    body: state,
    fetchOnMount: false,
    loadingDelay: 50,
    method: 'POST',
    fields: ['member_count'],
    url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/organization/user_groups/search`
  });

  const [deleteUserGroup, setDeleteUserGroup] = useState<{ key: string; name: string; } | null>(null);
  const [query, setQuery] = useState('');
  const queryDebounced = useDebounce(query, debounce);
  const [rows, setRows] = useState<StandardTableRow[]>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  const {
    fetch: deleteGroup,
    loading: deletingGroup,
    reset: deleteReset,
    response: deletedGroup
  } = useFetch({
    fetchOnMount: false,
    method: 'DELETE',
    url: `/api/v4/user_groups/${deleteUserGroup?.key}`
  });

  const formatRows = (userGroups: ApiData<UserGroupServer, 'user_groups'>): StandardTableRow[] => {
    return userGroups.data.map((userGroup) => {
      return {
        name: <Link className={classes.link} to={userGroup.id}>{decode(userGroup.attributes.name)}</Link>,
        createdAt: localizeDate(userGroup.attributes.created_at),
        count: userGroup.attributes.member_count || 0,
        delete: (
          <IconButton
            className={classnames(classes.textIconButton, classes.textButton)}
            icon={FontIcons.Trash}
            label={t`Delete user group`}
            look={ButtonLooks.TextWarning}
            onClick={(): void => {
              setDeleteUserGroup({ key: userGroup.id, name: userGroup.attributes.name });
              setShowDelete(true);
              deleteReset();
            }}
          />
        )
      };
    });
  };

  useEffect(() => {
    if (isMounted) {
      dispatch({ query: queryDebounced, type: 'query' });
    }
  }, [queryDebounced]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchedGroups?.data) {
      setRows(formatRows(fetchedGroups));
      setTotalPages(fetchedGroups.meta?.total_pages || 0);
    }
  }, [fetchedGroups]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchGroups();
    deleteReset();
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deletedGroup) {
      setDeleteUserGroup(null);
      setShowDelete(false);
      fetchGroups();
    }
  }, [deletedGroup]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section id="user-groups-list-section">
      {!showDelete && !deletingGroup && deletedGroup && (
        <StandardAlert
          className={classes.alert}
          look={AlertLooks.Success}
        >
          <Trans>User group deleted successfully!</Trans>
        </StandardAlert>
      )}

      <div className={classes.header}>
        <SearchTextfield
          className={classes.search}
          id="search-groups"
          label={t`Search user groups`}
          onChange={(e): void => setQuery(e.target.value)}
          placeholder={t`Search groups`}
          showLabel={false}
          value={query}
        />
        <StandardCombobox
          className={classes.sort}
          id="sort-groups"
          labels={getStandardComboboxLabels({
            iconButtonLabel: t`Show sort by options`,
            label: t`Sort By`,
            listboxLabel: t`Sort by options`
          })}
          onSelection={(option): void => {
            const parts = option.value.toString().split('|');
            dispatch({ order: parts[1] as Order, sort_by: parts[0] as SortBy, type: 'sort' });
          }}
          options={[
            {
              children: <Trans>Creation date (newest)</Trans>,
              key: 'created_at|desc',
              value: 'created_at|desc'
            },
            {
              children: <Trans>Creation date (oldest)</Trans>,
              key: 'created_at|asc',
              value: 'created_at|asc'
            },
            {
              children: <Trans>Name (A-Z)</Trans>,
              key: 'name|asc',
              value: 'name|asc'
            },
            {
              children: <Trans>Name (Z-A)</Trans>,
              key: 'name|desc',
              value: 'name|desc'
            }
          ]}
        />
      </div>
      <StandardTable
        caption={t`List of user groups with pagination`}
        columns={[
          {
            children: <Trans>Name</Trans>,
            heading: <Trans>Name</Trans>,
            rowKey: 'name',
            width: '50%'
          },
          {
            children: <Trans>Date Created</Trans>,
            heading: <Trans>Date Created</Trans>,
            rowKey: 'createdAt'
          },
          {
            children: <Trans>Number of Users</Trans>,
            heading: <Trans>Number of Users</Trans>,
            rowKey: 'count'
          },
          {
            children: null,
            heading: <Trans>Delete User Group</Trans>,
            rowKey: 'delete'
          }
        ]}
        empty={!loadingGroups && rows.length === 0}
        emptyContent={
          queryDebounced ? (
            <>
              <StandardText
                className={classes.empty}
              >
                <Trans>Sorry! No results were found.</Trans>
              </StandardText>
              <StandardButton
                className={classes.create}
                look={ButtonLooks.Default}
                onClick={(): void => {
                  setQuery('');
                  const searchInput = document.getElementById('search-groups');
                  if (searchInput) {
                    searchInput.focus();
                  }
                }}
              >
                <Trans>Try a different search</Trans>
              </StandardButton>
            </>
          ) : (
            <>
              <StandardText
                className={classes.empty}
              >
                <Trans>You haven't added any groups yet.</Trans>
              </StandardText>
              <StandardButton
                className={classes.create}
                look={ButtonLooks.Default}
                onClick={(): void => {
                  const groupName = document.getElementById('name');
                  if (groupName) {
                    groupName.focus();
                  }
                }}
              >
                <Trans>Create a group</Trans>
              </StandardButton>
              <img
                alt=""
                className={classes.img}
                src="https://cdn.brandfolder.io/27C9EC93/at/q6y1hf-fcrlsw-12xbfy/person-digging-in-empty-box.svg"
              />
            </>
          )
        }
        id="user-groups-list"
        loaderLabel={t`Loading`}
        loading={loadingGroups}
        rows={rows}
        scrollX={false}
      />
      {totalPages > 1 && (
        <StandardPagination
          className={classes.pagination}
          disabled={loadingGroups}
          labels={getStandardPaginationLabels()}
          onPageChange={(page): void => {
            dispatch({ page, type: 'page' });
          }}
          total={totalPages}
        />
      )}
      <StandardDialog
        id="delete-user-group-dialog"
        onClose={(): void => {
          setShowDelete(false);
        }}
        open={showDelete}
        setOpen={(): void => {
          setDeleteUserGroup(null);
          setShowDelete(false);
        }}
        showFooter={false}
        title={t`Delete group`}
        titleIcon={`bff-${FontIcons.People}`}
        width={450}
      >
        {!deleteUserGroup || deletingGroup ? (
          <>
            <CircleLoader label={t`Deleting group`} />
          </>
        ) : (
          <>
          <StandardText align={FontAlignments.Center}>
            <Trans>Are you sure you want to delete this group?</Trans>
          </StandardText>
          <StandardText align={FontAlignments.Center} className={classes.userGroupName} weight={FontWeights.Bold}>
            {decode(deleteUserGroup?.name)}
          </StandardText>
          <StandardButton
            className={classes.delete}
            look={ButtonLooks.Danger}
            onClick={(): void => {
              deleteGroup();
            }}
          >
            <Trans>Delete</Trans>
          </StandardButton>
          </>
        )}
      </StandardDialog>
    </section>
  );
};
