import { ReactNode } from 'react';

export enum ToastActionType {
  AddToast = 'addToast',
  RemoveToast = 'removeToast',
}

export enum ToastLooks {
  Error = 'error',
  Success = 'success',
}

export interface ToastMessage {
  id?: number;
  content?: ReactNode;
}

export interface ToastContext {
  toasts: ToastMessage[];
}

export interface ToastAction {
  type: string;
  payload: {
    value: ToastMessage;
  };
}
