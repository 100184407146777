/* eslint-disable @typescript-eslint/naming-convention */
import { ApiDataResponse } from '@api/v4/ApiResponseTypes';

export enum NotificationType {
  access_request,
  approval,
  asset_deletion,
  asset_expiration,
  asset_publish,
  asset_removal,
  asset_upload,
  asset_will_expire,
  attachment_duplication,
  collection_add_asset,
  comment,
  download_request,
  generic,
  guest_password_change,
  invite_accepted,
  message,
  privacy_change,
  section_deletion,
  share_link_asset_downloaded,
  share_link_asset_viewed,
  share_link_viewed,
  template_approval,
  user_groups
}

export interface Notification {
  body: string;
  notification_type: string;
  seen: boolean;
  timestamp: string;
  title: string;
  btn_link?: string;
  btn_text?: string;
  btn_type?: string;
  id?: string;
  image_source?: string;
}

export type NotificationsListResponse = ApiDataResponse<Notification, 'notifications'>;
