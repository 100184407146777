/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { FontIcon, FontIcons } from '../library/icon';

import { fetchOptionsForBrandfolders, fetchOptionsForDataSyncResponse, fetchOptionsForTurnOnSync, syncNotify,  fetchOptionsForTurnOffSync, fetchOptionsForResetSync, fetchOptionsForStopSyncingData, convertUTCDate } from './helpers';
import InsightsDataSyncTable from './InsightsDataSyncTable';
import { DataSyncActionTypes, DataSyncStatusResults } from './InsightsEnums';
import { BrandfoldersResponse, BrandfolderSyncStatusResponseDatum, DataSyncStateMap, InsightsDataSyncResponse, InsightsDataSyncResponseDatum } from './InsightsTypes';
import { IntegrationsSmartsheetConnect } from './IntegrationsSmartsheetConnect';

interface InsightsDataSmartsheetConnectProps {
  orgApiKey: string;
}

const InsightsDataSmartsheetConnect: FunctionComponent<InsightsDataSmartsheetConnectProps> = ({ orgApiKey }) => {
  const [dataSyncState, setDataSyncState] = useState({});
  const [targetBrandfolderId, setTargetBrandfolderId] = useState(null);
  const [syncActionType, setSyncActionType] = useState(null);
  const [hasUserConfirmed, setHasUserConfirmed] = useState(false);
  const [brandfoldersData, setBrandfoldersData] = useState(null);

  const getUsersBrandfoldersListData = useFetch<BrandfoldersResponse>(
    fetchOptionsForBrandfolders()
  );

  const getUserDataSyncResponse = useFetch<InsightsDataSyncResponse>(
    fetchOptionsForDataSyncResponse(orgApiKey)
  );

  const turnOnSyncResponse = useFetch<InsightsDataSyncResponse>(
    fetchOptionsForTurnOnSync(orgApiKey, targetBrandfolderId)
  );

  const turnOffSyncResponse = useFetch<InsightsDataSyncResponse>(
    fetchOptionsForTurnOffSync(orgApiKey, targetBrandfolderId)
  );

  const resetSyncResponse = useFetch<InsightsDataSyncResponse>(
    fetchOptionsForResetSync(orgApiKey, targetBrandfolderId)
  );

  const stopSyncingResponse = useFetch<InsightsDataSyncResponse>(
    fetchOptionsForStopSyncingData(orgApiKey)
  );

  const { data: userSyncData } = { ...getUserDataSyncResponse?.response };
  let { data: turnOnSyncData } = { ...turnOnSyncResponse?.response };
  let { data: turnOffSyncData } = { ...turnOffSyncResponse?.response };
  let { data: resetSyncData } = { ...resetSyncResponse?.response };
  let { data: stopSyncingData } = { ...stopSyncingResponse?.response };

  const isAuthed: boolean | undefined = userSyncData?.smartsheet_configuration?.has_authenticated;
  const syncedBrandfolders: [] | BrandfolderSyncStatusResponseDatum[] = userSyncData?.brandfolders;

  const handleTurnOnSync = (id: string): void => {
    setTargetBrandfolderId(id);
    setSyncActionType(DataSyncActionTypes.TurnOn);
  };

  const handleStopSyncingData = (): void => {
    stopSyncingResponse.fetch();
  };

  const displaySyncStatusIcon = (syncedBrandfolder?: BrandfolderSyncStatusResponseDatum): ReactElement => {
    let statusIcon;
    switch (syncedBrandfolder.sync_status) {
      case DataSyncStatusResults.Success:
        statusIcon = (<FontIcon aria-hidden className="data-sync-status-icon data-sync-status-icon__refresh" icon={FontIcons.Refresh} ></FontIcon>);
        break;
      case DataSyncStatusResults.Error:
        statusIcon = (<FontIcon aria-hidden className="data-sync-status-icon data-sync-status-icon__alert" icon={FontIcons.Alert}></FontIcon>);
        break;
      case DataSyncStatusResults.InProgress:
        statusIcon = (<FontIcon aria-hidden className="data-sync-status-icon data-sync-status-icon__refresh" icon={FontIcons.RefreshSpinning}></FontIcon>);
        break;
      default:
        statusIcon = (<FontIcon aria-hidden className="data-sync-status-icon data-sync-status-icon__x-circle" icon={FontIcons.XCircle}></FontIcon>);
    }
    return statusIcon;
  };

  const displaySyncStatusText = (syncedBrandfolder?: BrandfolderSyncStatusResponseDatum): ReactElement => {
    let statusText: string;
    let statusDate: string;
    if (syncedBrandfolder.last_sync_time && syncedBrandfolder.sync_status === DataSyncStatusResults.Success) {
      statusDate = convertUTCDate(syncedBrandfolder);
      statusText = t`Synced on ${statusDate}`;
    } else if (syncedBrandfolder.last_sync_time && syncedBrandfolder.sync_status === DataSyncStatusResults.Error) {
      statusDate = convertUTCDate(syncedBrandfolder);
      statusText = t`Sync Failed on ${statusDate}`;
    } else if (syncedBrandfolder.sync_status === DataSyncStatusResults.InProgress) {
      statusText = t`Sync in progress`;
      setTimeout(() => {
        getUserDataSyncResponse.fetch();
      }, 3000);
    } else {
      statusText = t`Not synced to Smartsheet`;
    }
    return syncedBrandfolder?.sync_status === DataSyncStatusResults.Error ? (<p className="data-sync-status-text-error">{statusText}</p>) : (<p className="data-sync-status-text">{statusText}</p>);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const updateSyncStatus = (syncedBrandfolder: BrandfolderSyncStatusResponseDatum) => {
    const syncStatus = {
      statusIcon: displaySyncStatusIcon(syncedBrandfolder),
      statusText: displaySyncStatusText(syncedBrandfolder)
    };

    return syncStatus;
  };

  const populateDataSyncState = (): DataSyncStateMap => {
    return brandfoldersData.reduce((switchStateAcc, brandfolder) => {
      switchStateAcc[brandfolder.id] = {
        isSyncing: false,
        lastSyncTime: null,
        syncResult: null,
        syncStatus: {
          statusIcon: (<FontIcon aria-hidden className="data-sync-status-icon data-sync-status-icon__x-circle" icon={FontIcons.XCircle}></FontIcon>),
          statusText: (<p className="data-sync-status-text"><Trans>Not synced to Smartsheet</Trans></p>)
        }
      };
      return switchStateAcc;
    }, {});
  };

  const updateDataSyncState = (response: InsightsDataSyncResponseDatum): DataSyncStateMap => {
    return brandfoldersData.reduce((syncStateAcc, brandfolder) => {
      response?.brandfolders.map((syncedBrandfolder: BrandfolderSyncStatusResponseDatum) => {
        if (brandfolder.id === syncedBrandfolder.brandfolder_key) {
          syncStateAcc[brandfolder.id] = {
            isSyncing: true,
            lastSyncTime: syncedBrandfolder.last_sync_time,
            syncResult: syncedBrandfolder.sync_status,
            syncStatus: updateSyncStatus(syncedBrandfolder),
          };
        } else if (!syncStateAcc[brandfolder.id] && brandfolder.id !== syncedBrandfolder.brandfolder_key) {
          syncStateAcc[brandfolder.id] = {
            isSyncing: false,
            lastSyncTime: null,
            syncResult: null,
            syncStatus: {
              statusIcon: (<FontIcon aria-hidden className="data-sync-status-icon data-sync-status-icon__x-circle" icon={FontIcons.XCircle}></FontIcon>),
              statusText: (<p className="data-sync-status-text"><Trans>Not synced to Smartsheet</Trans></p>)
            }
          };
        }
      });
      return syncStateAcc;
    }, {});
  };

  const handleDataSyncState = (response: InsightsDataSyncResponseDatum):void => {
    let syncStateMap: DataSyncStateMap;

    if (!response?.brandfolders.length) {
      syncStateMap = populateDataSyncState();
    } else {
      syncStateMap = updateDataSyncState(response);
    }
    setDataSyncState({ ...syncStateMap });
  };

  const handleDataSyncUpdateInProgress = (): void => {
    dataSyncState[targetBrandfolderId].syncStatus = {
      statusIcon: (<FontIcon aria-hidden className="data-sync-status-icon data-sync-status-icon__refresh-spinning" icon={FontIcons.RefreshSpinning}></FontIcon>),
      statusText: (<p className="data-sync-status-text"><Trans>Updating sync...</Trans></p>)
    };
  };

  const getTargetBrandfolderName = (): string => {
    let targetName: string;
    if (targetBrandfolderId) {
      const brandfolderObj = brandfoldersData.find((brandfolder) => {
        return brandfolder.id === targetBrandfolderId;

      });
      targetName = brandfolderObj.attributes.name;
    } else {
      targetName = 'Your Selected Brandfolder';
    }
    return targetName;
  };

  useEffect(() => {
    if (targetBrandfolderId !== null && syncActionType === DataSyncActionTypes.TurnOn) {
      turnOffSyncData = null;
      resetSyncData = null;
      stopSyncingData = null;
      turnOnSyncResponse.fetch();
      handleDataSyncUpdateInProgress();
    }
    if (targetBrandfolderId !== null && syncActionType === DataSyncActionTypes.TurnOff && hasUserConfirmed) {
      turnOnSyncData = null;
      resetSyncData = null;
      stopSyncingData = null;
      turnOffSyncResponse.fetch();
      handleDataSyncUpdateInProgress();
    }
    if (targetBrandfolderId !== null && syncActionType === DataSyncActionTypes.Reset && hasUserConfirmed) {
      turnOnSyncData = null;
      turnOffSyncData = null;
      stopSyncingData = null;
      resetSyncResponse.fetch();
      handleDataSyncUpdateInProgress();
    }
  }, [targetBrandfolderId, syncActionType, hasUserConfirmed]);

  useEffect(() => {
    if (turnOnSyncData) {
      setHasUserConfirmed(false);
      handleDataSyncState(turnOnSyncData);
      setTargetBrandfolderId(null);
    }
  }, [turnOnSyncData]);

  useEffect(() => {
    if (turnOffSyncData) {
      setHasUserConfirmed(false);
      handleDataSyncState(turnOffSyncData);
      setTargetBrandfolderId(null);
    }
  }, [turnOffSyncData]);

  useEffect(() => {
    if (resetSyncData) {
      setHasUserConfirmed(false);
      handleDataSyncState(resetSyncData);
      setTargetBrandfolderId(null);
    }
  }, [resetSyncData]);

  useEffect(() => {
    if (stopSyncingData) {
      setHasUserConfirmed(false);
      handleDataSyncState(stopSyncingData);
    }
  }, [stopSyncingData]);

  useEffect(() => {
    if (brandfoldersData && syncedBrandfolders) {
      handleDataSyncState(userSyncData);
    }
  }, [brandfoldersData, syncedBrandfolders]);

  useEffect(() => {
    if (getUsersBrandfoldersListData?.response) {
      setBrandfoldersData(getUsersBrandfoldersListData.response.data);
    }
  }, [getUsersBrandfoldersListData?.response]);

  useEffect(() => {
    if (turnOnSyncResponse?.response?.data && !turnOnSyncResponse?.error) {
      syncNotify('Your insights will sync daily at 5:00am MT', 'Data sync success!', 'success');
    }
  }, [turnOnSyncResponse.response]);

  useEffect(() => {
    if (turnOffSyncResponse?.response?.data && !turnOffSyncResponse?.error) {
      syncNotify('Your insights data will no longer sync daily.', 'Stop data sync success!', 'success');
    }
  }, [turnOffSyncResponse.response]);

  useEffect(() => {
    if (resetSyncResponse?.response?.data && !resetSyncResponse?.error) {
      syncNotify('Your insights will sync daily at 5:00am MT', 'Smartsheet data sheet reset success', 'success');
    }
  }, [resetSyncResponse.response]);

  useEffect(() => {
    if (stopSyncingResponse?.response && !stopSyncingResponse?.error) {
      syncNotify('Your insights data no longer sync daily.', 'Stop all data sync success!', 'success');
    }
  }, [stopSyncingResponse.response]);

  return (
    <I18nProviderWrapper>
      <section className="insights-data-connect">
        <div className="smartsheet-data-connector-container">
          <img alt="Smartsheet checkbox logo blue" className="smartsheet-checkbox-logo" src="https://cdn.bfldr.com/G51PIVV1/at/8fbpwqx8fnhrhj4tgkp4jck/smartsheet-logo-mark.svg?auto=webp&format=svg"></img>
          <h3 className="smartsheet-data-connector-title"><Trans>Insights Data in Smartsheet</Trans></h3>
          <span className="smartsheet-data-connector-description">
            <Trans>
              <p className="smartsheet-data-connector-description-text">
                Automatically send your Insights asset performance data to Smartsheet. This includes user data as well as asset data in separate automatically-generated sheets. Get tips on how to effectively manage the data in our&nbsp;
              </p>
              <a className="smartsheet-data-connector-description-link" href='https://help.smartsheet.com/4421309127831-Insights-Data-in-Smartsheet' target="_blank">Knowledge Base.</a>
            </Trans>
          </span>
          <div className="smartsheet-connect-container">
            <IntegrationsSmartsheetConnect brandfoldersData={brandfoldersData} orgApiKey={orgApiKey} stopSyncingData={handleStopSyncingData}/>
          </div>
        </div>
        <div className="data-insights-table-container">
          <InsightsDataSyncTable
            brandfoldersData={brandfoldersData}
            dataSyncState={dataSyncState}
            getTargetBrandfolderName={getTargetBrandfolderName}
            isAuthed={isAuthed}
            setDataSyncState={setDataSyncState}
            setHasUserConfirmed={setHasUserConfirmed}
            setSyncActionType={setSyncActionType}
            setTargetBrandfolderId={setTargetBrandfolderId}
            targetBrandfolderId={targetBrandfolderId}
            turnOnSync={(id): void => handleTurnOnSync(id)}
            userSyncData={userSyncData}
          />
        </div>
      </section>
    </I18nProviderWrapper>
  );
};

export default InsightsDataSmartsheetConnect;
