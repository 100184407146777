/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from '@lingui/macro';

import { TaskPriorities, TaskStatuses } from '@api/v4/tasks';
import {
  AvailabilityFilters,
  DueDateFilters,
  Filters,
  FilterType //is an enum that includes all the default filters including view_only
} from '@components/show_page/sections/section_search/SearchTypes';

/**
 * Get default Advanced Filters
 * @returns Filters
 */
export const defaultFilters = (): Filters => ({ //these are the actual filters on the page
  availability: null,
  comments: null,
  created_at: null,
  custom_fields: {},
  filetypes: [],
  label: null,
  orientation: [],
  section: '',
  tags: [],
  task_assigned_to: '',
  task_due_date: '',
  task_priority: TaskPriorities.All,
  task_status: TaskStatuses.All,
  view_only: null,
});

export const FILTER_DATE_RANGE_OPERATOR = ' TO ';

/**
 * Get date range array from filter string
 * @example task_due_date: [2021-08-06 TO 2021-10-05] => ["2021-08-06", "2021-10-05"]
 * @example [2021-08-06 TO 2021-10-05] => ["2021-08-06", "2021-10-05"]
 * @param customDateRange string
 * @returns string[]
 */
export const getCustomDateRangeArray = (customDateRange: string): [string, string] => {
  const seperators = ['\\[', '\\]', FILTER_DATE_RANGE_OPERATOR]; // separate on [, ], and TO
  const regexSeperators = new RegExp(seperators.join('|'), 'g');
  const parts = customDateRange.split(regexSeperators);
  return [parts[1], parts[2]];
};

/**
 * Get custom date range string
 * @param dateFromIso string
 * @param dateToIso string
 * @returns string
 */
export const getCustomDateRangeString = (dateFromIso: string, dateToIso: string): string => `[${dateFromIso.split('T')[0]}${FILTER_DATE_RANGE_OPERATOR}${dateToIso.split('T')[0]}]`;

/**
 * Get the due date range string. Defaults to today and 60 days in the future for "Custom".
 * @returns string
 */
export const getDueDateRangeString = (numberOfdaysFromNow = 60): string => {
  const now = new Date();
  const nowIso = now.toISOString();

  const daysFromNow = new Date(now);
  daysFromNow.setDate(daysFromNow.getDate() + numberOfdaysFromNow);
  const sixtyDaysFromNowIso = daysFromNow.toISOString();

  return getCustomDateRangeString(nowIso, sixtyDaysFromNowIso);
};

/**
 * Get whether a custom rate range filter is valid
 * @example [2021-08-06 TO 2021-10-05] => true
 * @param customDateRange string | null
 * @returns boolean
 */
export const getIsCustomDateRange = (customDateRange: string | null): boolean => customDateRange
    && customDateRange.includes('[')
    && customDateRange.includes(FILTER_DATE_RANGE_OPERATOR)
    && customDateRange.includes(']');

/**
 * Get created_at string for display with "Filtering by:"
 * @param value string
 * @returns string
 */
const mapUploadedDate = (value: string): string => {
  if (getIsCustomDateRange(value)) { // created_at date range
    const dateRange = getCustomDateRangeArray(value);
    return `uploaded: between ${dateRange[0]} and ${dateRange[1]}`;
  }

  const createdAtMap = {
    'created_at: >now-30m': 'uploaded: last 30 minutes',
    'created_at: >now-1d': 'uploaded: last 24 hours',
    'created_at: >now-7d': 'uploaded: last 7 days'
  };

  return createdAtMap[value];
};

/**
 * Handle rendering the string for display with "Filtering by:"
 * @param value string
 * @param filterStrings string[]
 * @returns string[]
 */
const checkAndUpdateFilterStringArray = (value: string, filterStrings: string[]): string[] => {
  if (value.includes('section: ')) {
    // section filters apply only when sections are disabled (userViewOptions.showSections === false)
    const sectionIndex = filterStrings.findIndex((filter) => filter.includes('section: '));
    const previousValue = filterStrings[sectionIndex];
    filterStrings.splice(sectionIndex, sectionIndex > -1 ? 1 : 0); // remove existing section filter

    if (value.includes('section: all') || value === previousValue || value.includes('section: null')) {
      // no section filter for 'all' or if clicking on an already selected section
      return filterStrings;
    }

    filterStrings.push(value);
    return filterStrings;
  }

  if (value.includes('created_at')) {
    for (let i = 0; i < filterStrings.length; i += 1) {
      // TODO: track down where undefined is being populated into the filterStrings array
      if (filterStrings[i]?.indexOf('uploaded') > -1) {
        filterStrings.splice(i, 1); // remove existing created_at filters
      }
    }

    if (!value.includes('null')) {
      filterStrings.push(mapUploadedDate(value));
    }
  } else if (Object.values(AvailabilityFilters).includes(value as AvailabilityFilters)) {
    if (!filterStrings.includes(value)) {
      const previousAvailabilityIndex = filterStrings.findIndex((filter) => Object.values(AvailabilityFilters).includes(filter as AvailabilityFilters));
      if (previousAvailabilityIndex > -1) {
        filterStrings.splice(previousAvailabilityIndex, 1); // remove existing availability filter
      }
      filterStrings.push(value); // add new one
    }
  } else if (value.includes(FilterType.TaskAssignedTo)) {
    // always filter out assigned to me
    // eslint-disable-next-line no-param-reassign
    filterStrings = filterStrings.filter((filter) => !filter.includes('assigned to me'));

    // display "assigned to me" instead of "task_assigned_to: abc123"
    if (value !== `${FilterType.TaskAssignedTo}: `) {
      filterStrings.push(t`assigned to me`);
    }
  } else if (value.includes(FilterType.TaskDueDate)) {
    // always filter out due date
    // eslint-disable-next-line no-param-reassign
    filterStrings = filterStrings.filter((filter) => !filter.includes('due date'));

    // display "due date: with 7 days" instead of "task_due_date: <now-7d"
    // OR for custom due date range:
    // display "due date: between 8/6/2021 and 10/5/2021" instead of "task_due_date: [2021-08-06 TO 2021-10-05]"
    if (getIsCustomDateRange(value)) {
      const dateRange = getCustomDateRangeArray(value);
      filterStrings.push(`due date: between ${dateRange[0]} and ${dateRange[1]}`);
    } else if (value !== `${FilterType.TaskDueDate}: `) {
      const dueDateMap = {
        [`${FilterType.TaskDueDate}: ${DueDateFilters.Within7Days}`]: t`due date: within 7 days`,
        [`${FilterType.TaskDueDate}: ${DueDateFilters.Within14Days}`]: t`due date: within 14 days`,
        [`${FilterType.TaskDueDate}: ${DueDateFilters.Within30Days}`]: t`due date: within 30 days`
      };
      filterStrings.push(dueDateMap[value]);
    }
  } else if (value.includes(FilterType.TaskPriority)) {
    // always filter out priority
    // eslint-disable-next-line no-param-reassign
    // TODO: I think this would cause errors in other locales
    filterStrings = filterStrings.filter((filter) => !filter.includes('priority'));

    // display "priority: medium" instead of "task_priority: medium"
    if (value !== `${FilterType.TaskPriority}: `) {
      const priorityMap = {
        [`${FilterType.TaskPriority}: ${TaskPriorities.Low}`]: t`priority: low`,
        [`${FilterType.TaskPriority}: ${TaskPriorities.Medium}`]: t`priority: medium`,
        [`${FilterType.TaskPriority}: ${TaskPriorities.High}`]: t`priority: high`
      };
      filterStrings.push(priorityMap[value]);
    }
  } else if (value.includes(FilterType.TaskStatus)) {
    // always filter out status
    // eslint-disable-next-line no-param-reassign
    filterStrings = filterStrings.filter((filter) => !filter.includes('status'));

    // display "status: not started" instead of "task_status: not_started"
    if (value !== `${FilterType.TaskStatus}: `) {
      const statusMap = {
        [`${FilterType.TaskStatus}: ${TaskStatuses.NotStarted}`]: t`status: not started`,
        [`${FilterType.TaskStatus}: ${TaskStatuses.InProgress}`]: t`status: in progress`,
        [`${FilterType.TaskStatus}: ${TaskStatuses.Completed}`]: t`status: completed`
      };
      filterStrings.push(statusMap[value]);
    }
  } else {
    const valueIndex = filterStrings.indexOf(value);

    if (valueIndex > -1) {
      filterStrings.splice(valueIndex, 1);
    } else {
      filterStrings.push(value);
    }
  }

  return filterStrings;
};

interface ManageFiltersOptions {
  filters: Filters;
  filterStrings: string[];
  filterType: FilterType;
  keyValue: any;
  subValue?: any;
}

interface ManageFiltersReturn {
  filters: Filters;
  filterStrings: string[];
}

/**
 * Handle advanced filters, both the filter that gets sent to the API call and also the UI display
 * @param options ManageFiltersOptions
 * @returns ManageFiltersReturn
 */
export const manageFilters = (options: ManageFiltersOptions): ManageFiltersReturn => {
  const {
    filters,
    filterStrings: filterStrings_,
    filterType,
    keyValue,
    subValue,
  } = options;

  let scrubbedKeyValue;
  let scrubbedSubValue;
  let filterStrings = filterStrings_;
  const currentFilters = { ...filters };
  const currentFilterValue = currentFilters[filterType];

  switch (filterType) {
    case 'tags':
      scrubbedKeyValue = keyValue.replace('"', '\\"');
      scrubbedSubValue = subValue;
      break;
    case 'custom_fields': case 'remove_custom_field':
      scrubbedKeyValue = keyValue;
      scrubbedSubValue = subValue.replace('"', '\\"');
      break;
    default:
      scrubbedKeyValue = keyValue;
      scrubbedSubValue = subValue;
      break;
  }

  switch (filterType) {
    case 'orientation': case 'tags': case 'filetypes':
      filterStrings = checkAndUpdateFilterStringArray(keyValue, filterStrings);
      if (keyValue === null) {
        currentFilters.filterType = [];
      } else {
        const existingValue = currentFilters[filterType].indexOf(scrubbedKeyValue);
        if (existingValue > -1) {
          currentFilters[filterType].splice(existingValue, 1);
        } else {
          currentFilters[filterType].push(scrubbedKeyValue);
        }
      }
      break;
    case 'custom_fields':
      filterStrings = checkAndUpdateFilterStringArray(`${keyValue}: ${subValue}`, filterStrings);
      if (subValue) {
        currentFilters[filterType] = currentFilters[filterType] || {};
        currentFilters[filterType][keyValue] = currentFilters[filterType][keyValue] || [];
        currentFilters[filterType][keyValue].push(scrubbedSubValue);
      }
      break;
    case 'remove_custom_field':
      filterStrings = checkAndUpdateFilterStringArray(`${keyValue}: ${subValue}`, filterStrings);
      currentFilters.custom_fields[keyValue] = currentFilters.custom_fields[keyValue].filter((value) => value !== scrubbedSubValue);
      if (currentFilters.custom_fields[keyValue].length === 0) {
        delete currentFilters.custom_fields[keyValue];
      }
      break;
    case 'comments':
      filterStrings = checkAndUpdateFilterStringArray('comments', filterStrings);
      if (currentFilters[filterType] === true) {
        currentFilters[filterType] = null;
        break;
      } else {
        currentFilters[filterType] = keyValue;
        break;
      }
    case 'created_at':
      filterStrings = checkAndUpdateFilterStringArray(`${filterType}: ${keyValue}`, filterStrings);
      currentFilters[filterType] = keyValue;
      break;
    case 'section':
      filterStrings = checkAndUpdateFilterStringArray(`${filterType}: ${keyValue}`, filterStrings);
      currentFilters[filterType] = (keyValue === 'all' || currentFilterValue === keyValue || keyValue === null)
        ? ''
        : keyValue;
      break;
    case 'availability':
      currentFilters[filterType] = keyValue;
      filterStrings = keyValue === null || keyValue === 'all'
        ? filterStrings.filter((filter) => !Object.values(AvailabilityFilters).includes(filter as AvailabilityFilters)) // remove any availability value
        : checkAndUpdateFilterStringArray(keyValue, filterStrings);
      break;
    case FilterType.TaskAssignedTo:
      filterStrings = checkAndUpdateFilterStringArray(`${filterType}: ${keyValue}`, filterStrings);
      currentFilters[filterType] = keyValue;
      break;
    case FilterType.TaskDueDate:
      filterStrings = checkAndUpdateFilterStringArray(`${filterType}: ${keyValue}`, filterStrings);
      currentFilters[filterType] = keyValue;
      break;
    case FilterType.TaskPriority:
      filterStrings = checkAndUpdateFilterStringArray(`${filterType}: ${keyValue}`, filterStrings);
      currentFilters[filterType] = keyValue;
      break;
    case FilterType.TaskStatus:
      filterStrings = checkAndUpdateFilterStringArray(`${filterType}: ${keyValue}`, filterStrings);
      currentFilters[filterType] = keyValue;
      break;
    case FilterType.ViewOnly:
      if (keyValue) {
        filterStrings = checkAndUpdateFilterStringArray(filterType, filterStrings);
        currentFilters[filterType] = keyValue;
      } else {
        filterStrings = filterStrings.filter((filter) => filter !== filterType);
        currentFilters[filterType] = undefined;
      }
      break;
    default:
      currentFilters[filterType as string] = keyValue;
      if (keyValue !== null) {
        filterStrings = checkAndUpdateFilterStringArray(keyValue, filterStrings);
      }
      break;
  }

  return {
    filters: currentFilters,
    filterStrings,
  };
};
