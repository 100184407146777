import React, { FunctionComponent } from 'react';

const ColorCircle: FunctionComponent<{getBackgroundColor: () => string; id: string}> = ({ getBackgroundColor, id }) => {
  const backgroundHex = getBackgroundColor();
  const isValidHex = /^([0-9A-F]{3}){1,2}$/i.test(backgroundHex);
  const colorCss = {
    backgroundColor: `#${backgroundHex}`
  };
  return (
    <div
      className={`edit-tab-sidebar-color-display-circle ${isValidHex ? '' : 'no-value'}`}
      id={id}
      style={colorCss}
    />
  );
};

export default ColorCircle;
