import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import createAssets from '@api/v4/assets';
import { UppyUploader } from '@components/common/uppy/UppyUploader';
import {
  formattedUppyAssets,
  uniqueFilesByKey,
  duplicateSweetAlertOptions } from '@helpers/uppy';

class AssetUppy extends React.Component {
  triggerUpload = (files) => {
    const { activeLabelKey } = this.props;
    const uniqFiles = uniqueFilesByKey(files, "md5hash");
    if (files.length !== uniqFiles.length) {
      window.swal(duplicateSweetAlertOptions, (removeDuplicates) => {
        if (removeDuplicates) {
          this.uploadAssets(formattedUppyAssets(uniqFiles, activeLabelKey));
        } else {
          this.uploadAssets(formattedUppyAssets(files, activeLabelKey));
        }
      });
    } else {
      this.uploadAssets(formattedUppyAssets(files, activeLabelKey));
    }
  }

  uploadAssets = (assets) => {
    const { onAssetProcessing, sectionKey } = this.props;
    const options = {
      url: this.uploadEndpoint(),
      section_key: sectionKey,
      data: { attributes: assets }
    };

    onAssetProcessing(assets.length);
    createAssets(options)
      .then((response) => {
        if (response && !response.ok) {
          this.props.newResources(response);
        } else {
          Notify.create({
            title: t`An error occurred while uploading assets. Please try again.`,
            type: 'error'
          });
        }
        onAssetProcessing(0);
      });
  }

  trigger = () => `uppy-asset-upload-trigger-${this.props.sectionKey}`;

  uploadEndpoint() {
    const brandfolderKey = BF.fx.brandfolder().key;
    const collectionKey = BF.fx.collection() ? BF.fx.collection().key : null;
    return collectionKey ? `/api/v4/collections/${collectionKey}/assets?fast_jsonapi=true&queue_priority=high`
      : `/api/v4/brandfolders/${brandfolderKey}/assets?fast_jsonapi=true&queue_priority=high`;
  }

  renderArea() {
    return (
      <div className="uppy-asset-area" id={this.trigger()}>
        <span className="bff-plus" />
        <p><Trans>Click to add an asset</Trans></p>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderArea()}
        <UppyUploader
          {...this.props}
          button
          handleUpload={this.triggerUpload}
          template="file_ingest"
          trigger={`#${this.trigger()}`}
          uniqIdentifier={`asset-uppy-${this.props.sectionKey}`}
        />
      </React.Fragment>
    );
  }
}

AssetUppy.propTypes = {
  activeLabelKey: PropTypes.string,
  sectionKey: PropTypes.string.isRequired,
  newResources: PropTypes.func.isRequired,
  onAssetProcessing: PropTypes.func,
};

AssetUppy.defaultProps = {
  activeLabelKey: null,
  onAssetProcessing: () => {},
};

export default AssetUppy;
