/* eslint-disable @typescript-eslint/naming-convention */
import { TaskClientBase } from '@api/v4/tasks';
import { ReducerState } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { getIsSameArray } from '@components/asset/modal/tabs/edit/helpers';
import { CreateAttachmentProperties } from './attachmentChangeEngine';

export interface TaskChanges {
  attachments: CreateAttachmentProperties[];
  data: Partial<TaskClientBase>;
}

enum TaskValues {
  Attachments,
  Data,
  Dimensions,
  DueDate,
  Filetype,
  Name,
  TaskDescription,
  TaskPriority,
  TaskStatus,
  TaskUsers
}

const getNewAttachments = (changes: Partial<TaskChanges>, state: ReducerState): Partial<TaskChanges> => {
  const { editState, initialData } = state;

  const newAttachments: CreateAttachmentProperties[] = [];

  editState.attachments.forEach((editAttachment) => {
    const matchingAttachment = initialData.attachments.find((initialAttachment) => initialAttachment.key === editAttachment.key);
    if (!matchingAttachment) {
      const { key, filename, position, url } = editAttachment;
      newAttachments.push({ key, filename, position, url });
    }
  });

  return newAttachments.length > 0
    ? {
      ...changes,
      attachments: newAttachments
    }
    : changes;
};

const getTaskDetailsChanges = (changes: Partial<TaskChanges>, state: ReducerState, taskDetailString: keyof TaskClientBase): Partial<TaskChanges> => {
  if (!state.initialData.task) {
    // for CREATING tasks, return edited data without comparing to initialData since all data is new
    return {
      ...changes,
      data: {
        ...state.editState.task
      },
    };
  }

  const initial = state.initialData.task[taskDetailString];
  const edited = state.editState.task[taskDetailString];

  // handle editState.taskUsers
  if (Array.isArray(edited)) {
    if (initial === null && edited.length === 0) {
      // don't register as a change when initial value is null and edited value is an empty array
      return { ...changes };
    }

    if ((initial === null && edited.length >= 0) || (Array.isArray(initial) && !getIsSameArray(initial, edited))) {
      return {
        ...changes,
        data: {
          ...state.editState.task,
          [taskDetailString]: edited
        }
      };
    }
  } else {
    if (initial === null && edited === '') {
      // don't register as a change when initial value is null and edited value is empty string
      return { ...changes };
    }

    if (initial !== edited) {
      return {
        ...changes,
        data: {
          ...state.editState.task,
          [taskDetailString]: edited
        }
      };
    }
  }

  return changes;
};

const taskChangeMap = {
  [TaskValues.Attachments]: getNewAttachments,
  [TaskValues.Dimensions]: (changes: Partial<TaskChanges>, state: ReducerState): Partial<TaskChanges> => getTaskDetailsChanges(changes, state, 'dimensions'),
  [TaskValues.DueDate]: (changes: Partial<TaskChanges>, state: ReducerState): Partial<TaskChanges> => getTaskDetailsChanges(changes, state, 'dueDate'),
  [TaskValues.Filetype]: (changes: Partial<TaskChanges>, state: ReducerState): Partial<TaskChanges> => getTaskDetailsChanges(changes, state, 'filetype'),
  [TaskValues.TaskDescription]: (changes: Partial<TaskChanges>, state: ReducerState): Partial<TaskChanges> => getTaskDetailsChanges(changes, state, 'taskDescription'),
  [TaskValues.TaskPriority]: (changes: Partial<TaskChanges>, state: ReducerState): Partial<TaskChanges> => getTaskDetailsChanges(changes, state, 'taskPriority'),
  [TaskValues.TaskStatus]: (changes: Partial<TaskChanges>, state: ReducerState): Partial<TaskChanges> => getTaskDetailsChanges(changes, state, 'taskStatus'),
  [TaskValues.TaskUsers]: (changes: Partial<TaskChanges>, state: ReducerState): Partial<TaskChanges> => getTaskDetailsChanges(changes, state, 'taskUsers')
};

export const getTaskChanges = (state: ReducerState): Partial<TaskChanges> | false => {
  let changes: Partial<TaskChanges> = {};
  changes = Object.keys(taskChangeMap).reduce((prevChanges, key) => taskChangeMap[key](prevChanges, state), changes);
  return Object.keys(changes).length > 0 && changes;
};
