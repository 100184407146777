import { t, Trans } from '@lingui/macro';
import React, { useEffect, useRef, useState, FunctionComponent } from 'react';

import { createBoard } from '@api/v4/boards';
import { BoardResponse, BoardResponseObject } from '@api/v4/boards/boardsTypes';
import { CloseButton, PrimaryButton, } from '@components/library/button/index';
import renderModal from '@components/show_page/modals/renderModal';

interface CreateBoardHandlerParams {
  boardTitle: string;
  closeModal: () => void;
  setBoards: React.Dispatch<React.SetStateAction<BoardResponseObject[]>>;
}
// reflect success or error message
// TODO add notify
const createBoardHandler = async (options: CreateBoardHandlerParams): Promise<void> => {
  const { boardTitle, closeModal, setBoards } = options;
  try {
    // reponse is created board
    const newBoard: BoardResponse = await createBoard({ boardTitle });
    setBoards((prevState) => prevState.concat([newBoard.data]));
    Notify.create({ title: t`Board successfully created.`, type: 'success' });
  } catch (error) {
    Notify.create({ title: t`Something went wrong, creating board failed.`, type: 'error' });
  } finally {
    closeModal();
  }
};

interface CreateBoardModalProps {
  closeModal: () => void;
  setBoards: React.Dispatch<React.SetStateAction<BoardResponseObject[]>>;
}

// setBoards is the setState handler of the parent/calling component
const CreateBoardModal: FunctionComponent<CreateBoardModalProps> = (options) => {
  const { closeModal, setBoards } = options;
  const [newBoardTitle, setNewBoardTitle] = useState('');
  const input = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (input && input.current) {
      input.current.focus();
    }
    return closeModal; // fire closeModal when unmounting
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal-content-wrapper create-board-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-boards icon" />
        <h3 className="modal-title">
          <Trans>Create New Board</Trans>
        </h3>
        <CloseButton onClick={closeModal} />
      </div>
      <div className="modal-content-wrapper__body">
        <div className="create-board-input-container">
          <label className="brandfolder-label">
            <Trans>Board Name</Trans>
            <input
              ref={input}
              className="brandfolder-input"
              name="board-name"
              onChange={(e): void => setNewBoardTitle(e.target.value)}
            />
          </label>
        </div>
        <div className="button-container">
          <PrimaryButton
            className="t-confirm-modal"
            disabled={newBoardTitle.length === 0}
            onClick={(): Promise<void> => createBoardHandler({ boardTitle: newBoardTitle.trim(), closeModal, setBoards })}
            size="medium"
          >
            <Trans>Create Board</Trans>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(CreateBoardModal, 'CreateBoardModal');
export default ModalComponent;
