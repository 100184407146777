import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from './styles/alert.module.scss';

type AlertTypes = 'attention' | 'comment' | 'warning';

type DivElementExtended = Omit<HTMLAttributes<HTMLDivElement>, 'role'>

interface AlertProps extends DivElementExtended {
  message: ReactNode;
  type: AlertTypes;
}

export const Alert: FunctionComponent<AlertProps> = (props) => {
  const { message, type, ...otherProps } = props;
  return (
    <div {...otherProps} className={classnames(classes.alert, classes[`alert-${type}`])} role="status">
      <span className={classnames(`bff-${type}`, classes.icon)} />
      <div className={classes.message}>
        {message}
      </div>
    </div>
  );
};
