import React from "react";
import Attachment from "./attachment";
import ProgressBar from "./progress_bar";

export default class AttachmentList extends React.Component {
  state = {
    loading: true,
    error: false,
    attachments: [],
    placingFile: false
  };

  constructor(props) {
    super(props);
    this.fetching = false;
    this.currentPage = 1;
    this.totalAttachmentCount = 0;
  }

  componentDidMount() {
    window.addEventListener("resize", this.fetchMoreAttachments, false);
    window.addEventListener(
      "com.brandfolder.attachment_browser.close_progress_bar",
      this.hideProgressBar,
      false
    );
    this.getAttachments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.currentPage = 1;
      this.totalAttachmentCount = 0;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        { loading: true, error: false, attachments: [] },
        () => {
          this.getAttachments();
        }
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.fetchMoreAttachments);
    window.removeEventListener(
      "com.brandfolder.attachment_browser.close_progress_bar",
      this.hideProgressBar
    );
  }

  getAttachments() {
    this.fetching = true;
    let url = `/api/v4/${this.props.resourceType}s/${this.props.selectedResource.id}/attachments?fields=thumbnail_url,download_url,image_type,updated_at&page=${this.currentPage}&per=100`;
    if (this.props.searchQuery) {
      const searchQuery = encodeURI(this.props.searchQuery);
      url += `&search=${searchQuery}`;
    }
    $.ajax({
      url,
      type: "GET",
      context: this,
      headers: { Authorization: `Bearer ${this.props.jwt}` },
      success: (response) => {
        this.currentPage += 1;
        this.totalAttachmentCount = response.meta.total_count;
        this.setState((prevState) => ({
          loading: false,
          attachments: prevState.attachments.concat(response.data)
        }));
      },
      error: () => {
        this.setState({ loading: false, error: true });
      },
      complete: () => {
        this.fetching = false;
      }
    });
  }

  showProgressBar = () => {
    this.setState({ placingFile: true });
  };

  hideProgressBar = () => {
    this.setState({ placingFile: false });
  };

  fetchMoreAttachments = () => {
    if (!this.fetching) {
      if (this.state.attachments.length < this.totalAttachmentCount) {
        // eslint-disable-next-line react/no-string-refs
        const attachmentList = this.refs.attachmentList;
        const scrollThreshold = attachmentList.scrollHeight - attachmentList.offsetHeight - 500;
        if (attachmentList.scrollTop >= scrollThreshold) {
          this.getAttachments();
        }
      }
    }
  };

  renderAttachments() {
    if (this.state.loading) {
      return (
        <div className="adobe-notification">
          <img
            alt="loading icon"
            className="bff-spinner-gif"
            src="https://cdn.bfldr.com/27C9EC93/at/qdipip-a094w0-9udpnk/bff-spinner-gif"
          />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <div className="adobe-notification">
          <span className="bff-warning" />
          <p>Unable to load files at this time</p>
        </div>
      );
    }

    if (this.state.attachments.length === 0) {
      let message;
      if (this.props.searchQuery) {
        message = "No results match that search";
      } else {
        message = "This Brandfolder is empty";
      }
      return (
        <div className="adobe-notification">
          <span className="bff-comment" />
          <p>{message}</p>
        </div>
      );
    }

    const attachments = this.state.attachments;
    const attachmentList = attachments.map((attachment) => (
      <li key={attachment.id}>
        <Attachment
          showProgressBar={this.showProgressBar}
          attachment={attachment.attributes}
          placing_file={this.state.placingFile}
        />
      </li>
    ));
    return <ul>{attachmentList}</ul>;
  }

  renderProgressBar() {
    if (this.state.placingFile) {
      return <ProgressBar message="Adding file" />;
    }

    return null;
  }

  render() {
    return (
      <div
        // eslint-disable-next-line react/no-string-refs
        ref="attachmentList"
        onScroll={this.fetchMoreAttachments}
        className="adobe-attachment-list"
      >
        {this.renderAttachments()}
        {this.renderProgressBar()}
      </div>
    );
  }
}
