import { t, Trans } from '@lingui/macro';
import { Form, Formik } from 'formik';
import { decode } from 'html-entities';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { object, SchemaOf, string } from 'yup';

import { useFetch } from '@api/ApiHelper';
import { createAssets } from '@api/v4/assets/assets';
import { SectionsApiData, SectionApiResponseObject } from '@api/v4/brandfolders/SectionTypes';
import { DesignHuddleProject } from '@api/v4/private/resources/design_huddles';
import { designHuddleProjectGetNew } from '@api/v4/private/resources/design_huddles/projects';
import { BFLoader } from '@components/common/loader/main';
import { getResourceUrl } from '@components/design_huddles/editors/helpers';
import { PrimaryButton, TertiaryButton, TextButton } from '@components/library/button';
import { DialogSizes, StandardDialog } from '@components/library/dialog';
import { ListOption, ListDropdown } from '@components/library/dropdown';
import { FontIcon, FontIcons } from '@components/library/icon';

import './styles/save-project.scss';

interface SaveProjectProps {
  project: DesignHuddleProject;
  projectId: string;
  sectionKey: string | null;
  setShowSaveProject: (open: boolean) => void;
  setShowSaveTemplate: (open: boolean) => void;
  showSaveProject: boolean;
  showSaveTemplate: boolean;
}

interface SaveProjectValues {
  sectionId: string;
}

const validationSchema = (): SchemaOf<SaveProjectValues> => object().shape({
  sectionId: string()
    .required(t`Section is required`)
});

export const SaveProject: FunctionComponent<SaveProjectProps> = (props) => {
  const resourceKey = BFG.resource.key;
  const resourceType = BFG.resource.type;

  const {
    project,
    projectId,
    sectionKey,
    setShowSaveProject,
    setShowSaveTemplate,
    showSaveProject,
    showSaveTemplate
  } = props;

  const fetchSections = useFetch<SectionsApiData>({
    fetchOnMount: false,
    params: {
      limit: 3000,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      default_asset_type: 'GenericFile'
      // TODO: fix the /sections api for collections (order and sort_by break the api)
      // we need programmatically in the sections useEffect below
      // order: 'asc',
      // sort_by: 'name'
    },
    url: `/api/v4/${resourceType}s/${resourceKey}/sections`
  });

  const [assetId, setAssetId] = useState('');
  const [options, setOptions] = useState<ListOption[]>([]);
  const [sections, setSections] = useState<SectionApiResponseObject[]>([]);
  const [submittingMessage, setSubmittingMessage] = useState('');

  useEffect(() => {
    if (showSaveProject) {
      fetchSections.fetch();
    }
  }, [showSaveProject]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchSections.response) {
      setSections(fetchSections.response.data);
    }
  }, [fetchSections.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sections.length > 0) {
      const sortedSections = sections.sort((a, b) => (a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase() ? 1 : -1));
      setOptions(sortedSections.map((section) => ({
        label: section.attributes.name,
        value: section.id
      })));
    }
  }, [sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRedirect = (): void => {
    window.onbeforeunload = undefined;
    setSubmittingMessage(t`Redirecting...`);
    window.location.assign(`/design_editor/project/${projectId}/${assetId}/?section_key=${sectionKey}`);
  };

  const getInitialSectionId = (): string => {
    const foundSectionKey = sections.find((section) => section.id === sectionKey);
    if (foundSectionKey) {
      return sectionKey;
    }
    return sections[0]?.id || '';
  };

  const onSubmit = async (values, formikHelpers): Promise<void> => {
    const { setSubmitting } = formikHelpers;

    try {
      const { sectionId } = values;

      setSubmittingMessage(t`Saving asset...`);
      const projectNewResponse = await designHuddleProjectGetNew({
        projectId,
        resourceKey,
        resourceType
      });

      const createAssetsResponse = await createAssets({
        url: `/api/v4/${resourceType}s/${resourceKey}/assets?fast_jsonapi=true`,
        headers: { 'X-Brandfolder-Change-Agent': 'Custom Templating Integration' },
        section_key: sectionId, // eslint-disable-line @typescript-eslint/naming-convention
        data: {
          attributes: [{
            name: projectNewResponse.data.relationships.assets.data.attributes.name,
            attachments: [
              {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                external_id: projectNewResponse.data.relationships.attachments.data.attributes.external_id,
                filename: projectNewResponse.data.relationships.attachments.data.attributes.filename,
                source: projectNewResponse.data.relationships.attachments.data.attributes.source,
                url: projectNewResponse.data.relationships.attachments.data.attributes.url,
              }
            ],
          }]
        }
      });

      setAssetId(createAssetsResponse.data[0].id);
    } catch (err) {
      Notify.create({
        title: t`There was an error saving your project. Please try again.`,
        type: 'error'
      });
    } finally {
      setSubmitting(false);
      setSubmittingMessage('');
    }
  };

  const { project_title: projectTitle } = project;
  const resourceName = decode(BFG.resource.name);

  return (
    <StandardDialog
      height={500}
      id="save-project-dialog"
      onClose={(): void => {
        if (assetId && !showSaveTemplate) {
          handleRedirect();
        }
      }}
      open={showSaveProject}
      setOpen={setShowSaveProject}
      showFooter={false}
      size={DialogSizes.Small}
      title={t`Save Project`}
    >
      {submittingMessage && (
        <div className="save-project__loader">
          <BFLoader />
          <p>{submittingMessage}</p>
        </div>
      )}
      {!assetId && !submittingMessage && (
        <div>
          <TextButton
            className="save-project__back"
            icon={FontIcons.CaretLeft}
            id="save-project-back"
            onClick={(): void => setShowSaveProject(false)}
          >
            <Trans>Back to editor</Trans>
          </TextButton>
          {fetchSections.loading && (
            <div className="save-project__loader">
              <BFLoader />
            </div>
          )}
          {!fetchSections.loading && fetchSections.error && (
            <p className="save-project__error">
              <Trans>Error loading sections. Please refresh the page and try again.</Trans>
            </p>
          )}
          {!fetchSections.error && !fetchSections.loading && sections.length > 0 && (
            <Formik
              initialValues={{ sectionId: getInitialSectionId() } as SaveProjectValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema()}
            >
                {(formikBag): ReactNode => {
                  const { errors, setFieldValue, touched, values } = formikBag;
                  const { sectionId } = values;

                  return (
                    <Form className="save-project__form">
                      <p className="save-project__p">
                        <Trans>Select where your project should be saved.</Trans>
                      </p>
                      <ListDropdown
                        className="save-project__section"
                        error={touched.sectionId && errors.sectionId}
                        id="project-section"
                        isLoading={fetchSections.loading}
                        label={<Trans>Section</Trans>}
                        onChange={(listOption): void => {
                          setFieldValue('sectionId', listOption.value as string, true);
                        }}
                        options={options}
                        value={sectionId}
                        virtualizeOptions={false}
                      />
                      <PrimaryButton
                        className="save-project__submit"
                        id="save-project-submit"
                        type="submit"
                      >
                        <Trans>Save Project</Trans>
                      </PrimaryButton>
                    </Form>
                  );
                }}
            </Formik>
          )}
        </div>
      )}
      {assetId && !submittingMessage && (
        <div className="save-project__success">
          <FontIcon
            aria-hidden
            className="save-project__icon"
            icon={FontIcons.CheckMarkCircle}
            iconSize={40}
          />
          <h3 className="save-project__h3">
            <Trans>
              {projectTitle} saved to {resourceName}!
            </Trans>
          </h3>
          <p className="save-project__p save-project__p--centered">
            <Trans>Would you like to generate a template from this project?</Trans>
          </p>
          <div className="save-project__button-group">
            <TertiaryButton
              className="save-project__no"
              id="generate-template-no"
              onClick={(): void => {
                window.location.assign(getResourceUrl());
              }}
            >
              <Trans>Not at this time</Trans>
            </TertiaryButton>
            <PrimaryButton
              className="save-project__yes"
              id="generate-template-yes"
              onClick={(): void => setShowSaveTemplate(true)}
            >
              <Trans>Generate Template</Trans>
            </PrimaryButton>
          </div>
        </div>
      )}
    </StandardDialog>
  );
};
