import {
  AlertLooks,
  MoreInfoTooltip,
  StandardAlert,
  StandardButton,
  StandardFieldset,
  StandardTextarea,
  StandardTextfield,
  Widths,
} from '@brandfolder/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { object, SchemaOf, string } from 'yup';

import { fetchJson, useFetch, UseFetchOptions } from '@api/ApiHelper';
import { ApiData, ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { UserGroupServer, UserGroupServerCreate } from '@api/v4/user_groups';
import { standardError } from '@translations';

import classes from './styles/user-groups.module.scss';

interface UserGroupsFormProps {
  organizationKey: string;
}

const defaultValues: UserGroupServerCreate = {
  description: '',
  name: ''
};

const errorMessages = (): { nameRequired: string } => ({
  nameRequired: t`Group name is required`
});

export const UserGroupsForm: FunctionComponent<UserGroupsFormProps> = (props) => {
  const schema: SchemaOf<UserGroupServerCreate> = object().shape({
    description: string().max(2000, t`Maximum characters allowed is 2000`),
    name: string()
      .required(errorMessages().nameRequired)
      .test('duplicate', t`A user group with that name already exists`, async (name): Promise<boolean> => {
        const matchingUserGroups = await fetchJson<ApiData<UserGroupServer, 'user_groups'>>({
          body: {
            query: name
          },
          method: 'POST',
          url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/organization/user_groups/search`
        });
        const matchingNames = matchingUserGroups.data.filter((ug) => (
          ug.attributes.name.toLowerCase().trim() === name.toLowerCase().trim()
        )) || [];

        return matchingNames.length === 0;
      })
  });
  const { organizationKey } = props;

  const { formState, handleSubmit, register } = useForm<UserGroupServerCreate, unknown, UserGroupServerCreate>({
    defaultValues,
    resolver: yupResolver(schema),
    // only revalidate on blur to decrease number of network requests
    // to test duplicate group names
    reValidateMode: 'onBlur'
  });

  const { errors, dirtyFields } = formState;
  const dirtyFieldsLength = Object.keys(dirtyFields).length;

  const fetchOptions: UseFetchOptions = {
    fetchOnMount: false,
    method: 'POST',
    url: `/api/v4/organizations/${organizationKey}/user_groups`
  };

  const {
    error,
    fetch,
    loading,
    response,
    reset: createReset
  } = useFetch<ApiDatumResponse<UserGroupServer, 'user_groups'>>(fetchOptions);

  useEffect(() => {
    if (response?.data) {
      window.location.assign(`${window.location.href}/${response.data.id}?created=true`);
    }
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dirtyFieldsLength > 0) {
      createReset();
    }
  }, [dirtyFieldsLength]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error && !loading && (
        <StandardAlert
          className={classes.alert}
          look={AlertLooks.Error}
        >
          {standardError()}
        </StandardAlert>
      )}

      <form
        id="user-groups-form"
        noValidate
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((values) => {
          fetch({
            ...fetchOptions,
            body: {
              data: {
                attributes: values
              }
            }
          });
        })}
      >
        <StandardFieldset
          className={classes.fieldset}
          disabled={loading}
          legend={<Trans>Create a group</Trans>}
          showLegend
        >
          <StandardTextfield
              {...register('name')}
              className={classes.textfield}
              disabled={loading}
              error={errors.name?.message}
              id="name"
              label={<Trans>Group name</Trans>}
              required
              requiredLabel={t`Required`}
            />
          <StandardTextarea
            {...register('description')}
            className={classes.textarea}
            disabled={loading}
            error={errors.description?.message}
            id="description"
            label={
              <div className={classes.descriptionLabel}>
                <Trans>
                  Description&nbsp;
                  <small className={classes.small}>
                    (optional)
                  </small>
                </Trans>
                <MoreInfoTooltip
                  iconLabel={t`More info`}
                  id="user-group-description-more-info"
                  tooltip={
                    <Trans>This summary of your group is only visible by admins on your group's detail page</Trans>
                  }
                  width={Widths.Small}
                />
              </div>
            }
            maxLength={2000}
          />
          <div className={classes.submitRow}>
            <StandardButton
              className={classes.button}
              disabled={loading}
              id="create-group"
              type="submit"
            >
              <Trans>Create group</Trans>
            </StandardButton>
          </div>
        </StandardFieldset>
      </form>
    </>
  );
};
