/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import { sanitize } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import React from 'react';

import { Asset, AssetTypes, Tag } from '@api/v4/assets/assetTypes';
import ClipboardCopy from '@components/common/clipboard_copy/main';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import AssetTags from './assetTags';
import { CustomFieldsAccordion } from './CustomFieldsAccordion';

/* NOTE : Anytime you do work in this component make sure there is parity within
the orgSearchResultsAssetDetails component. At some point these should become one
component. Right now they are separated because this component is getting the majority
of it's information from rails, whereas the orgSearchResultsAssetDetails is
getting it's information from an ajax call, which at this point is not sufficient
to receive the information and do all the actions we need */

export interface TextDetailsProps {
  asset: Asset;
  assetKey: string;
  formattedDescription: string;
  tags: Tag[];
  updatedAndCreated: string;
  approvedBy?: string; // only via app/views/assets/texts/show.html.slim
  assetType?: AssetTypes; // only via app/views/assets/texts/show.html.slim
  awaitingApproval?: string; // only via app/views/assets/texts/show.html.slim
  closeModal?: () => void; // only via app/javascript/components/asset/modal/tabs/asset_details/orgSearchResultsAssetDetails.jsx
  collectionExpiration?: string; // only via app/views/assets/texts/show.html.slim
  expirationDate?: string; // only via app/views/assets/texts/show.html.slim
  expired?: boolean; // only via app/views/assets/texts/show.html.slim
  orgSearchPage?: boolean; // only via app/javascript/components/asset/modal/tabs/asset_details/orgSearchResultsAssetDetails.jsx
  publishDate?: string; // only via app/views/assets/texts/show.html.slim
  unpublished?: boolean; // only via app/views/assets/texts/show.html.slim
}

class TextDetails extends React.Component<TextDetailsProps> {
  get brandfolder() {
    return BF.fx.brandfolder();
  }

  get collection() {
    return BF.fx.collection();
  }

  renderUpdatedCreated() {
    const { updatedAndCreated } = this.props;
    if (typeof updatedAndCreated === 'string') {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: sanitize(updatedAndCreated) }}
        />
      );
    }
    return updatedAndCreated;
  }

  render() {
    const {
      approvedBy,
      asset,
      assetKey,
      awaitingApproval,
      closeModal,
      collectionExpiration,
      expired,
      expirationDate,
      formattedDescription,
      orgSearchPage,
      publishDate,
      tags,
      unpublished,
      updatedAndCreated
    } = this.props;

    const descriptionMissing = !asset.description;

    return (
      <I18nProviderWrapper>
        {orgSearchPage || descriptionMissing ? (
          ' '
        ) : (
          <ClipboardCopy
            check
            showFeedbackAsTooltip={false}
            textToCopy={asset.description.replace(/(<([^>]+)>)/gi, '')}
          >
            <span className="bff-copy" />
            &nbsp; <Trans>Copy to clipboard</Trans>
          </ClipboardCopy>
        )}
        {orgSearchPage || descriptionMissing ? (
          ' '
        ) : (
          <ClipboardCopy
            check
            showFeedbackAsTooltip={false}
            textToCopy={asset.description}
          >
            <span className="bff-copy" />
            &nbsp; <Trans>Copy to clipboard (with formatting)</Trans>
          </ClipboardCopy>
        )}
        {formattedDescription ? (
          <div className="m-show-description s-asset-description">
            <p
              dangerouslySetInnerHTML={{
                __html: this.props.formattedDescription.replace(/\n/g, '<br>')
              }}
            />
          </div>
        ) : (
          ''
        )}

        {updatedAndCreated ? this.renderUpdatedCreated() : ''}

        {approvedBy ? (
          <div dangerouslySetInnerHTML={{ __html: approvedBy }} />
        ) : (
          ''
        )}
        {awaitingApproval ? (
          <div dangerouslySetInnerHTML={{ __html: awaitingApproval }} />
        ) : (
          ''
        )}
        {collectionExpiration ? (
          <div dangerouslySetInnerHTML={{ __html: collectionExpiration }} />
        ) : (
          ''
        )}

        {publishDate ? (
          <div className="s-modal-published-on">
            {unpublished ? <Trans>Will Publish on {publishDate}</Trans> : <Trans>Published on {publishDate}</Trans>}
          </div>
        ) : (
          ''
        )}

        {expirationDate ? (
          <div className="s-modal-expires-on">
            {expired ? <Trans>Expired on {expirationDate}</Trans> : <Trans>Expires on {expirationDate}</Trans>}
          </div>
        ) : (
          ''
        )}

        {tags.length > 0 ? (
          <AssetTags orgSearchPage={orgSearchPage} tags={tags} />
        ) : (
          ''
        )}

        <CustomFieldsAccordion assetKey={assetKey} closeModal={closeModal} count={6} />

      </I18nProviderWrapper>
    );
  }
}

export default TextDetails;
