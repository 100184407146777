import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { TextWarningButton } from '@components/library/button';

interface RemoveValuesButtonProps {
  isVisible: boolean;
  onClick: () => void;
  isSingular?: boolean;
}

export const RemoveValuesButton: FunctionComponent<RemoveValuesButtonProps> = ({
  isSingular = false,
  isVisible,
  onClick
}) => (
  <>
    {isVisible && (
      <TextWarningButton
        className="custom-field-values--remove"
        icon="bff-remove"
        onClick={onClick}
      >
        {isSingular ? <Trans>Remove value</Trans> : <Trans>Remove values</Trans>}
      </TextWarningButton>
    )}
  </>
);
