import { getCurrentUserIsAnonymous } from '@helpers/user';

interface GenerateBrandguideUrlOptions {
  slug: string;
  cname?: string | null;
  organizationKey?: string | null;
  v2?: boolean | null;
  public?: boolean;
}

/**
 * Generate the correct Brandguide URL for either v1 or v2 Brandguides.
 * It requires the `BFG` window global being available.
 *
 * @param options GenerateBrandguideUrlOptions
 * @returns string
 */
export const generateBrandguideUrl = (options: GenerateBrandguideUrlOptions): string => {
  const { cname, organizationKey, slug, v2 } = options;

  if (v2) {
    const cnameParam = cname ? `&cname=${cname}` : '';
    const organizationKeyParam = organizationKey ? `&org_key=${organizationKey}` : '';
    return `${BFG.brandguideUrl}?redirect=${slug}${cnameParam}${organizationKeyParam}`;
  } else if (cname) {
    return `https://${cname}/${slug}`;
  }  else {
    return `${BFG.brandguideV1Url}/${slug}`;
  }
};

export const generatePublicV2BrandguideUrl = (options: GenerateBrandguideUrlOptions): string => {
  const { cname, slug } = options;

  if (cname) {
    return `https://${cname}`;
  }

  return `${BFG.brandguideUrl}/${slug}`;
};

/**
 * Generate a permission-sensitive Brandguide URL
 * If the Brandguide is public and the user is not authorized as a guest
 * (the Brandfolder/Collection is public and the user is anonymous OR the user has access via a guest password)
 * we use the public URL. Otherwise, we include the redirect and org_key params to prompt login to allow editing.
 */
export const generatePermissionSensitiveBrandguideUrl = (options: GenerateBrandguideUrlOptions): string => {
  const { public: publicBrandguide, v2 } = options;
  const userAnonymous = getCurrentUserIsAnonymous(BFG.currentUser);

  if (publicBrandguide && userAnonymous && v2) {
    return generatePublicV2BrandguideUrl(options);
  }

  return generateBrandguideUrl(options);
};
