/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { CloseButton, TextButton } from '@components/library/button/index';
import { ListDropdown } from '@components/library/dropdown/index';
import { StandardSwitch, SwitchSize } from '@components/library/switch';
import { getSortOption, sortOptions } from '@components/show_page/sections/view_options/helper';
import { UserViewOptionsKeys } from '@components/show_page/sections/view_options/ViewOptionTypes';
import { getResourceIsCollection } from '@helpers/resource';
import { getCurrentUserIsAnonymousOrGuest } from '@helpers/user';

import ViewOptionsLanguages from './view_options_languages';

const ViewOptionsDropdown = ({
  defaultSortOrder,
  isVisible,
  paginatePer,
  searchSubmitted,
  setRenderViewOptions,
  updateUserViewOptions,
  userViewOptions,
}) => {
  const viewOptionsRef = useRef(null);
  const {
    showAsGrid,
    assetsPerPage,
    selectedUGTLocale,
    showCustomFields,
    showTaskDetails,
    showEmptySections,
    sortOption
  } = userViewOptions;
  const { role } = BFG.currentUser;
  const collabable = role === 'owner' || role === 'admin' || role === 'collaborator';
  const showCustomFieldsSection = getResourceIsCollection() && getCurrentUserIsAnonymousOrGuest(BFG.currentUser) ? BFG.showCustomFieldsOverviewTab : true;

  const assetsPerPageOptions = () => {
    const assetsPerPageValues = [10, 32, 75, 150, 300, 1000];
    const combinedValues = [paginatePer, ...assetsPerPageValues].sort((a, b) => (a - b));
    const assetsPerPageUpdate = [...new Set(combinedValues)]; // set ensures unique values

    return assetsPerPageUpdate.map((qty) => ({ value: qty, label: qty }));
  };

  // method copied from show-page.jsx
  const urlParams = () => {
    const params = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => params[key] = value); // eslint-disable-line no-return-assign
    return params;
  };

  // using 'per=' as a url param take priority over user option
  const disableAssetsPerPage = Boolean(urlParams().per);

  return (
    <div
      ref={viewOptionsRef}
      className={`view-options ${isVisible ? 'visible' : ''}`}
    >
      <div className="view-options__item header">
        <p className="title-copy">
          <Trans>View Options</Trans>
        </p>
        <CloseButton
          className="view-options__item--close"
          onClick={() => setRenderViewOptions(false)}
        />
      </div>
      <div className="view-options__item layout-item">
        <div className="view-options__item--row">
          <p className="view-options__item--row--copy"><Trans>Layout</Trans></p>
          <div className="layout-options">
            <button
              className={`toggle-grid-view layout-button ${showAsGrid ? 'active' : ''}`}
              onClick={showAsGrid ? undefined : () => updateUserViewOptions('showAsGrid', true)}
              type="button"
            >
              <span className="bff-grid-view icon" />
            </button>
            <button
              className={`toggle-list-view layout-button ${showAsGrid ? '' : 'active'}`}
              onClick={!showAsGrid ? undefined : () => updateUserViewOptions('showAsGrid', false)}
              type="button"
            >
              <span className="bff-list-view icon" />
            </button>
          </div>
        </div>
      </div>
      <div className="view-options__item">
        <div className={`view-options__item--row assets-per-page-row ${disableAssetsPerPage ? 'disabled' : ''}`}>
          <p className="view-options__item--row--copy"><Trans>Assets per section</Trans></p>
          <ListDropdown
            className="view-options__count-dropdown"
            isNestedDropdown
            onChange={(option) => updateUserViewOptions('assetsPerPage', option.value)}
            options={assetsPerPageOptions()}
            overflowParentRef={viewOptionsRef}
            value={assetsPerPage}
          />
        </div>
      </div>
      <div className="view-options__item sort-by-row">
        <div className="view-options__item--row">
          <p className="view-options__item--row--copy"><Trans>Sort by</Trans></p>
          <ListDropdown
            className="view-options__sort-dropdown--tablet"
            isNestedDropdown
            onChange={(option) => updateUserViewOptions(UserViewOptionsKeys.SortOption, option.value)}
            options={sortOptions(searchSubmitted)}
            overflowParentRef={viewOptionsRef}
            value={getSortOption(searchSubmitted, sortOption, defaultSortOrder)}
          />
        </div>
      </div>
      <div className="view-options__item">
        {showCustomFieldsSection && !BFG.resource.is_workspace && (
          <div className="view-options__item--row">
            <StandardSwitch
              className="show-custom-fields-switch"
              isChecked={showCustomFields}
              onChange={() => { updateUserViewOptions(UserViewOptionsKeys.ShowCustomFields); }}
              size={SwitchSize.Large}
            >
              <Trans>Show custom fields</Trans>
            </StandardSwitch>
          </div>
        )}
        {BFG.hasFeature('workspace') && BFG.resource.is_workspace && showAsGrid && (
          <div className="view-options__item--row">
            <StandardSwitch
              className="show-task-details-switch"
              isChecked={showTaskDetails}
              onChange={() => { updateUserViewOptions(UserViewOptionsKeys.ShowTaskDetails); }}
              size={SwitchSize.Large}
            >
              <Trans>Show task details</Trans>
            </StandardSwitch>
          </div>
        )}
        {collabable && (
          <div className="view-options__item--row show-empty-sections-row">
            <StandardSwitch
              className="show-empty-sections-switch"
              isChecked={showEmptySections}
              onChange={() => { updateUserViewOptions(UserViewOptionsKeys.ShowEmptySections); }}
              size={SwitchSize.Large}
            >
              <Trans>Show empty sections</Trans>
            </StandardSwitch>
          </div>
        )}
        {BFG.multiLanguageAssetDetailsEnabled && (
          <div className="view-options__item--row language-menu">
            <p className="view-options__item--row--copy"><Trans>Asset info</Trans></p>
            <ViewOptionsLanguages
              activeLocale={selectedUGTLocale}
              updateUserViewOptions={updateUserViewOptions}
            />
          </div>
        )}
      </div>
      <div className="reset-row">
        <TextButton
          className="reset-button"
          onClick={() => updateUserViewOptions('reset', {})}
        >
          <Trans>Reset</Trans>
        </TextButton>
      </div>
    </div>
  );
};

ViewOptionsDropdown.propTypes = {
  defaultSortOrder: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  paginatePer: PropTypes.number.isRequired,
  searchSubmitted: PropTypes.bool,
  setRenderViewOptions: PropTypes.func.isRequired,
  updateUserViewOptions: PropTypes.func.isRequired,
  userViewOptions: PropTypes.shape({
    assetsPerPage: PropTypes.number,
    selectedUGTLocale: PropTypes.string,
    showAsGrid: PropTypes.bool,
    showCustomFields: PropTypes.bool,
    showTaskDetails: PropTypes.bool,
    showEmptySections: PropTypes.bool,
    sortOption: PropTypes.string
  }),
};

ViewOptionsDropdown.defaultProps = {
  searchSubmitted: undefined,
  userViewOptions: {},
};

export default ViewOptionsDropdown;
