import React, { FunctionComponent, KeyboardEvent, MouseEvent } from 'react';
import classnames from 'classnames';

import { BaseSwitchProps, LabelPosition, SwitchChangeEvent, SwitchSize } from '@components/library/switch';

const baseName = 'switch';
const keydownKeys = ['Enter', 'Spacebar', ' '];

export const BaseSwitch: FunctionComponent<BaseSwitchProps> = ({
  children,
  className,
  isChecked,
  isDisabled,
  labelPosition = LabelPosition.Left,
  onChange,
  name,
  size = SwitchSize.Medium,
  styleClasses,
  variantName,
  ...switchProps
}) => {
  const handleChange = (e: SwitchChangeEvent): void => {
    if (isDisabled) return;
    if (onChange && keydownKeys.includes((e as KeyboardEvent<HTMLDivElement>).key)) onChange(e);
    if (onChange && (e as MouseEvent<HTMLDivElement>).type === 'click') onChange(e);
  };

  return (
    <div
      {...switchProps}
      aria-checked={isChecked}
      aria-disabled={isDisabled}
      aria-label={typeof children === 'string' ? children : name || ''}
      className={classnames(
        `bf-${baseName}__${variantName}`, // base-variant class for post consumption styling
        styleClasses[variantName], // class for css modules
        className, // user specified class
        styleClasses[labelPosition],
        styleClasses[size],
        {
          [styleClasses['no-label']]: !children,
          [styleClasses.checked]: isChecked,
          [styleClasses.disabled]: isDisabled,
        },
      )}
      onClick={handleChange}
      onKeyDown={handleChange}
      role="switch"
      tabIndex={0}
    >
      <div className={styleClasses['switch-container']}>
        <span className={styleClasses['switch-container--dot']} />
      </div>
      <div className={styleClasses['switch-label']}>{children}</div>
    </div>
  );
};
