import { t } from '@lingui/macro';
import { StandardTextfield, StandardQuantity, FontSizes } from '@brandfolder/react';
import React, { ChangeEventHandler, FunctionComponent } from 'react';

import { LimitTypes } from './customLimitsTypes';
import { assignLimitsUnits } from './helpers/assignLimitsUnits';

export const USAGE_ERROR_TEXT = (): string => t`Must match or exceed current usage`;

interface LimitsTableRowInputProps {
  currentLimit: number;
  currentUsage: number;
  handleChange: (value: number, resourceId: string) => void;
  limitType: LimitTypes;
  resourceId: string;
}

export const LimitsTableRowInput: FunctionComponent<LimitsTableRowInputProps> =
  ({ resourceId, currentLimit, currentUsage, handleChange, limitType }) => {
    const onCurrentLimitChange: ChangeEventHandler<HTMLInputElement> = (
      e
    ): void => {
      const { value } = e.target;
      handleChange(parseInt(value) || 0, resourceId);
    };

    const minLimit: number = currentLimit < currentUsage ? 0 : currentUsage;

    return limitType === 'admin' || limitType === 'collaborator' ? (
      <StandardQuantity
        data-testid="limits-standard-quantity"
        defaultValue={currentLimit}
        error={currentLimit < currentUsage && USAGE_ERROR_TEXT()}
        errorProps={
          currentLimit < currentUsage && {
            size: FontSizes.XxSmall,
            children: USAGE_ERROR_TEXT(),
            id: resourceId,
          }
        }
        id={`${resourceId}--currentLimit`}
        labels={{
          decrementLabel: t`Decrement limit`,
          incrementLabel: t`Increment limit`,
          label: t`Admin Limit`,
        }}
        min={minLimit}
        onQuantityChange={(limit): void => handleChange(limit, resourceId)}
        showLabel={false}
      />
    ) : (
      <div className={`limits-textfield-wrapper ${limitType}`}>
        <StandardTextfield
          className={`${limitType}-input`}
          data-testid="limits-standard-text-field"
          defaultValue={currentLimit}
          error={currentLimit < currentUsage && USAGE_ERROR_TEXT()}
          errorProps={
            currentLimit < currentUsage && {
              size: FontSizes.XxSmall,
              children: USAGE_ERROR_TEXT(),
              id: resourceId,
            }
          }
          id={`${resourceId}--currentLimit`}
          label={t`Limit Type Input`}
          min={minLimit}
          onChange={onCurrentLimitChange}
          showLabel={false}
          type="number"
          value={currentLimit || ''}
        />
        <span className={'limits-textfield-input__units'}>
          {assignLimitsUnits(limitType, '')}
        </span>
      </div>
    );
  };
