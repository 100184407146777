export enum BrandfolderHexColors {
  AquaKnockoff = '#00b0f7',
  Black = '#000000',
  BlueberryDelight = '#1d3163',
  DirtyMarshmallow = '#F1F5FA',
  GrayDark = '#6F6F6F',
  GrayMedium = '#CBCBCB',
  Marshmallow = '#F9FBFC',
  OrangeLight = '#FFE4B3',
  PurplePopsicle = '#4462c9',
  WarningRed = '#F64E63',
  Text = '#4A4A4A',
  Title = '#333333',
  White = '#FFFFFF',
}

export enum Colors {
  None = '', // intentionally first
  Danger = 'danger',
  Primary = 'primary',
}

export enum SmarAiColors {
  Active = '#4B309E',
  Disabled = '#A892EB',
  FocusOutline = '#1895F5',
  Hover = '#5E3BC4',
  Primary = '#7047EB',
}
