import { ButtonLooks, FontIcons, StandardButton } from '@brandfolder/react';

import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { SmarAiColors } from '@components/library/utils/types/colors';

const GenerateButton = styled(StandardButton)`
  background-color: transparent !important;
  min-height: unset;
  padding: 0;
  margin-bottom: 4px;

  span {
    color: ${SmarAiColors.Primary};
    font-family: 'Circular-Medium', 'Arial', 'Trebuchet MS', sans-serif;
    font-weight: 500;
  }

  &.disabled {
    span {
      color: ${SmarAiColors.Disabled};
    }
  }

  &:active {
    span {
      color: ${SmarAiColors.Active};
    }
  }

  &:hover {
    &:not(.disabled) {
      span {
        color: ${SmarAiColors.Hover};
      }
    }
  }

  &:focus-visible {
    outline: 2px solid ${SmarAiColors.FocusOutline};
  }

  .bf-icon {
    margin-right: 8px;
  }
`;

interface GenerateCaptionButtonProps {
  setShowGenerateCaption: (showGenerateCaption: boolean) => void;
  showGenerateCaption: boolean;
}

export const GenerateCaptionButton: FunctionComponent<GenerateCaptionButtonProps> = ({
  setShowGenerateCaption,
  showGenerateCaption,
}) => (
  <GenerateButton
    className={`${showGenerateCaption && 'disabled'}`}
    disabled={showGenerateCaption}
    look={ButtonLooks.TextSecondary}
    onClick={(): void => setShowGenerateCaption(true)}
    startIcon={FontIcons.SparkleFilled}
    type="button"
  >
    <Trans>Suggest Description</Trans>
  </GenerateButton>
);
