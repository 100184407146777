import React, { FunctionComponent, HTMLAttributes } from 'react';

import { BFLoader } from '@components/common/loader/main';

import classes from './styles/card.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CardLoaderProps extends HTMLAttributes<HTMLDivElement> {}

export const CardLoader: FunctionComponent<CardLoaderProps> = () => (
  <div className={classes.cardLoader}><BFLoader /></div>
);
