import {
  ButtonLooks,
  CopyButton,
  IconButton,
  FontIcon,
  FontIcons,
  MoreInfoTooltip,
  OnlyForScreenReader,
  StandardLink,
  StandardTooltip
} from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { moreInfoLabel } from '@components/standard-messages';

import {
  cropParams,
  padParams,
  resizeParams,
  CroppedData,
  Image,
  SelectedPreset
} from './options-helper';
import './styles/cropped-cdn-link.scss';

interface SizeError {
  cropHeightError: boolean;
  cropWidthError: boolean;
  imageSizeError: boolean;
}

interface CroppedCDNLinkProps {
  cdnUrl: string;
  croppedData: CroppedData;
  image: Image;
  imageToThumbnailRatio: number;
  fixedDimensions: boolean;
  selectedFiletype: string;
  selectedPreset: SelectedPreset | null;
  sizeError: SizeError | null;
}

export const CroppedCDNLink: FunctionComponent<CroppedCDNLinkProps> = ({
  cdnUrl,
  croppedData,
  image,
  imageToThumbnailRatio,
  fixedDimensions,
  selectedFiletype,
  selectedPreset,
  sizeError
}) => {
  if (!croppedData.width || !croppedData.height || !image.width || !image.height) return null;
  // strip any existing params from url
  const url = new URL(cdnUrl);
  const croppedCdnUrlBase = url.origin + url.pathname;

  const filetypeParam = { format: selectedFiletype.toLowerCase() };

  // Build CDN crop params, based on Fastly docs: https://developer.fastly.com/reference/io/crop/
  const searchParams = new URLSearchParams([...Object.entries({
    ...filetypeParam,
    ...cropParams({ croppedData, image, imageToThumbnailRatio }),
    ...padParams({ croppedData, fixedDimensions, image, imageToThumbnailRatio, selectedPreset }),
    ...resizeParams({ croppedData, fixedDimensions, image, imageToThumbnailRatio, selectedPreset })
  })]).toString();

  const croppedCdnUrl = new URL(`${croppedCdnUrlBase}?${searchParams}`).href;
  const disableCroppedCDNLink = !!sizeError;
  const sizeErrorCopy = t`CDN image cannot be sized larger than the original image. Zooming out may resolve this issue.`;

  return (
    <div className="cdn-url-container options__content--container">
      <StandardTooltip
        className="cropped-cdn-url-tooltip"
        id="cropped-cdn-url-tooltip"
        openOnFocus={!disableCroppedCDNLink}
        openOnHover={!disableCroppedCDNLink}
        tooltip={croppedCdnUrl}
        transitionDelay={500}
      >
        <StandardLink
          className="cropped-cdn-url-link"
          disabled={disableCroppedCDNLink}
          href={croppedCdnUrl}
          id="cropped-cdn-url-link"
          target="_blank"
          >
          <span className="span-for-cropped-cdn-url-link">
            {t`Cropped CDN Link`}
          </span>
          <OnlyForScreenReader>{t`Opens in a new tab`}</OnlyForScreenReader>
          <FontIcon aria-hidden icon={FontIcons.NewTab} />
        </StandardLink>
      </StandardTooltip>
      <CopyButton
        data={croppedCdnUrl}
        delay={2000}
        id="cropped-cdn-url-copy-button"
        tooltip={t`Copied!`}
      >
        <IconButton
          disabled={disableCroppedCDNLink}
          icon={FontIcons.Copy}
          label={t`Copy CDN Url`}
          look={ButtonLooks.Tertiary}
        />
      </CopyButton>
      {!!sizeError && (
        <MoreInfoTooltip
          className="size-error-more-info-tooltip"
          iconLabel={moreInfoLabel()}
          iconSize={16}
          id="size-error-more-info-tooltip"
          tooltip={sizeErrorCopy}
        />
      )}
    </div>
  );
};
