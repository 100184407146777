import { whitelabelPresenter } from '@brandfolder/utilities';
import { t, Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";
import { CSSTransition } from "react-transition-group";

import getAssetAttachment from "@api/v4/assets/attachments";
import { get as getCustomPresets } from '@api/v4/resources/conversion_presets';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { TextButton } from "@components/library/button";
import { ListDropdown } from "@components/library/dropdown/index";
import { capitalize } from '@components/library/utils';
import { isGettyClient } from '@helpers/getty-strings';

import AttachmentSidebar from "../shared/attachment_sidebar/main";
import VisibleAttachment from "../shared/visible_attachment/main";

import CDNLink from "./CDNLink";

import "./styles/main.scss";

class AssetEmbed extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
      customPresets: [],
      loading: true,
      selectedWidth: "",
      selectedHeight: "",
      selectedFormat: {
        value: "",
        label: "",
      },
      showAdditionalLinks: false,
      showCustomPresetCDNLinks: false,
      visibleAttachment: null,
    };

    this.activeRequests = [];
  }

  /* NOTE :: When you have a parent modal component that grabs the asset info
  you won't need to grab the attachments on each tab, you can grab them when
  you load the modal and then just pass them down. */
  componentDidMount() {
    let params = {
      assetKey: this.props.assetKey,
      fields:
        "best_metadata,other_metadata,download_url,thumbnail_url,view_thumbnail_retina,extension,thumbnailed,conversion_options,cdn_url,mux_hls_url",
    };

    params = BFG.manifestDigest ? { ...params, digest: BFG.manifestDigest } : params;
    params = this.props.watermarkResourceKey
      ? { ...params, watermark_resource_key: this.props.watermarkResourceKey }
      : params;

    getAssetAttachment(
      {
        assetKey: this.props.assetKey,
        params,
      },
      this.updateFetchControllers
    )
      .then((response) => {
        const sortedAttachments = response.data?.sort((a, b) => (a.attributes.position > b.attributes.position ? 1 : -1));
        const firstAttachment = sortedAttachments?.[0];

        // passing in a different extension if we get it from cdn_url
        const cdnUrl = firstAttachment?.attributes?.cdn_url ? new URL(firstAttachment?.attributes?.cdn_url) : null;
        const cdnFormat = cdnUrl && cdnUrl.searchParams.get('format');
        this.setState({
          attachments: sortedAttachments,
          loading: false,
          visibleAttachment: firstAttachment,
          selectedWidth: firstAttachment?.attributes?.width || 0,
          selectedHeight: firstAttachment?.attributes?.height || 0,
          selectedFormat: this.formatSelectedFormat(firstAttachment, cdnFormat),
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });

    // Custom presets are created in bulk management "Cropping"
    // They should only be accessible when the cropping feature is enabled
    if (BFG.hasFeature('cropping')) {
      getCustomPresets({
        resourceType: BFG.resource.type,
        resourceKey: BFG.resource.key,
      })
        .then((response) => {
          if (response.length) {
            this.setState({customPresets: response});
          }
        });
    }
  }

  componentWillUnmount() {
    this.abortFetchControllers();
  }

  get iframe() {
    if (!this.state.visibleAttachment) {
      return null;
    }

    return `<iframe src='https://brandfolder.com/${this.props.brandfolderSlug}/attachments/embed/${this.state.visibleAttachment.id}?resource_type=Brandfolder&frame=auto&animated=false&loop=false&muted=false&autoplay=false' frameborder='0' allowfullscreen></iframe>`;
  }

  get formatOptions() {
    if (!this.state.visibleAttachment) {
      return null;
    }

    const formatOptions = [
      { value: "jpg", label: "JPG" },
      { value: "png", label: "PNG" },
      { value: "pdf", label: "PDF" },
      { value: "gif", label: "GIF" },
    ];
    const visibleExtension = this.state.visibleAttachment.attributes.extension;

    if (BFG.smartCDNSource === "fastly") {
      formatOptions.push({
        value: 'auto',
        label: 'AUTO',
      });
    }

    if (!formatOptions.find((option) => option.value === visibleExtension)) {
      formatOptions.push({
        value: visibleExtension,
        label: visibleExtension.toUpperCase(),
      });
    }
    return formatOptions;
  }

  updateFetchControllers = (fetchController) => {
    this.activeRequests = [...this.activeRequests, fetchController];
  };

  abortFetchControllers = () => {
    this.activeRequests.forEach((fetchController) => {
      if (!fetchController.signal.aborted) {
        fetchController.abort();
      }
    });
  };

  formatSelectedFormat = (attachment, passedFormat = null) => {
    const fileFormat = passedFormat || attachment?.attributes?.extension || "";
    return { value: fileFormat.toLowerCase(), label: fileFormat.toUpperCase() };
  };

  stripExtensionFromName = (attachmentExtension, attachmentName) => {
    const extensionFromName = attachmentName.split(".").pop();
    // strip file extension from filename
    if (
      attachmentExtension
      && attachmentExtension.toLowerCase() === extensionFromName.toLowerCase()
    ) {
      const attachmentNameUpdated = attachmentName.split(".");
      attachmentNameUpdated.pop();
      return attachmentNameUpdated.join(".");
    }
    return attachmentName;
  };

  getV1AssetLink = (url) => {
    // url is good, just need to update extension
    // v1 = "http://fastly_v1.lvh.me:3000/6IGOKVHG/as/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2.png"
    const {
      selectedFormat: { value },
      selectedHeight,
      selectedWidth,
      visibleAttachment: {
        attributes: { height, position, width },
      },
    } = this.state;
    const pos = url.includes(".") ? url.lastIndexOf(".") : url.length;
    const fileRoot = url.substr(0, pos);
    // overwriting the png default extension with the state value
    // EMMI: This would be the place to fix when you have multiple format pickers
    const output = `${fileRoot}.${value}`;

    const assetUrl = new URL(output);
    const urlSearchParams = assetUrl.searchParams;

    // not mucking with format here, just changing extension

    if (width && selectedWidth !== width && selectedWidth !== "") {
      urlSearchParams.set('width', selectedWidth);
    }

    if (height && selectedHeight !== height && selectedHeight !== "") {
      urlSearchParams.set('height', selectedHeight);
    }

    if (position > 0) {
      urlSearchParams.set('position', position + 1);
    }

    const assetLink = assetUrl.toString();
    return assetLink;
  };

  getV1AttachmentLink = (url) => {
    // url is good, just need to update extension
    // v1 = "http://fastly_v1.lvh.me:3000/6IGOKVHG/at/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2.jpg"
    const {
      selectedFormat: { value },
      selectedHeight,
      selectedWidth,
      visibleAttachment: {
        attributes: { height, width },
      },
    } = this.state;
    const pos = url.includes(".") ? url.lastIndexOf(".") : url.length;
    const fileRoot = url.substr(0, pos);
    const output = `${fileRoot}.${value}`;

    const attachmentUrl = new URL(output);
    const urlSearchParams = attachmentUrl.searchParams;
    // not mucking with format here, just changing extension

    if (width && selectedWidth !== width && selectedWidth !== "") {
      urlSearchParams.set('width', selectedWidth);
    }

    if (height && selectedHeight !== height && selectedHeight !== "") {
      urlSearchParams.set('height', selectedHeight);
    }

    const attachmentLink = attachmentUrl.toString();
    return attachmentLink;
  };

  getV2AssetLink = (url) => {
    // v2 = "http://fastly_v2.lvh.me:3000/6IGOKVHG/as/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2"
    const {
      selectedFormat: { value },
      selectedHeight,
      selectedWidth,
      visibleAttachment: {
        attributes: { height, position, width, extension },
      },
    } = this.state;

    const assetUrl = new URL(url);
    const urlSearchParams = assetUrl.searchParams;

    if (value !== extension) {
      urlSearchParams.set('format', value);
    }

    if (width && selectedWidth !== width && selectedWidth !== "") {
      urlSearchParams.set('width', selectedWidth);
    }

    if (height && selectedHeight !== height && selectedHeight !== "") {
      urlSearchParams.set('height', selectedHeight);
    }

    if (position > 0) {
      urlSearchParams.set('position', position + 1)
    }

    const assetLink = assetUrl.toString();
    return assetLink;
  };

  getV2AttachmentLink = (url) => {
    // v2 = "http://fastly_v1.lvh.me:3000/6IGOKVHG/at/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2.jpg"
    const {
      selectedFormat: { value },
      selectedHeight,
      selectedWidth,
      visibleAttachment: {
        attributes: { height, extension, width },
      },
    } = this.state;

    const attachmentUrl = new URL(url);
    const urlSearchParams = attachmentUrl.searchParams;

    if (value !== extension) {
      urlSearchParams.set('format', value);
    }

    if (width && selectedWidth !== width && selectedWidth !== "") {
      urlSearchParams.set('width', selectedWidth);
    }

    if (height && selectedHeight !== height && selectedHeight !== "") {
      urlSearchParams.set('height', selectedHeight);
    }

    const attachmentLink = attachmentUrl.toString();
    return attachmentLink;
  };

  getV21AssetLink = (url) => {
    // v2_1 = "http://fastly_v2.lvh.me:3000/6IGOKVHG/as/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2?format=png&webp=auto"
    const {
      selectedFormat: { value },
      selectedHeight,
      selectedWidth,
      visibleAttachment: {
        attributes: { height, position, width },
      },
    } = this.state;

    const assetUrl = new URL(url);
    const urlSearchParams = assetUrl.searchParams;
    // This is trying to append format=avi to the cdn urls, which cause fastly to fail.
    if (urlSearchParams.get('format')) {
      urlSearchParams.set('format', value)
    }

    if (width && selectedWidth !== width && selectedWidth !== "") {
      urlSearchParams.set('width', selectedWidth);
    }

    if (height && selectedHeight !== height && selectedHeight !== "") {
      urlSearchParams.set('height', selectedHeight);
    }

    if (position > 0) {
      urlSearchParams.set('position', position + 1)
    }

    const assetLink = assetUrl.toString();
    return assetLink;
  };

  getV21AttachmentLink = (url) => {
    // url is good, just need to update extension
    // v21 = "http://fastly_v2.lvh.me:3000/6IGOKVHG/at/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2.jpg?format=png&webp=auto"
    const {
      selectedFormat: { value },
      selectedHeight,
      selectedWidth,
      visibleAttachment: {
        attributes: { height, width },
      },
    } = this.state;

    const attachmentUrl = new URL(url);
    const urlSearchParams = attachmentUrl.searchParams;

    if (urlSearchParams.get('format') === undefined && value === 'pdf') {
      urlSearchParams.set('format', undefined);
    } else {
      urlSearchParams.set('format', value);
    }

    if (width && selectedWidth !== width && selectedWidth !== "") {
      urlSearchParams.set('width', selectedWidth);
    }

    if (height && selectedHeight !== height && selectedHeight !== "") {
      urlSearchParams.set('height', selectedHeight);
    }

    const attachmentLink = attachmentUrl.toString();
    return attachmentLink;
  };

  getV21CustomPresetsLink = (url) => {
    const { customPresets } = this.state;
    if (customPresets.length === 0) return [];

    const customPresetLinks = [];

    customPresets.map(({ aspect_height, aspect_ratio, aspect_width, height, id, format, name, width }) => {
      const customPresetUrl = new URL(url);
      const urlSearchParams = customPresetUrl.searchParams;

      if (format) {
        urlSearchParams.set('format', format)
      }

      // custom presets require either a height and width OR an aspect_height and aspect_width
      const crop = (aspect_width && aspect_height)
        ? `${aspect_width}:${aspect_height},smart`
        : `${width}:${height},smart`;
      urlSearchParams.set('crop', crop)

      // admins may also specify a width or a height in the admin portal, which will appear as a param on the url here
      if (width) urlSearchParams.set('width', width)
      if (height) urlSearchParams.set('height', height)

      customPresetLinks.push({
        id,
        link: customPresetUrl.toString(),
        name,
      });
    });

    return customPresetLinks;
  }

  getMuxVideoGifLink = (url) => {
    if (!this.state.visibleAttachment?.attributes?.mux_hls_url) { return '' }
    // Example mux hls url 'https://stream.mux.com/P9uJ5Go7MxntHjrkBwUOOOTv6hd02Tdy00.m3u8'
    const muxID = this.state.visibleAttachment.attributes.mux_hls_url.match(/[^\/]+$/)[0].replace(".m3u8", "");
    const bfCDNMuxGifURL = "https://videogif.bfldr.com/" + muxID +"/animated.gif"
    //const bfCDNMuxGifURL = "https://image.mux.com/" + muxID +"/animated.gif"
    return bfCDNMuxGifURL;
  }

  getMuxHLSStreamURL = (url) => {
    if (!this.state.visibleAttachment?.attributes?.mux_hls_url) { return '' }
    // Example mux hls url 'https://stream.mux.com/P9uJ5Go7MxntHjrkBwUOOOTv6hd02Tdy00.m3u8'
    return this.state.visibleAttachment.attributes.mux_hls_url;
  }

  getV21OriginalLink = (url) => url.split("?")[0];

  getV21FrameLink = (url) => {
    const strippedOriginal = this.getV21OriginalLink(url);
    const strippedOriginalUrl = new URL(strippedOriginal);
    const frameUrl = new URL(BFG.smartCDNFrameURL);
    frameUrl.pathname = strippedOriginalUrl.pathname;
    return frameUrl.toString();
  };

  link = () => {
    if (!this.state.visibleAttachment) {
      return { assetLink: null, attachmentLink: null };
    }

    // assets
    // # v1   = "http://fastly_v1.lvh.me:3000/6IGOKVHG/as/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2.png"
    // # v2   = "http://fastly_v2.lvh.me:3000/6IGOKVHG/as/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2"
    // # v2_1 = "http://fastly_v2.lvh.me:3000/6IGOKVHG/as/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2?format=png&webp=auto"

    // attachments
    // # v1   = "http://fastly_v1.lvh.me:3000/6IGOKVHG/at/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2.jpg"
    // # v2   = "http://fastly_v2.lvh.me:3000/6IGOKVHG/at/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2"
    // # v2_1 = "http://fastly_v2.lvh.me:3000/6IGOKVHG/at/qc1a16-g67t8o-t9ye/3784-bliffert_DSC_0229_3_2.jpg?format=png&webp=auto"
    const { assetCdnUrl } = this.props;
    let {
      visibleAttachment: { attributes: { cdn_url } },
    } = this.state;

    if (!!cdn_url && !this.isEmbedEnabled()) {
      // replace cdn key with 'CDNKEY' when 'embed_asset' feature is disabled
      const cdnUrlSplit = cdn_url.split('/');

      // cdn key is just before (to the left of) 'at' in the url,
      // e.g. http://fastly_v2_1.lvh.me:3000/ZDB61M3R/at/RRMkAdPG8K90IKSH1n1X/texo_vero_autus.jpg?auto=webp&format=png
      const cdnKeyIndex = cdnUrlSplit?.findIndex((block) => (block === 'at')) - 1;

      if (cdnKeyIndex > -1) {
        const cdnKey = cdnUrlSplit[cdnKeyIndex];
        cdn_url = cdn_url.replace(cdnKey, 'CDNKEY');
      }
    }

    switch (BFG.smartCDNSource) {
      case "fastly":
        return {
          assetLink: this.getV1AssetLink(assetCdnUrl),
          attachmentLink: this.getV1AttachmentLink(cdn_url),
        };
      case "fastly_v2":
        // NOTE that for v2 we don't use extensions, they're confusing with their intent. format=<> conveys desire
        return {
          assetLink: this.getV2AssetLink(assetCdnUrl),
          attachmentLink: this.getV2AttachmentLink(cdn_url),
        };
      case "fastly_v2_1":
        return {
          assetLink: this.getV21AssetLink(assetCdnUrl),
          attachmentLink: this.getV21AttachmentLink(cdn_url),
          customPresetsLinks: this.getV21CustomPresetsLink(cdn_url),
          originalLink: this.getV21OriginalLink(cdn_url),
          frameLink: this.getV21FrameLink(cdn_url),
          muxVideoGif: this.getMuxVideoGifLink(cdn_url),
          muxHLSStream: this.getMuxHLSStreamURL(cdn_url),
        };
      default:
        return {
          assetLink: this.getV1AssetLink(assetCdnUrl),
          attachmentLink: this.getV1AttachmentLink(cdn_url),
        };
    }
  };

  maintainImageSizeRatio = (e) => {
    let { height, width } = this.state.visibleAttachment.attributes;
    const updatedValue = parseInt(e.target.value, 10);
    const aspectRatio = width / height;
    const isWidth = e.target.className && e.target.className.indexOf("asset-width") > -1;

    if (!Number.isInteger(updatedValue)) {
      width = "";
      height = "";
    } else if (isWidth) {
      width = updatedValue;
      height = Math.round(width / aspectRatio);
    } else {
      height = updatedValue;
      width = Math.round(height * aspectRatio);
    }

    this.setState({ selectedWidth: width, selectedHeight: height });
  };

  updateActiveImage = ({ id: attachmentId }) => {
    if (attachmentId !== this.state.visibleAttachment.id) {
      this.setState((prevState) => {
        const visible = prevState.attachments.find((attachment) => attachment.id === attachmentId);
        return {
          visibleAttachment: visible,
          selectedFormat: this.formatSelectedFormat(visible),
          selectedWidth: visible?.attributes?.width || 0,
          selectedHeight: visible?.attributes?.height || 0,
        };
      });
    }
  };

  toggleAdditionalLinks = (linksField) => {
    this.setState((prevState) => ({
      [linksField]: !prevState[linksField],
    }));
  }

  isEmbedEnabled = () => (
    BFG.hasFeature("embed_asset")
  )

  render() {
    const { attachments, loading, visibleAttachment } = this.state;
    const isVideo = visibleAttachment?.attributes?.mimetype
      && visibleAttachment.attributes.mimetype.indexOf("video") > -1;
    const isMux = visibleAttachment?.attributes?.mux_hls_url;
    const activeCard = visibleAttachment ? { id: visibleAttachment.id, type: "attachment" } : null;
    const platformName = whitelabelPresenter(isGettyClient() ? 'getty' : '').platformName;

    const { assetLink, attachmentLink, customPresetsLinks, originalLink, frameLink, muxVideoGif, muxHLSStream } = this.link();

    return (
      <I18nProviderWrapper>
        <div className="flex-wrapper asset-embed-component">
          <AttachmentSidebar
            activeCard={activeCard}
            attachments={attachments.length ? attachments : null}
            updateActiveCard={this.updateActiveImage}
          />
          {loading ? (
            <div className="flex-two-thirds no-similar-details loader-container">
              <video
                className="brandfolder-loader"
                height="90"
                poster="https://cdn.brandfolder.io/4OQZ5PW1/as/pxmju5-qfr48-21nt37/generic-loader.gif"
                width="90"
              />
            </div>
          ) : (
            <div className="modal-asset-two-thirds flex-two-thirds embed-details bf-scroll-element">
              {!this.isEmbedEnabled() && (
                <div className="embed-details__no-support">
                  <div className="embed-details__no-support--details flex-two-thirds">
                    <h3>
                      <Trans>Your account doesn't currently support embedding.</Trans>
                    </h3>
                    <p>
                      <Trans>Embedding with CDN (Content Delivery Network) Links allow you to embed assets on external websites, while housing the single source of truth version in {platformName}.</Trans>
                    </p>
                    <p>
                      <Trans>Do you have special requirements? Your account manager can answer questions and get you a quote for the embed feature based on your specific needs.</Trans>
                    </p>
                    <a href={`mailto:${this.props.supportEmail}`}>
                      <button className="button primary" type="button">
                        <Trans>Get a Quote</Trans>
                      </button>
                    </a>
                  </div>
                  <div className="embed-details__no-support--list flex-one-third">
                    <Trans comment="bulleted list">
                      <p>With CDN embedding, you can:</p>
                      <ul>
                        <li>
                          <span className="bff-confirmation" />
                          Edit or replace assets in {platformName} and see the changes reflected on external sites
                        </li>
                        <li>
                          <span className="bff-confirmation" />
                          Track asset usage outside of {platformName}
                        </li>
                        <li>
                          <span className="bff-confirmation" />
                          Ensure assets that live in marketing campaigns and digital channels are never out of date
                        </li>
                      </ul>
                    </Trans>
                  </div>
                </div>
              )}
              <div className={!this.isEmbedEnabled() ? "disabled" : ""}>
                {visibleAttachment && visibleAttachment.attributes && (
                  <>
                    <VisibleAttachment
                      extension={visibleAttachment.attributes.extension}
                      filename={visibleAttachment.attributes.filename}
                      height={visibleAttachment.attributes.height}
                      size={visibleAttachment.attributes.size}
                      thumbnail={visibleAttachment.attributes.thumbnail_url}
                      width={visibleAttachment.attributes.width}
                    />
                    <div className="embed-text">
                      <h3><Trans>Embed Attachment</Trans></h3>
                      <p>
                        <Trans>
                          Select the attachment you'd like to embed, and use the CDN Link below for use on external websites and apps. If the attachment is replaced in the Edit tab, the file will update anywhere the attachment has been embedded.
                        </Trans>
                      </p>
                    </div>
                    {!isVideo && (
                      <>
                        <div className="asset-embed-component__dimensions">
                          <div className="asset-embed-component__dimensions--dropdown-container">
                            <label className="bf-label bf-label--primary">
                              <Trans>File Type</Trans>
                            </label>
                            <ListDropdown
                              className="asset-embed-component__dimensions--dropdown"
                              disabled={!this.isEmbedEnabled()}
                              isNestedDropdown
                              onChange={(selected) => this.setState({ selectedFormat: selected })}
                              options={this.formatOptions}
                              value={this.state.selectedFormat}
                            />
                          </div>
                          <div className="asset-embed-component__dimensions--input-container">
                            <div className="width-input asset-embed-component__dimensions--input">
                              <label className="bf-label bf-label--primary">
                                <Trans>Width (px)</Trans>
                              </label>
                              <input
                                className="asset-width brandfolder-input"
                                disabled={!this.isEmbedEnabled()}
                                onChange={this.maintainImageSizeRatio}
                                value={this.state.selectedWidth}
                              />
                            </div>
                            <span className="bff-private" />
                            <div className="height-input asset-embed-component__dimensions--input">
                              <label className="bf-label bf-label--primary">
                                <Trans>Height (px)</Trans>
                              </label>
                              <input
                                className="asset-height brandfolder-input"
                                disabled={!this.isEmbedEnabled()}
                                onChange={this.maintainImageSizeRatio}
                                value={this.state.selectedHeight}
                              />
                            </div>
                          </div>
                        </div>
                        {attachmentLink && (
                          <CDNLink
                            disabled={!this.isEmbedEnabled()}
                            label={t`Attachment CDN Link`}
                            link={attachmentLink}
                          />
                        )}
                      </>
                    )}
                    {isVideo && (
                      <CDNLink
                        disabled={!this.isEmbedEnabled()}
                        label={t`Video Embed Code`}
                        link={this.iframe}
                      />
                    )}
                    <div className="asset-embed-component__documentation">
                      <div className="asset-embed-component__documentation--cdn">
                        <span className="bff-new-tab" />
                        <a
                          href="https://help.smartsheet.com/360005174134-Embedding-Assets-with-Smart-CDN"
                          rel="noopener noreferrer"
                          tabIndex={this.isEmbedEnabled() ? undefined : -1}
                          target="_blank"
                        >
                          <Trans>CDN documentation</Trans>
                        </a>
                      </div>
                      <div className="api-link">
                        <span className="bff-new-tab" />
                        <a
                          href="https://developers.brandfolder.com"
                          rel="noopener noreferrer"
                          tabIndex={this.isEmbedEnabled() ? undefined : -1}
                          target="_blank"
                        >
                          <Trans>API documentation</Trans>
                        </a>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <div className="asset-embed-component__additional-links--button">
                        <TextButton
                          disabled={!this.isEmbedEnabled()}
                          icon={
                            this.state.showAdditionalLinks
                              ? "bff-caret-down"
                              : "bff-caret-right"
                          }
                          onClick={() => this.toggleAdditionalLinks('showAdditionalLinks')}
                        >
                          <p><Trans>Additional CDN Links</Trans></p>
                        </TextButton>
                      </div>
                      <CSSTransition
                        classNames="fade-in"
                        in={this.state.showAdditionalLinks}
                        timeout={200}
                        unmountOnExit
                      >
                        <>
                          {this.state.showAdditionalLinks && (
                            <div className="asset-embed-component__additional-links--container">
                              {!isVideo && (
                                <CDNLink
                                  description={t`You can also use the Asset CDN Link to embed the asset. This embed code correlates with the order of attachments, and will by default select the first attachment to embed. Changing the order of the attachments in the Edit tab will automatically update images that are embedded externally.`}
                                  disabled={!this.isEmbedEnabled()}
                                  label={t`Asset CDN Link`}
                                  link={assetLink}
                                  styleClass="asset-embed-component__additional-links"
                                />
                              )}
                              {isVideo && (
                                <CDNLink
                                  description={t`You can also use the Asset CDN Link to embed the asset. This embed code correlates with the order of attachments, and will by default select the first attachment to embed. Changing the order of the attachments in the Edit tab will automatically update images that are embedded externally.`}
                                  disabled={!this.isEmbedEnabled()}
                                  label={t`Asset CDN Link`}
                                  link={assetLink}
                                  styleClass="asset-embed-component__additional-links"
                                />
                              )}
                              {originalLink && (
                                <CDNLink
                                  description={t`(Not recommended) The Original File CDN Link will embed the selected attachment without any conversions or optimizations.`}
                                  disabled={!this.isEmbedEnabled()}
                                  label={t`Attachment CDN Link (Original File)`}
                                  link={originalLink}
                                  styleClass="asset-embed-component__additional-links"
                                />
                              )}
                              {frameLink && !isVideo && (
                                <CDNLink
                                  description={t`Useful for automatically resizing based on the container it is inside.`}
                                  disabled={!this.isEmbedEnabled()}
                                  label={t`Frame CDN Link`}
                                  link={frameLink}
                                  styleClass="asset-embed-component__additional-links"
                                />
                              )}
                              {isVideo && isMux && (
                                <CDNLink
                                  description={t`Create a CDN link for an animated GIF of the video.`}
                                  disabled={!this.isEmbedEnabled()}
                                  label={t`Video GIF CDN Link`}
                                  link={muxVideoGif}
                                  styleClass="asset-embed-component__additional-links"
                                />
                              )}
                              {isVideo && isMux && BFG.hasFeature('video_streaming') && (
                                <CDNLink
                                  description={t`HLS Stream for Video Embeds.`}
                                  disabled={!this.isEmbedEnabled()}
                                  label={t`HLS Video Stream Link`}
                                  link={muxHLSStream}
                                  styleClass="asset-embed-component__additional-links"
                                />
                              )}
                            </div>
                          )}
                        </>
                      </CSSTransition>
                      {customPresetsLinks?.length > 0 && (
                        <>
                          <div className="asset-embed-component__custom-preset-links--button">
                            <TextButton
                              disabled={!this.isEmbedEnabled()}
                              icon={this.state.showCustomPresetCDNLinks ? "bff-caret-down" : "bff-caret-right"}
                              onClick={() => this.toggleAdditionalLinks('showCustomPresetCDNLinks')}
                            >
                              <p><Trans>Custom Preset CDN Links</Trans></p>
                            </TextButton>
                          </div>
                          <CSSTransition
                            classNames="fade-in"
                            in={this.state.showCustomPresetCDNLinks}
                            timeout={200}
                            unmountOnExit
                          >
                            <>
                              {customPresetsLinks.map(({ id, link, name }) => (
                                <CDNLink
                                  key={id}
                                  disabled={!this.isEmbedEnabled()}
                                  label={capitalize(name)}
                                  link={link}
                                  styleClass="asset-embed-component__custom-preset-links"
                                />
                              ))}
                            </>
                          </CSSTransition>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </I18nProviderWrapper>
    );
  }
}

AssetEmbed.propTypes = {
  assetKey: PropTypes.string.isRequired,
  brandfolderSlug: PropTypes.string.isRequired,
  libraryName: PropTypes.string.isRequired,
  supportEmail: PropTypes.string.isRequired,
  watermarkResourceKey: PropTypes.string,
  assetCdnUrl: PropTypes.string.isRequired,
};

AssetEmbed.defaultProps = {
  watermarkResourceKey: undefined,
};

export default AssetEmbed;
