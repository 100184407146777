import { t } from '@lingui/macro';
import { RadioButton } from '@components/library/radio_button_group';

export enum WatermarkPositionsEnum {
  SouthWestGravity = 'SouthWestGravity',
  NorthWestGravity = 'NorthWestGravity',
  SouthEastGravity = 'SouthEastGravity',
  NorthEastGravity = 'NorthEastGravity'
}

export interface WatermarkPosition extends RadioButton {
  copy: string;
  id: WatermarkPositionsEnum;
}

export const watermarkPositions = (): WatermarkPosition[] => [
  {
    copy: t`Bottom Left`,
    id: WatermarkPositionsEnum.SouthWestGravity
  },
  {
    copy: t`Top Left`,
    id: WatermarkPositionsEnum.NorthWestGravity
  },
  {
    copy: t`Bottom Right`,
    id: WatermarkPositionsEnum.SouthEastGravity
  },
  {
    copy: t`Top Right`,
    id: WatermarkPositionsEnum.NorthEastGravity
  }
];

export const defaultWatermarkPostion = watermarkPositions()[0].id;
