import { Plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import AttachmentCard from '@components/asset/data/attachment_card';
import SimpleAssetCard from '@components/asset/data/simple_asset_card';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { BFLoader } from '@components/common/loader/main';

import AttachmentSidebar from '../shared/attachment_sidebar/main';
import VisibleAttachment from '../shared/visible_attachment/main';

import './styles/main.scss';

class SimilarityTab extends React.Component {
  state = {
    ajaxCalls: [],
    attachments: [],
    brandfolderKey: '',
    loading: true,
    visibleAttachmentId: null,
    visibleDuplicate: [],
    visibleDuplicateFilenamesCount: 0,
    visibleSimilar: [],
    visibleSuggestedAssets: [],
  };

  /* NOTE :: When you have a parent modal component that grabs the asset info
  you won't need to grab the attachments on each tab, you can grab them when
  you load the modal and then just pass them down. Attachments will also no
  longer need to be stored in state when this happens */
  componentDidMount() {
    this.addTabListener();
  }

  componentWillUnmount() {
    this.removeTabListener();
    this.abortAjaxCalls();
  }

  getDuplicates() {
    const { visibleAttachmentId } = this.state;
    const paramsString = 'include=asset&fields=extension,thumbnail_url';

    const request = $.ajax({
      context: this,
      type: "GET",
      url: `/api/v4/private/ro/${BFG.resource.type}s/${BFG.resource.key}/attachments/${visibleAttachmentId}/duplicate?${paramsString}`,
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
      },
      success: (response) => {
        this.setState({ visibleDuplicate: response.data }, () => this.getSimilar());
      },
      error: () => {
        // TODO Create error state
        this.setState({ loading: false });
      }
    });

    this.updateAjaxCalls(request);
  }

  getDuplicateFilenameCount() {
    const { visibleAttachmentId } = this.state;

    const request = $.ajax({
      context: this,
      type: "GET",
      url: `/api/v4/private/ro/${BFG.resource.type}s/${BFG.resource.key}/attachments/${visibleAttachmentId}/duplicate_filenames_count`,
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
      },
      success: (response) => {
        this.setState({
          visibleDuplicateFilenamesCount: response.data.duplicate_filename_count
        });
      },
      error: () => {
        // TODO Create error state
        this.setState({ loading: false });
      }
    });

    this.updateAjaxCalls(request);
  }

  getSimilar() {
    const { visibleAttachmentId } = this.state;
    const paramsString = 'include=asset&fields=extension,thumbnail_url&queue_priority=high';

    const request = $.ajax({
      url: `/api/v4/private/ro/${BFG.resource.type}s/${BFG.resource.key}/attachments/${visibleAttachmentId}/similar?${paramsString}`,
      method: "GET",
      context: this,
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
      },
      success: (response) => {
        // remove overlap with duplicate attachments
        const visibleDuplicateKeys = this.state.visibleDuplicate.map((attachment) => attachment.id);
        const similarAttachments = response.data.filter((att) => !visibleDuplicateKeys.includes(att.id));
        this.setState({
          visibleSimilar: similarAttachments,
          loading: false
        });
      },
      error: () => {
        // TODO Create error state
        this.setState({ loading: false });
      }
    });
    this.updateAjaxCalls(request);
  }

  getBulkAttachments(attachmentKeys) {
    const { brandfolderKey } = this.state;
    const paramsString = 'include=asset&fields=extension,thumbnail_url&queue_priority=high';

    const request = $.ajax({
      url: `/api/v4/brandfolders/${brandfolderKey}/bulk_retrieve/attachments?${paramsString}`,
      method: 'POST',
      context: this,
      data: JSON.stringify({ ids: attachmentKeys }),
      contentType: 'application/json',
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
      },
      success: (response) => {
        const attachmentsArray = response.data;
        const visibleSimilarSorted = [];
        if (attachmentsArray.length) {
          attachmentKeys.forEach((id) => {
            attachmentsArray.forEach((attachment) => {
              if (id === attachment.id) {
                visibleSimilarSorted.push(attachment);
              }
            });
          });
        }
        this.setState({ visibleSimilar: visibleSimilarSorted });
      }
    });
    this.updateAjaxCalls(request);
  }

  getSuggestedAssets = () => {
    let url = `/api/v4/private/assets/${this.props.assetKey}/suggested_assets?fast_jsonapi=true&queue_priority=high`;
    if (this.props.watermarkResourceKey) {
      url += `&watermark_resource_key=${this.props.watermarkResourceKey}`;
    }
    const request = $.ajax({
      url,
      method: 'GET',
      context: this,
      contentType: 'application/json',
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
      },
      success: (response) => {
        this.setState({ visibleSuggestedAssets: response.data });
      }
    });
    this.updateAjaxCalls(request);
  }

  requestSimilarity = () => {
    if (this.state.loading) {
      let url = `/api/v4/assets/${this.props.assetKey}/attachments?fields=download_url,thumbnail_url,view_thumbnail_retina,extension,thumbnailed,extracted_tag_names,extracted_text,extracted_colors,folder_path&queue_priority=high`;
      if (this.props.watermarkResourceKey) {
        url += `&watermark_resource_key=${this.props.watermarkResourceKey}`;
      }
      const request = $.ajax({
        context: this,
        type: 'GET',
        url,
        beforeSend: (xhr) => {
          xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
        },
        success: (response) => {
          const brandfolderKey = BF.fx.brandfolder().key;
          const sortedAttachments = response.data.sort((a, b) => (
            a.attributes.position - b.attributes.position));

          this.setState({
            attachments: sortedAttachments,
            brandfolderKey,
            visibleAttachmentId: response.data[0].id,
          }, () => {
            this.getDuplicates();
            this.getDuplicateFilenameCount();
            this.getSuggestedAssets();
          });
        },
        error: () => {
          // TODO Create error state
          this.setState({ loading: false });
        }
      });
      this.updateAjaxCalls(request);
    }
  }

  updateActiveImage = ({ id: attachmentId }) => {
    const { visibleAttachmentId } = this.state;

    if (visibleAttachmentId !== attachmentId) {
      this.abortAjaxCalls();
      this.setState({
        ajaxCalls: [],
        visibleAttachmentId: attachmentId,
        visibleSimilar: [],
        visibleDuplicate: [],
        visibleDuplicateFilenamesCount: 0,
        loading: true
      }, () => {
        this.getDuplicates();
        this.getDuplicateFilenameCount();
      });
    }
  }

  searchFilename = (filename) => {
    const searchTerm = `filename:"${filename}"`;
    BF.dialog.dismiss();
    BF.fx.fillInAndFilterQuery(searchTerm);
  }

  addTabListener = () => {
    window.addEventListener(
      `similarityAssetTabClick${this.props.assetKey}`,
      this.requestSimilarity,
      false
    );
  }

  removeTabListener = () => {
    window.removeEventListener(
      `similarityAssetTabClick${this.props.assetKey}`,
      this.requestSimilarity,
      false
    );
  }

  updateAjaxCalls = (xhr) => {
    this.setState((prevState) => {
      const updatedAjaxCalls = [...prevState.ajaxCalls];
      updatedAjaxCalls.push(xhr);

      return { ajaxCalls: updatedAjaxCalls };
    });
  }

  abortAjaxCalls = () => {
    this.state.ajaxCalls.forEach((ajaxCall) => {
      if (ajaxCall.readyState !== 4) {
        ajaxCall.abort();
      }
    });
  }

  currentAttachment = () => (
    this.state.attachments.filter((attachment) => (
      attachment.id === this.state.visibleAttachmentId)[0])
  )

  renderSuggestedAssetCards = () => (
    this.state.visibleSuggestedAssets.map((asset) => (
      <SimpleAssetCard
        key={asset.id}
        asset={asset}
      />
    ))
  )

  renderDuplicates = () => (
    this.state.visibleDuplicate.map((duplicate) => (
      <AttachmentCard
        key={duplicate.id}
        assetKey={duplicate.relationships.asset.data.id}
        attachment={duplicate.attributes}
      />
    ))
  )

  renderSimilar = () => {
    const visibleSimilarArray = [];
    // 24 is optimal for UI (divisible by 3/4)
    this.state.visibleSimilar.slice(0, 24).forEach((similar) => {
      visibleSimilarArray.push(
        <AttachmentCard
          key={similar.id}
          assetKey={similar.relationships.asset.data.id}
          attachment={similar.attributes}
        />
      );
    });

    return visibleSimilarArray;
  }

  renderNoSimilar = () => <p className="gray-text"><Trans>No similar images detected</Trans></p>;

  renderSuggestedAssets = (visibleAttachmentObject) => {
    const { assetName } = this.props;
    return (
      <>
        {visibleAttachmentObject && visibleAttachmentObject.attributes
          ? (
            <h3 className="title">
              <Trans>
                Suggested Assets for <span className="title-bold">{assetName}</span>
              </Trans>
            </h3>
          )
          : ''
        }
        {this.state.visibleSuggestedAssets.length > 0
          ? (
            <div className="card-list t-suggested-container">
              {this.renderSuggestedAssetCards()}
            </div>
          )
          : (
            <div className="t-suggested-container">
              <p className="gray-text">
                <Trans>No suggested assets detected</Trans>
              </p>
            </div>
          )
        }
      </>
    );
  };

  renderBody() {
    const {
      attachments,
      visibleAttachmentId,
      loading,
      visibleDuplicate,
      visibleSimilar,
      visibleDuplicateFilenamesCount,
    } = this.state;
    const visibleAttachmentObject = visibleAttachmentId
      ? attachments.find((attachment) => attachment.id === visibleAttachmentId)
      : null;
    const activeCard = visibleAttachmentId
      ? { id: visibleAttachmentId, type: 'attachment' }
      : null;
    const visibleAttachmentFilename = visibleAttachmentObject?.attributes?.filename;

    return (
      <div className="flex-wrapper similar-tab-container">
        <AttachmentSidebar
          activeCard={activeCard}
          attachments={attachments.length ? attachments : null}
          updateActiveCard={this.updateActiveImage}
        />
        {loading ? (
          <div className="flex-two-thirds no-similar-details loader-container">
            <BFLoader />
          </div>
        ) : (
          <>
            <div className={`modal-asset-two-thirds flex-two-thirds bf-scroll-element ${visibleDuplicate.length > 0 ? 'similar-details' : 'no-similar-details'}`}>
              {visibleAttachmentObject && visibleAttachmentObject.attributes
                ? (
                  <VisibleAttachment
                    extension={visibleAttachmentObject.attributes.extension}
                    filename={visibleAttachmentFilename}
                    height={visibleAttachmentObject.attributes.height}
                    size={visibleAttachmentObject.attributes.size}
                    thumbnail={visibleAttachmentObject.attributes.thumbnail_url}
                    width={visibleAttachmentObject.attributes.width}
                  />
                ) : ''
              }
              {visibleAttachmentObject && visibleAttachmentObject.attributes && visibleDuplicateFilenamesCount > 0
                && (
                  <h3 className="title">
                    <Plural
                      one="# attachment has the same filename."
                      other="# attachments have the same filename."
                      value={visibleDuplicateFilenamesCount}
                    />
                    <a onClick={() => this.searchFilename(visibleAttachmentFilename)}>
                      <Plural
                        one="Locate this asset."
                        other="Locate these assets."
                        value={visibleDuplicateFilenamesCount}
                      />
                    </a>
                  </h3>
                )
              }
              {visibleAttachmentObject && visibleAttachmentObject.attributes
                ? (
                  <h3 className="title">
                    <Trans>
                      Duplicates of <span className="title-bold">{visibleAttachmentFilename}</span>
                    </Trans>
                  </h3>
                )
                : ''
              }
              {visibleDuplicate.length > 0
                ? (
                  <>
                    <div className="card-list card-list-duplicates t-duplicates-container">
                      {this.renderDuplicates()}
                    </div>
                    <hr />
                  </>
                ) : (
                  <>
                    <div className="card-list-empty t-duplicates-container">
                      <p className="gray-text">
                        <Trans>No duplicate images detected</Trans>
                      </p>
                    </div>
                    <hr />
                  </>
                )
              }
              {visibleAttachmentObject && visibleAttachmentObject.attributes
                ? (
                  <h3 className="title">
                    <Trans>
                      Visually similar to <span className="title-bold">{visibleAttachmentFilename}</span>
                    </Trans>
                  </h3>
                ) : ''
              }
              {visibleSimilar.length > 0
                ? <div className="card-list t-similar-container">{this.renderSimilar()}</div>
                : <div className="t-similar-container">{this.renderNoSimilar()}</div>
              }
              {this.renderSuggestedAssets(visibleAttachmentObject)}
            </div>
          </>
        )}
      </div>
    );
  }

  render() {
    return (
      <I18nProviderWrapper>
        {this.renderBody()}
      </I18nProviderWrapper>
    )
  }
}

SimilarityTab.propTypes = {
  assetKey: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
  watermarkResourceKey: PropTypes.string,
};

SimilarityTab.defaultProps = {
  watermarkResourceKey: undefined,
};

export default SimilarityTab;
