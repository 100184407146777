import fetchJSON from '@api/api_helper';

const url = ({ resourceKey, resourceType, id }) => {
  const baseUrl = `/api/v4/${resourceType}s/${resourceKey}/metadata_conversions`;

  return id ? `${baseUrl}/${id}` : baseUrl;
};

export const get = ({
  resourceType,
  resourceKey,
}) => (
  fetchJSON(url({ resourceKey, resourceType })).then(({ data }) => (
    data.map(({ id, attributes }) => ({
      id,
      ...attributes,
    }))
  ))
);

export const create = ({
  resourceType,
  resourceKey,
  metadata_term,
  key_term,
  convert_to_tags,
}) => (
  fetchJSON(url({ resourceKey, resourceType }), {
    method: 'POST',
    body: {
      data: {
        attributes: {
          metadata_term,
          key_term,
          convert_to_tags: !!convert_to_tags,
        },
      },
    },
  }).catch(async (response) => { throw (await response.json()); })
);
