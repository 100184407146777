import { t, Trans } from '@lingui/macro';
import { PickerResponse } from 'filestack-js/build/main/lib/picker';
import React, { useState, FunctionComponent } from 'react';

import { update as updateResource } from '@api/v4/resources/resources';
import { filteredFiles } from '@components/common/filestack_uploader/helpers';
import { UploadArea } from '@components/common/filestack_uploader/UploadArea';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { PrimaryButton, TextWarningButton } from '@components/library/button/index';
import { structureFilestackFiles } from '@helpers/uploaders';

import './styles/_watermark-setting.scss';
import { Watermark } from './watermarkTypes';

interface WatermarkProps {
  originalWatermark: Watermark;
  originalWatermarkEnabled: boolean;
}

const WatermarkUploadSetting: FunctionComponent<WatermarkProps> = ({ originalWatermark, originalWatermarkEnabled }) => {
  // important to track initial state to detect updates
  const [initialWatermark, setInitialWatermark] = useState<Watermark | null>(originalWatermark);

  const [watermark, setWatermark] = useState<Watermark | null>(originalWatermark);
  const [watermarkEnabled, setWatermarkEnabled] = useState(originalWatermarkEnabled);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const notifySuccess = (): void => Notify.create({
    title: t`Watermark updated!`,
    type: 'success'
  });

  const notifyFailure = (): void => Notify.create({
    title: t`Error updating watermark setting. Please contact support.`,
    type: 'error'
  });

  const submitDisableWatermark = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      await updateResource({
        key: BFG?.resource?.key,
        resourceType: BFG?.resource?.type,
        watermarkEnabled: false,
      });

      notifySuccess();
      setWatermarkEnabled(false);
      setWatermark(initialWatermark);
    } catch {
      notifyFailure();
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitWatermarkUpdate = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      await updateResource({
        key: BFG?.resource?.key,
        resourceType: BFG?.resource?.type,
        watermark: watermark && watermark !== initialWatermark ? watermark : undefined,
        watermarkEnabled: true,
      });
      setWatermarkEnabled(true);
      setInitialWatermark(watermark);
      notifySuccess();
    } catch {
      notifyFailure();
    } finally {
      setIsSubmitting(false);
    }
  };

  const enableWatermark = (): void => {
    if (watermark?.url) {
      submitWatermarkUpdate();
    } else {
      setWatermarkEnabled(true);
    }
  };

  const handleFilestackUpload = (files: PickerResponse): void => {
    const filtered = filteredFiles(files.filesUploaded);
    const structured = structureFilestackFiles(filtered);

    const file = structured[0];
    const { url, filename } = file;
    setWatermark({ url, filename });
  };

  const renderUpdateButton = (): JSX.Element => (
    <PrimaryButton
      className="watermark-image-update__submit"
      disabled={!watermark?.url || (initialWatermark?.url === watermark?.url)}
      isLoading={isSubmitting}
      loadingCopy={t`Updating`}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={submitWatermarkUpdate}
      size="medium"
    >
      <Trans>Update</Trans>
    </PrimaryButton>
  );

  return (
    <I18nProviderWrapper>
      <div className="watermark-image-setting-container">
        <div className="advanced-tab-settings__row">
          <div className="quarter-column">
            <p><Trans>Watermark</Trans></p>
          </div>
          <div className="advanced-tab-settings__options">
            <div className="radio-button-container">
              <input
                checked={watermarkEnabled}
                className="radio-margin"
                id="watermarking_enabled"
                name="watermarking"
                onChange={enableWatermark}
                type="radio"
                value="true"
              />
              <label htmlFor="watermarking_enabled"><Trans>Enabled</Trans></label>
            </div>
            <div className="radio-button-container">
              <input
                checked={!watermarkEnabled}
                className="radio-margin"
                id="watermarking_disabled"
                name="watermarking"
                onChange={(): void => { submitDisableWatermark(); }}
                type="radio"
                value="false"
              />
              <label htmlFor="watermarking_disabled"><Trans>Disabled</Trans></label>
            </div>
          </div>
        </div>
        {watermarkEnabled
          && <p className="watermark-upload-title bf-label"><Trans>Watermark Image</Trans></p>
        }
        <div className="watermark-image-update__container">
          {watermarkEnabled && watermark?.url
            && (
              <>
                <div className="existing-watermark-container">
                  <div className="file-display">
                    <a
                      className="existing-filename"
                      href={watermark.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {watermark.filename || 'watermark.png'}
                    </a>
                    <TextWarningButton
                      icon="bff-trash"
                      onClick={(): void => setWatermark(null)}
                    />
                  </div>
                </div>
                {renderUpdateButton()}
              </>
            )
          }
          {watermarkEnabled && !watermark?.url
            && (
              <>
                <UploadArea
                  id={`${BFG?.resource?.type}-watermark-${BFG?.resource?.key}`}
                  onUploadDone={handleFilestackUpload}
                  pickerOptions={{
                    accept: ['image/*'],
                    maxFiles: 1,
                    maxSize: 5 * 1024 * 1024 // 5MB
                  }}
                  uploadType={UploadAreaTypes.GenericUpload}
                />
                {renderUpdateButton()}
              </>
            )
          }
        </div>
      </div>
    </I18nProviderWrapper>
  );
};

export default WatermarkUploadSetting;
