import React from 'react';
import PropTypes from 'prop-types';

import copyToClipboard from '@helpers/copy_to_clipboard';
import './styles/metadata.scss';

class Metadata extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copying: false,
    };
  }

  metadataToString() {
    if (!this.props.metadata) { return ''; }

    return Object.keys(this.props.metadata).map((key) => (
      `${key}: ${this.props.metadata[key]}`
    )).join('\n');
  }

  copy() {
    copyToClipboard(this.metadataToString());
    this.setState({ copying: true }, () => {
      setTimeout(() => {
        this.setState({ copying: false });
      }, 2000);
    });
  }

  render() {
    const disabled = !this.props.metadata;
    const rows = this.props.metadata ? Object.keys(this.props.metadata).length : 5;
    const metadata = this.metadataToString();

    return (
      <div className="workbench-metadata">
        <label
          htmlFor="extracted_metadata"
          className={disabled ? 'disabled' : ''}
        >
          Extracted metadata:
        </label>
        <textarea
          id="extracted_metadata"
          disabled={disabled}
          readOnly
          value={metadata}
          rows={rows}
        />
        <button
          onClick={() => this.copy()}
          disabled={disabled}
          className="button dark-blue large"
          type="button"
        >
          {this.state.copying ? 'Copied to clipboard!' : 'Copy metadata'}
        </button>
        {this.props.renderResetButton()}
      </div>
    );
  }
}

Metadata.propTypes = {
  asset: PropTypes.shape({}),
  metadata: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  renderResetButton: PropTypes.func
};

Metadata.defaultProps = {
  asset: null,
  metadata: null,
  renderResetButton: () => {}
};

export default Metadata;
