import { Trans, Plural } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { ShareManifestServer, ShareManfiestResourcePath } from '@api/v4/share_manifests/ShareManifestTypes';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { getArrayIncludesOtherArray, getArraysHasIntersection } from '@helpers/arrays';

import { Alert, AttachmentGroup, HeadingWithActions, View } from './components';
import { mapShareManifestServerToClient } from './helpers';

import classes from './styles/show.module.scss';

interface ShowProps {
  allAssetCount: number;
  attachmentAssetKeys: string[];
  attachmentGroupAttachmentCount: number;
  attachmentGroupDownloadUrl: string | null;
  customLogoUrl: string | null;
  downloadRequestId: number | null;
  editable: boolean;
  expiresFriendly: string;
  hasAssets: boolean;
  largeFileIndicator: number;
  manifest: ShareManifestServer;
  name: string;
  notShownCount: number;
  readable: boolean;
  resourcePath: string;
  resourcePaths: ShareManfiestResourcePath[] | null;
  selectedAssetKeys: string[];
  selectedViewOnlyAssetKeys: string[];
  shareUrl: string;
  showMacZipMessage: boolean;
  showNotShown: boolean;
  galleryView: boolean;
  subject: string;
  totalAssetFriendly: string;
  totalAssetRaw: number;
  totalAssets: number;
  viewOnly: boolean;
}

export const Show: FunctionComponent<ShowProps> = (props) => {
  const {
    attachmentAssetKeys,
    attachmentGroupAttachmentCount,
    attachmentGroupDownloadUrl,
    customLogoUrl,
    downloadRequestId,
    editable,
    expiresFriendly,
    hasAssets,
    manifest,
    name,
    notShownCount,
    readable,
    resourcePath,
    resourcePaths,
    selectedAssetKeys,
    selectedViewOnlyAssetKeys,
    shareUrl,
    showMacZipMessage,
    showNotShown,
    galleryView,
    subject,
    totalAssetFriendly,
    totalAssets,
    viewOnly
  } = props;

  const shareManifest = mapShareManifestServerToClient(manifest);
  const { expired, key, message, shareableType } = shareManifest;

  const isAttachmentGroup = shareableType === 'AttachmentGroup' || shareableType === 'ConvertedAttachmentGroup';
  const isPreview = new URLSearchParams(window.location.search).get('preview') === 'true';

  const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');
  const hasViewOnlyAssets = !downloadRequestId && viewOnlyAssetsEnabled && selectedViewOnlyAssetKeys.length > 0;
  const hasOnlyViewOnlyAssets = hasViewOnlyAssets && getArrayIncludesOtherArray(selectedAssetKeys, selectedViewOnlyAssetKeys);
  const hasMixedViewOnlyAssets = hasViewOnlyAssets && getArraysHasIntersection(selectedViewOnlyAssetKeys, selectedAssetKeys);

  return (
    <I18nProviderWrapper>
      <View
        isPreview={isPreview}
        manifest={manifest}
      />
      <div className={classnames(classes.page, 'no-print')}>
        <HeadingWithActions
          customLogoUrl={customLogoUrl}
          editable={editable}
          galleryView={galleryView}
          hasAssets={hasAssets}
          hasMixedViewOnlyAssets={hasMixedViewOnlyAssets}
          hasOnlyViewOnlyAssets={hasOnlyViewOnlyAssets}
          hasViewOnlyAssets={hasViewOnlyAssets}
          isAttachmentGroup={isAttachmentGroup}
          isPreview={isPreview}
          name={name}
          readable={readable}
          resourcePath={resourcePath}
          resourcePaths={resourcePaths}
          selectedAssetKeys={selectedAssetKeys}
          selectedViewOnlyAssetKeys={selectedViewOnlyAssetKeys}
          shareManifest={shareManifest}
          shareUrl={shareUrl}
          subject={subject}
          totalAssetFriendly={totalAssetFriendly}
          totalAssets={totalAssets}
          viewOnly={viewOnly}
        />
        {!!expiresFriendly && (
          <Alert
            id="share-manifest-expiration-alert"
            message={(
              <p>
                {expired
                  ? (
                    <Trans>
                      <strong>Please note:</strong>{' '}
                      This share link expired {expiresFriendly} ago
                    </Trans>
                  ) : (
                    <Trans>
                      <strong>Please note:</strong>{' '}
                      This shareable link will expire in <strong>{expiresFriendly}</strong>
                    </Trans>
                  )
                }
              </p>
            )}
            type="warning"
          />
        )}
        {viewOnly && (
          <Alert
            id="share-manifest-view-only-alert"
            message={(
              <p>
                <Trans>
                  Downloading has been disabled for assets in this Share Link. You will need admin approval for permission to download.
                  {' '}
                  You can request download access by selecting assets and clicking "Request Download".
                </Trans>
              </p>
            )}
            type="warning"
          />
        )}
        {message && (
          <Alert
            id="share-manifest-message-alert"
            message={(
              <p>
                <Trans>
                  <strong>Message from the admin:</strong>{' '}
                  {message}
                </Trans>
              </p>
            )}
            type="comment"
          />
        )}
        {showNotShown && (
          <Alert
            id="share-manifest-not-shown-alert"
            message={(
              <p>
                <Plural
                  one="# asset hidden because it's unpublished, expired, unapproved, or set as view only."
                  other="# assets hidden because they're unpublished, expired, unapproved, or set as view only."
                  value={notShownCount}
                />
              </p>
            )}
            type="warning"
          />
        )}
        {hasAssets && (
          <>
            {showMacZipMessage && (
              <Alert
                id="share-manifest-mac-zip-alert"
                message={(
                  <p>
                    <Trans>
                      <strong>Attention:</strong>&nbsp;
                      The Mac Archive Utility&nbsp;
                      <strong>cannot unzip files larger than 4GB</strong>
                      {'. '}
                      Try using the&nbsp;
                      <a
                        href="https://itunes.apple.com/us/app/the-unarchiver/id425424353?mt=12"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Unarchiver app
                      </a>&nbsp;
                      available in the Mac Store, or a similar alternative.
                    </Trans>
                  </p>
              )}
                type="warning"
              />
            )}
          </>
        )}
        {!hasAssets && hasOnlyViewOnlyAssets && (
          <Alert
            id="share-manifest-no-assets-alert"
            message={(
              <p>
                <Trans>
                  <strong>Download Disabled:</strong>{' '}
                  The ability to download these view only assets has been restricted.
                </Trans>
              </p>
          )}
            type="warning"
          />
        )}
        {!hasAssets && !hasOnlyViewOnlyAssets && (
          <Alert
            id="share-manifest-no-assets-alert"
            message={(
              <p>
                <Trans>
                  <strong>No Assets:</strong>{' '}
                  Assets may have been removed or access to these assets may be restricted.
                </Trans>
              </p>
          )}
            type="warning"
          />
        )}
      </div>
      {isAttachmentGroup && (
        <AttachmentGroup
          assetKeys={attachmentAssetKeys}
          attachmentGroupAttachmentCount={attachmentGroupAttachmentCount}
          attachmentGroupDownloadUrl={attachmentGroupDownloadUrl}
          shareManifestKey={key}
        />
      )}
    </I18nProviderWrapper>
  );
};
