import { t } from '@lingui/macro';

import { TypeOfReportsListObj } from './customReportTypes';

interface Report {
  description: string;
  reportName: string;
  reportUrl: string;
}

const reportsArray = (): Report[] => ([
  {
    description: t`User engagement with assets, including views, downloads and shares.`,
    reportName: t`User Engagement With Assets`,
    reportUrl: 'user-engagement-with-assets'
  },
  {
    description: t`See your top-performing assets including views, shares, and downloads. Provides good indicators of healthy and active assets.`,
    reportName: t`Asset Performance By Brandfolder`,
    reportUrl: 'asset-performance-by-brandfolder'
  },
  {
    description: t`See your most and least active users.`,
    reportName: t`User Activity by Organization`,
    reportUrl: 'user-activity-by-organization'
  },
  {
    description: t`Top-performing Brandfolders and Collections based on all asset activity within them.`,
    reportName: t`Brandfolder and Collection Activity`,
    reportUrl: 'brandfolder-and-collection-activity'
  },
  {
    description: t`See your most and least active share links.`,
    reportName: t`Share Link Activity`,
    reportUrl: 'share-link-activity'
  },
  {
    description: t`Shows when a user was last active in your Brandfolder. Can be used to reallocate your organization’s seats.`,
    reportName: t`User Last Active Date`,
    reportUrl: 'user-last-active-date'
  },
  {
    description: t`Shows what your users are searching for.`,
    reportName: t`Popular Search Terms`,
    reportUrl: 'popular-search-terms'
  },
  {
    description: t`View unique resource page views.`,
    reportName: t`Unique Page Views`,
    reportUrl: 'unique-page-views'
  },
  {
    description: t`View CDN link activity by both hits and bandwidth usage.`,
    reportName: t`CDN Link Activity`,
    reportUrl: 'cdn-link-activity'
  }
]);

const getCorrectReportsArray = (): TypeOfReportsListObj[] => {
  if (BFG.hasFeature('embed_asset')) {
    return reportsArray();
  } else {
    return reportsArray().filter((report) => report.reportUrl !== 'cdn-link-activity');
  }
};

export const reportsList = (): TypeOfReportsListObj[] => getCorrectReportsArray();
