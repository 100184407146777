import {
  AlertLooks,
  BadgePill,
  BrandfolderHexColors,
  FontIcon,
  FontIcons,
  StandardAlert,
} from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import { useDialog } from '@components/common/custom_hooks/useDialog';
import { TextButton } from '@components/library/button';
import { FolderLimitsTable } from '@components/limits/FolderLimitsTable';

import { planLimitsDialogOptions } from '../helpers/limitsDialogOptions';

import { formatToProperCaseType } from './helpers';
import './usage_info_card.scss';
import { InfoCard } from './UsageInfoCard';

enum LimitTypeTitles {
  Administrators = 'admin',
  Collaborators = 'collaborator',
  Storage = 'storage'
}

export interface UsageInfoCardContentProps {
  card: InfoCard;
}

const UsageInfoCardContent: FunctionComponent<InfoCard> = ({
  icon,
  title,
  usage,
  dangerAlert,
  hasSetLimits = false,
  isEnabled,
  link,
  orgKey = BFG.organization_key,
  setLimitsAction,
  subtitle,
  warningAlert,
}) => {
  const { closeDialog, openDialog } = useDialog();

  const infoSectionClasses = classNames({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'usageInfoCard__info': true,
    hasSubtitle: !!subtitle
  });

  const titleCasedTitle = formatToProperCaseType(title);
  const upgradeText = t`Upgrade for ${titleCasedTitle}`;
  const upgradeEmailBody = `I\'d like to inquire about adding more ${title}.`;
  const upgradeEmailLink = `mailto:${BFG.supportEmail}?subject=Upgrade Inquiry&body=${upgradeEmailBody}`;

  const limitIsUnlimited = usage.limit === 'Unlimited' || usage.limit === -1;
  const usageLimit = !!usage.limit ? usage.limit : 0;

  const dangerMessage = dangerAlert && !limitIsUnlimited && (
    <StandardAlert
      icon={FontIcons.Warning}
      look={AlertLooks.Error}
      showHeading={false}
    >
      {dangerAlert}
    </StandardAlert>
  );

  const warningMessage = warningAlert && !dangerAlert && !limitIsUnlimited && (
    <StandardAlert look={AlertLooks.Warning} showHeading={false}>
      {warningAlert}
    </StandardAlert>
  );

  const disabledMessage = !isEnabled && (
    <BadgePill
      backgroundColor={BrandfolderHexColors.Gray}
      className="usageInfoCard__disabled-notification"
      fontSize={16}
    >
      <Trans>Not enabled</Trans>
    </BadgePill>
  );

  const handleCloseDialog = (): void => {
    closeDialog();
    if (setLimitsAction) {
      setLimitsAction();
    }
  };
  const openSetLimitsDialog = (): void => {
    openDialog(
      <FolderLimitsTable limitType={LimitTypeTitles[title]} orgKey={orgKey} setOpen={handleCloseDialog} />,
      planLimitsDialogOptions(LimitTypeTitles[title])
    );
  };

  const usageDisplay = (): ReactNode => {
    if (!isEnabled) {
      return (
        <strong>0</strong>
      );
    }

    if (usage.limit === undefined) {
      return (
        <strong>{usage.current}</strong>
      );
    }

    if (limitIsUnlimited) {
      return (
        <Trans>
          <strong>{usage.current}</strong>&nbsp;
          <span>of Unlimited</span>
        </Trans>
      );
    }

    return (
      <Trans>
        <strong>{usage.current}</strong>&nbsp;
        <span>of {usageLimit}</span>
      </Trans>
    );
  };

  return (
    <>
      <div className="usageInfoCard__top">
        <FontIcon
          aria-hidden
          icon={FontIcons[icon]}
          iconSize={20}
          title={FontIcons[icon]}
        />
        {dangerMessage}
        {warningMessage}
        {disabledMessage}
      </div>
      <div className={infoSectionClasses}>
        {usageDisplay()}
        <h3>{title}</h3>
        {subtitle && <span>{subtitle}</span>}
      </div>
      <div className="usageInfoCard__links">
        {link && isEnabled && <a href={link.linkTo}>{link.name}</a>}
        {!isEnabled && (
          <a href={upgradeEmailLink} target="_blank">
            {upgradeText}
          </a>
        )}
        {hasSetLimits && isEnabled && (
          <TextButton
            aria-label="Set limits"
            onClick={openSetLimitsDialog}
            onKeyDown={openSetLimitsDialog}
          >
            <Trans>Set limits</Trans>
          </TextButton>
        )}
      </div>
    </>
  );
};

export default UsageInfoCardContent;
