import { Plural, Trans } from '@lingui/macro';
import React, { ChangeEvent } from 'react';

import { Portal, ValidationErrors } from '@api/v4/private/PortalTypes';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';
import { TextSanitizationTooltipLabel } from '@components/shared/sanitization';

import { descriptionError, maxDescriptionLength, nameError, slugError } from './validations';

interface PortalFormStage1Props {
  portal: Portal;
  setPortal: SetStateDispatch<Portal>;
  stage: number;
  validationErrors: ValidationErrors[];
}

const PortalFormStage1: React.FunctionComponent<PortalFormStage1Props> = ({
  portal,
  setPortal,
  stage,
  validationErrors,
}) => {
  const nameLength = portal.name ? portal.name.length : 0;
  const descriptionLength = portal.description ? portal.description.length : 0;

  if (stage !== 1) { return null; }

  const invalidSubmission = (validationErrors.length > 0);
  const nameRemainingCharacters = 50 - nameLength;
  const descriptionRemainingCharacters = maxDescriptionLength - descriptionLength;

  return (
    <div>
      <h3 className="portal-form-modal__title"><Trans>General Settings</Trans></h3>
      <div className="portal-modal__character-limit">
        <InputContainer
          attributes={{
            className: 50 - nameLength < 0 ? 'invalid' : '',
            name: 'portal-name',
            onChange: (e: InputChangeEvent): void => {
              const name = e.target.value;
              setPortal((prevState: Portal): Portal => ({ ...prevState, name }));
            },
            type: InputType.Text,
          }}
          input={{ value: portal.name, error: nameError(validationErrors) }}
          labelCopy={<><Trans>Name</Trans>{' '}*</>}
          submitAttempted={invalidSubmission}
        />
        <span className="portal-modal__character-limit-count">
          <Plural
            one={<Trans><span className="countdown">{nameRemainingCharacters}</span>Character</Trans>}
            other={<Trans><span className="countdown">{nameRemainingCharacters}</span>Characters</Trans>}
            value={nameRemainingCharacters}
          />
        </span>
      </div>
      <div className="portal-form-modal__slug">
        <InputContainer
          attributes={{
            name: 'portal-slug',
            onChange: (e: InputChangeEvent): void => {
              const slug = e.target.value;
              setPortal((prevState: Portal): Portal => ({ ...prevState, slug }));
            },
            type: InputType.Text,
          }}
          input={{ value: portal.slug, error: slugError(validationErrors) }}
          labelCopy={<><Trans>Slug</Trans>{' '}*</>}
          submitAttempted={invalidSubmission}
        />
        <span className="slug-example">portals/</span>
      </div>
      <div className="portal-modal__character-limit">
        <InputContainer
          attributes={{
            className: maxDescriptionLength - descriptionLength < 0 ? 'invalid' : '',
            name: 'portal-description',
            onChange: (e: ChangeEvent<HTMLTextAreaElement>): void => {
              const description = e.target.value;
              setPortal((prevState: Portal): Portal => ({ ...prevState, description }));
            },
            type: InputType.Textarea,
          }}
          input={{ value: portal.description, error: descriptionError(validationErrors) }}
          labelCopy={
            <TextSanitizationTooltipLabel>
              <Trans>Description</Trans>
            </TextSanitizationTooltipLabel>
          }
          submitAttempted={invalidSubmission}
        />
        <span className="portal-modal__character-limit-count">
          <Plural
              one={<Trans><span className="countdown">{descriptionRemainingCharacters}</span>Character</Trans>}
              other={<Trans><span className="countdown">{descriptionRemainingCharacters}</span>Characters</Trans>}
              value={descriptionRemainingCharacters}
            />
        </span>
      </div>
    </div>
  );
};

export default PortalFormStage1;
