export enum TableBreakpoints {
  /** 320 */
  'stackXxxxs' = 'stackXxxxs',
  /** 414 */
  'stackXxxs' = 'stackXxxs',
  /** 568 */
  'stackXxs' = 'stackXxs',
  /** 768 */
  'stackXs' = 'stackXs',
  /** 1024 */
  'stackSm' = 'stackSm',
  /** 1280 */
  'stackMd' = 'stackMd',
  /** 1366 */
  'stackLg' = 'stackLg',
  /** 1440 */
  'stackXl' = 'stackXl',
  /** 1600 */
  'stackXxl' = 'stackXxl',
  /** 1800 */
  'stackXxxl' = 'stackXxxl',
  /** 1920 */
  'stackXxxxl' = 'stackXxxxl',
  /** 320 */
  'tableXxxxs' = 'tableXxxxs',
  /** 414 */
  'tableXxxs' = 'tableXxxs',
  /** 568 */
  'tableXxs' = 'tableXxs',
  /** 768 */
  'tableXs' = 'tableXs',
  /** 1024 */
  'tableSm' = 'tableSm',
  /** 1280 */
  'tableMd' = 'tableMd',
  /** 1366 */
  'tableLg' = 'tableLg',
  /** 1440 */
  'tableXl' = 'tableXl',
  /** 1600 */
  'tableXxl' = 'tableXxl',
  /** 1800 */
  'tableXxxl' = 'tableXxxl',
  /** 1920 */
  'tableXxxxl' = 'tableXxxxl'
}
