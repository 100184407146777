import { CanvaListOption, FileTypes, TemplateTypes } from '@components/show_page/sections/asset/canva/CanvaTypes';

const templateFileTypes = {
  [FileTypes.PDF]: [
    TemplateTypes.A4Document,
    TemplateTypes.Announcement,
    TemplateTypes.Brochure,
    TemplateTypes.Card,
    TemplateTypes.Flyer,
    TemplateTypes.GiftCertificate,
    TemplateTypes.Invitation,
    TemplateTypes.Invoice,
    TemplateTypes.Label,
    TemplateTypes.Letter,
    TemplateTypes.Menu,
    TemplateTypes.MindMap,
    TemplateTypes.Newsletter,
    TemplateTypes.Postcard,
    TemplateTypes.Poster,
    TemplateTypes.Presentation,
    TemplateTypes.Presentation43,
    TemplateTypes.RecipeCard,
    TemplateTypes.Resume,
    TemplateTypes.Ticket,
  ]
};

export const getNameFromFilename = (filename: string): string => {
  const extStart = filename.lastIndexOf('.');
  return filename.substring(0, extStart);
};

export const getFilenameFromUrl = (exportUrl: string): string => {
  const filenameIndex = exportUrl.indexOf('filename');
  const responseExpiresIndex = exportUrl.indexOf('&response-expires');
  const filenameComponent = decodeURIComponent(exportUrl.substring(filenameIndex, responseExpiresIndex));
  return decodeURIComponent(filenameComponent.replace("filename*=UTF-8''", ''));
};

export const getTemplateTypeOptions = (query?: string): CanvaListOption[] => {
  const canvaListOptions: CanvaListOption[] = [];

  // object.keys on a typescript enum returns "0" and "A4Document" in an array
  // we just want the numbered index positions (as a string), like "0"
  const indexes = Object.keys((TemplateTypes)).filter((key) => !Number.isNaN(Number(key)));

  indexes.forEach((i) => {
    const index = Number(i);
    const templateType = TemplateTypes[index];

    const isPdf = templateFileTypes[FileTypes.PDF].find((templateFileType) => templateFileType === index);

    const option: CanvaListOption = {
      value: templateType,
      // A4Document => A4 Document
      label: templateType.match(/[A-Z]([0-9]*[a-z]*)+/g)?.join(' '),
      fileType: isPdf !== undefined ? FileTypes.PDF : FileTypes.PNG
    };

    if (!query || (query && (option.label as string).toLowerCase().includes(query.toLowerCase()))) {
      canvaListOptions.push(option);
    }
  });

  return canvaListOptions;
};

export const canvaApiKey = 'rL0oMltMP2ORjYdrCQ-jwz3A';
