// if there is no valid thumbnail url, show message stating as such
import { Trans } from '@lingui/macro';
import * as React from 'react';

import { AssetsResponseObject } from '@api/v4/assets/assetTypes';
import { BoardResponseObject } from '@api/v4/boards/boardsTypes';

// copied from card.jsx
const handleThumbnailError = ({ event, resource }): void => {
  // Hacky, but don't want to implement state unless necessary
  const { extension, name } = resource.attributes;
  const modifiedExtension = extension ? extension.replace('.', '') : '-';
  const source = (modifiedExtension) ? `${modifiedExtension}.svg` : 'file.svg';
  event.target.src = `${BFG.icon_service_url}/assets/types/${source}`;
  event.target.alt = name; // eslint-disable-line no-param-reassign
  event.target.style.cssText = 'padding: 25px;'; // eslint-disable-line no-param-reassign
};

interface AssetBoardCardThumbnailProps {
  resource: BoardResponseObject | AssetsResponseObject;
  setShowOverlay: SetStateDispatch<boolean>;
}

const AssetBoardCardThumbnail: React.FunctionComponent<AssetBoardCardThumbnailProps> = ({
  children,
  resource,
  setShowOverlay
}) => (
  <div
    className="thumbnail-container"
    onMouseEnter={(): void => setShowOverlay(true)}
    onMouseLeave={(): void => setShowOverlay(false)}
  >
    {children}
    {resource.attributes.thumbnail_url ? (
      <div className="thumbnail">
        <img
          onError={(event): void => { handleThumbnailError({ event, resource }); }}
          src={resource.attributes.thumbnail_url}
        />
      </div>
    ) : (
      <div className="no-thumbnail">
        <span>
          <Trans>This board has no assets.</Trans>
        </span>
      </div>
    )}
  </div>
);

export default AssetBoardCardThumbnail;
