import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const SearchPinsAdd = ({ dispatch, updatedFiltersData }) => {
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [uniqueName, setUniqueName] = useState(true);
  const [pinLabel, setPinLabel] = useState('');
  const [pinQuery, setPinQuery] = useState('');

  const handleAddPin = () => {
    const lastPosition = updatedFiltersData.length;
    const key = Math.random().toString(36).substring(2, 15); // temporary key (keys are reset when put into db)
    const data = {
      featured: false,
      key,
      label: pinLabel,
      position: lastPosition,
      query: pinQuery
    };

    dispatch({ data, type: 'add-new-pin' });
    setPinLabel('');
    setPinQuery('');
    setAddButtonDisabled(true);
  };

  const isNameUnique = (newLabel) => {
    let updatedUniqueName = true;
    updatedFiltersData.forEach((filter) => {
      if (newLabel === filter.label) {
        updatedUniqueName = false;
      }
    });
    setUniqueName(updatedUniqueName);
  };

  useEffect(() => {
    if ((pinLabel === '' || pinQuery === '') || !uniqueName) {
      setAddButtonDisabled(true);
    } else {
      setAddButtonDisabled(false);
    }
  }, [pinLabel, pinQuery, uniqueName]);

  return (
    <div className="add-pin-container">
      <div className="input-container">
        <label className="brandfolder-label name-label">
          <Trans>New Pin Name</Trans>
          <input
            className="brandfolder-input t-add-label"
            onChange={(e) => {
              setPinLabel(e.target.value);
              isNameUnique(e.target.value);
            }}
            value={pinLabel}
          />
          <h4 className={classnames("duplicate-name-copy", { "show-duplicate-copy": !uniqueName })}>
            <Trans>Pin Name must be unique!</Trans>
          </h4>
        </label>
        <label className="brandfolder-label">
          <Trans>New Query</Trans>
          <input
            className="brandfolder-input t-add-query"
            onChange={(e) => setPinQuery(e.target.value)}
            value={pinQuery}
          />
        </label>
        <button
          className="button primary sm add-pin-button"
          disabled={addButtonDisabled}
          onClick={handleAddPin}
          type="button"
        >
          <span className="bff-plus icon" />
          <Trans>Add pin</Trans>
        </button>
      </div>
    </div>
  );
};

SearchPinsAdd.propTypes = {
  dispatch: PropTypes.func.isRequired,
  updatedFiltersData: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default SearchPinsAdd;
