/* eslint-disable max-len */
import { Breakpoints, StandardTable, StandardTableRow, useFetch } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { BFLoader } from '@components/common/loader/main';
import { SecondaryButton } from '@components/library/button';

import { ReportData, ReportInfo } from './customReportTypes';
import { ReportsNoResults } from './ReportsNoResults';
import { ReportSidebar } from './ReportsSidebar';

import './styles/reports-index.scss';

const handleSuccess = (): void => {
  Notify.create({
    title: t`Report downloaded`,
    type: 'success'
  });
};

const handleError = (): void => {
  Notify.create({
    title: t`Download failed`,
    type: 'error'
  });
};

export const ReportsIndex: FunctionComponent<ReportInfo> = ({
  customReports,
  orgApiKey,
  organization,
  reportDescription,
  reportTitle,
  reportType
}) => {

  const [reportRows, setReportRows] = useState([]);
  const returnLinkValues = {
    name: t`Reports`,
    slug: `${organization.slug}/standard_reports`
  };

  const reportsData = useFetch<{ data: ReportData[] }>({
    url: `https://brandfolder.com/api/v1/data-insights/standard-reports/org-reports/${organization.key}/organization_level/${reportType}`,
    fetchOnMount: true,
    bearerToken: orgApiKey
  });

  const downloadReportData = ( report ): void => {
    fetch(`https://brandfolder.com/api/v1/data-insights/standard-reports/download-reports/${report.report_prefix}`, {
      headers: {
        Authorization: `Bearer ${orgApiKey}`
      }
    })
      .then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${report.report_name}`;
          if (response.status === 200) {
            a.click();
            handleSuccess();
          } else {
            handleError();
          }
        });
      });
  };

  useEffect(() => {
    if (reportsData.response) {
      const rows: StandardTableRow[] = reportsData.response.data.map((report) => ({
        dateRange: (
          <span key={report.report_date_range} className="reports-index-range">
            {report.report_date_range}
          </span>
        ),
        generatedOn: (
          <span key={report.report_generated_on} className='reports-index-generated'>
            {report.report_generated_on}
          </span>
        ),
        download: (
          <SecondaryButton
          key={report.report_name}
          className='report-download-button'
          icon="bff-download"
          onClick={(): void => {
            downloadReportData(report);
          }}
          >
            <Trans>Download</Trans>
          </SecondaryButton>
        ),
      }));
      setReportRows(rows);
    }
  }, [reportsData.response]);

  if (reportsData.loading) {
    return (
      <div className='reports-home-page'>
        <ReportSidebar
          customReports={customReports}
          organization={organization}
          returnLink={returnLinkValues}
        />
        <div className='reports-index-table-container'>
          <div className="stnd-reports-loading">
            <BFLoader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='reports-home-page'>
      <ReportSidebar
        customReports={customReports}
        organization={organization}
        returnLink={returnLinkValues}
      />
      <div className='reports-index-table-container'>
        <div className='reports-breadcrumb'>
          <span>
            <Trans>
              <a href={`/organizations/${organization.slug}/standard_reports`}>Reports / </a>
              {reportTitle}
            </Trans>
          </span>
        </div>
        <div className='report-table-header'>
          <h2>{reportTitle}</h2>
          <span>{reportDescription}</span>
        </div>
        <StandardTable
          breakpoints={[Breakpoints.Sm]}
          caption={t`Reports`}
          columns={[
            {
              heading: <Trans>Date Range</Trans>,
              children: <Trans>Date Range</Trans>,
              rowKey: 'dateRange',
              width: '30%',
            },
            {
              heading: <Trans>Generated on</Trans>,
              children: <Trans>Generated on</Trans>,
              rowKey: 'generatedOn',
              width: '30%',
            },
            {
              heading: <Trans>DownLoad</Trans>,
              children: '',
              rowKey: 'download',
              width: '30%',
            },
          ]}
          emptyContent={<ReportsNoResults />}
          errorContent={<Trans>An error occurred</Trans>}
          footer={false}
          hover
          id="bf-limits-standard-table"
          rows={reportRows}
          scrollX
        />
        </div>
    </div>
  );
};
