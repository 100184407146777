import { Trans } from '@lingui/macro';
import React from 'react';

import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { LanguageMapEntry, findCurrentLanguageInMap } from '@components/common/language_menu/languagesMap';

import './styles/_ugt_disclaimer.scss';

interface UGTDisclaimerProps {
  getSelectedLanguage?: () => LanguageMapEntry;
  selectedLanguage?: LanguageMapEntry;
}

const UGTDisclaimer = ({ getSelectedLanguage, selectedLanguage }: UGTDisclaimerProps): JSX.Element | null => {
  if (!BFG.multiLanguageAssetDetailsEnabled) {
    return null;
  }

  const currentUgtLocale = selectedLanguage
    || (getSelectedLanguage && getSelectedLanguage())
    || findCurrentLanguageInMap(determineUGTLocale());
  if (!currentUgtLocale || currentUgtLocale.locale === BFG.locales.ugtLocaleDefault) {
    return null;
  }

  return (
    <div className="ugt-disclaimer">
      <span className="bff-warning ugt-disclaimer__icon" />
      <p className="ugt-disclaimer__copy">
        <Trans>
          You are now in edit mode for the language:&nbsp;
          <span className="bold-font">{currentUgtLocale.language}</span>.&nbsp;
          Edits made will only display for users who select&nbsp;
          <span className="bold-font">{currentUgtLocale.language}</span>&nbsp;
          as their language from the asset info language selector in the View Options.
        </Trans>
      </p>
    </div>
  );
};

export default UGTDisclaimer;
