import { plural, t, Plural, Trans } from '@lingui/macro';
import * as React from 'react';

import { addToLabel } from '@api/v4/bulk_actions/assets';
import { LabelTree as LabelTreeType } from '@api/v4/resources/types/labelsTypes';
import { promiseReflect, ReflectInterface, PromiseStatus } from '@api/v4/utils';

import LabelTree from '@components/show_page/bulk_actions/sub_components/LabelTree';

import renderModal from '../modals/renderModal';

import './styles/BulkAddLabelModal.scss';

// ensure all requests complete and collect any errors or failures
const addToLabelHandler = async ({
  selectedLabels,
  selectedAssetKeys,
  closeModal
}): Promise<void> => {
  const labelCount = selectedLabels.length;

  try {
    const promiseArray = selectedLabels.map((label: string) => promiseReflect(addToLabel(Array.from(selectedAssetKeys), label)));
    // will not throw if any promises fails, we will filter below
    const results = await Promise.all(promiseArray);

    if (results.some((result: ReflectInterface) => result.status === PromiseStatus.Rejected)) {
      throw new Error('Add to Label Failure');
    }

    const title = plural(labelCount, {
      one: 'Assets added successfully to label.',
      other: 'Assets added successfully to labels.'
    });
    Notify.create({ title, type: 'success' });
  } catch (error) {
    Notify.create({ title: t`Something went wrong. Try again soon!`, type: 'error' });
  } finally {
    closeModal();
  }
};

interface BulkLabelModalProps {
  labelTree: LabelTreeType;
  selectedAssetKeys: Set<string>;
  closeModal: () => void;
}

const BulkAddLabelModal: React.FunctionComponent<BulkLabelModalProps> = ({
  selectedAssetKeys,
  closeModal,
  labelTree
}): JSX.Element => {
  const [selectedLabels, setSelectedLabels] = React.useState<string[]>([]);
  const assetCount = selectedAssetKeys.size;

  React.useEffect(() => closeModal, [closeModal]);

  const modalButtonRow = (): JSX.Element => (
    <div className="button-container">
      <button
        className="button primary sm t-confirm-modal"
        disabled={selectedLabels.length === 0}
        onClick={(): void => { addToLabelHandler({ selectedLabels, selectedAssetKeys, closeModal }); }}
        type="button"
      >
        <Trans>Save</Trans>
      </button>
    </div>
  );

  return (
    <div className="modal-content-wrapper bulk-label-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-label icon" />
        <h3 className="modal-title"><Trans>Add to your Labels</Trans></h3>
        <button
          className="close-button"
          onClick={closeModal}
          type="button"
        >
          <span className="bff-close" />
        </button>
      </div>
      <div className="modal-content-wrapper__body">
        <h3 className="body-title">
          {/* TODO: don't rely on CSS for spacing, use a nbsp; so screenreaders read it as separate words */}
          <Plural
            one={
              <Trans>
                Add
                <span className="bold-text">
                  {assetCount} asset
                </span>
                to one or more Labels
              </Trans>
            }
            other={
              <Trans>
                Add
                <span className="bold-text">
                  {assetCount} assets
                </span>
                to one or more Labels
              </Trans>
            }
            value={assetCount}
          />
        </h3>
        <div className="modal-row">
          <LabelTree
            labelTree={labelTree}
            selectedLabels={selectedLabels}
            setSelectedLabels={setSelectedLabels}
          />
        </div>
        <div>
          {modalButtonRow()}
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(BulkAddLabelModal, 'BulkAddLabelModal');
export default ModalComponent;
