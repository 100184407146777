import { t } from '@lingui/macro';
import React, { FunctionComponent, MutableRefObject } from 'react';

import { CustomFieldValue } from '@api/v4/assets/customFieldTypes';
import { CustomFieldKeyValuesEntry } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { ListDropdown, ListOption } from '@components/library/dropdown/index';

interface SingleValueRestrictedProps {
  currValues: ListOption[];
  existingCustomFieldMap: CustomFieldKeyValuesEntry;
  handleUpdate: (customFieldValue: CustomFieldValue, customFieldRequired?: boolean) => void;
  options: ListOption[];
  required: boolean;
  overflowParentRef?: MutableRefObject<HTMLElement>;
}

export const SingleValueRestricted: FunctionComponent<SingleValueRestrictedProps> = ({
  currValues,
  existingCustomFieldMap,
  handleUpdate,
  options,
  overflowParentRef,
  required
}) => {
  const value = currValues && currValues.length > 0 ? currValues[0] : undefined;
  const touched = existingCustomFieldMap?.customFieldTouched;
  const showRequired = required && touched && !!value === false;

  return (
    <ListDropdown
      error={showRequired && t`Value required`}
      onChange={(listOption: ListOption): void => {
        let customFieldValueId = '';
        if (existingCustomFieldMap?.customFieldValues.length > 0) {
          customFieldValueId = existingCustomFieldMap?.customFieldValues[0].key;
        }
        handleUpdate({ key: customFieldValueId, value: listOption.value as string }, required);
      }}
      options={options}
      overflowParentRef={overflowParentRef}
      placeholder={t`Select value`}
      searchable
      value={value}
    />
  );
};
