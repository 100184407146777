import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import {
  getPortalCard,
  createPortalCard,
  updatePortalCard,
  mapBackendPortalCardToPortalCard
} from '@api/v4/private/portal_cards';
import {
  PortalCardTypes,
  DisplayWidths,
  ValidationErrorsEnum,
  PortalCardSubTypes,
  PortalCard
} from '@api/v4/private/PortalCardTypes';
import { BFLoader } from '@components/common/loader/main';
import RadioButton from '@components/common/radio_button/main';
import { CloseButton } from '@components/library/button/index';
import { ListDropdown, ListOption } from '@components/library/dropdown';
import { handleDeletion } from '@components/portals/helpers/card_deletion_helper';
import { EmbeddedWidgetsForm } from '@components/shared/embedded_widgets';
import renderModal from '@components/show_page/modals/renderModal';

import { handleValidations } from '../portal_cards/validations';

import { TileNavigationForm } from './tile_navigaton_form';

interface TileFormProps {
  closeModal: () => void;
  triggerCardsRefresh: () => void;
  portalCardId?: string;
}

const defaultCard: PortalCard = {
  buttonText: '',
  displayWidth: DisplayWidths.OneThird,
  description: '',
  imageUrl: null,
  linkUrl: null,
  name: '',
  srcUrl: null,
  subType: PortalCardSubTypes.NavigationTile,
  type: PortalCardTypes.PortalTile
};

const TileForm: FunctionComponent<TileFormProps> = ({ closeModal, portalCardId, triggerCardsRefresh }) => {
  const type = PortalCardTypes.PortalTile;
  const subTypes = [
    PortalCardSubTypes.NavigationTile,
    PortalCardSubTypes.EmbeddedFormTile
  ];
  const subTypeMap = {
    [PortalCardSubTypes.NavigationTile]: t`Navigation Tile`,
    [PortalCardSubTypes.EmbeddedFormTile]: t`Smartsheet Form`
  };

  const [card, setCard] = useState(defaultCard);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsEnum[]>([]);

  const portalId = (BFG.resource.type === 'portal') ? BFG.resource.key : null;
  const isValid = validationErrors.length === 0;

  useEffect(() => {
    if (portalCardId) {
      const loadPortalCard = async (): Promise<void> => {
        try {
          setLoading(true);
          const response = await getPortalCard({ portalCardId });
          const { attributes } = response.data;
          setCard(mapBackendPortalCardToPortalCard(attributes));
        } catch (err) {
          Notify.create({ title: t`Something went wrong, could not load tile.`, type: 'error' });
        } finally {
          setLoading(false);
        }
      };
      loadPortalCard();
    }
  }, [portalCardId]);

  // NOTE: this is a minor hack to fix card resetting after an image upload
  useEffect(() => {
    setCard({ ...card, imageUrl });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  const submitCreateCard = async (): Promise<void> => {
    if (portalId) {
      if (handleValidations(type, setValidationErrors, card, card.subType)) return;

      try {
        setSaving(true);
        await createPortalCard(portalId, card);
        setCard(defaultCard);
        setImageUrl(null);
        triggerCardsRefresh();
        closeModal();
      } catch (err) {
        Notify.create({ title: t`Something went wrong, tile creation failed.`, type: 'error' });
      } finally {
        setSaving(false);
      }
    }
  };

  const submitUpdateCard = async (): Promise<void> => {
    if (portalCardId) {
      if (handleValidations(type, setValidationErrors, card, card.subType)) return;

      try {
        setSaving(true);
        await updatePortalCard(portalCardId, card);
        setCard(defaultCard);
        setImageUrl(null);
        triggerCardsRefresh();
        closeModal();
      } catch (err) {
        Notify.create({ title: t`'Something went wrong, tile update failed.`, type: 'error' });
      } finally {
        setSaving(false);
      }
    }
  };

  const subTypeSelectionOptions: ListOption[] = subTypes.map((tileSubType) => (
    { isDisabled: portalCardId !== undefined, label: subTypeMap[tileSubType], value: tileSubType }
  ));

  return (
    <div className="modal-content-wrapper portal-modal tile-form">
      <div className="portal-modal__header">
        {portalCardId ? (
          <span aria-hidden="true" className="bff-edit" />
        ) : (
          <span aria-hidden="true" className="bff-plus" />
        )}
        <h3 className="modal-title">
          {portalCardId ? <Trans>Edit Tile</Trans> : <Trans>Create Tile</Trans>}
        </h3>
        <CloseButton onClick={closeModal} />
      </div>
      <div className="portal-modal__body">
        {loading ? <BFLoader /> : (
          <>
            <div className="tile-form__grid-selection radio-button-container">
              <div>
                <img
                  alt="one third tile grid"
                  className="tile-form__grid-selection--image"
                  src="https://cdn.brandfolder.io/27C9EC93/at/q6y1hw-46gjdc-928tl1/one-third-tile.auto"
                />
                <RadioButton
                  checkedButton={card.displayWidth}
                  labelCopy={t`1/3 tile`}
                  name="one-third-grid"
                  onChangeFunction={(): void => setCard({ ...card, displayWidth: DisplayWidths.OneThird, imageUrl: '' })}
                  valueCopy={DisplayWidths.OneThird}
                />
              </div>
              <div>
                <img
                  alt="two thirds tile grid"
                  className="tile-form__grid-selection--image"
                  src="https://cdn.brandfolder.io/27C9EC93/at/q6y1im-bd2kmw-7r2v38/two-thirds-tile.auto"
                />
                <RadioButton
                  checkedButton={card.displayWidth}
                  labelCopy={t`2/3 tile`}
                  name="two-thirds-grid"
                  onChangeFunction={(): void => setCard({ ...card, displayWidth: DisplayWidths.TwoThirds, imageUrl: '' })}
                  valueCopy={DisplayWidths.TwoThirds}
                />
              </div>
              <div>
                <img
                  alt="full width tile grid"
                  className="tile-form__grid-selection--image"
                  src="https://cdn.brandfolder.io/27C9EC93/at/q6y1jn-7xcm1k-2t5gh7/full-width-tile.auto"
                />
                <RadioButton
                  checkedButton={card.displayWidth}
                  labelCopy={t`Full-width tile`}
                  name="full-width-grid"
                  onChangeFunction={(): void => setCard({ ...card, displayWidth: DisplayWidths.Full, imageUrl: '' })}
                  valueCopy={DisplayWidths.Full}
                />
              </div>
            </div>
            <div className="tile-form__subtype">
              <label className="brandfolder-label" htmlFor="tile-type">
                <Trans>Tile Type</Trans> *
              </label>
              <ListDropdown
                onChange={(value): void => setCard({ ...card, subType: value.value as PortalCardSubTypes })}
                openOnClick
                openOnHover={false}
                options={subTypeSelectionOptions}
                placeholder={t`Select...`}
                value={card.subType === null ? PortalCardSubTypes.NavigationTile : card.subType}
              />
            </div>
            {/* Navigation tiles are currently saved as null for sub_type in postgres */}
            {(card.subType === PortalCardSubTypes.NavigationTile || card.subType === null) ? (
              <TileNavigationForm
                card={card}
                closeModal={closeModal}
                isValid={isValid}
                portalCardId={portalCardId}
                saving={saving}
                setCard={setCard}
                setImageUrl={setImageUrl}
                submitCreateCard={submitCreateCard}
                submitUpdateCard={submitUpdateCard}
                triggerCardsRefresh={triggerCardsRefresh}
                validationErrors={validationErrors}
              />
            ) : (
              <EmbeddedWidgetsForm
                buttonText={card.buttonText || ''}
                closeModal={closeModal}
                description={card.description || ''}
                displayWidth={card.displayWidth}
                handleDeletion={handleDeletion}
                id={portalCardId}
                isValid={isValid}
                name={card.name || ''}
                saving={saving}
                setButtonText={(buttonText: string): void => { setCard({ ...card, buttonText }); }}
                setDescription={(description: string): void => { setCard({ ...card, description }); }}
                  // setHeight={(height: string): void => { setCard({ ...card, height: Number(height) }); }}
                setName={(name: string): void => { setCard({ ...card, name }); }}
                setSrcUrl={(srcUrl: string): void => { setCard({ ...card, srcUrl }); }}
                  // setWidth={(width: string): void => { setCard({ ...card, width: Number(width) }); }}
                showButtons
                showCharacterLimit
                srcUrl={card.srcUrl || ''}
                submitCreate={submitCreateCard}
                submitUpdate={submitUpdateCard}
                triggerRefresh={triggerCardsRefresh}
                type="Tile"
                validationErrors={validationErrors}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ModalComponent = renderModal(TileForm, 'TileForm');
export default ModalComponent;
