import { t, Trans } from '@lingui/macro';
import React, { useContext, useEffect, FunctionComponent } from 'react';

import { deleteBoard } from '@api/v4/boards';
import { BoardResponseObject } from '@api/v4/boards/boardsTypes';
import { AllBoardsViewContext, AllBoardsContextTypes } from '@components/boards/all_boards_view';
import { CloseButton, DangerButton, TertiaryButton } from '@components/library/button/index';
import renderModal from '@components/show_page/modals/renderModal';

// reflect success or error message
// TODO add notify
interface DeleteBoardHandlerParams {
  boardId: string;
  boards: BoardResponseObject[];
  setBoards: AllBoardsContextTypes['setBoards'];
  closeModal: () => void;
}
const deleteBoardHandler = async (options: DeleteBoardHandlerParams): Promise<void> => {
  const { boardId, boards, setBoards, closeModal } = options;
  try {
    // reponse is created board
    await deleteBoard({ boardId });
    // remove deleted board, and update boards state
    const trimmedBoards = boards.filter((board) => board.id !== boardId);
    Notify.create({ title: t`Board successfully deleted`, type: 'success' });
    setBoards(trimmedBoards);
  } catch (error) {
    console.log(error);
    Notify.create({ title: t`Something went wrong, could not delete board.`, type: 'error' });
    closeModal();
  }
};

interface DeleteBoardModalProps {
  boardName: string;
  boardId: string;
  closeModal: () => void;
}
const DeleteBoardModal: FunctionComponent<DeleteBoardModalProps> = (options) => {
  const { boardName, boardId, closeModal } = options;
  const AllBoardsViewState = useContext(AllBoardsViewContext);
  useEffect(() => (closeModal), []); // fire closeModal when unmounting

  return (
    <div className="modal-content-wrapper delete-board-modal">
      <div className="modal-content-wrapper__header">
        <h3 className="modal-title">
          <span aria-hidden="true" className="bff-trash icon" />
          <Trans>Delete "{boardName}" Board</Trans>
        </h3>
        <CloseButton onClick={closeModal} />
      </div>
      <div className="modal-content-wrapper__body">
        <p>
          {/* TODO: whitelabel */}
          <Trans>This action will not delete assets from your Brandfolders.</Trans>
        </p>
        <div className="button-container">
          <TertiaryButton
            className="t-close-modal"
            onClick={closeModal}
            size="small"
          >
            <Trans>Cancel</Trans>
          </TertiaryButton>
          <DangerButton
            className="t-confirm-modal"
            onClick={(): void => {
              deleteBoardHandler({
                boardId,
                boards: AllBoardsViewState.boards,
                setBoards: AllBoardsViewState.setBoards,
                closeModal
              });
            }}
            size="small"
          >
            <Trans>Delete</Trans>
          </DangerButton>
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(DeleteBoardModal, 'DeleteBoardModal');
export default ModalComponent;
