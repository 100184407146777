import { ResourceTypes } from '@api/v4/resources/resourceTypes';

import {
  FlattenedApiData,
  LimitsApiDataResponse,
  LimitTypes,
} from '../customLimitsTypes';

const getLimitTypeUsage = (data: any, limitType: LimitTypes): number => {
  if (limitType === 'storage' && data.attributes.storage) {
    const storageValueUnit = data.attributes.storage.split(' ')[1];
    let storageInGB = Number(data.attributes.storage.split(' ')[0]);

    if (storageValueUnit === 'MB') {
      storageInGB = storageInGB / 1000;
    }
    return storageInGB;
  }
  if (data.attributes?.current_usage)
    return data.attributes?.current_usage[limitType];
  else return 0;
};

export const flattenLimitsData = (
  limitsResponse: LimitsApiDataResponse,
  resourceResponse: LimitsApiDataResponse,
  limitType: LimitTypes
): FlattenedApiData[] => {
  const flattenedLimitsDataAr: FlattenedApiData[] = [];
  const flattenedResourceDataAr: FlattenedApiData[] = [];

  if (limitsResponse.included) {
    limitsResponse.data.forEach((data) => {
      const resourceId = data.relationships.resource.data.id;

      /* matches the limit value to the correct resource
       in the {relationships} object being returned in the response */
      const includedResource = limitsResponse.included.find((obj) => {
        return obj.id === resourceId;
      });
      const limitsObject: FlattenedApiData = includedResource && {
        resourceName: includedResource?.attributes.name,
        resourceId: includedResource.id,
        currentLimit: data.attributes.value,
        currentUsage: data.attributes.current_usage,
        hasLimits: true,
        limitId: data.id,
        startingLimit: data.attributes.value,
        resourceType: data.relationships.resource.data.type,
      };

      flattenedLimitsDataAr.push(limitsObject);
    });
  }

  if (resourceResponse) {
    resourceResponse.data.forEach((data) => {
      const resourceObject: FlattenedApiData = {
        resourceName: data.attributes.name,
        resourceId: data.id,
        currentLimit: 0,
        currentUsage: getLimitTypeUsage(data, limitType),
        hasLimits: false,
        limitId: '',
        resourceType: data.type,
        startingLimit: 0,
      };
      flattenedResourceDataAr.push(resourceObject);
    });
  }

  const limitsResourceIds = flattenedLimitsDataAr.map((a) => a.resourceId);

  /* compare the limits response array against all resources on an org
   to determine which ones do not have a limit value set */
  const bfDataArr = flattenedResourceDataAr.filter(
    (b) => !limitsResourceIds.includes(b.resourceId)
  );

  const sortedAlphabeticallyByType = [
    ...bfDataArr,
    ...flattenedLimitsDataAr,
  ].sort(
    (a, b) =>
      a.resourceType.localeCompare(b.resourceType) ||
      a.resourceName.localeCompare(b.resourceName)
  );

  const sortedWithOrgFirst = sortedAlphabeticallyByType.sort(
    // move the organization folder to the front of the array
    (a, b) =>
      Number(b.resourceType === ResourceTypes.Organizations) -
      Number(a.resourceType === ResourceTypes.Organizations)
  );

  return sortedWithOrgFirst;
};

export const organizeNoLimitsFolderData = (
  noOrgLimitsData: boolean,
  brandfolderData: LimitsApiDataResponse,
  orgData?: any,
  portalsData?: LimitsApiDataResponse,
  brandguidesData?: LimitsApiDataResponse
): LimitsApiDataResponse => {
  if (noOrgLimitsData) {
    return {
      ...brandfolderData,
      data: [
        orgData?.data,
        ...(brandfolderData?.data || []),
        ...(portalsData?.data || []),
        ...(brandguidesData?.data || []),
      ],
    };
  } else
    return {
      ...brandfolderData,
      data: [
        ...(brandfolderData?.data || []),
        ...(portalsData?.data || []),
        ...(brandguidesData?.data || []),
      ],
    };
};
export const organizeLimitsFolderData = (
  orgLimitsFetch: LimitsApiDataResponse,
  bfLimitsFetch: LimitsApiDataResponse,
  portalLimitsFetch?: LimitsApiDataResponse,
  brandguideLimitsFetch?: LimitsApiDataResponse
): LimitsApiDataResponse => {
  return {
    ...orgLimitsFetch,
    data: [
      ...orgLimitsFetch?.data,
      ...bfLimitsFetch?.data,
      ...portalLimitsFetch?.data,
      ...brandguideLimitsFetch?.data,
    ],
    included: [
      ...(orgLimitsFetch?.included || []),
      ...(bfLimitsFetch?.included || []),
      ...(portalLimitsFetch?.included || []),
      ...(brandguideLimitsFetch?.included || []),
    ],
  };
};
