import { Trans } from '@lingui/macro';
import PropTypes            from 'prop-types';
import React, { Component } from 'react';

export default class MessageUsersStage2 extends Component {
  render() {
    const {
      cancelMessage,
      libraryName,
      updateMessageState,
      submitted,
      message
    } = this.props;

    if (this.props.stage !== 2) { return null; }

    return (
      <React.Fragment>
        <div className="section-title-container">
          <h3 className="section-title compose-message-title"><Trans>Compose Message</Trans></h3>
          <span className="compose-message">
            {`Users will be notified by email and in their ${libraryName} notifications`}
          </span>
        </div>
        <div className="section-body body-write-message">
          <div className="message-container">
            <textarea
              className="textareas"
              cols={100}
              id="compose-message"
              onChange={(e) => updateMessageState({ message: e.target.value })}
              value={message}
            />
          </div>
        </div>
        <div className="button-container">
          <button
            className="button tertiary sm"
            onClick={() => cancelMessage()}
            type="button"
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            className="button primary lg"
            disabled={submitted || !message}
            onClick={() => updateMessageState({ stage: 3 })}
            type="button"
          >
            <Trans>Review</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

MessageUsersStage2.defaultProps = {
  libraryName: "Brandfolder"
};

MessageUsersStage2.propTypes = {
  libraryName: PropTypes.string,
  cancelMessage: PropTypes.func.isRequired,
  libraryName: PropTypes.string,
  stage: PropTypes.number.isRequired,
  submitted: PropTypes.bool.isRequired,
  updateMessageState: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
};
