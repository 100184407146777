import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import Approval from '@components/asset/modal/tabs/workflow/asset_details/approval';
import Availability from '@components/asset/modal/tabs/workflow/asset_details/availability';
import Status from '@components/asset/modal/tabs/workflow/asset_details/status';

interface WorkflowAssetDetailsAsset {
  id : string;
  attributes: {
    activeCdnTraces: number;
    approved: boolean;
    availability: string;
    /* eslint-disable @typescript-eslint/naming-convention */
    availability_end: string;
    availability_start: string;
    /* eslint-enable @typescript-eslint/naming-convention */
  };
}

export interface WorkflowAssetDetailsProps {
  approver: boolean;
  asset: WorkflowAssetDetailsAsset;
  displayAssetStatus: boolean;
  refreshAsset: () => void;
  setAsset: SetStateDispatch<WorkflowAssetDetailsAsset>;
  updateFetchControllers: (fetchController: AbortController) => void;
  showTitle?: boolean;
}

export const WorkflowAssetDetails: FunctionComponent<WorkflowAssetDetailsProps> = ({
  approver,
  asset,
  displayAssetStatus,
  refreshAsset,
  setAsset,
  showTitle = false,
  updateFetchControllers
}) => {
  return (
    <section className="asset-details bf-scroll-element">
      {showTitle && (
        <h3 className="availability-row body-title"><Trans>Asset Availability</Trans></h3>
      )}
      {displayAssetStatus && (
        <Status
          asset={asset}
        />
      )}
      {(BFG.hasFeature('asset_approvals') && approver) && (
        <Approval
          asset={asset}
          setAsset={setAsset}
          updateFetchControllers={updateFetchControllers}
        />
      )}
      {(BFG.hasFeature('asset_availability') && (BFG.resource && BFG.resource.type !== 'collection')) && (
        <Availability
          asset={asset}
          refreshAsset={refreshAsset}
        />
      )}
    </section>
  );
};
