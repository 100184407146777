import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { destroy, run } from '@api/v4/metadata_conversions';

const Row = ({
  id,
  metaTerm,
  keyTerm,
  convertToTags,
  setEditableKey,
  setShowForm,
  onRemove,
}) => {
  const [deleteMode, setDeleteMode] = useState(false);
  const [disableApply, setDisableApply] = useState(false);

  const edit = () => {
    setEditableKey(id);
    setShowForm(true);
  };

  const remove = async () => {
    await destroy(id);
    onRemove();
    Notify.create({ type: "success", title: "Conversion deleted" });
  };

  const runOnExistingFiles = async () => {
    setDisableApply(true);
    await run(id);
    Notify.create({ type: "success", title: "In Progress" });
  };

  const renderRow = () => (
    <li className="row limited-height">
      <span>{metaTerm}</span>
      <span>{convertToTags ? <Trans>Tags</Trans> : <Trans>Custom Field: {keyTerm}</Trans>}</span>
      <span>
        {(BFG.currentUser?.su && BFG.currentUser.scope !== 'getty') && (
          <button
            className="button secondary sm"
            disabled={disableApply}
            onClick={runOnExistingFiles}
            type="button"
          >
            <span className="bff-refresh" />
            <Trans>Apply</Trans>
          </button>
        )}
      </span>
      <span className="button-container">
        <button
          onClick={edit}
          type="button"
        >
          <span className="bff-edit" />
        </button>
        <button
          onClick={() => setDeleteMode(true)}
          type="button"
        >
          <span className="bff-trash" />
        </button>
      </span>
    </li>
  );

  const renderDeleteMode = () => (
    <div className="delete-mode">
      <span className="delete-text">
        <Trans>Are you sure you want to permanently delete {metaTerm}?</Trans>
      </span>
      <div className="delete-buttons">
        <button
          className="button tertiary md cancel"
          onClick={() => setDeleteMode(false)}
          type="button"
        >
          <Trans>Cancel</Trans>
        </button>
        <button
          className="button warning md"
          onClick={remove}
          type="button"
        >
          <Trans>Delete Conversion</Trans>
        </button>
      </div>
    </div>
  );

  return deleteMode ? renderDeleteMode() : renderRow();
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  metaTerm: PropTypes.string.isRequired,
  keyTerm: PropTypes.string,
  convertToTags: PropTypes.bool,
  onRemove: PropTypes.func,
  setEditableKey: PropTypes.func.isRequired,
  setShowForm: PropTypes.func.isRequired
};

Row.defaultProps = {
  createdAt: null,
  keyTerm: null,
  onRemove: () => {},
};

export default Row;
