export enum Views {
  Create = 'create',
  Details = 'details',
  Home = 'home',
}

export enum EditOrCreate {
  Copy = 'copy', // UI experience like edit but action will be create
  Create = 'create',
  Edit = 'edit',
}

export enum SelectorTypes {
  Collection = 'Collection',
  CustomField = 'CustomField',
  Label = 'Label',
  // NameContains = 'NameContains',
  Section = 'Section',
  Tag = 'Tag',
  Watermark = 'Watermark'
}

export enum Fields {
  Actions = 'actions',
  ActionableId = 'actionableId',
  ActionableKeys = 'actionableKeys',
  ActionableType = 'actionableType',
  ActionableOptions = 'actionableOptions',
  ActionableValues = 'actionableValues',
  Name = 'name',
  TriggerType = 'triggerType',
  TriggerKeys = 'triggerKeys',
  Values = 'values'
}

export enum NameDisplayTypes {
  ListDropdown = 'ListDropdown',
  MultiselectDropdown = 'MultiselectDropdown',
  PillSelector = 'PillSelector',
  Textfield = 'Textfield',
  Watermark = 'Watermark'
}
