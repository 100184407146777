interface KnowledgeBaseLinks {
  knowledgeBaseHomePageLink: string,
  historyLogLink: string,
  userPermissionsLink: string,
  userGroupsLink: string,
  dependentCustomFieldsLink: string
  textSanitizationLink: string
  assetAutomationsLink: string
}

export const gettyKnowledgeBaseLinks: KnowledgeBaseLinks = {
  knowledgeBaseHomePageLink: 'https://damhelp.gettyimages.com',
  historyLogLink: 'https://damhelp.gettyimages.com/hc/en-us/articles/16967931828109-Asset-History-Log',
  userPermissionsLink: 'https://damhelp.gettyimages.com/hc/en-us/articles/13745771466509-User-Permissions',
  userGroupsLink: 'https://damhelp.gettyimages.com/hc/en-us/articles/13680241666957-User-Groups',
  dependentCustomFieldsLink: 'https://damhelp.gettyimages.com/hc/en-us/articles/13745771466509-User-Permissions',
  textSanitizationLink: 'https://damhelp.gettyimages.com/hc/en-us/articles/22228442084109-Text-Sanitization',
  assetAutomationsLink: ' https://damhelp.gettyimages.com/hc/en-us/articles/22229106595341-Bulk-Management-Asset-Automation'
}

export const defaultKnowledgeBaseLinks: KnowledgeBaseLinks = {
  knowledgeBaseHomePageLink: 'https://help.smartsheet.com/brandfolder',
  historyLogLink: 'https://help.smartsheet.com/13664386046487-Asset-History-Log',
  userPermissionsLink: 'https://help.smartsheet.com/115002602273-User-Permissions',
  userGroupsLink: 'https://help.smartsheet.com/9199616080279-User-Groups-',
  dependentCustomFieldsLink: 'https://help.smartsheet.com/13735397807895-Dependent-custom-fields',
  textSanitizationLink: 'https://help.smartsheet.com/14345859619095-Text-Sanitization',
  assetAutomationsLink: 'https://help.smartsheet.com/articles/2482950-Bulk-Management-Asset-Automation'
}