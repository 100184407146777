import { t, plural, Trans, Plural } from '@lingui/macro';
import { decode } from 'html-entities';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { fetchInBatches, fetchJson } from '@api/ApiHelper';
import { createAssetShareInsightsEvent } from '@api/insights/v1/events';
import { create as createLink } from '@api/v4/resources/share_manifests';
import {
  destroy as deleteLink,
  get as getLink,
  update as updateLink,
} from '@api/v4/share_manifests';
import ClipboardCopy from '@components/common/clipboard_copy/main';
import useDebounce from '@components/common/custom_hooks/useDebounce';
import { BFLoader } from '@components/common/loader/main';
import DateTimePicker from '@components/library/date_time_picker/BaseDateTimePicker';
import { StandardTextField } from '@components/library/text-field';
import ToggleContainer from '@components/show_page/bulk_actions/sub_components/bulk_share_toggle';
import { CustomLogoUpload } from '@components/show_page/bulk_actions/sub_components/CustomLogoUpload';
import renderModal from '@components/show_page/modals/renderModal';

import {
  getStorage,
  setStorage,
  SHARE_LINK_NOTIFICATIONS_PREFERENCE_KEY,
  StorageTypes
} from '@helpers/storage';
import { getCurrentUserIsAnonymous } from '@helpers/user';

const getInitialState = () => {
  const notificationsOptin = getStorage(StorageTypes.Local, SHARE_LINK_NOTIFICATIONS_PREFERENCE_KEY);
  return {
    // settings, user cannot change
    key: null, // share manifest key
    restrict_shares_setting: false, // BF setting, enforced before_save
    require_share_identification_setting: false, // BF setting, enforced before_save
    link: null,
    // boolean params, user can change, update immediately
    expires: false,
    internal: false, // user can update in bulk share modal
    require_identification: false,
    view_only: !!BFG.viewOnlyCollection, // not user/context specific, indicates column on Collection
    // params, user can change, update on clicking on save
    availability_end: null,
    name: '',
    notifications_optin: notificationsOptin === true || false,
    gallery_view: false,
    custom_logo_url: null
  };
};

const paramNotificationCopyMap = {
  internal: t`Privacy update`,
  require_identification: t`Email requirement updated`,
  view_only: t`View only mode updated`,
  // expires: bfTranslate('expiration updated'), notification for avialabilty_end only- these update together
  name: t`Share Link name updated`,
  availability_end: t`Expiration updated`,
  'reset-state': t`Fields restored`,
  notifications_optin: t`Notifications setting updated`,
  gallery_view: t`Gallery View setting updated. Please refresh to view changes.`,
  custom_logo_url: t`Custom Logo updated. Please refresh to view changes.`
};

const reducer = (state = getInitialState(), { payload, type }) => {
  switch (type) {
    case 'reset-state': {
      return {
        ...state,
        ...payload,
      };
    }
    case 'internal':
    case 'require_identification':
    case 'view_only':
    case 'expires': {
      const togglingInternalToFalse = (type === 'internal' && state.internal);
      const togglingExpiresToFalse = (type === 'expires' && state.expires);
      return {
        ...state,
        ...{ [type]: !state[type] },
        ...togglingInternalToFalse && { require_identification: false },
        ...togglingExpiresToFalse && { availability_end: null },
      };
    }
    case 'name':
    case 'availability_end': {
      return {
        ...state,
        ...{ [type]: payload },
      };
    }
    case 'notifications_optin': {
      return {
        ...state,
        ...{ [type]: !state[type] }
      };
    }
    case 'gallery_view': {
      return {
        ...state,
        ...{ [type]: !state[type] }
      };
    };
    case 'custom_logo_url': {
      return {
        ...state,
        ...{ [type]: payload }
      };
    }
    default:
      return state;
  }
};

const sweetAlertDeleteOptions = {
  title: t`Delete Share Link`,
  text: t`Are you sure you want to delete?`,
  type: "warning",
  showCancelButton: true,
  cancelButtonText: t`No`,
  confirmButtonText: t`Delete`,
  closeOnConfirm: true,
  customClass: "deleting"
};

const redirectToContextable = () => {
  if (BFG.resource.type === 'collection') {
    // TODO update BFG global to accomodate this situation (when collection AND bf slug are needed)
    window.location.href = `/${BF.fx.brandfolder().slug}/${BFG.resource.slug}`;
  } else if (BFG.resource.type === 'brandfolder') {
    window.location.href = `/${BFG.resource.slug}`;
  } else {
    window.location.href = "/";
  }
};

const showOptionsTransitionTime = 300; // milliseconds
const debounceDelay = 1500; // milliseconds
const editLinkView = !!BFG.manifestDigest;
const defaultExpiration = moment().add(7, 'days').set({ hour: 23, minute: 59, second: 0 }).format('YYYY-MM-DD HH:mm');

const BulkShareModal = ({ closeModal, selectedAssetKeys }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const [isLoading, setIsLoading] = useState(true);
  const [lockUpdatesForReset, setLockUpdatesForReset] = useState(true);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const shareLinkNameRef = useRef(null);
  const [isExpirationDateValid, setIsExpirationDateValid] = useState(true);

  // used for edit link view (e.g. within share link view)
  const [stateForReset, setStateForReset] = useState();
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const debouncedName = useDebounce({ delay: debounceDelay, value: state.name, disableDebounce: isLoading || lockUpdatesForReset });
  const debouncedExpiration = useDebounce({ delay: debounceDelay, value: state.availability_end, disableDebounce: isLoading || lockUpdatesForReset });
  const assetCount = selectedAssetKeys.size;

  const allowShareLinkNameEdits = getCurrentUserIsAnonymous(BFG.currentUser);
  const defaultName = plural(assetCount, {
    one: `# Asset from ${decode(BFG.resource.name)}`,
    other: `# Assets from ${decode(BFG.resource.name)}`
  })

  const handleNotify = (type) => {
    Notify.create({
      title: t`Share Link Change`,
      body: paramNotificationCopyMap[type],
      type: "success"
    });
  };

  const handleReset = ({ payload, disableFetchUpdate = true }) => {
    dispatch({ type: 'reset-state', payload, });
    if (disableFetchUpdate) { // initial handleGet or handleCreate
      setIsLoading(false);
      setLockUpdatesForReset(false);
      setStateForReset(payload);
      if (shareLinkNameRef.current && !allowShareLinkNameEdits) {
        shareLinkNameRef.current.select(); // highlight share link name to encourage the user to update it
      }
    } else { // user clicked 'reset settings'
      setLockUpdatesForReset(true);
      updateLink({
        ...state,
        ...payload,
      })
        .then(() => {
          handleNotify('reset-state');
          setLockUpdatesForReset(false);
        })
        .catch(() => {
          Notify.create({
            title: t`Error updating Share Link`,
            type: "error"
          });
          closeModal();
        });
    }

    const { expires, require_identification, view_only } = payload;
    if ((expires || require_identification || view_only) && !allowShareLinkNameEdits) {
      setShowMoreOptions(true);
    }
  };

  const handleGet = () => {
    getLink(BFG.manifestDigest)
      .then((data) => {
        const payload = {
          ...data.attributes,
          ...{ key: data.id },
        };
        handleReset({ payload });
      })
      .catch(() => closeModal());
  };

  const handleCreate = async () => {
    const { key, type } = BFG.resource;
    const assetKeys = [...selectedAssetKeys];
    let internal;
    let defaultExpirationInSeconds;
    if (type !== 'organization') {
      const resource = await fetchJson({
        url: `/api/v4/${type}s/${key}`,
        fields: ['default_share_manifest_private', 'default_share_manifest_expiration_seconds']
      })
      internal = resource?.data?.attributes?.default_share_manifest_private;
      defaultExpirationInSeconds = resource?.data?.attributes?.default_share_manifest_expiration_seconds || undefined;
    }

    let availability_end;
    let expires;
    if (defaultExpirationInSeconds) {
      let date = new Date();
      date.setSeconds(date.getSeconds() + defaultExpirationInSeconds);
      availability_end = date.toISOString();
      expires = true;
    }

    createLink({
      assetKeys,
      ...state,
      availability_end,
      expires,
      key, // pass after state, to override state values
      internal,
      type,
      name: defaultName,
    })
      .then(({ data }) => {
        /**
         * Logging to Insights for 2k+ assets shared is needed so that both the browser
         * and Insights API don't error when rapidly making many singular API requests.
         *
         * TODO: Remove once we've added an Insights API endpoint that accepts an array
         * of asset keys that can handle this in one request.
         */
        fetchInBatches({
          fetchPromise: createAssetShareInsightsEvent,
          items: assetKeys
        });

        const payload = {
          ...data.attributes,
          ...{ key: data.id },
        };
        handleReset({ payload });
      })
      .catch(() => closeModal());
  };

  const handleUpdate = ({ type, payload }) => {
    if (isLoading || lockUpdatesForReset) return;

    dispatch({ type, payload }); // update state immediately

    if (type === 'expires') return; // expires toggle update is tied to availability_end update, only need to updateLink for 1

    const isBooleanParam = typeof state[type] === 'boolean' || type === 'view_only' || type === 'notifications_optin' || type === 'gallery_view'
    const booleanValue = isBooleanParam ? !state[type] : undefined;
    const isStringParam = type === 'name' || type === 'availability_end' || type === 'custom_logo_url';

    updateLink({
      ...state,
      ...isBooleanParam && { [type]: booleanValue }, // update boolean param using current state, NOT new state
      ...isStringParam && { [type]: payload }, // update name, expiration date using argument, or custom logo NOT new state
    })
      .then(() => {
        handleNotify(type);
        setLockUpdatesForReset(false);
        if (type === 'notifications_optin') {
          setStorage(StorageTypes.Local, SHARE_LINK_NOTIFICATIONS_PREFERENCE_KEY, booleanValue);
        }
      })
      .catch(() => {
        Notify.create({
          title: t`Error updating Share Link`,
          type: "error"
        });
        closeModal();
      });
  };

  const handleDelete = () => {
    window.swal(sweetAlertDeleteOptions, (confirm) => {
      if (confirm) {
        deleteLink(state.key)
          .then(() => {
            closeModal();
            Notify.create({
              title: t`Share link deleted`,
              type: "success"
            });
            if (editLinkView) {
              redirectToContextable();
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  useEffect(() => {
    if (editLinkView && stateForReset) {
      let changesPresent = false;
      Object.keys(state).forEach((param) => {
        if (state[param] !== stateForReset[param]) {
          changesPresent = true;
        }
      });
      setUnsavedChanges(changesPresent);
    }
  }, [state, stateForReset]);

  useEffect(() => {
    handleUpdate({ type: 'name', payload: debouncedName });
  }, [debouncedName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleUpdate({ type: 'availability_end', payload: debouncedExpiration });
  }, [debouncedExpiration]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editLinkView) {
      handleGet();
    } else {
      handleCreate();
    }
    return (() => closeModal());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isExpirationDateValid) {
      Notify.create({
        title: t`Invalid date. Please try again.`,
        type: 'error'
      });
    }
  }, [isExpirationDateValid]);

  const handleExperationDateValidityChange = (isValid) => {
    setIsExpirationDateValid(isValid);
  };

  const isPrivate = state.restrict_shares_setting || state.internal;

  return (
    <div className="modal-content-wrapper bulk-share-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-share icon" />
        <h3 className="modal-title">
          {isPrivate ? <Trans>Private Share Link for</Trans> : <Trans>Public Share Link for</Trans>}
          <span className="bold-text asset-qty">
            <Plural
              one="# asset"
              other="# assets"
              value={assetCount}
            />
          </span>
        </h3>
        <button
          className="close-button"
          onClick={closeModal}
          type="button"
        >
          <span className="bff-close" />
        </button>
      </div>
      {isLoading
        ? (
          <BFLoader />
        )
        : (
          <div className="modal-content-wrapper__body">
            <StandardTextField
              ref={shareLinkNameRef}
              disabled={allowShareLinkNameEdits === true}
              id="share-link-name"
              label={<Trans>Share Link Name</Trans>}
              onChange={(e) => dispatch({ type: 'name', payload: e.target.value })}
              value={state.name}
            />
            <div className="share-link-container">
              <span className={isPrivate ? "bff-private" : "bff-public"} />
              <StandardTextField
                id="share-link"
                label={<Trans>Share Link URL</Trans>}
                readOnly
                value={state.link}
              />
              <ClipboardCopy textToCopy={state.link}>
                <button className="button primary sm" type="button"><Trans>Copy</Trans></button>
              </ClipboardCopy>
            </div>
            <div className="actions-links">
              <a
                className="actions-links__link"
                href={state.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                <h5 className="action-text"><Trans>Open in new tab</Trans></h5>
              </a>
              <a
                className="actions-links__link"
                href={`mailto:?subject=link%20to%20${state.name}&body=${state.link}`}
                target="_blank"
              >
                <h5 className="action-text"><Trans>Email link</Trans></h5>
              </a>
            </div>
            {!state.restrict_shares_setting && (BFG.resource.type !== 'organization') && (
              <ToggleContainer
                handleUpdate={handleUpdate}
                param="internal"
                state={state}
              />
            )}
            <CSSTransition
              classNames="more-options"
              in={showMoreOptions}
              timeout={showOptionsTransitionTime}
            >
              <div className="more-options-container">
                <ToggleContainer
                  handleUpdate={handleUpdate}
                  param="require_identification"
                  state={state}
                />
                <ToggleContainer
                  handleUpdate={handleUpdate}
                  param="view_only"
                  state={state}
                />
                <ToggleContainer
                  handleUpdate={handleUpdate}
                  param="expires"
                  state={state}
                />
                <CSSTransition
                  in={state.expires}
                  timeout={showOptionsTransitionTime}
                  unmountOnExit
                >
                  <div className="date-time-picker-transition">
                    <DateTimePicker
                      onChange={(timestamp) => dispatch({
                        type: 'availability_end',
                        payload: timestamp,
                      })}
                      onDateValidityChange={handleExperationDateValidityChange}
                      timestamp={state.availability_end || defaultExpiration}
                    />
                  </div>
                </CSSTransition>
                <ToggleContainer
                  handleUpdate={handleUpdate}
                  param="notifications_optin"
                  state={state}
                />
                <ToggleContainer
                  handleUpdate={handleUpdate}
                  param="gallery_view"
                  state={state}
                />
                <CustomLogoUpload
                  customLogoUrl={state.custom_logo_url}
                  handleUpdate={ (url) => handleUpdate({ type: 'custom_logo_url', payload: url }) }
                  isGalleryViewOpen={state.gallery_view}
                />
              </div>
            </CSSTransition>
            {!allowShareLinkNameEdits &&
              <div className="show-more-options">
                <button
                  className="show-more-options--button"
                  onClick={() => setShowMoreOptions((prevState) => (!prevState))}
                  type="button"
                >
                  <span
                    className={`icon ${showMoreOptions ? 'bff-caret-up' : 'bff-caret-down'}`}
                  />
                  <h5 className="options-copy">
                    {showMoreOptions ? <Trans>Less Options</Trans> : <Trans>More Options</Trans>}
                  </h5>
                </button>
              </div>}
          </div>
        )}
      {!allowShareLinkNameEdits &&
        <div className="button-container share-modal-button-container">
          {editLinkView && (
            <button
              className={`reset-button ${unsavedChanges ? 'active' : ''}`}
              onClick={() => handleReset({ payload: stateForReset, disableFetchUpdate: false })}
              type="button"
            >
              <Trans>Restore settings</Trans>
            </button>
          )}
          <button
            className="share-modal-delete-button"
            onClick={handleDelete}
            type="button"
          >
            <span className="bff-trash" />
            <Trans>Delete link</Trans>
          </button>
        </div>}
    </div>
  );
};

BulkShareModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
};

const ModalComponent = renderModal(BulkShareModal, 'BulkShareModal');
export default ModalComponent;
