export enum WorksheetAccessLevels {
  Owner = 'OWNER',
  Admin = 'ADMIN'
}

export enum DataSyncStatusResults {
  Error = 'ERROR',
  Success = 'SUCCESS',
  InProgress = 'IN_PROGRESS'
}

export enum DataSyncActionTypes {
  Disconnect = 'DISCONNECT',
  TurnOn = 'TURN ON',
  TurnOff = 'TURN OFF',
  Reset = 'RESET', 
}