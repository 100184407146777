import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import updateMetadata, { MetadataAttributes } from '@api/v4/private/BlitlineMetadataUpdate';
import { InputType } from '@components/library/inputs/Input.props';
import Input from '@components/library/inputs/PrimaryInput';

interface MetadataRowProps {
  attachmentKey: string;
  initialMetadataValue: string;
  isITPC: boolean;
  metadataKey: string;
  searchMetadata: (objectKey: string, objectValue: string) => void;
}
const MetadataRow: FunctionComponent<MetadataRowProps> = ({
  attachmentKey, metadataKey, initialMetadataValue, isITPC, searchMetadata
}) => {
  const [metadataValue, setMetadataValue] = useState(initialMetadataValue);
  const [editMode, setEditMode] = useState(false);

  const handleUpdateButtonClick = async (attributes: MetadataAttributes): Promise<void> => {
    await updateMetadata(attachmentKey, attributes);
    setEditMode(false);
  };

  const updateButton = (
    <button
      className="button secondary sm meta-row__buttons--edit"
      onClick={(): void => setEditMode(true)}
      type="button"
    >
      <span className="bff-edit" />
      <Trans>Edit</Trans>
    </button>
  );

  const editModeButtons = (attributes: MetadataAttributes): JSX.Element => (
    <div className="meta-row__buttons">
      <button
        className="button tertiary sm"
        onClick={(): void => {
          setMetadataValue(initialMetadataValue);
          setEditMode(false);
        }}
        type="button"
      >
        <Trans>Cancel</Trans>
      </button>
      <button
        className="button secondary sm"
        onClick={(): void => { handleUpdateButtonClick(attributes); }}
        type="button"
      >
        <Trans>Update</Trans>
      </button>
    </div>
  );

  const editView = (
    <div className="meta-row__editing">
      <Input
        attributes={{
          name: metadataKey,
          onChange: (e: InputChangeEvent): void => setMetadataValue(e.target.value),
          type: InputType.Text,
          value: metadataValue,
        }}
        input={{ value: metadataValue }}
      />
      { editModeButtons({ [metadataKey]: metadataValue }) }
    </div>
  );

  return (
    <span>
      { editMode ? editView
        : (
          <>
            <button
              className="link"
              onClick={(): void => searchMetadata(metadataKey, metadataValue)}
              type="button"
            >
              { metadataValue }
            </button>
            { isITPC && (updateButton) }
          </>
        )}
    </span>
  );
};

export default MetadataRow;
