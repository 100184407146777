// same as public.task_priority_enum
export enum TaskPriorities {
  All = '',
  Low = 'low',
  Medium = 'medium',
  High = 'high'
}

export enum TaskPrioritiyLabels {
  All = 'All',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

// same as public.task_status_enum
export enum TaskStatuses {
  All = '',
  Completed = 'completed',
  InProgress = 'in_progress',
  NotStarted = 'not_started'
}

export enum TaskStatusLabels {
  All = 'All',
  Completed = 'Completed',
  InProgress = 'In Progress',
  NotStarted = 'Not Started'
}
