import { createContext } from 'react';

import { FetchState } from '@api/ApiHelper';
import { AdminableResourcesResponse } from '@components/bulk_management/user_groups/helpers';

const AdminableResourcesContext = createContext<FetchState<AdminableResourcesResponse>>({
  error: undefined,
  fetch: undefined,
  loading: true,
  response: undefined,
  reset: undefined
});

export default AdminableResourcesContext;
