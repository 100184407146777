import { ListOption } from '@components/library/dropdown';
import { ResourceTypes } from '@api/v4/resources/resourceTypes';
import { SectionsResponseJSON } from '@api/v4/private/permissions/sections';

interface ParsedResources {
  name: string;
  options: ListOption[];
}

export type Resources = { key: string; value: ParsedResources } | {}

export const generateOptions = (
  result: SectionsResponseJSON,
  type: ResourceTypes,
  id: string,
): ListOption[] => {
  const options: ListOption[] = [];

  for (let i = 0; i < result.data.length; i += 1) {
    const { relationships } = result.data[i];
    if (relationships && relationships.brandfolder && relationships.organization) {
      const brandfolderId = relationships.brandfolder.data.id;
      const organizationId = relationships.organization.data.id;
      const isUnique = !options.find((option) => option.value === brandfolderId);

      if (isUnique && type === ResourceTypes.Organizations && organizationId === id) {
        // get all brandfolders associated with each organization
        const label = result.included?.find((resource) => resource?.id === brandfolderId)?.attributes.name;
        if (label) {
          options.push({ label, type: ResourceTypes.Brandfolders, value: brandfolderId });
        }
      }

      if (type === ResourceTypes.Brandfolders && brandfolderId === id) {
        // get all sections associated with each brandfolder
        const sectionId = result.data[i].id;
        const label = result.data[i].attributes.name;
        options.push({ label, type: ResourceTypes.Sections, value: sectionId });
      }
    }
  }

  return options;
};

export const parseResources = (response: SectionsResponseJSON): Resources => {
  const resources = {};
  if (response.data?.length === 0) return resources;

  const sections = response.included ? [...response.data, ...response.included] : [...response.data];
  sections.forEach((resource) => {
    resources[resource.id] = {
      name: resource.attributes.name,
      options: generateOptions(response, resource.type, resource.id),
    };
  });

  return resources;
};

export const parseOrganizations = (response?: SectionsResponseJSON): ListOption[] => {
  const options: ListOption[] = [];
  if (response && response.included) {
    response.included.flatMap((resource) => {
      if (resource.type === ResourceTypes.Organizations) {
        options.push({ label: resource.attributes.name, type: resource.type, value: resource.id });
      }
    });
  }
  return options;
};
