import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

const Status = ({ asset }) => {
  const statusMap = {
    approved: <Trans>Approved</Trans>,
    pending_approval: <Trans>Pending approval</Trans>,
    unpublished: <Trans>Draft</Trans>,
    expired: <Trans>Expired</Trans>
  };

  const { availability, availability_end, availability_start } = asset.attributes;
  let statusText;

  if (availability !== 'unpublished' && availability !== 'expired') {
    if (availability_start && new Date(availability_start) > new Date()) {
      switch (availability) {
        case 'approved':
          statusText = <Trans>Approved, unpublished</Trans>
          break;
        case 'pending_approval':
          statusText = <Trans>Pending approval, unpublished</Trans>
          break;
      }
    }

    if (availability_end && new Date(availability_end) < new Date()) {
      switch (availability) {
        case 'approved':
          statusText = <Trans>Approved, expired</Trans>
          break;
        case 'pending_approval':
          statusText = <Trans>Pending approval, expired</Trans>
          break;
      }
    }
  }

  if (!statusText) {
    statusText = statusMap[availability] || <Trans>Available</Trans>;
  }

  return (
    <div className="availability-row status-container">
      <Trans>
        <h4>Current status:</h4>
        <p className="status">{statusText}</p>
      </Trans>
    </div>
  );
};

Status.propTypes = {
  asset: PropTypes.shape({
    attributes: PropTypes.shape({
      availability: PropTypes.string,
      availability_end: PropTypes.string,
      availability_start: PropTypes.string,
    })
  }).isRequired,
};

export default Status;
