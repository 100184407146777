/**
 * Handly compiled list: https://lingohub.com/developers/supported-locales/language-designators-with-regions
 * Full list: https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 */
export enum IsoLanguagesRegions {
  /** Bulgarian */
  BG = 'bg',
  /** Czech */
  CS = 'cs',
  /** German */
  DE = 'de',
  /** Greek */
  EL = 'el',
  /** English */
  EN = 'en',
  /** English (Austrailia) */
  EN_AU = 'en-AU', // not currently used in BF
  /** English (Canada) */
  EN_CA = 'en-CA', // not currently used in BF
  /** English (United Kingdom) */
  EN_GB = 'en-GB', // not currently used in BF
  /** English (India) */
  EN_IN = 'en-IN', // not currently used in BF
  /** English (New Zealand) */
  EN_NZ = 'en-NZ', // not currently used in BF
  /** English (United States) */
  EN_US = 'en-US', // not currently used in BF
  /** English (South Africa) */
  EN_ZA = 'en-ZA', // not currently used in BF
  /** Spanish */
  ES = 'es',
  /** Finnish */
  FI = 'fi',
  /** French */
  FR = 'fr',
  /** French (Canada) */
  FR_CA = 'fr-CA', // not currently used in BF
  /** French (Switzerland) */
  FR_CH = 'fr-CH', // not currently used in BF
  /** Hungarian */
  HU = 'hu',
  /** Italian */
  IT = 'it',
  /** Japanese */
  JA = 'ja',
  /** Korean */
  KO = 'ko',
  /** Dutch */
  NL = 'nl',
  /** Dutch (Belgium) */
  NL_BE = 'nl-BE', // not currently used in BF
  /** Norwegian Bokmål */
  NB = 'nb',
  /** Polish */
  PL = 'pl',
  /** Portuguese */
  PT = 'pt',
  /** Romanian */
  RO = 'ro',
  /** Russian */
  RU = 'ru',
  /** Swedish */
  SV = 'sv',
  /** Thai */
  TH = 'th',
  /** Turkish */
  TR = 'tr',
  /** Vietnamese */
  VI = 'vi',
  /** Chinese */
  ZH = 'zh',
  /** Chinese (China) */
  ZH_CN = 'zh-cn', // not currently used in BF
  /** Chinese (Taiwan) */
  ZH_TW = 'zh-tw' // not currently used in BF
}

export const defaultIsoLanguageRegion = IsoLanguagesRegions.EN;

export const isoLanguagesRegions = Object.values(IsoLanguagesRegions).filter((l) => typeof l === "string");
