import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface EnableAiGeneratedImageCaptions {
  defaultEnabled: boolean | null;
}

const AiGeneratedImageCaptions: FunctionComponent<EnableAiGeneratedImageCaptions> = ({
  defaultEnabled
}) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled || false}
      errorTitle={t`Error updating Ai Generated Image Captions setting. Please contact support.`}
      id='enable-ai-generateted-image-captions'
      successTitle={t`Ai Generated Image Captions setting updated!`}
      title={t`Ai Generated Image Captions`}
      updateResourceKey='AiGeneratedImageCaptionsEnabled'
    />
  </I18nProviderWrapper>
);

export default AiGeneratedImageCaptions;
