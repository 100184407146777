import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@components/common/tooltip/main";
import WatermarkGrid from './watermark_grid';

function WatermarkAdjustments(props) {
  return (
    <div className="open watermark-options">
      <div className="full video-input-wrap">
        <div className="video-label full">
          <div className="float-left">Watermark height</div>
          <div className="float-right">
            {`${props.watermark_height} px`}
          </div>
        </div>
        <input
          type="range"
          name="watermarkHeight"
          defaultValue={props.watermark.height}
          min={10}
          max={props.watermark.height}
          step={10}
          onChange={(e) => {
            const newWidth = (props.watermark_width / props.watermark_height) * e.target.value;
            props.updateWatermarkHeight(parseInt(e.target.value, 10));
            props.updateWatermarkWidth(parseInt(newWidth, 10));
          }}
        />
        <div className="video-label full">
          <div className="float-left">Watermark opacity</div>
          <div className="float-right">{props.watermark_opacity || 1}</div>
        </div>
        <input
          type="range"
          name="bgopacity"
          defaultValue={1}
          min={0.1}
          max={1}
          step={0.1}
          onChange={(e) => { props.updateWatermarkOpacity(parseFloat(e.target.value)); }}
        />
      </div>

      <div className="video-label">
        Watermark Placement &nbsp;
        <Tooltip
          tooltipId="watermark-position-info"
          tooltipContent="Click a section within the grid
            or full screen to position the watermark."
        >
          <span className="bff-tool-tip" />
        </Tooltip>
      </div>
      <WatermarkGrid {...props} />
    </div>
  );
}

WatermarkAdjustments.propTypes = {
  watermark: PropTypes.shape({
    url: PropTypes.string.isRequired,
    height: PropTypes.number
  }).isRequired,
  watermark_height: PropTypes.number,
  watermark_width: PropTypes.number,
  watermark_opacity: PropTypes.number,
  updateWatermarkOpacity: PropTypes.func.isRequired,
  updateWatermarkHeight: PropTypes.func.isRequired,
  updateWatermarkWidth: PropTypes.func.isRequired,
  attachment: PropTypes.shape({
    height: PropTypes.number
  }).isRequired
};

WatermarkAdjustments.defaultProps = {
  watermark_height: 0,
  watermark_width: 0,
  watermark_opacity: 0.5
};

export default WatermarkAdjustments;
