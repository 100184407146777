import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTh, BaseThProps } from '../../base';

import classes from '../styles/selectable-table.module.scss';

interface StandardThProps extends BaseThProps {
  centered?: boolean;
}

export const Th: FunctionComponent<StandardThProps> = (props) => {
  const { centered, children, ...otherProps } = props;
  return (
    <BaseTh
      {...otherProps}
      className={classnames('selectabeTh', classes.th, centered && classes.thCentered, props.className)}
    >
      {children}
    </BaseTh>
  );
};
