import { Trans } from '@lingui/macro';
import React, { useState } from 'react';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { BackendPortalCard } from '@api/v4/private/PortalCardTypes';

import { PrimaryButton } from '@components/library/button/index';

import Widget from './widget';
import WidgetForm from './widget_form';

interface WidgetsProps {
  brandHexColor: string | undefined;
  editMode: boolean;
  libraryName: string;
  triggerCardsRefresh: () => void;
  widgets: ApiResponseObject<BackendPortalCard>[];
}

const Widgets: React.FunctionComponent<WidgetsProps> = ({
  brandHexColor,
  editMode,
  libraryName,
  triggerCardsRefresh,
  widgets
}) => {
  const [showForm, setShowForm] = useState(false);
  const toggleForm = (): void => setShowForm((prevState) => !prevState);

  return (
    <section className="widgets" style={{ backgroundColor: brandHexColor ? `#${brandHexColor}` : undefined }}>
      <div className="widgets-container" style={{ padding: `${widgets.length === 0 ? '0' : ''}` }}>
        {widgets.map((widget) => {
          const { name, sub_type } = widget.attributes; // eslint-disable-line @typescript-eslint/naming-convention
          if (!sub_type) return null;
          return (
            <Widget
              key={widget.id}
              brandHexColor={brandHexColor}
              editMode={editMode}
              libraryName={libraryName}
              name={name}
              portalCardId={widget.id}
              subType={sub_type}
              triggerCardsRefresh={triggerCardsRefresh}
            />
          );
        })}
        {editMode && (
          <div className="widgets-third edit-mode">
            <PrimaryButton
              className="widget-create-button"
              icon="bff-plus"
              onClick={toggleForm}
            >
              <Trans>Create Widget</Trans>
            </PrimaryButton>
          </div>
        )}
        {showForm && (
          <WidgetForm
            closeModal={toggleForm}
            libraryName={libraryName}
            triggerCardsRefresh={triggerCardsRefresh}
          />
        )}
      </div>
    </section>
  );
};

export default Widgets;
