import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { UserManagementUserGroupsTable } from '@components/bulk_management/user-settings/UserManagementUserGroupsTable';
import { UsersTable } from '@components/bulk_management/user-settings/users-table';
import { UsersTableProps } from '@components/bulk_management/user-settings/users-table/UsersTable';
import { StandardTabs } from '@components/library/tabs';

import './styles/user-usergroups.scss';

export type Tab = 'users' | 'user groups';

interface UserManagementTabsProps extends UsersTableProps {
  currentTab: Tab;
  reloadUserGroups: boolean;
  setCurrentTab: SetStateDispatch<Tab>;
  setReloadUserGroups: SetStateDispatch<boolean>;
}

export const UserManagementTabs: FunctionComponent<UserManagementTabsProps> = ({
  currentTab,
  reloadUserGroups,
  setCurrentTab,
  setReloadUserGroups,
  ...usersTableProps
}) => (
  <section className="user-usergroups">
    <StandardTabs
      activatePanelOnPrevNext={false}
      caption={t`Select between displaying users with access to the resource and user groups with access to the resource`}
      id="user-user-groups-tabs"
      initialActiveIndex={currentTab === 'users' ? 0 : 1}
      onChange={(nextIndex): void => {
        if (nextIndex === 0) {
          setCurrentTab('users');
        } else {
          setCurrentTab('user groups');
        }
      }}
      showUnderline
      tabButtonClassName="user-usergroups__tab-button"
      tabListClassName="user-usergroups__tab-list"
      tabs={[{
        disabled: false,
        tabButton: t`Users`,
        tabPanel: (
          <div className="user-usergroups__users-tab">
            <UsersTable {...usersTableProps}/>
          </div>
        )
      }, {
        disabled: false,
        tabButton: t`User Groups`,
        tabPanel: (
          <div className="user-usergroups__usergroups-tab">
            <UserManagementUserGroupsTable
              reloadUserGroups={reloadUserGroups}
              resourceName={usersTableProps.resourceName}
              selectedResourceKey={usersTableProps.selectedResourceKey}
              selectedResourceType={usersTableProps.selectedResourceType}
              setReloadUserGroups={setReloadUserGroups}/>
          </div>
        )
      }]}
    />
  </section>
);
