import { fetchJson } from '@api/ApiHelper';
import { ApiDatum } from '@api/v4/ApiResponseTypes';

export interface ConvertedAttachmentServer {
  cropping_image_width: number;
  cropping_image_height: number;
  url: string;
  bits_per_sample?: string;
}

interface ConvertedAttachmentsOptions {
  attachmentKey: string;
}

export type ConvertedAttachmentGetResponse = ApiDatum<ConvertedAttachmentServer | null, 'converted_attachment'>;

// eslint-disable-next-line max-len
export const getConvertedAttachment = async (options: ConvertedAttachmentsOptions): Promise<ConvertedAttachmentGetResponse> => {
  const { attachmentKey } = options;

  return fetchJson<ConvertedAttachmentGetResponse>({
    url: `/api/v4/private/attachments/${attachmentKey}/converted_attachments`
  });
};
