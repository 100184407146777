/* eslint-disable react/require-default-props */
import { keyPressHelper } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import NewComment from '@components/asset/modal/tabs/workflow/attachment_details/versions_sidebar/new_comment';
import DropdownWrapper from '@components/common/dropdown_wrapper/main';

const VersionHeader = ({
  handlePostAnnotations,
  mentionableUsers,
  selectedSortOption,
  setActiveVersion,
  setMentionableUsers,
  setSelectedSortOption,
  sortOptions,
  versionCard,
  versionCards,
}) => {
  const [closeSortDropdown, setCloseSortDropdown] = useState(false);
  const [closeVersionDropdown, setCloseVersionDropdown] = useState(false);
  const [showCommentsPostButton, setShowCommentsPostButton] = useState(false);

  const { id: displayedVersionid, versionIndex: displayedVersionIndex } = versionCard;
  const versionCardsNoReservations = versionCards.filter((item) => item.type === 'version');
  const versionCount = versionCardsNoReservations.length;
  const commentQuantity = versionCard.annotations.filter((card) => card.type === 'comment').length;
  const annotationQuantity = versionCard.annotations.filter((card) => card.type === 'annotation').length;

  const handleDropdownSelection = (id) => {
    if (id !== displayedVersionid) {
      setActiveVersion({ type: "version", id });
      setCloseVersionDropdown(true);
    }
  };

  const keypressHandleToggle = keyPressHelper(handleDropdownSelection);

  const createVersionDisplay = (index) => (
    <>
      <span><Trans>Version</Trans></span>
      <span className="version-number">{(`000${index}`).substr(-3)}</span>
      {index === versionCount && (<span><Trans>(Current)</Trans></span>)}
    </>
  );

  const versionList = () => (
    <ul className="selector__list">
      {versionCardsNoReservations.map((card) => (
        <li
          key={card.id}
          className={`selector__list--item ${card.id === displayedVersionid ? 'active' : ''}`}
          onClick={() => handleDropdownSelection(card.id)}
          onKeyPress={(e) => keypressHandleToggle(e, card.id)}
          role="button"
          tabIndex={0}
        >
          {createVersionDisplay(card.versionIndex)}
        </li>
      ))}
    </ul>
  );

  const sortOptionsList = () => (
    <ul className="selector__list">
      {sortOptions.map((option) => (
        <li
          key={option.value}
          className={`selector__list--item ${option.value === selectedSortOption.value ? 'active' : ''}`}
          onClick={() => {
            setSelectedSortOption(option);
            setCloseSortDropdown(true);
          }}
          onKeyPress={(e) => keypressHandleToggle(e, () => {
            setSelectedSortOption(option);
            setCloseSortDropdown(true);
          })}
          role="button"
          tabIndex={0}
        >
          {option.label}
        </li>
      ))}
    </ul>
  );

  return (
    <div className={`sidebar-header ${showCommentsPostButton ? 'expanded' : ''}`}>
      <DropdownWrapper
        close={closeVersionDropdown}
        closeCallback={() => setCloseVersionDropdown(false)}
        cssTransitionClasses="fade-in-translate"
        dropdownContents={versionList()}
        openOnHover={false}
        wrapperClassnames="version-selector version-sidebar-dropdown-wrapper"
      >
        <>
          <h4 className="selected-option">{createVersionDisplay(displayedVersionIndex)}</h4>
          <span className="bff-caret-down" />
        </>
      </DropdownWrapper>
      <NewComment
        mentionableUsers={mentionableUsers}
        onSubmit={handlePostAnnotations}
        setMentionableUsers={setMentionableUsers}
        setShowCommentsPostButton={setShowCommentsPostButton}
        showCommentsPostButton={showCommentsPostButton}
        versionKey={versionCard.id}
      />
      <div className="header-info">
        <div className="quantities-container">
          <span className="bff-comment" />
          <h4 className="quantity comment-quantity">{commentQuantity}</h4>
          <span className="annotation-dot" />
          <h4 className="quantity annotation-quantity">{annotationQuantity}</h4>
        </div>
        <DropdownWrapper
          close={closeSortDropdown}
          closeCallback={() => setCloseSortDropdown(false)}
          cssTransitionClasses="fade-in-translate"
          dropdownContents={sortOptionsList()}
          openOnHover={false}
          wrapperClassnames="sort-selector version-sidebar-dropdown-wrapper"
        >
          <>
            <h4 className="selected-option">{selectedSortOption.label}</h4>
            <span className="bff-caret-down" />
          </>
        </DropdownWrapper>
      </div>
    </div>
  );
};

export default VersionHeader;

VersionHeader.propTypes = {
  handlePostAnnotations: PropTypes.func.isRequired,
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  selectedSortOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  setActiveVersion: PropTypes.func.isRequired,
  setMentionableUsers: PropTypes.func.isRequired,
  setSelectedSortOption: PropTypes.func.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  versionCard: PropTypes.shape({
    annotations: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(['annotation', 'comment'])
    })),
    id: PropTypes.string,
    versionIndex: PropTypes.number,
  }).isRequired,
  versionCards: PropTypes.arrayOf(PropTypes.shape({
    annotations: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(['annotation', 'comment'])
    })),
    type: PropTypes.oneOf(['reservation', 'version']),
  })).isRequired,
};

VersionHeader.defaultProps = {
  mentionableUsers: null,
};
