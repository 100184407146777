import getJson from '@api/api_helper';

export default ({ resourceKey, query, collection }) => {
  const resource = collection ? 'collections' : 'brandfolders';
  const url = `/api/v3/${resource}/${resourceKey}/autosuggest_search`;
  return getJson(url, {
    params: {
      term: query,
    },
  });
};
