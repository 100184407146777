
export enum DialogAnimations {
    FadeIn = 'fade-in',
    FadeInFromBottom = 'fade-in-from-bottom',
    FadeInFromLeft = 'fade-in-from-left',
    FadeInFromRight = 'fade-in-from-right',
    FadeInFromTop = 'fade-in-from-top'
}

export enum DialogSizes {
    Auto = 'auto',
    Xxsmall = 'xxsmall',
    Xsmall = 'xsmall',
    Small= 'small',
    Medium = 'medium',
    Large = 'large',
    Xlarge = 'xlarge',
    Xxlarge = 'xxlarge',
    Unlimited = 'unlimited',
    Fullscreen = 'fullscreen'
}

export enum DialogTypes {
    Drawer = 'drawer',
    Modal = 'modal'
}

export enum DrawerAnimations {
    FadeInFromLeft = 'fade-in-from-left',
    FadeInFromRight = 'fade-in-from-right'
}
