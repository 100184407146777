/* eslint-disable react/no-array-index-key */
import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { StandardTableProps, TableBreakpoints } from '@components/library/table';
import { Table, TableWrapper, TBody, Td, TFoot, Th, THead, Tr, TrEmpty, TrError, TrLoader } from './components';

import classes from './styles/standard-table.module.scss';

/**
 * @deprecated Use `import { StandardTable } from '@brandfolder/react';` instead
 */
export const StandardTable: FunctionComponent<StandardTableProps> = (props) => {
  const {
    breakpoints = [TableBreakpoints.tableSm],
    breakpointsOn = true,
    caption,
    columns,
    empty,
    emptyContent,
    error,
    errorContent,
    footer,
    hover = true,
    id,
    loading,
    maxHeight,
    rows,
    scrollX = true,
    scrollY,
    ...otherProps
  } = props;

  const [bodyRows, setBodyRows] = useState<JSX.Element[]>([]);
  const [headRow, setHeadRow] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const ths = columns.map((column, columnIndex) => (
      <Th
        key={`${id}-th-${columnIndex}`}
        centered={column.centered}
        scope="col"
        style={{
          maxWidth: column.maxWidth,
          minWidth: column.minWidth,
          width: column.width
        }}
        title={column.headingTitle}
      >
        {column.heading}
      </Th>
    ));
    setHeadRow(ths);
  }, [columns]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const trs = rows.map((row, rowIndex) => (
      <Tr
        key={`${id}-tr-${rowIndex}`}
        hover={hover}
      >
        {columns.map((column, columnIndex) => (
          <Td
            key={`${id}-td-${columnIndex}`}
            centered={column.centered}
            ellipsis={column.ellipsis}
            heading={column.heading}
            style={{
              maxWidth: column.maxWidth,
              minWidth: column.minWidth,
              width: column.width
            }}
            title={column.cellTitle}
          >
            {row[column.rowKey]}
          </Td>
        ))}
      </Tr>
    ));
    setBodyRows(trs);
  }, [columns, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const columnsLength = columns.length;
  const rowsLength = bodyRows.length;
  const isEmpty = (empty ?? rowsLength === 0);

  const breakpointClasses = breakpointsOn && breakpoints ? breakpoints.map((breakpoint) => classes[breakpoint]) : [];

  const table = (
    <Table
      {...otherProps}
      caption={caption}
      captionProps={{
        ...props.captionProps,
        className: classnames(classes.srOnly, props.captionProps?.className)
      }}
      className={classnames(
        breakpointsOn && breakpointClasses.length > 0 && classes.stack,
        breakpointClasses
      )}
      id={id}
    >
      <THead>
        <Tr>
          {headRow}
        </Tr>
      </THead>
      <TBody>
        {isEmpty && !loading && (
          <TrEmpty colSpan={columnsLength}>
            {emptyContent}
          </TrEmpty>
        )}
        {error && !loading && (
          <TrError colSpan={columnsLength}>
            {errorContent}
          </TrError>
        )}
        {loading && (
          <TrLoader colSpan={columnsLength} />
        )}
        {!error && !isEmpty && !loading && bodyRows.map((bodyRow) => bodyRow)}
      </TBody>
      {footer ? (
        <TFoot>
          <Tr>
            <Td colSpan={columnsLength}>
              {footer}
            </Td>
          </Tr>
        </TFoot>
      ) : null}
    </Table>
  );

  const renderedTable = (scrollX || scrollY) ? (
    <TableWrapper
      scrollX={scrollX}
      scrollY={scrollY}
      style={{ maxHeight: scrollY ? (maxHeight || '500px') : undefined }}
    >
      {table}
    </TableWrapper>
  ) : table;

  return renderedTable;
};
