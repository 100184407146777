import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

const ModalHeader = ({ dispatch, handleClose, updatedFiltersData }) => (
  <div className="modal__header">
    <div className="header-copy-container">
      <h2 className="modal-title"><Trans>Manage Pinned Searches</Trans></h2>
      <h4 className="modal-subtitle">
        <Trans>Create, rename, reorder, or delete pinned searches</Trans>
      </h4>
    </div>
    <button
      className="button secondary alphabetize-button"
      disabled={!updatedFiltersData.length}
      onClick={() => dispatch({ type: 'alphabetize' })}
      type="button"
    >
      <Trans>Alphabetize</Trans>
    </button>
    <button
      className="close-button"
      onClick={() => handleClose()}
      type="button"
    >
      <span className="bff-close" />
    </button>
  </div>
);

ModalHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  updatedFiltersData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ModalHeader;
