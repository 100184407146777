import React, { FunctionComponent } from 'react';

import { CreateNewAssetTypes } from '@components/show_page/sections/asset/custom-templates';
import { CreateProject } from '@components/show_page/sections/asset/custom-templates/CreateProject';

interface CreateDigitalDesignProjectProps {
  sectionKey: string;
}

export const CreateDigitalDesignProject: FunctionComponent<CreateDigitalDesignProjectProps> = ({ sectionKey }) => (
  <CreateProject
    assetType={CreateNewAssetTypes.DigitalDesignProject}
    sectionKey={sectionKey}
  />
);
