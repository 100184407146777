import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

class AccordionSection extends React.Component {
  state = {
    isOpen: false
  }

  render() {
    const { children, title, overflowContent } = this.props;
    const { isOpen } = this.state;

    const filterTitleEscaped = title.toLowerCase().replace(/ /g, '-');

    return (
      <div className={classNames(
        "filter-section",
        `cx-filters-${filterTitleEscaped}`,
        "accordion-section",
        `${filterTitleEscaped}`,
        { "overflow-content": overflowContent }
      )}>
        <div
          className="flex-row accordion-tab"
          onClick={() => this.setState({ isOpen: !isOpen })}
        >
          <p className="accordion-title">{title}</p>
          <span
            className={`bff-caret-down ${isOpen ? 'open' : ''} `}
            aria-label="Expand"
          />
        </div>
        <div className={`accordion-content ${isOpen ? 'open' : 'closed'}`}>
          {isOpen && children}
        </div>
      </div>
    );
  }
}

AccordionSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  overflowContent: PropTypes.bool
};

AccordionSection.defaultProps = {
  overflowContent: false
}

export default AccordionSection;
