import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { FetchState } from '@api/ApiHelper';
import { ShareManifestPostAddAssetsResponse } from '@api/v4/share_manifests/ShareManifestTypes';
import { PrimaryButton, SecondaryButton, TertiaryButton } from '@components/library/button';
import { ShareLinkRow } from '@components/show_page/bulk_actions/bulk-share-link-modal/BulkShareLinkModalTypes';

interface FooterProps {
  isSubmitted: boolean;
  postAddAssetsToShareLink: FetchState<ShareManifestPostAddAssetsResponse>;
  selectedRow: ShareLinkRow;
  setIsSubmitted: SetStateDispatch<boolean>;
  setOpen: SetStateDispatch<boolean>;
}

export const Footer: FunctionComponent<FooterProps> = ({
  isSubmitted,
  postAddAssetsToShareLink,
  selectedRow,
  setIsSubmitted,
  setOpen,
}) => {
  if (isSubmitted) {
    return (
      <div className="submission-success-buttons">
        <TertiaryButton onClick={(): void => { setOpen(false); }}>
          <Trans>Close</Trans>
        </TertiaryButton>
        <SecondaryButton onClick={(): void => { setIsSubmitted(false); }}>
          <Trans>Add assets to another Share Link</Trans>
        </SecondaryButton>
      </div>
    );
  }

  return (
    <PrimaryButton
      disabled={!selectedRow || postAddAssetsToShareLink.loading}
      onClick={(): void => { postAddAssetsToShareLink.fetch(); }}
    >
      <Trans>Add to Share Link</Trans>
    </PrimaryButton>
  );
};
