import { t, plural } from '@lingui/macro';

interface SearchStatusProps {
  resultQuantity: number;
  resultsShown: boolean;
  isLoading?: boolean;
}

export const searchStatus = ({
  isLoading = false,
  resultQuantity,
  resultsShown,
}: SearchStatusProps): string => {
  if (isLoading) return t`Search loading`;
  if (resultsShown) {
    return plural(resultQuantity, {
      one: '# result displayed',
      other: '# results displayed'
    });
  }
  return t`No search input`; // not loading and no search inputs
};
