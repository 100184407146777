// types
import type { BaseAvatarProps, OverflowAvatarProps, StandardAvatarProps } from './AvatarTypes';

export type { BaseAvatarProps, OverflowAvatarProps, StandardAvatarProps };

// components
export { OverflowAvatar } from './overflow';
export { StandardAvatar } from './standard';

// enums
export { AvatarTypes } from './AvatarEnums';
