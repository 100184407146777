import { Trans } from '@lingui/macro';
import React, { useCallback, useContext, useEffect, useRef, useState, FunctionComponent, ReactText } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { MAX_PAGE_SIZE } from '@api/ApiHelper';
import { listCustomFieldValues } from '@api/v4/assets/custom_fields';
import { CustomFieldValue } from '@api/v4/assets/customFieldTypes';
import { getCustomFieldKeys } from '@api/v4/resources/custom_fields';
import { CustomFieldKeysResponseObject, DependentCustomField } from '@api/v4/resources/CustomFieldKeysTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import {
  ActionTypes,
  CreateCustomFieldsDispatch,
  CustomFieldKeyValuesEntry,
  CustomFieldPayloadOperations,
  DeleteCustomFieldValuesDispatch,
  FlattenedCustomFieldKeyValuesMap,
  InitialCustomFieldKeysDispatch,
  InitialCustomFieldValuesDataDispatch,
  UpdateCustomFieldKeyDispatch,
  UpdateCustomFieldValuesDispatch,
  ValidationErrors
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import languagesMap, { Locale } from '@components/common/language_menu/languagesMap';
import { BFLoader } from '@components/common/loader/main';
import { SecondaryButton } from '@components/library/button';

import { containsSpecialCharacters } from '@helpers/custom-field-validation';

import { CustomFieldKeyValueRow } from './custom-field-row/CustomFieldKeyValueRow';

import './styles/_custom_fields.scss';

interface CustomFieldsSectionProps {
  initialData: {
    assetKey: string;
    customFieldKeys: CustomFieldKeysResponseObject[];
    flattenedCustomFieldKeyValuesMap: FlattenedCustomFieldKeyValuesMap;
    dependentCustomFields: DependentCustomField[];
  };
  editState: {
    flattenedCustomFieldKeyValuesMap: FlattenedCustomFieldKeyValuesMap;
  };
  errorState: ValidationErrors[];
  ugtLocale: Locale | '';
}

interface CustomFieldsScopedContext {
  state: CustomFieldsSectionProps;
  dispatch: InitialCustomFieldKeysDispatch
  | CreateCustomFieldsDispatch
  | DeleteCustomFieldValuesDispatch
  | InitialCustomFieldValuesDataDispatch
  | UpdateCustomFieldKeyDispatch;
}

export const CustomFieldsList: FunctionComponent = () => {
  const [newCustomFieldFocus, setNewCustomFieldFocus] = useState(false);
  const listContainerRef = useRef(null);
  const { state, dispatch }: CustomFieldsScopedContext = useContext(assetModalContext);
  const initialCustomFieldKeys = state?.initialData?.customFieldKeys;
  const editedCustomFieldMap = state?.editState?.flattenedCustomFieldKeyValuesMap;
  const controlledCustomFieldsEnabled = !!BFG.brandfolderSettings?.controlled_custom_fields_enabled;
  const dependentCustomFieldsMap = state.initialData.dependentCustomFields?.reduce((acc, dcf) => {
    acc[dcf.child_key] = dcf;
    return acc;
  }, {} as DependentCustomField);
  const dependentFieldIds = dependentCustomFieldsMap && Object.keys(dependentCustomFieldsMap);

  const selectedLanguage = languagesMap.find((langDetails) => langDetails.locale === state.ugtLocale);
  const translating = selectedLanguage
    && state.ugtLocale !== BFG.locales.ugtLocaleDefault
    && BFG.multiLanguageAssetDetailsEnabled;

  const dispatchCustomFieldValue = (
    customFieldKeyId: string,
    existingCustomFieldMap: CustomFieldKeyValuesEntry,
    optionValue: ReactText,
    operation: CustomFieldPayloadOperations,
    customFieldValueId?: string,
    customFieldTouched?: boolean,
    customFieldRequired?: boolean,
  ): void => {
    const cfv = existingCustomFieldMap?.customFieldValues.find(({ key }) => key === customFieldValueId);
    const customFieldValueDispatch = dispatch as UpdateCustomFieldValuesDispatch;
    customFieldValueDispatch({
      type: ActionTypes.UpdateCustomFieldValues,
      payload: {
        operation,
        customFieldKeyId,
        customFieldRequired,
        customFieldTouched,
        customFieldValue: { key: customFieldValueId || '', ...cfv && cfv, value: optionValue.toString() }
      }
    });
  };

  const genHandleCreate = (
    customFieldKeyId: string,
    existingCustomFieldMap: CustomFieldKeyValuesEntry
  ) => (customFieldValue: CustomFieldValue | CustomFieldValue[], customFieldRequired?: boolean): void => {
    if (Array.isArray(customFieldValue)) {
      customFieldValue.forEach((tagValue) => {
        dispatchCustomFieldValue(
          customFieldKeyId,
          existingCustomFieldMap,
          tagValue.value,
          CustomFieldPayloadOperations.Create,
          tagValue.key,
          true, // customFieldTouched
          customFieldRequired
        );
      });
    } else {
      dispatchCustomFieldValue(
        customFieldKeyId,
        existingCustomFieldMap,
        customFieldValue.value,
        CustomFieldPayloadOperations.Create,
        customFieldValue.key,
        true, // customFieldTouched
        customFieldRequired
      );
    }
  };

  const genHandleDelete = (
    customFieldKeyId: string,
    existingCustomFieldMap: CustomFieldKeyValuesEntry
  ) => (customFieldValue: CustomFieldValue, customFieldRequired?: boolean): void => {
    dispatchCustomFieldValue(
      customFieldKeyId,
      existingCustomFieldMap,
      customFieldValue.value,
      CustomFieldPayloadOperations.Delete,
      customFieldValue.key,
      true, // customFieldTouched
      customFieldRequired
    );
  };

  const handleAddRow = (): void => {
    const customFieldKeyId = `create-cfk-${uuidv4()}`;
    const customFieldValueDispatch = dispatch as UpdateCustomFieldValuesDispatch;
    customFieldValueDispatch({
      type: ActionTypes.UpdateCustomFieldValues,
      payload: {
        operation: CustomFieldPayloadOperations.Create,
        customFieldKeyId,
        customFieldKeyName: '',
        customFieldValue: { key: '', value: '' }
      }
    });
  };

  const handleKeyUpdate = (customFieldKey: { id: string; name: string }): void => {
    const customFieldKeyDispatch = dispatch as UpdateCustomFieldKeyDispatch;

    customFieldKeyDispatch({
      type: ActionTypes.UpdateCustomFieldKey,
      payload: {
        customFieldKeyId: customFieldKey.id,
        customFieldKeyName: customFieldKey.name
      }
    });
  };

  const handleDeleteRow = (customFieldKeyId: string): void => {
    const deleteDispatch = dispatch as DeleteCustomFieldValuesDispatch;
    deleteDispatch({
      type: ActionTypes.DeleteCustomFieldValues,
      payload: {
        customFieldKeyId,
        deleteKey: true
      }
    });
  };

  const handleRemoveValues = (customFieldKeyId: string, customFieldRequired?: boolean): void => {
    const deleteDispatch = dispatch as DeleteCustomFieldValuesDispatch;
    deleteDispatch({
      type: ActionTypes.DeleteCustomFieldValues,
      payload: {
        customFieldKeyId,
        customFieldTouched: true,
        customFieldRequired
      }
    });
  };

  const genHandleUpdate = (
    customFieldKeyId: string,
    existingCustomFieldMap: CustomFieldKeyValuesEntry,
  ) => (customFieldValue: CustomFieldValue, customFieldRequired?: boolean): void => {
    let op = CustomFieldPayloadOperations.Update;

    if (!existingCustomFieldMap || existingCustomFieldMap.customFieldValues.length === 0) {
      op = CustomFieldPayloadOperations.Create;
    }

    dispatchCustomFieldValue(
      customFieldKeyId,
      existingCustomFieldMap,
      customFieldValue.value,
      op,
      customFieldValue.key,
      true, // customFieldTouched
      customFieldRequired
    );
  };

  const getCustomFieldsAsync = useCallback(async (): Promise<void> => {
    const getCustomFieldKeysAsync = async (): Promise<void> => {
      const customFieldKeysParams = {
        fields: 'multi_value_enabled',
        include: 'dependent_custom_fields',
        order: 'asc',
        per: MAX_PAGE_SIZE,
        sort_by: 'name', // eslint-disable-line @typescript-eslint/naming-convention
        ugt_locale: state.ugtLocale || undefined // eslint-disable-line @typescript-eslint/naming-convention
      };
      const options = { params: customFieldKeysParams, resourceKey: BFG.resource.key, resourceType: BFG.resource.type };
      const response = await getCustomFieldKeys(options);
      const initialDispatch = dispatch as InitialCustomFieldKeysDispatch;
      initialDispatch({ type: ActionTypes.FetchCustomFieldKeys, payload: { response } });
    };

    const listCustomFieldValuesAsync = async (): Promise<void> => {
      const queryParams = {
        per: MAX_PAGE_SIZE.toString(),
        include: 'custom_field_key',
        ugt_locale: state.ugtLocale || undefined, // eslint-disable-line @typescript-eslint/naming-convention
      };
      const response = await listCustomFieldValues(state.initialData.assetKey, queryParams);
      const initialDispatch = dispatch as InitialCustomFieldValuesDataDispatch;
      initialDispatch({ type: ActionTypes.FetchCustomFieldValues, payload: { response } });
    };

    if (!state.initialData.assetKey) {
      await getCustomFieldKeysAsync();
      const emptyCustomFieldValueList = { data: [] };
      const initialDispatch = dispatch as InitialCustomFieldValuesDataDispatch;
      initialDispatch({ type: ActionTypes.FetchCustomFieldValues, payload: { response: emptyCustomFieldValueList } });
    } else {
      await Promise.all([getCustomFieldKeysAsync(), listCustomFieldValuesAsync()]);
    }
  }, [state.initialData.assetKey, state.ugtLocale, dispatch]);

  useEffect(() => {
    if (state.initialData.customFieldKeys === null
      || state.initialData.flattenedCustomFieldKeyValuesMap === null
    ) {
      getCustomFieldsAsync();
    }
  }, [
    state.initialData.customFieldKeys,
    state.initialData.dependentCustomFields,
    state.initialData.flattenedCustomFieldKeyValuesMap,
    getCustomFieldsAsync
  ]);

  if (
    !state.initialData.customFieldKeys ||
    !state.initialData.flattenedCustomFieldKeyValuesMap
  ) {
    return <BFLoader />;
  }

  // these are custom field keys that have been added through the UI
  // new custom field keys are only allowed to be added when custom fields
  // are not controlled
  const newUncontrolledCustomFieldKeys = editedCustomFieldMap
    ? Object.keys(editedCustomFieldMap).filter((cfkId) => (
      !initialCustomFieldKeys.some((initialKey) => initialKey.id === cfkId)
    )) : [];

  const filteredCustomFieldKeys = !dependentFieldIds?.length ?
    initialCustomFieldKeys :
    initialCustomFieldKeys?.filter((cfkId) => {
      const parentKey = dependentCustomFieldsMap[cfkId.id]?.parent_key;
      const parentField = parentKey && editedCustomFieldMap[parentKey];
      const parentValues = parentField?.customFieldValues?.map((cfv) => cfv.value) || [];
      return (
        !dependentFieldIds?.includes(cfkId.id) ||
        parentValues?.includes(dependentCustomFieldsMap[cfkId.id]?.value)
      );
    });

  return (
    <div className="asset-modal-edit-custom-fields">
      <header className="custom-fields-list--heading">
        <div className="custom-fields-list--heading--key"><p><Trans>Key</Trans></p></div>
        <div className="custom-fields-list--heading--values"><p><Trans>Values</Trans></p></div>
      </header>
      <ul
        ref={listContainerRef}
        className={`custom-field-list bf-scroll-element ${controlledCustomFieldsEnabled ? 'max-height' : 'max-height-decrease'}`}
      >
        {newUncontrolledCustomFieldKeys.reverse().map((keyId, index) => {
          const existingCustomFieldMap = state?.editState?.flattenedCustomFieldKeyValuesMap?.[keyId];
          return (
            <CustomFieldKeyValueRow
              key={keyId}
              controlledCustomFieldsEnabled={controlledCustomFieldsEnabled}
              customFieldKey={{
                attributes: {
                  name: editedCustomFieldMap[keyId].customFieldKey.name,
                  allowed_values: [] // eslint-disable-line @typescript-eslint/naming-convention
                },
                id: keyId,
                type: 'custom_field_keys'
              }}
              errorState={containsSpecialCharacters(editedCustomFieldMap[keyId].customFieldKey.name) ? state.errorState : []}
              existingCustomFieldMap={existingCustomFieldMap}
              focused={index === 0 && newCustomFieldFocus}
              handleDeleteRow={handleDeleteRow}
              handleKeyUpdate={handleKeyUpdate}
              handleUpdate={genHandleUpdate(keyId, existingCustomFieldMap)}
              setNewCustomFieldFocus={setNewCustomFieldFocus}
              translating={translating}
            />
          );
        })}
        {editedCustomFieldMap && filteredCustomFieldKeys?.map((key) => {
          const existingCustomFieldMap = state?.editState?.flattenedCustomFieldKeyValuesMap?.[key.id];
          return (
            <CustomFieldKeyValueRow
              key={key.id}
              controlledCustomFieldsEnabled={controlledCustomFieldsEnabled}
              customFieldKey={key}
              existingCustomFieldMap={existingCustomFieldMap}
              handleCreate={genHandleCreate(key.id, existingCustomFieldMap)}
              handleDelete={genHandleDelete(key.id, existingCustomFieldMap)}
              handleRemoveValues={handleRemoveValues}
              handleUpdate={genHandleUpdate(key.id, existingCustomFieldMap)}
              overflowParentRef={listContainerRef}
              translating={translating}
            />
          );
        })}
      </ul>
      {!controlledCustomFieldsEnabled && (
        <SecondaryButton
          className="add-custom-field-button"
          icon="bff-plus"
          onClick={(): void => {
            handleAddRow();
            setNewCustomFieldFocus(true);
          }}
        >
          <Trans>Add Custom Field</Trans>
        </SecondaryButton>
      )}
    </div>
  );
};
