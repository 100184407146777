import { Trans } from '@lingui/macro';
import React, { useState } from 'react';

import { TertiaryIconButton } from '@components/library/button/index';
import { ActionsDropdown } from '@components/library/dropdown/index';
import { ActionDropdownItem } from '@components/library/dropdown_item/index';

import PortalForm from './portal_form';

interface PortalSettingsProps {
  editMode: boolean;
  libraryName: string;
  showSettingsDropdown: boolean;
  setShowSettingsDropdown: SetStateDispatch<boolean>;
  showForm: boolean;
  toggleEditMode: () => void;
  toggleForm: () => void;
}

const PortalSettings: React.FunctionComponent<PortalSettingsProps> = (props) => {
  const [closeDropdown, setCloseDropdown] = useState(false);

  const anchorElement = (
    <TertiaryIconButton
      className="ellipsis-button"
      icon="bff-ellipsis"
      iconSize={20}
    />
  );

  return (
    <>
      <ActionsDropdown
        anchorElement={anchorElement}
        className="portal-header__dropdown"
        closeCallback={(): void => setCloseDropdown(false)}
        openOnClick
        openOnHover={false}
        toggleDropdown={closeDropdown}
        userDevice={BFG.currentUser.device}
      >
        <ActionDropdownItem
          icon="bff-settings"
          name="portal-settings"
          onChoose={(): void => {
            props.toggleForm();
            setCloseDropdown(true);
          }}
        >
          <Trans>Portal Settings</Trans>
        </ActionDropdownItem>
        <ActionDropdownItem
          icon="bff-show"
          name="edit-mode"
          onChoose={(): void => {
            props.toggleEditMode();
            setCloseDropdown(true);
          }}
        >
          {props.editMode ? <Trans>Switch to Preview Mode</Trans> : <Trans>Switch to Edit Mode</Trans>}
        </ActionDropdownItem>
      </ActionsDropdown>
      {props.showForm && <PortalForm closeModal={props.toggleForm} libraryName={props.libraryName} />}
    </>
  );
};

export default PortalSettings;
