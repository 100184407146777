import { ButtonLooks, FontIcons, IconButton } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useContext } from 'react';

import { ExpandedViewCard } from './ExpandedViewCard';
import { isMimeTypeImgCompatible } from './helpers';
import { AssetViewModel } from './model';
import './styles/attachment-gallery.module.scss';
import classes from './styles/attachment-gallery.module.scss';
import { ViewOnlyContext } from './GalleryView';
export interface ExpandedViewProps {
  asset: AssetViewModel;
  attachmentId?: string;
  closeExpandedView: () => void;
}

export const ExpandedView: FunctionComponent<ExpandedViewProps> = ({
  asset,
  attachmentId = null,
  closeExpandedView,
}) => {
  const downloadsDisabled = useContext(ViewOnlyContext);
  let src: string;

  if (attachmentId) {
    const selectedAttachment = asset.attachments.find(
      ({ id }) => id === attachmentId
    );
    const {
      cdn_url: cdnUrl,
      mimetype,
      thumbnail_url: thumbnailUrl,
      url,
      view_thumbnail_retina: viewThumbnailRetina,
    } = selectedAttachment.attributes;
    src = isMimeTypeImgCompatible(mimetype) && !downloadsDisabled
      ? cdnUrl || viewThumbnailRetina || url
      : viewThumbnailRetina || thumbnailUrl;
  } else {
    src = asset.attributes.thumbnail_url;
  }

  const handleCloseClick = (): void => {
    const body = document.querySelector('body');
    body.style.overflow = 'visible';
    closeExpandedView();
  };

  return (
    <div className={classes.expandedView} data-testid="expanded-view">
      <IconButton
        className={classes.closeButton}
        icon={FontIcons.Close}
        iconProps={{
          iconSize: 20,
        }}
        label={t`Icon Close Button`}
        loaderLabel={t`Loading`}
        look={ButtonLooks.White}
        onClick={(): void => handleCloseClick()}
        showIconButton
        type="button"
      />
      {src && (
        <div className={classes.expandedViewImageContainer}>
          <ExpandedViewCard
            alt={asset.attributes.name}
            asset={asset}
            attachmentId={attachmentId}
            src={src}
          />
        </div>
      )}
    </div>
  );
};
