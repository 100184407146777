/* eslint-disable react/require-default-props */
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { create } from '@api/v4/resources/conversion_presets';
import Checkbox from '@components/common/checkbox/main';
import { ListDropdown } from '@components/library/dropdown';

const PresetForm = ({ resourceType, resourceKey, onAddPreset }) => {
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [aspectWidth, setAspectWidth] = useState(1);
  const [aspectHeight, setAspectHeight] = useState(1);
  const [width, setWidth] = useState(200);
  const [height, setHeight] = useState(200);
  const [format, setFormat] = useState('Unset');
  const [aspectOnly, setAspectOnly] = useState(false);
  const [error, setError] = useState(null);

  const calculatedAspectRatio = parseInt(aspectWidth, 10) / parseInt(aspectHeight, 10);

  const submit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    setError(null);

    create({
      resourceType,
      resourceKey,
      name,
      aspectWidth: aspectOnly ? aspectWidth : undefined,
      aspectHeight: aspectOnly ? aspectHeight : undefined,
      width,
      height,
      format: format === 'Unset' ? null : format,
    }).then(() => {
      onAddPreset();
      setName('');
      setAspectWidth(1);
      setAspectHeight(1);
      setWidth(200);
      setHeight(200);
      setFormat(null);
      setAspectOnly(false);
    }).catch(({ errors }) => {
      setError(errors);
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const updateWidth = (newWidth) => {
    if (aspectOnly) {
      const newHeight = Math.round(parseInt(newWidth, 10) / calculatedAspectRatio);
      setHeight(Number.isNaN(newHeight) || newHeight <= 0 ? '' : newHeight);
    }

    setWidth(newWidth);
  };

  const updateHeight = (newHeight) => {
    if (aspectOnly) {
      const newWidth = Math.round(parseInt(newHeight, 10) * calculatedAspectRatio);
      setWidth(Number.isNaN(newWidth) || newWidth <= 0 ? '' : newWidth);
    }

    setHeight(newHeight);
  };

  useEffect(() => {
    // Force the height value to update to match the aspect ratio once they toggle that on
    if (aspectOnly) {
      updateWidth(width);
    }
  }, [aspectWidth, aspectHeight, aspectOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form
      className="cropping--preset-form"
      onSubmit={submit}
    >
      <div>
        <label
          className="brandfolder-label"
          htmlFor="preset-name"
        >
          <Trans>Preset Name</Trans>
          <input
            className="brandfolder-input"
            data-testid="preset-name"
            disabled={submitting}
            id="preset-name"
            name="preset-name"
            onChange={({ target }) => setName(target.value)}
            required
            type="text"
            value={name}
          />
        </label>

        <label
          className="brandfolder-label"
          htmlFor="preset-format"
        >
          <Trans>Download Format</Trans>
          <ListDropdown
            data-testid="preset-format"
            disabled={submitting}
            name="preset-format"
            onChange={({ value }) => setFormat(value)}
            options={[
              { value: 'Unset', label: t`Allow User To Select`},
              { value: 'JPG', label: 'JPG' },
              { value: 'PNG', label: 'PNG' },
            ]}
            searchable={false}
            value={format}
            virtualizeOptions={false}
          />
        </label>
      </div>
      <div>
        <label
          className="brandfolder-label"
          htmlFor="preset-width"
        >
          <Trans>Width (px)</Trans>
          <input
            className="brandfolder-input"
            data-testid="preset-width"
            disabled={submitting}
            id="preset-width"
            name="preset-width"
            onChange={({ target }) => updateWidth(target.value)}
            pattern="\d+"
            required={!aspectOnly}
            type="number"
            value={width}
          />
        </label>
        <label
          className="brandfolder-label"
          htmlFor="preset-height"
        >
          <Trans>Height (px)</Trans>
          <input
            className="brandfolder-input"
            data-testid="preset-height"
            disabled={submitting}
            id="preset-height"
            name="preset-height"
            onChange={({ target }) => updateHeight(target.value)}
            pattern="\d+"
            required={!aspectOnly}
            type="number"
            value={height}
          />
        </label>
      </div>

      <div className="preset--aspect-ratio-container">
        <div>
          <label
            className="brandfolder-label"
            htmlFor="preset-aspect-ratio"
          >
            <Trans>Aspect Ratio</Trans>
          </label>
          <div className="inputs-container">
            <input
              className="brandfolder-input"
              data-testid="preset-aspect-width"
              disabled={submitting || !aspectOnly}
              id="preset-aspect-width"
              name="preset-aspect-width"
              onChange={({ target }) => setAspectWidth(target.value)}
              pattern="\d+"
              required={aspectOnly}
              type="number"
              value={aspectWidth}
            />
            <span className="preset--aspect-ratio-divider">:</span>
            <input
              className="brandfolder-input"
              data-testid="preset-aspect-height"
              disabled={submitting || !aspectOnly}
              id="preset-aspect-height"
              name="preset-aspect-height"
              onChange={({ target }) => setAspectHeight(target.value)}
              pattern="\d+"
              required={aspectOnly}
              type="number"
              value={aspectHeight}
            />
          </div>
        </div>
        <Checkbox
          checked={aspectOnly}
          label={t`Lock aspect ratio`}
          size="md"
          toggle={() => setAspectOnly(!aspectOnly)}
        />
      </div>

      <div className={`cropping--button-container ${error && 'has-error'}`}>
        {error && (
          <p className="error-message">{error}</p>
        )}

        <button
          className="button primary sm"
          data-testid="create-preset"
          disabled={submitting}
          type="submit"
        >
          <Trans>Create Preset</Trans>
        </button>
      </div>
    </form>
  );
};

PresetForm.propTypes = {
  resourceKey: PropTypes.string.isRequired,
  onAddPreset: PropTypes.func,
  resourceType: PropTypes.string,
};

PresetForm.defaultProps = {
  onAddPreset: () => {},
  resourceType: 'brandfolder',
};

export default PresetForm;
