import { Plural, Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { rollbackVersions } from '@api/v4/attachments/versions';
import { handleUploadAssetAttachment } from '@components/common/filestack_uploader/helpers';
import { UploadArea } from '@components/common/filestack_uploader/UploadArea';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';

import TimelineEvent from './timeline_event';

import './styles/main.scss';

const Versions = ({
  activeAttachment,
  initializeSidebar,
  setActiveTab,
  setActiveVersion,
  updateFetchControllers,
  users,
  versionCards,
}) => {
  const [isVersionUploading, setIsVersionUploading] = useState(false);
  const [isVersionUpdating, setIsVersionUpdating] = useState(false);

  const makeCurrentVersion = (versionKey) => {
    setIsVersionUpdating(true);
    rollbackVersions({ versionKey }, updateFetchControllers)
      .then((response) => {
        setIsVersionUpdating(false);
        if (response.data) {
          initializeSidebar();
        }
      });
  };

  const currentVersionIndex = versionCards.findIndex((card) => card.type === 'version');
  const quantityVersions = versionCards.filter((card) => card.type === 'version').length;
  const currentVersionFilename = versionCards[currentVersionIndex].version_details.filename;

  return (
    <div className="versions-container bf-scroll-element">
      <div className="timeline-header-container">
        <div className="header-copy">
          <h4>
            <Plural
              one={<Trans>1 previous version of <span className="filename">{currentVersionFilename}</span></Trans>}
              other={
                <Trans>
                  {quantityVersions} previous versions of <span className="filename">{currentVersionFilename}</span>
                </Trans>
              }
              value={quantityVersions}
            />
          </h4>
        </div>
        {!BFG.hasFeature('uppy_uploader_features') && (
          <UploadArea
            id={`version-status-uploader-${activeAttachment.id}`}
            onUploadDone={(files) => {
              handleUploadAssetAttachment({
                attachment: activeAttachment.attributes,
                attachmentKey: activeAttachment.id,
                files,
                onSuccess: initializeSidebar,
                setLoading: setIsVersionUploading
              })
            }}
            pickerOptions={{
              maxFiles: 1
            }}
            text={t`To upload a new version of this attachment, drag a file here or click to browse`}
            uploadType={UploadAreaTypes.AttachmentVersionStatus}
          />
        )}
      </div>
      <div className="timeline-events">
        {versionCards.map((event, i) => (
          <TimelineEvent
            key={event.created_at}
            isCurrentVersion={i === currentVersionIndex}
            isVersionUpdating={isVersionUpdating}
            makeCurrentVersion={makeCurrentVersion}
            setActiveTab={setActiveTab}
            setActiveVersion={setActiveVersion}
            users={users}
            version={event}
            versionUploading={isVersionUploading}
          />
        ))}
      </div>
    </div>
  );
};

export default Versions;

Versions.propTypes = {
  activeAttachment: PropTypes.shape({
    attributes: PropTypes.object,
    id: PropTypes.string
  }).isRequired,
  asset: PropTypes.shape({
    asset_key: PropTypes.string
  }).isRequired,
  initializeSidebar: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setActiveVersion: PropTypes.func.isRequired,
  updateFetchControllers: PropTypes.func.isRequired,
  users: PropTypes.shape({}).isRequired,
  versionCards: PropTypes.arrayOf(PropTypes.shape({
    version_details: PropTypes.shape({
      filename: PropTypes.string,
    })
  })).isRequired,
};
