import { Trans, t } from '@lingui/macro';
import React from 'react';

import { ColorData } from '@api/v4/assets/assetTypes';
import ClipboardCopy from '@components/common/clipboard_copy/main';
import Tooltip from '@components/common/tooltip/main';

import './styles/org_color_asset_modal.scss';

type PantoneType = 'Coated' | 'Uncoated';

const renderHex = (hex: string): JSX.Element => (
  <li>
    <div className="color-value">
      <span className="color-label">
        <Trans>Hex</Trans>:
      </span>
      <p>#{hex}</p>
    </div>
    <p className="s-copy-color">
      <ClipboardCopy check showFeedbackAsTooltip={false} textToCopy={`#${hex}`}>
        <Trans>Copy Hex</Trans>
      </ClipboardCopy>
    </p>
  </li>
);

const renderRGB = (assetDataString: string): JSX.Element => (
  <li>
    <div className="color-value">
      <span className="color-label">RGB:</span>
      <p>{assetDataString}</p>
    </div>
    <p className="s-copy-color">
      <ClipboardCopy check showFeedbackAsTooltip={false} textToCopy={assetDataString}>
        <Trans>Copy RGB</Trans>
      </ClipboardCopy>
    </p>
  </li>
);

const renderCMYK = (assetDataString: string): JSX.Element => (
  <li>
    <div className="color-value">
      <span className="color-label">CMYK:{' '}
        <Tooltip
          placement="right"
          tooltipContent={
            t`Displayed color for CMYK is approximate. Consider printing CMYK color before finalizing CMYK color values.`
          }
          tooltipId="cmyk-view"
          type="light"
        >
          <span className="bff-warning" />
        </Tooltip>
      </span>
      <p>{assetDataString}</p>
    </div>
    <p className="s-copy-color">
      <ClipboardCopy check showFeedbackAsTooltip={false} textToCopy={assetDataString}>
        <Trans>Copy CMYK</Trans>
      </ClipboardCopy>
    </p>
  </li>
);

const renderPantone = (pantone: string, pantoneType: PantoneType): JSX.Element => {
  const pantoneLabel = pantoneType === 'Coated' ? <Trans>Pantone Coated</Trans> : <Trans>Pantone Uncoated</Trans>;

  return (
    <li>
      <div className="color-value">
        <span className="color-label">
          {pantoneLabel}:
        </span>
        <p>{pantone}</p>
      </div>
      <p className="s-copy-color">
        <ClipboardCopy showFeedbackAsTooltip={false} textToCopy={pantone}>
          <Trans>Copy Pantone</Trans>
        </ClipboardCopy>
      </p>
    </li>
  );
};

const ColorDetails = ({ assetData }): JSX.Element => {
  const { b, c, g, hex, k, m, pantone, pantone_u, r, y } = assetData as ColorData;

  return (
    <ul className="m-show-full-display-color color-list">
      {!!hex && renderHex(hex)}
      {!!r && !!g && !!b && renderRGB(`${r} / ${g} / ${b}`)}
      {!!c && !!m && !!y && !!k && renderCMYK(`${c} / ${m} / ${y} / ${k}`)}
      {!!pantone && renderPantone(pantone, 'Coated')}
      {!!pantone_u && renderPantone(pantone_u, 'Uncoated')}
    </ul>
  );
};

export default ColorDetails;
