import { Trans } from '@lingui/macro';
import React from 'react';

import LanguageMenuDropdown from '@components/common/language_menu/LanguageMenuDropdown';
import {
  Locale,
} from '@components/common/language_menu/languagesMap';

interface UGTLocaleDefaultProps {
  handleUpdateLocaleDefault: (localeDefault: Locale) => void;
  localeDefault: Locale;
}

const UGTLocaleDefault = ({
  handleUpdateLocaleDefault,
  localeDefault,
}: UGTLocaleDefaultProps): JSX.Element => {
  const updateLocaleDefault = (selectedLocaleDefault: Locale): boolean => {
    if (selectedLocaleDefault === localeDefault) return false;

    handleUpdateLocaleDefault(selectedLocaleDefault);
    return true;
  };

  return (
    <div className="advanced-settings-row">
      <h4 className="quarter-column setting-name"><Trans>Default Language</Trans></h4>
      <LanguageMenuDropdown
        allowAllLocales
        onLocaleChange={updateLocaleDefault}
        openOnHover={false}
        selectedLocale={localeDefault}
        showSelectedLocaleName
        wrapperClassnames="right-container default-locales"
      />
    </div>
  );
};

export default UGTLocaleDefault;
