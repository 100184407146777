import { FontIcon } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import {
  CustomizedTemplateApprovalStatuses,
  DesignHuddleProject
} from '@api/v4/private/resources/design_huddles';

import { APPROVAL_STATUS_STRINGS } from './helpers';

interface TitleDetailsProps {
  approvalStatus: CustomizedTemplateApprovalStatuses;
  project: DesignHuddleProject | null;
  projectView: boolean;
  templateView: boolean;
}

export const TitleDetails: FunctionComponent<TitleDetailsProps> = ({
  approvalStatus,
  project,
  projectView,
  templateView
}) => (
  <div className={classnames({
    'project__title': true, // eslint-disable-line @typescript-eslint/naming-convention
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'project__title--stacked': BFG.brandfolderSettings?.custom_templates_approvals_required
  })}>
    {project && (
      <h1
        className="project__heading"
        id="project-title"
        title={project.project_title}
      >
        {project.project_title}
      </h1>
    )}
    <div className="project__title__details">
      <p
        className={classnames({
          'file-type-pill': true,
          'file-type-pill--template': templateView,
          'file-type-pill--project': projectView,
          'file-type-pill--approval-sibling': approvalStatus !== CustomizedTemplateApprovalStatuses.NoApprovalRequested
        })}
        id="project-type"
      >
        {templateView ? <Trans>Template</Trans> : <Trans>Project</Trans>}
      </p>
      {templateView && approvalStatus !== CustomizedTemplateApprovalStatuses.NoApprovalRequested && (
        <span className="template__approval-status">
          <FontIcon aria-hidden icon={APPROVAL_STATUS_STRINGS()[approvalStatus]?.fontIcon} />
          <span className="template__approval-status__label">{APPROVAL_STATUS_STRINGS()[approvalStatus]?.label}</span>
        </span>
      )}
    </div>
  </div>
);
