import fetchJSON from '@api/api_helper';

const url = (attachmentKey: string): string => `/api/v4/private/attachments/${attachmentKey}/blitline_metadata_update`;

export interface MetadataAttributes {
  creator?: string;
  description?: string;
  headline?: string;
  keywords?: string;
  title?: string;
}

const updateMetdata = (
  attachmentKey: string,
  attributes: MetadataAttributes
): Promise<Response> => {
  const appendedOptions = {
    method: 'POST',
    body: {
      data: {
        attributes
      }
    }
  };
  return fetchJSON(url(attachmentKey), appendedOptions, () => { /* no op */ });
};

export default updateMetdata;
