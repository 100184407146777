import { TileButton, TileButtons } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import createAssets from '@api/v4/assets';
import { AssetsResponse } from '@api/v4/assets/assetTypes';
import { searchStatus, StandardSearchField } from '@components/library/search-field';
import { CanvaButtonApiV2, CanvaListOption } from '@components/show_page/sections/asset/canva/CanvaTypes';
import { getFilenameFromUrl, getNameFromFilename, getTemplateTypeOptions } from '@components/show_page/sections/asset/canva/helper';

interface CreateCanvaDesignProps {
  canvaApi: CanvaButtonApiV2;
  onAssetProcessing: (numAssets: number) => void;
  onNewAssets: (response: AssetsResponse) => void;
  sectionKey: string;
  setShowCreateNewAssetDialog: SetStateDispatch<boolean>;
}

export const CreateCanvaDesign: FunctionComponent<CreateCanvaDesignProps> = (props) => {
  const { canvaApi, onAssetProcessing, onNewAssets, sectionKey, setShowCreateNewAssetDialog } = props;

  const [selectedOption, setSelectedOption] = useState<CanvaListOption | null>(null);
  const [query, setQuery] = useState('');

  const onDesignPublish = useCallback(async ({ exportUrl, designId }): Promise<void> => {
    // Triggered when design is published to an image
    // Save the image to your server as the exportUrl will expire shortly.

    const source = 'canva';
    const externalId = `canva::${designId}`;
    const filename = getFilenameFromUrl(exportUrl as string);
    const name = getNameFromFilename(filename);
    const data = {
      attributes: [{
        name,
        attachments: [
          {
            external_id: externalId,
            filename,
            source,
            url: exportUrl,
          }
        ],
      }]
    };
    try {
      onAssetProcessing(1);
      const response = await createAssets({
        url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/assets?fast_jsonapi=true`,
        section_key: sectionKey, // eslint-disable-line @typescript-eslint/naming-convention
        data
      });
      if (response) {
        Notify.create({
          type: 'success',
          title: t`Asset Created`,
          body: t`The template you saved from Canva has been added.`
        });
        onNewAssets(response as AssetsResponse);
      } else {
        throw new Error();
      }
    } catch (e) {
      Notify.create({
        type: 'error',
        title: t`An error occurred while saving from Canva. Please try again.`,
      });
    } finally {
      onAssetProcessing(0);
      setSelectedOption(null);
      setShowCreateNewAssetDialog(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedOption) {
      canvaApi.createDesign({
        type: selectedOption.value as string,
        design: {
          type: selectedOption.value as string,
        },
        editor: {
          fileType: selectedOption?.fileType,
          publishLabel: t`Save to Brandfolder`
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onDesignPublish
      });
    }
  }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const options = getTemplateTypeOptions(query);

  return (
    <>
      <StandardSearchField
        ariaSearchLabel={t`Search Canva designs`}
        className="create-new-asset-dialog__search"
        id="canva-options-search"
        label={t`Search design types`}
        name="canva-options-search"
        onChange={(e: InputChangeEvent): void => setQuery(e.target.value)}
        placeholder={t`Search designs`}
        searchStatus={searchStatus({
          isLoading: false,
          resultQuantity: options.length,
          resultsShown: query.length > 0
        })}
        showLabel={false}
        showSearchStatus={query.length > 0 && options.length === 0}
      />
      {options.length > 0 && (
        <TileButtons className="create-new-asset-dialog__canva-design-types">
          {options.map((option) => (
            <TileButton
              key={option.value}
              className="create-new-asset-dialog__canva-design-type"
              heading={option.label}
              onClick={(): void => {
                setSelectedOption(option);
              }}
            />
          ))}
        </TileButtons>
      )}
    </>
  );
};
