import { t } from '@lingui/macro';

import { FetchMethods, UseFetchOptions } from '@api/ApiHelper';
import {
  getIntegrationsContentSyncApiKey,
  getIntegrationsContentSyncBrandfolderKey,
  getIntegrationsContentSyncResourceKey,
  getIntegrationsContentSyncUrl
} from '@api/contentsync';

interface PopupCenter {
  url: string;
  title: string;
  w: number;
  h: number;
}

// Adapted from https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
// TODO - review this helper to remove eslint-disable stuff
export const popupCenter = ({
  url,
  title = '_blank',
  w,
  h
}: PopupCenter): Window => {
  // Fixes dual-screen position                            Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth // eslint-disable-line no-nested-ternary
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width; // eslint-disable-line no-restricted-globals

  const height = window.innerHeight // eslint-disable-line no-nested-ternary
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height; // eslint-disable-line no-restricted-globals

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const params = `width=${w / systemZoom},height=${h / systemZoom},top=${top},left=${left}`;

  const newWindow: Window = window.open(url, title, params);

  return newWindow;
};

const fetchOptions = (apiKey: string, method: FetchMethods, smarWorkspaceId?: number): UseFetchOptions => ({
  customToken: getIntegrationsContentSyncApiKey(apiKey),
  body: {
    data: {
      attributes: {
        brandfolder_key: getIntegrationsContentSyncBrandfolderKey(), // eslint-disable-line @typescript-eslint/naming-convention
        collection_key: getIntegrationsContentSyncResourceKey(), // eslint-disable-line @typescript-eslint/naming-convention
        ...smarWorkspaceId && { smar_workspace_id: smarWorkspaceId }, // eslint-disable-line @typescript-eslint/naming-convention
      }
    }
  },
  fetchOnMount: false,
  method,
  queuePriorityHigh: false,
  url: method === 'POST'
    ? `${getIntegrationsContentSyncUrl()}/smartsheet-connect`
    : `${getIntegrationsContentSyncUrl()}/smartsheet-connect/reset-auth`,
});

export const getLinkOptions = (orgApiKey: string): UseFetchOptions => ({
  customToken: getIntegrationsContentSyncApiKey(orgApiKey),
  queuePriorityHigh: false,
  url: `${getIntegrationsContentSyncUrl()}/smartsheet-connect?brandfolder_key=${getIntegrationsContentSyncBrandfolderKey()}&collection_key=${getIntegrationsContentSyncResourceKey()}`
});

export const postOptions = (orgApiKey: string): UseFetchOptions => (
  fetchOptions(orgApiKey, 'POST')
);

export const setSheetOptions = (orgApiKey: string, smarWorkspaceId: number): UseFetchOptions => (
  fetchOptions(orgApiKey, 'POST', smarWorkspaceId)
);

export const resetWorksheetOptions = (orgApiKey: string): UseFetchOptions => (
  fetchOptions(orgApiKey, 'PUT')
);

export const connectionSuccessfulNotify = (successCopy: string): void => {
  Notify.create({
    body: successCopy,
    title: t`Success!`,
    type: 'success'
  });
};

export const connectionUnsuccessfulNotify = (): void => {
  Notify.create({
    body: t`Smartsheet is not connected to your Brandfolder Workspace`,
    title: t`Connection Unsuccessful!`,
    type: 'error'
  });
};

export const errorNotify = (): void => {
  Notify.create({
    body: t`Something went wrong. Please try again or contact support`,
    title: t`Error`,
    type: 'error'
  });
};
