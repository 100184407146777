/* eslint-disable max-len */
import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { getIntegrationsContentSyncUrl } from '@api/contentsync';
import { PrimaryButton, TertiaryButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { FontIcons } from '@components/library/icon';

import {
  getLinkOptionsForConnect,
  setSheetOptions,
} from '@components/profile/helpers';
import { popupCenter } from '@components/profile/helpers';
import {
  AuthUrlResponse,
  ConnectResponseServer,
  WorksheetDatum,
  InsightsDataSyncResponse,
  BrandfoldersResponseObject,
} from '@components/profile/InsightsTypes';

import {
  connectionSuccessfulNotify,
  connectionUnsuccessfulNotify,
} from '../workspace/helpers';

import { ConnectDeniedDialog } from './ConnectDeniedDialog';

import { ConnectionSetupDialog } from './ConnectionSetupDialog';
import { ConnectSuccessDialog } from './ConnectSuccessDialog';

import './styles/integrations-smartsheet-connect.scss';

const successCopy = (): string => t`Smartsheet is connected to your Brandfolder Workspace`;
const successCopyAuthorized = (): string => t`Authorization is complete`;

interface IntegrationsSmartsheetConnectProps {
  brandfoldersData: BrandfoldersResponseObject[];
  orgApiKey: string;
  stopSyncingData: () => void;
}

export const IntegrationsSmartsheetConnect: FunctionComponent<IntegrationsSmartsheetConnectProps> = (
  { brandfoldersData,
    orgApiKey,
    stopSyncingData
  }) => {
  const [renderDialogHTML, setRenderDialogHTML] = useState(false);
  const [isSelectionDialogOpen, setIsSelectionDialogOpen] = useState(false);
  const [isCtaDialogOpen, setIsCtaDialogOpen] = useState(false);
  const [isConnectionSuccessDialogOpen, setIsConnectionSuccessDialogOpen] = useState(false);
  const [renderStopSyncingDataHTML, setrenderStopSyncingDataHTML] = useState(false);
  const [isStopSyncingDataDialogOpen, setIsStopSyncingDataDilogOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<WorksheetDatum>(null);
  const [workspaceDropdownState, setWorkspaceDropdownState] =
    useState<WorksheetDatum>(null);

  const getUserAuthenticationResponse = useFetch<InsightsDataSyncResponse>({
    customToken: orgApiKey,
    method: 'GET',
    queuePriorityHigh: false,
    url: `${getIntegrationsContentSyncUrl()}/insights-connect`
  });
  const getSmartsheetUrlResponse = useFetch<AuthUrlResponse>(
    getLinkOptionsForConnect(orgApiKey, 'POST')
  );
  const setWorksheetResponse = useFetch<ConnectResponseServer>(
    setSheetOptions(orgApiKey, workspaceDropdownState?.id)
  );

  const { data: userAuthenticationData } = { ...getUserAuthenticationResponse?.response };
  const { url: smartsheetUrl } = { ...getSmartsheetUrlResponse?.response?.data };
  const { data: setWorksheetData } = { ...setWorksheetResponse?.response };

  const oauthUrl: string | undefined = smartsheetUrl;
  let dataSmartsheetWorksheets: WorksheetDatum[] | undefined = userAuthenticationData?.smartsheet_configuration?.available_workspaces;
  const smarSheetPermalink: string | undefined = selectedWorkspace?.permalink;
  const isAuthed: boolean | undefined = userAuthenticationData?.smartsheet_configuration?.has_authenticated;

  if (userAuthenticationData && !selectedWorkspace) {
    dataSmartsheetWorksheets =
      userAuthenticationData.smartsheet_configuration.available_workspaces;
  }

  if (userAuthenticationData?.smartsheet_configuration?.default_workspace_id &&
    !selectedWorkspace
  ) {
    const selectedWorkspaceDatum =
      dataSmartsheetWorksheets.find((workspace) => {
        return (
          workspace.id ===
          Number(userAuthenticationData.smartsheet_configuration.default_workspace_id)
        );
      });
    setSelectedWorkspace(selectedWorkspaceDatum);
    setIsSelectionDialogOpen(false);
  }

  let oauthWindow: Window;

  const handleReceiveOauthMessage = (e: MessageEvent): void => {
    if (!e.data || !Object.keys(e.data).includes('authorized')) return;

    if (oauthWindow && !oauthWindow.closed) oauthWindow.close();
    window.removeEventListener('message', handleReceiveOauthMessage);

    if (e?.data?.authorized) {
      if (!smarSheetPermalink) {
        getUserAuthenticationResponse.fetch();
        connectionSuccessfulNotify(successCopyAuthorized());
        setIsSelectionDialogOpen(true);
      } else {
        connectionSuccessfulNotify(successCopy());
      }

      getSmartsheetUrlResponse.fetch();
    } else {
      connectionUnsuccessfulNotify();
      setIsCtaDialogOpen(true);
    }
  };

  const handleSmartsheetConnectClick = (): void => {
    if (userAuthenticationData.smartsheet_configuration.has_authenticated === false) {
      if (oauthWindow && !oauthWindow.closed) {
        oauthWindow.focus();
      } else {
        const popupWidth = 740;
        const popupHeight = 530;
        oauthWindow = popupCenter({
          url: oauthUrl,
          title: '_blank',
          w: popupWidth,
          h: popupHeight,
        });

        window.addEventListener('message', handleReceiveOauthMessage);
      }
    }
    if (!smarSheetPermalink && dataSmartsheetWorksheets && !selectedWorkspace) {
      setIsSelectionDialogOpen(true);
      return;
    }
  };

  const handleSelectedWorkspace = (): void=> {
    setWorksheetResponse.fetch();
    getUserAuthenticationResponse.fetch();
  };

  const handleViewBrandfoldersToSync = (): void => {
    setIsConnectionSuccessDialogOpen(false);
    window.location.reload();
  };

  const handleStopSyncingDataClick = (): void => {
    setIsStopSyncingDataDilogOpen(true);
    setrenderStopSyncingDataHTML(true);
  };

  const handleStopSyncingDataConfirmed = (): void => {
    stopSyncingData();
    setIsStopSyncingDataDilogOpen(false);
  };

  const isConnected = !!isAuthed && !!smarSheetPermalink && !!selectedWorkspace;

  useEffect(() => {
    if (setWorksheetData?.smartsheet_configuration?.has_authenticated && setWorksheetData?.smartsheet_configuration?.default_workspace_id) {
      setIsSelectionDialogOpen(false);
      setIsConnectionSuccessDialogOpen(true);
    }
  }, [setWorksheetData]);

  return (
    <>
      {isConnected ? (
        <PrimaryButton
          className="smartsheet-disconnect-button"
          disabled={Object.keys(userAuthenticationData?.smartsheet_configuration || {})?.length === 0}
          onClick={(): void => {
            handleStopSyncingDataClick();
          }}
        >
          <Trans>Stop data syncing</Trans>
        </PrimaryButton>)
        :
        (<PrimaryButton
        className="smartsheet-connect-button"
        disabled={Object.keys(userAuthenticationData?.smartsheet_configuration || {})?.length === 0}
        icon={FontIcons.Smartsheet}
        onClick={(): void => {
          handleSmartsheetConnectClick();
          if (!renderDialogHTML) setRenderDialogHTML(true);
        }}
      >
        <Trans>Connect to Smartsheet</Trans>
      </PrimaryButton>)
      }
      {renderStopSyncingDataHTML && (
        <StandardDialog
          dialogBodyClassName="stop-all-data-sync-dialog-body samr-insights-dialog-body"
          dialogHeaderClassName="stop-all-data-sync-dialog-header smar-insights-dialog-header"
          height={350}
          id="stop-all-data-sync"
          open={isStopSyncingDataDialogOpen}
          setOpen={setIsStopSyncingDataDilogOpen}
          showFooter={false}
          title={t`Stop all data syncing`}
          width={720}
        >
          <div className="dialog-text-wrapper">
            <h3 className="dialog-text"><Trans>Are you sure you want to prevent all future data syncing to Smartsheet?</Trans></h3>
            <p className="dialog-subtext"><Trans>Previously synced data sheets will be kept, but they will no longer update.</Trans></p>
          </div>
          <div className="dialog-button-wrapper">
            <TertiaryButton className="keep-syncing-button" onClick={(): void => setIsStopSyncingDataDilogOpen(false)}><Trans>Keep Syncing</Trans></TertiaryButton>
            <PrimaryButton className="stop-all-data-sync-button" onClick={(): void => handleStopSyncingDataConfirmed()}><Trans>Stop all data syncing</Trans></PrimaryButton>
          </div>
        </StandardDialog>
      )}
      {renderDialogHTML && (
        <>
          <ConnectionSetupDialog
            dataSmartsheetWorksheets={dataSmartsheetWorksheets}
            handleSelectedWorkspace={handleSelectedWorkspace}
            isConnected={isConnected}
            isSelectionDialogOpen={isSelectionDialogOpen}
            setIsSelectionDialogOpen={setIsSelectionDialogOpen}
            setSelectedWorkspace={setSelectedWorkspace}
            setWorksheetResponse={setWorksheetResponse}
            setWorkspaceDropdownState={setWorkspaceDropdownState}
            workspaceDropdownState={workspaceDropdownState}
          />
          <ConnectSuccessDialog
            brandfoldersData={brandfoldersData}
            handleViewBrandfoldersToSync={handleViewBrandfoldersToSync}
            isConnectionSuccessDialogOpen={isConnectionSuccessDialogOpen}
            setIsConnectionSuccessDialogOpen={setIsConnectionSuccessDialogOpen}
          />
          <ConnectDeniedDialog handleSmartsheetConnectClick={handleSmartsheetConnectClick}
            isCtaDialogOpen={isCtaDialogOpen}
            setIsCtaDialogOpen={setIsCtaDialogOpen}
          />
        </>
      )}
    </>
  );
};
