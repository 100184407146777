import React from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import AssetManager from '@components/workbench/individual/asset_manager/main';
import Palette from '@components/workbench/individual/palette/palette';

class PaletteTool extends React.Component {
  render() {
    return (
      <I18nProviderWrapper>
        <AssetManager extractColors>
          <Palette />
        </AssetManager>
      </I18nProviderWrapper>
    );
  }
}

export default PaletteTool;
