import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import { PrimaryButton } from '@components/library/button/index';
import { isGettyClient } from '@helpers/getty-strings';

import TileForm from './tile_form';

interface TilesEmptyTemplateProps {
  triggerCardsRefresh: () => void;
}

const TilesEmptyTemplate: FunctionComponent<TilesEmptyTemplateProps> = ({
  triggerCardsRefresh
}) => {
  const [showForm, setShowForm] = useState(false);
  const toggleForm = (): void => setShowForm((prevState) => !prevState);

  return (
    <section className="tiles">
      <div className="tiles__item tiles__item--two-thirds-width">
        <div className="tiles__item--template-description">
          <span className="bff-face-sad" />
          <h3><Trans>You don't have any navigation tiles yet!</Trans></h3>
          <p>
            {isGettyClient()
              ? <Trans>Use navigation tiles to link out to Libraries, Collections, or external websites.</Trans>
              : <Trans>Use navigation tiles to link out to Brandfolders, Collections, or external websites.</Trans>
            }
          </p>
          <p><Trans>Don’t forget to customize your tile with an image!</Trans></p>
          <PrimaryButton
            className="tile-create-button"
            icon="bff-plus"
            onClick={toggleForm}
          >
            <Trans>Create Tile</Trans>
          </PrimaryButton>
        </div>
      </div>
      <div className="tiles__item tiles__item--one-third-width">
        <div className="tiles__item--image placeholder" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y18n-8wjz1k-fi9mwk/image-placeholder.auto")' }} />
        <img alt="text placeholder" className="tiles__item--template-text-placeholder" src="https://cdn.brandfolder.io/27C9EC93/at/q6y1al-7x2y1s-dl1522/text-placeholder.auto" />
      </div>
      <div className="tiles__item tiles__item--one-third-width">
        <div className="tiles__item--image placeholder" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y18n-8wjz1k-fi9mwk/image-placeholder.auto")' }} />
        <img alt="text placeholder" className="tiles__item--template-text-placeholder" src="https://cdn.brandfolder.io/27C9EC93/at/q6y1al-7x2y1s-dl1522/text-placeholder.auto" />
      </div>
      <div className="tiles__item tiles__item--one-third-width">
        <div className="tiles__item--image placeholder" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y18n-8wjz1k-fi9mwk/image-placeholder.auto")' }} />
        <img alt="text placeholder" className="tiles__item--template-text-placeholder" src="https://cdn.brandfolder.io/27C9EC93/at/q6y1al-7x2y1s-dl1522/text-placeholder.auto" />
      </div>
      <div className="tiles__item tiles__item--one-third-width">
        <div className="tiles__item--image placeholder" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y18n-8wjz1k-fi9mwk/image-placeholder.auto")' }} />
        <img alt="text placeholder" className="tiles__item--template-text-placeholder" src="https://cdn.brandfolder.io/27C9EC93/at/q6y1al-7x2y1s-dl1522/text-placeholder.auto" />
      </div>
      {showForm
        && (
          <TileForm
            closeModal={toggleForm}
            triggerCardsRefresh={triggerCardsRefresh}
          />
        )
      }
    </section>
  );
};

export default TilesEmptyTemplate;
