import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import BulkShareModal from '@components/show_page/bulk_actions/BulkShareModal';

const ShareAsset = ({ assetKey }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <I18nProviderWrapper>
      <a
        onClick={(e) => {
          if (!showModal) {
            e.preventDefault();
            setShowModal((prevState) => (!prevState));
          }
        }}
        role="button"
        tabIndex={0}
      >
        <button
          className="button secondary sm share-button"
          type="button"
        >
          <div className="attachment-icon">
            <span aria-hidden="true" className="bff-share" />
          </div>
          <Trans>Share</Trans>
          {showModal && (
            <BulkShareModal
              closeModal={() => setShowModal(false)}
              selectedAssetKeys={new Set([assetKey])}
            />
          )}
        </button>
      </a>
    </I18nProviderWrapper>
  );
};

ShareAsset.propTypes = {
  assetKey: PropTypes.string.isRequired
};

export default ShareAsset;
