import { plural, t } from '@lingui/macro';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import SearchDropdown from '@components/common/search_dropdown';
import DropdownToggleButton from '@components/common/search_dropdown/dropdown_toggle_button';

import { filterMatch } from '@helpers/search_filters';
import { sortStrings } from '@helpers/sort_helpers';

import './styles/collections_dropdown.scss';

// add some attributes required by the dropdown
const formatCollections = (collections, brandfolderSlug) => collections.map((collection) => ({
  id: collection.id,
  name: collection.name,
  public: collection.public,
  slug: `${brandfolderSlug}/${collection.slug}`,
  stealth: collection.stealth,
  type: "collections"
})).sort(sortStrings);

const searchCollections = (collections, filter) => (
  collections.filter((collection) => filterMatch(collection.name, filter))
);

/* Props
  brandfolderSlug: brandfolder slug used in building link
  collections: currently sourced from rails local variables
*/
const CollectionsDropdownWrapper = ({ brandfolderSlug = '', collections = [], isLast = false, rootDomain }) => {
  const [formattedCollections, setFormattedCollections] = useState(formatCollections(collections, brandfolderSlug));
  const [menuVisible, setMenuVisibility] = useState(false);
  const collectionDropdownRef = useRef(null);
  const collectionsCount = formattedCollections.length;
  const hasSubCollections = collectionsCount > 0 && collections.find((collection) => !!collection.parent_id);

  const addCollection = (e) => {
    const formattedNewCollection = formatCollections([e.detail.newCollection], brandfolderSlug);
    setFormattedCollections(formattedCollections.concat(formattedNewCollection));
  };

  // close dropdown if users clicks outside it
  const handleOutsideClick = (e) => {
    if (collectionDropdownRef.current && !collectionDropdownRef.current.contains(e?.target)) {
      setMenuVisibility(false);
    }
  };

  useEffect(() => {
    // close dropdown on clicking outside the dropdown
    window.addEventListener("mousedown", handleOutsideClick);
    // since rails local variable won't update the component without a page reload,
    // we use an event listener to grab the new collection
    window.addEventListener('newCollectionAdded', addCollection);
    /* Hold off until custom translations are in v4 api
      getCollections({ include: 'brandfolder' }).then((collection) => setCollections(formatCollections(collection, brandfolderSlug)); );
    */
    return () => {
      window.removeEventListener('newCollectionAdded', addCollection);
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const title = () => (
    hasSubCollections
    ? plural(collectionsCount, { one: `1 Subcollection`, other: `# Subcollections` })
    : plural(collectionsCount, { one: `1 Collection`, other: `# Collections` })
  );
  const searchPlaceholder = () => (
    hasSubCollections ? t`Search Subcollections` : t`Search Collections`
  );
  const showAllText = () => (
    hasSubCollections ? t`View All Subcollections` : t`View All Collections`
  );

  return (
    <I18nProviderWrapper>
      <div
        ref={collectionDropdownRef}
        className={classnames(
          'collections-dropdown',
          isLast && 'is-last'
        )}
        data-testid="collections-dropdown-wrapper"
      >
        <div
          data-testid="collections-dropdown-toggle"
          onClick={(e) => {
            e.stopPropagation();
            setMenuVisibility(!menuVisible);
          }}
        >
          <DropdownToggleButton title={title()} />
        </div>
        {menuVisible && (
          <SearchDropdown
            maxResults={collectionsCount}
            rootDomain={rootDomain}
            searchFunc={searchCollections}
            searchIndex={formattedCollections}
            searchPlaceholder={searchPlaceholder()}
            showAllText={showAllText()}
          />
        )}
      </div>
    </I18nProviderWrapper>
  );
};

CollectionsDropdownWrapper.propTypes = {
  brandfolderSlug: PropTypes.string.isRequired,
  collections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLast: PropTypes.bool.isRequired,
  rootDomain: PropTypes.string.isRequired,
};

export default CollectionsDropdownWrapper;
