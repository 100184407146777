import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { PrimaryButton } from '@components/library/button/index';
import RequestDownLoadAccessModal from '@components/show_page/bulk_actions/RequestDownloadAccessModal';

interface PrepareDownloadRequestButtonProps {
  assetKey: string;
}

export const PrepareDownloadRequestButton: FunctionComponent<PrepareDownloadRequestButtonProps> = (props) => {
  const [showRequestDownloadAccessModal, setShowRequestDownloadAccessModal] = useState(false);
  const { assetKey } = props;
  return (
    <I18nProviderWrapper>
      <PrimaryButton
        className="prepare-download-request-button"
        onClick={(): void => setShowRequestDownloadAccessModal(true)}
      >
        <Trans>Request download</Trans>
      </PrimaryButton>
      {showRequestDownloadAccessModal && (
        <RequestDownLoadAccessModal
          closeModal={(): void => setShowRequestDownloadAccessModal(false)}
          selectedAssetKeys={[assetKey]}
          selectedViewOnlyAssetKeys={[assetKey]}
        />
      )}
    </I18nProviderWrapper>
  );
};
