import fetchJSON from '@api/api_helper';

const url = ({ resourceKey, resourceType }) => (
  `/api/v4/${resourceType}s/${resourceKey}/share_manifests`
);

export const create = ({
  key: resourceKey,
  type: resourceType,
  assetKeys: asset_keys,
  availability_end = null,
  downloadRequestKey: download_request_key = null,
  expires = false,
  internal = false,
  message = null,
  name = '',
  view_only = false,
  requestApproval: request_approval,
  requireIdentification: require_identification = false,
  notifications_optin = false,
  gallery_view = false,
  custom_logo_url = null
}) => (
  fetchJSON(url({ resourceKey, resourceType }), {
    method: 'POST',
    body: {
      data: {
        attributes: {
          asset_keys,
          availability_end,
          custom_logo_url,
          download_request_key,
          expires,
          internal,
          message,
          name,
          notifications_optin,
          request_approval,
          require_identification,
          gallery_view,
          view_only,
        }
      }
    }
  }).catch(async (response) => { throw (await response.json()); })
);
