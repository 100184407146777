import { ListboxOption, StandardCombobox, useDebounce } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { Locale } from '@components/common/language_menu/languagesMap';
import { getStandardComboboxLabels } from '@translations';
import { ListDropdownChildNode } from '@components/common/search_result_bolding/main';

interface CustomFieldValuesResponse {
  data: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    custom_field_values: string[];
  }
}

interface CustomFieldValueComboboxProps {
  customFieldKeyKey: string;
  resetKey: () => void;
  selectedUgtLocale: Locale;
  updateCustomFieldValues: (customFieldValueValue: string) => void;
}

export const CustomFieldValueCombobox: FunctionComponent<CustomFieldValueComboboxProps> = ({
  customFieldKeyKey,
  resetKey,
  selectedUgtLocale,
  updateCustomFieldValues
}) => {
  const [fullOptions, setFullOptions] = useState<ListboxOption[]>();
  const [options, setOptions] = useState<ListboxOption[]>([]);
  const [reset, setReset] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);
  const translatedValues = selectedUgtLocale && selectedUgtLocale !== BFG.locales?.ugtLocaleDefault;
  const shouldSearch = !translatedValues && debouncedSearch.length >= 3;

  const valuesToOptions = (customFieldValuesResponse: CustomFieldValuesResponse): ListboxOption[] => (
    customFieldValuesResponse.data.custom_field_values.map((cfv) => ({
      children: ListDropdownChildNode({ searchResult: cfv, searchInput: search }),
      key: cfv,
      value: cfv
    }))
  );

  const initialCustomFieldValueFetch = useFetch<CustomFieldValuesResponse>({
    url: `/api/v4/${BFG.resource?.type}s/${BFG.resource?.key}/custom_field_keys/${customFieldKeyKey}/custom_field_values`,
    params: {
      /* eslint-disable @typescript-eslint/naming-convention */
      ugt_locale: selectedUgtLocale,
      unique_values: true
      /* eslint-enable @typescript-eslint/naming-convention */
    }
  });

  const customFieldValueSearchFetch = useFetch<CustomFieldValuesResponse>({
    url: `/api/v4/${BFG.resource?.type}s/${BFG.resource?.key}/custom_field_keys/${customFieldKeyKey}/custom_field_values/search`,
    params: {
      /* eslint-disable @typescript-eslint/naming-convention */
      search: debouncedSearch,
      /* eslint-enable @typescript-eslint/naming-convention */
    },
    fetchOnMount: false
  });

  useEffect(() => {
    setFullOptions(undefined);
    setSearch('');
    setReset(true);
  }, [customFieldKeyKey]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (shouldSearch) {
      customFieldValueSearchFetch.fetch();
    } else if (fullOptions) {
      const filteredOptions = fullOptions.filter((opt) => (
        (opt.value as string).toLowerCase().includes(debouncedSearch.toLowerCase())
      ));

      filteredOptions.forEach((opt) => opt.children = ListDropdownChildNode({ searchResult: opt.value as string, searchInput: search }));
      setOptions(filteredOptions);
    }
  }, [fullOptions, debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialCustomFieldValueFetch.response) {
      const valueOptions = valuesToOptions(initialCustomFieldValueFetch.response);
      if (!fullOptions) {
        setFullOptions(valueOptions);
      }
      setOptions(valueOptions);
    }
  }, [initialCustomFieldValueFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customFieldValueSearchFetch.response) {
      const valueOptions = valuesToOptions(customFieldValueSearchFetch.response);
      setOptions(valueOptions);
    }
  }, [customFieldValueSearchFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StandardCombobox
        className="custom-field-dropdown"
        comboboxTextareaProps={{
          onChange: (e): void => {
            setSearch(e.target.value);
          },
          placeholder: 'Search custom field values'
        }}
        editable
        id={`advanced-filter-custom-field-value-dropdown-${customFieldKeyKey}`}
        labels={{
          ...getStandardComboboxLabels(),
          label: t`Value`
        }}
        loading={initialCustomFieldValueFetch.loading || customFieldValueSearchFetch.loading}
        onSelection={(selected): void => {
          updateCustomFieldValues(selected.value as string);
          resetKey();
        }}
        options={options}
        reset={reset}
        search={false}
      />
    </>
  );
};
