import React from 'react';
import PropTypes from 'prop-types';

import Annotator from '@components/asset/modal/tabs/workflow/attachment_details/annotations/annotator';
import VersionsSidebar from '@components/asset/modal/tabs/workflow/attachment_details/versions_sidebar/main';
import CheckInCheckOut from '@components/asset/modal/tabs/workflow/attachment_details/checkin_checkout/main';
import Versions from './versions/main';

const AttachmentContent = ({
  activeAttachment,
  activeAttachmentReservations,
  activeTab,
  annotations,
  asset,
  onNewAnnotation,
  initializeSidebar,
  initializeTab,
  mentionableUsers,
  setActiveTab,
  setActiveVersion,
  setAnnotationsResponse,
  setAttachmentReservations,
  setMentionableUsers,
  setTempAnnotation,
  setVersionsActionLoading,
  tempAnnotation,
  updateFetchControllers,
  url,
  users,
  versionCard,
  versionCards,
  versionsActionLoading,
}) => {
  if (activeTab === 'annotations') {
    return (
      <div className="annotations-container">
        <Annotator
          annotations={annotations}
          assetKey={asset?.id}
          mentionableUsers={mentionableUsers}
          onNewAnnotation={onNewAnnotation}
          setMentionableUsers={setMentionableUsers}
          setTempAnnotation={setTempAnnotation}
          tempAnnotation={tempAnnotation}
          url={url}
          versionsActionLoading={versionsActionLoading}
        />
        <VersionsSidebar
          mentionableUsers={mentionableUsers}
          setActiveVersion={setActiveVersion}
          setAnnotationsResponse={setAnnotationsResponse}
          setMentionableUsers={setMentionableUsers}
          setTempAnnotation={setTempAnnotation}
          users={users}
          versionCard={versionCard}
          versionCards={versionCards}
        />
      </div>
    );
  }

  if (activeTab === 'versions') {
    return (
      <Versions
        activeAttachment={activeAttachment}
        asset={asset}
        initializeSidebar={initializeSidebar}
        setActiveTab={setActiveTab}
        setActiveVersion={setActiveVersion}
        updateFetchControllers={updateFetchControllers}
        users={users}
        versionCards={versionCards}
      />
    );
  }

  if (activeTab === 'checkInCheckOut') {
    return (
      <CheckInCheckOut
        activeAttachment={activeAttachment}
        activeAttachmentReservations={activeAttachmentReservations}
        asset={asset}
        initializeTab={initializeTab}
        setAttachmentReservations={setAttachmentReservations}
        setVersionsActionLoading={setVersionsActionLoading}
        updateFetchControllers={updateFetchControllers}
        versionCard={versionCard}
        versionsActionLoading={versionsActionLoading}
      />
    );
  }

  return null;
};

export default AttachmentContent;

AttachmentContent.propTypes = {
  activeAttachment: PropTypes.shape({}).isRequired,
  activeAttachmentReservations: PropTypes.shape({
    currentStatus: PropTypes.string,
    history: PropTypes.arrayOf(PropTypes.shape({})),
    users: PropTypes.shape({}),
  }),
  activeTab: PropTypes.oneOf(['annotations', 'versions', 'checkInCheckOut']).isRequired,
  annotations: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
    id: PropTypes.string,
  })),
  asset: PropTypes.shape({}).isRequired,
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  onNewAnnotation: PropTypes.func.isRequired,
  initializeSidebar: PropTypes.func.isRequired,
  initializeTab: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setActiveVersion: PropTypes.func.isRequired,
  setAnnotationsResponse: PropTypes.func.isRequired,
  setAttachmentReservations: PropTypes.func.isRequired,
  setMentionableUsers: PropTypes.func.isRequired,
  setTempAnnotation: PropTypes.func.isRequired,
  setVersionsActionLoading: PropTypes.func.isRequired,
  tempAnnotation: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
    comment: PropTypes.string,
    number: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  updateFetchControllers: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  users: PropTypes.shape({}).isRequired,
  versionCard: PropTypes.shape({
    type: PropTypes.oneOf(['reservation', 'version'])
  }).isRequired,
  versionCards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  versionsActionLoading: PropTypes.bool.isRequired,
};

AttachmentContent.defaultProps = {
  activeAttachmentReservations: {},
  annotations: [],
  mentionableUsers: null,
  tempAnnotation: null,
};
