import React, { forwardRef } from 'react';

import BaseButton from '../BaseButton';
import styleClassNames from './styles.module.scss';
import { TertiaryIconButtonProps } from './TertiaryIconButtonProps';

const TertiaryIconButton = forwardRef<HTMLButtonElement, TertiaryIconButtonProps>((props, ref) => {
  const { height, style, width, ...baseButtonProps } = props;

  const updatedStyles = {
    ...style,
    ...height && { height: `${height}px` },
    ...width && { minWidth: `${width}px` },
  };

  const baseProps = {
    ...baseButtonProps,
    ...updatedStyles && { style: updatedStyles },
    styleClassNames,
    variantName: 'tertiary-icon',
  };

  return <BaseButton {...baseProps} ref={ref} />;
});

export default TertiaryIconButton;
