import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { FontIcons } from '@components/library/icon';
import BaseButton from '../BaseButton';
import styleClassNames from './styles.module.scss';

interface TextWarningButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string | FontIcons;
  iconSize?: number;
  isLoading?: boolean;
  loadingCopy?: string;
  size?: ButtonSize;
}

type ButtonSize = 'small' | 'medium' | 'large';

const TextWarningButton = forwardRef<HTMLButtonElement, TextWarningButtonProps>((props, ref) => {
  const baseProps = {
    ...props,
    buttonLoaderColor: 'red' as 'red',
    styleClassNames,
    variantName: 'text-warning',
  };

  return <BaseButton {...baseProps} ref={ref} />;
});

export default TextWarningButton;
