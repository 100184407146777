import { keyPressHelper } from '@brandfolder/utilities';
import { Trans, Plural } from '@lingui/macro';
import React from 'react';

import Checkbox from '@components/common/checkbox/main';
import DropdownWrapper from '@components/common/dropdown_wrapper/main';
import languagesMap, { LanguageMapEntry, Locale } from '@components/common/language_menu/languagesMap';

interface UGTLocaleDefaultProps {
  localeDefault: Locale;
  setWhitelistedLocalesMap: SetStateDispatch<{ [key: string]: boolean }>;
  whitelistedLocalesMap: { [key: string]: boolean };
}

const WhitelistedLocales = ({
  localeDefault,
  setWhitelistedLocalesMap,
  whitelistedLocalesMap,
}: UGTLocaleDefaultProps): JSX.Element => {
  const isDefaultLocale = (languageMapEntry: LanguageMapEntry): boolean => (
    localeDefault === languageMapEntry.locale
  );

  const handleToggleLanguage = (languageMapEntry: LanguageMapEntry): void => {
    if (isDefaultLocale(languageMapEntry)) return;

    setWhitelistedLocalesMap((prevState) => (
      {
        ...prevState,
        [languageMapEntry.locale]: !prevState[languageMapEntry.locale]
      }
    ));
  };

  const keypressHandleToggleLanguage = keyPressHelper(handleToggleLanguage);

  const selectedLocaleDefault = (languageMapEntry: LanguageMapEntry): JSX.Element => (
    <>
      {languageMapEntry.language} ({languageMapEntry.ISOCode})
      <span className="default-language">{isDefaultLocale(languageMapEntry) ? <Trans>(default)</Trans> : ''}</span>
    </>
  );

  const dropdownContents = (): JSX.Element => (
    <div className="language-menu__dropdown">
      <ul className="language-menu__list">
        {languagesMap.map((languageMapEntry: LanguageMapEntry) => (
          <li
            key={languageMapEntry.language}
            className={`language-menu__list--item ${isDefaultLocale(languageMapEntry) ? 'default-locale' : ''}`}
            id={`${languageMapEntry.locale}`}
            onKeyPress={(e): void => keypressHandleToggleLanguage(e, languageMapEntry)}
            role="button"
            tabIndex={0}
          >
            <Checkbox
              checked={whitelistedLocalesMap[languageMapEntry.locale]}
              disabled={isDefaultLocale(languageMapEntry)}
              labelHtml={selectedLocaleDefault(languageMapEntry)}
              size="sm"
              toggle={(): void => handleToggleLanguage(languageMapEntry)}
            />
          </li>
        ))}
      </ul>
    </div>
  );

  const quantityWhitelisted: number = Object.keys(whitelistedLocalesMap).filter((locale) => (
    whitelistedLocalesMap[locale]
  )).length;

  return (
    <div className="advanced-settings-row">
      <h4 className="quarter-column setting-name"><Trans>Preferred Languages</Trans></h4>
      <div className="right-container">
        <DropdownWrapper
          cssTransitionClasses="fade-in-translate"
          dropdownContents={dropdownContents()}
          openOnHover={false}
          wrapperClassnames="language-menu whitelisted-locales"
        >
          <>
            <h4 className="selected-option"><Trans>Select languages</Trans></h4>
            <span className="bff-caret-down whitelistedlocales-caret-icon" />
          </>
        </DropdownWrapper>
        <h4 className="selected-quantity">
          <Plural
            one={<Trans><span className="selected-quantity__count">{quantityWhitelisted}</span> selected</Trans>}
            other={<Trans><span className="selected-quantity__count">{quantityWhitelisted}</span> selected</Trans>}
            value={quantityWhitelisted}
          />
        </h4>
      </div>
    </div>
  );
};

export default WhitelistedLocales;
