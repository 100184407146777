import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import setUserStaticSiteLocale from '@api/v4/private/users';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import LanguageMenuDropdown from '@components/common/language_menu/LanguageMenuDropdown';
import {
  determineCurrentLanguage,
  findCurrentLanguageInMap,
  Locale,
} from '@components/common/language_menu/languagesMap';

import '../../common/language_menu/styles/_language_menu.scss';

import './styles/header_styles.scss';

const HeaderLanguageMenuDropdown: FunctionComponent = () => {
  // fallback to english flag entry if the static site locale can't be found in our map
  const currentFlag = determineCurrentLanguage() ? determineCurrentLanguage() : findCurrentLanguageInMap('en');

  const reloadLocale = (locale: Locale): void => {
    const newLocale = locale;
    const currentLocale = BFG.locales.staticSiteLocale;
    let currentHref = window.location.href;
    // handle urls like those in bulk management (/bulk_management#tags)
    const hrefPieces = currentHref.split('#');
    if (hrefPieces.length > 1) {
      currentHref = hrefPieces[0];
    }
    const separator = currentHref.includes('?') ? '&' : '?';
    let newUrl = `${currentHref}${separator}locale=${newLocale}`;
    if (hrefPieces.length > 1) {
      newUrl += `#${hrefPieces[1]}`;
    }

    // include separator to avoid matching ugt_locale
    if (currentHref.includes('?locale=')) {
      newUrl = window.location.href.replace(`?locale=${currentLocale}`, `?locale=${newLocale}`);
    } else if (currentHref.includes('&locale=')) {
      newUrl = window.location.href.replace(`&locale=${currentLocale}`, `&locale=${newLocale}`);
    }
    window.location.href = newUrl;
  };

  const updateUserLocale = async (locale: Locale): Promise<void> => {
    if (BFG.currentUser.user_id) {
      await setUserStaticSiteLocale(locale);
    }
  };

  const updateLocale = async (locale: Locale): Promise<void> => {
    await updateUserLocale(locale);
    reloadLocale(locale);
  };

  const handleLocaleChange = (locale: Locale): boolean => {
    if (currentFlag && currentFlag.locale !== locale) {
      updateLocale(locale);
      return true;
    }
    return false;
  };

  return (
    <I18nProviderWrapper>
      <LanguageMenuDropdown
        allowAllLocales
        copy={t`Select a language to change your locale.`}
        defaultLocale={BFG.locales.ugtLocaleDefault}
        onLocaleChange={handleLocaleChange}
        openOnHover={false}
        selectedLocale={currentFlag?.locale || Locale.English}
        wrapperClassnames="header"
      />
    </I18nProviderWrapper>
  );
};

export default HeaderLanguageMenuDropdown;
