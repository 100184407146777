import {
  AlertLooks,
  ButtonSizes,
  InputLabelPositions,
  StandardAlert,
  StandardButton,
  StandardFieldset,
  StandardLink,
  StandardSwitch
} from '@brandfolder/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';
import { array, boolean, object, SchemaOf } from 'yup';

import { useFetch } from '@api/ApiHelper';
import { EmailAddresses } from '@components/bulk_management/user-settings/invitations/EmailAddresses';
import {
  EmailsAndUserGroups,
  emailsAndUserGroupsSchema
} from '@components/bulk_management/user-settings/invitations/form-type-helpers';
import { useAlertTimeout } from '@components/common/custom_hooks/useAlertTimeout';
import { emailAddressRegex } from '@helpers/emails';
import { standardError } from '@translations';

import './styles/alert-transitions.scss';
import classes from './styles/user-groups.module.scss';

interface UserEmails {
  emails: EmailsAndUserGroups[];
  preventEmail: boolean;
}

interface UserGroupsProps {
  setReloadMembers: SetStateDispatch<boolean>
  userGroupKey: string;
}

const schema = (): SchemaOf<UserEmails> => object().shape({
  emails: array()
    .of(emailsAndUserGroupsSchema
      .test('emailsAndUserGroups', t`Invalid email address.`, (value): boolean => {
        return emailAddressRegex.test(value.value as string);
      }))
    .min(1, t`At least one email address is required.`),
  preventEmail: boolean()
});

const getFirstError = (errs: FieldError[]): FieldError => {
  const errorIndex = errs.findIndex((err) => err !== undefined);
  if (errorIndex === -1) {
    return undefined;
  }
  return errs[errorIndex];
};

export const UserGroupsAddUsers: FunctionComponent<UserGroupsProps> = ({
  setReloadMembers,
  userGroupKey
}) => {
  const [submissionValues, setSubmissionValues] = useState<UserEmails | undefined>(undefined);
  const [clearEmails, setClearEmails] = useState(false);
  const [waitingToReload, setWaitingToReload] = useState(false);
  const [alertVisible, setAlertVisible] = useAlertTimeout({ timeout: 7000 });

  const {
    formState: { errors, touchedFields },
    getValues,
    handleSubmit,
    setValue,
    watch
  } = useForm<UserEmails, unknown, UserEmails>({
    resolver: yupResolver(schema()),
    defaultValues: {
      emails: [],
      preventEmail: false
    }
  });

  const setFieldTouched = (field: string, isTouched: boolean): void => {
    setValue('emails', getValues('emails'), { shouldTouch: isTouched });
  };

  watch(['emails', 'preventEmail']);

  const emailSubmissionFetch = useFetch({
    url: `/api/v4/user_groups/${userGroupKey}/user_group_members`,
    body: {
      data: {
        attributes: {
          emails: [...new Set(submissionValues?.emails.filter((option) => option.type === 'user' || option.type === 'create').map((option) => option.value))],
          prevent_email: submissionValues?.preventEmail // eslint-disable-line @typescript-eslint/naming-convention
        }
      }
    },
    method: 'POST',
    fetchOnMount: false
  });

  useEffect(() => {
    if (submissionValues) {
      emailSubmissionFetch.fetch();
    }
  }, [submissionValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (emailSubmissionFetch.response) {
      setWaitingToReload(true);
      setTimeout(() => {
        setClearEmails(true);
        setSubmissionValues(undefined);
        setAlertVisible(true);
        setReloadMembers(true);
        setWaitingToReload(false);
      }, 1000);
    }
  }, [emailSubmissionFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (emailSubmissionFetch.error) {
      setAlertVisible(true);
    }
  }, [emailSubmissionFetch.error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clearEmails) {
      setClearEmails(false);
    }
  }, [clearEmails]);

  const handleEmailsSubmit: SubmitHandler<UserEmails> = (values): void => {
    setSubmissionValues(values);
  };

  const setFieldValue = (_: string, value: EmailsAndUserGroups[]): void => {
    setValue('emails', value, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };

  const submissionAlerts = (
    <>
      {(
        <CSSTransition
          classNames="fade-in-translate"
          in={emailSubmissionFetch.response && alertVisible}
          timeout={600}
          unmountOnExit
        >
          <StandardAlert
            className={classnames('user-groups-add-user__submission-alert', 'user-groups-alert--transition', classes.alert)}
            look={AlertLooks.Success}
          >
            <Trans>
              We're working on adding users to this user group.
              You'll receive a&nbsp;
              <StandardLink href="/profile#notifications">notification</StandardLink>&nbsp;
              when the users have been added.
            </Trans>
          </StandardAlert>
        </CSSTransition>
      )}
      {(
        <CSSTransition
          classNames="fade-in-translate"
          in={emailSubmissionFetch.error && alertVisible}
          timeout={600}
          unmountOnExit
        >
          <StandardAlert
            className={classnames('user-groups-add-user__submission-alert', 'user-groups-alert--transition', classes.alert)}
            look={AlertLooks.Error}
          >
            {standardError}
          </StandardAlert>
        </CSSTransition>
      )}
    </>
  );

  const userForm = (
    <form
      className={classnames(classes.addUserForm, 'user-groups-add-user__form')}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(handleEmailsSubmit)}
    >
      <EmailAddresses
        allowReusableInvite={false}
        allowUserGroups={false}
        clearTags={clearEmails}
        error={touchedFields.emails && (
          Array.isArray(errors.emails) ? getFirstError(errors.emails)?.message : errors.emails?.message
        )}
        helpText={t`Only existing users will be added to the group.`}
        label={t`Email addresses`}
        placeholder={t`Search users or enter email addresses`}
        setFieldValue={setFieldValue}
      />
      <div className={classnames(classes.addUserFormButtonContainer, 'user-groups-add-user__submit')}>
        <StandardSwitch
          id="user-email-switch"
          isChecked={!getValues('preventEmail')}
          labelPosition={InputLabelPositions.Right}
          onChange={(): void => setValue('preventEmail', !getValues('preventEmail'))}
        >
          <Trans>Send an email invitation</Trans>
        </StandardSwitch>
        <StandardButton
          loaderProps={{
            label: t`Adding user`
          }}
          loading={emailSubmissionFetch.loading || waitingToReload}
          onClick={(): void => {
            setFieldTouched('emails', true);
          }}
          size={ButtonSizes.XSmall}
          type="submit"
        >
          <Trans>Add users</Trans>
        </StandardButton>
      </div>
    </form>
  );

  return (
    <>
      {submissionAlerts}
      <StandardFieldset
        className={classes.fieldset}
        disabled={emailSubmissionFetch.loading || waitingToReload}
        legend={<Trans>Add users to group</Trans>}
        showLegend
      >
        {userForm}
      </StandardFieldset>
    </>
  );
};
