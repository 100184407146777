import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, MutableRefObject } from 'react';
import { EditorRef } from 'react-email-editor';

import { PrimaryButton, TertiaryButton, TextButton } from '@components/library/button';

import { PressReleaseFormSteps, PressReleaseValidationErrors } from './PressReleaseTypes';

import classes from './styles/press_release_form_footer.module.scss';

interface PressReleaseFormFooterProps {
  drafting: boolean;
  emailEditorRef: MutableRefObject<EditorRef | null>;
  handleStep1: () => void;
  handleStep2: () => void;
  handleStep3: (action: 'drafting' | 'sending' | 'testing') => void;
  saving: boolean;
  sending: boolean;
  step: PressReleaseFormSteps;
  testing: boolean;
  validationErrors: PressReleaseValidationErrors;
}

export const PressReleaseFormFooter: FunctionComponent<PressReleaseFormFooterProps> = (props) => {
  const { drafting, emailEditorRef, handleStep1, handleStep2, handleStep3, saving, sending, step, testing, validationErrors } = props;

  switch (step) {
    case 1:
      return (
        <PrimaryButton
          className={classes.nextButton}
          disabled={!emailEditorRef?.current?.editor}
          id="next-select-recipients"
          onClick={(): void => {
            handleStep1();
          }}
        >
          <Trans>Next: Select Recipients</Trans>
        </PrimaryButton>
      );
    case 2:
      return (
        <PrimaryButton
          className={classes.nextButton}
          disabled={!validationErrors.valid}
          id="next-preview-email"
          isLoading={saving}
          onClick={(): void => {
            handleStep2();
          }}
        >
          <Trans>Next: Preview Email</Trans>
        </PrimaryButton>
      );
    case 3:
      return (
        <div className={classes.buttons}>
          <TextButton
            className={classes.testButton}
            disabled={saving && !testing}
            icon="bff-sent"
            iconSize={16}
            id="test-email"
            isLoading={testing}
            loadingCopy={t`Sending test...`}
            onClick={(): void => {
              handleStep3('testing');
            }}
          >
            <Trans>Send a test to myself</Trans>
          </TextButton>
          <div className={classes.saveAndSendButtons}>
            <TertiaryButton
              className={classes.saveButton}
              disabled={sending || testing}
              icon="bff-draft"
              iconSize={16}
              id="save-email-as-draft"
              isLoading={drafting}
              loadingCopy={t`Saving draft...`}
              onClick={(): void => {
                handleStep3('drafting');
              }}
            >
              <Trans>Save as Draft</Trans>
            </TertiaryButton>
            <PrimaryButton
              className={classes.sendButton}
              disabled={saving && !sending}
              icon="bff-sent"
              iconSize={16}
              id="send-email"
              isLoading={sending}
              loadingCopy={t`Sending...`}
              onClick={(): void => {
                handleStep3('sending');
              }}
            >
              <Trans>Send Email</Trans>
            </PrimaryButton>
          </div>
        </div>
      );
    default:
      return (
        <></>
      );
  }
};
