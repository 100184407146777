import React from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import AssetManager from '@components/workbench/individual/asset_manager/main';
import Controls from '@components/workbench/individual/resize/controls';

class Resize extends React.Component {
  render() {
    return (
      <I18nProviderWrapper>
        <AssetManager>
          <Controls />
        </AssetManager>
      </I18nProviderWrapper>
    );
  }
}

export default Resize;
