import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Tooltip = ({
  x,
  y,
  onClose,
  containerWidth,
  containerHeight,
  children,
  setTempAnnotation,
}) => {
  const tooltipContainer = useRef(null);
  let horizontalDirection = 'right';
  let verticalDirection = 'bottom';

  // hardcoding these dimensions because useRef is null initially
  const tooltipContainerWidth = 335;
  const tooltipContainerHeight = 221;

  if (containerWidth) {
    const xPos = containerWidth * x + tooltipContainerWidth;
    if (xPos > containerWidth) {
      horizontalDirection = 'left';
    }
  }

  if (containerHeight) {
    const yPos = containerHeight * y + tooltipContainerHeight;
    if (yPos > containerHeight) {
      verticalDirection = 'top';
    }
  }

  const onClick = (e) => {
    if (!tooltipContainer.current.contains(e.target)) {
      setTempAnnotation(null);
    }
  };

  useEffect(() => {
    if (!tooltipContainer) { return undefined; }
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  }, []);

  return (
    <div
      ref={tooltipContainer}
      style={{
        top: `${Math.round(y * 100)}%`,
        left: `${Math.round(x * 100)}%`,
      }}
      onClick={(event) => event.stopPropagation()}
      className={classnames([
        "annotator-tooltip",
        horizontalDirection,
        verticalDirection
      ])}
    >
      {children}
      <button
        type="button"
        className="tooltip-close"
        onClick={() => onClose()}
      >
        <span className="bff-close icon" />
      </button>
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  x: PropTypes.string.isRequired,
  y: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  containerWidth: PropTypes.number,
  containerHeight: PropTypes.number,
  setTempAnnotation: PropTypes.func.isRequired,
};

Tooltip.defaultProps = {
  onClose: undefined,
  containerWidth: null,
  containerHeight: null,
};

export default Tooltip;
