import { InputLabelPositions, StandardSwitch } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, {
  useEffect,
  useState,
  FunctionComponent,
} from 'react';

import { updateBrandguide } from '@api/v3/brandguides';
import { getBrandguide } from '@api/v4/resources/brandguides';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { FilestackUploader } from '@components/common/filestack_uploader/FilestackUploader';
import { BFLoader } from '@components/common/loader/main';
import {
  CloseButton,
  PrimaryButton,
  TextWarningButton,
} from '@components/library/button/index';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';
import renderModal from '@components/show_page/modals/renderModal';

interface SettingsModalProps {
  brandguideKey: string;
  closeModal: () => void;
  organizationKey: string | null;
}

interface Attributes {
  cardImage: string;
  name: string;
  isPublic: boolean;
  slug: string;
}

const defaultData: Attributes = { cardImage: '', name: '', isPublic: true, slug: '' };

const SettingsModal: FunctionComponent<SettingsModalProps> = ({
  brandguideKey,
  closeModal,
  organizationKey
}) => {
  const [initialData, setInitialData] = useState(defaultData);
  const [updatedData, setUpdatedData] = useState(defaultData);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = async (): Promise<void> => {
    const { cardImage, isPublic, name, slug } = updatedData;
    setIsSubmitting(true);

    try {
      await updateBrandguide({
        initialSlug: initialData.slug,
        ...(initialData.cardImage !== cardImage) && { cardImage },
        ...(initialData.name !== name) && { name },
        ...(initialData.isPublic !== isPublic) && { isPublic },
        ...(initialData.slug !== slug) && { slug },
      });
      Notify.create({
        title: t`Brandguide settings updated! Refreshing...`,
        type: 'success'
      });
      setIsSubmitDisabled(true); // maintain disabled button while page reloads
      window.location.reload();
    } catch (error) {
      Notify.create({
        title: t`An error occurred while updating this Brandguide. Please try again or contact support.`,
        type: 'error',
        ...error.errors && { body: error.errors },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateInput = (e: InputChangeEvent): void => {
    e.persist();
    const { name, value } = e.target;
    setUpdatedData((prevState) => ({ ...prevState, [name]: value }));
  };

  const checkDiff = (): void => {
    const {
      cardImage: cardImageInitial,
      isPublic: isPublicInitial,
      name: nameInitial,
      slug: slugInitial
    } = initialData;

    const { cardImage, isPublic, name, slug } = updatedData;

    setIsSubmitDisabled(
      cardImageInitial === cardImage &&
      isPublic === isPublicInitial &&
      name === nameInitial &&
      slug === slugInitial
    );
  };

  const fetchBrandguide = async (): Promise<void> => {
    try {
      const { attributes } = await getBrandguide(brandguideKey);
      const { card_image: cardImage, name, public: isPublic, slug } = attributes;
      setInitialData({ cardImage, name, isPublic, slug });
      setUpdatedData({ cardImage, name, isPublic, slug });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBrandguide();
    return closeModal;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    checkDiff();
  }, [updatedData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal-content-wrapper brandguide-modal">
      <div className="modal-content-wrapper__header">
        <span className="bff-settings icon" />
        <h3><Trans>Brandguide Settings</Trans></h3>
        <CloseButton onClick={closeModal} />
      </div>
      {isLoading ? (
        <div className="loader-container"><BFLoader /></div>
      ) : (
        <div className="modal-content-wrapper__body">
          <InputContainer
            attributes={{
              name: 'name',
              onChange: handleUpdateInput,
              type: InputType.Text,
            }}
            input={{ value: updatedData?.name }}
            labelCopy={t`Name`}
          />
          <InputContainer
            attributes={{
              name: 'slug',
              onChange: handleUpdateInput,
              type: InputType.Text,
            }}
            input={{ value: updatedData?.slug }}
            labelCopy={t`Unique URL`}
          />
          <StandardSwitch
            className="brandguide-modal-switch"
            id="brandguide-is-public"
            isChecked={updatedData.isPublic}
            labelPosition={InputLabelPositions.Right}
            onChange={(): void => {
              setUpdatedData((prevState) => ({ ...prevState, isPublic: !prevState.isPublic }));
            }}
          >
            <Trans>Public</Trans>
          </StandardSwitch>
          <div className="brandguide-modal__filestack-container">
            <div className="brandguide-modal__filestack-container--description">
              <Trans>
                <h3>Card Image</h3>
                <p>Recommended size: 260px x 130px</p>
              </Trans>
            </div>
            {updatedData?.cardImage ? (
              <div className="brandguide-modal__filestack-container--thumbnail">
                <div className="brandguide-modal__filestack-container--thumbnail-container">
                  <FilestackPreviewImage
                    alt="thumbnail"
                    className="brandguide-modal__filestack-container--thumbnail--image"
                    resourceKey={organizationKey || undefined}
                    resourceType={organizationKey ? 'organization' : undefined}
                    src={updatedData?.cardImage}
                  />
                </div>
                <TextWarningButton
                  icon="bff-trash"
                  onClick={(): void => setUpdatedData((prevState) => ({ ...prevState, cardImage: '' }))}
                />
              </div>
            ) : (
            <FilestackUploader
                onUploadDone={(files): void => setUpdatedData((prevState) => ({ ...prevState, cardImage: files?.filesUploaded?.[0]?.url }))}
                pickerOptions={{
                  accept: 'image/*',
                  maxFiles: 1,
                  transformations: {
                    crop: {
                      aspectRatio: (260 / 130),
                      force: true
                    }
                  }
                }}
                resourceKey={organizationKey || undefined}
                resourceType={organizationKey ? 'organization' : undefined}
              />
            )}
          </div>
          <div className="button-container">
            <PrimaryButton
              disabled={isSubmitDisabled}
              isLoading={isSubmitting}
              onClick={(): void => {
                handleSubmit();
              }}
              size="medium"
            >
              <Trans>Update Settings</Trans>
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

const ModalComponent = renderModal(SettingsModal, 'brandguide-settings-modal');
export default ModalComponent;
