import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useContext, useState } from 'react';

import { ColorData } from '@api/v4/assets/assetTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import ColorCircle from '@components/asset/modal/tabs/edit/side_bar/color_assets/colorCircle';
import { InputType } from '@components/library/inputs/Input.props';
import Input from '@components/library/inputs/PrimaryInput';
import Label from '@components/library/labels/PrimaryLabel';

import { ValidationErrors } from '../../EditTabTypes';

interface HexScopedContext {
  state: {
    editState: HexScopedProps;
    errorState: ValidationErrors[];
  };
}

interface HexScopedProps {
  colorData: {
    hex: string;
  };
}

const HexInput: FunctionComponent<{
  handleColorUpdate: (partial: Partial<ColorData>) => void;
  autofillEmptyColors: (partial: Partial<ColorData>) => void;
}> = ({ handleColorUpdate, autofillEmptyColors }) => {
  const [hexFocused, setHexFocused] = useState(false);

  const { state }: HexScopedContext = useContext(assetModalContext);
  if (!state.editState.colorData) {
    return null;
  }
  const { hex } = state.editState.colorData;

  const hasInputError = state.errorState.includes(ValidationErrors.InvalidHexValue);

  return (
    <>
      <div className="hex-label-error-container">
        <div className="edit-tab-sidebar-color-label-swatch-container">
          <Label attributes={{ htmlFor: 'hex', className: 'edit-tab-sidebar-label' }}>HEX</Label>
          <ColorCircle
            getBackgroundColor={(): string => hex}
            id="color-circle-hex"
          />
        </div>
        <p className={`input-error ${hasInputError ? 'active' : ''}`}>
          <Trans>Invalid HEX code</Trans>
        </p>
      </div>
      <div className="edit-tab-sidebar-color-input-and-color">
        <div className="edit-tab-sidebar-color-flex-inputs">
          <Input
            attributes={{
              name: 'hex',
              onChange: (e: InputChangeEvent): void => handleColorUpdate({ hex: e.target.value.replace(/#/g, '') }),
              onFocus: (): void => setHexFocused(true),
              onBlur: (): void => {
                setHexFocused(false);
                autofillEmptyColors({ hex });
              },
              type: InputType.Text,
              value: `#${hex}`,
              className: `edit-tab-sidebar-color-hex ${!hexFocused && hex === '' ? 'hex-inactive' : ''}`
            }}
            input={{ value: `#${hex}`, error: `${hasInputError ? 'hex error' : ''}` }}
            submitAttempted={hasInputError}
          />
        </div>
      </div>
    </>
  );
};

export default HexInput;
