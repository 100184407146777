import { t } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { ListDropdown } from '@components/library/dropdown/index';
import { searchOptions } from '@components/show_page/sections/section_search/helper';
import { SearchOperatorOptions, SearchOption } from '@components/show_page/sections/section_search/SearchTypes';
import { update as updateBrandfolderResource } from '@api/v4/resources/resources';

interface DefaultSearchOptionProps {
  brandfolderKey: string;
  defaultStrictSearch: boolean;
  defaultSearchOperator: SearchOperatorOptions.OR | SearchOperatorOptions.AND;
}

const notifySuccess = (): void => Notify.create({
  title: t`Default search option setting updated!`,
  type: 'success'
});

const notifyFailure = (): void => Notify.create({
  title: t`Error updating default search option. Please contact support.`,
  type: 'error'
});

const DefaultSearchOption: FunctionComponent<DefaultSearchOptionProps> = ({
  brandfolderKey,
  defaultStrictSearch,
  defaultSearchOperator,
}) => {
  const [selectedValue, setSelectedValue] = useState(SearchOperatorOptions.Strict);

  const handleChange = (value: SearchOperatorOptions): void => {
    setSelectedValue((prevState) => {
      if (prevState !== value) {
        updateBrandfolderResource({
          key: brandfolderKey || BFG.resource.key,
          ...(value === SearchOperatorOptions.Strict)
            ? { defaultStrictSearch: true }
            : { defaultSearchOperator: value, defaultStrictSearch: false },
        })
          .then(() => { notifySuccess(); })
          .catch(() => { notifyFailure(); });
      }
      return value;
    });
  };

  useEffect(() => {
    setSelectedValue(defaultStrictSearch ? SearchOperatorOptions.Strict : defaultSearchOperator);
  }, []);

  return (
    <I18nProviderWrapper>
      <div className="advanced-tab-settings__options">
        <ListDropdown
          onChange={(option: SearchOption): void => handleChange(option.value)}
          options={searchOptions().filter((option: SearchOption) => option.value !== SearchOperatorOptions.Quoted)}
          value={selectedValue}
        />
      </div>
    </I18nProviderWrapper>
  );
};

export default DefaultSearchOption;
