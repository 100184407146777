import { Trans } from '@lingui/macro';
import * as React from 'react';

import { getBoard } from '@api/v4/boards/index';

import { AssetsResponse, AssetsResponseObject } from '@api/v4/assets/assetTypes';
import { BFLoader } from '@components/common/loader/main';

import CardGrid from './components/card_grid';
import AssetCard from './asset_card/asset_card';

// we share the assets collection and the method to mutate them
// for when we mutate them through the API, we can update the local react state
interface ContextValue {
  assets: SingleBoardAssetViewState['assets'];
  setAssets: React.Dispatch<React.SetStateAction<SingleBoardAssetViewState['assets']>>;
}

// give setAssets a default no-op
// just as a placeholder until we set it
const initialState: ContextValue = {
  assets: [],
  setAssets: (assets: []) => { /* no op */ }
};

export const SingleBoardAssetsViewContext = React.createContext(initialState);

interface SingleBoardAssetViewState {
  assets: AssetsResponseObject[];
}

interface SingleBoardAssetViewProps {
  boardName: string;
  boardId: string;
  showAllBoards: () => void;
}
const SingleBoardAssetsView: React.FunctionComponent<SingleBoardAssetViewProps> = (options) => {
  const { boardName, boardId, showAllBoards } = options;
  const [assets, setAssets] = React.useState<SingleBoardAssetViewState['assets']>([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    (async (): Promise<void> => {
      try {
        // get asset details of this board
        const { data }: AssetsResponse = await getBoard({ boardId, options: { params: { fields: 'best_link_for,extension,is_downloadable,view_only' } } });
        // can we use asset_count somewhere?
        setAssets(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        console.log('something went wrong with /api/v4/board');
      }
    })();
  }, [boardId]);

  return (
    <>
      {
        loading
          ? <BFLoader />
          : (
            <div className="single-board-view">
              <div className="header-section">
                <a onClick={(): void => showAllBoards()}>
                  <span className="bff-arrow-left" />
                  <Trans>Back to Boards</Trans>
                </a>
                <h1>{boardName}</h1>
              </div>
              {
            assets.length > 0
              ? (
                <SingleBoardAssetsViewContext.Provider value={{ assets, setAssets }}>
                  <CardGrid>
                    {
                  assets.map((asset) => (
                    <AssetCard
                      key={asset.id}
                      asset={asset}
                      boardId={boardId}
                      boardName={boardName}
                    />
                  ))
                }
                  </CardGrid>
                </SingleBoardAssetsViewContext.Provider>
              )
              : <h3><Trans>No Assets within {boardName}</Trans></h3>
          }
            </div>
          )
      }
    </>
  );
};

export default SingleBoardAssetsView;
