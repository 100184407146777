import { ReducerState, Submit } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { SubmissionData } from '@components/asset/modal/tabs/edit/submission';
import { CustomFieldValue } from '@api/v4/assets/customFieldTypes';
import { getAssetChanges, AssetChanges } from '@components/asset/modal/tabs/edit/change_detection/assetChangeEngine';
import { getCustomFieldChanges } from './custom-fields-change-detection';
import { getDataChanges } from './data-change-engine';
import { getTaskChanges } from './taskChangeEngine';
import getAttachmentChanges from './attachmentChangeEngine';
import getTagChanges from './tagChangeEngine';

/**
 * Rolls up all the change map functionality
 * so we can call this once for both submit button greying out and api call payloads
 * @param state ReducerState
 * @param isSubmitting boolean | undefined
 * @param isTask boolean | undefined
 * @returns SubmissionData[]
 */
const detectChanges = (state: ReducerState, isSubmitting?: boolean, isTask?: boolean): SubmissionData[] => {
  const assetChanges = getAssetChanges(state);
  const attachmentChanges = getAttachmentChanges(state);
  const dataChanges = getDataChanges(state);
  const multiValueCustomFieldChanges = getCustomFieldChanges(state.editState, state.initialData);
  const tagChanges = getTagChanges(state);

  const requiredSubmissions: SubmissionData[] = [];

  if (assetChanges || dataChanges) {
    // applies to assets AND tasks since 'assetChanges' include asset details, asset name etc..
    const data = {
      ...!!assetChanges && assetChanges,
      ...!!dataChanges && { data: dataChanges }
    } as Partial<AssetChanges>;

    requiredSubmissions.push({ action: Submit.UpdatedAsset, data });
  }

  if (isTask) {
    const taskChanges = getTaskChanges(state);
    if (taskChanges) {
      requiredSubmissions.push({ action: Submit.UpdatedTask, data: taskChanges });
    }
  }

  if (multiValueCustomFieldChanges) {
    const {
      createdCustomFieldKeyValuePears,
      createdCustomFieldValues,
      deletedCustomFieldValueIds,
      updatedCustomFieldValues
    } = multiValueCustomFieldChanges;

    if (createdCustomFieldKeyValuePears) {
      createdCustomFieldKeyValuePears.forEach((pair) => {
        requiredSubmissions.push({ action: Submit.NewCustomFieldKeyValuePair, data: pair });
      });
    }

    if (createdCustomFieldValues) {
      requiredSubmissions.push({ action: Submit.NewCustomFieldValues, data: createdCustomFieldValues });
    }

    if (deletedCustomFieldValueIds) {
      deletedCustomFieldValueIds.forEach((deletedId) => {
        requiredSubmissions.push({ action: Submit.DeletedCustomFieldValue, data: deletedId });
      });
    }

    if (updatedCustomFieldValues) {
      Object.keys(updatedCustomFieldValues).forEach((updatedCFVId) => {
        const customFieldValue: CustomFieldValue = {
          key: updatedCFVId,
          value: updatedCustomFieldValues[updatedCFVId].value
        };
        requiredSubmissions.push({
          action: Submit.UpdatedCustomFieldValue,
          data: customFieldValue
        });
      });
    }
  }

  if (tagChanges) {
    requiredSubmissions.push({ action: Submit.Tags, data: tagChanges });
  }

  if (attachmentChanges) {
    const { deletedAttachmentKeys, updatedAttachments } = attachmentChanges;
    if (deletedAttachmentKeys) {
      deletedAttachmentKeys.forEach((key) => {
        requiredSubmissions.push({ action: Submit.DeletedAttachment, data: key });
      });
    }

    if (updatedAttachments) {
      updatedAttachments.forEach((updatedAttachment) => {
        requiredSubmissions.push({ action: Submit.UpdatedAttachment, data: updatedAttachment });
      });
    }
  }

  // used to trigger unsaved changes alert when closing asset modal
  if (isSubmitting) {
    BFG.editTabUnsavedChanges = false; // allows user to close asset modal while updates are submitting (i.e. no "unsaved changes" alert will trigger)
  } else {
    BFG.editTabUnsavedChanges = requiredSubmissions.length > 0;
  }

  return requiredSubmissions;
};

export default detectChanges;
