import fetchJSON from '@api/api_helper';

const url = (id) => {
  const baseUrl = `/api/v4/metadata_conversions`;

  return id ? `${baseUrl}/${id}` : baseUrl;
};

// export const get = (key) => (
//   fetchJSON(url({ key })).then(({ data }) => (
//     data.map(({ id, attributes }) => ({
//       id,
//       ...attributes,
//     }))
//   ))
// );

export const update = ({
  id,
  metadata_term,
  key_term,
  convert_to_tags,
}) => (
  fetchJSON(url(id), {
    method: 'PUT',
    body: {
      data: {
        attributes: {
          metadata_term,
          key_term,
          convert_to_tags: !!convert_to_tags,
        },
      },
    },
  }).catch(async (response) => { throw (await response.json()); })
);

export const destroy = (id) => (
  fetchJSON(url(id), {
    method: 'DELETE',
  })
);

export const run = (id) => (
  fetchJSON(`${url(id)}/run`, {
    method: 'GET',
  })
);
