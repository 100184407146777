import { FlattenedCustomFieldKeyValuesMap } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { Locale } from '@components/common/language_menu/languagesMap';
import { createCustomFieldKey } from '@api/v4/resources/custom_fields';
import { CustomFieldKeyServer } from '@api/v4/resources/CustomFieldKeysTypes';
import { ApiResponseObject } from '@api/v4/ApiResponseTypes';

export const unsavedChanges = (
  customFieldsMap: FlattenedCustomFieldKeyValuesMap,
  tempKeyPrefix: string
): boolean => (
  customFieldsMap && Object.keys(customFieldsMap).some((cfKey) => (
    // at least 1 existing row must have a value populated
    // or at least 1 new row (new uncontrolled cf) must have a key name OR a value populated
    customFieldsMap[cfKey].customFieldValues.length
      || (cfKey.includes(tempKeyPrefix) && customFieldsMap[cfKey].customFieldKey.name.length)
  ))
);

export const isValid = (
  customFieldsMap: FlattenedCustomFieldKeyValuesMap,
  tempKeyPrefix: string
): boolean => {
  // presence of unsaved changes is checked separately,
  // this 'isValid' method only runs as needed AFTER checking for unsaved changes
  const cfKeys = Object.keys(customFieldsMap);
  const customFieldKeyNames = cfKeys.map((cfKey) => customFieldsMap[cfKey].customFieldKey.name);
  const keyNamesAreUnique: boolean = (new Set(customFieldKeyNames)).size === customFieldKeyNames.length;
  const newKeyValuePairsArePopulated = cfKeys.every((cfKey) => {
    if (!cfKey.includes(tempKeyPrefix)) return true;
    const hasName = !!customFieldsMap[cfKey].customFieldKey.name.length;
    const hasValue = !!customFieldsMap[cfKey].customFieldValues.length && !!customFieldsMap[cfKey].customFieldValues.length;
    // check that all new rows (new uncontrolled cfs) have both the key AND value populated
    return (hasName && hasValue) || (!hasName && !hasValue);
  });

  return customFieldsMap && keyNamesAreUnique && newKeyValuePairsArePopulated;
};

export const fetchNewKeys = async (
  customFieldsMap: FlattenedCustomFieldKeyValuesMap,
  tempKeyPrefix: string,
  ugtLocale: Locale | undefined,
): Promise<{ key: string; name: string; tempKey: string }[]> => {
  const tempKeys: { id: string; name: string }[] = Object.keys(customFieldsMap).flatMap((cfKey) => {
    const { id, name } = customFieldsMap[cfKey].customFieldKey;
    return cfKey.includes(tempKeyPrefix) && name && customFieldsMap[cfKey].customFieldValues.length
      ? { id, name }
      : [];
  });

  const newKeys = await createCustomFieldKey(
    BFG.resource.type as 'brandfolder' | 'collection',
    BFG.resource.key,
    tempKeys.map(({ name }) => (name)),
    ugtLocale
  );

  const getTempKey = (
    newKey: ApiResponseObject<CustomFieldKeyServer, 'custom_field_keys'>
  ): string => {
    const tempKeyObj = tempKeys.find((tempKey) => tempKey.name === newKey.attributes.name);
    return tempKeyObj ? tempKeyObj.id : '';
  };

  return newKeys.data.map((newKey) => ({
    key: newKey.id,
    name: newKey.attributes.name,
    tempKey: getTempKey(newKey),
  }));
};

export const getNewKeyNames = (
  customFieldsMap: FlattenedCustomFieldKeyValuesMap,
  tempKeyPrefix: string,
) : string[] => {
  const newKeyNames: string[] = Object.keys(customFieldsMap).flatMap((cfk) => {
    const { name } = customFieldsMap[cfk].customFieldKey;
    return cfk.includes(tempKeyPrefix) && name && customFieldsMap[cfk].customFieldValues.length
      ? name
      : [];
  });
  return newKeyNames;
};
