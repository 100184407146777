import React, { FunctionComponent } from 'react';

import { Organization } from './customReportTypes';
import { reportsList } from './reportsList';
import { ReportsMenu } from './ReportsMenu';
import { ReportSidebar } from './ReportsSidebar';

interface HomeProps {
  customReports: boolean;
  organization: Organization;
}

export const Home: FunctionComponent<HomeProps> = ({ customReports, organization }) => {
  return (
    <div className='reports-home-page'>
      <ReportSidebar
        customReports={customReports}
        organization={organization}
        returnLink={organization}
      />
      <ReportsMenu organization={organization} reports={reportsList()} />
    </div>
  );
};
