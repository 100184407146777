/* eslint-disable react-hooks/exhaustive-deps */
import { CircleLoader, LoaderLabels, LoaderSizes } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import {
  EmbeddedWidget,
  getEmbeddedWidget,
  postEmbeddedWidget,
  putEmbeddedWidget,
  ResourceTypes
} from '@api/v4/private/embedded_widgets';
import { ValidationErrorsEnum } from '@api/v4/private/PortalCardTypes';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { LabelPosition, StandardSwitch, SwitchSize } from '@components/library/switch';
import { handleDeletion } from '@components/portals/helpers/card_deletion_helper';// TODO: replace
import { validateName, validateButtonText, validateSrcUrl } from '@components/portals/portal_cards/validations';
import { EmbeddedWidgetsForm, EmbeddedWidgetsPreview } from '@components/shared/embedded_widgets';

import './styles/embedded_widgets_wrapper.scss';

interface EmbeddedWidgetsWrapperProps {
  resourceKey: string;
  resourceType: ResourceTypes;
}

const defaultEmbeddedWidget: EmbeddedWidget = {
  buttonText: '',
  description: '',
  height: null,
  isActive: false,
  name: '',
  srcUrl: null,
  width: null
};

export const EmbeddedWidgetsWrapper: FunctionComponent<EmbeddedWidgetsWrapperProps> = (props) => {
  const { resourceKey, resourceType } = props;

  const [embeddedWidget, setEmbeddedWidget] = useState(defaultEmbeddedWidget);
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorsEnum[]>([]);

  // get the embedded widget from the api and set it if we have one saved (default is none/null)
  useEffect(() => {
    const fetchEmbeddedWidget = async (): Promise<void> => {
      try {
        setLoading(true);
        const result = await getEmbeddedWidget({ resourceKey, resourceType });
        if (result) {
          setEmbeddedWidget(result);
        }
        // else do nothing, use the defaultEmbeddedWidget above
      } catch (err) {
        Notify.create({ title: t`Something went wrong, could not load Smartsheet form settings.`, type: 'error' });
      } finally {
        setLoading(false);
      }
    };
    if (resourceKey) {
      fetchEmbeddedWidget();
    }
  }, [resourceKey]);

  const handleModalClose = (): void => {
    if (saved) {
      window.location.reload();
    }
  };

  // we're wiring in to the modal close button to reload the brandfolder or collection page
  // so that the user can see their saved embedded widget on that page (or see it deactivated and not appear)
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (saved) {
      const modalClose = document.getElementById('close-modal');

      if (modalClose) {
        modalClose.addEventListener('click', handleModalClose);
      }

      return (): void => {
        if (modalClose) {
          modalClose.removeEventListener('click', handleModalClose);
        }
      };
    }
  }, [saved]);

  const validate = (): ValidationErrorsEnum[] => {
    const errors = [
      ...validateName({ name: embeddedWidget.name }),
      ...validateButtonText({ buttonText: embeddedWidget.buttonText }),
      ...validateSrcUrl({ srcUrl: embeddedWidget.srcUrl || '' })
    ];
    return errors;
  };

  const handleValidations = (): boolean => {
    const submissionErrors = validate();
    if (submissionErrors.length > 0) {
      setValidationErrors(submissionErrors);
      return true;
    }
    setValidationErrors([]);
    return false;
  };

  const createEmbeddedForm = async (): Promise<void> => {
    if (handleValidations()) return;

    try {
      setSaving(true);
      const result = await postEmbeddedWidget({ embeddedWidget, resourceKey, resourceType });
      setEmbeddedWidget(result);
      setSaved(true);
    } catch (err) {
      Notify.create({ title: t`Something went wrong, could not save Smartsheet form.`, type: 'error' });
    } finally {
      setSaving(false);
    }
  };

  const updateEmbeddedForm = async (): Promise<void> => {
    if (handleValidations()) return;
    try {
      setSaving(true);
      const result = await putEmbeddedWidget({ embeddedWidget, resourceKey, resourceType });
      setEmbeddedWidget(result);
      setSaved(true);
    } catch (err) {
      Notify.create({ title: t`Something went wrong, could not update Smartsheet form.`, type: 'error' });
    } finally {
      setSaving(false);
      setToggled(false);
    }
  };

  useEffect(() => {
    // this is needed for toggling the embedded widget off and on and saving to the api immediately
    // we use toggled state here to allow setEmbeddedWidget time to set isActive before calling updateEmbeddedForm
    if (toggled) {
      // NOTE: toggled is a temporary state that gets set back to false within updateEmbeddedForm
      // this should only run when toggled === true
      updateEmbeddedForm();
    }
  }, [toggled]);

  const isValid = validationErrors.length === 0;

  // fetching the embedded widget from the api
  if (loading) return <CircleLoader label={LoaderLabels.Loading} size={LoaderSizes.Large} />;

  // the user doesn't have permission (show nothing)
  if (!loading && !resourceKey) return null;

  return (
    <I18nProviderWrapper>
      <div className="embedded-wrapper">
        <div className="embedded-wrapper__form">
          <StandardSwitch
            isChecked={embeddedWidget.isActive}
            isDisabled={loading || saving}
            labelPosition={LabelPosition.Right}
            onChange={(): void => {
              setEmbeddedWidget({ ...embeddedWidget, isActive: !embeddedWidget.isActive });
              if (embeddedWidget.key) {
                setToggled(true);
              }
            }}
            size={SwitchSize.Large}
          >
            {embeddedWidget.isActive
              ? <Trans>Smartsheet Form (Enabled)</Trans>
              : <Trans>Smartsheet Form (Disabled)</Trans>
            }
          </StandardSwitch>
          <EmbeddedWidgetsForm
            buttonText={embeddedWidget.buttonText}
            description={embeddedWidget.description || ''}
            disabled={!embeddedWidget.isActive || loading}
            displayWidth={undefined}
            handleDeletion={handleDeletion}
            id={embeddedWidget.key}
            isValid={isValid}
            name={embeddedWidget.name}
            saving={saving}
            setButtonText={(buttonText: string): void => { setEmbeddedWidget({ ...embeddedWidget, buttonText }); }}
            setDescription={(description: string): void => { setEmbeddedWidget({ ...embeddedWidget, description }); }}
            // setHeight={(height: string): void => { setCard({ ...card, height: Number(height) }); }}
            setName={(name: string): void => { setEmbeddedWidget({ ...embeddedWidget, name }); }}
            setSrcUrl={(srcUrl: string): void => { setEmbeddedWidget({ ...embeddedWidget, srcUrl }); }}
            // setWidth={(width: string): void => { setCard({ ...card, width: Number(width) }); }}
            showButtons={embeddedWidget.isActive}
            showCharacterLimit={false}
            srcUrl={embeddedWidget.srcUrl || ''}
            submitCreate={createEmbeddedForm}
            submitUpdate={updateEmbeddedForm}
            type="Smartsheet Form"
            validationErrors={validationErrors}
          />
        </div>
        <div className="embedded-wrapper__preview">
          {embeddedWidget.isActive && !!embeddedWidget.srcUrl && (
            <EmbeddedWidgetsPreview srcUrl={embeddedWidget.srcUrl} />
          )}
        </div>
      </div>
    </I18nProviderWrapper>
  );
};
