import { FontIcons, StandardButton } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import CreateSFtpIngestForm from '@components/bulk_management/sftp-ingest/CreateSftpIngestForm';
import { SftpIngestTable } from '@components/bulk_management/sftp-ingest/SftpIngestTable';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { TextButton } from '@components/library/button';
import { isGettyClient } from '@helpers/getty-strings';

import './styles/sftp-ingest.scss';

// Just handling prod or stage here
const SFTP_LINK = BFG.environment === 'staging' ?
  'sftp://transfer-stage.bfldr.com' : 'sftp://files.transfer.brandfolder.com/';

const SftpIngestFormContainer: FunctionComponent<{ setShowForm: SetStateDispatch<boolean> }> = ({
  setShowForm
}) => (
  <div id="manage-sftp-ingest">
    <TextButton
      className="sftp-ingests--cancel-edit"
      icon={FontIcons.ArrowLeft}
      onClick={(): void => setShowForm(false)}
    >
      <Trans>Back</Trans>
    </TextButton>
    <CreateSFtpIngestForm
      setShowForm={setShowForm}
    />
  </div>
);

export const SftpIngestManagement: FunctionComponent = () => {
  const [showForm, setShowForm] = useState(false);

  return (
    <I18nProviderWrapper>
      <div className="manage-sftp-ingests">
        <div className="manage-sftp-ingests__header">
          <div>
            <h2>{showForm ? <Trans>Create new SFTP Ingest</Trans> : <Trans>SFTP Ingest</Trans>}</h2>
            {isGettyClient() ? (
              <p className="ftp-ingest-description--content">
                <Trans>
                  Ingest assets into Media Manager using:&nbsp;
                  <strong>{SFTP_LINK}</strong>
                  <br />
                  <strong>Port 22</strong>
                </Trans>
              </p>
            ) : (
              <p className="ftp-ingest-description--content">
                <Trans>
                  Ingest assets into Brandfolder using:&nbsp;
                  <strong>{SFTP_LINK}</strong>
                  <br />
                  <strong>Port 22</strong>
                </Trans>
              </p>
            )}
          </div>
          {!showForm && (
            <StandardButton
              className="ftp-ingest-create-button"
              onClick={(): void => setShowForm(true)}
              startIcon={FontIcons.Plus}
            >
              <Trans>Create new SFTP ingest</Trans>
            </StandardButton>
          )}
        </div>
        <hr />
        {showForm ? (
          <SftpIngestFormContainer
            setShowForm={setShowForm}
          />
        ) : (
          <SftpIngestTable
            setShowForm={setShowForm}
          />
        )}
      </div>
    </I18nProviderWrapper>
  );
};
