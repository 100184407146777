import React, { FunctionComponent } from 'react';

import { ResourceTooltipName } from './ResourceTooltipName';
import { ResourceHeading } from '@components/bulk_management/user-settings/resource-sidebar/ResourceHeading';
import { CollectionAccordion } from '@components/bulk_management/user-settings/resource-sidebar/CollectionAccordion';
import {
  Collection,
  HeadingType,
  ResourceDetails,
  ResourceBase
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';

interface ResourceAccordionProps {
  resources: ResourceBase[];
  selectedResourceKey: string;
  setResource: SetStateDispatch<ResourceDetails>;
  headingType: HeadingType;
}

export const ResourceAccordion: FunctionComponent<ResourceAccordionProps> = ({
  headingType,
  resources,
  selectedResourceKey,
  setResource
}) => {
  const resourceAccordions = resources?.map((resource, resourceIndex) => {
    const id = `${headingType}-accordion-${resource.key}`;

    if (headingType === 'collection') {
      return (
        <CollectionAccordion
          key={id}
          headingType={headingType}
          resource={resource as Collection}
          resourceIndex={resourceIndex}
          selectedResourceKey={selectedResourceKey}
          setResource={setResource}
        />
      );
    }

    return (
      <div key={id}>
        <ResourceTooltipName
          headingType={headingType}
          resource={resource}
          selectedResourceKey={selectedResourceKey}
          setResource={setResource}
        />
      </div>
    );
  });

  return (
    <div className="resource-sidebar__accordion">
      <ResourceHeading
        headingType={headingType}
        length={resources?.length || 1}
      />
      {resourceAccordions}
    </div>
  );
};
