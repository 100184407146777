/**
 * Get whether an asset is a Design Huddle project
 * @param designHuddleEditorLink string | null | undefined
 * @returns boolean
 */
export const getAssetIsDesignHuddleProject = (designHuddleEditorLink: string | null | undefined): boolean => {
  return designHuddleEditorLink && designHuddleEditorLink.includes('project');
};

/**
 * Get whether an asset is a Design Huddle template
 * @param designHuddleEditorLink string | null | undefined
 * @returns boolean
 */
export const getAssetIsDesignHuddleTemplate = (designHuddleEditorLink: string | null | undefined): boolean => {
  return designHuddleEditorLink && designHuddleEditorLink.includes('template');
};

/**
 * Get whether an asset is a Design Huddle project
 * @param designHuddleEditorLink string | null | undefined
 * @returns boolean
 */
export const getAssetIsDesignHuddleAsset = (designHuddleEditorLink: string | null | undefined): boolean => {
  return designHuddleEditorLink && (getAssetIsDesignHuddleProject(designHuddleEditorLink) || getAssetIsDesignHuddleTemplate(designHuddleEditorLink));
};
