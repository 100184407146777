import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from '../styles/dialog.module.scss';

interface DialogProps extends HTMLAttributes<HTMLDivElement> {
    /** children is required */
    children: ReactNode;
}

/**
 * Dialog is the outermost element, which controls visibility
 * @param {DialogProps} props DialogProps
 */
export const Dialog: FunctionComponent<DialogProps> = (props) => {
  const { children, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={classnames("dialog", classes.dialog, divProps.className)}
      role="presentation"
    >
      {children}
    </div>
  );
};
