import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from '../styles/selectable-table.module.scss';

interface StandardTableWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  scrollY?: boolean;
}

export const TableWrapper: FunctionComponent<StandardTableWrapperProps> = (props) => {
  const { children, scrollY, ...otherProps } = props;
  return (
    <div
      {...otherProps}
      className={classnames(
        'tableWrapper',
        'selectableTableWrapper',
        scrollY && classes.tableScrollY,
        scrollY && 'bf-scroll-element',
        props.className
      )}
    >
      {children}
    </div>
  );
};
