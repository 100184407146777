import { StandardTaggerLabels } from '@brandfolder/react';
import { t } from '@lingui/macro';

import { loaderLabel } from './loader';

export const getStandardTaggerLabels = (labels: Partial<StandardTaggerLabels> = {}): StandardTaggerLabels => {
  return {
    duplicateOptionLabel: t`Duplicate option`,
    emptyLabel: t`No options`,
    iconButtonLabel: t`Toggle options`,
    label: t`Options`,
    listboxLabel: t`List of options`,
    loaderLabel: loaderLabel(),
    removeTagLabel: t`Remove`,
    requiredLabel: t`Required`,
    ...labels
  };
};
