import { plural, t } from '@lingui/macro';

export const handleNotification = (notifyType, assetApproved, approvalQty = 'N/A') => {
  if (notifyType === 'success') {
    let bodyText;
    if (approvalQty === 'N/A' || !approvalQty) {
      bodyText = '';
    } else if (assetApproved) {
      bodyText = plural(approvalQty, {
        one: 'This asset requires # total approval. All previous approvals are void.',
        other: 'This asset requires # total approvals. All previous approvals are void.'
      });
    } else {
      bodyText = plural(approvalQty, {
        one: 'This asset requires # total approval.',
        other: 'This asset requires # total approvals.'
      });
    }

    Notify.create({
      title: assetApproved ? t`Approved 1 asset!` : t`Unapproved 1 asset!`,
      body: bodyText,
      type: 'success'
    });
  }

  if (notifyType === 'error') {
    const approvingErrorCopy = t`An error occurred when approving assets. Please try again.`;
    const unapprovingErrorCopy = t`An error occurred when unapproving assets. Please try again.`;

    Notify.create({
      title: assetApproved ? approvingErrorCopy : unapprovingErrorCopy,
      type: 'error'
    });
  }
};

export const sweetAlertUnapproveOptions = () => ({
  title: t`This asset has external links.`,
  text: `${t`Are you sure you want to unapprove? Unapproving will cause broken image links on sites that depend on the availability of this asset. Check the usage tab to see active external links before unapproving.`}\n\n`,
  type: 'warning',
  showCancelButton: true,
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Unapprove',
  closeOnConfirm: true,
  customClass: 'merging'
});
