import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

interface EnableDisableProps {
  enabled: boolean;
  id: string;
  resetState: () => void;
  setEnabled: SetStateDispatch<boolean>
  setSubmitDisabled: SetStateDispatch<boolean>
}

export const EnableDisable: FunctionComponent<EnableDisableProps> = (props) => {
  const { enabled, id, resetState, setSubmitDisabled, setEnabled } = props;

  const handleDisable = (): void => {
    setEnabled(false);
    resetState();
    setSubmitDisabled(true);
  };

  return (
      <div className="default-share-manifest-expiration-enable-disable">
        <div className="quarter-column">
          <p><Trans>Expire new share links by default amount of time</Trans></p>
        </div>
        <div className="three-quarters-column">
          <div className="advanced-tab-settings__options">
            <div className="radio-button-container">
              <input
                checked={enabled}
                className="radio-margin"
                id={`${id}-setting-enabled`}
                name={`${id}-setting-enabled-disabled`}
                onChange={(): void => setEnabled(true)}
                type="radio"
                value="true"
              />
              <label htmlFor={`${id}-setting-enabled`}>
                <Trans>Enabled</Trans>
              </label>
            </div>
            <div className="radio-button-container">
              <input
                checked={!enabled}
                className="radio-margin"
                id={`${id}-setting-disabled`}
                name={`${id}-setting-enabled-disabled`}
                onChange={handleDisable}
                type="radio"
                value="false"
              />
              <label htmlFor={`${id}-setting-disabled`}>
                <Trans>Disabled</Trans>
              </label>
            </div>
          </div>
        </div>
      </div>
  );
};
