import { t, Trans } from '@lingui/macro';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';

import './styles/_tags.scss';

import getSuggestedTags from '@api/v4/private/assets/suggested_tags';
import { AsyncComponentProps } from '@components/common/AsyncComponentProps';
import { Locale } from '@components/common/language_menu/languagesMap';

import assetModalContext from '../asset_modal_context';
import { ReactTag } from '../EditTabTypes';

interface SuggestedTagComponentProps {
  addTag: (tagName: string) => void;
  tagName: string;
}
interface SuggestedTagsProps extends AsyncComponentProps {
  addReactTag: (tag: ReactTag) => void;
}

interface SuggestedTagsScopedContext {
  state: {
    initialData: {
      assetKey: string;
    };
    editState: {
      tags: ReactTag[];
    };
    ugtLocale: Locale | '';
  };
}

const maxAutoSuggestedTags = 15;
const SuggestedTagComponent: FunctionComponent<SuggestedTagComponentProps> = ({ tagName, addTag }) => (
  <div className="tag-container tag-dashed-border">
    <button
      aria-label={t`Add tag ${tagName}`}
      className="tags__add"
      onClick={(): void => addTag(tagName)}
      type="button"
    >
      <div className="bff-plus" />
      <span className="tag-name">{tagName}</span>
    </button>
  </div>
);

const SuggestedTags: FunctionComponent<SuggestedTagsProps> = ({ addReactTag, updateFetchControllers }): JSX.Element => {
  const { state }: SuggestedTagsScopedContext = useContext(assetModalContext);
  const { ugtLocale } = state;
  const { tags } = state.editState;
  const [loading, setLoading] = useState(true);
  const [suggestedTags, setSuggestedTags] = useState<ReactTag[] | null>(null);
  const [suggestedTagsResponse, setSuggestedTagsResponse] = useState<{ name: string }[] | null>(null);

  useEffect(() => {
    let didCancel = false;
    const getSuggestedTagsAsync = async (): Promise<void> => {
      try {
        const params = ugtLocale ? { ugt_locale: ugtLocale } : undefined;
        const response = await getSuggestedTags(state.initialData.assetKey, { params, updateFetchControllers });
        if (!didCancel) {
          // I'm wary of this approach since it seems equivalent to isMounted
          // which is an anti-pattern because it obscures valid memory leak errors
          // but I'm at loss as to how to handle when `getSuggestedTags` isn't
          // aborted by the unmounting because it finishes just before unmount
          setSuggestedTagsResponse(response?.data);
        }
      } catch (e) {
        if (!didCancel) {
          setLoading(false);
        }
      }
    };

    if (!suggestedTagsResponse && state.initialData.assetKey) {
      setLoading(true);
      getSuggestedTagsAsync();
    }

    return (): void => { didCancel = true; };
  }, [ugtLocale, state.initialData.assetKey, updateFetchControllers, suggestedTagsResponse]);

  useEffect(() => {
    if (suggestedTagsResponse) {
      const filteredTags = suggestedTagsResponse
        .filter((suggestedTag) => (
          !tags.map((t) => t.name.toUpperCase()).includes(suggestedTag.name.toUpperCase())
        )) // remove if it exists in tags already
        .slice(0, maxAutoSuggestedTags)
        .map((suggestedTag) => ({ name: suggestedTag.name, id: suggestedTag.name, auto_generated: false }));

      setSuggestedTags(filteredTags);
      setLoading(false);
    }
  }, [suggestedTagsResponse, tags]);

  useEffect(() => {
    setSuggestedTagsResponse(null);
  }, [ugtLocale]);

  const handleAdd = (tagName: string): void => {
    addReactTag({ name: tagName, id: tagName, auto_generated: false });
    const suggestedTagsCopy = [...suggestedTags];
    suggestedTagsCopy.splice(suggestedTagsCopy.findIndex((suggestedTag) => suggestedTag.name === tagName), 1);
    setSuggestedTags(suggestedTagsCopy);
  };

  const getTagComponents = (): JSX.Element[] | JSX.Element => (
    suggestedTags && suggestedTags.length > 0
      ? suggestedTags.map((suggested) => (
        <SuggestedTagComponent
          key={`suggested-tag-${suggested.name}`}
          addTag={(tagName: string): void => handleAdd(tagName)}
          tagName={suggested.name}
        />
      ))
      : <div><p><Trans>No suggested tags</Trans></p></div>
  );

  return (
    <div className="suggested-tags-container">
      {loading
        ? <p><Trans>Looking for suggested tags...</Trans></p>
        : getTagComponents()
      }
    </div>
  );
};

export default SuggestedTags;
