interface InView {
  all?: boolean;
  any?: boolean;
  bottom?: boolean;
  bounding?: ClientRect;
  left?: boolean;
  right?: boolean;
  top?: boolean;
}

export const isOutOfView = (
  elem: HTMLDivElement | HTMLUListElement | undefined,
  overflowParentElem: HTMLElement | undefined,
  offset = 0
) => {
  const elemBounding = elem.getBoundingClientRect();

  // Check if it's out of the viewport on each side
  const outOfViewport: InView = {};
  outOfViewport.top = elemBounding.top < 0;
  outOfViewport.left = elemBounding.left < 0;
  outOfViewport.bottom = (elemBounding.bottom + offset) > (window.innerHeight || document.documentElement.clientHeight);
  outOfViewport.right = (elemBounding.right + offset) > (window.innerWidth || document.documentElement.clientWidth);
  outOfViewport.any = outOfViewport.top || outOfViewport.left || outOfViewport.bottom || outOfViewport.right;
  outOfViewport.all = outOfViewport.top && outOfViewport.left && outOfViewport.bottom && outOfViewport.right;
  outOfViewport.bounding = elemBounding;

  // Check if it spills out of overflow parent element
  const outOfOverflowParent: InView = {};
  if (overflowParentElem) {
    const parentBounding = overflowParentElem.getBoundingClientRect();
    outOfOverflowParent.top = elemBounding.top < parentBounding.top;
    outOfOverflowParent.left = elemBounding.left < parentBounding.left;
    outOfOverflowParent.bottom = (elemBounding.bottom + offset) > parentBounding.bottom;
    outOfOverflowParent.right = (elemBounding.right + offset) > parentBounding.right;
    outOfOverflowParent.any = outOfOverflowParent.top || outOfOverflowParent.left || outOfOverflowParent.bottom || outOfOverflowParent.right;
    outOfOverflowParent.all = outOfOverflowParent.top && outOfOverflowParent.left && outOfOverflowParent.bottom && outOfOverflowParent.right;
    outOfOverflowParent.bounding = parentBounding;
  }

  return {
    outOfViewport,
    outOfOverflowParent,
  };
};
