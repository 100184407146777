import { useEffect, useState } from 'react';

type AlertState = [boolean, (visible: boolean) => void];

interface AlertProps {
  initialShow?: boolean
  timeout?: number;
}

export const useAlertTimeout = (props?: AlertProps): AlertState => {
  const defaultProps: AlertProps = {
    initialShow: false,
    timeout: 6000
  };
  const {
    initialShow,
    timeout,
  } = { ...defaultProps, ...props };
  const [alertVisible, setAlertVisible] = useState(initialShow);
  const [alertTimeoutId, setAlertTimeoutId] = useState<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    // if showing alert, set a new timeout to hide it after a delay
    const timeoutId = setTimeout(() => {
      setAlertVisible(false);
    }, timeout);
    setAlertTimeoutId(timeoutId);
  }, [alertVisible, timeout]);

  const setShowAlert = (visible: boolean): void => {
    clearTimeout(alertTimeoutId);
    setAlertVisible(visible);
  };

  return [alertVisible, setShowAlert];
};
