import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { Organization, Report } from './customReportTypes';
import { reportsGeneratedMsg } from './reportsCopy';
import { ReportsTile } from './ReportsTile';
import './styles/reports-menu.scss';

interface ReportsMenuProps {
  organization: Organization;
  reports: Report[];
}

export const ReportsMenu: FunctionComponent<ReportsMenuProps> = ({ organization, reports }) => {
  const reportTiles = reports.map((report) => {
    return (
    <div key={report.reportName} className='reports-tile'>
      <ReportsTile
        organization={organization}
        report={report}
      />
    </div>
    );
  });

  return (
    <div className='reports-menu'>
      <h1><Trans>Reports</Trans></h1>
      <span className='reports-menu-gen-message'>{reportsGeneratedMsg()}</span>
      <div className='report-tile-wrapper'>
        {reportTiles}
      </div>
    </div>
  );
};
