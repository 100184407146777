import { BadgePill, BrandfolderHexColors, FontIcons, ListboxOption, MoreInfoTooltip } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import { decode } from 'html-entities';
import React, { FunctionComponent } from 'react';

import { ApiDataResponse } from '@api/v4/ApiResponseTypes';
import { UserGroupServer } from '@api/v4/user_groups';
import { PermissionsGetResponse } from '@api/v4/user_groups/user-group-permissions/UserGroupPermissionsTypes';
import { User } from '@api/v4/UserTypes';
import {
  Brandfolder,
  Portal,
  ServerResourceType
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';
import { permissionResponseToTypeKeyPairs, ResourceTypeKeyPair } from '@components/bulk_management/user_groups/helpers';
import { ListOption } from '@components/library/dropdown';
import { moreInfoLabel } from '@components/standard-messages';
import { getAssetLibraryTitle } from '@helpers/getty-strings';

interface ResourceDropdownLabelProps {
  resourceLabel: string;
  resourceName: string;
}

export const PermissionLevelTooltip: FunctionComponent = () => (
  <MoreInfoTooltip
    iconLabel={moreInfoLabel()}
    id="invitation-form__permission-level__more-info"
    tooltip={
      <>
        <span>
          <Trans>
            <strong>
              Guests
            </strong>: View and download assets.
          </Trans>
        </span><br />
        <span>
          <Trans>
            <strong>
              Collaborators
            </strong>: Add and edit assets, plus all guest abilities.
          </Trans>
        </span><br />
        <span>
          <Trans>
            <strong>
              Admins
            </strong>: Full access to managing assets, users, and settings.
          </Trans>
        </span>
      </>
    }
  />
);

export const ResourceDropdownLabel: FunctionComponent<ResourceDropdownLabelProps> = ({
  resourceLabel,
  resourceName
}) => (
  <div className="resource-dropdown-label-wrapper">
    <span className="resource-dropdown-label">
      {resourceName}
    </span>
    <BadgePill
      backgroundColor={BrandfolderHexColors.DirtyMarshmallowHalf}
      borderColor={BrandfolderHexColors.DirtyMarshmallowHalf}
      className="resource-dropdown-label__type-badge"
      radius={12}
    >
      {resourceLabel}
    </BadgePill>
  </div>
);

const translatedClosePill = (): string => t`Close pill`;

export const generateBrandfolderCollectionOptions = (
  brandfolders: Brandfolder[],
  portals: Portal[],
  selectedResources: ResourceTypeKeyPair[],
  permissionsResponse?: PermissionsGetResponse
): ListOption[] => {
  const permissionTypeKeyPairs = permissionsResponse?.included
    ? permissionResponseToTypeKeyPairs(permissionsResponse)
    : undefined;

  const existingKeysForType = (toFilter: ResourceTypeKeyPair[], type: ServerResourceType): string[] => (
    toFilter
      ?.filter((resource) => resource.type === type)
      ?.map(({ key }) => key)
  );
  const selectedBrandfolderKeys = existingKeysForType(selectedResources, 'brandfolder');
  const selectedCollectionKeys = existingKeysForType(selectedResources, 'collection');
  const existingBrandfolderPermissionKeys = existingKeysForType(permissionTypeKeyPairs, 'brandfolder');
  const existingCollectionPermissionKeys = existingKeysForType(permissionTypeKeyPairs, 'collection');
  const existingBrandguidePermissionKeys = existingKeysForType(permissionTypeKeyPairs, 'brandguide');
  const existingPortalPermissionKeys = existingKeysForType(permissionTypeKeyPairs, 'portal');

  const options: ListOption[] = [];
  const brandguideOptions: ListOption[] = [];
  brandfolders.forEach((brandfolder) => {
    const brandfolderSelected = !!selectedBrandfolderKeys.find((key) => key === brandfolder.key);
    options.push({
      closeButtonAriaLabel: `${translatedClosePill()} ${brandfolder.name}`,
      label: (
        <ResourceDropdownLabel
          resourceLabel={getAssetLibraryTitle()}
          resourceName={brandfolder.name}
        />
      ),
      searchValue: brandfolder.name,
      value: `brandfolder|${brandfolder.key}`,
      isDisabled: !brandfolder.adminable || existingBrandfolderPermissionKeys?.includes(brandfolder.key)
    });

    brandfolder.collections.forEach((collection) => {
      const collectionSelected = !!selectedCollectionKeys.find((key) => key === collection.key);
      options.push({
        closeButtonAriaLabel: `${translatedClosePill()} ${collection.name}`,
        indentation: 1,
        isDisabled: brandfolderSelected
          || !collection.adminable
          || existingCollectionPermissionKeys?.includes(collection.key),
        label: (
          <ResourceDropdownLabel
            resourceLabel={t`Collection`}
            resourceName={collection.name}
          />
        ),
        searchValue: collection.name,
        value: `collection|${collection.key}`
      });
      collection.subcollections?.forEach((subcollection) => {
        options.push({
          closeButtonAriaLabel: `${translatedClosePill()} ${subcollection.name}`,
          indentation: 2,
          isDisabled: brandfolderSelected
            || collectionSelected
            || !subcollection.adminable
            || existingCollectionPermissionKeys?.includes(subcollection.key),
          label: (
            <ResourceDropdownLabel
              resourceLabel={t`Subcollection`}
              resourceName={subcollection.name}
            />
          ),
          searchValue: subcollection.name,
          value: `collection|${subcollection.key}`
        });
      });
    });

    brandfolder.workspaces.forEach((workspace) => {
      options.push({
        closeButtonAriaLabel: `${translatedClosePill()} ${workspace.name}`,
        indentation: 1,
        isDisabled: brandfolderSelected
          || !workspace.adminable
          || existingCollectionPermissionKeys?.includes(workspace.key),
        label: (
          <ResourceDropdownLabel
            resourceLabel={t`Workspace`}
            resourceName={workspace.name}
          />
        ),
        searchValue: workspace.name,
        value: `collection|${workspace.key}`
      });
    });

    brandfolder.brandguides.forEach((brandguide) => {
      brandguideOptions.push({
        closeButtonAriaLabel: `${translatedClosePill()} ${brandguide.name}`,
        label: (
          <ResourceDropdownLabel
            resourceLabel={t`Brandguide`}
            resourceName={brandguide.name}
          />
        ),
        searchValue: brandguide.name,
        value: `brandguide|${brandguide.key}`,
        isDisabled: !brandguide.adminable || existingBrandguidePermissionKeys?.includes(brandguide.key)
      });
    });
  });

  const portalOptions: ListOption[] = portals.map((portal) => ({
    closeButtonAriaLabel: `${translatedClosePill()} ${portal.name}`,
    label: (
      <ResourceDropdownLabel
        resourceLabel={t`Portal`}
        resourceName={portal.name}
      />
    ),
    searchValue: portal.name,
    value: `portal|${portal.key}`,
    isDisabled: !portal.adminable || existingPortalPermissionKeys?.includes(portal.key)
  }));

  return options.concat(brandguideOptions).concat(portalOptions);
};

export const userOption = (userResponse: ApiDataResponse<User, 'users'>): ListboxOption[] => {
  const userOptions = userResponse.data.map((user) => {
    const { attributes, id } = user;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { email, first_name, last_name } = attributes;

    const name = first_name && last_name ? `${first_name} ${last_name}` : '';

    return {
      children: (
        <span className='user-option'>
          <span className='user-option__name'>{name || email}</span>
          {email && name && (
            <small className='user-option__email'>{email}</small>
          )}
        </span>
      ),
      label: name || email,
      key: id,
      tooltip: name ? email : undefined,
      type: 'user',
      value: email
    };
  });

  return userOptions;
};

export const userGroupUserOption = (userResponse: ApiDataResponse<User, 'users'>, userGroupResponse: ApiDataResponse<UserGroupServer, 'user_groups'>): ListboxOption[] => {
  const userOptions = userOption(userResponse);

  const userGroupOptions = userGroupResponse.data.map((userGroup) => {
    const { attributes, id } = userGroup;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { name } = attributes;

    return {
      children: (
        <span className='user-option'>
          <span className='user-option__name'>{decode(name)}</span>
        </span>
      ),
      label: name,
      key: id,
      value: name,
      type: 'user-group'
    };
  });

  return [...userOptions, ...userGroupOptions];
};

export const tagTypeIcon = (tagType:string): FontIcons => {
  switch (tagType) {
    case 'user':
      return FontIcons.Person;
    case 'user-group':
      return FontIcons.People;
    case 'create':
    default:
      return FontIcons.UserInvited;
  }
};
