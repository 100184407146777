import { Dispatch } from 'react';
import { SubmissionData, SubmitData } from './submission';
import { ActionTypes, ModalEditFormReducerAction, Submit } from './EditTabTypes';
import { AssetChanges } from './change_detection/assetChangeEngine';

const getNewAttachmentKeys = (requiredSubmissions: SubmissionData[]): string[] => {
  const assetChanges = requiredSubmissions.find((submission) => submission.action === Submit.UpdatedAsset).data as Partial<AssetChanges>;
  if (!assetChanges.attachments) {
    return [];
  }

  return assetChanges.attachments.map((attachment) => attachment.key);
};

const handlePartialSuccess = (
  requiredSubmissions: SubmissionData[],
  submissionResults: SubmitData,
  dispatch: Dispatch<ModalEditFormReducerAction>
): void => {
  const { successes } = submissionResults;
  successes.forEach(({ action, results }) => {
    switch (action) {
      case Submit.DeletedAttachment:
        dispatch({ type: ActionTypes.ResolveDeletedAttachment, payload: { attachmentKey: results } });
        break;
      case Submit.DeletedCustomFieldValue:
        dispatch({ type: ActionTypes.ResolveDeletedCustomField, payload: { customFieldValueKey: results } });
        break;
      case Submit.Tags:
        dispatch({ type: ActionTypes.ResolveTags });
        break;
      case Submit.UpdatedAsset:
        dispatch({
          type: ActionTypes.ResolveAsset,
          payload: {
            asset: results.data?.attributes ?? results.attributes,
            newAttachmentKeys: getNewAttachmentKeys(requiredSubmissions)
          }
        });
        break;
      case Submit.UpdatedAttachment:
        dispatch({ type: ActionTypes.ResolveAttachmentUpdate, payload: results.data });
        break;
      default:
        break;
    }
  });
};

export default handlePartialSuccess;
