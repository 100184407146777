import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { generateBrandguideUrl } from '@helpers/brandguide';

interface ListItemProps {
  cname: string;
  name: string;
  slug: string;
  rootDomain: string;
  type:
  | 'brandfolder'
  | 'brandguide'
  | 'collections'
  | 'organization'
  | 'portal';
  publicPrivacy?: boolean;
  stealthPrivacy?: boolean;
  v2?: boolean;
  organizationKey?: string;
}

const renderPrivacyIcon: FunctionComponent<{
  publicPrivacy: boolean;
  stealthPrivacy?: boolean;
}> = ({ publicPrivacy, stealthPrivacy }) => {
  let privacy = 'stealth';
  if (publicPrivacy === true && stealthPrivacy === false) {
    privacy = 'public';
  } else if (publicPrivacy === false && !stealthPrivacy) {
    privacy = 'private';
  }
  return (
    <div className="privacy-pill">
      <div className={`circle ${privacy}`} />
      <span
        aria-hidden
        className={`bff-${privacy}`}
        data-testid="list-item-privacy-pill"
      />
    </div>
  );
};

export const ListItem: FunctionComponent<ListItemProps> = ({
  cname,
  name,
  publicPrivacy = true,
  rootDomain,
  slug,
  stealthPrivacy = false,
  type,
  v2 = false,
  organizationKey = null,
}) => {
  const getUrl = (): string => {
    const url = `${window.location.protocol}//${cname || rootDomain}`;

    switch (type) {
      case 'organization':
        return `${url}/organizations/${slug}`;
      case 'portal':
        return `${url}/portals/${slug}`;
      case 'brandguide':
        return generateBrandguideUrl({ cname, slug, v2, organizationKey });
      case 'brandfolder':
        return `${url}/${slug}`;
      // a collection is already nested under a type with a cname
      default:
        return `/${slug}`;
    }
  };

  return (
    <li
      className={`search-dropdown__item search-dropdown__item--${type}`}
      data-testid="search-dropdown-list-item"
    >
      {`${type}` === 'brandfolder' && (
        <span
          aria-hidden
          className={`bff-grid-view search-dropdown__item--${type}--icon`}
        />
      )}
      {`${type}` === 'brandguide' && (
        <span
          aria-hidden
          className={`bff-brand-guide search-dropdown__item--${type}--icon`}
        />
      )}
      {`${type}` === 'portal' && (
        <span
          aria-hidden
          className={`bff-portal search-dropdown__item--${type}--icon`}
        />
      )}
      <a
        aria-label={`${name} ${type}`}
        data-testid="search-dropdown-list-item-link"
        href={getUrl()}
      >
        {`${type}` === 'collections' &&
          renderPrivacyIcon({ publicPrivacy, stealthPrivacy })}
        <span>{name}</span>
      </a>
      {`${type}` === 'organization' && (
        <span
          aria-hidden
          className="org-label"
          data-testid="search-dropdown-org-label"
        >
          <Trans>Org</Trans>
        </span>
      )}
    </li>
  );
};

export default ListItem;
