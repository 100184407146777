import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { formattedUppyAttachment } from '@helpers/uppy';

class CustomizationUppy extends React.Component {
  handleCustomizationFiles = (files) => {
    if (!files.length) { return; }

    const file = formattedUppyAttachment(files[0]);
    const $parent = $(`.j-upload-settings-well.${this.uploadType()}`);
    $parent.find('.filepicker_object').val(JSON.stringify(file));
    $parent.find('.filepicker-complete .filename').text(file.filename);
    $parent.find('.filepicker-complete .filename').attr('href', file.url);
    $parent.find('.filepicker-complete-pane').addClass('complete');
    $parent.find('.uppy-customization-container').hide();
  }

  uploadType = () => this.props.uploadType.toLowerCase();

  trigger = () => `uppy-customization-trigger-${this.uploadType()}`;

  renderArea = () => (
    <div className="uppy-customization-settings" id={this.trigger()}>
      <p><Trans>Click to upload</Trans></p>
    </div>
  );

  render() {
    return (
      <I18nProviderWrapper>
        {this.renderArea()}
        <UppyUploader
          {...this.props}
          button
          handleUpload={this.handleCustomizationFiles}
          restrictions={{ maxNumberOfFiles: 1, allowedFileTypes: ['image/*'], maxFileSize: 10485760 }} // 10MB = 10485760 bytes
          template={this.uploadType()}
          trigger={`#${this.trigger()}`}
          uniqIdentifier={`uppy-customization-${this.uploadType()}`}
        />
      </I18nProviderWrapper>
    );
  }
}

CustomizationUppy.propTypes = {
  uploadType: PropTypes.string.isRequired
};

export default CustomizationUppy;
