import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { FontIcons } from '@components/library/icon';
import BaseButton, { ButtonLoaderColors } from '../BaseButton';
import styleClassNames from './styles.module.scss';

interface DangerButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string | FontIcons;
  iconSize?: number;
  isLoading?: boolean;
  loadingCopy?: string;
  loadingIcon?: string | FontIcons;
  loadingIconSize?: number;
  size?: ButtonSize;
}

type ButtonSize = 'small' | 'medium' | 'large';

const DangerButton = forwardRef<HTMLButtonElement, DangerButtonProps>((props, ref) => {
  const baseProps = {
    ...props,
    buttonLoaderColor: 'white' as ButtonLoaderColors,
    styleClassNames,
    variantName: 'danger',
  };

  return <BaseButton {...baseProps} ref={ref} />;
});

export default DangerButton;
