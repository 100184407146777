import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import {
  ShareManifestContextableTypes,
  ShareManifestPutResponse,
  ShareManifestServer
} from '@api/v4/share_manifests/ShareManifestTypes';
import Tooltip from '@components/common/tooltip/main';
import { StandardSwitch } from '@components/library/switch';
import { localizeDateTime } from '@helpers/localize';
import { setStorage, SHARE_LINK_NOTIFICATIONS_PREFERENCE_KEY, StorageTypes } from '@helpers/storage';

import { mapShareManifestServerToClient } from '../../helpers';
import { Card, CardHeader, CardPairing } from '../card';

import classes from './styles/metadata.module.scss';
import { isGettyClient } from '@helpers/getty-strings';

interface MetadataProps {
  assetsHidden: number;
  assetsShown: number;
  manifest: ShareManifestServer;
  requireShareIdentification: boolean;
  resourceName: string;
  resourcePath: string;
  resourceType: ShareManifestContextableTypes;
}

export const Metadata: FunctionComponent<MetadataProps> = (props) => {
  const { assetsHidden, assetsShown, manifest, requireShareIdentification, resourceName, resourcePath, resourceType } = props;

  const [shareManifestServer, setShareManifestServer] = useState(manifest);
  const [updated, setUpdated] = useState(false);

  const shareManifest = mapShareManifestServerToClient(shareManifestServer);
  const { availabilityEnd, createdBy, createdAt, expires, internal, key, notificationsOptin, requireIdentification } = shareManifest;

  const putShareManifest = useFetch<ShareManifestPutResponse>({
    body: {
      data: {
        attributes: {
          notifications_optin: notificationsOptin,
          require_identification: requireIdentification
        }
      }
    },
    fetchOnMount: false,
    method: 'PUT',
    url: `/api/v4/share_manifests/${key}`
  });

  useEffect(() => {
    if (putShareManifest.error) {
      Notify.create({
        title: t`Error updating Share Link. Please try again.`,
        type: 'error'
      });
    }
  }, [putShareManifest.error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (putShareManifest.response) {
      setShareManifestServer({ ...shareManifestServer, ...putShareManifest.response.data.attributes });
      Notify.create({
        title: t`Share Link successfully updated.`,
        type: 'success'
      });
    }
  }, [putShareManifest.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updated) {
      putShareManifest.fetch();
      setUpdated(false);
    }
  }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <p className={classes.visible}>
        <span className={classnames('bff-show', classes['bff-show'])} />
        <Trans>Only visible to editors</Trans>
      </p>
      <CardHeader>
        <CardPairing>
          <h6 className={classes.h6}>{resourceType}</h6>
          <p className={classnames(classes.p, classes.noMargin)}>
            <a
              href={resourcePath}
              rel="noopener noreferrer"
              target="_blank"
            >
              {resourceName === 'Brandfolder' && isGettyClient() ? <Trans>Library</Trans> : resourceName}
            </a>
          </p>
        </CardPairing>
      </CardHeader>
      <CardPairing className={classes.p} tag="p">
        <div>
          <strong><Trans>Assets hidden</Trans></strong>
          <Tooltip
            className={classes.tooltip}
            tooltipContent={t`Hidden assets are either unpublished, expired or unapproved.`}
            tooltipId="assets-hidden-metadata"
          >
            <span className={`bff-tool-tip ${classes['bff-tool-tip']}`} />
          </Tooltip>
        </div>
        <span>{assetsHidden}</span>
      </CardPairing>
      <CardPairing className={classes.p} tag="p">
        <Trans>
          <strong>Assets shown</strong> <span>{assetsShown}</span>
        </Trans>
      </CardPairing>
      <CardPairing className={classes.p} tag="p">
        {internal
          ? (
            <Trans>
              <strong>Access</strong> <span>Private</span>
            </Trans>
          ) : (
            <Trans>
              <strong>Access</strong> <span>Public</span>
            </Trans>
          )
        }
      </CardPairing>
      {/* only show this switch if require_share_identification is turned off at the Brandfolder level (essentially a global override) */}
      {/* require_share_identification is toggle via the Brandfolder settings modal at "Require ID for public shares" */}
      {!requireShareIdentification && (
      <CardPairing>
        <p className={classnames(classes.p, classes.noMargin)}>
          <strong><Trans>Require email for access</Trans></strong>{' '}
        </p>
        <StandardSwitch
          className={classes.switch}
          id="share-manifest-require-identification-switch"
          isChecked={requireIdentification}
          onChange={(): void => {
            setShareManifestServer({
              ...shareManifestServer,
              require_identification: !shareManifestServer.require_identification
            });
            setUpdated(true);
          }}
        />
      </CardPairing>
      )}
      <CardPairing className={classes.p} tag="p">
        <strong><Trans>Creation date</Trans></strong> <span>{localizeDateTime(createdAt)}</span>
      </CardPairing>
      <CardPairing className={classes.p} tag="p">
        <strong><Trans>Expiration date</Trans></strong> <span>{expires ? localizeDateTime(availabilityEnd) : '-'}</span>
      </CardPairing>
      <CardPairing className={classes.p} tag="p">
        <strong><Trans>Created by</Trans></strong> <span>{createdBy}</span>
      </CardPairing>
      <CardPairing>
        <p className={classnames(classes.p, classes.noMargin)}>
          <strong><Trans>Notifications</Trans></strong>{' '}
        </p>
        <StandardSwitch
          className={classes.switch}
          id="share-manifest-notifications-switch"
          isChecked={notificationsOptin}
          onChange={(): void => {
            const updatedNotificationsOptin = !shareManifestServer.notifications_optin;
            setShareManifestServer({
              ...shareManifestServer,
              notifications_optin: updatedNotificationsOptin
            });
            setUpdated(true);
            setStorage(StorageTypes.Local, SHARE_LINK_NOTIFICATIONS_PREFERENCE_KEY, updatedNotificationsOptin);
          }}
        />
      </CardPairing>
    </Card>
  );
};
