import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { emptyStates, emptyStatesUnprocessedBrandfolder } from '../constants';
import { FilterValues, PeoplePageParamKeys } from '../customPeopleTagTypes';
import { usePeoplePageSearchState } from '../hooks/usePeoplePageQueryState';

const EmptyStateWrapper = styled.div`
  margin: 0 auto;
  max-width: 480px;
  padding: 64px 0;
  text-align: center;

  h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    padding: 32px 0 12px;
  }

  p {
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
  }
`;

interface EmptyStateProps {
  bfProcessed: boolean;
}

export const EmptyState: FunctionComponent<EmptyStateProps> = ({ bfProcessed }) => {
  const [state] = usePeoplePageSearchState();

  const getEmptyStateKey = (): PeoplePageParamKeys | string => {
    if (state.search)
      return PeoplePageParamKeys.Search;
    if (state.ids)
      return PeoplePageParamKeys.AssetIds;

    return state.filter || FilterValues.All;
  };

  const emptyKey = getEmptyStateKey();
  const content = bfProcessed ? emptyStates() : emptyStatesUnprocessedBrandfolder();

  return (
    <EmptyStateWrapper>
      <img alt={t`no results`} src={content[emptyKey].imgSrc} />
      <h5>{content[emptyKey].heading}</h5>
      <p>{content[emptyKey].subheading}</p>
    </EmptyStateWrapper>
  );
};
