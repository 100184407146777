import { keyPressHelper } from '@brandfolder/utilities';
import React, { useEffect, useState, KeyboardEvent } from 'react';

import styleClassNames from '@styles/modules/section_bar_heading.module.scss';

import LanguageMenuDropdown from './languageMenuDropdown';

export interface SectionDetails {
  displayName: string;
  content: JSX.Element;
  onActiveSection?: () => void;
  tabClassName?: string;
}

export interface SectionBarProps {
  sections: SectionDetails[];
}

const SectionBar = (props: SectionBarProps): JSX.Element => {
  const { sections } = props;
  const [visibleSection, setVisibleSection] = useState(0);
  const onClickHandler = (targetSection: number): void => {
    setVisibleSection(targetSection);
  };

  useEffect(() => {
    const callback = sections[visibleSection].onActiveSection;
    if (callback) { callback(); }
  }, [sections, visibleSection]);

  const onKeyPressHandler = keyPressHelper(onClickHandler);

  return (
    <div>
      <div className={`section-bar-heading ${styleClassNames['section-bar-heading']}`}>
        <ul className={`${styleClassNames['section-bar-list']}`} role="tablist">
          {sections.map((section, i) => {
            const activeClass = i === visibleSection ? `t-active ${styleClassNames['active-section']}` : '';
            const itemClass = styleClassNames['section-bar-item'];
            const tabClass = section.tabClassName || '';
            return (
              <li
                key={section.displayName}
                className={`section-bar-item ${itemClass} ${tabClass} ${activeClass}`}
                data-title={section.displayName}
                onClick={(): void => onClickHandler(i)}
                onKeyPress={(e: KeyboardEvent<HTMLLIElement>): void => onKeyPressHandler(e, i)}
                role="tab"
                tabIndex={0}
              >
                {section.displayName}
              </li>
            );
          })}
        </ul>
        {BFG.multiLanguageAssetDetailsEnabled && (
          <LanguageMenuDropdown />
        )}
      </div>
      <div className="section-content">
        {sections[visibleSection].content}
      </div>
    </div>
  );
};

export default SectionBar;
