import React, {
  FunctionComponent,
  ReactNode,
} from 'react';
import classnames from 'classnames';

import { capitalize } from '@helpers/humanize';


interface RadioButtonGroupProps {
  name: string;
  styleClassNames: { [key: string]: string };
  variantName: string;
  className?: string;
  containerId?: string;
}

const baseName = 'radio-button-group';

const BaseRadioButtonGroup: FunctionComponent<RadioButtonGroupProps> = (
  props,
) => {
  const {
    children,
    className,
    containerId,
    name,
    styleClassNames,
    variantName,
  } = props;

  return (
    <div
      aria-label={capitalize(name)}
      className={classnames(
        className,
        `bf-${baseName}__${variantName}`, // base-variant class for post consumption styling
        styleClassNames[variantName], // class for css modules
      )}
      id={containerId}
      role="radiogroup"
    >
      {children}
    </div>
  );
};

export default BaseRadioButtonGroup;
