import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import ClipboardCopy from '@components/common/clipboard_copy/main';
import { PrimaryButton } from '@components/library/button';

interface CDNLinkProps {
  label: string;
  link: string;
  description?: string;
  disabled?: boolean;
  styleClass?: string;
}

const CDNLink: FunctionComponent<CDNLinkProps> = ({
  description,
  disabled,
  label,
  link,
  styleClass
}) => (
  <div className={`cdn-builder ${styleClass}`}>
    <span className="cdn-builder__title bf-label bf-label--primary">
      {label}
    </span>
    <div className="embed-link-container">
      <pre
        className="embed-link"
      >
        <code
          className={`${disabled ? 'disabled-code' : ''}`}
        >
          {link}
        </code>
      </pre>
      <PrimaryButton
        disabled={disabled}
      >
        <ClipboardCopy
          check
          showFeedbackAsTooltip={false}
          textToCopy={link}
        >
          <Trans>Copy link</Trans>
        </ClipboardCopy>
      </PrimaryButton>
    </div>
    <p className="embed-link-container__description">
      {description}
    </p>
  </div>
);

export default CDNLink;
