import React, { FunctionComponent, KeyboardEvent } from 'react';


import { BFLoaderOverlay } from '@components/common/loader/main';
import { AccordionAnimations, AccordionHeadingLevels, SingleAccordionProps } from '@components/library/accordion';
import { AccordionHeader } from './AccordionHeader';
import { AccordionPanel } from './AccordionPanel';

import classes from '../styles/accordion.module.scss';

export interface AccordionProps {
  accordion: SingleAccordionProps;
  disabled: boolean | undefined;
  focusIndex: number | null;
  id: string;
  index: number;
  onChange: (index: number) => void;
  onKeyUp: (e: KeyboardEvent<HTMLButtonElement>, index: number) => void;
  open: boolean | undefined;
  animation?: AccordionAnimations;
  headingLevel?: AccordionHeadingLevels;
  initialOpen?: boolean;
  loading?: boolean;
  unmountOnExit?: boolean;
}

/**
 * A single accordion with a header and panel.
 * @param {AccordionProps} props AccordionProps
 */
export const Accordion: FunctionComponent<AccordionProps> = (props) => {
  const {
    accordion,
    animation,
    disabled,
    focusIndex,
    id,
    index,
    loading,
    onChange,
    onKeyUp,
    open,
    unmountOnExit
  } = props;

  const accordionButtonId = `${id}-button-${index}`;
  const accordionPanelId = `${id}-panel-${index}`;

  return (
    <>
      <AccordionHeader
        accordionPanelId={accordionPanelId}
        buttonIcon={accordion.buttonIcon}
        buttonIconPosition={accordion.buttonIconPosition}
        buttonProps={accordion.buttonProps}
        disabled={disabled}
        focusIndex={focusIndex}
        headerContent={accordion.headerContent}
        headerContentPosition={accordion.headerContentPosition}
        headingProps={accordion.headingProps}
        id={accordionButtonId}
        index={index}
        onChange={onChange}
        onKeyUp={onKeyUp}
        open={open}
        showText={accordion.showText}
      >
        {accordion.button}
      </AccordionHeader>
      <AccordionPanel
        accordionButtonId={accordionButtonId}
        animation={animation}
        id={accordionPanelId}
        loading={loading}
        open={open}
        panelProps={accordion.panelProps}
        unmountOnExit={unmountOnExit}
      >
        {accordion.panel}
        {loading && <BFLoaderOverlay loaderClassName={classes.loader} />}
      </AccordionPanel>
    </>
  );
};
