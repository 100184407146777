import { t, Trans } from '@lingui/macro';
import React, { Dispatch, FunctionComponent, ReactNode, SetStateAction } from 'react';

import { FetchState } from '@api/ApiHelper';
import { PrimaryButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { ListDropdown } from '@components/library/dropdown';
import { FontIcon, FontIcons } from '@components/library/icon';
import Label from '@components/library/labels/PrimaryLabel';

import { InsightsDataSyncResponse, WorksheetDatum } from './InsightsTypes';

interface ConnectionSetupDialogProps {
  dataSmartsheetWorksheets: WorksheetDatum[];
  handleSelectedWorkspace: () => void;
  isConnected: boolean;
  isSelectionDialogOpen: boolean;
  setIsSelectionDialogOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedWorkspace: (workspace) => void;
  setWorkspaceDropdownState: Dispatch<SetStateAction<WorksheetDatum>>;
  setWorksheetResponse: FetchState<InsightsDataSyncResponse>;
  workspaceDropdownState: WorksheetDatum;
}

const noWorkspacesAvailableCopy = (): ReactNode => (
  <Trans>
    No Smartsheet Workspaces available to connect to. Navigate to your Smartsheet account to join or create a Smartsheet Workspace and then try connecting again.
  </Trans>
);

export const ConnectionSetupDialog: FunctionComponent<ConnectionSetupDialogProps> = (
  {
    dataSmartsheetWorksheets,
    handleSelectedWorkspace,
    isConnected,
    isSelectionDialogOpen,
    setIsSelectionDialogOpen,
    setSelectedWorkspace,
    setWorkspaceDropdownState,
    setWorksheetResponse,
    workspaceDropdownState
  }) => {

  const worksheetOptions = dataSmartsheetWorksheets
    ? dataSmartsheetWorksheets.map(({ id, name }) => ({
      label: name,
      value: id,
    }))
    : null;

  return (
    <StandardDialog
      dialogBodyClassName="insights-connect-smartsheet-dialog-body"
      dialogHeaderClassName="insights-connect-smartsheet-dialog-header"
      height={450}
      id="insights-connect-smartsheet-dialog"
      onClose={(): void => setSelectedWorkspace(null)}
      open={isSelectionDialogOpen}
      setOpen={setIsSelectionDialogOpen}
      showFooter={false}
      title={t`Select a Workspace in Smartsheet to connect to Brandfolder`}
      titleIcon="bff-smartsheet"
      width={750}
    >
      <div className="insights-connect-smartsheet-dialog__body">
        <div className="insights-connect-smartsheet-dialog__options">
          {dataSmartsheetWorksheets?.length === 0 && (
            <div className="connect-smartsheet-dialog__options">
              <FontIcon aria-hidden icon={FontIcons.Warning} />
              <p>{noWorkspacesAvailableCopy()}</p>
            </div>
          )}
          <Label><Trans>Select Smartsheet Workspace</Trans></Label>
          <ListDropdown
            className="insights-connect-smartsheet-dialog__options--dropdown"
            id="workspace-options"
            onChange={({ value }): void => {
              setWorkspaceDropdownState(
                dataSmartsheetWorksheets?.find(
                  (sheet) => sheet.id === value
                )
              );
            }}
            options={worksheetOptions}
            placeholder={workspaceDropdownState?.name}
            value={workspaceDropdownState?.id}
          />
        </div>
        <PrimaryButton
          className="insights-connect-smartsheet-dialog__submit"
          disabled={dataSmartsheetWorksheets?.length < 1 || isConnected}
          icon={isConnected ? FontIcons.CheckMark : undefined}
          isLoading={setWorksheetResponse.loading}
          loadingCopy={t`Connecting`}
          loadingIcon={FontIcons.RefreshSpinning}
          onClick={(): void => {
            handleSelectedWorkspace();
          }}
          size="small"
          type="submit"
        >
          {isConnected
            ? t`Connected`
            : t`Connect`}
        </PrimaryButton>
      </div>
    </StandardDialog>
  );
};
