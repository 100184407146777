
import { fetchJson } from '@api/ApiHelper';
import { UsersPermissibleResources } from './UsersTypes';

interface GetUsersPermissibleResourcesOptions {
  organizationKey: string;
  userKey: string;
  permissionLevel?: string;
}

interface GetUsersPermissibleResourcesResponse {
  data: UsersPermissibleResources;
}

/**
 * Get a users' permissible resources (their Brandfolders, Collections, and Organizations)
 * @param {GetUsersPermissibleResourcesOptions} options GetUsersPermissibleResourcesOptions
 * @returns {Promise<GetUsersPermissibleResourcesResponse>} Promise<GetUsersPermissibleResourcesResponse>
 */
export const getUsersPermissibleResources = async (options: GetUsersPermissibleResourcesOptions): Promise<GetUsersPermissibleResourcesResponse> => {
  try {
    const { organizationKey, permissionLevel = 'admin', userKey } = options;
    const result = await fetchJson<GetUsersPermissibleResourcesResponse>({
      url: `/api/v3/users/${userKey}/permissible_resources?permission_level=${permissionLevel}&organization_key=${organizationKey}`
    });
    return Promise.resolve(result);
  } catch (err) {
    return Promise.reject(err);
  }
};
