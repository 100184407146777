import { AlertLooks, StandardAlert } from '@brandfolder/react';
import React, { useState, FunctionComponent, useEffect } from 'react';

import { useFetch } from '@api/ApiHelper';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import CustomFieldsValueManagement from '@components/bulk_management/custom_field_values/main';
import { CustomFieldKeyCount } from '@components/bulk_management/custom_fields/CustomFieldKeyCount';
import { CustomFieldsManagement } from '@components/bulk_management/custom_fields/CustomFieldsManagement';
import {
  getCurrentUrlLocale
} from '@components/bulk_management/user_generated_translations/BulkManagementLanguageMenuDropdown';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { Locale } from '@components/common/language_menu/languagesMap';
import { UGTAlert } from '@components/common/language_menu/UGTAlert';
import { genericError } from '@components/standard-messages';

export const BulkCustomFields: FunctionComponent = () => {
  const [selectedCustomFieldKey, setSelectedCustomFieldKey] = useState<CustomFieldKeyCount>();
  const [ugtLocale, setUgtLocale] = useState<Locale>(
    getCurrentUrlLocale() || determineUGTLocale() || BFG.locales?.ugtLocaleDefault
  );

  const customFieldKeyCountFetch = useFetch<CustomFieldKeyCount[]>({
    url: `/api/v4/private/brandfolders/${BFG.resource?.key}/custom_field_key_counts`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    params: { 'ugt_locale': ugtLocale },
    fetchOnMount: false
  });

  useEffect(() => {
    if (BFG.resource?.key && ugtLocale) {
      customFieldKeyCountFetch.fetch();
    }
  }, [ugtLocale]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!BFG.resource?.key || customFieldKeyCountFetch.error) {
    return (
      <StandardAlert
        look={AlertLooks.Error}
      >
        {genericError()}
      </StandardAlert>
    );
  }

  return (
    <I18nProviderWrapper>
      <div>
        <UGTAlert locale={ugtLocale} />
        {selectedCustomFieldKey ? (
          <CustomFieldsValueManagement
            customFieldKeyKey={selectedCustomFieldKey.key}
            customFieldKeyName={selectedCustomFieldKey.name}
            setSelectedCustomFieldKey={setSelectedCustomFieldKey}
            setUgtLocale={setUgtLocale}
            ugtLocale={ugtLocale}
          />
        ) : (
          <CustomFieldsManagement
            customFieldKeys={customFieldKeyCountFetch.response || []}
            loading={customFieldKeyCountFetch.loading}
            setSelectedCustomFieldKey={setSelectedCustomFieldKey}
            setUgtLocale={setUgtLocale}
            ugtLocale={ugtLocale}
          />
        )}
      </div>
    </I18nProviderWrapper>
  );
};
