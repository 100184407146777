import { Trans } from '@lingui/macro';
import React, { useState } from 'react';

import { BrandguideCreateForm } from '@components/brandguides/BrandguideCreateForm';
import DropdownWrapper from '@components/common/dropdown_wrapper/main';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { isGettyClient } from '@helpers/getty-strings'

import PortalForm from '../../portals/portal/portal_form';

import NewBrandfolderModal from './NewBrandfolderModal';

import './styles/_create_new_dropdown.scss';

interface CreateNewDropdownProps {
  atBrandfolderLimit: boolean;
  atBrandguideLimit: boolean;
  atPortalLimit: boolean;
  libraryName: string;
  organizationKey: string;
  name: string;
  slug: string;
}

const CreateNewDropdown = ({
  atBrandfolderLimit,
  atBrandguideLimit,
  atPortalLimit,
  libraryName,
  name,
  organizationKey,
  slug
}: CreateNewDropdownProps): JSX.Element => {
  const [showForm, setShowForm] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [showNewBrandfolderModal, setShowNewBrandfolderModal] = useState(false);
  const [showNewBrandguideModal, setShowNewBrandguideModal] = useState(false);
  const toggleForm = (): void => setShowForm((prevState) => !prevState);
  const toggleDropdown = (): void => setCloseDropdown((prevState) => !prevState);

  let redirectToQuote, upgradeCopy, translatedLibraryName;

  if (isGettyClient()) {
    redirectToQuote = (): void => {
      window.location.href = `mailto:${BFG.supportEmail}?subject=Media Manager Inquiry&body=Please detail your support request here`;
    };
    upgradeCopy = <Trans>Upgrade for Libraries</Trans>;
    translatedLibraryName = <Trans>Library</Trans>;
  } else {
    redirectToQuote = (): void => { window.location.href = 'https://brandfolder.com/quote'; };
    upgradeCopy = <Trans>Upgrade for Brandfolders</Trans>;
    translatedLibraryName = <Trans>Brandfolder</Trans>;
  }

  const renderBrandfolderDropdown = (): JSX.Element => {
    if (atBrandfolderLimit) {
      return (
        <li
          key="brandfolder"
          className="create-new-dropdown__list--item upgrade-brandfolder"
          onClick={redirectToQuote}
          role="button"
          tabIndex={0}
        >
          <span className="bff-grid-view icon" />
          {upgradeCopy}
        </li>
      );
    }

    return (
      <li
        key="brandfolder"
        className="create-new-dropdown__list--item"
        onClick={(): void => setShowNewBrandfolderModal(true)}
        role="button"
        tabIndex={0}
      >
        <span className="bff-grid-view icon" />
        {translatedLibraryName}
      </li>
    );
  };

  const renderPortalDropdown = (): JSX.Element | null => {
    // For Beta Mode
    if (atPortalLimit) { return null; }

    return (
      <li
        key="portal"
        className="create-new-dropdown__list--item create-portal"
        onClick={toggleForm}
        role="button"
        tabIndex={0}
      >
        <span className="bff-portal icon" />
        <Trans>Brand Portal</Trans>
      </li>
    );
  };

  const renderBrandguideDropdown = (): JSX.Element => {
    if (atBrandguideLimit) { return null; }

    return (
      <li
        key="brandguide"
        className="create-new-dropdown__list--item create-brandguide"
        onClick={(): void => setShowNewBrandguideModal(true)}
        role="button"
        tabIndex={0}
      >
        <span className="bff-brand-guide icon" />
        <Trans>Brandguide</Trans>
      </li>
    );
  };

  const dropdownContents: JSX.Element = (
    <ul className="create-new-dropdown__list">
      {renderBrandfolderDropdown()}
      {renderPortalDropdown()}
      {renderBrandguideDropdown()}
    </ul>
  );

  return (
    <I18nProviderWrapper>
      <DropdownWrapper
        close={closeDropdown}
        closeCallback={toggleDropdown}
        cssTransitionClasses="fade-in-translate"
        dropdownContents={dropdownContents}
        openOnClick={false}
        wrapperClassnames="create-new-dropdown"
      >
        <span className="bff-caret-down" />
      </DropdownWrapper>
      {showNewBrandguideModal && (
        <BrandguideCreateForm isOrg open={showNewBrandguideModal} organizationKey={organizationKey} setOpen={setShowNewBrandguideModal} />
      )}
      {showForm
        && (
          <PortalForm
            closeModal={toggleForm}
            libraryName={libraryName}
            organizationKey={organizationKey}
          />
        )
      }
      {showNewBrandfolderModal && (
        <NewBrandfolderModal
          closeModal={(): void => setShowNewBrandfolderModal(false)}
          libraryName={libraryName}
          name={name}
          slug={slug}
        />
      )}
    </I18nProviderWrapper>
  );
};

export default CreateNewDropdown;
