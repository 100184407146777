import fetchJSON from '@api/api_helper';

const getUrl = (attachmentKey) => `/api/v4/attachments/${attachmentKey}/reservations`;

const processReservations = (response) => {
  // for data: GET response is array, POST response is object
  const responseData = Array.isArray(response.data) ? response.data : [response.data];

  const currentStatus = !responseData.length
    ? "checked_in"
    : responseData.sort((a, b) => (
      new Date(b.attributes.created_at) - new Date(a.attributes.created_at)
    ))[0].attributes.status;

  const users = {};
  if (response.included && response.included.length) {
    response.included.forEach((includedItem) => {
      if (includedItem.type === 'users') {
        users[includedItem.id] = { ...includedItem.attributes };
      }
    });
  }

  const history = !responseData.length ? [] : responseData.map((reservation) => (
    {
      id: reservation.id,
      author: reservation.relationships.user ? reservation.relationships.user.data.id : null,
      type: "reservation",
      ...reservation.attributes,
    }
  ));

  return { currentStatus, history, users };
};

export default (options, updateFetchControllers) => (
  fetchJSON(getUrl(options.attachmentKey), options, updateFetchControllers)
    .then((response) => (processReservations(response)))
);

export const postReservations = (options, updateFetchControllers) => {
  const url = getUrl(options.attachmentKey);
  const data = {
    status: options.updatedStatus,
    note: options.checkinCheckoutNote
  };
  const appendedOptions = {
    body: { data: { attributes: data } },
    method: 'POST'
  };

  if (options.params) {
    appendedOptions.params = options.params;
  }

  return fetchJSON(url, appendedOptions, updateFetchControllers)
    .then((response) => processReservations(response));
};
