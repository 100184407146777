import { FontIcons, TileButton, TileButtons } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, ReactNode, useState } from 'react';

import { AssetsResponse, AssetTypes } from '@api/v4/assets/assetTypes';
import ModalEditFormContent from '@components/asset/modal/tabs/edit/main';
import { TextButton } from '@components/library/button';
import { DialogSizes, StandardDialog } from '@components/library/dialog';
import { CanvaButtonApiV2 } from '@components/show_page/sections/asset/canva/CanvaTypes';
import { CreateCanvaDesign } from '@components/show_page/sections/asset/CreateCanvaDesign';
import { CreateNewAssetTypes } from '@components/show_page/sections/asset/custom-templates';
import { CreateDigitalDesignProject } from '@components/show_page/sections/asset/custom-templates/CreateDigitalDesignProject';
import { CreatePrintDesignProject } from '@components/show_page/sections/asset/custom-templates/CreatePrintDesignProject';

import '@components/show_page/sections/asset/styles/create-new-asset-dialog.scss';

interface CreateNewAssetDialogProps {
  canvaApi: CanvaButtonApiV2 | null;
  onAssetProcessing: (numAssets: number) => void;
  onNewAssets: (response: AssetsResponse) => void;
  sectionKey: string;
  showCreateNewAssetDialog: boolean;
  setShowCreateNewAssetDialog: SetStateDispatch<boolean>;
  hideBackButton?: boolean;
  initialStep?: CreateNewAssetTypes;
}

interface CreateNewAssetType {
  className: string;
  description: string;
  heading: string;
  icon: FontIcons;
  id: CreateNewAssetTypes;
  show: boolean;
}

export const CreateNewAssetDialog: FunctionComponent<CreateNewAssetDialogProps> = ({
  canvaApi,
  hideBackButton,
  initialStep,
  onAssetProcessing,
  onNewAssets,
  sectionKey,
  setShowCreateNewAssetDialog,
  showCreateNewAssetDialog
}) => {
  const [step, setStep] = useState(initialStep || CreateNewAssetTypes.ChooseAssetType);

  const hasCanva = BFG.hasFeature('canva_button');
  const hasCustomTemplates = BFG.hasFeature('custom_templates');
  const hasTask = BFG.hasFeature('workspace') && BFG.resource.is_workspace;
  const isCreateProject = step === CreateNewAssetTypes.DigitalDesignProject || step === CreateNewAssetTypes.PrintDesignProject;

  const createNewAssetTypes: CreateNewAssetType[] = [
    {
      className: 'create-new-asset-dialog__digital-design-project-button',
      description: t`For social media, ads and websites using pixel-based dimensions`,
      heading: t`Digital Design Project`,
      icon: FontIcons.Monitor,
      id: CreateNewAssetTypes.DigitalDesignProject,
      show: hasCustomTemplates
    },
    {
      className: 'create-new-asset-dialog__print-design-project-button',
      description: t`High quality with bleed & CMYK using inches-based dimensions`,
      heading: t`Print Design Project`,
      icon: FontIcons.Print,
      id: CreateNewAssetTypes.PrintDesignProject,
      show: hasCustomTemplates
    },
    // {
    //   className: 'create-new-asset-dialog__video-project-button',
    //   description: t`Create one or more animated scenes of any duration and size`,
    //   heading: t`Video Project`,
    //   icon: FontIcons.Video,
    //   id: CreateNewAssetTypes.VideoProject,
    //   show: hasCustomTemplates
    // },
    {
      className: 'create-new-asset-dialog__canva-button',
      description: t`Open and create assets within Canva`,
      heading: t`Canva Design`,
      icon: FontIcons.Canva,
      id: CreateNewAssetTypes.CanvaDesign,
      show: canvaApi && hasCanva
    },
    {
      className: 'create-new-asset-dialog__task-button',
      description: t`Create a Task for a Workspace`,
      heading: t`Task`,
      icon: FontIcons.Task,
      id: CreateNewAssetTypes.Task,
      show: hasTask
    }
  ];

  const renderChooseAssetType = (): ReactNode => (
    <div className="create-new-asset-dialog__padding">
      <p className="create-new-asset-dialog__p">
        <Trans>Choose an asset type</Trans>
      </p>
      <TileButtons>
        {createNewAssetTypes.map((createNewAssetType) => {
          const { className, description, heading, icon, id, show } = createNewAssetType;
          return show
            ? (
              <TileButton
                key={id}
                className={className}
                description={description}
                heading={heading}
                icon={icon}
                onClick={(): void => setStep(id)}
              />
            )
            : null;
        })}
      </TileButtons>
    </div>
  );

  const renderBackButton = (): ReactNode => (!hideBackButton && (
    <TextButton
      className="create-new-asset-dialog__back"
      icon={FontIcons.CaretLeft}
      onClick={(): void => setStep(CreateNewAssetTypes.ChooseAssetType)}
    >
      <Trans>Back</Trans>
    </TextButton>
  ));

  const renderCanvaDesign = (): ReactNode => (
    <>
      {renderBackButton()}
      <div className="create-new-asset-dialog__padding">
        <CreateCanvaDesign
          canvaApi={canvaApi}
          onAssetProcessing={onAssetProcessing}
          onNewAssets={onNewAssets}
          sectionKey={sectionKey}
          setShowCreateNewAssetDialog={setShowCreateNewAssetDialog}
        />
      </div>
    </>
  );

  const renderCreateDigitalDesignProject = (): ReactNode => (
    <>
      {renderBackButton()}
      <CreateDigitalDesignProject
        sectionKey={sectionKey}
      />
    </>
  );

  const renderCreatePrintDesignProject = (): ReactNode => (
    <>
      {renderBackButton()}
      <CreatePrintDesignProject
        sectionKey={sectionKey}
      />
    </>
  );

  const renderCreateVideoDesignProject = (): ReactNode => (
    <>
      {renderBackButton()}
      <div className="create-new-asset-dialog__padding">
        <p>Video</p>
      </div>
    </>
  );

  const renderTask = (): ReactNode => (
    <>
      {renderBackButton()}
      <ModalEditFormContent
        assetKey=""
        assetType={AssetTypes.GenericFile}
        isCreate
        isTask
        sectionKey={sectionKey}
      />
    </>
  );

  const renderTitle = (): string => {
    switch (step) {
      case (CreateNewAssetTypes.CanvaDesign || CreateNewAssetTypes.ChooseCanvaDesign):
        return t`Create Canva Design`;
      case CreateNewAssetTypes.DigitalDesignProject:
        return t`Create Digital Design Project`;
      case CreateNewAssetTypes.PrintDesignProject:
        return t`Create Print Design Project`;
      case CreateNewAssetTypes.Task:
        return t`Create Task`;
      case CreateNewAssetTypes.VideoProject:
        return t`Create Video Project`;
      default:
        return t`Create new asset`;
    }
  };

  const renderTitleIcon = (): string => {
    switch (step) {
      case (CreateNewAssetTypes.CanvaDesign || CreateNewAssetTypes.ChooseCanvaDesign):
        return `bff-${FontIcons.Canva}`;
      case CreateNewAssetTypes.DigitalDesignProject:
        return `bff-${FontIcons.Monitor}`;
      case CreateNewAssetTypes.PrintDesignProject:
        return `bff-${FontIcons.Print}`;
      case CreateNewAssetTypes.Task:
        return `bff-${FontIcons.Task}`;
      case CreateNewAssetTypes.VideoProject:
        return `bff-${FontIcons.Video}`;
      default:
        return `bff-${FontIcons.Plus}`;
    }
  };

  const renderSteps = (): ReactNode => {
    switch (step) {
      case (CreateNewAssetTypes.CanvaDesign || CreateNewAssetTypes.ChooseCanvaDesign):
        return renderCanvaDesign();
      case CreateNewAssetTypes.DigitalDesignProject:
        return renderCreateDigitalDesignProject();
      case CreateNewAssetTypes.PrintDesignProject:
        return renderCreatePrintDesignProject();
      case CreateNewAssetTypes.VideoProject:
        return renderCreateVideoDesignProject();
      case CreateNewAssetTypes.Task:
        return renderTask();
      default:
        return renderChooseAssetType();
    }
  };

  const getDialogHeight = (): number | undefined => {
    if (step === CreateNewAssetTypes.ChooseAssetType) {
      if (hasCanva && hasTask) {
        return 640;
      }
      return 450;
    }
    return undefined;
  };

  const getDialogWidth = (): number | undefined => {
    if (step === CreateNewAssetTypes.ChooseAssetType) {
      return 840;
    }
    if (isCreateProject) {
      return 960;
    }
    return undefined;
  };

  const getDialogSize = (): DialogSizes | undefined => {
    if (step === CreateNewAssetTypes.Task) {
      return DialogSizes.Xxlarge;
    }
    if (step === CreateNewAssetTypes.CanvaDesign || step === CreateNewAssetTypes.ChooseCanvaDesign) {
      return DialogSizes.Large;
    }
    return undefined;
  };

  const getFocusLock = (): boolean => {
    if (step === CreateNewAssetTypes.CanvaDesign) {
      return false;
    }
    return true;
  };

  return (
    <StandardDialog
      dialogClassName={classnames({
        'create-new-asset-dialog': true,
        'create-new-asset-dialog__create-project': isCreateProject // eslint-disable-line @typescript-eslint/naming-convention
      })}
      focusLock={getFocusLock()}
      height={getDialogHeight()}
      id="create-new-asset-dialog"
      onClose={(): void => {
        setStep(initialStep || CreateNewAssetTypes.ChooseAssetType);
      }}
      open={showCreateNewAssetDialog}
      setOpen={setShowCreateNewAssetDialog}
      showFooter={false}
      size={getDialogSize()}
      title={renderTitle()}
      titleIcon={renderTitleIcon()}
      width={getDialogWidth()}
    >
      {renderSteps()}
    </StandardDialog>
  );
};
