/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ListDropdown } from '@components/library/dropdown';

import ManageSearchPinsModal from './manage_search_pins/manage_modal';
import './styles/main.scss';

const PinnedSearches = (props) => {
  const [showModal, updateShowModal] = useState(false);
  const {
    editable,
    pinsDropdownSetting,
    searchFilters,
    searchQuery,
    submitSearchQuery,
    updateSearchFilters,
  } = props;

  const renderPinnedSearchesButtons = () => {
    const featuredSearchFilters = searchFilters.filter((filter) => filter.featured);
    return featuredSearchFilters.map((filter) => {
      const active = searchQuery === filter.query;
      const staticClasses = 'search-pill nav-pills-custom c-custom-color c-custom-pin-background';
      const searchQueryOptions = { resetSearchOperator: true, searchKey: filter.key };

      return (
        <button
          key={filter.key}
          className={classnames(staticClasses, { 'pill-active': active })}
          data-key={filter.key}
          data-term={filter.query}
          onClick={() => submitSearchQuery(filter.query, null, searchQueryOptions)}
          type="button"
        >
          <span aria-hidden="true" className="bff-pin"  />
          {filter.label}
        </button>
      );
    });
  };

  const handleOnChange = (e) => {
    let newQuery = '';
    if (e) {
      if (e.value === 'no-pin-selected') {
        newQuery = '';
      } else {
        newQuery = e.value;
      }
    }

    submitSearchQuery(newQuery, null, { resetSearchOperator: true, searchKey: e.label })
  };

  const renderPinnedSearchesDropdownList = () => {
    const clearOption = { value: 'no-pin-selected', label: '-'};
    let selectedPin = clearOption;

    const featuredSearchFilters = searchFilters.filter((filter) => (filter.featured));
    const options = featuredSearchFilters.map((filter) => {
      const active = searchQuery === filter.query;
      const option = { value: filter.query, label: filter.label };

      if (active) {
        selectedPin = option;
      }
      return option;
    });
    options.unshift(clearOption);

    return (
      <div className="select-list-wrapper pin-wrapper">
        <ListDropdown
          className="select-list"
          onChange={handleOnChange}
          options={options}
          value={selectedPin}
          virtualizeOptions={false}
        />
      </div>
    );
  };

  const displaySettingClass = pinsDropdownSetting
    ? 'display-pins-dropdown'
    : 'display-pins-buttons';

  return (
    <ul
      className={`j-filter-bar j-searches-list ${displaySettingClass}`}
      id="pinned-searches-list"
    >
      {editable && (
        <li className="edit search-pill t-manage-pinned-searches">
          <button
            className="button tertiary pins-button"
            onClick={() => updateShowModal(true)}
            type="button"
          >
            <span className="bff-edit" />
            <Trans>Pins</Trans>
          </button>
        </li>
      )}
      {(editable && showModal) && (
        <ManageSearchPinsModal
          resetShowModal={() => updateShowModal(false)}
          searchFilters={searchFilters}
          updateSearchFilters={updateSearchFilters}
        />
      )}
      {searchFilters.length && pinsDropdownSetting
        ? renderPinnedSearchesDropdownList()
        : renderPinnedSearchesButtons()
      }
    </ul>
  );
};

PinnedSearches.propTypes = {
  editable: PropTypes.bool.isRequired,
  searchFilters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  searchQuery: PropTypes.string,
  pinsDropdownSetting: PropTypes.bool,
  submitSearchQuery: PropTypes.func.isRequired,
  updateSearchFilters: PropTypes.func.isRequired,
};

PinnedSearches.defaultProps = {
  pinsDropdownSetting: null,
  searchFilters: [],
  searchQuery: null
};

export default PinnedSearches;
