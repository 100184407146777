import { StandardPopoutLabels } from '@brandfolder/react';
import { t } from '@lingui/macro';

export const getStandardPopoutLabels = (labels: Partial<StandardPopoutLabels> = {}): StandardPopoutLabels => {
  return {
    closeButtonLabel: t`Close`,
    loaderLabel: t`Loading`,
    ...labels
  };
};
