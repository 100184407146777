import fetchJSON from '@api/api_helper';

const { type, key } = BFG.resource;
const url = (customFieldKey) => `/api/v4/${type}s/${key}/custom_field_keys/${customFieldKey}/custom_field_values?unique_values=true`;

export const getUniqueValues = (customFieldKey, selectedUGTLocale) => (
  fetchJSON(url(customFieldKey), { params: { ugt_locale: selectedUGTLocale } })
    .then(({ data }) => data)
    .catch(async (response) => { throw (await response.json()); })
);
