import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { Organization } from './customPeopleTagTypes';
import './styles/people-tags-page-not-found.scss';
interface PageNotFoundProps {
  organization: Organization;
}

export const PageNotFound: FunctionComponent<PageNotFoundProps> = () => (
  <div className='people_tags-page-not-found'>
    <h1>
      <Trans>Page Not Found</Trans>
    </h1>
    {/* change this img */}
    <img alt="Reports No Results" src="https://cdn.bfldr.com/27C9EC93/at/s95463rng5kvhqx3f99g4wx/reports.svg?auto=webp&format=svg&width=224&height=160"></img>
    <span>
      <Trans>This is likely due to a mistyped URL or out-of-date link.</Trans>
    </span>
  </div>
);
