// this component is only rendered by the org search asset modal
import { localizeFileSize } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";

import Tooltip from "../../../../common/tooltip/main";
import { determineUGTLocale, getFilenameSansExt, handleThumbnailError } from '../edit/helpers';

import EmbedAttachmentButton from "./EmbedAttachmentButton";

class Attachment extends React.Component {
  postEmbedMessage = (options) => {
    const { attachment } = this.props;
    /* eslint-disable no-undef */
    // printuiFrameId defined in Rails controller/view
    const attachmentEmbedInfo = {
      key: attachment.id,
      name: attachment.attributes.filename,
      type: "embed",
      url: attachment.attributes.url,
      displayUrl: (attachment.attributes.view_thumbnail_retina
        || attachment.attributes.thumbnail_url),
      text: false,
      crop: (options.crop),
      printuiFrameId
    };
    window.parent.postMessage(attachmentEmbedInfo, '*');
    /* eslint-enable no-undef */
  }

  downloadLink() {
    const { attachmentKey } = this.props;
    return `/${BF.fx.organization().slug}/attachments/${attachmentKey}?dl=true&resource_key=${BF.fx.organization().key}&resource_type=Organization`;
  }

  renderSize() {
    const { height, size, width } = this.props.attachment.attributes;
    if (size) {
      if (width && height) {
        return <span> {localizeFileSize({ locale: determineUGTLocale(), size })} &middot; </span>;
      }
      return <span> {localizeFileSize({ locale: determineUGTLocale(), size })}</span>;
    }
    return null;
  }

  renderDimensions() {
    const { height, width } = this.props.attachment.attributes;
    if (width && height) {
      return <span>{width} x {height}</span>;
    }
    return null;
  }

  renderAction() {
    const { extension } = this.props.attachment.attributes;
    const { isDownloadable } = this.props;
    if (BFG.embedView) {
      return (
        <div style={{ padding: '8px 20px 10px' }}>
          <button
            className="button secondary sm"
            onClick={() => this.postEmbedMessage({ crop: false })}
            style={{ display: 'inline', marginBottom: '5px' }}
            type="button"
          >
            <Trans>Select</Trans>
          </button>
          <button
            className="button secondary sm"
            onClick={() => this.postEmbedMessage({ crop: true })}
            style={{ padding: '8px 20px 10px', display: 'inline' }}
            type="button"
          >
            <Trans>Crop</Trans>
          </button>
        </div>
      );
    }
    if (window.location.host.indexOf('embed') >= 0) {
      const { attachment } = this.props;
      return (
        <>
          <EmbedAttachmentButton
            attachmentKey={this.props.attachmentKey}
            displayUrl={(attachment.attributes.view_thumbnail_retina || attachment.attributes.thumbnail_url)}
            name={attachment.attributes.filename}
            url={attachment.attributes.url}
          />
          <EmbedAttachmentButton
            attachmentKey={this.props.attachmentKey}
            displayUrl={(attachment.attributes.view_thumbnail_retina || attachment.attributes.thumbnail_url)}
            downloadAttachment
            name={attachment.attributes.filename}
            url={attachment.attributes.url}
          />
        </>
      );
    }
    if (extension && isDownloadable) {
      return (
        <a
          className="pull-right j-download-attachment-link j-show-download-alert s-attachment-options"
          download
          href={this.downloadLink()}
          rel="noopener noreferrer"
          target="_blank"
        >
          <button className="button secondary sm" type="button">
            <div className="attachment-icon">
              <span aria-label="download" className="bff-download" />
            </div>
          </button>
        </a>
      );
    }
    return null;
  }

  render() {
    const { attachment, isActive } = this.props;
    const { extension, filename, id, mimetype, thumbnail_url, url } = attachment.attributes;

    return (
      <li
        key={id}
        className={`m-download-attachment s-download-computer dropdown ${isActive ? 'active' : ''}`}
        onClick={() => this.props.updateActiveImage(attachment)}
      >
        <div className="s-asset-card-details">
          <div className="flex-two-thirds">
            <div className="m-show-thumbnail-img flex-one-third">
              <img
                alt={filename}
                className="m-asset-img-thumb"
                data-private
                onError={(event) => {
                  handleThumbnailError(
                    event,
                    extension,
                    getFilenameSansExt(filename, extension)
                  );
                }}
                src={thumbnail_url}
              />
            </div>
            <div className="s-image-details flex-two-thirds">
              <Tooltip
                tooltipContent={filename}
                tooltipId={`attachment-${filename}`}
              >
                <div className="s-image-name" title={filename}>
                  {filename}
                </div>
              </Tooltip>
              <div className="s-file-download-meta">
                {this.renderSize()}
                {this.renderDimensions()}
              </div>
            </div>
          </div>
          <div>
            {!this.props.assetViewOnly && (
              <div className={`s-download-edit-attachments ${window.location.host.indexOf('embed') >= 0 ? 'embed-view' : ''}`}>
                {(mimetype && mimetype.indexOf("video") > -1)
                  && (url === null) ? (
                  <Tooltip
                    tooltipContent="Preparing Media Preview"
                    tooltipId="attachment-video"
                  >
                    <div className="dropdown-link">
                      <img
                        alt="loading icon"
                        className="bff-spinner-gif lighter-gray-text mid-gray-text-on-hover"
                        src="https://cdn.brandfolder.io/27C9EC93/at/qdh7e1-cbakhk-47987g/bff-spinner.gif"
                      />
                    </div>
                  </Tooltip>
                ) : (
                  ""
                )}
                {this.renderAction()}
              </div>
            )}
          </div>
        </div>
      </li>
    );
  }
}

Attachment.propTypes = {
  attachmentKey: PropTypes.string.isRequired,
  attachment: PropTypes.shape({
    attributes: PropTypes.shape({
      id: PropTypes.string,
      thumbnail_url: PropTypes.string,
      filename: PropTypes.string,
      mimetype: PropTypes.string,
      url: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
      size: PropTypes.number,
      extension: PropTypes.string,
      assetViewOnly: PropTypes.bool
    })
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  updateActiveImage: PropTypes.func.isRequired,
  isDownloadable: PropTypes.bool.isRequired
};

export default Attachment;
