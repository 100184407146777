import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from '../styles/dialog_inner.module.scss';

interface DialogInnerProps extends HTMLAttributes<HTMLDivElement> {
    /** children is required */
    children: ReactNode;
}

/**
 * Dialog inner, which surrounds <DialogHeader>, <DialogBody>, and <DialogFooter> (but not any prev or next components)
 * @param {DialogInnerProps} props DialogInnerProps
 */
export const DialogInner: FunctionComponent<DialogInnerProps> = (props) => {
  const { children, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={classnames(
        "dialog--inner",
        classes["dialog--inner"],
        divProps.className
      )}
    >
      {children}
    </div>
  );
};
