import { PickerResponse } from 'filestack-js/build/main/lib/picker';
import React, { useContext, ReactElement } from 'react';

import { StructuredFileMetadata } from '@api/uploaders';
import { ActionTypes, AssetDetailsDispatch } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { filteredFiles } from '@components/common/filestack_uploader/helpers';
import { UploadArea } from '@components/common/filestack_uploader/UploadArea';
import { UploadAreaTypes } from '@components/common/filestack_uploader/UploadAreaEnums';
import AttachmentUppy from '@components/common/uppy/attachment_uppy';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import { structureFilestackFiles } from '@helpers/uploaders';
import { tempAttachmentTemplate } from '../helpers';

interface UploaderScopedContext {
  state: {
    initialData: {
      assetKey: string;
    };
  };
  dispatch: AssetDetailsDispatch;
}

const AttachmentUploader = (): ReactElement => {
  const { state, dispatch }: UploaderScopedContext = useContext(assetModalContext);

  const onUpload = (files: StructuredFileMetadata[]): void => {
    const attachments = files.map((file) => tempAttachmentTemplate(file));

    dispatch({
      type: ActionTypes.UploadNewAttachment,
      payload: {
        attachments
      }
    });
  };

  const onUploadDone = (files: PickerResponse): void => {
    const filtered = filteredFiles(files.filesUploaded);
    const structured = structureFilestackFiles(filtered);
    const attachments = structured.map((file) => tempAttachmentTemplate(file));

    Insight.createEvent('added_attachments', state.initialData.assetKey, 'asset');

    dispatch({
      type: ActionTypes.UploadNewAttachment,
      payload: {
        attachments
      }
    });
  };

  if (!BFG.hasFeature('uppy_uploader_features')) {
    return (
      <UploadArea
        id={state.initialData.assetKey}
        onUploadDone={onUploadDone}
        uploadType={UploadAreaTypes.Attachment}
      />
    );
  }

  if (BFG.hasFeature('uppy_uploader_features')) {
    return (
      <AttachmentUppy
        assetKey={state.initialData.assetKey}
        onUpload={onUpload}
      />
    );
  }

  return null;
};

export default AttachmentUploader;
