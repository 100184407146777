export default class Asset {
  constructor(file) {
    this.file               = file
    this.filename           = file.filename
    this.filetype           = file.filetype
    this.width              = file.width
    this.height             = file.height
    this.size               = file.size
    this.url                = file.url
    this.friendlyDimensions = this.friendlyDimensions.bind(this)
    this.portrait           = this.portrait.bind(this)
    this.landscape          = this.landscape.bind(this)
    this.aspectRatio        = this.aspectRatio.bind(this)
    this.scaleWidthTo       = this.scaleWidthTo.bind(this)
    this.scaleHeightTo      = this.scaleHeightTo.bind(this)
    this.minimumWidth       = this.minimumWidth.bind(this)
    this.minimumHeight      = this.minimumHeight.bind(this)
  }

  static friendlyDimensions(width, height) {
    return `${width} × ${height}`
  }

  static aspectRatio(width, height) {
    return (width / height) || 1
  }

  friendlyDimensions() {
    return Asset.friendlyDimensions(this.width, this.height)
  }

  aspectRatio() {
    return Asset.aspectRatio(this.width, this.height)
  }

  portrait() {
    return this.height > this.width
  }

  landscape() {
    return this.width > this.height
  }

  scaleWidthTo(height) {
    return Math.round(height * this.aspectRatio())
  }

  scaleHeightTo(width) {
    return Math.round(width / this.aspectRatio())
  }

  minimumHeight(height=1) {
    if (this.portrait()) {
      if (height * this.aspectRatio() < 1) {
        return this.minimumHeight(height+=1)
      } else {
        return height
      }
    } else {
      if (this.aspectRatio() / height < 1) {
        return this.minimumHeight(height+=1)
      } else {
        return height
      }
    }
  }

  minimumWidth(width=1) {
    if (this.portrait()) {
      if (width / this.aspectRatio() < 1) {
        return this.minimumWidth(width+=1)
      } else {
        return width
      }
    } else {
      if (width / this.aspectRatio() < 1) {
        return this.minimumWidth(width+=1)
      } else {
        return width
      }
    }
  }
}
