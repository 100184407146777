import React from 'react';
import PropTypes from 'prop-types';

import LabelContent from './label_content';

const LabelTree = (props) => {
  const {
    activeLabelKey,
    expandedLabels,
    labelsTree,
    onLabelSelect,
    toggleExpand
  } = props;
  const renderedChildren = labelsTree.children.map((label, index) => <LabelTree key={`label-tree-${index}`} {...props} labelsTree={label} />);
  // Labels are converted from a flat list of labels, to a nested structure.  The root node is empty, but has 0 or more children.  So it is expected
  // to have an id of undefined here.
  if (labelsTree.id === undefined) {
    return <ul className="labels-container">{ renderedChildren }</ul>;
  }
  const labelId = labelsTree.id;
  const activeClass = activeLabelKey === labelId ? 'active' : '';
  const expandedClass = expandedLabels[labelId] ? 'expanded' : '';
  const childCount = labelsTree.children.length;
  const { name, path } = labelsTree.attributes;
  const indentCounter = path.length - 1;
  return (
    <li key={labelId} className={`label-item ${activeClass} ${expandedClass}`}>
      <LabelContent
        key={labelId}
        onLabelSelect={onLabelSelect}
        toggleExpand={toggleExpand}
        hasChildren={childCount > 0}
        labelId={labelId}
        indentationLevel={indentCounter}
        name={name}
      />
      {!!expandedLabels[labelId] && (
        <ul className="labels-container">{renderedChildren}</ul>
      )}
    </li>
  );
};

LabelTree.propTypes = {
  activeLabelKey: PropTypes.string,
  expandedLabels: PropTypes.shape({}).isRequired,
  labelsTree: PropTypes.shape({}).isRequired,
  onLabelSelect: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func.isRequired,
};

LabelTree.defaultProps = {
  activeLabelKey: null,
};

export default LabelTree;
