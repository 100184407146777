/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import { BFLoader } from '@components/common/loader/main';
import MentionableInput from '@components/common/mentions/MentionableInput';
import { getMomentLocale } from '@helpers/locale';

import Annotation from './annotation';
import NewAnnotation from './newAnnotation';
import './styles/annotations.scss';
import Tooltip from './tooltip';


const Annotator = ({
  annotations,
  assetKey,
  mentionableUsers,
  onNewAnnotation,
  setMentionableUsers,
  setTempAnnotation,
  tempAnnotation,
  url,
  versionsActionLoading,
}) => {
  const [hasMouseTrail, setHasMouseTrail] = useState(false);
  const [mouseTrailActive, setMouseTrailActive] = useState(false);
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const [scrollbarVisible, setScrollbarVisible] = useState(false);
  const [containerScrollHeight, setContainerScrollHeight] = useState({ scrollHeight: 0, containerheight: 0 });
  const sectionContainer = useRef(null);
  const imageContainer = useRef(null);
  const mouseTrail = useRef(null);

  const setContainerDimensions = () => {
    if (sectionContainer) {
      setContainerScrollHeight({
        scrollHeight: sectionContainer.current.scrollHeight,
        containerHeight: sectionContainer.current.clientHeight,
      });
    }
  }

  // Capture container dimensions when the Workflow tab is clicked upon
  // Need the setTimeout in order to set the dimensions before the render
  const tabClickFire = () => {
    setTimeout(() => {
      setContainerDimensions();
    }, 1)
  }

  useEffect(() => {
    window.addEventListener('resize', setContainerDimensions);
    window.addEventListener(`workflowAssetTabClick${assetKey}`, tabClickFire);
    return () => {
      window.removeEventListener('resize', setContainerDimensions);
      window.removeEventListener(`workflowAssetTabClick${assetKey}`, tabClickFire);
    }
  }, []);

  useEffect(() => {
    setScrollbarVisible(!!(containerScrollHeight.scrollHeight > containerScrollHeight.containerHeight));
  }, [containerScrollHeight, scrollbarVisible])

  useEffect(() => {
    if (hasMouseTrail && !tempAnnotation) {
      setMouseTrailActive(true);
    } else {
      setMouseTrailActive(false);
    }
  }, [annotations, hasMouseTrail, tempAnnotation]);

  // activate/deactivate mouse trail
  useEffect(() => {
    if (!imageContainer) { return undefined; }
    const imageEl = imageContainer.current;
    const onMouseLeave = () => setHasMouseTrail(false);
    imageEl.addEventListener('mouseleave', onMouseLeave);

    const onMouseMove = () => {
      if (mouseTrailActive) { return; }
      setHasMouseTrail(true);
    };
    imageEl.addEventListener('mouseenter', onMouseMove);

    return () => {
      imageEl.removeEventListener('mousemove', onMouseMove);
      imageEl.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [imageContainer, mouseTrailActive]);

  // position mouse trail
  useEffect(() => {
    if (!sectionContainer || !mouseTrailActive) { return undefined; }
    const containerEl = sectionContainer.current;
    const imageEl = imageContainer.current;
    const mouseTrailEl = mouseTrail.current;

    const onMove = (e) => {
      if (e.target.classList.contains('annotator-image')) {
        const { offsetX, offsetY } = e;
        let x = offsetX + 20;
        let y = offsetY + 20;

        // spaces (gap) between image and container
        const leftGap = (containerEl.offsetWidth - imageEl.offsetWidth) / 2;
        const topGap = (containerEl.offsetHeight - imageEl.offsetHeight) / 2;

        const maxX = containerEl.offsetWidth - leftGap - mouseTrailEl.offsetWidth;
        if (x > maxX) { x = maxX; }

        const maxY = containerEl.offsetHeight - topGap - mouseTrailEl.offsetHeight;
        if (y > maxY) { y = maxY; }

        window.requestAnimationFrame(() => {
          mouseTrailEl.style.top = `${y}px`;
          mouseTrailEl.style.left = `${x}px`;
        });
      }
    };

    containerEl.addEventListener('mousemove', onMove);

    return () => {
      containerEl.removeEventListener('mousemove', onMove);
    };
  }, [sectionContainer, mouseTrailActive, mouseTrail]);

  const handleClick = (event) => {
    const { width, height } = event.target.getBoundingClientRect();
    const { offsetX: x, offsetY: y } = event.nativeEvent;

    const xPercent = x / width;
    const yPercent = y / height;

    setTempAnnotation({
      x: xPercent.toString(),
      y: yPercent.toString(),
      number: annotations.length + 1,
    });

    return undefined;
  };

  const onNewAnnotationSubmit = (text, mentions) => {
    onNewAnnotation({
      ...tempAnnotation,
      text,
      mentions
    })
      .then(() => {
        setTempAnnotation(null);
      });
  };

  const annotationEls = annotations.map((annotation, i) => (
    <Annotation
      key={annotation.id}
      number={i + 1}
      onClick={() => { setTempAnnotation({ ...annotation, number: i + 1 }); }}
      x={annotation.x}
      y={annotation.y}
    />
  ));

  const ExistingAnnotationWrapper = () => {
    const { firstName, lastName, createdAt, updatedAt, comment } = tempAnnotation;
    const timestamp = moment(updatedAt || createdAt).locale(getMomentLocale()).format('LL');
    return (
      <div className="event__header">
        <div className="name-container">
          <p className="name">{firstName} {lastName}</p>
          <p className="event-timestamp">{timestamp}</p>
        </div>
        <MentionableInput
          content={comment}
          htmlAttributes={{
            disabled: true
          }}
        />
      </div>
    );
  };

  return (
    <section
      ref={sectionContainer}
      className={`annotations bf-scroll-element ${scrollbarVisible === true ? 'top-align' : ''}`}
    >
      {versionsActionLoading ? (
        <div className="loader-container">
          <BFLoader />
        </div>
      ) : (
        <div
          className="annotations-background"
          onClick={handleClick}
          role="button"
          tabIndex="0"
        >
          <img
            ref={imageContainer}
            alt="attachment version"
            className="annotator-image"
            data-private
            onError={() => setImageIsLoading(() => (false))}
            onLoad={() => setImageIsLoading(() => (false))}
            src={url}
          />
          {imageIsLoading && <BFLoader />}
          {annotationEls}
          {tempAnnotation !== null && sectionContainer.current !== null && (
            <>
              <Annotation {...tempAnnotation} />
              <Tooltip
                containerHeight={sectionContainer.current.clientHeight}
                containerWidth={sectionContainer.current.clientWidth}
                onClose={() => setTempAnnotation(null)}
                setTempAnnotation={setTempAnnotation}
                x={tempAnnotation.x}
                y={tempAnnotation.y}
              >
                {tempAnnotation.comment
                  ? (
                    <ExistingAnnotationWrapper />
                  ) : (
                    <NewAnnotation
                      mentionableUsers={mentionableUsers}
                      number={tempAnnotation.number}
                      onSubmit={onNewAnnotationSubmit}
                      setMentionableUsers={setMentionableUsers}
                    />
                  )
                }
              </Tooltip>
            </>
          )}
          {mouseTrailActive && (
            <div
              ref={mouseTrail}
              className="mouse-trail"
            >
              <p><Trans>Click to leave an annotation</Trans></p>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

Annotator.propTypes = {
  annotations: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
    id: PropTypes.string,
  })),
  assetKey: PropTypes.string,
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  onNewAnnotation: PropTypes.func,
  setMentionableUsers: PropTypes.func.isRequired,
  setTempAnnotation: PropTypes.func.isRequired,
  tempAnnotation: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
    comment: PropTypes.string,
    number: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  url: PropTypes.string.isRequired,
  versionsActionLoading: PropTypes.bool.isRequired,
};

Annotator.defaultProps = {
  annotations: [],
  mentionableUsers: null,
  onNewAnnotation: undefined,
  tempAnnotation: null,
};

export default Annotator;
