import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { StandardTooltipProps } from '@components/library/tooltip';
import { Colors, Widths } from '@components/library/utils';
import { BaseTooltip } from '../base';

import classes from './styles/standard-tooltip.module.scss';

export const StandardTooltip: FunctionComponent<StandardTooltipProps> = (props) => {
  const { className, color = Colors.Primary, width = Widths.Medium, wrapperProps, ...otherProps } = props;

  return (
    <BaseTooltip
      {...otherProps}
      className={classnames(
        classes.tooltip,
        classes[color],
        classes[width],
        className
      )}
      variantName="standard"
      wrapperProps={{
        ...wrapperProps,
        className: classnames(
          classes.wrapper,
          wrapperProps && wrapperProps.className
        )
      }}
    />
  );
};
