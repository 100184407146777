import fetchJSON from '@api/api_helper';
import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';

export const getBrandfolderPermissionLevel = ({
  brandfolderKey,
  params = {},
  updateFetchControllers = undefined
}): Promise<ApiDatumResponse<never, 'permission_level'>> => (
  fetchJSON(
    `/api/v4/brandfolders/${brandfolderKey}/permission_level`,
    { params },
    updateFetchControllers
  )
);