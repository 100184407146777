import { removeFileExtension } from '@brandfolder/utilities';
import { t, Trans, Plural } from '@lingui/macro';
import { PickerResponse } from 'filestack-js/build/main/lib/picker';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState, FunctionComponent, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import { object, SchemaOf, string } from 'yup';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import { StructuredFileMetadata } from '@api/uploaders';
import { AssetsResponse } from '@api/v4/assets/assetTypes';
import { FilestackCredentialsPostOptions } from '@api/v4/private/resources/fs_creds';
import { FlattenedCustomFieldKeyValuesMap } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { filteredFiles } from '@components/common/filestack_uploader/helpers';
import { UploadArea } from '@components/common/filestack_uploader/UploadArea';
import GuestUploadUppy from '@components/common/uppy/guest_upload_uppy';
import { PrimaryButton, TextButton } from '@components/library/button';
import { StandardTextField } from '@components/library/text-field';

import { sendAction, TrackedAction } from '@helpers/datadog-rum';
import { removeUnderscores } from '@helpers/humanize';

import { structureFilestackFiles } from '@helpers/uploaders';

import { GuestUploadFileList } from './GuestUploadFileList';

export interface GuestUploadFormProps {
  brandfolderKey: string;
  brandfolderName: string | null;
  setResetForm: SetStateDispatch<boolean>;
  uploadLinkKey: string;
  allRequiredCustomFields?: boolean;
  setFinalCustomFields?: SetStateDispatch<FlattenedCustomFieldKeyValuesMap>;
  setNewAssetKeys?: SetStateDispatch<string[]>;
  setSubmissionAttempted?: SetStateDispatch<boolean>;
}

interface GuestUploadFormValues {
  email: string;
  description: string;
}

enum Fields {
  Email = 'email',
  Description = 'description'
}

export const errorMessages = (): { emailRequired: string; descriptionTooLong: string } => ({
  emailRequired: t`An email is required)`,
  descriptionTooLong: t`Message is too long (maximum is 500 characters)`
});

const validationSchema = (): SchemaOf<GuestUploadFormValues> => object().shape({
  email: string().email('Invalid email')
    .required(errorMessages().emailRequired),
  description: string()
    .max(500, errorMessages().descriptionTooLong)
});

export const GuestUploadForm: FunctionComponent<GuestUploadFormProps> = ({
  allRequiredCustomFields,
  brandfolderKey,
  brandfolderName,
  setFinalCustomFields,
  setNewAssetKeys,
  setResetForm,
  setSubmissionAttempted,
  uploadLinkKey
}) => {
  const [allFiles, setAllFiles] = useState<StructuredFileMetadata[]>([]);
  const [assetAttributes, setAssetAttributes] = useState(null);
  const [email, setEmail] = useState('');
  const [files, setFiles] = useState<StructuredFileMetadata[]>([]);
  const [description, setDescription] = useState('');
  const [showFileUploader, setShowFileUploader] = useState(false);

  const trackAbandonment = (): void => {
    if (files.length === 0) {
      sendAction(TrackedAction.GuestUploadAbandonment);
    }
  };

  const handleAbandonment = (touched): void => {
    if (touched.email || touched.description) {
      window.onbeforeunload = (): string => '';
    }
  };

  useEffect(() => {
    window.addEventListener('onbeforeunload', handleAbandonment);
    window.addEventListener('beforeunload', trackAbandonment);

    return (): void => {
      window.removeEventListener('onbeforeunload', handleAbandonment);
      window.removeEventListener('beforeunload', trackAbandonment);
    };
  }, []);

  const uploadToBrandfolder = (): void => {
    const fileAttributes = files.map((file) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { external_id, filename, folder_path, mimetype, size, url } = file;
      return {
        name: removeUnderscores(removeFileExtension(filename)),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        data: { guest_upload_data: { email, description } },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        attachments: [{ external_id, filename, folder_path, mime_type: mimetype, size, source: 'guestupload', url }]
      };
    });
    setAssetAttributes(fileAttributes);
  };

  // NOTE: this response is limited and does not use the standard V4 asset serializer due to security concerns
  const guestUploadPostParams: UseFetchOptions = ({
    body: {
      data: {
        attributes: assetAttributes,
      },
    },
    fetchOnMount: false,
    method: 'POST',
    url: `/api/v4/upload_links/${uploadLinkKey}/assets`
  });

  const guestUploadPost = useFetch<AssetsResponse>(guestUploadPostParams);

  useEffect(() => {
    if (assetAttributes) {
      guestUploadPost.fetch();
    }
  }, [assetAttributes]);

  useEffect(() => {
    if (files.length > 0) {
      uploadToBrandfolder();
    }
  }, [files]);

  useEffect(() => {
    if (guestUploadPost.response) {
      setNewAssetKeys(guestUploadPost.response.data.map((asset) => asset.id));
    }
  }, [guestUploadPost.response]);

  useEffect(() => {
    if (allRequiredCustomFields && email) {
      setShowFileUploader(true);
    } else {
      setShowFileUploader(false);
    }
  }, [allRequiredCustomFields, email]);

  const handleFormValidation = (values: GuestUploadFormValues): void => {
    setEmail(values.email);
    setDescription(values.description);
  };

  const addFiles = (uploadedFiles: StructuredFileMetadata[]): void => {
    setFiles(uploadedFiles);
    setAllFiles((prevState) => ([...prevState, ...uploadedFiles]));
  };

  const onUploadDone = (uploadedFiles: PickerResponse): void => {
    const filtered = filteredFiles(uploadedFiles.filesUploaded);
    const structured = structureFilestackFiles(filtered);
    setFiles(structured);
    setAllFiles((prevState) => ([...prevState, ...structured]));
  };

  const resetFiles = (): void => {
    setAllFiles([]);
    setEmail('');
    setFiles([]);
    setDescription('');
    setFinalCustomFields(null);
    setNewAssetKeys(null);
    setResetForm(true);
    setSubmissionAttempted(false);
    setShowFileUploader(false);
  };

  const guestCredentialOptions : FilestackCredentialsPostOptions = {
    expiry_seconds: 30 * 60, // 30 minutes, shorten for guest upload, see MAXIMUM_GUEST_UPLOAD_STORE_EXPIRY_SECONDS
    operations: ['pick', 'store'] // limit permissions, do not allow read for security concerns
  };

  const renderUploadArea = (): ReactNode => {
    const gig = 1073741824;
    if (BFG.hasFeature('uppy_uploader_features')) {
      return (
        <GuestUploadUppy
          appendFiles={addFiles}
          uploadLinkKey={uploadLinkKey}
        />
      );
    }
    return (
      <div className="filestack-container">
        <UploadArea
          credentialsOptions={guestCredentialOptions}
          id={uploadLinkKey}
          onUploadDone={onUploadDone}
          pickerOptions={{
            maxFiles: 1000,
            maxSize: (10 * gig),
            disableThumbnails: true,
          }}
          resourceKey={brandfolderKey}
          resourceType="brandfolder"
          uploadLinkKey={uploadLinkKey}
        />
      </div>
    );
  };

  const allFilesLength = allFiles.length;

  return (
    <div className="guest-upload__form">
      {!showFileUploader ? (
        <Formik
          initialValues={{ email: '' || email, description: '' || description }}
          onSubmit={handleFormValidation}
          validateOnBlur={false}
          validationSchema={validationSchema()}
        >
          {(formikBag): JSX.Element => {
            const { errors, touched } = formikBag;

            return (
              <Form>
                {handleAbandonment(touched)}
                <Field name={Fields.Email}>
                  {({ field }): JSX.Element => (
                    <StandardTextField
                      {...field}
                      className="guest-upload__form--email"
                      error={errors.email}
                      id="guest-upload-email"
                      label={t`Enter your email address`}
                      name={Fields.Email}
                      placeholder=""
                      required
                    />
                  )}
                </Field>
                <Field name={Fields.Description}>
                  {({ field }): JSX.Element => (
                    <StandardTextField
                      {...field}
                      className="guest-upload__form--message"
                      error={errors.description}
                      id="guest-upload-description"
                      label={t`Message (optional)`}
                      multiLine
                      placeholder=""
                    />
                  )}
                </Field>
                <div className="guest-upload__form--button">
                  <PrimaryButton
                    onClick={(): void => setSubmissionAttempted(true)}
                    size="medium"
                    type="submit"
                  >
                    <Trans>Select files for upload</Trans>
                  </PrimaryButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className="guest-upload__uploader-section">
          {files.length > 0 && (
            <GuestUploadFileList
              allFiles={allFiles}
              brandfolderKey={brandfolderKey}
              uploadLinkKey={uploadLinkKey}
            />
          )}
          <CSSTransition
            classNames="fade-in"
            in={showFileUploader}
            timeout={300}
            unmountOnExit
          >
            <div className="guest-upload__file-uploader">
              {renderUploadArea()}
            </div>
          </CSSTransition>
          {allFilesLength > 0 && (
            <div className="guest-upload__success-message">
              <Plural
                one={
                  <Trans>
                    You have successfully uploaded {allFilesLength} asset into {brandfolderName}.
                    You can upload more above, or&nbsp;
                    <TextButton onClick={(): void => resetFiles()}>start over</TextButton>.
                  </Trans>
                }
                other={
                  <Trans>
                    You have successfully uploaded {allFilesLength} assets into {brandfolderName}.
                    You can upload more above, or&nbsp;
                    <TextButton onClick={(): void => resetFiles()}>start over</TextButton>.
                  </Trans>
                }
                value={allFilesLength}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
