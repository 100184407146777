/* eslint-disable @typescript-eslint/naming-convention */
import fetchJSON from '@api/api_helper';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';

interface UpdateBrandguideProps {
  initialSlug: string;
  cardImage?: string;
  isPublic?: boolean;
  name?: string;
  slug?: string;
}

interface Brandguide {
  card_image: string;
  name: string;
  public: boolean;
  slug: string;
}

type BrandguideResponseObject = ApiResponseObject<Brandguide, 'brandguide'>;

// v3 used for PUT because v3 handles triggering BrandguideSlugUpdateJob and v4 does not
const url = (brandguideSlug: string): string => `/api/v3/brandguides/${brandguideSlug}`;

export const updateBrandguide = ({
  cardImage,
  initialSlug,
  isPublic,
  name,
  slug,
}: UpdateBrandguideProps): Promise<BrandguideResponseObject> => (
  fetchJSON(url(initialSlug), {
    method: 'PUT',
    body: {
      brandguides: {
        ...name && { name },
        ...(isPublic !== undefined && { public: isPublic }),
        ...slug && { slug },
        ...(cardImage || cardImage === '') && { options_card_image: { url: cardImage } },
      }
    }
  })
    .then((response) => response)
    .catch(async (response) => { throw (await response.json()); })
);
