import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface ShowMetadataProps {
  defaultEnabled: boolean | null;
}

const ShowMetadata: FunctionComponent<ShowMetadataProps> = ({ defaultEnabled }) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled === null ? true : defaultEnabled}
      description={t`Only applies to assets in Collections and Share Links`}
      errorTitle={t`Error updating metadata viewing permissions. Please contact support.`}
      id="show-metadata"
      successTitle={t`Metadata viewing permissions updated!`}
      title={t`Allow guest and public users to see metadata`}
      updateResourceKey="showMetadata"
    />
  </I18nProviderWrapper>
);

export default ShowMetadata;
