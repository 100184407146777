import React from 'react';
import PropTypes from 'prop-types';

import './styles/tags.scss';

class Controls extends React.Component {
  renderTags() {
    const tags = this.props.tags || Array(3).fill('');
    return tags.map((tag, i) => (
      <li key={this.props.tags ? tag : i}>
        <span className="icon bff-tag" />
        {tag}
      </li>
    ));
  }

  render() {
    const disabled = !this.props.tags;

    return (
      <div className="workbench-tags">
        <ul className={disabled ? "disabled" : ''}>
          {this.renderTags()}
        </ul>
        {this.props.renderResetButton()}
      </div>
    );
  }
}

Controls.propTypes = {
  asset: PropTypes.shape({}),
  renderResetButton: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.string)
};

Controls.defaultProps = {
  asset: null,
  renderResetButton: () => {},
  tags: null
};

export default Controls;
