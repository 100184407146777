import { t, Trans } from '@lingui/macro';
import $ from "jquery";
import React from "react";

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { ListDropdown } from '@components/library/dropdown';

import Checkbox from "../common/checkbox/main";
import Tooltip from "../common/tooltip/main";

import "./styles/settings_form.scss";

export default class PrintuiSettingsForm extends React.Component {
    state = {
        client: this.props.printui.client,
        auth: this.props.printui.auth,
        section_id: this.props.printui.section_id,
        approvals: this.props.printui.approvals,
        locale: this.grabSetting("locale", "en_US"),
        allowUpload: this.grabSetting("allowUpload", true),
        showUploadPane: this.grabSetting("showUploadPane", false),
        allowNewText: this.grabSetting("allowNewText", true),
        allowFontSizing: this.grabSetting("allowFontSizing", false),
        showTextPalette: this.grabSetting("showTextPalette", true),
        enableImagePaletteOptions: this.grabSetting(
            "enableImagePaletteOptions",
            true
        ),
        useBrandfolderEditor: this.grabSetting("useBrandfolderEditor", false),
        useFormEditor: this.grabSetting("useHTMLEditor", true),
        useCanvasEditor: this.grabSetting("useCanvasEditor", false),
        useSimpleCanvasEditor: this.grabSetting("useSimpleCanvasEditor", false),
        tags: this.grabSetting("tags", undefined),
        approveAllJobs: this.grabSetting("approveAllJobs", "impossibleDefault"),
        downloadPreset: this.grabSetting("downloadPreset", "High Quality Print"),
        useStylesForTables: this.grabSetting("useStylesForTables", false)
    };

    get baseSelection() {
      const { attachment } = this.props;
      const baseSelection = [
        {
          field: "useBrandfolderEditor",
          label: t`Use Editor`,
          type: "checkbox"
        },
        {
          field: "useFormEditor",
          label: t`Use Form Editor`,
          type: "checkbox"
        },
        {
          field: "useSimpleCanvasEditor",
          label: t`Use Simple Canvas Editor`,
          type: "checkbox"
        },
        {
          field: "useCanvasEditor",
          label: t`Use Full Canvas Editor`,
          type: "checkbox"
        }
      ];
      if (attachment && !this.state.useBrandfolderEditor) {
        baseSelection.push({
          field: "tags",
          label: t`Accessible Tags`,
          type: "text"
        });
      }
      if (!attachment) {
        baseSelection.push({
          field: "approvals",
          label: t`Require Admin Approval`,
          type: "checkbox"
        });
      }
      return baseSelection;
    }

    get fields() {
        const { printui, attachment } = this.props;
        const presetOptions = printui.data.printuiSettings.presets;
        let settingsArray = [];
        if(this.state.useBrandfolderEditor) {
            settingsArray = this.baseSelection;
            settingsArray.push(
                {
                    field: "downloadPreset",
                    label: t`Download Preset`,
                    type: "dropdown",
                    options: presetOptions,
                    default: presetOptions[0]
                }
            )
        } else if (this.state.useCanvasEditor) {
            settingsArray = this.baseSelection;
            settingsArray.push(
                {
                    field: "allowUpload",
                    label: t`Allow Image Replacement`,
                    type: "checkbox"
                },
                {
                    field: "downloadPreset",
                    label: t`Download Preset`,
                    type: "dropdown",
                    options: presetOptions,
                    default: presetOptions[0]
                },
                {
                    field: "allowNewText",
                    label: t`Allow New Text`,
                    type: "checkbox"
                },
                {
                    field: "showTextPalette",
                    label: t`Allow Text Styling Alerations`,
                    type: "checkbox"
                },
                {
                    field: "enableImagePaletteOptions",
                    label: t`Allow Image Alterations`,
                    type: "checkbox"
                }
              )
              if(attachment) {
                settingsArray.push(
                  {
                    field: "useStylesForTables",
                    label: t`Table Formatting`,
                    type: "checkbox"
                  }
                )
              }
              settingsArray.push(
                { field: "locale", label: t`Location`, type: "text" }
              )
        } else if (this.state.useSimpleCanvasEditor) {
            settingsArray = this.baseSelection;
            settingsArray.push(
                {
                    field: "downloadPreset",
                    label: t`Download Preset`,
                    type: "dropdown",
                    options: presetOptions,
                    default: presetOptions[0]
                },
                { field: "locale", label: t`Location`, type: "text" }
            );
        } else if (this.state.useFormEditor) {
            settingsArray = this.baseSelection;
            settingsArray.push(
                {
                    field: "allowUpload",
                    label: t`Allow Image Replacement`,
                    type: "checkbox"
                },
                {
                    field: "downloadPreset",
                    label: t`Download Preset`,
                    type: "dropdown",
                    options: presetOptions,
                    default: presetOptions[0]
                },
                { field: "locale", label: t`Location`, type: "text" }
            );
        }
        if (attachment && printui.approvals) {
            settingsArray.push({
                field: "approveAllJobs",
                label: t`Auto Approve Downloads`,
                type: "checkbox"
            });
        }
        if (this.state.allowUpload && !this.state.useBrandfolderEditor) {
            let indexOfAllowUpload = settingsArray.findIndex(
                (i) => i.field == "allowUpload"
            );
            let indexOfShowUploadPane = indexOfAllowUpload + 1;
            settingsArray.splice(indexOfShowUploadPane, 0, {
                field: "showUploadPane",
                label: t`Allow Image Upload`,
                type: "checkbox"
            });
        }
        if (this.state.useBrandfolderEditor && attachment) {
          settingsArray.push(
            {
              field: "allowFontSizing",
              label: t`Allow Font Sizing`,
              type: "checkbox"
            }
          );
        }
        return settingsArray;
    }

    grabSetting(field, defaultSetting) {
        const { printui, attachment } = this.props;
        let printuiSettings = printui.data.printuiSettings;
        if (
            attachment &&
            attachment.data.printuiSettings &&
            attachment.data.printuiSettings[field] != null
        ) {
            return attachment.data.printuiSettings[field];
        } else if (printuiSettings && printuiSettings[field] != null) {
            return printuiSettings[field];
        } else {
            return defaultSetting;
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        $(e.target).closest('form').attr("data-locked", "false");
        this.updatePrintuiSettings();
        if (this.props.attachment) {
            this.handlePrintuiApproval();
        }
    };

    formData() {
      let data = {
        printuiSettings: {
          locale: this.state.locale,
          allowUpload: this.state.allowUpload,
          allowNewText: this.state.allowNewText,
          allowFontSizing: this.state.allowFontSizing,
          showUploadPane: this.state.showUploadPane,
          showTextPalette: this.state.showTextPalette,
          enableImagePaletteOptions: this.state.enableImagePaletteOptions,
          useBrandfolderEditor: this.state.useBrandfolderEditor,
          useHTMLEditor: this.state.useFormEditor,
          useCanvasEditor: this.state.useCanvasEditor,
          useSimpleCanvasEditor: this.state.useSimpleCanvasEditor,
          tags: this.state.tags,
          approveAllJobs: this.state.approveAllJobs,
          downloadPreset: this.state.downloadPreset,
          useStylesForTables: this.state.useStylesForTables,
        }
      }
      if (!this.props.attachment) {
        let account_info = {  client: this.state.client,
                              auth: this.state.auth,
                              section_id: this.state.section_id,
                              approvals: this.state.approvals}
        data = Object.assign(data, account_info )
      }
      return data
    }

    updatePrintuiSettings() {
        const { attachment, printui } = this.props;
        let data, url;
        if (attachment) {
            data = { data: this.formData() };
            url = `/api/v3/attachments/${attachment.attachment_key}`;
        } else {
            data = { printui: { data: this.formData() } };
            url = `/api/v3/printui/printuis/${printui.key}`;
        }
        $.ajax({
            type: "PUT",
            url: url,
            contentType: "application/json",
            data: JSON.stringify(data),
            beforeSend: function(xhr) {
                xhr.setRequestHeader("Authorization", "Bearer " + BF_Token);
            },
            success: (response) => {
                $(".close").click();
            },
            error: (response) => {
                Notify.create({
                    title: t`Unable to update settings`,
                    body: t`Sorry about that, try again soon!`,
                    type: "error"
                });
            }
        });
    }

    handlePrintuiApproval() {
        if (
            this.state.approveAllJobs != "impossibleDefault" &&
              this.state.approveAllJobs != this.grabSetting("approveAllJobs", "impossibleDefault")
        ) {
            if (this.state.approveAllJobs) {
                this.createWhitelistedPrintuiApproval();
            } else {
                this.destroyWhitelistedPrintuiApproval();
            }
        }
    }

    createWhitelistedPrintuiApproval() {
        const { attachment } = this.props;
        let date = new Date().toLocaleDateString("en-US");
        let data = {
          printui_approval: {
            job_id: "*",
            approved: true,
            notes: t`Automatically approved by ${BFG.currentUser.email} on ${date}`
          }
        };
        $.ajax({
            type: "POST",
            url: `/api/v3/printui/attachments/${attachment.id}/printui_approvals`,
            contentType: "application/json",
            data: JSON.stringify(data),
            beforeSend: function(xhr) {
                xhr.setRequestHeader("Authorization", "Bearer " + BF_Token);
            },
            success: (response) => {
                Notify.create({
                    title: t`Approval settings updated`,
                    body:
                        t`All derived templates will not require manual approval`,
                    type: "success"
                });
            },
            error: (response) => {
                Notify.create({
                    title: t`Unable to update approval settings`,
                    body: t`Sorry about that, try again soon!`,
                    type: "error"
                });
            }
        });
    }

    destroyWhitelistedPrintuiApproval() {
        const { attachment } = this.props;
        $.ajax({
            type: "DELETE",
            url: `/api/v3/printui/printui_approvals/${
                attachment.id
            }?printui_approval[job_id]=*`,
            contentType: "application/json",
            beforeSend: function(xhr) {
                xhr.setRequestHeader("Authorization", "Bearer " + BF_Token);
            },
            success: (response) => {
                Notify.create({
                    title: t`Approval settings updated`,
                    body:
                        t`All derived templates will not require manual approval`,
                    type: "success"
                });
            },
            error: (response) => {
                Notify.create({
                    title: t`Unable to remove approval settings`,
                    body: t`Sorry about that, try again soon!`,
                    type: "error"
                });
            }
        });
    }

    updateValue(e, key, type) {
        if(key[0] === "useBrandfolderEditor") {
          this.useBrandfolderEditorDefaults();
        } else if (key[0] === "useCanvasEditor") {
            this.useCanvasEditorDefaults();
        } else if (key[0] === "useSimpleCanvasEditor") {
            this.useSimpleCanvasEditorDefaults();
        } else if (key[0] === "useFormEditor") {
            this.useFormEditorDefaults();
        } else if (key[0] === "Download Preset") {
            this.setState({ downloadPreset: type });
        } else if (type === "checkbox") {
            this.setState({ [key]: !this.state[key] });
        } else if (type === "number") {
            this.setState({ [key]: parseInt(e.target.value) });
        } else {
            this.setState({ [key]: e.target.value });
        }
    }

    useBrandfolderEditorDefaults() {
      this.setState({
        useBrandfolderEditor: true,
        useSimpleCanvasEditor: false,
        useCanvasEditor: false,
        useFormEditor: false,
        allowUpload: true,
        showUploadPane: true,
        allowNewText: false,
        allowFontSizing: false,
        showTextPalette: false,
        enableImagePaletteOptions: false
      });
    }

    useSimpleCanvasEditorDefaults() {
      this.setState({
        useSimpleCanvasEditor: true,
        useBrandfolderEditor: false,
        useCanvasEditor: false,
        useFormEditor: false,
        allowUpload: false,
        showUploadPane: false,
        allowNewText: false,
        showTextPalette: false,
        enableImagePaletteOptions: false
      });
    }

    useCanvasEditorDefaults() {
      this.setState({
        useCanvasEditor: true,
        useBrandfolderEditor: false,
        useFormEditor: false,
        useSimpleCanvasEditor: false,
        allowUpload: true,
        showUploadPane: false,
        allowNewText: true,
        showTextPalette: true,
        enableImagePaletteOptions: true
      });
    }

    useFormEditorDefaults() {
      this.setState({
        useFormEditor: true,
        useBrandfolderEditor: false,
        useSimpleCanvasEditor: false,
        useCanvasEditor: false,
        allowUpload: true,
        showUploadPane: false,
        allowNewText: false,
        showTextPalette: false,
        enableImagePaletteOptions: false
      });
    }

    tooltipCopy(label) {
        if (label === "Location") {
            return t`Locations determine language and can be:  en_US, he_IL, nl_NL, cs_CZ, es_ES, es_MX, tr_TR, de_DE, ja_JP, sk_SK, hu_HU, pt_BR, fr_FR, ro_RO, no_NO`;
        } else if (label === "Accessible Tags") {
            return t`Assets with associated, specified tags will show up in the template editor for users to select. ex: 'templating-logo, templating-image'. If empty, all images from this Brandfolder will be available.`;
        }
    }

    renderUserSelectOptions(options) {
        let optionArray = [];
        options.map((option, i) => {
            let optionObject = {};
            optionObject.value = option;
            optionObject.label = option;
            optionArray.push(optionObject);
        });
        return optionArray;
    }

    renderCheckboxField(fieldData, index, checked) {
        let label_column = this.props.attachment ? 4 : 3;
        let input_column = this.props.attachment ? 4 : 5;

        return (
            <div key={index} className="flex-wrapper flex-row">
                <Checkbox
                    checked={checked}
                    label={fieldData.label}
                    labelAlign="left"
                    size='md'
                    toggle={(e) => {
                      $('form.print-ui-form').attr('data-locked', 'true');
                      this.updateValue(e, [fieldData.field], fieldData.type)
                    }
                    }
                    tooltipCopy={
                        fieldData.label === "Auto Approve Downloads"
                            ? t`Immediately approve every rendition of this template and allow guests to download without admin confirmation.`
                            : null
                    }
                />
            </div>
        );
    }

    renderDropdownField(fieldData, index) {
      let other_options = this.renderUserSelectOptions(fieldData.options);
      // Stolen from SelectDropdown
      return (
        <div
          key={index}
          className="flex-wrapper flex-row print-ui"
        >
          <div className="checkbox-title-container flex-two-third-column">
            <h4>{fieldData.label}</h4>
          </div>
          <div className="flex-one-third-column">
            <ListDropdown
              className="s-create-collection-dropdown"
              id="s-create-collection-dropdown"
              name="s-create-collection-dropdown"
              onChange={(option) => {
                this.updateValue(
                  null,
                  [fieldData.label],
                  option.value
                );
              }}
              options={other_options}
              value={this.state[fieldData.field]}
              virtualizeOptions={false}
            />
          </div>
        </div>
      );
    }

    renderOtherFields(fieldData, index, checked) {
      let showLabel =
          fieldData.label === "Location" ||
          fieldData.label === "Show Social Galleries" ||
          fieldData.label === "Accessible Tags";

      return (
        <div key={index} className="flex-wrapper flex-row">
          <div className="input-container flex-wrapper flex-row print-ui">
            <div className="flex-two-third-column">
              {showLabel ? (
                <h4>
                  {fieldData.label}
                  <Tooltip
                    tooltipContent={this.tooltipCopy(
                      fieldData.label
                    )}
                    tooltipId={`label-for-${fieldData.label}`}
                  >
                    <span aria-hidden="true" className="bff-tool-tip icon" />
                  </Tooltip>
                </h4>
              ) : (
                <h4>{fieldData.label}</h4>
              )}
            </div>
            <div className="flex-one-third-column">
              <input
                autoComplete="off"
                autoFocus={true}
                checked={checked}
                className="print-ui-location-input"
                name={fieldData.field}
                onChange={(e) => this.updateValue(
                  e,
                  [fieldData.field],
                  fieldData.type
                )}
                placeholder={this.state[fieldData.field] ? this.state[fieldData.field].toString() : "" }
                type={fieldData.type}
                value={this.state[fieldData.field]}
              />
            </div>
          </div>
        </div>
      );
    }

    renderPrintuiSettingsFields(start, end) {
        let count = 0;
        return this.fields.map((fieldData, index) => {
            let checked = this.state[fieldData.field] === true;
            count += 1;
            if (start < count && count < end) {
                if (fieldData.type === "checkbox") {
                    return this.renderCheckboxField(fieldData, index, checked);
                }
                if (fieldData.type === "dropdown") {
                    return this.renderDropdownField(fieldData, index);
                } else {
                }
                return this.renderOtherFields(fieldData, index, checked);
            }
        });
    }

    renderForm(count, totalCount) {
      return (
        <>
          {this.renderPrintuiSettingsFields(0, count)}
          <div>
            {this.renderPrintuiSettingsFields(count - 1, totalCount)}
          </div>
        </>
      );
    }

    render() {
      const count = 5;
      const totalCount = this.fields.length + count;
      return (
        <I18nProviderWrapper>
          <div className="print-ui-settings-component">
            <form className="form print-ui-form">
              {this.renderForm(count, totalCount)}
              <div className="center">
                <button
                  className="button primary lg j-modal-submit-button"
                  onClick={this.submitForm}
                  type="button"
                >
                  <Trans>Update PrintUI Settings</Trans>
                </button>
              </div>
            </form>
          </div>
        </I18nProviderWrapper>
      );
    }
}
