import React, { FunctionComponent } from 'react';

import { StandardRadioButton } from '@components/library/radio_button/index';
import { RadioButton } from '@components/library/radio_button_group/index';
import { capitalize } from '@helpers/humanize';
import BaseRadioButtonGroup from '../BaseRadioButtonGroup';
import styleClassNames from './styles.module.scss';

interface StandardRadioButtonGroupProps {
  checkedId: string;
  name: string;
  onChange: (e: InputChangeEvent) => void;
  radioButtons: Array<RadioButton>;
  className?: string;
}

export const StandardRadioButtonGroup: FunctionComponent<StandardRadioButtonGroupProps> = (
  props
) => {
  const {
    checkedId,
    className,
    name,
    onChange,
    radioButtons,
  } = props;

  // important for accessibility, this id is used with 'aria-labelledby' attribute on radio input
  const containerId = name;

  const radioButtonElements = radioButtons.map((radioButton) => (
    <StandardRadioButton
      checked={radioButton.id === checkedId}
      containerId={containerId}
      disabled={radioButton.disabled}
      id={radioButton.id}
      key={radioButton.id}
      name={name}
      onChange={onChange}
      value={radioButton.value || radioButton.id}
    >
      {radioButton.copy || capitalize(radioButton.id)}
    </StandardRadioButton>
  ));

  const baseProps = {
    children: radioButtonElements,
    containerId,
    className,
    name,
    styleClassNames,
    variantName: 'standard',
  };

  return <BaseRadioButtonGroup {...baseProps} />;
};
