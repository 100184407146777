
import React from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import AssetManager from '@components/workbench/individual/asset_manager/main';
import Text from '@components/workbench/individual/text/text';

class TextExtractor extends React.Component {
  render() {
    return (
      <I18nProviderWrapper>
        <AssetManager extractText>
          <Text />
        </AssetManager>
      </I18nProviderWrapper>
    );
  }
}

export default TextExtractor;
