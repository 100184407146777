import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';

import { Portal, ValidationErrors } from '@api/v4/private/PortalTypes';
import Checkbox from '@components/common/checkbox/main';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';
import { FilestackUploader } from '@components/common/filestack_uploader/FilestackUploader';
import { TextWarningButton } from '@components/library/button/index';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';
import Input from '@components/library/inputs/PrimaryInput';
import Label from '@components/library/labels/PrimaryLabel';
import { makeValidUrl } from '@components/portals/helpers/make_valid_url';
import { isGettyClient } from '@helpers/getty-strings';

import { brandHexColorError, headerHexColorError, searchUrlError } from './validations';

interface PortalFormStage3Props {
  libraryName: string;
  portal: Portal;
  setPortal: SetStateDispatch<Portal>;
  stage: number;
  validationErrors: ValidationErrors[];
}

const PortalFormStage3: React.FunctionComponent<PortalFormStage3Props> = ({
  portal,
  setPortal,
  stage,
  validationErrors,
}) => {
  const [brandHexFocused, setBrandHexFocused] = useState(false);
  const [headerHexFocused, setHeaderHexFocused] = useState(false);

  const getColorInputValue = (hex: string | undefined, focused: boolean): string => {
    if (hex) {
      return `#${hex}`;
    }
    if (focused) {
      return '#';
    }
    return '';
  };

  const [brandHexColorInput, setBrandHexColorInput] = useState(
    getColorInputValue(portal.brandHexColor, brandHexFocused)
  );
  const [headerHexColorInput, setHeaderHexColorInput] = useState(
    getColorInputValue(portal.headerHexColor, headerHexFocused)
  );
  useEffect(() => setBrandHexColorInput(getColorInputValue(portal.brandHexColor, brandHexFocused)),
    [portal.brandHexColor, brandHexFocused]);
  useEffect(() => setHeaderHexColorInput(getColorInputValue(portal.headerHexColor, headerHexFocused)),
    [portal.headerHexColor, headerHexFocused]);

  if (stage !== 3) { return null; }

  const invalidSubmission = (validationErrors.length > 0);

  const renderColorSwatch = (value: string | undefined): JSX.Element => {
    let hexBackgroundColor = value ? value.trim() : '';
    if (!hexBackgroundColor.startsWith('#')) hexBackgroundColor = '#'.concat(hexBackgroundColor);
    return (
      <span
        className="portal-form-modal__brand-color--swatch"
        style={{ backgroundColor: `${hexBackgroundColor}` }}
      />
    );
  };

  return (
    <div>
      <h3 className="portal-form-modal__title"><Trans>Customization Settings</Trans></h3>
      <div className="portal-form-modal__brand-color-full-container">
        <div className="portal-form-modal__brand-color">
          <div className="portal-form-modal__brand-color--hex">
            <Label attributes={{ htmlFor: 'brand-color' }}><Trans>Brand color (HEX)</Trans></Label>
            <Input
              attributes={{
                name: 'brand-color',
                onChange: (e: InputChangeEvent): void => {
                  const brandHexColor = e.target.value.replace(/#/g, '');
                  setPortal((prevState): Portal => ({ ...prevState, brandHexColor }));
                },
                onFocus: (): void => setBrandHexFocused(true),
                onBlur: (): void => setBrandHexFocused(false),
                placeholder: '#FFFFFF',
                type: InputType.Text,
              }}
              input={{ value: brandHexColorInput, error: brandHexColorError(validationErrors) }}
              submitAttempted={invalidSubmission}
            />
          </div>
          {renderColorSwatch(portal.brandHexColor)}
        </div>
      </div>
      <div className="portal-form-modal__header-customizations">
        <h3 className="bf-label-styling"><Trans>Header image</Trans></h3>
        <p><Trans>Recommended size: 1600 x 335px, PNG, JPG or animated GIF.</Trans></p>
        <div className="portal-modal__filestack-container">
          <div className="portal-modal__filestack-container--description">
            {portal.headerUrl ? (
              <div className="portal-modal__filestack-container--thumbnail">
                <div className="portal-modal__filestack-container--thumbnail-container">
                  <FilestackPreviewImage
                    alt="Header"
                    className="portal-modal__filestack-container--thumbnail--image"
                    data-private
                    src={portal.headerUrl}
                  />
                </div>
                <TextWarningButton
                  className="portal-delete-button stage-3-delete"
                  icon="bff-trash"
                  onClick={(): void => setPortal((prevState): Portal => ({ ...prevState, headerUrl: '' }))}
                >
                  <Trans>Delete Image</Trans>
                </TextWarningButton>
              </div>
            )
              : (
                <FilestackUploader
                  onUploadDone={(files): void => {
                    const headerUrl = files.filesUploaded[0].url;
                    setPortal((prevState): Portal => ({ ...prevState, headerUrl }));
                  }}
                  pickerOptions={{
                    accept: 'image/*',
                    maxFiles: 1,
                    transformations: {
                      crop: {
                        aspectRatio: (1600 / 335),
                        force: true
                      }
                    }
                  }}
                />
              )
            }
          </div>
          <div>
            <div className="portal-form-modal__image-text">
              <Checkbox
                checked={portal.headerNameOverlay}
                label={t`Display name on image`}
                size="sm"
                toggle={(): void => {
                  setPortal((prevState): Portal => ({ ...prevState, headerNameOverlay: !prevState.headerNameOverlay }));
                }}
              />
            </div>
            <div className="portal-form-modal__brand-color">
              <div className="portal-form-modal__brand-color--hex">
                <Label attributes={{ htmlFor: 'title-color' }}><Trans>Title text color</Trans></Label>
                <Input
                  attributes={{
                    name: 'title-color',
                    onChange: (e: InputChangeEvent): void => {
                      const headerHexColor = e.target.value.replace(/#/g, '');
                      setPortal((prevState): Portal => ({ ...prevState, headerHexColor }));
                    },
                    onFocus: (): void => setHeaderHexFocused(true),
                    onBlur: (): void => setHeaderHexFocused(false),
                    placeholder: '#FFFFFF',
                    type: InputType.Text,
                  }}
                  input={{ value: headerHexColorInput, error: headerHexColorError(validationErrors) }}
                  submitAttempted={invalidSubmission}
                />
              </div>
              {renderColorSwatch(portal.headerHexColor)}
            </div>
          </div>
        </div>
      </div>
      <div className="portal-form-modal__search-url">
        <p className="portal-form-modal__search-url--description">
          {isGettyClient()
            ? <Trans>If you'd like a search bar to appear on your Brand Portal, enter the URL for a Library or Organization from which to populate search results.</Trans>
            : <Trans>If you'd like a search bar to appear on your Brand Portal, enter the URL for a Brandfolder or Organization from which to populate search results.</Trans>
          }
        </p>
        <InputContainer
          attributes={{
            name: 'portal-search-url',
            onChange: (e: InputChangeEvent): void => {
              const searchUrl = e.target.value;
              setPortal((prevState: Portal): Portal => ({ ...prevState, searchUrl }));
            },
            onBlur: (): void => {
              setPortal((prevState: Portal): Portal => ({ ...prevState, searchUrl: makeValidUrl(portal.searchUrl || '') }));
            },
            placeholder: 'https://brandfolder.com/marketing-assets',
            type: InputType.Text,
          }}
          className="portal-form-modal__search-url--input"
          input={{ value: portal.searchUrl, error: searchUrlError(validationErrors) }}
          labelCopy={isGettyClient() ? t`Org or Library URL` : t`Org or Brandfolder URL`}
          submitAttempted={invalidSubmission}
        />
      </div>
    </div>
  );
};

export default PortalFormStage3;
