import React, { useRef, useState } from 'react';
import dragDropTypes from '@components/common/drop_zone/drag_drop_types';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { FilestackPreviewImage } from '@components/common/filestack_uploader/FilestackPreviewImage';

import { updatePortalCard } from '@api/v4/private/portal_cards';
import { PortalCardTypes } from '@api/v4/private/PortalCardTypes';
import { TertiaryIconButton } from '@components/library/button/index';
import LinkForm from './link_form';

interface LinkProps {
  draggedIndex: number;
  editMode: boolean;
  iconUrl: string;
  linkUrl: string;
  name: string;
  portalCardId: string;
  triggerCardsRefresh: () => void;
}

interface HoveredItem {
  draggedIndex: number;
  id: string;
  index: number;
  insertedCardName: string;
  insertedCardId: string;
  startingIndex: number;
  type: string;
}

const BrandfolderIcon = "https://cdn.brandfolder.io/27C9EC93/at/q8y6v3-7yddcg-brgt1m/brandfolder-icon.svg";

const Link: React.FunctionComponent<LinkProps> = ({
  draggedIndex,
  editMode,
  iconUrl,
  linkUrl,
  name,
  portalCardId,
  triggerCardsRefresh
}) => {
  const [showForm, setShowForm] = useState(false);
  const toggleShowForm = (): void => setShowForm((prevState) => !prevState);
  const previewRef = useRef<HTMLDivElement | null>(null);
  const dragButtonRef = useRef<HTMLButtonElement | null>(null);

  const updatePosition = (insertedCardId: string, insertedCardName: string, position: number): void => {
    updatePortalCard(insertedCardId, {
      name: insertedCardName,
      position,
      type: PortalCardTypes.PortalLink
    }).then(triggerCardsRefresh);
  };

  const [, drop] = useDrop({
    accept: dragDropTypes.LINK,
    hover(item: HoveredItem, monitor: DropTargetMonitor) {
      if (!previewRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = draggedIndex;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      updatePosition(item.insertedCardId, item.insertedCardName, hoverIndex);
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      insertedCardId: portalCardId,
      insertedCardName: name,
      startingIndex: draggedIndex,
      type: dragDropTypes.LINK,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
  });

  if (editMode) {
    drop(preview(previewRef));
    drag(dragButtonRef);
  }

  const renderLink = (): JSX.Element => (
    <div ref={previewRef} className={`link-sidebar__item ${isDragging ? 'is-dragging' : ''}`}>
      <div className="link-sidebar__item--container">
        <button
          ref={dragButtonRef}
          className={`link-sidebar__item--reorder-button ${editMode ? "" : "hidden"}`}
          type="button"
        >
          <span className="bff-reorder" />
        </button>
        {editMode && (
          <TertiaryIconButton
            className="link-sidebar__item--edit-settings-button"
            icon="bff-edit"
            onClick={toggleShowForm}
          />
        )}
        <a className="link-sidebar__item--link" href={linkUrl} rel="noopener noreferrer" target="_blank">
          {name}
        </a>
        <div className="link-sidebar__item--icon-container">
          <FilestackPreviewImage
            alt="link icon"
            className="link-sidebar__item--icon"
            data-private
            src={iconUrl || BrandfolderIcon}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {showForm
        && (
          <LinkForm
            closeModal={toggleShowForm}
            portalCardId={portalCardId}
            triggerCardsRefresh={triggerCardsRefresh}
          />
        )
      }
      {renderLink()}
    </>
  );
};

export default Link;
