import { FontIcon } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { DragSource } from 'react-dnd';

import Checkbox from '@components/common/checkbox/main';
import dragDropTypes from '@components/common/drop_zone/drag_drop_types';
import DropZone from '@components/common/drop_zone/drop_zone';

const SearchPinsRow = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [pinLabel, setPinLabel] = useState('');
  const [pinQuery, setPinQuery] = useState('');

  const thisRow = useRef();

  const {
    dispatch,
    dragInProgress,
    editingActive,
    searchPin,
    setEditingActive,
    updatedFiltersData,
  } = props; // parent props
  const {
    isDragging,
    isHovered,
    connectDragSource,
  } = props; // React DnD props

  const searchPinIndex = updatedFiltersData.indexOf(searchPin);

  const resetRowData = () => {
    setPinLabel(searchPin.label);
    setPinQuery(searchPin.query);
  };

  useEffect(() => {
    resetRowData();
  }, [searchPin]);

  const generateUpdatedSearchPin = () => {
    const { featured, key, position } = searchPin;

    return { featured, key, label: pinLabel, position, query: pinQuery };
  };

  const toggleEdit = (action) => {
    const updateEditing = (bool) => {
      setIsEditing(bool);
      setEditingActive(bool);
    };

    if (action === 'edit') {
      updateEditing(true);
    }

    if (action === 'close') {
      resetRowData();
      updateEditing(false);
    }

    if (action === 'done') {
      dispatch({ data: generateUpdatedSearchPin(), index: searchPinIndex, type: 'done-editing' });
      updateEditing(false);
    }
  };

  const handleReposition = ({
    draggedIndex,
    hoveredItem: hoveredRow,
  }) => {
    dispatch({
      data: { hoveredRow },
      index: draggedIndex,
      type: 'update-row-position',
    });
  };

  const pinRowContainer = (
    <li
      ref={thisRow}
      className={classnames(
        "pin-row-container",
        {
          "disable-row": editingActive && !isEditing,
          "disable-row-editing": !isEditing,
          "is-dragging": isDragging,
          "is-hovered": isHovered,
        },
      )}
      id={searchPin.key}
    >
      <div className="pin-row-container__drag-icon">
        <FontIcon icon="drag-indicator" />
      </div>
      <div className="pin-row-container__label">
        <input
          className="brandfolder-input label-input"
          disabled={!isEditing}
          onChange={(e) => setPinLabel(e.target.value)}
          value={pinLabel}
        />
      </div>
      <div className="pin-row-container__featured">
        <Checkbox
          checked={searchPin.featured}
          size="md"
          toggle={() => dispatch({ type: 'update-featured', index: searchPinIndex })}
        />
      </div>
      <div className="pin-row-container__query">
        <input
          className="brandfolder-input query-input"
          disabled={!isEditing}
          onChange={(e) => setPinQuery(e.target.value)}
          value={pinQuery}
        />
      </div>
      {isEditing ? (
        <div className="pin-row-container__buttons">
          <button
            className="button tertiary sm cancel-edit-button"
            onClick={() => toggleEdit("close")}
            type="button"
          >
            <Trans>Cancel</Trans>
          </button>
          <button
            className="button primary sm done-editing-button"
            onClick={() => toggleEdit("done")}
            type="button"
          >
            <Trans>Update</Trans>
          </button>
        </div>
      ) : (
        <div className="pin-row-container__buttons">
          <button
            className="button tertiary sm edit-row-button"
            onClick={() => toggleEdit("edit")}
            type="button"
          >
            <span className="bff-edit" />
            <Trans>Edit</Trans>
          </button>
          <button
            className="delete-row-button"
            onClick={() => dispatch({ index: searchPinIndex, type: 'delete-row' })}
            type="button"
          >
            <span className="bff-trash" />
          </button>
        </div>
      )}
      {!editingActive && dragInProgress && (
        <>
          <DropZone
            dragDropType="SEARCH_PIN"
            dropHalf="top"
            handleReposition={handleReposition}
            hoveredItemKey={searchPin.key}
          />
          <DropZone
            dragDropType="SEARCH_PIN"
            dropHalf="bottom"
            handleReposition={handleReposition}
            hoveredItemKey={searchPin.key}
          />
        </>
      )}
    </li>
  );

  // disable drag n drop when editing row
  if (editingActive) {
    return pinRowContainer;
  }

  return connectDragSource(pinRowContainer);
};

SearchPinsRow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dragInProgress: PropTypes.bool.isRequired,
  editingActive: PropTypes.bool.isRequired,
  searchPin: PropTypes.shape({}).isRequired,
  setEditingActive: PropTypes.func.isRequired,
  updatedFiltersData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  // props used for React DnD wrapper
  /* eslint-disable react/no-unused-prop-types */
  index: PropTypes.number.isRequired,
  setDragInProgress: PropTypes.func.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

const dragSource = {
  beginDrag(props) {
    props.setDragInProgress(true);

    return { key: props.searchPin.key, draggedIndex: props.index };
  },
  endDrag(props) {
    props.setDragInProgress(false);
  }
};

const dragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

export default DragSource(dragDropTypes.SEARCH_PIN, dragSource, dragCollect)(SearchPinsRow);
