/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import MentionableInput from '@components/common/mentions/MentionableInput';

const NewAnnotation = ({ mentionableUsers, onSubmit, setMentionableUsers }) => {
  const [value, setValue] = useState("");
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const annotationTextarea = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(value, mentionedUsers);
  };

  return (
    <div>
      <h4><Trans>Leave an annotation</Trans></h4>
      <MentionableInput
        content={value}
        focusOnMount
        inputRef={annotationTextarea}
        onChange={(content, ids) => { setValue(content); setMentionedUsers(ids); }}
        onSubmit={handleSubmit}
        providedData={mentionableUsers}
        setParentMentionableUsers={setMentionableUsers}
      />
      <button
        className="button primary sm new-annotation-button"
        disabled={!value}
        onClick={handleSubmit}
        type="button"
      >
        <Trans>Post</Trans>
      </button>
    </div>
  );
};

NewAnnotation.propTypes = {
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  onSubmit: PropTypes.func,
  setMentionableUsers: PropTypes.func.isRequired,
};

NewAnnotation.defaultProps = {
  mentionableUsers: null,
  onSubmit: () => {},
};

export default NewAnnotation;
