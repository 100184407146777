import React from "react";

const ProgressBar = (props) => (
  <div className="adobe-progress-bar">
    <img
      alt="loading icon"
      className="bff-spinner-gif"
      src="https://cdn.bfldr.com/27C9EC93/at/qdipip-a094w0-9udpnk/bff-spinner-gif"
    />
    {props.message}
  </div>
);

export default ProgressBar;
