import React from "react";
import PropTypes from "prop-types";
import FontInput from "./font_input";

class TextInput extends React.Component {
  state = {
    value: this.props.frame.value,
    fontSize: null,
    validationMessage: null,
  }

  componentDidMount() {
    this.setValue(); // Respect pagination updates
  }

  setValue = () => {
    const { textFrameData, frame } = this.props;
    if (textFrameData[frame.id] && textFrameData[frame.id].value) {
      this.setState({ value: textFrameData[frame.id].value });
    }
  }

  triggerFrameUpdate = () => {
    const { frame, updateFrameData } = this.props;
    const { value, fontSize } = this.state;
    updateFrameData({ [frame.id]: { value, font_size: fontSize } });
  }

  validateValue = (e) => {
    // form validations deny url unless it's HTTP - we want to bypass www.
    const validWebAddress = (e.target.validationMessage === "Please enter a URL."
      && e.target.value.includes("www."));
    if (!e.target.checkValidity() && !validWebAddress) {
      this.setState({ validationMessage: e.target.validationMessage });
      e.target.value = e.target.defaultValue;
    } else {
      this.setState({ validationMessage: null });
    }
  }

  updateFontSize = (fontSize) => {
    this.setState({ fontSize: parseInt(fontSize, 10) }, () => {
      this.triggerFrameUpdate();
    });
  }

  inputType = () => {
    if (["url", "email", "number", "date", "tel", "time"]
      .includes(this.props.validations.inputType)) {
      return this.props.validations.inputType;
    }
    return 'text';
  }

  denyReplacement = () => (
    !this.props.editMode && this.props.validations?.inputType === 'deny_replacement'
  );
  renderValidationMessage = () => (
    <p style={{ color: "red" }}>{ this.state.validationMessage }</p>
  )

  render() {
    const { frame, validations, fontSettings, templateSettings } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
        <div className="input-headers">
          <label className="bf-label bf-label--primary" htmlFor={frame.id}>
            {frame.name === "$ID/" ? "" : frame.name}
          </label>
          <FontInput
            denyTextReplacement={this.denyReplacement()}
            fontSettings={fontSettings}
            frame={frame}
            templateSettings={templateSettings}
            updateFontSize={this.updateFontSize}
          />
        </div>
        <input
          aria-disabled={this.denyReplacement()}
          className="bf-input bf-input--primary full-width"
          disabled={this.denyReplacement()}
          id={frame.id}
          max={validations.length_max || 99999}
          maxLength={validations.length_max || 9999}
          name={`${frame.id}-text-input`}
          onBlur={this.triggerFrameUpdate}
          onChange={(e) => this.setState({ value: e.target.value })}
          onKeyUp={(e) => { if (e.key === 'Enter') { this.triggerFrameUpdate(); } }}
          required
          type={this.inputType()}
          value={value}
        />
        {this.renderValidationMessage()}
      </React.Fragment>
    );
  }
}

TextInput.propTypes = {
  frame: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
  }).isRequired,
  printuiInput: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    field_identifier: PropTypes.string,
    options: PropTypes.shape({
      custom_settings: PropTypes.shape({
        type: PropTypes.string
      })
    })
  }),
  validations: PropTypes.shape({
    inputType: PropTypes.string
  }),
  updateFrameData: PropTypes.func.isRequired,
  fontSettings: PropTypes.shape({}).isRequired,
  templateSettings: PropTypes.shape({
    allowFontSizing: PropTypes.bool
  }).isRequired,
  textFrameData: PropTypes.shape({})
};

TextInput.defaultProps = {
  printuiInput: {},
  textFrameData: {},
  validations: {}
};

export default TextInput;
