import { plural, t, Plural, Trans } from '@lingui/macro';
import * as React from 'react';

import { getAllBoards, createBoard, updateBoard } from '@api/v4/boards';

import { BoardsResponse, BoardResponse, BoardResponseObject } from '@api/v4/boards/boardsTypes';

import Checkbox from '@components/common/checkbox/main';

import renderModal from '../modals/renderModal';

import './styles/BulkBoardModal.scss';

interface AddToExistingBoardHandlerParams {
  closeModal: () => void;
  selectedBoardsIds: string[];
  selectedAssetKeys: Set<string>;
}

// reflect success or error message
const addToExistingBoardHandler = async (options: AddToExistingBoardHandlerParams): Promise<void> => {
  const { selectedBoardsIds, selectedAssetKeys, closeModal } = options;
  try {
    await Promise.all(
      selectedBoardsIds.map((selectedBoardId): Promise<BoardResponse> => updateBoard({ boardId: selectedBoardId, assetIds: selectedAssetKeys, operation: 'ADD' }))
    );

    const boardCount = selectedBoardsIds.length;
    const title = plural(boardCount, {
      one: 'Assets added successfully to board.',
      other: 'Assets added successfully to boards.'
    });
    Notify.create({ title, type: 'success' });
  } catch (error) {
    console.log(error);
    Notify.create({ title: t`Something went wrong. Try again soon!`, type: 'error' });
  } finally {
    closeModal();
  }
};

interface AddToNewBoardHandlerParams {
  boardTitle: string;
  closeModal: () => void;
  selectedAssetKeys: Set<string>;
}

// reflect success or error message
const addToNewBoardHandler = async (options: AddToNewBoardHandlerParams): Promise<void> => {
  const { selectedAssetKeys, boardTitle, closeModal } = options;
  try {
    // reponse is created board
    await createBoard({ boardTitle, assetIds: selectedAssetKeys });
    Notify.create({ title: t`Board successfully created and assets added.`, type: 'success' });
  } catch (error) {
    console.log(error);
    Notify.create({ title: t`Something went wrong. Try again soon!`, type: 'error' });
  } finally {
    closeModal();
  }
};

interface ToggleSelectedBoardsParams {
  boardId: string;
  selectedBoardsIds: string[];
}

const toggleSelectedBoards = ({ selectedBoardsIds, boardId }: ToggleSelectedBoardsParams): string[] => {
  let trimmedBoardsIds: string[] = [];
  if (selectedBoardsIds.includes(boardId)) {
    trimmedBoardsIds = selectedBoardsIds.filter((selectedBoardId) => selectedBoardId !== boardId);
  } else {
    trimmedBoardsIds = selectedBoardsIds.concat([boardId]);
  }
  return trimmedBoardsIds;
};

interface ExistingBoardChecklistProps {
  boards: Array<BoardResponseObject>;
  selectedBoardsIds: string[];
  setSelectedBoardsIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const ExistingBoardChecklist: React.FunctionComponent<ExistingBoardChecklistProps> = (options) => {
  const { boards, selectedBoardsIds, setSelectedBoardsIds } = options;
  React.useEffect(() => (): void => setSelectedBoardsIds([]), [setSelectedBoardsIds]);
  return (
    <div className="board-list-container">
      {
        boards.map((board) => (
          <div key={board.id} className="checkbox-container">
            <Checkbox
              checked={selectedBoardsIds.includes(board.id)}
              labelHtml={<h5> {board.attributes.name} </h5>}
              size="sm"
              toggle={(): void => setSelectedBoardsIds(toggleSelectedBoards({ boardId: board.id, selectedBoardsIds }))}
            />
          </div>
        ))
      }
    </div>
  );
};

type NewBoardInputComponentType = React.FunctionComponent<{ setNewBoardTitle: React.Dispatch<React.SetStateAction<string>> }>;

const NewBoardInput: NewBoardInputComponentType = ({ setNewBoardTitle }) => {
  const input = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (input && input.current) {
      input.current.focus();
    }
    return (): void => setNewBoardTitle('');
  }, [setNewBoardTitle]);

  return (
    <label className="brandfolder-label new-resource-label">
      <Trans>Board Name</Trans>
      <input
        ref={input}
        className="inputs bf-input--primary"
        name="board-name"
        onChange={(e): void => setNewBoardTitle(e.target.value.trim())}
        type="text"
      />
    </label>
  );
};

interface BulkBoardModalProps {
  closeModal: () => void;
  selectedAssetKeys: Set<string>;
}

const BulkBoardModal: React.FunctionComponent<BulkBoardModalProps> = ({
  selectedAssetKeys,
  closeModal
}): JSX.Element => {
  const [boards, setBoards] = React.useState<BoardResponseObject[]>([]);
  const [showNewBoardView, setShowNewBoardView] = React.useState(true);
  const [selectedBoardsIds, setSelectedBoardsIds] = React.useState<string[]>([]);
  const [newBoardTitle, setNewBoardTitle] = React.useState('');
  const assetCount = selectedAssetKeys.size;

  React.useEffect(() => {
    (async (): Promise<void> => {
      try {
        const { data }: BoardsResponse = await getAllBoards();
        setBoards(data);
      } catch (err) {
        console.log('getAllBoards fetch failed in AssetBoard');
        console.log(err);
      }
    })();
    return closeModal;
  }, [closeModal]);

  const modalRadioElements = (): JSX.Element => (
    <div className="radio-button-container">
      <label className="radio-row">
        <h4><Trans>Add to existing Boards</Trans></h4>
        <input
          checked={!showNewBoardView}
          className="radio-input"
          name="radio"
          onChange={(): void => setShowNewBoardView(!showNewBoardView)}
          type="radio"
          value="add-to-existing-board"
        />
        <span className="radio-dot" />
      </label>
      <label className="radio-row">
        <h4><Trans>Create new Board</Trans></h4>
        <input
          checked={showNewBoardView}
          className="radio-input"
          name="radio"
          onChange={(): void => setShowNewBoardView(!showNewBoardView)}
          type="radio"
          value="add-to-new-board"
        />
        <span className="radio-dot" />
      </label>
    </div>
  );

  const modalButtonRow = (): JSX.Element => (
    <div className="button-container">
      <button
        className="button primary sm t-confirm-modal"
        disabled={showNewBoardView ? newBoardTitle.length === 0 : selectedBoardsIds.length === 0}
        onClick={(): void => {
          if (showNewBoardView) {
            addToNewBoardHandler({ selectedAssetKeys, boardTitle: newBoardTitle, closeModal });
          } else {
            addToExistingBoardHandler({ selectedBoardsIds, selectedAssetKeys, closeModal });
          }
        }}
        type="button"
      >
        <Trans>Save</Trans>
      </button>
    </div>
  );

  return (
    <div className="modal-content-wrapper bulk-board-modal">
      <div className="modal-content-wrapper__header">
        <span aria-hidden="true" className="bff-boards icon" />
        <h3 className="modal-title">
          <Plural
            one={
              <Trans>
                Add
                <span className="bold-text">
                  {assetCount} asset
                </span>
                to one or more Boards
              </Trans>
            }
            other={
              <Trans>
                Add
                <span className="bold-text">
                  {assetCount} assets
                </span>
                to one or more Boards
              </Trans>
            }
            value={assetCount}
          />
        </h3>
        <button
          className="close-button"
          onClick={closeModal}
          type="button"
        >
          <span className="bff-close" />
        </button>
      </div>
      <div className="modal-content-wrapper__body">
        <div className="modal-row">
          {modalRadioElements()}
          {
            showNewBoardView
              ? (
                <NewBoardInput
                  setNewBoardTitle={setNewBoardTitle}
                />
              )
              : (
                <ExistingBoardChecklist
                  boards={boards}
                  selectedBoardsIds={selectedBoardsIds}
                  setSelectedBoardsIds={setSelectedBoardsIds}
                />
              )
          }
        </div>
        <div>
          { modalButtonRow() }
        </div>
      </div>
    </div>
  );
};

const ModalComponent = renderModal(BulkBoardModal, 'BulkBoardModal');
export default ModalComponent;
