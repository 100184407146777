import { getCurrentUserIsAdminPlus } from '@helpers/user';

const HISTORY_ALLOWED_RESOURCE_TYPES = ['brandfolder', 'collection'];

export const shouldDisplayAssetHistory = (): boolean => (
  // Asset History Log API only supports getting asset history at brandfolder/collection level so
  // don't render history tab if not on one of those resources
  HISTORY_ALLOWED_RESOURCE_TYPES.includes(BFG.resource?.type)
  && getCurrentUserIsAdminPlus(BFG.currentUser)
);

export const shouldDisplayAssetDetails = (): boolean => (
  BFG.hasFeature('asset_approvals') || BFG.hasFeature('asset_availability')
);
