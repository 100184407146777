import { Plural, t, Trans } from '@lingui/macro';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isGettyClient } from "../../helpers/getty-strings";

export default class InviteUsersStage3 extends Component {
  state = {
    emailsSelected: false,
    isSubmittingInvites: false,
    resourcesSelected: false,
    submitButtonEnabled: false,
    textareaValue: ''
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const emailsSelected = nextProps.emailsArray.length ? true : false;
    const resourcesSelected = Object.keys(nextProps.selectedResources).length ? true : false;
    const submitButtonEnabled = emailsSelected && resourcesSelected ? true : false;

    return {emailsSelected, resourcesSelected, submitButtonEnabled}
  }

  handleSubmitInvites() {
    const successOptions = {
      type: 'success',
      title: t`Requests Submitted`,
      text: t`Your invites are processing! Permissions will be updated shortly.`,
      customClass: 'submitting-invites',
      showConfirmButton: true,
      allowOutsideClick: true
    }

    const errorOptions = {
      type: 'error',
      title: t`Submission Failed`,
      text: t`An error occurred while submitting invites. Please try again or contact support.`,
      customClass: 'submitting-invites',
      showConfirmButton: true,
      allowOutsideClick: true
    }

    this.setState({isSubmittingInvites: true, submitButtonEnabled: false}, () => {
      this.submitInvites()
        .then((response) => {
          setTimeout(() => {
            this.props.updateInviteState({submitted: true});
            this.setState({isSubmittingInvites: false});
            ReactRailsUJS.unmountComponents('#dialogBody');
            BF.dialog.dismiss();
            swal(successOptions, (isConfirm) => {
            });
          }, 1500)
        })
        .catch((error) => {
          BF.dialog.dismiss();
          swal(errorOptions);
        })
    });
  }

  submitInvites() {
    return fetch('/api/v4/invitations', {
      method: 'POST',
      headers: { 'Authorization' : `Bearer ${BF_Token}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(this.buildSubmitData())
    })
  }

  buildSubmitData () {
    const {emailsArray, selectedPermissionLevel, selectedResources} = this.props;
    const {textareaValue} = this.state;

    const emailsArrayText = emailsArray.map((email) => email.text);
    const resourcesArray = Object.keys(selectedResources).map((resourceKey) => {
      return {
        resource_type: selectedResources[resourceKey].type,
        resource_key: resourceKey
      }
    })

    return {
      data: {
        attributes: {
          emails: emailsArrayText,
          permission_level: selectedPermissionLevel,
          personal_message: textareaValue,
          prevent_email: false
        },
        resources: resourcesArray
      }
    }

  }

  getEmailList() {
    if (!this.state.emailsSelected) {
      return (
        <React.Fragment>
          <h4 className='missing-input-warning-bold'><Trans>You haven’t entered any email addresses. </Trans></h4>
          <h4 className='missing-input-warning'><Trans>Return to step 01 to invite users by email.</Trans></h4>
        </React.Fragment>
      )
    }

    return this.props.emailsArray.map((email, i) => {
      return <h4 key={i} className='email-copy'>{email.text}</h4>
    })
  }

  getResourceList() {
    const { libraryName, resources, selectedResources } = this.props;
    const resourcesToSubmit = [];

    if (!this.state.resourcesSelected) {
      return (
        <React.Fragment>
          <h4 className='missing-input-warning-bold'>
            {isGettyClient()
              ? <Trans>You haven't selected any Libraries or Collections</Trans>
              : <Trans>You haven't selected any Brandfolders or Collections</Trans>
            }
          </h4>
          <h4 className='missing-input-warning'>
            {isGettyClient()
              ? <Trans>Return to step 02 to select Libraries and Collections</Trans>
              : <Trans>Return to step 02 to select Brandfolders and Collections</Trans>
            }
          </h4>
        </React.Fragment>
      )
    }

    Object.keys(resources).forEach((brandfolderKey) => {
      if (selectedResources[brandfolderKey]) {
        resourcesToSubmit.push(
          <div key={brandfolderKey} className='brandfolder-submit-container submit-container'>
            <div className='brandfolder-submit-row submit-row'>
              <h4>{selectedResources[brandfolderKey].name}</h4>
            </div>
          </div>
        )
      } else {
        let parentKeyIndicator = false;

        Object.keys(selectedResources).forEach((resourceKey) => {
          selectedResources[resourceKey].parent_key === brandfolderKey ? parentKeyIndicator = true : null;
        })

        if (parentKeyIndicator) {
          resourcesToSubmit.push(
            <div key={brandfolderKey} className='brandfolder-submit-container submit-container'>
              <div className='brandfolder-submit-row submit-row not-submitted'>
                <h4>{resources[brandfolderKey].name}</h4>
              </div>
              <div className='collection-submit-container submit-container'>
                {this.buildCollectionsToSubmit(brandfolderKey)}
              </div>
            </div>
          )
        }
      }
    })

    if (resourcesToSubmit.length) {
      resourcesToSubmit.splice(0, 0, <h6 key={0} className='resource-title'>{pluralize(libraryName).toUpperCase()}</h6>);
    }

    return resourcesToSubmit;
  }

  buildCollectionsToSubmit(brandfolderKey) {
    const {selectedResources} = this.props;
    const collectionsToSubmit = [];

    Object.keys(selectedResources).forEach((resourceKey) => {
      const selectedResource = selectedResources[resourceKey];

      if (selectedResource.parent_key === brandfolderKey) {
        collectionsToSubmit.push(
          <div key={resourceKey} className='collection-submit-row submit-row'><h4>{selectedResource.name}</h4></div>
        )
      }
    })

    if (collectionsToSubmit.length) {
      collectionsToSubmit.splice(0, 0, <h6 key={0} className='resource-title'><Trans>COLLECTIONS</Trans></h6>);
    }

    return collectionsToSubmit
  }

  submitFeedback() {
    const {emailsArray, selectedResources, submitted} = this.props;
    const {submitButtonEnabled, isSubmittingInvites} = this.state;

    if (!submitButtonEnabled && isSubmittingInvites) {
      return (<h3 className='section-title center'>Submitting...</h3>)
    } else if (submitted) {
      return (<h3 className='section-title center'><Trans>Submitted!</Trans></h3>)
    } else if (!submitButtonEnabled) {
      return (<h3 className='section-title'><Trans>Review your selections:</Trans></h3>)
    }

    const emailCount = emailsArray.length;
    let   brandfoldersToSubmit = 0;
    let   collectionsToSubmit = 0;

    Object.keys(selectedResources).forEach((key) => {
      if (selectedResources[key].type === 'brandfolder') {
        brandfoldersToSubmit += 1;
      } else {
        collectionsToSubmit += 1;
      }
    })

    const collectionCopy = collectionsToSubmit > 1 ? 'Collections' : 'Collection';

    if (brandfoldersToSubmit && collectionsToSubmit) {
      return (
        <h3 className='section-title'>
          <Plural
            one={
              <Trans>
                Are you sure you want to invite
                <span className="bold-text">{emailCount} user</span>
                <span className="following-copy">to the following</span>
                Brandfolders and Collections?
              </Trans>
            }
            other={
              <Trans>
                Are you sure you want to invite
                <span className="bold-text">{emailCount} users</span>
                <span className="following-copy">to the following</span>
                Brandfolders and Collections?
              </Trans>
            }
            value={emailCount}
          />
        </h3>
      );
    } else if (brandfoldersToSubmit) {
      return (
        <h3 className='section-title'>
        <Plural
          one={
            <Trans>
              Are you sure you want to invite
              <span className="bold-text">{emailCount} user</span>
              <span className="following-copy">to the following</span>
              Brandfolders?
            </Trans>
          }
          other={
            <Trans>
              Are you sure you want to invite
              <span className="bold-text">{emailCount} users</span>
              <span className="following-copy">to the following</span>
              Brandfolders?
            </Trans>
          }
          value={emailCount}
        />
      </h3>
      );
    } else if (collectionsToSubmit) {
      return (
        <h3 className='section-title'>
        <Plural
          one={
            <Trans>
              Are you sure you want to invite
              <span className="bold-text">{emailCount} user</span>
              <span className="following-copy">to the following</span>
              Collections?
            </Trans>
          }
          other={
            <Trans>
              Are you sure you want to invite
              <span className="bold-text">{emailCount} users</span>
              <span className="following-copy">to the following</span>
              Collections?
            </Trans>
          }
          value={emailCount}
        />
      </h3>
      );
    }
  }

  render () {
    if (this.props.stage !== 3) { return null }

    const { submitted, updateInviteState } = this.props;
    const { submitButtonEnabled, textareaValue } = this.state;

    return (
      <React.Fragment>
        <div className='section-title-container'>
          {this.submitFeedback()}
        </div>
        {this.state.isSubmittingInvites
          ? <video className='brandfolder-loader' height='90' poster='https://cdn.brandfolder.io/4OQZ5PW1/as/pxmju5-qfr48-21nt37/generic-loader.gif' width='90'>Browser Doesn't Support HTML5 Video Tag</video>
          : <div className='section-body body-review-invite'>
          <div className='review-list-container'>
            <div className='email-list'>{this.getEmailList()}</div>
            <div className='resource-list'>{this.getResourceList()}</div>
          </div>
          <div className='personal-message-container'>
            <h3 className='section-title personal-message-copy'>
              <Trans>
                Add a personal message to invited users
                <span className='optional-text'>(optional)</span>
              </Trans>
            </h3>
            <textarea className='textareas'
                      onChange={(e) => this.setState({textareaValue: e.target.value})}
                      value={textareaValue}>
            </textarea>
          </div>
        </div>}
        <div className='button-container'>
          <button
            className='button tertiary sm'
            onClick={() => updateInviteState({stage: 2})}
            type="button"
          >
            <Trans>Back</Trans>
          </button>
          <button
            className='button primary lg'
            disabled={!submitButtonEnabled || submitted}
            onClick={() => this.handleSubmitInvites()}
            type="button"
          >
            <Trans>Send Invites</Trans>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

InviteUsersStage3.defaultProps = {
  libraryName: "Brandfolder"
};

InviteUsersStage3.propTypes = {
  emailsArray: PropTypes.array.isRequired,
  libraryName: PropTypes.string,
  resources: PropTypes.object.isRequired,
  selectedPermissionLevel: PropTypes.string.isRequired,
  selectedResources: PropTypes.object.isRequired,
  stage: PropTypes.number.isRequired,
  submitted: PropTypes.bool.isRequired,
  updateInviteState: PropTypes.func.isRequired
};
