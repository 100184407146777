import { useContext } from 'react';

import { DialogContext, DialogContextProps } from '../providers/DialogContextProvider';

export const useDialog = (): DialogContextProps => {
  const context = useContext(DialogContext);

  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider.');
  }

  return context;
};
