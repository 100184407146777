import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { GenericFileData } from '@api/v4/assets/assetTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import {
  ActionTypes,
  AssetDetailsDispatch,
  FlattenedAttachment,
  ModalEditFormContentState
} from '@components/asset/modal/tabs/edit/EditTabTypes';
import { GelatoPrintConfig } from '@components/gelato';
import { AttachmentApiDatumResponse } from '@components/gelato/helpers/attachments';
import { TextButton } from '@components/library/button';
import { FontIcon, FontIcons } from '@components/library/icon';
import InputContainer from '@components/library/input_container/InputContainer';
import { InputType } from '@components/library/inputs/Input.props';

interface GelatoProductUidScopedContext {
  state: {
    editState: {
      attachments: FlattenedAttachment[];
      genericFileData: GenericFileData | null;
    };
  };
  dispatch: AssetDetailsDispatch;
}

const GelatoProductUid: FunctionComponent = () => {
  const { state, dispatch }: GelatoProductUidScopedContext = useContext(assetModalContext);
  const { attachments, genericFileData } = state.editState;
  const { gelato_pages_override, gelato_product_uid } = { ...genericFileData };

  const [gelatoProductUid, setGelatoProductUid] = useState(gelato_product_uid || '');
  const [gelatoPagesOverride, setGelatoagesOverride] = useState<string | undefined>(gelato_pages_override);
  const [showGelatoPrintConfig, setShowGelatoPrintConfig] = useState(false);

  const attachment = attachments.find((a) => a.extension === 'pdf' || a.extension === 'bftemplate');

  const attachmentBestMetadata = useFetch<AttachmentApiDatumResponse>({
    fetchOnMount: false,
    params: { fields: 'best_metadata' },
    url: `/api/v4/attachments/${attachment?.key}`
  });

  useEffect(() => {
    if (attachment) {
      attachmentBestMetadata.fetch();
    }
  }, [attachment]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (attachmentBestMetadata.response && !gelatoPagesOverride) {
      const pageCount = attachmentBestMetadata.response.data?.attributes?.best_metadata?.page_count;
      setGelatoagesOverride(!Number.isNaN(pageCount as unknown) ? pageCount : '1');
    }
  }, [attachmentBestMetadata.response]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGelatoProductUid = (value: string): void => {
    const payload: Partial<ModalEditFormContentState> = {
      genericFileData: {
        ...genericFileData,
        gelato_product_uid: value
      }
    };
    setGelatoProductUid(value);
    dispatch({ type: ActionTypes.UpdateAsset, payload });
  };

  const handleGelatoPagesOverride = (value: string): void => {
    const payload: Partial<ModalEditFormContentState> = {
      genericFileData: {
        ...genericFileData,
        gelato_pages_override: value
      }
    };
    setGelatoagesOverride(value);
    dispatch({ type: ActionTypes.UpdateAsset, payload });
  };

  return (
    <div className="asset-advanced__feature asset-advanced__feature--gelato">
      <div className="gelato-config">
        <div className="gelato-config__form">
          {attachment && (
            <>
              <InputContainer
                attributes={{
                  id: 'gelatoProductUid',
                  name: 'gelatoProductUid',
                  onChange: (e: InputChangeEvent): void => handleGelatoProductUid(e.target.value),
                  type: InputType.Text,
                }}
                input={{ value: gelatoProductUid }}
                labelCopy={<Trans>Ciloo Product ID</Trans>}
              />
              <InputContainer
                attributes={{
                  className: 'gelato-config__pages',
                  id: 'gelatoPagesOverride',
                  name: 'gelatoPagesOverride',
                  onChange: (e: InputChangeEvent): void => handleGelatoPagesOverride(e.target.value),
                  type: InputType.Number,
                }}
                input={{ value: gelatoPagesOverride }}
                labelCopy={(
                  <Trans>
                    Number of pages<br />
                    <small className="gelato-config__pages--helper">
                      ('e.g. 6 for trifolds')
                    </small>
                  </Trans>
                )}
              />
            </>
          )}
          {!attachment && (
            <div className="gelato-config__warning-state">
              <FontIcon aria-hidden icon={FontIcons.Warning} />
              <p className="gelato-config__warning-state-copy">
                <Trans>Add a PDF attachment to enable Ciloo printing for this asset.</Trans>
              </p>
            </div>
          )}
          {attachment && (
            <TextButton
              icon={showGelatoPrintConfig ? `bff-${FontIcons.Hide}` : `bff-${FontIcons.Show}`}
              onClick={(): void => setShowGelatoPrintConfig((prev) => !prev)}
            >
              {showGelatoPrintConfig ? <Trans>Hide Ciloo Config</Trans> : <Trans>Show Ciloo Config</Trans>}
            </TextButton>
          )}
        </div>
      </div>
      {showGelatoPrintConfig && <GelatoPrintConfig handleGelatoProductUid={handleGelatoProductUid} />}
    </div>
  );
};

export default GelatoProductUid;
