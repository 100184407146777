import React, { FunctionComponent, ReactNode } from 'react';

import classes from './styles/validation-alert.module.scss';

interface ValidationAlertProps {
  children: ReactNode;
}

export const ValidationAlert: FunctionComponent<ValidationAlertProps> = ({ children }) => (
  <p
    className={classes['validation-alert']}
    role="alert"
  >
    {children}
  </p>
);
