export const makeValidUrl = (url: string): string => {
  const pattern = /^([A-z]*:\/\/)/;

  if (pattern.test(url)) {
    return url;
  }

  if (url) {
    return `http://${url}`;
  }

  return '';
};
