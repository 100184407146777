import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

const PersonDetails = ({
  bio,
  email,
  linkedin,
  name,
  phoneNumber,
  title,
  twitter,
  twitterUrl
}) => (
  <div className="m-show-description">
      {title ? (
        <h3 className="s-people-name"> {name}, {title} </h3>
      ) : (
        <h3 className="s-people-name"> {name} </h3>
      )}
    <div className="s-person-show-email">
      {email ? (
        <a
          className="noline"
          href={`mailto:${email}`}
          rel="noopener noreferrer"
          target="_blank"
          title={email}
        >
          <span aria-label="email" className="bff-email icon" />
          <span className="person-info">{email}</span>
        </a>
      ) : (
        ""
      )}
      {phoneNumber ? (
        <a
          className="noline phone-number"
          href={`tel:${phoneNumber}`}
          rel="noopener noreferrer"
          target="_blank"
          title={phoneNumber}
        >
          <span aria-label="phone" className="bff-phone icon" />
          <span className="person-info">{phoneNumber}</span>
        </a>
      ) : (
        ""
      )}
      {twitter ? (
        <a
          className="noline"
          href={twitterUrl}
          rel="noopener noreferrer"
          target="_blank"
          title={`Twitter: @${twitter}`}
        >
          <span aria-label="Twitter" className="bff-twitter-icon icon" />
          <span className="person-info">{twitter}</span>
        </a>
      ) : (
        ""
      )}

      {linkedin ? (
        <a
          className="noline"
          href={linkedin}
          rel="noopener noreferrer"
          target="_blank"
          title="LinkedIn"
        >
          <span aria-label="LinkedIn" className="bff-linked-in icon" />
          <span>{linkedin}</span>
        </a>
      ) : (
        ""
      )}
    </div>
    <hr className="s-people-line-break" />
    {bio ? (
      <div className="m-show-description s-asset-description">
        <span className="s-asset-details-title modal-label">
          <Trans>Description</Trans>
        </span>
        {bio}
      </div>
    ) : (
      ""
    )}
  </div>
);

PersonDetails.propTypes = {
  bio: PropTypes.string,
  email: PropTypes.string,
  linkedin: PropTypes.string,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  title: PropTypes.string,
  twitter: PropTypes.string,
  twitterUrl: PropTypes.string
};

export default PersonDetails;
