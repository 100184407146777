import { t } from '@lingui/macro';
import React, { useState, useEffect, useContext } from 'react';

import { AssetTypes } from '@api/v4/assets/assetTypes';
import { TaskClient } from '@api/v4/tasks';

import assetModalContext from '../asset_modal_context';
import { AssetDetailsDispatch, FlattenedAttachment } from '../EditTabTypes';

import AdvancedSection from './advanced/AdvancedSection';
import { CustomFieldsList } from './custom_fields/CustomFieldsList';
import DetailsSection, { initializeTrumbowyg } from './detailsSection';
import SectionBar, { SectionDetails } from './sectionBar';
import './styles/_main.scss';
import TemplatingSection from './templating/TemplatingSection';
import { WorkspaceDetailsSection } from './WorkspaceDetailsSection';

export const ASSET_MODAL_CUSTOM_FIELDS_TAB = 'section-bar-custom-fields';

interface GenericFileEditFormProps {
  includeAdvancedOptions?: boolean;
  isCreate?: boolean;
  isTask?: boolean;
  orgApiKey?: string;
}

interface GenericFileEditFormScopedContext {
  state: {
    initialData: {
      assetKey: string;
      assetType: AssetTypes;
    };
    editState: {
      aiGeneratedCaptionId: string | null;
      assetDescription: string;
      attachments: FlattenedAttachment[];
      task: TaskClient | null;
    };
  };
  dispatch: AssetDetailsDispatch;
}

const GenericFileEditForm: React.FunctionComponent<GenericFileEditFormProps> = (props): JSX.Element => {
  const { includeAdvancedOptions, isCreate, isTask, orgApiKey } = props;

  const { state, dispatch }: GenericFileEditFormScopedContext = useContext(assetModalContext);
  const { assetDescription, attachments, task } = state.editState;

  const [textareaVisible, setTextareaVisible] = useState(true);
  const hasTemplateAttachmentKey = (): string => (
    attachments?.find((attachment: FlattenedAttachment) => attachment.extension === 'bftemplate')?.key || ''
  );

  const sections: SectionDetails[] = [];

  const isWorkspace = BFG.hasFeature('workspace') && BFG.resource.is_workspace;
  const isCreatingTask = isTask && isCreate;
  const isEditingTask = isTask && !isCreate && !!task && task.workspaceKey === BFG.resource.key;
  const hasTask = isWorkspace && (isCreatingTask || isEditingTask);

  if (hasTask) {
    sections.push({
      tabClassName: 'section-bar-task-details',
      displayName: t`Task Details`,
      content: <WorkspaceDetailsSection />,
    });
  }

  if (!isCreatingTask) {
    sections.push({
      tabClassName: 'section-bar-asset-details',
      displayName: t`Asset Details`,
      content: <DetailsSection orgApiKey={orgApiKey} />,
      onActiveSection: (): void => setTextareaVisible(true)
    });
  }

  if (BFG.hasFeature('custom_fields')) {
    sections.push({
      tabClassName: ASSET_MODAL_CUSTOM_FIELDS_TAB,
      displayName: t`Custom Fields`,
      content: <CustomFieldsList />,
      onActiveSection: (): void => setTextareaVisible(false)
    });
  }

  if (state.initialData.assetType !== AssetTypes.Text) {
    /**
     * includeAdvancedOptions indicates whether anything more than
     * the custom thumbnail override setting should be displayed
     * in the advanced tab
     *
     * every asset type _except_ text assets should have the custom
     * thumbnail override setting available but text assets don't use
     * thumbnail in their card/overview displays so we shouldn't show
     * that setting. That would be the only setting in the advanced
     * tab for text assets so we just shouldn't show the advanced
     * tab for text assets at all
     */
    sections.push({
      tabClassName: 'section-bar-advanced',
      displayName: t`Advanced`,
      content: <AdvancedSection includeAdvancedOptions={includeAdvancedOptions} isTask={isTask} />,
      onActiveSection: (): void => setTextareaVisible(false)
    });
  }

  const templateAttachmentKey = hasTemplateAttachmentKey();
  if (templateAttachmentKey) {
    sections.push({
      displayName: t`Templating`,
      content: <TemplatingSection attachmentKey={templateAttachmentKey} />,
      onActiveSection: (): void => setTextareaVisible(false),
      tabClassName: 'templating',
    });
  }

  useEffect(() => {
    if (textareaVisible) {
      initializeTrumbowyg(assetDescription, dispatch);
    } else {
      // if not on details tab, kill trumbowyg
      ($('#asset-description-textarea') as TrumbowygJquery).trumbowyg('destroy');
    }
  }, [textareaVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="edit-tab-main-pane two-thirds bf-scroll-element">
      <SectionBar sections={sections} />
    </div>
  );
};

export default GenericFileEditForm;
