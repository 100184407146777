import { CircleLoader, HelpText } from '@brandfolder/react';
import { plural, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { fetchJson } from '@api/ApiHelper';
import * as RowPage from '@components/common/shroud/row_page';
import { ListDropdown } from '@components/library/dropdown';

import CustomFieldValueRow from './custom_field_value_row';

import './styles/main.scss';

class CustomFieldsValueManagement extends React.Component {
  state = {
    customFieldKey: null,
    customFieldValues: null,
    loading: true,
    visibleValues: null,
    queryString: null,
    sortBy: 'name',
    pageSize: 0
  }

  componentDidMount() {
    fetchJson({
      url: `/api/v4/private/custom_field_keys/${this.props.customFieldKeyKey}/custom_field_value_counts`,
      params: {
        'ugt_locale': this.props.ugtLocale
      }
    }).then((response) => {
      const { custom_field_key: customFieldKey, custom_field_values: customFieldValues } = response;
      this.setState({
        customFieldKey,
        customFieldValues,
        visibleValues: customFieldValues,
        pageSize: this.getPageSize(customFieldValues),
        loading: false
      });
    });
  }

  getPageSize(updatedValues) {
    return RowPage.getPageSize(Object.keys(updatedValues || {}).length);
  }

  updateSearch = (event) => {
    this.setState({ queryString: event.target.value });
  }

  handleSorting = (selection) => {
    this.setState({ sortBy: selection.value });
  }

  updateValues = (oldValue, newValue) => {
    const body = {
      data: {
        management_action: newValue ? 'update' : 'delete',
        brandfolder_key: BF.fx.brandfolder().key,
        custom_field_key: this.props.customFieldKeyKey,
        old_value: oldValue,
        new_value: newValue,
        bypass_controlled_custom_fields: true
      }
    };

    fetchJson({
      url: '/api/v4/bulk_actions/assets/manage_custom_fields',
      params: {
        ugt_locale: this.props.ugtLocale
      },
      body,
      method: 'PUT'
    }).then(() => {
      this.updateVisibleValues(oldValue, newValue);
    });
  }

  updateVisibleValues(oldValue, newValue) {
    const { visibleValues } = this.state;
    let updatedValues;
    if (newValue) {
      updatedValues = Object.assign(visibleValues, { [newValue]: visibleValues[oldValue] });
    } else {
      updatedValues = Object.assign({}, visibleValues);
    }
    delete updatedValues[oldValue];
    const pageSize = this.getPageSize(updatedValues);
    this.setState({ visibleValues: updatedValues, pageSize });
  }

  queriedValues() {
    const { visibleValues, queryString } = this.state;
    return Object.keys(visibleValues).filter((value) => (
      value.toLowerCase().includes(queryString.toLowerCase())));
  }

  sortedByValue() {
    const { visibleValues, queryString } = this.state;
    const valuesToSort = queryString ? this.queriedValues() : Object.keys(visibleValues);
    return valuesToSort.sort((a, b) => ((a.toLowerCase() < b.toLowerCase()) ? -1 : 1));
  }

  sortedByCount() {
    const { visibleValues, queryString } = this.state;
    const valuesToSort = queryString ? this.queriedValues() : Object.keys(visibleValues);
    return valuesToSort.sort((a, b) => ((visibleValues[a] < visibleValues[b]) ? 1 : -1));
  }

  sortedValues() {
    const { sortBy } = this.state;
    if (sortBy === "count") {
      return this.sortedByCount();
    }
    return this.sortedByValue();
  }

  renderSortingOptions() {
    if (this.state.loading) {
      return null;
    }

    const customFieldValuesCount = Object.keys(this.state.customFieldValues).length;

    return (
      <div className="sorting-options">
        <input
          className="bf-input searchbar"
          id="custom-field-values-search"
          onChange={this.updateSearch}
          placeholder={plural(customFieldValuesCount, {
            one:'Search one custom field value',
            other: `Search ${customFieldValuesCount} custom field values`
          })}
          type="text"
        />
        <div className="sort-options">
          <div><p>{t`Sort by`}:</p></div>
          <ListDropdown
            className="custom-field-key-sorting"
            closeOnSelect
            isSearchable={false}
            onChange={this.handleSorting}
            options={[
              { value: 'name', label: t`Name` },
              { value: 'count', label: t`Count` }
            ]}
            placeholder={t`Name`}
            value={this.state.sortBy}
            virtualizeOptions={false}
          />
        </div>
      </div>
    );
  }

  renderNoCustomFields() {
    const { customFieldKeyName } = this.props;
    return (
      <div className="manage-custom-fields">
        {t`${customFieldKeyName} does not have any custom field values.`}
      </div>
    );
  }

  renderRows(pageIndex) {
    const pageKeys = RowPage.getPageSlice(pageIndex, this.state.pageSize, this.sortedValues());
    return pageKeys.map((value) => (
      <div key={value} className="custom-field-value-row">
        <CustomFieldValueRow
          count={this.state.customFieldValues[value]}
          customFieldKey={this.state.customFieldKey}
          ugtLocale={this.props.ugtLocale}
          updateValues={this.updateValues}
          value={value}
        />
      </div>
    ));
  }

  renderPages() {
    const { pageSize } = this.state;
    const renderedPages = [];
    const numPages = RowPage.getNumPages(pageSize, this.sortedValues().length);
    for (let pageIndex = 0; pageIndex < numPages; pageIndex += 1) {
      renderedPages.push(
        <RowPage.RowPage
          key={`custom-field-values-row-page-${pageIndex}`}
          isInitialPage={pageIndex === 0}
          pageSize={pageSize}
          renderRows={() => this.renderRows(pageIndex)}
        />
      );
    }
    return renderedPages;
  }

  render() {
    const { customFieldKeyName, setSelectedCustomFieldKey } = this.props;
    if (!this.state.loading && this.state.customFieldValues?.length < 1) {
      return this.renderNoCustomFields();
    }
    return (
      <div className="manage-custom-field-values">
        <h2>{t`Custom Field Values for ${customFieldKeyName}`}</h2>
        {BFG.brandfolderSettings?.controlled_custom_fields_enabled && (
          <HelpText>
            {t`Controlled custom field values may only be renamed to other values in the list of allowed values defined for that custom field.`}
          </HelpText>
        )}
        <hr />
        <span className="redirect" onClick={() => setSelectedCustomFieldKey(undefined)}>
          {t`Custom Fields`}
        </span>
        <span className="selected-key">
          {` / ${customFieldKeyName}`}
        </span>
        {this.renderSortingOptions()}
        <div className="custom-fields-list">
          <div className="headers">
            <div className="headers-container">
              <h3 className="quarter-width">{t`Key`}</h3>
              <h3 className="twenty-width">{t`Values`}</h3>
              <h3 className="twenty-width"># {t`Assets`}</h3>
            </div>
          </div>
          { this.state.loading ? (
            <CircleLoader
              className="manage-custom-fields__page-loader"
              label={t`Loading custom field values`}
            />
          ) : this.renderPages() }
        </div>
      </div>
    );
  }
}

CustomFieldsValueManagement.propTypes = {
  customFieldKeyKey: PropTypes.string.isRequired,
  customFieldKeyName: PropTypes.string.isRequired,
  setSelectedCustomFieldKey: PropTypes.func.isRequired,
  ugtLocale: PropTypes.string.isRequired
};

export default CustomFieldsValueManagement;
