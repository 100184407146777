import { FontIcon, FontIcons, OnlyForScreenReader, StandardTooltip } from '@brandfolder/react';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import classes from './styles/text-sanitization-tooltip.module.scss';
import { defaultKnowledgeBaseLinks, gettyKnowledgeBaseLinks } from '@components/kb-article-links';
import { isGettyClient } from '@helpers/getty-strings';

interface TextSanitizationTooltipProps {
  margin?: boolean;
}

let knowledgeBaseUrl;

if (isGettyClient()) {
  knowledgeBaseUrl = gettyKnowledgeBaseLinks.textSanitizationLink
} else {
  knowledgeBaseUrl = defaultKnowledgeBaseLinks.textSanitizationLink
};


export const TextSanitizationTooltip: FunctionComponent<TextSanitizationTooltipProps> = ({
  margin = false
}) => {
  return (
    <I18nProviderWrapper>
      <StandardTooltip
        id="text-sanitization-tooltip"
        tooltip={t`Some links are not permitted for security reasons. Click to learn more.`}
        triggerOffset={10}
        wrapperProps={{
          className: classes.textSanitizationTooltipWrapper
        }}
      >
        <a
          className={classNames({
            [`${classes.textSanitizationTooltipLink}`]: true,
            [`${classes.textSanitizationTooltipLinkMargin}`]: !!margin,
          })}
          href={knowledgeBaseUrl}  
          rel="noreferrer"
          target="_blank"
        >
          <FontIcon icon={FontIcons.ToolTip} />
          <OnlyForScreenReader>{t`Description will be sanitized.`}</OnlyForScreenReader>
        </a>
      </StandardTooltip>
    </I18nProviderWrapper>
  );
};
