import {
  ButtonLooks,
  Colors,
  FontIcons,
  IconButton,
  StandardTooltip,
  brandfolderColorPalette,
} from '@brandfolder/react';
import { Trans, t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ToastLooks, useCreateToast } from '@components/common/toast';

import { updateAiCaptionInGCS } from './fetch';
import { Rating } from './generateCaptionTypes';

const CaptionFeedbackControlsWrapper = styled.div`
  align-items: center;
  display: flex;

  .caption-rating {
    &-text {
      margin-right: 16px;
    }
  
    &-wrapper {
      margin-right: 12px;
    }
  
    &-button {
      border-color: ${brandfolderColorPalette.neutrals[300]};
      height: 36px;
      min-height: unset;
      padding: 10px;
      width: 36px;
  
      &:hover {
        background-color: ${brandfolderColorPalette.neutrals[100]};
        color: ${brandfolderColorPalette.neutrals[700]};
      }
  
      &.selected {
        background-color: ${brandfolderColorPalette.primary[700]};
        border-color: ${brandfolderColorPalette.primary[700]};
        color: ${brandfolderColorPalette.neutrals[0]};
      }
  
      &:active {
        background-color: ${brandfolderColorPalette.neutrals[200]};
        border-color: ${brandfolderColorPalette.neutrals[300]};
        color: ${brandfolderColorPalette.neutrals[700]};
      } 
    }
  }
`;

interface CaptionFeedbackControlsProps {
  captionId: string;
  orgApiKey: string;
}

export const CaptionFeedbackControls: FunctionComponent<CaptionFeedbackControlsProps> = ({
  captionId,
  orgApiKey,
}) => {
  const [rating, setRating] = useState<Rating>(undefined);
  const { toast } = useCreateToast();

  useEffect(() => {
    if (rating) {
      toast(
        t`Thanks for your feedback`,
        ToastLooks.Success
      );
      updateAiCaptionInGCS(orgApiKey, captionId, rating);
    }
  }, [rating, orgApiKey, captionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CaptionFeedbackControlsWrapper>
        <span className="caption-rating-text">
          <Trans>How is this description?</Trans>
        </span>
        <StandardTooltip
          color={Colors.None}
          id="like"
          openOnFocus={false}
          tooltip={t`It's good`}
          wrapperProps={{ className: 'caption-rating-wrapper' }}
        >
          <IconButton
            className={`caption-rating-button ${rating === Rating.Positive && 'selected'}`}
            icon={FontIcons.Approve}
            label="like"
            look={ButtonLooks.Tertiary}
            onClick={(): void => setRating(Rating.Positive)}
            value="like"
          />
        </StandardTooltip>
        <StandardTooltip
          color={Colors.None}
          id="dislike"
          openOnFocus={false}
          tooltip={t`I don't like it`}
          wrapperProps={{ className: 'caption-rating-wrapper' }}
        >
          <IconButton
            className={`caption-rating-button ${rating === Rating.Negative && 'selected'}`}
            icon={FontIcons.Unapprove}
            label="dislike"
            look={ButtonLooks.Tertiary}
            onClick={(): void => {
              setRating(Rating.Negative);
            }}
            value="dislike"
          />
        </StandardTooltip>
      </CaptionFeedbackControlsWrapper>
    </>
  );
};
