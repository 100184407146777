import { FontIcons } from '@brandfolder/react';
import { t } from '@lingui/macro';
import { boolean, mixed, number, object, SchemaOf, string } from 'yup';

import {
  DesignHuddleDimensionsClient,
  DesignHuddleLimits,
  DesignHuddleMediaTypes,
  DesignHuddleUnitTypes
} from '@api/v4/private/resources/design_huddles';
import { ListOption } from '@components/library/dropdown';
import {
  AllCanvasSizes,
  CanvasSize,
  CanvasSizes,
  CreateNewAssetTypes,
  CreateProjectTypes,
  CreateProjectValues,
  SubmittingMessages
} from '@components/show_page/sections/asset/custom-templates';

/**
 * Get initial values for CreateProject
 * @param assetType CreateNewAssetTypes
 * @returns CreateProjectValues
 */
export const getInitialValues = (assetType: CreateNewAssetTypes): CreateProjectValues => {
  if (assetType === CreateNewAssetTypes.PrintDesignProject) {
    return {
      backgroundImageUrl: '',
      canvasSize: CanvasSizes.Flyers,
      dimensions: null,
      filename: '',
      mediaType: DesignHuddleMediaTypes.Print,
      projectTitle: '',
      projectType: null,
      sizeToBackgroundImage: true,
      templateId: null
    };
  }
  return {
    backgroundImageUrl: '',
    canvasSize: CanvasSizes.Facebook,
    dimensions: null,
    filename: '',
    mediaType: DesignHuddleMediaTypes.Digital,
    projectTitle: '',
    projectType: null,
    sizeToBackgroundImage: true,
    templateId: null
  };
};

/**
 * Get canvas sizes for CreateProject
 * @param assetType CreateNewAssetTypes
 * @returns AllCanvasSizes
 */
export const getCanvasSizes = (assetType: CreateNewAssetTypes): AllCanvasSizes => {
  if (assetType === CreateNewAssetTypes.PrintDesignProject) {
    const flyerCanvasSizes: CanvasSize[] = [
      {
        heading: t`Letter Portrait`,
        height: 11,
        id: 'flyer-letter-portrait',
        width: 8.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Letter Landscape`,
        height: 8.5,
        id: 'flyer-letter-landscape',
        width: 11,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Half Letter Portrait`,
        height: 8.5,
        id: 'flyer-half-letter-portrait',
        width: 5.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Half Letter Landscape`,
        height: 5.5,
        id: 'flyer-half-letter-landscape',
        width: 8.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Legal Portrait`,
        height: 14,
        id: 'flyer-legal-portrait',
        width: 8.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Legal Landscape`,
        height: 8.5,
        id: 'flyer-legal-landscape',
        width: 14,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Tabloid Portrait`,
        height: 17,
        id: 'flyer-tabloid-portrait',
        width: 11,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Tabloid Landscape`,
        height: 11,
        id: 'flyer-tabloid-landscape',
        width: 17,
        unitTypeId: DesignHuddleUnitTypes.Inches
      }
    ];

    const envelopeCanvasSizes: CanvasSize[] = [
      {
        heading: t`No. 6¼ Envelope`,
        height: 3.5,
        id: 'envelope-no6-quarter',
        width: 6.0,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 6¾ Envelope`,
        height: 3.625,
        id: 'envelope-no6-three-quarters',
        width: 6.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 7 Envelope`,
        height: 3.75,
        id: 'envelope-no7',
        width: 6.75,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 7¾ Envelope`,
        height: 3.875,
        id: 'envelope-no7-three-quarters',
        width: 7.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 8⅝ Envelope`,
        height: 3.625,
        id: 'envelope-no8-five-eighths',
        width: 8.625,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 9 Envelope`,
        height: 3.875,
        id: 'envelope-no9',
        width: 8.875,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 10 Envelope`,
        height: 4.125,
        id: 'envelope-no10',
        width: 9.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 11 Envelope`,
        height: 4.5,
        id: 'envelope-no11',
        width: 10.375,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 12 Envelope`,
        height: 4.75,
        id: 'envelope-no12',
        width: 11,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 14 Envelope`,
        height: 5,
        id: 'envelope-no14',
        width: 11.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`No. 16 Envelope`,
        height: 6,
        id: 'envelope-no16',
        width: 12,
        unitTypeId: DesignHuddleUnitTypes.Inches
      }
    ];

    const postcardCanvasSizes: CanvasSize[] = [
      {
        heading: t`Postcard Minimum Landscape`,
        height: 3.5,
        id: 'postcard-minimum-landscape',
        width: 5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Postcard Minimum Portrait`,
        height: 5,
        id: 'postcard-minimum-portrait',
        width: 3.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Postcard Maximum Landscape`,
        height: 4.25,
        id: 'postcard-maximum-landscape',
        width: 6,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Postcard Maximum Portrait`,
        height: 6,
        id: 'postcard-maximum-portrait',
        width: 4.25,
        unitTypeId: DesignHuddleUnitTypes.Inches
      }
    ];

    const businessCardCanvasSizes: CanvasSize[] = [
      {
        heading: t`Business Card Landscape`,
        height: 2,
        id: 'business-card-landscape',
        width: 3.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Business Card Portrait`,
        height: 3.5,
        id: 'business-card-portrait',
        width: 2,
        unitTypeId: DesignHuddleUnitTypes.Inches
      },
      {
        heading: t`Business Card Square`,
        height: 2.5,
        id: 'business-card-square',
        width: 2.5,
        unitTypeId: DesignHuddleUnitTypes.Inches
      }
    ];

    return {
      [CanvasSizes.Flyers]: flyerCanvasSizes,
      [CanvasSizes.Envelopes]: envelopeCanvasSizes,
      [CanvasSizes.Postcards]: postcardCanvasSizes,
      [CanvasSizes.BusinessCards]: businessCardCanvasSizes,
      [CanvasSizes.Custom]: []
    };
  }

  const facebookCanvasSizes: CanvasSize[] = [
    {
      heading: t`Profile Photo`,
      height: 180,
      icon: FontIcons.FacebookIcon,
      id: 'facebook-profile-photo',
      width: 180,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Landscape Post`,
      height: 630,
      icon: FontIcons.FacebookIcon,
      id: 'facebook-landscape-post',
      width: 1200,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Portrait Post`,
      height: 1200,
      icon: FontIcons.FacebookIcon,
      id: 'facebook-portrait-post',
      width: 630,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Square Post`,
      height: 1200,
      icon: FontIcons.FacebookIcon,
      id: 'facebook-square-post',
      width: 1200,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Story`,
      height: 1920,
      icon: FontIcons.FacebookIcon,
      id: 'facebook-story',
      width: 1080,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Profile Cover Photo`,
      height: 312,
      icon: FontIcons.FacebookIcon,
      id: 'facebook-profile-cover-photo',
      width: 820,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Event Cover Photo`,
      height: 1005,
      icon: FontIcons.FacebookIcon,
      id: 'facebook-event-cover-photo',
      width: 1920,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    }
  ];

  const instagramCavasSizes: CanvasSize[] = [
    {
      heading: t`Profile Photo`,
      height: 110,
      icon: FontIcons.Instagram,
      id: 'instagram-profile-photo',
      width: 110,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Landscape Post`,
      height: 566,
      icon: FontIcons.Instagram,
      id: 'instagram-landscape-post',
      width: 1080,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Portrait Post`,
      height: 1350,
      icon: FontIcons.Instagram,
      id: 'instagram-portrait-post',
      width: 1080,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Square Post`,
      height: 1080,
      icon: FontIcons.Instagram,
      id: 'instagram-square-post',
      width: 1080,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Story`,
      height: 1920,
      icon: FontIcons.Instagram,
      id: 'instagram-story',
      width: 1080,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    }
  ];

  const linkedinCavasSizes: CanvasSize[] = [
    {
      heading: t`Profile Photo`,
      height: 300,
      icon: FontIcons.LinkedIn,
      id: 'linkedin-profile-photo',
      width: 300,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Landscape Post`,
      height: 627,
      icon: FontIcons.LinkedIn,
      id: 'linkedin-landscape-post',
      width: 1200,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Portait Post`,
      height: 1200,
      icon: FontIcons.LinkedIn,
      id: 'linkedin-portrait-post',
      width: 627,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Story`,
      height: 1920,
      icon: FontIcons.LinkedIn,
      id: 'linkedin-story',
      width: 1080,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Cover Photo`,
      height: 396,
      icon: FontIcons.LinkedIn,
      id: 'linkedin-cover-photo',
      width: 1584,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Company Logo`,
      height: 300,
      icon: FontIcons.LinkedIn,
      id: 'linkedin-company-logo',
      width: 300,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Company Cover Photo`,
      height: 191,
      icon: FontIcons.LinkedIn,
      id: 'linkedin-company-cover-photo',
      width: 1128,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    }
  ];

  const pinterestCavasSizes: CanvasSize[] = [
    {
      heading: t`Profile Photo`,
      height: 165,
      icon: FontIcons.Pinterest,
      id: 'pinterest-profile-photo',
      width: 165,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Portrait Post`,
      height: 900,
      icon: FontIcons.Pinterest,
      id: 'pinterest-portrait-post',
      width: 600,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Square Post`,
      height: 600,
      icon: FontIcons.Pinterest,
      id: 'pinterest-square-post',
      width: 600,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    }
  ];

  const twitterCavasSizes: CanvasSize[] = [
    {
      heading: t`Profile Photo`,
      height: 400,
      icon: FontIcons.TwitterIcon,
      id: 'twitter-profile-photo',
      width: 400,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Landscape Post`,
      height: 1200,
      icon: FontIcons.TwitterIcon,
      id: 'twitter-landscape-post',
      width: 675,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Story`,
      height: 1920,
      icon: FontIcons.TwitterIcon,
      id: 'twitter-story',
      width: 1080,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Cover Photo`,
      height: 500,
      icon: FontIcons.TwitterIcon,
      id: 'twitter-cover-photo',
      width: 1500,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    }
  ];

  const youtubeCavasSizes: CanvasSize[] = [
    {
      heading: t`Channel Profile Photo`,
      height: 800,
      icon: FontIcons.YouTubeIcon,
      id: 'youtube-profile-photo',
      width: 800,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    },
    {
      heading: t`Channel Cover Photo`,
      height: 1440,
      icon: FontIcons.YouTubeIcon,
      id: 'youtube-cover-photo',
      width: 2560,
      unitTypeId: DesignHuddleUnitTypes.Pixels
    }
  ];

  return {
    [CanvasSizes.Facebook]: facebookCanvasSizes,
    [CanvasSizes.Instagram]: instagramCavasSizes,
    [CanvasSizes.LinkedIn]: linkedinCavasSizes,
    [CanvasSizes.Pinterest]: pinterestCavasSizes,
    [CanvasSizes.Twitter]: twitterCavasSizes,
    [CanvasSizes.YouTube]: youtubeCavasSizes,
    [CanvasSizes.Custom]: []
  };
};

/**
 * Get a Formik/Yup validation schema for CreateProject
 * @param assetType CreateNewAssetTypes
 * @returns SchemaOf<CreateProjectValues>
 */
export const getValidationSchema = (assetType: CreateNewAssetTypes): SchemaOf<CreateProjectValues> => {
  const errorMessages: Record<string, string> = {
    backgroundImageUrlRequired: t`Image is required`,
    canvasSizeOneOf: t`Canvas size not valid`,
    canvasSizeRequired: t`Canvas size is required`,
    dimensionsHeightRequired: t`Height is required`,
    dimensionsMaxInches: t`Maximum is ${DesignHuddleLimits.Inches}`,
    dimensionsMaxMillimeters: t`Maximum is ${DesignHuddleLimits.Millimeters}`,
    dimensionsMaxPixels: t`Maximum is ${DesignHuddleLimits.Pixels}`,
    dimensionsMinimum: t`Minimum is ${DesignHuddleLimits.Minimum}`,
    dimensionsRequired: t`Canvas size is required`,
    dimensionsWidthRequired: t`Width is required`,
    dimensionsUnitTypeOneOf: t`Unit type not valid`,
    dimensionsUnitTypeRequired: t`Unit type is required`,
    mediaTypeOneOf: t`Media type not valid`,
    mediaTypeRequired: t`Media type is required`,
    nameRequired: t`Project name is required`,
    nameTooShort: t`Minimum 1 character`,
    nameTooLong: t`Maximum 50 characters`,
    templateIdRequired: t`Template is required`
  };

  const dimensionsSchema: SchemaOf<DesignHuddleDimensionsClient> = object()
    .shape({
      height: number()
        .when('unitTypeId', {
          is: DesignHuddleUnitTypes.Inches,
          then: number()
            .max(DesignHuddleLimits.Inches, errorMessages.dimensionsMaxInches)
            .min(DesignHuddleLimits.Minimum, errorMessages.dimensionsMinimum)
            .required(errorMessages.dimensionsHeightRequired)
        })
        .when('unitTypeId', {
          is: DesignHuddleUnitTypes.Millimeters,
          then: number()
            .max(DesignHuddleLimits.Millimeters, errorMessages.dimensionsMaxMillimeters)
            .min(DesignHuddleLimits.Minimum, errorMessages.dimensionsMinimum)
            .required(errorMessages.dimensionsHeightRequired)
        })
        .when('unitTypeId', {
          is: DesignHuddleUnitTypes.Pixels,
          then: number()
            .max(DesignHuddleLimits.Pixels, errorMessages.dimensionsMaxPixels)
            .min(DesignHuddleLimits.Minimum, errorMessages.dimensionsMinimum)
            .required(errorMessages.dimensionsHeightRequired)
        }),
      id: string().required(),
      width: number()
        .when('unitTypeId', {
          is: DesignHuddleUnitTypes.Inches,
          then: number()
            .max(DesignHuddleLimits.Inches, errorMessages.dimensionsMaxInches)
            .min(DesignHuddleLimits.Minimum, errorMessages.dimensionsMinimum)
            .required(errorMessages.dimensionsWidthRequired)
        })
        .when('unitTypeId', {
          is: DesignHuddleUnitTypes.Millimeters,
          then: number()
            .max(DesignHuddleLimits.Millimeters, errorMessages.dimensionsMaxMillimeters)
            .min(DesignHuddleLimits.Minimum, errorMessages.dimensionsMinimum)
            .required(errorMessages.dimensionsWidthRequired)
        })
        .when('unitTypeId', {
          is: DesignHuddleUnitTypes.Pixels,
          then: number()
            .max(DesignHuddleLimits.Pixels, errorMessages.dimensionsMaxPixels)
            .min(DesignHuddleLimits.Minimum, errorMessages.dimensionsMinimum)
            .required(errorMessages.dimensionsWidthRequired)
        }),
      unitTypeId: mixed()
        .oneOf([
          DesignHuddleUnitTypes.Inches,
          DesignHuddleUnitTypes.Pixels,
          DesignHuddleUnitTypes.Millimeters
        ], errorMessages.dimensionsUnitTypeOneOf)
        .required(errorMessages.dimensionsUnitTypeRequired)
    }).nullable();

  const canvasSizeOneOf = assetType === CreateNewAssetTypes.PrintDesignProject
    ? [
      CanvasSizes.BusinessCards,
      CanvasSizes.Custom,
      CanvasSizes.Flyers,
      CanvasSizes.Envelopes,
      CanvasSizes.Postcards
    ]
    : [
      CanvasSizes.Custom,
      CanvasSizes.Facebook,
      CanvasSizes.Instagram,
      CanvasSizes.LinkedIn,
      CanvasSizes.Pinterest,
      CanvasSizes.Twitter,
      CanvasSizes.YouTube
    ];

  const validationSchema: SchemaOf<CreateProjectValues> = object().shape({
    backgroundImageUrl: string()
      .when('projectType', {
        is: CreateProjectTypes.UploadAnImage,
        then: string()
          .required(errorMessages.backgroundImageUrlRequired)
      }),
    canvasSize: mixed() // for display only
      .oneOf(canvasSizeOneOf, errorMessages.canvasSizeOneOf)
      .required(errorMessages.canvasSizeRequired),
    dimensions: dimensionsSchema.when('projectType', {
      is: CreateProjectTypes.ChooseCanvasSize,
      then: dimensionsSchema.required(errorMessages.dimensionsRequired)
    }),
    filename: string(), // for display only
    mediaType: mixed()
      .oneOf([
        DesignHuddleMediaTypes.Digital,
        DesignHuddleMediaTypes.Print
      ], errorMessages.mediaTypeOneOf)
      .required(errorMessages.mediaTypeRequired),
    projectTitle: string()
      .min(1, errorMessages.nameTooShort)
      .max(50, errorMessages.nameTooLong)
      .required(errorMessages.nameRequired),
    projectType: mixed() // for display only
      .oneOf([
        CreateProjectTypes.ChooseCanvasSize,
        CreateProjectTypes.CreateFromExistingTemplate,
        CreateProjectTypes.UploadAnImage
      ])
      .required().nullable(),
    sizeToBackgroundImage: boolean()
      .when('projectType', {
        is: CreateProjectTypes.UploadAnImage,
        then: boolean()
          .required()
      }),
    templateId: number()
      .when('projectType', {
        is: CreateProjectTypes.CreateFromExistingTemplate,
        then: number()
          .required(errorMessages.templateIdRequired)
          .nullable()
      })
      .nullable()
  });

  return validationSchema;
};

/**
 * Get custom dimensions
 * @param assetType CreateNewAssetTypes
 * @returns DesignHuddleDimensionsClient
 */
export const getCustomDimensions = (assetType: CreateNewAssetTypes): DesignHuddleDimensionsClient => {
  if (assetType === CreateNewAssetTypes.PrintDesignProject) {
    return {
      height: 11,
      id: 'custom-print',
      width: 8.5,
      unitTypeId: DesignHuddleUnitTypes.Inches
    };
  }

  return {
    height: 50,
    id: 'custom-digital',
    width: 50,
    unitTypeId: DesignHuddleUnitTypes.Pixels
  };
};

/**
 *
 * @param assetType CreateNewAssetTypes
 * @returns ListOption[]
 */
export const getCustomDimensionsUnitTypes = (assetType: CreateNewAssetTypes): ListOption[] => {
  if (assetType === CreateNewAssetTypes.PrintDesignProject) {
    return [
      {
        label: DesignHuddleUnitTypes.Inches,
        value: DesignHuddleUnitTypes.Inches
      },
      {
        label: DesignHuddleUnitTypes.Millimeters,
        value: DesignHuddleUnitTypes.Millimeters
      }
    ];
  }
  return [
    {
      label: DesignHuddleUnitTypes.Pixels,
      value: DesignHuddleUnitTypes.Pixels
    }
  ];
};

/**
 * Get canvas size translations
 * @example BusinessCards => Business Cards
 * @param canvasSize CanvasSizes
 * @returns string
 */
export const getCanvasSizeTranslation = (canvasSize: CanvasSizes): string => {
  switch (canvasSize) {
    case CanvasSizes.BusinessCards:
      return t`Business Cards`;
    case CanvasSizes.Custom:
      return t`Custom`;
    case CanvasSizes.Envelopes:
      return t`Envelopes`;
    case CanvasSizes.Flyers:
      return t`Flyers`;
    case CanvasSizes.Postcards:
      return t`Postcards`;
    default:
      // product names like YouTube, which don't get translated
      return canvasSize;
  }
};
/**
 * Get submitting message translations
 * @example CreatingAsset => Saving Asset...
 * @param message SubmittingMessages
 * @returns string
 */
export const getSubmittingMessages = (message: SubmittingMessages): string => {
  switch (message) {
    case SubmittingMessages.CreatingAsset: return t`Saving asset...`;
    case SubmittingMessages.CreatingProject: return t`Saving project...`;
    case SubmittingMessages.Redirecting: return t`Redirecting...`;
  }
};
