import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { object, SchemaOf, string } from 'yup';

import { fetchJson, useFetch } from '@api/ApiHelper';
import { createAssets } from '@api/v4/assets/assets';
import { SectionsApiData, SectionApiResponseObject } from '@api/v4/brandfolders/SectionTypes';
import {
  DesignHuddleTemplateGetNewResponse,
  DesignHuddleTemplatePostResponse
} from '@api/v4/private/resources/design_huddles';
import { BFLoader } from '@components/common/loader/main';
import { getResourceUrl } from '@components/design_huddles/editors/helpers';
import { PrimaryButton, TextButton } from '@components/library/button';
import { DialogSizes, StandardDialog } from '@components/library/dialog';
import { ListOption, ListDropdown } from '@components/library/dropdown';
import { FontIcons } from '@components/library/icon';
import { StandardTextField } from '@components/library/text-field';

import './styles/save-template.scss';

interface SaveTemplateProps {
  projectId: string;
  sectionKey: string | null;
  setShowSaveTemplate: (open: boolean) => void;
  showSaveTemplate: boolean;
}

interface SaveTemplateValues {
  sectionId: string;
  templateTitle: string;
}

const validationSchema = (): SchemaOf<SaveTemplateValues> => object().shape({
  sectionId: string()
    .required(t`Section is required`),
  templateTitle: string()
    .min(1, t`Minimum 1 character`)
    .max(50, t`Maximum 50 characters`)
    .required(t`Name is required`)
});

export const SaveTemplate: FunctionComponent<SaveTemplateProps> = (props) => {
  const resourceKey = BFG.resource.key;
  const resourceType = BFG.resource.type;

  const { projectId, sectionKey, setShowSaveTemplate, showSaveTemplate } = props;

  const fetchSections = useFetch<SectionsApiData>({
    fetchOnMount: false,
    params: {
      limit: 3000,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      default_asset_type: 'GenericFile'
      // TODO: fix the /sections api for collections (order and sort_by break the api)
      // we need programmatically in the sections useEffect below
      // order: 'asc',
      // sort_by: 'name'
    },
    url: `/api/v4/${resourceType}s/${resourceKey}/sections`
  });

  const [options, setOptions] = useState<ListOption[]>([]);
  const [sections, setSections] = useState<SectionApiResponseObject[]>([]);
  const [submittingMessage, setSubmittingMessage] = useState('');

  useEffect(() => {
    if (showSaveTemplate) {
      fetchSections.fetch();
      window.onbeforeunload = undefined;
    }
  }, [showSaveTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchSections.response) {
      setSections(fetchSections.response.data);
    }
  }, [fetchSections.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sections.length > 0) {
      const sortedSections = sections.sort((a, b) => (a.attributes.name.toLowerCase() > b.attributes.name.toLowerCase() ? 1 : -1));
      setOptions(sortedSections.map((section) => ({
        label: section.attributes.name,
        value: section.id
      })));
    }
  }, [sections]); // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialSectionId = (): string => {
    const foundSectionKey = sections.find((section) => section.id === sectionKey);
    if (foundSectionKey) {
      return sectionKey;
    }
    return sections[0]?.id || '';
  };

  const onSubmit = async (values, formikHelpers): Promise<void> => {
    const { setSubmitting } = formikHelpers;

    try {
      const { sectionId, templateTitle } = values;

      setSubmittingMessage(t`Generating template...`);
      const createTemplateResponse = await fetchJson<DesignHuddleTemplatePostResponse>({
        body: {
          data: {
            project_id: projectId, // eslint-disable-line @typescript-eslint/naming-convention
            template_title: templateTitle // eslint-disable-line @typescript-eslint/naming-convention
          }
        },
        method: 'POST',
        url: `/api/v4/private/${resourceType}s/${resourceKey}/design_huddles/templates`
      });

      const templateId = createTemplateResponse.data.template_id;
      const templateNewResponse = await fetchJson<DesignHuddleTemplateGetNewResponse>({
        url: `/api/v4/private/${resourceType}s/${resourceKey}/design_huddles/templates/${templateId}/new`
      });

      setSubmittingMessage(t`Saving asset...`);
      await createAssets({
        url: `/api/v4/${resourceType}s/${resourceKey}/assets?fast_jsonapi=true`,
        headers: { 'X-Brandfolder-Change-Agent': 'Custom Templating Integration' },
        section_key: sectionId, // eslint-disable-line @typescript-eslint/naming-convention
        data: {
          attributes: [{
            name: templateNewResponse.data.relationships.assets.data.attributes.name,
            attachments: [
              {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                external_id: templateNewResponse.data.relationships.attachments.data.attributes.external_id,
                filename: templateNewResponse.data.relationships.attachments.data.attributes.filename,
                source: templateNewResponse.data.relationships.attachments.data.attributes.source,
                url: templateNewResponse.data.relationships.attachments.data.attributes.url,
              }
            ],
          }]
        }
      });

      setSubmittingMessage(t`Redirecting...`);
      window.location.assign(getResourceUrl());
    } catch (err) {
      Notify.create({
        title: t`There was an error generating your template. Please try again.`,
        type: 'error'
      });

      setSubmitting(false);
      setSubmittingMessage('');
    }
  };

  return (
    <StandardDialog
      height={530}
      id="save-template-dialog"
      open={showSaveTemplate}
      setOpen={setShowSaveTemplate}
      showFooter={false}
      size={DialogSizes.Small}
      title={t`Generate Template`}
      titleIcon={`bff-${FontIcons.Template}`}
    >
      <div>
        <TextButton
          className="save-template__back"
          icon={FontIcons.CaretLeft}
          id="save-template-back"
          onClick={(): void => setShowSaveTemplate(false)}
        >
          <Trans>Back to editor</Trans>
        </TextButton>
        {fetchSections.loading && (
          <div className="save-template__loader">
            <BFLoader />
          </div>
        )}
        {!fetchSections.loading && fetchSections.error && (
          <p className="save-template__error">
            <Trans>Error loading sections. Please refresh the page and try again.</Trans>
          </p>
        )}
        {!fetchSections.error && !fetchSections.loading && sections.length > 0 && (
          <Formik
            initialValues={{
              sectionId: getInitialSectionId(),
              templateTitle: ''
            } as SaveTemplateValues}
            onSubmit={onSubmit}
            validateOnChange
            validationSchema={validationSchema()}
          >
            {(formikBag): ReactNode => {
              const { errors, setFieldValue, touched, values } = formikBag;
              const { sectionId } = values;

              if (submittingMessage) {
                return (
                  <div className="save-template__loader">
                    <BFLoader />
                    <p>{submittingMessage}</p>
                  </div>
                );
              }

              return (
                <Form className="save-template__form">
                  <ListDropdown
                    className="save-template__section"
                    error={touched.sectionId && errors.sectionId}
                    id="template-section"
                    isLoading={fetchSections.loading}
                    label={<Trans>Select a section</Trans>}
                    onChange={(listOption): void => {
                      setFieldValue('sectionId', listOption.value as string, true);
                    }}
                    options={options}
                    value={sectionId}
                    virtualizeOptions={false}
                  />
                  <Field name="templateTitle">
                    {({ field }): ReactNode => (
                      <StandardTextField
                        {...field}
                        autoFocus
                        className="save-template__template-title"
                        error={touched.templateTitle && errors.templateTitle}
                        id="template-title"
                        label={t`Name your template`}
                        maxLength={50}
                        placeholder={t`Template name`}
                      />
                    )}
                  </Field>
                  <PrimaryButton
                    className="save-template__submit"
                    id="save-template-submit"
                    type="submit"
                  >
                    <Trans>Generate Template</Trans>
                  </PrimaryButton>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </StandardDialog>
  );
};
