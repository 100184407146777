import { Trans, Plural, t } from '@lingui/macro';
import { decode } from 'html-entities';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { UserDataResponse } from '@api/v4/brandfolders/UsersTypes';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { StandardAvatars } from '@components/library/avatars';
import { TertiaryButton, TertiaryIconButton } from '@components/library/button';
import { FontIcons } from '@components/library/icon';
import { AvatarUser } from '@components/library/utils';
import { WorkspaceEvents } from '@components/workspace/WorkspaceEnums';
import { WorkspaceProgressStatus } from '@components/workspace/WorkspaceProgressStatus';
import { WorkspaceSmartsheetConnect } from '@components/workspace/WorkspaceSmartsheetConnect';
import { DashbarUpdatedEvent } from '@components/workspace/WorkspaceTypes';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';
import { getUserDisplayName } from '@helpers/user';

import classes from './styles/workspace-dashbar.module.scss';

interface WorkspaceDashbarProps {
  adminableBy: boolean;
  orgApiKey?: string;
}

export const WorkspaceDashbar: FunctionComponent<WorkspaceDashbarProps> = (props) => {
  const { adminableBy, orgApiKey } = props;

  const { key, slug, type } = BFG.resource;

  const [refresh, setRefresh] = useState(false);
  const [users, setUsers] = useState<AvatarUser[]>([]);

  const usersFetch = useFetch<UserDataResponse>({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    params: { exclude_parent_resources: true },
    url: `/api/v4/collections/${BFG.resource.key}/users`
  });

  const handleDashbarUpdated = (e: DashbarUpdatedEvent): void => {
    if (e.detail?.refresh) {
      setRefresh(e.detail?.refresh);
    }
  };

  useEffect(() => {
    sendAction(TrackedAction.WorkspaceVisit);
    window.addEventListener(WorkspaceEvents.DashbarUpdated, handleDashbarUpdated);

    return (): void => {
      window.removeEventListener(WorkspaceEvents.DashbarUpdated, handleDashbarUpdated);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refresh) {
      usersFetch.fetch();
      setRefresh(false);
    }
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (usersFetch.response) {
      const avatarUsers: AvatarUser[] = usersFetch.response.data.map(({ attributes }) => ({
        email: attributes.email,
        name: getUserDisplayName(attributes)
      }));
      setUsers(avatarUsers);
    }
  }, [usersFetch.response]);

  useEffect(() => {
    if (usersFetch.error) {
      Notify.create({
        title: t`There was an error listing team members. Please try again.`,
        type: 'error'
      });
    }
  }, [usersFetch.error]);

  const addTeamMembersButton = (
    <TertiaryIconButton
      aria-label={t`Manage Users`}
      className={classes.settings}
      icon={FontIcons.UserSettings}
      iconSize={16}
      id="workspace-show-user-permissions"
      onClick={(): void => {
        // see showUserPermissionsModal function at
        // app/assets/javascripts/brandfolder/handlers/brandfolders_index_handlers.js
        BF.UserPermission.show(null, {
          initialResourceKey: key,
          initialResourceSlug: slug,
          initialResourceType: type,
          openNewTab: true
        });
      }}
    />
  );

  const adminView = (
    <div className={`${classes.column} workspace-dashbar__users-management`}>
      {users.length > 0 ? (
        <StandardAvatars
          after={(addTeamMembersButton)}
          showAfter={adminableBy}
          users={users}
        />
      ) : addTeamMembersButton}
      <p className={classes.p}>
        <Plural
          one="# team member"
          other="# team members"
          value={users.length}
        />
      </p>
    </div>
  );

  const nonAdminView = users.length > 0 && (
    <div className={`${classes.column} workspace-dashbar__users-management`}>
      <StandardAvatars
        after={(addTeamMembersButton)}
        showAfter={adminableBy}
        users={users}
      />
      <p className={classes.p}>
        <Plural
          one="# team member"
          other="# team members"
          value={users.length}
        />
      </p>
    </div>
  );

  return (
    <I18nProviderWrapper>
      <div className={`${classes.dashbar} workspace-dashbar`}>
        <div className={classes['dashbar__left-side']}>
          <WorkspaceProgressStatus />
          {adminableBy ? adminView : nonAdminView}
        </div>
        <div className={classes['dashbar__right-side']}>
          <WorkspaceSmartsheetConnect orgApiKey={orgApiKey} />
          {adminableBy && (
            <TertiaryButton
              icon={FontIcons.Settings}
              id="workspace-settings"
              onClick={(): void => {
                // see openEditBrandfolder function at
                // app/assets/javascripts/brandfolder/handlers/brandfolders_show_edit_handlers.js
                BF.handlers.openEditWorkspaceSettings({
                  brandfolderSlug: BFG.brandfolder_slug,
                  workspaceName: `${decode(BFG.resource.name)}`,
                  workspaceSlug: BFG.resource.slug
                });
              }}
            >
              <Trans>Settings</Trans>
            </TertiaryButton>
          )}
        </div>
      </div>
    </I18nProviderWrapper>
  );
};
