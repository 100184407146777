import { Trans } from '@lingui/macro';
import React, { useContext, FunctionComponent } from 'react';

import { Asset, LinkObject } from '@api/v4/assets/assetTypes';

import assetModalContext from '../asset_modal_context';
import { FlattenedAttachment } from '../EditTabTypes';

import AttachmentImage from './attachmentImage';
import AttachmentList from './attachmentList';
import AttachmentUploader from './attachmentUploader';

interface AttachmentListContainerScopedContext {
  state: {
    initialData: {
      asset: Asset;
      assetKey: string;
      attachments: FlattenedAttachment[];
    };
    editState: {
      assetName: string;
      attachments: FlattenedAttachment[];
      thumbnailOverride: LinkObject;
      thumbnailUrl: string;
      watermark: LinkObject;
    };
  };
}

const AttachmentListContainer: FunctionComponent<{ isTask?: boolean; }> = ({
  isTask = false
}) => {
  const { state }: AttachmentListContainerScopedContext = useContext(assetModalContext);
  const { assetName, attachments, thumbnailOverride, thumbnailUrl } = state.editState;

  /**
   * Because of the HTML splitting the number and the label here, we need to wrap
   * the entire thing in Trans, rather than using Plural
   * However, this situation should be avoided because Plural accounts for more than just
   * the one/other plurality of English and this method does not
   */
  const singularAttachment = (
    <Trans>
      <h4 className="attachment-list-count">{attachments.length}</h4>
      <h4>Attachment</h4>
    </Trans>
  );

  const pluralAttachments = (
    <Trans>
      <h4 className="attachment-list-count">{attachments.length}</h4>
      <h4>Attachments</h4>
    </Trans>
  );

  return (
    <>
      <AttachmentImage
        assetKey={state.initialData.assetKey}
        assetName={assetName}
        attachmentCount={attachments.length}
        attachmentExtension={state.initialData.asset ? state.initialData.asset.extension : ''}
        isTask={isTask}
        src={thumbnailOverride?.url || thumbnailUrl}
      />
      <div className="attachment-list-title">
        <span className="bff-paperclip" />
        {attachments.length === 1 ? singularAttachment : pluralAttachments}
      </div>
      <AttachmentList />
      <AttachmentUploader />
    </>
  );
};

export default AttachmentListContainer;
