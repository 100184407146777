import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles/search-pins-menu.scss';

import { manageDropdownListener } from '@helpers/use_effect_callbacks';
import { TertiaryButton } from '@components/library/button/index';
import SearchFilterPins from './search_filter_pins';

const SearchFilterPinsMenu = ({ searchFilters, submitSearchQuery }) => {
  const [showSearchPinsMenu, setShowSearchPinsMenu] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const thisComponent = useRef();

  const dropdownEventListenerCallback = (e) => {
    e.stopPropagation();
    setShowSearchPinsMenu(false);
  };

  useEffect(() => (
    manageDropdownListener(thisComponent, showSearchPinsMenu, dropdownEventListenerCallback)
  ), [thisComponent, showSearchPinsMenu]);

  useEffect(() => {
    setIsVisible(showSearchPinsMenu); // make visible after element renders
  }, [showSearchPinsMenu]);

  return (
    <div className="search-pins-menu-wrapper" ref={thisComponent}>
      <TertiaryButton
        className={classnames("search-pins-menu-button", { active: showSearchPinsMenu })}
        icon="bff-pin"
        onClick={() => setShowSearchPinsMenu(!showSearchPinsMenu)}
      >
        {searchFilters.length}
      </TertiaryButton>
      {showSearchPinsMenu && (
        <SearchFilterPins
          isVisible={isVisible}
          searchFilters={searchFilters}
          setShowSearchPinsMenu={setShowSearchPinsMenu}
          submitSearchQuery={submitSearchQuery}
        />
      )}
    </div>
  );
};

SearchFilterPinsMenu.propTypes = {
  searchFilters: PropTypes.arrayOf(PropTypes.shape({})),
  submitSearchQuery: PropTypes.func.isRequired,
};

SearchFilterPinsMenu.defaultProps = {
  searchFilters: [],
};

export default SearchFilterPinsMenu;
