import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTableProps } from '../BaseTableProps';

export const BaseTable: FunctionComponent<BaseTableProps> = (props) => {
  const { caption, captionProps, children, className, ...otherProps } = props;
  return (
    <table {...otherProps} className={classnames('baseTable', className)}>
      <caption {...captionProps}>{caption}</caption>
      {children}
    </table>
  );
};
