import { SearchOperatorOptions } from '@components/show_page/sections/section_search/SearchTypes';

export interface UserViewOptions {
  assetsPerPage?: number;
  searchOperator?: SearchOperatorOptions;
  selectedUGTLocale?: string;
  showAsGrid?: boolean;
  showCustomFields?: boolean;
  showTaskDetails?: boolean;
  showSections?: boolean;
  showEmptySections?: boolean;
  sortOption?: string;
}

export enum UserViewOptionsKeys {
  AssetsPerPage = 'assetsPerPage',
  SearchOperator = 'searchOperator',
  SelectedUGTLocale = 'selectedUGTLocale',
  ShowAsGrid = 'showAsGrid',
  ShowCustomFields = 'showCustomFields',
  ShowTaskDetails = 'showTaskDetails',
  ShowSections = 'showSections',
  ShowEmptySections = 'showEmptySections',
  SortOption = 'sortOption'
}

export type UpdateUserViewOptions = (option: UserViewOptionsKeys, updatedValue?: string | number | boolean) => void;
