// get custom fields data for particular asset
import { fetchJson } from '@api/ApiHelper';

import { CustomFieldValuesListData } from './customFieldTypes';

export const listCustomFieldValues = (
  assetKey: string,
  queryParams: { [key: string]: string | undefined }
): Promise<CustomFieldValuesListData> => {
  const query = new URLSearchParams(queryParams).toString();

  const appendedQueryString = query ? `?${query}` : '';
  const url = `/api/v4/assets/${assetKey}/custom_field_values${appendedQueryString}`;
  return fetchJson({ url });
};
