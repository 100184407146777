/* eslint-disable max-len */
import { t } from '@lingui/macro';

import { isGettyClient } from '@helpers/getty-strings';

import { EmptyStateContent, FilterValues, PeoplePageParamKeys, SortValues } from './customPeopleTagTypes';

export const PER_PAGE_COUNT = 50;
export const supportedFileExtensions = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'raw', 'ico'];

export const filterByOptions = (): { key: FilterValues; label: string }[] => ([
  { key: FilterValues.All, label: t`All` },
  { key: FilterValues.Tagged, label: t`Tagged` },
  { key: FilterValues.Untagged, label: t`Untagged` },
]);

export const sortByOptions = (): { key: SortValues; label: string }[] => ([
  { key: SortValues.Newest, label: t`Upload date (newest)` },
  { key: SortValues.Oldest, label: t`Upload date (oldest)` },
  { key: SortValues.Most, label: t`Frequency (appears most)` },
  { key: SortValues.Least, label: t`Frequency (appears least)` },
  { key: SortValues.Ascending, label: t`Name (A-Z)` },
  { key: SortValues.Descending, label: t`Name (Z-A)` },
]);

export const libraryTitle = isGettyClient() ? 'Library' : 'Brandfolder';

export const emptyStates = (): EmptyStateContent => ({
  [FilterValues.All]: {
    heading: t`No people found`,
    imgSrc: 'https://cdn.bfldr.com/27C9EC93/at/khsnqnp7k75jmt9qpvf2zhv/ghosts.svg?auto=webp&format=svg',
    subheading: t`People from photos in this ${libraryTitle} will appear here.`,
  },
  [PeoplePageParamKeys.Search]: {
    heading: t`No results found`,
    imgSrc: 'https://cdn.bfldr.com/27C9EC93/at/khsnqnp7k75jmt9qpvf2zhv/ghosts.svg?auto=webp&format=svg',
    subheading: t`We couldn’t find a person with this name. They may not have been tagged yet, or if you recently uploaded assets, the photos may not have been processed for tagging.`,
  },
  [PeoplePageParamKeys.AssetIds]: {
    heading: t`No results found`,
    imgSrc: 'https://cdn.bfldr.com/27C9EC93/at/khsnqnp7k75jmt9qpvf2zhv/ghosts.svg?auto=webp&format=svg',
    subheading: t`We couldn’t find the people in these assets. If you recently uploaded assets, the photos may not have been processed for tagging.`,
  },
  [FilterValues.Tagged]: {
    heading: t`No tagged people`,
    imgSrc: 'https://cdn.bfldr.com/27C9EC93/at/khsnqnp7k75jmt9qpvf2zhv/ghosts.svg?auto=webp&format=svg',
    subheading: t`People who have been tagged with a name will appear here.`,
  },
  [FilterValues.Untagged]: {
    heading: t`No untagged people`,
    imgSrc: 'https://cdn.bfldr.com/27C9EC93/at/9gnrpsj4mjskfh875j5jb5v/checks.svg?auto=webp&format=svg',
    subheading: t`People who can be tagged with a name will appear here.`,
  },
});

export const emptyStatesUnprocessedBrandfolder = (): EmptyStateContent => ({
  ...emptyStates(),
  [FilterValues.All]: {
    heading: t`Gathering people from your photos...`,
    imgSrc: 'https://cdn.bfldr.com/27C9EC93/at/s5h36khcc9n499bn2fkhcm/people.svg?auto=webp&format=svg',
    subheading: t`People who are in this ${libraryTitle}’s photos will appear here. Check back soon to view and tag these people.`,
  },
  [FilterValues.Untagged]: {
    heading: t`Gathering people from your photos...`,
    imgSrc: 'https://cdn.bfldr.com/27C9EC93/at/s5h36khcc9n499bn2fkhcm/people.svg?auto=webp&format=svg',
    subheading: t`People who are in this ${libraryTitle}’s photos will appear here. Check back soon to view and tag these people.`,
  }
});
