import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getMomentLocale } from '@helpers/locale';
import ReplyContainer from '@components/asset/modal/tabs/workflow/attachment_details/versions_sidebar/reply_container';
import CardActionsDropdown from '@components/asset/modal/tabs/workflow/attachment_details/versions_sidebar/card_actions_dropdown';
import MentionableInput from '@components/common/mentions/MentionableInput';
import { deleteAnnotation } from '@api/v4/annotations/annotations';
import { extractAnnotation } from '@components/asset/modal/tabs/workflow/helper';

const EventCard = (props) => {
  const {
    annotationNumber,
    cardEvent,
    childEvent,
    mentionableUsers,
    onReply,
    parentId,
    setAnnotationsResponse,
    setMentionableUsers,
    setTempAnnotation,
    users,
  } = props;

  const {
    author,
    children,
    created_at,
    id,
    note,
    type,
    updated_at,
    x_offset_in_percentage,
    y_offset_in_percentage,
  } = cardEvent;

  const [showEditMessage, setShowEditMessage] = useState(false);

  const handleReply = () => {
    setShowEditMessage((prevState) => (!prevState));
  };

  const handleDeleteAnnotation = (annotationKey) => (
    deleteAnnotation({ annotationKey })
      .then(() => {
        setAnnotationsResponse((prevState) => (extractAnnotation(annotationKey, prevState)));
      })
      .catch((err) => {
        console.log(err);
      })
  );

  const renderHeader = () => {
    const firstName = users[author] ? users[author].first_name : '';
    const lastName = users[author] ? users[author].last_name : '';
    const email = users[author] ? users[author].email : '';
    const timestamp = moment(updated_at || created_at).locale(getMomentLocale()).format('LL');

    switch (type) {
      case 'annotation':
        return (
          <>
            {!childEvent && (
              <div className="annotation-number">
                <span>{annotationNumber}</span>
              </div>
            )}
            <div className="name-container">
              <p className="name">{firstName && lastName ? `${firstName} ${lastName}` : email}</p>
              <p className="event-timestamp">{timestamp}</p>
            </div>
          </>
        );
      case 'comment':
        return (
          <>
            {!childEvent && <span className="bff-comment icon" />}
            <div className="name-container">
              <p className="name">{firstName} {lastName}</p>
              <p className="event-timestamp">{timestamp}</p>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderBody = () => {
    switch (type) {
      case 'annotation':
      case 'comment':
        return Boolean(note) && (
          <MentionableInput
            content={note}
            htmlAttributes={{
              disabled: true
            }}
          />
        );
      default:
        return null;
    }
  };

  const renderReplyContainer = () => {
    switch (type) {
      case 'annotation':
      case 'comment':
        return (
          <ReplyContainer
            cardEvent={cardEvent}
            mentionableUsers={mentionableUsers}
            onReply={onReply}
            parentId={parentId || id}
            setMentionableUsers={setMentionableUsers}
            setShowEditMessage={setShowEditMessage}
            showEditMessage={showEditMessage}
          />
        );
      default:
        return null;
    }
  };

  const renderCardActionsDropdown = () => {
    switch (type) {
      case 'annotation':
      case 'comment':
        return (
          <CardActionsDropdown
            cardEvent={cardEvent}
            onDelete={handleDeleteAnnotation}
            onReply={onReply}
            handleReply={handleReply}
          />
        );
      default:
        return null;
    }
  };

  const renderChildren = () => {
    if (!Array.isArray(children)) { return null; }

    return (
      <ul>
        {children.map((childrenEvent) => (
          <EventCard
            key={childrenEvent.id}
            cardEvent={childrenEvent}
            childEvent="child-event"
            mentionableUsers={mentionableUsers}
            onReply={onReply}
            parentId={id}
            setAnnotationsResponse={setAnnotationsResponse}
            setMentionableUsers={setMentionableUsers}
            users={users}
          />
        ))}
      </ul>
    );
  };

  const selectAnnotation = () => {
    setTempAnnotation({
      x: x_offset_in_percentage,
      y: y_offset_in_percentage,
      comment: note,
      number: annotationNumber,
      firstName: users[author]?.first_name,
      lastName: users[author]?.last_name,
      email: users[author]?.email,
      createdAt: created_at,
      updatedAg: updated_at
    });
  };

  return (
    <li
      className={`event ${childEvent} ${type}-event`}
      onClick={type === 'annotation' ? () => selectAnnotation() : undefined}
    >
      <div className="event__header">
        {renderHeader()}
        {renderCardActionsDropdown()}
      </div>
      <div className="event__body">
        {renderBody()}
      </div>
      {showEditMessage
        && (
          renderReplyContainer()
        )
      }
      {renderChildren()}
    </li>
  );
};

EventCard.propTypes = {
  annotationNumber: PropTypes.number,
  cardEvent: PropTypes.shape({
    author: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    created_at: PropTypes.string,
    id: PropTypes.string,
    note: PropTypes.string,
    type: PropTypes.oneOf(['annotation', 'comment', 'reservation']),
    updated_at: PropTypes.string,
    x_offset_in_percentage: PropTypes.string,
    y_offset_in_percentage: PropTypes.string,
  }).isRequired,
  childEvent: PropTypes.string,
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  onReply: PropTypes.func,
  parentId: PropTypes.string,
  setAnnotationsResponse: PropTypes.func,
  setTempAnnotation: PropTypes.func,
  setMentionableUsers: PropTypes.func,
  users: PropTypes.shape({}).isRequired,
};

EventCard.defaultProps = {
  annotationNumber: null,
  childEvent: '',
  mentionableUsers: undefined,
  onReply: undefined,
  setAnnotationsResponse: undefined,
  setMentionableUsers: undefined,
  setTempAnnotation: undefined,
  parentId: null,
};

export default EventCard;
