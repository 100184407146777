export type CsvResourceTypes = 'brandfolder' | 'collection';

// intentionally plural
export enum CsvContextsEnum {
  // /bulk_management#csv_upload
  Metasheets = 'metasheets',
  // /bulk_management#translations
  Translations = 'translations',
  DependentCustomFields = 'dependent_custom_fields'
}

// capitalized because these refer to Rails classes
// intentionally singular
export enum CsvActionableTypesEnum {
  Asset = 'Asset',
  Label = 'Label',
  Section = 'Section',
  DependentCustomField = 'DependentCustomField'
}

export interface CsvOptionsBase {
  context: CsvContextsEnum;
  actionableType?: CsvActionableTypesEnum;
}
