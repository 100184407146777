import { AlertLooks, StandardAlert, StandardLink } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { useRef, useState, FunctionComponent } from 'react';

import { AccessRequests } from '@components/bulk_management/user-settings/AccessRequests';
import { notAdminableAlertText } from '@components/bulk_management/user-settings/helpers';
import { InvitationMessageTabs } from '@components/bulk_management/user-settings/invitations';
import { PlanLimits } from '@components/bulk_management/user-settings/PlanLimits';
import {
  Brandfolder,
  Portal,
  ResourceDetails,
  ResourceSidebar,
  ServerOrganization
} from '@components/bulk_management/user-settings/resource-sidebar';
import { Tab, UserManagementTabs } from '@components/bulk_management/user-settings/UserManagementTabs';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { defaultKnowledgeBaseLinks, gettyKnowledgeBaseLinks } from '@components/kb-article-links';
import { OnlyForScreenReader } from '@components/library/screen-reader';
import { isGettyClient } from '@helpers/getty-strings';

import './styles/user-settings.scss';

interface UserSettingsProps {
  brandfolders: Brandfolder[];
  organization: ServerOrganization;
  organizationAdmin: boolean;
  organizationOwner: boolean;
  portals?: Portal[];
}

export const UserSettings: FunctionComponent<UserSettingsProps> = ({
  brandfolders,
  organization,
  organizationAdmin,
  organizationOwner,
  portals = []
}) => {
  const body = useRef<HTMLDivElement | null>(null);
  const [resource, setResource] = useState<ResourceDetails>({
    adminable: true, // being able to load into bulk management page means user is admin
    headingType: BFG.resource.type,
    key: BFG.resource.key,
    name: BFG.resource.name,
    type: BFG.resource.type === 'workspace' ? 'collection' : BFG.resource.type
  });
  const [reloadPlanLimits, setReloadPlanLimits] = useState(false);
  const [reloadUsers, setReloadUsers] = useState(false);
  const [reloadUserGroups, setReloadUserGroups] = useState(false);
  const [reloadPendingInvitations, setReloadPendingInvitations] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [currentTab, setCurrentTab] = useState<Tab>('users');

  let knowledgeBaseUrl;

  if (isGettyClient()) {
    knowledgeBaseUrl = gettyKnowledgeBaseLinks.userPermissionsLink;
  } else {
    knowledgeBaseUrl = defaultKnowledgeBaseLinks.userPermissionsLink;
  }

  let bodyContent;

  if (!resource.adminable) {
    bodyContent = (
      <div className="user-settings__not-adminable-alert">
        <StandardAlert
          heading={resource.name}
          look={AlertLooks.Error}
        >
          {notAdminableAlertText()[resource.type]}
        </StandardAlert>
      </div>
    );
  } else if (resource.type === 'organization') {
    bodyContent = (
      <div className="user-settings__organization-alert">
        <StandardAlert
          heading={resource.name}
          look={AlertLooks.Warning}
        >
          <StandardLink
            href={`/organizations/${organization.slug}#!manage_users`}
          >
            <Trans>Invite users at the Organization level.</Trans>
          </StandardLink>
        </StandardAlert>
      </div>
    );
  } else {
    bodyContent = (
      <div ref={body} className="user-settings__main">
        {/* Portals do not support access requests */}
        {resource.type !== 'portal' && (
          <AccessRequests
            selectedResourceKey={resource.key}
            selectedResourceType={resource.type}
            setReloadPendingInvitations={setReloadPendingInvitations}
            setReloadPlanLimits={setReloadPlanLimits}
            setReloadUsers={setReloadUsers}
          />
        )}
        <div className="user-settings__segment">
          <PlanLimits
            orgKey={BFG.organization_key}
            organizationOwner={organizationOwner}
            reload={reloadPlanLimits}
            resource={resource}
            setReload={setReloadPlanLimits}
          />
        </div>
        <InvitationMessageTabs
          brandfolders={brandfolders}
          portals={portals}
          reloadPendingInvitations={reloadPendingInvitations}
          selectedResourceKey={resource.key}
          selectedResourceType={resource.type}
          setReloadPendingInvitations={setReloadPendingInvitations}
          setReloadPlanLimits={setReloadPlanLimits}
          setReloadUserGroups={setReloadUserGroups}
          setReloadUsers={setReloadUsers}
        />
        <UserManagementTabs
          brandfolders={brandfolders}
          currentTab={currentTab}
          reloadUserGroups={reloadUserGroups}
          reloadUsers={reloadUsers}
          resourceName={resource.name}
          selectedExtendedResourceType={resource.headingType}
          selectedResourceKey={resource.key}
          selectedResourceType={resource.type}
          setCurrentTab={setCurrentTab}
          setReloadPlanLimits={setReloadPlanLimits}
          setReloadUserGroups={setReloadUserGroups}
          setReloadUsers={setReloadUsers}
          setUsersLoaded={setUsersLoaded}
        />
      </div>
    );
  }

  return (
    <I18nProviderWrapper>
      <div className="user-settings">
        <h2>
          <Trans>All Users</Trans>
        </h2>
        <p className="user-settings__permissions-blurb">
          {isGettyClient()
            ? <Trans>Administrators have the ability to invite users to Library resources.</Trans>
            : <Trans>Administrators have the ability to invite users to Brandfolder resources.</Trans>}&nbsp;
          <Trans>This seamless process invites users to access certain resources at any permission level.</Trans>&nbsp;
          <StandardLink
            href={knowledgeBaseUrl}
            target="_blank"
          >
            <Trans>Learn more about user permissions.</Trans>
            <OnlyForScreenReader><Trans>Opens in a new tab.</Trans></OnlyForScreenReader>
          </StandardLink>
        </p>
        <div className="user-settings__body">
          <div className="user-settings__sidebar">
            <ResourceSidebar
              bodyRef={body}
              brandfolders={brandfolders}
              organization={{
                ...organization,
                adminable: organizationAdmin,
                privacy: 'private',
              }}
              portals={portals}
              selectedResourceKey={resource.key}
              setResource={setResource}
              usersLoaded={usersLoaded}
            />
            </div>
            {bodyContent}
          </div>
        </div>
      </I18nProviderWrapper>
  );
};
