/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-useless-escape */
import { DesignExtended, IdentifiedPressRelease, PressReleaseUpsert } from '../PressReleaseTypes';

// this is the default "empty" json that react-email-editor creates
export const emptyPressReleaseContentJson: DesignExtended = {
  counters: {
    u_column: 1,
    u_row: 1
  },
  body: {
    rows: [
      {
        cells: [1],
        columns: [
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: {
                htmlID: "u_column_1", htmlClassNames: "u_column"
              }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: { url: "", fullWidth: true, repeat: false, center: true, cover: false },
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true }
      }
    ],
    values: {
      backgroundColor: "#e7e7e7",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false
      },
      contentWidth: "500px",
      fontFamily: {
        label: "Arial", value: "arial,helvetica,sans-serif"
      },
      preheaderText: "",
      linkStyle: {
        body: true,
        linkColor: "#0000ee",
        linkHoverColor: "#0000ee",
        linkUnderline: true,
        linkHoverUnderline: true
      },
      _meta: {
        htmlID: "u_body", htmlClassNames: "u_body"
      }
    }
  },
  schemaVersion: 5
};

export const universalPressReleaseSubject = `Jurassic World: Dominion (2022) Press Kit Now Available`;

export const universalPressReleaseJson: DesignExtended = {
  counters: {
    u_column: 32,
    u_row: 17,
    u_content_image: 15,
    u_content_divider: 7,
    u_content_text: 7
  },
  body: {
    rows: [
      {
        cells: [1],
        columns: [
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px",
                  _meta: {
                    htmlID: "u_content_image_1",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/trsqp6h4gb6cqkktb473ggvm/universal-jurrasic-world-dominion-header-top",
                    width: 500,
                    height: 118
                  },
                  textAlign: "center",
                  altText: "Universal",
                  action: {
                    name: "web",
                    values: {
                      href: "https://www.universalpictures.com",
                      target: "_blank"
                    },
                    attrs: { href: "{{href}}", target: "{{target}}" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "#000000",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1],
        columns: [
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px",
                  _meta: {
                    htmlID: "u_content_image_2",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/kgx33gkwvx4n8w9s8kptqns/universal-jurrasic-world-dominion-header-bottom",
                    width: 500,
                    height: 32
                  },
                  textAlign: "center",
                  altText: "Pictures",
                  action: {
                    name: "web",
                    values: {
                      href: "https://www.universalpictures.com",
                      target: "_blank"
                    },
                    attrs: { href: "{{href}}", target: "{{target}}" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_2", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "#ffffff",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_2", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1],
        columns: [
          {
            contents: [
              {
                type: "text",
                values: {
                  containerPadding: "0px 0px 25px",
                  _meta: {
                    htmlID: "u_content_text_3",
                    htmlClassNames: "u_content_text"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  color: "#000000",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  hideDesktop: false,
                  hideMobile: false,
                  text:
                    '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-size: 14px; line-height: 19.6px; color: #8a8a8a;">PRESS RELEASE</span></p>'
                }
              },
              {
                type: "text",
                values: {
                  containerPadding: "10px",
                  _meta: {
                    htmlID: "u_content_text_4",
                    htmlClassNames: "u_content_text"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  color: "#000000",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  hideDesktop: false,
                  hideMobile: false,
                  text:
                    '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-size: 30px; line-height: 42px;">Jurassic World: Dominion (2022) Press Kit Now Available</span></p>'
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_17", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "#ffffff",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "50px 25px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_11", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1, 1],
        columns: [
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_image_9",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/sskm27sbkhjqrhs7rj2h3tw8/universal-jurrasic-world-dominion-poster",
                    width: 236,
                    height: 373,
                    autoWidth: false,
                    maxWidth: "100%"
                  },
                  textAlign: "center",
                  altText: "Poster",
                  action: {
                    name: "web",
                    values: { href: "", target: "_blank" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_15", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "text",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_text_2",
                    htmlClassNames: "u_content_text"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  color: "#000000",
                  textAlign: "left",
                  lineHeight: "160%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  hideDesktop: false,
                  hideMobile: false,
                  text:
                    '<p style="font-size: 14px; line-height: 160%;"><span style="font-size: 16px; line-height: 25.6px; color: #ffffff;">Print-ready posters available for download in CMYK PDF format. Web-ready posters are available in RGB for JPG, PNG, and now AVIF file formats.</span></p>'
                }
              },
              {
                type: "image",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_image_8",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/fbc5xbfj986qfqwff3p2rpc/universal-jurrasic-world-dominion-download-posters",
                    width: 237,
                    height: 50
                  },
                  textAlign: "center",
                  altText: "Download Posters",
                  action: {
                    name: "web",
                    values: {
                      href: "https://www.brandfolder.com",
                      target: "_blank"
                    },
                    attrs: { href: "{{href}}", target: "{{target}}" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_16", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "50px 0px 0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_10", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1, 1, 1],
        columns: [
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_7", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "divider",
                values: {
                  containerPadding: "25px 0px",
                  _meta: {
                    htmlID: "u_content_divider_4",
                    htmlClassNames: "u_content_divider"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  width: "100%",
                  border: {
                    borderTopWidth: "1px",
                    borderTopStyle: "solid",
                    borderTopColor: "#8a8a8a"
                  },
                  textAlign: "center",
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_8", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_9", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_7", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1, 1],
        columns: [
          {
            contents: [
              {
                type: "text",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_text_6",
                    htmlClassNames: "u_content_text"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  color: "#000000",
                  textAlign: "left",
                  lineHeight: "160%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  hideDesktop: false,
                  hideMobile: false,
                  text:
                    '<p style="font-size: 14px; line-height: 160%;"><span style="font-size: 16px; line-height: 25.6px; color: #ffffff;">Logos available for download horizontal and vertical orientations. Print-ready and web-ready formats are available</span></p>'
                }
              },
              {
                type: "image",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_image_13",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/qqsv73sq4s68crx8zvb6p94/universal-jurrasic-world-dominion-download-photos",
                    width: 237,
                    height: 50,
                    autoWidth: false,
                    maxWidth: "94.8%"
                  },
                  textAlign: "center",
                  altText: "Download Logos",
                  action: {
                    name: "web",
                    attrs: { href: "{{href}}", target: "{{target}}" },
                    values: {
                      href: "https://www.brandfolder.com",
                      target: "_blank"
                    }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_26", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_image_12",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/rxbh98rw9b4q862sgzq5tc6t/universal-jurrasic-world-dominion-logo",
                    width: 236,
                    height: 185,
                    autoWidth: false,
                    maxWidth: "100%"
                  },
                  textAlign: "center",
                  altText: "Logo",
                  action: {
                    name: "web",
                    values: { href: "", target: "_blank" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_27", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "25px 0px 0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_15", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1, 1, 1],
        columns: [
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_23", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "divider",
                values: {
                  containerPadding: "25px 0px",
                  _meta: {
                    htmlID: "u_content_divider_6",
                    htmlClassNames: "u_content_divider"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  width: "100%",
                  border: {
                    borderTopWidth: "1px",
                    borderTopStyle: "solid",
                    borderTopColor: "#8a8a8a"
                  },
                  textAlign: "center",
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_24", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_25", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_14", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1, 1],
        columns: [
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_image_10",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/nmrv7p646kkgwqxxxvhz7mmk/universal-jurrasic-world-dominion-photo",
                    width: 236,
                    height: 157,
                    autoWidth: false,
                    maxWidth: "100%"
                  },
                  textAlign: "center",
                  altText: "Photo",
                  action: {
                    name: "web",
                    values: { href: "", target: "_blank" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_18", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "text",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_text_5",
                    htmlClassNames: "u_content_text"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  color: "#000000",
                  textAlign: "left",
                  lineHeight: "160%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  hideDesktop: false,
                  hideMobile: false,
                  text:
                    '<p style="font-size: 14px; line-height: 160%;"><span style="font-size: 16px; line-height: 25.6px; color: #ffffff;">Movie stills and behind the scenes press photos are available for print and web in various formats.</span></p>'
                }
              },
              {
                type: "image",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_image_11",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/qqsv73sq4s68crx8zvb6p94/universal-jurrasic-world-dominion-download-photos",
                    width: 237,
                    height: 50
                  },
                  textAlign: "center",
                  altText: "Download Photos",
                  action: {
                    name: "web",
                    values: {
                      href: "https://www.brandfolder.com",
                      target: "_blank"
                    },
                    attrs: { href: "{{href}}", target: "{{target}}" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_19", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "25px 0px 0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_12", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1, 1, 1],
        columns: [
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_28", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "divider",
                values: {
                  containerPadding: "25px 0px",
                  _meta: {
                    htmlID: "u_content_divider_7",
                    htmlClassNames: "u_content_divider"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  width: "100%",
                  border: {
                    borderTopWidth: "1px",
                    borderTopStyle: "solid",
                    borderTopColor: "#8a8a8a"
                  },
                  textAlign: "center",
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_29", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_30", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_16", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1, 1],
        columns: [
          {
            contents: [
              {
                type: "text",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_text_7",
                    htmlClassNames: "u_content_text"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  color: "#000000",
                  textAlign: "left",
                  lineHeight: "160%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  hideDesktop: false,
                  hideMobile: false,
                  text:
                    '<p style="font-size: 14px; line-height: 160%;"><span style="font-size: 16px; line-height: 25.6px; color: #ffffff;">Full-length trailer available in MP4 format, along with YouTube embed link.</span></p>'
                }
              },
              {
                type: "image",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_image_14",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/pzwqrjwqsjj5phbcnq2cfk/universal-jurrasic-world-dominion-download-trailer",
                    width: 237,
                    height: 50,
                    autoWidth: false,
                    maxWidth: "94.8%"
                  },
                  textAlign: "center",
                  altText: "Download Trailer",
                  action: {
                    name: "web",
                    attrs: { href: "{{href}}", target: "{{target}}" },
                    values: {
                      href: "https://www.brandfolder.com",
                      target: "_blank"
                    }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_31", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px 25px 25px",
                  _meta: {
                    htmlID: "u_content_image_15",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/s4csx244jb5tc6xwc7wmw4/universal-jurrasic-world-dominion-trailer",
                    width: 237,
                    height: 101,
                    autoWidth: false,
                    maxWidth: "100%"
                  },
                  textAlign: "center",
                  altText: "Logo",
                  action: {
                    name: "web",
                    values: { href: "", target: "_blank" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_32", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "25px 0px 0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_17", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1, 1, 1],
        columns: [
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_20", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "divider",
                values: {
                  containerPadding: "25px 0px",
                  _meta: {
                    htmlID: "u_content_divider_5",
                    htmlClassNames: "u_content_divider"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  width: "100%",
                  border: {
                    borderTopWidth: "1px",
                    borderTopStyle: "solid",
                    borderTopColor: "#8a8a8a"
                  },
                  textAlign: "center",
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_21", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_22", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_13", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1],
        columns: [
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px",
                  _meta: {
                    htmlID: "u_content_image_3",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/krm3bcprhn2vmt362jcnjhkt/universal-jurrasic-world-dominion-footer-logo",
                    width: 101,
                    height: 56
                  },
                  textAlign: "center",
                  altText: "Universal Pictures",
                  action: {
                    name: "web",
                    values: {
                      href: "https://www.universalpictures.com",
                      target: "_blank"
                    },
                    attrs: { href: "{{href}}", target: "{{target}}" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_4", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "25px 0px 50px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_4", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      },
      {
        cells: [1, 1, 1, 1],
        columns: [
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px",
                  _meta: {
                    htmlID: "u_content_image_7",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/gtxsv4q73mgw7nchj5rhbtsn/universal-jurrasic-world-dominion-footer-twitter",
                    width: 20,
                    height: 20
                  },
                  textAlign: "right",
                  altText: "Twitter",
                  action: {
                    name: "web",
                    values: {
                      href: "https://twitter.com/universalpics",
                      target: "_blank"
                    },
                    attrs: { href: "{{href}}", target: "{{target}}" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_11", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px 0px 0px 25px",
                  _meta: {
                    htmlID: "u_content_image_4",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/rjb8rm3n4rnswt3cp69vttk/universal-jurrasic-world-dominion-footer-facebook",
                    width: 20,
                    height: 20
                  },
                  textAlign: "center",
                  altText: "Facebook",
                  action: {
                    name: "web",
                    values: {
                      href: "http://facebook.com/universalpictures",
                      target: "_blank"
                    },
                    attrs: { href: "{{href}}", target: "{{target}}" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_12", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px 25px 0px 0px",
                  _meta: {
                    htmlID: "u_content_image_6",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/bm5bnj7wzxnm4k3j5hw74jx/universal-jurrasic-world-dominion-footer-instagram",
                    width: 20,
                    height: 20
                  },
                  textAlign: "center",
                  altText: "Instagram",
                  action: {
                    name: "web",
                    values: {
                      href: "https://instagram.com/universalpictures/",
                      target: "_blank"
                    },
                    attrs: { href: "{{href}}", target: "{{target}}" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_13", htmlClassNames: "u_column" }
            }
          },
          {
            contents: [
              {
                type: "image",
                values: {
                  containerPadding: "0px",
                  _meta: {
                    htmlID: "u_content_image_5",
                    htmlClassNames: "u_content_image"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  src: {
                    url:
                      "https://cdn.bfldr.com/IEYMEYO9/as/83rs89fn6rkg6v96rp47htk/universal-jurrasic-world-dominion-footer-youtube",
                    width: 20,
                    height: 20
                  },
                  textAlign: "left",
                  altText: "YouTube",
                  action: {
                    name: "web",
                    values: {
                      href: "https://www.youtube.com/user/UniversalPictures",
                      target: "_blank"
                    },
                    attrs: { href: "{{href}}", target: "{{target}}" }
                  },
                  hideDesktop: false,
                  hideMobile: false
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_14", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px 0px 50px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_9", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          _override: { mobile: { noStackMobile: true } }
        }
      },
      {
        cells: [1],
        columns: [
          {
            contents: [
              {
                type: "text",
                values: {
                  containerPadding: "0px",
                  _meta: {
                    htmlID: "u_content_text_1",
                    htmlClassNames: "u_content_text"
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  color: "#000000",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true
                  },
                  hideDesktop: false,
                  hideMobile: false,
                  text:
                    '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-size: 12px; line-height: 16.8px; color: #8a8a8a;">&copy;2020 Universal Pictures</span></p>'
                }
              }
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: { htmlID: "u_column_10", htmlClassNames: "u_column" }
            }
          }
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false
          },
          padding: "0px 0px 50px",
          hideDesktop: false,
          hideMobile: false,
          noStackMobile: false,
          _meta: { htmlID: "u_row_8", htmlClassNames: "u_row" },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true
        }
      }
    ],
    values: {
      backgroundColor: "#1a1a1a",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false
      },
      contentWidth: "500px",
      fontFamily: {
        label: "Arial",
        value: "arial,helvetica,sans-serif",
        url: "",
        defaultFont: true
      },
      preheaderText:
        "Posters, logos, photos, and trailers now available for download!",
      linkStyle: {
        body: true,
        linkColor: "#ffffff",
        linkHoverColor: "#0000ee",
        linkUnderline: false,
        linkHoverUnderline: true,
        inherit: false
      },
      _meta: { htmlID: "u_body", htmlClassNames: "u_body" }
    }
  },
  schemaVersion: 5
};

export const universalPressReleaseHtml = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
<!--[if gte mso 9]>
<xml>
  <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
  </o:OfficeDocumentSettings>
</xml>
<![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="x-apple-disable-message-reformatting">
  <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
  <title></title>
  
    <style type="text/css">
      a { color: #ffffff; text-decoration: none; }
[owa] .u-row .u-col {
  display: table-cell;
  float: none !important;
  vertical-align: top;
}

.ie-container .u-row,
[owa] .u-row {
  width: 500px !important;
}

.ie-container .u-col-25,
[owa] .u-col-25 {
  width: 125px !important;
}

.ie-container .u-col-33p33,
[owa] .u-col-33p33 {
  width: 166.65px !important;
}

.ie-container .u-col-50,
[owa] .u-col-50 {
  width: 250px !important;
}

.ie-container .u-col-100,
[owa] .u-col-100 {
  width: 500px !important;
}


@media only screen and (min-width: 520px) {
  .u-row {
    width: 500px !important;
  }
  .u-row .u-col {
    vertical-align: top;
  }

  .u-row .u-col-25 {
    width: 125px !important;
  }

  .u-row .u-col-33p33 {
    width: 166.65px !important;
  }

  .u-row .u-col-50 {
    width: 250px !important;
  }

  .u-row .u-col-100 {
    width: 500px !important;
  }

}

@media (max-width: 520px) {
  .u-row-container {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .u-row .u-col {
    min-width: 320px !important;
    max-width: 100% !important;
    display: block !important;
  }
  .u-row {
    width: calc(100% - 40px) !important;
  }
  .u-col {
    width: 100% !important;
  }
  .u-col > div {
    margin: 0 auto;
  }
  .no-stack .u-col {
    min-width: 0 !important;
    display: table-cell !important;
  }

  .no-stack .u-col-25 {
    width: 25% !important;
  }

  .no-stack .u-col-33p33 {
    width: 33.33% !important;
  }

  .no-stack .u-col-50 {
    width: 50% !important;
  }

  .no-stack .u-col-100 {
    width: 100% !important;
  }

}
body {
  margin: 0;
  padding: 0;
}

table,
tr,
td {
  vertical-align: top;
  border-collapse: collapse;
}

p {
  margin: 0;
}

.ie-container table,
.mso-container table {
  table-layout: fixed;
}

* {
  line-height: inherit;
}

a[x-apple-data-detectors='true'] {
  color: inherit !important;
  text-decoration: none !important;
}

.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
  line-height: 100%;
}

@media (max-width: 480px) {
  .hide-mobile {
    display: none !important;
    max-height: 0px;
    overflow: hidden;
  }
}

@media (min-width: 481px) {
  .hide-desktop {
    display: none !important;
    max-height: none !important;
  }
}
    </style>
  
  

</head>

<body class="clean-body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #1a1a1a">
  <!--[if IE]><div class="ie-container"><![endif]-->
  <!--[if mso]><div class="mso-container"><![endif]-->
  <table class="nl-container" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #1a1a1a;width:100%" cellpadding="0" cellspacing="0">
  <tbody>
  <tr>
    <td style="display:none !important;visibility:hidden;mso-hide:all;font-size:1px;color:#ffffff;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden;">
      Posters, logos, photos, and trailers now available for download!
    </td>
  </tr>
  
  <tr style="vertical-align: top">
    <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
    <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #1a1a1a;"><![endif]-->
    

<div class="u-row-container" style="padding: 0px;background-color: #000000">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: #000000;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_1" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      <a href="https://www.universalpictures.com" target="_blank">
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/trsqp6h4gb6cqkktb473ggvm/universal-jurrasic-world-dominion-header-top" alt="Universal" title="Universal" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 500px;" width="500" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 0px;background-color: #ffffff">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: #ffffff;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_2" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      <a href="https://www.universalpictures.com" target="_blank">
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/kgx33gkwvx4n8w9s8kptqns/universal-jurrasic-world-dominion-header-bottom" alt="Pictures" title="Pictures" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 500px;" width="500" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 50px 25px;background-color: #ffffff">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 50px 25px;background-color: #ffffff;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_text_3" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 0px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <div class="v-text-align" style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
    <p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-size: 14px; line-height: 19.6px; color: #8a8a8a;">PRESS RELEASE</span></p>
  </div>

      </td>
    </tr>
  </tbody>
</table>

<table id="u_content_text_4" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <div class="v-text-align" style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
    <p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-size: 30px; line-height: 42px;">Jurassic World: Dominion (2022) Press Kit Now Available</span></p>
  </div>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 50px 0px 0px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 50px 0px 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="250" style="width: 250px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-50" style="max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_9" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/sskm27sbkhjqrhs7rj2h3tw8/universal-jurrasic-world-dominion-poster" alt="Poster" title="Poster" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 200px;" width="200" class="v-src-width v-src-max-width"/>
      
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="250" style="width: 250px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-50" style="max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_text_2" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <div class="v-text-align" style="color: #000000; line-height: 160%; text-align: left; word-wrap: break-word;">
    <p style="font-size: 14px; line-height: 160%;"><span style="font-size: 16px; line-height: 25.6px; color: #ffffff;">Print-ready posters available for download in CMYK PDF format. Web-ready posters are available in RGB for JPG, PNG, and now AVIF file formats.</span></p>
  </div>

      </td>
    </tr>
  </tbody>
</table>

<table id="u_content_image_8" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      <a href="https://www.brandfolder.com" target="_blank">
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/fbc5xbfj986qfqwff3p2rpc/universal-jurrasic-world-dominion-download-posters" alt="Download Posters" title="Download Posters" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 200px;" width="200" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 0px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_divider_4" class="u_content_divider" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:25px 0px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #8a8a8a;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
    <tbody>
      <tr style="vertical-align: top">
        <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
          <span>&#160;</span>
        </td>
      </tr>
    </tbody>
  </table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 25px 0px 0px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 25px 0px 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="250" style="width: 250px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-50" style="max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_text_6" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <div class="v-text-align" style="color: #000000; line-height: 160%; text-align: left; word-wrap: break-word;">
    <p style="font-size: 14px; line-height: 160%;"><span style="font-size: 16px; line-height: 25.6px; color: #ffffff;">Logos available for download horizontal and vertical orientations. Print-ready and web-ready formats are available</span></p>
  </div>

      </td>
    </tr>
  </tbody>
</table>

<table id="u_content_image_13" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      <a href="https://www.brandfolder.com" target="_blank">
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/qqsv73sq4s68crx8zvb6p94/universal-jurrasic-world-dominion-download-photos" alt="Download Logos" title="Download Logos" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 94.8%;max-width: 189.6px;" width="189.6" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="250" style="width: 250px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-50" style="max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_12" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/rxbh98rw9b4q862sgzq5tc6t/universal-jurrasic-world-dominion-logo" alt="Logo" title="Logo" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 200px;" width="200" class="v-src-width v-src-max-width"/>
      
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 0px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_divider_6" class="u_content_divider" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:25px 0px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #8a8a8a;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
    <tbody>
      <tr style="vertical-align: top">
        <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
          <span>&#160;</span>
        </td>
      </tr>
    </tbody>
  </table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 25px 0px 0px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 25px 0px 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="250" style="width: 250px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-50" style="max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_10" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/nmrv7p646kkgwqxxxvhz7mmk/universal-jurrasic-world-dominion-photo" alt="Photo" title="Photo" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 200px;" width="200" class="v-src-width v-src-max-width"/>
      
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="250" style="width: 250px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-50" style="max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_text_5" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <div class="v-text-align" style="color: #000000; line-height: 160%; text-align: left; word-wrap: break-word;">
    <p style="font-size: 14px; line-height: 160%;"><span style="font-size: 16px; line-height: 25.6px; color: #ffffff;">Movie stills and behind the scenes press photos are available for print and web in various formats.</span></p>
  </div>

      </td>
    </tr>
  </tbody>
</table>

<table id="u_content_image_11" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      <a href="https://www.brandfolder.com" target="_blank">
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/qqsv73sq4s68crx8zvb6p94/universal-jurrasic-world-dominion-download-photos" alt="Download Photos" title="Download Photos" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 200px;" width="200" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 0px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_divider_7" class="u_content_divider" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:25px 0px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #8a8a8a;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
    <tbody>
      <tr style="vertical-align: top">
        <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
          <span>&#160;</span>
        </td>
      </tr>
    </tbody>
  </table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 25px 0px 0px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 25px 0px 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="250" style="width: 250px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-50" style="max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_text_7" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <div class="v-text-align" style="color: #000000; line-height: 160%; text-align: left; word-wrap: break-word;">
    <p style="font-size: 14px; line-height: 160%;"><span style="font-size: 16px; line-height: 25.6px; color: #ffffff;">Full-length trailer available in MP4 format, along with YouTube embed link.</span></p>
  </div>

      </td>
    </tr>
  </tbody>
</table>

<table id="u_content_image_14" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      <a href="https://www.brandfolder.com" target="_blank">
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/pzwqrjwqsjj5phbcnq2cfk/universal-jurrasic-world-dominion-download-trailer" alt="Download Trailer" title="Download Trailer" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 94.8%;max-width: 189.6px;" width="189.6" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="250" style="width: 250px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-50" style="max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_15" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/s4csx244jb5tc6xwc7wmw4/universal-jurrasic-world-dominion-trailer" alt="Logo" title="Logo" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 200px;" width="200" class="v-src-width v-src-max-width"/>
      
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 0px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_divider_5" class="u_content_divider" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:25px 0px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #8a8a8a;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
    <tbody>
      <tr style="vertical-align: top">
        <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
          <span>&#160;</span>
        </td>
      </tr>
    </tbody>
  </table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="167" style="width: 167px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-33p33" style="max-width: 320px;min-width: 167px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 25px 0px 50px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 25px 0px 50px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_3" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      <a href="https://www.universalpictures.com" target="_blank">
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/krm3bcprhn2vmt362jcnjhkt/universal-jurrasic-world-dominion-footer-logo" alt="Universal Pictures" title="Universal Pictures" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 101px;" width="101" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 0px 0px 50px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row no-stack">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px 0px 50px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="125" style="width: 125px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-25" style="max-width: 320px;min-width: 125px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_7" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="right">
      <a href="https://twitter.com/universalpics" target="_blank">
      <img align="right" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/gtxsv4q73mgw7nchj5rhbtsn/universal-jurrasic-world-dominion-footer-twitter" alt="Twitter" title="Twitter" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 20px;" width="20" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="125" style="width: 125px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-25" style="max-width: 320px;min-width: 125px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_4" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 0px 0px 25px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      <a href="http://facebook.com/universalpictures" target="_blank">
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/rjb8rm3n4rnswt3cp69vttk/universal-jurrasic-world-dominion-footer-facebook" alt="Facebook" title="Facebook" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 20px;" width="20" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="125" style="width: 125px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-25" style="max-width: 320px;min-width: 125px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_6" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 25px 0px 0px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="center">
      <a href="https://instagram.com/universalpictures/" target="_blank">
      <img align="center" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/bm5bnj7wzxnm4k3j5hw74jx/universal-jurrasic-world-dominion-footer-instagram" alt="Instagram" title="Instagram" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 20px;" width="20" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
<!--[if (mso)|(IE)]><td align="center" width="125" style="width: 125px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-25" style="max-width: 320px;min-width: 125px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_image_5" class="u_content_image" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:arial,helvetica,sans-serif;" align="left">
        
<table width="100%" cellpadding="0" cellspacing="0" border="0">
  <tr>
    <td style="padding-right: 0px;padding-left: 0px;" align="left">
      <a href="https://www.youtube.com/user/UniversalPictures" target="_blank">
      <img align="left" border="0" src="https://cdn.bfldr.com/IEYMEYO9/as/83rs89fn6rkg6v96rp47htk/universal-jurrasic-world-dominion-footer-youtube" alt="YouTube" title="YouTube" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;width: 100%;max-width: 20px;" width="20" class="v-src-width v-src-max-width"/>
      </a>
    </td>
  </tr>
</table>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>



<div class="u-row-container" style="padding: 0px 0px 50px;background-color: transparent">
  <div style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;" class="u-row">
    <div style="border-collapse: collapse;display: table;width: 100%;background-color: transparent;">
      <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px 0px 50px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
      
<!--[if (mso)|(IE)]><td align="center" width="500" style="width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
<div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
  <div style="width: 100% !important;">
  <!--[if (!mso)&(!IE)]><!--><div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->
  
<table id="u_content_text_1" class="u_content_text" style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
  <tbody>
    <tr>
      <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:arial,helvetica,sans-serif;" align="left">
        
  <div class="v-text-align" style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
    <p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-size: 12px; line-height: 16.8px; color: #8a8a8a;">&copy;2020 Universal Pictures</span></p>
  </div>

      </td>
    </tr>
  </tbody>
</table>

  <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
  </div>
</div>
<!--[if (mso)|(IE)]></td><![endif]-->
      <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
    </div>
  </div>
</div>


    <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
    </td>
  </tr>
  </tbody>
  </table>
  <!--[if (mso)|(IE)]></div><![endif]-->
</body>

</html>`;

// just a test draft press release
export const draftPressRelease: IdentifiedPressRelease = {
  brandfolder_user_sources: [],
  collection_user_sources: [],
  content_json: JSON.stringify(universalPressReleaseJson),
  content_html: universalPressReleaseHtml,
  created_at: new Date().toISOString(),
  id: 'abc123',
  permission_levels: [],
  request_send_at: null,
  sent_at: null,
  subject: universalPressReleaseSubject,
  updated_at: new Date().toISOString()
};

export const defaultPressRelease: PressReleaseUpsert = {
  brandfolder_user_sources: [],
  collection_user_sources: [],
  content_html: '',
  content_json: '',
  from_name: '',
  id: '',
  permission_levels: [],
  request_send_at: null,
  sent_at: null,
  subject: ''
};
