import { plural, t, Plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from "react";

import { BFLoader } from '@components/common/loader/main';
import { DangerButton } from '@components/library/button/index';
import { bulkRefreshSections } from '@helpers/show_page_helpers';

import renderModal from '../modals/renderModal';

class BulkDeleteModal extends React.Component {
  state = {
    disableSubmitButton: false,
    isLoading: false
  }

  componentWillUnmount() {
    this.props.closeModal();
  }

  deleteAssets = () => {
    this.setState({ disableSubmitButton: true, isLoading: true });

    const { addRemoveSelected, closeModal, selectedAssetKeys } = this.props;

    $.ajax({
      type: "DELETE",
      url: '/api/v4/bulk_actions/assets/destroy?queue_priority=high',
      contentType: "application/json",
      data: JSON.stringify(this.buildDataBody()),
      context: this,
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: (response, _, xhr) => {
        const assetCount = selectedAssetKeys.size;

        addRemoveSelected([]);
        closeModal();
        if (xhr.status === 202) {
          // processing async because of quantity
          const delayTime = 1000 + (assetCount * 50);
          bulkRefreshSections(selectedAssetKeys, delayTime);
          setTimeout(() => {
            BF.fx.updateAssetPageCount(assetCount, false);
          }, delayTime);
          Notify.create({
            title: plural(assetCount, {
              one: '# asset processing!',
              other: '# assets processing!'
            }),
            body: t`Assets in deletion process. Your changes will take place over the next few minutes.`,
            type: 'success'
          });
        } else {
          bulkRefreshSections(selectedAssetKeys, 1000);
          BF.fx.updateAssetPageCount(assetCount, false);
          Notify.create({
            title: plural(assetCount, {
              one: '# asset deleted!',
              other: '# assets deleted!'
            }),
            type: 'success'
          });
        }
      },
      error: () => {
        this.setState({ disableSubmitButton: false, isLoading: false });
        Notify.create({
          title: t`An error occurred when deleting assets. Please try again.`,
          type: 'error'
        });
      }
    });
  }

  buildDataBody() {
    const { selectedAssetKeys } = this.props;
    return { data: { asset_keys: Array.from(selectedAssetKeys) } };
  }

  render() {
    const { selectedAssetKeys } = this.props;
    const assetCount = selectedAssetKeys.size;
    const loaderActive = this.state.isLoading ? 'loader-active' : '';

    return (
      <div className="modal-content-wrapper bulk-delete-modal">
        <div className="modal-content-wrapper__header">
          <span aria-hidden="true" className="bff-trash icon" />
          <h3 className="modal-title"><Trans>Delete</Trans></h3>
          <button
            className="close-button"
            onClick={this.props.closeModal}
            type="button"
          >
            <span className="bff-close" />
          </button>
        </div>
        <div className={`modal-content-wrapper__body ${loaderActive}`}>
          {this.state.isLoading
            ? <BFLoader />
            : (
              <div>
                <h1 className="body-title">
                  <Plural
                    one="# asset"
                    other="# assets"
                    value={assetCount}
                  />
                </h1>
                <h3 className="body-title">
                  {/* TODO: don't rely on bold-text for spacing */}
                  <Plural
                    one={(
                      <Trans>
                        Are you sure you want to permanently delete
                        <span className="bold-text">
                          {assetCount} asset?
                        </span>
                      </Trans>
                    )}
                    other={(
                      <Trans>
                        Are you sure you want to permanently delete
                        <span className="bold-text">
                          {assetCount} asset?
                        </span>
                      </Trans>
                    )}
                    value={assetCount}
                  />
                </h3>
                <div className="button-container">
                  <DangerButton
                    className="t-confirm-modal"
                    disabled={this.state.disableSubmitButton}
                    onClick={this.deleteAssets}
                    size="small"
                  >
                    <Trans>Delete</Trans>
                  </DangerButton>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

BulkDeleteModal.propTypes = {
  addRemoveSelected: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
};

const ModalComponent = renderModal(BulkDeleteModal, 'BulkDeleteModal');
export default ModalComponent;
