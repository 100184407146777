import { ActionsOpenDirections, DropdownKeys, ListOpenDirections } from '@components/library/dropdown';
import { FontIcons } from '@components/library/icon';

export const dropdownKeydownHelper = (
  keyPressed: DropdownKeys,
  optionsLength: number | undefined,
  targetedIndex: number | undefined,
): number | boolean => {
  if (optionsLength === undefined || targetedIndex === undefined) return false;

  const firstIndex = 0;
  const lastIndex = optionsLength - 1;
  const nextIndex = targetedIndex + 1;
  const previousIndex = targetedIndex - 1;

  switch (keyPressed) {
    case DropdownKeys.ArrowDown:
    case DropdownKeys.ArrowRight:
      if (targetedIndex > -1) {
        return (optionsLength > nextIndex) ? nextIndex : firstIndex;
      }
      return firstIndex;
    case DropdownKeys.ArrowLeft:
    case DropdownKeys.ArrowUp:
      if (targetedIndex > -1) {
        return targetedIndex === 0 ? lastIndex : previousIndex;
      }
      return lastIndex;
    case DropdownKeys.Backspace:
    case DropdownKeys.Enter:
    case DropdownKeys.Spacebar:
    case DropdownKeys.Space:
      if (targetedIndex > -1) {
        return true;
      }
      break;
    default:
      break;
  }

  return false;
};

export const whichCaret = (openDirection: ActionsOpenDirections | ListOpenDirections): FontIcons | undefined => {
  if (['down', 'down-left', 'down-right'].includes(openDirection)) return FontIcons.CaretDown;
  if (['right-down', 'right-up'].includes(openDirection)) return FontIcons.CaretRight;
  if (['up-left', 'up-right', 'up'].includes(openDirection)) return FontIcons.CaretUp;
  return undefined;
};
