import React from 'react';
import PropTypes from 'prop-types';

import { fetchJson } from '@api/ApiHelper';
import { DEFAULT_FILESTACK_POST_OPTIONS } from '@api/v4/private/resources/fs_creds';
import Blitline from "@helpers/blitline";
import GoogleVision from '@helpers/google_vision';
import Asset from "@helpers/asset";
import { FilestackUploader } from "@components/common/filestack_uploader/FilestackUploader";
import { initializeFilestackMetadata } from '@components/common/filestack_uploader/initialize';
import { Loader } from '@components/common/loader/main';

import './styles/asset_manager.scss';

class AssetManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      asset: null,
      width: '',
      height: '',
      thumbnailUrl: '',
      error: false,
      colors: Array(5).fill(null),
      metadata: null,
      text: null,
      tags: null,
      loadingMessage: null,
      filetype: null,
      filename: null,
    };
  }

  async onUpload(files) {
    this.setState({ error: false });

    try {
      const filestackUrl = files.filesUploaded[0].url;
      const urlParts = filestackUrl.split("/");
      const handle = urlParts[urlParts.length - 1];

      const filestackCredentials = await fetchJson({
        body: DEFAULT_FILESTACK_POST_OPTIONS,
        method: 'POST',
        url: `/api/v4/private/workbenches/asset-manager/fs_creds`
      });

      initializeFilestackMetadata({
        filestackCredentials: filestackCredentials.data,
        handle,
        metadataOptions: {
          exif: true,
          size: true
        }
      }).then((_file) => {
        const file = _file;
        const splitFilename = file.filename.split('.');
        const extension = splitFilename.length > 1 ? splitFilename.pop() : file.mimetype.split("/")[1];
        file.filetype = Blitline.normalizeFiletype(extension);
        file.url = filestackUrl;

        file.width = file.width || 0;
        file.height = file.height || 0;

        if (!Blitline.hasSupportFor(file.filetype)) {
          this.setState({ error: 'File type not supported.' });
          return;
        }

        // if (!Blitline.webViewable(file.filetype)) {
        //   const blitline = new Blitline(file.url, file.filetype);
        //   blitline.thumbnail()
        //     .then((thumbnailUrl) => this.setState({ thumbnailUrl }))
        //     .catch((error) => {
        //       this.setState({ error });
        //     });
        // } else {
        const transparentFiles = ['png', 'svg'];
        const format = transparentFiles.includes(file.filetype) ? 'png' : 'jpg';
        const thumbnailUrl = `${file.url}/convert?rotate=exif&fit=max&w=400&h=300&format=${format}`;
        this.setState({ thumbnailUrl });
        // }

        const asset = new Asset(file);
        const filetype = asset.filetype;
        const filename = asset.filename;
        this.setState({ asset, filetype, filename }, () => { this.extractAdditional(); });
      });
    } catch (err) {
      this.setState({ error: 'Error uploading file. Please refresh the page and try again.' });
    }
  }

  onUploadError(file, error) {
    this.setState({ error: error?.message || 'Error uploading file. Please refresh the page and try again.' });
  }

  onPropertyChange(props) {
    let assetUpdated = false;
    const asset = Object.assign({}, this.state.asset);

    if (props.width !== undefined) {
      asset.width = props.width;
      assetUpdated = true;
    }

    if (props.height !== undefined) {
      asset.height = props.height;
      assetUpdated = true;
    }

    if (props.filetype !== undefined) {
      asset.filetype = props.filetype;
      asset.filename = asset.filename.replace(/\.\w*$/, `.${props.filetype}`);
      assetUpdated = true;
    }

    if (assetUpdated) {
      this.setState({ asset });
    }
  }

  extractAdditional() {
    // These are additional (optional) extractions that happen after the asset is loaded
    if (this.props.extractColors) {
      this.extractColors();
    }

    if (this.props.extractMetadata) {
      this.extractMetadata();
    }

    if (this.props.extractText) {
      this.extractText();
    }

    if (this.props.extractTags) {
      this.extractTags();
    }
  }

  extractColors() {
    this.setState({
      loadingMessage: 'Extracting colors...'
    });

    const blitline = new Blitline(this.state.asset.url, this.state.asset.filetype);
    blitline.extractColors().then((colors) => {
      this.setState({
        colors: colors.map((colorObj) => colorObj.color.hex_color),
        loadingMessage: null,
      });
    }).catch(() => {
      this.setState({
        error: 'Failed to extract colors. Please try again.',
        loadingMessage: null,
      });
    });
  }

  extractMetadata() {
    this.setState({
      loadingMessage: 'Extracting metadata...'
    });

    const blitline = new Blitline(this.state.asset.url, this.state.asset.filetype);
    blitline.extractMetadata().then((metadata) => {
      this.setState({
        metadata,
        loadingMessage: null,
      });
    }).catch(() => {
      this.setState({
        error: 'Failed to extract metadata. Please try again.',
        loadingMessage: null,
      });
    });
  }

  extractText() {
    this.setState({
      loadingMessage: 'Extracting text...'
    });

    let promise;
    if (GoogleVision.hasSupportFor(this.state.asset.filetype)) {
      const vision = new GoogleVision(this.state.asset.url);
      promise = vision.extractText();
    } else {
      const blitline = new Blitline(this.state.asset.url, this.state.asset.filetype);
      promise = blitline.extractText();
    }

    promise.then((text) => {
      this.setState({
        text,
        loadingMessage: null,
      });
    }).catch(() => {
      this.setState({
        error: 'Failed to extract text. Please try again.',
        loadingMessage: null,
      });
    });
  }

  extractTags() {
    this.setState({
      loadingMessage: 'Extracting tags...'
    });

    let promise;
    if (GoogleVision.hasSupportFor(this.state.asset.filetype)) {
      const vision = new GoogleVision(this.state.asset.url);
      promise = vision.extractTags();
    } else {
      const blitline = new Blitline(this.state.asset.url, this.state.asset.filetype);
      promise = blitline.extractTags();
    }

    promise.then((tags) => {
      this.setState({
        tags: Array.from(new Set(tags)),
        loadingMessage: null,
      });
    }).catch(() => {
      this.setState({
        error: 'Failed to extract tags. Please try again.',
        loadingMessage: null,
      });
    });
  }

  renderResetButton() {
    // reloading so we can rate limit with RackAttack gem on backend
    if (!this.state.asset) { return undefined; }

    return (
      <button
        className="workbench__reset-button"
        onClick={() => window.location.reload(false)}
        type="button"
      >
        <span className="bff-refresh" />
        Start over
      </button>
    );
  }

  renderPreview() {
    if (!this.state.asset) { return undefined; }

    return (
      <div className="workbench__uploader-preview">
        <img
          alt=""
          src={this.state.thumbnailUrl}
        />
        <p>{this.state.filename}</p>
      </div>
    );
  }

  renderLoadingMessage() {
    if (!this.state.loadingMessage) { return undefined; }

    return (
      <React.Fragment>
        <Loader />
        <p>{this.state.loadingMessage}</p>
      </React.Fragment>
    );
  }

  renderUploader() {
    const gig = 1073741824;
    if (this.state.asset) { return undefined; }

    return (
      <div className="workbench__uploader-container">
        <FilestackUploader
          onUploadDone={(files) => this.onUpload(files)}
          pickerOptions={{
            maxFiles: 1,
            maxSize: (1/2 * gig),
            onFileUploadFailed: (file, error) => this.onUploadError(file, error)
          }}
          resourceKey="asset-manager"
          resourceType="workbenche" // "s" is added in useFilestackCredentials.ts
        />
      </div>
    );
  }

  renderError() {
    if (!this.state.error) { return undefined; }
    return (
      <p className="workbench__error">{this.state.error}</p>
    );
  }

  render() {
    const { asset, colors, metadata, text, tags, filetype } = this.state;
    const children = React.Children.map(this.props.children, (child) => (
      React.cloneElement(child, {
        asset,
        colors,
        metadata,
        text,
        tags,
        filetype,
        onPropertyChange: (props) => this.onPropertyChange(props),
        renderResetButton: (props) => this.renderResetButton(props)
      })
    ));

    return (
      <div className="workbench">
        {this.renderResetButton()}
        {this.renderPreview()}
        {this.renderLoadingMessage()}
        {this.renderUploader()}
        {this.renderError()}
        {children}
      </div>
    );
  }
}

AssetManager.propTypes = {
  children: PropTypes.node.isRequired,
  extractColors: PropTypes.bool,
  extractMetadata: PropTypes.bool,
  extractText: PropTypes.bool,
  extractTags: PropTypes.bool,
};

AssetManager.defaultProps = {
  extractColors: false,
  extractMetadata: false,
  extractTags: false,
  extractText: false,
};

export default AssetManager;
