import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';

import { Asset } from '@api/v4/assets/assetTypes';
import { getAssetIsDesignHuddleAsset } from '@helpers/design_huddle';

import assetModalContext from '../asset_modal_context';
import { AttachmentActionDispatch, FlattenedAttachment } from '../EditTabTypes';

import AttachmentListItem from './attachmentListItem';

import './styles/_attachment_list.scss';

interface AttachmentListEditState {
  attachments: FlattenedAttachment[];
}

interface AttachmentListScopedContext {
  dispatch: AttachmentActionDispatch;
  state: {
    initialData: {
      asset: Asset;
    },
    editState: AttachmentListEditState;
  };
}

const AttachmentList: FunctionComponent = () => {
  const { dispatch, state }: AttachmentListScopedContext = useContext(assetModalContext);
  const { attachments }: AttachmentListEditState = state.editState;

  const [droppedIndex, setDroppedIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (droppedIndex !== undefined) {
      setTimeout(() => {
        setDroppedIndex(undefined);
      }, 500);
    }
  }, [droppedIndex]);

  const isDesignHuddleAsset = getAssetIsDesignHuddleAsset(state?.initialData?.asset?.design_huddle_editor_link);
  const isContentAutomationAsset = !!state?.initialData?.asset?.content_automation_editor_link;

  return useMemo(() => (
    <ul className="attachment-list">
      {(attachments.map((attachment, i) => (
        <AttachmentListItem
          key={attachment.key}
          attachment={attachment}
          dispatch={dispatch}
          droppedIndex={droppedIndex}
          index={i}
          isTemplateAsset={isContentAutomationAsset || isDesignHuddleAsset}
          setDroppedIndex={setDroppedIndex}
        />
      )))}
    </ul>
  ), [attachments, dispatch, droppedIndex]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default AttachmentList;
