export enum DropdownVariants {
  Actions = 'actions',
  List = 'list',
  Multiselect = 'multiselect',
}

export enum ActionsDropdownTransitions {
  FadeInTranslate = 'fade-in-translate',
  FadeIn = 'fade-in',
}

export enum ListDropdownTransitions {
  FadeIn = 'fade-in',
}

export enum ActionsOpenDirections {
  DownLeft = 'down-left',
  DownRight = 'down-right',
  RightDown = 'right-down',
  RightUp = 'right-up',
  UpLeft = 'up-left',
  UpRight = 'up-right',
}

export enum OpenDirectionOverride {
  Up = 'up',
}

export enum ListOpenDirections {
  Down = 'down',
  RightDown = 'right-down',
  RightUp = 'right-up',
  Up = 'up',
}

export enum DropdownKeys {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Backspace = 'Backspace', // on a mac: "delete"
  End = 'End', // on a mac: "fn" + "ArrowRight"
  Enter = 'Enter', // on a mac: "return"
  Home = 'Home', // on a mac: "fn" + "ArrowLeft"
  Spacebar = 'Spacebar',
  Space = ' ',
}

export enum LabelPosition {
  Left = 'label-left',
  Top = 'label-top',
}
