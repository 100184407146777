import { t, Trans } from '@lingui/macro';
import React, { useEffect, useMemo, useState, FunctionComponent, ReactNode } from 'react';

import { useFetch } from '@api/ApiHelper';
import {
  Notification,
  NotificationsListResponse,
  NotificationType,
} from '@api/v4/NotificationTypes';
import {
  defaultPagination,
  mapMetaToPagination,
  Pagination
} from '@components/bulk_management/shared';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { BFLoader } from '@components/common/loader/main';
import { TextButton } from '@components/library/button';
import { ListDropdown, ListOption } from '@components/library/dropdown';
import { NotificationCard } from '@components/profile/NotificationCard';

import classes from './styles/notifications.module.scss';

const MAX_RECORDS = 10000;
const NOTIFICATION_TYPE_LABELS = (): Record<NotificationType, string> => ({
  [NotificationType.access_request]: t`Access Request`,
  [NotificationType.approval]: t`Approval`,
  [NotificationType.asset_deletion]: t`Asset Deletion`,
  [NotificationType.asset_expiration]: t`Asset Expiration`,
  [NotificationType.asset_publish]: t`Asset Publish`,
  [NotificationType.asset_removal]: t`Asset Removal`,
  [NotificationType.asset_upload]: t`Asset Upload`,
  [NotificationType.asset_will_expire]: t`Asset Will Expire`,
  [NotificationType.attachment_duplication]: t`Attachment Duplication`,
  [NotificationType.collection_add_asset]: t`Asset Added to Collection`,
  [NotificationType.comment]: t`Comment`,
  [NotificationType.download_request]: t`Download Request`,
  [NotificationType.generic]: t`Generic`,
  [NotificationType.guest_password_change]: t`Guest Password Change`,
  [NotificationType.invite_accepted]: t`Invite Accepted`,
  [NotificationType.message]: t`Message`,
  [NotificationType.privacy_change]: t`Privacy Change`,
  [NotificationType.section_deletion]: t`Section Deletion`,
  [NotificationType.share_link_asset_downloaded]: t`Share Link Asset Downloaded`,
  [NotificationType.share_link_asset_viewed]: t`Share Link Asset Viewed`,
  [NotificationType.share_link_viewed]: t`Share Link Viewed`,
  [NotificationType.template_approval]: t`Template Approval`,
  [NotificationType.user_groups]: t`User Groups`
});

interface NotificationsListProps {
  libraryNameCopy: string;
  notificationTypes: string[];
}

export const NotificationsList: FunctionComponent<NotificationsListProps> = ({
  libraryNameCopy,
  notificationTypes
}) => {
  const [filterType, setFilterType] = useState('all');
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationPagination, setNotificationPagination] = useState(defaultPagination);
  const notificationsBlurb = libraryNameCopy === 'Libraries'
    ? <Trans>View updates, alerts, and requests to stay up to speed on your Libraries</Trans>
    : <Trans>View updates, alerts, and requests to stay up to speed on your Brandfolders</Trans>;

  const getNotifications = useFetch<NotificationsListResponse>({
    params: {
      'filter[notification_type]': filterType === 'all' ? undefined : filterType,
      page: notificationPagination.currentPage,
      per: notificationPagination.per,
    },
    url: '/api/v4/notifications',
  });

  const filterOptions = useMemo(() => {
    const availableTypes = notificationTypes.map((notificationType) => ({
      label: NOTIFICATION_TYPE_LABELS()[NotificationType[notificationType]],
      value: notificationType
    }));
    return [{
      label: t`All`,
      value: 'all'
    },
    ...availableTypes];
  }, [notificationTypes]);

  useEffect(() => {
    getNotifications.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, notificationPagination.currentPage, notificationPagination.per]);

  useEffect(() => {
    if (getNotifications.response) {
      setNotifications(
        getNotifications.response.data.map(({ id, attributes }) => ({ ...attributes, id }))
      );
      setNotificationPagination({
        ...notificationPagination,
        ...mapMetaToPagination(getNotifications.response.meta, notificationPagination.per, MAX_RECORDS)
      });
    }
  }, [getNotifications.response]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBodyByFetchState = (): ReactNode => {
    if (getNotifications.error) {
      return (
        <div>
          <Trans>
            <p>Error retrieving notifications.</p>
            <TextButton onClick={(): void => { getNotifications.fetch(); }}>
              Try again
            </TextButton>
          </Trans>
        </div>
      );
    }

    if (getNotifications.loading) {
      return <BFLoader />;
    }

    if (getNotifications.response) {
      return (
        <>
          <ul className={classes.notificationList}>
            {notifications.map((notification) => (
              <NotificationCard key={notification.id} {...notification} />
            ))}
          </ul>
          <Pagination
            {...notificationPagination}
            onPageChange={(currentPage): void => {
              setNotificationPagination({ ...notificationPagination, currentPage });
            }}
            onPerChange={(per): void => {
              setNotificationPagination({ ...notificationPagination, per });
            }}
          />
        </>
      );
    }

    return null;
  };

  return (
    <I18nProviderWrapper>
      <div className={`content profile-container ${classes.notificationsPage}`} id="notifications-page">
        <div className={classes.notificationsHeader}>
          <div>
            <h1><Trans>Notifications</Trans></h1>
            <h3 className="sub-title">
              {notificationsBlurb}
            </h3>
          </div>
          <div className={classes.filterNotifications}>
            <ListDropdown
              anchorElementLabel={t`Filter by`}
              className={classes.notificationsFilter}
              onChange={({ value }: ListOption): void => {
                setFilterType(value as string);
                setNotificationPagination({ ...notificationPagination, currentPage: 1 });
              }}
              openOnClick
              options={filterOptions}
              value={filterType}
              virtualizeOptions={false}
            />
          </div>
        </div>
        {getBodyByFetchState()}
      </div>
    </I18nProviderWrapper>
  );
};
