import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { userHasAdminPrivileges } from '@helpers/share-links';
import { Collection } from '@helpers/show_page_helpers';
import BulkShareModal from '../bulk_actions/BulkShareModal';

const InternalShareBar = ({ allAssetKeys }) => {
  const pageAssetCount = allAssetKeys.size;
  const unfilteredRedirect = window.location.href.split("?")[0];
  const urlSearchParams = new URLSearchParams(window.location.search);
  const digest = Object.fromEntries(urlSearchParams).digest;
  const openModal = Object.fromEntries(urlSearchParams).show_edit_share_mode === "true";

  const [showShareModal, toggleShareModal] = useState(openModal);

  const shareEditPermission = new URLSearchParams(window.location.search).has('user_can_edit')
    || userHasAdminPrivileges(BFG.currentUser?.role, BFG.currentUser?.su);
  const renderShareEditLink = () => (
    shareEditPermission ? (
      <div className="link" onClick={() => toggleShareModal(true)}>
        <span aria-hidden="true" className="bff-edit" /> Edit Share Link
      </div>
    ) : null
  );

  if (digest && pageAssetCount > 0) {
    return (
      <div className="internal-share-bar">
        { showShareModal ? (
          <BulkShareModal
            closeModal={() => toggleShareModal(false)}
            selectedAssetKeys={allAssetKeys}
          />
        ) : ''}

        <h3> Share Link showing {pageAssetCount}
          {` asset${pageAssetCount > 1 ? 's' : ''} `}
          from {`${Collection.key ? "Collection" : "Brandfolder"}`}
        </h3>
        {renderShareEditLink()}
        <a className="link" href={unfilteredRedirect}>
          View all assets in {`${Collection.key ? "Collection" : "Brandfolder"}`}
        </a>
      </div>
    );
  }

  return null;
};

InternalShareBar.propTypes = {
  allAssetKeys: PropTypes.instanceOf(Set).isRequired
};

export default InternalShareBar;
