import { CircleLoader, LoaderSizes, brandfolderColorPalette } from '@brandfolder/react';
import { Trans, plural, t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ToastLooks, useCreateToast } from '@components/common/toast';

import { LoadingOverlay } from '../common/LoadingOverlay';
import { MergeFacesDialog } from '../common/MergeFacesDialog';
import { Pagination } from '../common/Pagination';
import { PeopleTagsDropdown } from '../common/PeopleTagsDropdown';
import { RetagDialog } from '../common/RetagDialog';
import { PER_PAGE_COUNT } from '../constants';
import { Person, PersonDetails, PersonDetailsParamKeys, } from '../customPeopleTagTypes';
import { useFetchPersonDetailsData, useMergeFaces, useRemoveFaceTag, useUpdatePersonTag, } from '../fetch';
import { usePersonDetailsQueryState } from '../hooks/usePersonDetailsQueryState';

import { PersonAssetCard } from './PersonAssetCard';
import { PersonDetailsHeader } from './PersonDetailsHeader';

const PersonDetailsPageWrapper = styled.div`
  margin: 40px auto;
  max-width: 1608px;
  width: 93%;

  .bf-loader__circle {
    height: 50vh;
  }
`;

const ActionBarWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-bottom: 36px;

  .heading {
    color: ${brandfolderColorPalette.neutrals[500]};
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }

  .dropdown-button,
  ul#sort-dropdown-list {
    width: 244px;
    z-index: 2;
  }
`;

const MatchingPhotosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;

  > * {
    margin: 0 20px 20px 0;
  }

  img {
    cursor: pointer;
    max-height: 252px;
  }
`;

export const PersonDetailsPage: FunctionComponent = () => {
  const [selectedAsset, setSelectedAsset] = useState<PersonDetails>(undefined);
  const [showRetagDialog, setShowRetagDialog] = useState(false);
  const [retagPersonLabel, setRetagPersonLabel] = useState('');
  const [showMergeFacesDialog, setShowMergeFacesDialog] = useState(false);
  const [targetPerson, setTargetPerson] = useState<Person>(undefined);

  const [state, setState] = usePersonDetailsQueryState();
  const { personDetailsData, personDetailsLoading, refetchPersonDetails } = useFetchPersonDetailsData();
  const { removeFaceTag, removedTag, removeTagLoading, removeTagSuccess, resetTag } = useRemoveFaceTag();
  const { updatePersonTag, updatePersonTagLoading } = useUpdatePersonTag();
  const { mergeFaces, mergeFacesLoading } = useMergeFaces();

  const { id: personId } = useParams();
  const { toast } = useCreateToast();
  const navigate = useNavigate();
  const perPage = PER_PAGE_COUNT;

  useEffect(() => {
    if (removedTag && !removeTagLoading && removeTagSuccess) {
      if (mergeFacesLoading || updatePersonTagLoading) return;
      if (!!removedTag.cluster_deleted) {
        navigate(`/${BFG.brandfolder_slug}/people_tags`);
        toast(t`Person removed`, ToastLooks.Success);
      } else {
        refetchPersonDetails();
        setTargetPerson(undefined);
        setRetagPersonLabel('');
        resetTag();
      }
    }
  }, [removedTag, removeTagLoading, removeTagSuccess, mergeFacesLoading, updatePersonTagLoading]);

  useEffect(() => {
    if (removeTagSuccess && retagPersonLabel && !targetPerson) {
      updatePersonTag(removedTag.new_person_id, retagPersonLabel);
    }
  }, [removeTagSuccess, retagPersonLabel, removedTag?.new_person_id]);

  useEffect(() => {
    if (removeTagSuccess && targetPerson) {
      mergeFaces(targetPerson.person_id, removedTag.new_person_id);
    }
  }, [removeTagSuccess, targetPerson, removedTag?.new_person_id]);

  const setSortByState = (key: string): void => {
    setState({ [PersonDetailsParamKeys.Sort]: key });
  };

  const handleTagAsDifferentPerson = (person: PersonDetails): void => {
    setShowRetagDialog(true);
    setSelectedAsset(person);
  };

  const handleMergeFaces = (): void => {
    setShowMergeFacesDialog(false);
    if (targetPerson) {
      removeFaceTag(selectedAsset.match_face_id, personId);
    } else {
      mergeFaces(targetPerson.person_id, personId);
    }
  };

  const handleSelectTargetPerson = (person: Person): void => {
    setTargetPerson(person);
    setShowRetagDialog(false);
    setShowMergeFacesDialog(true);
  };

  const handleConfirmRetag = (): void => {
    removeFaceTag(selectedAsset.match_face_id, personId);
    setShowRetagDialog(false);
  };

  const initialPersonDetailsLoading = personDetailsLoading && !personDetailsData?.data.length;
  const refetchingPersonDetails = personDetailsLoading && !!personDetailsData?.data.length;

  return (
    <>
      <PersonDetailsPageWrapper>
        <PersonDetailsHeader />
        <ActionBarWrapper>
          <span className="heading">
            <Trans>MATCHING PHOTOS</Trans>
          </span>
          <PeopleTagsDropdown
            selectedValue={state.sort}
            setSelectedValue={setSortByState}
            type="sort"
          />
        </ActionBarWrapper>
        <Pagination
          page={Number(state.page) || 1}
          perPage={perPage}
          setPage={setState}
          text={plural(personDetailsData?.meta.total_count, {
            one: 'photo',
            other: 'photos',
          })}
          total={personDetailsData?.meta.total_count}
        />
        {initialPersonDetailsLoading ? (
          <CircleLoader label="person-details-data" size={LoaderSizes.Large} />
        ) : (
          <MatchingPhotosWrapper>
            {!!personDetailsData?.data.length &&
              personDetailsData?.data.map((person: PersonDetails) => (
                <PersonAssetCard
                  key={`${person.match_face_id}-${person.asset_id}`}
                  person={person}
                  removeFaceTag={(): void => { removeFaceTag(person.match_face_id, personId); }}
                  retagPersonAsset={(): void => handleTagAsDifferentPerson(person)}
                />
              ))}
          </MatchingPhotosWrapper>
        )}
      </PersonDetailsPageWrapper>
      {showRetagDialog && (
        <RetagDialog
          closeDialog={(): void => setShowRetagDialog(false)}
          handleOnSave={handleConfirmRetag}
          handleSelectTargetPerson={handleSelectTargetPerson}
          isOpen={showRetagDialog}
          personLabel={retagPersonLabel}
          selectedAsset={selectedAsset}
          setPersonLabel={setRetagPersonLabel}
        />
      )}
      {showMergeFacesDialog && (
        <MergeFacesDialog
          currentPersonThumbnail={selectedAsset?.gcs_path}
          handleCancelMerge={(): void => setShowMergeFacesDialog(false)}
          handleMerge={handleMergeFaces}
          isOpen={showMergeFacesDialog}
          targetPersonThumbnail={targetPerson?.face_uri}
        />
      )}
      {(removeTagLoading ||
        mergeFacesLoading ||
        refetchingPersonDetails ||
        (updatePersonTagLoading && retagPersonLabel)
      ) && <LoadingOverlay />}
    </>
  );
};
