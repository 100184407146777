import { Trans } from '@lingui/macro';
import React from 'react';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { BackendPortalCard, PortalCardSubTypes } from '@api/v4/private/PortalCardTypes';

import Link from './link';

interface LinkSidebarProps {
  editMode: boolean;
  links: ApiResponseObject<BackendPortalCard>[];
  tiles: ApiResponseObject<BackendPortalCard>[];
  triggerCardsRefresh: () => void;
}

const LinkSidebar: React.FunctionComponent<LinkSidebarProps> = ({
  editMode,
  links,
  tiles,
  triggerCardsRefresh
}) => {
  const sortBySubTypeAndPostion = (subType: PortalCardSubTypes): ApiResponseObject<BackendPortalCard>[] => (
    links.filter((link) => link.attributes.sub_type === subType)
      .sort((a, b) => (a.attributes.position !== undefined && b.attributes.position !== undefined && a.attributes.position > b.attributes.position ? 1 : -1))
  );

  const internalLinks = sortBySubTypeAndPostion(PortalCardSubTypes.InternalLink);
  const externalLinks = sortBySubTypeAndPostion(PortalCardSubTypes.ExternalLink);

  const renderLinks = (linksSubset: ApiResponseObject<BackendPortalCard>[]): React.ReactNode => (
    /* eslint-disable @typescript-eslint/camelcase */
    linksSubset.map((link, i) => {
      const { name, link_url, icon_url } = link.attributes;
      return (
        <Link
          key={link.id}
          draggedIndex={i}
          editMode={editMode}
          iconUrl={icon_url || ''}
          linkUrl={link_url || ''}
          name={name}
          portalCardId={link.id}
          triggerCardsRefresh={triggerCardsRefresh}
        />
      );
    })
  );

  return (
    <aside
      className={`link-sidebar bf-scroll-element ${editMode ? 'edit' : ''} ${tiles.length >= 6 && 'remove-max-height'}`}
    >
      {internalLinks.length > 0 && (
        <div className="link-sidebar__portal-links">
          <h3 className="h3-bold-styling"><Trans>Other Portals</Trans></h3>
          {renderLinks(internalLinks)}
        </div>
      )}
      {externalLinks.length > 0 && (
        <div className="link-sidebar__external-links">
          <h3 className="h3-bold-styling"><Trans>Additional Links</Trans></h3>
          {renderLinks(externalLinks)}
        </div>
      )}
    </aside>
  );
};

export default LinkSidebar;
