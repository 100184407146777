import { t } from '@lingui/macro';

import { FontData } from '@api/v4/assets/assetTypes';

export const getFontSource = (assetData: FontData): string => {
  if (assetData.web_font_id && assetData.web_font_type === 'google') {
    return t`Google Fonts`;
  }

  if (assetData.web_font_id && assetData.web_font_type === 'adobe') {
    return t`Adobe Fonts`;
  }

  return 'Fonts.com';
};

export const getFontUrl = (assetData: FontData, assetName: string): string => {
  const fontName = assetName.replace(/\s+/g, '+').toLowerCase();
  const fontId = assetData.web_font_id.replace(/\s+/g, '+');

  if (assetData.web_font_id && assetData.web_font_type === 'google') {
    return `https://fonts.google.com/specimen/${fontId}`;
  }

  if (assetData.web_font_type === 'adobe') {
    return `https://fonts.adobe.com/search?query=${fontName}`;
  }

  if (assetData.web_font_id && assetData.web_font_type === 'adobe') {
    return `https://edgewebfonts.adobe.com/fonts#/?collection=${assetData.web_font_id}`;
  }

  return `http://www.fonts.com/search/all-fonts?searchtext=${fontName}`;
};
