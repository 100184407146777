import React, {
  FunctionComponent,
  LiHTMLAttributes,
} from 'react';

import BaseDropdownItem from '@components/library/dropdown_item/base/BaseDropdownItem';
import { CheckableDropdownItemProps } from '@components/library/dropdown_item/DropdownItemProps';
import { StandardCheckbox } from '@components/library/checkbox';
import styleClassNames from './checkable-dropdown-item.module.scss';

const CheckableDropdownItem: FunctionComponent<CheckableDropdownItemProps & LiHTMLAttributes<HTMLLIElement>> = ({
  children,
  id,
  isDisabled,
  isSelected,
  name,
  onChoose,
  tabIndex,
  ...baseDropdownItemProps
}) => {
  const checkableChildren = (
    <StandardCheckbox
      checked={isSelected}
      disabled={isDisabled}
      dontWrapText
      id={id || name?.toString()}
      labelCopy={children}
      name={name?.toString()}
      tabIndex={tabIndex}
    />
  );

  const baseProps = {
    children: checkableChildren,
    id,
    isDisabled,
    isSelected,
    name,
    onChoose,
    styleClassNames,
    variantName: 'checkable',
    ...baseDropdownItemProps,
  };

  return <BaseDropdownItem {...baseProps} />;
};

export default CheckableDropdownItem;
