import fetchJSON from '@api/api_helper';

const url = (id) => (`/api/v4/share_manifests/${id}`);

export const get = (manifestDigest) => (
  fetchJSON(url(manifestDigest))
    .then(({ data }) => data)
    .catch(async (response) => { throw (await response.json()); })
);

export const update = ({
  key,
  assetKeys: asset_keys,
  availability_end = null,
  expires = false,
  internal = false,
  name = '',
  require_identification = false,
  view_only = false,
  notifications_optin = false,
  gallery_view = false,
  custom_logo_url = null
}) => (
  fetchJSON(url(key), {
    method: 'PUT',
    body: {
      data: {
        attributes: {
          asset_keys,
          availability_end,
          expires,
          internal,
          name,
          require_identification,
          view_only,
          notifications_optin,
          gallery_view,
          custom_logo_url
        }
      }
    }
  })
    .then((response) => response)
    .catch(async (response) => { throw (await response.json()); })
);

export const destroy = (key) => (
  fetchJSON(url(key), {
    method: 'DELETE',
  })
    .then((response) => response)
    .catch(async (response) => { throw (await response.json()); })
);
