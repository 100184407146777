import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from '../styles/dialog_footer.module.scss';

interface DialogFooterProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
}

/**
 * Dialog footer
 * @param {DialogFooterProps} props DialogFooterProps
 */
export const DialogFooter: FunctionComponent<DialogFooterProps> = (props) => {
  const { children, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={classnames("dialog--footer", classes["dialog--footer"], divProps.className)}
    >
      {children}
    </div>
  );
};
