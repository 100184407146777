import { t } from '@lingui/macro';

import { ApiDatumResponse, ApiDataResponse } from '@api/v4/ApiResponseTypes';

export type PortalCardsResponse = ApiDataResponse<BackendPortalCard>;

export type PortalCardResponse = ApiDatumResponse<BackendPortalCard>;

export enum PortalCardTypes {
  PortalTile = 'PortalTile',
  PortalLink = 'PortalLink',
  PortalWidget = 'PortalWidget'
}

export enum PortalCardSubTypes {
  EmbeddedFormTile = 'embedded_form_tile',
  ExternalLink = 'external_link',
  FeatureTaggedAssetsWidget = 'featured_tagged_assets',
  InternalLink = 'internal_link',
  NavigationTile = 'navigation_tile',
  RecentlyCreatedAssetsWidget = 'recently_created_assets',
  RecentlyUpdatedAssetsWidget = 'recently_updated_assets',
  TopScoredAssetsWidget = 'top_scored_assets'
}

export enum DisplayWidths {
  OneThird = 'one-third',
  TwoThirds = 'two-thirds',
  Full = 'full'
}

export interface WidgetResource {
  key: string;
  type: string;
  name?: string;
}

export interface PortalCard {
  name: string;
  type: PortalCardTypes;
  buttonText?: string | null;
  description?: string;
  displayWidth?: DisplayWidths;
  iconUrl?: string;
  imageUrl?: string | null;
  linkUrl?: string | null;
  position?: number;
  subType?: PortalCardSubTypes | null;
  srcUrl?: string | null;
  widgetResource?: WidgetResource;
}

export interface BackendPortalCard {
  name: string;
  type: PortalCardTypes;
  button_text?: string | null;
  description?: string;
  display_width?: DisplayWidths;
  icon_url?: string;
  image_url?: string | null;
  link_url?: string | null;
  position?: number;
  src_url?: string | null;
  sub_type?: PortalCardSubTypes | null;
}

export enum ValidationErrorsEnum {
  DescriptionLengthExceeded,
  InvalidLink,
  InvalidResource,
  MissingButtonText,
  MissingSrcUrl,
  MissingLink,
  MissingName,
  MissingSubType,
  MissingResource,
  NameLengthExceeded,
  SrcUrlNotAllowed
}

export const validationErrorMessages = (): Record<ValidationErrorsEnum, string> => ({
  [ValidationErrorsEnum.DescriptionLengthExceeded]: t`Description length exceeded`,
  [ValidationErrorsEnum.InvalidLink]: t`Invalid link url`,
  [ValidationErrorsEnum.InvalidResource]: t`Invalid Brandfolder`,
  [ValidationErrorsEnum.MissingButtonText]: t`Button text required`,
  [ValidationErrorsEnum.MissingLink]: t`Link required`,
  [ValidationErrorsEnum.MissingName]: t`Name required`,
  [ValidationErrorsEnum.MissingSrcUrl]: t`URL is required`,
  [ValidationErrorsEnum.MissingSubType]: t`Please select a type`,
  [ValidationErrorsEnum.MissingResource]: t`A Brandfolder is required`,
  [ValidationErrorsEnum.NameLengthExceeded]: t`Name length exceeded`,
  [ValidationErrorsEnum.SrcUrlNotAllowed]: t`URL not allowed`
});
