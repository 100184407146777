import { InputLabelPositions, StandardSwitch } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useState, useEffect } from 'react';

import { useFetch } from '@api/ApiHelper';
import { AssetsResponse } from '@api/v4/assets/assetTypes';

import { PrimaryButton } from '@components/library/button';
import { DialogSizes, StandardDialog } from '@components/library/dialog';
import { getArrayIncludesOtherArray } from '@helpers/arrays';
import { bulkRefreshSections } from '@helpers/show_page_helpers';

import classes from './styles/bulk-view-only-dialog.module.scss';

interface BulkViewOnlyDialogProps {
  open: boolean;
  selectedAssetKeys: string[];
  selectedViewOnlyAssetKeys: string[];

  setOpen: (open: boolean) => void;
}

const initialViewOnly = true;

export const BulkViewOnlyDialog: FunctionComponent<BulkViewOnlyDialogProps> = ({
  selectedAssetKeys,
  selectedViewOnlyAssetKeys,
  open,
  setOpen,
}) => {
  const [viewOnly, setViewOnly] = useState(initialViewOnly);

  const updateViewOnlyAssets = useFetch<AssetsResponse>({
    body: {
      data: {
        asset_keys: selectedAssetKeys,
        view_only: viewOnly,
      }
    },
    fetchOnMount: false,
    method: 'PUT',
    url: '/api/v4/bulk_actions/assets/view_only'
  });

  useEffect(() => {
    if (updateViewOnlyAssets.response) {
      bulkRefreshSections(selectedAssetKeys);
      setViewOnly(initialViewOnly);
      setOpen(false);
    }

  }, [updateViewOnlyAssets.response]);

  useEffect(() => {
    if (updateViewOnlyAssets.error) {
      Notify.create({
        title: t`Something went wrong. Try again soon!`,
        type: 'error'
      });
    }
  }, [updateViewOnlyAssets.error]);

  useEffect(() => {
    if (selectedViewOnlyAssetKeys.length > 0) {
      setViewOnly(!getArrayIncludesOtherArray<string>(selectedViewOnlyAssetKeys, selectedAssetKeys));
    }
  }, [selectedAssetKeys, selectedViewOnlyAssetKeys]);

  return (
      <StandardDialog
        dialogBodyClassName={classes.dialogBody}
        dialogClassName={classes.dialog}
        id="bulk-view-only-dialog"
        open={open}
        setOpen={setOpen}
        showFooter={false}
        size={DialogSizes.Auto}
        title={t`View only mode`}
        titleIcon="bff-show"
      >
        <div>
          <p className={classes.p}>
            <Trans>Disable downloads for users. Administrators will be notified if a user requests a download.</Trans>
          </p>
          <StandardSwitch
            className={classes.switch}
            id="view-only-toggle"
            isChecked={viewOnly}
            labelPosition={InputLabelPositions.Right}
            onChange={(): void => {
              setViewOnly((prevState) => (!prevState));
            }}
          >
            <Trans>Disable downloads (view only mode)</Trans>
          </StandardSwitch>
          <div className={classes.buttonGroup}>
            <PrimaryButton
              className={classes.button}
              isLoading={updateViewOnlyAssets.loading}
              onClick={(): void => {
                updateViewOnlyAssets.fetch();
              }}
            >
              <Trans>Save Changes</Trans>
            </PrimaryButton>
          </div>
        </div>
      </StandardDialog>
  );
};
