import { t } from '@lingui/macro';

import fetchJSON from '@api/api_helper';

/**
 * @deprecated Use fetchWithPolling instead!
 */
export const downloadPoller = (pollerUrl, options, interval) => {
  const downloadIndicator = document.getElementsByClassName('download_progress_indicator')[0];
  const indicatorText = downloadIndicator.querySelector('span');

  if (interval > 27000) {
    // 27,000ms corresponds to ~5 minutes total elapsed time
    // for reference, 3000ms -> 30s, 6000ms -> ~1 minute, 11000ms -> ~2 minutes, 17000 -> ~3 minutes, 22000ms -> 4 minutes
    Notify.create({
      title: t`We're working on preparing your download.`,
      body: t`We'll send a notification when it's ready.`,
      type: 'help'
    });
    downloadIndicator.classList.remove('active');
    downloadIndicator.style.visibility = "hidden";
    throw Error;
  }

  if (interval < 1000) {
    indicatorText.innerHTML =t`Hang tight! We're preparing your download...`;
  } else if (interval > 1000 && interval < 2000) {
    indicatorText.innerHTML =t`Still working...`;
  } else if (interval > 2000 && interval < 4000) {
    indicatorText.innerHTML =t`No really, still working on it...`;
  } else if (interval > 4000 && interval < 6000) {
    indicatorText.innerHTML =t`We haven't given up...`;
  } else if (interval > 6000) {
    indicatorText.innerHTML =t`We'll send a notification when this is complete.`;
  }

  fetchJSON(pollerUrl, options, null, true).then(({ response, json }) => {
    const { status } = response;
    if (status === 200) {
      window.location = json.download_url;
      setTimeout(() => {
        downloadIndicator.classList.remove('active');
        downloadIndicator.style.visibility = "hidden";
      }, 1000);
    } else if (status === 202) {
      // No immediate download, update UI & keep polling
      downloadIndicator.style.visibility = "visible";
      downloadIndicator.classList.add('active');
      const newInterval = interval * 1.1;
      setTimeout(() => {
        downloadPoller(pollerUrl, options, newInterval);
      }, newInterval);
    }
  }).catch(() => {
    downloadIndicator.classList.remove('active');
    downloadIndicator.style.visibility = "hidden";
    Notify.create({
      title: t`Could not convert file`,
      body: t`Sorry, the file could not be converted.`,
      type: 'error'
    });
  });
};
