import React, { FunctionComponent, ReactNode } from 'react';

import { BaseDialogProps } from '@components/library/dialog';
import { BaseDialog } from '@components/library/dialog/base_dialog';

type BaseDialogPropsExtended = Omit<BaseDialogProps, 'position' | 'type'>

interface StandardDialogProps extends BaseDialogPropsExtended {
    children: ReactNode;
}

/**
 * A modal dialog for displaying navs, forms, alerts, error messages, etc.
 * @param {StandardDialogProps} props StandardDialogProps
 */
export const StandardDialog: FunctionComponent<StandardDialogProps> = (props) => {
  const { children, ...baseProps } = props;

  return (
    <BaseDialog {...baseProps}>
      {children}
    </BaseDialog>
  );
};
