import React, { FunctionComponent } from 'react';

import { PreviewType, AttachmentWithViewOnly } from '@api/v4/attachments/attachmentTypes';
import VideoJSPlayer from '@components/video/videojs_player/main';

const FallbackMuxUrl = 'https://stream.mux.com/K3udqiR00NYegByHgUngUOhtPdO6oZ9ZN.m3u8';

export const MuxVideoPreview: FunctionComponent<AttachmentWithViewOnly> = ({
  cdn_url: cdnUrl,
  mimetype,
  mux_hls_url: muxHlsUrl,
  preview_type: previewType,
  url: edgeUrl,
  view_only: viewOnly
}) => {
  let videoOptions = {
    sources: [
      {
        src: muxHlsUrl,
        type: 'application/x-mpegURL'
      }
    ]
  };

  // When an MP4 updates to view_only, CDN url is invalidated thus breaking the preview, fallback to edge url
  if (viewOnly && previewType === PreviewType.VideoOther && edgeUrl) {
    videoOptions = {
      sources: [
        {
          src: edgeUrl,
          type: mimetype
        },
        {
          src: edgeUrl,
          type: 'video/mp4'
        }
      ]
    };
  } else if (previewType === PreviewType.VideoOther && cdnUrl) {
    videoOptions = {
      sources: [
        {
          src: cdnUrl,
          type: mimetype
        },
        // Chrome doesn't support quicktime, so provide the MP4 fallback
        {
          src: cdnUrl,
          type: 'video/mp4'
        }
      ]
    };
  } else if (!muxHlsUrl) {
    // then show processing video
    // blank when processing on-demand video
    videoOptions = {
      sources: [
        {
          src: FallbackMuxUrl,
          type: 'application/x-mpegURL'
        }
      ]
    };
  }

  return (
    <div
      className="m-show-full-size-display s-expand-view"
      data-private
      data-testid="asset-preview--video-player"
      id="advanced-video"
    >
      <VideoJSPlayer
        key={cdnUrl}
        className="m-show-full-size-display"
        {...videoOptions}
      />
      ;
    </div>
  );
};
