/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import PresetTableRow from './preset_table_row';

import './styles/preset_table.scss';

const PresetTable = ({
  items,
  onPresetChange,
}) => {
  const listItems = items.map(({
    id,
    name,
    aspect_width,
    aspect_height,
    width,
    height,
    format,
  }) => (
    <PresetTableRow
      key={id}
      aspectHeight={aspect_height}
      aspectWidth={aspect_width}
      format={format}
      height={height}
      id={id}
      name={name}
      onRemove={onPresetChange}
      width={width}
    />
  ));

  return (
    <ul className="cropping--preset-table">
      <li className="headers">
        <h3 className="forty"><Trans>Name</Trans></h3>
        <h3 className="ten center"><Trans>Aspect Ratio</Trans></h3>
        <h3 className="ten center"><Trans>Width (px)</Trans></h3>
        <h3 className="ten center"><Trans>Height (px)</Trans></h3>
        <h3 className="ten center"><Trans>Filetype</Trans></h3>
        <span />
      </li>
      {listItems}
    </ul>
  );
};

PresetTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    aspect_width: PropTypes.number,
    aspect_height: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    format: PropTypes.string,
  })),
  onPresetChange: PropTypes.func,
};

PresetTable.defaultProps = {
  items: [],
  onPresetChange: () => {},
};

export default PresetTable;
