import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface CustomTemplatesApprovalSettingProps {
  defaultEnabled: boolean | null;
}

const CustomTemplatesApprovalsSetting: FunctionComponent<CustomTemplatesApprovalSettingProps> = ({
  defaultEnabled
}) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled || false}
      description={t`Customized templates must be approved by an administrator before end users are able to download or save to Brandfolder`}
      errorTitle={t`Error updating custom templates approval setting. Please contact support.`}
      id="custom-templates-approvals-required"
      successTitle={t`Custom templates approval setting updated!`}
      title={t`Require administrator approval on custom templates`}
      updateResourceKey="customTemplatesApprovalsRequired"
    />
  </I18nProviderWrapper>
);

export default CustomTemplatesApprovalsSetting;
