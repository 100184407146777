import { removeFileExtension } from '@brandfolder/utilities';
import { t, Trans } from '@lingui/macro';
import { PickerDisplayMode, PickerResponse } from 'filestack-js/build/main/lib/picker';
import React, { useEffect, useRef, useState, FunctionComponent, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useFetch } from '@api/ApiHelper';
import { StructuredFileMetadata } from '@api/uploaders';
import { AssetCreateBodyAttributes, AssetsResponse } from '@api/v4/assets/assetTypes';
import { CustomFieldValue, FlattenedCustomFieldKeyValuesMap } from '@api/v4/assets/customFieldTypes';
import { DependentCustomField } from '@api/v4/resources/CustomFieldKeysTypes';
import {
  CustomFieldKeyValueRow
} from '@components/asset/modal/tabs/edit/main_pane/custom_fields/custom-field-row/CustomFieldKeyValueRow';
import { useFilestackPicker } from '@components/common/custom_hooks/useFilestackPicker';
import { addFiles } from '@components/common/filestack_uploader/helpers';
import { BFLoader } from '@components/common/loader/main';
import { PrimaryButton, TertiaryButton } from '@components/library/button';
import { StandardDialog, DialogSizes } from '@components/library/dialog';
import { FontIcons } from '@components/library/icon';
import {
  isMissingCustomFieldValue,
  putCustomFields
} from '@components/show_page/sections/asset/required-custom-fields-dialog-helper';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';
import { removeUnderscores } from '@helpers/humanize';

export interface RequiredCustomFieldsDialogProps {
  customFieldKeys: FlattenedCustomFieldKeyValuesMap;
  dependentCustomFields: DependentCustomField[];
  libraryName: string;
  onNewAssets: (response: AssetsResponse) => void;
  onAssetProcessing: (quantity: number) => void;
  sectionKey: string;
  setShowRequiredCustomFieldsDialog: SetStateDispatch<boolean>;
  showRequiredCustomFieldsDialog: boolean;
  activeLabelKey?: string;
}

const cleanName = (filename: string): string => removeUnderscores(removeFileExtension(filename)).trim();

enum RequiredCustomFieldsDialogSteps {
  CloseWarning,
  CreatingAssets,
  CustomFields,
  Upload
}

export const RequiredCustomFieldsDialog: FunctionComponent<RequiredCustomFieldsDialogProps> = ({
  activeLabelKey,
  customFieldKeys: flattenedCustomFieldKeys,
  dependentCustomFields,
  libraryName,
  onNewAssets,
  onAssetProcessing,
  sectionKey,
  setShowRequiredCustomFieldsDialog,
  showRequiredCustomFieldsDialog
}) => {
  const customFieldKeys = Object.keys(flattenedCustomFieldKeys);
  const [customFieldsMap, setCustomFieldsMap] = useState<FlattenedCustomFieldKeyValuesMap>(flattenedCustomFieldKeys);
  const [step, setStep] = useState(RequiredCustomFieldsDialogSteps.CustomFields);
  const [files, setFiles] = useState<Record<string, StructuredFileMetadata[]>>({});

  const assetCreateBodyAttributes = (): AssetCreateBodyAttributes[] => {
    const fileIndices = Object.keys(files);
    return fileIndices.map((fileIndex) => ({
      attachments: [...files[fileIndex]],
      ...activeLabelKey && { labels: [activeLabelKey] },
      name: cleanName(files[fileIndex][0].filename)
    }));
  };

  const createAssetsFetch = useFetch<AssetsResponse>({
    url: `/api/v4/${BFG.resource.type}s/${BFG.resource.key}/assets?fast_jsonapi=true&queue_priority=high`,
    fetchOnMount: false,
    method: 'POST',
    body: {
      data: {
        attributes: assetCreateBodyAttributes()
      },
      section_key: sectionKey // eslint-disable-line @typescript-eslint/naming-convention
    }
  });

  const listContainerRef = useRef(null);
  const instructionsCopy = <Trans>Please complete the following required Custom Fields before uploading assets to this {libraryName}.</Trans>;
  const tempValuePrefix = 'create-cfv';

  const onUpload = (filestackFiles: StructuredFileMetadata[] | Record<string, StructuredFileMetadata[]>): void => {
    if (Array.isArray(filestackFiles)) {
      const mappedFiles = {};
      filestackFiles.forEach((filestackFile, i) => {
        mappedFiles[i] = [filestackFile];
      });
      setFiles(mappedFiles);
    } else {
      // files with the same name have been merged
      setFiles(filestackFiles);
    }
  };

  const onUploadDone = (pickerResponse: PickerResponse): void => {
    addFiles(pickerResponse, onUpload);
  };

  const { loading, picker, render } = useFilestackPicker({
    onUploadDone,
    pickerOptions: {
      container: '.required-custom-fields-dialog__step-2',
      displayMode: PickerDisplayMode.inline
    }
  });

  const dependentCustomFieldsMap = dependentCustomFields?.reduce((acc, dcf) => {
    acc[dcf.child_key] = dcf;
    return acc;
  }, {} as DependentCustomField);
  const dependentFieldIds = dependentCustomFieldsMap && Object.keys(dependentCustomFieldsMap);
  const filteredCustomFieldKeys = !dependentFieldIds?.length ?
    customFieldKeys.filter((cfkId) => customFieldsMap[cfkId]?.customFieldKey.required) :
    customFieldKeys.filter((cfkId) => {
      const parentKey = dependentCustomFieldsMap[cfkId]?.parent_key;
      const parentField = parentKey && customFieldsMap[parentKey];
      const parentValues = parentField?.customFieldValues?.map((cfv) => cfv.value) || [];
      return (
        customFieldsMap[cfkId]?.customFieldKey.required &&
        !dependentFieldIds?.includes(cfkId) ||
        parentValues?.includes(dependentCustomFieldsMap[cfkId]?.value)
      );
    });

  useEffect(() => {
    if (!showRequiredCustomFieldsDialog) {
      setCustomFieldsMap(flattenedCustomFieldKeys);
      setStep(RequiredCustomFieldsDialogSteps.CustomFields);
    }
  }, [showRequiredCustomFieldsDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customFieldKeys.some((requiredKey, i) => requiredKey !== Object.keys(customFieldsMap)[i])) {
      setCustomFieldsMap(flattenedCustomFieldKeys);
    }
  }, [flattenedCustomFieldKeys]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (step === RequiredCustomFieldsDialogSteps.Upload) {
      render();
    } else if (picker) {
      picker.close();
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (picker) {
      picker.open();
    }
  }, [picker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(files).length > 0) {
      onAssetProcessing(Object.keys(files).length);
      createAssetsFetch.fetch();
      setStep(RequiredCustomFieldsDialogSteps.CreatingAssets);
    }
  }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

  const beforeClose = (forceClose?: boolean): boolean => {
    const hasTouched = customFieldKeys.find((requiredKey) => (
      customFieldsMap[requiredKey].customFieldTouched
    ));

    if (!forceClose && hasTouched && step !== RequiredCustomFieldsDialogSteps.CloseWarning) {
      if (picker) {
        picker.close();
      }
      setStep(RequiredCustomFieldsDialogSteps.CloseWarning);
      return false;
    }

    setShowRequiredCustomFieldsDialog(false);
    return true;
  };

  useEffect(() => {
    if (createAssetsFetch.response) {
      const assetKeys = createAssetsFetch.response.data.map((assetResponse) => assetResponse.id);
      const keysWithValues = customFieldKeys.filter((key) => !!customFieldsMap[key]?.customFieldValues?.length);
      const customFieldPromises = putCustomFields(keysWithValues, customFieldsMap, assetKeys);

      onNewAssets(createAssetsFetch.response);
      onAssetProcessing(0);
      Promise.all(customFieldPromises).catch(() => {
        // note that Promise.all returns a single error as soon
        // as one promise is rejected, if we want
        // more granular error tracking on the failures
        // we'll need to handle each request individually
        Notify.create({
          type: 'error',
          title: t`There was a problem applying Custom Fields to your Assets. Please update them manually.`
        });
        sendAction(TrackedAction.RequiredCustomFieldsCreateError);
      }).finally(() => {
        // we show an error when there's a problem but still close the dialog
        beforeClose(true /* forceClose */);
      });
    }
  }, [createAssetsFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createAssetsFetch.error) {
      // display error
      Notify.create({
        type: 'error',
        title: t`There was a problem uploading your files. Please try again or contact support.`
      });
      sendAction(TrackedAction.RequiredCustomFieldsAssetUploadError);
      // reset file state but not custom fields
      setFiles({});
      // go back to uploader
      setStep(RequiredCustomFieldsDialogSteps.Upload);
      onAssetProcessing(0);
    }
  }, [createAssetsFetch.error]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreate = (
    customFieldKeyId: string | undefined = `${tempValuePrefix}-${uuidv4()}`,
    customFieldValue: CustomFieldValue | CustomFieldValue[] | undefined = undefined
  ): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    if (customFieldsMapCopy[customFieldKeyId]) {
      if (Array.isArray(customFieldValue)) {
        const customFieldValues = customFieldValue.map((field) => (
          {
            key: field.key || `${tempValuePrefix}-${uuidv4()}`,
            value: field.value
          }
        ));
        customFieldsMapCopy[customFieldKeyId] = {
          ...customFieldsMap[customFieldKeyId],
          customFieldTouched: true,
          customFieldValues
        };
      } else {
        customFieldsMapCopy[customFieldKeyId] = {
          ...customFieldsMap[customFieldKeyId],
          customFieldTouched: true,
          customFieldValues: [
            ...customFieldsMap[customFieldKeyId].customFieldValues,
            {
              key: customFieldValue?.key || `${tempValuePrefix}-${uuidv4()}`,
              value: customFieldValue?.value || ''
            }
          ]
        };
      }
    }

    setCustomFieldsMap(customFieldsMapCopy);
  };

  const genHandleCreate = (customFieldKeyId: string) => (customFieldValue: CustomFieldValue): void => {
    handleCreate(customFieldKeyId, customFieldValue);
  };

  const genHandleDelete = (customFieldKeyId: string) => (customFieldValue: CustomFieldValue): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    const valuesCopy: CustomFieldValue[] = [];
    customFieldsMap[customFieldKeyId].customFieldValues.forEach((cfv) => {
      if (cfv.key !== customFieldValue.key) {
        valuesCopy.push(cfv);
      }
    });
    customFieldsMapCopy[customFieldKeyId] = {
      ...customFieldsMap[customFieldKeyId],
      customFieldValues: valuesCopy,
      customFieldTouched: true
    };
    setCustomFieldsMap(customFieldsMapCopy);
  };

  const genHandleUpdate = (customFieldKeyId: string) => (customFieldValue: CustomFieldValue): void => {
    if (!customFieldValue.key) {
      return handleCreate(customFieldKeyId, customFieldValue);
    }

    const customFieldsMapCopy = { ...customFieldsMap };
    const valuesCopy = customFieldsMapCopy[customFieldKeyId].customFieldValues.map((cfv) => {
      if (cfv.key === customFieldValue.key) {
        return customFieldValue;
      }

      return cfv;
    });

    // clear out both value AND the temp key if the value is empty
    const resetSingleEntryValues = valuesCopy.length === 1 && !valuesCopy[0].value;
    customFieldsMapCopy[customFieldKeyId] = {
      ...customFieldsMap[customFieldKeyId],
      customFieldValues: resetSingleEntryValues ? [] : valuesCopy,
      customFieldTouched: true
    };

    return setCustomFieldsMap(customFieldsMapCopy);
  };

  const handleRemoveValues = (removeCustomFieldKeyId: string): void => {
    const customFieldsMapCopy = { ...customFieldsMap };
    customFieldsMapCopy[removeCustomFieldKeyId] = {
      ...customFieldsMap[removeCustomFieldKeyId],
      customFieldValues: []
    };
    const childFields = dependentCustomFields?.filter((dcf) => dcf.parent_key === removeCustomFieldKeyId);
    childFields?.forEach((childField) => {
      const childKey = childField.child_key;
      customFieldsMapCopy[childKey] = {
        ...customFieldsMap[childKey],
        customFieldValues: []
      };
    });
    setCustomFieldsMap(customFieldsMapCopy);
  };

  const handleNextStep = (): void => {
    let missingRequiredField = false;
    const customFieldsMapCopy: FlattenedCustomFieldKeyValuesMap = {};
    customFieldKeys.forEach((requiredKey) => {
      customFieldsMapCopy[requiredKey] = {
        ...customFieldsMap[requiredKey],
        customFieldTouched: true
      };
      if (!missingRequiredField
        && customFieldsMap[requiredKey]?.customFieldKey.required
        && filteredCustomFieldKeys.includes(customFieldsMap[requiredKey]?.customFieldKey.id)
      ) {
        missingRequiredField = isMissingCustomFieldValue(customFieldsMap[requiredKey]);
      }
    });

    if (!missingRequiredField) {
      setStep(RequiredCustomFieldsDialogSteps.Upload);
    } else {
      setCustomFieldsMap(customFieldsMapCopy);
    }
  };

  const renderCustomFieldsBody = (): ReactNode => (
    <>
      <p className="required-custom-fields-dialog__instructions">{instructionsCopy}</p>
      <>
        <header className="custom-fields-list--heading">
          <div className="custom-fields-list--heading--key"><p><Trans>Key</Trans></p></div>
          <div className="custom-fields-list--heading--values"><p><Trans>Values</Trans></p></div>
        </header>
        <ul
          ref={listContainerRef}
          className="custom-field-list bf-scroll-element"
        >
          {customFieldsMap && filteredCustomFieldKeys.map((id) => (
            <CustomFieldKeyValueRow
              key={id}
              controlledCustomFieldsEnabled
              customFieldKey={{
                attributes: flattenedCustomFieldKeys[id]?.customFieldKey,
                id,
                type: 'custom_field_keys'
              }}
              existingCustomFieldMap={customFieldsMap[id]}
              handleCreate={genHandleCreate(id)}
              handleDelete={genHandleDelete(id)}
              handleRemoveValues={handleRemoveValues}
              handleUpdate={genHandleUpdate(id)}
              overflowParentRef={listContainerRef}
              // Always say translating is false
              // whatever locale they are adding in will
              // create in the default locale as well
              translating={false}
            />
          ))}
        </ul>
      </>
    </>
  );

  const renderCustomFieldsFooter = (): ReactNode => (
    <div className="required-custom-fields-dialog__next-step--container">
      <PrimaryButton
        className="required-custom-fields-dialog__next-step"
        onClick={handleNextStep}
        size="small"
      >
        <Trans>Next Step</Trans>
      </PrimaryButton>
    </div>
  );

  const renderCloseWarningBody = (): ReactNode => (
    <div className="required-custom-fields-dialog__close-warning--body">
      <h3 className="required-custom-fields-dialog__close-warning--title">
        <Trans>Are you sure you want to cancel your upload?</Trans>
      </h3>
      <p className="required-custom-fields-dialog__close-warning--content">
        <Trans>Custom Fields are required to upload new assets into this {libraryName}.</Trans>
      </p>
    </div>
  );

  const renderCloseWarningFooter = (): ReactNode => (
    <div className="required-custom-fields-dialog__close-warning--footer">
      <TertiaryButton
        className="required-custom-fields-dialog__close-warning--button"
        onClick={(): boolean => beforeClose(true)}
        size="small"
      >
        <Trans>Yes, cancel upload</Trans>
      </TertiaryButton>
      <PrimaryButton
        className="required-custom-fields-dialog__close-warning--button"
        onClick={(): void => setStep(RequiredCustomFieldsDialogSteps.CustomFields)}
        size="small"
      >
        <Trans>No, return to Custom Fields</Trans>
      </PrimaryButton>
    </div>
  );

  const renderUpload = (): ReactNode => (
    <div className={`required-custom-fields-dialog__step-2${loading ? ' required-custom-fields-dialog__step-2--loading' : ''}`}>
      {loading && <BFLoader />}
    </div>
  );

  const renderCreatingAssets = (): ReactNode => (
    <div className="required-custom-fields-dialog__creating-assets">
      <BFLoader />
      <p><Trans>Please wait while upload completes.</Trans></p>
    </div>
  );

  const renderStepBodies = (): ReactNode => {
    switch (step) {
      case RequiredCustomFieldsDialogSteps.CloseWarning:
        return renderCloseWarningBody();
      case RequiredCustomFieldsDialogSteps.Upload:
        return renderUpload();
      case RequiredCustomFieldsDialogSteps.CreatingAssets:
        return renderCreatingAssets();
      default:
        return renderCustomFieldsBody();
    }
  };

  const renderStepFooters = (): ReactNode => {
    switch (step) {
      case RequiredCustomFieldsDialogSteps.CloseWarning:
        return renderCloseWarningFooter();
      case RequiredCustomFieldsDialogSteps.CustomFields:
        return renderCustomFieldsFooter();
      default:
        return null;
    }
  };

  const dialogClassName = (): string => {
    if (step === RequiredCustomFieldsDialogSteps.Upload) {
      return 'required-custom-fields-dialog required-custom-fields-dialog--upload';
    }

    return 'required-custom-fields-dialog';
  };

  const dialogTitle = (): string | undefined => {
    switch (step) {
      case RequiredCustomFieldsDialogSteps.CustomFields:
        return t`Add required Custom Fields`;
      case RequiredCustomFieldsDialogSteps.Upload:
        return t`Upload files`;
      default:
        return undefined;
    }
  };

  const titleIcon = (): string | undefined => {
    switch (step) {
      case RequiredCustomFieldsDialogSteps.CustomFields:
        return `bff-${FontIcons.CustomField}`;
      case RequiredCustomFieldsDialogSteps.Upload:
        return `bff-${FontIcons.Ingest}`;
      default:
        return undefined;
    }
  };

  const dialogSize = (): DialogSizes => {
    switch (step) {
      case RequiredCustomFieldsDialogSteps.CreatingAssets:
        return DialogSizes.Xxsmall;
      case RequiredCustomFieldsDialogSteps.CloseWarning:
        return DialogSizes.Medium;
      default:
        return DialogSizes.Large;
    }
  };

  const noFooterSteps = [RequiredCustomFieldsDialogSteps.Upload, RequiredCustomFieldsDialogSteps.CreatingAssets];
  const noTitleSteps = [RequiredCustomFieldsDialogSteps.CloseWarning, RequiredCustomFieldsDialogSteps.CreatingAssets];

  return (
    <StandardDialog
      beforeClose={beforeClose}
      contentCentered={step === RequiredCustomFieldsDialogSteps.CloseWarning}
      dialogClassName={dialogClassName()}
      dialogHeaderClassName={step === RequiredCustomFieldsDialogSteps.CreatingAssets
        ? 'required-custom-fields-dialog--hide-header'
        : ''}
      footer={renderStepFooters()}
      id="required-custom-fields-dialog"
      open={showRequiredCustomFieldsDialog}
      setOpen={setShowRequiredCustomFieldsDialog}
      showClose={step !== RequiredCustomFieldsDialogSteps.CreatingAssets}
      showFooter={!noFooterSteps.includes(step)}
      showTitle={!noTitleSteps.includes(step)}
      size={dialogSize()}
      title={dialogTitle()}
      titleIcon={titleIcon()}
    >
      {renderStepBodies()}
    </StandardDialog>
  );
};
