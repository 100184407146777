import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from '../styles/dialog_wrapper.module.scss';

interface DialogWrapperProps extends HTMLAttributes<HTMLDivElement> {
    /** children is required */
    children: ReactNode;
}

/**
 * Dialog wrapper controls vertical and horizontal centering
 * @param {DialogWrapperProps} props DialogWrapperProps
 */
export const DialogWrapper: FunctionComponent<DialogWrapperProps> = (props) => {
  const { children, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={classnames(
        "dialog--wrapper",
        classes["dialog--wrapper"],
        divProps.className
      )}
      role="presentation"
    >
      {children}
    </div>
  );
};
