import React, { FunctionComponent } from 'react';

import UsageInfoCard, {
  InfoCard,
} from '@components/limits/usage_info_card/UsageInfoCard';

interface SubscriptionInfoCardsProps {
  subscriptionInfoCards: InfoCard[];
}

const SubscriptionInfoCards: FunctionComponent<SubscriptionInfoCardsProps> = ({
  subscriptionInfoCards,
}) => {
  const subscriptionsOrderedByEnabled = subscriptionInfoCards.sort(
    (a, b) => Number(b.isEnabled) - Number(a.isEnabled)
  );

  return (
    <div className="subscription__infoCards">
      {subscriptionsOrderedByEnabled.map((card) => (
        <UsageInfoCard key={card.title} {...card} />
      ))}
    </div>
  );
};

export default SubscriptionInfoCards;
