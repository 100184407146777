/* eslint-disable @typescript-eslint/naming-convention */

/**
 * User roles that can appear in BFG.currentUser.role but are
 * not recognized as valid user roles on the backend
 * Anonymous users locally are "guest" with an empty user_id.
 * Anonymous users on production are "none".
 */
export enum ExtendedUserRoles {
  Empty = '',
  None = 'none',
}

/**
 * Roles that are accepted as valid on the backend
 */
export enum UserRoles {
  Admin = 'admin',
  Collaborator = 'collaborator',
  Guest = 'guest',
  Owner = 'owner'
}

export type BFGUserRoles = UserRoles | ExtendedUserRoles;

export type UserDevices = 'mobile-view' | 'tablet-view' | 'desktop-view';

/**
 * Defined in app/views/shared/_js_globals.html.erb
 */
export interface BFGCurrentUser {
  authorizedAsGuest: boolean | null;
  company: string;
  device: UserDevices;
  email: string;
  firstName: string;
  lastName: string;
  role: BFGUserRoles;
  scope: string;
  su: boolean;
  user_id: string;
}

export interface User {
  email: string;
  first_name?: string; // may not present on InsightsSession for an event
  last_name?: string; // may not present on InsightsSession for an event
  superuser?: boolean; // may not present on InsightsSession for an event
  user_key?: string; // may not present on InsightsSession for an event
}
