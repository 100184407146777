import { Submit } from '@components/asset/modal/tabs/edit/EditTabTypes';

export const updatedAssetRequiredSubmission = {
  action: Submit.UpdatedAsset,
  data: {
    name: "asset",
    attachments: [{
      key: "new-attachment-key",
      filename: "new_attachment.jpg",
      url: "new_attachment.jpg",
      position: 0
    }]
  }
};
