import { getInputFormatLocale, validateDate } from '@brandfolder/react';

import { AssetTypes } from '@api/v4/assets/assetTypes';
import { getCustomFieldChanges } from '@components/asset/modal/tabs/edit/change_detection/custom-fields-change-detection';
import { containsSpecialCharacters } from '@helpers/custom-field-validation';

import { InitialModalEditFormContentState, ModalEditFormContentState, ValidationErrors } from './EditTabTypes';
import { determineUGTLocale, getHasMissingRequiredCustomFields, isValidHex } from './helpers';
import { ASSET_MODAL_CUSTOM_FIELDS_TAB } from './main_pane/genericFileEditForm';

const validateName = (assetType, { assetName }: ModalEditFormContentState): ValidationErrors[] => {
  if (!assetName && assetType !== AssetTypes.Font) {
    return [ValidationErrors.MissingAssetName];
  }

  return [];
};

const validateDescription = (assetType, { assetDescription }: ModalEditFormContentState): ValidationErrors[] => {
  if (assetType === AssetTypes.Text && !assetDescription) {
    return [ValidationErrors.MissingDescription];
  }

  return [];
};

const validateTaskDescription = ({ task }: ModalEditFormContentState): ValidationErrors[] => {
  if (task && task.taskDescription && task.taskDescription.length > 5000) {
    return [ValidationErrors.DescriptionMaxLengthExceeded];
  }

  return [];
};

const validateTaskDueDate = ({ task }: ModalEditFormContentState): ValidationErrors[] => {
  if (task) {
    const inputFormatLocale = getInputFormatLocale(determineUGTLocale());
    const { valid } = validateDate(inputFormatLocale.inputFormat.format, task.dueDate);

    if (!!task.dueDate && !valid) {
      return [ValidationErrors.InvalidDueDate];
    }
  }

  return [];
};

const validateUrl = (assetType, { externalMediumData }: ModalEditFormContentState): ValidationErrors[] => {
  if (assetType === AssetTypes.ExternalMedium) {
    // TODO: do we want to validate that it's a valid url?
    if (!externalMediumData || !externalMediumData.url) {
      return [ValidationErrors.MissingMediaUrl];
    }
  }

  return [];
};

const validateFontInput = (assetType, { fontData }: ModalEditFormContentState): ValidationErrors[] => {
  if (assetType === AssetTypes.Font) {
    if (!fontData || (!fontData.other_font_id && !fontData.web_font_id)) {
      return [ValidationErrors.MissingFontInput];
    }
  }

  return [];
};

const validateHexInput = (assetType, { colorData }: ModalEditFormContentState): ValidationErrors[] => {
  if (assetType === AssetTypes.Color) {
    if (colorData && colorData.hex) {
      if (!isValidHex(colorData.hex)) {
        return [ValidationErrors.InvalidHexValue];
      }
    }
  }

  return [];
};

const validateRequiredCustomFields = (assetType, { flattenedCustomFieldKeyValuesMap }: ModalEditFormContentState): ValidationErrors[] => {
  if (
    assetType === AssetTypes.GenericFile
    && flattenedCustomFieldKeyValuesMap
    && Object.keys(flattenedCustomFieldKeyValuesMap).length > 0) {
    if (getHasMissingRequiredCustomFields(flattenedCustomFieldKeyValuesMap)) {
      const customFieldTab = document.querySelector(`.${ASSET_MODAL_CUSTOM_FIELDS_TAB}`);
      if (customFieldTab && !customFieldTab.classList.contains('t-active')) {
        (customFieldTab as HTMLButtonElement).click();
      }
      return [ValidationErrors.MissingRequiredCustomFields];
    }
  }

  return [];
};

const validateCustomFieldsKeyNames = (editState: ModalEditFormContentState, initialData: InitialModalEditFormContentState): ValidationErrors[] => {
  const { createdCustomFieldKeyValuePears = [] } = getCustomFieldChanges(editState, initialData) ?? {};

  const keysWithSpecialCharacters = createdCustomFieldKeyValuePears?.some(({ customFieldKey }) => containsSpecialCharacters(customFieldKey.name));

  return keysWithSpecialCharacters ? [ValidationErrors.InvalidKeyName] : [];
};

const validate = (assetType: AssetTypes, editState: ModalEditFormContentState, initialData: InitialModalEditFormContentState): ValidationErrors[] => (
  [
    ...validateName(assetType, editState),
    ...validateDescription(assetType, editState),
    ...validateTaskDescription(editState),
    ...validateTaskDueDate(editState),
    ...validateUrl(assetType, editState),
    ...validateFontInput(assetType, editState),
    ...validateHexInput(assetType, editState),
    ...validateRequiredCustomFields(assetType, editState),
    ...validateCustomFieldsKeyNames(editState, initialData)
  ]
);

export default validate;
