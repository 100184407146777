import classnames from 'classnames';
import React, { ElementType, FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classes from './styles/card.module.scss';

interface CardPairingProps extends HTMLAttributes<HTMLOrSVGElement> {
  children: ReactNode;
  tag?: ElementType;
}

export const CardPairing: FunctionComponent<CardPairingProps> = (props) => {
  const { children, className, tag = 'div', ...otherProps } = props;
  const Tag = tag;
  return (
    <Tag {...otherProps} className={classnames(classes.cardPairing, className)}>
      {children}
    </Tag>
  );
};
