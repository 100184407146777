import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import { AutoFocusInside } from 'react-focus-lock';

import { BFLoaderOverlay } from '@components/common/loader/main';

import classes from '../styles/dialog_body.module.scss';

interface DialogBodyProps extends HTMLAttributes<HTMLDivElement> {
    contentCentered?: boolean;
    /** children is required */
    children: ReactNode;
    /** show a loader while data fetches */
    loading?: boolean;
}

/**
 * Dialog body
 * @param {DialogBodyProps} props DialogBodyProps
 */
export const DialogBody: FunctionComponent<DialogBodyProps> = (props) => {
  const { contentCentered, children, loading, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={classnames(
        "dialog--body",
        classes["dialog--body"],
        contentCentered && classes["dialog--body__centered"],
        loading && classes["dialog--body__loading"],
        divProps.className
      )}
    >
      <AutoFocusInside>
        {children}
        {loading && <BFLoaderOverlay />}
      </AutoFocusInside>
    </div>
  );
};
