import { t, Trans } from '@lingui/macro';
import React, { useContext, useEffect, useState, FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { UserDataResponse } from '@api/v4/brandfolders/UsersTypes';
import assetModalContext from '@components/asset/modal/tabs/edit/asset_modal_context';
import { ActionTypes } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { TaskDetailsSectionScopedContext } from '@components/asset/modal/tabs/edit/main_pane/WorkspaceTypes';
import { StandardAvatar } from '@components/library/avatar';
import { StandardCheckboxGroup } from '@components/library/checkbox_group';
import { StandardSearchField, searchStatus } from '@components/library/search-field';
import { AvatarUser } from '@components/library/utils';
import { getUserDisplayName } from '@helpers/user';

import './styles/_workspace-details.scss';

const url = `/api/v4/collections/${BFG.resource.key}/users`;

const errorNotification = (): void => {
  Notify.create({
    title: t`There was an error listing team members. Please try again.`,
    type: 'error'
  });
};

export const WorkspaceUserSection: FunctionComponent = () => {
  const { state, dispatch }: TaskDetailsSectionScopedContext = useContext(assetModalContext);
  const { task } = state.editState;
  const { taskUsers } = task;

  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState<AvatarUser[]>([]);

  const usersFetch = useFetch<UserDataResponse>({ params: { exclude_parent_resources: true }, url }); // eslint-disable-line @typescript-eslint/naming-convention

  const searchedUsers = users.filter((user) => (
    `${user.email}${user.name}`?.toUpperCase()?.includes(searchValue?.toUpperCase())
  ));

  const displayedUsers = searchedUsers.map((user) => {
    const userSelected = taskUsers.includes(user.id);

    return {
      // initital taskUsers gets populated from
      // app/javascript/components/asset/modal/tabs/edit/main.tsx
      // when opening an asset dialog/modal
      checked: userSelected,
      id: user.id,
      labelCopy: (
        <>
          <span className="workspace-dialog__users--name">{user.name}</span>
          <StandardAvatar
            className="user-"
            email={user.email}
            name={user.name}
            selected={userSelected}
            size={32}
          />
        </>
      ),
    };
  });

  const handleCheckboxChange = (selectedUserId: string): void => {
    const updatedPayload = [...taskUsers];
    if (updatedPayload.includes(selectedUserId)) {
      updatedPayload.splice(updatedPayload.findIndex((userId) => userId === selectedUserId), 1);
    } else {
      updatedPayload.push(selectedUserId);
    }
    dispatch({
      type: ActionTypes.UpdateTask,
      payload: {
        task: {
          ...task,
          taskUsers: updatedPayload
        }
      }
    });
  };

  useEffect(() => {
    if (usersFetch.response) {
      const avatarUsers: AvatarUser[] = usersFetch.response.data.map((user) => ({
        email: user.attributes.email,
        id: user.id,
        name: getUserDisplayName(user.attributes)
      }));
      setUsers(avatarUsers);
    }
  }, [usersFetch.response]);

  useEffect(() => {
    if (usersFetch.error) errorNotification();
  }, [usersFetch.error]);

  return (
    <div className="workspace-dialog__users">
      <StandardSearchField
        ariaSearchLabel={t`Users search`}
        className="workspace-dialog__users--search"
        id="create-workspace-search"
        label={t`Assign to`}
        onChange={(e: InputChangeEvent): void => { setSearchValue(e.target.value); }}
        placeholder={t`Search users`}
        searchStatus={searchStatus({ resultQuantity: searchedUsers.length, resultsShown: searchValue.length > 0 })}
        showLabel
        value={searchValue}
      />
      <StandardCheckboxGroup
        checkboxes={displayedUsers}
        className="workspace-dialog__users--selections bf-scroll-element"
        name="workspace-users"
        onChange={handleCheckboxChange}
      />
      {users.length > 0 && displayedUsers.length === 0 && searchValue !== '' && (
        <p className="users-display-fallback">
          <Trans>No results to display</Trans>
        </p>
      )}
      {users.length === 0 && usersFetch.response && (
        <p className="users-display-fallback">
          <Trans>No users found</Trans>
        </p>
      )}
    </div>
  );
};
