import React from 'react';
import PropTypes from 'prop-types';

import { setStorage, StorageTypes } from '@helpers/storage';

const ExpandSidebarButton = ({ setCollapsed }) => {
  const handleToggle = () => {
    setCollapsed((prevState) => {
      setStorage(StorageTypes.Local, 'assetModalAttachmentsSidebarCollapsed', !prevState);
      BF.fx.dispatchWindowEvent('updateSidebarCollapsed', null, { isCollapsed: !prevState });
      return !prevState;
    });
  };

  return (
    <button
      className="expand-sidebar-button"
      onClick={handleToggle}
      type="button"
    >
      <span className="bff-arrow-right" />
      <span className="bff-arrow-left" />
    </button>
  );
};

ExpandSidebarButton.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
};

export default ExpandSidebarButton;
