import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useEffect } from 'react';

import { useFetch } from '@api/ApiHelper';
import {
  InsightsEventActions,
  InsightsEventTypes,
  InsightsEventsListApiDataResponse
} from '@api/insights/v1/events';
import { ShareManifestServer } from '@api/v4/share_manifests/ShareManifestTypes';
import { localizeDateTime } from '@helpers/localize';
import { getUserDisplayName } from '@helpers/user';

import { Alert } from '../alert';
import { Card, CardHeader, CardLoader } from '../card';

import classes from './styles/insights-events.module.scss';

interface InsightsEventsProps {
  manifest: ShareManifestServer;
}

export const InsightsEvents: FunctionComponent<InsightsEventsProps> = (props) => {
  const { manifest } = props;
  const { key } = manifest;

  // NOTE: Insights does not like decimal seconds in ISO dates for end_date and start_date
  // For example, in 2021-04-19T12:00:00.001Z, the ".001Z" part causes Insights to bomb
  const endDate = `${new Date().toISOString().split('.')[0]}`;
  // 7 days ago
  const startDate = `${new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('.')[0]}`;

  const dates = `end_date=${endDate}&start_date=${startDate}`;
  const include = '&include=session';
  const limit = '&limit=50';
  const permissionLevel = `&permission_level=${BFG.currentUser.role}`;
  const source = `&source[resource_key]=${BFG.resource.key}&source[resource_type]=${BFG.resource.type}&source[${InsightsEventTypes.SHARE_MANIFEST}_key]=${key}`;

  const getShareManifestEvents = useFetch<InsightsEventsListApiDataResponse>({
    fetchOnMount: false,
    method: 'GET',
    url: `${BFG.INSIGHTS_API_URL}/api/v1/events?${dates}${include}${limit}${permissionLevel}${source}`
  });

  useEffect(() => {
    getShareManifestEvents.fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card>
      <CardHeader showBorder={false}>
        <h6 className={classes.h6}><Trans>Share link activity</Trans></h6>
      </CardHeader>
      {getShareManifestEvents.loading && <CardLoader />}
      {getShareManifestEvents.response && getShareManifestEvents.response.data.length > 0 && getShareManifestEvents.response.data.map((event) => {
        const sessionId = event.relationships?.session.data.id;
        if (sessionId && getShareManifestEvents.response.included) {
          // we need to use find here because there may be only one session for many events
          // (we can't do getShareManifestEvents.response.included[index] because it's not a 1:1 of events to sessions)
          const session = getShareManifestEvents.response.included.find((s) => s.id === sessionId);
          if (session) {
            const displayName = getUserDisplayName(session.attributes.user);
            const actionText = event.attributes.data.action_name === InsightsEventActions.VIEWED
              ? <Trans><strong>{displayName}</strong> viewed this share link</Trans>
              : <Trans><strong>{displayName}</strong> downloaded assets from this share link</Trans>;
            const icon = event.attributes.data.action_name === InsightsEventActions.VIEWED ? 'bff-show' : 'bff-download';
            return (
              <div key={event.id} className={classes.event}>
                <span aria-hidden className={classnames(icon, classes.eventIcon)} role="img" />
                <p className={classes.eventDetail}>
                  <span>{actionText}</span>
                  <time className={classes.eventTime} dateTime={event.attributes.created_at}>
                    {localizeDateTime(event.attributes.created_at)}
                  </time>
                </p>
              </div>
            );
          }
          return null;
        }
        return null;
      })}
      {getShareManifestEvents.response && getShareManifestEvents.response.data.length === 0 && (
        <p className={classes.p}><Trans>There is no activity yet. Please check back later.</Trans></p>
      )}
      {getShareManifestEvents.error && (
        <Alert
          id="share-manifest-insights-events-error"
          message={(
            <p className={classes.p}><Trans>There was an error loading activity. Please refresh the page to try again.</Trans></p>
          )}
          type="attention"
        />
      )}
    </Card>
  );
};
