import React, { FunctionComponent, SyntheticEvent } from 'react';

import { handleThumbnailError } from '@components/asset/modal/tabs/edit/helpers';

import { AttachmentThumbnailImage } from '../../shared/AttachmentThumbnailImage';

interface AttachmentImageProps {
  assetKey: string;
  assetName: string;
  attachmentCount: number;
  attachmentExtension: string;
  src: string;
  isTask?: boolean;
}

const AttachmentImage: FunctionComponent<AttachmentImageProps> = (props) => {
  const attachmentThumbnail = (
    <div className="edit-tab-sidebar-attachment-image-container">
      <AttachmentThumbnailImage
        alt={props.assetName}
        className="edit-tab-sidebar-attachment-image"
        onError={(e: SyntheticEvent<HTMLImageElement, Event>): void => handleThumbnailError(
          e,
          props.attachmentExtension,
          props.assetName,
          true, /* isLargeView */
          undefined, /* isHoverImage */
          props.isTask, /* isTask */
        )}
        src={props.src}
      />
    </div>
  );

  return (
    <div className="edit-tab-sidebar-attachment-container">
      {attachmentThumbnail}
      <div className="edit-tab-sidebar-attachment-asset-details">
        <span className="asset-name">{props.assetName}</span>
        <div className="file-type-pill">
          <span aria-label="hidden" className="bff-paperclip attachment-count" />
          <span className="count">{props.attachmentCount}</span>
        </div>
      </div>
    </div>
  );
};

export default AttachmentImage;
