import React, { FunctionComponent, ReactElement } from 'react';

import classes from '@components/bulk_management/automation/form/styles/non-interactive-section.module.scss';

interface NonInteractiveSectionprops {
  children: ReactElement;
  /** when "false", makes section non-interactive via "disabled" attribute and "pointer-events: none" css */
  isInteractive: boolean;
}

export const NonInteractiveSection: FunctionComponent<NonInteractiveSectionprops> = ({
  children,
  isInteractive
}) => (
  isInteractive
    ? children
    : (
      <fieldset
        className={classes['non-interactive-section']}
        disabled
      >
        {children}
      </fieldset>
    )
);
