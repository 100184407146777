import { Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent, ReactNode } from 'react';

import { update as updateResource } from '@api/v4/resources/resources';

import classes from './styles/settings-enable-disable.module.scss';

interface SettingsEnableDisableProps {
  defaultEnabled: boolean | null;
  errorTitle: string;
  id: string;
  successTitle: string;
  title: string;
  updateResourceKey: string;
  description?: ReactNode;
}

export const SettingsEnableDisable: FunctionComponent<SettingsEnableDisableProps> = (props) => {
  const { defaultEnabled, description, errorTitle, id, successTitle, title, updateResourceKey } = props;

  const [enabled, setEnabled] = useState(defaultEnabled || false);
  const [mounted, setMounted] = useState(false);

  const handleSubmit = async (): Promise<void> => {
    try {
      await updateResource({
        key: BFG?.resource?.key,
        resourceType: BFG?.resource?.type,
        [updateResourceKey]: enabled
      });

      Notify.create({
        title: successTitle,
        type: 'success'
      });
    } catch {
      Notify.create({
        title: errorTitle,
        type: 'error'
      });
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      handleSubmit();
    }
  }, [enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.container}>
      <div className="advanced-tab-settings__row">
        <div className="quarter-column">
          <p>{title}</p>
          {description && <p className="advanced-setting-description">{description}</p>}
        </div>
        <div className="three-quarters-column">
          <div className="advanced-tab-settings__options">
            <div className="radio-button-container">
              <input
                checked={enabled}
                // adding no-trigger will prevent the aggressive unsaved
                // changes function alertOfUnsavedChanges from running on these autosaves
                className="radio-margin no-trigger"
                id={`${id}-setting-enabled`}
                name={`${id}-setting-enabled-disabled`}
                onChange={(): void => setEnabled(true)}
                type="radio"
                value="true"
              />
              <label htmlFor={`${id}-setting-enabled`}>
                <Trans>Enabled</Trans>
              </label>
            </div>
            <div className="radio-button-container">
              <input
                checked={!enabled}
                // adding no-trigger will prevent the aggressive unsaved
                // changes function alertOfUnsavedChanges from running on these autosaves
                className="radio-margin no-trigger"
                id={`${id}-setting-disabled`}
                name={`${id}-setting-enabled-disabled`}
                onChange={(): void => setEnabled(false)}
                type="radio"
                value="false"
              />
              <label htmlFor={`${id}-setting-disabled`}>
                <Trans>Disabled</Trans>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
