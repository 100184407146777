/* eslint-disable @typescript-eslint/naming-convention */
import { getResourceIsShareLink } from '@helpers/resource';
import { User } from '@api/v4/UserTypes';
import { Annotation, AnnotationsApiResponse } from '@api/v4/annotations/AnnotationTypes';

export interface ProcessAnnotationResponse {
  history: AnnotationExtended[];
  users: { [userId: string]: User }
}

interface AnnotationExtended extends Annotation{
  id: string,
  author: string,
  parent: string,
  version: string,
  type: 'annotation' | 'comment'
}

interface ResourceContext {
  resourceType: string;
  resourceKey: string;
}

export const processAnnotationResponse = (
  response: AnnotationsApiResponse
): ProcessAnnotationResponse => {
  const responseData = Array.isArray(response.data) ? response.data : [response.data];

  const history = !responseData.length ? [] : responseData.map((annotation) => {
    const { author, parent, version } = annotation.relationships;
    const annotationExtended: AnnotationExtended = {
      id: annotation.id,
      author: author && author.data ? author.data.id : '',
      parent: parent && parent.data ? parent.data.id : '',
      version: version.data.id,
      type: annotation.attributes.x_offset_in_percentage ? 'annotation' : 'comment',
      ...annotation.attributes,
    };
    return annotationExtended;
  });

  const users = {};
  if (response.included && response.included.length) {
    response.included.forEach((includedItem) => {
      if (includedItem.type === 'users') {
        users[includedItem.id] = { ...includedItem.attributes };
      }
    });
  }

  return { history, users };
};

export const getResourceContext = (): ResourceContext => {
  if (getResourceIsShareLink()) {
    return { resourceType: 'share_manifest', resourceKey: BFG.manifest?.key };
  }

  return { resourceType: BFG.resource.type, resourceKey: BFG.resource.key };
};
