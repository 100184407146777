import React from "react";
import PropTypes from "prop-types";
import FontInput from "./font_input";

import { ListDropdown } from '@components/library/dropdown';

class TextDropdown extends React.Component {
  state = {
    value: this.props.frame.value,
    fontSize: null
  }

  componentDidMount() {
    this.setValue(); // Respect pagination updates
  }

  setValue = () => {
    const { textFrameData, frame } = this.props;
    if (textFrameData[frame.id] && textFrameData[frame.id].value) {
      this.setState({ value: textFrameData[frame.id].value });
    }
  }

  triggerFrameUpdate = () => {
    const { frame, updateFrameData } = this.props;
    const { value, fontSize } = this.state;
    updateFrameData({ [frame.id]: { value, font_size: fontSize } });
  }

  handleValueChange = (value) => {
    this.setState({ value }, () => {
      this.triggerFrameUpdate();
    });
  }

  updateFontSize = (fontSize) => {
    this.setState({ fontSize: parseInt(fontSize, 10) }, () => {
      this.triggerFrameUpdate();
    });
  }

  formattedOptions = () => (
    Object.values(this.props.options).map((value) => (
      { value, label: value }
    ))
  );

  render() {
    const { frame, fontSettings, templateSettings } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
        <div className="input-headers">
          <label className="bf-label bf-label--primary" htmlFor={frame.id}>
            {frame.name === "$ID/" ? "" : frame.name}
          </label>
          <FontInput
            fontSettings={fontSettings}
            frame={frame}
            templateSettings={templateSettings}
            updateFontSize={this.updateFontSize}
          />
        </div>
        <ListDropdown
          className="text-dropdown-select"
          onChange={(option) => this.handleValueChange(option.value)}
          options={this.formattedOptions()}
          value={value}
          virtualizeOptions={false}
        />
      </React.Fragment>
    );
  }
}

TextDropdown.propTypes = {
  frame: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
  }).isRequired,
  options: PropTypes.shape({
    0: PropTypes.string
  }),
  updateFrameData: PropTypes.func.isRequired,
  fontSettings: PropTypes.shape({}).isRequired,
  templateSettings: PropTypes.shape({
    allowFontSizing: PropTypes.bool
  }).isRequired,
  textFrameData: PropTypes.shape({}),
};

TextDropdown.defaultProps = {
  options: {},
  textFrameData: {}
};

export default TextDropdown;
