import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

import { PlainCard as Card } from "../../show_page/sections/asset/card";
import AssetModalContentWrap from "../modal/orgSearchResultsAssetModalContentWrap";
import { useConfigurableExpiryUrls } from '../modal/tabs/asset_details/helpers/constants'

class AssetCard extends React.Component {
  state = {
    attachments: [],
    showModal: false
  };

  handleSelectionClick = (e) => {
    const { asset, position, shiftSelect, toggleSelected } = this.props;

    e.stopPropagation();
    e.persist();

    const viewOnlyAssetKeys = asset.attributes?.view_only ? asset.id : undefined

    if (e.shiftKey) {
      shiftSelect(asset.id, position, viewOnlyAssetKeys);
    } else if (toggleSelected) {
      toggleSelected({
        assetKeys: asset.id,
        viewOnlyAssetKeys
      });
    }
  };

  showModal = () => {
    const { asset } = this.props;

    if (BFG.embedView && asset.type === "texts") {
      this.postEmbedMessageText();
    } else {
      let attachmentFields = 'best_metadata,other_metadata,download_url,thumbnail_url,view_thumbnail_retina,extension,thumbnailed,position'
      if (useConfigurableExpiryUrls) {
        attachmentFields += ',configurable_expiry_url,configurable_expiry_thumbnail_url,configurable_expiry_view_thumbnail_retina';
      }
      $.ajax({
        context: this,
        type: "GET",
        url: `/api/v4/assets/${
          asset.id
        }/attachments?fields=${attachmentFields}`,
        beforeSend: (xhr) => {
          xhr.setRequestHeader("Authorization", `Bearer ${BF_Token}`);
        },
        success: (response) => {
          const sortedAttachments = response.data.sort((a, b) => (
            a.attributes.position > b.attributes.position ? 1 : -1
          ));
          this.setState({ attachments: sortedAttachments, showModal: true });
        }
      });
    }
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  postEmbedMessageText() {
    const { asset } = this.props;
    // printuiFrameId defined in Rails controller/view
    const attachmentEmbedInfo = {
      key: asset.id,
      name: asset.attributes.name,
      type: "embed",
      url: asset.attributes.thumbnail_url,
      displayUrl: asset.attributes.thumbnail_url,
      description: asset.attributes.description,
      text: true,
      printuiFrameId: window.printuiFrameId,
    };

    window.parent.postMessage(attachmentEmbedInfo, '*');
  }

  tagNames() {
    return this.props.tags.map((tag) => tag.attributes.name);
  }

  render() {
    const { asset, brandfolder, libraryName, selected, tags } = this.props;

    return (
      <React.Fragment>
        <Modal
          bsSize="large"
          className="modal-dialog modal-lg modal-asset"
          onHide={this.closeModal}
          show={this.state.showModal}
        >
          <button
            className="close close-modal"
            data-dismiss="modal"
            onClick={this.closeModal}
            type="button"
          >
            <span aria-label="close" className="bff-close" />
          </button>
          <AssetModalContentWrap
            asset={asset}
            attachments={this.state.attachments}
            brandfolder={brandfolder}
            closeModal={this.closeModal}
            libraryName={libraryName}
            tags={tags}
          />
        </Modal>
        <div id={asset.id}>
          <Card
            alwaysOpenInModal
            asset={asset}
            editable={false}
            handleSelectionClick={this.handleSelectionClick}
            list={false}
            oldTypes
            onVisitAsset={this.showModal}
            selected={selected}
          />
        </div>
      </React.Fragment>
    );
  }
}

AssetCard.propTypes = {
  asset: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
      view_only: PropTypes.bool
    }),
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  brandfolder: PropTypes.shape({}).isRequired,
  libraryName: PropTypes.string,
  position: PropTypes.number,
  selected: PropTypes.bool,
  shiftSelect: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  toggleSelected: PropTypes.func,
};

AssetCard.defaultProps = {
  libraryName: 'Brandfolder'
}

export default AssetCard;
