// see node_modules/react-laag/dist/PlacementType.d.ts
export enum Placements {
  BottomCenter = 'bottom-center',
  BottomEnd = 'bottom-end',
  BottomStart = 'bottom-start',
  Center = 'center',
  LeftCenter = 'left-center',
  LeftEnd = 'left-end',
  LeftStart = 'left-start',
  RightCenter = 'right-center',
  RightEnd = 'right-end',
  RightStart = 'right-start',
  TopCenter = 'top-center',
  TopEnd = 'top-end',
  TopStart = 'top-start'
}
