import fetchJSON from '@api/api_helper';

const { type, key } = BF.fx.resource();
const url = `/api/v3/${type}s/${key}/search_filters`;

export const get = () => (
  fetchJSON(url).then((response) => response)
);

export const update = (searchFilters) => {
  fetchJSON(url, {
    method: 'PUT',
    body: {
      data: searchFilters
    }
  }).catch(async (response) => { throw (await response.json()); });
};
