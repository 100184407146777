import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { AssetData, AssetTypes, isExpectedDataShape, ColorData, FontData, ExternalMediumData } from '@api/v4/assets/assetTypes';
import { PrimaryButton } from '@components/library/button/index';

import ColorLink from './color_link';
import FontLink from './font_link';

interface AssetLinksProps {
  assetData: AssetData;
  assetType: AssetTypes;
  assetName?: string;
}

const AssetLinks: FunctionComponent<AssetLinksProps> = ({
  assetData,
  assetName,
  assetType,
}) => {
  if (isExpectedDataShape<FontData>(assetType, AssetTypes.Font, assetData)) {
    return (
      <li className="s-download-computer s-asset-action-item s-org-button sidebar-footer-action">
        <FontLink assetData={assetData} assetName={assetName} />
      </li>
    );
  }

  if (isExpectedDataShape<ColorData>(assetType, AssetTypes.Color, assetData)) {
    return (
      <li className="s-download-computer s-asset-action-item s-org-button s-org-button-color sidebar-footer-action">
        <ColorLink assetData={assetData} />
      </li>
    );
  }

  if (isExpectedDataShape<ExternalMediumData>(assetType, AssetTypes.ExternalMedium, assetData)) {
    return (
      <li className="s-download-computer s-asset-action-item s-org-button sidebar-footer-action">
        <PrimaryButton
          icon="bff-visit-media"
          onClick={(): void => { window.open(assetData.url, '_blank'); }}
        >
          <Trans>Visit Media</Trans>
        </PrimaryButton>
      </li>
    );
  }

  return null;
};

export default AssetLinks;
