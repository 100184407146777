import {
  CheckboxSwitch,
  FontAlignments,
  FontIcons,
  FontWeights,
  InputLabelPositions,
  StandardButton,
  StandardDialog,
  StandardTabs,
  StandardText,
  StandardTextfield
} from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import { slugify } from '@brandfolder/utilities';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { boolean, object, SchemaOf, string } from 'yup';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import { ApiDatumResponse } from '@api/v4/ApiResponseTypes';
import { BrandguidePost } from '@api/v4/resources/brandguides';
import { BrandguidesCombobox } from '@components/brandguides/BrandguidesCombobox';
import { isGettyClient } from '@helpers/getty-strings';
import { getStandardDialogLabels } from '@translations';

import classes from './styles/brandguide-create-form.module.scss';

const errorMessages = (): { nameRequired: string, slugRequired: string } => ({
  nameRequired: t`Name required`,
  slugRequired: t`Slug required`
});

const defaultValues: BrandguidePost = {
  name: '',
  slug: '',
  public: true
};

const schema: SchemaOf<BrandguidePost> = object().shape({
  name: string().required(errorMessages().nameRequired),
  public: boolean().required(),
  slug: string().required(errorMessages().slugRequired)
});

interface BrandguideCreateFormProps {
  open: boolean;
  organizationKey: string;
  setOpen: SetStateDispatch<boolean>;
  isOrg?: boolean;
}

export type ResourceType = 'brandfolder' | 'collection';

export const BrandguideCreateForm: FunctionComponent<BrandguideCreateFormProps> = (props) => {
  const { isOrg, open, organizationKey, setOpen } = props;

  const [resourceKey, setResourceKey] = useState('');
  const [resourceType, setResourceType] = useState<ResourceType>('brandfolder');

  const fetchOptions: UseFetchOptions = {
    fetchOnMount: false,
    url: `/api/v4/${isOrg ? resourceType : BFG.resource.type}s/${isOrg ? resourceKey : BFG.resource.key}/brandguides`,
    method: 'POST'
  };

  const { error, loading, fetch, response } = useFetch<ApiDatumResponse<BrandguidePost, 'brandguides'>>(fetchOptions);

  const { formState, handleSubmit, register, reset, setValue } = useForm<BrandguidePost, unknown, BrandguidePost>({
    defaultValues,
    resolver: yupResolver(schema)
  });

  const { onChange, ...nameProps } = register('name');

  const { errors, touchedFields } = formState;

  const handleClose = (): void => {
    reset();
    setOpen(false);
  };

  useEffect(() => {
    if (response?.data) {
      const { slug } = response.data.attributes;

      Notify.create({
        body: 'Reloading...',
        title: t`Brandguide created!`,
        type: 'success'
      });

      const url = new URL(window.location.href);
      if (!isOrg) {
        url.searchParams.set('brandguide', encodeURIComponent(slug));
      }
      window.location.assign(url);
    }
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      Notify.create({
        title: t`Error creating your Brandguide. Please try again.`,
        type: 'error'
      });
    }
  }, [error]);

  return (
    <StandardDialog
      className={classes.dialog}
      id="create-brandguide-dialog"
      labels={getStandardDialogLabels()}
      open={open}
      setOpen={handleClose}
      showFooter={false}
      title={t`Create New Brandguide`}
      titleIcon={FontIcons.Plus}
      width={700}
    >
      {isOrg && (
        <StandardTabs
          caption={isGettyClient()
            ? t`Select between displaying Libraries or collections`
            : t`Select between displaying Brandfolders or collections`}
          className={classes.brandfolderCollectionTabs}
          id="brandfolder-collection-tabs"
          onChange={(nextIndex): void=> {
            if (nextIndex === 0) {
              setResourceType('brandfolder');
            } else {
              setResourceType('collection');
            }
          }}
          showUnderline
          tabPanelProps={{
            active: null,
            children: '',
            className: classes.brandguideTabPanel,
            id: 'tab-panels',
            tabButtonId: 'tab-button'
          }}
          tabs={[
            {
              tabButton: isGettyClient() ? <Trans>Library</Trans> : <Trans>Brandfolder</Trans>,
              tabPanel: (
                <BrandguidesCombobox
                  organizationKey={organizationKey}
                  setResourceKey={setResourceKey}
                  type="brandfolder"
                />
              )
            },
            {
              tabButton: <Trans>Collection</Trans>,
              tabPanel: (
                <BrandguidesCombobox
                  organizationKey={organizationKey}
                  setResourceKey={setResourceKey}
                  type="collection"
                />
              )
            }
          ]}
        />
      )}
      {isOrg && (<hr className={classes.line}></hr>)}
      <form
        className={classes.form}
        noValidate
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((values) => {
          fetch({ ...fetchOptions,
            body: {
              data: {
                attributes: {
                  ...values
                }
              }
            }
          });
        })}
      >
        <StandardTextfield
          {...nameProps}
          className={classes.textfield}
          disabled={loading}
          error={errors.name?.message}
          id="create-brandguide-name"
          label={<Trans>Brandguide Name</Trans>}
          onChange={(e): void => {
            onChange(e);

            if (!touchedFields.slug) {
              setValue('slug', slugify(e.target.value), {
                shouldDirty: true,
                shouldTouch: false,
                shouldValidate: false
              });
            }
          }}
          required
          requiredLabel={t`Required`}
        />
        <div className={classes.formControl}>
          <StandardTextfield
            {...register('slug')}
            before={`${BFG.brandguideUrl}/`}
            beforeProps={{ className: classes.before }}
            className={classes.textfield}
            disabled={loading}
            error={errors.slug?.message}
            id="create-brandguide-slug"
            label={<Trans>Brandguide URL</Trans>}
            placeholder={t`brandguide-name`}
            required
            requiredLabel={t`Required`}
          />
        </div>
        <div className={classes.formControl}>
        <StandardText align={FontAlignments.Left} weight={FontWeights.Bold}>
          {t`Privacy`}
        </StandardText>
          <CheckboxSwitch
            {...register('public')}
            disabled={loading}
            id="create-brandguide-public"
            label={<Trans>Public</Trans>}
            labelPosition={InputLabelPositions.Right}
          />
        </div>
        <div className={classes.submitButton}>
          <StandardButton
            disabled={loading}
            id="create-brandguide-submit"
            type="submit"
          >
            <Trans>Create Brandguide</Trans>
          </StandardButton>
        </div>
      </form>
    </StandardDialog>
  );
};
