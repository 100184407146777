import { ButtonLooks, StandardButton } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, ReactElement } from 'react';

import '@components/bulk_management/share_links/styles/ShareLinksDeleteConfirmationDialog.scss';
import { DialogAnimations, StandardDialog } from '@components/library/dialog';

interface SharelinksDeleteConfirmationDialogProps {
  confirmationMessage: string;
  confirmationOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
}

export const SharelinksDeleteConfirmationDialog: FunctionComponent<SharelinksDeleteConfirmationDialogProps> = ({
  confirmationMessage,
  confirmationOpen,
  onCancel,
  onConfirm,
  title
}: SharelinksDeleteConfirmationDialogProps): ReactElement => (
    <div id='bulk-delete-confirmation-container'>
      <StandardDialog
        animation={DialogAnimations.FadeInFromTop}
        height={311}
        id='bulk-delete-share-links'
        onClose={onCancel}
        open={confirmationOpen}
        showFooter={false}
        showTitle={true}
        title={title}
        titleIcon='bff-trash'
        width={477}
        >
        <p className='bulk-delete-confirmation-message'>{confirmationMessage}</p>
        <div className='bulk-delete-confirmation-button-wrapper'>
          <StandardButton
            look={ButtonLooks.Secondary}
            onClick={onCancel}
          >
            <Trans>Cancel</Trans>
          </StandardButton>
          <StandardButton
            look={ButtonLooks.Danger}
            onClick={onConfirm}
          >
            <Trans>Delete</Trans>
          </StandardButton>
        </div>
      </StandardDialog>
    </div>
);
