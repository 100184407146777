import { FontIcon, FontIcons, HoverTooltip, TooltipLooks } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { TertiaryIconButton } from '@components/library/button';

interface PendingInvitationsProps {
  pendingInvitationKey: string
}

export const PendingInvitationsResendIcon: FunctionComponent<PendingInvitationsProps> = ({
  pendingInvitationKey
}) => {
  const [checked, setChecked] = useState(false);

  const pendingInvitationsResend = useFetch({
    url: `/api/v4/invitations/${pendingInvitationKey}/resend`,
    fetchOnMount: false,
    method: 'POST'
  });

  useEffect(() => {
    if (pendingInvitationsResend.response) {
      setChecked(true);

      const setIcon = setTimeout(() => {
        setChecked(false);
      }, 3000);

      return () => {
        clearTimeout(setIcon);
      };
    }
  }, [pendingInvitationsResend.response]);

  return (
    <HoverTooltip
      look={TooltipLooks.Primary}
      text={checked ? t`Resent` : t`Resend`}
    >
      {checked ? (
        <FontIcon
          aria-label={t`Resent invitation`}
          className="pending-invites-icons pending-invites-checked"
          icon={FontIcons.CheckMark}
        />
      ) : (
        <TertiaryIconButton
          aria-label={t`Resend invitation`}
          className="pending-invites-resend pending-invites-icons"
          icon={FontIcons.Email}
          onClick={(): void => {
            pendingInvitationsResend.fetch();
          }}
        />
      )}
    </HoverTooltip>
  );
};
