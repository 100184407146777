import { t, Trans } from '@lingui/macro';
import React, { useEffect, FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { WarningButton } from '@components/library/button';
import { resetWorksheetOptions } from '@components/workspace/helpers';
import { WorkspaceEvents } from '@components/workspace/WorkspaceEnums';
import { ResetResponseServer, WorkspaceUpdated } from '@components/workspace/WorkspaceTypes';
import { resetOptions, successOptions } from '@helpers/sweet_alert_options';

interface ResetSmartsheetConnectionProps {
  orgApiKey: string;
}

const errorNotify = (): void => {
  Notify.create({
    body: t`Please try again or contact support`,
    title: t`Error resetting connection`,
    type: 'error'
  });
};

const thingToDelete = (): string => t`This will reset the Smartsheet Workspace connection owner.`;

export const ResetSmartsheetConnection: FunctionComponent<ResetSmartsheetConnectionProps> = ({ orgApiKey }) => {
  const resetWorksheetConnection = useFetch<ResetResponseServer>(resetWorksheetOptions(orgApiKey));

  const handleReset = (): void => {
    window.swal(resetOptions({ thingToDelete: thingToDelete() }), (confirm: boolean): void => {
      if (confirm) {
        resetWorksheetConnection.fetch();
      }
    });
  };

  useEffect(() => {
    if (resetWorksheetConnection.response) {
      if (resetWorksheetConnection.response.status === 'success') {
        window.swal(successOptions({ successCopy: t`Smartsheet connection successfully reset.` }));
        // update connection button on workspace showpage
        const resetWorksheetConnectionEvent = new CustomEvent<WorkspaceUpdated>(WorkspaceEvents.WorkspaceConnectionReset, {
          detail: { connectionReset: true }
        });
        window.dispatchEvent(resetWorksheetConnectionEvent);
      } else {
        errorNotify();
      }
    }
  }, [resetWorksheetConnection.response]);

  useEffect(() => {
    if (resetWorksheetConnection.error) errorNotify();
  }, [resetWorksheetConnection.error]);

  return (
    <I18nProviderWrapper>
      <div className="reset-smartsheet-connection-container">
        <div className="advanced-tab-settings__row">
          <div className="half-column">
            <p><Trans>Reset Smartsheet connection owner</Trans></p>
            <p className="advanced-setting-description">
              <Trans>If the Workspace has been connected to a Smartsheet Workspace, resetting this connection will remove that user's authorization and disable the connection. Another Workspace owner or admin must reconnect to the Smartsheet Workspace to re-enable the link to Smartsheet.</Trans>
            </p>
          </div>
          <div className="half-column">
            <WarningButton
              className="reset-smartsheet-connection-button"
              onClick={handleReset}
            >
              <Trans>Reset Smartsheet Connection</Trans>
            </WarningButton>
          </div>
        </div>
      </div>
    </I18nProviderWrapper>
  );
};
