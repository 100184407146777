import React, { useEffect, useState, FunctionComponent } from 'react';

import { Views } from '@components/bulk_management/automation/AutomationEnums';
import { CreateView, DetailsView, HomeView } from '@components/bulk_management/automation/views';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import './styles/automation.scss';

interface AutomationProps {
  libraryName: string;
}

export const Automation: FunctionComponent<AutomationProps> = ({ libraryName }) => {
  const [view, setView] = useState<Views>(Views.Home);
  const [automationKey, setAutomationKey] = useState<string>(undefined);
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    if (view !== Views.Details) {
      setAutomationKey(undefined);
      setIsCopy(false);
    }
  }, [view]);

  return (
    <I18nProviderWrapper>
      <div className="manage-automation">
        {{
          [Views.Create]: <CreateView setView={setView} />,
          [Views.Home]: (
            <HomeView
              libraryName={libraryName}
              setAutomationKey={setAutomationKey}
              setIsCopy={setIsCopy}
              setView={setView}
            />
          ),
          [Views.Details]: <DetailsView automationKey={automationKey} isCopy={isCopy} setView={setView} />,
        }[view]}
      </div>
    </I18nProviderWrapper>
  );
};
