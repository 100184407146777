import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { createCustomFieldValues } from '@api/v4/custom_fields/custom-field-keys';
import { GuestUploadResponse } from '@api/v4/guest_upload';
import { FlattenedCustomFieldKeyValuesMap } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { Footer } from '@components/common/footer/Footer';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { GuestUploadCustomFields } from './GuestUploadCustomFields';
import { GuestUploadForm } from './GuestUploadForm';

import './styles/guest-upload.scss';

export interface GuestUploadProps {
  brandfolderKey: string;
  brandfolderName: string | null;
  copyright: string;
  organizationLogo: string | null;
  platformName: string;
  privacyPolicy: string;
  controlledCustomFields: boolean,
  rootDomain: string;
  termsOfService: string;
  uploadLinkKey: string;
  mladLocale: string;
}

export const GuestUpload: FunctionComponent<GuestUploadProps> = (props) => {
  const {
    brandfolderKey,
    brandfolderName,
    copyright,
    organizationLogo,
    platformName,
    privacyPolicy,
    controlledCustomFields,
    rootDomain,
    termsOfService,
    uploadLinkKey,
    mladLocale
  } = props;

  const [allRequiredCustomFields, setAllRequiredCustomFields] = useState(false || !controlledCustomFields);
  const [finalCustomFields, setFinalCustomFields] = useState<FlattenedCustomFieldKeyValuesMap>(null);
  const [newAssetKeys, setNewAssetKeys] = useState<string[]>(null);
  const [resetForm, setResetForm] = useState(false);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);
  const [prompt, setPrompt] = useState('');

  const guestUploadLinkFetch = useFetch<GuestUploadResponse>({ url: `/api/v4/upload_links/${uploadLinkKey}` });

  useEffect(() => {
    if (guestUploadLinkFetch.response) {
      setPrompt(guestUploadLinkFetch.response?.data?.attributes.description);
    }
  }, [guestUploadLinkFetch.response]);

  useEffect(() => {
    if (finalCustomFields && newAssetKeys) {
      Object.keys(finalCustomFields).forEach((customFieldKeyId) => {
        const assetValuePairs = finalCustomFields[customFieldKeyId].customFieldValues.flatMap(({ value }) => (
          newAssetKeys.map((assetKey) => ({ value, assetKey }))
        ));
        createCustomFieldValues(assetValuePairs, customFieldKeyId, undefined /* ugtLocale */, uploadLinkKey);
      });
    }
  }, [finalCustomFields, newAssetKeys]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <I18nProviderWrapper>
      <div className="guest-upload">
        <div className="guest-upload__header">
          <a aria-label={t`logo link back to ${platformName}`} href="/">
            <img
              alt=""
              aria-hidden="true"
              className="brandfolder-logo"
              src={organizationLogo}
            />
          </a>
          <a
            className="bf-logo-container"
            href="https://brandfolder.com/home"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Trans>
              <span>Powered by</span>
              <img
                alt="branfolder"
                className="bf-logo-horiz"
                src="https://cdn.bfldr.com/I6FML9WY/at/pgltaq-5fsycg-8zia3c/brandfolder-logo.svg"
              />
            </Trans>
          </a>
        </div>
        <div className="guest-upload__body">
          <h1>
            <Trans>Upload files to {brandfolderName}</Trans>
          </h1>
          <p>{prompt}</p>
          {controlledCustomFields && (
            <GuestUploadCustomFields
              brandfolderKey={brandfolderKey}
              controlledCustomFields={controlledCustomFields}
              mladLocale={mladLocale}
              newAssetKeys={newAssetKeys}
              platformName={platformName}
              resetForm={resetForm}
              setAllRequiredCustomFields={setAllRequiredCustomFields}
              setFinalCustomFields={setFinalCustomFields}
              setSubmissionAttempted={setSubmissionAttempted}
              submissionAttempted={submissionAttempted}
              uploadLinkKey={uploadLinkKey}
            />
          )}
          <GuestUploadForm
            allRequiredCustomFields={allRequiredCustomFields}
            brandfolderKey={brandfolderKey}
            brandfolderName={brandfolderName}
            setFinalCustomFields={setFinalCustomFields}
            setNewAssetKeys={setNewAssetKeys}
            setResetForm={setResetForm}
            setSubmissionAttempted={setSubmissionAttempted}
            uploadLinkKey={uploadLinkKey}
          />
        </div>
        <div className="guest-upload__brandfolder-description">
          <h2><Trans>What is {platformName}?</Trans></h2>
          <p>
            <Trans>
              Store, share, and showcase your assets in one place with {platformName}, the world's most powerfully simple digital asset management software.
            </Trans>
          </p>
          <a
            className="learn-more-link"
            href={`//${rootDomain}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Trans>Learn More</Trans>
          </a>
          <div aria-hidden="true" className="mast-image__container">
            <img
              alt="Brandfolder collage"
              className="mast-image"
              src="https://cdn.brandfolder.io/I6FML9WY/at/pkrt6a-6zd4io-9r73tv/product-collage?auto=webp&format=png"
            />
          </div>
        </div>
        <Footer
          copyright={copyright}
          privacyPolicy={privacyPolicy}
          termsOfService={termsOfService}
        />
      </div>
    </I18nProviderWrapper>
  );
};
