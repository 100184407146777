import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTr, BaseTrProps } from '../../base';

import classes from '../styles/standard-table.module.scss';

export interface StandardTrProps extends BaseTrProps {
  hover?: boolean;
}

export const Tr: FunctionComponent<StandardTrProps> = (props) => {
  const { children, hover, ...otherProps } = props;
  return (
    <BaseTr
      {...otherProps}
      className={classnames('standardTr', classes.tr, hover && classes.trHover, props.className)}
    >
      {children}
    </BaseTr>
  );
};
