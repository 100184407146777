import classnames from 'classnames';
import { AccordionAnimations, FontIcon, FontIcons, StandardAccordion, StandardTooltip } from '@brandfolder/react';
import React, { useState, FunctionComponent, ReactNode, useRef, useEffect } from 'react';

import {
  Brandfolder,
  ResourceDetails
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';
import { ResourceButton } from '@components/bulk_management/user-settings/resource-sidebar/ResourceButton';
import { ResourceAccordion } from '@components/bulk_management/user-settings/resource-sidebar/ResourceAccordion';
import { ResourcePrivacyBadge } from '@components/bulk_management/user-settings/resource-sidebar/ResourcePrivacyBadge';
import { brandfolderOrDescendantSelected } from '@components/bulk_management/user-settings/helpers';

interface BrandfolderAccordionProps {
  brandfolder: Brandfolder;
  index: number;
  selectedResourceKey: string;
  setResource: SetStateDispatch<ResourceDetails>;
}

export const BrandfolderAccordion: FunctionComponent<BrandfolderAccordionProps> = ({
  brandfolder,
  index,
  selectedResourceKey,
  setResource
}) => {
  const [open, setOpen] = useState(brandfolderOrDescendantSelected(brandfolder, selectedResourceKey));
  const [showTooltip, setShowTooltip] = useState(false);
  const { adminable, collections, key, name, privacy, workspaces } = brandfolder;
  const id = `brandfolders-accordion-${brandfolder.key}`;
  const hasCollections = collections && collections.length > 0;
  const hasWorkspaces = workspaces && workspaces.length > 0;
  const brandfolderResource = useRef(null);
  const brandfolderAccordion = useRef(null);

  useEffect(() => {
    if (brandfolderResource?.current) {
      const { offsetWidth, scrollWidth } = brandfolderResource?.current;
      if (offsetWidth < scrollWidth) {
        setShowTooltip(true);
      }
    }

    if (brandfolderAccordion?.current) {
      const { offsetWidth, scrollWidth } = brandfolderAccordion?.current;
      if (offsetWidth < scrollWidth) {
        setShowTooltip(true);
      }
    }
  }, [showTooltip]);

  useEffect(() => {
    if (selectedResourceKey) {
      setOpen(brandfolderOrDescendantSelected(brandfolder, selectedResourceKey));
    }
  }, [selectedResourceKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const brandfolderResourceAccordion = (
    <>
      {hasCollections && (
        <ResourceAccordion
          headingType="collection"
          resources={collections}
          selectedResourceKey={selectedResourceKey}
          setResource={setResource}
        />
      )}
      {hasWorkspaces && (
        <ResourceAccordion
          headingType="workspace"
          resources={workspaces}
          selectedResourceKey={selectedResourceKey}
          setResource={setResource}
        />
      )}
    </>
  );

  const checkAccordionState = (): ReactNode => {
    if (open) {
      return (
        <FontIcon aria-hidden className="accordion-caret-down" icon={FontIcons.CaretDown} />
      );
    }

    return (
      <FontIcon aria-hidden className="accordion-caret-right" icon={FontIcons.CaretRight} />
    );
  };

  if (!(hasCollections || hasWorkspaces)) {
    return (
      <ResourceButton
        ref={brandfolderResource}
        headingType="brandfolder"
        resource={brandfolder}
        selectedResourceKey={selectedResourceKey}
        setResource={setResource}
        showTooltip={showTooltip}
      />
    );
  }

  return (
    <div
      key={`accordion-${id}`}
      className={classnames(
        'resource-sidebar__accordion',
        'brandfolder-accordion',
        { ['active-resource']: selectedResourceKey === key },
      )}
    >
      <StandardAccordion
        accordions={[{
          button:
            <div ref={brandfolderAccordion} className='accordion-button-text__tooltip-container'>
              {checkAccordionState()}
              {showTooltip ? (
                <StandardTooltip id={`${name}-${id}`} tooltip={name}>
                  <p>{name}</p>
                </StandardTooltip>
              ) : (
                <p>{name}</p>
              )}
            </div>,
          buttonIcon: <ResourcePrivacyBadge privacy={privacy} />,
          panel: brandfolderResourceAccordion
        }]}
        allowZeroExpanded
        animation={AccordionAnimations.FadeInFromTop}
        id={id}
        initialOpen={open}
        onChange={(): void => {
          if (selectedResourceKey === key) {
            setOpen((prev) => !prev);
          } else {
            setResource({ adminable, headingType: 'brandfolder', key, name, type: 'brandfolder' });
          }
        }}
        overrideAccordionsState={[{ isDisabled: false, isOpen: open }]}
      />
    </div>
  );
};