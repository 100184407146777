import { t } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';

const defaultFetchErrorMessage = (): string => t`Something went wrong. Try again.`;
export const MAX_PAGE_SIZE = 3000;

export type FetchFields =
  | 'access_request_prompt'
  | 'admin_invites'
  | 'admins'
  | 'asset_count'
  | 'asset_data'
  | 'attachment_count'
  | 'availability_end'
  | 'availability_start'
  | 'availability'
  | 'background_color'
  | 'best_link_for'
  | 'best_metadata'
  | 'brandfolder_cdn_key'
  | 'brandfolder_user_sources'
  | 'can_execute'
  | 'cdn_url'
  | 'collaborator_invites'
  | 'collaborators'
  | 'collection_user_sources'
  | 'combined_size'
  | 'company'
  | 'completed_task_count'
  | 'content_html'
  | 'content_json'
  | 'country'
  | 'created_at'
  | 'created_with_saml'
  | 'created_with_sso'
  | 'creator'
  | 'default_share_manifest_private'
  | 'department'
  | 'default_share_manifest_expiration_seconds'
  | 'download_url'
  | 'due_date'
  | 'extension'
  | 'extracted_colors'
  | 'extracted_tag_names'
  | 'extracted_text'
  | 'folder_path'
  | 'formatted_description'
  | 'ftp_permission'
  | 'full_name'
  | 'html'
  | 'in_progress_task_count'
  | 'is_downloadable'
  | 'label_names'
  | 'last_active_at'
  | 'last_executed'
  | 'limit_counts'
  | 'locale'
  | 'member_count'
  | 'metadata'
  | 'multi_value_enabled'
  | 'multi_value_peristed'
  | 'name'
  | 'not_started_task_count'
  | 'other_metadata'
  | 'parent_id'
  | 'permission_level'
  | 'permission_count'
  | 'position'
  | 'progress'
  | 'rootdir'
  | 'section_count'
  | 'site_id'
  | 'storage'
  | 'tag_names'
  | 'thumbnail_url'
  | 'thumbnailed'
  | 'title'
  | 'type'
  | 'updated_at'
  | 'user_count'
  | 'values'
  | 'version_count'
  | 'view_thumbnail_retina';

export type FetchInclude =
  | 'asset'
  | 'assets'
  | 'attachments'
  | 'brandfolder'
  | 'brandfolders'
  | 'collection'
  | 'collections'
  | 'custom_field_key'
  | 'custom_field_values'
  | 'custom_fields'
  | 'dependent_custom_fields'
  | 'inviteable'
  | 'inviter'
  | 'labels'
  | 'organization'
  | 'permissible'
  | 'search_filters'
  | 'section'
  | 'sections'
  | 'subcollections'
  | 'tags'
  | 'tasks'
  | 'user_group'
  | 'users'
  | 'user_permissions';

export type FetchMethods = 'DELETE' | 'GET' | 'PATCH' | 'PUT' | 'POST';

interface FetchJsonOptions {
  url: string;
  /** body will be automatically stringified when application/json */
  body?: FormData | Record<string, any> | URLSearchParams;
  /** defaults to true */
  customToken?: string;
  contentType?:
  | 'application/json'
  | 'application/x-www-form-urlencoded'
  | 'multipart/form-data';
  credentials?: RequestCredentials;
  /** Error message to return on rejection */
  error?: string;
  /** Adds one or more &fields= params */
  fields?: FetchFields[] | FetchFields;
  /** Defaults to 'Content-Type': 'application/json' */
  headers?: { [key: string]: string };
  /** Adds one or more &include= params */
  include?: FetchInclude[] | FetchInclude;
  /** Defaults to GET */
  method?: FetchMethods;
  /** Allow user defined params */
  params?: Record<string, any>;
  /** Prioritizes internal api calls */
  queuePriorityHigh?: boolean;
  /** Allows consumer to cancel/abort the fetch call */
  signal?: AbortSignal;
  /** Allows consumer to ignore the status condition for 204 & 202 (if a response is expected) */
  ignoreStatusCondition?: boolean;
}

/**
 * Abstracted fetch API call for retrieving JSON data. Defaults to GET.
 * @example await fetchJson<MyInterface>({ url: 'https://mywebsite.com/api/stuff' })
 * @param {FetchJsonOptions} options FetchJsonOptions
 * @returns {Promise<T>} Promise<T>
 */
export const fetchJson = async <T>(options: FetchJsonOptions): Promise<T> => {
  const {
    body,
    customToken = '',
    contentType = 'application/json',
    credentials,
    error,
    fields,
    headers,
    include,
    method = 'GET',
    params,
    queuePriorityHigh = true,
    signal,
    url,
    ignoreStatusCondition = false
  } = options;

  let fetchBody: BodyInit | null = null;
  if (body) {
    if (contentType === 'application/json') {
      fetchBody = JSON.stringify({
        ...(body as Record<string, any>),
        ...(queuePriorityHigh && { queue_priority: 'high' }), // eslint-disable-line @typescript-eslint/naming-convention
      });
    } else {
      fetchBody = body as FormData | URLSearchParams;
    }
  }

  const requestUrl = new URL(url, window.location.origin);
  if (!body && queuePriorityHigh)
    requestUrl.searchParams.append('queue_priority', 'high');
  if (fields)
    requestUrl.searchParams.append(
      'fields',
      Array.isArray(fields) ? fields.join(',') : fields
    );
  if (include)
    requestUrl.searchParams.append(
      'include',
      Array.isArray(include) ? include.join(',') : include
    );
  if (params) {
    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined && params[key] !== null) {
        requestUrl.searchParams.append(key, params[key].toString());
      }
    });
  }

  const customBearerToken = customToken ? `Bearer ${customToken}` : '';
  const authToken =
    !customBearerToken && BFG.BF_Token
      ? `Bearer ${BFG.BF_Token}`
      : customBearerToken; // eslint-disable-line @typescript-eslint/naming-convention

  try {
    const response = await fetch(requestUrl.toString(), {
      body: fetchBody,
      credentials,
      headers: {
        Authorization: authToken, // eslint-disable-line @typescript-eslint/naming-convention
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ...(method !== 'GET' && { 'Content-Type': contentType }), // no content with a GET request
        ...headers,
      },
      method,
      signal,
    });

    // this needs to be before response.ok, because you can't do response.json() after response.ok
    // (json will be undefined when doing "const json = await response.json()", not sure why)
    const json =
      (response.status !== 204 && response.status !== 202) || ignoreStatusCondition
        ? await response.json()
        : {};

    if (response.ok || (response.status >= 200 && response.status < 300)) {
      return await Promise.resolve(json);
    }

    if (response.status === 422 || response.status === 409) {
      return await Promise.reject(json);
    }

    throw new Error(error || defaultFetchErrorMessage());
  } catch (err) {
    // error is the optional string passed in above
    return Promise.reject(
      new Error(
        error ||
        err?.message ||
        err?.errors?.[0]?.detail ||
        err?.errors ||
        defaultFetchErrorMessage()
      )
    );
  }
};

/**
 * A Promise to delay a Promise
 * @param milliseconds number
 * @returns {Promise<void>} Promise<void>
 */
export const delay = (milliseconds: number): Promise<void> =>
  new Promise((_) => setTimeout(_, milliseconds));

interface FetchMockJsonOptions extends FetchJsonOptions {
  /** Mocked fetch response data */
  data: unknown;
  /** Timeout in milliseconds before resolve/reject */
  delayTimeout?: number;
  /** Mock rejecting the API call */
  reject?: boolean;
}

/**
 * Mock fetch API call for retrieving JSON data.
 * This can be used for stubbing front end screens before API endpoints are created.
 * @example await fetchMockJson<MyInterface>({ data: MyMockData, url: 'https://mywebsite.com/api/stuff' })
 * @param {FetchMockJsonOptions} options FetchMockJsonOptions
 * @returns {Promise<T | unknown>} Promise<T | unknown>
 */
export const fetchMockJson = async <T>(
  options: FetchMockJsonOptions
): Promise<T> => {
  const { data, delayTimeout = 1000, error, reject } = options;
  try {
    await delay(delayTimeout);
    if (reject) {
      throw new Error(error || defaultFetchErrorMessage());
    }
    return await Promise.resolve(data as T);
  } catch (err) {
    return Promise.reject(new Error(error || defaultFetchErrorMessage()));
  }
};

export interface FetchWithPollingResponse<T> {
  error: boolean;
  success: boolean;
  timeout: boolean;
  polledData?: T;
}

/**
 * A reusable Promise polling mechanism.
 * By default, this will try polling your Promise every 5 seconds for 30 seconds.
 * IMPORTANT: Do NOT pollute this function with UI like notifications/toasts
 * ...this is intentionally abstracted to be portable and reusable with any client app.
 * (You should be handling notifications/toasts/messaging within your component
 * try/catch, using the object that fetchWithPolling returns).
 * @param {Promise<T>} fetchPromise Promise<T>
 * @param {(response: T) => boolean} validatePromise (response: T) => boolean
 * @param {(attempt: number) => void} beforeAttempt (attempt: number) => void
 * @param {(attempt: number) => void} afterAttempt (attempt: number) => void
 * @param {number} pollInterval number
 * @param {number} pollTimeout number
 * @returns {Promise<FetchWithPollingResponse<T>>} Promise<FetchWithPollingResponse<T>>
 */
export const fetchWithPolling = <T>(
  /** The Promise that needs to be polled */
  fetchPromise: () => Promise<T>,
  /** A function to tell the polling if the Promise returned successfully */
  validatePromise: (response: T) => boolean,
  /** Defaults to polling every 5 seconds */
  pollInterval = 5 * 1000,
  /** Defaults to a 60 seconds max timeout */
  pollTimeout = 60 * 1000,
  /** A callback to run before each polling attempt */
  beforeAttempt?: (attempt: number) => void,
  /** A callback to run after each polling attempt */
  afterAttempt?: (attempt: number) => void
): Promise<FetchWithPollingResponse<T>> => {
  // these are intentionally initialized outside executePoll
  let attempt = 0;
  const endTime = new Date().getTime() + pollTimeout;

  // eslint-disable-next-line consistent-return
  const executePoll = async (
    resolve: (arg: any) => any,
    reject: (arg: any) => any
  ): Promise<FetchWithPollingResponse<T> | undefined> => {
    let pollingResponse: FetchWithPollingResponse<T> = {
      error: false,
      success: false,
      timeout: false,
    };

    try {
      // execute the beforeAttempt if there is one
      if (beforeAttempt) {
        beforeAttempt(attempt);
      }

      const response = await fetchPromise();
      const isValid = validatePromise(response);

      // stop polling and resolve
      if (isValid) {
        pollingResponse = {
          ...pollingResponse,
          polledData: response,
          success: true,
        };
        return resolve(pollingResponse);
      }

      // stop polling if we're pass the pollTimeout
      const now = new Date().getTime();
      if (now > endTime) {
        pollingResponse = { ...pollingResponse, timeout: true };
        return reject(pollingResponse);
      }

      // execute the afterAttempt if there is one
      if (afterAttempt) {
        afterAttempt(attempt);
      }

      attempt += 1;

      // else we poll again
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(executePoll, pollInterval, resolve, reject);
    } catch (err) {
      // if the promise returns an error, stop polling
      pollingResponse = { ...pollingResponse, error: true };
      return reject(pollingResponse);
    }

    return undefined;
  };

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return new Promise(executePoll);
};

const defaultPollingDownloadMessages = (): string[] => ([
  // these strings are intentionally a little bit longer each time
  // so that the user will notice the text changing (in English, at least)
  t`Downloading...`,
  t`Still downloading...`,
  t`Still preparing the download...`,
  t`Hang tight! Still downloading...`,
  t`Still working on it, please wait...`,
  t`This is taking a while, keep waiting...`,
  t`We haven't given up, please keep waiting...`,
]);

export const getPollingDownloadMessage = (attempt: number): string =>
  attempt > defaultPollingDownloadMessages.length - 1
    ? defaultPollingDownloadMessages[defaultPollingDownloadMessages.length - 1]
    : defaultPollingDownloadMessages[attempt];

export interface UseFetchOptions extends FetchJsonOptions {
  /**
   * Fetches data when the consuming component mounts.
   * If fetching has dependencies, call the exposed fetch() function instead.
   * */
  fetchOnMount?: boolean;
  /**
   * Delay in milliseconds after an response is returned before loading is set to false.
   * Useful running a formatting function on the response to make sure there's no blip of showing stale content.
   */
  loadingDelay?: number;
}

export interface ServerError extends Error {
  errors?: string;
}

interface FetchInitialState<T> {
  loading: boolean;
  error?: ServerError;
  fetch?: () => Promise<void>;
  response?: T;
}

export interface FetchState<T> extends FetchInitialState<T> {
  fetch: (fetchDataOptions?: UseFetchOptions) => Promise<void>;
  reset: () => void;
}

/**
 * A React hook to fetch JSON data
 * @example const deleteExample = useFetch<MyInterface>({ fetchOnMount: false, method: 'DELETE', url: `/api/example/${key}` });
 * @example const getExample = useFetch<MyInterface>({ url: `/api/example` });
 * @example const postExample = useFetch<MyInterface>({ fetchOnMount: false, method: 'POST', url: `/api/example` });
 * @example const putExample = useFetch<MyInterface>({ fetchOnMount: false, method: 'PUT', url: `/api/example/${key}` });
 * @param  {UseFetchOptions} options UseFetchOptions
 * @returns {FetchState<T>} FetchState<T>
 */
export const useFetch = <T>(options: UseFetchOptions): FetchState<T> => {
  const {
    fetchOnMount = true,
    loadingDelay = 0,
    url,
    ...otherOptions
  } = options;

  const abortControllerRef = useRef<AbortController | null>(null);
  const [fetchState, setFetchState] = useState<FetchInitialState<T>>({
    error: undefined,
    fetch: undefined,
    // only set loading to true initially when fetchOnMount is true
    loading: fetchOnMount,
    response: undefined,
  });

  const fetchData = async (
    fetchDataOptions?: UseFetchOptions
  ): Promise<void> => {
    abortControllerRef.current = new AbortController();

    try {
      setFetchState((prevState) => ({
        ...prevState,
        error: undefined,
        loading: true,
      }));

      const response = await fetchJson<T>({
        ...otherOptions,
        signal: abortControllerRef.current.signal,
        url,
        ...fetchDataOptions,
      });

      if (
        abortControllerRef.current &&
        !abortControllerRef.current.signal.aborted
      ) {
        if (loadingDelay) {
          setFetchState((prevState) => ({ ...prevState, response }));
          await delay(loadingDelay);
          setFetchState((prevState) => ({ ...prevState, loading: false }));
        } else {
          setFetchState((prevState) => ({
            ...prevState,
            loading: false,
            response,
          }));
        }
      }
    } catch (err) {
      if (
        abortControllerRef.current &&
        !abortControllerRef.current.signal.aborted
      ) {
        const error = err;
        setFetchState((prevState) => ({ ...prevState, error, loading: false }));
      }
    }
  };

  const reset = (): void => {
    if (!fetchState.loading) {
      setFetchState({
        error: undefined,
        fetch: undefined,
        loading: fetchOnMount,
        response: undefined,
      });
    }
  };

  useEffect(() => {
    if (fetchOnMount && url) {
      fetchData();
    }

    return (): void => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [fetchOnMount, url]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ...fetchState, fetch: fetchData, reset } as FetchState<T>;
};

/**
 * A React hook for basic error handling for useFetch hook
 * This will generate and display an error toast that an error occurred
 * @param  {FetchState<T>} fetchState FetchState<T>
 * @param {string} [customErrorMessage] string
 * @returns {void} void
 */
export const useNotifyFetchError = <T>(
  fetchState: FetchState<T>,
  customErrorMessage?: string
): void => {
  useEffect(() => {
    if (fetchState.error) {
      Notify.create({
        title: customErrorMessage || t`Oops! Something went wrong.`,
        type: 'error',
      });
    }
  }, [fetchState.error]); // eslint-disable-line react-hooks/exhaustive-deps
};

interface BatchFetchOptions<T, V> {
  fetchPromise: (item: T) => Promise<V>;
  /** An array of items (such as keys to Insights API) */
  items: T[];
  /** Defaults to 300 */
  batchSize?: number;
  /** Defaults to 300. Delay in milliseconds between batches (only if batching is needed). */
  delayBetweenBatches?: number;
}

interface BatchFetchState<V> {
  allFullfilled: boolean;
  allRejected: boolean;
  fullfilled: PromiseSettledResult<Awaited<V>>[];
  rejected: PromiseSettledResult<Awaited<V>>[];
}

/**
 * @example await fetchInBatches({ batchSize: 100, fetchPromise, items: [1, 2, ... 10,000] })
 * @param options BatchFetchOptions<T, V>
 * @returns Promise<BatchFetchState<V>>
 */
export const fetchInBatches = async <T, V>(
  options: BatchFetchOptions<T, V>
): Promise<BatchFetchState<V>> => {
  const {
    batchSize = 300,
    delayBetweenBatches = 300,
    fetchPromise,
    items,
  } = options;

  // if batching is not needed, we don't run delays below
  const batchingNeeded = items.length > batchSize;

  const state: BatchFetchState<V> = {
    allFullfilled: false,
    allRejected: false,
    fullfilled: [],
    rejected: [],
  };

  let currentBatch = 0;

  while (currentBatch < items.length) {
    const batchOfItems = items.slice(currentBatch, currentBatch + batchSize);

    if (batchingNeeded && currentBatch !== 0 && delayBetweenBatches) {
      await delay(delayBetweenBatches);
    }

    const results = await Promise.allSettled<V>(
      batchOfItems.map(async (item) => fetchPromise(item))
    );

    state.fullfilled = [
      ...state.fullfilled,
      ...results.filter((result) => result.status === 'fulfilled'),
    ];
    state.rejected = [
      ...state.rejected,
      ...results.filter((result) => result.status === 'rejected'),
    ];

    currentBatch += batchSize;
  }

  state.allFullfilled = items.length === state.fullfilled.length;
  state.allRejected = items.length === state.rejected.length;

  return state;
};
