import { fetchJson } from '@api/ApiHelper';
import { ShareManifestGetResponse, ShareManifestServer } from '@api/v4/share_manifests';

export const fetchShareManifest = async (key: string): Promise<ShareManifestServer> => {
  const shareManifestResponse = await fetchJson<ShareManifestGetResponse>({
    url: `/api/v4/share_manifests/${key}`
  });
  return shareManifestResponse.data.attributes;
};

export const shareManifestExpired = (shareManifest: ShareManifestServer): boolean => (
  shareManifest.expires && (new Date(Date.now()) >  new Date(shareManifest.availability_end))
);
