import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { Td } from './Td';
import { Tr } from './Tr';
import { BaseTrErrorProps } from '../../base/BaseTableProps';

import classes from '../styles/selectable-table.module.scss';

export const TrError: FunctionComponent<BaseTrErrorProps> = (props) => {
  const { children, colSpan } = props;
  return (
    <Tr className={classnames('trError', 'selectableTrError', classes.trError)} hover={false}>
      <Td colSpan={colSpan}>
        {children}
      </Td>
    </Tr>
  );
};
