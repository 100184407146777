import { t, Trans } from '@lingui/macro';
import React from 'react';

import { Portal } from '@api/v4/private/PortalTypes';
import { Privacy } from '@api/v4/resources/resourceTypes';
import { ListDropdown } from '@components/library/dropdown';
import { isGettyClient } from '@helpers/getty-strings';

interface PortalFormStage2Props {
  libraryName: string;
  portal: Portal;
  setPortal: SetStateDispatch<Portal>;
  stage: number;
}

const PortalFormStage2: React.FunctionComponent<PortalFormStage2Props> = ({
  portal,
  setPortal,
  stage,
}) => {
  if (stage !== 2) { return null; }

  const stealthCopy = isGettyClient()
    ? <Trans>This Brand Portal is only accessible to the users you specify, and will not show up in Google search results and other areas of your Library.</Trans>
    : <Trans>This Brand Portal is only accessible to the users you specify, and will not show up in Google search results and other areas of your Brandfolder.</Trans>;

  return (
    <div>
      <h3 className="portal-form-modal__title"><Trans>Privacy Settings</Trans></h3>
      <ListDropdown
        className="portal-form-modal__privacy-dropdown"
        onChange={(option): void => {
          const privacy = option.value as Privacy;
          setPortal((prevState): Portal => ({ ...prevState, privacy }));
        }}
        options={[
          { value: Privacy.Public, label: t`Public` },
          { value: Privacy.Private, label: t`Private` },
          { value: Privacy.Stealth, label: t`Stealth (Extra Private)` },
        ]}
        value={portal.privacy}
        virtualizeOptions={false}
      />
      <h3 className="portal-form-modal__privacy-title bold-label-styling"><Trans>Public</Trans></h3>
      <p><Trans>Anyone can access this Brand Portal</Trans>.</p>
      <h3 className="portal-form-modal__privacy-title bold-label-styling"><Trans>Private</Trans></h3>
      <p><Trans>This Brand Portal is only accessible to the users you specify</Trans>.</p>
      <h3 className="portal-form-modal__privacy-title bold-label-styling"><Trans>Stealth (Extra Private)</Trans></h3>
      <p>{stealthCopy}</p>
    </div>
  );
};

export default PortalFormStage2;
