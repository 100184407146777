/* eslint-disable @typescript-eslint/naming-convention */
export interface AssetEvent {
  actor_email: string,
  asset_key: string,
  asset_name: string,
  category: Category | string,
  change_agent: ChangeAgent | string,
  event_time: string, // date string
  event_type: EventType,
}

// these are sourced from and should stay in sync with app/lib/change_agents.rb
export enum ChangeAgent {
  BrandfolderSupport = 'Brandfolder Support', // For Rails console activity or scripts
  BrandfolderUi = 'Brandfolder UI',
  CanvaIntegration = 'Canva Integration', // Has JS hardcoded values
  ContentAutomationIntegration = 'Content Automation Integration',
  DesignHuddleIntegration = 'Custom Templating Integration', // Has JS hardcoded values
  GettylinkIntegration = 'Getty Link Integration',
  PrintuiIntegration = 'Printui Integration',
  SmartsheetPublishIntegration = 'Smartsheet Publish Integration',
  StoryteqIntegration = 'Storyteq Integration',
  AssetAutomations = 'Asset Automations',
  AttachmentIdentifiers = 'Attachment Identifiers', // svc-image-hasher
  AttachmentMetadata = 'Attachment Metadata', // svc-filemetadata
  AvailabilityDetection = 'Availability Detection', // AssetAvailabilityJob cron
  CsvMetasheets = 'CSV Metasheets',
  DuplicateDetection = 'Duplicate Detection',
  FiletypeDetection = 'Filetype Detection',
  FtpIngest = 'FTP Ingest',
  GcsIngest = 'GCS Ingest',
  GuestUpload = 'Guest Upload',
  SftpIngest = 'SFTP Ingest',
  MachineLearningDisneyModel = 'Disney Machine Learning',
  MachineLearningGoogleVideoIntelligence = 'Video Intelligence',
  MachineLearningGoogleVision = 'Auto Tagging',
  MachineLearningGoogleVisionCelebrities = 'Celebrity Detection',
  MachineLearningGoogleVisionLandmarks = 'Landmark Detection',
  MachineLearningGoogleVisionLogos = 'Logo Detection',
  MachineLearningHiveModel = 'Hive Logo Detection',
  MachineLearningPeopleTagging = 'People Tagging',
  MetadataConversions = 'Metadata Conversions'
}

// these are sourced from and should stay in sync with app/lib/asset_events/asset_topic_event.rb
export enum Category {
  // ACTION_CATEGORIES_FROM_ASSET_FIELDS
  Availability = 'availability status',
  AvailabilityStart = 'publish date',
  AvailabilityEnd = 'expiration date',
  BrandfolderId = 'brandfolder',
  Description = 'description',
  Name = 'name',
  SectionId = 'section',
  ThumbnailOverride = 'thumbnail override',
  ViewOnly = 'view only',
  Watermark = 'watermark',
  // ACTION_CATEGORIES_FROM_ASSET_LIFECYCLE_EVENTS
  Asset = 'asset',
  Merge = 'merge',
  // ACTION_CATEGORIES_FROM_ASSET_ASSOCIATIONS
  Approved = 'approved',
  AttachmentCreated = 'attachment created',
  AttachmentDeleted = 'attachment deleted',
  AttachmentUpdated = 'attachment updated',
  Collections = 'collections',
  Comments = 'comments',
  CustomFieldValues = 'custom field values',
  Labels = 'labels',
  Tags = 'tags',
  Unapproved = 'unapproved',
  Versions = 'versions',
  Workspaces = 'workspaces',
  // ACTION_CATEGORIES_FROM_ASSET_TYPE
  ExternalMedium = 'media info',
  Font = 'font info',
  GenericFile = 'asset info',
  Color = 'color info',
  Press = 'press info',
  Person = 'personal info',
  // ACTION_CATEGORIES_UNCATEGORIZED
  IndexOnly = 'INDEX_ONLY',
  Uncategorized = 'UNCATEGORIZED'
}

export enum EventType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}
