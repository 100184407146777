import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { deletePressRelease, listPressReleases } from '@api/v4/private/resources/press_releases';
import { TertiaryIconButton, TextButton, WarningButton } from '@components/library/button';
import { StandardTable, StandardTableColumn, StandardTableRow } from '@components/library/table';
import { IdentifiedPressRelease } from '@components/press_releases';
import { deleteOptions } from '@helpers/sweet_alert_options';

import { PressReleaseFormSteps } from './PressReleaseTypes';

import classes from './styles/press_releases.module.scss';

interface PressReleasesListProps {
  handleOpen: (pressRelease?: IdentifiedPressRelease, duplicate?: boolean) => Promise<void>;
  setStep: SetStateDispatch<PressReleaseFormSteps>;
  setUpdate: SetStateDispatch<ApiResponseObject<IdentifiedPressRelease, 'press_release'> | null>;
  update: ApiResponseObject<IdentifiedPressRelease, 'press_release'> | null;
  reset?: boolean;
}

const columns = (): StandardTableColumn[] => ([
  {
    heading: <Trans>Subject</Trans>,
    rowKey: 'subject',
    width: '37.5%'
  },
  {
    heading: <Trans>Creation Date</Trans>,
    rowKey: 'createdAt',
    minWidth: '120px',
    width: '15%'
  },
  {
    heading: <Trans>Recipients</Trans>,
    rowKey: 'recipients',
    minWidth: '130px',
    width: '15%'
  },
  {
    heading: <Trans>Status</Trans>,
    minWidth: '100px',
    rowKey: 'status',
    width: '17.5%'
  },
  {
    heading: '',
    minWidth: '190px',
    rowKey: 'actions',
    width: '15%'
  }
]);

const userPermissionLevels = (): { id: string, name: string }[] => ([{
  id: 'admin',
  name: t`Admins`
},
{
  id: 'collaborator',
  name: t`Collaborators`
},
{
  id: 'guest',
  name: t`Guests`
}]);

export const PressReleasesList: FunctionComponent<PressReleasesListProps> = ({ handleOpen, reset, setStep, setUpdate, update }) => {
  const [pressReleases, setPressReleases] = useState<ApiResponseObject<IdentifiedPressRelease, 'press_release'>[]>([]);
  const [pressReleasesError, setPressReleasesError] = useState(false);
  const [pressReleasesInitial, setPressReleasesInitial] = useState(true);
  const [pressReleasesLoading, setPressReleasesLoading] = useState(true);
  const [pressReleasesLoadingMore, setPressReleasesLoadingMore] = useState(false);
  const [pressReleasesPage, setPressReleasesPage] = useState(1);
  const [pressReleasesQuery, setPressReleasesQuery] = useState('');
  const [pressReleasesTotalPages, setPressReleasesTotalPages] = useState<number | undefined>(undefined);

  const fetchPressReleases = async (): Promise<void> => {
    try {
      if (!pressReleasesLoadingMore) {
        setPressReleases([]);
      }

      setPressReleasesError(false);
      setPressReleasesLoading(true);

      const response = await listPressReleases({
        resourceKey: BFG.resource.key,
        resourceType: BFG.resource.type as 'brandfolder' | 'collection'
      }, {
        page: pressReleasesPage,
        per: 100
      });

      setPressReleasesTotalPages(response.meta?.total_pages);

      const identifiedData: ApiResponseObject<IdentifiedPressRelease, 'press_release'>[] = response.data.map((pressRelease) => ({
        ...pressRelease,
        attributes: {
          ...pressRelease.attributes,
          id: pressRelease.id
        }
      }));

      if (pressReleasesLoadingMore) {
        setPressReleases([...pressReleases, ...identifiedData]);
      } else {
        setPressReleases(identifiedData);
      }
    } catch (err) {
      setPressReleasesError(true);
    } finally {
      setPressReleasesInitial(false);
      setPressReleasesLoading(false);
      setPressReleasesLoadingMore(false);
    }
  };

  const resetPressReleases = (): void => {
    setPressReleasesError(false);
    setPressReleasesInitial(true);
    setPressReleasesLoading(false);
    setPressReleasesLoadingMore(false);
    setPressReleasesPage(1);
    setPressReleasesQuery('');
    setPressReleasesTotalPages(undefined);
  };

  const handleDelete = (pressRelease: IdentifiedPressRelease): void => {
    window.swal({ ...deleteOptions({ closeOnConfirm: false, thingToDelete: pressRelease.subject }), content: '<div>hi</div>' }, async (confirm) => {
      if (confirm && pressRelease.id) {
        try {
          await deletePressRelease(pressRelease.id);
          setPressReleases(pressReleases.filter((pr) => pr.id !== pressRelease.id));
          window.swal.close();
        } catch (e) {
          Notify.create({
            title: t`Error deleting email. Please try again.`,
            type: 'error'
          });
        }
      }
    });
  };

  useEffect(() => {
    if (pressReleasesInitial || pressReleasesLoadingMore) {
      fetchPressReleases();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressReleasesInitial, pressReleasesLoadingMore]);

  useEffect(() => {
    if (reset) {
      resetPressReleases();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (update) {
      const index = pressReleases.findIndex((pressRelease) => pressRelease.id === update.id);
      const pressReleasesList = [...pressReleases];

      // update the press release in the loaded list
      if (index > -1) {
        pressReleasesList[index] = { ...update, attributes: { ...update.attributes, id: update.id } };
      } else {
        // press release was created , add it to the top of the list
        // NOTE: if we reset the list here instead, the email could be hidden from the list
        // since sent are further down the list, which could potentially be confusing to users
        // having one item out of order is probably better than the confusion that might happen
        pressReleasesList.unshift({ ...update, attributes: { ...update.attributes, id: update.id } });
      }

      setPressReleases(pressReleasesList);
      setUpdate(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const hasPressReleases = pressReleases.length > 0 && !pressReleasesError;
  const noPressReleases = pressReleases.length === 0 && !pressReleasesLoading && !pressReleasesError;

  const showEmpty = noPressReleases && !pressReleasesQuery;
  const showError = !pressReleasesLoading && pressReleasesError;
  const showLoading = pressReleasesLoading && !pressReleasesLoadingMore;
  const showLoadMore = hasPressReleases && !pressReleasesInitial && !!pressReleasesTotalPages && (pressReleasesPage < pressReleasesTotalPages || pressReleasesLoadingMore);

  const renderRows = (): StandardTableRow[] => pressReleases.map((pressRelease, index) => {
    const { created_at, permission_levels, request_send_at, sent_at, subject } = pressRelease.attributes;

    const createdAt = new Intl.DateTimeFormat(BFG.locales.staticSiteLocale).format(new Date(created_at));
    const requestSendAt = request_send_at ? new Intl.DateTimeFormat(BFG.locales.staticSiteLocale).format(new Date(request_send_at)) : null;
    const sentAt = sent_at ? new Intl.DateTimeFormat(BFG.locales.staticSiteLocale).format(new Date(sent_at)) : null;
    const hasBeenSent = requestSendAt || sentAt;

    // eslint-disable-next-line array-callback-return, consistent-return
    const permissionLevels = permission_levels.map((permissionLevel) => {
      const level = userPermissionLevels().find((userPermissionLevel) => permissionLevel === userPermissionLevel.id);
      if (level) {
        return level.name;
      }
    });

    const sentStatus = (): ReactNode => {
      if (sentAt) {
        return (
          <p className={classes.p}>
            <span aria-hidden="true" className={classnames(classes.icon, 'bff-sent')} role="img" />
            <Trans>Sent on {sentAt}</Trans>
          </p>
        );
      }
      if (requestSendAt) {
        return (
          <p className={classes.p}>
            <span aria-hidden="true" className={classnames(classes.icon, 'bff-sent')} role="img" />
            <Trans>Sending</Trans>
          </p>
        );
      }
      return (
        <p className={classes.p}>
          <span aria-hidden="true" className={classnames(classes.icon, 'bff-draft')} role="img" />
          <Trans>Draft</Trans>
        </p>
      );
    };

    return {
      actions: <>
        {!hasBeenSent ? (
          <TertiaryIconButton
            aria-label={t`Edit email`}
            className={classes.button}
            disabled={pressReleasesLoading}
            icon="bff-edit"
            id={`email-edit-${index}`}
            onClick={(): void => {
              handleOpen(pressRelease.attributes);
            }}
          />
        ) : (
          <TertiaryIconButton
            className={classes.button}
            disabled
            icon="bff-edit"
            id={`email-edit-${index}`}
          />
        )}
        <TertiaryIconButton
          aria-label={t`Duplicate email`}
          className={classes.button}
          disabled={pressReleasesLoading}
          icon="bff-copy"
          id={`email-duplicate-${index}`}
          onClick={(): void => {
            handleOpen(pressRelease.attributes, true);
          }}
        />
        <WarningButton
          aria-label={t`Delete email`}
          className={classnames(classes.button, classes.warningButton)}
          disabled={pressReleasesLoading}
          icon="bff-trash"
          id={`email-delete-${index}`}
          onClick={(): void => handleDelete(pressRelease.attributes)}
        />
      </>, // eslint-disable-line react/jsx-closing-tag-location
      createdAt,
      recipients: <>
        {!hasBeenSent ? (
          <TextButton
            className={classes.textButton}
            onClick={(): void => {
              handleOpen(pressRelease.attributes);
              setStep(2);
            }}
          >
            {permissionLevels.join(', ')}
          </TextButton>
        ) : (
          permissionLevels.join(', ')
        )}
      </>, // eslint-disable-line react/jsx-closing-tag-location
      status: sentStatus(),
      subject: <>
        {!hasBeenSent ? (
          <TextButton
            className={classes.textButton}
            onClick={(): void => {
              handleOpen(pressRelease.attributes);
            }}
          >
            {subject}
          </TextButton>
        ) : (
          subject
        )}
      </> // eslint-disable-line react/jsx-closing-tag-location
    };
  });

  return (
    <>
      <section className={classes.section}>
        <StandardTable
          caption="A list of emails"
          columns={columns()}
          empty={showEmpty}
          emptyContent={(
            <div className={classes.empty}>
              <p className={classes.p}><Trans>You have no emails.</Trans></p>
              <TextButton
                onClick={(): void => {
                  handleOpen(undefined);
                }}
              >
                <Trans>Create Email</Trans>
              </TextButton>
            </div>
          )}
          error={showError}
          errorContent={(
            <div className={classes.error}>
              <p className={classes.p}><Trans>Error retrieving emails.</Trans></p>
              <TextButton onClick={(): void => {
                if (pressReleasesPage === 1) {
                  setPressReleasesInitial(true);
                } else {
                  setPressReleasesLoadingMore(true);
                }
              }}
              >
                <Trans>Try again</Trans>
              </TextButton>
            </div>
          )}
          footer={showLoadMore ? (
            <div className={classes.loadMore}>
              <TextButton
                isLoading={pressReleasesLoadingMore}
                onClick={(): void => {
                  setPressReleasesPage(pressReleasesPage + 1);
                  setPressReleasesLoadingMore(true);
                }}
              >
                {pressReleasesLoadingMore ? <Trans>Loading...</Trans> : <Trans>Load more</Trans>}
              </TextButton>
            </div>
          ) : null}
          id="emails-list"
          loading={showLoading}
          rows={renderRows()}
          scrollX={false}
        />
      </section>
    </>
  );
};
