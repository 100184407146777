export const deKabobize = (kabobbedString: string): string => (
  kabobbedString.replace(/-/g, ' ')
);

export const capitalize = (string: string, capitalizeAll = false): string => {
  let newString = string;
  if (newString.includes('-')) {
    newString = deKabobize(newString);
  }

  let newStringTail = newString.slice(1).toLowerCase();
  if (capitalizeAll) {
    newStringTail = newStringTail.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
  }

  return `${newString.charAt(0).toUpperCase()}${newStringTail}`;
};

const isObject = (item: string | object): boolean => item !== null && typeof item === 'object' && !Array.isArray(item);

// regex: divides string into groups separated capital letters
// then join with '_' and lowercase everything
const snakeifyCamelCase = (item: string): string => item.split(/(?=[A-Z])/).join('_').toLowerCase();

export const snakeifyKeys = (camelCaseItem: string | object): string | object => {
  if (typeof camelCaseItem === 'string') {
    return snakeifyCamelCase(camelCaseItem);
  }

  if (isObject(camelCaseItem)) {
    const snakeCaseObject = {};
    // drill into object recursively and camelize all object keys
    Object.keys(camelCaseItem).forEach((item) => {
      snakeCaseObject[snakeifyKeys(item) as string] = isObject(camelCaseItem[item])
        ? snakeifyKeys(camelCaseItem[item]) // continue recursion drilling if value is object
        : camelCaseItem[item]; // return value itself if not an object
    });
    return snakeCaseObject;
  }

  return camelCaseItem;
};

export const removeUnderscores = (filename: string | undefined): string | undefined => {
  if (filename) {
    return filename.replace(/_/g, ' ');
  }

  return undefined;
};
