import { StandardPill } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import ReactTags, { TagComponentProps, Tag } from 'react-tag-autocomplete';

// keycodes for what input triggers
// creation of a new tag
const tagDelimiters = [
  9 /* tab key */,
  13 /* enter key */,
  186 /* semicolon */,
];

// keycodes for what input triggers
// creation of a new tag using
// comma instead of semicolon
const tagCommaDelimiters = [
  9 /* tab key */,
  13 /* enter key */,
  188 /* comma */,
];

const makeTags = (values: string[] | number[] | null): Tag[] => {
  if (values) {
    return values.map((value) => ({ id: value, name: value }));
  }

  return [];
};

interface PillSelectorProps {
  handleCreate: (createdTag: Tag) => void;
  handleDelete: (deletedTag: Tag, i: number) => void;
  id: string;
  selectedValues: string[] | number[];
  className?: string;
  error?: ReactNode;
  label?: ReactNode;
  placeholder?: string;
  required?: boolean;
  setSearchQuery?: SetStateDispatch<string>;
  suggestedValues?: string[] | number[] | null;
  useCommaAsDelimiter?: boolean;
}

export const PillSelector: FunctionComponent<PillSelectorProps> = ({
  className,
  error,
  handleCreate,
  handleDelete,
  id,
  label,
  placeholder,
  required,
  selectedValues,
  suggestedValues,
  useCommaAsDelimiter,
  setSearchQuery = null,
}) => {
  const labelHTML = (): ReactNode => (
    <label
      className="label-and-error-container__label"
      htmlFor={id}
    >
      {label}
      {required && <span className="required-asterix">*</span>}
    </label>
  );

  const errorHTML = (): ReactNode => (
    <span
      className="label-and-error-container__error"
      id={`${id}_error`}
      role="alert"
    >
      {error}
    </span>
  );

  const TagComponent = (props: TagComponentProps): ReactElement => (
    <StandardPill
      onClose={(): void => { handleDelete(props.tag, selectedValues.findIndex((value) => (props.tag.id === value))); }}
      value={props.tag.id}
    >
      {props.tag.name}
    </StandardPill>
  );

  const selectedPills = makeTags(selectedValues);

  return (
    <div
      aria-required={required}
      className={`${className} ${error ? 'pill-selector pill-error' : 'pill-selector'}`}
    >
      {(!!error || !!label) && (
        <div className="label-and-error-container">
          {!!label && labelHTML()}
          {!!error && errorHTML()}
        </div>
      )}
      <ReactTags
        addOnBlur
        allowBackspace
        allowNew
        autofocus={false}
        delimiters={useCommaAsDelimiter ? tagCommaDelimiters : tagDelimiters}
        handleAddition={(tag): void => {
          // TODO: add optional prop that will filter out duplicate tags: compare added tag against selectedPills
          handleCreate(tag);
        }}
        handleDelete={(i): void => {
          if (i > -1) handleDelete(selectedPills[i], i);
        }}
        handleInputChange={(input: string): void => {
          if (!!setSearchQuery) {
            setSearchQuery(input);
          }
        }}
        minQueryLength={1}
        placeholder={(!selectedPills || selectedPills.length === 0) ? placeholder ?? t`Input values` : ''}
        suggestions={makeTags(suggestedValues)}
        tagComponent={TagComponent}
        tags={selectedPills || []}
      />
    </div>
  );
};
