import fetchJSON from '@api/api_helper';

import {
  CreateLabelOptions,
  GetLabelsResponseData,
  LabelTree,
  LabelTreeChild,
  isLabelTreeChild,
  GetLabelsResponse
} from './types/labelsTypes';

const { type: fxType, key: fxKey } = BF.fx.resource();

const url = (
  resourceType?: string,
  resourceKey?: string
): string => `/api/v4/${resourceType ?? fxType}s/${resourceKey ?? fxKey}/labels?per=3000`;

interface GetLabelsOptions {
  fetchAll?: boolean;
  maxPages?: number;
  params?: Record<string, string>;
  resourceKey?: string;
  resourceType?: string;
  updateFetchControllers?: (controller: AbortController) => void;
}

/**
 * GET labels. Defaults getting the first 3,000 labels (the API max). Pass in fetchAll as true to get all labels.
 * @param {GetLabelsOptions | undefined} options (optional)
 * @returns {Promise<GetLabelsResponseData[]>} GetLabelsResponseData[] (array of labels)
 */
export const get = async (options?: GetLabelsOptions): Promise<GetLabelsResponseData[]> => {
  const { fetchAll, maxPages, params, resourceType, resourceKey, updateFetchControllers } = options || {};
  let labels: GetLabelsResponseData[] = [];
  const paramString = params ? Object.keys(params).map((k) => `&${k}=${params[k]}`).join('') : '';
  const labelsUrl = `${url(resourceType, resourceKey)}${paramString}`;

  try {
    const firstPage = await fetchJSON(labelsUrl, {}, updateFetchControllers) as GetLabelsResponse;
    labels = firstPage.data;

    if (fetchAll && firstPage.meta.total_pages > 1) {
      // create a numeric array out of the remaining total_pages
      // for example, if total_pages = 4, remainingPages would be [2, 3, 4]
      const totalRemainingPages = firstPage.meta.total_pages - 1;
      const remainingPages = Array.from({
        length: maxPages < firstPage.meta.total_pages ? maxPages - 1 : totalRemainingPages
      }, (_, k) => k + 2);

      // we use Promise.all to get the remaining labels from the API in parallel
      await Promise.all(
        remainingPages.map(async (page) => {
          const result = await fetchJSON(`${labelsUrl}&page=${page}`, {}, updateFetchControllers) as GetLabelsResponse;
          labels = [...labels, ...result.data];
        })
      );
    }

    return await Promise.resolve(labels);
  } catch (err) {
    throw (err?.name === 'AbortError' ? err : await err?.json() || new Error('Unknown error getting labels'));
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const create = (options: CreateLabelOptions): Promise<any> => (
  // example options: {name: 'Sales Team', position: 6, parent_key: 'abcd'}
  // omitted parent_key results in top level label
  // omitted position results in position 0
  fetchJSON(url(), {
    method: 'POST',
    body: {
      data: {
        attributes: {
          ...options
        }
      }
    }
  }).catch(async (response) => { throw (await response.json()); })
);

const buildTree = (parentLabel = {} as LabelTree | LabelTreeChild, allLabels = []): LabelTree => {
  // leverage typeguard. Unfortunately have to call it each time to set type of assignment
  const parentKey = isLabelTreeChild(parentLabel) ? parentLabel.id : undefined;
  const labelName = isLabelTreeChild(parentLabel) ? parentLabel.attributes.name : undefined;
  const children = allLabels
    .filter(({ parentId }) => parentId === parentKey)
    .sort((a, b) => a.attributes.position - b.attributes.position);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const label: any = {
    ...parentLabel,
    title: labelName,
    name: labelName,
    key: parentKey,
    children: children.map((child) => buildTree(child, allLabels))
  };

  return label;
};

// helper: returns tree object of all labels with their attributes
export const formatLabelsTree = (labels: GetLabelsResponseData[], expandedLabels = [] as string[]): LabelTree => {
  const labelsWithParentId = labels.map((label) => ({
    ...label,
    expanded: expandedLabels.includes(label.id),
    parentId: label.attributes.path.length > 1 ? label.attributes.path[label.attributes.path.length - 2] : undefined
  }));
  const labelsTree = buildTree({} as LabelTree, labelsWithParentId);

  return labelsTree;
};

// helper: returns flat object of all labels with their attributes
// TODO set return type
// eslint-disable-next-line
export const formatLabelsFlat = (labels) => {
  const labelsFlat = {};

  labels.forEach((label) => { labelsFlat[label.id] = label.attributes; });

  return labelsFlat;
};
