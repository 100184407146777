import classnames from 'classnames';
import React, { BaseHTMLAttributes, FunctionComponent, ReactNode } from 'react';

import classes from '../styles/tab_panel.module.scss';

type BaseDivAttributesOmitted = Omit<BaseHTMLAttributes<HTMLDivElement>,
'aria-labelledby' | 'hidden' | 'role' | 'tabIndex'>

interface TabPanelProps extends BaseDivAttributesOmitted {
  active: boolean;
  /** content is required */
  children: ReactNode;
  /** required for accessibility */
  id: string;
  /** the id of the TabButton (required for accessibility) */
  tabButtonId: string;
}

/**
 * The content for tabs
 * @param {TabPanelProps} props TabPanelProps
 */
export const TabPanel: FunctionComponent<TabPanelProps> = (props) => {
  const { active, children, id, tabButtonId, ...divProps } = props;

  return (
    <section
      {...divProps}
      aria-labelledby={tabButtonId}
      className={classnames(classes.tabPanel, divProps.className)}
      data-testid={id}
      hidden={!active}
      id={id}
      role="tabpanel"
      tabIndex={0}
    >
      {children}
    </section>
  );
};
