import { brandfolderColorPalette } from '@brandfolder/react';
import React, { FunctionComponent } from 'react';

import styled from 'styled-components';

import { Person } from '../customPeopleTagTypes';

const SuggestedList = styled.ul`
  background-color: ${brandfolderColorPalette.neutrals[0]};
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  list-style: none;
  padding: 8px;
  position: absolute;
  margin-top: 2px;
  z-index: 2;

  .autocomplete-option {
    &-image {
      border-radius: 40px;
      height: 40px;
      margin-right: 12px;
      width: 40px;
    }

    &-button {
      align-items: center;
      background-color: transparent;
      border: none;
      display: flex;
      margin: 2px 0;
      padding: 8px;
      text-align: left;
      width: 100%;
      
      &:hover {
        background-color: ${brandfolderColorPalette.neutrals[100]};
        border-radius: 4px;
      }
    }
  }
`;

interface SuggestedNamesListProps {
  handleOnSelectTargetPerson: (person: Person) => void;
  suggestions: Person[];
  listClasses?: string;
}

export const SuggestedNamesList: FunctionComponent<SuggestedNamesListProps> = ({
  suggestions,
  handleOnSelectTargetPerson,
  listClasses = null
}) => (
  <SuggestedList className={listClasses}>
    {suggestions?.map((person) => (
      <li key={person.face_uri} className="autocomplete-option">
        <button
          className="autocomplete-option-button"
          id={person.person_id}
          onClick={(): void => handleOnSelectTargetPerson(person)}
          type="button"
        >
          <img alt="person thumbnail" className="autocomplete-option-image" src={person.face_uri} />
          <span>{person.tag_name}</span>
        </button>
      </li>
    ))}
  </SuggestedList>
);
