import { Reducer } from 'react';

import { ToastAction, ToastActionType, ToastContext } from './toastTypes';

export const reducer: Reducer<ToastContext, ToastAction> = (state, action) => {
  switch (action.type) {
    case ToastActionType.AddToast:
      return { toasts: [...state.toasts, action.payload.value] };
    case ToastActionType.RemoveToast:
      return {
        toasts: state.toasts.filter(({ id }) => id !== action.payload.value.id),
      };
    default:
      return state;
  }
};
