import oldType from '@helpers/oldType';
import { Brandfolder } from '@helpers/show_page_helpers';
import fetchJSON from '@api/api_helper';

export default ({ sectionKey, type, assetId, index, token }) => {
  const url = `/${Brandfolder.slug}/sections/${sectionKey}/ui/${oldType(type)}/${assetId}/position/${index}`;

  return fetchJSON(url, {
    method: 'PUT',
    headers: {
      'X-CSRF-TOKEN': token,
      'Content-type': 'application/json',
    }
  });
};
