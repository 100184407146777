import React from "react";
import PropTypes from "prop-types";

function ClippingOptions(props) {
  const validateClipping = (clipTime) => (clipTime <= props.attachment.duration);

  const secondsToMinutes = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const hoursDisplay = (hours > 0) ? `${hours}:` : "";
    let remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const minutesDisplay = (`0${minutes}`).slice(-2);
    remainingSeconds %= 60;
    const secondsDisplay = (`0${parseInt(remainingSeconds.toString().substring(0, 2), 10)}`).slice(-2);
    return `${hoursDisplay}${minutesDisplay}:${secondsDisplay}`;
  };

  const minutesToSeconds = (formattedTime) => {
    if (formattedTime.match(/:/g).length === 2) {
      const [hours, minutes, seconds] = formattedTime.split(":").map(
        (timeframe) => parseInt(timeframe, 10)
      );
      return ((hours || 0) * 3600) + (minutes * 60) + seconds;
    }
    const [minutes, seconds] = formattedTime.split(":").map(
      (timeframe) => parseInt(timeframe, 10)
    );
    return (minutes * 60) + seconds;
  };

  const showDuration = () => ((props.attachment.duration > 60)
    ? secondsToMinutes(props.attachment.duration) : props.attachment.duration);

  const handleClipping = (e, start) => {
    const clipTime = e.target.value;
    let clipSeconds;
    if (!clipTime && start) {
      clipSeconds = 0;
    } else if (!clipTime && !start) {
      clipSeconds = props.attachment.duration;
    } else if (clipTime && clipTime.includes(":")) {
      clipSeconds = minutesToSeconds(clipTime);
    } else {
      clipSeconds = parseFloat(clipTime, 10);
    }

    if (validateClipping(clipSeconds)) {
      if (start) {
        props.player.tech().setCurrentTime(clipSeconds);
        props.player.pause();
        props.updateClipStart(clipSeconds);
      } else {
        props.updateClipEnd(clipSeconds);
        // props.player.duration(clipSeconds); can't go back
      }
    } else if (clipSeconds) {
      Notify.create({ title: `Invalid ${start ? "start" : "end"} time.`, type: "error" });
      e.target.value = showDuration();
    }
  };

  return (
    <div className="clipping-options">
      <label className="video-label bf-label bf-label--primary full">Clip Start</label>
      <input
        className="full"
        type="text"
        placeholder={0}
        defaultValue={0}
        onMouseLeave={(e) => handleClipping(e, true)}
      />
      <label className="video-label bf-label bf-label--primary full">Clip End</label>
      <input
        className="full"
        type="text"
        placeholder={showDuration()}
        defaultValue={showDuration()}
        onMouseLeave={(e) => handleClipping(e, false)}
      />
    </div>
  );
}

ClippingOptions.propTypes = {
  attachment: PropTypes.shape({
    duration: PropTypes.number
  }).isRequired,
  updateClipStart: PropTypes.func.isRequired,
  updateClipEnd: PropTypes.func.isRequired,
  player: PropTypes.shape({
    tech: PropTypes.func,
    pause: PropTypes.func
  })
};

ClippingOptions.defaultProps = {
  player: undefined
};

export default ClippingOptions;
