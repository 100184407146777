const downloadBlob = (blob, filename) => {
  const anchor = document.createElement('a');
  anchor.style = 'display: none';
  document.body.appendChild(anchor);
  const objUrl = window.URL.createObjectURL(blob);
  anchor.href = objUrl;
  anchor.download = filename;
  anchor.click();
  window.URL.revokeObjectURL(objUrl);
};

export default downloadBlob;
