import { Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent } from 'react';

import { useFetch } from '@api/ApiHelper';
import { PreviewType } from '@api/v4/attachments/attachmentTypes';
import { IssuesResponse } from '@api/v4/private/assets/issues';
import AssetAttachment, {
  FullAttachment
} from '@components/asset/modal/tabs/asset_details/asset_attachments/AssetAttachment';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import './styles/_asset_attachments_list.scss';

interface AttachmentConversionInfo {
  attachmentKey: string;
  conversionOptions: string[];
  colorSpace: string;
  isConvertable: boolean;
  presetSizes: number[];
  previewType: PreviewType;
  processed: boolean;
  restrictedDimensions: {
    width: { min: number; max: number };
    height: { min: number; max: number };
  };
  thumbnailUrl: string;
}

export interface AssetAttachmentsProps {
  assetCreatedAt: string;
  assetKey: string;
  attachmentConversionInfo: AttachmentConversionInfo[];
  attachments: FullAttachment[];
  downloadRequestId: number | null;
  isDownloadable: boolean;
  isProcessing: boolean;
  manifestDigestPresent: boolean;
  viewOnly: boolean | null;
}

const AssetAttachments: FunctionComponent<AssetAttachmentsProps> = ({
  assetCreatedAt,
  assetKey,
  attachmentConversionInfo,
  attachments,
  downloadRequestId,
  isDownloadable,
  isProcessing,
  viewOnly
}) => {
  const [selectedAttachmentKey, setSelectedAttachmentKey] = useState(attachments[0].attachment_key);
  const fetchIssues = useFetch<IssuesResponse>({
    url: `/api/v4/private/assets/${assetKey}/issues`
  });

  useEffect(() => {
    BF.fx.dispatchWindowEvent('updateSelectedAttachment', null, { attachmentKey: selectedAttachmentKey });
  }, [selectedAttachmentKey]);

  // This check is because some older (pre 2021) assets are stuck in 'processing' state
  // when they have all conversion info (height, width etc.)
  // so the conversion options shouldn't be hidden for these
  // whereas newer assets in 'processing' most likely don't have conversion info so the conversion
  // options SHOULD be hidden in this case
  const isOld = (new Date(assetCreatedAt) < new Date('1/1/2021'));
  const displayAsProcessed = !isProcessing || (isProcessing && isOld);

  const attachmentChildren = attachments.map((attachment) => {
    const {
      conversionOptions,
      colorSpace,
      isConvertable,
      presetSizes,
      previewType,
      processed,
      restrictedDimensions,
      thumbnailUrl
    } = attachmentConversionInfo.find((att) => (
      att.attachmentKey === attachment.attachment_key
    ));
    return (
      <AssetAttachment
        key={attachment.attachment_key}
        assetKey={assetKey}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        attachment={{ ...attachment, thumbnail_url: thumbnailUrl }}
        attachmentConversionOptions={conversionOptions}
        colorSpace={colorSpace}
        displayAsProcessed={displayAsProcessed}
        downloadRequestId={downloadRequestId}
        isConvertable={isConvertable}
        isDownloadable={isDownloadable}
        issues={fetchIssues}
        presetSizes={presetSizes}
        previewType={previewType}
        processed={processed}
        restrictedDimensions={restrictedDimensions}
        selectedAttachmentKey={selectedAttachmentKey}
        setSelectedAttachmentKey={setSelectedAttachmentKey}
        viewOnly={viewOnly}
      />
    );
  });

  /**
   * the nested HTML here means that we need to create a separate <Trans> component
   * that wraps the full clause "n attachments" or "n attachment"
   * It may look like a lot of duplication but this allows translators to reorder
   * the count and the label without losing the html structure
   * That said, this situation should be avoided because Plural accounts for more than just
   * the one/other plurality of English and this method does not
   */
  const pluralAttachments = (
    <Trans>
      <h4
        className="asset-attachments__list--title-count"
        data-testid="asset-attachments--title-count"
      >
        {attachments.length}
      </h4>
      <h4>attachments</h4>
    </Trans>
  );

  const singularAttachment = (
    <Trans>
      <h4
        className="asset-attachments__list--title-count"
        data-testid="asset-attachments--title-count"
      >
        {attachments.length}
      </h4>
      <h4>attachment</h4>
    </Trans>
  );

  return (
    <I18nProviderWrapper>
      <div className="asset-attachments__list">
        <div className="asset-attachments__list--title__container">
          <div className="asset-attachments__list--title">
            <span className="bff-paperclip" />
            {attachments.length > 1 ? pluralAttachments : singularAttachment}
          </div>
          {!displayAsProcessed && (
            <div className="asset-attachments__list--title" data-testid="asset-attachments--processing">
              <img
                alt=""
                aria-hidden
                src="https://cdn.bfldr.com/27C9EC93/at/jgp7255xw8jcg79x68pk9pqt/processing"
              />
              <span className="asset-attachments__list--title-processing-label">
                <Trans>Processing</Trans>
              </span>
            </div>
          )}
        </div>
        {attachmentChildren}
      </div>
    </I18nProviderWrapper>
  );
};

export default AssetAttachments;
