/* eslint-disable @typescript-eslint/naming-convention */
import { StandardLink } from '@brandfolder/react';
import { sanitize, localizeDate } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, ReactNode } from 'react';

import {
  shouldDisplayAssetHistory
} from '@components/asset/modal/tabs/workflow/asset_details/asset-history-log/should-display-asset-history';

import { AssetDetailsRule } from './AssetDetailsRule';
import { getTimezone } from './helpers/asset-details-metadata';

import classes from './styles/asset-details-metadata.module.scss';

interface AssetDetailsMetadataProps {
  approvedBy: string;
  awaitingApproval: string;
  collectionExpiration: string;
  expired: boolean;
  expirationDate: string;
  isWorkspace: boolean;
  publishDate: string;
  unpublished: boolean;
  updatedAndCreated: string;
}

export const AssetDetailsMetadata: FunctionComponent<AssetDetailsMetadataProps> = (props) => {
  const {
    approvedBy,
    awaitingApproval,
    collectionExpiration,
    expired,
    expirationDate,
    isWorkspace,
    publishDate,
    unpublished,
    updatedAndCreated
  } = props;

  const openAssetHistoryLog = (): void => {
    const historyLogEvent = new CustomEvent('openAssetHistoryLog');
    window.dispatchEvent(historyLogEvent);
    const workflowTab = document.getElementsByClassName('workflow j-trigger-react-tab');
    if (workflowTab && workflowTab.length > 0) {
      const tabLink = workflowTab[0].children[0] as HTMLAnchorElement;
      tabLink?.click();
    }
  };

  const renderDot = (): ReactNode => (
    isWorkspace ? <span aria-hidden className={classes['asset-details-metadata__bullet']}>&middot;</span> : null
  );

  return (
    <div className={isWorkspace && classes['asset-details-metadata']}>
      <div className={classes['asset-details-metadata__created-updated']}>
        {!!updatedAndCreated && <div dangerouslySetInnerHTML={{ __html: sanitize(updatedAndCreated) }} />}
        {shouldDisplayAssetHistory() && (
          <>
            <span aria-hidden className={classes['asset-details-metadata__bullet']}>&middot;</span>
            <StandardLink onClick={openAssetHistoryLog}>
              <Trans>View recent updates</Trans>
            </StandardLink>
          </>
        )}
      </div>

      {!!approvedBy && renderDot()}
      {!!approvedBy && <div dangerouslySetInnerHTML={{ __html: approvedBy }} />}

      {!!awaitingApproval && renderDot()}
      {!!awaitingApproval && <div dangerouslySetInnerHTML={{ __html: awaitingApproval }} />}

      {!!collectionExpiration && renderDot()}
      {!!collectionExpiration && <div dangerouslySetInnerHTML={{ __html: collectionExpiration }} />}

      {!!publishDate && renderDot()}
      {!!publishDate && (
        <div className="s-modal-published-on">
          {unpublished ? <Trans>Will Publish on {localizeDate(publishDate)} {getTimezone()}</Trans> : <Trans>Published on {localizeDate(publishDate)} {getTimezone()}</Trans>}
        </div>
      )}

      {!!expirationDate && renderDot()}
      {!!expirationDate && (
        <div className="s-modal-expires-on">
          {expired ? <Trans>Expired on {localizeDate(expirationDate)} {getTimezone()}</Trans> : <Trans>Expires on {localizeDate(expirationDate)} {getTimezone()}</Trans>}
        </div>
      )}

      {isWorkspace && <AssetDetailsRule />}
    </div>
  );
};
