/* eslint-disable react/require-default-props */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import EventCard from '@components/asset/modal/tabs/workflow/attachment_details/versions_sidebar/event_card';

const VersionFeed = (props) => {
  const {
    handlePostAnnotations,
    mentionableUsers,
    selectedSortOption,
    setAnnotationsResponse,
    setMentionableUsers,
    setTempAnnotation,
    users,
    versionCard,
  } = props;
  const buildName = (nameId) => (
    (users[nameId]?.first_name + users[nameId]?.last_name)?.toUpperCase()
  );

  const sortAlphabetically = (a, b) => {
    let comparison = 0;
    if (a > b) comparison = 1;
    if (b > a) comparison = -1;
    return comparison * (selectedSortOption.type === 'asc' ? 1 : -1);
  };

  const sortingFunctions = {
    author_a_z: (a, b) => sortAlphabetically(buildName(a.author), buildName(b.author)),
    author_z_a: (a, b) => sortAlphabetically(buildName(a.author), buildName(b.author)),
    newest: (a, b) => (new Date(b.created_at) - new Date(a.created_at)),
    oldest: (a, b) => (new Date(a.created_at) - new Date(b.created_at)),
    type: (a, b) => sortAlphabetically(a.type, b.type),
  };

  const sortedAnnotations = [...versionCard.annotations].sort(sortingFunctions[selectedSortOption.value]);
  let annotationCount = selectedSortOption.type === 'asc'
    ? sortedAnnotations.filter((annotation) => (annotation.type === 'annotation')).length
    : 1;

  const handleAnnotationNumber = (eventType) => {
    const currentNumber = annotationCount;
    if (eventType === 'annotation') {
      annotationCount = selectedSortOption.type === 'asc'
        ? annotationCount - 1
        : annotationCount + 1;
    }
    return currentNumber;
  };

  return (
    <div className="feed">
      {!sortedAnnotations.length ? (
        <h4 className="no-content-display">
          <Trans>
            No comments or annotations to display
          </Trans>
        </h4>
      ) : (
        sortedAnnotations.map((cardEvent) => (
          <EventCard
            key={cardEvent.id}
            annotationNumber={handleAnnotationNumber(cardEvent.type)}
            cardEvent={cardEvent}
            mentionableUsers={mentionableUsers}
            onReply={handlePostAnnotations}
            setAnnotationsResponse={setAnnotationsResponse}
            setMentionableUsers={setMentionableUsers}
            setTempAnnotation={setTempAnnotation}
            users={users}
          />
        ))
      )}
    </div>
  );
};

VersionFeed.propTypes = {
  handlePostAnnotations: PropTypes.func.isRequired,
  mentionableUsers: PropTypes.arrayOf(PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  })),
  setAnnotationsResponse: PropTypes.func.isRequired,
  selectedSortOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  setMentionableUsers: PropTypes.func.isRequired,
  setTempAnnotation: PropTypes.func.isRequired,
  versionCard: PropTypes.shape({
    created_at: PropTypes.string,
    type: PropTypes.oneOf(['reservation', 'version']),
    id: PropTypes.string,
    version_details: PropTypes.shape({
      filename: PropTypes.string,
      small_thumbnail: PropTypes.string,
    }),
    annotations: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(['annotation', 'comment']),
    })),
  }).isRequired,
  users: PropTypes.shape({}).isRequired,
};

VersionFeed.defaultProps = {
  mentionableUsers: null,
};

export default VersionFeed;
