import { StandardButton } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import { UserRoles } from '@api/v4/UserTypes';
import { userPermissionOptions } from '@components/bulk_management/user-settings/helpers';
import { ListDropdown, ListOption } from '@components/library/dropdown';

import { ServerResourceType } from './resource-sidebar';

import './styles/grant-access-form.scss';
interface GrantAccessRequestProps {
  accessRequestFetch: (fetchDataOptions?: UseFetchOptions) => Promise<void>;
  selectedResourceKey: string;
  selectedResourceType: ServerResourceType;
  selectedEmails: string[];
  setGrantedAccessRequest: SetStateDispatch<boolean>;
  setReloadPlanLimits: SetStateDispatch<boolean>;
  setReloadUsers: SetStateDispatch<boolean>;
  setReloadPendingInvitations: SetStateDispatch<boolean>;
  setShowAlert: SetStateDispatch<boolean>;
  setShowError: SetStateDispatch<boolean>;
}

export const GrantAccessRequestForm: FunctionComponent<GrantAccessRequestProps> = ({
  accessRequestFetch,
  selectedResourceKey,
  selectedResourceType,
  selectedEmails,
  setGrantedAccessRequest,
  setReloadPlanLimits,
  setReloadUsers,
  setReloadPendingInvitations,
  setShowAlert,
  setShowError
}) => {
  const [permissionLevel, setPermissionLevel] = useState<string>(UserRoles.Guest);

  const postInvitations = useFetch({
    url: '/api/v4/invitations',
    fetchOnMount: false,
    method: 'POST',
    /* eslint-disable @typescript-eslint/naming-convention */
    body: {
      data: {
        resources: [{
          resource_type: selectedResourceType,
          resource_key: selectedResourceKey
        }],
        attributes: {
          emails: selectedEmails,
          permission_level: permissionLevel
        }
      }
    }
    /* eslint-disable @typescript-eslint/naming-convention */
  });

  useEffect(() => {
    if (postInvitations.response) {
      // gives the bulk invitation job 1 second to reasonably complete on the backend
      setTimeout(() => {
        accessRequestFetch();
        setReloadPlanLimits(true);
        setReloadUsers(true);
        setReloadPendingInvitations(true);
        setShowAlert(true);
      }, 1000);
    }
  }, [postInvitations.response]);

  useEffect(() => {
    if (postInvitations.error) {
      setShowError(true);
    }
  }, [postInvitations.error]);

  const handleGrantAccessSubmit = (): void => {
    if (selectedEmails.length > 0) {
      setShowAlert(false);
      setShowError(false);
      setGrantedAccessRequest(true);
      postInvitations.fetch();
    }
  };

  return (
    <div className="grant-access">
      <h3 className="grant-access__dropdown-heading"><Trans>Permission Level</Trans></h3>
      <div className="grant-access-form-wrapper">
        <ListDropdown
          id='access-request-permission-levels'
          onChange={(selectedOption: ListOption): void => {
            setPermissionLevel(selectedOption.value as string);
          }}
          options={userPermissionOptions()}
          value={permissionLevel}
          virtualizeOptions={false}
        />
        <StandardButton
          className="grant-access-button"
          id="grant-access-button"
          onClick={(): void => handleGrantAccessSubmit()}
        >
          <Trans>Grant access</Trans>
        </StandardButton>
      </div>
    </div>
  );
};
