import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { TextButton } from '@components/library/button';

import classes from './styles/press_release_toggle_all.module.scss';

interface PressReleaseToggleAllProps {
  handleDeselectAll: (deselectAll?: boolean) => void;
  handleSelectAll: (selectAll?: boolean) => void;
  heading: string;
  deselectAllText?: string;
  disableDeselectAll?: boolean;
  disableSelectAll?: boolean;
  selectAllText?: string;
  showToggles?: boolean;
}

export const PressReleaseToggleAll: FunctionComponent<PressReleaseToggleAllProps> = (props) => {
  const {
    deselectAllText,
    disableDeselectAll,
    disableSelectAll,
    handleDeselectAll,
    handleSelectAll,
    heading,
    selectAllText,
    showToggles = true
  } = props;

  return (
    <div className={classes.toggleAll}>
      <h4 className={classes.h4}>{heading}</h4>
      {showToggles && (
        <>
          <TextButton
            disabled={disableSelectAll}
            onClick={(): void => handleSelectAll(true)}
          >
            {selectAllText || <Trans>Select All</Trans>}
          </TextButton>
          <span aria-hidden className={classes.pipe}>|</span>
          <TextButton
            disabled={disableDeselectAll}
            onClick={(): void => handleDeselectAll(false)}
          >
            {deselectAllText || <Trans>Deselect All</Trans>}
          </TextButton>
        </>
      )}
    </div>
  );
};
