import { ListboxOption, StandardCombobox } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { ApiDataResponse } from '@api/v4/ApiResponseTypes';
import { CollectionServer } from '@api/v4/collections';
import { isGettyClient } from '@helpers/getty-strings';
import { Brandfolder } from '@mocks/api/v4/brandfolders';

import { ResourceType } from './BrandguideCreateForm';

import classes from './styles/brandguide-combobox.module.scss';

interface BrandguidesComboboxProps {
  organizationKey: string;
  setResourceKey: React.Dispatch<React.SetStateAction<string>>,
  type: ResourceType;
}

export const BrandguidesCombobox: FunctionComponent<BrandguidesComboboxProps> = ({
  organizationKey,
  setResourceKey,
  type
}) => {
  const [options, setOptions] = useState<ListboxOption[]>([]);
  const {
    fetch: brandfoldersFetch,
    loading: brandfoldersLoading,
    response: brandfoldersResponse
  } = useFetch<ApiDataResponse<Brandfolder, 'brandfolders'>>({
    fetchOnMount: false,
    url: `/api/v4/organizations/${organizationKey}/brandfolders`
  });

  const {
    fetch: collectionsFetch,
    loading: collectionsLoading,
    response: collectionsResponse
  } = useFetch<ApiDataResponse<CollectionServer, 'collections'>>({
    fetchOnMount: false,
    url: `/api/v4/organizations/${organizationKey}/collections?is_workspace=false&per=3000`
  });

  useEffect(() => {
    if (type === 'brandfolder') {
      brandfoldersFetch();
    } else {
      collectionsFetch();
    }
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (brandfoldersResponse) {
      setOptions([
        ...brandfoldersResponse?.data.map((brandfolder) => {
          return {
            children: brandfolder.attributes.name,
            key: brandfolder.id,
            value: brandfolder.id
          };
        })
      ]);
    }
  }, [brandfoldersResponse]);

  useEffect(() => {
    if (collectionsResponse) {
      setOptions([
        ...collectionsResponse?.data.map((collection) => {
          return {
            children: collection.attributes.name,
            key: collection.id,
            value: collection.id
          };
        })
      ]);
    }
  }, [collectionsResponse]);

  const gettyClient = isGettyClient();
  const brandfolderToggleCopy = gettyClient ? t`Toggle Libraries` : t`Toggle Brandfolders`;
  const chooseBrandfolderCopy = gettyClient
    ? t`Choose a Library`
    : t`Choose a Brandfolder`;
  const brandfolderListCopy = gettyClient ? t`List of Libraries` : t`List of Brandfolders`;
  const placeholder = type === 'brandfolder' ?  chooseBrandfolderCopy : t`Choose a Collection`;

  return (
    <StandardCombobox
      id={`${type}-options`}
      labels={{
        iconButtonLabel: type === 'brandfolder' ?  brandfolderToggleCopy : t`Toggle Collections`,
        label: placeholder,
        listboxLabel: type === 'brandfolder' ?  brandfolderListCopy : t`List of Collections`,
        loaderLabel: t`Loading`,
        requiredLabel: t`Required`
      }}
      loading={type === 'brandfolder' ? brandfoldersLoading : collectionsLoading}
      onSelection={(option): void => {
        setResourceKey(option.value as string);
      }}
      optionProps={{ className: classes.brandguideOptions, index: null, onSelected: null, option: null }}
      options={[{
        children: null,
        key: 0,
        placeholder,
        value: '' },
      ...options
      ]}
      required
      showLabel={false}
    />
  );
};
