import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState, FunctionComponent, MutableRefObject } from 'react';

import { getUniqueValues } from '@api/v4/resources/custom_field_values';
import { getCustomFieldKeys } from '@api/v4/resources/custom_fields';
import { CustomFieldKeysResponseObject } from '@api/v4/resources/CustomFieldKeysTypes';
import { Locale } from '@components/common/language_menu/languagesMap';
import { BFLoader } from '@components/common/loader/main';
import { MultiselectDropdown, ListOption, ListOptionValue } from '@components/library/dropdown';
import { StandardSwitch } from '@components/library/switch';
import {
  getCustomFieldKeysOptions,
  syncCustomFields,
  CustomFieldValues
} from '@components/show_page/sections/advanced_filters/customFieldsHelper';
import { FilterType, SearchFilterOperator, SearchFilterOperators } from '@components/show_page/sections/section_search/SearchTypes';

type ChangeFilters = (filterKey: string, filterValue: string) => void;
interface PrioritizedCustomFieldFilterProps {
  filteredCustomFieldsStringified: string;
  loading: boolean;
  removeFilters: ChangeFilters;
  searchFilterOperators: SearchFilterOperators;
  selectedUGTLocale: Locale;
  updateFilters: ChangeFilters;
  updateSearchFilterOperator: (filterType: string, operatorUpdate: { [key: string]: SearchFilterOperator }) => void;
  advancedFiltersContainerRef?: MutableRefObject<HTMLElement>;
}

interface CustomFieldValueOptions {
  [key: string]: ListOption[];
}

const createOptions = (values: ListOptionValue[] | undefined): ListOption[] => (
  values?.map((value) => ({ label: value?.toString() || '', value })) || []
);

export const PrioritizedCustomFieldFilter: FunctionComponent<PrioritizedCustomFieldFilterProps> = ({
  advancedFiltersContainerRef,
  filteredCustomFieldsStringified,
  loading,
  removeFilters,
  searchFilterOperators,
  selectedUGTLocale,
  updateFilters,
  updateSearchFilterOperator,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [prioritizedCustomFieldKeys, setPrioritizedCustomFieldKeys] = useState<CustomFieldKeysResponseObject[]>([]);
  const [prioritizedCustomFieldValueOptions, setPrioritizedCustomFieldValueOptions] = useState<CustomFieldValueOptions | undefined>(undefined);
  const [selectedValues, setSelectedValues] = useState<CustomFieldValues | undefined>(undefined);

  const getNameForKey = (selectedKey: string): string => (
    prioritizedCustomFieldKeys.find((key) => selectedKey === key.id)?.attributes?.name || ''
  );

  const fetchCustomFieldKeysAsync = async (): Promise<void> => {
    const response = await getCustomFieldKeys(getCustomFieldKeysOptions(selectedUGTLocale));
    const prioritized = response.data?.flatMap((key) => (key.attributes.prioritized ? key : []));
    setPrioritizedCustomFieldKeys(prioritized);
    setIsLoading(false);
  };

  const fetchCustomFieldValuesAsync = async (customFieldKeyId: string): Promise<void> => {
    if (!prioritizedCustomFieldValueOptions?.[customFieldKeyId]) {
      // values are fetched as needed when a user selects the custom field key dropdown
      // fetch values if not cached in state already
      try {
        const response = await getUniqueValues(customFieldKeyId, selectedUGTLocale);
        setPrioritizedCustomFieldValueOptions((prevState) => ({
          ...prevState,
          [customFieldKeyId]: createOptions(response.custom_field_values),
        }));
      } catch {
        // TODO catch something?
      }
    }
  };

  const handleChange = (
    selectedOptionValues: ListOptionValue[],
    isAdded: boolean,
    updatedValue: ListOptionValue,
    selectedKey: string,
  ): void => {
    setSelectedValues((prevState) => {
      if (isAdded) {
        return {
          ...prevState,
          [selectedKey]: [...selectedOptionValues]
        };
      }

      const updatedState = { ...prevState };
      const updatedValues = updatedState[selectedKey].filter((values) => values !== updatedValue);
      if (!updatedValues.length) {
        delete updatedState[selectedKey];
      } else {
        updatedState[selectedKey] = updatedValues;
      }
      return updatedState;
    });

    if (isAdded) {
      updateFilters(getNameForKey(selectedKey), updatedValue as string);
    } else {
      removeFilters(getNameForKey(selectedKey), updatedValue as string);
    }
  };

  const handleSwitchChange = (customFieldName: string): void => {
    const currentOperator = searchFilterOperators.custom_fields?.[customFieldName];
    const updatedOperator = currentOperator === SearchFilterOperator.AND
      ? SearchFilterOperator.OR
      : SearchFilterOperator.AND;
    updateSearchFilterOperator(FilterType.CustomFields, { [customFieldName]: updatedOperator });
  };

  useEffect(() => {
    syncCustomFields(filteredCustomFieldsStringified, prioritizedCustomFieldKeys, setSelectedValues);
  }, [filteredCustomFieldsStringified, prioritizedCustomFieldKeys]);

  useEffect(() => {
    fetchCustomFieldKeysAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isLoading && prioritizedCustomFieldKeys.length === 0) {
    return null;
  }

  return (
    <div className="filter-section prioritized-custom-fields">
      {isLoading ? (
        <BFLoader />
      ) : prioritizedCustomFieldKeys.map((key) => {
        const listOptionValues = selectedValues?.[key.id];
        const hasSelectedValues = (listOptionValues && listOptionValues.length > 1) || false;
        return (
          <div
            key={key.id}
            className="prioritized-custom-fields__row"
          >
            {hasSelectedValues && (
              <div className="any-all-switch">
                <h4 className="any-all-copy"><Trans>Any</Trans></h4>
                <StandardSwitch
                  isChecked={searchFilterOperators.custom_fields?.[key.attributes.name] === SearchFilterOperator.AND || false}
                  isDisabled={loading}
                  name={`enable all (inclusive search) for ${key.attributes.name} key`}
                  onChange={(): void => handleSwitchChange(key.attributes.name)}
                />
                <h4 className="any-all-copy"><Trans>All</Trans></h4>
              </div>
            )}
            <div className="prioritized-custom-fields__row--dropdown-container">
              <MultiselectDropdown
                className="custom-field-dropdown keys"
                cfDropdown={true}
                isLoading={!prioritizedCustomFieldValueOptions?.[key.id]}
                label={key.attributes.name}
                onChange={(selectedOptions, isAdded, updatedValue): void => {
                  handleChange(selectedOptions, isAdded || false, updatedValue, key.id);
                }}
                onOpen={(): void => { fetchCustomFieldValuesAsync(key.id); }}
                options={prioritizedCustomFieldValueOptions?.[key.id]}
                overflowParentRef={advancedFiltersContainerRef}
                placeholder={t`Select ${key.attributes.name}`}
                searchable
                values={(!!selectedValues?.[key.id] && createOptions(selectedValues[key.id])) || undefined}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
