import {
  Colors,
  ComboboxPlacements,
  FontIcon,
  ListboxOption,
  Placements,
  StandardTagger,
  StandardTooltip,
  useDebounce
} from '@brandfolder/react';
import { t } from '@lingui/macro';
import { decode } from 'html-entities';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { ApiDataResponse } from '@api/v4/ApiResponseTypes';
import { UserGroupServer } from '@api/v4/user_groups';
import { User } from '@api/v4/UserTypes';
import {
  tagTypeIcon,
  userGroupUserOption,
  userOption
} from '@components/bulk_management/user-settings/invitations/form-helper-components';
import { EmailsAndUserGroups } from '@components/bulk_management/user-settings/invitations/form-type-helpers';
import { validateEmail } from '@helpers/emails';

import { getStandardTaggerLabels } from '@translations';

import '../styles/email-address.scss';

export interface EmailAddressesProps {
  setFieldValue: (field: string, value: EmailsAndUserGroups[], shouldValidate?: boolean) => void;
  label: string;
  placeholder: string;
  allowUserGroups: boolean;
  allowReusableInvite?: boolean;
  clearTags?: boolean;
  /** Used for `@testing-library/react` tests only */
  delay?: number;
  error?: string;
  helpText?: string;
}

export const EmailAddresses: FunctionComponent<EmailAddressesProps> = (props) => {
  const {
    allowUserGroups,
    allowReusableInvite = true,
    clearTags,
    delay = 400,
    error,
    helpText,
    label,
    placeholder,
    setFieldValue
  } = props;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<ListboxOption[]>([]);
  const [query, setQuery] = useState('');
  const queryDebounced = useDebounce(query, delay);

  const { error: fetchError, fetch, loading: fetchLoading, response } = useFetch<ApiDataResponse<User, 'users'>>({
    body: {
      order: 'ASC',
      per: 10,
      query: `${queryDebounced}*`,
      sort_by: 'email' // eslint-disable-line @typescript-eslint/naming-convention
    },
    fetchOnMount: false,
    method: 'POST',
    url: `/api/v4/private/${BFG.resource.type}s/${BFG.resource.key}/organization/users/search`
  });

  const userGroupsFetch = useFetch<ApiDataResponse<UserGroupServer, 'user_groups'>>({
    url: `api/v4/${BFG.resource.type}s/${BFG.resource.key}/organization/user_groups/search`,
    fetchOnMount: false,
    method: 'POST',
    body: {
      order: 'ASC',
      per: 10,
      query: queryDebounced,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      sort_by: 'name'
    }
  });

  const resetEmailAddresses = (): void => {
    setQuery('');
    setLoading(false);
  };

  useEffect(() => {
    fetch();
    if (allowUserGroups) {
      userGroupsFetch.fetch();
    }
  }, [queryDebounced]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchError || userGroupsFetch.error) {
      setLoading(false);
    }
  }, [fetchError, userGroupsFetch.error]);

  useEffect(() => {
    if (allowUserGroups && response && userGroupsFetch.response) {
      setOptions(userGroupUserOption(response, userGroupsFetch.response));
      setLoading(false);
    } else if (response) {
      setOptions(userOption(response));
      setLoading(false);
    }
  }, [response, userGroupsFetch.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clearTags) {
      resetEmailAddresses();
    }
  }, [clearTags]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StandardTagger
      className='email-address-tagger'
      clearTags={clearTags}
      comboboxProps={{
        comboboxTextareaProps: {
          autoFocus: true,
          onChange: (e): void => {
            setLoading(true);
            setQuery(e.target.value);
          },
          placeholder
        },
        placement: ComboboxPlacements.BottomStart,
        possiblePlacements: [ComboboxPlacements.BottomStart]
      }}
      error={error}
      height={142}
      helpText={helpText}
      id="email-address-tagger"
      labels={getStandardTaggerLabels({
        emptyLabel: t`No users`,
        iconButtonLabel: t`Toggle users`,
        label,
        listboxLabel: t`List of users`,
        removeTagLabel: t`Remove user`
      })}
      loading={fetchLoading || userGroupsFetch.loading || loading}
      onTagged={(tags): void => {
        tags.forEach((tag) => {
          if (tag.type === 'create') {
            tag.error = !validateEmail(tag.value.toString(), allowReusableInvite);
          }
        });
        setFieldValue('emailsAndUserGroups', tags, true);
      }}
      options={options}
      search={false}
      tagFormatter={(tag): ReactNode => {
        const tagLabel = <span>{decode(tag.label)}</span>;
        const userTag = tag.error ? tagLabel : (
          <span className='user-tag'>
            <FontIcon icon={tagTypeIcon(tag.type)} iconSize={13} />
            {tagLabel}
          </span>
        );
        if (!tag.tooltip) return userTag;
        return (
          <StandardTooltip
            color={Colors.Primary}
            id={`${tag.key}-tooltip`}
            placement={Placements.TopCenter}
            tooltip={tag.tooltip || ''}
            triggerOffset={15}
          >
            {userTag}
          </StandardTooltip>
        );
      }}
    />
  );
};
