import classnames from 'classnames';
import React, { FunctionComponent, KeyboardEvent, ReactNode, useEffect, useRef } from 'react';

import {
  AccordionButtonProps,
  AccordionHeaderProps,
  AccordionHeadingProps,
  AccordionHeadingLevels,
  AccordionButtonIconPositions,
  AccordionHeaderContentPositions
} from '@components/library/accordion';

import classes from '../styles/accordion_header.module.scss';

export interface AccordionHeaderComponentProps {
  accordionPanelId: string;
  children: ReactNode;
  disabled: boolean | undefined;
  focusIndex: number | null;
  id: string;
  index: number;
  onChange: (index: number) => void;
  onKeyUp: (e: KeyboardEvent<HTMLButtonElement>, index: number) => void;
  open: boolean | undefined;
  buttonIcon?: ReactNode;
  buttonIconPosition?: AccordionButtonIconPositions;
  buttonProps?: AccordionButtonProps;
  headerContent?: ReactNode;
  headerContentPosition?: AccordionHeaderContentPositions;
  headerProps?: AccordionHeaderProps;
  headingLevel?: AccordionHeadingLevels;
  headingProps?: AccordionHeadingProps;
  showText?: boolean;
}

/**
 * Accordion header with heading, button, and any before/after content.
 * @param {AccordionHeaderComponentProps} props AccordionHeaderComponentProps
 */
export const AccordionHeader: FunctionComponent<AccordionHeaderComponentProps> = (props) => {
  const {
    accordionPanelId,
    buttonIcon,
    buttonIconPosition = AccordionButtonIconPositions.Before,
    buttonProps,
    children,
    disabled,
    focusIndex,
    headerContent,
    headerContentPosition = AccordionHeaderContentPositions.After,
    headerProps,
    headingLevel = AccordionHeadingLevels.H3,
    headingProps,
    id,
    index,
    onChange,
    onKeyUp,
    open,
    showText = true
  } = props;

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusIndex !== null && focusIndex === index && ref?.current) {
      ref.current.focus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusIndex]);

  const HeadingTag = headingLevel;

  return (
    <div
      {...headerProps}
      className={classnames('accordion-header', classes.accordionHeader, headerProps?.className)}
    >
      {headerContent && headerContentPosition === AccordionHeaderContentPositions.Before ? headerContent : null}
      {/* Heading element MUST be included for accessibility. For why, see: */}
      {/* https://www.w3.org/TR/wai-aria-practices-1.2/#wai-aria-roles-states-and-properties */}
      <HeadingTag
        {...headingProps}
        className={classnames('accordion-heading', classes.accordionHeading, headingProps?.className)}
      >
        <button
          {...buttonProps}
          ref={ref}
          aria-controls={accordionPanelId}
          aria-disabled={disabled}
          aria-expanded={open}
          className={classnames('accordion-button', classes.accordionButton, buttonProps?.className)}
          disabled={disabled}
          id={id}
          onClick={(e): void => {
            if (buttonProps && buttonProps.onClick) {
              buttonProps.onClick(e);
            }
            onChange(index);
          }}
          onKeyUp={(e): void => {
            if (buttonProps && buttonProps.onKeyUp) {
              buttonProps.onKeyUp(e);
            }
            onKeyUp(e, index);
          }}
          type="button"
        >
          {buttonIcon && buttonIconPosition === AccordionButtonIconPositions.Before ? buttonIcon : null}
          <span
            className={classnames('accordion-button-text', classes.accordionButtonText, open ? "isOpen" : "isClosed", !showText && classes.srOnly)}
          >
            {children}
          </span>
          {buttonIcon && buttonIconPosition === AccordionButtonIconPositions.After ? buttonIcon : null}
        </button>
      </HeadingTag>
      {headerContent && headerContentPosition === AccordionHeaderContentPositions.After ? headerContent : null}
    </div>
  );
};
