import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, MutableRefObject } from 'react';

import { Meta } from '@api/v4/ApiResponseTypes';
import { TextButton } from '@components/library/button';
import { ListDropdown, ListOption } from '@components/library/dropdown';
import { PaginationCounts } from '@components/bulk_management/shared/components/PaginationTypes';

import classes from '../styles/pagination.module.scss';

interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
  currentPage: number;
  nextPage: number | null;
  onPageChange: (page: number) => void;
  prevPage: number | null;
  totalCount: number;
  totalPages: number;
  hideWhenAllShown?: boolean;
  loading?: boolean;
  overflowParentRef?: MutableRefObject<HTMLElement>;
  per?: number;
  perOptions?: ListOption[];
  onPerChange?: (perPage: PerPageEnum) => void;
}

// kaminari is one based
const defaultFirstPage = 1;

export enum PerPageEnum {
  TwentyFive = 25,
  Fifty = 50,
  OneHundred = 100
}

export const defaultPerOptions: ListOption[] = Object.values(PerPageEnum).filter((enumPart) => (
  typeof enumPart === 'number'
)).map((number) => (
  { label: number, value: number }
));

export const Pagination: FunctionComponent<PaginationProps> = (props) => {
  const {
    currentPage,
    hideWhenAllShown = false,
    loading,
    nextPage,
    onPageChange,
    onPerChange,
    overflowParentRef,
    per = PerPageEnum.TwentyFive,
    perOptions = defaultPerOptions,
    prevPage,
    totalCount,
    totalPages,
    ...otherProps
  } = props;

  const allShown = totalCount <= per;
  if (hideWhenAllShown && allShown) return null;

  return (
    <div {...otherProps} className={classnames(classes.pagination, otherProps.className)}>
      <div className={classes.buttonGroup}>
        <TextButton
          disabled={loading || currentPage === defaultFirstPage}
          onClick={(): void => onPageChange(defaultFirstPage)}
          type="button"
        >
          <Trans>First</Trans>
        </TextButton>
        <TextButton
          disabled={loading || !prevPage}
          onClick={(): void => {
            if (prevPage) {
              onPageChange(prevPage);
            }
          }}
          type="button"
        >
          <Trans>Prev</Trans>
        </TextButton>
        <TextButton
          disabled={loading || !nextPage}
          onClick={(): void => {
            if (nextPage) {
              onPageChange(nextPage);
            }
          }}
          type="button"
        >
          <Trans>Next</Trans>
        </TextButton>
        <TextButton
          disabled={loading || currentPage === totalPages}
          onClick={(): void => onPageChange(totalPages)}
          type="button"
        >
          <Trans>Last</Trans>
        </TextButton>
      </div>
      <ListDropdown
        className={classes.perPage}
        onChange={(listOption: ListOption): void => {
          if (onPerChange) {
            onPerChange(listOption.value as PerPageEnum);
          }
        }}
        openOnClick
        openOnHover={false}
        options={perOptions}
        overflowParentRef={overflowParentRef}
        placeholder={per.toString()}
        value={per.toString()}
        virtualizeOptions={false}
      />
      <p className={classes.totalCount}>
        <Trans>
          Page {currentPage}/{totalPages} <span className={classes.bullet}>&bull;</span> Total items: {totalCount}
        </Trans>
      </p>
    </div>
  );
};

export const defaultPagination: PaginationCounts = {
  currentPage: 1,
  nextPage: null,
  per: defaultPerOptions[0].value as PerPageEnum,
  prevPage: null,
  totalCount: 0,
  totalPages: 0
};

/**
 * Map JSONAPI meta pagination attributes to JS pagination
 * https://jsonapi.org/format/#document-meta
 * @param {Meta} meta Meta
 * @param {PerPageEnum} per PerPageEnum
 * @returns {Pagination} Pagination
 */
export const mapMetaToPagination = (meta?: Meta, per?: PerPageEnum, maxRecords?: number): PaginationCounts => {
  const perOrDefault = per || defaultPerOptions[0].value as PerPageEnum;
  let totalPages = meta?.total_pages || defaultPagination.totalPages;
  let totalCount = meta?.total_count || defaultPagination.totalCount;
  // accepting that this will exclude maxRecords === 0, that's a weird thing to do, don't do that
  if (maxRecords && (perOrDefault * totalPages) > maxRecords) {
    totalPages = maxRecords / perOrDefault;
    totalCount = totalPages * perOrDefault;
  }

  return ({
    currentPage: meta?.current_page || defaultPagination.currentPage,
    nextPage: meta?.next_page || defaultPagination.nextPage,
    per: perOrDefault,
    prevPage: meta?.prev_page || defaultPagination.prevPage,
    totalCount,
    totalPages
  });
};
