import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DropdownWrapper from '@components/common/dropdown_wrapper/main';
import { getCurrentUserIsGuest, getCurrentUserIsCollaborator} from '@helpers/user';

const CardActionsDropdown = ({
  cardEvent,
  handleReply,
  onDelete,
}) => {
  const { id } = cardEvent;
  const [closeDropdown, setCloseDropdown] = useState(false);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(id);
  };

  const handleReplyAction = (handlerMethod) => {
    handlerMethod();
    setCloseDropdown(true);
  };

  const isDisabled = getCurrentUserIsGuest(BFG.currentUser) || getCurrentUserIsCollaborator(BFG.currentUser)

  const dropdownContents = () => (
    <ul className="event__dropdown-actions--menu">
      <li
        className="event-action reply-button"
        onClick={() => handleReplyAction(handleReply)}
        onKeyPress={() => handleReplyAction(handleReply)}
        role="button"
        tabIndex={0}
        type="button"
      >
        <span className="bff-comment" />
        <Trans>Reply</Trans>
      </li>
      <li
        aria-disabled={isDisabled || undefined}
        className={classnames({
          'disabled': isDisabled,
          'event-action delete-button': !isDisabled
        })}
        onClick={!isDisabled ? handleDelete : undefined}
        onKeyPress={!isDisabled ? handleDelete : undefined}
        role="button"
        tabIndex={0}
        type="button"
      >
        <span className="bff-trash" />
        <Trans>Delete</Trans>
      </li>
    </ul>
  );

  return (
    <>
      <DropdownWrapper
        close={closeDropdown}
        closeCallback={() => setCloseDropdown(false)}
        cssTransitionClasses="fade-in-translate"
        dropdownContents={dropdownContents}
        openOnHover={false}
        wrapperClassnames="event__dropdown-actions"
      >
        <span className="bff-ellipsis" />
      </DropdownWrapper>
    </>
  );
};

CardActionsDropdown.propTypes = {
  cardEvent: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  handleReply: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CardActionsDropdown;
