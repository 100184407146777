import { createContext, Dispatch } from 'react';
import { ModalEditFormReducerAction, ReducerState } from './EditTabTypes';

interface AssetModalContext {
  state: ReducerState;
  dispatch: Dispatch<ModalEditFormReducerAction> | null;
}

const assetModalContext = createContext<AssetModalContext>({ state: null, dispatch: null });

export default assetModalContext;
