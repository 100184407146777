import { AssetTypesPascalCaseSingular } from '@api/v4/assets/assetTypes';
import { CustomFieldValuesListResponse, FlattenedCustomFieldKeyValuesMap } from '@api/v4/assets/customFieldTypes';
import { createCustomFieldValues } from '@api/v4/custom_fields/custom-field-keys';
import { CustomFieldKeyValuesEntry } from '@components/asset/modal/tabs/edit/EditTabTypes';

export const isMissingCustomFieldValue = (customFieldsMapEntry: CustomFieldKeyValuesEntry): boolean => (
  !customFieldsMapEntry?.customFieldValues?.length || !customFieldsMapEntry.customFieldValues[0].value
);

export const putCustomFields = (
  requiredCustomFieldKeys: string[],
  customFieldsMap: FlattenedCustomFieldKeyValuesMap,
  assetKeys: string[]
): Promise<CustomFieldValuesListResponse>[] => {
  const customFieldPromises: Promise<CustomFieldValuesListResponse>[] = []; // type
  requiredCustomFieldKeys.forEach((requiredKey) => {
    const values: { value: string; assetKey: string }[] = [];
    customFieldsMap[requiredKey]?.customFieldValues.forEach((customFieldValue) => {
      assetKeys.forEach((assetKey) => {
        values.push({ value: customFieldValue.value, assetKey });
      });
    });
    customFieldPromises.push(createCustomFieldValues(values, requiredKey));
  });
  return customFieldPromises;
};

/**
 * Get whether there are any required custom field keys for an asset that's a GenericFile
 * @param requiredCustomFieldKeys CustomFieldKeysResponseObject[] | null
 * @param assetType string
 * @returns boolean
 */
export const getHasRequiredCustomFields = (
  customFieldMap: FlattenedCustomFieldKeyValuesMap | null,
  dependentFieldIds: string[],
  assetType: string
): boolean => {
  const customFieldKeys = customFieldMap ? Object.keys(customFieldMap) : null;
  const requiredParentFields = customFieldKeys?.filter((key) => (
    customFieldMap[key]?.customFieldKey?.required &&
    !dependentFieldIds?.includes(key)
  ));

  return requiredParentFields?.length > 0
    && assetType === AssetTypesPascalCaseSingular.GenericFile;
};
