import React, { FunctionComponent } from "react";
import { PortalCard } from "@api/v4/private/PortalCardTypes";

interface TileNavigationCardProps {
  card: PortalCard;
  placeholderImage: string;
}

export const TileNavigationCard: FunctionComponent<TileNavigationCardProps> = ({ card, placeholderImage }) => {
  const { description, displayWidth, imageUrl, linkUrl, name } = card;
  // TODO: ideally uses FilestackPreviewImage in place of background image - requires CSS help
  return (
    <>
      <a href={linkUrl || undefined}>
        <div className={`tiles__item--image-container ${displayWidth}-image`}>
          <div
            aria-label={`${!imageUrl ? "placeholder" : "thumbnail"}`}
            className="tiles__item--image"
            data-private
            role="img"
            style={{ backgroundImage: `url("${imageUrl || placeholderImage}")` }}
          />
        </div>
      </a>
      <div className="tiles__item--description">
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
    </>
  );
};
