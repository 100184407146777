import { Trans } from '@lingui/macro';
import React, { useState } from 'react';

import { PrimaryButton } from '@components/library/button/index';
import { isGettyClient } from '@helpers/getty-strings';

import WidgetForm from './widget_form';

interface WidgetEmptyTemplateProps {
  brandHexColor: string | undefined;
  triggerCardsRefresh: () => void;
}

const WidgetEmptyTemplate: React.FunctionComponent<WidgetEmptyTemplateProps> = ({
  brandHexColor,
  triggerCardsRefresh
}) => {
  const [showForm, setShowForm] = useState(false);
  const toggleForm = (): void => setShowForm((prevState) => !prevState);

  return (
    <section className="widgets" style={{ backgroundColor: brandHexColor ? `#${brandHexColor}` : undefined }}>
      <div className="widgets-container">
        <div className="widgets-third widget-create-card template">
          <span className="bff-face-sad" />
          <h3><Trans>You don't have any widgets yet!</Trans></h3>
          <p>
            {isGettyClient()
              ? <Trans>Use a widget feed to give your team insight into realtime changes and updates within your Libraries.</Trans>
              : <Trans>Use a widget feed to give your team insight into realtime changes and updates within your Brandfolders.</Trans>
            }
          </p>
          <p>
            <Trans>
              Customizable options for your widget feeds include recently created assets, assets that are expiring soon, your most popular assets, etc.
            </Trans>
          </p>
          <PrimaryButton
            className="widget-create-button"
            icon="bff-plus"
            onClick={toggleForm}
          >
            <Trans>Create Widget</Trans>
          </PrimaryButton>
        </div>
        <div className="widgets-third widget-placeholder-card template">
          <div aria-label="thumbnail placeholder" className="widget-placeholder-image" role="img" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y18n-8wjz1k-fi9mwk/image-placeholder.auto")' }} />
          <div aria-label="text placeholder" className="widget-placeholder-text" role="img" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y1al-7x2y1s-dl1522/text-placeholder.auto")' }} />
          <div aria-label="thumbnail placeholder" className="widget-placeholder-image" role="img" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y18n-8wjz1k-fi9mwk/image-placeholder.auto")' }} />
          <div aria-label="text placeholder" className="widget-placeholder-text" role="img" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y1al-7x2y1s-dl1522/text-placeholder.auto")' }} />
        </div>
        <div className="widgets-third widget-placeholder-card template">
          <div aria-label="thumbnail placeholder" className="widget-placeholder-image" role="img" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y18n-8wjz1k-fi9mwk/image-placeholder.auto")' }} />
          <div aria-label="text placeholder" className="widget-placeholder-text" role="img" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y1al-7x2y1s-dl1522/text-placeholder.auto")' }} />
          <div aria-label="thumbnail placeholder" className="widget-placeholder-image" role="img" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y18n-8wjz1k-fi9mwk/image-placeholder.auto")' }} />
          <div aria-label="text placeholder" className="widget-placeholder-text" role="img" style={{ backgroundImage: 'url("https://cdn.brandfolder.io/27C9EC93/at/q6y1al-7x2y1s-dl1522/text-placeholder.auto")' }} />
        </div>
        {showForm && (
          <WidgetForm
            closeModal={toggleForm}
            triggerCardsRefresh={triggerCardsRefresh}
          />
        )}
      </div>
    </section>
  );
};

export default WidgetEmptyTemplate;
