/* eslint-disable react/require-default-props */
import { localizeFileSize } from '@brandfolder/utilities';
import { Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";

import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';

import "./styles/main.scss";

const VisibleAttachment = ({ extension, filename, height, size, thumbnail, width }) => (
  <div className="visible-attachment">
    <div className="half-container">
      <div className="attachment-thumbnail">
        <img
          data-private
          src={thumbnail}
        />
      </div>
      <div className="attachment-name">
        <label className="modal-label">
          {' '}
          <Trans>Name</Trans>
          {' '}
        </label>
        <p>{filename || "NA"}</p>
      </div>
    </div>
    <div className="half-container">
      <div className="attachment-filetype">
        <label className="modal-label">
          {' '}
          <Trans>File Type</Trans>
          {' '}
        </label>
        <p>{extension || "NA"}</p>
      </div>
      <div className="attachment-size">
        <label className="modal-label">
          {' '}
          <Trans>Size</Trans>
          {' '}
        </label>
        <p>
          {size ? (
            <span>
              {' '}
              {localizeFileSize({ locale: determineUGTLocale(), size })}
              {' '}
            </span>
          ) : "NA"}

        </p>
      </div>
      <div className="attachment-dimensions">
        <label className="modal-label">
          {' '}
          <Trans>Dimensions</Trans>
          {' '}
        </label>
        <p>
          {width && height ? (
            <span>
              {width}
              {' '}
              x
              {' '}
              {height}
            </span>
          ) : "NA"}

        </p>
      </div>
    </div>
  </div>
);

VisibleAttachment.propTypes = {
  extension: PropTypes.string,
  filename: PropTypes.string,
  height: PropTypes.number,
  size: PropTypes.number,
  thumbnail: PropTypes.string,
  width: PropTypes.number
};

VisibleAttachment.defaultProps = {
  extension: '',
  filename: '',
  height: null,
  size: null,
  thumbnail: null,
  width: null,
};

export default VisibleAttachment;
