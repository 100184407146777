import React, { FunctionComponent, useEffect } from 'react';

import { useFetch } from '@api/ApiHelper';
import { InsightsEventActions, InsightsEventTypes } from '@api/insights/v1/events';
import { ShareManifestServer } from '@api/v4/share_manifests/ShareManifestTypes';
import { userCreatedShareLink } from '@helpers/share-links';
import { mapShareManifestServerToClient } from '../../helpers';

interface ViewProps {
  isPreview: boolean;
  manifest: ShareManifestServer;
}

export const View: FunctionComponent<ViewProps> = (props) => {
  const { isPreview, manifest } = props;

  const shareManifest = mapShareManifestServerToClient(manifest);
  const { key, userKey } = shareManifest;

  const postViewedEvent = useFetch<null>({
    body: {
      data: {
        attributes: {
          data: {
            action_name: InsightsEventActions.VIEWED,
            resource_key: key,
            resource_type: InsightsEventTypes.SHARE_MANIFEST,
            source: {
              resource_key: BFG.resource.key,
              resource_type: BFG.resource.type,
              share_manifest_key: key
            }
          }
        }
      }
    },
    fetchOnMount: false,
    method: 'POST',
    url: `${BFG.INSIGHTS_API_URL}/api/v1/events`
  });

  useEffect(() => {
    // only create insights viewed events for non-creators
    if (!isPreview && !userCreatedShareLink(BFG.currentUser?.user_id, userKey) && !BFG.currentUser?.su) {
      postViewedEvent.fetch();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};
