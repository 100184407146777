import React, { FunctionComponent } from 'react';

import { BaseProgressProps } from '@components/library/progress';
import { BaseProgress } from '../base';

import './styles/standard-progress.module.scss';

type StandardProgressProps = BaseProgressProps;

export const StandardProgress: FunctionComponent<StandardProgressProps> = (props) => (
  <BaseProgress {...props} />
);
