import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import { sortCheckboxes } from '@components/library/checkbox';
import { StandardCheckboxGroup } from '@components/library/checkbox_group';
import { InputError } from '@components/library/input_container';

import { PressReleaseCollections } from './PressReleaseCollections';
import {
  PressReleasePermissionLevels,
  PressReleaseUpsert,
  PressReleaseValidationErrors,
  UserPermissionLevels
} from './PressReleaseTypes';

import classes from './styles/press_release_form_step2.module.scss';
import { isGettyClient } from '@helpers/getty-strings';

interface PressReleaseFormStep2Props {
  pressRelease: PressReleaseUpsert;
  setPressRelease: SetStateDispatch<PressReleaseUpsert>;
  validationErrors: PressReleaseValidationErrors;
}

/**
 * Step 2: Recipients (user permissions levels and selected collections and subcollections)
 * @param {PressReleaseFormStep2Props} props PressReleaseFormStep2Props
 */
export const PressReleaseFormStep2: FunctionComponent<PressReleaseFormStep2Props> = (props) => {
  const { pressRelease, setPressRelease, validationErrors } = props;

  const initializeUserPermissionLevelsCheckboxes = (): PressReleasePermissionLevels[] => {
    const initialCheckboxes: PressReleasePermissionLevels[] = [{
      checked: false,
      id: 'admin',
      labelCopy: t`Admins`
    },
    {
      checked: false,
      id: 'collaborator',
      labelCopy: t`Collaborators`
    },
    {
      checked: false,
      id: 'guest',
      labelCopy: t`Guests`
    }];

    // loop through the initial user permission levels checkboxes and check any that have already been selected
    // on the email (used when editing a email)
    // eslint-disable-next-line array-callback-return
    initialCheckboxes.map((initialCheckbox) => {
      const level = pressRelease.permission_levels.find((permissionLevel) => permissionLevel === initialCheckbox.id);
      if (level) {
        // eslint-disable-next-line no-param-reassign
        initialCheckbox.checked = true;
      }
    });

    return sortCheckboxes(initialCheckboxes, 'labelCopy');
  };

  const [checkboxes, setCheckboxes] = useState(initializeUserPermissionLevelsCheckboxes());

  const handleUserPermissionLevels = (id: UserPermissionLevels, e: InputChangeEvent): void => {
    // handle local checkboxes state
    const toggledCheckbox = checkboxes.find((checkbox) => checkbox.id === id);
    if (toggledCheckbox) {
      const otherCheckboxes = checkboxes.filter((checkbox) => checkbox.id !== id);
      setCheckboxes(sortCheckboxes<PressReleasePermissionLevels>([...otherCheckboxes, { ...toggledCheckbox, checked: e.target.checked }], 'labelCopy'));
    }

    // handle email permission levels array
    if (e.target.checked) {
      // add the permission level (assumes there's no way to add this twice in the UI)
      setPressRelease({ ...pressRelease, permission_levels: [...pressRelease.permission_levels, id] });
    } else {
      // filter out the unchecked permission level
      const permissionLevels = pressRelease.permission_levels.filter((permissionLevel) => permissionLevel !== id);
      setPressRelease({ ...pressRelease, permission_levels: permissionLevels });
    }
  };

  const collectionsHeading = isGettyClient()
    ? <Trans>Send to Recipients belonging to the following Library, Collection, or Subcollections</Trans>
    : <Trans>Send to Recipients belonging to the following Brandfolder, Collection, or Subcollections</Trans>;

  return (
    <>
      <InputError
        className={classes.inputError}
        error={validationErrors.recipients}
        submitAttempted={validationErrors.submitAttempted}
      />
      <section>
        <h3 className={classes.h3}><Trans>Send to Recipients by User Permission Level</Trans></h3>
        <StandardCheckboxGroup
          checkboxes={checkboxes}
          className={classes.checkboxes}
          name="user-permission-levels"
          onChange={(id, e): void => handleUserPermissionLevels(id as UserPermissionLevels, e)}
        />
      </section>
      <section>
        <h3 className={classes.h3}>{collectionsHeading}</h3>
        <PressReleaseCollections
          pressRelease={pressRelease}
          setPressRelease={setPressRelease}
        />
      </section>
    </>
  );
};
