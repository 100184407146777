import { Portal, ValidationErrors } from '@api/v4/private/PortalTypes';

const validSlug = (slug: string): boolean => (
  /^[a-z0-9_-]+$/i.test(slug)
);

const validUrl = (url: string): boolean => (
  /^https?/i.test(url)
);

const validHexColor = (hexColor: string): boolean => (
  /^#?([0-9A-F]{3}){1,2}$/i.test(hexColor)
);

export const maxDescriptionLength = 10000;

const validateName = ({ name }: Portal): ValidationErrors[] => {
  if (!name) {
    return [ValidationErrors.MissingName];
  }
  if (name.length > 50) {
    return [ValidationErrors.NameLengthExceeded];
  }
  return [];
};

const validateDescription = ({ description }: Portal): ValidationErrors[] => {
  if (description && description.length > maxDescriptionLength) {
    return [ValidationErrors.DescriptionLengthExceeded];
  }
  return [];
};

const validateSlug = ({ slug }: Portal): ValidationErrors[] => {
  if (!slug) {
    return [ValidationErrors.MissingSlug];
  }

  if (slug && (!validSlug(slug) || slug.length < 2 || slug.length > 50)) {
    return [ValidationErrors.InvalidSlug];
  }

  return [];
};

const validatePrivacy = ({ privacy }: Portal): ValidationErrors[] => {
  if (!privacy) {
    return [ValidationErrors.MissingPrivacy];
  }
  return [];
};

const validateBrandHexColor = ({ brandHexColor }: Portal): ValidationErrors[] => {
  if (brandHexColor && !validHexColor(brandHexColor)) {
    return [ValidationErrors.InvalidBrandHexColor];
  }

  return [];
};

const validatedHeaderHexColor = ({ headerHexColor }: Portal): ValidationErrors[] => {
  if (headerHexColor && !validHexColor(headerHexColor)) {
    return [ValidationErrors.InvalidHeaderHexColor];
  }

  return [];
};

const validateSearchUrl = ({ searchUrl }: Portal): ValidationErrors[] => {
  if (searchUrl && !validUrl(searchUrl)) {
    return [ValidationErrors.InvalidSearchUrl];
  }

  return [];
};

export const validate = (portal: Portal, stage: number | null): ValidationErrors[] => {
  switch (stage) {
    case 1:
      return [
        ...validateName(portal),
        ...validateSlug(portal),
        ...validateDescription(portal),
      ];
    case 2:
      return [
        ...validatePrivacy(portal)
      ];
    case 3:
      return [
        ...validateBrandHexColor(portal),
        ...validatedHeaderHexColor(portal),
        ...validateSearchUrl(portal),
      ];
    default:
      return [
        ...validateName(portal),
        ...validateSlug(portal),
        ...validateDescription(portal),
        ...validateBrandHexColor(portal),
        ...validatedHeaderHexColor(portal),
        ...validateSearchUrl(portal),
      ];
  }
};

export const invalidAttributes = (
  portal: Portal,
  setValidationErrors: (errors: ValidationErrors[]) => void,
  stage: number | null,
): boolean => {
  const submissionErrors = validate(portal, stage);
  if (submissionErrors.length > 0) {
    setValidationErrors(submissionErrors);
    return true;
  }
  return false;
};

export const nameError = (validationErrors: ValidationErrors[]): string | null => {
  if (validationErrors.includes(ValidationErrors.MissingName)) {
    return ValidationErrors.MissingName;
  }
  if (validationErrors.includes(ValidationErrors.NameLengthExceeded)) {
    return ValidationErrors.NameLengthExceeded;
  }
  return null;
};

export const descriptionError = (validationErrors: ValidationErrors[]): string | null => {
  if (validationErrors.includes(ValidationErrors.DescriptionLengthExceeded)) {
    return ValidationErrors.DescriptionLengthExceeded;
  }
  return null;
};

export const slugError = (validationErrors: ValidationErrors[]): string | null => {
  if (validationErrors.includes(ValidationErrors.MissingSlug)) {
    return ValidationErrors.MissingSlug;
  }

  if (validationErrors.includes(ValidationErrors.InvalidSlug)) {
    return ValidationErrors.InvalidSlug;
  }

  return null;
};

export const brandHexColorError = (validationErrors: ValidationErrors[]): string | null => {
  if (validationErrors.includes(ValidationErrors.InvalidBrandHexColor)) {
    return ValidationErrors.InvalidBrandHexColor;
  }
  return null;
};

export const headerHexColorError = (validationErrors: ValidationErrors[]): string | null => {
  if (validationErrors.includes(ValidationErrors.InvalidHeaderHexColor)) {
    return ValidationErrors.InvalidHeaderHexColor;
  }
  return null;
};

export const searchUrlError = (validationErrors: ValidationErrors[]): string | null => {
  if (validationErrors.includes(ValidationErrors.InvalidSearchUrl)) {
    return ValidationErrors.InvalidSearchUrl;
  }
  return null;
};
