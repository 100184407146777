import { ComboboxPlacements, ListboxOption, StandardCombobox } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { ReactElement } from 'react';

interface ShareLinksSortComboboxProps {
  selectSort: (sortBy: string) => void;
}

export const ShareLinksSortCombobox = ({ selectSort }: ShareLinksSortComboboxProps):ReactElement => {

  return (
    <StandardCombobox
      className='sort-by-combobox'
      id='sort-share-links'
      initialSelectedIndex={4}
      labels={{
        emptyLabel: t`No sorting options available`,
        iconButtonLabel: t`Select share links sort`,
        label: t`Sort by`,
        listboxLabel: t`List of states and territories`,
        loaderLabel: t`Loading`,
        requiredLabel: t`Required`
      }}
      onSelection={(option: ListboxOption): void => selectSort(option.value as string)}
      options={[
        {
          children: <><Trans>Name (A-Z)</Trans></>,
          key: 0,
          value: 'name asc'
        },
        {
          children: <><Trans>Name (Z-A)</Trans></>,
          key: 1,
          value: 'name desc'
        },
        {
          children: <><Trans>Created by (A-Z)</Trans></>,
          key: 2,
          value: 'created_by asc'
        },
        {
          children: <><Trans>Created by (Z-A)</Trans></>,
          key: 3,
          value: 'created_by desc'
        },
        {
          children: <><Trans>Creation Date (Newest)</Trans></>,
          key: 4,
          value: 'created_at desc'
        },
        {
          children: <><Trans>Creation Date (Oldest)</Trans></>,
          key: 5,
          value: 'created_at asc'
        },
      ]}
      placement={ComboboxPlacements.BottomStart}
      showPlaceholderOption={false}
    />
  );
};
