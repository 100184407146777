import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { updateBrandfolderSettings } from '@api/v4/brandfolders';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import Tooltip from '@components/common/tooltip/main';
import { ListDropdown } from '@components/library/dropdown';
import { resolveDefaultSortOrder, sortOptions } from '@components/show_page/sections/view_options/helper';

export default class DefaultSortOption extends React.Component {
  state = {
    selectedSortName: resolveDefaultSortOrder(this.props.sortName)
  };

  updateSortOrder(order) {
    this.setState({ selectedSortName: order });
    updateBrandfolderSettings({
      brandfolderKey: this.props.brandfolderKey,
      defaultOrderMethod: order,
    })
      .then(() => {
        if (BFG.brandfolderSettings.default_order_method) { // not present on org page
          BFG.brandfolderSettings.default_order_method = order;
        }
        Notify.create({
          title: t`The default sort order has been updated for all users for this Brandfolder.`,
          type: "success"
        });
      })
      .catch(() => {
        this.setState({ selectedSortName: this.props.sortName });
        Notify.create({
          title: t`Error updating default sort order. Please contact support.`,
          type: "error"
        });
      });
  }

  render() {
    return (
      <I18nProviderWrapper>
        <div className="default-sort-container">
          <div className="advanced-settings-row">
            <div className="quarter-column default-sort-title">
              <h4><Trans>Default Sort Option</Trans></h4>
              <Tooltip
                tooltipContent={t`Set the default sort option for all users that visit this Brandfolder or any of its Collections.`}
                tooltipId="default-sort-info"
              >
                <span className="bff-tool-tip" />
              </Tooltip>
            </div>
            <div className="three-quarter-column">
              <ListDropdown
                onChange={(option) => this.updateSortOrder(option.value)}
                options={sortOptions()}
                value={this.state.selectedSortName}
              />
            </div>
          </div>
        </div>
      </I18nProviderWrapper>
    );
  }
}

DefaultSortOption.propTypes = {
  brandfolderKey: PropTypes.string.isRequired,
  sortName: PropTypes.string.isRequired,
};
