import { keyPressHelper } from '@brandfolder/utilities';
import { t } from '@lingui/macro';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { withBreakpoints } from 'react-breakpoints';

import fetchOrgs from '@api/v4/organizations';
import SearchDropdown from '@components/common/search_dropdown';
import DropdownToggleButton from '@components/common/search_dropdown/dropdown_toggle_button';
import { isGettyClient } from '@helpers/getty-strings';
import { filterMatch } from '@helpers/search_filters';
import { sortStrings } from '@helpers/sort_helpers';

const searchPlaceholder = () => isGettyClient() ? t`Search Libraries` : t`Search Brandfolders`;
const title = () => (
  isGettyClient() ? t`My Libraries` : t`My Brandfolders`
);
const showAllText = () => (
  isGettyClient() ? t`View All Libraries` : t`View All Brandfolders`
);

// flatten out the nested org -> folder object
const collateOrgsAndFolders = (organizations) => organizations.sort(sortStrings).map((org) => {
  const { cname } = org;

  const brandfolders = org.brandfolders.map((brandfolder) => (
    Object.assign({},
      cname === null ? null : { cname },
      brandfolder,
    )
  ))

  const portals = org.portals.map((portal) => (
    Object.assign({},
      cname === null ? null : { cname },
      portal,
    )
  ))

  return {
    ...org,
    brandfolders: brandfolders.sort(sortStrings),
    brandguides: org.brandguides.sort(sortStrings),
    portals: portals.sort(sortStrings),
  }
});

const searchOrgsAndFolders = (organizations, filter) => organizations.reduce((acc, org) => {
  if (filter === '') {
    return acc.concat([org, ...org.brandfolders, ...org.brandguides, ...org.portals]);
  }
  const folders = [[], org.brandfolders, org.brandguides, org.portals].reduce((prev, subType) => ([
    ...prev,
    ...subType.filter((i) => filterMatch(i.name, filter))
  ]));
  if (!folders.length) {
    // We want to still include the org name in the search results,
    // even if it doesn't have any brandfolders that match!
    if (filterMatch(org.name, filter)) {
      return acc.concat([org]);
    }
    return acc;
  }
  return acc.concat([org, ...folders]);
}, []);

const NavigationDropdownWrapper = ({
  currentBreakpoint,
  libraryName,
  rootDomain,
}) => {
  const mounted = useRef(false);

  const [organizations, setOrganizations] = useState([]);
  const [menuVisible, setMenuVisibility] = useState(false);
  const whitelabelingLibraryName = pluralize(libraryName);

  useEffect(() => {
    mounted.current = true;

    fetchOrgs().then((orgs) => {
      if (mounted.current) {
        setOrganizations(collateOrgsAndFolders(orgs))
      }
    });

    return () => {
      mounted.current = false;
    }
  // can we rely on rails views to update this component? Is there another prop we can rely on?
  }, []);

  const isMobile = currentBreakpoint === 'mobile';

  return (
    <>
      { isMobile ? (
        <>
          <div
            className="navigation-dropdown-wrapper"
            data-testid="navigation-dropdown-wrapper"
            onClick={() => setMenuVisibility(!menuVisible)}
            onKeyPress={keyPressHelper(() => setMenuVisibility(!menuVisible))}
            role="button"
            tabIndex="0"
          >
            <DropdownToggleButton title={title()} />
          </div>
          {
            menuVisible
            && (
              <SearchDropdown
                maxResults={9999999}
                rootDomain={rootDomain}
                searchFunc={searchOrgsAndFolders}
                searchIndex={organizations}
                searchPlaceholder={searchPlaceholder()}
                showAllText={showAllText()}
              />
            )
          }
        </>
      ) : (
        <div
          className="navigation-dropdown-wrapper"
          data-testid="navigation-dropdown-wrapper"
          onMouseEnter={() => setMenuVisibility(true)}
          onMouseLeave={() => setMenuVisibility(false)}
        >
          <a href="/organizations">
            <DropdownToggleButton
              ariaLabel={isGettyClient() ? t`Searchable list of your Libraries` : t`Searchable list of your Brandfolders`}
              onToggle={() => setMenuVisibility((prev) => !prev)}
              title={title()}
            />
          </a>
          {
            menuVisible
            && (
              <SearchDropdown
                maxResults={9999999}
                rootDomain={rootDomain}
                searchFunc={searchOrgsAndFolders}
                searchIndex={organizations}
                searchPlaceholder={searchPlaceholder()}
                showAllText={showAllText()}
              />
            )
          }
        </div>
      )}
    </>
  );
};

NavigationDropdownWrapper.defaultProps = {
  currentBreakpoint: 'desktop',
  libraryName: "Brandfolder",
};

NavigationDropdownWrapper.propTypes = {
  currentBreakpoint: PropTypes.string,
  libraryName: PropTypes.string,
  rootDomain: PropTypes.string.isRequired,
};

export default withBreakpoints(NavigationDropdownWrapper);
