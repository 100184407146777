export enum AccordionHeadingLevels {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6'
}

export enum AccordionHeaderContentPositions {
  After = 'after',
  Before = 'before'
}

export enum AccordionButtonIconPositions {
  After = 'after',
  Before = 'before'
}

export enum AccordionAnimations {
  FadeIn = 'fadeIn',
  FadeInFromTop = 'fadeInFromTop'
}
