import React, { FunctionComponent, ReactNode } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import { TextSanitizationTooltip } from './TextSanitizationTooltip';

interface TextSanitizationTooltipLabelProps {
  children: ReactNode;
  margin?: boolean;
}

export const TextSanitizationTooltipLabel: FunctionComponent<TextSanitizationTooltipLabelProps> = ({
  children, margin = false
}) => {
  return (
    <I18nProviderWrapper>
      <div className="text-sanitization-tooltip">
        {children}
        <TextSanitizationTooltip margin={margin} />
      </div>
    </I18nProviderWrapper>
  );
};
