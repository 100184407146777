import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, FunctionComponent, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useFetch } from '@api/ApiHelper';
import { ApiDataResponse, ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { CustomFieldKeyServer } from '@api/v4/resources/CustomFieldKeysTypes';
import { get as getLabels } from '@api/v4/resources/labels';
import {
  EditOrCreate,
  Fields,
  NameDisplayTypes,
  SelectorTypes
} from '@components/bulk_management/automation/AutomationEnums';
import {
  Action,
  ActionSelectorTypes,
  CollectionsServer,
  DisplayMapEntry,
  FormState,
  KeyLabelPair,
  LabelsServer,
  SectionsServer,
  TagsServerResponse,
  TriggerSelectorTypes,
  WatermarkActionOptions
} from '@components/bulk_management/automation/AutomationTypes';
import { FieldDetailsSelector } from '@components/bulk_management/automation/form/FieldDetailsSelector';
import { FieldSummaryIcon } from '@components/bulk_management/automation/form/FieldSummaryIcon';
import {
  automationTooltips,
  fetchCollectionsOptions,
  fetchCustomFieldKeysOptions,
  fetchSectionsOptions,
  fetchTagsOptions,
  generateCollectionKeys,
  getFieldDetails,
  makeCollectionOptions,
  makeLabelsOptions,
  makeOptions,
  selectorTypeOptions,
} from '@components/bulk_management/automation/helpers';
import { summarizeTriggerType } from '@components/bulk_management/automation/summary-helpers';
import { getFormStateError, getFormStateTouched } from '@components/bulk_management/automation/validation';

import { usePrevious } from '@components/common/custom_hooks/usePrevious';
import { SecondaryButton, TextButton, TextWarningButton } from '@components/library/button';
import { ListDropdown, ListOption, ListOptionValueNoNull } from '@components/library/dropdown';
import { FontIcons } from '@components/library/icon';
import { MoreInfoTooltip } from '@components/library/tooltip';

interface FieldSelectorProps {
  closeField: SetStateDispatch<boolean>;
  errors: FormikErrors<FormState>;
  isOpen: boolean;
  selectorValues: Partial<FormState> & Partial<Action>;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setTouched: (touched: FormikTouched<FormState>, shouldValidate?: boolean) => void;
  touched: FormikTouched<FormState>;
  validateForm: (values?: any) => Promise<FormikErrors<FormState>>;
  actionIndex?: number;
  actions?: Action[];
  editOrCreate?: EditOrCreate;
  isOneAction?: boolean;
  isSectionDisabled?: boolean;
  isTriggerSet?: boolean;
  remove?: (index: number) => void;
  setIsTriggerSet?: SetStateDispatch<true>;
}

export const FieldSelector: FunctionComponent<FieldSelectorProps> = (props) => {
  const {
    actionIndex,
    actions,
    closeField,
    editOrCreate,
    errors,
    isOpen,
    isOneAction,
    isSectionDisabled,
    isTriggerSet,
    remove,
    selectorValues,
    setFieldTouched,
    setFieldValue,
    setIsTriggerSet,
    setTouched,
    touched,
    validateForm
  } = props;

  const { actionableId, triggerType } = selectorValues;

  const { fieldKeys, fieldType, values } = getFieldDetails(selectorValues);
  const isCustomFields = fieldType === SelectorTypes.CustomField;
  const isWatermark = fieldType === SelectorTypes.Watermark;

  const prevFieldType = usePrevious(fieldType);

  const [labels, setLabels] = useState<ApiResponseObject<LabelsServer, 'labels'>[]>([]);
  const [labelsLoading, setLabelsLoading] = useState(false);

  const collectionsResponse = useFetch<ApiDataResponse<CollectionsServer, 'collections'>>(fetchCollectionsOptions);
  const customFieldKeysResponse = useFetch<ApiDataResponse<CustomFieldKeyServer, 'custom_field_keys'>>(
    fetchCustomFieldKeysOptions
  );
  const sectionsResponse = useFetch<ApiDataResponse<SectionsServer, 'sections'>>(fetchSectionsOptions);
  const tagsResponse = useFetch<TagsServerResponse>(fetchTagsOptions);

  const collections = collectionsResponse.response?.data || [];
  const collectionOptions = makeCollectionOptions(collections, fieldKeys);

  const labelOptions = makeLabelsOptions(labels);

  // TODO: remove options if already used
  const customFieldKeyOptions = makeOptions<CustomFieldKeyServer, 'custom_field_keys'>(
    customFieldKeysResponse.response?.data
  );
  const sectionOptions = makeOptions<SectionsServer, 'sections'>(sectionsResponse.response?.data);
  const tagOptions: ListOption[] = tagsResponse.response?.data?.tag_names?.map((tag) => ({ label: tag, value: tag }));

  const displayMap: Record<SelectorTypes, DisplayMapEntry> = {
    [SelectorTypes.Collection]: {
      labelContent: t`Collection`,
      loading: collectionsResponse.loading,
      nameDisplayType: NameDisplayTypes.MultiselectDropdown,
      nameOptions: collectionOptions,
      placeholderCopy: t`Select Collections`,
    },
    [SelectorTypes.CustomField]: {
      labelContent: t`Custom Field Key`,
      loading: customFieldKeysResponse.loading,
      nameDisplayType: NameDisplayTypes.ListDropdown,
      nameOptions: customFieldKeyOptions,
      placeholderCopy: t`Select Custom Field key`,
    },
    [SelectorTypes.Label]: {
      labelContent: t`Labels`,
      loading: labelsLoading,
      nameDisplayType: NameDisplayTypes.MultiselectDropdown,
      nameOptions: labelOptions,
      placeholderCopy: t`Select Labels`,
    },
    [SelectorTypes.Section]: {
      labelContent: t`Section`,
      loading: sectionsResponse.loading,
      nameDisplayType: actionableId ? NameDisplayTypes.ListDropdown : NameDisplayTypes.MultiselectDropdown,
      nameOptions: sectionOptions,
      placeholderCopy: t`Select Section`,
    },
    [SelectorTypes.Tag]: {
      labelContent: t`Tags`,
      loading: tagsResponse.loading,
      nameDisplayType: NameDisplayTypes.PillSelector,
      nameOptions: tagOptions,
      placeholderCopy: t`Add Tags`,
    },
    // [SelectorTypes.NameContains]: {
    //   labelContent: t`Name Contains`,
    //   loading: null,
    //   nameDisplayType: NameDisplayTypes.Textfield,
    //   nameOptions: null,
    //   placeholderCopy: t`Name Contains`,
    // },
    [SelectorTypes.Watermark]: {
      labelContent: t`Watermark`,
      loading: null,
      nameDisplayType: NameDisplayTypes.Watermark,
      nameOptions: null
    }
  };

  const updateTriggerType = (updatedTriggerType: TriggerSelectorTypes): void => {
    setFieldValue(Fields.TriggerType, updatedTriggerType);
    setFieldTouched(Fields.TriggerType, true);
  };

  const updateActionType = (actionType: ActionSelectorTypes): void => {
    const selectedAction = { ...actions[actionIndex] };
    const updatedActions = [...actions];
    selectedAction.actionableType = actionType;
    updatedActions.splice(actionIndex, 1, selectedAction);
    setFieldValue(Fields.Actions, [...updatedActions]);
    setFieldTouched(Fields.ActionableType, true);
  };

  const updateSelectedKeys = (keys: KeyLabelPair[] | null): void => {
    if (actionableId) {
      const selectedAction = { ...actions[actionIndex] };
      const updatedActions = [...actions];
      selectedAction.actionableKeys = keys ? [...keys] : null;
      updatedActions.splice(actionIndex, 1, selectedAction);
      setFieldValue(Fields.Actions, [...updatedActions]);
    } else {
      setFieldValue(Fields.TriggerKeys, keys);
    }
  };

  const updateCollectionKeys = (listOptionValues: ListOptionValueNoNull[]): void => {
    updateSelectedKeys(generateCollectionKeys(listOptionValues, collections, collectionOptions, fieldKeys));
  };

  const updateCustomFieldKey = (customFieldKey: KeyLabelPair | null, customFieldIndex?: number): void => {
    if (actionableId) {
      const selectedAction = { ...actions[actionIndex] };
      const updatedActions = [...actions];
      const actionableKeys = [...(selectedAction.actionableKeys || [])];
      if (customFieldKey === null && (customFieldIndex !== undefined && customFieldIndex !== null)) {
        actionableKeys.splice(customFieldIndex, 1);
        selectedAction.actionableKeys = actionableKeys;
      } else {
        actionableKeys.splice(customFieldIndex ?? actionableKeys.length, 1, customFieldKey);
        selectedAction.actionableKeys = actionableKeys;
        selectedAction.actionableValues = { ...selectedAction.actionableValues, [customFieldKey.key]: [] };
      }
      updatedActions.splice(actionIndex, 1, selectedAction);
      setFieldValue(Fields.Actions, [...updatedActions]);
    } else {
      // can only trigger off a single custom field key so index is irrelevant
      setFieldValue(Fields.TriggerKeys, customFieldKey === null ? null : [customFieldKey]);
    }
  };

  const updateCustomFieldValues = (customFieldKey: string, updatedCustomFieldValues: string[]): void => {
    if (actionableId) {
      const selectedAction = { ...actions[actionIndex] };
      const updatedActions = [...actions];
      const actionableValues = { ...selectedAction.actionableValues };
      actionableValues[customFieldKey] = updatedCustomFieldValues;
      selectedAction.actionableValues = actionableValues;
      updatedActions.splice(actionIndex, 1, selectedAction);
      setFieldValue(Fields.Actions, [...updatedActions]);
    } else {
      setFieldValue(Fields.Values, { [customFieldKey]: updatedCustomFieldValues });
    }
  };

  const updateWatermark = (watermark: WatermarkActionOptions | null): void => {
    const selectedAction = { ...actions[actionIndex] };
    const updatedActions = [...actions];
    selectedAction.actionableOptions = watermark;
    updatedActions.splice(actionIndex, 1, selectedAction);
    setFieldValue(Fields.Actions, [...updatedActions]);
  };

  const cleanUpCustomFieldData = (): void => {
    if (actionableId) {
      const selectedAction = { ...actions[actionIndex] };
      const updatedActions = [...actions];
      const actionableKeys = [...(selectedAction.actionableKeys || [])];
      selectedAction.actionableKeys = actionableKeys.filter((actionableKey) => !!actionableKey.key);
      if (selectedAction.actionableValues) {
        const actionableValues = {};
        selectedAction.actionableKeys.forEach(({ key }) => {
          // only keep the values for which we have the corresponding entry in the final list of actionableKeys
          actionableValues[key] = selectedAction.actionableValues[key];
        });
        selectedAction.actionableValues = actionableValues;
      }
      updatedActions.splice(actionIndex, 1, selectedAction);
      setFieldValue(Fields.Actions, [...updatedActions]);
    }
  };

  const resetField = (): void => {
    if (actionableId) {
      const selectedAction = { ...actions[actionIndex] };
      const updatedActions = [...actions];
      selectedAction.actionableKeys = null;
      selectedAction.actionableValues = null;
      updatedActions.splice(actionIndex, 1, selectedAction);
      setFieldValue(Fields.Actions, [...updatedActions]);
      setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableId}`, false);
      setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableKeys}`, false);
      setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableType}`, false);

      if (isCustomFields) {
        setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableValues}`, false);
      }
      if (isWatermark) {
        setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableOptions}`, false);
      }
    } else {
      setFieldValue(Fields.TriggerKeys, null);
      setFieldTouched(Fields.TriggerKeys, false);
      setFieldValue(Fields.Values, null);
      setFieldTouched(Fields.Values, false);
    }
  };

  const fetchLabels = async (): Promise<void> => {
    setLabelsLoading(true);
    const labelsResponse = await getLabels({
      fetchAll: true,
      maxPages: 2,
      params: {
        order: 'asc',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        sort_by: 'name'
      },
      resourceKey: BFG.resource.key,
      resourceType: BFG.resource.type,
    });

    setLabels(labelsResponse);
    setLabelsLoading(false);
  };

  useEffect(() => {
    if (!fieldType) return;

    // don't make any updates if mounting
    if (prevFieldType !== undefined) {
      if (actionableId) {
        updateActionType(fieldType as ActionSelectorTypes);
      } else {
        updateTriggerType(fieldType as TriggerSelectorTypes);
      }

      resetField();
    }

    if (fieldType === SelectorTypes.Collection) collectionsResponse.fetch();
    if (fieldType === SelectorTypes.CustomField) customFieldKeysResponse.fetch();
    if (fieldType === SelectorTypes.Section) sectionsResponse.fetch();
    if (fieldType === SelectorTypes.Tag) tagsResponse.fetch();
    if (fieldType === SelectorTypes.Label) fetchLabels();
  }, [fieldType]); // eslint-disable-line react-hooks/exhaustive-deps

  // return null here when <FieldSummary ... /> is displaying to avoid extra fetches
  // this keeps the component mounted and the fetched items still in state but displays nothing
  if (!isOpen) return null;

  const selectedDisplayObject = displayMap[fieldType];
  const selectorOptions = selectorTypeOptions(actionableId, triggerType, actions);

  const typeError = getFormStateError({
    actionableId,
    actionIndex,
    errors,
    fieldType,
    isSectionDisabled,
    rowType: true
  });
  const typeTouched = getFormStateTouched({
    actionableId,
    actionIndex,
    fieldType,
    isSectionDisabled,
    rowType: true,
    touched
  });

  const handleSave = async (): Promise<void> => {
    const validationErrors = await validateForm();

    // trigger
    if (!actionableId) {
      setFieldTouched(Fields.TriggerKeys, true);
      setFieldTouched(Fields.TriggerType, true);

      if (isCustomFields) {
        setFieldTouched(Fields.Values, true);
      }

      if (!validationErrors.triggerKeys
        && !validationErrors.triggerType
        && (!isCustomFields || (isCustomFields && !validationErrors.values))
      ) {
        closeField(false);
        cleanUpCustomFieldData();
        setTouched({});
        if (setIsTriggerSet) setIsTriggerSet(true);
      }
    }
    // action
    if (actionableId) {
      setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableId}`, true);
      setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableKeys}`, true);
      setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableType}`, true);

      if (isCustomFields) {
        setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableValues}`, true);
      }
      if (isWatermark) {
        setFieldTouched(`${Fields.Actions}[${actionIndex}].${Fields.ActionableOptions}`, true);
      }

      if (!validationErrors.actions) {
        closeField(false);
        cleanUpCustomFieldData();
        setTouched({});
      }
    }
  };

  return (
    <div className="selector-row">
      { isTriggerSet && !actionableId ? (
        <div className="automation-field-summary__details automation-field-summary__details--stacked">
          <div className="automation-field-summary__trigger-label">
            <p className="tooltip-title">
              <Trans>Trigger</Trans>
              <span className='trigger-asterisk'>*</span>
              <MoreInfoTooltip
                iconSize={16}
                id="automation-trigger-label"
                tooltip={automationTooltips().trigger}
              />
            </p>
          </div>
          <div className="automation-field-summary__field-summary-wrapper">
            <FieldSummaryIcon fieldType={fieldType} />
            <p className="automation-field-summary__details__text">
              {summarizeTriggerType(fieldType)}
            </p>
          </div>
        </div>
      ) : (
        <ListDropdown
          className="selector-row__type"
          disabled={isSectionDisabled}
          error={typeTouched ? typeError : undefined}
          id="selector-options-dropdown"
          label={actionableId ? <Trans>Action</Trans> : <Trans>Trigger</Trans>}
          onChange={(selectedOption: ListOption): void => {
            if (selectedOption.value !== fieldType) {
              // updateField(fieldTypeString, [selectedOption.value as string | SelectorTypes]);
              if (actionableId) {
                updateActionType(selectedOption.value as ActionSelectorTypes);
              } else {
                updateTriggerType(selectedOption.value as TriggerSelectorTypes);
              }
            }
          }}
          options={selectorOptions}
          placeholder={actionableId ? t`Select Action` : t`Select Trigger`}
          required
          tooltip={actionableId ? (
            <MoreInfoTooltip
              iconSize={16}
              id="automation-form-action"
              tooltip={automationTooltips().action}
            />
          ) : (
            <MoreInfoTooltip
              iconSize={16}
              id="automation-form-trigger"
              tooltip={automationTooltips().trigger}
            />
          )}
          value={fieldType}
          virtualizeOptions={false}
        />
      )}
      <CSSTransition
        in={!!fieldType}
        timeout={500}
        unmountOnExit
      >
        <>
          {(fieldType !== SelectorTypes.CustomField || (!fieldKeys || fieldKeys.length === 1)) && (
            <FieldDetailsSelector
              actionIndex={actionIndex}
              actionableId={actionableId}
              customFieldKeysResponse={customFieldKeysResponse}
              errors={errors}
              fieldIndex={0}
              fieldKeys={fieldKeys}
              fieldType={fieldType}
              isSectionDisabled={isSectionDisabled}
              resetField={resetField}
              selectedDisplayObject={selectedDisplayObject}
              setFieldTouched={setFieldTouched}
              touched={touched}
              updateCollectionKeys={updateCollectionKeys}
              updateCustomFieldKey={updateCustomFieldKey}
              updateCustomFieldValues={updateCustomFieldValues}
              updateSelectedKeys={updateSelectedKeys}
              updateWatermark={updateWatermark}
              values={values}
              watermark={actionableId ? actions[actionIndex]?.actionableOptions : null}
            />
          )}
          {fieldType === SelectorTypes.CustomField
            && actionableId
            && fieldKeys?.length > 1
            && fieldKeys?.map((fieldKey, i) => (
              <FieldDetailsSelector
                actionIndex={actionIndex}
                actionableId={actionableId}
                customFieldKeysResponse={customFieldKeysResponse}
                errors={errors}
                fieldIndex={i}
                fieldKeys={fieldKeys}
                fieldType={fieldType}
                isSectionDisabled={isSectionDisabled}
                resetField={resetField}
                selectedDisplayObject={selectedDisplayObject}
                setFieldTouched={setFieldTouched}
                touched={touched}
                updateCollectionKeys={updateCollectionKeys}
                updateCustomFieldKey={updateCustomFieldKey}
                updateCustomFieldValues={updateCustomFieldValues}
                updateSelectedKeys={updateSelectedKeys}
                updateWatermark={updateWatermark}
                values={values}
                watermark={actionableId ? actions[actionIndex]?.actionableOptions : null}
              />
            ))}
          {/* TODO: only show button if key left to select */}
          {fieldType === SelectorTypes.CustomField && actionableId && (
            <TextButton
              className="selector-row__name-and-values--add-button"
              icon={FontIcons.Plus}
              onClick={(): void => {
                updateCustomFieldKey({ key: '', label: '' });
              }}
            >
              <Trans>Add Another Custom Field</Trans>
            </TextButton>
          )}
        </>
      </CSSTransition>
      <div
        className={classnames(
          'automation-field-buttons__container',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'align-right': !(!isOneAction && actionableId) }
        )}
      >
        {!isOneAction && actionableId && (
          <TextWarningButton
            aria-label="delete action"
            className="automation-field-buttons__delete"
            icon={FontIcons.Trash}
            iconSize={18}
            onClick={(): void => { remove(actionIndex); }}
          >
            <Trans>Delete Action</Trans>
          </TextWarningButton>
        )}
        <SecondaryButton
          className="automation-field-buttons__update"
          onClick={(): void =>  { handleSave(); }}
        >
          {actionableId ? <Trans>Save Action</Trans> : <Trans>Save Trigger</Trans>}
        </SecondaryButton>
      </div>
    </div>
  );
};
