import { SearchTextfield } from '@brandfolder/react';

import { t } from '@lingui/macro';
import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

import { PeopleTagsDropdown } from '../common/PeopleTagsDropdown';
import { FilterValues, PeoplePageParamKeys } from '../customPeopleTagTypes';
import { usePeoplePageSearchState } from '../hooks/usePeoplePageQueryState';

const PeopleActionsBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  padding-bottom: 16px;

  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

const SearchPeopleTextfield = styled(SearchTextfield)`
  @media (min-width: 900px) {
    width: 50%;
  }
`;

const ActionBarDropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  .filter-people-dropdown,
  .sort-people-dropdown {
    position: relative;
    width: 48.5%;
    z-index: 2;
  }

  .dropdown-button {
    width: 100%;
  }

  @media (min-width: 744px) {
    .filter-people-dropdown {
      margin-right: 10px;
    }
    .sort-people-dropdown {
      margin-left: 10px;
      width: 70%;
    }
  }

  @media (min-width: 900px) {
    margin: 0 0 20px 20px;
    width: 48.5%;
  }

  @media (min-width: 1526px) {
    .filter-people-dropdown {
      width: 30%;
    }
    .sort-people-dropdown {
      width: 70%;
    }
  }
`;

export const PeoplePageActionsBar: FunctionComponent = () => {
  const [state, setState] = usePeoplePageSearchState();
  const showSearchBar = (state.filter as FilterValues) !== FilterValues.Untagged;
  const resetPagination = { [PeoplePageParamKeys.Page]: '1' };

  useEffect(() => {
    if (state.search && state.filter === 'untagged') {
      setState({ search: '' });
    }
  }, [state.filter, state.search, setState]);

  const setSortByState = (key: string): void => {
    setState({ [PeoplePageParamKeys.Sort]: key, ...resetPagination });
  };

  const setFilterByState = (key: string): void => {
    setState({ [PeoplePageParamKeys.Filter]: key, ...resetPagination });
  };

  const setSearchState = (e: ChangeEvent<HTMLInputElement>): void => {
    setState({ [PeoplePageParamKeys.Search]: e.target.value, ...resetPagination });
  };

  return (
    <PeopleActionsBarWrapper>
      {showSearchBar && (
        <SearchPeopleTextfield
          id="search-textfield"
          label={t`Search for people textfield`}
          onChange={setSearchState}
          placeholder={t`Search for people`}
          showLabel={false}
          textfieldProps={{
            autoComplete: 'off',
          }}
          value={state.search || ''}
        />
      )}
      <ActionBarDropdownWrapper>
        <PeopleTagsDropdown
          selectedValue={state.filter}
          setSelectedValue={setFilterByState}
          type="filter"
        />
        <PeopleTagsDropdown
          selectedValue={state.sort}
          setSelectedValue={setSortByState}
          type="sort"
        />
      </ActionBarDropdownWrapper>
    </PeopleActionsBarWrapper>
  );
};
