import ColorSideBar from '@components/asset/modal/tabs/edit/side_bar/color_assets/colorSideBar';
import ExternalMediumSideBar from '@components/asset/modal/tabs/edit/side_bar/externalMediumSideBar';
import GenericFileSideBar from '@components/asset/modal/tabs/edit/side_bar/genericFileSideBar';
import PeopleSideBar from '@components/asset/modal/tabs/edit/side_bar/peopleSideBar';
import PressSideBar from '@components/asset/modal/tabs/edit/side_bar/pressSideBar';
import TextSideBar from '@components/asset/modal/tabs/edit/side_bar/textSideBar';
import FontSideBar from '@components/asset/modal/tabs/edit/side_bar/fontSideBar';

export {
  ColorSideBar,
  ExternalMediumSideBar,
  GenericFileSideBar,
  PeopleSideBar,
  PressSideBar,
  TextSideBar,
  FontSideBar
};
