import { plural, t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import { BFLoader } from '@components/common/loader/main';
import { PrimaryButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { FontIcons } from '@components/library/icon';
import {
  GetWorkspacesResponse,
  WorkspaceData
} from '@components/show_page/bulk_actions/bulk-workspace-modal/BulkWorkspaceModalTypes';
import { SelectWorkspacesView } from '@components/show_page/bulk_actions/bulk-workspace-modal/SelectWorkspacesView';
import { WorkspaceCreateForm } from '@components/show_page/sections/navbar-admin/WorkspaceCreateForm';

import { sendAction, TrackedAction } from '@helpers/datadog-rum';

import './styles/bulk-workspace-modal.scss';

interface BulkWorkspaceProps {
  assetKeys: string[];
  open: boolean;
  setOpen: (open: boolean) => void;
  /** show search input in UI when workspace count exceeds this number */
  showSearchWorkspaceCount?: number;
}

enum ContentState {
  IsLoading,
  LoadedWithWorkspaces,
  LoadedWithNoWorkspaces,
}

const getWorkspacesOptions = {
  params: { is_workspace: true, per: 1000 }, // eslint-disable-line @typescript-eslint/naming-convention
  url: `/api/v4/brandfolders/${BFG.brandfolder_key}/collections`,
};

const postWorkspacesOptions = (assetKeys: string[], selectedWorkspaceKeys: string[]): UseFetchOptions => ({
  body: {
    data: {
      asset_keys: [...assetKeys], // eslint-disable-line @typescript-eslint/naming-convention
      brandfolder_key: BFG.brandfolder_key, // eslint-disable-line @typescript-eslint/naming-convention
      collection_keys: [...selectedWorkspaceKeys], // eslint-disable-line @typescript-eslint/naming-convention
    }
  },
  fetchOnMount: false,
  method: 'POST',
  url: '/api/v4/bulk_actions/assets/collection_associations',
});

export const BulkWorkspaceModal: FunctionComponent<BulkWorkspaceProps> = ({
  assetKeys,
  open,
  setOpen,
  showSearchWorkspaceCount = 10
}) => {
  const [workspaces, setWorkspaces] = useState<WorkspaceData[]>([]);
  const [selectedWorkspaceKeys, setSelectedWorkspaceKeys] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const getWorkspacesResponse = useFetch<GetWorkspacesResponse>(getWorkspacesOptions);
  const postWorkspacesResponse = useFetch<{ result: string } | Error>(postWorkspacesOptions(assetKeys, selectedWorkspaceKeys));
  const assetCount = assetKeys.length;

  const titleCopy = plural(assetCount, {
    one: 'Add # asset to a Workspace',
    other: 'Add # assets to a Workspace'
  });

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const renderNoWorkspaces = (
    <div>
      <p className='no-workspace-message'><Trans>No workspaces were found.</Trans></p>
      <WorkspaceCreateForm
        brandfolderSlug={BFG.brandfolder_slug}
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
      />
    </div>
  );

  const contentState = (): ContentState => {
    if (!getWorkspacesResponse.loading && workspaces.length) return ContentState.LoadedWithWorkspaces;
    if (!getWorkspacesResponse.loading && !workspaces.length) return ContentState.LoadedWithNoWorkspaces;
    // default to loading state
    return ContentState.IsLoading;
  };

  useEffect(() => {
    if (getWorkspacesResponse.response) setWorkspaces(getWorkspacesResponse.response.data);
  }, [getWorkspacesResponse.response]);

  useEffect(() => {
    if (postWorkspacesResponse.response) {
      const workspaceCount = selectedWorkspaceKeys.length;
      const title = plural(workspaceCount, {
        one: 'Assets added to # Workspace',
        other: 'Assets added to # Workspaces'
      });
      Notify.create({
        title,
        type: 'success'
      });
      sendAction(TrackedAction.WorkspaceAssetAdd);
      setOpen(false);
    }
  }, [postWorkspacesResponse.response]);

  useEffect(() => {
    if (postWorkspacesResponse.error) {
      Notify.create({
        title: t`Error adding assets to workspaces. Please try again.`,
        type: 'error'
      });
      sendAction(TrackedAction.WorkspaceAssetAddError);
    }
  }, [postWorkspacesResponse.error]);

  const selectWorkspacesViewProps = {
    searchValue,
    selectedWorkspaceKeys,
    setSearchValue,
    setSelectedWorkspaceKeys,
    showSearchWorkspaceCount,
    workspaces,
  };

  return (
    <StandardDialog
      footer={(
        <PrimaryButton
          isLoading={getWorkspacesResponse.loading || postWorkspacesResponse.loading}
          loadingCopy={getWorkspacesResponse.loading ? t`Loading` : t`Submitting`}
          onClick={(): void => {
            if (workspaces.length === 0) {
              setIsOpenDialog(true);
            } else {
              postWorkspacesResponse.fetch();
            }
          }}
          size="medium"
        >
          {workspaces.length === 0 ? <Trans>Create a Workspace</Trans> : <Trans>Submit</Trans>}
        </PrimaryButton>
      )}
      id="bulk-workspace-dialog"
      open={open}
      setOpen={setOpen}
      showFooter
      title={titleCopy}
      titleIcon={FontIcons.Plus}
      width={660}
    >
      <div className="bulk-workspace-dialog__content">
        {{
          [ContentState.IsLoading]: <BFLoader />,
          [ContentState.LoadedWithWorkspaces]: SelectWorkspacesView(selectWorkspacesViewProps),
          [ContentState.LoadedWithNoWorkspaces]: renderNoWorkspaces,
        }[contentState()]}
      </div>
    </StandardDialog>
  );
};
