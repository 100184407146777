import fetchJSON from '@api/api_helper';
import { notifyOverLimits } from '@helpers/notify_over_limits';

const getUrl = (assetKey) => `/api/v4/assets/${assetKey}`;

export const get = (assetKey, options) => (
  fetchJSON(getUrl(assetKey), options)
    .then(({ data }) => data)
    .catch(async (response) => { throw (await response.json()); })
);

const createAssets = async (options) => {
  const url = options.url;
  const appendedOptions = {
    method: 'POST',
    body: {
      section_key: options.section_key,
      data: options.data
    },
    context: options.context
  };

  const response = await fetchJSON(url, appendedOptions);
  if (response?.meta?.over_limits) {
    notifyOverLimits();
  }
  return response;
};

export default createAssets;
