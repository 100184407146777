import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { TextButton } from '@components/library/button/index';

interface EmbedAttachmentButtonProps {
  attachmentKey: string;
  displayUrl: string;
  name: string;
  url: string;
  downloadAttachment?: boolean;
}

const EmbedAttachmentButton: FunctionComponent<EmbedAttachmentButtonProps> = (props) => {
  const embedAttachment = (): void => {
    const { attachmentKey: key, displayUrl, downloadAttachment, name, url } = props;
    const attachmentEmbedInfo = {
      displayUrl,
      name,
      key,
      type: downloadAttachment ? 'download' : 'embed',
      url,
    };

    window.parent.postMessage(attachmentEmbedInfo, '*');
  };

  const { downloadAttachment } = props;

  return (
    <TextButton
      className={downloadAttachment ? 'attach-attachment-button' : 'embed-attachment-button'}
      icon={downloadAttachment ? 'bff-download' : 'bff-embed'}
      onClick={embedAttachment}
      size="small"
    >
      {downloadAttachment ? <Trans>Attach</Trans> : <Trans>Embed</Trans>}
    </TextButton>
  );
};

export default EmbedAttachmentButton;
