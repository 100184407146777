export enum CreateProjectTypes {
  ChooseCanvasSize = 'choose-canvas-size',
  CreateFromExistingTemplate = 'create-from-existing-template',
  UploadAnImage = 'upload-an-image'
}

export enum CanvasSizes {
  BusinessCards = 'BusinessCards',
  Custom = 'Custom',
  Facebook = 'Facebook',
  Flyers = 'Flyers',
  Envelopes = 'Envelopes',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  Postcards = 'Postcards',
  Pinterest = 'Pinterest',
  Twitter = 'Twitter',
  YouTube = 'YouTube'
}

export enum SubmittingMessages {
  CreatingAsset,
  CreatingProject,
  Redirecting
}

export enum CreateNewAssetTypes {
  CanvaDesign = 'canva', // when the canva editor is open
  ChooseAssetType = 'choose',
  ChooseCanvaDesign = 'choose-canva', // picking a canva design type
  // digital, print, and video are media_type from
  // https://api.designhuddle.com/admin
  DigitalDesignProject = 'digital',
  PrintDesignProject = 'print',
  VideoProject = 'video',
  Task = 'task'
}
