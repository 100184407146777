import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';

import { useFetch, UseFetchOptions } from '@api/ApiHelper';
import {
  CustomFieldKeyValueMap,
  DisplayMapEntry,
  KeyLabelPair
} from '@components/bulk_management/automation/AutomationTypes';
import { PillSelector } from '@components/bulk_management/automation/form/PillSelector';
import { ListDropdown, ListOption, MultiselectDropdown } from '@components/library/dropdown';
import { StandardTextField } from '@components/library/text-field';

import { NameDisplayTypes } from '../AutomationEnums';
import { getAppropriateCustomFieldSelector } from '../helpers';
import { useDebounce } from '@brandfolder/react';

interface CustomFieldValueSearchResponse {
  data: {
    custom_field_values: string[];
  };
}

interface CustomFieldValuesSelectorProps {
  actionableId: string;
  associatedCustomFieldKey: string;
  error: ReactNode | undefined;
  fieldKeys: KeyLabelPair[];
  isMultiValuesAllowed: boolean;
  isRestricted: boolean;
  isSectionDisabled: boolean;
  selectedDisplayObject: DisplayMapEntry;
  updateCustomFieldValues: (customFieldKey: string, updatedCustomFieldValues: string[]) => void;
  valueAllowedOptions: ListOption[];
  values: CustomFieldKeyValueMap;
}

export const CustomFieldValuesSelector: FunctionComponent<CustomFieldValuesSelectorProps> = ({
  actionableId,
  associatedCustomFieldKey,
  error,
  fieldKeys,
  isMultiValuesAllowed,
  isRestricted,
  isSectionDisabled,
  updateCustomFieldValues,
  valueAllowedOptions,
  values
}) => {
  const appropriateCustomFieldValueSelector = useMemo(() => getAppropriateCustomFieldSelector(
    !actionableId,
    isRestricted,
    isMultiValuesAllowed
  ), [actionableId, isRestricted, isMultiValuesAllowed]);
  const [searchQuery, setSearchQuery] = useState('');
  const searchDebounced = useDebounce(searchQuery, 600);

  const getCustomFieldValueOptions: UseFetchOptions = {
    url: `/api/v4/brandfolders/${BFG.resource.key}/custom_field_keys/${associatedCustomFieldKey}/custom_field_values/search`,
    fetchOnMount: false,
    params: searchDebounced ? { search: searchDebounced } : null
  };
  // TODO - define custom interface for custom field value fetch
  const getCustomFieldValues = useFetch<CustomFieldValueSearchResponse>({
    ...getCustomFieldValueOptions
  });

  useEffect(() => {
    if (appropriateCustomFieldValueSelector === NameDisplayTypes.PillSelector) {
      // only non-controlled multi-value custom fields need suggested values
      getCustomFieldValues.fetch({ ...getCustomFieldValueOptions });
    }
  }, [appropriateCustomFieldValueSelector, associatedCustomFieldKey, searchDebounced]);

  return (
    <div className="selector-row__value">
      {appropriateCustomFieldValueSelector === NameDisplayTypes.MultiselectDropdown && (
        <MultiselectDropdown
          className="selector-values-input"
          disabled={!fieldKeys || isSectionDisabled}
          error={error}
          id="selector-values-input"
          label={<Trans>Custom Field Values</Trans>}
          onChange={(selectedOptions): void => {
            updateCustomFieldValues(associatedCustomFieldKey, selectedOptions as string[]);
          }}
          options={valueAllowedOptions}
          required
          searchable
          values={values?.[associatedCustomFieldKey]}
        />
      )}
      {appropriateCustomFieldValueSelector === NameDisplayTypes.PillSelector && (
        <PillSelector
          className="selector-values-input"
          error={error}
          handleCreate={(createdTag): void => {
            updateCustomFieldValues(
              associatedCustomFieldKey,
              [...values?.[associatedCustomFieldKey] || [], createdTag.name]
            );
          }}
          handleDelete={(_, i): void => {
            updateCustomFieldValues(
              associatedCustomFieldKey,
              [...values?.[associatedCustomFieldKey].filter((value, j) => (i !== j)) || []]
            );
          }}
          id="selector-values-input"
          label={<Trans>Custom Field Values</Trans>}
          required
          selectedValues={values?.[associatedCustomFieldKey]}
          setSearchQuery={setSearchQuery}
          suggestedValues={getCustomFieldValues.response ? getCustomFieldValues.response?.data?.custom_field_values.map((customFieldValue) => customFieldValue) : []}
        />
      )}
      {appropriateCustomFieldValueSelector === NameDisplayTypes.ListDropdown && (
        <ListDropdown
          className="selector-values-input"
          error={error}
          id="selector-values-input"
          label={<Trans>Custom Field Value</Trans>}
          onChange={(selectedOption: ListOption): void => {
            updateCustomFieldValues(associatedCustomFieldKey, [selectedOption.value.toString()]);
          }}
          options={valueAllowedOptions}
          placeholder={t`Select custom field value`}
          required
          value={values?.[associatedCustomFieldKey]?.[0]}
        />
      )}
      {appropriateCustomFieldValueSelector === NameDisplayTypes.Textfield && (
        <StandardTextField
          aria-required
          className="selector-values-input"
          disabled={!fieldKeys || isSectionDisabled}
          error={error}
          id="selector-values-input"
          label={<Trans>Custom Field Value</Trans>}
          onChange={(e: InputChangeEvent): void => {
            updateCustomFieldValues(associatedCustomFieldKey, [e.target.value]);
          }}
          onKeyPress={(e: InputKeyboardEvent): void => {
            if (e.key === 'Enter') {
              // prevent typing "Enter" from submitting the form
              e.preventDefault();
            }
          }}
          placeholder={t`Enter custom field value`}
          required
          value={!values ? '' : values[associatedCustomFieldKey]?.[0]}
        />
      )}
    </div>
  );
};
