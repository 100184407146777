/* eslint-disable @typescript-eslint/naming-convention */
import { FilestackCredentialsPostOptions } from '.';

export const DEFAULT_FILESTACK_POST_OPTIONS: FilestackCredentialsPostOptions = {
  // See Api::V4::Private::Resources::FilestackCredentialsController::MAXIMUM_EXPIRY_SECONDS
  expiry_seconds: (3600 * 8), // 8 hours
  // exif and stat are needed for workbench
  operations: ['exif', 'pick', 'read', 'stat', 'store']
};

export const DEFAULT_FILESTACK_CNAME = 'fs.brandfolder.com';
