import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getStorage, StorageTypes } from '@helpers/storage';
import { BFLoader } from '@components/common/loader/main';
import { SearchFilterOperator } from '@components/show_page/sections/section_search/SearchTypes';
import AdvancedFiltersDrawer from '../advanced_filters/main';
import LabelsDrawer from '../labels/main';

import './styles/main.scss';

const FiltersDrawer = (props) => {
  const {
    activateLabel,
    activeLabelKey,
    labels,
    loading,
    filters,
    filtersDrawerOpen,
    searchableThings,
    searchFilterOperators,
    selectedAssetKeys,
    selectedUGTLocale,
    toggleFiltersDrawer,
    updateFilters,
    updateSearchFilterOperator,
  } = props;

  const advancedFiltersClass = filtersDrawerOpen === "advanced-filters" ? "open" : "closed";

  const setDrawerState = () => {
    if (getStorage(StorageTypes.Local, 'drawerOpen') === 'advanced-filters' && searchableThings) {
      toggleFiltersDrawer('advanced-filters');
    }
  };

  useEffect(setDrawerState, [searchableThings]);

  return (
    <nav className={classnames('filters-drawer', { open: filtersDrawerOpen !== 'none' })}>
      {labels && labels.labelsTree && labels.labelsTree.children.length > 0 ? (
        <LabelsDrawer
          activateLabel={activateLabel}
          activeLabelKey={activeLabelKey}
          filtersDrawerOpen={filtersDrawerOpen}
          labels={labels}
          selectedAssetKeys={selectedAssetKeys}
          toggleFiltersDrawer={toggleFiltersDrawer}
        />
      ) : null}
      {!BFG.hasFeature("disable_advanced_filters") && (
        <div className={`advanced-filters-container ${advancedFiltersClass}`}>
          {!searchableThings ? (
            <div className="loader-container">
              <BFLoader />
            </div>
          ) : (
            <AdvancedFiltersDrawer
              filters={filters}
              filtersDrawerOpen={filtersDrawerOpen}
              loading={loading}
              searchFilterOperators={searchFilterOperators}
              searchableThings={searchableThings}
              selectedAssetKeys={selectedAssetKeys}
              selectedUGTLocale={selectedUGTLocale}
              toggleFiltersDrawer={toggleFiltersDrawer}
              updateFilters={updateFilters}
              updateSearchFilterOperator={updateSearchFilterOperator}
            />
          )}
        </div>
      )}
    </nav>
  );
};

FiltersDrawer.propTypes = {
  activateLabel: PropTypes.func.isRequired,
  activeLabelKey: PropTypes.string,
  labels: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.shape({}).isRequired,
  filtersDrawerOpen: PropTypes.oneOf(['labels', 'advanced-filters', 'none']).isRequired,
  searchableThings: PropTypes.shape({}),
  searchFilterOperators: PropTypes.shape({
    custom_fields: PropTypes.shape({ all: PropTypes.oneOf(Object.values(SearchFilterOperator)) }),
    tags: PropTypes.oneOf(Object.values(SearchFilterOperator)).isRequired,
  }).isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
  selectedUGTLocale: PropTypes.string.isRequired,
  toggleFiltersDrawer: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
  updateSearchFilterOperator: PropTypes.func.isRequired,
};

FiltersDrawer.defaultProps = {
  activeLabelKey: null,
  labels: {},
  searchableThings: null,
};

export default FiltersDrawer;
