import React, { FunctionComponent } from 'react';

import { InputType, StandardTextField } from '@components/library/text-field';
import { convertDaysToSeconds, convertSecondsToDays } from '@components/settings/brandfolder/advanced-tab/default-share-manifest-expiration/helpers';

interface ExpirationValueTextFieldProps {
  defaultExpirationValue: number | null;
  setDefaultExpirationValue: SetStateDispatch<number | null>;
}

const ExpirationValueTextField: FunctionComponent<ExpirationValueTextFieldProps>  = ({ 
  defaultExpirationValue,
  setDefaultExpirationValue 
}) => {
  
  return (
    <StandardTextField
      className='expiration-value-text-field'
      id="expiration-value-text-field"
      label='Days'
      min={1}
      onChange={(e) :void => setDefaultExpirationValue(convertDaysToSeconds(e.target.value))}
      placeholder={'#'}
      type={InputType.Number}
      value={defaultExpirationValue ? convertSecondsToDays(defaultExpirationValue) : '1'}
    />
  );
};

export default ExpirationValueTextField;