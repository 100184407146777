import { UseFetchOptions, FetchMethods } from '@api/ApiHelper';
import { getIntegrationsContentSyncUrl } from '@api/contentsync';
import { localizeDate } from '@helpers/localize';

import { BrandfolderSyncStatusResponseDatum } from './InsightsTypes';

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
interface PopupCenter {
  url: string;
  title: string;
  w: number;
  h: number;
}

// Adapted from https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
// TODO - review this helper to remove eslint-disable stuff
export const popupCenter = ({
  url,
  title = '_blank',
  w,
  h
}: PopupCenter): Window => {
  // Fixes dual-screen position                            Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth // eslint-disable-line no-nested-ternary
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width; // eslint-disable-line no-restricted-globals

  const height = window.innerHeight // eslint-disable-line no-nested-ternary
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height; // eslint-disable-line no-restricted-globals

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const params = `width=${w / systemZoom},height=${h / systemZoom},top=${top},left=${left}`;

  const newWindow: Window = window.open(url, title, params);

  return newWindow;
};

export const fetchOptionsForBrandfolders = (): UseFetchOptions => ({
  fetchOnMount: true,
  method: 'GET',
  queuePriorityHigh: false,
  url: '/api/v4/brandfolders?include=organization'
});

const fetchOptionsForSetSheet = (apiKey: string, method: FetchMethods, smarWorkspaceId?: number): UseFetchOptions => ({
  customToken: apiKey,
  body: {
    data: {
      attributes: {
        smar_workspace_id: smarWorkspaceId
      }
    }
  },
  fetchOnMount: false,
  method,
  queuePriorityHigh: false,
  url: `${getIntegrationsContentSyncUrl()}/insights-connect?smar_workspace_id=${smarWorkspaceId}`,
});

export const setSheetOptions = (orgApiKey: string, smarWorkspaceId: number): UseFetchOptions => (
  fetchOptionsForSetSheet(orgApiKey, 'POST', smarWorkspaceId)
);

export const getLinkOptionsForConnect = (orgApiKey: string, method: FetchMethods): UseFetchOptions => ({
  customToken: orgApiKey,
  queuePriorityHigh: false,
  method,
  url: `${getIntegrationsContentSyncUrl()}/oauth/smartsheet/connect`
});

export const fetchOptionsForDataSyncResponse = (orgApiKey: string): UseFetchOptions => ({
  customToken: orgApiKey,
  queuePriorityHigh: false,
  method: 'GET',
  url: `${getIntegrationsContentSyncUrl()}/insights-connect`,
});

export const fetchOptionsForTurnOnSync = (orgApiKey: string, targetBrandfolderId: string): UseFetchOptions => ({
  customToken: orgApiKey,
  fetchOnMount: false,
  method: 'POST',
  queuePriorityHigh: false,
  url: `${getIntegrationsContentSyncUrl()}/insights-connect/${targetBrandfolderId}`,
});

export const fetchOptionsForTurnOffSync = (orgApiKey: string, targetBrandfolderId: string): UseFetchOptions => ({
  customToken: orgApiKey,
  fetchOnMount: false,
  method: 'DELETE',
  queuePriorityHigh: false,
  url: `${getIntegrationsContentSyncUrl()}/insights-connect/${targetBrandfolderId}`,
});

export const fetchOptionsForResetSync = (orgApiKey: string, targetBrandfolderId: string): UseFetchOptions => ({
  customToken: orgApiKey,
  fetchOnMount: false,
  method: 'POST',
  queuePriorityHigh: false,
  url: `${getIntegrationsContentSyncUrl()}/insights-connect/${targetBrandfolderId}/reset`,
});

export const fetchOptionsForStopSyncingData = (orgApiKey: string): UseFetchOptions => ({
  customToken: orgApiKey,
  queuePriorityHigh: false,
  fetchOnMount: false,
  method: 'POST',
  url: `${getIntegrationsContentSyncUrl()}/insights-connect/disconnect`
});

export const syncNotify = (body: string, title: string, type: NotifyTypes): void => {
  Notify.create({
    body,
    title,
    type
  });
};

export const convertUTCDate = (syncedBrandfolder?: BrandfolderSyncStatusResponseDatum): string => {
  const dateInMiliseconds = syncedBrandfolder.last_sync_time * 1000;
  const dateIsoString = new Date(dateInMiliseconds).toISOString();
  const statusDate = localizeDate(dateIsoString);
  return statusDate;
};
