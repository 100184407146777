/* eslint-disable react/require-default-props */
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import AutosuggestSearch from '@components/common/autosuggest_search/main';
import Checkbox from '@components/common/checkbox/main';
import RadioButton from '@components/common/radio_button/main';
import { SearchFilterOperator } from '@components/show_page/sections/section_search/SearchTypes';

const TagsFilter = ({
  searchableThings,
  searchFilterOperators,
  tags: filteredTags,
  updateFilters,
  updateSearchFilterOperator
}) => {
  const [allTags, setAllTags] = useState([]);
  const [visibleTags, setVisibleTags] = useState([]);

  useEffect(() => {
    if (searchableThings.tags !== undefined) {
      setAllTags(searchableThings.tags);
      const initialVisibleTags = [];
      filteredTags.forEach((selectedTag) => {
        const inFirstTen = initialVisibleTags.map((visibleTagName) => visibleTagName).includes(selectedTag);
        if (!inFirstTen) {
          // add tags that are not included in the initial 10 tags
          // (these are tags that are selected - in filters - and have been added by searching)
          initialVisibleTags.push(
            searchableThings.tags.find((tagName) => tagName === selectedTag)
          );
        }
      });
      setVisibleTags(initialVisibleTags);
    }
  }, [searchableThings]);

  const updateTags = (suggestion) => {
    const visibleTagsUpdated = [...visibleTags];

    updateFilters(suggestion);
    visibleTagsUpdated.push(suggestion);
    setVisibleTags(visibleTagsUpdated);
  };

  if (allTags.length <= 0) { return null; }
  return (
    <div className="filter-section tags">
      <div className="flex-row title">
        <p><Trans>Tags</Trans></p>
        <div className="radio-button-container">
          <RadioButton
            checkedButton={searchFilterOperators.tags === SearchFilterOperator.AND ? "All" : "Any"}
            labelCopy={t`Any selected`}
            name="search-operator-or"
            onChangeFunction={() => updateSearchFilterOperator('tags', SearchFilterOperator.OR)}
            valueCopy="Any"
          />
          <RadioButton
            checkedButton={searchFilterOperators.tags === SearchFilterOperator.AND ? "All" : "Any"}
            labelCopy={t`All selected`}
            name="search-operator-or"
            onChangeFunction={() => updateSearchFilterOperator('tags', SearchFilterOperator.AND)}
            valueCopy="All"
          />
        </div>
      </div>
      <div className="tag-columns">
        {visibleTags.map((tagName) => {
          return (
            <div key={tagName} className="tag-item">
              <Checkbox
                checked={filteredTags.indexOf(tagName.replace('"', '\\"')) > -1}
                label={tagName}
                size="sm"
                toggle={() => updateFilters(tagName)}
              />
            </div>
          );
        })}
      </div>
      { _.difference(allTags, visibleTags).length === 0 ? '' : (
        <AutosuggestSearch
          allValues={allTags}
          id="tag-autosuggest"
          onSelect={updateTags}
          visibleValues={visibleTags}
        />
      )}
    </div>
  );
};

TagsFilter.propTypes = {
  searchableThings: PropTypes.shape({}).isRequired,
  searchFilterOperators: PropTypes.shape({
    custom_fields: PropTypes.shape({ all: PropTypes.oneOf(Object.values(SearchFilterOperator)) }),
    tags: PropTypes.oneOf(Object.values(SearchFilterOperator)).isRequired,
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  updateFilters: PropTypes.func.isRequired,
  updateSearchFilterOperator: PropTypes.func.isRequired,
};

TagsFilter.defaultProps = {
  tags: [],
};

export default TagsFilter;
