import React from 'react';
import classnames from 'classnames';

import { ListDropdown } from '@components/library/dropdown';

import { InputProps, InputType, InputAttributes } from './Input.props';

import './base_styles.scss';

const defaultAttributeProps: Partial<InputAttributes> = {
  type: InputType.Text
};

const BaseInput = (props: InputProps): JSX.Element => {
  const fullProps = {
    ...props,
    attributes: {
      ...defaultAttributeProps,
      ...props.attributes
    }
  };

  const {
    attributes,
    options,
    className,
    input,
    selectValue,
    submitAttempted,
    validationRules
  } = fullProps;

  const defaultValidationRules = {
    ...attributes.type === InputType.Email && { isEmail: true },
    ...attributes.type === InputType.Password && { isPassword: true },
    isRequired: true, // TODO: decide if want to make isRequired false by default
  };

  const allValidationRules = {
    ...defaultValidationRules,
    ...validationRules,
  };

  const { onBlur, onChange, onFocus } = attributes;

  const allAttributes = {
    ...attributes,
    className: classnames(
      attributes.type === 'textarea' ? 'bf-textarea' : 'bf-input',
      attributes.className,
      className,
      { invalid: submitAttempted && input?.error },
    ),
    id: attributes.id || attributes.name,
    onBlur: onBlur ? (e): void => onBlur(e, allValidationRules) : undefined,
    onChange: onChange ? (e): void => onChange(e, allValidationRules) : undefined,
    onFocus: onFocus ? (e): void => onFocus(e, allValidationRules) : undefined,
    value: input?.value as string || attributes.value || '',
  };

  const selectAttributes = {
    ...allAttributes,
    onChange: (e): void => {
      if (attributes && attributes.onChange) {
        attributes.onChange({ ...e, inputName: attributes.name }, allValidationRules);
      }
    }
  };

  switch (attributes.type) {
    case InputType.Textarea:
      return <textarea {...allAttributes} />;
    case InputType.Select:
      return <ListDropdown {...selectAttributes} options={options} virtualizeOptions={false} />;
    default:
      return <input {...allAttributes} />;
  }
};

export default BaseInput;
