import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { UppyUploader } from '@components/common/uppy/UppyUploader';
import { formattedUppyAttachment } from '@helpers/uppy';

class VideoWatermarkUppy extends React.Component {
  handleUpload = (files) => {
    if (!files.length) { return; }
    const file = formattedUppyAttachment(files[0]);
    this.props.onUpload(file)
  }

  uploadType = () => this.props.uploadType.toLowerCase();

  trigger = () => `uppy-trigger-${this.uploadType()}`;

  renderArea = () => (
    <div className="uppy-video-watermark" id={this.trigger()}>
      <p><Trans>Click to upload watermark</Trans></p>
    </div>
  );

  render() {
    return (
      <React.Fragment>
        {this.renderArea()}
        <UppyUploader
          {...this.props}
          button
          handleUpload={this.handleUpload}
          locale={{ strings: { dropPaste: "Drag a file here or %{browse}", browse: "click to browse"} }}
          restrictions={{ maxNumberOfFiles: 1, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.bmp', '.gif'] }}
          template={this.uploadType()}
          trigger={`#${this.trigger()}`}
          uniqIdentifier={`uppy-${this.uploadType()}`}
        />
      </React.Fragment>
    );
  }
}

VideoWatermarkUppy.propTypes = {
  uploadType: PropTypes.string.isRequired
};

export default VideoWatermarkUppy;
