import { fetchJson } from '@api/ApiHelper';
import { notifyOverLimits } from '@helpers/notify_over_limits';

const getUrl = (assetKey) => `/api/v4/assets/${assetKey}`;

export default (options) => (
  fetchJson({
    url: getUrl(options.assetKey),
    ...options
  })
);

// helper: returns custom fields as an object
export const formatCustomFields = (response) => {
  if (!response.included) { return undefined; }

  const formattedCustomFields = {};

  response.included.forEach((field) => {
    formattedCustomFields[field.attributes.key] = field.attributes.value;
  });

  return formattedCustomFields;
};


export const updateAsset = ({ assetKey, attributes, params }) => {
  return fetchJson({
    url: getUrl(assetKey),
    method: 'PUT',
    body: {
      data: {
        attributes
      }
    },
    params
  });
};

export const deleteAsset = (assetKey) => {
  return fetchJson({
    url: getUrl(assetKey),
    method: 'DELETE'
  });
};

export const createAssets = async (options) => {
  const response = await fetchJson({
    url: options.url,
    method: 'POST',
    headers: options.headers,
    body: {
      section_key: options.section_key,
      data: options.data
    }
  });
  if (response?.meta?.over_limits) {
    notifyOverLimits();
  }
  return response;
};
