/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, ReactNode } from 'react';

import { Asset, AssetClient } from '@api/v4/assets/assetTypes';
import { TaskPriorities, TaskStatuses } from '@api/v4/tasks';
import { TaskServerResponseObject } from '@api/v4/tasks/TaskTypes';
import { StandardAvatars } from '@components/library/avatars';
import { DashEmptyLabel } from '@components/library/empty-label';
import { FontIcon, FontIcons } from '@components/library/icon';
import { camelizeObjectKeys, AvatarUser } from '@components/library/utils';
import { localizeDate } from '@helpers/localize';
import { getUserDisplayName } from '@helpers/user';

import '@components/show_page/sections/asset/styles/workspace-task-details.scss';

const renderStatus = (status: string): ReactNode => {
  switch (status) {
    case TaskStatuses.NotStarted:
      return <p className="workspace-task-details__item--status not-started"><Trans>Not Started</Trans></p>;
    case TaskStatuses.InProgress:
      return <p className="workspace-task-details__item--status in-progress"><Trans>In Progress</Trans></p>;
    case TaskStatuses.Completed:
      return <p className="workspace-task-details__item--status completed"><Trans>Completed</Trans></p>;
    default:
      return undefined;
  }
};

const renderPriority = (priority: string): ReactNode => {
  switch (priority) {
    case TaskPriorities.Low:
      return (
        <div className="workspace-task-details__item--priority low">
          <FontIcon
            aria-hidden
            className="low-icon"
            icon={FontIcons.ArrowDown}
          />
          <p className="workspace-task-details__item--priority--copy">
            <Trans context="low priority">low</Trans>
          </p>
        </div>
      );
    case TaskPriorities.Medium:
      return (
        <div className="workspace-task-details__item--priority medium">
          <FontIcon
            aria-hidden
            className="medium-icon"
            icon={FontIcons.PriorityMedium}
          />
          <p className="workspace-task-details__item--priority--copy">
            <Trans context="medium priority">medium</Trans>
          </p>
        </div>
      );
    case TaskPriorities.High:
      return (
        <div className="workspace-task-details__item--priority high">
          <FontIcon
            aria-hidden
            className="high-icon"
            icon={FontIcons.PriorityHigh}
          />
          <p className="workspace-task-details__item--priority--copy">
            <Trans context="high priority">high</Trans>
          </p>
        </div>
      );
    default:
      return undefined;
  }
};

interface WorkspaceTaskDetailsProps {
  asset: Asset;
  task: TaskServerResponseObject | undefined;
}

export const WorkspaceTaskDetails: FunctionComponent<WorkspaceTaskDetailsProps> = ({ asset, task }) => {
  const attributes = task?.attributes;
  if (!attributes) return null;

  const { assignedUsers } = camelizeObjectKeys<Asset, AssetClient>(asset);
  const users: AvatarUser[] = assignedUsers ? assignedUsers.map((user) => ({
    ...user,
    name: getUserDisplayName(user)
  })) : [];

  return (
    <ul className="workspace-task-details">
      <li className="workspace-task-details__item">
        <p><Trans>Status</Trans></p>
        {renderStatus(attributes?.task_status)}
      </li>
      <li className="workspace-task-details__item">
        <p><Trans>Priority</Trans></p>
        {renderPriority(attributes?.task_priority)}
      </li>
      <li className="workspace-task-details__item">
        <p><Trans>Assigned to</Trans></p>
        <div className="workspace-task-details__item--assigned-to">
          {users.length > 0
            ? (
              <StandardAvatars
                overflowAvatarProps={{
                  size: 36
                }}
                standardAvatarProps={{
                  size: 36
                }}
                users={users}
              />
            )
            : (
              <DashEmptyLabel
                aria-label={t`Not Specified`}
              />
            )}
        </div>
      </li>
      <li className="workspace-task-details__item">
        <p><Trans>Due Date</Trans></p>
        <p className="workspace-task-details__item--due-date">
          {attributes?.due_date
            ? localizeDate(attributes.due_date)
            : (
              <DashEmptyLabel
                aria-label={t`Not Specified`}
              />
            )}
        </p>
      </li>
    </ul>
  );
};
