import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  FunctionComponent,
  ReactNode
} from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import {
  ActionsOpenDirections,
  BaseDropdownProps,
  DropdownVariants,
  LabelPosition,
  ListOpenDirections,
  OpenDirectionOverride,
} from '@components/library/dropdown';
import { manageDropdownListener, manageKeydownListener } from '@helpers/use_effect_callbacks';
import { isOutOfView } from '@helpers/isInView';
import { BFLoader } from '@components/common/loader/main';

import '@components/library/dropdown/dropdown_transitions.scss';

const baseName = 'dropdown';

const BaseDropdown: FunctionComponent<BaseDropdownProps> = (props) => {
  const {
    anchorElement,
    arrowedItem,
    children,
    className = '',
    disableDynamicPositioning,
    disabled,
    styleClassNames,
    variantName,
    dropdownTransition = '',
    dropdownTransitionTime = 300,
    closeCallback = undefined,
    error,
    expandOnSpacebarKeypress = true,
    keydownCallback,
    id,
    isLoading,
    isNestedDropdown,
    label,
    labelPosition = LabelPosition.Top,
    openDirection = ActionsOpenDirections.DownLeft,
    openOnClick = true,
    openOnHover = false,
    overflowParentRef,
    required,
    setIsDropdownOpen = (): void => { /* noop */ },
    toggleDropdown = false,
    tooltip,
    useUlContainer = true,
    userDevice = BFG?.currentUser?.device || 'desktop-view',
    virtualizeOptions,
  } = props;
  const isMounted = useRef(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [openDirectionOverride, setOpenDirectionOverride] = useState<OpenDirectionOverride | null>(null);
  const [inlineStyles, setInlineStyles] = useState({});
  const [storedTimeouts, setStoredTimeouts] = useState<number[]>([]);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const contentContainerDivRef = useRef<HTMLDivElement | null>(null);
  const contentContainerUlRef = useRef<HTMLUListElement | null>(null);

  const cancelStoredTimeouts = (): void => {
    storedTimeouts.forEach((storedTimeout) => clearTimeout(storedTimeout));
  };

  const isTouchScreen = userDevice === 'mobile-view' || userDevice === 'tablet-view';

  const handleOpenAndClose = (e: any): void => {
    const triggerKeys = expandOnSpacebarKeypress
      ? ['ArrowUp', 'ArrowDown', 'Enter', 'Spacebar', ' ']
      : ['ArrowUp', 'ArrowDown', 'Enter'];
    const updateShowDropdown = (): void => {
      setShowDropdown((prevState) => {
        // if update is to open the dropdown, close other BF dropdowns that may be open
        // in most cases, other dropdowns will close due to clicking away from them,
        // which is handled in the 'manageDropdownListener' method, however,
        // this 'closeBFDropdowns' event is necessary because code may contain e.stopPropagation()
        // which prevents 'manageDropdownListener' from detecting the event
        if (!prevState && !isNestedDropdown) window.BF?.fx?.dispatchWindowEvent('closeBFDropdowns'); // eslint-disable-line no-unused-expressions
        return !prevState;
      });
    };

    switch (e.type) {
      case 'keydown':
        // toggle the menu open/closed, but if a dropdown item is targeted (highlighted via arrow
        // keys) defer to manageKeydownListener method so that 'Enter' keydown will select it
        // !arrowedItem is needed because the keydown event here was intercepting the 'enter'
        // intended to select a arrowedItem
        if (!showDropdown && (triggerKeys.includes(e.key))) updateShowDropdown();
        break;
      case 'click':
        if (openOnClick || isTouchScreen) updateShowDropdown();
        break;
      case 'mouseenter':
      case 'mouseleave':
        if (openOnHover && !isTouchScreen) setShowDropdown(e.type === 'mouseenter');
        break;
      default:
    }
  };

  const runDynamicPositioning = (): void => {
    cancelStoredTimeouts();
    const { outOfViewport, outOfOverflowParent } = isOutOfView(contentContainerDivRef?.current || contentContainerUlRef?.current, overflowParentRef?.current);
    if (showDropdown && (outOfViewport.bottom || outOfOverflowParent.bottom)) { // overflows bottom of viewport or parent container
      setOpenDirectionOverride(OpenDirectionOverride.Up);

      // if an overflowParentRef is provided, check if the dropdown will overflow the parent container when it's respositioned to open up
      if (overflowParentRef?.current && menuRef?.current) {
        const containerOffset = 15; // gap between dropdown and overflow container
        const dropdownWrapperCoords = menuRef.current.getBoundingClientRect();
        const gapBetweenDropdownWrapperAndContainer = dropdownWrapperCoords.top - outOfOverflowParent.bounding.top;
        const dropdownHeight = outOfViewport.bounding.height;
        const willBeOutOfContainerParentTop = gapBetweenDropdownWrapperAndContainer > 0 && dropdownHeight > (gapBetweenDropdownWrapperAndContainer + containerOffset);

        if (willBeOutOfContainerParentTop || outOfOverflowParent?.top) {
          const bottom = `${dropdownWrapperCoords.bottom - (outOfOverflowParent.bounding.top + containerOffset + dropdownHeight)}px`;
          setInlineStyles({ bottom });
        }
      }
    }

    // reset state for dynamic dropdown placement (refs need to be reset so initially rendered dropdown will be out of view)
    if (!showDropdown) {
      const newTimeout = window.setTimeout(() => {
        if (isMounted.current) {
          setOpenDirectionOverride(null);
          setInlineStyles({});
        }
      }, dropdownTransitionTime + 50); // setTimeout accounts for CSSTransition 'timeout' time, the setTimeout callback should fire after the CSSTransition transition has finished
      setStoredTimeouts((timeouts) => ([...timeouts, newTimeout]));
    }
  };

  const labelHTML = (): ReactNode => (
    <label
      className={classnames(
        styleClassNames[`${variantName}--label`],
        `${variantName}--label`,
      )}
      htmlFor={id}
    >
      {label}
      {required && <span className={styleClassNames['required-asterix']}>*</span>}
      {tooltip && <span className={styleClassNames.tooltip}>{tooltip}</span>}
    </label>
  );

  const errorHTML = (): ReactNode => (
    <span
      className={`${styleClassNames['dropdown-error-container']} dropdown-error-container`}
      id={`${id}_error`}
      role="alert"
    >
      {error}
    </span>
  );

  const contentContainerClassNames: string = classnames(
    'content-container',
    'dropdown-transitions',
    styleClassNames['content-container'],
    styleClassNames[openDirectionOverride || openDirection], // orients dropdown container relative to wrapper
    {
      'virtualize-options': virtualizeOptions,
      [styleClassNames['virtualize-options']]: virtualizeOptions,
    },
  );

  const dropdownEventListenerCallback = (e: MouseEvent | KeyboardEvent): void => {
    e.stopPropagation();
    e.preventDefault();
    setShowDropdown(false);
  };

  const cleanup = (): void => {
    setShowDropdown(false); // close dropdown when unmounting
    cancelStoredTimeouts(); // cancel all outstanding timeouts when unmounting
    isMounted.current = false;
  };

  useEffect(() => cleanup, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => (
    manageDropdownListener(menuRef, showDropdown, dropdownEventListenerCallback)
  ), [menuRef, showDropdown, dropdownEventListenerCallback]);

  useEffect(() => (
    manageKeydownListener(showDropdown, keydownCallback)
  ), [showDropdown, keydownCallback]);

  useEffect(() => {
    if (toggleDropdown) {
      setShowDropdown(() => {
        if (showDropdown && closeCallback) {
          closeCallback();
        }
        return !showDropdown;
      });
    }
  }, [toggleDropdown]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsDropdownOpen(showDropdown);
  }, [showDropdown]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (disabled) {
      setShowDropdown(false);
    }
  }, [disabled]);

  useLayoutEffect(() => { // useLayoutEffect runs after render but before useEffect
    if (!disableDynamicPositioning) {
      if ((contentContainerDivRef.current && openDirection === ListOpenDirections.Down) || (contentContainerUlRef.current && openDirection === ListOpenDirections.Down)) { // TODO: setup for openDirection values other than 'down'
        runDynamicPositioning();
      }
    }
  }, [showDropdown, overflowParentRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        ref={menuRef}
        aria-required={required}
        className={classnames(
          className, // user specified class
          `bf-${baseName}__${variantName}`, // base-variant class for post consumption styling
          styleClassNames[variantName], // class for css modules
          {
            [styleClassNames.open]: showDropdown,
            [styleClassNames['error-and-no-label']]: !label && !!error,
            [styleClassNames['no-error-and-no-label']]: !label && !error,
            [styleClassNames['label-left']]: labelPosition === LabelPosition.Left,
            [styleClassNames.disabled]: disabled,
            open: showDropdown,
            closed: !showDropdown
          },
        )}
        id={id}
        onMouseEnter={!disabled ? handleOpenAndClose : undefined}
        onMouseLeave={!disabled ? handleOpenAndClose : undefined}
      >
        {labelPosition === LabelPosition.Top && (!!error || !!label) && (
          <div className={`${styleClassNames['top-container']} top-container`}>
            {!!label && labelHTML()}
            {!!error && errorHTML()}
          </div>
        )}
        {labelPosition === LabelPosition.Left && !!label && labelHTML()}
        <div
          aria-disabled={disabled || undefined}
          className={classnames(
            'anchor-element-click-container',
            styleClassNames['anchor-element-click-container'],
            { [styleClassNames.disabled]: disabled },
          )}
          data-testid="anchor-element-click-container-id"
          onClick={!disabled ? handleOpenAndClose : undefined}
          onKeyDown={!disabled ? handleOpenAndClose : undefined}
          role="group"
          tabIndex={!disabled ? 0 : -1}
        >
          {anchorElement || 'ANCHOR ELEMEMT PLACEHOLDER'}
        </div>
        <CSSTransition
          classNames={dropdownTransition}
          in={showDropdown}
          timeout={dropdownTransitionTime}
          unmountOnExit
        >
          {/* accomodates use case where consumer passes in children that include more content than just list items */}
          {useUlContainer ? (
            <ul
              ref={contentContainerUlRef}
              aria-activedescendant={arrowedItem?.toString()}
              aria-multiselectable={variantName === DropdownVariants.Multiselect}
              className={contentContainerClassNames}
              role="listbox"
              style={inlineStyles}
            >
              {isLoading ? <BFLoader className={styleClassNames.loader} /> : children}
            </ul>
          ) : (
            <div
              ref={contentContainerDivRef}
              className={contentContainerClassNames}
              style={inlineStyles}
            >
              {isLoading ? <BFLoader /> : children}
            </div>
          )}
        </CSSTransition>
      </div>
      <CSSTransition
        appear
        in={labelPosition === LabelPosition.Left && !!error}
        timeout={500}
        unmountOnExit
      >
        {errorHTML()}
      </CSSTransition>
    </>
  );
};

export default BaseDropdown;
