import { Trans } from '@lingui/macro';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { AssetTypes } from '@api/v4/assets/assetTypes';
import '@assets/javascripts/lib/trumbowyg.min';
import {
  acceptedFileExtensions,
  GenerateCaptionButton,
  GenerativeAiImageCaptions,
} from '@components/asset/modal/tabs/edit/main_pane/generative_ai_image_captions';
import { Locale } from '@components/common/language_menu/languagesMap';
import Tooltip from '@components/common/tooltip/main';
import { InputType } from '@components/library/inputs/Input.props';
import Input from '@components/library/inputs/PrimaryInput';
import Label from '@components/library/labels/PrimaryLabel';
import { TextSanitizationTooltipLabel } from '@components/shared/sanitization';

import assetModalContext from '../asset_modal_context';
import { ActionTypes, AssetDetailsDispatch, ValidationErrors } from '../EditTabTypes';

import TagsInput from './tagsInput';

($ as TrumbowygJqueryStatic).trumbowyg.svgPath = '/icons.svg';

interface DetailsSectionProps {
  aiGeneratedCaptionId: string | null;
  assetDescription: string;
  terribleTrumbowygFlag: Locale | '';
}

interface DetailsSectionScopedContext {
  state: {
    editState: DetailsSectionProps;
    errorState: ValidationErrors[];
    initialData: {
      asset: {
        description: string;
        extension?: string;
      };
      assetType: AssetTypes;
      assetKey: string;
    };
  };
  dispatch: AssetDetailsDispatch;
}

export const initializeTrumbowyg = (assetDescription: string, dispatch: AssetDetailsDispatch): void => {
  ($('#asset-description-textarea') as TrumbowygJquery)
    .trumbowyg({
      semantic: false,
      urlProtocol: true,
      btns: [
        ['viewHTML'],
        ['formatting'],
        ['strong', 'em', 'del'],
        ['link'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList']
      ]
    })
    .on('tbwchange', (e) => dispatch({ type: ActionTypes.UpdateAsset, payload: { assetDescription: e.target.value } }))
    .on('tbwpaste', (e) => dispatch({ type: ActionTypes.UpdateAsset, payload: { assetDescription: e.target.value } }))
    .trumbowyg('html', assetDescription);
};

const DetailsSection = (props): JSX.Element => {
  const { state, dispatch }: DetailsSectionScopedContext = useContext(assetModalContext);
  const { initialData, editState, errorState } = state;
  const { assetDescription } = editState;
  const [showGenerateCaption, setShowGenerateCaption] = useState(false);
  const hasDescriptionError = errorState.includes(ValidationErrors.MissingDescription);
  const { orgApiKey } = props;
  // We need to always show description on text assets
  const isTextAssetClassName = initialData.assetType === AssetTypes.Text ? 'show-text-asset-description' : '';

  const embedlyLink = 'https://embed.ly/embed/features/providers';
  const tooltipContent = (
    <div>
      <Trans>
        We automatically fetch titles, descriptions, and embed codes for over 250 providers with
        <a className="embedly-link" href={embedlyLink} rel="noopener noreferrer" target="_blank">Embed.ly</a>
      </Trans>
    </div>
  );

  const generateCaptionFeatureEnabled =
    !BFG.context.hasFeature('disable_generative_image_captions') &&
    BFG.brandfolderSettings.ai_generated_captions &&
    acceptedFileExtensions.includes(initialData.asset?.extension?.toLowerCase()) &&
    initialData.assetType === AssetTypes.GenericFile;

  useEffect(() => (
    initializeTrumbowyg(editState.assetDescription, dispatch)
  ), [dispatch, editState.terribleTrumbowygFlag, showGenerateCaption]); // editState.assetDescription intentionally left out of dep array

  useEffect(() => (
    (): void => {
      // kill trumbowyg
      ($('#asset-description-textarea') as TrumbowygJquery).trumbowyg('destroy');
    }
  ), []);

  const handleCloseGenerateCaption = useCallback((): void => {
    setShowGenerateCaption(false);
  }, []);

  return (
    <>
      <div className={`description-label-error-container ${isTextAssetClassName}`}>
        <TextSanitizationTooltipLabel margin>
          <Label
            attributes={{
              htmlFor: 'asset-description-textarea',
            }}
          >
            <Trans>Description</Trans>
          </Label>
        </TextSanitizationTooltipLabel>
        {initialData.assetType === AssetTypes.Text
          && (
            <p className={`input-error ${hasDescriptionError ? 'active' : ''}`}>
              <Trans>Asset description required</Trans>
            </p>
          )}
        {initialData.assetType === AssetTypes.ExternalMedium
          && (
            <Tooltip
              clickable
              delayHide={500}
              placement="bottom"
              tooltipContent={tooltipContent}
              tooltipId="embedly-pro-tip-tooltip"
              type="light"
            >
              <div className="embedly-pro-tip-tooltip">
                <span
                  aria-hidden
                  aria-label="Embedly Pro Tip"
                  className="icon bff-tip"
                />
                <h4 className="pro-tip-text">
                  <Trans>Pro tip</Trans>
                </h4>
              </div>
            </Tooltip>
          )}
        {generateCaptionFeatureEnabled &&
          <GenerateCaptionButton
            setShowGenerateCaption={setShowGenerateCaption}
            showGenerateCaption={showGenerateCaption}
          />
        }
      </div>
      <div className={`asset-description-container ${hasDescriptionError ? 'invalid' : ''} ${isTextAssetClassName} ${showGenerateCaption && 'hide-text-area'}`}>
        <Input
          attributes={{
            id: 'asset-description-textarea',
            type: InputType.Textarea,
            name: 'description',
            value: assetDescription,
            onChange: (e: InputChangeEvent): void => dispatch(
              { type: ActionTypes.UpdateAsset, payload: { assetDescription: e.target.value } }
            )
          }}
          input={{ value: assetDescription }}
        />
        {showGenerateCaption && generateCaptionFeatureEnabled && (
          <div className="generative-ai-image-captions">
            <GenerativeAiImageCaptions
              asset={{
                extension: initialData.asset?.extension || null,
                key: initialData.assetKey,
              }}
              closeCaption={handleCloseGenerateCaption}
              currentDescription={assetDescription}
              orgApiKey={orgApiKey}
              updateDescription={(description: string, captionId: string): void =>
                dispatch({
                  type: ActionTypes.UpdateAsset,
                  payload: {
                    assetDescription: description,
                    aiGeneratedCaptionId: captionId,
                  }
                })
              }
            />
          </div>
        )}
      </div>
      <TagsInput />
    </>
  );
};

export default DetailsSection;
