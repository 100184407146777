import { FontIcon, FontIcons, HoverTooltip, TooltipLooks, TooltipPositions } from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import classes from './styles/view_only_icon.module.scss';

export const ViewOnlyIcon: FunctionComponent = () => {
  return (
    <HoverTooltip
      className={classes['bf-tooltip']}
      look={TooltipLooks.Primary}
      position={TooltipPositions.Left}
      text={t`View only`}
    >
      <FontIcon
        aria-hidden={false}
        aria-label={t`View only`}
        className={classes['view-only-circle']}
        icon={FontIcons.Show}
        iconSize={20}
      />
    </HoverTooltip>
  );
};
