export const processCards = (attachmentReservations, versionsResponse, annotationsResponse) => {
  // create version cards with associated annotations
  const versionCards = versionsResponse.history.map((version) => {
    const versionAnnotationsFlat = [...annotationsResponse.history].filter((annotation) => (
      annotation.version === version.id
    ));

    // nest child annotations under parent annotations
    const versionAnnotations = [];
    if (versionAnnotationsFlat.length) {
      versionAnnotationsFlat.forEach((annotation) => {
        if (!annotation.parent) {
          // top level (parent) annotation
          versionAnnotations.push(annotation);
        } else {
          // child annotation
          const parentIndex = versionAnnotations.findIndex((updatedAnnotation) => (
            updatedAnnotation.id === annotation.parent
          ));
          const parentAnnotationOriginal = versionAnnotationsFlat.find((parentObj) => (
            parentObj.id === annotation.parent
          ));

          if (parentIndex > -1) {
            // replace annotation with new annotation that has child(ren)
            const parentAnnotationNew = versionAnnotations.find((parentObj) => (
              parentObj.id === annotation.parent
            ));

            versionAnnotations.splice(parentIndex, 1, {
              ...parentAnnotationOriginal, // original parent annotation has no children array
              children: [...parentAnnotationNew.children || [], annotation],
            });
          } else {
            versionAnnotations.push({
              ...parentAnnotationOriginal,
              children: [annotation],
            });
          }
        }
      });
    }

    return {
      ...version,
      annotations: versionAnnotations.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))),
    };
  });

  // combine versions and reservations and sort on created_at
  let assembledCards = [...versionCards];
  if (attachmentReservations && attachmentReservations.history) {
    assembledCards = [
      ...assembledCards,
      ...attachmentReservations.history
    ];
  }
  const assembledSortedCards = assembledCards.sort((a, b) => (
    new Date(a.created_at) - new Date(b.created_at)
  ));

  let mostRecentVersion = 0;
  let versionIndex = 0;
  const allCardsWithDetails = assembledSortedCards.map((processedCard, i) => {
    if (processedCard.type === 'reservation') {
      // reservation cards
      return {
        ...processedCard,
        version_details: { ...assembledSortedCards[mostRecentVersion]?.version_details }
      };
    }

    mostRecentVersion = i;
    versionIndex += 1;
    // version cards
    return {
      ...processedCard,
      versionIndex, // for display purposes
    };
  });

  return allCardsWithDetails.reverse();
};

export const updateReservations = (newReservation, prevState, attachmentId) => {
  const updatedReservations = { ...prevState };

  const oldHistory = updatedReservations[attachmentId].history;
  const oldUsers = updatedReservations[attachmentId].users;
  const newUser = newReservation.users;
  const newStatus = newReservation.currentStatus;
  const newHistory = newReservation.history;

  updatedReservations[attachmentId].currentStatus = newStatus;
  updatedReservations[attachmentId].history = [...oldHistory, ...newHistory];
  updatedReservations[attachmentId].users = Object.assign(oldUsers, newUser);

  return updatedReservations;
};

export const updateAnnotations = (newAnnotation, prevState) => {
  const updatedAnnotations = { ...prevState };

  const oldHistory = updatedAnnotations.history;
  const oldUsers = updatedAnnotations.users;

  const newHistory = newAnnotation.history;
  const newUser = newAnnotation.users;

  updatedAnnotations.history = [...oldHistory, ...newHistory];
  updatedAnnotations.users = Object.assign(oldUsers, newUser);

  return updatedAnnotations;
};

export const updateAttachments = (newAttachment, prevState) => {
  const updatedAttachments = [...prevState];
  const attachmentIndex = updatedAttachments.findIndex((attachment) => (attachment.id === newAttachment.data.id));
  updatedAttachments.splice(attachmentIndex, 1, newAttachment.data);

  return updatedAttachments;
};

export const extractAnnotation = (annotationId, prevState) => {
  const updatedAnnotations = { ...prevState };

  // filter out annotation/comment/reply and associated children if necessary
  updatedAnnotations.history = [...prevState.history].filter((event) => (
    event.id !== annotationId && event.parent !== annotationId
  ));

  return updatedAnnotations;
};
