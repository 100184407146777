// update specific custom field value
import { fetchJson } from '@api/ApiHelper';
import { CustomFieldValue, CustomFieldValueData } from '@api/v4/assets/customFieldTypes';
import { Locale } from '@components/common/language_menu/languagesMap';

// key in this context is the uuid of the CF value entry
const mutateUrl = (customFieldValueKey): string => `/api/v4/custom_field_values/${customFieldValueKey}`;

export const updateCustomFieldValue = (
  customFieldValue: CustomFieldValue,
  ugtLocale?: Locale | ''
): Promise<CustomFieldValueData> => {
  const { key, value } = customFieldValue;
  const ugtLocaleParam = ugtLocale ? `?ugt_locale=${ugtLocale}` : '';
  const url = `${mutateUrl(key)}${ugtLocaleParam}`;
  const body = {
    data: {
      attributes: {
        value
      }
    }
  };
  return fetchJson({
    url,
    method: 'PUT',
    body
  });
};

export const deleteCustomFieldValue = (key: string, ugtLocale?: Locale | ''): Promise<null> => {
  const ugtLocaleParam = ugtLocale ? `?ugt_locale=${ugtLocale}` : '';
  const url = `${mutateUrl(key)}${ugtLocaleParam}`;
  return fetchJson({ url, method: 'DELETE' });
};
