/* eslint-disable @typescript-eslint/naming-convention */
import { CamelCasedProperties } from 'type-fest';
import { fetchJson } from '@api/ApiHelper';
import { ApiData } from '@api/v4/ApiResponseTypes';
import { CsvResourceTypes } from '@api/v4/private/resources/csv';

export interface CsvUploadFailureServer {
  actionable_key: string;
  created_at: string;
  failure_notes: string[];
  id?: string; // only on CsvUploadFailureClient when mapped
}

export type CsvUploadFailureClient = CamelCasedProperties<CsvUploadFailureServer>;

interface CsvUploadFailuresListOptions {
  csvUploadKey: string;
  page: number;
  per: number;
  resourceType: CsvResourceTypes;
}

type CsvUploadFailuresListResponse = ApiData<CsvUploadFailureServer, 'csv_upload_failures'>;

export const getCsvUploadFailures = async (options: CsvUploadFailuresListOptions): Promise<CsvUploadFailuresListResponse> => {
  const { key } = BFG.resource;
  const { csvUploadKey, page, per, resourceType } = options;

  return fetchJson<CsvUploadFailuresListResponse>({
    url: `/api/v4/private/${resourceType}s/${key}/csv/uploads/${csvUploadKey}/failures?page=${page}&per=${per}`
  });
};
