import React from 'react';
import PropTypes from 'prop-types';

import Zoom from '@components/advanced_download/zoom';
import '@components/advanced_download/styles/cropper_footer';

const CropperFooter = ({ imageToThumbnailRatio, setSetter, zoomRatio }) => (
  <section className="cropper-footer-container">
    <Zoom
      imageToThumbnailRatio={imageToThumbnailRatio}
      setSetter={setSetter}
      zoomRatio={zoomRatio}
    />
  </section>
);

CropperFooter.propTypes = {
  imageToThumbnailRatio: PropTypes.number.isRequired,
  setSetter: PropTypes.func.isRequired,
  zoomRatio: PropTypes.number.isRequired,
};

export default CropperFooter;
