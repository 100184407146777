import {
  FontWeights,
  HeadingLevels,
  OnlyForScreenReader,
  StandardHeading,
  StandardLink,
  StandardText
} from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { UserGroupsForm } from '@components/bulk_management/user_groups/UserGroupsForm';
import { UserGroupsList } from '@components/bulk_management/user_groups/UserGroupsList';
import { defaultKnowledgeBaseLinks, gettyKnowledgeBaseLinks } from '@components/kb-article-links';
import { isGettyClient } from '@helpers/getty-strings';

import classes from './styles/user-groups.module.scss';

let knowledgeBaseUrl;

if (isGettyClient()) {
  knowledgeBaseUrl = gettyKnowledgeBaseLinks.userGroupsLink;
} else {
  knowledgeBaseUrl = defaultKnowledgeBaseLinks.userGroupsLink;
}

interface UserGroupsCreateProps {
  organizationKey: string;
}

export const UserGroupsCreate: FunctionComponent<UserGroupsCreateProps> = (props) => {
  const { organizationKey } = props;

  return (
    <div className={classes.userGroups}>
      <section>
        <StandardHeading
          className={classes.title}
          level={HeadingLevels.h2}
          weight={FontWeights.Book}
        >
          <Trans>User Groups</Trans>
        </StandardHeading>
        <StandardText
          className={classes.text}
        >
          <Trans>
            User groups allow you to easily manage users in bulk. Name your group, add email addresses, and you’re all set to manage roles and access for everyone on your team.
          </Trans>
          &nbsp;
          <StandardLink
            className={classes.link}
            href={knowledgeBaseUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Trans>Get the details in our Knowledge Base.</Trans>
            <OnlyForScreenReader><Trans>Opens in a new tab.</Trans></OnlyForScreenReader>
          </StandardLink>
        </StandardText>
      </section>
      <UserGroupsForm organizationKey={organizationKey} />
      <UserGroupsList />
    </div>
  );
};
