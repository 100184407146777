import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseTabsProps } from '@components/library/tabs';
import { BaseTabs } from '../base_tabs/BaseTabs';

import styles from './styles/standard_tabs.module.scss';

/**
 * Standard tabs
 * @param {BaseTabsProps} props BaseTabsProps
 */
export const StandardTabs: FunctionComponent<BaseTabsProps> = (props) => (
  <BaseTabs
    {...props}
    showSlider={!props.showUnderline}
    tabButtonClassName={classnames(styles.standardTabButton, props.tabButtonClassName)}
    tabPanelClassName={classnames(styles.standardTabPanel, props.tabPanelClassName)}
    tabSliderClassName={classnames(styles.standardTabSlider, props.tabSliderClassName)}
  />
);
