import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes } from 'react';

import { DialogSizes } from '@components/library/dialog';

import classes from '../styles/dialog_overlay.module.scss';

interface DialogOverlayProps extends HTMLAttributes<HTMLDivElement> {
    size: DialogSizes;
}

/**
 * Dialog overlay provides the opaque dark grey color on top of the app.
 * Tabindex is intentionally set to -1 as it should never be interacted with.
 * @param {DialogOverlayProps} props DialogOverlayProps
 */
export const DialogOverlay: FunctionComponent<DialogOverlayProps> = (props) => {
  const { size, ...divProps } = props;

  return (
    <div
      {...divProps}
      className={classnames(
        "dialog--inner",
        classes["dialog--overlay"],
        classes[`dialog--overlay__${size}`],
        divProps.className
      )}
      role="presentation"
      tabIndex={-1}
    />
  );
};
