import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, HTMLAttributes, } from 'react';

import classes from './styles/press_release_message.module.scss';

interface PressReleaseMessageProps extends HTMLAttributes<HTMLParagraphElement> {
  showIcon?: boolean;
}

export const PressReleaseMessage: FunctionComponent<PressReleaseMessageProps> = ({
  children = null, className, showIcon = true, ...otherProps
}) => {
  return (
    <p {...otherProps} className={classnames(classes.message, className)}>
      {showIcon && (
        <span className={classnames('bff-warning', classes.messageIcon)} />
      )}
      <span>{children || <Trans>Something went wrong. Try again.</Trans>}</span>
    </p>
  );
};
