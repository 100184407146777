import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { CircleStatusProps } from '@components/library/status';
import { BaseStatus } from '../base';

import classes from './circle-status.module.scss';

export const CircleStatus: FunctionComponent<CircleStatusProps> = (props) => (
  <BaseStatus
    {...props}
    className={classnames(
      classes.circle,
      classes[props.status],
      props.className
    )}
    variantName="circle"
  />
);
