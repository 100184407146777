import {
  FontIcons,
  ListboxOptionValue,
  StandardCombobox,
  brandfolderColorPalette,
} from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { filterByOptions, sortByOptions } from '../constants';

const createOptionMap = (options: { key: string; label: string }[]): Record<string, string> =>
  options.reduce((obj, item) => (obj[item.key] = item.label, obj), {});

const PeopleTagsDropdownWrapper = styled(StandardCombobox)`
  .bf-combobox {
    &[aria-expanded='true'],
    &:active,
    &:focus-visible {
      background-color: transparent;
      border: 2px solid ${brandfolderColorPalette.primary[600]};
    }  
  }

  .bff-button__icon {
    &:hover {
      background-color: transparent;
    }
  }

  .bf-list {
    max-height: unset;
    
    .bf-listitem {
      border-radius: 4px;
      color: ${brandfolderColorPalette.neutrals[700]};

      &[aria-selected='true'] {
        background-color: ${brandfolderColorPalette.neutrals[100]};
      }
    }
  }
`;

type DropdownType = 'sort' | 'filter';

interface DropdownOptions {
  listOptions: { key: string; label: string }[];
  optionsMap: { [key: string]: string };
  icon: FontIcons;
}

interface PeopleTagsDropdownProps {
  type: DropdownType;
  selectedValue: ListboxOptionValue;
  setSelectedValue: (value: ListboxOptionValue) => void;
}

export const PeopleTagsDropdown: FunctionComponent<PeopleTagsDropdownProps> = ({
  type,
  selectedValue,
  setSelectedValue,
}) => {
  const getDropdownValues = (): DropdownOptions => {
    switch (type) {
      case 'sort':
        return {
          listOptions: sortByOptions(),
          optionsMap: createOptionMap(sortByOptions()),
          icon: FontIcons.ArrowUpDown,
        };
      case 'filter':
        return {
          listOptions: filterByOptions(),
          optionsMap: createOptionMap(filterByOptions()),
          icon: FontIcons.Filters,
        };
    }
  };

  const dropdown = getDropdownValues();
  const selectedOptionIndex = dropdown.listOptions.findIndex(({ label }) => {
    return label === dropdown.optionsMap[selectedValue];
  });

  return (
    <PeopleTagsDropdownWrapper
      className={`${type}-people-dropdown`}
      defaultValue={dropdown.optionsMap[selectedValue]}
      id={`${type}-dropdown`}
      initialSelectedIndex={selectedOptionIndex}
      labels={{
        listboxLabel: t`List of ${type} options`,
        iconButtonLabel: t`Toggle list of ${type} options`,
        label: t`${type} options`,
      }}
      leftIcon={dropdown.icon}
      onSelection={({ key }): void => setSelectedValue(key)}
      options={dropdown.listOptions.map(({ key, label }) => ({
        children: <>{label}</>,
        key,
        value: label,
      }))}
      showEmptyOption
      showLabel={false}
      showPlaceholderOption={false}
    />
  );
};
