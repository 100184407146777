import classnames from 'classnames';
import React, { FunctionComponent, ReactNode, TdHTMLAttributes } from 'react';

interface BaseTdProps extends TdHTMLAttributes<HTMLTableCellElement> {
  children: ReactNode;
}

export const BaseTd: FunctionComponent<BaseTdProps> = (props) => {
  const { children, className, ...otherProps } = props;
  return (
    <td {...otherProps} className={classnames('baseTd', className)}>{children}</td>
  );
};
