/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, FunctionComponent } from 'react';

import { Notification } from '@api/v4/NotificationTypes';
import { SecondaryButton } from '@components/library/button';

import classes from './styles/notifications.module.scss';

const NOTIFICATION_ICON_CDN_URLS = {
  access_request: 'https://cdn.bfldr.com/27C9EC93/at/452n5ptwt43bvnscjgpqvx/access-request.svg?auto=webp&format=svg',
  approval: 'https://cdn.bfldr.com/27C9EC93/at/rncfx428bn8z3bbgqg8p2f7v/approval.svg?auto=webp&format=svg',
  asset_deletion: 'https://cdn.bfldr.com/27C9EC93/at/ckjzqbscm8vswm6v2ttn9j5/asset_deletion.svg?auto=webp&format=svg',
  asset_expiration: 'https://cdn.bfldr.com/27C9EC93/at/b8ngq8655fqqx3xrj838pg/asset_expiration.svg?auto=webp&format=svg',
  asset_publish: 'https://cdn.bfldr.com/27C9EC93/at/3m9qfkjwf7t95fn3r37g3tm/asset_publish.svg?auto=webp&format=svg',
  asset_removal: 'https://cdn.bfldr.com/27C9EC93/at/6gjm8v3zc4nrvnfkn3pbwm/asset_removal.svg?auto=webp&format=svg',
  asset_upload: 'https://cdn.bfldr.com/27C9EC93/at/kmfw6w6g4wrv8vrs67k4bspp/asset_upload.svg?auto=webp&format=svg',
  asset_will_expire: 'https://cdn.bfldr.com/27C9EC93/at/5c4qt9t3jbwh966cg3c53jmx/asset_will_expire.svg?auto=webp&format=svg',
  attachment_duplication: 'https://cdn.bfldr.com/27C9EC93/at/m5xk5mx3vj8tvfjrw53fpr/attachment_duplication.svg?auto=webp&format=svg',
  collection_add_asset: 'https://cdn.bfldr.com/27C9EC93/at/kmfw6w6g4wrv8vrs67k4bspp/asset_upload.svg?auto=webp&format=svg',
  comment: 'https://cdn.bfldr.com/27C9EC93/at/hfr79fq3rxg4c6vbhpfncqg/comment.svg?auto=webp&format=svg',
  download_request: 'https://cdn.bfldr.com/27C9EC93/at/vrwj55f6k2zjrk9qtbbwzk3/download_request.svg?auto=webp&format=svg',
  generic: 'https://cdn.bfldr.com/27C9EC93/at/gvvvvt9ffwrwfvq8tvvc658/generic.svg?auto=webp&format=svg',
  guest_password_change: 'https://cdn.bfldr.com/27C9EC93/at/9bpp8c9hm89b39cm739tf3s/guest_password_change.svg?auto=webp&format=svg',
  invite_accepted: 'https://cdn.bfldr.com/27C9EC93/at/jspfbb7gs78zvcwb36v8pjvp/invite_accepted.svg?auto=webp&format=svg',
  message: 'https://cdn.bfldr.com/27C9EC93/at/5mfgsqs5pj9bgcnhx8x8jz/message.svg?auto=webp&format=svg',
  privacy_change: 'https://cdn.bfldr.com/27C9EC93/at/6n9s27svsb5nfnxkggnkph8/privacy_change.svg?auto=webp&format=svg',
  section_deletion: 'https://cdn.bfldr.com/27C9EC93/at/vzqzxxfkbv3f6rkpjn3rkcnh/section_deletion.svg?auto=webp&format=svg',
  share_link_asset_downloaded: 'https://cdn.bfldr.com/27C9EC93/at/9tr4x8nhrsb48wtjtxhm6c3w/share_link_asset_downloaded.svg?auto=webp&format=svg',
  share_link_asset_viewed: 'https://cdn.bfldr.com/27C9EC93/at/pqbxkhfmctmfwnq4tpk5cc5j/share_link_asset_viewed.svg?auto=webp&format=svg',
  share_link_viewed: 'https://cdn.bfldr.com/27C9EC93/at/v4n8wzrsc7mj64fnm3mc4bvv/share_link_viewed.svg?auto=webp&format=svg',
  template_approval: 'https://cdn.bfldr.com/27C9EC93/at/rncfx428bn8z3bbgqg8p2f7v/approval.svg?auto=webp&format=svg',
  user_groups: 'https://cdn.bfldr.com/27C9EC93/at/7kmpqz7bckktkgmb82n3np/notification-user-group.svg?auto=webp&format=png'
};

export const NotificationCard: FunctionComponent<Notification> = ({
  body,
  btn_link: btnLink,
  btn_text: btnText,
  btn_type: btnType,
  id,
  image_source: imageSource,
  notification_type,
  timestamp,
  title,
}) => {
  const [imageError, setImageError] = useState(false);

  return (
    <li key={id} className={classes.cardContainer}>
      <div className={classes.notifCard}>
        <div className={classes.notifLeft}>
          <div className={classes.notifImgContainer}>
            {imageSource && !imageError && (
              <img alt="" onError={(): void => setImageError(true)} src={imageSource} />
            )}
            {(!imageSource || imageError) && (
              <img alt="" className={classes.notifIcon} src={NOTIFICATION_ICON_CDN_URLS[notification_type] || NOTIFICATION_ICON_CDN_URLS.generic} />
            )}
          </div>
          <div className={classes.notifTextContainer}>
            <div className={classes.notifTextContent}>
              <h3 className={classes.notifTitle}>
                <img alt="" className={classes.mobileIcon} src={NOTIFICATION_ICON_CDN_URLS[notification_type] || NOTIFICATION_ICON_CDN_URLS.generic} />
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </h3>
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: body }} className={classes.notifCopy} />
            </div>
            <div className={classes.notifTimestampContainer}>
              <span aria-hidden className="bff-calendar" />
              <span className={classes.notifTimestamp}>{timestamp}</span>
            </div>
          </div>
        </div>
        {btnLink && btnText && btnType && (
          <div className={classes.notifRight}>
            { /* Notifications are stored with a btn_type but we're always going to display as a secondary button now */ }
            { /* I don't love using a button to open a link but this is matching existing functionality */ }
            <SecondaryButton
              onClick={(): void => {
                // TODO: remove this temporary code exception that makes "Uploaded CSV" work again
                // 1. fix where we are saving http://storage.googleapis.com to SQL (instead of https://storage.googleapis.com)
                // 2. run a SQL script to update existing saved http://storage.googleapis.com to https://storage.googleapis.com
                if (btnLink.includes('http://storage.googleapis.com')) {
                  const parts = btnLink.split('http://storage.googleapis.com');
                  const link = `https://storage.googleapis.com${parts[1]}`;
                  window.open(link, '_blank');
                } else {
                  window.open(btnLink, '_blank');
                }
              }}
              size="medium"
            >
              {btnText}
            </SecondaryButton>
          </div>
        )}
      </div>
    </li>
  );
};
