import { CustomFieldKeyRow } from '@components/bulk_management/custom_fields/CustomFieldKeyRow';

/**
 * Sorts custom field key rows by preferred order (position).
 *
 * @param keys The custom field key rows to sort.
 * @param visibleKeys Full collection of custom field key rows sorted by priority, null positions will use the index position in visibleKeys. Defaults to keys if not set.
 * @returns Array of sorted custom field key rows by preferred order.
 */
export const sortedByPreferredOrder = (keys: CustomFieldKeyRow[], visibleKeys?: CustomFieldKeyRow[]): CustomFieldKeyRow[] => {
  if (!visibleKeys) {
    visibleKeys = keys;
  }
  return keys.sort((a, b) => {
    const aPosition = (a.position != null) ? a.position : visibleKeys.indexOf(a);
    const bPosition = (b.position != null) ? b.position : visibleKeys.indexOf(b);
    return aPosition - bPosition;
  });
};

/**
 * Sorts custom field key rows by priority.
 *
 * @param keys The custom field key rows to sort.
 * @returns Array of sorted custom field key rows by priority.
 */
export const sortedByPriority = (keys: CustomFieldKeyRow[]): CustomFieldKeyRow[] => {
  return keys.sort((a, b) => {
    if (a.prioritized === b.prioritized) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }
    if (a.prioritized && !b.prioritized) {
      return -1;
    }
    return 1;
  });
};

/**
 * Sorts custom field key rows by name.
 *
 * @param keys The custom field key rows to sort.
 * @returns Array of sorted custom field key rows by name.
 */
export const sortedByName = (keys: CustomFieldKeyRow[]): CustomFieldKeyRow[] => {
  return keys.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};

/**
 * Sorts custom field key rows by asset count.
 *
 * @param keys The custom field key rows to sort.
 * @returns Array of sorted custom field key rows by asset count.
 */
export const sortedByAssetCount = (keys: CustomFieldKeyRow[]): CustomFieldKeyRow[] => {
  return keys.sort((a, b) => b.counted_assets - a.counted_assets);
};

/**
 * Sorts custom field key rows by value count.
 *
 * @param keys The custom field key rows to sort.
 * @returns Array of sorted custom field key rows by value count.
 */
export const sortedByValueCount = (keys: CustomFieldKeyRow[]): CustomFieldKeyRow[] => {
  return keys.sort((a, b) => b.uniq_values - a.uniq_values);
};
