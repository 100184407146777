import * as React from 'react';

interface AssetBoardCardProps {
  children: React.ReactNode;
  className: string;
}
// simple grid setup view
const AssetBoardCard: React.FunctionComponent<AssetBoardCardProps> = ({ children, className }): JSX.Element => (
  <div className={`asset-board-card ${className}`}>
    { children }
  </div>
);

export default AssetBoardCard;
