import React from 'react';
import './styles/select_list_dropdown.scss';

export default class SelectListDropdown extends React.Component {
  state = {
    open: false,
    selectedValue: null
  };

  updateSelected(value) {
    this.setState({ selectedValue: value });
    this.props.onSelect(value);
    this.toggleOpenClose();
  }

  toggleOpenClose() {
    this.setState((prevState) => ({
      open: !prevState.open
    }));
  }

  renderOptionsList() {
    return this.props.list.map((format, key) => {
      // TODO: move itemClass out of the generic dropdown component
      const itemClass = (format === 'animated gif' || format === 'mp3/audio' ? 'border-top' : '');
      return (
        <li
          key={key}
          onClick={(event) => this.updateSelected(event.target.innerHTML)}
          className={this.state.selectedValue === format ? `${itemClass} selected` : itemClass}
        >
          {format}
        </li>
      );
    });
  }

  render() {
    const chevronStlye = `float-right ${this.state.open ? 'bff-caret-up' : 'bff-caret-down'}`;
    const wrapClass = `select-container ${this.props.scroll ? 'scroll' : 'no-scroll'}`;
    return (
      <div className="select-list-dropdown-component">
        <div className="select-dropdown-wrap full">
          <div className="select-dropdown-label">
            <label className="bf-label bf-label--primary">{this.props.dropdownLabel}</label>
          </div>
          <div className={wrapClass}>
            <div className="open-close-button" onClick={this.toggleOpenClose.bind(this)}>
              <div className="default-value float-left">
                {this.state.selectedValue || this.props.defaultValue}
              </div>
              <span className={chevronStlye} />
            </div>
            <ul className={this.state.open ? 'dropdown-options open' : 'dropdown-options'}>
              {this.renderOptionsList()}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
