import { PrivacyBadge, Privacy } from '@brandfolder/react';
import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

interface ResourcePrivacyBadgeProps {
  privacy: string;
}

export const ResourcePrivacyBadge: FunctionComponent<ResourcePrivacyBadgeProps> = ({ privacy }) => (
  <>
    {privacy === 'private' && (
      <PrivacyBadge privacyLevel={Privacy.Private} showIcon showText={false}>
        <Trans>Private</Trans>
      </PrivacyBadge>
    )}
    {privacy === 'stealth' && (
      <PrivacyBadge privacyLevel={Privacy.Stealth} showIcon showText={false}>
        <Trans>Stealth</Trans>
      </PrivacyBadge>
    )}
    {privacy === 'public' && (
      <PrivacyBadge privacyLevel={Privacy.Public} showIcon showText={false}>
        <Trans>Public</Trans>
      </PrivacyBadge>
    )}
  </>
);
