import { Trans } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";

import BoulderIFrame from "./boulder_iframe";
import FontInput from "./font_input";

class TextSelector extends React.Component {
  state = {
    value: this.props.frame.value,
    fontSize: null,
    renderIFrame: false,
  }

  componentDidMount() {
    this.setValue(); // Respect pagination updates
  }

  setValue = () => {
    const { textFrameData, frame } = this.props;
    if (textFrameData[frame.id] && textFrameData[frame.id].value) {
      this.setState({ value: textFrameData[frame.id].value });
    }
  }

  triggerFrameUpdate = () => {
    const { frame, updateFrameData } = this.props;
    const { value, fontSize } = this.state;
    updateFrameData({ [frame.id]: { value, font_size: fontSize } });
  }

  handleValueChange = ({ description }) => {
    const fauxDiv = document.createElement('div');
    fauxDiv.innerHTML = description;
    const descriptionText = fauxDiv.innerText;
    this.setState({ value: descriptionText, renderIFrame: false }, () => {
      this.triggerFrameUpdate();
    });
  }

  updateFontSize = (fontSize) => {
    this.setState({ fontSize: parseInt(fontSize, 10) }, () => {
      this.triggerFrameUpdate();
    });
  }

  maxLine = (lines) => lines.reduce((a, b) => ((b.length > a.length) ? b : a));

  renderText() {
    const { frame, fontSettings, templateSettings } = this.props;
    const { value } = this.state;
    const lines = value.replace(/\r\n/g, "\n").replace(/\r/g, "").split(/\n/g); // split lines
    const cols = this.maxLine(lines).length;
    const lineCount = (lines.length > 1) ? lines.length : 1;
    return (
      <div className="text-selector-container">
        <div className="text-area-display">
          <div className="input-headers">
            <label className="bf-label bf-label--primary">
              {frame.name === "$ID/" ? "" : frame.name}
            </label>
            <FontInput
              fontSettings={fontSettings}
              frame={frame}
              templateSettings={templateSettings}
              updateFontSize={this.updateFontSize}
            />
          </div>
          <textarea
            cols={cols}
            readOnly="readOnly"
            rows={lineCount}
            style={{ height: "auto", resize: "none" }}
            value={value}
          />
        </div>
        <div className="embed-text">
          <button
            className="embed-printui-text button secondary lg"
            onClick={() => this.setState({ renderIFrame: true })}
            type="button"
          >
            <Trans>Select from Brandfolder</Trans>
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { frame, orgSlug, printuiInput } = this.props;
    const { renderIFrame } = this.state;
    return (
      <React.Fragment>
        { renderIFrame ? (
          <BoulderIFrame
            frame={frame}
            orgSlug={orgSlug}
            printuiInput={printuiInput}
            updateTextSelector={this.handleValueChange}
          />
        )
          : this.renderText()
        }
      </React.Fragment>
    );
  }
}

TextSelector.propTypes = {
  frame: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
  }).isRequired,
  printuiInput: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    field_identifier: PropTypes.string,
    options: PropTypes.shape({
      custom_settings: PropTypes.shape({
        type: PropTypes.string
      })
    })
  }),
  validations: PropTypes.shape({
    inputType: PropTypes.string
  }).isRequired,
  updateFrameData: PropTypes.func.isRequired,
  orgSlug: PropTypes.string.isRequired,
  fontSettings: PropTypes.shape({}).isRequired,
  templateSettings: PropTypes.shape({
    allowFontSizing: PropTypes.bool
  }).isRequired,
  textFrameData: PropTypes.shape({}),
};

TextSelector.defaultProps = {
  printuiInput: null,
  textFrameData: {}
};

export default TextSelector;
