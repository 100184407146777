/* eslint-disable react/require-default-props */
import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '@components/common/checkbox/main';

import AccordionSection from './accordionSection';

const CommentsFilter = ({ checked, updateFilters }) => (
  <AccordionSection title={t`Comments`}>
    <div className="comments-section">
      <Checkbox
        checked={checked}
        label={t`Only show assets with comments`}
        size="sm"
        toggle={() => updateFilters(!checked)}
      />
    </div>
  </AccordionSection>
);

CommentsFilter.propTypes = {
  checked: PropTypes.bool,
  updateFilters: PropTypes.func.isRequired,
};

CommentsFilter.defaultProps = {
  checked: false,
};

export default CommentsFilter;
