import { StandardButton, ButtonLooks, ButtonSizes } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';

import { CustomFieldKeyCount } from '@components/bulk_management/custom_fields/CustomFieldKeyCount';
import { StandardTextField } from '@components/library/text-field';
import { containsSpecialCharacters } from '@helpers/custom-field-validation';

import { formValidationErrorTitle, nameHasSpecialCharactersError, nameNotChangedError } from './constants';

export interface CustomFieldsRowEditProps extends Pick<CustomFieldKeyCount, 'name'> {
  loading: boolean;
  onCancel: () => void;
  onSubmit: (newName: string) => void;
}

export const CustomFieldsRowEdit: FunctionComponent<CustomFieldsRowEditProps> = ({
  loading,
  name,
  onCancel,
  onSubmit,
}) => {
  const [newName, setNewName] = useState(name);

  const handleChange = (event: InputChangeEvent): void => {
    setNewName(event.currentTarget.value);
  };

  const handleCancel = (): void => {
    onCancel();
  };

  const handleSubmit = (): void => {
    if (newName && name !== newName) {
      if (!containsSpecialCharacters(newName)) {
        onSubmit(newName);
      } else {
        Notify.create({
          title: formValidationErrorTitle(),
          body: nameHasSpecialCharactersError(),
          type: 'error'
        });
      }
    } else {
      Notify.create({
        title: nameNotChangedError(),
        type: 'error',
      });
    }
  };

  return (
    <>
      <div className="edit-custom-fields-mode">
        &nbsp; {/* Placeholder for spacing */}
      </div>
      <div className="edit-custom-fields-mode overlay">
        <StandardTextField
          className="bf-input bf-input--primary"
          id={`${name}-input`}
          label={t`Key`}
          onChange={handleChange}
          showError={false}
          showLabel={false}
          value={newName}
        />
        <div className="edit-buttons">
          {!loading && (
            <StandardButton
              className="button tertiary md cancel"
              look={ButtonLooks.Tertiary}
              onClick={handleCancel}
              type="button"
            >
              <Trans>Cancel</Trans>
            </StandardButton>
          )}
          <StandardButton
            disabled={loading}
            loading={loading}
            onClick={handleSubmit}
            size={ButtonSizes.Medium}
          >
            <Trans>Rename key</Trans>
          </StandardButton>
        </div>
      </div>
    </>
  );
};
