import {
  StandardAlert,
  StandardTable,
  StandardTableColumn,
  StandardTableRow,
  FontIcons,
  AlertLooks
} from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { SftpIngestSourceListResponse, GcsIngestSourceType } from '@api/v4/gcs-ingest-sources';
import { defaultPagination, mapMetaToPagination, Pagination } from '@components/bulk_management/shared';
import { TextButton, TextWarningButton, DangerButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';
import { isGettyClient } from '@helpers/getty-strings';

export const SftpIngestTable: FunctionComponent<{ setShowForm: SetStateDispatch<boolean> }> = ({ setShowForm }) => {
  const [ingestSourceDeleteKey, setIngestSourceDeleteKey] = useState<string | undefined>();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [pagination, setPagination] = useState(defaultPagination);

  const sftpIngestFetch = useFetch<SftpIngestSourceListResponse>({
    url: `/api/v4/brandfolders/${BFG.resource.key}/gcs_ingest_sources`,
    params: {
      page: pagination.currentPage,
      per: pagination.per,
      type: GcsIngestSourceType.SFTP,
    },
    fields: 'rootdir',
    include: ['collection', 'section']
  });

  const ingestSourceDelete = useFetch({
    url: `/api/v4/gcs_ingest_sources/${ingestSourceDeleteKey}`,
    fetchOnMount: false,
    method: 'DELETE'
  });

  useEffect(() => {
    if (ingestSourceDeleteKey) {
      setDeleteConfirmationOpen(true);
    }
  }, [ingestSourceDeleteKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ingestSourceDelete.response) {
      setIngestSourceDeleteKey(undefined);
      setDeleteConfirmationOpen(false);
      sftpIngestFetch.fetch();
    }
  }, [ingestSourceDelete.response]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pagination.currentPage && pagination.per) {
      sftpIngestFetch.fetch();
    }
  }, [pagination.currentPage, pagination.per]); // eslint-disable-line react-hooks/exhaustive-deps

  const gettyClient = isGettyClient();

  const columns: StandardTableColumn[] = [{
    children: <Trans>SFTP Name</Trans>,
    heading: <Trans>SFTP Name</Trans>,
    rowKey: 'rootdir'
  }, {
    children: <Trans>Add to Section</Trans>,
    heading: <Trans>Add to Section</Trans>,
    rowKey: 'section',
    width: gettyClient ? '20%' : '30%'
  }, {
    children: <Trans>Also add to Collection</Trans>,
    heading: <Trans>Also add to Collection</Trans>,
    rowKey: 'collection',
    width: gettyClient ? '20%' : '30%'
  }, {
    heading: '',
    rowKey: 'delete',
    width: '10%'
  }];

  const deleteConfirmationDialog = (
    <StandardDialog
      dialogBodyClassName="ingest-source-delete-confirmation-dialog"
      dialogFooterClassName="ingest-source-delete-confirmation-dialog__footer"
      footer={
        <DangerButton
          isLoading={ingestSourceDelete.loading}
          onClick={(): void => {
            ingestSourceDelete.fetch();
          }}
        >
          <Trans>Delete SFTP Ingest</Trans>
        </DangerButton>
      }
      id="ingest-source-delete-confirmation-dialog"
      open={deleteConfirmationOpen}
      setOpen={setDeleteConfirmationOpen}
      showFooter={true}
      title={t`Delete SFTP Ingest`}
    >
      <p><Trans>Are you sure you want to delete this SFTP ingest? This action cannot be undone.</Trans></p>
    </StandardDialog>
  );

  const rows: StandardTableRow[] = sftpIngestFetch.response ? sftpIngestFetch.response.data.map((ingestSource) => ({
    rootdir: ingestSource.attributes.rootdir,
    section: sftpIngestFetch.response.included.find((section) => (
      section.type === 'sections' && section.id === ingestSource.relationships?.section?.data?.id
    ))?.attributes.name,
    collection: sftpIngestFetch.response.included.find((collection) => (
      collection.type === 'collections' && collection.id === ingestSource.relationships?.collection?.data?.id
    ))?.attributes.name || '-',
    delete: (
      <TextWarningButton
        aria-label={t`Delete SFTP ingest`}
        className="sftp-delete-button"
        icon={FontIcons.Trash}
        onClick={(): void => {
          setDeleteConfirmationOpen(true);
          setIngestSourceDeleteKey(ingestSource.id);
        }}
      />
    )
  })) : [];

  return (
    <>
      {sftpIngestFetch.response && deleteConfirmationDialog}
      {ingestSourceDelete.error && (
        <StandardAlert
          heading={t`Oops!`}
          look={AlertLooks.Error}
          showHeading
          showIcon
        >
          <Trans>Something went wrong deleting this ingest source. Ensure the ingest bucket is empty before attempting to delete.</Trans>
        </StandardAlert>
      )}
      <StandardTable
        caption={t`SFTP ingest source details`}
        columns={columns}
        empty={rows.length === 0 && !sftpIngestFetch.loading && !sftpIngestFetch.error}
        emptyContent={(
          <div>
            <p><Trans>You haven't added anything yet.</Trans></p>
            <TextButton onClick={(): void => setShowForm(true)}>
              <Trans>Create a new SFTP ingest</Trans>
            </TextButton>
          </div>
        )}
        error={!!sftpIngestFetch.error}
        errorContent={(
          <div>
            <p><Trans>Oops! Something went wrong.</Trans></p>
            <TextButton onClick={(): void => { sftpIngestFetch.fetch(); }}>
              <Trans>Try again</Trans>
            </TextButton>
          </div>
        )}
        id="sftp-ingest-source-table"
        loading={sftpIngestFetch.loading}
        rows={rows}
      />
      {rows.length > 0 && !sftpIngestFetch.error && (
        <Pagination
          {...mapMetaToPagination(sftpIngestFetch.response?.meta, pagination.per)}
          onPageChange={(currentPage): void => {
            setPagination({ ...pagination, currentPage });
          }}
          onPerChange={(per): void => {
            setPagination({ ...pagination, per });
          }}
        />
      )}
    </>
  );
};
