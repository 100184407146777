/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!! README !!!!!!!!!!!!!!!!!!
 * If you make changes to this file or any other
 * lazy rendering-related file, please be sure
 * to manually test the bulk tags display with
 * enough tags to trigger multiple pages.
 * That means > 250 tags or temporarily change
 * the page size to test that multiple pages are
 * rendered correctly.
 *
 * You also may want to run the lazy_render_spec
 * locally and ensure that it passes.
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
import * as React from 'react';

interface RowShroudProps {
  onShown: () => void;
  pageSize: number | undefined;
  rowHeight: number | undefined;
}

const RowShroud = (props: RowShroudProps): JSX.Element => {
  const { onShown, pageSize, rowHeight } = props;

  const container = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => { // eslint-disable-line consistent-return
    if (container && container.current) {
      const observer = new IntersectionObserver((entries) => {
        const isIntersecting = entries
          .reduce((initial, entry) => initial || entry.isIntersecting, false);
        if (isIntersecting) {
          onShown();
        }
      });

      observer.observe(container.current);
      return (): void => {
        observer.disconnect();
      };
    }
  }, [container, props, onShown]);

  const height = pageSize !== undefined && rowHeight !== undefined ? rowHeight * pageSize : undefined;

  return <div ref={container} className="row-shroud" style={{ height }} />;
};

export default RowShroud;
