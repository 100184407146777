import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { isGettyClient } from '@helpers/getty-strings';

import InviteUsersStage1 from './invite_users_stage_1';
import InviteUsersStage2 from './invite_users_stage_2';
import InviteUsersStage3 from './invite_users_stage_3';

export default class InviteUsers extends Component {
  state = {
    emailsArray: [],
    expandedBrandfolders: {},
    initialResourceKey: this.props.currentResourceKey,
    initialResourceType: this.props.currentResourceType,
    resources: {},
    selectedPermissionLevel: 'Guest',
    selectedResources: {},
    stage: 1,
    submitted: false
  }

  componentDidMount() {
    this.getResources();
    this.updateStylingClass('add');
  }

  componentWillUnmount() {
    this.updateStylingClass('remove');
  }

  updateStylingClass(updateType) {
    const dialog = document.getElementById('dialog');

    // 'user-permissions-modal' class used to flag React component for unmounting when closed
    updateType === 'add'
      ? dialog.classList.add('user-permissions-modal')
      : dialog.classList.remove('user-permissions-modal');
  }

  updateInviteState = (obj) => {
    Object.keys(obj).forEach((key) => this.setState({ [key]: obj[key]}));
  }

  getResources() {
    const {currentOrganizationKey, currentUser} = this.props;

    fetch(`/api/v3/users/${currentUser.user_key}/permissible_resources?permission_level=admin&organization_key=${currentOrganizationKey}`, {
      method: 'GET',
      headers: { 'Authorization' : `Bearer ${BF_Token}` },
    })
    .then((response) => response.json())
    .then((response) => this.processResourceData(response))
  }

  processResourceData(resources) {
    const {currentResourceKey, currentOrganizationKey} = this.props;
    const {brandfolders, collections} = resources.data;
    const resourceTreeData = {};

    if (!brandfolders.length && !collections.length) {
      return
    }

    brandfolders.forEach((brandfolder) => {
      resourceTreeData[brandfolder.key] = {type: 'brandfolder', name: brandfolder.name, permitted: true};
    })

    collections.forEach((collection) => {
      if (resourceTreeData[collection.parent_key] && resourceTreeData[collection.parent_key].collections) {
        Object.assign(resourceTreeData[collection.parent_key].collections, {[collection.key]: this.buildResourceObject('collection', collection.name, true)})
      } else if (resourceTreeData[collection.parent_key]) {
        resourceTreeData[collection.parent_key].collections = {[collection.key]: this.buildResourceObject('collection', collection.name, true)};
      } else {
        // user has access to collection but not parent brandfolder
        resourceTreeData[collection.parent_key] = this.buildResourceObject('brandfolder', collection.parent_name, false);
        resourceTreeData[collection.parent_key].collections = {[collection.key]: this.buildResourceObject('collection', collection.name, true)};
      }
    })

    this.setState({resources: resourceTreeData}, () => {
      if (currentResourceKey !== currentOrganizationKey) { // skip if user is on org page
        this.toggleResource(currentResourceKey);
        this.toggleTreeExpand(currentResourceKey);
      }
    });
  }

  buildResourceObject(type, name, permitted) {
    return {type, name, permitted};
  }

  buildSelectedResourceObject(type, name, parent_key = null) {
    return {type, name, parent_key};
  }

  cancelInvite = () => {
    const {initialResourceKey, initialResourceType} = this.state;
    const resourceInfo = {initialResourceKey, initialResourceType};

    ReactRailsUJS.unmountComponents('#dialogBody');
    BF.dialog.setup();
    BF.UserPermission.show(null, resourceInfo);
  }

  toggleResource = (clickedKey) => {
    const {resources} = this.state;
    let   {selectedResources} = this.state;

    selectedResources = Object.assign(selectedResources, {});

    // remove or add clicked resource to selectedResources
    if (selectedResources[clickedKey]) {
      delete selectedResources[clickedKey]; // deselect resource key
    } else {
      if (resources[clickedKey] && resources[clickedKey].type === 'brandfolder') {
        this.toggleBrandfolder(clickedKey, resources[clickedKey]);
      } else {
        this.toggleCollection(clickedKey, resources);
      }
    }

    this.setState({selectedResources});
  }

  toggleBrandfolder (clickedKey, brandfolder) {
    const collections = brandfolder.collections
    let {selectedResources} = this.state;

    if (collections) {
      this.toggleAllCollections(collections, clickedKey, 'deselectAll');
    }
    // add brandfolder key
    selectedResources[clickedKey] = this.buildSelectedResourceObject('brandfolder', brandfolder.name);
    this.setState({selectedResources});
  }

  toggleCollection (clickedKey, resources) {
    let {selectedResources} = this.state;

    Object.keys(resources).forEach((brandfolderKey) => {
      if (resources[brandfolderKey].collections) {
        const collections = resources[brandfolderKey].collections;

        Object.keys(collections).forEach((collectionKey) => {
          if (clickedKey === collectionKey) {
            // add collection key
            selectedResources[clickedKey] = this.buildSelectedResourceObject('collection', collections[clickedKey].name, brandfolderKey);
          }
        })
      }
    })

    this.setState({selectedResources});
  }

  toggleAllCollections = (collections, parentKey, toggleType) => {
    let {selectedResources} = this.state;

    Object.keys(collections).forEach((collectionKey) => {
      delete selectedResources[collectionKey];
      if (toggleType === 'selectAll') {
        selectedResources[collectionKey] = this.buildSelectedResourceObject('collection', collections[collectionKey].name, parentKey);
      }
    });

    this.setState({selectedResources});
  }

  toggleTreeExpand = (clickedResource) => {
    const {resources, selectedResources, updateInviteState} = this.state;
    let   {expandedBrandfolders} = this.state;
    expandedBrandfolders = Object.assign(expandedBrandfolders, {});

    if (!resources[clickedResource]) { // expand collection's parent brandfolder
      clickedResource = selectedResources[clickedResource] && selectedResources[clickedResource].parent_key;
    }

    if (!clickedResource) {
      return;
    }

    if (expandedBrandfolders[clickedResource]) {
      delete expandedBrandfolders[clickedResource]
    } else {
      expandedBrandfolders[clickedResource] = true
    }
    this.setState({ expandedBrandfolders });
  }

  navClass(navSection) {
    return navSection === this.state.stage ? 'active' : '';
  }

  render() {
    const {
      emailsArray,
      expandedBrandfolders,
      resources,
      selectedPermissionLevel,
      selectedResources,
      stage,
      submitted
    } = this.state;
    const { libraryName } = this.props;

    return (
      <div className="invite-users-modal">
        <nav className="nav">
          <div className={`nav-users nav-section ${this.navClass(1)}`}
                onClick={() => this.updateInviteState({stage: 1})}>
            <h5 className='nav-text'>
              <Trans>
                <span>01</span>Select Users
              </Trans>
            </h5>
          </div>
          <div  className={`nav-resources nav-section ${this.navClass(2)}`}
                onClick={() => this.updateInviteState({stage: 2})}>
            <h5 className='nav-text'>
              <Trans>
                <span>02</span>
                {isGettyClient() ? 'Select Libraries or Collections' : 'Select Brandfolders or Collections'}
              </Trans>
            </h5>
          </div>
          <div  className={`nav-review nav-section ${this.navClass(3)}`}
                onClick={() => this.updateInviteState({stage: 3})}>
            <h5 className="nav-text">
              <Trans>
                <span>03</span>Review
              </Trans>
            </h5>
          </div>
        </nav>
        <InviteUsersStage1
          cancelInvite={this.cancelInvite}
          emailsArray={emailsArray}
          libraryName={libraryName}
          stage={stage}
          submitted={submitted}
          updateInviteState={this.updateInviteState}
        />
        <InviteUsersStage2
          emailsArray={emailsArray}
          expandedBrandfolders={expandedBrandfolders}
          libraryName={libraryName}
          resources={resources}
          selectedPermissionLevel={selectedPermissionLevel}
          selectedResources={selectedResources}
          stage={stage}
          submitted={submitted}
          toggleAllCollections={this.toggleAllCollections}
          toggleResource={this.toggleResource}
          toggleTreeExpand={this.toggleTreeExpand}
          updateInviteState={this.updateInviteState}
        />
        <InviteUsersStage3
          emailsArray={emailsArray}
          libraryName={libraryName}
          resources={resources}
          selectedPermissionLevel={selectedPermissionLevel.toLowerCase()}
          selectedResources={selectedResources}
          stage={stage}
          submitted={submitted}
          updateInviteState={this.updateInviteState}
        />
      </div>
    );
  }
}

InviteUsers.defaultProps = {
  libraryName: "Brandfolder"
};

InviteUsers.propTypes = {
  currentOrganizationKey: PropTypes.string.isRequired,
  currentResourceKey: PropTypes.string.isRequired,
  currentResourceType: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  libraryName: PropTypes.string
};
