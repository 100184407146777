import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import getVersions from '@api/v4/attachments/versions';
import { getAnnotations } from '@api/v4/resources/attachments/annotations';
import { postAnnotations } from '@api/v4/resources/versions/annotations';
import AttachmentContentHeader from '@components/asset/modal/tabs/workflow/attachment_details/AttachmentContentHeader';
import AttachmentContent from '@components/asset/modal/tabs/workflow/attachment_details/AttachmentContent';
import { BFLoader } from '@components/common/loader/main';
import { processCards, updateAnnotations } from '@components/asset/modal/tabs/workflow/helper';

const defaultActiveTab = BFG.context.hasFeature('comments_and_annotations')
  ? 'annotations'
  : 'versions';

const AttachmentDetails = (props) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [versionsLoading, setVersionsLoading] = useState(false);
  const [versionsActionLoading, setVersionsActionLoading] = useState(false);
  const [activeVersion, setActiveVersion] = useState(null); // { type: "version", id: "<version id>" }
  const [versionCards, setVersionCards] = useState(null);
  const [versionCard, setVersionCard] = useState(null);
  const [users, setUsers] = useState(null);
  const [tempAnnotation, setTempAnnotation] = useState(null);
  // downstream MentionableInput will make initial fetch for mentionable users and store it here
  // to share across comments, replies, and annotations
  const [mentionableUsers, setMentionableUsers] = useState(null);

  // stored for processing
  const [annotationsResponse, setAnnotationsResponse] = useState(null);
  const [versionsResponse, setVersionsResponse] = useState(null);

  const {
    abortFetchControllers,
    activeAttachment,
    activeCard,
    asset,
    attachmentReservations,
    initializeTab,
    isGuest,
    setAttachmentReservations,
    setAttachments,
    updateFetchControllers,
  } = props;

  let tabs = BFG.context.hasFeature('comments_and_annotations') ? ['annotations'] : [];
  if (!isGuest) {
    tabs = tabs.concat(['versions', 'checkInCheckOut']);
  }
  const activeAttachmentReservations = attachmentReservations[activeCard.id];

  const retrieveVersions = () => (
    getVersions({ attachmentKey: activeCard.id, params: { per: 250 }}, updateFetchControllers)
      .then((response) => (response))
      .catch(() => {
        setVersionsLoading(false);
        // TODO handle error here
      })
  );

  const retrieveAnnotations = () => {
    const options = {
      params: { fields: 'version,parent' },
      attachmentKey: activeCard.id,
    };

    return getAnnotations(options, updateFetchControllers)
      .then((response) => (response))
      .catch(() => {
        setVersionsLoading(false);
        // TODO handle error here
      });
  };

  const resetVersionInfo = () => {
    setActiveVersion(null);
    setVersionCard(null);
    setVersionCards(null);
    setUsers(null);
  };

  const initializeSidebar = () => {
    abortFetchControllers();
    setVersionsLoading(true);
    resetVersionInfo();

    const versionsPromise = retrieveVersions();
    const annotationsPromise = retrieveAnnotations();
    Promise.all([versionsPromise, annotationsPromise])
      .then((responses) => {
        const [versions, annotations] = responses;
        if (versions && annotations) {
          const processedCards = processCards(activeAttachmentReservations, versions, annotations);
          // these state updates are not batched because in async Promise.all
          setVersionCards(processedCards);
          setUsers({ ...versions?.users, ...annotations?.users, ...activeAttachmentReservations?.users });
          setAnnotationsResponse(annotations);
          setVersionsResponse(versions);
        }

        setVersionsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setVersionsLoading(false);
      });
  };

  const processAnnotations = () => {
    const versionAnnotations = [];

    versionCard.annotations.forEach(({
      x_offset_in_percentage: x,
      y_offset_in_percentage: y,
      id,
      note: comment,
      type,
      author,
      created_at: createdAt,
      updated_at: updatedAt
    }) => {
      if (type === 'annotation') {
        versionAnnotations.push({
          x,
          y,
          id,
          comment,
          firstName: users[author]?.first_name,
          lastName: users[author]?.last_name,
          email: users[author]?.email,
          createdAt,
          updatedAt
        });
      }
    });

    return versionAnnotations.reverse();
  };

  const handleNewAnnotation = (newAnnotation) => (
    postAnnotations({
      versionKey: activeVersion.id,
      note: newAnnotation.text,
      x_offset_in_percentage: newAnnotation.x,
      y_offset_in_percentage: newAnnotation.y,
      mentionedUsers: newAnnotation.mentions,
      params: { fields: 'version' },
    }, updateFetchControllers)
      .then((response) => (
        setAnnotationsResponse((prevState) => (updateAnnotations(response, prevState)))
      ))
      .catch((err) => {
        console.log(err);
      })
  );

  useEffect(() => {
    initializeSidebar();
  }, [activeCard]);

  useEffect(() => {
    if (!versionCards) { return undefined; }

    const activeSidebarCard = activeVersion
      ? versionCards.find((card) => card.id === activeVersion.id)
      : versionCards.find((card) => (card.type === 'version'));

    setVersionCard(activeSidebarCard);

    if (!activeVersion) {
      setActiveVersion({ type: "version", id: activeSidebarCard.id });
    }

    return undefined;
  }, [versionCards]);

  useEffect(() => {
    if (activeVersion) {
      setVersionCard(versionCards.find((card) => card.id === activeVersion.id));
    }
  }, [activeVersion]);

  useEffect(() => {
    if (!annotationsResponse || !versionsResponse || (!activeAttachmentReservations && !isGuest)) { return undefined; }

    const processedCards = processCards(activeAttachmentReservations, versionsResponse, annotationsResponse);
    setVersionCards(processedCards);
    setUsers({ ...versionsResponse?.users, ...annotationsResponse?.users, ...activeAttachmentReservations?.users });

    return undefined;
  }, [annotationsResponse, versionsResponse, attachmentReservations]);

  return (versionsLoading || !versionCard) ? (
    <div className="loader-container">
      <BFLoader />
    </div>
  ) : (
    <section className="attachment-content-container">
      <AttachmentContentHeader
        activeAttachmentReservations={activeAttachmentReservations}
        activeTab={activeTab}
        tabs={tabs}
        updateActiveContent={(tab) => setActiveTab(tab)}
      />
      <AttachmentContent
        activeAttachment={activeAttachment}
        activeAttachmentReservations={activeAttachmentReservations}
        activeCard={activeCard}
        activeTab={activeTab}
        annotations={processAnnotations()}
        asset={asset}
        initializeSidebar={initializeSidebar}
        initializeTab={initializeTab}
        mentionableUsers={mentionableUsers}
        onNewAnnotation={handleNewAnnotation}
        setActiveTab={setActiveTab}
        setActiveVersion={setActiveVersion}
        setAnnotationsResponse={setAnnotationsResponse}
        setAttachmentReservations={setAttachmentReservations}
        setAttachments={setAttachments}
        setMentionableUsers={setMentionableUsers}
        setTempAnnotation={setTempAnnotation}
        setVersionsActionLoading={setVersionsActionLoading}
        tempAnnotation={tempAnnotation}
        updateFetchControllers={updateFetchControllers}
        url={versionCard.version_details.large_thumbnail}
        users={users}
        versionCard={versionCard}
        versionCards={versionCards}
        versionsActionLoading={versionsActionLoading}
      />
    </section>
  );
};

AttachmentDetails.propTypes = {
  activeAttachment: PropTypes.shape({}).isRequired,
  activeCard: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(['asset', 'attachment'])
  }).isRequired,
  asset: PropTypes.shape({}).isRequired,
  attachmentReservations: PropTypes.shape({}).isRequired,
  initializeTab: PropTypes.func.isRequired,
  isGuest: PropTypes.bool.isRequired,
  setAttachmentReservations: PropTypes.func.isRequired,
  setAttachments: PropTypes.func.isRequired,
  updateFetchControllers: PropTypes.func.isRequired,
};

export default AttachmentDetails;
