import { t, Trans } from '@lingui/macro';
import { PickerOptions, PickerDisplayMode, PickerUploadDoneCallback } from 'filestack-js/build/main/lib/picker';
import React, { FunctionComponent, ReactElement, useEffect } from 'react';

import { FilestackCredentials, FilestackCredentialsPostOptions } from '@api/v4/private/resources/fs_creds';
import { useFilestackPicker } from '@components/common/custom_hooks/useFilestackPicker';
import { FilestackUploaderTypes } from '@components/common/filestack_uploader/FilestackUploaderEnums';
import { BFLoader } from '@components/common/loader/main';

import './styles/filestack_uploader.scss';

const DEFAULT_FILESTACK_ID = 'filestack-dropzone';

export interface FilestackUploaderProps {
  onUploadDone: PickerUploadDoneCallback;
  /** Override fetching /fs_creds by passing in valid credentials (i.e. from a .slim file) */
  credentials?: FilestackCredentials;
  credentialsOptions?: FilestackCredentialsPostOptions;
  /** Defaults to "filestack-dropzone" */
  dropzoneContainerId?: string;
  onClick?: (e) => void;
  printuiAssetKey?: string;
  printuiDigestKey?: string;
  pickerOptions?: PickerOptions;
  resourceKey?: string;
  resourceType?: string;
  /**
   * Defaults to "Drag file(s) here or click to browse" for FilestackUploaderTypes.Area.
   * Defaults to "Browse Files" for FilestackUploaderTypes.Button.
   * Defaults to "Add Files" for FilestackUploaderTypes.Text.
   * */
  text?: string;
  /** Defaults to FilestackUploaderTypes.Area */
  type?: FilestackUploaderTypes;
  uploadLinkKey?: string;
}

/**
 * `<FilestackUploader>` shows `<BFLoader>` while useFilestackPicker hooks call /fs_creds.
 *
 * When type FilestackUploaderTypes.Area is used, this component initializes two Filestack instances:
 * one PickerDisplayMode.dropPane (for drag and drop) and one PickerDisplayMode.overlay (for opens dialog).
 *
 * When other types are used, only PickerDisplayMode.overlay is initialized.
 *
 * @param props UploadAreaProps
 * @returns ReactElement
 */
export const FilestackUploader: FunctionComponent<FilestackUploaderProps> = (props) => {
  const {
    credentials,
    credentialsOptions,
    dropzoneContainerId,
    onClick,
    onUploadDone,
    printuiAssetKey,
    printuiDigestKey,
    pickerOptions,
    resourceKey,
    resourceType,
    text,
    type = FilestackUploaderTypes.Area,
    uploadLinkKey
  } = props;

  const { loading: overlayLoading, picker: overlayPicker, render: renderOverlayPicker } = useFilestackPicker({
    credentials,
    credentialsOptions,
    onUploadDone,
    printuiAssetKey,
    printuiDigestKey,
    pickerOptions: {
      ...pickerOptions,
      displayMode: PickerDisplayMode.overlay
    },
    resourceKey,
    resourceType,
    uploadLinkKey
  });

  const handleOpenOverlayPicker = (): void => {
    if (overlayPicker) {
      overlayPicker.open();
    }
  };

  const clickHandler = onClick || handleOpenOverlayPicker;

  const dropPanePickerOptions = (): PickerOptions => {
    return {
      container: dropzoneContainerId || DEFAULT_FILESTACK_ID,
      displayMode: PickerDisplayMode.dropPane,
      dropPane: {
        overlay: false,
        disableClick: true,
        onClick: clickHandler,
        customText: text || t`Drag file(s) here or click to browse`
      }
    };
  };

  const { loading: dropPaneLoading, picker: dropPanePicker, render: renderDropPanePicker } = useFilestackPicker({
    credentials,
    credentialsOptions,
    onUploadDone,
    printuiAssetKey,
    printuiDigestKey,
    pickerOptions: {
      ...pickerOptions,
      ...dropPanePickerOptions()
    },
    resourceKey,
    resourceType,
    uploadLinkKey
  });

  useEffect(() => {
    /**
     * !overlayPicker prevents multiple pickers from rendering.
     */
    if (!overlayPicker) {
      renderOverlayPicker();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    /**
     * Order here is EXTREMELY important: overlayPicker must be returned BEFORE renderDropPanePicker
     * can be called otherwise "onClick: handleOpenOverlayPicker" above won't work.
     * !dropPanePicker prevents multiple pickers from rendering.
     */
    if (overlayPicker && !dropPanePicker && type === FilestackUploaderTypes.Area) {
      renderDropPanePicker();
    }
  }, [overlayPicker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dropPanePicker) {
      if (type === FilestackUploaderTypes.Area) {
        dropPanePicker.open();
      }
    }
  }, [dropPanePicker]); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = dropPaneLoading || overlayLoading;

  const renderArea = (): ReactElement => {
    return (
      <div className="filestack-uploader-component">
        <div
          aria-disabled={isLoading || undefined}
          className="filestack-upload-area"
          data-testid={DEFAULT_FILESTACK_ID}
          id={DEFAULT_FILESTACK_ID}
        />
        {isLoading && (
          <div className="filestack-uploader-component__upload-area-loader">
            <BFLoader />
          </div>
        )}
      </div>
    );
  };

  const renderDropzoneArea = (): ReactElement => {
    if (isLoading) {
      return (
        <div className="filestack-uploader-component__dropzone-container-loader">
          <BFLoader />
        </div>
      );
    }
    return null;
  };

  const renderButton = (): ReactElement => {
    const buttonStyle = dropzoneContainerId && dropzoneContainerId.includes('checkin')
      ? 'primary'
      : 'secondary';

    return (
      <div className="filestack-uploader-component">
        <button
          aria-disabled={isLoading || undefined}
          className={`filestack-upload-button button ${buttonStyle}`}
          onClick={!isLoading ? clickHandler : undefined}
          type="button"
        >
          {text || <Trans>Browse Files</Trans>}
        </button>
      </div>
    );
  };

  const renderText = (): ReactElement => {
    return (
      <div className="filestack-uploader-component">
        <button
          aria-disabled={isLoading || undefined}
          className="filestack-upload-text"
          onClick={!isLoading ? clickHandler : undefined}
          type="button"
        >
          {text || <Trans>Add Files</Trans>}
        </button>
      </div>
    );
  };

  const renderFilestackUploader = (): ReactElement => {
    switch (type) {
      case FilestackUploaderTypes.Button:
        return renderButton();
      case FilestackUploaderTypes.Text:
        return renderText();
      default:
        return dropzoneContainerId ? renderDropzoneArea() : renderArea();
    }
  };

  return renderFilestackUploader();
};
