import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { PrimaryButton, TertiaryButton } from '@components/library/button';
import { StandardDialog } from '@components/library/dialog';

import classes from './styles/bulk-download-limit-dialog.module.scss';

interface BulkDownloadLimitDialogProps {
  clearSelected: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const BulkDownloadLimitDialog: FunctionComponent<BulkDownloadLimitDialogProps> = ({
  clearSelected, open, setOpen
}) => (
  <StandardDialog
    id="bulk-download-limit-dialog"
    open={open}
    setOpen={setOpen}
    showFooter={false}
    title={t`Download Limit Exceeded`}
    titleIcon="bff-attention"
    width={400}
  >
    <div className={classes.content}>
      <p><Trans>Please select 1,000 or fewer assets to download in bulk.</Trans></p>
      <div className={classes.buttonGroup}>
        <TertiaryButton
          className={classnames(classes.button, classes.tertiaryButton)}
          onClick={(): void => setOpen(false)}
        >
          <Trans>Close</Trans>
        </TertiaryButton>
        <PrimaryButton
          className={classes.button}
          onClick={(): void => clearSelected()}
        >
          <Trans>Clear Selection</Trans>
        </PrimaryButton>
      </div>
    </div>
  </StandardDialog>
);
