import { IconButton, FontIcons, ButtonLooks, StandardDialog, StandardText, StandardButton } from '@brandfolder/react';
import { Trans, t } from '@lingui/macro';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { UseFetchOptions, useFetch } from '@api/ApiHelper';
import { Locale } from '@components/common/language_menu/languagesMap';

import { ManageTagsData, TagCountResponse, updateVisibleTagsAfterDelete } from './helpers';
import './styles/delete_tag.scss';

export interface DeleteTagProps {
  autoGenerated: boolean,
  setTags: SetStateDispatch<TagCountResponse>;
  tagName: string;
  tags: TagCountResponse;
  ugtLocale: Locale;
}

export const DeleteTag: FunctionComponent<DeleteTagProps> = ({
  autoGenerated,
  setTags,
  tagName,
  tags,
  ugtLocale
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const putOptions: UseFetchOptions = {
    url: '/api/v4/bulk_actions/assets/manage_tags',
    method: 'PUT',
    params: {
      ugt_locale: ugtLocale // eslint-disable-line @typescript-eslint/naming-convention
    },
    fetchOnMount: false
  };
  const bulkTagsPut = useFetch(putOptions);

  const handleDelete = (): void => {
    bulkTagsPut.fetch({
      ...putOptions,
      body: {
        data: {
          /* eslint-disable @typescript-eslint/naming-convention */
          manage_tags: {
            management_action: 'delete',
            brandfolder_key: BFG.resource?.key,
            old_name: tagName,
            new_name: tagName,
            auto_generated: autoGenerated,
          }
          /* eslint-enable @typescript-eslint/naming-convention */
        } satisfies ManageTagsData
      }
    });
  };

  useEffect(() => {
    if (bulkTagsPut.response) {
      setShowConfirmation(false);
      updateVisibleTagsAfterDelete(tags, setTags, tagName);
    }
  }, [bulkTagsPut.response]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <span className="delete-tag__container">
      <IconButton
        icon={FontIcons.Trash}
        label={t`Delete tag`}
        look={ButtonLooks.Warning}
        onClick={(): void => { setShowConfirmation(true); }}
      />
      <StandardDialog
        footer={(
          <div className="delete-confirmation__button-container">
            <StandardButton
              disabled={bulkTagsPut.loading}
              look={ButtonLooks.Tertiary}
              onClick={(): void => { setShowConfirmation(false); }}
            >
              <Trans>Cancel</Trans>
            </StandardButton>
            <StandardButton
              loading={bulkTagsPut.loading}
              look={ButtonLooks.Danger}
              onClick={(): void => { handleDelete(); }}
            >
              <Trans>Delete</Trans>
            </StandardButton>
          </div>
        )}
        id="delete-tag-confirmation"
        labels={{
          closeButtonLabel: t`Close`,
        }}
        open={showConfirmation}
        setOpen={setShowConfirmation}
        title={t`Delete`}
        titleIcon={FontIcons.Trash}
      >
        <StandardText><Trans>Are you sure you want to delete the <em>{tagName}</em> tag?</Trans></StandardText>
      </StandardDialog>
    </span>
  );

};
