import { KeyboardEvent, MutableRefObject, SyntheticEvent } from 'react';

import { ListOption } from '@components/library/dropdown';

export type SelectionValue = ListOption;

export enum InputType {
  Text = 'text',
  Number = 'number',
  Email = 'email',
  Textarea = 'textarea',
  Password = 'password',
  Select = 'select',
  Url = 'url',
  Search = 'search'
}

export interface InputAttributes {
  name: string;
  'aria-label'?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  id?: string;
  max?: number;
  min?: number;
  onBlur?: InputHandlerFn;
  onChange?: InputHandlerFn;
  onFocus?: InputHandlerFn;
  onKeyPress?: (e: KeyboardEvent<HTMLElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLElement>) => void;
  placeholder?: string;
  ref?: MutableRefObject<any>;
  type?: InputType;
  value?: string;
}

export interface ValidationRules {
  minLength?: number;
  isRequired?: boolean;
  isPassword?: boolean;
  isEmail?: boolean;
}

export interface InputDetails {
  error?: string | null;
  touched?: boolean;
  value?: string | SelectionValue;
}

export interface InputProps {
  attributes?: InputAttributes;
  options?: SelectionValue[];
  selectValue?: SelectionValue;
  children?: JSX.Element | JSX.Element[];
  className?: string;
  id?: string;
  input?: InputDetails;
  submitAttempted?: boolean;
  validationRules?: ValidationRules;
}

export interface InputsMap {
  [key: string]: InputDetails;
}

export interface SelectOnChangeEvent {
  inputName: string;
  label?: string; // not present when deleting/backspacing
  value?: string; // not present when deleting/backspacing
}

export type InputEvent = SyntheticEvent | InputChangeEvent | SelectOnChangeEvent;

export type InputHandlerFn = (event: InputEvent, validationRules: ValidationRules) => void;
