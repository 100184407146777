import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import {
  DEFAULT_FILESTACK_POST_OPTIONS,
  FilestackCredentials,
  FilestackCredentialsPostOptions,
  FilestackCredentialsPostResponse,
} from '@api/v4/private/resources/fs_creds';
import { getFilestackCredentials } from '@helpers/filestack';
import { FILESTACK_CREDENTIALS_PREFERENCE_KEY, setStorage, StorageTypes } from '@helpers/storage';

interface FilestackCredentialsState {
  error: Error | null;
  fetch: () => void;
  filestackCredentials: FilestackCredentials | null;
  loading: boolean;
}

interface UseFilestackCredentialsOptions {
  /** Override fetching /fs_creds by passing in valid credentials (i.e. from a .slim file) */
  credentials?: FilestackCredentials;
  credentialsOptions?: FilestackCredentialsPostOptions;
  printuiAssetKey?: string;
  printuiDigestKey?: string;
  resourceKey?: string;
  resourceType?: string;
  uploadLinkKey?: string;
}

const message = (): string => t`Filestack credentials could not be fetched. Please refresh to try again.`;
const name = 'Filestack';

/**
 * Hook for getting a Filestack credentials from /api/v4/private/:resource/:resource_key/fs_creds.
 * @param options UseFilestackCredentialsOptions | undefined
 * @returns FilestackCredentialsState
 */
export const useFilestackCredentials = (options: UseFilestackCredentialsOptions = {}): FilestackCredentialsState => {
  const {
    credentials,
    credentialsOptions = DEFAULT_FILESTACK_POST_OPTIONS,
    printuiAssetKey,
    printuiDigestKey,
    resourceKey,
    resourceType,
    uploadLinkKey
  } = options;

  const fetchFilestackCredentials = useFetch<FilestackCredentialsPostResponse>({
    body: {
      ...credentialsOptions,
      ...uploadLinkKey && { upload_key: uploadLinkKey }, // eslint-disable-line @typescript-eslint/naming-convention
      ...printuiAssetKey && { printui_asset_key: printuiAssetKey }, // eslint-disable-line @typescript-eslint/naming-convention
      ...printuiDigestKey && { printui_digest_key: printuiDigestKey } // eslint-disable-line @typescript-eslint/naming-convention
    },
    fetchOnMount: false,
    method: 'POST',
    url: `/api/v4/private/${resourceType || BFG.resource.type}s/${resourceKey || BFG.resource.key}/fs_creds`
  });

  const [filestackCredentials, setFilestackCredentials] = useState<FilestackCredentials | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const customPostOptions = () : boolean => (Object.keys(credentialsOptions).some((key) => DEFAULT_FILESTACK_POST_OPTIONS[key] != credentialsOptions[key]));

  const fetch = (): void => {
    const creds = credentials || getFilestackCredentials();
    // NOTE: force refresh when custom POST options are set (Guest Upload)
    if (creds && !customPostOptions()) {
      setFilestackCredentials(creds);
    } else {
      fetchFilestackCredentials.fetch();
    }
  };

  useEffect(() => {
    if (fetchFilestackCredentials.error) {
      setError({ message: message(), name });
    }
  }, [fetchFilestackCredentials.error]);

  useEffect(() => {
    setLoading(fetchFilestackCredentials.loading);
  }, [fetchFilestackCredentials.loading]);

  useEffect(() => {
    if (fetchFilestackCredentials.response) {
      const { data } = fetchFilestackCredentials.response;

      if (data) {
        setFilestackCredentials(data);
        setStorage(StorageTypes.Session, FILESTACK_CREDENTIALS_PREFERENCE_KEY, data);
      } else {
        setError({ message: message(), name });
      }
    }
  }, [fetchFilestackCredentials.response]);

  return { error, fetch, filestackCredentials, loading };
};
