/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { t, plural } from '@lingui/macro';
import classnames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import { useFetch } from '@api/ApiHelper';
import { CollectionsListResponse } from '@api/v4/collections';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { ListDropdown, ListOption } from '@components/library/dropdown';
import { FontIcon, FontIcons } from '@components/library/icon';
import { ProgressStatus, Statuses } from '@components/library/status';

import classes from './styles/workspace-list.module.scss';

interface WorkspaceListProps {
  brandfolderKey?: string;
  brandfolderSlug?: string;
  showShareButton?: boolean;
}

export const WorkspaceList: FunctionComponent<WorkspaceListProps> = (props) => {
  const { brandfolderKey, brandfolderSlug, showShareButton } = props;
  const { brandfolder_key, brandfolder_slug } = BFG;
  const { pathname } = window.location;

  const { loading, response } = useFetch<CollectionsListResponse>({
    params: { is_workspace: true, per: 3000 },
    url: `/api/v4/brandfolders/${brandfolderKey || brandfolder_key}/collections`
  });

  const [rendering, setRendering] = useState(true);
  const [options, setOptions] = useState<ListOption[]>([]);

  const getStatusLabel = (status: string): string => {
    switch (status) {
      case Statuses.Completed:
        return t`Status: Completed`;
      case Statuses.InProgress:
        return t`Status: In Progress`;
      default:
        return t`Status: Not Started`;
    }
  };

  const renderOptions = (): void => {
    setRendering(true);

    const listOptions: ListOption[] = response && response.data ? response.data.map((workspace) => {
      const { attributes } = workspace;
      const { name, progress, slug, status } = attributes;

      const href = `/${brandfolderSlug || brandfolder_slug}/${slug}`;
      const title = pathname === href ? t`${name} (Current)` : name;

      const label = (
        <a
          className={classes.link}
          href={href}
        >
          <FontIcon
            aria-hidden
            className={classes.icon}
            icon={FontIcons.Workspace}
          />
          <span
            className={classes.name}
            title={title}
          >
            {title}
          </span>
          {status && (
            <ProgressStatus
              className={classes['project-status']}
              label={getStatusLabel(status)}
              locale={determineUGTLocale()}
              progress={progress || 0}
              status={status as Statuses}
            />
          )}
        </a>
      );
      return {
        label,
        value: slug
      };
    }) : [];

    setOptions(listOptions);
    setRendering(false);
  };

  useEffect(() => {
    renderOptions();
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This hack is needed because <a> tags inside of <ListDropdown> options don't work
   * with key events like hitting the enter key to activate the link
   * TODO: Refactor <ListDropdown> so that <a> tag options work onKeyUp/onKeyDown/onKeyPress
   */
  const handleOnChange = (listOption: ListOption): void => {
    window.location.href = `/${brandfolderSlug || brandfolder_slug}/${listOption.value}`;
  };

  const workspaces = response && response.data;

  const hasNoWorkspaces = workspaces && workspaces.length === 0;
  if (hasNoWorkspaces === true) return null;

  return (
    <I18nProviderWrapper>
      <ListDropdown
        className={classnames(
          classes.dropdown,
          showShareButton && classes['show-share-button']
        )}
        isLoading={loading || rendering}
        onChange={(listOption): void => handleOnChange(listOption)}
        openOnClick
        options={options}
        placeholder={workspaces ? plural(workspaces.length, { one: '# Workspace', other: '# Workspaces' }) : ''}
        virtualizeOptions
      />
    </I18nProviderWrapper>
  );
};
