import { CsvUploadFailureClient, CsvUploadFailureServer } from '@api/v4/private/resources/csv/failures';
import { CsvUploadClient, CsvUploadServer } from '@api/v4/private/resources/csv/uploads';
import { Locale } from '@components/common/language_menu/languagesMap';

/**
 * Get the first whitelisted locale from thw window global BFG.locales.whitelistedLocales
 * (or undefined if it's an empty string)
 * @returns {string} string
 */
export const getFirstAvailableWhitelistedLocale = (): Locale | undefined => {
  const parsedLocales = BFG.locales.whitelistedLocales.split(',');
  if (parsedLocales.length && parsedLocales[0] !== '') {
    return parsedLocales[0] as Locale;
  }
  return undefined;
};

/**
 * Convert snake case CSV upload to camel case
 */
export const mapCsvUploadServerToClient = (data: CsvUploadServer, id?: string): CsvUploadClient => ({
  completedAt: data.completed_at,
  createdAt: data.created_at,
  creatorEmail: data.creator_email,
  failedCount: data.failed_count,
  filename: data.filename,
  id,
  status: data.status,
  succeededCount: data.succeeded_count,
  totalCount: data.total_count,
  validationFailureCount: data.validation_failure_count,
  validationFailureDownloadUrl: data.validation_failure_download_url,
  validationSuccessCount: data.validation_success_count
});

/**
 * Convert camel case CSV upload to snake case
 */
export const mapCsvUploadClientToServer = (data: CsvUploadClient): CsvUploadServer => ({
  completed_at: data.completedAt,
  created_at: data.createdAt,
  creator_email: data.creatorEmail,
  failed_count: data.failedCount,
  filename: data.filename,
  status: data.status,
  succeeded_count: data.succeededCount,
  total_count: data.totalCount,
  validation_failure_count: data.validationFailureCount,
  validation_failure_download_url: data.validationFailureDownloadUrl,
  validation_success_count: data.validationSuccessCount
});

/**
 * Convert snake case CSV upload failure to camel case
 */
export const mapCsvUploadFailureServerToClient = (data: CsvUploadFailureServer, id?: string): CsvUploadFailureClient => ({
  actionableKey: data.actionable_key,
  createdAt: data.created_at,
  failureNotes: data.failure_notes,
  id
});
