import { plural, Plural, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { ListDropdown } from '@components/library/dropdown';
import { bulkRefreshSections } from '@helpers/show_page_helpers';

import renderModal from '../modals/renderModal';

class BulkMergeModal extends React.Component {
  state = {
    destinationAssetKey: this.props.selectedAssetsData[0].id,
    destinationAssetName: this.props.selectedAssetsData[0].attributes.name,
    disableSubmitButton: false,
    isLoading: false,
    showConfirmation: false
  }

  componentWillUnmount() {
    this.props.closeModal();
  }

  buildDataBody = () => (
    { data: {
      asset_keys: Array.from(this.props.selectedAssetKeys),
      bulk_merge: {
        destination_asset_key: this.state.destinationAssetKey
      } } }
  )

  mergeAssets = () => {
    this.setState({ disableSubmitButton: true, isLoading: true });
    const assetCount = this.props.selectedAssetKeys.size;

    $.ajax({
      type: "PUT",
      url: '/api/v4/bulk_actions/assets/merge?queue_priority=high',
      contentType: "application/json",
      data: JSON.stringify(this.buildDataBody()),
      context: this,
      headers: { Authorization: `Bearer ${BF_Token}` },
      success: () => {
        BF.fx.updateAssetPageCount((assetCount - 1), false);
        this.props.addRemoveSelected([this.state.destinationAssetKey]); // remove merged assets from selectedAssetKeys
        bulkRefreshSections(this.props.selectedAssetKeys);
        this.props.closeModal();
        Notify.create({
          title: plural(assetCount, {
            one: `${assetCount} asset is being merged. This may take a moment.`,
            other: `${assetCount} assets are being merged. This may take a moment.`
          }),
          type: 'success'
        });
      },
      error: () => {
        this.setState({ disableSubmitButton: false, isLoading: false });
        Notify.create({
          title: plural(assetCount, {
            one: `An error occurred while merged ${assetCount} asset. Please try again.`,
            other: `An error occurred while merging ${assetCount} assets. Please try again.`
          }),
          type: 'error'
        });
      }
    });
  }

  createOptionsForSelect(options) {
    return options.map((asset) => ({
      value: asset.id,
      label: `${asset.attributes.name} (${asset.attributes.extension})`
    }));
  }

  renderMerge() {
    if (this.state.showConfirmation) { return null; }

    const { destinationAssetKey, disableSubmitButton } = this.state;
    const assetCount = this.props.selectedAssetKeys.size;

    return (
      <>
        <div className="modal-content-wrapper__header">
          <span aria-hidden="true" className="bff-merge icon"/>
          <h3 className="modal-title"><Trans>Merge</Trans></h3>
          <button
            className="close-button"
            onClick={this.props.closeModal}
            type="button"
          >
            <span className="bff-close" />
          </button>
        </div>
        <div className="modal-content-wrapper__body">
          <h3 className="body-title">
            {/* TODO: don't rely on bold-text class for spacing */}
            <Plural
              one={
                <Trans>
                  Merge
                  <span className="bold-text">{assetCount} asset</span>
                  into:
                </Trans>
              }
              other={
                <Trans>
                  Merge
                  <span className="bold-text">{assetCount} assets</span>
                  into:
                </Trans>
              }
              value={assetCount}
            />
          </h3>
          <div className="modal-row">
            <ListDropdown
              onChange={(option) => this.setState({
                destinationAssetKey: option.value,
                destinationAssetName: option.label
              })}
              options={this.createOptionsForSelect(this.props.selectedAssetsData)}
              value={destinationAssetKey}
              virtualizeOptions={false}
            />
          </div>
          <p className="merge-copy">
            <Trans>Merging assets will combine all attachments, tags, & custom fields.</Trans>
          </p>
          <div className="button-container">
            <button
              className="button sm primary t-confirm-modal"
              disabled={disableSubmitButton}
              onClick={() => this.setState({ showConfirmation: true })}
              type="button"
            >
              <Trans>Merge</Trans>
            </button>
          </div>
        </div>
      </>
    );
  }

  renderConfirmation() {
    if (!this.state.showConfirmation) { return null; }

    const { destinationAssetName, disableSubmitButton, isLoading } = this.state;
    const assetCount = this.props.selectedAssetKeys.size;
    const loaderActive = isLoading ? 'loader-active' : '';

    return (
      <React.Fragment>
        <div className={`modal-content-wrapper__header ${loaderActive}`}>
          <span aria-hidden="true" className="bff-warning icon"/>
          <h3 className="modal-title"><Trans>Bulk Merge Confirmation</Trans></h3>
          <button
            className="close-button"
            onClick={this.props.closeModal}
            type="button"
          >
            <span className="bff-close" />
          </button>
        </div>
        <div className="modal-content-wrapper__body">
          <div>
            <p className="merge-copy">
              {/* TODO: don't rely on bold-text for spacing */}
              <Plural
                one={
                  <Trans>
                    Are you sure you want to permanently merge
                    <span className="bold-text">{assetCount} asset</span>
                    into {destinationAssetName}
                  </Trans>
                }
                other={
                  <Trans>
                    Are you sure you want to permanently merge
                    <span className="bold-text">{assetCount} assets</span>
                    into {destinationAssetName}
                  </Trans>
                }
                value={assetCount}
              />
            </p>
            <p className="merge-copy"><Trans>This action cannot be undone!</Trans></p>
          </div>
          <div className="button-container">
            <button
              className="button sm primary t-confirm-modal"
              disabled={disableSubmitButton}
              onClick={this.mergeAssets}
              type="button"
            >
              <Trans>Merge</Trans>
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="modal-content-wrapper bulk-merge-modal">
        {this.renderMerge()}
        {this.renderConfirmation()}
      </div>
    );
  }
}

BulkMergeModal.propTypes = {
  addRemoveSelected: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedAssetsData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
};

const ModalComponent = renderModal(BulkMergeModal, 'BulkMergeModal');
export default ModalComponent;
