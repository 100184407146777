import Avatar from 'boring-avatars';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { AvatarTypes, BaseAvatarProps } from '@components/library/avatar';
import { StandardTooltip, TooltipTypes } from '@components/library/tooltip';
import { BrandfolderHexColors } from '@components/library/utils';

const baseName = 'avatar';

export const BaseAvatar: FunctionComponent<BaseAvatarProps> = (props) => {
  const {
    avatarProps,
    email,
    image,
    imageProps,
    name,
    selected = true,
    showImage,
    size = 40,
    tooltipProps,
    triggerOffset = 5,
    type = AvatarTypes.Beam,
    variantName,
    ...linkProps
  } = props;

  return (
    <StandardTooltip
      {...tooltipProps}
      id={email}
      tooltip={name}
      triggerOffset={triggerOffset}
      type={TooltipTypes.Label}
    >
      <a
        {...linkProps}
        className={classnames(
          `bf-${baseName}__${variantName}`,
          linkProps && linkProps.className
        )}
        href={`mailto:${email}`}
        rel="noopener noreferrer"
        style={{
          height: size,
          width: size
        }}
        target="_blank"
      >
        {showImage && image
          ? (
            <img
              {...imageProps}
              alt={name}
              src={image}
              style={{
                ...imageProps?.style,
                height: size,
                width: size
              }}
            />
          )
          : (
            <Avatar
              {...avatarProps}
              colors={selected ? [
                BrandfolderHexColors.AquaKnockoff,
                BrandfolderHexColors.BlueberryDelight,
                BrandfolderHexColors.PurplePopsicle
              ] : [
                BrandfolderHexColors.DirtyMarshmallow,
                BrandfolderHexColors.GrayDark,
                BrandfolderHexColors.GrayMedium
              ]}
              name={name}
              size={size}
              variant={type}
            />
          )}

      </a>
    </StandardTooltip>
  );
};
