import fetchJSON from '@api/api_helper';

const url = ({ resourceKey, resourceType, id }) => {
  const baseUrl = `/api/v4/${resourceType}s/${resourceKey}/conversion_presets`;

  return id ? `${baseUrl}/${id}` : baseUrl;
};

export const get = ({
  resourceType,
  resourceKey,
}) => (
  fetchJSON(url({ resourceKey, resourceType })).then(({ data }) => (
    data.map(({ id, attributes }) => ({
      id,
      ...attributes,
    }))
  ))
);

export const create = ({
  resourceType,
  resourceKey,
  name,
  aspectWidth: aspect_width,
  aspectHeight: aspect_height,
  width,
  height,
  format,
}) => (
  fetchJSON(url({ resourceKey, resourceType }), {
    method: 'POST',
    body: {
      data: {
        attributes: {
          name,
          aspect_width,
          aspect_height,
          width,
          height,
          format,
        },
      },
    },
  }).catch(async (response) => { throw (await response.json()); })
);

export const destroy = (id) => (
  fetchJSON(`/api/v4/conversion_presets/${id}`, {
    method: 'DELETE',
  })
);
