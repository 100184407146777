import {
  InputLooks,
  StandardTextfield,
} from '@brandfolder/react';
import { t } from '@lingui/macro';
import React, { FunctionComponent, RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Person } from '../customPeopleTagTypes';
import { useAutocompletePeopleTags } from '../fetch';

import { SuggestedNamesList } from './SuggestedNamesList';

const TagPersonTextfieldWrapper = styled.div`
  position: relative;
  width: 100%;

  .person-details-suggested-names-list {
    width: 100%;
  }
`;

export interface TagPersonTextfieldProps {
  setPersonLabel: (personLabel: string) => void;
  currentPerson?: { id: string, image: string, assetId?: string };
  onSelectTargetPerson?: (person: Person) => void;
  placeholder?: string;
  searchTag?: string;
  showLabel?: boolean;
  targetPerson?: Person;
  textfieldRef?: RefObject<HTMLInputElement>;
}

export const TagPersonTextfield: FunctionComponent<TagPersonTextfieldProps> = ({
  setPersonLabel,
  currentPerson = undefined,
  onSelectTargetPerson = undefined,
  placeholder = undefined,
  searchTag = undefined,
  showLabel = false,
  targetPerson = undefined,
  textfieldRef = undefined
}) => {
  const [showAutocompleteResults, setShowAutocompleteResults] = useState(false);
  const { autocompleteResults } = useAutocompletePeopleTags(searchTag, currentPerson?.id);

  useEffect(() => {
    if (autocompleteResults?.length) {
      setShowAutocompleteResults(true);
    }
  }, [autocompleteResults]);

  useEffect(() => {
    if (!autocompleteResults?.length || targetPerson || !searchTag) {
      setShowAutocompleteResults(false);
    }
  }, [autocompleteResults, targetPerson, searchTag]);

  const handleOnSelectTargetPerson = (person: Person): void => {
    if (onSelectTargetPerson) {
      onSelectTargetPerson(person);
    }
    setShowAutocompleteResults(false);
    setPersonLabel(person.tag_name);
  };

  return (
    <>
      <TagPersonTextfieldWrapper tabIndex={-1}>
        <StandardTextfield
          ref={textfieldRef}
          className="tag-person-textfield"
          id="textfield"
          label="Name"
          look={InputLooks.Default}
          onChange={(e): void => setPersonLabel(e.target.value)}
          placeholder={placeholder || t`Add a name`}
          showLabel={showLabel}
          textfieldProps={{
            autoFocus: true,
            autoComplete: 'off',
            className: 'textfield'
          }}
          type="text"
        />
        {showAutocompleteResults && (
          <SuggestedNamesList
            handleOnSelectTargetPerson={handleOnSelectTargetPerson}
            listClasses="person-details-suggested-names-list"
            suggestions={autocompleteResults}
          />
        )}
      </TagPersonTextfieldWrapper>
    </>
  );
};
