import getJSON from '@api/api_helper';

export const removeFromAllCollections = (
  brandfolderKey: string,
  assetKeys: string[],
): Promise<void> => {
  const url = `/api/v4/private/brandfolders/${brandfolderKey}/assets/remove_from_all_collections`;
  const appendedOptions = {
    method: 'DELETE',
    body: {
      data: {
        attributes: {
          asset_keys: assetKeys
        }
      }
    }
  };
  return getJSON(url, appendedOptions);
};
