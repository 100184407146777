import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';

import { update as updateFilters } from '@api/v3/search_filters';
import renderModal from '@components/show_page/modals/renderModal';
import { unsavedChangesOptions } from '@helpers/sweet_alert_options';

import ButtonsContainer from './buttons_container';
import ModalBodyContent from './modal_body_content';
import ModalHeader from './modal_header';
import SearchPinsAdd from './search_pins_add';

import './styles/manage_search_pins.scss';

const ManageSearchPinsModal = (props) => {
  const [editingActive, setEditingActive] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [dragInProgress, setDragInProgress] = useState(false);

  const { closeModal, searchFilters, updateSearchFilters } = props;

  const reorderRows = (rowsData) => (
    rowsData.map((pin, i) => {
      const updatedPin = pin;
      updatedPin.position = i;
      return updatedPin;
    })
  );

  const removeRow = (stateArgument, indexToRemove) => {
    const newState = [];
    stateArgument.forEach((row, i) => {
      if (i !== indexToRemove) {
        newState.push({ ...row });
      }
    });
    return newState;
  };

  const formatSearchFiltersData = (searchFiltersRaw) => (
    searchFiltersRaw.map(({ featured, key, label, position, query }) => ({ featured, key, label, position, query }))
  );

  const reducer = (state, action) => {
    const { data, index, type } = action;

    switch (type) {
      case 'delete-row':
        return removeRow(state, index);
      case 'update-featured':
        return state.map((row, i) => {
          if (i === index) {
            const prevFeatured = { ...row }.featured;
            const newRow = { ...row };
            newRow.featured = !prevFeatured;
            return newRow;
          }
          return row;
        });
      case 'done-editing':
        return state.map((row, i) => {
          if (i === index) {
            return data;
          }
          return row;
        });
      case 'initial-load': {
        return formatSearchFiltersData(searchFilters); // set reducer state
      }
      case 'update-row-position': {
        const draggedRow = state[index];
        const { hoveredRow } = data;

        if (draggedRow.key === hoveredRow.key) { return [...state]; } // can't drop on itself

        const newState = removeRow(state, index); // delete pin in previous position
        const hoveredRowIndex = newState.map((pin) => pin.key).indexOf(hoveredRow.key);
        const insertionIndex = hoveredRow.hoveredHalf === 'top'
          ? hoveredRowIndex
          : hoveredRowIndex + 1;

        newState.splice(insertionIndex, 0, draggedRow); // insert pin into updated position

        return reorderRows(newState);
      }
      case 'alphabetize': {
        const updatedState = [...state].sort((a, b) => {
          if (a.label.toUpperCase() < b.label.toUpperCase()) { return -1; }
          if (a.label.toUpperCase() > b.label.toUpperCase()) { return 1; }
          return 0;
        });
        return reorderRows(updatedState);
      }
      case 'add-new-pin': {
        const updatedState = [...state];
        updatedState.push(data);
        return updatedState;
      }
      default: return state;
    }
  };

  const [updatedFiltersData, dispatch] = useReducer(reducer, []);

  const enableDisableSave = () => {
    const initialFiltersData = formatSearchFiltersData(searchFilters);
    let isDataUpdated = false;

    if (initialFiltersData.length !== updatedFiltersData.length) {
      isDataUpdated = true;
    } else {
      initialFiltersData.forEach((initialFilter) => {
        const filterToCompare = updatedFiltersData.filter((updatedFilter) => (initialFilter.key === updatedFilter.key));

        if (!filterToCompare.length) {
          isDataUpdated = true;
        } else {
          Object.keys(filterToCompare[0]).forEach((filterKey) => {
            if (initialFilter[filterKey] !== filterToCompare[0][filterKey]) {
              isDataUpdated = true;
            }
          });
        }
      });
    }

    setSaveButtonDisabled(!isDataUpdated);
  };

  useEffect(() => {
    dispatch({ type: 'initial-load' });
  }, [searchFilters]);

  useEffect(() => {
    enableDisableSave();
  }, [updatedFiltersData]);

  const handleClose = () => {
    if (!saveButtonDisabled) {
      window.swal(unsavedChangesOptions({}), (confirmSelected) => {
        if (confirmSelected) { closeModal(); }
      });
    } else { closeModal(); }
  };

  const handleSubmit = () => {
    updateFilters(updatedFiltersData); // update endpoint
    updateSearchFilters(updatedFiltersData); // update show page state
    Notify.create({
      title: t`Search pins updated!`,
      type: 'success'
    });
    closeModal();
  };

  return (
    <div className="manage-search-pins-modal-wrapper">
      <ModalHeader
        dispatch={dispatch}
        handleClose={handleClose}
        updatedFiltersData={updatedFiltersData}
      />
      <div className="modal__body">
        <ModalBodyContent
          dispatch={dispatch}
          dragInProgress={dragInProgress}
          editingActive={editingActive}
          setDragInProgress={setDragInProgress}
          setEditingActive={setEditingActive}
          updatedFiltersData={updatedFiltersData}
        />
        <SearchPinsAdd
          dispatch={dispatch}
          updatedFiltersData={updatedFiltersData}
        />
        <ButtonsContainer
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          saveButtonDisabled={saveButtonDisabled}
        />
      </div>
    </div>
  );
};

ManageSearchPinsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  searchFilters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
  updateSearchFilters: PropTypes.func.isRequired,
};

const ModalComponent = renderModal(ManageSearchPinsModal, 'manage-search-pins-modal');
export default ModalComponent;
