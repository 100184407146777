// types
import type { BaseIconProps } from './IconTypes';

export type { BaseIconProps };

// components
export { FontIcon } from './font';

// enums
export { FontIconColors, FontIconPositions, FontIcons } from './IconEnums';
