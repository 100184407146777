import { t } from '@lingui/macro';
import PropTypes from "prop-types";
import React from "react";

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';

import Checkbox from "../../common/checkbox/main";

import './styles/organization_search_bar.scss';

class SearchBar extends React.Component {
  state = {
    value: this.inputValue,
    isStrict: this.isStrict
  };

  componentDidMount() {
    const { query } = this.props;
    const previousUrl = this.getCookie("previousUrl");
    if (previousUrl && previousUrl.indexOf("search?q") > -1) {
      this.setCookie("previousUrl");
    } else if (window.location.search.length === 0) {
      this.setCookie("parentUrl");
    }
    if (query) {
      this.setState({ value: query });
    }

    window.addEventListener('submitJQueryInput', this.handleSearch);
  }

  componentWillUnmount() {
    window.removeEventListener('submitJQueryInput', this.handleSearch);
  }

  get inputValue() {
    return this.props.query ? this.props.query : "";
  }

  get isStrict() {
    return window.location.href.split('strict_search=')[1] === "true";
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts
        .pop()
        .split(";")
        .shift();
    }

    return undefined;
  }

  setCookie(name) {
    document.cookie = `${name}=${window.location.href}; path=/`;
  }

  handleSearch = () => {
    const { isStrict, value } = this.state;
    const { slug, restricted } = this.props;

    if (!value && window.location.search.length > 0) {
      this.redirectToOrgs();
    } else if (value.length > 0 && value.startsWith("FindAll: ")) {
      // eslint-disable-next-line max-len
      window.location = `/organizations/${slug}/search?q=${this.adjustSearch()}${isStrict ? "&strict_search=true" : ''}`;
    } else if (BFG.embedView) {
      // Printui Iframe
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams);
      // eslint-disable-next-line max-len
      window.location = `/organizations/${slug}/embedded_search?embed=printui&q=${value}${isStrict ? "&strict_search=true" : ''}${restricted ? "&restricted=true" : ''}${params.printui_frame ? `&printui_frame=${params.printui_frame}` : ""}`;
    } else if (value.length > 0) {
      // eslint-disable-next-line max-len
      window.location = `/organizations/${slug}/search?q=${value}${isStrict ? "&strict_search=true" : ''}`;
    }
  }

  handleChange(e) {
    if (e.target.value.indexOf(",") > -1) {
      this.setState({ value: this.handleCommaSeparatedSearch(e) });
    } else {
      this.setState({ value: e.target.value });
    }
  }

  handleCommaSeparatedSearch(e) {
    return e.target.value.replace(/\s*,\s*/g, " || ");
  }

  handleBlur() {
    if (!this.state.value && window.location.search.length > 0) {
      this.redirectToOrgs();
    }
  }

  toggleStrictSearch() {
    this.setState((state) => (
      { isStrict: !state.isStrict }
    ), () => {
      this.handleSearch();
    });
  }

  adjustSearch() {
    // customer 'Evergreen' uses this FindAll for org search
    // important to maintain this functionality for this customer
    const term = "FindAll: ";
    const query = this.state.value;
    let removedTerm = query.substring(term.length, query.length);
    removedTerm = removedTerm.split(" || ").join(" ");
    return `"${removedTerm.split(" ").join('" || "')}"`;
  }

  handleKeyUp(e) {
    if (e.key === "Enter") {
      this.handleSearch();
    }
  }

  redirectToOrgs() {
    if (BFG.embedView) { return; }
    this.setCookie("previousUrl");
    const url = this.getCookie("parentUrl") || `/organizations`;
    window.location = url;
  }

  renderBody() {
    const { hide_search_bar } = this.props;

    if (!BFG.embedView && (BFG.downloadAlertEnabled || hide_search_bar)) {
      // hide org search if org has a brandfolder/collection with download alerts enabled
      // does NOT apply to template embed view
      return null;
    }

    const { value } = this.state;

    return (
      <div className="organization-search-bar-component org-search open">
        <div className={`strict-toggle ${value.length > 0 ? 'visible' : 'hidden'}`}>
          <Checkbox
            checked={this.state.isStrict}
            label={t`Strict Search`}
            size="md"
            toggle={() => this.toggleStrictSearch()}
          />
        </div>
        <div className="s-search-input-container s-search-focus">
          <div className="search-input-container">
            <input
              className="searchbar org-search"
              id="brandfolderSearch"
              maxLength={10000}
              onBlur={() => this.handleBlur()}
              onChange={this.handleChange.bind(this)}
              onKeyUp={this.handleKeyUp.bind(this)}
              placeholder={t`Search`}
              type="text"
              value={this.state.value}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <I18nProviderWrapper>
        {this.renderBody()}
      </I18nProviderWrapper>
    )
  }
}

SearchBar.propTypes = {
  hide_search_bar: PropTypes.bool,
  query: PropTypes.string,
  restricted: PropTypes.bool,
  slug: PropTypes.string,
};

export default SearchBar;
