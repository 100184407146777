import { Trans } from '@lingui/macro';
import React, { FunctionComponent, MutableRefObject } from 'react';

import { TaskStatuses } from '@api/v4/tasks';
import { ListDropdown, ListOption, ListOptionValue } from '@components/library/dropdown/index';

interface WorkspaceTaskStatusDropdownProps {
  id: string
  onChange: (listOption: ListOption) => void;
  value: ListOption | ListOptionValue;
  className?: string;
  overflowParentRef?: MutableRefObject<HTMLElement>;
  showAllOptions?: boolean;
}

export const WorkspaceTaskStatusDropdown: FunctionComponent<WorkspaceTaskStatusDropdownProps> = (props) => {
  const { className, id, onChange, overflowParentRef, showAllOptions, value } = props;
  const statusOptions: ListOption[] = [
    {
      label: <Trans>Not Started</Trans>,
      value: TaskStatuses.NotStarted
    },
    {
      label:<Trans>In Progress</Trans>,
      value: TaskStatuses.InProgress
    },
    {
      label: <Trans>Completed</Trans>,
      value: TaskStatuses.Completed
    },
  ];

  const statusAllOption: ListOption = {
    label: <Trans>All</Trans>,
    value: TaskStatuses.All
  };

  return (
    <ListDropdown
      className={className}
      id={id}
      onChange={onChange}
      options={showAllOptions ? [statusAllOption, ...statusOptions] : statusOptions}
      overflowParentRef={overflowParentRef}
      value={value}
      virtualizeOptions={false}
    />
  );
};
