import { DesignExtended, PressReleaseUpsert } from '../PressReleaseTypes';

/**
 * Get whether a press release email is empty (content hasn't been added yet)
 * @param {PressReleaseUpsert | null} pressRelease PressReleaseUpsert | null
 * @returns {boolean} boolean
 */
export const getIsEmailEmpty = (pressRelease: PressReleaseUpsert | null): boolean => {
  if (!pressRelease) {
    return true;
  }
  if (pressRelease && (!pressRelease.content_html || !pressRelease.content_json)) {
    return true;
  }
  if (pressRelease && pressRelease.content_json) {
    try {
      const design = JSON.parse(pressRelease.content_json) as DesignExtended;
      // no rows of content (when a user deletes the default single column)
      const noRows = design.body.rows.length === 0;
      // the editor could have any number of rows with between 1-4 columns each
      // at least one row with one column should have some content (hence nested Array.some)
      const hasSomeContent = design.body.rows.some((row) => row.columns.some((column) => column.contents.length > 0));
      if (noRows || !hasSomeContent) {
        return true;
      }
    } catch (err) {
      // if parsing content_json fails, something went wrong so we can assume empty
      return true;
    }
  }
  return false;
};
