import classnames from 'classnames';
import React, { forwardRef, Ref } from 'react';

import { StandardTooltip } from '@brandfolder/react';
import { TextButton } from '@components/library/button/index';
import { ResourcePrivacyBadge } from '@components/bulk_management/user-settings/resource-sidebar/ResourcePrivacyBadge';
import {
  HeadingType,
  ResourceDetails,
  ResourceBase
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';

interface ResourceButtonProps {
  headingType: HeadingType;
  resource: ResourceBase;
  selectedResourceKey: string;
  setResource: SetStateDispatch<ResourceDetails>;
  showTooltip: boolean;
  hidePrivacy?: boolean;
}

export const ResourceButton = forwardRef<HTMLButtonElement, ResourceButtonProps>((props, ref: Ref<HTMLButtonElement>) => {
  const {
    headingType,
    hidePrivacy,
    resource,
    selectedResourceKey,
    setResource,
    showTooltip,
  } = props;
  const id = `${headingType}-accordion-${resource.key}`;

  return (
    <TextButton
      key={`button-${id}`}
      ref={ref}
      className={classnames(
        'resource-sidebar__button',
        { ['active-resource']: selectedResourceKey === resource.key },
        { ['resource-sidebar__button--brandfolder']: headingType === 'brandfolder' },
        { ['resource-sidebar__button--collection']: headingType === 'collection' }
      )}
      onClick={(): void => setResource({
        adminable: resource.adminable,
        headingType,
        key: resource.key,
        name: resource.name,
        type: headingType === 'subcollection' || headingType === 'workspace' ? 'collection' : headingType
      })}
    >
      {!hidePrivacy && <ResourcePrivacyBadge privacy={resource.privacy} />}
      {showTooltip ? (
        <StandardTooltip id={`${headingType}-${id}`} tooltip={resource.name} transitionDuration={100}>
          <p className={classnames(
            'resource-sidebar__button--no-accordion',
            { ['resource-sidebar__button--no-accordion--nested']: headingType === 'subcollection' }
          )}>
            {resource.name}
          </p>
        </StandardTooltip>
      ) : (
        <span className={classnames(
          'resource-sidebar__button--no-accordion',
          { ['resource-sidebar__button--no-accordion--nested']: headingType === 'subcollection' }
        )}>
          {resource.name}
        </span>
      )}
    </TextButton>
  );
});