import { CamelCasedProperties } from 'type-fest';
import { WorksheetAccessLevels } from '@components/workspace/WorkspaceEnums';

export interface DashbarUpdated {
  refresh?: boolean;
}

export interface DashbarUpdatedEvent extends Event {
  detail?: DashbarUpdated;
}

export interface WorkspaceUpdated {
  connectionReset?: boolean;
  dueDate?: string;
  refresh?: boolean;
}

export interface WorkspaceUpdatedEvent extends Event {
  detail?: WorkspaceUpdated;
}

export interface ResetResponseServer {
  status: string;
}

export type ApiDatumResponseConnectServer = {
  data: ApiResponseObjectConnectServer
};

export interface ApiResponseObjectConnectServer {
  attributes: ConnectResponseAttributesServer;
  id?: string;
  oauth_url?: string; // eslint-disable-line @typescript-eslint/naming-convention
  type?: 'environment_smartsheet_destination';
}

export interface ApiResponseObjectConnectClient {
  attributes: ConnectResponseAttributesClient;
  id: string;
  oauthUrl: string;
  type: 'environment_smartsheet_destination';
}

export interface ConnectResponseAttributesServer {
  has_authenticated: boolean; // eslint-disable-line @typescript-eslint/naming-convention
  smar_sheet_permalink: string; // eslint-disable-line @typescript-eslint/naming-convention
  bf_workspace_id?: string; // eslint-disable-line @typescript-eslint/naming-convention
  refresh_token?: string; // eslint-disable-line @typescript-eslint/naming-convention
  smar_sheet_id?: string; // eslint-disable-line @typescript-eslint/naming-convention
}

export type ConnectResponseAttributesClient = CamelCasedProperties<ConnectResponseAttributesServer>;

export interface WorksheetDatum {
  accessLevel: WorksheetAccessLevels;
  id: number;
  name: string;
  permalink: string;
}

export interface GetConnectWorksheets {
  data: WorksheetDatum[]
}

export type ConnectResponseServer = ApiDatumResponseConnectServer;
export type ConnectUrlData = ApiResponseObjectConnectServer;
export type GetConnectUrl = ConnectResponseServer;
export type GetConnectResponse = Response & (GetConnectUrl | GetConnectWorksheets);

// Type Predicates
export const isConnectWorksheetsData = (data: WorksheetDatum[] | ConnectUrlData): data is WorksheetDatum[] => (
  Array.isArray(data)
);

export const isConnectUrlData = (data: WorksheetDatum[] | ConnectUrlData): data is ConnectUrlData => (
  !Array.isArray(data)
);
