// appendedOptions example:
// appendedOptions = {
//  context: this,
//  body: { asset_keys: [asset.id] },
//  params: { include: 'user,organization', fields: 'count' }
// }

/**
 * @deprecated Use TypeScript fetchJson or useFetch instead
 */
export default (urlRaw, appendedOptionsRaw = {}, updateFetchControllers, fullResponse = false) => {
  let url = urlRaw;
  const appendedOptions = appendedOptionsRaw;
  const controller = new AbortController();
  const signal = controller.signal;
  const token = typeof(BFG) !== "undefined" ? BFG.BF_Token : BF_Token
  const defaultOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    signal
  };

  if (updateFetchControllers) {
    updateFetchControllers(controller);
  }

  // Add 'queue_priority=high' to our internal endpoints
  if ('DELETE,PUT,POST'.includes(appendedOptions?.method?.toUpperCase())) {
    // Add to body for POSTs, DELETEs and PUTs, fetch api will convert call into GET if params are
    // added to url for POST, DELETE and PUT
    appendedOptions.body = {
      ...appendedOptions.body,
      queue_priority: 'high',
    };
  } else {
    appendedOptions.params = {
      ...appendedOptions?.params,
      queue_priority: 'high',
    };
  }

  if (appendedOptions?.params) {
    const { params } = appendedOptions;

    Object.keys(params).forEach((key) => {
      let seperator = '?';
      const value = params[key];

      if (value === undefined || value === null) { return; }

      if (url.indexOf('?') >= 0) {
        seperator = '&';
      }

      url += `${seperator}${key}=${value}`;
    });
  }

  if (appendedOptions?.body) {
    appendedOptions.body = JSON.stringify(appendedOptions.body);
  }

  const fetchOptions = Object.assign(defaultOptions, appendedOptions || {});

  return fetch(url, fetchOptions)
    .then(async (response) => {
      if (!response.ok) {
        throw response;
      }

      const json = response.status === 202 || response.status === 204 ? await {} : await response.json();

      return fullResponse ? { response, json } : json;
    });
};
