import { plural } from '@lingui/macro';
import React, { useEffect, useRef, useState, FunctionComponent } from 'react';
import { CSSTransition } from 'react-transition-group';

import { fetchInBatches, useFetch } from '@api/ApiHelper';
import { createAssetShareInsightsEvent } from '@api/insights/v1/events';
import { ShareManifestPostAddAssetsResponse } from '@api/v4/share_manifests/ShareManifestTypes';
import { StandardDialog } from '@components/library/dialog';

import { BodyView } from '@components/show_page/bulk_actions/bulk-share-link-modal/BodyView';
import {
  ShareLinkRow,
  WindowDimensions
} from '@components/show_page/bulk_actions/bulk-share-link-modal/BulkShareLinkModalTypes';
import { Footer } from '@components/show_page/bulk_actions/bulk-share-link-modal/Footer';
import { SuccessView } from '@components/show_page/bulk_actions/bulk-share-link-modal/SuccessView';
import { sendAction, TrackedAction } from '@helpers/datadog-rum';

import './styles/bulk-share-link-modal.scss';

interface BulkShareLinkModalProps {
  assetKeys: string[];
  open: boolean;
  setOpen: (open: boolean) => void;
  windowDimensions: WindowDimensions;
}

const addAssetsError = (assetCount: number): void => {
  const errorTitle = plural(assetCount, {
    one: 'Error adding asset to Share Link. Please try again.',
    other: 'Error adding assets to Share Link. Please try again.'
  });

  Notify.create({
    title: errorTitle,
    type: 'error'
  });
};

export const BulkShareLinkModal: FunctionComponent<BulkShareLinkModalProps> = ({
  assetKeys,
  open,
  setOpen,
  windowDimensions,
}) => {
  const [selectedRow, setSelectedRow] = useState<ShareLinkRow>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const bodyContainerRef = useRef(null);

  const assetCount = assetKeys.length;
  const title = plural(assetCount, {
    one: 'Add asset to existing Share Link',
    other: 'Add # assets to existing Share Link'
  });

  const postAddAssetsToShareLink = useFetch<ShareManifestPostAddAssetsResponse>({
    body: {
      data: {
        attributes: {
          asset_keys: assetKeys // eslint-disable-line @typescript-eslint/naming-convention
        }
      }
    },
    fetchOnMount: false,
    method: 'POST',
    url: `/api/v4/share_manifests/${selectedRow?.id}/add_assets`
  });

  useEffect(() => {
    if (open) {
      sendAction(TrackedAction.ShareLinkAssetAdd);
    }
  }, [open]);

  useEffect(() => {
    if (postAddAssetsToShareLink.error) {
      sendAction(TrackedAction.ShareLinkAssetAddError);
      addAssetsError(assetCount);
    }
  }, [postAddAssetsToShareLink.error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (postAddAssetsToShareLink.response) {
      /**
       * Logging to Insights for 2k+ assets shared is needed so that both the browser
       * and Insights API don't error when rapidly making many singular API requests.
       *
       * TODO: Remove once we've added an Insights API endpoint that accepts an array
       * of asset keys that can handle this in one request.
       */
      fetchInBatches({
        fetchPromise: createAssetShareInsightsEvent,
        items: assetKeys
      });

      sendAction(TrackedAction.ShareLinkAssetAddSuccess);
      setIsSubmitted(true);
    }
  }, [postAddAssetsToShareLink.response]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StandardDialog
      dialogBodyClassName="bulk-share-links__dialog"
      footer={(
        <Footer
          isSubmitted={isSubmitted}
          postAddAssetsToShareLink={postAddAssetsToShareLink}
          selectedRow={selectedRow}
          setIsSubmitted={setIsSubmitted}
          setOpen={setOpen}
        />
      )}
      id="bulk-share-links-dialog"
      open={open}
      setOpen={setOpen}
      showFooter
      title={title}
      titleIcon="bff-share"
    >
      <div className="bulk-share-links__dialog--container">
        <div
          ref={bodyContainerRef}
          className="flex-wrapper body-container"
        >
          {!isSubmitted && (
            <BodyView
              bodyContainerRef={bodyContainerRef}
              open={open}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              windowDimensions={windowDimensions}
            />
          )}
          <CSSTransition
            classNames="fade-in"
            in={isSubmitted}
            mountOnEnter
            timeout={500}
            unmountOnExit
          >
            <SuccessView
              assetCount={assetKeys.length}
              shareLink={selectedRow?.link}
              shareLinkName={selectedRow?.name}
            />
          </CSSTransition>
        </div>
      </div>
    </StandardDialog>
  );
};
