import { t } from '@lingui/macro';

export const createRequestContent = ({ printerSettings, printerSettingsPopulated }) => {
  const requestContentArray = [];

  Object.keys(printerSettingsPopulated).forEach((key) => {
    if (printerSettingsPopulated[key]) {
      const printerSetting = printerSettings.filter((setting) => setting.id === key);

      if (printerSetting && printerSetting.length) {
        requestContentArray.push({
          field: key,
          order: printerSetting[0].order,
          value: printerSettingsPopulated[key],
        });
      }
    }
  });

  return requestContentArray;
};

export const postPrinterRequestsSwal = (printerName, goBack) => {
  window.swal({
    type: 'success',
    title: t`Request Submitted!`,
    text: t`Your print request has been sent to ${printerName}`,
    customClass: 'submitting-print-request',
    showConfirmButton: true,
    allowOutsideClick: false
  }, () => { goBack(); });
};
