const FILETYPE_ALIASES = { jpeg: 'jpg' };
// https://cloud.google.com/vision/docs/supported-files
const SUPPORTED_FILETYPES = ['png', 'jpg', 'bmp', 'gif', 'webp', 'raw', 'ico'];

const SAFE_FOR_WORK_THRESHOLDS = {
  adult: ['UNLIKELY', 'VERY_UNLIKELY'],
  spoof: ['UNLIKELY', 'VERY_UNLIKELY'],
  medical: ['POSSIBLE', 'UNLIKELY', 'VERY_UNLIKELY'],
  violence: ['POSSIBLE', 'UNLIKELY', 'VERY_UNLIKELY']
};

export default class GoogleVision {
  constructor(url) {
    this.url = url;
    this.extractTags = this.extractTags.bind(this);
    this.extractText = this.extractText.bind(this);
    this.detectSafeSearch = this.detectSafeSearch.bind(this);
    this.reverseImageSearch = this.reverseImageSearch.bind(this);
  }

  static hasSupportFor(filetype) {
    const updatedFiletype = this.normalizeFiletype(filetype);
    return SUPPORTED_FILETYPES.includes(updatedFiletype);
  }

  static normalizeFiletype(filetype) {
    const updatedFiletype = filetype.toLowerCase();
    return FILETYPE_ALIASES[updatedFiletype] || updatedFiletype;
  }

  extractTags() {
    const data = {
      requests: [{
        image: { source: { imageUri: this.url } },
        features: [{ type: 'LABEL_DETECTION', maxResults: 10 }]
      }]
    };
    return this.makeRequest(data).then((response) => {
      let tags = response.responses[0].labelAnnotations;
      tags = tags.filter((tag) => Math.floor(tag.score * 100) > CONFIDENCE_THRESHOLD); // eslint-disable-line no-undef
      return tags.map((tag) => tag.description);
    }).catch(() => null);
  }

  extractText() {
    const data = {
      requests: [{
        image: { source: { imageUri: this.url } },
        features: [{ type: 'DOCUMENT_TEXT_DETECTION' }]
      }]
    };
    return this.makeRequest(data).then((response) => {
      try {
        return response.responses[0].fullTextAnnotation.text;
      } catch (error) {
        return null;
      }
    }).catch(() => null);
  }

  detectSafeSearch() {
    const data = {
      requests: [{
        image: { source: { imageUri: this.url } },
        features: [{ type: 'SAFE_SEARCH_DETECTION' }]
      }]
    };
    return this.makeRequest(data).then((response) => {
      let search_is_safe = true;
      const results = response.responses[0].safeSearchAnnotation;
      const categories = Object.keys(results);
      categories.forEach((category) => {
        if (!SAFE_FOR_WORK_THRESHOLDS[category].includes(results[category])) {
          search_is_safe = false;
        }
      });
      return search_is_safe;
    }).catch(() => false);
  }

  reverseImageSearch() {
    const data = {
      requests: [{
        image: { source: { imageUri: this.url } },
        features: [{ type: 'WEB_DETECTION', maxResults: 5 }]
      }]
    };
    return this.makeRequest(data).then((response) => {
      const results = response.responses[0].webDetection.pagesWithMatchingImages;
      return results.map((result) => result.url);
    }).catch(() => null);
  }

  makeRequest(data) {
    return $.ajax({
      type: 'POST',
      url: `https://vision.googleapis.com/v1/images:annotate?key=${BROWSER_GOOGLE_VISION_API_KEY}`, // eslint-disable-line no-undef
      contentType: 'application/json',
      data: JSON.stringify(data),
      success: (response) => response
    });
  }
}
