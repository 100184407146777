import React, { useState } from 'react';
import PropTypes from "prop-types";
import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import VideoJSPlayer from '@components/video/videojs_player/main';
import DownloadOptions from './download_options';

import './styles/main.scss';

function AdvancedVideoEditor(props) {
  const [player, setPlayer] = useState(undefined);

  const onSuccess = (videoPlayer) => {
    setPlayer(videoPlayer);
  };

  const videoOptions = {
    sources: [props.video],
    onSuccess: (videoPlayer) => { onSuccess(videoPlayer); }
  };

  return (
    <I18nProviderWrapper>
      <div className="advanced-editor-modal">
        <div className="m-show-full-size-display s-expand-view" controls id="advanced-video">
          <VideoJSPlayer className="m-show-full-size-display" {...videoOptions} />
        </div>
        <DownloadOptions {...props} player={player} />
      </div>
    </I18nProviderWrapper>
  );
}

AdvancedVideoEditor.propTypes = {
  video: PropTypes.shape({
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  attachment: PropTypes.shape({
    key: PropTypes.string,
    extension: PropTypes.string,
    height: PropTypes.number,
    filename: PropTypes.string
  }).isRequired,
  assetKey: PropTypes.string.isRequired,
};

export default AdvancedVideoEditor;
