export enum Statuses {
  Completed = 'completed',
  HasNotification = 'has-notification',
  InProgress = 'in-progress',
  NotStarted = 'not-started'
}

// TODO - that wouldn't work --> update to function with t``
/**
 * Pass these English strings in to a translation service
 * @example bfTranslate(DefaultStatusLabels.Completed)
 */
export enum DefaultStatusLabels {
  Completed = 'Status: Completed',
  InProgress = 'Status: In Progress',
  NotStarted = 'Status: Not Started'
}
