/**
 * Get whether one array has all items included in another array.
 * The limited set is the first parameter, the full set is the second parameter.
 * The arrays should have the same signature (string[], number[], etc).
 * @example
 * const mySquads = ["Brand"];
 * const allSquads = ["Asset", "Brand", "Integration", "Platform"];
 * const isTrue = getArrayIncludesOtherArray(mySquads, allSquads);
 * @param a T[]
 * @param b T[]
 * @returns boolean
 */
export const getArrayIncludesOtherArray = <T>(a: T[], b: T[]): boolean => {
  return a.every((item) => b.includes(item));
};

/**
 * Get the intersection between two arrays.
 * The arrays should have the same signature (string[] or number[]).
 * @param a T[]
 * @param b T[]
 * @returns T[]
 */
export const getArraysIntersection = <T>(a: T[], b: T[]): T[] => {
  const setB = new Set(b);
  return [...new Set(a)].filter((x) => setB.has(x));
};

/**
 * Get whether two arrays intersect.
 * The arrays should have the same signature (string[] or number[]).
 * @param a T[]
 * @param b T[]
 * @returns boolean
 */
export const getArraysHasIntersection = <T>(a: T[], b: T[]): boolean => {
  return getArraysIntersection(a, b).length > 0;
};

/**
 * Filter out any items in an array that are in another array.
 * The arrays should have the same signature (string[] or number[]).
 * @example
 * const mySquads = ["Brand"];
 * const allSquads = ["Asset", "Brand", "Integration", "Platform"];
 * const notMySquads = getFilteredArray(allSquads, mySquads);
 * ["Asset", "Integration", "Platform"]
 * @param a T[]
 * @param b T[]
 * @returns T[]
 */
export const getFilteredArray = <T>(a: T[], b: T[]): T[] => {
  return a.filter((x) => !b.includes(x));
};
