import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '@components/common/checkbox/main';

import AccordionSection from './accordionSection';

const OrientationFilter = ({ orientation, updateFilters }) => (
  <AccordionSection title={t`Orientation`}>
    <React.Fragment>
      <div className="flex-row">
        <Checkbox
          checked={orientation.indexOf('landscape') > -1}
          label={t`Horizontal`}
          size="sm"
          toggle={() => updateFilters("landscape")}
        />
        <div className="icon-holder">
          <span className="bff-horizontal-orientation" />
        </div>
      </div>
      <div className="flex-row">
        <Checkbox
          checked={orientation.indexOf('portrait') > -1}
          label={t`Vertical`}
          size="sm"
          toggle={() => updateFilters("portrait")}
        />
        <div className="icon-holder">
          <span className="bff-vertical-orientation" />
        </div>
      </div>
      <div className="flex-row">
        <Checkbox
          checked={orientation.indexOf('square') > -1}
          label={t`Square`}
          size="sm"
          toggle={() => updateFilters("square")}
        />
        <div className="icon-holder">
          <span className="bff-square-orientation" />
        </div>
      </div>
      <div className="flex-row">
        <Checkbox
          checked={orientation.indexOf('panorama') > -1}
          label={t`Panoramic`}
          size="sm"
          toggle={() => updateFilters("panorama")}
        />
        <div className="icon-holder">
          <span className="bff-panoramic-orientation" />
        </div>
      </div>
    </React.Fragment>
  </AccordionSection>
);

OrientationFilter.propTypes = {
  orientation: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default OrientationFilter;
