import { t } from '@lingui/macro';
import { FormikErrors, FormikTouched } from 'formik';
import React, { FunctionComponent } from 'react';

import { FetchState } from '@api/ApiHelper';
import { ApiDataResponse, ApiResponseObject } from '@api/v4/ApiResponseTypes';
import { CustomFieldKeyServer } from '@api/v4/resources/CustomFieldKeysTypes';
import {
  Fields,
  NameDisplayTypes,
  SelectorTypes
} from '@components/bulk_management/automation/AutomationEnums';
import {
  CustomFieldKeyValueMap,
  DisplayMapEntry,
  FormState,
  KeyLabelPair,
  WatermarkActionOptions
} from '@components/bulk_management/automation/AutomationTypes';
import { CustomFieldValuesSelector } from '@components/bulk_management/automation/form/CustomFieldValuesSelector';
import { PillSelector } from '@components/bulk_management/automation/form/PillSelector';
import { WatermarkSelector } from '@components/bulk_management/automation/form/WatermarkSelector';
import {
  getCustomFieldValuesError,
  getCustomFieldValuesTouched,
  getFormStateError,
  getFormStateTouched
} from '@components/bulk_management/automation/validation';

import { TextWarningButton } from '@components/library/button';
import { ListDropdown, ListOption, ListOptionValueNoNull, MultiselectDropdown } from '@components/library/dropdown';
import { FontIcons } from '@components/library/icon';
import { StandardTextField } from '@components/library/text-field';

interface FieldDetailsSelectorProps {
  actionableId: string;
  actionIndex: number;
  customFieldKeysResponse: FetchState<ApiDataResponse<CustomFieldKeyServer, 'custom_field_keys'>>;
  errors: FormikErrors<FormState>;
  fieldIndex: number;
  fieldKeys: KeyLabelPair[];
  fieldType: SelectorTypes;
  isSectionDisabled: boolean;
  selectedDisplayObject: DisplayMapEntry;
  resetField: () => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  touched: FormikTouched<FormState>;
  updateCollectionKeys: (listOptions: ListOptionValueNoNull[]) => void;
  updateCustomFieldKey: (customFieldKey: KeyLabelPair | null, customFieldIndex?: number) => void;
  updateCustomFieldValues: (customFieldKey: string, updatedCustomFieldValues: string[]) => void;
  updateSelectedKeys: (keys: KeyLabelPair[] | null) => void;
  updateWatermark: (watermark: WatermarkActionOptions | null) => void;
  values: CustomFieldKeyValueMap;
  watermark: WatermarkActionOptions | null;
}

export const FieldDetailsSelector: FunctionComponent<FieldDetailsSelectorProps> = ({
  actionableId,
  actionIndex,
  customFieldKeysResponse,
  errors,
  fieldIndex,
  fieldKeys,
  fieldType,
  isSectionDisabled,
  resetField,
  selectedDisplayObject,
  setFieldTouched,
  touched,
  updateCollectionKeys,
  updateCustomFieldKey,
  updateCustomFieldValues,
  updateSelectedKeys,
  updateWatermark,
  values,
  watermark
}) => {
  const selectedNameObj: ApiResponseObject<CustomFieldKeyServer, 'custom_field_keys'> = customFieldKeysResponse.response?.data?.find((customFieldKey) => customFieldKey.id === fieldKeys?.[fieldIndex]?.key);
  const isRestricted = selectedNameObj?.attributes?.restricted;
  const valueAllowedOptions: ListOption[] | undefined = isRestricted
    && selectedNameObj?.attributes?.allowed_values?.map((allowedValue) => ({
      label: allowedValue,
      value: allowedValue
    }));
  const isMultiValuesAllowed: boolean = selectedNameObj?.attributes?.multi_value_enabled;

  const fieldError = getFormStateError({
    actionableId,
    actionIndex,
    errors,
    fieldIndex,
    fieldType,
    isSectionDisabled
  });
  const fieldTouched = getFormStateTouched({
    actionableId,
    actionIndex,
    fieldIndex,
    fieldType,
    isSectionDisabled,
    touched
  });

  const customFieldValuesError = getCustomFieldValuesError({
    actionableId,
    actionIndex,
    errors,
    fieldType,
    isSectionDisabled
  });
  const customFieldValuesTouched = getCustomFieldValuesTouched({
    actionableId,
    actionIndex,
    fieldType,
    isSectionDisabled,
    touched
  });

  return (
    <div className={`selector-row__name-and-values selector-row__name-and-values--${fieldType}`}>
      <div className="selector-row__name">
        {selectedDisplayObject?.nameDisplayType === NameDisplayTypes.MultiselectDropdown && (
          <MultiselectDropdown
            className="selector-name-input"
            disabled={isSectionDisabled}
            error={fieldError && fieldTouched ? fieldError : undefined}
            id="selector-name-input"
            isLoading={selectedDisplayObject.loading}
            label={<>{selectedDisplayObject?.labelContent}</>}
            onChange={(selectedOptions): void => {
              if (actionableId && fieldType === SelectorTypes.Collection) {
                updateCollectionKeys(selectedOptions);
              } else {
                const completeOptions: KeyLabelPair[] = [];
                selectedDisplayObject?.nameOptions.forEach((nameOpt) => {
                  if (selectedOptions.includes(nameOpt.value)) {
                    completeOptions.push({
                      key: nameOpt.value.toString(),
                      // eslint-disable-next-line @typescript-eslint/no-base-to-string
                      label: nameOpt.label.toString()
                    });
                  }
                });
                updateSelectedKeys(completeOptions);
              }
            }}
            options={selectedDisplayObject?.nameOptions}
            placeholder={selectedDisplayObject?.placeholderCopy}
            required
            searchable
            values={fieldKeys?.map((fieldKey) => fieldKey.key)}
          />
        )}
        {selectedDisplayObject?.nameDisplayType === NameDisplayTypes.ListDropdown && (
          <ListDropdown
            className="selector-name-input"
            disabled={isSectionDisabled}
            error={fieldError && fieldTouched ? fieldError : undefined}
            id="selector-name-input"
            isLoading={selectedDisplayObject.loading}
            label={<>{selectedDisplayObject?.labelContent}</>}
            onChange={(selectedOption: ListOption): void => {
              if (!fieldKeys || selectedOption.value !== fieldKeys[fieldIndex]?.key) {
                const keyLabelPair: KeyLabelPair = {
                  key: selectedOption.value.toString(),
                  // eslint-disable-next-line @typescript-eslint/no-base-to-string
                  label: selectedOption.label.toString()
                };
                if (fieldType === SelectorTypes.CustomField) {
                  updateCustomFieldKey(keyLabelPair, fieldIndex);
                } else {
                  updateSelectedKeys([keyLabelPair]);
                }
              }
            }}
            options={selectedDisplayObject?.nameOptions}
            placeholder={selectedDisplayObject?.placeholderCopy}
            required
            searchable
            value={fieldKeys?.[fieldIndex]?.key}
            virtualizeOptions={fieldType === SelectorTypes.CustomField}
          />
        )}
        {selectedDisplayObject?.nameDisplayType === NameDisplayTypes.PillSelector && (
          <PillSelector
            className={fieldError && fieldTouched ? 'selector-name-input field-error' : 'selector-name-input'}
            error={fieldError && fieldTouched ? fieldError : undefined}
            handleCreate={(createdTag): void => {
              updateSelectedKeys([...fieldKeys || [], { key: createdTag.name, label: createdTag.name }]);
            }}
            handleDelete={(_, i): void => {
              updateSelectedKeys([...fieldKeys.filter((key, j) => (i !== j)) || []]);
            }}
            id="selector-name-input"
            label={<>{selectedDisplayObject?.labelContent}</>}
            placeholder={selectedDisplayObject?.placeholderCopy}
            required
            selectedValues={fieldKeys?.map((fieldKey) => fieldKey.key)}
            suggestedValues={selectedDisplayObject?.nameOptions?.map((option: ListOption) => (option.value.toString()))}
          />
        )}
        {selectedDisplayObject?.nameDisplayType === NameDisplayTypes.Textfield && (
          <StandardTextField
            className="selector-name-input"
            disabled={isSectionDisabled}
            error={fieldError && fieldTouched ? fieldError : undefined}
            id="selector-name-input"
            label={<>{selectedDisplayObject?.labelContent}</>}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              updateSelectedKeys([{ key: e.target.value, label: e.target.value }]);
            }}
            placeholder={selectedDisplayObject?.placeholderCopy}
            required
          />
        )}
        {selectedDisplayObject?.nameDisplayType === NameDisplayTypes.Watermark && (
          <WatermarkSelector
            error={fieldError && fieldTouched ? fieldError : undefined}
            updateWatermark={updateWatermark}
            watermark={watermark}
          />
        )}
      </div>
      {fieldType === SelectorTypes.CustomField && (
        <CustomFieldValuesSelector
          actionableId={actionableId}
          associatedCustomFieldKey={fieldKeys?.[fieldIndex]?.key}
          error={customFieldValuesError && customFieldValuesTouched ? customFieldValuesError : undefined}
          fieldKeys={fieldKeys}
          isMultiValuesAllowed={isMultiValuesAllowed}
          isRestricted={isRestricted}
          isSectionDisabled={isSectionDisabled}
          selectedDisplayObject={selectedDisplayObject}
          updateCustomFieldValues={updateCustomFieldValues}
          valueAllowedOptions={valueAllowedOptions}
          values={values}
        />
      )}
      {/* Resetting watermark is handled within <WatermarkSelector /> */}
      {fieldType !== SelectorTypes.Watermark && (
        <TextWarningButton
          aria-label={t`reset selector values button`}
          className="reset-selector-values-button"
          disabled={!fieldKeys}
          icon={FontIcons.Trash}
          iconSize={16}
          onClick={(): void => {
            if (fieldType === SelectorTypes.CustomField) {
              updateCustomFieldValues(fieldKeys?.[fieldIndex]?.key, null);
              updateCustomFieldKey(null, fieldIndex);
              if (actionableId) {
                setFieldTouched(Fields.Actions, false);
              } else {
                setFieldTouched(Fields.TriggerKeys, false);
                setFieldTouched(Fields.Values, false);
              }
            } else {
              resetField();
            }
          }}
        />
      )}

    </div>
  );
};
