/* eslint-disable react/require-default-props */
import { t, Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { StandardCheckboxGroup } from '@components/library/checkbox_group';
import { StandardSearchField, searchStatus } from '@components/library/search-field';
import { WorkspaceData } from '@components/show_page/bulk_actions/bulk-workspace-modal/BulkWorkspaceModalTypes';

export interface SelectWorkspacesViewProps {
  searchValue?: string;
  selectedWorkspaceKeys?: string[];
  setSearchValue?: SetStateDispatch<string>;
  setSelectedWorkspaceKeys?: SetStateDispatch<string[]>;
  showSearchWorkspaceCount?: number;
  workspaces?: WorkspaceData[];
}

export const SelectWorkspacesView: FunctionComponent<SelectWorkspacesViewProps> = ({
  searchValue,
  selectedWorkspaceKeys,
  setSearchValue,
  setSelectedWorkspaceKeys,
  showSearchWorkspaceCount,
  workspaces,
}) => {
  const sortWorkspaces = (): WorkspaceData[] => (
    workspaces && workspaces.sort((a, b) => {
      if (a.attributes.name.toUpperCase() > b.attributes.name.toUpperCase()) return 1;
      if (b.attributes.name.toUpperCase() > a.attributes.name.toUpperCase()) return -1;
      return 0;
    })
  );

  const displaySearch = workspaces && workspaces.length >= showSearchWorkspaceCount;
  const checkboxifiedWorkspaces = sortWorkspaces().map((workspace) => {
    const isSelected = selectedWorkspaceKeys.includes(workspace.id);
    return { checked: isSelected, id: workspace.id, labelCopy: workspace.attributes.name };
  });
  const filteredWorkspaces = checkboxifiedWorkspaces.filter((checkbox) => (
    checkbox?.labelCopy?.toUpperCase()?.includes(searchValue?.toUpperCase())
  ));

  return (
    <>
      {!displaySearch ? (
        <h3><Trans>Select Workspaces</Trans></h3>
      ) : (
        <StandardSearchField
          ariaSearchLabel={t`Workspace search`}
          id="workspace-search"
          label={t`Search workspaces to select`}
          onChange={(e: InputChangeEvent): void => { setSearchValue(e.target.value); }}
          placeholder={t`Search Workspaces`}
          searchStatus={searchStatus({ resultQuantity: filteredWorkspaces.length, resultsShown: searchValue.length > 0 })}
          showLabel
          value={searchValue}
        />
      )}
      <div className={`workspace-list-container ${displaySearch ? 'fixed-height' : ''}`}>
        {filteredWorkspaces.length === 0 && searchValue && (
          <span className="no-results-copy"><Trans>No results to display</Trans></span>
        )}
        <StandardCheckboxGroup
          checkboxes={filteredWorkspaces}
          name="available-workspaces"
          onChange={(selectedWorkspaceKey): void => setSelectedWorkspaceKeys((prevState) => (
            prevState.includes(selectedWorkspaceKey)
              ? prevState.filter((prevStateKey) => prevStateKey !== selectedWorkspaceKey)
              : [...prevState, selectedWorkspaceKey]
          ))}
        />
      </div>
    </>
  );
};
