import { StandardTextfield } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import React, {
  useEffect,
  useRef,
  FunctionComponent,
  MutableRefObject,
  ReactNode
} from 'react';

import { CustomFieldValue } from '@api/v4/assets/customFieldTypes';
import { CustomFieldKeysResponseObject } from '@api/v4/resources/CustomFieldKeysTypes';
import { CustomFieldKeyValuesEntry, ValidationErrors } from '@components/asset/modal/tabs/edit/EditTabTypes';
import { TextWarningButton } from '@components/library/button';
import { ListOption } from '@components/library/dropdown/index';

import { FontIcon, FontIcons } from '@components/library/icon';

import {
  MultiValueFreeEntry,
  MultiValueRestricted,
  SingleValueFreeEntry,
  SingleValueRestricted
} from './custom-field-row-values';
import { RemoveValuesButton } from './RemoveValuesButton';

interface CustomFieldKeyValueRowProps {
  controlledCustomFieldsEnabled: boolean;
  customFieldKey: CustomFieldKeysResponseObject;
  existingCustomFieldMap: CustomFieldKeyValuesEntry;
  // customFieldRequired is only used by <CustomFieldsList>
  handleUpdate: (customFieldValue: CustomFieldValue, customFieldRequired?: boolean) => void;
  translating: boolean;
  errorState?: ValidationErrors[];
  focused?: boolean;
  handleCreate?: (customFieldValue: CustomFieldValue) => void;
  handleDelete?: (customFieldValue: CustomFieldValue) => void;
  handleDeleteRow?: (customFieldKeyId: string) => void;
  handleKeyUpdate?: (customFieldKey: { id: string; name: string }) => void;
  // customFieldRequired is only used by <CustomFieldsList>
  handleRemoveValues?: (customFieldKeyId: string, customFieldRequired?: boolean) => void;
  overflowParentRef?: MutableRefObject<HTMLElement>;
  setNewCustomFieldFocus?: SetStateDispatch<boolean>;
}

export const CustomFieldKeyValueRow: FunctionComponent<CustomFieldKeyValueRowProps> = ({
  controlledCustomFieldsEnabled,
  customFieldKey,
  errorState,
  existingCustomFieldMap,
  focused,
  handleCreate,
  handleDelete,
  handleDeleteRow,
  handleKeyUpdate,
  handleRemoveValues,
  handleUpdate,
  overflowParentRef,
  setNewCustomFieldFocus,
  translating,
}) => {
  const keyInputRef = useRef<HTMLInputElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { allowed_values, multi_value_enabled, name, prioritized, required, restricted } = customFieldKey.attributes;

  useEffect(() => {
    if (focused && keyInputRef.current) {
      keyInputRef.current.focus();
    }
  }, [focused]);

  const translationsWarning = (
    <div className="custom-field-values--translations">
      <span className="bff-warning" />
      <p className="custom-field-values--translations-hint">
        <Trans>Unrestricted multi-value custom fields cannot be translated.</Trans>
      </p>
    </div>
  );

  /**
   * https://brandfolder-team.atlassian.net/wiki/spaces/PROD/pages/1703084059/Multi-Value+Custom+Fields+UI+Behavior+Expectations
   * See above for guidance on the different scenarios that must be supported by custom fields
   */
  const getCustomFieldValueComponent = (): ReactNode => {
    if (controlledCustomFieldsEnabled && restricted) {
      const options: ListOption[] = allowed_values.map((allowedValue) => (
        { label: allowedValue, value: allowedValue }
      ));
      const currValues = options.filter((opt) => (
        existingCustomFieldMap?.customFieldValues?.some((cfv) => cfv.value === opt.value)
      ));
      if (multi_value_enabled) {
        if (translating) {
          return translationsWarning;
        }

        return (
          <MultiValueRestricted
            currValues={currValues}
            existingCustomFieldMap={existingCustomFieldMap}
            handleCreate={handleCreate}
            handleDelete={handleDelete}
            options={options}
            overflowParentRef={overflowParentRef}
            required={required}
          />
        );
      }

      return (
        <SingleValueRestricted
          currValues={currValues}
          existingCustomFieldMap={existingCustomFieldMap}
          handleUpdate={handleUpdate}
          options={options}
          overflowParentRef={overflowParentRef}
          required={required}
        />
      );
    }

    if (multi_value_enabled) {
      if (translating) {
        return translationsWarning;
      }

      return (
        <MultiValueFreeEntry
          existingCustomFieldMap={existingCustomFieldMap}
          handleCreate={handleCreate}
          handleDelete={handleDelete}
          required={required}
        />
      );
    }

    return (
      <SingleValueFreeEntry
        customFieldKeyId={customFieldKey.id}
        existingCustomFieldMap={existingCustomFieldMap}
        handleUpdate={handleUpdate}
        required={required}
      />
    );
  };

  const getNewCustomFieldIndicator = (): string => {
    if (customFieldKey.id.includes('create-cfk')) {
      return 'added-custom-field-row';
    }

    return '';
  };

  const isInvalidKeyError = errorState?.includes(
    ValidationErrors.InvalidKeyName
  );

  const getKeyComponent = (): ReactNode => {
    if (customFieldKey.id.includes('create-cfk')) {
      return (
        <StandardTextfield
          ref={keyInputRef}
          className='custom-field-primary-input'
          error={isInvalidKeyError
            && t`Key names may not contain colons, quotation marks, or periods`
          }
          id={`custom-field-input--${customFieldKey.id}`}
          label='Custom Field Key'
          onChange={(e): void => {
            if (setNewCustomFieldFocus) {
              setNewCustomFieldFocus(false);
            }
            handleKeyUpdate({ id: customFieldKey.id, name: e.target.value });
          }}
          placeholder={t`Key`}
          showLabel={false}
          value={name}
        />
      );
    }

    if (customFieldKey.attributes.multi_value_enabled && !customFieldKey.attributes.restricted) {
      return (
        <div>
          <span className='custom-field-key'>
            {prioritized && (<FontIcon aria-hidden className='custom-field-key__prioritized-symbol' data-testid='custom-field-key__prioritized-symbol' icon={FontIcons.ArrowUp} iconSize={10}/>)}
            {customFieldKey.attributes.name}
          </span>
          <p className="custom-field-key--value-separation-hint">
            <Trans comment="parenthetical hint">(Separate values with semicolons)</Trans>
          </p>
        </div>
      );
    }
    return (
      <span className='custom-field-key'>
        {prioritized && (<FontIcon aria-hidden className='custom-field-key__prioritized-symbol' data-testid='custom-field-key__prioritized-symbol' icon={FontIcons.ArrowUp} iconSize={10}/>)}
        {customFieldKey.attributes.name}
      </span>
    );
  };

  return (
    <li
      key={`custom-field-row-${customFieldKey.id}`}
      className={`custom-field-row ${getNewCustomFieldIndicator()}`}
    >
      <span className="custom-field-key--container">
        {getKeyComponent()}
      </span>
      <div className={classnames({
        'custom-field-value--container': true,
        'custom-field-key-error-present': isInvalidKeyError,
      })}>
        <div className="custom-field-value--input">
          {getCustomFieldValueComponent()}
        </div>
        <div className="custom-field-values--remove-buttons">
          {customFieldKey.id.includes('create-cfk') ? (
            <TextWarningButton
              className="custom-field-values--remove"
              icon="bff-trash"
              onClick={(): void => {
                handleDeleteRow(customFieldKey.id);
              }}
            >
              <Trans>Remove row</Trans>
            </TextWarningButton>
          ) : (
            <RemoveValuesButton
              isSingular={existingCustomFieldMap?.customFieldValues?.length === 1}
              isVisible={
                !(translating && customFieldKey.attributes.multi_value_enabled)
                && existingCustomFieldMap?.customFieldValues?.length > 0
                && existingCustomFieldMap.customFieldValues[0].value !== ''
              }
              onClick={(): void => handleRemoveValues(customFieldKey.id, customFieldKey.attributes.required)}
            />
          )}
        </div>
      </div>
    </li>
  );
};
