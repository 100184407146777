import { t } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import { I18nProviderWrapper } from '@components/common/I18nProviderWrapper';
import { SettingsEnableDisable } from '@components/settings/shared/settings-enable-disable';

interface DefaultShareManifestsPrivateProps {
  defaultEnabled: boolean | null;
}

const DefaultShareManifestPrivate: FunctionComponent<DefaultShareManifestsPrivateProps> = ({ defaultEnabled }) => (
  <I18nProviderWrapper>
    <SettingsEnableDisable
      defaultEnabled={defaultEnabled}
      errorTitle={t`Error updating default privacy of share links. Please contact support.`}
      id="default-share-manifests-private"
      successTitle={t`Default privacy of share links updated.`}
      title={t`Make share links private by default`}
      updateResourceKey="defaultShareManifestPrivate"
    />
  </I18nProviderWrapper>
);

export default DefaultShareManifestPrivate;
