import React, { createContext, useState, ReactNode, FunctionComponent } from 'react';

import { StandardDialog } from '@components/library/dialog';

/**
 * Provider that keeps context of current StandardDialog content and open || closed state.
 * This provider includes methods to open and close the dialog via the 'useDialog' hook.
 *
 * Example:
 *
 * ```jsx
 * import { useDialog } from '@components/common/custom_hooks/useDialog';
 *
 * const { openDialog, closeDialog } = useDialog();
 *
 * <Button onClick={() => openDialog(<div>The dialog content</div>)}>Open dialog</Button>;
 *
 * <Button onClick={closeDialog}>Close Dialog</Button>
 * ```
 */

export interface DialogOptions {
  id: string;
  title: string;
  dialogClassName?: string;
  focusLock?: boolean;
  height?: number;
  showFooter?: boolean;
  titleIcon?: string;
  width?: number;
}

export interface DialogContextProps {
  closeDialog: () => void;
  openDialog: (node: ReactNode, options?: DialogOptions) => void;
}

export const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export const DialogProvider: FunctionComponent = ({ children }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<ReactNode>(null);
  const [dialogOptions, setDialogOptions] = useState<DialogOptions>();

  const closeDialog = (): void => {
    setIsDialogOpen(false);
    if ((dialogOptions || dialogContent) && !isDialogOpen) {
      setDialogContent(null);
      setDialogOptions(undefined);
    }
  };

  const openDialog = (node: ReactNode, options?: DialogOptions): void => {
    setIsDialogOpen(true);
    setDialogContent(node);
    setDialogOptions(options);
  };

  return (
    <DialogContext.Provider
      value={{
        closeDialog,
        openDialog,
      }}
    >
      {children}
      <StandardDialog open={isDialogOpen} setOpen={setIsDialogOpen} {...dialogOptions}>
        {dialogContent}
      </StandardDialog>
    </DialogContext.Provider>
  );
};
