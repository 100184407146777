/* eslint-disable @typescript-eslint/naming-convention */
import fetchJSON from '@api/api_helper';

import { ApiResponseObject } from '@api/v4/ApiResponseTypes';

interface UpdateBrandguideProps {
  brandguideKey: string;
  cardImage?: string;
  name?: string;
  slug?: string;
}

export interface Brandguide {
  card_image: string;
  name: string;
  public: boolean;
  slug: string;
}

export type BrandguidePost = Omit<Brandguide, 'card_image'>;

type BrandguideResponseObject = ApiResponseObject<Brandguide, 'brandguide'>;

const url = (brandguideKey: string): string => `/api/v4/brandguides/${brandguideKey}`;

export const getBrandguide = (brandguideKey: string): Promise<BrandguideResponseObject> => (
  fetchJSON(url(brandguideKey))
    .then(({ data }) => data)
    .catch(async (response) => { throw (await response.json()); })
);

export const deleteBrandguide = (brandguideKey: string): Promise<BrandguideResponseObject> => (
  fetchJSON(url(brandguideKey), { method: 'DELETE' })
    .then(({ data }) => data)
    .catch(async (response) => { throw (await response.json()); })
);
