import { t } from '@lingui/macro';
import React, { useState, FunctionComponent } from 'react';

import { FormTabs } from '@components/bulk_management/user-settings/invitations/form-type-helpers';
import { InvitationForm } from '@components/bulk_management/user-settings/invitations/InvitationForm';
import { PendingInvitations } from '@components/bulk_management/user-settings/invitations/PendingInvitations';
import {
  Brandfolder,
  Portal,
  ServerResourceType
} from '@components/bulk_management/user-settings/resource-sidebar/ResourceTypes';
import { StandardTabs } from '@components/library/tabs';

import '../styles/invitation-form.scss';

interface InvitationMessageTabsProps {
  brandfolders: Brandfolder[];
  portals: Portal[];
  reloadPendingInvitations: boolean;
  selectedResourceKey: string;
  selectedResourceType: ServerResourceType;
  setReloadPendingInvitations: SetStateDispatch<boolean>;
  setReloadPlanLimits: SetStateDispatch<boolean>;
  setReloadUserGroups: SetStateDispatch<boolean>;
  setReloadUsers: SetStateDispatch<boolean>;
}

export const InvitationMessageTabs: FunctionComponent<InvitationMessageTabsProps> = ({
  brandfolders,
  portals,
  reloadPendingInvitations,
  selectedResourceKey,
  selectedResourceType,
  setReloadPendingInvitations,
  setReloadPlanLimits,
  setReloadUserGroups,
  setReloadUsers
}) => {
  const [currentTab, setCurrentTab] = useState<FormTabs>('invite');
  const invitationForm = (
    <InvitationForm
      brandfolders={brandfolders}
      currentTab={currentTab}
      portals={portals}
      selectedResourceKey={selectedResourceKey}
      selectedResourceType={selectedResourceType}
      setReloadPendingInvitations={setReloadPendingInvitations}
      setReloadPlanLimits={setReloadPlanLimits}
      setReloadUserGroups={setReloadUserGroups}
      setReloadUsers={setReloadUsers}
    />
  );

  return (
    <div className="invitation-form__invitation-message-tabs">
      <StandardTabs
        activatePanelOnPrevNext={false}
        caption={t`Choose between inviting new users and messaging existing users.`}
        id="invitation-form__tabs"
        onChange={(nextIndex): void => {
          if (nextIndex === 0) {
            setCurrentTab('invite');
          } else {
            setCurrentTab('message');
          }
        }}
        showUnderline
        tabListClassName="invitation-message-tabList"
        tabs={[{
          disabled: false,
          tabButton: t`Invite`,
          tabPanel: (
            <div className="user-settings__invite-users">
              {invitationForm}
              <div className="user-settings__pending-invitations">
                <PendingInvitations
                  reload={reloadPendingInvitations}
                  selectedResourceKey={selectedResourceKey}
                  selectedResourceType={selectedResourceType}
                  setReload={setReloadPendingInvitations}
                />
              </div>
            </div>
          )
        }, {
          disabled: false,
          tabButton: t`Message`,
          tabPanel:
            <div className="user-settings__invite-users">
              {invitationForm}
            </div>
        }]}
      />
    </div>
  );
};
