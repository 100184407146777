import fetchJSON from '@api/api_helper';
import { ProcessAnnotationResponse, getResourceContext, processAnnotationResponse } from '@api/v4/annotations/helper';

interface UrlOptions {
  attachmentKey: string;
  resourceKey: string;
  resourceType: string;
}

const getUrl = ({ resourceType, resourceKey, attachmentKey }: UrlOptions): string => (
  `/api/v4/${resourceType}s/${resourceKey}/attachments/${attachmentKey}/annotations`
);

export const getAnnotations = async (options, updateFetchControllers): Promise<ProcessAnnotationResponse> => {
  const { resourceType, resourceKey } = getResourceContext();
  const urlParams = { resourceType, resourceKey, attachmentKey: options.attachmentKey };
  const response = await fetchJSON(getUrl(urlParams), options, updateFetchControllers);
  return processAnnotationResponse(response);
};
