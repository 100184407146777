import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

import { BaseIconProps } from '@components/library/icon';

export const BaseIcon: FunctionComponent<BaseIconProps> = (props) => {
  const { className, variantName, ...otherProps } = props;

  const globalClassName = 'bf-icon';

  return (
    <span
      {...otherProps}
      className={classnames(
        globalClassName,
        variantName && `${globalClassName}__${variantName}`,
        className
      )}
      role="img"
    />
  );
};
