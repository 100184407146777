import { Trans } from '@lingui/macro';
import React, { FunctionComponent } from 'react';

import classNames from 'classnames';
import '../styles/subscription_feature.scss';

export const FEATURE_COPY = {
  contactUs: 'Get in Touch',
  emailBody: 'I\'d like to inquire about adding the',
  enabled: 'Enabled',
  pending: 'Pending',
  request: 'Request'
};

const enabledFeatureTag = (
  <>
    <span className='bff-confirmation' />
    <span><Trans>{FEATURE_COPY.enabled}</Trans></span>
  </>
);

const pendingFeatureTag = (
  <span className='pending'><Trans>{FEATURE_COPY.pending}</Trans></span>
);

const getInTouchFeatureTag = (
  <a href={`mailto:${BFG.supportEmail}`}><Trans>{FEATURE_COPY.contactUs}</Trans></a>
);

export interface Feature {
  description: string;
  name: string;
  id: string;
  isEnabled?: boolean | null;
}

export interface SubscriptionFeatureProps {
  feature: Feature;
  organizationName: string;
}

const SubscriptionFeature: FunctionComponent<SubscriptionFeatureProps> = ({ feature, organizationName }) => {
  const statusClasses = classNames({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'subscription__feature-status': true,
    enabled: !!feature.isEnabled,
    disabled: !feature.isEnabled
  });

  const pendingConfiguration =
    (feature.name == 'SAML/SSO' ||
      feature.name == 'Templates' ||
      feature.name == 'Brandguide');

  const requestFeatureTag: JSX.Element =
    // eslint-disable-next-line max-len
    <a href={`mailto:${BFG.supportEmail}?subject=${feature.name} Feature Request&body=${FEATURE_COPY.emailBody} ${feature.name} feature to ${organizationName}.`}>
      <Trans>{FEATURE_COPY.request}</Trans>
    </a>;

  const renderFeatureTag = (): JSX.Element => {
    if (feature.isEnabled) return enabledFeatureTag;
    if (pendingConfiguration) return pendingFeatureTag;
    if (feature.name === 'Other') return getInTouchFeatureTag;
    return requestFeatureTag;
  };

  return (
    <div key={feature.name} className='subscription__feature'>
      <div>
        <h3>{feature.name}</h3>
        <p>{feature.description}</p>
      </div>
      <div className={statusClasses}>
        {renderFeatureTag()}
      </div>
    </div>
  );
};

export default SubscriptionFeature;
