import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { BFLoader } from '@components/common/loader/main';

export default class ImagePreview extends React.Component {
  state = {
    isLoadingiFrame: true,
  }

  render() {
    const { isLoadingiFrame } = this.state;
    const { imageUrls, pdfUrl } = this.props;
    const displayiFrameClass = isLoadingiFrame ? 'hide-iframe' : '';

    if (imageUrls && imageUrls.length === 1) {
      return (
        <img
          alt="print preview"
          className="preview-image"
          src={imageUrls[0]}
        />
      );
    }

    return (
      <React.Fragment>
        {isLoadingiFrame ? <BFLoader /> : null }
        <iframe
          className={`preview-iframe ${displayiFrameClass}`}
          onLoad={() => this.setState({ isLoadingiFrame: false })}
          src={pdfUrl}
          title="pdf preview"
        >
          <p><Trans>Your browser does not support iframes.</Trans></p>
        </iframe>
      </React.Fragment>
    );
  }
}

ImagePreview.propTypes = {
  pdfUrl: PropTypes.string,
  imageUrls: PropTypes.PropTypes.arrayOf(PropTypes.string),
};

ImagePreview.defaultProps = {
  imageUrls: null,
  pdfUrl: '',
};
