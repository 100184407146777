import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';

import LinkForm from './link-form';

import './styles/modal.scss';

class LinkModal extends React.Component {
  handleSubmit = ({ name, url }) => {
    this.props.onUpdate({
      name,
      url,
    });

    this.props.onClose();
  }

  render() {
    const { name, url, id, brandfolderKey, onClose } = this.props;

    const isNew = name === '' && url === '';

    return (
      <ReactModal
        ariaHideApp
        className="react-modal-content"
        closeTimeout={500}
        isOpen
        onRequestClose={onClose}
        overlayClassName="react-modal-overlay"
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        shouldFocusAfterRender
        shouldReturnFocusAfterClose
      >
        <div className="link-modal__body">
          <div className="modal-content-wrapper__header">
            <span aria-hidden="true" className="bff-plus icon" />
            <h3>{isNew ? <Trans>New Link</Trans> : <Trans>Edit Link</Trans>}</h3>
          </div>
          <div className="modal-content-wrapper__body">
            <LinkForm
              brandfolderKey={brandfolderKey}
              id={id}
              name={name}
              onCancel={onClose}
              onSubmit={this.handleSubmit}
              url={url}
            />
          </div>
        </div>
      </ReactModal>
    );
  }
}

LinkModal.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string,
  brandfolderKey: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func,
};

LinkModal.defaultProps = {
  id: null,
  name: '',
  onClose: () => {},
  onUpdate: () => {},
  url: '',
};

export default LinkModal;
