import { Trans } from '@lingui/macro';
import React, { useEffect, FunctionComponent } from 'react';

import { sendAction, TrackedAction } from '@helpers/datadog-rum';

export const NoResources: FunctionComponent = () => {
  useEffect(() => {
    sendAction(TrackedAction.SmartsheetPublishNoResources);
  }, []);

  return (
    <div className="no-resources">
      <div className="submit-success__header">
        <span className="bff-private icon" />
        <h4 className="no-resources__header--title"><Trans>Whoops!</Trans></h4>
      </div>
      <p className="no-resources__instructions">
        <Trans>It looks like you don’t have permission to publish to any Brandfolder resources.</Trans>
      </p>
      <p className="no-resources__instructions">
        <Trans>Reach out to a Brandfolder admin to request permission.</Trans>
      </p>
    </div>
  );
};
