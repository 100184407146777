/* eslint-disable @typescript-eslint/naming-convention */
import fetchJSON from '@api/api_helper';
import { ProcessAnnotationResponse, getResourceContext, processAnnotationResponse } from '@api/v4/annotations/helper';

interface PostAnnotationsOptions {
  note: string;
  parent_id: string;
  versionKey: string;
  mentionedUsers: string[];
  x_offset_in_percentage: string;
  y_offset_in_percentage: string;
  params?: { [key: string]: string };
}

const url = ({ resourceType, resourceKey, versionKey }): string =>
  `/api/v4/${resourceType}s/${resourceKey}/versions/${versionKey}/annotations`;

export const postAnnotations = async (
  options: PostAnnotationsOptions,
  updateFetchControllers
): Promise<ProcessAnnotationResponse> => {
  const {
    note,
    parent_id,
    versionKey,
    mentionedUsers,
    x_offset_in_percentage,
    y_offset_in_percentage,
    params
  } = options;

  const { resourceKey, resourceType } = getResourceContext();
  const postUrl = url({ resourceType, resourceKey, versionKey });

  const data = {
    note,
    ...x_offset_in_percentage && { x_offset_in_percentage },
    ...y_offset_in_percentage && { y_offset_in_percentage },
    ...parent_id && { parent_id },
    ...mentionedUsers && { mentioned_users: mentionedUsers }
  };

  const appendedOptions = {
    body: { data: { attributes: data } },
    method: 'POST',
    ...params && { params }
  };

  const response = await fetchJSON(postUrl, appendedOptions, updateFetchControllers);
  return processAnnotationResponse(response);
};
